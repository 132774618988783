export const userRoles = {
  idEntity: 1,
  idModule: 7,
  fields: {
    idFieldPhone: 4,
    idFieldNamePrefix: 5,
    idFieldMiddleName: 7,
    idFieldNameSuffix: 9,
    idFieldTitle: 10,
    idFieldBirthday: 11,
    idFieldActive: 12,
    idFieldUserOffice: 867,
  },
  permissions: {
    idPermissionResetPassword: 4,
  },
};
