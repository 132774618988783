import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialBalanceFieldsPermissions } from '../../interfaces';

export const listBalanceReceivedQuery = (
  fieldsPermissions: SatFinancialBalanceFieldsPermissions,
): DocumentNode => gql`
  query ListSatForeignTradeSupplierPaymentsByIdSat(
    $data: ListSatForeignTradeSupplierPaymentsByIdSatInput!
  ) {
    listSatForeignTradeSupplierPaymentsByIdSat(data: $data) {
      items
      data {
        idSatForeignTradePayment
        ${
          fieldsPermissions.shipment.view
            ? `idSatForeignTradeSupplier2 {
                satForeignTradeSupplierNumber
              }
              `
            : ''
        }
        ${fieldsPermissions.percentage.view ? 'percentage' : ''}
        ${fieldsPermissions.amount.view ? 'amount' : ''}
        ${fieldsPermissions.paymentDate.view ? 'paymentDate' : ''}
      }
    }
  }
`;

export const getSumBalancePayments = gql`
  query GetSumDataFromSatForeignTradeSupplierBalanceByIdSat(
    $idSat: Int!
    $typeIdDomainGroup: Int!
  ) {
    getSumDataFromSatForeignTradeSupplierBalanceByIdSat(
      idSat: $idSat
      typeIdDomainGroup: $typeIdDomainGroup
    ) {
      totalAmount
      totalPercentage
    }
  }
`;
