import styled from 'styled-components';

export const Replies = styled.div`
  margin: 8px 0;
  margin-left: 42px;

  .show-repplies {
    padding: 12px 0;

    span {
      color: #42526e;
      display: flex;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        color: var(--grey-scale);
      }
    }

    span::before {
      content: '';
      height: 1px;
      width: 23px;
      background: #42526e;
      margin-right: 1em;
    }
  }
`;
