import { useLazyQuery } from '@apollo/client';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import React, {
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import Grid from '../../../../../components/Grid';
import GridCircle from '../../../../../components/Grid/GridCircle';
import GridInput from '../../../../../components/Grid/GridInput';
import MultiSelect from '../../../../../components/Grid/MultiSelect';
import pagination from '../../../../../config/pagination';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { ISatItem } from '../../../../../interfaces/ISatItem';
import { ProductImageType } from '../../../../../shared/enums/productImageType';
import { getAttachmentDownloadLinkQuery } from '../../../../../shared/querys/attachment';
import { removeTimeFromIsoDate } from '../../../../../utils/convertDate';
import { parseStCodeColumn } from '../../../../../utils/gridColumnsParse';
import updateLocalStorageInDb from '../../../../../utils/updateLocalStorageInDb';
import { ISat, ISatReferenceSizeBarcode } from '../../interfaces';
import { ISatItemsLazyParams } from './interfaces';
import { listSatItemsBySatIdQuery } from './queries';
import ReferenceSizeGrid from './ReferenceSizeGrid';
import { CheckboxContainer, GridHeader } from './styles';
import { gridConstants } from '../../../../../components/Grid/constants';
import FormCheckbox from '../../../../../components/FormCheckbox';

export interface ISatArtworkGridRef {
  getSatItemsToUpdate(): Promise<ISatItem[] | undefined>;
  getSatReferenceSizeBarcodesToUpdate(): Promise<
    ISatReferenceSizeBarcode[] | undefined
  >;
}

interface IArtworkGridProps {
  sat: ISat;
  selected: boolean;
  ref: Ref<ISatArtworkGridRef>;
  setHasGridChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArtworkGrid: React.FC<IArtworkGridProps> = React.forwardRef(
  ({ sat, selected, setHasGridChanged }, ref) => {
    const isConfection = sat.indConfection;

    const gridColumnsName = isConfection
      ? '@SAT:satArtworkGridConfectionColumns'
      : '@SAT:satArtworkGridColumns';

    const { showError } = useRefHook();

    const [globalFilter, setGlobalFilter] = useState('');

    const initialLazyParams: ISatItemsLazyParams = {
      ...pagination.initialLazyParams,
    };

    const [lazyParams, setLazyParams] =
      useState<ISatItemsLazyParams>(initialLazyParams);

    const isMounted = useRef(false);

    const [satItems, setSatItems] = useState<ISatItem[]>();

    const gridColumns = useMemo(() => {
      const columnList = [];
      columnList.push({ field: 'stCode', header: 'ST Code' });
      columnList.push({
        field: 'idSatSupplier2.idSupplier',
        header: 'SUN Number',
      });
      columnList.push({ field: 'namePt', header: 'Name PT' });
      columnList.push({
        field: 'packageDescription',
        header: 'Selling Package',
      });
      columnList.push({
        field: 'packageDescriptionResponsibles',
        header: 'Responsible',
      });
      if (sat.indConfection) {
        columnList.push({ field: 'artworkPt', header: 'Artwork (PT)' });
        columnList.push({ field: 'techniquePt', header: 'Technique (PT)' });
      }
      columnList.push({ field: 'packageNotes', header: 'Package Notes' });
      columnList.push({ field: 'packageEan13', header: 'Barcode Ean 13' });
      columnList.push({ field: 'innerdun14', header: 'Barcode Inner Dun 14' });
      columnList.push({ field: 'dun14', header: 'Barcode Dun 14' });
      columnList.push({ field: 'extraInfo', header: 'Extra Info' });
      columnList.push({
        field: 'importantInformation',
        header: 'Important Information',
      });
      columnList.push({ field: 'ncmAnuencias', header: 'Anuências' });
      columnList.push({ field: 'hrp', header: 'HRP' });
      if (sat.indConfection) {
        columnList.push({
          field: 'reference',
          header: 'Reference Size',
        });
        columnList.push({
          field: 'idSatItemReference2.colourEn',
          header: 'Reference Color',
        });
      }
      columnList.push({ field: 'diecut', header: 'DIECUT' });
      columnList.push({ field: 'sample', header: 'Sample' });
      columnList.push({ field: 'samplesApproved', header: 'Samples Approved' });
      columnList.push({ field: 'reviewNotes', header: 'Review Notes' });
      columnList.push({
        field: 'commercialReview',
        header: 'Commercial Review',
      });
      columnList.push({ field: 'designReview', header: 'Design Review' });
      columnList.push({
        field: 'licensorApproval',
        header: 'Licensor Approval',
      });
      columnList.push({ field: 'shippingMarks', header: 'Shipping Marks' });
      columnList.push({ field: 'finalArtwork', header: 'Final Artwork' });
      columnList.push({ field: 'sketches', header: 'Sketches' });

      return { columnList };
    }, [sat.indConfection]);

    const [selectedColumns, setSelectedColumns] = useState(
      gridColumns.columnList,
    );

    const [loadItemsData, { loading: satItemsLoading, data: satItemsData }] =
      useLazyQuery(listSatItemsBySatIdQuery, {
        variables: {
          data: {
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
              _orderBy: lazyParams.sortField,
              _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
            },
            globalSearch: lazyParams.globalFilter,
            idSat: sat.idSat,
            stCode: lazyParams.stCode,
            referenceSize: lazyParams.referenceSize,
            namePt: lazyParams.namePt,
            sellingPackage: lazyParams.sellingPackage,
            packageNotes: lazyParams.packageNotes,
          },
        },
        onCompleted: response => {
          if (response.listSatItemsBySatId) {
            setSatItems(response.listSatItemsBySatId.data);
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting SAT Items',
            detail: errorData.message,
          });
        },
      });

    useImperativeHandle(ref, () => ({
      getSatItemsToUpdate: async () => {
        return satItems?.map(satItem => {
          return {
            idSatItem: satItem.idSatItem,
            extraInfo: satItem.extraInfo,
            reviewNotes: satItem.reviewNotes,
            commercialReview: satItem.commercialReview,
            designReview: satItem.designReview,
            licensorApproval: satItem.licensorApproval,
            shippingMarks: satItem.shippingMarks,
            finalArtwork: satItem.finalArtwork,
            sketches: satItem.sketches,
          } as ISatItem;
        });
      },
      getSatReferenceSizeBarcodesToUpdate: async () => {
        const barcodesToUpdate = satItems
          ?.map(satItem => satItem.satItemReferences)
          .flat()
          .map(reference => reference?.satReferenceSizeBarcodes)
          .flat()
          .map(barcode => {
            return {
              idSatReferenceSizeBarcode: barcode?.idSatReferenceSizeBarcode,
              commercialReview: barcode?.commercialReview,
              designReview: barcode?.designReview,
              finalArtwork: barcode?.finalArtwork,
              sketches: barcode?.sketches,
            } as ISatReferenceSizeBarcode;
          });

        return barcodesToUpdate;
      },
    }));

    const onColumnToggle = (event: MultiSelectChangeEvent) => {
      const newSelectedColumns = event.value;
      const orderedSelectedColumns = gridColumns.columnList.filter(col =>
        newSelectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
      );

      // Salva colunas selecionadas no local storage
      localStorage.setItem(
        gridColumnsName,
        JSON.stringify(orderedSelectedColumns),
      );
      setSelectedColumns(orderedSelectedColumns);

      updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
    };

    function handleColumnHeader(headerName: string, tooltip?: string) {
      return (
        <span
          className="custom-header"
          data-pr-tooltip={tooltip}
          data-pr-position="right"
        >
          {headerName}
        </span>
      );
    }

    const parseSunColumn = (rowData: any) => {
      return (
        rowData.idSatSupplier2 && (
          <Link
            to={`/suppliers/list/${rowData.idSatSupplier2?.idSupplier}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {`SUN${rowData.idSatSupplier2?.idSupplier
              .toString()
              .padStart(5, '0')}`}
            <FiExternalLink size={15} />
          </Link>
        )
      );
    };

    const parseHrpColumn = (rowData: any) => {
      const hasHrpImage = rowData.idProduct2?.productImages?.some(
        (image: { productImageTypes: any[] }) =>
          image.productImageTypes.some(
            imageType => imageType.type === ProductImageType.HRP,
          ),
      );
      return <GridCircle isPositiveValue={hasHrpImage} />;
    };

    const parseDiecutColumn = (rowData: any) => {
      const hasDiecutImage = rowData.idProduct2?.productImages?.some(
        (image: { productImageTypes: any[] }) =>
          image.productImageTypes.some(
            imageType => imageType.type === ProductImageType.DIE_CUT,
          ),
      );
      return <GridCircle isPositiveValue={hasDiecutImage} />;
    };

    function handleColumnSize(column: string) {
      switch (column) {
        case 'namePt':
          return { width: '20rem' };
        case 'innerdun14':
        case 'importantInformation':
          return { width: '14.26rem' };
        case 'packageDescription':
        case 'packageNotes':
        case 'packageEan13':
        case 'dun14':
        case 'samplesApproved':
        case 'commercialReview':
        case 'designReview':
        case 'licensorApproval':
        case 'shippingMarks':
        case 'finalArtwork':
        case 'sketches':
          return { width: '12.86rem' };
        case 'artworkPt':
        case 'techniquePt':
        case 'reference':
        case 'idSatItemReference2.colourEn':
          return { width: '11.43rem' };
        case 'diecut':
          return { width: '5.5rem' };
        case 'hrp':
          return { width: '4rem' };
        default:
          return { width: '10rem' };
      }
    }

    const parseImportantInformationColumn = useCallback((props: any) => {
      return (
        props?.idProduct && (
          <Link
            className="flex justify-content-center"
            to={`/products/list/${props.idProduct}?tab=Checklist`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            Open
            <FiExternalLink size={15} />
          </Link>
        )
      );
    }, []);

    const calculateActualRowIndex = useCallback(
      (itemIndex: number) => {
        return itemIndex - lazyParams.rows * lazyParams.page;
      },
      [lazyParams.page, lazyParams.rows],
    );

    const onEditorValueChange = useCallback(
      (options: ColumnEditorOptions, value: any) => {
        const itemIndex = calculateActualRowIndex(options.rowIndex);
        const newSatItems = options.props.value;
        const newSatItem = newSatItems[itemIndex];
        newSatItem[options.field] = value;

        setSatItems([...newSatItems]);
        setHasGridChanged(true);
      },
      [calculateActualRowIndex, setHasGridChanged],
    );

    const onReferenceEditorValueChange = useCallback(
      (value: any) => {
        if (satItems) {
          const newSatItems = [...satItems];
          const newSatItem = newSatItems.find(satItem =>
            satItem.satItemReferences?.some(
              reference =>
                reference.idSatItemReference === value.idSatItemReference,
            ),
          );

          const newSatItemReference = newSatItem?.satItemReferences?.find(
            reference =>
              reference.idSatItemReference === value.idSatItemReference,
          );

          if (newSatItemReference) {
            newSatItemReference.satReferenceSizeBarcodes =
              newSatItemReference.satReferenceSizeBarcodes?.map(barcode => {
                if (
                  barcode.idSatReferenceSizeBarcode ===
                  value.idSatReferenceSizeBarcode
                ) {
                  return {
                    ...value,
                    idSatItemReference2: undefined,
                  } as ISatReferenceSizeBarcode;
                }
                return barcode;
              });
          }

          setSatItems([...newSatItems]);
          setHasGridChanged(true);
        }
      },
      [satItems, setHasGridChanged],
    );

    const onReplicate = useCallback(
      (field: string, value: string) => {
        if (satItems) {
          const newSatItems = satItems.map(satItem => {
            return {
              ...satItem,
              [field]: value,
            };
          });

          setSatItems([...newSatItems]);
        }
      },
      [satItems],
    );

    const parseDateInputField = useCallback(
      (props: ColumnEditorOptions, field: string) => {
        return (
          <GridInput
            field={field}
            gridName="satArtworkGridItems"
            defaultValue={
              props.rowData[field]
                ? removeTimeFromIsoDate(props.rowData[field])
                : ''
            }
            onChange={e => onEditorValueChange(props, e.currentTarget.value)}
            onReplicate={onReplicate}
            onClick={e => e.stopPropagation()}
            showReplicateButton
            type="date"
          />
        );
      },
      [onEditorValueChange, onReplicate],
    );

    const parseBooleanColumn = (rowData: any, field: string) => {
      const fieldIsTrue = rowData[field] === true;
      return fieldIsTrue ? 'Yes' : 'No';
    };

    const parseTextInputField = useCallback(
      (props: any, field: string) => {
        return (
          <InputText
            defaultValue={props.rowData[field]}
            onChange={e => onEditorValueChange(props, e.currentTarget.value)}
            onClick={e => e.stopPropagation()}
          />
        );
      },
      [onEditorValueChange],
    );

    const handleEditor = useCallback(
      (props: ColumnEditorOptions, column: string) => {
        switch (true) {
          case column === 'stCode':
            return parseStCodeColumn(props.rowData, props);
          case column === 'idSatSupplier2.idSupplier':
            return parseSunColumn(props.rowData);
          case column === 'hrp':
            return parseHrpColumn(props.rowData);
          case column === 'diecut':
            return parseDiecutColumn(props.rowData);
          case column === 'extraInfo':
          case column === 'reviewNotes':
            return parseTextInputField(props, column);
          case column === 'importantInformation':
            return parseImportantInformationColumn(props.rowData);
          case column === 'commercialReview' && !isConfection:
          case column === 'designReview' && !isConfection:
          case column === 'licensorApproval':
          case column === 'shippingMarks':
          case column === 'finalArtwork' && !isConfection:
          case column === 'sketches' && !isConfection:
            return parseDateInputField(props, column);
          case column === 'sample':
          case column === 'samplesApproved':
            return parseBooleanColumn(props.rowData, column);
          case column === 'commercialReview' && isConfection:
          case column === 'designReview' && isConfection:
          case column === 'finalArtwork' && isConfection:
          case column === 'sketches' && isConfection:
            return undefined;
          default:
            return props.rowData[column];
        }
      },
      [
        isConfection,
        parseDateInputField,
        parseImportantInformationColumn,
        parseTextInputField,
      ],
    );

    const dynamicColumns = selectedColumns.map(col => {
      return (
        col.header &&
        col.field && (
          <Column
            key={col.field}
            columnKey={col.field}
            field={col.field}
            header={handleColumnHeader(col.header)}
            style={handleColumnSize(col.field)}
            sortable={
              col.field !== 'diecut' &&
              col.field !== 'hrp' &&
              col.field !== 'commercialReview' &&
              col.field !== 'designReview' &&
              col.field !== 'licensorApproval' &&
              col.field !== 'shippingMarks' &&
              col.field !== 'finalArtwork' &&
              col.field !== 'sketches'
            }
            editor={props => handleEditor(props, col.field)}
          />
        )
      );
    });

    function onPage(event: DataTablePageEvent) {
      const newLazyParams = { ...lazyParams, ...event };
      setLazyParams(newLazyParams);
    }

    function onSort(event: DataTableSortEvent) {
      const newLazyParams = { ...lazyParams, ...event };
      setLazyParams(newLazyParams);
    }

    // Ao pesquisar no filtro global
    useEffect(() => {
      // Valida se componente esta montado
      if (isMounted.current) {
        // Define delay na busca para nao bater no backend a cada tecla digitada
        const delayDebounceFn = setTimeout(() => {
          const newLazyParams = { ...lazyParams };
          newLazyParams.first = 0;
          newLazyParams.page = 0;
          newLazyParams.globalFilter = globalFilter;
          setLazyParams(newLazyParams);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      // Define que componente esta montado
      isMounted.current = true;
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter]);

    const [downloadChecklist, { loading: downloadChecklistLoading }] =
      useLazyQuery(getAttachmentDownloadLinkQuery, {
        variables: {
          name: 'CHECKLIST_EMBALAGENS.PDF',
        },
        onCompleted: async response => {
          window.open(response.getAttachmentDownloadLink, '_blank');
        },
        onError: error => {
          showError({
            summary: 'Error while downloading checklist',
            detail: error.message,
          });
        },
      });

    const handleDownloadChecklist = useCallback(() => {
      downloadChecklist();
    }, [downloadChecklist]);

    useEffect(() => {
      if (!satItems?.length && selected) {
        loadItemsData();
      }
    }, [loadItemsData, satItems?.length, selected]);

    useEffect(() => {
      const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

      if (localStorageSelectedColumns) {
        setSelectedColumns(JSON.parse(localStorageSelectedColumns));
      }
    }, [gridColumnsName]);

    const onReferenceReplicate = useCallback(
      (field: string, value: string) => {
        if (satItems) {
          const newSatItems = satItems.map(satItem => {
            return {
              ...satItem,
              satItemReferences: satItem.satItemReferences?.map(reference => {
                return {
                  ...reference,
                  satReferenceSizeBarcodes:
                    reference.satReferenceSizeBarcodes?.map(barcode => {
                      return {
                        ...barcode,
                        [field]: value,
                      };
                    }),
                };
              }),
            };
          });

          setSatItems(newSatItems);
        }
      },
      [satItems],
    );

    const rowExpansionTemplate = useCallback(
      (data: any) => {
        const satReferenceSizeBarcodes = data.satItemReferences
          ?.map(
            (reference: {
              idSatItemReference: number;
              satReferenceSizeBarcodes: any[];
            }) =>
              reference.satReferenceSizeBarcodes?.map((barcode: any) => {
                return {
                  ...barcode,
                  idSatItemReference: reference.idSatItemReference,
                  idSatItemReference2: reference,
                  packageEan13: barcode.sellingPackage,
                  innerdun14: barcode.innerBox,
                  dun14: barcode.masterBox,
                };
              }),
          )
          .flat();
        return (
          satReferenceSizeBarcodes &&
          satReferenceSizeBarcodes.length > 0 && (
            <ReferenceSizeGrid
              data={satReferenceSizeBarcodes}
              columns={selectedColumns}
              handleColumnSize={handleColumnSize}
              handleReplication={onReferenceReplicate}
              onReferenceEditorValueChange={onReferenceEditorValueChange}
            />
          )
        );
      },
      [onReferenceEditorValueChange, onReferenceReplicate, selectedColumns],
    );

    return (
      <div>
        <GridHeader>
          <FormTitle className="col-12 md:col-6 mt-5" name="Artwork Grid" />
          <div className="grid-header-buttons mt-5">
            <InputText
              className="gridSearch"
              type="search"
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              placeholder="Search an Item"
            />
            <Button
              className=""
              label="Checklist"
              onClick={handleDownloadChecklist}
              icon={
                downloadChecklistLoading ? 'pi pi-spin pi-spinner' : undefined
              }
            />
            <MultiSelect
              className="grid-multiselect-panel"
              value={selectedColumns}
              options={gridColumns.columnList.filter(
                column => column.field && column.header,
              )}
              onChange={onColumnToggle}
            />
          </div>
        </GridHeader>
        <CheckboxContainer>
          <FormCheckbox
            className="m-2"
            name="hrpNotRequired"
            label="HRP Not Required"
            initialValue={sat.satArtwork?.hrpNotRequired}
          />
          <FormCheckbox
            className="m-2"
            name="diecutNotRequired"
            label="DIECUT Not Required"
            initialValue={sat.satArtwork?.diecutNotRequired}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <FormCheckbox
            className="m-2"
            name="sampleNotRequired"
            label="Sample Not Required"
            initialValue={sat.satArtwork?.sampleNotRequired}
          />
          <FormCheckbox
            className="m-2"
            name="clientInfoNotRequired"
            label="Client info Not Required"
            initialValue={sat.satArtwork?.clientInfoNotRequired}
          />
        </CheckboxContainer>
        <Grid
          className="s-expanded-datatable"
          name="satArtworkGridItems"
          lazy
          totalRecords={
            !satItemsData ? 0 : satItemsData?.listSatItemsBySatId?.items
          }
          value={satItems}
          globalFilter={globalFilter}
          emptyMessage="No SAT items found."
          removableSort
          rows={lazyParams.rows}
          first={lazyParams.first}
          onSort={onSort}
          onPage={onPage}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          loading={satItemsLoading}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          editMode="row"
          editingRows={satItems}
          onRowEditChange={() => ''}
          expandedRows={isConfection ? satItems : undefined}
          rowExpansionTemplate={rowExpansionTemplate}
          tableStyle={{
            tableLayout: 'fixed',
          }}
        >
          {dynamicColumns}
        </Grid>
      </div>
    );
  },
);

export default ArtworkGrid;
