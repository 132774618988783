import {
  ForeignTradeReducer,
  ForeignTradeReducerAction,
  ForeignTradeReducerActionKind,
  SatForeignTradeItemContainer,
  SatForeignTradeItemContainerToCreate,
  SatForeignTradeItemContainerToCreatePayload,
  SatForeignTradeItemContainerToUpdatePayload,
  SatForeignTradeItemToUpdate,
  SatItemReferenceToUpdate,
} from './interfaces';

export const foreignTradeReducerInitialState: ForeignTradeReducer = {
  tabActiveIndex: 0,
  itemsToUpdate: [],
  itemContainersToUpdate: [],
  itemContainersToCreate: [],
  divergentData: [],
  consolidationData: [],
  satItemReferencesToUpdate: [],
};

export const foreignTradesReducer = (
  state: ForeignTradeReducer,
  action: ForeignTradeReducerAction,
): ForeignTradeReducer => {
  switch (action.type) {
    case ForeignTradeReducerActionKind.RESET_STATE: {
      return {
        itemsToUpdate: [],
        itemContainersToUpdate: [],
        itemContainersToCreate: [],
        divergentData: [],
        consolidationData: [],
        satItemReferencesToUpdate: [],
        tabActiveIndex:
          action.payload?.tabActiveIndex !== undefined
            ? action.payload?.tabActiveIndex
            : foreignTradeReducerInitialState.tabActiveIndex,
      };
    }

    case ForeignTradeReducerActionKind.SET_ACTIVE_TAB_INDEX: {
      if (action.payload?.tabActiveIndex === undefined) return state;

      return { ...state, tabActiveIndex: action.payload.tabActiveIndex };
    }

    case ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_UPDATE:
      if (action.payload?.itemContainerToUpdate) {
        const { itemContainerToUpdate } = action.payload;

        // Busca indice item que possui o container modificado
        const itemToUpdateIndex = state.itemContainersToUpdate.findIndex(
          item =>
            item.idSatForeignTradeItemCtnr ===
            itemContainerToUpdate.idSatForeignTradeItemCtnr,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToUpdateIndex !== -1) {
          // Muda apenas o campo que foi atualizado, sem sobrescrever outros
          // valores
          const { fieldUpdated } = itemContainerToUpdate;
          const updatedItemObject: SatForeignTradeItemContainer = {
            ...state.itemContainersToUpdate[itemToUpdateIndex],
            [fieldUpdated]:
              itemContainerToUpdate[
                fieldUpdated as keyof SatForeignTradeItemContainerToUpdatePayload
              ],
          };

          state.itemContainersToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          // Se nao estiver, adiciona no array com todas as informacoes
          const {
            idSatForeignTrade,
            idSatForeignTradeItemCtnr,
            quantityCtnr,
            grossWeight,
            netWeight,
            cbm,
          } = action.payload?.itemContainerToUpdate;

          const updatedItemObject: SatForeignTradeItemContainer = {
            idSatForeignTrade,
            idSatForeignTradeItemCtnr,
            quantityCtnr,
            grossWeight,
            netWeight,
            cbm,
          };

          state.itemContainersToUpdate.push(updatedItemObject);
        }
      }

      return state;

    case ForeignTradeReducerActionKind.SET_ITEM_CONTAINER_TO_CREATE:
      if (action.payload?.itemContainerToCreate) {
        const { itemContainerToCreate } = action.payload;
        const {
          idSatForeignTradeItem,
          idSatForeignTradeItemReference,
          idSatForeignTradeCtnr,
        } = itemContainerToCreate;

        // Busca indice item que possui o container modificado
        const itemToCreateIndex = state.itemContainersToCreate.findIndex(
          item =>
            item.idSatForeignTradeItem === idSatForeignTradeItem &&
            item.idSatForeignTradeItemReference ===
              idSatForeignTradeItemReference &&
            item.idSatForeignTradeCtnr === idSatForeignTradeCtnr,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToCreateIndex !== -1) {
          // Muda apenas o campo que foi atualizado, sem sobrescrever outros
          // valores
          const { fieldUpdated } = itemContainerToCreate;
          const itemToCreate: SatForeignTradeItemContainerToCreate = {
            ...state.itemContainersToCreate[itemToCreateIndex],
            [fieldUpdated]:
              itemContainerToCreate[
                fieldUpdated as keyof SatForeignTradeItemContainerToCreatePayload
              ],
          };

          state.itemContainersToCreate.splice(
            itemToCreateIndex,
            1,
            itemToCreate,
          );
        } else {
          // Se nao estiver, adiciona no array com todas as informacoes
          const {
            idSatForeignTrade,
            quantityCtnr,
            grossWeight,
            netWeight,
            cbm,
          } = itemContainerToCreate;

          const itemToCreate: SatForeignTradeItemContainerToCreate = {
            idSatForeignTrade,
            idSatForeignTradeCtnr,
            idSatForeignTradeItem,
            idSatForeignTradeItemReference,
            quantityCtnr,
            grossWeight,
            netWeight,
            cbm,
          };

          state.itemContainersToCreate.push(itemToCreate);
        }
      }
      return state;

    case ForeignTradeReducerActionKind.REMOVE_UPDATES_AND_CREATES_FROM_FOREIGN_TRADE:
      if (action.payload?.idForeignTradeToRemoveEntries) {
        const newItemContainersToCreate = state.itemContainersToCreate.filter(
          item =>
            item.idSatForeignTrade !==
            action.payload?.idForeignTradeToRemoveEntries,
        );

        const newItemContainersToUpdate = state.itemContainersToUpdate.filter(
          item =>
            item.idSatForeignTrade !==
            action.payload?.idForeignTradeToRemoveEntries,
        );

        return {
          ...state,
          itemContainersToCreate: newItemContainersToCreate,
          itemContainersToUpdate: newItemContainersToUpdate,
        };
      }

      return state;

    case ForeignTradeReducerActionKind.SET_DIVERGENT_DATA:
      if (action.payload?.divergentData) {
        return {
          ...state,
          divergentData: action.payload.divergentData,
        };
      }

      return state;

    case ForeignTradeReducerActionKind.SET_GRID_ITEM_TO_UPDATE:
      if (action.payload?.itemToUpdate) {
        const { idSatForeignTrade, idSatForeignTradeItem, quantityPiShipment } =
          action.payload?.itemToUpdate;

        const updatedItemObject: SatForeignTradeItemToUpdate = {
          idSatForeignTrade,
          idSatForeignTradeItem,
          quantityPiShipment,
        };

        // Busca indice item que possui a referenca modificada
        const itemToUpdateIndex = state.itemsToUpdate.findIndex(
          item => item.idSatForeignTradeItem === idSatForeignTradeItem,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToUpdateIndex !== -1) {
          state.itemsToUpdate.splice(itemToUpdateIndex, 1, updatedItemObject);
        } else {
          // Se nao estiver, adiciona no array
          state.itemsToUpdate.push(updatedItemObject);
        }
      }
      return state;

    case ForeignTradeReducerActionKind.SET_CONSOLIDATION_DATA:
      if (action.payload?.consolidationData) {
        return {
          ...state,
          consolidationData: action.payload.consolidationData,
        };
      }

      return state;

    case ForeignTradeReducerActionKind.SET_GRID_SAT_ITEM_REFERENCE_TO_UPDATE:
      if (action.payload?.satItemReferenceToUpdate) {
        const {
          idSatForeignTradeItem,
          idSatItemReference,
          quantityPiShipment,
        } = action.payload?.satItemReferenceToUpdate;

        const updatedReferenceObject: SatItemReferenceToUpdate = {
          idSatForeignTradeItem,
          idSatItemReference,
          quantityPiShipment,
        };

        // Busca indice item que possui a referenca modificada
        const itemToUpdateIndex = state.satItemReferencesToUpdate.findIndex(
          item =>
            item.idSatForeignTradeItem === idSatForeignTradeItem &&
            item.idSatItemReference === idSatItemReference,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToUpdateIndex !== -1) {
          state.satItemReferencesToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedReferenceObject,
          );
        } else {
          // Se nao estiver, adiciona no array
          state.satItemReferencesToUpdate.push(updatedReferenceObject);
        }
      }

      return state;

    default:
      throw new Error();
  }
};
