import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  icmsName: {
    field: 'icmsName',
    header: 'ICMS Name',
    advancedFilterField: 'icmsName',
  },
  client: {
    field: 'idClient2.name',
    header: 'ICMS Client',
    advancedFilterField: 'client',
  },
  region: {
    field: 'idRegion2.name',
    header: 'Estado',
    advancedFilterField: 'region',
  },
  destinacao: {
    field: 'idDestinacao2.description',
    header: 'Destinação',
    advancedFilterField: 'destinacao',
  },
  icmsBase: {
    field: 'icmsBase',
    header: 'Alíquota Base ICMS',
    advancedFilterField: 'icmsBase',
  },
  icmsRecolhido: {
    field: 'icmsRecolhido',
    header: 'Alíquota ICMS Recolhido',
    advancedFilterField: 'icmsRecolhido',
  },
};
