import { FormHandles } from '@unform/core';
import { Toast, ToastMessage } from 'primereact/toast';
import React, {
  createContext,
  useState,
  useContext,
  useRef,
  RefObject,
} from 'react';
import ToastLife from '../shared/enums/toastLife';

type ToastProps = Omit<ToastMessage, 'severity'> & {
  summary: string;
};

// Interface do contexto de referencia
interface IRefHookContext {
  formRef: RefObject<FormHandles>;
  toastRef: RefObject<Toast>;
  showError: (props: ToastProps) => void;
  showSuccess: (props: ToastProps) => void;
  showWarn: (props: ToastProps) => void;
}

// Cria o contexto
const RefHookContext = createContext<IRefHookContext>({} as IRefHookContext);

const RefHookProvider: React.FC = ({ children }) => {
  // Referencia ao formulario
  const refCont = useRef<FormHandles>(null);
  const [formRef] = useState(refCont);

  // Referencia a toast
  const toast = useRef<Toast>(null);
  const [toastRef] = useState(toast);

  function showError(props: ToastProps) {
    toastRef.current?.show({
      ...props,
      severity: 'error',
      life: ToastLife.ERROR,
    });
  }

  function showSuccess(props: ToastProps) {
    toastRef.current?.show({
      ...props,
      severity: 'success',
      life: ToastLife.SUCCESS,
    });
  }

  function showWarn(props: ToastProps) {
    toastRef.current?.show({
      ...props,
      severity: 'warn',
      life: ToastLife.WARN,
    });
  }

  return (
    <RefHookContext.Provider
      value={{ formRef, toastRef, showError, showSuccess, showWarn }}
    >
      {children}
    </RefHookContext.Provider>
  );
};

function useRefHook(): IRefHookContext {
  // Busca contexto
  const context = useContext(RefHookContext);

  // Valida o uso no arquivo app.tsx
  if (!context) {
    throw new Error('useRefHook must be used within an RefHookProvider');
  }

  // retorna contexto
  return context;
}

export { RefHookProvider, useRefHook };
