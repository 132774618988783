/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';

import { DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import Button from '../../../components/Button';
import AddBrand, { IAddBrandRef } from './AddBrand';
import { useRefHook } from '../../../hooks/useRefHook';
import { IPartnerBranding, PartnerBrandingLazyParams } from './interfaces';
import pagination from '../../../config/pagination';
import {
  deletePartnerBrandingsQuery,
  listPartnerBrandingsQuery,
} from './queries';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import { parseDateColumm } from '../../../utils/gridColumnsParse';

interface IBrandProps extends PageTabContainerProps {
  idPartner: number;
}

const Branding: React.FC<IBrandProps> = ({ selected, idPartner }) => {
  const [deletePartnerBrandingsMutation, { loading: deleteInProgress }] =
    useMutation(deletePartnerBrandingsQuery);

  const addBrandRef = useRef<IAddBrandRef>(null);
  const { showError, showSuccess } = useRefHook();

  const [partnerBrandings, setPartnerBrandings] =
    useState<IPartnerBranding[]>();
  const [selectedBrands, setSelectedBrands] = useState<IPartnerBranding[]>([]);
  const [lazyParams, setLazyParams] = useState<PartnerBrandingLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadBrandsData,
    { loading: brandsLoading, data: brandsData, refetch: brandsRefetch },
  ] = useLazyQuery(listPartnerBrandingsQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        idPartner,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
      },
    },
    onCompleted: response => {
      if (response.listPartnerBrandings) {
        setPartnerBrandings([...response.listPartnerBrandings.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Partner Brandings',
        detail: errorData.message,
      });
    },
  });

  const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
    return rowData.imageUrl ? (
      <a
        href={`${rowData.imageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p> - </p>
    );
  };

  async function handleDeleteSelected() {
    try {
      await deletePartnerBrandingsMutation({
        variables: {
          idsPartnerBrandings: selectedBrands.map(
            brand => brand.idPartnerBranding,
          ),
        },
      });
      await brandsRefetch();

      showSuccess({
        summary: 'Brands deleted',
        detail: `${selectedBrands.length} brands deleted`,
      });

      setSelectedBrands([]);
    } catch (error) {
      showError({
        summary: 'Error while deleting brands',
        detail: error.message,
      });
    }
  }

  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    addBrandRef.current?.toggleModal(e.data);
  }

  function onPage(event: DataTablePageEvent) {
    setLazyParams({ ...lazyParams, ...event });
  }

  function onSort(event: DataTableSortEvent) {
    setLazyParams({ ...lazyParams, ...event });
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button
        label="Add Brand"
        onClick={() => addBrandRef.current?.toggleModal()}
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => {
          confirmDialog({
            message: `Are you sure you want to delete ${selectedBrands.length} brands?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-question-circle',
            accept: () => handleDeleteSelected(),
          });
        }}
        disabled={!selectedBrands.length}
        loading={deleteInProgress}
      />
    </div>
  );

  useEffect(() => {
    if (selected && !partnerBrandings) {
      loadBrandsData();
    }
  }, [loadBrandsData, partnerBrandings, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AddBrand
        ref={addBrandRef}
        idPartner={idPartner}
        brandsRefetch={brandsRefetch}
      />

      <Container>
        <Grid
          className="s-internal-datatable"
          value={partnerBrandings}
          header={gridHeader}
          selection={selectedBrands}
          onSelectionChange={e => setSelectedBrands(e.value)}
          emptyMessage="No brands found"
          onRowClick={onRowClick}
          selectionMode="checkbox"
          lazy
          loading={brandsLoading}
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={brandsData?.listPartnerBrandings?.items ?? 0}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          onSort={onSort}
          onPage={onPage}
          removableSort
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column
            field="name"
            header="Name"
            sortable
            headerStyle={{ flex: '1 1' }}
            style={{ flex: '1 1' }}
          />
          <Column
            field="expirationDate"
            header="Expiration Date"
            sortable
            headerStyle={{ flex: '1 1' }}
            style={{ flex: '1 1' }}
            body={parseDateColumm}
          />
          <Column
            header="Attachment"
            body={imageBodyTemplate}
            headerStyle={{ flex: '1 1' }}
            style={{ flex: '1 1' }}
          />
        </Grid>
      </Container>
    </PageTabContainer>
  );
};

export default Branding;
