import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CategoryData = styled.div`
  padding: 26px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    width: 400px;
  }

  .add-item-button {
    max-width: 100px;
  }

  .category-items-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 587px;
    margin-top: 12px;
  }

  .item-and-preview {
    display: flex;
    width: 100%;

    .category-item {
      margin-left: 100px;
      margin-top: 12px;
    }
  }

  .buttons {
    max-width: 389px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    button + button {
      margin-left: 10px;
    }
    .load-other-categories {
      width: 140px;
      .p-button-icon {
        margin-right: 2px;
      }
    }
  }
`;
