import { DocumentNode, gql } from '@apollo/client';
import {
  GeneralInformationFieldsPermissions,
  SatItemsOrderAreaFieldsPermissions,
} from './interfaces';
import { FinancialFieldsCommonWithSatFieldPermissions } from './Financial/interfaces';

// Mutation de atualizacao de dados de cliente
export const updateSatSupplierQuery = gql`
  mutation UpdateSatSupplier($data: UpdateSatSupplierInput!) {
    updateSatSupplier(data: $data) {
      idSatSupplier
      mainProduct
    }
  }
`;

// Query para buscar arquivo template
export const generateSellerPIQuery = gql`
  query generateSellerPIQuery($idSat: Int!) {
    generateSatSellerPI(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateCotPIQuery = gql`
  query generateCotPIQuery($idSat: Int!) {
    generateSatCotPI(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateDDGIPQuery = gql`
  query generateDDGIPQuery($idSat: Int!) {
    generateSatDDGIP(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateODQuery = gql`
  query generateODQuery($idSat: Int!) {
    generateSatOD(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateShippingMarkQuery = gql`
  query generateShippingMarkQuery($idSat: Int!) {
    generateSatShippingMark(idSat: $idSat)
  }
`;

export const generateRoundStickerQuery = gql`
  query generateRoundStickerQuery($idSat: Int!) {
    generateSatRoundStickers(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateStickerQuery = gql`
  query generateStickerQuery($idSat: Int!) {
    generateSatStickers(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateInnerStickerQuery = gql`
  query generateInnerStickerQuery($idSat: Int!) {
    generateSatInnerStickers(idSat: $idSat)
  }
`;

// Query para buscar arquivo template
export const generateExportCiPlQuery = gql`
  query generateExportCiPlQuery($idSat: Int!) {
    generateSatExportCiPl(idSat: $idSat)
  }
`;

/**
 * Query para listar suppliers
 */
export const listAllSuppliersQuery = gql`
  query listAllSuppliers($data: ListAllSuppliersInput!) {
    listAllSuppliers(data: $data) {
      data {
        idSupplier
        name
        address
        idCountry2 {
          idCountry
          name
        }
      }
      items
    }
  }
`;

/**
 * Query para listar SATs por Cliente
 */
export const listAllSatsByClient = gql`
  query ListAllSatsByClient(
    $listAllSatsByClientInput: ListAllSatsByClientInput!
  ) {
    listAllSatsByClient(listAllSatsByClientInput: $listAllSatsByClientInput) {
      items
      data {
        idSat
        satNumber
      }
    }
  }
`;

/**
 * Mutation para criacao de Attachments
 */
export const createSatAttachmentsQuery = gql`
  mutation CreateSatAttachments($data: CreateSatAttachmentsInput!) {
    createSatAttachments(data: $data) {
      idSatAttachment
      idAttachment2 {
        nameGivenByUser
        idAttachment
        name
        url
      }
    }
  }
`;

/**
 * Mutation para apagar SatAttachment
 */
export const deleteSatAttachmentsQuery = gql`
  mutation DeleteSatAttachments($satAttachmentIds: [Int]!) {
    deleteSatAttachments(satAttachmentIds: $satAttachmentIds)
  }
`;

/**
 * Mutation para criar SAT Shipment
 */
export const createSatShipmentQuery = gql`
  mutation CreateSatShipment($data: CreateSatShipmentInput!) {
    createSatShipment(data: $data) {
      idSatForeignTrade
    }
  }
`;

// Query para listar Sat Cancel
export const listSatCancellationReasonQuery = gql`
  query ListSatCancellationReasonQuery($id: Int!) {
    listSatCancellationReason(id: $id) {
      idSatCancellationReason
      idSat
      isPrice
      isQuality
      isDeliveryTime
      isLegalRequirements
      isOthers
      detailReason
      expectedReactivationDate
    }
  }
`;

/**
 * Query para gerar relatorios de SAT
 */
export const getSatReportsQuery = gql`
  query GenerateSatBulkDownloads(
    $generateSatBulkDownloadsInput: GenerateSatBulkDownloadsInput!
  ) {
    generateSatBulkDownloads(
      generateSatBulkDownloadsInput: $generateSatBulkDownloadsInput
    ) {
      summary {
        error
        reportName
      }
      downloadUrl
    }
  }
`;

// Query para listar Sat
export const listSatQuery = (
  generalInformationFieldsPermissions: GeneralInformationFieldsPermissions,
  satItemsOrderAreaFieldsPermissions: SatItemsOrderAreaFieldsPermissions,
  financialFieldsCommonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions,
): DocumentNode => gql`
query listSatQuery($idSat: Int!) {
  listSatById(idSat: $idSat) {
    idSat
    ${
      generalInformationFieldsPermissions.idSupplierExporter.view ||
      satItemsOrderAreaFieldsPermissions.idSupplierExporter.view
        ? `idSupplierExporter
            idSupplierExporter2 {
              idSupplier
              name
              address
            }`
        : ''
    }
    idPartnerExporter
    idPartnerExporter2 {
      idPartner
      name
      address
    }
    ${
      generalInformationFieldsPermissions.idClient.view ||
      satItemsOrderAreaFieldsPermissions.idClient.view
        ? `idClient
            idClient2 {
              idClient
              name
              idLogo2 {
                url
              }
            }`
        : ''
    }
    fx
    satNumber
    ${
      generalInformationFieldsPermissions.companyName.view ||
      financialFieldsCommonWithSatFieldsPermissions.companyName.view
        ? 'companyName'
        : ''
    }
    ${generalInformationFieldsPermissions.clientCnpj.view ? 'clientCnpj' : ''}
    ${
      generalInformationFieldsPermissions.clientAddress.view
        ? 'clientAddress'
        : ''
    }
    ${
      generalInformationFieldsPermissions.idFirstBuyer.view
        ? `idFirstBuyer
            idFirstBuyer2 {
              idClientContact
              name
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idSecondBuyer.view
        ? `idSecondBuyer
            idSecondBuyer2 {
              idClientContact
              name
            }`
        : ''
    }
    ${generalInformationFieldsPermissions.idImporter.view ? 'idImporter' : ''}
    ${
      generalInformationFieldsPermissions.importerName.view ||
      satItemsOrderAreaFieldsPermissions.importerName.view ||
      financialFieldsCommonWithSatFieldsPermissions.importerName.view
        ? 'importerName'
        : ''
    }
    ${
      generalInformationFieldsPermissions.importerCnpj.view
        ? 'importerCnpj'
        : ''
    }
    ${
      generalInformationFieldsPermissions.importerAddress.view
        ? 'importerAddress'
        : ''
    }
    expireDate
    ${
      generalInformationFieldsPermissions.idSalesUser.view
        ? `idSalesUser
            idSalesUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idComexExportUser.view
        ? `idComexExportUser
            idComexExportUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idComexImportUser.view
        ? `idComexImportUser
            idComexImportUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idPurchaseUser.view
        ? `idPurchaseUser
            idPurchaseUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idDesignerUser.view
        ? `idDesignerUser
            idDesignerUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idPort.view
        ? `idPort
            idPort2 {
                idPort
                name
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idClientIncoterm.view ||
      satItemsOrderAreaFieldsPermissions.idClientIncoterm.view ||
      financialFieldsCommonWithSatFieldsPermissions.idClientIncoterm.view
        ? `idClientIncoterm
            idClientIncoterm2 {
              idDomain
              description
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.paymentTermAdvance.view ||
      satItemsOrderAreaFieldsPermissions.paymentTermAdvance.view
        ? 'paymentTermAdvance'
        : ''
    }
    ${
      generalInformationFieldsPermissions.paymentTermBalance.view ||
      satItemsOrderAreaFieldsPermissions.paymentTermBalance.view
        ? 'paymentTermBalance'
        : ''
    }
    ${
      generalInformationFieldsPermissions.idPaymentTermCondition.view ||
      satItemsOrderAreaFieldsPermissions.idPaymentTermCondition.view
        ? `idPaymentTermCondition
            idPaymentTermCondition2 {
                idDomain
                description
            }`
        : ''
    }
    idCurrency2 {
      idCurrency
      currency
      abbreviation
    }
    seller
    satSuppliers {
      ${
        generalInformationFieldsPermissions.satSuppliers.view ||
        satItemsOrderAreaFieldsPermissions.satSuppliers.view
          ? 'idSupplier'
          : ''
      }
      idSatSupplier
      mainProduct
      incoterm
      productionTime
      idSupplier2 {
        ${
          satItemsOrderAreaFieldsPermissions.satSuppliersNames.view
            ? 'name'
            : ''
        }
        idCountry2 {
          name
        }
      }
    }
    ${generalInformationFieldsPermissions.manufacture.view ? 'manufacture' : ''}
    ${
      generalInformationFieldsPermissions.idLoadingPort.view
        ? `idLoadingPort
            idLoadingPort2 {
              idPort
              name
              idCountry2 {
                name
              }
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.estimatedInspectionDate.view ||
      satItemsOrderAreaFieldsPermissions.estimatedInspectionDate.view
        ? 'estimatedInspectionDate'
        : ''
    }
    ${
      generalInformationFieldsPermissions.estimatedShipmentDate.view ||
      satItemsOrderAreaFieldsPermissions.estimatedShipmentDate.view
        ? 'estimatedShipmentDate'
        : ''
    }
    ${
      generalInformationFieldsPermissions.estimatedArrivalDate.view ||
      satItemsOrderAreaFieldsPermissions.estimatedArrivalDate.view
        ? 'estimatedArrivalDate'
        : ''
    }
    ${
      generalInformationFieldsPermissions.idCategory.view ||
      satItemsOrderAreaFieldsPermissions.idCategory.view
        ? `idCategory
            idCategory2 {
              name
            }`
        : ''
    }
    qtyTypeCtnr
    ${
      generalInformationFieldsPermissions.typeOfOrder.view ||
      satItemsOrderAreaFieldsPermissions.typeOfOrder.view
        ? `typeOfOrder
          typeOfOrder2 {
            idDomain
            description
          }`
        : ''
    }
    service
    typeOfOrderNumber
    ${
      generalInformationFieldsPermissions.remarks.view ||
      satItemsOrderAreaFieldsPermissions.remarks.view
        ? 'remarks'
        : ''
    }
    ${
      generalInformationFieldsPermissions.packagesNotes.view ||
      satItemsOrderAreaFieldsPermissions.packagesNotes.view
        ? 'packagesNotes'
        : ''
    }
    cbmTotalSum
    ctrSum
    ${generalInformationFieldsPermissions.productLine.view ? 'productLine' : ''}
    designLevel
    satItems {
      idSatItem
      idSat
    }
    idImporter2 {
      idClient
      name
    }
    ${
      generalInformationFieldsPermissions.idCountryAcquisition.view
        ? `idCountryAcquisition
            idCountryAcquisition2 {
              idCountry
              name
            }`
        : ''
    }
    pv
    amountTotal
    advancePaymentPercentage
    estAdvancePaymentAmount
    balancePaymentPercentage
    createdAt
    updatedAt
    createdBy2 {
      firstName
      lastName
    }
    updatedBy2 {
      firstName
      lastName
    }
    ${
      generalInformationFieldsPermissions.idSatOrigin.view ||
      satItemsOrderAreaFieldsPermissions.idSatOrigin.view
        ? `idSatOrigin
           idSatOrigin2 {
            idSat
            satNumber
          }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.indSamples.view ||
      satItemsOrderAreaFieldsPermissions.indSamples.view
        ? 'indSamples'
        : ''
    }
    ${
      generalInformationFieldsPermissions.samplesLeadTime.view ||
      satItemsOrderAreaFieldsPermissions.samplesLeadTime.view
        ? 'samplesLeadTime'
        : ''
    }
    satCtnrs {
      idSatCtnr
      ${
        generalInformationFieldsPermissions.idTypeCtnr.view
          ? `idTypeCtnr
              idTypeCtnr2 {
                idDomain
                description
              }`
          : ''
      }
      ${generalInformationFieldsPermissions.qtyCtnr.view ? 'qtyCtnr' : ''}
    }
    ${
      generalInformationFieldsPermissions.clientDepartment.view
        ? 'clientDepartment'
        : ''
    }
    ${
      generalInformationFieldsPermissions.idPurchaseIncoterm.view ||
      satItemsOrderAreaFieldsPermissions.idPurchaseIncoterm.view ||
      financialFieldsCommonWithSatFieldsPermissions.purchaseIncotermDescription
        .view
        ? `idPurchaseIncoterm
            idPurchaseIncoterm2 {
              idDomain
              description
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.exporterAddress.view ||
      satItemsOrderAreaFieldsPermissions.exporterAddress.view
        ? 'exporterAddress'
        : ''
    }
    ${
      generalInformationFieldsPermissions.productionTime.view ||
      satItemsOrderAreaFieldsPermissions.productionTime.view
        ? 'productionTime'
        : ''
    }
    ${
      generalInformationFieldsPermissions.idCountryProcedence.view
        ? `idCountryProcedence
            idCountryProcedence2 {
              idCountry
              name
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idCountryOrigin.view ||
      financialFieldsCommonWithSatFieldsPermissions.countryOriginName.view
        ? `idCountryOrigin
            idCountryOrigin2 {
              idCountry
              name
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.estimatedArrivalClientDate.view ||
      satItemsOrderAreaFieldsPermissions.estimatedArrivalClientDate.view
        ? 'estimatedArrivalClientDate'
        : ''
    }
    ${
      generalInformationFieldsPermissions.satGroup.view
        ? `idSatGroup2 {
            idSatGroup
            sats {
              idSat
              satNumber
            }
          }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idSalesSecondUser.view
        ? `idSalesSecondUser
            idSalesSecondUser2 {
              idUser
              firstName
              lastName
              username
              email
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.idTypeOperation.view
        ? `idTypeOperation
            idTypeOperation2 {
              idDomain
              description
            }`
        : ''
    }
    ${
      generalInformationFieldsPermissions.mainProduct.view ||
      satItemsOrderAreaFieldsPermissions.mainProduct.view ||
      financialFieldsCommonWithSatFieldsPermissions.mainProduct.view
        ? 'mainProduct'
        : ''
    }
    idFinancialUser
    idFinancialUser2 {
      idUser
      firstName
      lastName
      username
      email
    }
    versionLock
    indConfection
    confirmOrderComercial
    confirmOrderPurchase
    status
    status2 {
      idDomain
      description
    }
    idRegisterUser
    idRegisterUser2 {
      idUser
      firstName
      lastName
      username
      email
    }
    stock
  }
}
`;

export const existsRncBySatIdQuery = gql`
  query ExistsRncBySatId($satId: Int!) {
    existsRncBySatId(satId: $satId)
  }
`;

// Mutation de atualizacao de dados de sat
export const updateSatQuery = gql`
  mutation UpdateSat($data: UpdateSatInput!) {
    updateSat(data: $data) {
      warnings
    }
  }
`;

// Mutation de para duplicar SAT
export const duplicateSatQuery = gql`
  mutation DuplicateSat($idSat: Int!) {
    duplicateSat(idSat: $idSat) {
      idSat
      satNumber
    }
  }
`;

// Mutation de para deletar SAT
export const deleteSatQuery = gql`
  mutation DeleteSats($idsSats: [Int]!) {
    deleteSats(idsSats: $idsSats)
  }
`;

// Mutation de para concluir a SAT
export const finishSatQuery = gql`
  mutation FinishSat($idSat: Int!) {
    finishSat(idSat: $idSat)
  }
`;
