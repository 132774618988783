import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  DataTableEditingRows,
  DataTablePageEvent,
  DataTableRowEditEvent,
} from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { number, object, string } from 'yup';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  deleteRecurringPaymentQuery,
  listAllFinancialTransactionsQuery,
  updateRecurringPaymentQuery,
} from './queries';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  RecurringPaymentData,
  RecurringPaymentsLazyParams,
} from './interfaces';
import Grid from '../../../../components/Grid';
import StatusTag from '../../../../components/StatusTag';
import { renderNumber } from '../../../../utils/formatLocale';
import getValidationErrors from '../../../../utils/getValidationErrors';
import {
  parseCurrencyPtBrNumberColumn,
  parseDateColumm,
} from '../../../../utils/gridColumnsParse';
import { GridActions } from '../../../../components/Grid/styles';
import { gridConstants } from '../../../../components/Grid/constants';

export type RecurringPaymentsListRef = {
  refetch: () => Promise<void>;
};

interface IRecurringPaymentsListProps {
  ref?: Ref<RecurringPaymentsListRef>;
  idFinancialTransaction: number;
  idFinancialTransactionGroup?: number;
}

const RecurringPaymentsList: React.FC<IRecurringPaymentsListProps> = forwardRef(
  ({ idFinancialTransaction, idFinancialTransactionGroup }, ref) => {
    const [updateRecurringPaymentMutation] = useMutation(
      updateRecurringPaymentQuery,
    );
    const [deleteRecurringPaymentMutation] = useMutation(
      deleteRecurringPaymentQuery,
    );

    const { showError, showSuccess } = useRefHook();

    const [lazyParams, setLazyParams] = useState<RecurringPaymentsLazyParams>(
      pagination.initialLazyParams,
    );
    const [editingRows, setEditingRows] = useState<DataTableEditingRows>({});
    const [updatingRows, setUpdatingRows] = useState<DataTableEditingRows>({});
    const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

    const {
      data,
      loading: getRecurringPaymentsLoading,
      refetch,
    } = useQuery(listAllFinancialTransactionsQuery, {
      skip: !idFinancialTransaction || !idFinancialTransactionGroup,
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
          },
          fieldsSearch: {
            idFinancialTransactionGroup: [idFinancialTransactionGroup],
            notIdFinancialTransaction: [idFinancialTransaction],
          },
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Recurring Payments',
          detail: errorData.message,
        });
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        refetch: async () => {
          await refetch();
        },
      }),
      [refetch],
    );

    function onPage(event: DataTablePageEvent) {
      setLazyParams(current => ({
        ...current,
        first: event.first,
        rows: event.rows,
        page: event.page ?? current.page,
      }));
    }

    function renderPaymentSituationDescriptionColumn(
      rowData: RecurringPaymentData,
    ) {
      return (
        <StatusTag
          className="status-tag"
          domain={rowData.idPaymentSituation}
          status={rowData.idPaymentSituation2.description}
        />
      );
    }

    function renderAmountColumn(rowData: RecurringPaymentData) {
      const value = renderNumber(
        rowData.amount,
        'currency',
        rowData.idFinancialAccount2.idCurrency2.abbreviation ?? undefined,
      );

      return (
        <span className="s-grid-break-text" title={value}>
          {value}
        </span>
      );
    }

    async function handleRemoveRecurringPayment(itemId: number) {
      try {
        setItemBeingDeleted(itemId);

        await deleteRecurringPaymentMutation({
          variables: {
            idFinancialTransaction: itemId,
          },
        });

        showSuccess({
          summary: 'Recurring Payment deleted',
        });

        await refetch();
      } catch (error) {
        showError({
          summary: 'Error while deleting Recurring Payment',
          detail: error.message,
        });
      } finally {
        setItemBeingDeleted(undefined);
      }
    }

    async function validateRow(rowData: RecurringPaymentData) {
      try {
        const schema = object().shape({
          amount: number()
            .not([0], 'Amount must be different than 0')
            .nullable()
            .required('Amount is required'),
          paymentForecast: string().nullable().required('Forecast is required'),
        });

        await schema.validate(rowData, { abortEarly: false });

        return true;
      } catch (error) {
        const errors = getValidationErrors(error);

        showError({
          summary: 'Some fields are invalid',
          detail: Object.values(errors).join(', '),
        });

        return false;
      }
    }

    async function updateRow(rowData: RecurringPaymentData) {
      try {
        setUpdatingRows(curr => ({
          ...curr,
          [rowData.idFinancialTransaction]: true,
        }));

        await updateRecurringPaymentMutation({
          variables: {
            data: {
              idFinancialTransaction: rowData.idFinancialTransaction,
              amount: rowData.amount,
              paymentForecast: rowData.paymentForecast,
              competencyRegime: rowData.competencyRegime,
              cashflowRegime: rowData.cashflowRegime,
              versionLock: rowData.versionLock,
            },
          },
        });

        showSuccess({
          summary: 'Recurring Payment updated',
        });

        await refetch();

        setEditingRows(curr => {
          const newEditingRows = { ...curr };
          delete newEditingRows[rowData.idFinancialTransaction];
          return newEditingRows;
        });
      } catch (error) {
        showError({
          summary: 'Error while updating Recurring Payment',
          detail: error.message,
        });
      } finally {
        setUpdatingRows(curr => {
          const newUpdatingRows = { ...curr };
          delete newUpdatingRows[rowData.idFinancialTransaction];
          return newUpdatingRows;
        });
      }
    }

    async function saveRow(rowData: RecurringPaymentData) {
      const rowIsValid = await validateRow(rowData);

      if (rowIsValid) await updateRow(rowData);
    }

    async function saveAndEditNextRow(options: ColumnEditorOptions) {
      const rowIsValid = await validateRow(options.rowData);

      if (rowIsValid) {
        updateRow(options.rowData);

        const nextRow =
          data?.listAllFinancialTransactions?.data[options.rowIndex + 1];

        if (nextRow) {
          setEditingRows(curr => ({
            ...curr,
            [nextRow.idFinancialTransaction]: true,
          }));
        }
      }
    }

    function removeRowFromEditingRows(rowId: number) {
      setEditingRows(curr => {
        const newEditingRows = { ...curr };
        delete newEditingRows[rowId];
        return newEditingRows;
      });
    }

    function onRowEditChange(e: DataTableRowEditEvent) {
      // Tipagem de e.data vem incorreta. Corrigir quando possivel
      // (em atualizacao do primereact)
      setEditingRows(e.data as DataTableEditingRows);
    }

    function handleEditorKeyDown(
      e: React.KeyboardEvent<HTMLInputElement>,
      options: ColumnEditorOptions,
    ) {
      switch (e.key) {
        case 'Enter':
          saveAndEditNextRow(options);
          break;
        case 'Escape':
          removeRowFromEditingRows(options.rowData.idFinancialTransaction);
          break;
        default:
          break;
      }
    }

    function shouldDisableEditor(itemId: number) {
      return !!updatingRows[itemId] || itemBeingDeleted === itemId;
    }

    function renderNumberEditor(options: ColumnEditorOptions) {
      return (
        <InputNumber
          value={options.value}
          disabled={shouldDisableEditor(options.rowData.idFinancialTransaction)}
          onChange={(e: InputNumberChangeEvent) => {
            if (options.editorCallback) options.editorCallback(e.value);
          }}
          locale="pt-BR"
          mode="currency"
          currency={
            options.rowData.idFinancialAccount2.idCurrency2.abbreviation
          }
          onKeyDown={e => handleEditorKeyDown(e, options)}
          autoFocus
        />
      );
    }

    function renderDateEditor(options: ColumnEditorOptions) {
      return (
        <InputText
          value={options.value}
          disabled={shouldDisableEditor(options.rowData.idFinancialTransaction)}
          onChange={e => {
            if (options.editorCallback) options.editorCallback(e.target.value);
          }}
          type="date"
          onKeyDown={e => handleEditorKeyDown(e, options)}
        />
      );
    }

    function renderEditActionsColumn(rowData: any) {
      return (
        !updatingRows[rowData.idFinancialTransaction] &&
        rowData.idFinancialTransaction !== itemBeingDeleted
      );
    }

    function gridRowClassName(rowData: any) {
      return {
        'bg-yellow-50 opacity-80 cursor-wait':
          !!updatingRows[rowData.idFinancialTransaction],
      };
    }

    function renderDeleteButton(rowData: RecurringPaymentData) {
      const isBeingDeleted =
        itemBeingDeleted === rowData.idFinancialTransaction;

      return (
        <GridActions>
          <button
            type="button"
            className="trash-button"
            onClick={() =>
              confirmDialog({
                message: 'Are you sure you want to delete this item?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () =>
                  handleRemoveRecurringPayment(rowData.idFinancialTransaction),
              })
            }
            disabled={
              !!itemBeingDeleted ||
              !!updatingRows[rowData.idFinancialTransaction]
            }
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <i className="pi pi-trash" style={{ fontSize: '1.25rem' }} />
            )}
          </button>
        </GridActions>
      );
    }

    return (
      <div className="max-w-min">
        <Grid
          dataKey="idFinancialTransaction"
          lazy
          totalRecords={data?.listAllFinancialTransactions?.items ?? 0}
          value={data?.listAllFinancialTransactions?.data ?? []}
          emptyMessage="No items found."
          rows={lazyParams.rows}
          first={lazyParams.first}
          onPage={onPage}
          loading={getRecurringPaymentsLoading}
          editMode="row"
          editingRows={editingRows}
          onRowEditChange={onRowEditChange}
          onRowEditSave={e => saveRow(e.newData as RecurringPaymentData)}
          rowEditValidator={() => false}
          rowClassName={gridRowClassName}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
        >
          <Column
            key="editButtons"
            style={{ width: '6.5rem' }}
            rowEditor={renderEditActionsColumn}
            bodyClassName="pr-0"
            pt={{
              rowEditorSaveButton: {
                // Impede que o botao receba foco ao abrir a edicao
                'data-p-row-editor-save': 'false',
              },
            }}
          />

          <Column
            key="deleteButton"
            style={{ width: '4rem' }}
            body={renderDeleteButton}
            bodyClassName="pl-0"
          />

          <Column
            key="idFinancialTransaction"
            field="idFinancialTransaction"
            header="Bill Number"
            style={{ width: '8rem' }}
          />
          <Column
            key="idPaymentSituation2.description"
            field="idPaymentSituation2.description"
            header="Payment Situation"
            body={renderPaymentSituationDescriptionColumn}
            style={{ width: '12rem' }}
          />
          <Column
            key="amount"
            field="amount"
            header="Amount"
            body={renderAmountColumn}
            editor={renderNumberEditor}
            style={{ width: '10rem' }}
          />
          <Column
            key="billRate"
            field="billRate"
            header="Bill Rate"
            body={(rowData, options) =>
              parseCurrencyPtBrNumberColumn(
                rowData,
                options,
                2,
                rowData.idFinancialAccount2.idCurrency2.abbreviation,
              )
            }
            style={{ width: '7rem' }}
          />
          <Column
            key="currency"
            field="idFinancialAccount2.idCurrency2.abbreviation"
            header="Currency"
            style={{ width: '7rem' }}
          />
          <Column
            key="paymentForecast"
            field="paymentForecast"
            header="Forecast"
            body={parseDateColumm}
            editor={renderDateEditor}
            style={{ width: '11rem' }}
          />
          <Column
            key="competencyRegime"
            field="competencyRegime"
            header="Competency"
            body={parseDateColumm}
            editor={renderDateEditor}
            style={{ width: '11rem' }}
          />
          <Column
            key="cashflowRegime"
            field="cashflowRegime"
            header="Cashflow"
            body={parseDateColumm}
            editor={renderDateEditor}
            style={{ width: '11rem' }}
          />
        </Grid>
      </div>
    );
  },
);

export default RecurringPaymentsList;
