import { TComment } from '../CommentAndReplies/interfaces';

// eslint-disable-next-line no-shadow
export enum ReplyToCommentDialogActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export type ReplyToCommentDialogReducer = {
  commentToReply?: TComment;
  dialogVisible: boolean;
  replyInitialValue?: string;
};

export type ReplyToCommentDialogReducerAction = {
  type: ReplyToCommentDialogActionKind;
  payload?: {
    commentToReply: TComment;
    replyInitialValue: string;
  };
};
