/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu';
import { financialTransactionMenuItems } from '../FinancialTransaction/constants';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import { FinancialTransactionRouteParams } from '../FinancialTransaction/interfaces';
import Updates from '../FinancialTransaction/Updates';
import FinancialTransactionAttachmentsList from '../FinancialTransactionAttachmentsList';

const FinancialTransactionBeneficiaryAcessPage: React.FC = () => {
  const location = useLocation();

  const idFinancialTransaction = parseInt(
    useParams<FinancialTransactionRouteParams>().idFinancialTransaction,
    10,
  );

  // Estado de loading
  const [loading] = useState(false);

  const defaultPageTitle = 'Manage Bill To Pay / To Receive';

  const urlTabParam = new URLSearchParams(location.search).get('tab');

  const menuItems: IPageMenuItem[] = [
    {
      key: financialTransactionMenuItems.attachments.key,
      label: financialTransactionMenuItems.attachments.label,
    },
    {
      key: financialTransactionMenuItems.updates.key,
      label: financialTransactionMenuItems.updates.label,
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);

  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Home',
      path: '/home',
    },
    {
      name: defaultPageTitle,
      path: location.pathname,
    },
  ];

  useEffect(() => {
    if (urlTabParam) {
      setSelectedMenuItem(urlTabParam);
    }
  }, [urlTabParam]);

  return (
    <div>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160} />
      <div className="flex flex-column pb-2">
        <PageMenu
          menuFlexDirection="line"
          menuWidth="100%"
          setSelectedMenuItem={setSelectedMenuItem}
          selectedMenuItem={selectedMenuItem}
          items={menuItems}
          menuMarginBottom="30px"
          stickyMenuTopDistance="1260px"
        />

        <FinancialTransactionAttachmentsList
          idFinancialTransaction={idFinancialTransaction}
          selected={
            selectedMenuItem === financialTransactionMenuItems.attachments.key
          }
        />

        <Updates
          idFinancialTransaction={idFinancialTransaction}
          selected={
            selectedMenuItem === financialTransactionMenuItems.updates.key
          }
        />
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default FinancialTransactionBeneficiaryAcessPage;
