import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { Menu as PrimeMenu } from 'primereact/menu';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineCheckCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import { toolbarOptions } from '../../../shared/options/editorToolbarOptions';
import { clientQuotationRoles } from '../../../shared/roles/clientQuotation';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import Attachments from './Attachments';
import {
  ClientQuotationAprovarAction,
  ClientQuotationElaborarUserAction,
  ClientQuotationPurchaseAction,
  ClientQuotationStatus,
  IClientQuotation,
  IClientQuotationRouteParams,
} from './interfaces';

import StatusTag from '../../../components/StatusTag';
import { Role } from '../../../shared/enums/role';
import userHasPermission from '../../../utils/userHasPermission';
import userHasRole from '../../../utils/userHasRole';
import Financial, { IClientQuotationFinancialRef } from './Financial';
import GeneralInformation, {
  IClientQuotationGeneralInformationRef,
} from './GeneralInformation';
import Items, { IClientQuotationItemsRef } from './Items';
import { Container, CrudForm } from './styles';
import Suppliers, { IClientQuotationSuppliersRef } from './Suppliers';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../components/MutationResponseSummary';
import UpdatesContent from './UpdatesContent';
import useTitle from '../../../hooks/useTitle';
import {
  clientQuotationAprovarActionQuery,
  clientQuotationElaborarActionQuery,
  clientQuotationPoolPurchaseActionQuery,
  clientQuotationPurchaseActionQuery,
  createSatQuery,
  duplicateClientQuotationQuery,
  listClientQuotationQuery,
} from './queries';
import { clientQuotationLateralMenuItems } from './constants';

const ClientQuotation: React.FC = () => {
  // ID da Client Quotation
  const idCq = parseInt(useParams<IClientQuotationRouteParams>().idCq, 10);

  const defaultPageTitle = `${idCq ? 'Manage' : 'Create'} CQ`;

  // Estado de Client Quotation
  const [clientQuotation, setClientQuotation] = useState<IClientQuotation>(
    {} as IClientQuotation,
  );

  useTitle(
    clientQuotation?.cqNumber
      ? `CQ${clientQuotation.cqNumber} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // URL params
  const location = useLocation();

  // Reference do componente de Financial
  const financialRef = useRef<IClientQuotationFinancialRef>(null);

  // Reference do componente de Suppliers
  const suppliersRef = useRef<IClientQuotationSuppliersRef>(null);

  // Reference do componente de General information
  const generalInformationRef =
    useRef<IClientQuotationGeneralInformationRef>(null);

  // Reference do componente de Items
  const itemsRef = useRef<IClientQuotationItemsRef>(null);

  // Referencia ao toast e formulario
  const { showError, showSuccess } = useRefHook();

  // Referencia do menu de export
  const exportMenuRef = useRef<PrimeMenu>(null);

  const mutationResponseSummaryRef = useRef<IMutationResponseSummaryRef>(null);

  // Redirect
  const history = useHistory();

  const [
    updateClientQuotationMutationResponse,
    setUpdateClientQuotationMutationResponse,
  ] = useState<IMutationResponse[]>([]);

  // Estado que define se dialog de comentario do usuario e exibida
  const [displayEnterACommentDialog, setDisplayEnterACommentDialog] =
    useState(false);

  // Estado de comentario do usuario
  const [userComment, setUserComment] = useState<string>();

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // Estado de acao do usuario
  const [userAction, setUserAction] = useState('');

  // Dados para retornar para listagem de Client Quotations
  const initialPage = parseInt(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const changePropertyValue = (property: string, value: any) => {
    setClientQuotation(prevClientQuotation => ({
      ...prevClientQuotation,
      [property]: value,
    }));
  };

  // Roles do usuario
  const { roles, idUser } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    clientQuotationRoles.idEntity,
  );

  // Permissions de Client Quotation
  const {
    idPermissionCreateClientQuotation,
    idPermissionDuplicateClientQuotation,
    idPermissionExportSellerPi,
    idPermissionExportPurchasePi,
    idPermissionUpdateClientQuotation,
    idPermissionApproveClientQuotation,
    idPermissionCreateSatFromCQ,
  } = clientQuotationRoles.permissions;

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'List of CQs',
      path:
        initialPage && initialFirst
          ? `/client-quotations/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/client-quotations/list',
    },
    {
      name: defaultPageTitle,
      path: location.pathname,
    },
  ];

  // Itens do menu lateral
  const menuItems = useMemo(() => {
    const itemsList: IPageMenuItem[] = [];
    itemsList.push({
      key: clientQuotationLateralMenuItems.generalInformation.key,
      label: clientQuotationLateralMenuItems.generalInformation.label,
    });

    // Se Client Quotation ja estiver criada, exibe demais itens do menu
    if (clientQuotation.idCq) {
      itemsList.push(
        {
          key: clientQuotationLateralMenuItems.suppliers.key,
          label: clientQuotationLateralMenuItems.suppliers.label,
        },
        {
          key: clientQuotationLateralMenuItems.financial.key,
          label: clientQuotationLateralMenuItems.financial.label,
        },
        {
          key: clientQuotationLateralMenuItems.updates.key,
          label: clientQuotationLateralMenuItems.updates.label,
        },
        {
          key: clientQuotationLateralMenuItems.cqItems.key,
          label: clientQuotationLateralMenuItems.cqItems.label,
        },
        {
          key: clientQuotationLateralMenuItems.attachments.key,
          label: clientQuotationLateralMenuItems.attachments.label,
        },
      );
    }

    return itemsList;
  }, [clientQuotation.idCq]);

  // cria método para chamar a mutation
  const [clientQuotationElaborarActionMutation] = useMutation(
    clientQuotationElaborarActionQuery,
  );
  const [clientQuotationPoolPurchaseActionMutation] = useMutation(
    clientQuotationPoolPurchaseActionQuery,
  );
  const [clientQuotationPurchaseActionMutation] = useMutation(
    clientQuotationPurchaseActionQuery,
  );
  const [clientQuotationAprovarActionMutation] = useMutation(
    clientQuotationAprovarActionQuery,
  );
  const [duplicateClientQuotationMutation] = useMutation(
    duplicateClientQuotationQuery,
  );

  const [createSatMutation] = useMutation(createSatQuery);

  /**
   * Busca dados da Client Quotation
   */
  const [
    loadClientQuotationData,
    { loading: clientQuotationLoading, refetch: clientQuotationRefetch },
  ] = useLazyQuery(listClientQuotationQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    onCompleted: response => {
      if (response.listClientQuotationById) {
        setClientQuotation(response.listClientQuotationById);
      } else {
        showError({
          summary: 'CQ not found',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting CQ data',
        detail: errorData.message,
      });
    },
  });

  const cleanClientQuotation = useCallback(() => {
    setClientQuotation({} as IClientQuotation);
  }, [setClientQuotation]);

  const urlTabParam = new URLSearchParams(location.search).get('tab');

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        history.push(
          initialPage && initialFirst
            ? `/client-quotations/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
            : '/client-quotations/list',
        ),
    });
  }
  /**
   * Oculta dialog de comentario
   */
  const onCommentDialogHide = () => {
    setDisplayEnterACommentDialog(false);
    setUserComment(undefined);
    setUserAction('');
  };

  /**
   * Valida se botao para assumir Client Quotation deve estar desabilitado
   */
  const shouldButtonAssumeClientQuotationBeDisabled = () => {
    // Se houver usuario atribuido para Client Quotation, retorna true
    if (clientQuotation.idResponsibleUser) return true;

    // Efetua validacao pelo status
    switch (clientQuotation.idStatus) {
      case ClientQuotationStatus.WAITING_PURCHASE:
        // Se usuario nao possuir role de Purchase
        if (!userHasRole(Role.PURCHASE, roles.rolesUser)) return true;
        break;
      default:
        break;
    }

    return false;
  };

  const validateForms = useCallback(async () => {
    await generalInformationRef.current?.validateGeneralInformationForm();
    // await financialRef.current?.handleValidateFinancialForm();
  }, []);

  const submitForms = useCallback(async () => {
    const mutationsResponses: IMutationResponse[] = [];

    const response =
      await generalInformationRef.current?.handleSaveGeneralInformation();

    const updateItemsResponse = await itemsRef.current?.handleUpdateItems();

    if (updateItemsResponse?.warnings?.length || updateItemsResponse?.errors) {
      mutationsResponses.push({
        moduleChanged: 'Client Quotation Items',
        warnings: updateItemsResponse?.warnings,
        errors: updateItemsResponse?.errors,
      });
    }

    await financialRef.current?.handleUpdateSuppliers();

    if (mutationsResponses.length) {
      setUpdateClientQuotationMutationResponse(mutationsResponses);
      mutationResponseSummaryRef.current?.toggleDisplaySummary();
    }

    return response;
  }, []);

  const handleSaveClientQuotation = useCallback(async () => {
    setLoading(true);
    try {
      await validateForms();

      const creationResponse = await submitForms();

      if (!idCq) {
        history.push(
          `/client-quotations/list/${creationResponse?.data.createClientQuotation.idCq}`,
        );
      }
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  }, [history, idCq, submitForms, validateForms]);

  /**
   * Executa acao de Client Quotation Elaborar
   */
  async function handleElaborarClientQuotationAction(
    action: ClientQuotationElaborarUserAction,
  ) {
    try {
      // Define loading da pagina como true
      setLoading(true);

      // Atualiza dados da Client Quotation
      await validateForms();
      await submitForms();

      // Chama mutation para enviar Client Quotation para procurement
      await clientQuotationElaborarActionMutation({
        variables: {
          data: {
            idCq,
            userAction: action,
            message: userComment,
          },
        },
      });

      // Em caso de sucesso exibe toast
      showSuccess({
        summary: `Client Quotation ${
          action === ClientQuotationElaborarUserAction.ENVIAR_PURCHASE
            ? 'sent'
            : 'canceled'
        }`,
      });

      // Oculta dialog se estiver visivel
      if (displayEnterACommentDialog) onCommentDialogHide();

      // Recarrega dados da Client Quotation
      setClientQuotation({} as IClientQuotation);
      clientQuotationRefetch();
    } catch (error) {
      if (error instanceof Yup.ValidationError) return;
      // Exibe toast sobre o erro
      showError({
        summary: `Error while ${
          action === ClientQuotationElaborarUserAction.ENVIAR_PURCHASE
            ? 'sending'
            : 'canceling'
        } Client Quotation`,
        detail: error.message,
      });
    } finally {
      // Define loading da pagina como false
      setLoading(false);
    }
  }

  /**
   * Executa acao de Client Quotation Pool Purchase
   */
  async function handleClientQuotationPoolPurchaseAction() {
    setLoading(true);

    try {
      // Executa mutation
      await clientQuotationPoolPurchaseActionMutation({
        variables: {
          idCq,
        },
      });

      // Em caso de sucesso exibe toast
      showSuccess({
        summary: 'Client Quotation assumed',
      });

      // Recarrega dados da Client Quotation
      setClientQuotation({} as IClientQuotation);
      clientQuotationRefetch();
    } catch (error) {
      // Exibe toast sobre o erro
      showError({
        summary: 'Error while canceling Client Quotation',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Executa acao de Client Quotation Purchase
   * @param action Acao do usuario
   */
  async function handleClientQuotationPurchaseAction(
    action: ClientQuotationPurchaseAction,
  ) {
    setLoading(true);

    try {
      // Chama mutation de Client Quotation Purchase
      await clientQuotationPurchaseActionMutation({
        variables: {
          data: {
            idCq,
            userAction: action,
            message: userComment,
          },
        },
      });

      // Em caso de sucesso exibe toast
      showSuccess({
        summary: `Client Quotation sent ${
          action === ClientQuotationPurchaseAction.ENVIAR_COMMERCIAL
            ? 'to commercial'
            : 'back to purchase pool'
        }`,
      });

      // Oculta dialog se estiver visivel
      if (displayEnterACommentDialog) onCommentDialogHide();

      // Recarrega dados da Client Quotation
      setClientQuotation({} as IClientQuotation);
      clientQuotationRefetch();
    } catch (error) {
      // Exibe toast sobre o erro
      showError({
        summary: `Error while sending Client Quotation ${
          action === ClientQuotationPurchaseAction.ENVIAR_COMMERCIAL
            ? 'to commercial'
            : 'back to purchase pool'
        }`,
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Acao de aprovar Client Quotation
   */
  async function handleClientQuotationAprovarAction(
    action: ClientQuotationAprovarAction,
  ) {
    setLoading(true);

    // Strings dos toasts
    let errorMessage;
    let successMessage;

    // Muda mensagem de acordo com acao do usuario
    switch (action) {
      case ClientQuotationAprovarAction.APROVAR_CQ:
        successMessage = 'approved';
        errorMessage = 'approving CQ';
        break;
      case ClientQuotationAprovarAction.CANCELAR_CQ:
        successMessage = 'canceled';
        errorMessage = 'canceling CQ';
        break;
      case ClientQuotationAprovarAction.ENVIAR_PURCHASE:
        successMessage = 'sent back to purchase';
        errorMessage = 'sending CQ back to purchase';
        break;
      default:
        break;
    }

    try {
      await clientQuotationAprovarActionMutation({
        variables: {
          data: {
            idCq,
            userAction: action,
            message: userComment,
          },
        },
      });

      // Em caso de sucesso exibe toast
      showSuccess({
        summary: `Client Quotation ${successMessage}`,
      });

      // Oculta dialog se estiver visivel
      if (displayEnterACommentDialog) onCommentDialogHide();

      // Recarrega dados da Client Quotation
      setClientQuotation({} as IClientQuotation);
      clientQuotationRefetch();
    } catch (error) {
      // Exibe toast sobre o erro
      showError({
        summary: `Error while ${errorMessage}`,
        detail: error.message,
      });
    } finally {
      // Define loading da pagina como false
      setLoading(false);
    }
  }

  async function handleCreateSatFromCq(idClientQuotation: number) {
    setLoading(true);
    try {
      const response = await createSatMutation({
        variables: {
          idCq: idClientQuotation,
        },
      });

      // Exibe toast de sucesso
      showSuccess({
        summary: 'SAT Created',
        detail: (
          <>
            <p>New SAT: </p>
            {response.data.createSat.idSat && (
              <Link
                to={`/commercial/sats/${response.data.createSat.idSat}`}
                className="transparent-link"
              >
                {response.data.createSat.satNumber}
              </Link>
            )}
          </>
        ),
      });
    } catch (error) {
      // Exibe toast de erro
      showError({
        summary: 'Error while creating SAT',
        detail: error.message,
      });
    } finally {
      if (clientQuotation.idStatus !== ClientQuotationStatus.APPROVED) {
        setLoading(false);
        setUserAction(ClientQuotationAprovarAction.APROVAR_CQ);
        setDisplayEnterACommentDialog(true);
      } else {
        setLoading(false);
      }
    }
  }

  /**
   * Acao de aprovar Client Quotation e Criar SAT
   */
  const handleClientQuotationAprovarAndCreateSAT = async (
    action: ClientQuotationAprovarAction,
    idClientQuotation: number,
  ) => {
    setLoading(true);
    try {
      const result = await clientQuotationAprovarActionMutation({
        variables: {
          data: {
            idCq,
            userAction: action,
            message: userComment,
          },
        },
      });

      if (result) {
        // Em caso de sucesso exibe toast
        showSuccess({
          summary: 'Client Quotation approved',
        });
        await handleCreateSatFromCq(idClientQuotation);
      } else {
        showError({
          summary: `Error while creating SAT`,
        });
      }

      // Oculta dialog se estiver visivel
      if (displayEnterACommentDialog) onCommentDialogHide();

      // Recarrega dados da Client Quotation
      setClientQuotation({} as IClientQuotation);
      clientQuotationRefetch();
    } catch (error) {
      // Exibe toast sobre o erro
      showError({
        summary: 'Error while approving CQ',
        detail: error.message,
      });
    } finally {
      // Define loading da pagina como false
      setLoading(false);
    }
  };

  /**
   * Realiza submit de acordo com escolha do usuario
   */
  function handleSubmitComment() {
    // Chama funcao de acordo com Acao do usuario
    switch (userAction) {
      case ClientQuotationElaborarUserAction.CANCELAR_CQ:
        handleElaborarClientQuotationAction(
          ClientQuotationElaborarUserAction.CANCELAR_CQ,
        );
        break;
      case ClientQuotationPurchaseAction.DEVOLVER_POOL_PURCHASE:
        handleClientQuotationPurchaseAction(
          ClientQuotationPurchaseAction.DEVOLVER_POOL_PURCHASE,
        );
        break;
      case ClientQuotationAprovarAction.ENVIAR_PURCHASE:
      case ClientQuotationAprovarAction.CANCELAR_CQ:
      case ClientQuotationAprovarAction.APROVAR_CQ:
        handleClientQuotationAprovarAction(userAction);
        break;
      case ClientQuotationAprovarAction.APROVAR_CQ_CRIAR_SAT:
        handleClientQuotationAprovarAndCreateSAT(
          ClientQuotationAprovarAction.APROVAR_CQ,
          idCq,
        );
        break;
      default:
        break;
    }
  }

  /**
   * Busca nome do pool em que a CQ esta para exibir na tag com base no status da CQ
   */
  function getPoolNameByStatusId(idStatus: number) {
    switch (idStatus) {
      case ClientQuotationStatus.DRAFT:
        return clientQuotation.createdBy2?.roleUsers
          ? clientQuotation.createdBy2?.roleUsers[0]?.idRole2.name
          : '';
      case ClientQuotationStatus.WAITING_PURCHASE:
      case ClientQuotationStatus.AT_PURCHASE:
        return 'Purchase';
      case ClientQuotationStatus.AT_COMMERCIAL:
        return 'Commercial';
      default:
        return undefined;
    }
  }

  /**
   * Duplica Client Quotation
   */
  async function handleDuplicateClientQuotation() {
    setLoading(true);
    try {
      const response = await duplicateClientQuotationMutation({
        variables: {
          idCq,
        },
      });

      showSuccess({
        summary: 'Duplicated successfully',
        detail: (
          <>
            <p>New CQ: </p>
            <Link
              to={`/client-quotations/list/${response.data.duplicateClientQuotation.idCq}`}
              className="transparent-link"
            >
              {response.data.duplicateClientQuotation.cqNumber}
            </Link>
          </>
        ),
      });
    } catch (error) {
      showError({
        summary: 'Error while duplicating CQ',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleApproveCQ = () => {
    setLoading(false);
    setUserAction(ClientQuotationAprovarAction.APROVAR_CQ);
    setDisplayEnterACommentDialog(true);
  };

  const handleApproveCQAndCreateSAT = () => {
    confirmDialog({
      message: `Do you want to approve CQ and create a SAT?`,
      header: 'Approve CQ and Create SAT',
      icon: 'pi pi-info-circle',
      accept: () => {
        setDisplayEnterACommentDialog(true);
      },
    });

    setLoading(false);
    setUserAction(ClientQuotationAprovarAction.APROVAR_CQ_CRIAR_SAT);
  };

  /**
   * Footer da modal
   * @returns Footer
   */
  const dialogFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            handleSubmitComment();
          }}
          disabled={
            userAction !== ClientQuotationAprovarAction.APROVAR_CQ &&
            !userComment?.trim()
          }
        />
        <Button
          label="Cancel"
          severity="danger"
          icon="pi pi-times"
          onClick={() => onCommentDialogHide()}
        />
      </div>
    );
  };

  // Query para buscar arquivo template
  const generateSellerPIQuery = gql`
    query generateSellerPIQuery($idCq: Int!) {
      generateCqSellerPI(idCq: $idCq)
    }
  `;

  // Gera Seller PI
  const [getSellerPILink] = useLazyQuery(generateSellerPIQuery, {
    onCompleted: response => {
      if (response.errors) {
        showError({
          summary: 'Error while generating Seller PI',
          detail: response.errors[0].message,
        });
      } else {
        window.open(response.generateCqSellerPI, '_blank');
      }
      setLoading(false);
    },

    onError: error => {
      showError({
        summary: 'Error while generating Seller PI',
        detail: error.message,
      });
      setLoading(false);
    },
  });

  // Query para buscar arquivo template
  const generatePurchasePIQuery = gql`
    query generatePurchasePIQuery($idCq: Int!) {
      generateCqPurchasePI(idCq: $idCq)
    }
  `;

  // Gera Purchase PI
  const [getPurchasePILink] = useLazyQuery(generatePurchasePIQuery, {
    onCompleted: response => {
      if (response.errors) {
        showError({
          summary: 'Error while generating Purchase PI',
          detail: response.errors[0].message,
        });
      } else {
        window.open(response.generateCqPurchasePI, '_blank');
      }
      setLoading(false);
    },

    onError: error => {
      showError({
        summary: 'Error while generating Purchase PI',
        detail: error.message,
      });
      setLoading(false);
    },
  });

  // Itens do botao de export
  const exportButtonItems = useMemo(() => {
    const items = [];

    if (
      userHasPermission(
        idPermissionExportSellerPi,
        userPermissions.userPermissions,
      )
    ) {
      items.push({
        label: 'SUN PI',
        command: () => {
          getSellerPILink({ variables: { idCq } });
          setLoading(true);
        },
      });
    }

    if (
      userHasPermission(
        idPermissionExportPurchasePi,
        userPermissions.userPermissions,
      )
    ) {
      items.push({
        label: 'COT PI',
        command: () => {
          getPurchasePILink({ variables: { idCq } });
          setLoading(true);
        },
      });
    }

    return items;
  }, [
    getPurchasePILink,
    getSellerPILink,
    idCq,
    idPermissionExportPurchasePi,
    idPermissionExportSellerPi,
    userPermissions.userPermissions,
  ]);

  /**
   * Seleciona aba de acordo com o parametro da URL
   */
  useEffect(() => {
    if (urlTabParam) {
      setSelectedMenuItem(urlTabParam);
    }
  }, [urlTabParam]);

  /**
   * Valida se pagina deve carregar dados de uma Client Quotation
   */
  useEffect(() => {
    // Valida se ID de Client Quotation foi informado na URL
    if (idCq) {
      // Carrega dados da Client Quotation
      loadClientQuotationData({
        variables: {
          idCq,
        },
      });
    }

    return () => setClientQuotation({} as IClientQuotation);
  }, [idCq, loadClientQuotationData]);

  return (
    <Container>
      <PrimeMenu
        model={exportButtonItems}
        popup
        ref={exportMenuRef}
        id="popup_menu"
      />
      <Dialog
        header="Enter a comment for this action"
        visible={displayEnterACommentDialog}
        onHide={() => onCommentDialogHide()}
        closable={false}
        footer={dialogFooter()}
      >
        <Editor
          headerTemplate={<></>}
          style={{ height: '320px' }}
          value={userComment}
          onTextChange={e => setUserComment(e.htmlValue || '')}
          modules={{ toolbar: toolbarOptions }}
        />
      </Dialog>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          clientQuotation?.cqNumber
            ? `CQ${clientQuotation.cqNumber}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {clientQuotation.idStatus && (
          <StatusTag
            domain={clientQuotation.idStatus}
            name={getPoolNameByStatusId(clientQuotation.idStatus)}
            status={clientQuotation.idStatus2.description}
            className="statusTag"
          />
        )}

        <p className={idCq ? 'createdAt' : ''}>
          {clientQuotation.idCq && !clientQuotationLoading
            ? `${new Date(clientQuotation.createdAt).toLocaleString()} by ${
                clientQuotation.createdBy2?.firstName
              } ${clientQuotation.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idCq ? 'updatedAt' : ''}>
          {clientQuotation.idCq && !clientQuotationLoading
            ? `${new Date(clientQuotation.updatedAt).toLocaleString()} by ${
                clientQuotation.updatedBy2?.firstName
              } ${clientQuotation.updatedBy2?.lastName}`
            : ''}
        </p>

        {clientQuotation.idResponsibleUser === idUser &&
          clientQuotation.idStatus === ClientQuotationStatus.DRAFT && (
            <MainButton
              className="mainButton"
              onClick={() => {
                handleElaborarClientQuotationAction(
                  ClientQuotationElaborarUserAction.ENVIAR_PURCHASE,
                );
              }}
            >
              <p>Send</p>
            </MainButton>
          )}

        {clientQuotation.idResponsibleUser === idUser &&
          clientQuotation.idStatus === ClientQuotationStatus.DRAFT && (
            <Button
              className="p-button-danger"
              onClick={() => {
                setUserAction(ClientQuotationElaborarUserAction.CANCELAR_CQ);
                setDisplayEnterACommentDialog(true);
              }}
            >
              <p>Cancel CQ</p>
            </Button>
          )}

        {clientQuotation.idStatus ===
          ClientQuotationStatus.WAITING_PURCHASE && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handleClientQuotationPoolPurchaseAction();
            }}
            disabled={shouldButtonAssumeClientQuotationBeDisabled()}
          >
            <p>Assume</p>
          </MainButton>
        )}

        {clientQuotation.idResponsibleUser === idUser &&
          clientQuotation.idStatus === ClientQuotationStatus.AT_PURCHASE && (
            <MainButton
              className="mainButton"
              onClick={() => {
                handleClientQuotationPurchaseAction(
                  ClientQuotationPurchaseAction.ENVIAR_COMMERCIAL,
                );
              }}
            >
              <p>Send To Commercial</p>
            </MainButton>
          )}

        {clientQuotation.idStatus === ClientQuotationStatus.AT_PURCHASE && (
          <Button
            className="p-button-danger"
            onClick={() => {
              setUserAction(
                ClientQuotationPurchaseAction.DEVOLVER_POOL_PURCHASE,
              );
              setDisplayEnterACommentDialog(true);
            }}
            disabled={
              clientQuotation.idResponsibleUser !== idUser &&
              !userHasRole(Role.ADMINISTRATOR, roles.rolesUser) &&
              !userHasRole(Role.PURCHASE_KEY_USER, roles.rolesUser) &&
              !userHasRole(Role.MANAGER, roles.rolesUser)
            }
          >
            <p>Send back to Pool</p>
          </Button>
        )}

        {clientQuotation.idStatus === ClientQuotationStatus.AT_COMMERCIAL &&
          userHasPermission(
            idPermissionApproveClientQuotation,
            userPermissions.userPermissions,
          ) && (
            <div className="secondaryButton">
              <Button
                className="secondaryButton"
                action="approve"
                onClick={() => {
                  handleApproveCQ();
                }}
              >
                <AiOutlineCheckCircle size={22} />
                <p>Approve</p>
              </Button>
            </div>
          )}

        {clientQuotation.idStatus === ClientQuotationStatus.AT_COMMERCIAL &&
          userHasPermission(
            idPermissionApproveClientQuotation,
            userPermissions.userPermissions,
          ) &&
          userHasPermission(
            idPermissionCreateSatFromCQ,
            userPermissions.userPermissions,
          ) && (
            <div className="secondaryButton">
              <Button
                action="approve"
                onClick={() => {
                  handleApproveCQAndCreateSAT();
                }}
              >
                <IoCheckmarkDoneCircleOutline size={22} />
                <p>Approve and create SAT</p>
              </Button>
            </div>
          )}

        {clientQuotation.idStatus === ClientQuotationStatus.APPROVED &&
          userHasPermission(
            idPermissionCreateSatFromCQ,
            userPermissions.userPermissions,
          ) && (
            <Button
              className="secondaryButton"
              label="Create SAT"
              onClick={() => {
                confirmDialog({
                  message: `Do you want to create a SAT from CQ?`,
                  header: 'Create SAT',
                  icon: 'pi pi-info-circle',
                  accept: () => handleCreateSatFromCq(idCq),
                });
              }}
            />
          )}

        {((idCq &&
          userHasPermission(
            idPermissionUpdateClientQuotation,
            userPermissions.userPermissions,
          )) ||
          userHasPermission(
            idPermissionCreateClientQuotation,
            userPermissions.userPermissions,
          )) && (
          <Button
            className="secondaryButton"
            label="Save"
            onClick={() => handleSaveClientQuotation()}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => handleCancel()}
        />

        {idCq &&
          userHasPermission(
            idPermissionDuplicateClientQuotation,
            userPermissions.userPermissions,
          ) && (
            <Button
              className="secondaryButton"
              label="Duplicate"
              onClick={() => handleDuplicateClientQuotation()}
            />
          )}

        {idCq && !!exportButtonItems.length && (
          <Button
            className="secondaryButton"
            label="Export"
            onClick={event => exportMenuRef.current?.toggle(event)}
          />
        )}

        {clientQuotation.idStatus === ClientQuotationStatus.AT_COMMERCIAL && (
          <Button
            className="p-button-danger"
            onClick={() => {
              setUserAction(ClientQuotationAprovarAction.ENVIAR_PURCHASE);
              setDisplayEnterACommentDialog(true);
            }}
            disabled={
              clientQuotation.idResponsibleUser !== idUser &&
              !userHasRole(Role.ADMINISTRATOR, roles.rolesUser) &&
              !userHasRole(Role.COMMERCIAL_KEY_USER, roles.rolesUser) &&
              !userHasRole(Role.MANAGER, roles.rolesUser)
            }
          >
            <p>Send to Purchase</p>
          </Button>
        )}

        {clientQuotation.idResponsibleUser === idUser &&
          clientQuotation.idStatus === ClientQuotationStatus.AT_COMMERCIAL && (
            <Button
              className="secondaryButton"
              action="reject"
              onClick={() => {
                setUserAction(ClientQuotationAprovarAction.CANCELAR_CQ);
                setDisplayEnterACommentDialog(true);
              }}
            >
              <AiOutlineMinusCircle size={22} />
              <p>Not Approved</p>
            </Button>
          )}
      </PageHeader>

      <MutationResponseSummary
        ref={mutationResponseSummaryRef}
        header={`${idCq ? 'Update' : 'Create'} Client Quotation Summary`}
        data={updateClientQuotationMutationResponse}
      />

      {((idCq &&
        !clientQuotationLoading &&
        clientQuotation.idCq !== undefined) ||
        !idCq) && (
        <CrudForm>
          <PageMenu
            menuWidth="195px"
            setSelectedMenuItem={setSelectedMenuItem}
            selectedMenuItem={selectedMenuItem}
            items={menuItems}
          />
          <GeneralInformation
            selected={
              selectedMenuItem ===
              clientQuotationLateralMenuItems.generalInformation.key
            }
            clientQuotation={clientQuotation}
            changePropertyValue={changePropertyValue}
            idCq={clientQuotation.idCq}
            ref={generalInformationRef}
            setSelectedMenuItem={setSelectedMenuItem}
            pageMenuItemKey={
              clientQuotationLateralMenuItems.generalInformation.key
            }
            roleEntityFields={userPermissions.userFields}
            setClientQuotation={setClientQuotation}
          />
          {clientQuotation.idCq && (
            <>
              <Suppliers
                ref={suppliersRef}
                selected={
                  selectedMenuItem ===
                  clientQuotationLateralMenuItems.suppliers.key
                }
                clientQuotation={clientQuotation}
                roleEntityFields={userPermissions.userFields}
                roleEntityPermissions={userPermissions.userPermissions}
              />
              <Financial
                ref={financialRef}
                selected={
                  selectedMenuItem ===
                  clientQuotationLateralMenuItems.financial.key
                }
                clientQuotation={clientQuotation}
                setSelectedMenuItem={setSelectedMenuItem}
                pageMenuItemKey={clientQuotationLateralMenuItems.financial.key}
                roleEntityFields={userPermissions.userFields}
                suppliersRef={suppliersRef}
              />
              <Items
                ref={itemsRef}
                selected={
                  selectedMenuItem ===
                  clientQuotationLateralMenuItems.cqItems.key
                }
                clientQuotation={clientQuotation}
                setPageLoading={setLoading}
                roleEntityFields={userPermissions.userFields}
                roleEntityPermissions={userPermissions.userPermissions}
                clientQuotationRefetch={clientQuotationRefetch}
                cleanClientQuotation={cleanClientQuotation}
              />
              <UpdatesContent
                selected={
                  selectedMenuItem ===
                  clientQuotationLateralMenuItems.updates.key
                }
                clientQuotation={clientQuotation}
                setPageLoading={setLoading}
                pageLoading={loading}
                userPermissions={userPermissions.userPermissions}
              />
              <Attachments
                selected={
                  selectedMenuItem ===
                  clientQuotationLateralMenuItems.attachments.key
                }
                idCq={clientQuotation.idCq}
                setPageLoading={setLoading}
              />
            </>
          )}
        </CrudForm>
      )}

      {(loading || clientQuotationLoading) && <Loading />}
    </Container>
  );
};

export default ClientQuotation;
