import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  inlandFreightTaxName: {
    field: 'inlandFreightTaxName',
    header: 'Tax Name',
    advancedFilterField: 'inlandFreightTaxName',
  },
  idPortAirportDestination: {
    field: 'idPortAirportDestination2.name',
    header: 'Port/Airport Destination',
    advancedFilterField: 'idPortAirportDestination',
  },
  taxValuePos: {
    field: 'taxValuePos',
    header: 'Posicionamento',
    advancedFilterField: 'taxValuePos',
  },
  idCurPos: {
    field: 'idCurPos2.abbreviation',
    header: 'Currency Pos',
    advancedFilterField: 'idCurPos',
  },
  idBcValuePos: {
    field: 'idBcValuePos2.description',
    header: 'BC Value Pos',
    advancedFilterField: 'idBcValuePos',
  },
  bcCifPos: {
    field: 'bcCifPos',
    header: 'BC CIF Pos',
    advancedFilterField: 'bcCifPos',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtPos: {
    field: 'updatedAtPos',
    header: 'Last Updated Pos',
    advancedFilterField: '',
  },
  taxValuePes: {
    field: 'taxValuePes',
    header: 'Pesagem',
    advancedFilterField: 'taxValuePes',
  },
  idCurPes: {
    field: 'idCurPes2.abbreviation',
    header: 'Currency Pes',
    advancedFilterField: 'idCurPes',
  },
  idBcValuePes: {
    field: 'idBcValuePes2.description',
    header: 'BC Value Pes',
    advancedFilterField: 'idBcValuePes',
  },
  bcCifPes: {
    field: 'bcCifPes',
    header: 'BC CIF Pes',
    advancedFilterField: 'bcCifPes',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtPes: {
    field: 'updatedAtPes',
    header: 'Last Updated Pes',
    advancedFilterField: '',
  },
  taxValueArm: {
    field: 'taxValueArm',
    header: 'Armazenagem (10 Dias)',
    advancedFilterField: 'taxValueArm',
  },
  idCurArm: {
    field: 'idCurArm2.abbreviation',
    header: 'Currency Arm',
    advancedFilterField: 'idCurArm',
  },
  idBcValueArm: {
    field: 'idBcValueArm2.description',
    header: 'BC Value Arm',
    advancedFilterField: 'idBcValueArm',
  },
  bcCifArm: {
    field: 'bcCifArm',
    header: 'BC CIF Arm',
    advancedFilterField: 'bcCifArm',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtArm: {
    field: 'updatedAtArm',
    header: 'Last Updated Arm',
    advancedFilterField: '',
  },
  taxValueAdm: {
    field: 'taxValueAdm',
    header: 'Taxa Administrativa',
    advancedFilterField: 'taxValueAdm',
  },
  idCurAdm: {
    field: 'idCurAdm2.abbreviation',
    header: 'Currency Adm',
    advancedFilterField: 'idCurAdm',
  },
  idBcValueAdm: {
    field: 'idBcValueAdm2.description',
    header: 'BC Value Adm',
    advancedFilterField: 'idBcValueAdm',
  },
  bcCifAdm: {
    field: 'bcCifAdm',
    header: 'BC CIF Adm',
    advancedFilterField: 'bcCifAdm',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtAdm: {
    field: 'updatedAtAdm',
    header: 'Last Updated Adm',
    advancedFilterField: '',
  },
};
