import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';

import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { SatFinancialTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface ISellerTotalReceivedProps {
  fieldsPermissions: SatFinancialTotalFieldsPermissions;
  userCanSee: boolean;
  satCurrency?: string;
  totalReceived: number;
  totalRemainSun: number;
  totalReceivedLoading: boolean;
}

const SellerTotalReceived: React.FC<ISellerTotalReceivedProps> = ({
  fieldsPermissions,
  userCanSee,
  satCurrency,
  totalReceived,
  totalRemainSun,
  totalReceivedLoading,
}) => {
  const satCurrencySymbol = getCurrencySymbol(satCurrency);

  return userCanSee ? (
    <div className="grid">
      <FormTitle className="col-12" name="Total Received" />

      {fieldsPermissions.total.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Received"
          value={totalReceived}
          loading={totalReceivedLoading}
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
        />
      )}
      {fieldsPermissions.totalRemain.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Remain SUN"
          value={totalRemainSun}
          loading={totalReceivedLoading}
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
          severity={totalRemainSun < 0 ? 'danger' : undefined}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SellerTotalReceived;
