import React from 'react';
import { Container } from './styles';

interface StatusTagProps {
  className?: string;
  name?: string;
  status: string;
  domain: number;
}

const StatusTag: React.FC<StatusTagProps> = ({
  className,
  name,
  status,
  domain,
}) => {
  return (
    <Container
      domain={domain}
      statusOnly={!name}
      className={`status-tag ${className ?? ''}`}
    >
      {name && <span className="name">{name}</span>}
      <span className="status">{status}</span>
    </Container>
  );
};

export default StatusTag;
