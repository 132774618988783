import { gql } from '@apollo/client';

export const listAllCurrenciesInFinancialAccountsQuery = gql`
  query listAllCurrenciesInFinancialAccounts {
    listAllCurrenciesInFinancialAccounts {
      idCurrency
      abbreviation
      usdExchangeRate
      usdExchangeRateUpdatedAt
    }
  }
`;

export const listAllCompaniesQuery = gql`
  query listAllCompanies($data: ListAllCompaniesInput!) {
    listAllCompanies(data: $data) {
      data {
        idCompany
        name
      }
    }
  }
`;
