import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DataTablePageEvent } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import {
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
} from '../interfaces';
import {
  SatForeignTradePayment,
  SellerBalanceReceivedLazyParams,
} from './interfaces';
import pagination from '../../../../../../config/pagination';
import { getSumBalancePayments, listBalanceReceivedQuery } from './queries';
import { FinancialSellerPaymentType } from '../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import FormTitle from '../../../../../../components/FormTitle';
import {
  parseCurrencyPtBrNumberColumn,
  parsePercentageColumnPt,
} from '../../../../../../utils/gridColumnsParse';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import Grid from '../../../../../../components/Grid';
import { SatFinancialBalanceFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface ISellerBalancePaidProps {
  idSat: number;
  selected: boolean;
  totalDispatch: Dispatch<ForeignTradeSupplierTotalReducerAction>;
  fieldsPermissions: SatFinancialBalanceFieldsPermissions;
  satCurrency?: string;
}

const SellerBalanceReceived: React.FC<ISellerBalancePaidProps> = ({
  idSat,
  selected,
  totalDispatch,
  fieldsPermissions,
  satCurrency,
}) => {
  const { toastRef } = useRefHook();

  const userCanSeeBlock =
    fieldsPermissions.percentage.view ||
    fieldsPermissions.amount.view ||
    fieldsPermissions.paymentDate.view ||
    fieldsPermissions.shipment.view;

  const [payments, setPayments] = useState<SatForeignTradePayment[]>();

  const [lazyParams, setLazyParams] = useState<SellerBalanceReceivedLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadForeignTradePaymentsData,
    {
      loading: loadingForeignTradePaymentsData,
      data: foreignTradePaymentsData,
    },
  ] = useLazyQuery(listBalanceReceivedQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
        idSat,
        idsTypes: [FinancialSellerPaymentType.BALANCE],
      },
    },
    onCompleted: response => {
      if (response.listSatForeignTradeSupplierPaymentsByIdSat.data) {
        setPayments(response.listSatForeignTradeSupplierPaymentsByIdSat.data);
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Total Seller Balance Received',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [loadGridSumData, { loading: gridSumLoading, data: gridSumSumData }] =
    useLazyQuery(getSumBalancePayments, {
      variables: {
        idSat,
        typeIdDomainGroup: DomainGroup.FINANCIAL_SELLER_PAYMENT_TYPES,
      },
      onCompleted: response => {
        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_BALANCE_RECEIVED_TOTAL,
          payload: {
            sellerBalanceReceivedTotal:
              response.getSumDataFromSatForeignTradeSupplierBalanceByIdSat,
          },
        });
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Total Seller Balance Received',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_BALANCE_RECEIVED_TOTAL_LOADING,
        });
      },
    });

  const loadGridData = useCallback(async () => {
    totalDispatch({
      type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_BALANCE_RECEIVED_TOTAL_LOADING,
    });

    loadForeignTradePaymentsData();

    loadGridSumData();
  }, [loadForeignTradePaymentsData, loadGridSumData, totalDispatch]);

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageEvent) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  const totalColumnFooter = (key: string, type?: 'currency' | 'percentage') => {
    if (gridSumLoading) return <Skeleton />;

    const sumData =
      gridSumSumData?.getSumDataFromSatForeignTradeSupplierBalanceByIdSat[key];

    if (!sumData) {
      return '';
    }

    const formattedSumData = numberToPtString(sumData, type ? 2 : 0);

    switch (type) {
      case 'currency':
        return `${getCurrencySymbol(satCurrency)} ${formattedSumData}`;
      case 'percentage':
        return `${formattedSumData}%`;
      default:
        return formattedSumData;
    }
  };

  useEffect(() => {
    if (!payments && selected) {
      loadGridData();
    }
  }, [loadGridData, payments, selected]);

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Balance Received" />

      <Grid
        className="p-datatable-sm col-12"
        emptyMessage="No balance received found"
        loading={loadingForeignTradePaymentsData}
        value={payments}
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={
          !foreignTradePaymentsData
            ? 0
            : foreignTradePaymentsData
                .listSatForeignTradeSupplierPaymentsByIdSat?.items
        }
        scrollable
        scrollHeight="calc(100vh - 272px)"
        onPage={onPage}
      >
        {fieldsPermissions.shipment.view && (
          <Column
            field="idSatForeignTradeSupplier2.satForeignTradeSupplierNumber"
            header="Shipment"
            style={{ width: '16rem' }}
          />
        )}

        {fieldsPermissions.percentage.view && (
          <Column
            field="percentage"
            header="Balance %"
            style={{ width: '8rem' }}
            body={parsePercentageColumnPt}
            footer={() => totalColumnFooter('totalPercentage', 'percentage')}
          />
        )}

        {fieldsPermissions.amount.view && (
          <Column
            field="amount"
            header="Amount"
            style={{ width: '8rem' }}
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
            }
            footer={() => totalColumnFooter('totalAmount', 'currency')}
          />
        )}

        {fieldsPermissions.paymentDate.view && (
          <Column
            field="paymentDate"
            header="Payment Date"
            style={{ width: '8rem' }}
            body={e => parseToLocaleFormat(e.paymentDate)}
          />
        )}
      </Grid>
    </div>
  ) : (
    <div />
  );
};

export default SellerBalanceReceived;
