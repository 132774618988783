import React from 'react';
import logoLargeColor from '../../assets/logoLargeColor.svg';
import { Container } from './styles';
import useTitle from '../../hooks/useTitle';

const Forbidden: React.FC = () => {
  useTitle('Forbidden');

  return (
    <Container>
      <h1>Forbidden</h1>
      <h3>You don&apos;t have permission to access this page</h3>
      <img src={logoLargeColor} alt="SAT" />
    </Container>
  );
};

export default Forbidden;
