/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { useRefHook } from '../../../hooks/useRefHook';
import { Container, CrudForm } from './styles';

import {
  queryToUpdate,
  queryToListIdDomain,
  queryToCreateDomain,
} from '../../../services/querysGql';
import { IGeneralInformationFormData } from '../../../services/dataInterface';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface SpecialNegotiationRouteParams {
  specialNegotiationId: string;
}
const idGroupToSpecialNegotiationsClient = 6;

const SpecialNegotiation: React.FC = () => {
  // ID do cliente special negotiation
  const { specialNegotiationId } = useParams<SpecialNegotiationRouteParams>();

  const defaultPageTitle = `${
    specialNegotiationId ? 'Manage' : 'Create'
  } Special Negotiation`;

  // Nome da special negotiation
  const [specialNegotiationName, setSpecialNegotiationName] = useState('');

  useTitle(
    specialNegotiationName
      ? `${specialNegotiationName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Estado da versão lock otimista
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Special Negotiation',
      path: '/list/special-negotiations',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Carrega response do especial negotiation
  const [
    loadSpecialNegotiationData,
    {
      called: SpecialNegotiationCalled,
      loading: SpecialNegotiationLoading,
      data: SpecialNegotiationData,
      error: SpecialNegotiationError,
    },
  ] = useLazyQuery(queryToListIdDomain, {
    variables: {
      idDomain: parseInt(specialNegotiationId, 10),
    },
    onCompleted: async response => {
      // Valida se o special negotiation nao foi encontrado
      if (
        !response.listDomainById ||
        response.listDomainById.idGroup !== idGroupToSpecialNegotiationsClient
      ) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Client Special Negotiation not found',
          life: ToastLife.ERROR,
        });
        // Redireciona para listagem de special negotiation cadastrados
        history.push('/list/special-negotiations');
        return;
      }
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listDomainById.versionLock);

      // Define novo nome da special negotiation
      setSpecialNegotiationName(response.listDomainById.description);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Client Special Negotiation data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/list/special-negotiations');
    },
  });

  // Ao carregar a pagina, carrega os Special Negotiation cadastrados
  useEffect(() => {
    if (specialNegotiationId) {
      setPageLoading(true);
      loadSpecialNegotiationData();
    }
  }, [specialNegotiationId, loadSpecialNegotiationData]);

  // cria método para chamar a mutation
  const [createSpecialNegotiation] = useMutation(queryToCreateDomain);
  const [updateSpecialNegotiation] = useMutation(queryToUpdate);

  // Submit para criação de um cliente Special Negotiation
  const handleCreateSpecialNegotiationSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria um special-negotiation
        await createSpecialNegotiation({
          variables: {
            domainData: {
              description,
              idGroup: idGroupToSpecialNegotiationsClient,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client Special Negotiation created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem dos status
        history.push('/list/special-negotiations');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);
          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating client special negotiation',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createSpecialNegotiation, toastRef, history],
  );

  const handleEditSpecialNegotiationSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes do status
        const updateResponse = await updateSpecialNegotiation({
          variables: {
            domainData: {
              idDomain: parseInt(specialNegotiationId, 10),
              description,
              versionLock,
            },
          },
        });
        // atualiza estado de acordo com retorno do servidor
        setVersionLock(updateResponse.data.updateDomain.versionLock);

        // Define novo nome da special negotiation
        setSpecialNegotiationName(description);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client special negotiation updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating client status',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      updateSpecialNegotiation,
      specialNegotiationId,
      versionLock,
      toastRef,
    ],
  );

  const handleCancel = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/special-negotiations'),
    });
  };

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          SpecialNegotiationData
            ? `${defaultPageTitle} - ${specialNegotiationName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            specialNegotiationId
              ? handleEditSpecialNegotiationSubmit
              : handleCreateSpecialNegotiationSubmit
          }
          initialData={
            SpecialNegotiationCalled &&
            !SpecialNegotiationLoading &&
            !SpecialNegotiationError
              ? SpecialNegotiationData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
      </CrudForm>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default SpecialNegotiation;
