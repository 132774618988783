import React, { useState, useEffect, useRef } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PageMenuButton from '../../components/PageMenuButton';
import { Container, CrudForm, Menu } from './styles';
import PageHeader from '../../components/PageHeader';
import Image from '../../components/PageHeader/Image';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';

import Button from '../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import MainButton from '../../components/MainButton';

import { useRefHook } from '../../hooks/useRefHook';
import GeneralInformation from './GeneralInformation';
import Loading from '../../components/Loading';
import Roles, { IRolesRef } from './Roles';
import { useAuth } from '../../hooks/useAuth';

import userHasPermission from '../../utils/userHasPermission';
import getFieldPermission from '../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../utils/getUserFieldsAndPermissionsByEntity';
import { userRoles } from '../../shared/roles/user';
import ToastLife from '../../shared/enums/toastLife';
import { IOffice } from '../../shared/interfaces/office';
import useTitle from '../../hooks/useTitle';
import { FileUploadResponse } from '../../components/FileUpload/interfaces';

// Parametros da rota de usuario
interface UserRouteParams {
  userId: string;
}

interface IUserOffice {
  idOffice2: IOffice;
}

export interface IListUserResponse {
  idUser: number;
  username: string;
  confirmationToken: string;
  passwordChanged: Date;
  email: string;
  phone: string;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  title: string;
  birthday: Date;
  userOffices: IUserOffice[];
  lastLogin: Date;
  loginCount: number;
  active: string;
  createdAt: Date;
  updatedAt: Date;
  userChanged2: IListUserResponse;
  avatarUrl: string;
}

const User: React.FC = () => {
  // ID da entity de usuario
  const { idEntity } = userRoles;

  // Permissions do usuario
  const { idPermissionResetPassword } = userRoles.permissions;

  // IDs dos fields de usuario
  const {
    idFieldPhone,
    idFieldNamePrefix,
    idFieldNameSuffix,
    idFieldMiddleName,
    idFieldTitle,
    idFieldBirthday,
    idFieldActive,
  } = userRoles.fields;

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID do usuario
  const userId = parseInt(useParams<UserRouteParams>().userId, 10);

  const defaultPageTitle = `${userId ? 'Manage' : 'Create'} User`;

  // Nome de usuario
  const [userName, setUserName] = useState('');

  useTitle(userName ? `${userName} - ${defaultPageTitle}` : defaultPageTitle);

  // URL do avatar do usuario
  const [avatarUrl, setAvatarUrl] = useState('');

  // Arquivo do avatar
  const [avatarFile, setAvatarFile] = useState<string>();

  // URL temporaria para upload de arquivo
  const [uploadAvatarTempUrl, setUploadAvatarTempUrl] = useState('');

  // Dados de usuario
  const [user, setUser] = useState<IListUserResponse>();

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] =
    useState('GeneralInformation');

  // Estado de version lock
  const [versionLock, setVersionLock] = useState<number>();

  const rolesRef = useRef<IRolesRef>(null);

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Users',
      path: '/users',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Query para listar dados do usuario
  const listUserQuery = gql`
    query listUser($listUserIdUser: Int) {
      listUser(idUser: $listUserIdUser) {
        username
        email
        firstName
        lastName
        updatedAt
        createdAt
        avatarUrl
        versionLock
        ${
          getFieldPermission(idFieldNamePrefix, userPermissions.userFields).view
            ? 'namePrefix'
            : ''
        }
        ${
          getFieldPermission(idFieldNameSuffix, userPermissions.userFields).view
            ? 'nameSuffix'
            : ''
        }
        ${
          getFieldPermission(idFieldMiddleName, userPermissions.userFields).view
            ? 'middleName'
            : ''
        }
        ${
          getFieldPermission(idFieldBirthday, userPermissions.userFields).view
            ? 'birthday'
            : ''
        }
        userOffices {
          idOffice2 {
            idOffice
            officeName
          }
        }
        ${
          getFieldPermission(idFieldTitle, userPermissions.userFields).view
            ? 'title'
            : ''
        }
        ${
          getFieldPermission(idFieldPhone, userPermissions.userFields).view
            ? 'phone'
            : ''
        }
        ${
          getFieldPermission(idFieldActive, userPermissions.userFields).view
            ? 'active'
            : ''
        }
        userChanged2 {
          firstName
          lastName
        }
      }
    }
  `;

  // Mutation para solicitar reset de senha do usuario
  const setResetPassword = gql`
    mutation SetResetPassword($idUser: Int!) {
      setPasswordReset(idUser: $idUser)
    }
  `;

  // cria método para chamar a mutation
  const [setResetPasswordMutation] = useMutation(setResetPassword);

  const [loadUserData, { loading: userLoading, data: userData }] = useLazyQuery(
    listUserQuery,
    {
      variables: {
        listUserIdUser: userId,
      },
      onCompleted: response => {
        // Valida se usuario nao foi encontrado
        if (!response.listUser) {
          // Exibe erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'User not found',
            life: ToastLife.ERROR,
          });
          // Redireciona usuario para listagem de usuarios
          history.push('/users');
          return;
        }
        setAvatarUrl(response.listUser.avatarUrl);
        setUserName(
          `${response.listUser.firstName}` +
            ' ' +
            `${response.listUser.lastName}`,
        );
        setUser(response.listUser);
        setVersionLock(response.listUser.versionLock);
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting user data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        // Redireciona usuario para listagem de usuarios
        history.push('/users');
      },
    },
  );

  useEffect(() => {
    if (userId) {
      loadUserData();
    }
  }, [loadUserData, userId]);

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/users'),
    });
  }

  function handleAvatarChange(data: FileUploadResponse[]) {
    setAvatarFile(data[0].serverName);
    setUploadAvatarTempUrl(data[0].fileUrl);
  }

  async function handleSaveUser() {
    await formRef.current?.submitForm();
  }

  function handleResetPassword() {
    confirmDialog({
      message: `User ${userName} will have to reset password.`,
      header: 'Reset Password',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setLoading(true);
        try {
          // Deleta portos
          await setResetPasswordMutation({
            variables: {
              idUser: userId,
            },
          });

          // Incrementa o version lock
          if (versionLock !== undefined) setVersionLock(versionLock + 1);

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Password reset requested',
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while setting password to reset',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={userName ? `${defaultPageTitle} - ${userName}` : 'Manage User'}
        minScrollStickyButtons={160}
        hasImage
      >
        <Image
          className="image"
          onChange={e => handleAvatarChange(e)}
          src={
            !uploadAvatarTempUrl
              ? avatarUrl || imagePlaceholder
              : uploadAvatarTempUrl
          }
          alt={userData ? userData.listUser.username : 'Select an image'}
        />

        <p className={userId ? 'createdAt' : ''}>
          {userData && !userLoading
            ? new Date(userData.listUser.createdAt).toLocaleString()
            : ''}
        </p>
        <p className={userId ? 'updatedAt' : ''}>
          {userData && !userLoading
            ? `${new Date(userData.listUser.updatedAt).toLocaleString()} by ${
                userData.listUser.userChanged2.firstName
              } ${userData.listUser.userChanged2.lastName}`
            : ''}
        </p>
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            handleSaveUser();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />

        {userId &&
        userHasPermission(
          idPermissionResetPassword,
          userPermissions.userPermissions,
        ) ? (
          <Button
            label="Reset Password"
            className="p-button-danger"
            severity="danger"
            onClick={handleResetPassword}
          />
        ) : null}
      </PageHeader>
      {/* Formulario */}
      {((userId && !userLoading && user) || !userId) && (
        <CrudForm>
          <Menu>
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('GeneralInformation');
              }}
              selected={selectedMenuItem === 'GeneralInformation'}
              title="General Information"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Roles');
              }}
              selected={selectedMenuItem === 'Roles'}
              title="Roles"
            />
          </Menu>

          <GeneralInformation
            user={user}
            avatar={avatarFile}
            setUserName={setUserName}
            selected={selectedMenuItem === 'GeneralInformation'}
            userId={userId}
            setLoading={setLoading}
            rolesRef={rolesRef}
            roleEntityFields={userPermissions.userFields}
            versionLock={versionLock}
            setVersionLock={setVersionLock}
          />

          <Roles
            innerRef={rolesRef}
            selected={selectedMenuItem === 'Roles'}
            userId={userId}
            setLoading={setLoading}
          />
        </CrudForm>
      )}
      {(userLoading || loading) && <Loading />}
    </Container>
  );
};
export default User;
