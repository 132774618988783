import styled from 'styled-components';

interface IContainerProps {
  noGap?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  gap: ${props => (props.noGap ? '0' : '1.25rem')};
  margin-bottom: 12px;

  * + & {
    margin-top: 38px;
  }
`;
