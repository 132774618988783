/* eslint-disable jsx-a11y/label-has-associated-control */

import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Form } from '@unform/web';
import { clone } from 'lodash';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import * as Yup from 'yup';

import { confirmDialog } from 'primereact/confirmdialog';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';

import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import ChecklistItemPreview from '../../../components/ChecklistItem';
import { useRefHook } from '../../../hooks/useRefHook';

import {
  Container,
  ChecklistData,
  ChecklistItem,
  OtherChecklistsContainer,
  OtherChecklistsHeader,
  OtherChecklistsItem,
} from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import generateRandomString from '../../../utils/generateRandomString';
import Loading from '../../../components/Loading';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface IFormData {
  name: string;
}

const checklistItemType = [
  { name: 'Radio', value: 'radio' },
  { name: 'Check', value: 'check' },
  { name: 'Text', value: 'text' },
  { name: 'Long Text', value: 'longText' },
];

interface ChecklistItem {
  [key: string]: string | number | undefined | unknown;
  idCheckListItem: number | string;
  name: string;
  type?: string;
  section?: number;
  sectionObject?: unknown;
  versionLock: number;
}

interface IOtherChecklistItem {
  idCheckListItem: number;
  name: string;
}

interface IOtherChecklist {
  idCheckList: number;
  name: string;
  selected: boolean;
  checkListItems: IOtherChecklistItem[];
}

// Parametros da rota de checklist
interface ChecklistRouteParams {
  checklistId: string;
}

const Checklist: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID da incoterm
  const checklistId = parseInt(
    useParams<ChecklistRouteParams>().checklistId,
    10,
  );

  const defaultPageTitle = `${checklistId ? 'Manage' : 'Create'} Checklist`;

  // Nome da checklist
  const [checklistName, setChecklistName] = useState('');

  useTitle(
    checklistName ? `${checklistName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // Estado de version lock
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // Estado de itens do checklist
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([
    {
      idCheckListItem: generateRandomString(4),
      name: '',
      section: undefined,
      type: undefined,
      versionLock: 0,
    },
  ]);

  const [otherChecklists, setOtherChecklists] = useState<IOtherChecklist[]>([]);

  function addChecklistItem() {
    setChecklistItems([
      ...checklistItems,
      {
        idCheckListItem: generateRandomString(4),
        name: '',
        section: undefined,
        type: undefined,
        versionLock: 0,
      },
    ]);
  }

  function removeChecklistItem(index: number) {
    const items = clone(checklistItems);
    items.splice(index, 1);
    setChecklistItems(items);
  }

  function handleChange(
    index: number,
    e: ChangeEvent<HTMLInputElement> | DropdownChangeEvent,
  ) {
    const items = clone(checklistItems);

    if (e.target.name === 'section') {
      items[index].sectionObject = e.target.value;
      items[index].section = e.target.value.idDomain;
    } else {
      items[index][e.target.name] = e.target.value;
    }

    setChecklistItems(items);
  }

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/products/checklists'),
    });
  }

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Checklists',
      path: '/products/checklists',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  const sectionDomainGroupId = 18;

  // Query para listar sections
  const listSectionsQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  // Query para listar checklist
  const listChecklistQuery = gql`
    query ListChecklistQuery($id: Int!) {
      listChecklistWithItemsById(id: $id) {
        idCheckList
        name
        versionLock
        checkListItems {
          idCheckListItem
          idCheckList
          name
          type
          section
          createdBy
          createdAt
          updatedBy
          updatedAt
          versionLock
        }
      }
    }
  `;

  // Query para criar checklist
  const createChecklistQuery = gql`
    mutation CreateChecklistWithItemsMutation(
      $checklistData: CreateChecklistWithItemsInput!
    ) {
      createChecklistWithItems(data: $checklistData) {
        name
        versionLock
      }
    }
  `;

  // Query para atualizar checklist
  const updateChecklistQuery = gql`
    mutation UpdateChecklistWithItemsMutation(
      $checklistData: UpdateChecklistWithItemsInput!
    ) {
      updateChecklistWithItems(data: $checklistData) {
        idCheckList
        name
        versionLock
        checkListItems {
          idCheckListItem
          idCheckList
          name
          type
          section
          createdBy
          createdAt
          updatedBy
          updatedAt
          versionLock
        }
      }
    }
  `;

  // Query para listar informacoes de outras checklists
  const listOtherChecklistsQuery = gql`
    query ListOtherChecklistsQuery {
      listChecklistsWithItems {
        idCheckList
        name
        checkListItems {
          idCheckListItem
          name
        }
      }
    }
  `;

  /**
   * Busca checklists
   */
  // Carrega response do status
  const [loadOtherChecklists, { loading: otherChecklistsLoading }] =
    useLazyQuery(listOtherChecklistsQuery, {
      onCompleted: response => {
        const filteredChecklists = response.listChecklistsWithItems.filter(
          (checklist: IOtherChecklist) => checklist.idCheckList !== checklistId,
        );
        setOtherChecklists(filteredChecklists);
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting checklists',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  // cria método para chamar a mutation
  const [createChecklist] = useMutation(createChecklistQuery);
  const [updateChecklist] = useMutation(updateChecklistQuery);

  // Submit do formulario
  const handleSubmitChecklist = useCallback(
    async (formData: IFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        const hasEmptyField = checklistItems.find(
          item => !item.name || !item.section || !item.type,
        );

        // Valida se ha algum campo em branco
        if (hasEmptyField) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Please fill all fields',
            life: ToastLife.WARN,
          });
          return;
        }

        // Valida se ch
        if (!checklistItems.length) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Please add at least one item',
            life: ToastLife.WARN,
          });
          return;
        }

        // Separa cada propriedade
        const { name } = formData;

        if (checklistId) {
          const itemsToBackend = checklistItems.map(item => {
            return {
              idCheckListItem:
                typeof item.idCheckListItem === 'number'
                  ? item.idCheckListItem
                  : 0,
              name: item.name,
              type: item.type,
              section: item.section,
              versionLock: item.versionLock,
            };
          });

          // Atualiza checklist
          const response = await updateChecklist({
            variables: {
              checklistData: {
                idCheckList: checklistId,
                name,
                checklistItems: itemsToBackend,
                versionLock,
              },
            },
          });

          setChecklistItems(
            response.data.updateChecklistWithItems.checkListItems,
          );
          setVersionLock(response.data.updateChecklistWithItems.versionLock);
        } else {
          const itemsToBackend = checklistItems.map(item => {
            return {
              name: item.name,
              type: item.type,
              section: item.section,
            };
          });

          // Cria checklist
          await createChecklist({
            variables: {
              checklistData: {
                name,
                checklistItems: itemsToBackend,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Checklist ${checklistId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de checklists
        if (!checklistId) history.push('/products/checklists');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              checklistId ? 'editing' : 'creating'
            }  checklist`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      checklistId,
      toastRef,
      history,
      updateChecklist,
      checklistItems,
      versionLock,
      createChecklist,
    ],
  );

  /**
   * Busca sections
   */
  const {
    data: sectionsData,
    error: sectionsError,
    loading: sectionsLoading,
  } = useQuery(listSectionsQuery, {
    variables: {
      id: sectionDomainGroupId,
      pagination: {
        _page: 1,
        _limit: 100,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting sections',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Carrega response do status
  const [
    loadChecklistData,
    {
      called: checklistCalled,
      loading: checklistLoading,
      data: checklistData,
      error: checklistError,
    },
  ] = useLazyQuery(listChecklistQuery, {
    variables: {
      id: checklistId,
    },
    onCompleted: async response => {
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listChecklistWithItemsById.versionLock);

      // Define novo nome de checklist
      setChecklistName(response.listChecklistWithItemsById.name);

      setChecklistItems(response.listChecklistWithItemsById.checkListItems);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting checklist data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/products/checklists');
    },
  });

  useEffect(() => {
    if (checklistId) {
      setPageLoading(true);
      loadChecklistData();
    }
  }, [checklistId, loadChecklistData]);

  function handleChangeOtherChecklist(index: number) {
    const checklists = clone(otherChecklists);

    checklists[index].selected = !checklists[index].selected;

    setOtherChecklists(checklists);
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          checklistId
            ? `${defaultPageTitle} - ${checklistName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>
      <ChecklistData>
        <Form
          ref={formRef}
          onSubmit={handleSubmitChecklist}
          initialData={
            checklistCalled && !checklistLoading && !checklistError
              ? checklistData.listChecklistWithItemsById
              : undefined
          }
        >
          <FormInput name="name" label="Checklist name" required />
        </Form>

        {!!checklistItems.length && (
          <div className="checklist-items-header">
            <h1>Items</h1>
            <h1>Preview</h1>
          </div>
        )}

        {checklistItems.map((item, index) => {
          return (
            <div className="item-and-preview" key={item.idCheckListItem}>
              <ChecklistItem>
                <div className="button-div">
                  <button
                    type="button"
                    onClick={() => {
                      removeChecklistItem(index);
                    }}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>

                <span className="field-detail">
                  <label htmlFor={`${index.toString()}-name`}>Question</label>
                  <InputText
                    id={`${index.toString()}-name`}
                    name="name"
                    value={item.name}
                    onChange={e => handleChange(index, e)}
                    placeholder="Enter a Question"
                    className={!item.name ? 'p-invalid block' : ''}
                  />
                </span>
                <span className="field-detail">
                  <label htmlFor={`${index.toString()}-type`}>Type</label>
                  <Dropdown
                    id={`${index.toString()}-type`}
                    name="type"
                    optionLabel="name"
                    value={item.type}
                    options={checklistItemType}
                    onChange={e => handleChange(index, e)}
                    placeholder="Select a Type"
                    className={!item.type ? 'p-invalid' : ''}
                  />
                </span>
                <span className="field-detail">
                  <label htmlFor={`${index.toString()}-type`}>Section</label>
                  <Dropdown
                    id={`${index.toString()}-section`}
                    name="section"
                    optionLabel="description"
                    value={
                      !sectionsData || sectionsError
                        ? undefined
                        : sectionsData.listDomainsByGroupId.data.find(
                            (a: ChecklistItem) =>
                              a === item.sectionObject ||
                              a.idDomain === item.section,
                          )
                    }
                    disabled={sectionsLoading || !!sectionsError}
                    options={
                      !sectionsData || sectionsError
                        ? undefined
                        : sectionsData.listDomainsByGroupId.data
                    }
                    onChange={e => handleChange(index, e)}
                    placeholder={
                      sectionsLoading ? 'Loading' : 'Select a Section'
                    }
                    className={!item.section ? 'p-invalid' : ''}
                  />
                </span>
              </ChecklistItem>
              <ChecklistItemPreview
                question={item.name}
                fieldType={item.type}
              />
            </div>
          );
        })}

        <div className="buttons">
          <MainButton
            className="add-item-button"
            onClick={() => {
              addChecklistItem();
            }}
          >
            New item
          </MainButton>
          <Button
            onClick={() =>
              otherChecklists.length
                ? setOtherChecklists([])
                : loadOtherChecklists()
            }
            loading={otherChecklistsLoading}
            className="load-other-checklists"
          >
            {otherChecklists.length ? 'Hide all checks' : 'Show all checks'}
          </Button>
        </div>

        <OtherChecklistsContainer>
          {otherChecklists.map((checklist, index) => {
            return (
              <React.Fragment key={checklist.idCheckList}>
                <OtherChecklistsHeader
                  onClick={() => {
                    handleChangeOtherChecklist(index);
                  }}
                >
                  <p>{checklist.name}</p>
                  {checklist.selected ? (
                    <FiChevronUp size={20} />
                  ) : (
                    <FiChevronDown size={20} />
                  )}
                </OtherChecklistsHeader>
                {checklist.selected &&
                  checklist.checkListItems.map(item => {
                    return (
                      <OtherChecklistsItem key={item.idCheckListItem}>
                        {item.name}
                      </OtherChecklistsItem>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </OtherChecklistsContainer>
      </ChecklistData>

      {pageLoading && <Loading />}
    </Container>
  );
};

export default Checklist;
