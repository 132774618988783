/**
 * Calcula o campo Final RNC Cost (USD/EUR)
 * @param totalCostAmountBr Somatório dos RNC Costs com currency BRL
 * @param totalCostAmountSat Somatório dos RNC Costs com currency da SAT
 * @param exchangeValueRate Taxa de conversão
 * @returns Campo Final RNC Cost calculado
 */
export function calculateFinalRncCost(
  totalCostAmountBr: number | undefined,
  totalCostAmountSat: number | undefined,
  exchangeValueRate: number | undefined,
): number | undefined {
  if (totalCostAmountBr && exchangeValueRate && totalCostAmountSat) {
    return totalCostAmountBr / exchangeValueRate + totalCostAmountSat;
  }

  if (exchangeValueRate && totalCostAmountBr) {
    return totalCostAmountBr / exchangeValueRate;
  }

  return totalCostAmountSat;
}
