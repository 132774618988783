import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  getCompanyCashflowDataQuery,
  getFinancialTransactionCashflowDataByIdCompanyQuery,
} from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';
import { BalanceCard } from './styles';
import Loading from '../../../../components/Loading';
import { renderNumber } from '../../../../utils/formatLocale';
import DynamicIcon from '../../../../components/DynamicIcon';
import ReadOnlyInput from '../../../../components/ReadOnlyInput';
import { CompanyRegister } from '../interfaces';

interface ICompanyContentProps {
  idCompany: number;
  companyName: string;
  selected: boolean;
}

interface ICompanyCashflow {
  idCompany: number;
  balanceAmount: number;
  financialAccounts?: {
    idFinancialAccount: number;
    balance: number;
    idCurrency2?: {
      idCurrency: number;
      abbreviation?: string;
    };
  }[];
}

interface IFinancialTransactionCashflow {
  toPayReceivedFromHavanAdvance: number;
  toPayReceivedFromHavanBalance: number;
  toPayReceivedFromOtherClientsAdvance: number;
  toPayReceivedFromOtherClientsBalance: number;
  toReceiveAdvance: number;
  toReceiveBalance: number;
  toPayAdvance: number;
  toPayBalance: number;
  otherExpenses: number;
}

const CompanyContent: React.FC<ICompanyContentProps> = ({
  idCompany,
  companyName,
  selected,
}) => {
  const { showError } = useRefHook();

  const [companyCashflowData, setCompanyCashflowData] =
    useState<ICompanyCashflow>();

  const [
    financialTransactionCashflowData,
    setFinancialTransactionCashflowData,
  ] = useState<IFinancialTransactionCashflow>();

  const [getCompanyCashflowData, { loading: companyCashflowDataLoading }] =
    useLazyQuery(getCompanyCashflowDataQuery, {
      variables: {
        idCompany,
      },
      onCompleted: response => {
        if (response.getCompanyCashflowData) {
          setCompanyCashflowData(response.getCompanyCashflowData);
        }
      },
      onError: error => {
        showError({
          summary: 'Error while getting Company Cashflow data',
          detail: error.message,
        });
      },
    });

  const [
    getFinancialTransactionCashflowData,
    { loading: financialTransactionCashflowDataLoading },
  ] = useLazyQuery(getFinancialTransactionCashflowDataByIdCompanyQuery, {
    variables: {
      idCompany,
    },
    onCompleted: response => {
      if (response.getFinancialTransactionCashflowDataByIdCompany) {
        setFinancialTransactionCashflowData(
          response.getFinancialTransactionCashflowDataByIdCompany,
        );
      }
    },
    onError: error => {
      showError({
        summary: 'Error while getting Financial Transactions Cashflow data',
        detail: error.message,
      });
    },
  });

  const toPayReceivedFromHavan = financialTransactionCashflowData
    ? financialTransactionCashflowData.toPayReceivedFromHavanAdvance +
      financialTransactionCashflowData.toPayReceivedFromHavanBalance
    : 0;

  const toPayReceivedFromOtherClients = financialTransactionCashflowData
    ? financialTransactionCashflowData.toPayReceivedFromOtherClientsAdvance +
      financialTransactionCashflowData.toPayReceivedFromOtherClientsBalance
    : 0;

  const availableBalance = companyCashflowData
    ? companyCashflowData.balanceAmount +
      toPayReceivedFromHavan +
      toPayReceivedFromOtherClients
    : 0;

  const toReceive = financialTransactionCashflowData
    ? financialTransactionCashflowData.toReceiveAdvance +
      financialTransactionCashflowData.toReceiveBalance
    : 0;

  const toPay = financialTransactionCashflowData
    ? financialTransactionCashflowData.toPayAdvance +
      financialTransactionCashflowData.toPayBalance
    : 0;

  const thisMonthsProjection =
    availableBalance +
    toReceive +
    toPay +
    (financialTransactionCashflowData?.otherExpenses || 0);

  function renderBalanceCurrencies() {
    const financialAccounts = companyCashflowData?.financialAccounts;

    if (financialAccounts?.length) {
      return financialAccounts.map((financialAccount, index) => {
        // Se for a Financial Account de dolar (primeira do array), formata com destaque
        if (index === 0) {
          return (
            <h2 key={financialAccount.idFinancialAccount}>
              {renderNumber(financialAccount.balance, 'float')}{' '}
              {financialAccount.idCurrency2?.abbreviation}
            </h2>
          );
        }

        return (
          <h3 key={financialAccount.idFinancialAccount}>
            {renderNumber(financialAccount.balance, 'float')}{' '}
            {financialAccount.idCurrency2?.abbreviation}
          </h3>
        );
      });
    }

    return '';
  }

  const renderSatAndFzcCashflowData =
    (idCompany === CompanyRegister.SAT ||
      idCompany === CompanyRegister.SAT_FZC) &&
    financialTransactionCashflowData &&
    !financialTransactionCashflowDataLoading;

  const renderFlaAndSatAmericasCashflowData =
    (idCompany === CompanyRegister.FLA ||
      idCompany === CompanyRegister.SAT_AMERICAS_GROUP_CORP) &&
    financialTransactionCashflowData &&
    !financialTransactionCashflowDataLoading;

  useEffect(() => {
    if (!companyCashflowData && !financialTransactionCashflowData && selected) {
      getCompanyCashflowData();
      getFinancialTransactionCashflowData();
    }
  }, [
    getCompanyCashflowData,
    getFinancialTransactionCashflowData,
    selected,
    companyCashflowData,
    financialTransactionCashflowData,
  ]);

  return (
    <div>
      {companyCashflowData && !companyCashflowDataLoading && (
        <div className="flex flex-column gap-2">
          <h1>Balances</h1>

          <div className="flex gap-5 py-3">
            <BalanceCard
              style={{ backgroundColor: 'var(--sunasia-cashflow-1)' }}
            >
              <h3 className="text-center">{companyName} Balance</h3>

              <DynamicIcon icon="sat/SatBalanceBank" size="118" />

              <div className="w-full">
                <p>Balance Amount</p>
                <h2>
                  {renderNumber(
                    companyCashflowData.balanceAmount,
                    'currency',
                    'USD',
                  )}
                </h2>
                {(idCompany === CompanyRegister.SAT ||
                  idCompany === CompanyRegister.SAT_FZC) && (
                  <div>
                    <p>Available Balance</p>
                    <h2 className="text-green-500">
                      {renderNumber(availableBalance, 'currency', 'USD')}
                    </h2>
                  </div>
                )}
              </div>
            </BalanceCard>

            <BalanceCard
              style={{ backgroundColor: 'var(--sunasia-cashflow-2)' }}
            >
              <h3 className="text-center">{companyName} Balance/Currencies</h3>

              <DynamicIcon icon="sat/SatBalanceCash" size="118" />

              <div className="w-full">{renderBalanceCurrencies()}</div>
            </BalanceCard>
          </div>

          <h2 className="text-green-500">{companyName}</h2>

          {renderSatAndFzcCashflowData && (
            <div className="flex flex-column gap-4">
              <div className="grid">
                <ReadOnlyInput
                  className="col-4 text-green-500"
                  label="Balance Amount"
                  value={renderNumber(
                    companyCashflowData.balanceAmount,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-green-500"
                  label="Available Balance"
                  value={renderNumber(availableBalance, 'currency', 'USD')}
                />

                <span className="col-4" />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="TO PAY Received from HAVAN"
                  value={renderNumber(
                    toPayReceivedFromHavan,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Advance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayReceivedFromHavanAdvance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Balance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayReceivedFromHavanBalance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="TO PAY Received from Other Clients"
                  value={renderNumber(
                    toPayReceivedFromOtherClients,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Advance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayReceivedFromOtherClientsAdvance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Balance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayReceivedFromOtherClientsBalance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-blue-500"
                  label="TO RECEIVE (Pre Shipment / After Shipment)"
                  value={renderNumber(toReceive, 'currency', 'USD')}
                />

                <ReadOnlyInput
                  className="col-4 text-blue-500"
                  label="Advance"
                  value={renderNumber(
                    financialTransactionCashflowData.toReceiveAdvance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-blue-500"
                  label="Balance"
                  value={renderNumber(
                    financialTransactionCashflowData.toReceiveBalance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="TO PAY (Pre Shipment / After Shipment)"
                  value={renderNumber(toPay, 'currency', 'USD')}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Advance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayAdvance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Balance"
                  value={renderNumber(
                    financialTransactionCashflowData.toPayBalance,
                    'currency',
                    'USD',
                  )}
                />

                <ReadOnlyInput
                  className="col-4 text-red-500"
                  label="Other Expenses"
                  value={renderNumber(
                    financialTransactionCashflowData.otherExpenses,
                    'currency',
                    'USD',
                  )}
                />
              </div>

              <div>
                <h2 className="text-yellow-500">PROJECTION</h2>

                <ReadOnlyInput
                  className="text-yellow-500"
                  label="This Month's"
                  value={renderNumber(thisMonthsProjection, 'currency', 'USD')}
                />
              </div>
            </div>
          )}

          {renderFlaAndSatAmericasCashflowData && (
            <div className="flex flex-column gap-2">
              <ReadOnlyInput
                className="text-green-500"
                label="Balance Amount"
                value={renderNumber(
                  companyCashflowData.balanceAmount,
                  'currency',
                  'USD',
                )}
              />

              <h2 className="text-red-500">TO PAY</h2>

              <ReadOnlyInput
                className="text-red-500"
                label="Other Expenses"
                value={renderNumber(
                  financialTransactionCashflowData?.otherExpenses,
                  'currency',
                  'USD',
                )}
              />
            </div>
          )}
        </div>
      )}

      {companyCashflowDataLoading &&
        financialTransactionCashflowDataLoading && <Loading />}
    </div>
  );
};

export default CompanyContent;
