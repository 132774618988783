import { gql } from '@apollo/client';

export const getProcessStatusQuery = gql`
  query GetSatProcessStatus($idSat: Int!) {
    getSatProcessStatus(idSat: $idSat) {
      satForeignTradeNumber
      satForeignTradeSupplierNumber
      processStatus {
        registerStatus {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        statusPurchasePayment {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        statusSellingPayment {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        statusArtwork {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        productionStatus {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        shipmentStatus {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
        documentsStatus {
          idDomain
          description
          statusLayout {
            idLayout
          }
        }
      }
    }
  }
`;
