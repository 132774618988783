import React, { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useAuth } from '../../../hooks/useAuth';
import RoundUserAvatar from '../../RoundUserAvatar';
import Mentions from '../../Mentions';
import MainButton from '../../MainButton';
import FileUpload from '../../FileUpload';
import { FileType } from '../../../shared/enums/fileType';

import { Container } from './styles';
import generateRandomString from '../../../utils/generateRandomString';
import { FileUploadResponse } from '../../FileUpload/interfaces';
import { IAttachment } from '../../../shared/interfaces/attachment';

type OnSaveParams = {
  reply: string;
  idAttachment?: number;
  isRestrictInfo: boolean;
};

interface IReplyCommentProps {
  initialValue?: string;
  onSave?: (e: OnSaveParams) => void;
  saveLoading?: boolean;
  attachmentDisabled?: boolean;
  showRestrictInfo?: boolean;
  isRestrictInfoValue?: boolean;
  isRestrictInfoDefaultValue?: boolean;
  onUploadAttachment(e: FileUploadResponse[]): Promise<IAttachment | undefined>;
}

let container: Element | undefined;

const ReplyToComment: React.FC<IReplyCommentProps> = ({
  initialValue = '',
  onSave,
  saveLoading,
  attachmentDisabled,
  showRestrictInfo,
  isRestrictInfoValue = false,
  isRestrictInfoDefaultValue = false,
  onUploadAttachment,
}) => {
  const { user } = useAuth();
  const userFullName = `${user.firstName} ${user.lastName}`;

  const commentInput = useRef<HTMLTextAreaElement>(null);
  const [reply, setReply] = useState<string>('');
  const [idAttachment, setIdAttachment] = useState<number>();
  const [isRestrictInfo, setRestrictInfo] = useState<boolean>(
    isRestrictInfoValue || isRestrictInfoDefaultValue,
  );

  const restrictInfoInputId = `restrictInfo-${generateRandomString(4)}`;

  const chooseOptions = {
    icon: idAttachment ? 'pi pi-check' : 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  async function onAttachmentUpload(e: FileUploadResponse[]) {
    const attachment = await onUploadAttachment(e);
    setIdAttachment(attachment?.idAttachment);
    setReply(`${reply} <${attachment?.nameGivenByUser}> `);
  }

  useEffect(() => {
    if (initialValue) {
      setReply(initialValue);
      commentInput.current?.focus();
    }
  }, [initialValue]);

  return (
    <Container
      id="suggestionPortal"
      className="grid"
      ref={el => {
        container = el ?? undefined;
      }}
    >
      <RoundUserAvatar
        className="mr-2"
        image={user.avatarUrl ?? ''}
        userFullName={userFullName}
        imageAlt={userFullName}
      />

      <div className="col-11 input-wrapper">
        <Mentions
          inputRef={commentInput}
          value={reply}
          onChange={e => setReply(e)}
          suggestionsPortalHost={container}
        />
        <div className="col-12 flex justify-content-between">
          <MainButton
            className="mainButton"
            label="Save"
            onClick={() => {
              if (onSave) {
                onSave({ reply, idAttachment, isRestrictInfo });
              }
            }}
            disabled={!reply}
            loading={saveLoading}
          />

          <div className="flex align-items-center">
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => onAttachmentUpload(e)}
              disabled={!!idAttachment || attachmentDisabled}
            />

            {showRestrictInfo && (
              <label htmlFor={restrictInfoInputId} className="p-checkbox-label">
                <Checkbox
                  className="mr-2"
                  id={restrictInfoInputId}
                  inputId={restrictInfoInputId}
                  onChange={e => setRestrictInfo(!!e.checked)}
                  checked={isRestrictInfo}
                  disabled={isRestrictInfoValue}
                />
                Restrict Info
              </label>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReplyToComment;
