import { useLazyQuery, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';

import { gridColumnsData } from './constants';
import { IBeneficiaryData, IBeneficiaryLazyParams } from './interfaces';
import { beneficiaryRoles } from '../../../shared/roles/beneficiary';
import { exportBeneficiariesQuery, listBeneficiariesQuery } from './queries';

const screenSubject = 'Beneficiaries';
const pageTitle = `List of ${screenSubject}`;

const Beneficiaries: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:beneficiaryGridColumns';

  const lazyParamsName = '@SAT:beneficiaryLazyParams';

  const advancedFiltersName = '@SAT:beneficiaryAdvancedFilters';

  const history = useHistory();

  const { showError } = useRefHook();

  const gridRef = useRef<GridRef<any>>(null);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const isMounted = useRef(false);

  const { idPermissionCreateBeneficiary } = beneficiaryRoles.permissions;

  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsBeneficiary = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    beneficiaryRoles.idEntity,
  );

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(gridColumnsData);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);
    if (localStorageSelectedColumns) {
      const parsedColumns = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(
        Array.isArray(parsedColumns) ? parsedColumns : gridColumnsData,
      );
    } else {
      setSelectedColumns(gridColumnsData);
    }
  }, [gridColumnsName]);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IBeneficiaryLazyParams>(
    localStorageLazyParams || pagination.initialLazyParams,
  );

  const saveLazyParams = (newLazyParams: IBeneficiaryLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  };

  const [selectedBeneficiaries, setSelectedBeneficieries] = useState([]);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return gridColumnsData.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [lazyParams]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const beneficiariesCommonVariables = {
    name: lazyParams.name,
    email: lazyParams.email,
    phone: lazyParams.phone,
    description: lazyParams.description,
    active: lazyParams.active,
  };

  /**
   * Busca Beneficiaries
   */
  const { loading: beneficiariesLoading, data: beneficiariesData } = useQuery(
    listBeneficiariesQuery,
    {
      variables: {
        listAllBeneficiariesInput: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          globalSearch: lazyParams.globalFilter,
          ...beneficiariesCommonVariables,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Beneficiaries List',
          detail: errorData.message,
        });
      },
    },
  );

  const { userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      beneficiaryRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      canExportBeneficiaries: userHasPermission(
        beneficiaryRoles.permissions.idPermissionExportListBeneficiaries,
        userPermissions,
      ),
    };
  }, [userPermissions]);

  /**
   * Busca colunas selecionadas na ordem em que sao apresentadas ao usuario
   * @returns Colunas selecionadas na ordem em que sao apresentadas ao usuario
   */
  function getOrderedSelectedColumns() {
    const columnsOrder = gridRef.current?.columnOrder;

    if (columnsOrder && columnsOrder.length) {
      // Ordena itens de acordo com o array columnsOrder. Itens que não existam
      // em columnsOrder são colocados no final do array
      const sortedSelectedColumns = sortBy(selectedColumns, item => {
        const index = columnsOrder.indexOf(item.field);
        return index === -1 ? Infinity : index;
      });

      return sortedSelectedColumns.map(col => col.field);
    }

    // Se não houver informação de ordenação das colunas, retorna colunas
    // selecionadas na ordem default
    return selectedColumns.map(col => col.field);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  const beneficiaryStatus = (rowData: IBeneficiaryData) => {
    return <span>{rowData.active ? 'Active' : 'Inactive'}</span>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'active':
        return beneficiaryStatus;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'name':
      case 'email':
      case 'description':
        return { overflow: 'hidden', width: '260px' };
      default:
        return { overflow: 'hidden', width: '140px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = gridColumnsData.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do beneficiary clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/list/beneficiaries/${e.data.idBeneficiary}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          first: event.first,
          rows: event.rows,
          page: event.page ?? current.page,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
      gridRef.current?.resetScroll();
    }
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          multiSortMeta: event.multiSortMeta,
          sortField: event.sortField,
          sortOrder: event.sortOrder,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
    }
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Evita que o filtro seja atualizado na montagem do componente
    if (isMounted.current) {
      const { first, page } = pagination.initialLazyParams;

      const delayDebounceFn = setTimeout(() => {
        setLazyParams(current => {
          const newLazyParams = {
            ...current,
            first,
            page,
            globalFilter,
          };

          saveLazyParams(newLazyParams);

          return newLazyParams;
        });
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }

    isMounted.current = true;
    return undefined;
  }, [globalFilter]);

  /**
   * Busca Beneficiaries para exportar para XLSX
   */
  const [exportBeneficiaries, { loading: exportLoading }] = useLazyQuery(
    exportBeneficiariesQuery,
    {
      onCompleted: response => {
        if (response.exportBeneficiaries) {
          window.open(response.exportBeneficiaries, '_blank');
        } else {
          showError({
            summary: 'Error while exporting Beneficiaries',
          });
        }
      },

      onError: error => {
        showError({
          summary: 'Error while exporting Beneficiaries',
          detail: error.message,
        });
      },
    },
  );

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(gridColumnsData).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader
        title="List of Beneficiaries"
        fixedStickyButtons={fixedStickyButtons}
      >
        {/* Botao para criar Beneficiary */}

        {userHasPermission(
          idPermissionCreateBeneficiary,
          userPermissionsBeneficiary.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Beneficiary"
            onClick={() => history.push('/list/beneficiaries/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={gridColumnsData}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            setLazyParams(current => {
              const newLazyParams = {
                ...current,
                ...e,
                first: pagination.initialLazyParams.first,
                page: pagination.initialLazyParams.page,
                rows: pagination.initialLazyParams.rows,
              };

              saveLazyParams(newLazyParams);

              return newLazyParams;
            })
          }
          onClear={() =>
            setLazyParams({
              ...pagination.initialLazyParams,
              globalSearch: globalFilter,
            })
          }
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={gridColumnsData.filter(
            column => column.field && column.header,
          )}
          onChange={onColumnToggle}
        />

        {permissions.canExportBeneficiaries && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={exportLoading}
            onClick={() => {
              exportBeneficiaries({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    columnsToExport: getOrderedSelectedColumns(),
                    ...beneficiariesCommonVariables,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an Item"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="Beneficiaries"
        lazy
        totalRecords={
          !beneficiariesData ? 0 : beneficiariesData.listAllBeneficiaries.items
        }
        value={
          beneficiariesData && beneficiariesData.listAllBeneficiaries
            ? beneficiariesData.listAllBeneficiaries.data
            : []
        }
        globalFilter={globalFilter}
        emptyMessage="No Beneficiaries found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedBeneficiaries}
        onSelectionChange={e => setSelectedBeneficieries(e.value)}
      >
        {dynamicColumns}
      </Grid>
      {beneficiariesLoading && <Loading />}
    </div>
  );
};
export default Beneficiaries;
