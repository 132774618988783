import { FormHandles } from '@unform/core';
import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { QueryLazyOptions, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import { asyncSelectLoadSuppliers } from '../../../../../shared/querys/supplier';
import { IPrc, IPrcQuotation } from '../../interfaces';
import FormCheckbox from '../../../../../components/FormCheckbox';
import FormInputTextArea from '../../../../../components/FormInputTextArea';
import { useRefHook } from '../../../../../hooks/useRefHook';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import FormDatePicker from '../../../../../components/FormDatePicker';
import { FileType } from '../../../../../shared/enums/fileType';
import { createPrcQuotationQuery, updatePrcQuotationQuery } from './queries';
import Button from '../../../../../components/Button';
import FileUpload from '../../../../../components/FileUpload';
import FormInputNumber from '../../../../../components/FormInputNumber';

export interface IPrcQuotationRef {
  toggleModal(data?: IPrcQuotation): void;
}

interface IPrcQuotationProps {
  ref: Ref<IPrcQuotationRef>;
  /**
   * Estado de PRC
   */
  prc: IPrc;

  /**
   * Define estado de PRC
   */
  setPrc: React.Dispatch<React.SetStateAction<IPrc>>;

  /**
   * Busca dados da PRC
   */
  loadPrcData: (
    options?:
      | QueryLazyOptions<{
          idPrc: number;
        }>
      | undefined,
  ) => void;

  /**
   * Se usuario possui roles especificas da logica
   */
  userHasSpecificProfiles: boolean;
}

const PrcQuotation: React.FC<IPrcQuotationProps> = forwardRef(
  ({ prc, setPrc, loadPrcData, userHasSpecificProfiles }, ref) => {
    const formRef = useRef<FormHandles>(null);
    const [createPrcQuotationMutation, { loading: createLoading }] =
      useMutation(createPrcQuotationQuery);
    const [updatePrcQuotationMutation, { loading: updateLoading }] =
      useMutation(updatePrcQuotationQuery);
    const loading = createLoading || updateLoading;

    const [displayModal, setDisplayModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | undefined>();
    const [initialFormData, setInitialFormData] = useState<IPrcQuotation>();

    const uploadFileButtonDisabled = !!uploadedFile || loading;

    const { showSuccess, showError } = useRefHook();

    function closeModal() {
      setDisplayModal(false);
      setUploadedFile(undefined);
      setInitialFormData(undefined);
    }

    useImperativeHandle(ref, () => ({
      toggleModal: (data?: IPrcQuotation) => {
        if (displayModal) {
          closeModal();
        } else {
          setDisplayModal(true);
          if (data) {
            setInitialFormData(data);
          }
        }
      },
    }));

    async function handlePrcQuotationSave(formData: any) {
      try {
        // Esvazia possiveis erros ja existentes no formulario
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idSupplier: Yup.number().nullable().required('Required Field'),
        });

        // Efetua validacao
        await schema.validate(formData, { abortEarly: false });

        // Variaveis comuns a ambas as mutations
        const commonVariables = {
          idSupplier: formData.idSupplier,
          validDate: formData.validDate,
          bestPrice: formData.bestPrice,
          comment: formData.comment,
          uploadedFile,
        };

        if (!initialFormData) {
          await createPrcQuotationMutation({
            variables: {
              data: {
                idPrc: prc.idPrc,
                ...commonVariables,
              },
            },
          });
        } else {
          await updatePrcQuotationMutation({
            variables: {
              data: {
                idPrcQuotation: formData.idPrcQuotation,
                ...commonVariables,
              },
            },
          });
        }

        showSuccess({
          summary: `PRC quotation ${initialFormData ? 'updated' : 'created'}`,
        });

        setPrc({} as IPrc);
        loadPrcData();
      } catch (error) {
        // Verifica se sao erros de validacao
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          showError({
            summary: `Error while ${
              initialFormData ? 'updating' : 'creating'
            } PRC quotation`,
            detail: error.message,
          });
        }
      }
    }

    const dialogFooter = () => {
      return (
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
            loading={loading}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => closeModal()}
            className="p-button-danger"
            severity="danger"
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <Dialog
        header="PRC Quotation Item"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
        footer={dialogFooter()}
      >
        <Form
          className="formgrid grid"
          ref={formRef}
          onSubmit={handlePrcQuotationSave}
          initialData={initialFormData}
        >
          <FormInputNumber name="idPrcQuotation" label="" hidden />
          <FormAsyncSelect
            className="field col-12"
            name="idSupplier"
            label="Supplier"
            required
            loadOptions={asyncSelectLoadSuppliers}
            getOptionLabel={option => option.sunNumber}
            getOptionValue={option => option.idSupplier}
            additional={{
              page: 1,
            }}
            noOptionsMessage={() => 'No suppliers found'}
            initialValue={initialFormData?.idSupplier2}
          />

          <FormDatePicker
            className="field col-6"
            name="validDate"
            label="Valid Date"
          />
          {userHasSpecificProfiles && (
            <FormCheckbox
              className="flex align-self-center field col-6"
              name="bestPrice"
              label="Best Price"
            />
          )}

          {userHasSpecificProfiles && (
            <>
              <FormInputTextArea
                className="field col-12"
                name="comment"
                label="Comment"
                rows={6}
              />

              <FileUpload
                mode="basic"
                className="col-12"
                accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.ALL_IMAGES},${FileType.PDF}`}
                auto
                chooseLabel={uploadedFile ? 'File uploaded' : 'Upload a file'}
                disabled={uploadFileButtonDisabled}
                onConfirm={e => setUploadedFile(e[0].serverName)}
              />
            </>
          )}
        </Form>
      </Dialog>
    );
  },
);

export default PrcQuotation;
