import { gql } from '@apollo/client';

/**
 * Query para listar confirm order files de uma SAT
 */
export const listSatConfirmOrderFilesQuery = gql`
  query ListSatConfirmOrderFilesBySatId(
    $data: ListSatConfirmOrderFilesBySatIdInput!
  ) {
    listSatConfirmOrderFilesBySatId(data: $data) {
      idSatAttachment
      idAttachment2 {
        idAttachment
        url
      }
    }
  }
`;
