// eslint-disable-next-line no-shadow
export enum DivergentForeignTradesReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export type DivergentData = {
  idSatForeignTrade: number;
  satForeignTradeNumber: string;
  realEtd?: string;
  realEta?: string;
  realArrivalAtClient?: string;
  idLoadingPort?: number;
  loadingPortName?: string;
  idPort?: number;
  portName?: string;
  bookingConfirmed?: string;
  shipmentConfirmed?: string;
  freightForwarderOrigin?: number;
  freightForwarderOriginName?: string;
  freightForwarderDestination?: number;
  freightForwarderDestinationName?: string;
};

export type DivergentForeignTradesResponse = {
  idForeignTradeSent: number;
  foreignTradeSentNumber: string;
  divergentData: DivergentData[];
};

export type DivergentForeignTradesReducer = {
  displayDialog: boolean;
  divergentForeignTrades: DivergentForeignTradesResponse[];
};

export type DivergentForeignTradesReducerAction = {
  type: DivergentForeignTradesReducerActionKind;
  payload?: {
    divergentForeignTrades?: DivergentForeignTradesResponse[];
  };
};

export type DivergentForeignTradesGetDataMode = 'addGroup' | 'saveForeignTrade';
