import { Dialog } from 'primereact/dialog';
import React, { Ref, useImperativeHandle, useState } from 'react';

import { Container, ResponseItem } from './styles';

export interface IMutationResponse {
  moduleChanged: string;
  warnings?: string[];
  errors?: string[];
}

export interface IMutationResponseSummaryRef {
  toggleDisplaySummary(): void;
}

interface IMutationResponseSummary {
  ref: Ref<IMutationResponseSummaryRef>;
  header: string;
  data: IMutationResponse[];
}

const MutationResponseSummary: React.FC<IMutationResponseSummary> =
  React.forwardRef(({ header, data }, ref) => {
    const [displayDialog, setDisplayDialog] = useState(false);

    useImperativeHandle(ref, () => ({
      toggleDisplaySummary: () => {
        setDisplayDialog(!displayDialog);
      },
    }));

    return (
      <Dialog
        header={header}
        visible={displayDialog}
        style={{ maxWidth: '1000px' }}
        modal
        onHide={() => setDisplayDialog(false)}
      >
        {data.length && (
          <Container>
            {data.map(response => {
              return (
                <ResponseItem key={response.moduleChanged}>
                  <h2>{response.moduleChanged}</h2>
                  {!!response.warnings?.length && (
                    <div className="response-content">
                      <h3 className="warning-title">
                        <i className="pi pi-exclamation-circle" />
                        Warnings
                      </h3>
                      {response.warnings.map(warning => {
                        return <p key={warning}>{warning}</p>;
                      })}
                    </div>
                  )}
                  {!!response.errors?.length && (
                    <div className="response-content">
                      <h3 className="error-title">
                        <i className="pi pi-times-circle" />
                        Errors
                      </h3>
                      {response.errors.map(error => {
                        return <p key={error}>{error}</p>;
                      })}
                    </div>
                  )}
                </ResponseItem>
              );
            })}
          </Container>
        )}
      </Dialog>
    );
  });

export default MutationResponseSummary;
