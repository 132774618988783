import { ProductPackageDescription } from '../../../interfaces';

// eslint-disable-next-line no-shadow
export enum ManagePackageDescriptionReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export type ManagePackageDescriptionReducer = {
  displayDialog: boolean;
  packageDescription?: ProductPackageDescription;
};

export type ManagePackageDescriptionReducerAction = {
  type: ManagePackageDescriptionReducerActionKind;
  payload?: {
    packageDescription?: ProductPackageDescription;
  };
};
