import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsCommonWithSatFieldPermissions,
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';

interface IPurchaseProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  sat: ISat;
  fieldsPermissions: FinancialFieldsPermissions;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
  userCanSeePurchaseBlock: boolean;
}

const Purchase: React.FC<IPurchaseProps> = ({
  satForeignTradeSupplier,
  sat,
  fieldsPermissions,
  commonWithSatFieldsPermissions,
  userCanSeePurchaseBlock,
}) => {
  return userCanSeePurchaseBlock ? (
    <div className="grid">
      <FormTitle className="col-12 purchase-form-title" name="Purchase" />

      {fieldsPermissions.idStatusPurchasePayment.view && (
        <ReadOnlyInput
          className="col-4"
          label="Status Purchase Payment"
          value={satForeignTradeSupplier.idStatusPurchasePayment2?.description}
        />
      )}

      {fieldsPermissions.sunNumber.view && (
        <ReadOnlyInput
          className="col-4"
          label="SUN Number"
          value={satForeignTradeSupplier.idSupplier2?.sunNumber}
        />
      )}

      {fieldsPermissions.supplierName.view && (
        <ReadOnlyInput
          className="col-4"
          label="Supplier Name"
          value={satForeignTradeSupplier.idSupplier2?.name}
        />
      )}

      {commonWithSatFieldsPermissions.purchaseIncotermDescription.view && (
        <ReadOnlyInput
          className="col-4"
          label="Purchase Incoterm"
          value={sat.idPurchaseIncoterm2?.description}
        />
      )}

      {commonWithSatFieldsPermissions.mainProduct.view && (
        <ReadOnlyInput
          className="col-4"
          label="Main Product"
          value={sat.mainProduct}
        />
      )}

      {commonWithSatFieldsPermissions.countryOriginName.view && (
        <ReadOnlyInput
          className="col-4"
          label="Country Of Origin"
          value={sat.idCountryOrigin2?.name}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default Purchase;
