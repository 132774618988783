import { gql } from '@apollo/client';

export const createSatOtherExpenseQuery = gql`
  mutation CreateSatOtherExpense($data: CreateSatOtherExpenseInput!) {
    createSatOtherExpense(data: $data) {
      idSatOtherExpense
    }
  }
`;

export const updateSatOtherExpenseQuery = gql`
  mutation UpdateSatOtherExpense($data: UpdateSatOtherExpenseInput!) {
    updateSatOtherExpense(data: $data) {
      idSatOtherExpense
    }
  }
`;
