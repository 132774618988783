/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { TreeSelect, TreeSelectProps } from 'primereact/treeselect';

import { Container } from './styles';

interface CheckboxMenuProps extends TreeSelectProps {
  label: string;
  className?: string;
  getSelectedItemsIcon?: string;
  getSelectedOptionsLoading?: boolean;
  onGetSelectedItems?: (items: string[]) => void;
}

const CheckboxMenu: React.FC<CheckboxMenuProps> = ({
  label,
  className,
  getSelectedItemsIcon,
  getSelectedOptionsLoading,
  onGetSelectedItems,
  ...rest
}) => {
  const [selectedItems, setSelectedItems] = useState<any>();

  function handleGetSelectedItems(): string[] {
    if (!selectedItems) return [];

    const items = Object.keys(selectedItems).filter(
      key => selectedItems[key].checked,
    );

    return items;
  }

  return (
    <Container className={`s-checkbox-menu ${className ?? ''}`}>
      <TreeSelect
        valueTemplate={label}
        placeholder={label}
        value={selectedItems}
        {...rest}
        onChange={e => setSelectedItems(e.value)}
        className="p-button s-checkbox-menu-panel-button"
        selectionMode="checkbox"
      />
      <button
        className="p-button s-checkbox-menu-get-selected-button"
        type="button"
        onClick={() => {
          const items = handleGetSelectedItems();
          if (onGetSelectedItems) onGetSelectedItems(items);
        }}
        disabled={
          getSelectedOptionsLoading ||
          !selectedItems ||
          !Object.keys(selectedItems).length
        }
      >
        {getSelectedOptionsLoading ? (
          <i className="pi pi-spin pi-spinner" />
        ) : (
          <i className={`${getSelectedItemsIcon || 'pi pi-download'}`} />
        )}
      </button>
    </Container>
  );
};

export default CheckboxMenu;
