import React, { useEffect } from 'react';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import Input from '../../../../../../components/FormInput';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { SellerOtherExpensesTotalReducerObject } from '../interfaces';

import { Container, Row } from './styles';
import { divide } from '../../../../../../utils/mathUtil';

interface IEstimatedReceiveProps {
  sat: ISat;
  itemIndex: number;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanSeeEstimatedReceive: boolean;
  estimatedTotalAdvance?: number;
  setEstimatedTotalAdvance: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  estimatedTotalBalance?: number;
  setEstimatedTotalBalance: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  sellerOtherExpensesTotal?: SellerOtherExpensesTotalReducerObject;
}

const EstimatedReceive: React.FC<IEstimatedReceiveProps> = ({
  sat,
  itemIndex,
  satForeignTradeSupplier,
  fieldsPermissions,
  userCanSeeEstimatedReceive,
  estimatedTotalAdvance,
  setEstimatedTotalAdvance,
  estimatedTotalBalance,
  setEstimatedTotalBalance,
  sellerOtherExpensesTotal,
}) => {
  useEffect(() => {
    const totalSunCi = satForeignTradeSupplier.totalSunCiShipment;
    const totalSunPi = satForeignTradeSupplier.totalSunPiShipment;

    function setEstimatedTotalAdvanceValue() {
      const estimatedValue =
        (totalSunCi || totalSunPi) * (divide(sat.paymentTermAdvance, 100) || 0);

      setEstimatedTotalAdvance(estimatedValue);
    }

    function setEstimatedTotalBalanceValue() {
      const totalCreditToClient =
        sellerOtherExpensesTotal?.totalCreditToClient || 0;
      const totalForApportionment =
        sellerOtherExpensesTotal?.totalForApportionment || 0;

      const totalBalance =
        (totalSunCi || totalSunPi) +
        totalForApportionment -
        totalCreditToClient -
        (estimatedTotalAdvance || 0);

      setEstimatedTotalBalance(totalBalance);
    }

    setEstimatedTotalAdvanceValue();
    setEstimatedTotalBalanceValue();
  }, [
    estimatedTotalAdvance,
    sat.paymentTermAdvance,
    satForeignTradeSupplier.totalSunCiShipment,
    satForeignTradeSupplier.totalSunPiShipment,
    sellerOtherExpensesTotal?.totalCreditToClient,
    sellerOtherExpensesTotal?.totalForApportionment,
    setEstimatedTotalAdvance,
    setEstimatedTotalBalance,
  ]);

  return userCanSeeEstimatedReceive ? (
    <Container className="grid">
      <FormTitle className="col-12" name="Estimated Receive" />
      <Row className="flex flex-wrap w-full">
        {fieldsPermissions.estimatedReceiveAdvance.view && (
          <ReadOnlyInput
            className="col-12 md:col-3"
            label="Estimated Advanced (%)"
            value={sat.paymentTermAdvance ?? 0}
            suffix="%"
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedReceiveTotalAdvance.view && (
          <ReadOnlyInput
            className="col-12 md:col-3"
            label="Estimated Total Advance"
            value={estimatedTotalAdvance}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedReceiveAdvanceRequest.view && (
          <Input
            className="col-12 md:col-3"
            name={`data[${itemIndex}].sellerEstimatedAdvanceRequest`}
            label="Estimated Advance Payment Request Date"
            disabled={!fieldsPermissions.estimatedReceiveAdvanceRequest.edit}
            type="date"
          />
        )}
      </Row>
      <Row className="flex flex-wrap w-full">
        {fieldsPermissions.estimatedReceiveBalance.view && (
          <ReadOnlyInput
            className="col-12 md:col-3"
            label="Estimated Balance (%)"
            value={sat.paymentTermBalance}
            suffix="%"
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedReceiveTotalBalance.view && (
          <ReadOnlyInput
            className="col-12 md:col-3"
            label="Estimated Total Balance"
            value={estimatedTotalBalance}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
          />
        )}
        {fieldsPermissions.estimatedReceiveBalanceRequest.view && (
          <Input
            className="col-12 md:col-3"
            name={`data[${itemIndex}].sellerEstimatedBalanceRequest`}
            label="Estimated Balance Payment Request Date"
            disabled={!fieldsPermissions.estimatedReceiveBalanceRequest.edit}
            type="date"
          />
        )}
        {fieldsPermissions.estimatedReceiveTermCondition.view && (
          <FormAsyncSelect
            className="col-12 md:col-3"
            name={`data[${itemIndex}].sellerEstimatedTermCondition`}
            label="Payment Term Condition"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            initialValue={satForeignTradeSupplier.sellerEstimatedTermCondition2}
            additional={{ id: 13 }}
            disabled={!fieldsPermissions.estimatedReceiveTermCondition.edit}
          />
        )}
      </Row>
    </Container>
  ) : (
    <div />
  );
};

export default EstimatedReceive;
