import generateRandomString from '../../../../utils/generateRandomString';
import { ISatCtnr } from '../interfaces';
import {
  ISatGeneralInformationReducer,
  ISatGeneralInformationReducerAction,
  SatGeneralInformationActionKind,
} from './interfaces';

export const satGeneralInformationReducerInitialState: ISatGeneralInformationReducer =
  {
    satCtnrs: [],
    satCtnrsToUpdate: [],
    satCtnrsToRemove: [],
  };

export const satGeneralInformationReducer = (
  state: ISatGeneralInformationReducer,
  action: ISatGeneralInformationReducerAction,
): ISatGeneralInformationReducer => {
  switch (action.type) {
    case SatGeneralInformationActionKind.ADD_CTNR:
      return {
        ...state,
        satCtnrs: [
          ...state.satCtnrs,
          { idSatCtnr: generateRandomString(4) } as ISatCtnr,
        ],
      };
    case SatGeneralInformationActionKind.REMOVE_CTNR:
      if (!action.payload) return state;
      {
        const { idCtnr } = action.payload;
        const filteredCtnrState = state.satCtnrs.filter(
          ctnr => ctnr.idSatCtnr !== idCtnr,
        );

        const ctnrIsOnDatabase = typeof idCtnr === 'number';

        if (ctnrIsOnDatabase) {
          const filteredCtnrsToUpdateState = state.satCtnrsToUpdate.filter(
            idCtnrToUpdate => idCtnrToUpdate !== idCtnr,
          );

          return {
            ...state,
            satCtnrs: filteredCtnrState,
            satCtnrsToRemove: [...state.satCtnrsToRemove, idCtnr],
            satCtnrsToUpdate: filteredCtnrsToUpdateState,
          };
        }

        return { ...state, satCtnrs: filteredCtnrState };
      }
    case SatGeneralInformationActionKind.SET_CHANGED_CNTR: {
      if (!action.payload) return state;

      const { idCtnr } = action.payload;
      const ctnrIsOnDatabase = typeof idCtnr === 'number';

      if (ctnrIsOnDatabase) {
        const itemIsAlreadyToBeUpdated = state.satCtnrsToUpdate.find(
          ctnrToUpdate => ctnrToUpdate === idCtnr,
        );

        if (!itemIsAlreadyToBeUpdated) {
          return {
            ...state,
            satCtnrsToUpdate: [...state.satCtnrsToUpdate, idCtnr],
          };
        }
      }

      return state;
    }
    case SatGeneralInformationActionKind.RESET_CTNRS_STATES: {
      return {
        ...state,
        satCtnrs: action.payload?.satCtnrs ?? [],
        satCtnrsToRemove: [],
        satCtnrsToUpdate: [],
      };
    }
    default:
      throw new Error();
  }
};
