import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import { FiExternalLink, FiTrash2 } from 'react-icons/fi';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import FormAsyncSelect from '../../../../../components/FormAsyncSelect';
import FormTitle from '../../../../../components/FormTitle';
import Grid from '../../../../../components/Grid';
import { GridActions } from '../../../../../components/Grid/styles';
import MainButton from '../../../../../components/MainButton';
import pagination from '../../../../../config/pagination';
import { useRefHook } from '../../../../../hooks/useRefHook';
import client from '../../../../../services/apollo/client';
import ToastLife from '../../../../../shared/enums/toastLife';
import { shouldDisableField } from '../../../../../utils/disableField';
import {
  GeneralInformationFieldsPermissions,
  ISat,
  ISatGroup,
} from '../../interfaces';
import { listAllSatsQuery } from '../queries';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Container } from './styles';
import { SatForeignTrade } from '../../ForeignTrade/interfaces';
import { convertDateToUTC } from '../../../../../utils/dateUtil';
import { SatStatus } from '../../../../../components/SatStatusTag';

interface ISatGroupProps {
  sat: ISat;
  isSatCancelled: boolean;
  fieldsPermissions: GeneralInformationFieldsPermissions;
  selected: boolean;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  satRefetch(): Promise<void>;
}

type SatGroupFormData = {
  idNewSat: number;
};

const SatGroup: React.FC<ISatGroupProps> = ({
  sat,
  isSatCancelled,
  fieldsPermissions,
  selected,
  setPageLoading,
  satRefetch,
}) => {
  const { toastRef } = useRefHook();

  const formRef = useRef<FormHandles>(null);

  // Sat Group utilizadas na grid
  const [satGroup, setSatGroup] = useState<ISatGroup>();

  // Dados que vão aparacer na DataTable de Sat Group, podem ser
  // as Foreign Trades das sats do grupo ou as proprias Sats
  const [satGroupTableElements, setSatGroupTableElements] = useState<
    ISat[] | SatForeignTrade[]
  >([]);

  // Sats selecionadas
  const [idSelectedSat, setIdSelectedSat] = useState<number>();

  const listSatGroupWithSatForeignTradesQuery = gql`
    query ListSatGroupWithSatForeignTrades($idSatGroup: Int!) {
      listSatGroupWithSatForeignTrades(idSatGroup: $idSatGroup) {
        idSatGroup
        sats {
          idSat
          satNumber
          satForeignTrades {
            idSatForeignTrade
            idSat
            satForeignTradeNumber
            realInspectionDate
            realEtd
            realEta
            realArrivalAtClient
          }
        }
      }
    }
  `;

  const addSatGroupQuery = gql`
    mutation AddSatGroup($data: AddSatGroupInput!) {
      addSatGroup(data: $data)
    }
  `;

  const removeSatFromGroupQuery = gql`
    mutation RemoveSatFromGroup($idSat: Int!) {
      removeSatFromGroup(idSat: $idSat)
    }
  `;

  const [addSatGroupMutation] = useMutation(addSatGroupQuery);

  const [removeSatFromGroupMutation] = useMutation(removeSatFromGroupQuery);

  const [listSatGroupWithSatForeignTrades, { loading: listSatGroupLoading }] =
    useLazyQuery(listSatGroupWithSatForeignTradesQuery, {
      onCompleted: response => {
        if (response.listSatGroupWithSatForeignTrades) {
          setSatGroup(response.listSatGroupWithSatForeignTrades);
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting SAT Group',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  // Constroi o array de elementos de Sats e ForeignTrades que populam a Grid
  const buildSatGroupDataTable = useCallback((sats: ISat[]) => {
    const response: any[] = [];

    sats.forEach(s => {
      if (s.satForeignTrades?.length) {
        s.satForeignTrades.forEach(foreignTrade => {
          response.push(foreignTrade);
        });
      } else {
        response.push(s);
      }
    });

    setSatGroupTableElements(response);
  }, []);

  const handleAddSatGroup = useCallback(
    async (formData: SatGroupFormData) => {
      setPageLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          idNewSat: Yup.number().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        await addSatGroupMutation({
          variables: {
            data: {
              idSatThatIsMemberOfTheGroup: sat.idSat,
              idSatThatWillBeAddedToTheGroup: formData.idNewSat,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `SAT added to Group`,
          life: ToastLife.SUCCESS,
        });

        formRef.current?.clearField('idNewSat');

        if (!sat.idSatGroup2) {
          await satRefetch();
        } else {
          listSatGroupWithSatForeignTrades({
            variables: {
              idSatGroup: sat.idSatGroup2?.idSatGroup,
            },
          });
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while adding SAT to Group`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      addSatGroupMutation,
      listSatGroupWithSatForeignTrades,
      sat,
      setPageLoading,
      satRefetch,
      toastRef,
      formRef,
    ],
  );

  const handleRemoveSatFromGroup = async (idSat: number) => {
    setPageLoading(true);
    setIdSelectedSat(idSat);

    try {
      await removeSatFromGroupMutation({
        variables: {
          idSat,
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'SAT removed from Group',
        life: ToastLife.SUCCESS,
      });

      if (idSat === sat.idSat) {
        await satRefetch();
        setSatGroup(undefined);
      } else {
        listSatGroupWithSatForeignTrades({
          variables: {
            idSatGroup: sat.idSatGroup2?.idSatGroup,
          },
        });
        await satRefetch();
      }
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while removing SAT from Group',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setIdSelectedSat(undefined);
      setPageLoading(false);
    }
  };

  const loadSatsOptions: LoadOptions<
    any,
    GroupBase<any>,
    { page: any }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: listAllSatsQuery,
      variables: {
        listAllSatsInput: {
          pagination: {
            _page: pageData?.page,
            _limit: pagination.itemsPerPage,
          },
          satNumber: search,
          isNotPartOfASatGroup: true,
          client: sat.idClient2?.name,
        },
      },
    });

    const filteredOptions = res.data.listAllSats.data.filter(
      (opc: any) => opc.satNumber !== sat.satNumber,
    );

    const quantity = res.data.listAllSats.items;

    const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  const columnActions = (rowData: ISat) => {
    const satBeingRemoved = rowData.idSat === idSelectedSat;

    const canRemoveSatGroup =
      sat.status !== SatStatus.CANCELED && sat.status !== SatStatus.STAND_BY;

    return (
      <>
        <GridActions>
          {canRemoveSatGroup && (
            <button
              type="button"
              className="trash-button"
              disabled={satBeingRemoved}
              onClick={() =>
                confirmDialog({
                  message: `Remove ${
                    rowData.satForeignTradeNumber ?? rowData.satNumber
                  } from group?`,
                  header: 'Delete Confirmation',
                  icon: 'pi pi-info-circle',
                  acceptClassName: 'p-button-danger',
                  accept: () => handleRemoveSatFromGroup(rowData.idSat),
                })
              }
            >
              {satBeingRemoved ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: '1.43rem' }}
                />
              ) : (
                <FiTrash2 size={20} />
              )}
            </button>
          )}
        </GridActions>
      </>
    );
  };

  /**
   * Retorna coluna com link para SAT/Shipment
   * @param rowData Dados da Linha
   * @returns Coluna com link para General Infos ou para Foreign Trade
   */
  const parseSatShipmentColumn = (rowData: any) => {
    return (
      <Link
        to={
          rowData.satForeignTradeNumber
            ? `/commercial/sats/${rowData.idSat}?tab=foreignTrade`
            : `/commercial/sats/${rowData.idSat}`
        }
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.satForeignTradeNumber ?? rowData.satNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  };

  // Busca SAT Group quando estiver na aba de General infos e satGroup undefined
  useEffect(() => {
    if (selected && sat.idSatGroup2 && !satGroup) {
      listSatGroupWithSatForeignTrades({
        variables: {
          idSatGroup: sat.idSatGroup2?.idSatGroup,
        },
      });
    }
  }, [listSatGroupWithSatForeignTrades, satGroup, selected, sat.idSatGroup2]);

  useEffect(() => {
    if (satGroup) {
      buildSatGroupDataTable(satGroup.sats);
    }
  }, [satGroup, buildSatGroupDataTable]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleAddSatGroup}>
        <FormTitle className="col-12" name="SAT Group" />
        <div className="grid group">
          <FormAsyncSelect
            name="idNewSat"
            className="col-6"
            label="SAT Number"
            loadOptions={loadSatsOptions}
            getOptionLabel={(option: any) => option.satNumber}
            getOptionValue={(option: any) => option.idSat}
            readOnly={
              shouldDisableField(fieldsPermissions.satGroup, sat.idSat) ||
              isSatCancelled
            }
          />

          <MainButton
            className="mainButton"
            type="button"
            label="Add"
            onClick={() => {
              formRef.current?.submitForm();
            }}
            disabled={isSatCancelled}
          />
        </div>

        {sat.idSatGroup2 && (
          <Grid
            className="grid"
            value={satGroupTableElements}
            loading={listSatGroupLoading}
            emptyMessage="No Sat Groups found"
            sortOrder={1}
            paginator={false}
          >
            <Column
              field="actions"
              headerStyle={{ width: '8rem' }}
              columnKey="actions"
              body={columnActions}
            />

            <Column
              field="satNumber"
              header="SAT/Shipment"
              body={parseSatShipmentColumn}
            />

            <Column
              field="realInspectionDate"
              columnKey="realInspectionDate"
              header="Real Inspection"
              body={rowData => convertDateToUTC(rowData.realInspectionDate)}
            />

            <Column
              field="realEtd"
              columnKey="realEtd"
              header="Real ETD"
              body={rowData => convertDateToUTC(rowData.realEtd)}
            />

            <Column
              field="realEta"
              columnKey="realEta"
              header="Real ETA"
              body={rowData => convertDateToUTC(rowData.realEta)}
            />

            <Column
              field="realArrivalAtClient"
              columnKey="realArrivalAtClient"
              header="Real Arrival at Client"
              body={rowData => convertDateToUTC(rowData.realArrivalAtClient)}
            />
          </Grid>
        )}
      </Form>
    </Container>
  );
};

export default SatGroup;
