import {
  gql,
  OperationVariables,
  QueryLazyOptions,
  useMutation,
} from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import React, { useRef } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { FiDownload, FiEdit2, FiTrash2 } from 'react-icons/fi';
import Button from '../../../../components/Button';
import FormRow from '../../../../components/FormRow';
import FormTitle from '../../../../components/FormTitle';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import TransparentButton from '../../../../components/TransparentButton';
import { useRefHook } from '../../../../hooks/useRefHook';
import { PrcFileType, PrcStatus } from '../../../../shared/enums/domains';
import { FileType } from '../../../../shared/enums/fileType';
import ToastLife from '../../../../shared/enums/toastLife';
import changeStateProperty from '../../../../utils/changeStateProperty';
import { parseSunColumn } from '../../../../utils/gridColumnsParse';

import { IPrc, IPrcQuotation } from '../interfaces';
import PrcQuotation, { IPrcQuotationRef } from './PrcQuotation';

import { Container, GridActions } from './styles';
import FilesUpload, { IFilesUploadRef } from '../PrcUploadFiles';
import { IPrcFileLogRef } from '../PrcFileLog/interfaces';
import PrcFileLog from '../PrcFileLog';
import FileUpload from '../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';

interface IPrcQuotationProps extends PageTabContainerProps {
  /**
   * Estado de PRC
   */
  prc: IPrc;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFile: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * ID do usuario logado
   */
  idUser: number;

  /**
   * Funcao de upload de arquivos
   */
  handleFileUpload: (
    event: FileUploadResponse[],
    idType: number,
    idPrcQuotation?: number,
  ) => void;

  /**
   * Query para buscar template de arquivo
   */
  getFileQuery: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  getFileLoading: boolean;

  /**
   * Define estado de PRC
   */
  setPrc: React.Dispatch<React.SetStateAction<IPrc>>;

  /**
   * Define estado de loading
   */
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Busca dados da PRC
   */
  loadPrcData: (
    options?:
      | QueryLazyOptions<{
          idPrc: number;
        }>
      | undefined,
  ) => void;

  /**
   * Se usuario possui roles especificas da logica
   */
  userHasSpecificProfiles: boolean;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFileTemplate: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Indicador de loading para download de file template
   */
  downloadFileTemplateLoading: boolean;
}

const PrcQuotations: React.FC<IPrcQuotationProps> = ({
  selected,
  prc,
  handleDownloadFile,
  idUser,
  handleFileUpload,
  getFileQuery,
  getFileLoading,
  setPrc,
  setLoading,
  loadPrcData,
  userHasSpecificProfiles,
  handleDownloadFileTemplate,
  downloadFileTemplateLoading,
}) => {
  const { toastRef } = useRefHook();
  const prcQuotationRef = useRef<IPrcQuotationRef>(null);
  const prcFileLogRef = useRef<IPrcFileLogRef>(null);

  // Array de PRC Quotations
  const prcQuotations = prc.prcFileLogs?.filter(
    item => item.idType === PrcFileType.PRC_QUOTATION,
  );

  // Array de PRC Compare
  const prcCompare = prc.prcFileLogs?.filter(
    item => item.idType === PrcFileType.PRC_COMPARE,
  );

  const prcQuotationBestPrice = prc.prcQuotations?.find(
    prcQuotation => prcQuotation.bestPrice,
  );

  // Array de PRC Best Price
  const prcBestPrice = prc.prcFileLogs?.filter(
    item =>
      item.idType === PrcFileType.PRC_BEST_PRICE ||
      item.idPrcQuotation === prcQuotationBestPrice?.idPrcQuotation,
  );

  // Nome do arquivo template de PRC Compare
  const prcCompareFileName = 'PRC_SUN-ASIA_COMPARE.xlsx';

  const compareFilesUploadRef = useRef<IFilesUploadRef>(null);
  const bestPriceFilesUploadRef = useRef<IFilesUploadRef>(null);

  // Mutation para remover PRC Quotation
  const disablePrcQuotationQuery = gql`
    mutation DisablePrcQuotation($idPrcQuotation: Int!) {
      disablePrcQuotation(idPrcQuotation: $idPrcQuotation)
    }
  `;

  // cria metodo para chamar a mutation
  const [disablePrcQuotationMutation] = useMutation(disablePrcQuotationQuery);

  /**
   * Valida se usuario pode executar acoes nas quotations
   * @returns True ou False
   */
  const userCanPerformQuotationActions = () => {
    if (prc.idResponsibleUser !== idUser) return false;
    switch (prc.idStatus) {
      case PrcStatus.AT_PROCUREMENT:
      case PrcStatus.AT_PURCHASE:
        return true;
      default:
        return false;
    }
  };

  /**
   * Define se compare deve ser exibido
   * @returns True ou False
   */
  function shouldShowCompareAndBestPrice() {
    if (prc.idStatus !== PrcStatus.DRAFT && userHasSpecificProfiles) {
      return true;
    }

    return false;
  }

  /**
   * Deleta PRC Quotation
   * @param idPrcQuotation ID de PRC Quotation
   */
  function handleDeleteQuotation(idPrcQuotation: number) {
    confirmDialog({
      message: 'Are you sure you want to delete this item?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setLoading(true);
        try {
          await disablePrcQuotationMutation({
            variables: {
              idPrcQuotation,
            },
          });

          // Filtra PRC quotations
          const filteredItems = prc.prcQuotations?.filter(
            quotation => quotation.idPrcQuotation !== idPrcQuotation,
          );

          // Atualiza estado de PRC
          changeStateProperty('prcQuotations', filteredItems, setPrc);

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Quotation deleted',
            life: ToastLife.ERROR,
          });
        } catch (error) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting PRC Quotation',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  }

  /**
   * Renderiza botoes de acao para grid de PRC Quotation
   * @param rowData Dados da Linha
   * @returns Botoes de acao para grid de PRC Quotation
   */
  function prcQuotationColumnActions(rowData: IPrcQuotation) {
    return (
      <GridActions>
        <button
          type="button"
          onClick={() => prcQuotationRef.current?.toggleModal(rowData)}
        >
          <FiEdit2 size={20} />
        </button>
        <button
          type="button"
          className="trash-button"
          onClick={() => handleDeleteQuotation(rowData.idPrcQuotation)}
        >
          <FiTrash2 size={20} />
        </button>
      </GridActions>
    );
  }

  /**
   * Renderiza botoes de acao para grid de PRC Quotation
   * @param rowData Dados da Linha
   * @returns Botoes de acao para grid de PRC Quotation
   */
  function prcQuotationFileColumn(rowData: IPrcQuotation) {
    if (!rowData.idAttachment2) return <></>;

    return (
      <div className="prc-quotation-file-column">
        <TransparentButton
          type="button"
          onClick={() =>
            handleDownloadFile({
              variables: {
                idPrcFileLog: prc.prcFileLogs?.find(
                  prcFileLog =>
                    prcFileLog.idPrcQuotation === rowData.idPrcQuotation,
                )?.idPrcFileLog,
              },
            })
          }
        >
          <FiDownload size={18} />
        </TransparentButton>
        <FileUpload
          className="grid-file-upload"
          mode="basic"
          auto
          customUpload
          onConfirm={e =>
            handleFileUpload(
              e,
              PrcFileType.PRC_QUOTATION,
              rowData.idPrcQuotation,
            )
          }
          accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.ALL_IMAGES},${FileType.PDF}`}
          chooseOptions={{
            icon: 'pi pi-upload',
            iconOnly: true,
            className: 's-transparent-button',
          }}
        />
        <div className="file-log-div">
          <button
            type="button"
            className="items-log"
            onClick={() =>
              prcFileLogRef.current?.toggleDialog(
                prcQuotations?.filter(
                  quotation =>
                    quotation.idPrcQuotation === rowData.idPrcQuotation,
                ) ?? [],
                'PRC Quotation - Log',
              )
            }
          >
            <AiOutlineUnorderedList size={24} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <PageTabContainer selected={selected}>
      <PrcQuotation
        ref={prcQuotationRef}
        prc={prc}
        setPrc={setPrc}
        loadPrcData={loadPrcData}
        userHasSpecificProfiles={userHasSpecificProfiles}
      />
      {shouldShowCompareAndBestPrice() && (
        <>
          <Container id="prc-quotations-compare">
            <FormTitle name="Compare" />

            <FormRow>
              {prcCompare && prcCompare?.length > 0 && (
                <TransparentButton
                  type="button"
                  onClick={() =>
                    handleDownloadFile({
                      variables: {
                        idPrcFileLog:
                          prcCompare[prcCompare?.length - 1].idPrcFileLog,
                      },
                    })
                  }
                >
                  <FiDownload size={18} className="svg-left" />
                  COMPARE
                </TransparentButton>
              )}

              {prc.idResponsibleUser === idUser &&
                (prc.idStatus === PrcStatus.AT_PROCUREMENT ||
                  prc.idStatus === PrcStatus.AT_PURCHASE ||
                  prc.idStatus === PrcStatus.AT_MANAGEMENT) && (
                  <>
                    <Button
                      type="button"
                      label="Upload"
                      icon="pi pi-upload"
                      onClick={() =>
                        compareFilesUploadRef.current?.toggleModal()
                      }
                    />

                    <FilesUpload
                      idPrc={prc.idPrc}
                      idType={PrcFileType.PRC_COMPARE}
                      ref={compareFilesUploadRef}
                    />

                    <Button
                      type="button"
                      onClick={() =>
                        getFileQuery({
                          variables: {
                            name: prcCompareFileName,
                          },
                        })
                      }
                      label="Download Template"
                      icon={<FiDownload size={17} />}
                      loading={getFileLoading}
                    />
                  </>
                )}

              <button
                type="button"
                className="items-log"
                onClick={() =>
                  prcFileLogRef.current?.toggleDialog(
                    prcCompare ?? [],
                    'PRC Compare - Log',
                  )
                }
              >
                <AiOutlineUnorderedList size={24} />
              </button>
            </FormRow>
          </Container>

          <Container id="prc-quotations-best-price">
            <FormTitle name="Best Price" />

            <FormRow>
              {prcBestPrice && prcBestPrice?.length > 0 && (
                <TransparentButton
                  type="button"
                  onClick={() =>
                    handleDownloadFile({
                      variables: {
                        idPrcFileLog:
                          prcBestPrice[prcBestPrice?.length - 1].idPrcFileLog,
                      },
                    })
                  }
                >
                  <FiDownload size={18} className="svg-left" />
                  BEST PRICE
                </TransparentButton>
              )}

              {prc.idResponsibleUser === idUser &&
                (prc.idStatus === PrcStatus.AT_PROCUREMENT ||
                  prc.idStatus === PrcStatus.AT_PURCHASE) && (
                  <>
                    <Button
                      type="button"
                      label="Upload"
                      icon="pi pi-upload"
                      onClick={() =>
                        bestPriceFilesUploadRef.current?.toggleModal()
                      }
                    />

                    <FilesUpload
                      idPrc={prc.idPrc}
                      idType={PrcFileType.PRC_BEST_PRICE}
                      ref={bestPriceFilesUploadRef}
                    />

                    <Button
                      type="button"
                      onClick={() =>
                        handleDownloadFileTemplate({
                          variables: {
                            idPrc: prc.idPrc,
                            fileType: PrcFileType.PRC_BEST_PRICE,
                          },
                        })
                      }
                      label="Download Template"
                      icon={<FiDownload size={17} />}
                      loading={downloadFileTemplateLoading}
                    />
                  </>
                )}

              <button
                type="button"
                className="items-log"
                onClick={() =>
                  prcFileLogRef.current?.toggleDialog(
                    prcBestPrice ?? [],
                    'PRC Best Price - Log',
                  )
                }
              >
                <AiOutlineUnorderedList size={24} />
              </button>
            </FormRow>
          </Container>
        </>
      )}
      <Container>
        <FormTitle name="PRC Quotations" />

        <DataTable
          value={prc.prcQuotations}
          removableSort
          emptyMessage="No items to show"
        >
          {userCanPerformQuotationActions() && (
            <Column field="actions" body={prcQuotationColumnActions} />
          )}

          {userHasSpecificProfiles && (
            <Column
              field="bestPrice"
              header="Best Price"
              body={e =>
                e.bestPrice && <FaCheckCircle size={20} color="green" />
              }
            />
          )}

          <Column
            field="idSupplier2.sunNumber"
            header="SUN"
            sortable
            body={parseSunColumn}
          />
          {userHasSpecificProfiles && (
            <Column field="idSupplier2.name" header="Supplier" sortable />
          )}

          <Column
            field="validDate"
            header="Valid Date"
            body={e =>
              e.validDate ? new Date(e.validDate).toLocaleString() : ''
            }
            sortable
          />

          {userHasSpecificProfiles && (
            <Column
              field="comment"
              header="Comment"
              sortable
              body={e => <pre>{e.comment}</pre>}
            />
          )}
          {userHasSpecificProfiles && (
            <Column
              field="createdBy2.firstName"
              header="Created By"
              body={e => `${e.createdBy2.firstName} ${e.createdBy2.lastName}`}
              sortable
            />
          )}
          {userHasSpecificProfiles && (
            <Column field="file" header="File" body={prcQuotationFileColumn} />
          )}
        </DataTable>

        {userCanPerformQuotationActions() && (
          <Button
            className="new-quotation-button"
            label="New Quotation"
            type="button"
            onClick={() => prcQuotationRef.current?.toggleModal()}
          />
        )}
      </Container>

      <PrcFileLog ref={prcFileLogRef} />
    </PageTabContainer>
  );
};

export default PrcQuotations;
