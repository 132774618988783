import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  satCurrency: {
    field: 'satCurrency',
    header: 'SAT Currency',
    advancedFilterField: 'idCurrency',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'abbreviation',
      optionValue: 'idCurrency',
      optionData: asyncSelectLoadCurrencies,
      isMulti: true,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  satForeignTradeSupplierNumber: {
    field: 'satForeignTradeSupplierNumber',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  sunNumber: {
    field: 'sunNumber',
    header: 'SUN Number',
    advancedFilterField: 'sunNumber',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  nameSupplier: {
    field: 'nameSupplier',
    header: 'Supplier Name',
    advancedFilterField: 'nameSupplier',
  },
  countryOfOrigin: {
    field: 'countryOfOrigin',
    header: 'Country of Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientOrder: {
    field: 'clientOrder',
    header: 'Client Order',
    advancedFilterField: 'clientOrder',
  },
  statusSat: {
    field: 'statusSat',
    header: 'SAT Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'idStatusPurchasePayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'idStatusSellingPayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  exportStatus: {
    field: 'exportStatus',
    header: 'Export Status',
    advancedFilterField: 'idExportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.EXPORT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  startDate: {
    field: 'startDate',
    header: 'Start Date',
    advancedFilterField: 'startDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  purchaseIncoterm: {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  purchaseTotalCotPi: {
    field: 'purchaseTotalCotPi',
    header: 'Total COT PI',
    advancedFilterField: '',
  },
  purchaseTotalCotCi: {
    field: 'purchaseTotalCotCi',
    header: 'Total COT CI',
    advancedFilterField: '',
  },
  exchangeRate: {
    field: 'exchangeRate',
    header: 'Exchange Rate',
    advancedFilterField: '',
  },
  purchaseTotalConvertedCotPiUsd: {
    field: 'purchaseTotalConvertedCotPiUsd',
    header: 'Total Converted COT PI USD',
    advancedFilterField: '',
  },
  purchaseTotalConvertedCotCiUsd: {
    field: 'purchaseTotalConvertedCotCiUsd',
    header: 'Total Converted COT CI USD',
    advancedFilterField: '',
  },
  estimatedAdvance: {
    field: 'estimatedAdvance',
    header: 'Purchase Estimated Advance',
    advancedFilterField: '',
  },
  estimatedBalance: {
    field: 'estimatedBalance',
    header: 'Purchase Estimated Balance',
    advancedFilterField: '',
  },
  purchaseEstimatedTermCondition: {
    field: 'purchaseEstimatedTermCondition',
    header: 'Purchase Estimated Term Condition',
    advancedFilterField: '',
  },
  purchaseEstimatedTotalAdvance: {
    field: 'purchaseEstimatedTotalAdvance',
    header: 'Purchase Estimated Total Advance',
    advancedFilterField: '',
  },
  estimatedAdvanceRequest: {
    field: 'estimatedAdvanceRequest',
    header: 'Purchase Estimated Advance Request',
    advancedFilterField: 'estimatedAdvanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  purchaseEstimatedTotalBalance: {
    field: 'purchaseEstimatedTotalBalance',
    header: 'Purchase Estimated Total Balance',
    advancedFilterField: '',
  },
  estimatedBalanceRequest: {
    field: 'estimatedBalanceRequest',
    header: 'Purchase Estimated Balance Request',
    advancedFilterField: 'estimatedBalanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  purchaseTotalOtherExpenses: {
    field: 'purchaseTotalOtherExpenses',
    header: 'Purchase Total Other Expenses',
    advancedFilterField: '',
  },
  purchaseTotalOnMargin: {
    field: 'purchaseTotalOnMargin',
    header: 'Purchase Total On Margin',
    advancedFilterField: '',
  },
  purchaseTotalOutOfMargin: {
    field: 'purchaseTotalOutOfMargin',
    header: 'Purchase Total Out Of Margin',
    advancedFilterField: '',
  },
  paidTotalAdvance: {
    field: 'paidTotalAdvance',
    header: 'Paid Total Advance',
    advancedFilterField: '',
  },
  paidTotalRolling: {
    field: 'paidTotalRolling',
    header: 'Paid Total Rolling',
    advancedFilterField: '',
  },
  paidAdvancePaymentDate: {
    field: 'paidAdvancePaymentDate',
    header: 'Paid Advance Date',
    advancedFilterField: 'paidAdvancePaymentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  paidTotalBalance: {
    field: 'paidTotalBalance',
    header: 'Paid Total Balance',
    advancedFilterField: '',
  },
  paidBalancePaymentDate: {
    field: 'paidBalancePaymentDate',
    header: 'Paid Balance Date',
    advancedFilterField: 'paidBalancePaymentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  totalPaid: {
    field: 'totalPaid',
    header: 'Total Paid',
    advancedFilterField: '',
  },
  totalCotRemain: {
    field: 'totalCotRemain',
    header: 'Total COT Remain',
    advancedFilterField: '',
  },
  purchaseDiscountFormSupplier: {
    field: 'purchaseDiscountFormSupplier',
    header: 'Discount From Supplier',
    advancedFilterField: '',
  },
  purchasePayToSupplier: {
    field: 'purchasePayToSupplier',
    header: 'Pay To Supplier',
    advancedFilterField: '',
  },
  sellingIncoterm: {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  sellerTotalSunPi: {
    field: 'sellerTotalSunPi',
    header: 'Total SUN PI',
    advancedFilterField: '',
  },
  sellerTotalSunCi: {
    field: 'sellerTotalSunCi',
    header: 'Total SUN CI',
    advancedFilterField: '',
  },
  sellerTotalConvertedSunPi: {
    field: 'sellerTotalConvertedSunPi',
    header: 'Total Converted SUN PI',
    advancedFilterField: '',
  },
  sellerTotalConvertedSunCi: {
    field: 'sellerTotalConvertedSunCi',
    header: 'Total Converted SUN CI',
    advancedFilterField: '',
  },
  totalWithApportionment: {
    field: 'totalWithApportionment',
    header: 'Total With Apportionment',
    advancedFilterField: '',
  },
  sellerReceiveEstimatedAdvanced: {
    field: 'sellerReceiveEstimatedAdvanced',
    header: 'Seller Estimated Advance',
    advancedFilterField: '',
  },
  sellerReceiveEstimatedBalance: {
    field: 'sellerReceiveEstimatedBalance',
    header: 'Seller Estimated Balance',
    advancedFilterField: '',
  },
  sellerPaymentTermCondition: {
    field: 'sellerPaymentTermCondition',
    header: 'Seller Estimated Term Condition',
    advancedFilterField: '',
  },
  sellerEstimatedTotalAdvance: {
    field: 'sellerEstimatedTotalAdvance',
    header: 'Seller Estimated Total Advance',
    advancedFilterField: '',
  },
  sellerEstimatedAdvanceRequest: {
    field: 'sellerEstimatedAdvanceRequest',
    header: 'Seller Estimated Advance Request',
    advancedFilterField: 'sellerEstimatedAdvanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  sellerEstimatedTotalBalance: {
    field: 'sellerEstimatedTotalBalance',
    header: 'Seller Estimated Total Balance',
    advancedFilterField: '',
  },
  sellerEstimatedBalanceRequest: {
    field: 'sellerEstimatedBalanceRequest',
    header: 'Seller Estimated Balance Request',
    advancedFilterField: 'sellerEstimatedBalanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  sellerTotalOtherExpenses: {
    field: 'sellerTotalOtherExpenses',
    header: 'Seller Total Other Expenses',
    advancedFilterField: '',
  },
  sellerTotalOnMargin: {
    field: 'sellerTotalOnMargin',
    header: 'Seller Total On Margin',
    advancedFilterField: '',
  },
  sellerTotalOutOfMargin: {
    field: 'sellerTotalOutOfMargin',
    header: 'Seller Total Out Of Margin',
    advancedFilterField: '',
  },
  receivedTotalAdvance: {
    field: 'receivedTotalAdvance',
    header: 'Received Total Advance',
    advancedFilterField: '',
  },
  receivedTotalRolling: {
    field: 'receivedTotalRolling',
    header: 'Received Total Rolling',
    advancedFilterField: '',
  },
  receivedAdvanceReceivedDate: {
    field: 'receivedAdvanceReceivedDate',
    header: 'Received Advance Date',
    advancedFilterField: 'receivedAdvanceReceivedDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  receivedTotalBalance: {
    field: 'receivedTotalBalance',
    header: 'Received Total Balance',
    advancedFilterField: '',
  },
  receivedBalanceReceivedDate: {
    field: 'receivedBalanceReceivedDate',
    header: 'Received Balance Date',
    advancedFilterField: 'receivedBalanceReceivedDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  totalReceived: {
    field: 'totalReceived',
    header: 'Total Received',
    advancedFilterField: '',
  },
  totalRemainSun: {
    field: 'totalRemainSun',
    header: 'Total SUN Remain',
    advancedFilterField: '',
  },
  sellerTotalCreditToClient: {
    field: 'sellerTotalCreditToClient',
    header: 'Credit To Client',
    advancedFilterField: '',
  },
  sellerTotalForApportionment: {
    field: 'sellerTotalForApportionment',
    header: 'Total For Apportionment',
    advancedFilterField: '',
  },
  estimatedGrossMargin: {
    field: 'estimatedGrossMargin',
    header: 'Estimated Gross Margin',
    advancedFilterField: '',
  },
  estimatedGrossProfit: {
    field: 'estimatedGrossProfit',
    header: 'Estimated Gross Profit',
    advancedFilterField: '',
  },
  estimatedNetMargin: {
    field: 'estimatedNetMargin',
    header: 'Estimated NET Margin',
    advancedFilterField: '',
  },
  estimatedNetProfit: {
    field: 'estimatedNetProfit',
    header: 'Estimated NET Profit',
    advancedFilterField: '',
  },
  grossMargin: {
    field: 'grossMargin',
    header: 'Gross Margin',
    advancedFilterField: '',
  },
  grossProfit: {
    field: 'grossProfit',
    header: 'Gross Profit',
    advancedFilterField: '',
  },
  netMargin: {
    field: 'netMargin',
    header: 'NET Margin',
    advancedFilterField: '',
  },
  netProfit: {
    field: 'netProfit',
    header: 'NET Profit',
    advancedFilterField: '',
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  purchaseNotes: {
    field: 'purchaseNotes',
    header: 'Purchase Notes',
    advancedFilterField: '',
  },
  sellerNotes: {
    field: 'sellerNotes',
    header: 'Seller Notes',
    advancedFilterField: '',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.satCurrency.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.satForeignTradeSupplierNumber.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.nameSupplier.field,
          gridColumnsData.countryOfOrigin.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.category.field,
          gridColumnsData.exporter.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.statusSat.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.exportStatus.field,
          gridColumnsData.shipmentResume.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.realEta.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.purchaseTotalCotPi.field,
          gridColumnsData.purchaseTotalCotCi.field,
          gridColumnsData.estimatedAdvance.field,
          gridColumnsData.estimatedBalance.field,
          gridColumnsData.purchaseEstimatedTermCondition.field,
          gridColumnsData.purchaseEstimatedTotalAdvance.field,
          gridColumnsData.estimatedAdvanceRequest.field,
          gridColumnsData.estimatedBalanceRequest.field,
          gridColumnsData.purchaseTotalOtherExpenses.field,
          gridColumnsData.paidTotalAdvance.field,
          gridColumnsData.paidTotalRolling.field,
          gridColumnsData.paidAdvancePaymentDate.field,
          gridColumnsData.paidTotalBalance.field,
          gridColumnsData.paidBalancePaymentDate.field,
          gridColumnsData.totalPaid.field,
          gridColumnsData.totalCotRemain.field,
          gridColumnsData.supplierPaymentStatus.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.sellerTotalSunPi.field,
          gridColumnsData.sellerTotalSunCi.field,
          gridColumnsData.totalWithApportionment.field,
          gridColumnsData.sellerReceiveEstimatedAdvanced.field,
          gridColumnsData.sellerReceiveEstimatedBalance.field,
          gridColumnsData.sellerPaymentTermCondition.field,
          gridColumnsData.sellerEstimatedTotalAdvance.field,
          gridColumnsData.sellerEstimatedAdvanceRequest.field,
          gridColumnsData.sellerEstimatedTotalBalance.field,
          gridColumnsData.sellerEstimatedBalanceRequest.field,
          gridColumnsData.sellerTotalOtherExpenses.field,
          gridColumnsData.receivedTotalAdvance.field,
          gridColumnsData.receivedTotalRolling.field,
          gridColumnsData.receivedAdvanceReceivedDate.field,
          gridColumnsData.receivedTotalBalance.field,
          gridColumnsData.receivedBalanceReceivedDate.field,
          gridColumnsData.totalReceived.field,
          gridColumnsData.totalRemainSun.field,
          gridColumnsData.sellerTotalCreditToClient.field,
          gridColumnsData.sellerTotalForApportionment.field,
          gridColumnsData.clientPaymentStatus.field,
          gridColumnsData.estimatedGrossMargin.field,
          gridColumnsData.estimatedGrossProfit.field,
          gridColumnsData.estimatedNetMargin.field,
          gridColumnsData.estimatedNetProfit.field,
          gridColumnsData.grossMargin.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.netMargin.field,
          gridColumnsData.netProfit.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
