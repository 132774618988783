/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import FormInput from '../../../../components/FormInput';
import { Container } from './styles';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import { IPartnerContact, IDepartment } from '../../interfaces';
import { IDomain } from '../../../../services/dataInterface';
import FormDropdown from '../../../../components/FormDropdown';
import FormInputTextArea from '../../../../components/FormInputTextArea';

interface IAddContactProps {
  newContact: IPartnerContact;
  setNewContact: Dispatch<SetStateAction<IPartnerContact>>;
}
const AddContact: React.FC<IAddContactProps> = ({
  setNewContact,
  newContact,
}) => {
  const client = useApolloClient();

  // Query para listar departmentos contato do  parceiro
  const listDepartmentQuery = gql`
    query listAllDepartmentsQuery(
      $listAllDepartmentsInput: ListAllDepartmentsInput!
    ) {
      listAllDepartments(listAllDepartmentsInput: $listAllDepartmentsInput) {
        data {
          idDepartment
          name
        }
        items
      }
    }
  `;

  const {
    loading: departmentLoading,
    data: departmentData,
    error: departmentError,
  } = useQuery(listDepartmentQuery, {
    variables: {
      listAllDepartmentsInput: {
        _page: 1,
        _limit: 25,
      },
    },
  });

  function onDepartmentChange(dept: Record<string, unknown>) {
    const newDept: IDepartment = {
      idDepartment: parseInt(`${dept.idDepartment}`, 10),
      name: `${dept.name}`,
    };

    setNewContact({
      ...newContact,
      idDepartment2: newDept,
    });
  }

  const loadOptions: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query ListDomainsByGroupIdQuery($id: Int!, $pagination: Pagination!) {
          listDomainsByGroupId(id: $id, pagination: $pagination) {
            data {
              idDomain
              description
              versionLock
            }
            items
          }
        }
      `,
      variables: {
        id: 17,
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
      },
    });

    const filteredOptions = res.data.listDomainsByGroupId.data;
    const quantity = res.data.listDomainsByGroupId.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  function onRoleChange(e: IDomain) {
    setNewContact({
      ...newContact,
      idRole2: e,
    });
  }

  return (
    <Container>
      <FormAsyncSelect
        name="role"
        label="Role"
        debounceTimeout={1000}
        loadOptions={loadOptions}
        initialValue={newContact.idRole}
        getOptionLabel={(option: any) => option.description}
        getOptionValue={(option: any) => option.idDomain}
        additional={{
          page: 1,
        }}
        noOptionsMessage={() => 'No roles found'}
        onValueChange={onRoleChange}
      />

      <FormDropdown
        name="department"
        label="Department"
        readOnly={departmentLoading || departmentError !== undefined}
        options={
          departmentLoading || departmentError
            ? undefined
            : departmentData.listAllDepartments.data
        }
        optionLabel="name"
        placeholder={departmentLoading ? 'Loading' : ''}
        initialValue={newContact.idDepartment}
        filter
        filterBy="name"
        onValueChange={onDepartmentChange}
      />

      <FormInput
        name="nameContact"
        label="Name"
        defaultValue={newContact.name}
        onChange={e => setNewContact({ ...newContact, name: e.target.value })}
      />
      <FormInput
        name="email"
        label="E-mail"
        defaultValue={newContact.email}
        onChange={e => setNewContact({ ...newContact, email: e.target.value })}
      />
      <FormInputTextArea
        name="telephone"
        label="Telephone"
        defaultValue={newContact.telephone}
        onChange={e =>
          setNewContact({ ...newContact, telephone: e.target.value })
        }
        rows={5}
        cols={73}
      />
    </Container>
  );
};

export default AddContact;
