import { gql } from '@apollo/client';

export const updateProductPackageDescriptionQuery = gql`
  mutation UpdateProductPackageDescription(
    $data: UpdateProductPackageDescriptionInput!
  ) {
    updateProductPackageDescription(data: $data) {
      idPackageDescription
    }
  }
`;

export const createProductPackageDescriptionQuery = gql`
  mutation Mutation($data: CreateProductPackageDescriptionInput!) {
    createProductPackageDescription(data: $data) {
      idPackageDescription
    }
  }
`;
