import React, { Dispatch, SetStateAction } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { IListSatSupplier, ISat } from '../../interfaces';
import { Container, BreadCrumb } from './styles';
import { IRoleEntityField } from '../../../../../interfaces/IRoleEntityField';
import { satSuppliersRoles } from '../../../../../shared/roles/satSuppliers';
import getFieldPermission from '../../../../../utils/getFieldPermission';
import ReadOnlyInput from '../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';

interface ISupplierProps {
  supplierToDetail: IListSatSupplier;
  setSupplierToDetail: Dispatch<SetStateAction<IListSatSupplier | undefined>>;
  sat: ISat;
  roleEntityFields: IRoleEntityField[];
}

const Supplier: React.FC<ISupplierProps> = ({
  supplierToDetail,
  setSupplierToDetail,
  sat,
  roleEntityFields,
}) => {
  const satCurrencySymbol = getCurrencySymbol(sat.idCurrency2?.abbreviation);

  const {
    idFieldAdvancePaymentPercentage,
    idFieldArtWork,
    idFieldBalancePaymentPercentage,
    idFieldIdLoadingPort,
    idFieldIdSupplier,
    idFieldIncoterm,
    idFieldPaymentTermAdvance,
    idFieldPaymentTermBalance,
    idFieldPaymentTermCondition,
    idFieldProductionTime,
    idFieldPurchaseCI,
    idFieldPurchasePI,
    idFieldSupplierLoadingAmountCot,
    idFieldName,
    idFieldAddress,
    idFieldGrossMargin,
    idFieldNetMargin,
    idFieldRemainTotalCot,
    idFieldBeneficiaryName,
    idFieldMainBankName,
    idFieldMainBankAddress,
    idFieldIdBankCountry,
    idFieldIdBankRegion,
    idFieldBankCity,
    idFieldSwift,
    idFieldAccountNumber,
    idFieldIban,
    idFieldIntermediaryBeneficiaryName,
    idFieldIntermediaryBankAddress,
    idFieldIdBankSecondCountry,
    idFieldIdBankSecondRegion,
    idFieldIntermediaryBankCity,
    idFieldIntermediarySwift,
    idFieldIntermediaryAccountNumber,
    idFieldIntermediaryIban,
  } = satSuppliersRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  return (
    <Container>
      <BreadCrumb>
        <button type="button" onClick={() => setSupplierToDetail(undefined)}>
          List of Suppliers
        </button>
        <FiChevronRight />
        <p>{supplierToDetail.idSupplier2?.sunNumber ?? 'SUPPLIER'}</p>
      </BreadCrumb>
      <div className="form-item">
        <h1>Supplier Info</h1>
        <div className="flex flex-wrap">
          {showField(idFieldIdSupplier) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="SUN"
              value={supplierToDetail.idSupplier2?.sunNumber}
            />
          )}
          {showField(idFieldName) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Supplier Name"
              value={supplierToDetail.name}
            />
          )}
          {showField(idFieldAddress) && (
            <ReadOnlyInput
              className="md:col-6 sm:col-12"
              label="Supplier Address"
              value={supplierToDetail.address}
            />
          )}
          {showField(idFieldProductionTime) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Production Time"
              value={supplierToDetail.productionTime}
            />
          )}
          {showField(idFieldIdLoadingPort) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Port/Airport Of Loading"
              value={supplierToDetail.idLoadingPort2?.name}
            />
          )}
          {showField(idFieldIncoterm) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Purchase Incoterm"
              value={supplierToDetail.incoterm}
            />
          )}
          {showField(idFieldArtWork) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Artwork Format"
              value={supplierToDetail.artWork}
            />
          )}
          {showField(idFieldPaymentTermAdvance) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Payment Term Advance(%)"
              type="number"
              locale="pt-BR"
              suffix="%"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={supplierToDetail.paymentTermAdvance}
            />
          )}
          {showField(idFieldPaymentTermBalance) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Payment Term Balance(%)"
              type="number"
              locale="pt-BR"
              suffix="%"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={supplierToDetail.paymentTermBalance}
            />
          )}
          {showField(idFieldPaymentTermCondition) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Payment Term Condition"
              value={supplierToDetail.paymentTermCondition}
            />
          )}
        </div>
      </div>
      <div className="form-item">
        <h1>Payment Supplier</h1>
        <div className="flex flex-wrap">
          {showField(idFieldPurchasePI) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="TOTAL COT PI"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              value={supplierToDetail.purchasePI}
            />
          )}
          {showField(idFieldPurchaseCI) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="TOTAL COT CI"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              value={supplierToDetail.purchaseCI}
            />
          )}
          {showField(idFieldAdvancePaymentPercentage) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Advance %"
              type="number"
              locale="pt-BR"
              suffix="%"
              value={supplierToDetail.advancePaymentPercentage}
            />
          )}
          {showField(idFieldBalancePaymentPercentage) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Balance %"
              type="number"
              locale="pt-BR"
              suffix="%"
              value={supplierToDetail.balancePaymentPercentage}
            />
          )}
          {showField(idFieldGrossMargin) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Gross Margin"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              value={supplierToDetail.grossMargin}
            />
          )}
          {showField(idFieldNetMargin) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Net Margin"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              value={supplierToDetail.netMargin}
            />
          )}
          {showField(idFieldSupplierLoadingAmountCot) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Loading Amount Cot"
              type="number"
              locale="pt-BR"
              prefix={satCurrencySymbol}
              value={supplierToDetail.supplierLoadingAmountCot}
            />
          )}
        </div>
        <div className="flex flex-wrap">
          {showField(idFieldRemainTotalCot) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Total COT Remain"
              type="number"
              locale="pt-BR"
              value={supplierToDetail.remainTotalCot}
            />
          )}
        </div>
      </div>
      <div className="form-item">
        <h1>Bank Details</h1>
        <div className="flex flex-wrap">
          {showField(idFieldBeneficiaryName) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Beneficiary Name"
              value={supplierToDetail.beneficiaryName}
            />
          )}
          {showField(idFieldMainBankName) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Main Bank Name"
              value={supplierToDetail.mainBankName}
            />
          )}
          {showField(idFieldMainBankAddress) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Main Bank Address"
              value={supplierToDetail.mainBankAddress}
            />
          )}
          {showField(idFieldIdBankCountry) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Country"
              value={supplierToDetail.idBankCountry2?.name}
            />
          )}
          {showField(idFieldIdBankRegion) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="State/Province"
              value={supplierToDetail.idBankRegion2?.name}
            />
          )}
          {showField(idFieldBankCity) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="City"
              value={supplierToDetail.bankCity}
            />
          )}
          {showField(idFieldSwift) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Swift"
              value={supplierToDetail.swift}
            />
          )}
        </div>
        <div className="flex flex-wrap">
          {showField(idFieldAccountNumber) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Account Number"
              value={supplierToDetail.accountNumber}
            />
          )}
          {showField(idFieldIban) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Iban"
              value={supplierToDetail.iban}
            />
          )}
        </div>
        <div className="flex flex-wrap">
          {showField(idFieldIntermediaryBeneficiaryName) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Beneficiary Bank Name"
              value={supplierToDetail.intermediaryBeneficiaryName}
            />
          )}
          {showField(idFieldIntermediaryBankAddress) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Bank Address"
              value={supplierToDetail.intermediaryBankAddress}
            />
          )}
          {showField(idFieldIdBankSecondCountry) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Bank Country"
              value={supplierToDetail.idBankSecondCountry2?.name}
            />
          )}
          {showField(idFieldIdBankSecondRegion) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Bank State/Province"
              value={supplierToDetail.idBankSecondRegion2?.name}
            />
          )}
          {showField(idFieldIntermediaryBankCity) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Bank City"
              value={supplierToDetail.intermediaryBankCity}
            />
          )}
          {showField(idFieldIntermediarySwift) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Swift"
              value={supplierToDetail.intermediarySwift}
            />
          )}
          {showField(idFieldIntermediaryAccountNumber) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Account Number"
              value={supplierToDetail.intermediaryAccountNumber}
            />
          )}
          {showField(idFieldIntermediaryIban) && (
            <ReadOnlyInput
              className="md:col-3 sm:col-12"
              label="Intermediary Iban"
              value={supplierToDetail.intermediaryIban}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Supplier;
