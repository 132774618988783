/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-alert */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Menu } from 'primereact/menu';
import AdvancedFiltersPanel from '../../../../components/AdvancedFiltersPanel';
import Button from '../../../../components/Button';
import Grid, { GridRef } from '../../../../components/Grid';
import { ColumnData } from '../../../../components/Grid/interfaces';
import MultiSelect from '../../../../components/Grid/MultiSelect';
import Loading from '../../../../components/Loading';
import MainButton from '../../../../components/MainButton';
import PageHeader from '../../../../components/PageHeader';
import pagination, {
  searchDelayMiliseconds,
} from '../../../../config/pagination';
import { useAuth } from '../../../../hooks/useAuth';
import { useRefHook } from '../../../../hooks/useRefHook';
import useTitle from '../../../../hooks/useTitle';
import { IBulkResponse } from '../../../../interfaces/IBulkResponse';
import { expandOrCollapseHeader } from '../../../../services/layoutsDefinitions';
import { ncmRoles } from '../../../../shared/roles/ncm';
import getFieldPermission from '../../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../../utils/userHasPermission';
import { INcm, INcmLazyParams } from '../Ncm/interfaces';
import { gridColumnsData } from './constants';
import { createNcmBulkQuery, updateNcmBulkQuery } from './queries';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../../components/FileUpload/interfaces';
import FileUpload from '../../../../components/FileUpload';
import { FileType } from '../../../../shared/enums/fileType';

const screenSubject = 'NCMs';
const pageTitle = `List of ${screenSubject}`;

const Ncms: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:ncmsGridColumns';

  const lazyParamsName = '@SAT:ncmsLazyParams';

  const advancedFiltersName = '@SAT:ncmsAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Produtos selecionados
  const [selectedNcms, setSelectedNcms] = useState([]);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const gridRef = useRef<GridRef<any>>(null);
  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);
  const massiveActionMenuRef = useRef<Menu>(null);

  const { showError, showSuccess } = useRefHook();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    ncmRoles.idEntity,
  );

  const roleEntityFields = userPermissions.userFields;

  const roleEntityPermissions = userPermissions.userPermissions;

  const {
    idFieldAnuencias,
    idFieldBaseDumping,
    idFieldDestaque,
    idFieldDumping,
    idFieldExclusaoTTD,
    idFieldIcmsST,
    idFieldLi,
    idFieldLinkDumping,
    idFieldListaCamex,
    idFieldMva,
    idFieldNotes,
    idFieldNve1,
    idFieldNve2,
    idFieldNve3,
    idFieldNve4,
    idFieldNve5,
    idFieldNve6,
    idFieldPriceMin,
  } = ncmRoles.fields;

  const {
    idPermissionCreateNcm,
    idPermissionDeleteNcm,
    idPermissionExportNcm,
    idPermissionMassiveActionNcm,
  } = ncmRoles.permissions;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList: ColumnData[] = [];

    columnList.push(gridColumnsData.ncmName);
    columnList.push(gridColumnsData.ncm);
    columnList.push(gridColumnsData.description);
    if (showField(idFieldDestaque)) {
      columnList.push(gridColumnsData.destaque);
    }
    if (showField(idFieldNve1)) {
      columnList.push(gridColumnsData.nve1);
    }

    if (showField(idFieldNve2)) {
      columnList.push(gridColumnsData.nve2);
    }

    if (showField(idFieldNve3)) {
      columnList.push(gridColumnsData.nve3);
    }

    if (showField(idFieldNve4)) {
      columnList.push(gridColumnsData.nve4);
    }

    if (showField(idFieldNve5)) {
      columnList.push(gridColumnsData.nve5);
    }

    if (showField(idFieldNve6)) {
      columnList.push(gridColumnsData.nve6);
    }
    if (showField(idFieldMva)) {
      columnList.push(gridColumnsData.mva);
    }
    if (showField(idFieldLi)) {
      columnList.push(gridColumnsData.li);
    }
    if (showField(idFieldAnuencias)) {
      columnList.push(gridColumnsData.anuencias);
    }
    if (showField(idFieldDumping)) {
      columnList.push(gridColumnsData.dumping);
    }
    if (showField(idFieldBaseDumping)) {
      columnList.push(gridColumnsData.baseDumping);
    }
    if (showField(idFieldLinkDumping)) {
      columnList.push(gridColumnsData.linkDumping);
    }
    if (showField(idFieldPriceMin)) {
      columnList.push(gridColumnsData.priceMin);
    }
    if (showField(idFieldListaCamex)) {
      columnList.push(gridColumnsData.listaCamex);
    }
    if (showField(idFieldExclusaoTTD)) {
      columnList.push(gridColumnsData.exclusaoTtd);
    }
    if (showField(idFieldIcmsST)) {
      columnList.push(gridColumnsData.icmsSt);
    }
    columnList.push(gridColumnsData.ii);
    columnList.push(gridColumnsData.ipi);
    columnList.push(gridColumnsData.pis);
    columnList.push(gridColumnsData.cofins);
    if (showField(idFieldNotes)) {
      columnList.push(gridColumnsData.notes);
    }
    columnList.push(gridColumnsData.nveDesc1);
    columnList.push(gridColumnsData.nveDesc2);
    columnList.push(gridColumnsData.nveDesc3);
    columnList.push(gridColumnsData.nveDesc4);
    columnList.push(gridColumnsData.nveDesc5);
    columnList.push(gridColumnsData.nveDesc6);

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = useMemo(() => {
    return [...columns, gridColumnsData.nve, gridColumnsData.nveDesc];
  }, [columns]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // URL params
  const location = useLocation();

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
    idNcm: undefined,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<INcmLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: INcmLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: INcmLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return filters.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [filters, lazyParams]);

  // Variaveis utilizadas para exportar NCMs para xlsx
  const exportNcmVariables = {
    variables: {
      listAllNcmInput: {
        _page: 0,
        _limit: 0,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        globalSearch: lazyParams.globalFilter,
        active: true,
        idNcm: lazyParams.idNcm,
        ncmName: lazyParams.ncmName,
        ncm: lazyParams.ncm,
        description: lazyParams.description,
        destaque: lazyParams.destaque,
        nve: lazyParams.nve,
        nveDesc: lazyParams.nveDesc,
        mva: lazyParams.mva,
        li: lazyParams.li,
        anuencias: lazyParams.anuencias,
        dumping: lazyParams.dumping,
        baseDumping: lazyParams.baseDumping,
        linkDumping: lazyParams.linkDumping,
        priceMin: lazyParams.priceMin,
        listaCamex: lazyParams.listaCamex,
        exclusaoTtd: lazyParams.exclusaoTtd,
        icmsSt: lazyParams.icmsSt,
        notes: lazyParams.notes,
      },
    },
  };

  // Query para listar ncm
  const queryToListNcm = gql`
    query listAllNcmQuery($listAllNcmInput: ListAllNcmInput!) {
      listAllNcm(listAllNcmInput: $listAllNcmInput) {
        data {
          idNcm
          ncmName
          ncm
          description
          ${showField(idFieldDestaque) ? 'destaque' : ''}
          ${showField(idFieldNve1) ? 'nve1' : ''}
          ${showField(idFieldNve2) ? 'nve2' : ''}
          ${showField(idFieldNve3) ? 'nve3' : ''}
          ${showField(idFieldNve4) ? 'nve4' : ''}
          ${showField(idFieldNve5) ? 'nve5' : ''}
          ${showField(idFieldNve6) ? 'nve6' : ''}
          nveDesc1
          nveDesc2
          nveDesc3
          nveDesc4
          nveDesc5
          nveDesc6
          ${showField(idFieldMva) ? 'mva' : ''}
          ${showField(idFieldLi) ? 'li' : ''}
          ${showField(idFieldAnuencias) ? 'anuencias' : ''}
          ${showField(idFieldDumping) ? 'dumping' : ''}
          ${
            showField(idFieldBaseDumping)
              ? `baseDumping2 {
                description
                }`
              : ''
          }
          ${showField(idFieldLinkDumping) ? 'linkDumping' : ''}
          ${showField(idFieldPriceMin) ? 'priceMin' : ''}
          ${showField(idFieldListaCamex) ? 'listaCamex' : ''}
          ${showField(idFieldExclusaoTTD) ? 'exclusaoTtd' : ''}
          ${showField(idFieldIcmsST) ? 'icmsSt' : ''}
          ${showField(idFieldNotes) ? 'notes' : ''}
          ii
          ipi
          pis
          cofins
        }
        items
      }
    }
  `;

  // Busca parceiros
  const {
    loading: ncmLoading,
    data: ncmData,
    error: ncmError,
    refetch: ncmRefetch,
  } = useQuery(queryToListNcm, {
    variables: {
      listAllNcmInput: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        globalSearch: lazyParams.globalFilter,
        idNcm: lazyParams.idNcm,
        active: true,
        ncmName: lazyParams.ncmName,
        ncm: lazyParams.ncm,
        description: lazyParams.description,
        destaque: lazyParams.destaque,
        nve: lazyParams.nve,
        nveDesc: lazyParams.nveDesc,
        mva: lazyParams.mva,
        li: lazyParams.li,
        anuencias: lazyParams.anuencias,
        dumping: lazyParams.dumping,
        baseDumping: lazyParams.baseDumping,
        linkDumping: lazyParams.linkDumping,
        priceMin: lazyParams.priceMin,
        listaCamex: lazyParams.listaCamex,
        exclusaoTtd: lazyParams.exclusaoTtd,
        icmsSt: lazyParams.icmsSt,
        notes: lazyParams.notes,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting NCMs',
        detail: errorData.message,
      });
    },
  });

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Salva arquivo como xlsx
   * @param buffer
   * @param fileName Nome do arquivo
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(ncmExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    ncmExportData.listAllNcm.data.forEach((ncm: any) => {
      ncm.descricao = ncm.description;
      ncm.precoMinimo = ncm.priceMin;

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'description':
            delete ncm.descricao;
            break;
          case 'priceMin':
            delete ncm.precoMinimo;
            break;
          default:
            delete ncm[column.field];
            break;
        }
      });

      // Remover colunas __typename e idNcm
      delete ncm.__typename;
      delete ncm.idNcm;
      delete ncm.baseDumping2;
      delete ncm.description;
      delete ncm.priceMin;
    });

    // Busca ordenacao das colunas da grid
    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idNcm' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'description':
          columnsOrder[index] = 'descricao';
          break;
        case 'priceMin':
          columnsOrder[index] = 'precoMinimo';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(ncmExportData.listAllNcm.data, {
      header: columnsOrder,
    });
    worksheet['!autofilter'] = { ref: 'A1:AE1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveAsExcelFile(excelBuffer, 'NCMs');
  }

  /**
   * Busca usuarios para exportar para XLSX
   */
  const [
    loadNcmToExport,
    { loading: ncmsExportLoading, data: ncmsToExportData },
  ] = useLazyQuery(queryToListNcm, {
    onCompleted: () => {
      exportToXlsx(ncmsToExportData);
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting NCMs',
        detail: errorData.message,
      });
    },
  });

  /**
   * Aplica máscara na coluna ncm
   * @param rowData Dados da linha
   * @returns Valor com máscara
   */
  const parseNcmColumn = (rowData: any, field: any) => {
    if (field.field === 'ncm') {
      return (
        <p>
          {rowData.ncm
            ? rowData.ncm
                .toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, '$1.$2.$3')
            : ''}
        </p>
      );
    }
    return undefined;
  };

  /**
   * Aplica link na coluna linkDumping
   * @param rowData Dados da linha
   * @returns Valor com link
   */
  const parseLinkDumpingColumn = (rowData: any) => {
    return (
      rowData.linkDumping && (
        <a
          className="link"
          href={
            rowData.linkDumping?.match('http')
              ? rowData.linkDumping
              : `http://${rowData.linkDumping}`
          }
          target="_blank"
          rel="noreferrer"
        >
          {rowData.linkDumping}
        </a>
      )
    );
  };

  /**
   * Valida se coluna eh de porcentagem
   * @param rowData Dados da linha
   * @returns Campo com porcentagem
   */
  const parsePercentageColumn = (rowData: any, field: any) => {
    return (
      <p>{`${rowData[field.field]?.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`}</p>
    );
  };

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    if (rowData[field.field] !== null && rowData[field.field] !== undefined) {
      return <p>{rowData[field.field] ? 'Yes' : 'No'}</p>;
    }
    return undefined;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'ncm':
        return parseNcmColumn;
      case 'linkDumping':
        return parseLinkDumpingColumn;
      case 'mva':
      case 'li':
      case 'dumping':
      case 'priceMin':
      case 'listaCamex':
      case 'icmsSt':
      case 'exclusaoTtd':
      case 'active':
        return parseBooleanColumn;
      case 'ii':
      case 'ipi':
      case 'pis':
      case 'cofins':
        return parsePercentageColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'ncmName':
      case 'linkDumping':
      case 'baseDumping':
      case 'baseDumping2.description':
      case 'priceMin':
        return { width: '10.71rem' };
      case 'listaCamex':
        return { width: '9.29rem' };
      case 'exclusaoTtd':
        return { width: '10rem' };
      case 'notes':
      case 'nveDesc1':
      case 'nveDesc2':
      case 'nveDesc3':
      case 'nveDesc4':
      case 'nveDesc5':
      case 'nveDesc6':
      case 'description':
      case 'anuencias':
        return { width: '28.57rem' };
      default:
        return {
          width: '8.57rem',
        };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map((col: any) => {
    return (
      <Column
        key={col.field}
        // Valida o body da coluna (caso precise ser um link)
        body={handleColumn(col.field)}
        // body={col.field}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'coresString'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  // Query para deletar parceiro
  const queryToDeleteNcms = gql`
    mutation DeleteNcmMutation($ncmIds: [Int]!) {
      deleteNcm(ncmIds: $ncmIds)
    }
  `;
  // cria método para chamar a mutation
  const [deleteNcms] = useMutation(queryToDeleteNcms);

  /**
   * Deleta ncm selecionadas
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedNcms.length} NCMs?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada parceiro
        const idsToDelete = selectedNcms.map((a: INcm) => a.idNcm);
        try {
          // Deleta parceiros
          await deleteNcms({
            variables: {
              ncmIds: idsToDelete,
            },
          });

          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedNcms.length} NCMs`,
          });

          // Zera estado de parceiros selecionadas
          setSelectedNcms([]);

          // Atualiza grid de parceiros
          await ncmRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting NCMs',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  const expandCollapsePageHeader = () => {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  };

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Direciona usuario para pagina do NCM clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/products/ncm/${e.data.idNcm}`);
  }

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  const [createNcmBulkMutation] = useMutation(createNcmBulkQuery);
  const [updateNcmBulkMutation] = useMutation(updateNcmBulkQuery);

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      const response = await createNcmBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createNcmBulk,
        type: 'CREATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while creating NCMs',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      const response = await updateNcmBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateNcmBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while updating NCMs',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;

  // Carrega dados do porto
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'ncm_bulk_template_v1.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>NCMs found in the spreadsheet:</b> {bulkResponse?.total}
        </p>
        <p>
          <b>
            NCMs {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.created || bulkResponse?.updated}
        </p>
        {!!bulkResponse?.errors?.length && (
          <p>
            <b>Products/Cells with error:</b>
            <br />
            {bulkResponse?.errors.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar product */}
        {hasPermission(idPermissionCreateNcm) && (
          <MainButton
            className="mainButton"
            label="New NCM"
            onClick={() => history.push('/products/ncm/create')}
          />
        )}

        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={filters}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir produtos selecionados */}
        {hasPermission(idPermissionDeleteNcm) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={!selectedNcms.length}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {hasPermission(idPermissionMassiveActionNcm) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}

        {hasPermission(idPermissionExportNcm) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={ncmsExportLoading}
            onClick={() => {
              loadNcmToExport(exportNcmVariables);
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an NCM"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="ncms"
        lazy
        totalRecords={!ncmData || ncmError ? 0 : ncmData.listAllNcm?.items}
        value={!ncmData || ncmError ? undefined : ncmData.listAllNcm?.data}
        globalFilter={globalFilter}
        emptyMessage="No NCM found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedNcms}
        onSelectionChange={e => setSelectedNcms(e.value)}
        selectionMode="checkbox"
      >
        {/* {userHasPermission(
          idPermissionDeletePartner,
          userPermissions.userPermissions,
        ) && (
            )} */}
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3rem' }}
          reorderable={false}
        />
        {dynamicColumns}
      </Grid>
      {(pageLoading || ncmLoading || ncmsExportLoading) && <Loading />}
    </div>
  );
};
export default Ncms;
