/* eslint-disable no-shadow */

/**
 * Enum com ID de Entities
 */
export enum EntityType {
  PARTNER = 22,
  SAT = 23,
  CLIENT = 24,
  SUPPLIER = 27,
  PRODUCT = 37,
  PRC = 42,
  CLIENT_QUOTATION = 46,
}
