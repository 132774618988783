import { get } from 'lodash';
import {
  FilesByTypesReducerActionKind,
  IFilesByTypesReducer,
  IFilesByTypesReducerAction,
} from './interfaces';

export const filesByTypesReducerInitialState: IFilesByTypesReducer = {
  fileTypes: [],
  files: [],
};

export const filesByTypesReducer = (
  state: IFilesByTypesReducer,
  action: IFilesByTypesReducerAction,
): IFilesByTypesReducer => {
  switch (action.type) {
    case FilesByTypesReducerActionKind.SET_FILE_TYPES:
      if (!action.payload.fileTypes) return state;

      return {
        ...state,
        fileTypes: action.payload.fileTypes.filter(Boolean),
      };

    case FilesByTypesReducerActionKind.SET_FILES:
      if (!action.payload.files) return state;

      return {
        ...state,
        files: action.payload.files,
      };

    case FilesByTypesReducerActionKind.TOGGLE_FILE_CHECKBOX:
      if (!action.payload.toggleFileCheckboxData) return state;

      {
        const { fileId, fileIdKey, fileTypeKey, checked } =
          action.payload.toggleFileCheckboxData;

        const fileFound = state.files.find(
          file => get(file, fileIdKey) === fileId,
        );

        if (!fileFound) return state;

        fileFound.isSelected = checked;

        // Valida se todo o tipo foi selecionado apos o toggle
        const fileFoundType = get(fileFound, fileTypeKey);

        const filesFromTheSameType = state.files.filter(
          file => get(file, fileTypeKey) === fileFoundType,
        );

        const filesFromTheSameTypeSelected = filesFromTheSameType.filter(
          file => file.isSelected,
        );

        const fileTypeFound = state.fileTypes.find(
          fileType => fileType.idDomain === fileFoundType,
        );

        if (!fileTypeFound) return { ...state };

        fileTypeFound.isSelected =
          filesFromTheSameType.length === filesFromTheSameTypeSelected.length;

        return { ...state };
      }

    case FilesByTypesReducerActionKind.TOGGLE_FILE_TYPE_CHECKBOX:
      if (!action.payload.toggleFileTypeCheckboxData) return state;

      {
        const { idDomain, fileTypeKey, checked } =
          action.payload.toggleFileTypeCheckboxData;

        const fileTypeFound = state.fileTypes.find(
          fileType => fileType.idDomain === idDomain,
        );

        if (!fileTypeFound) return state;

        fileTypeFound.isSelected = checked;

        // Efetua toggle de todos os arquivos do mesmo tipo
        const filesFromTheSameType = state.files.filter(
          file => get(file, fileTypeKey) === idDomain,
        );

        for (let index = 0; index < filesFromTheSameType.length; index += 1) {
          filesFromTheSameType[index].isSelected = checked;
        }

        return { ...state };
      }

    default:
      throw new Error();
  }
};
