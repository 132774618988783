/* eslint-disable no-shadow */
export enum RegisterProductDetailsOptions {
  CHECKING_UPDATE_MISSING_INFO = 1350,
  CHECKING_DONE = 1351,
  UPDATE_MISSING_INFO_DONE = 1352,
}

export enum RegisterClassificacaoFiscalOptions {
  MISSING_REQUEST_CLASSIFICACAO_FISCAL = 1353,
}

export enum RegisterHrpOptions {
  MISSING_CHECK = 1354,
  MISSING_NOT_REQUIRED = 1355,
}

export enum RegisterClientRegisterOptions {
  MISSING_REQUEST = 1356,
  MISSING_NOT_REQUIRED = 1357,
}

export enum ArtworkProductInfoForAwOptions {
  CHECKING_UPDATE_MISSING_INFO = 1358,
  CHECKING_DONE = 1359,
  MISSING_DONE = 1360,
}
