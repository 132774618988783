export const commercialFormCheckboxItems = {
  contractedModalityItems: [
    {
      name: 'FOB',
      isChecked: false,
    },
    {
      name: 'Service',
      isChecked: false,
    },
    {
      name: 'Full Service',
      isChecked: false,
    },
  ],

  awResponsibleItems: [
    {
      name: 'Design',
      isChecked: false,
    },
    {
      name: 'Supplier',
      isChecked: false,
    },
    {
      name: 'Client',
      isChecked: false,
    },
  ],

  serviceNdiDistribuctionItems: [
    {
      name: 'No',
      isChecked: false,
    },
    {
      name: 'Assembling',
      isChecked: false,
      additionalValue: '',
    },
    {
      name: 'Labeling',
      isChecked: false,
      additionalValue: '',
    },
  ],

  rfIdItems: [
    {
      name: 'No',
      isChecked: false,
    },
    {
      name: 'Yes',
      isChecked: false,
      additionalValue: '',
    },
  ],
};
