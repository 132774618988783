import { gql } from '@apollo/client';

export const listPackageReferencesQuery = gql`
  query ListProductPackageReferencesByIdProductPackage(
    $idProductPackage: Int!
    $pagination: Pagination!
  ) {
    listProductPackageReferencesByIdProductPackage(
      idProductPackage: $idProductPackage
      pagination: $pagination
    ) {
      items
      data {
        idPackageReference
        idProductPackage
        colourEn
        colourPt
        idGender
        idGender2 {
          description
        }
        idSizeGroup
        idSizeGroup2 {
          description
        }
        masterBarcode
        idAttachment
        idAttachment2 {
          idAttachment
          url
          nameGivenByUser
        }
        productPackageSizeBarcodes {
          idPackageReference
          innerBox
          size
          size2 {
            description
          }
          unit
          unit2 {
            description
          }
          reference
          sizeRange
          sellingPackage
          unitInnerBox
          masterBox
          unitMasterBox
          auto
          autoMasterBox
          autoInnerBox
          idPackageSizeBarcode
        }
      }
    }
  }
`;

export const deletePackageReferenceQuery = gql`
  mutation DeleteProductPackageReference($idPackageReference: Int!) {
    deleteProductPackageReference(idPackageReference: $idPackageReference)
  }
`;

export const uploadProductPackageReferenceAttachmentQuery = gql`
  mutation UploadProductPackageReferenceAttachment(
    $data: UploadProductPackageReferenceAttachmentInput!
  ) {
    uploadProductPackageReferenceAttachment(data: $data) {
      url
      nameGivenByUser
    }
  }
`;
