export const financialTransactionMenuItems = {
  generalInformation: {
    key: 'generalInformation',
    label: 'General Information',
  },
  attachments: {
    key: 'attachments',
    label: 'Attachments',
  },
  updates: {
    key: 'updates',
    label: 'Updates',
  },
};
