import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTablePageEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { FiDownload, FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Dialog } from 'primereact/dialog';
import Button from '../../../../../components/Button';
import Grid from '../../../../../components/Grid';
import pagination from '../../../../../config/pagination';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ILazyParams from '../../../../../services/lazyParams';
import { FileType } from '../../../../../shared/enums/fileType';
import { parseSunColumn } from '../../../../../utils/gridColumnsParse';
import { SatAttachment } from '../CompareFile/interfaces';
import {
  SatQuotation,
  SatQuotationFileLog,
  SatQuotationsResponse,
} from './interfaces';
import {
  createSatQuotationFileLog,
  deleteSatQuotationQuery,
  listSatQuotationsBySatId,
} from './queries';
import Quotation from './Quotation';

import { Container, FileColumn, GridActions } from './styles';
import FileUpload from '../../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

export interface ISatQuotationsProps {
  idSat: number;
  selected: boolean;
  isSatCancelled: boolean;
}

const Quotations: React.FC<ISatQuotationsProps> = ({
  idSat,
  selected,
  isSatCancelled,
}) => {
  const { showError, showSuccess } = useRefHook();
  const [lazyParams, setLazyParams] = useState<ILazyParams>(
    pagination.initialLazyParams,
  );

  const [satQuotations, setSatQuotations] = useState<SatQuotationsResponse>();
  const [selectedQuotation, setSelectedQuotation] = useState<SatQuotation>();

  const [idQuotationThatIsUploadingFile, setIdQuotationThatIsUploadingFile] =
    useState<number>();

  const [idQuotationBeingDeleted, setIdQuotationBeingDeleted] =
    useState<number>();
  const [dialogFileLogs, setDialogFileLogs] = useState<SatQuotationFileLog[]>();

  const [createQuotationFileLogMutation] = useMutation(
    createSatQuotationFileLog,
  );
  const [deleteSatQuotationMutation] = useMutation(deleteSatQuotationQuery);

  const [getSatQuotations, { loading: getSatQuotationsLoading }] = useLazyQuery(
    listSatQuotationsBySatId,
    {
      variables: {
        data: {
          idSat,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
        },
      },
      onCompleted: response => {
        setSatQuotations(response.listSatQuotationsBySatId);
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting compare files',
          detail: errorData.message,
        });
      },
    },
  );

  const handleDeleteQuotation = async (idSatQuotation: number) => {
    setIdQuotationBeingDeleted(idSatQuotation);
    try {
      await deleteSatQuotationMutation({
        variables: {
          idSatQuotation,
        },
      });

      showSuccess({
        summary: 'SAT quotation deleted',
      });

      getSatQuotations();
    } catch (error) {
      showError({
        summary: 'Error while deleting SAT quotation',
        detail: error.message,
      });
    } finally {
      setIdQuotationBeingDeleted(undefined);
    }
  };

  const columnActions = (rowData: SatQuotation) => {
    const quotationBeingDeleted =
      rowData.idSatQuotation === idQuotationBeingDeleted;

    return (
      <GridActions>
        <button type="button" onClick={() => setSelectedQuotation(rowData)}>
          <FiEdit2 size={20} />
        </button>
        <button
          type="button"
          className="trash-button"
          disabled={quotationBeingDeleted}
          onClick={() =>
            confirmDialog({
              message: 'Are you sure you want to delete this quotation?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => handleDeleteQuotation(rowData.idSatQuotation),
            })
          }
        >
          {quotationBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <FiTrash2 size={20} />
          )}
        </button>
      </GridActions>
    );
  };

  const handleUploadQuotationFileLog = async (
    data: FileUploadResponse[],
    idSatQuotation: number,
  ) => {
    setIdQuotationThatIsUploadingFile(idSatQuotation);
    try {
      await createQuotationFileLogMutation({
        variables: {
          data: {
            idSatQuotation,
            uploadedFile: data[0].serverName,
          },
        },
      });

      showSuccess({
        summary: 'Quotation file log uploaded',
      });

      getSatQuotations();
    } catch (error) {
      showError({
        summary: 'Error while uploading quotation file log ',
        detail: error.message,
      });
    } finally {
      setIdQuotationThatIsUploadingFile(undefined);
    }
  };

  const fileColumn = (rowData: SatQuotation) => {
    const uploadInProgress =
      idQuotationThatIsUploadingFile === rowData.idSatQuotation;

    return (
      <FileColumn>
        {!!rowData.satQuotationFileLogs?.length && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={rowData.idAttachment2?.url}
          >
            <FiDownload size={18} />
          </a>
        )}

        <FileUpload
          className="grid-file-upload"
          mode="basic"
          auto
          chooseLabel={' '}
          chooseOptions={{
            icon: uploadInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-upload',
          }}
          uploadInProgress={uploadInProgress}
          accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOCX},${FileType.DOC},${FileType.PDF},${FileType.ALL_IMAGES}`}
          onConfirm={e =>
            handleUploadQuotationFileLog(e, rowData.idSatQuotation)
          }
        />

        {!!rowData.satQuotationFileLogs?.length && (
          <div className="sat-quotation-file-log">
            <button
              type="button"
              className="items-log-button"
              onClick={() => setDialogFileLogs(rowData.satQuotationFileLogs)}
            >
              <AiOutlineUnorderedList size={24} />
            </button>
          </div>
        )}
      </FileColumn>
    );
  };

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  const onSort = (event: DataTableSortEvent) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageEvent) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  function renderBestPriceColumn(rowData: SatQuotation) {
    if (rowData.bestPrice) return <FaCheckCircle size={20} color="green" />;

    return undefined;
  }

  // Busca SAT Quotations se aba SAT Items estiver selecionada e estado de
  // satQuotations for undefined
  useEffect(() => {
    if (selected && !satQuotations) {
      getSatQuotations();
    }
  }, [getSatQuotations, satQuotations, selected]);

  return (
    <Container>
      <Dialog
        header="SAT Quotation - Log"
        closable
        closeOnEscape
        dismissableMask
        visible={!!dialogFileLogs}
        onHide={() => setDialogFileLogs(undefined)}
      >
        <DataTable
          key="idSatQuotationFileLog"
          value={dialogFileLogs}
          style={{ maxWidth: '400px', marginTop: '12px' }}
          removableSort
          emptyMessage="No items to show"
          sortField="createdAt"
          sortOrder={-1}
        >
          <Column
            field="createdAt"
            header="Created At"
            body={e => new Date(e.createdAt).toLocaleDateString()}
            sortable
          />
          <Column
            field="createdBy2.firstName"
            header="Created By"
            body={e => `${e.createdBy2.firstName} ${e.createdBy2.lastName}`}
            sortable
          />
          <Column
            field="file"
            header="File"
            body={(e: SatAttachment) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
                href={e.idAttachment2.url}
              >
                <FiDownload size={18} className="svg-left" />
              </a>
            )}
          />
        </DataTable>
      </Dialog>

      <Quotation
        satQuotation={selectedQuotation}
        setSelectedQuotation={setSelectedQuotation}
        idSat={idSat}
        getSatQuotations={getSatQuotations}
      />

      <h2>SAT Quotations</h2>
      <Button
        label="New Quotation"
        type="button"
        onClick={() => setSelectedQuotation({} as SatQuotation)}
        disabled={isSatCancelled}
      />

      <Grid
        lazy
        value={satQuotations?.data}
        removableSort
        emptyMessage="No quotations to show"
        loading={getSatQuotationsLoading}
        totalRecords={satQuotations?.items ?? 0}
        rows={lazyParams.rows}
        first={lazyParams.first}
        onSort={onSort}
        onPage={onPage}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        <Column
          field="actions"
          headerStyle={{ width: '8rem' }}
          columnKey="actions"
          body={columnActions}
        />

        <Column
          field="bestPrice"
          columnKey="bestPrice"
          header="Best Price"
          body={data => renderBestPriceColumn(data)}
          headerStyle={{ width: '8rem' }}
        />

        <Column
          field="idSupplier2.sunNumber"
          columnKey="sunNumber"
          header="SUN"
          sortable
          body={parseSunColumn}
        />

        <Column
          field="idSupplier2.name"
          columnKey="name"
          header="Supplier"
          sortable
        />

        <Column
          field="comment"
          columnKey="comment"
          header="Comment"
          sortable
          body={e => <pre>{e.comment}</pre>}
        />

        <Column field="file" columnKey="file" header="File" body={fileColumn} />
      </Grid>
    </Container>
  );
};

export default Quotations;
