/**
 * Valida se usuario possui permissao para acessar o modulo financeiro
 * @param envProperty Propriedade de ambiente com lista de usuarios autorizados
 * @param idUser ID do usuario a ser validado
 * @returns True se usuario possui permissao
 */
export function ensureEnvAuthorization(
  envProperty: string,
  idUser: number,
): boolean {
  const allowedUsers: number[] = JSON.parse(process.env[envProperty] || '[]');

  return allowedUsers.includes(idUser);
}
