import { RefObject, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { IFinancialTransaction, IClient, ISupplier } from '../interfaces';
import FormTitle from '../../../../components/FormTitle';
import ReadOnlyInput from '../../../../components/ReadOnlyInput';
import FormInput from '../../../../components/FormInput';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import { asyncSelectLoadCountries } from '../../../../shared/querys/country';
import FormInputNumber from '../../../../components/FormInputNumber';
import { asyncSelectLoadSatForeignTradesToFinancialTransaction } from '../../../../shared/querys/satForeignTrade';
import { asyncSelectLoadClients } from '../../../../shared/querys/client';
import { asyncSelectLoadSuppliersBySatForeignTrade } from '../../../../shared/querys/supplier';
import { asyncSelectLoadBeneficiaries } from '../../../../shared/querys/beneficiary';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import {
  FinancialPaymentSituation,
  RecurringPaymentsType,
} from '../../../../shared/enums/domains';
import { asyncSelectLoadCurrenciesUsedInFinancialAccounts } from '../../../../shared/querys/currency';
import PageTabContainer from '../../../../components/PageTabContainer';
import { asyncSelectLoadCompanies } from '../../../../shared/querys/companies';
import { asyncSelectLoadFinancialTransactionCategories } from '../../../../shared/querys/financials';
import RecurringPaymentsList, {
  RecurringPaymentsListRef,
} from '../RecurringPaymentsList';
import CreateRecurringPayment from '../CreateRecurringPayment';
import { IDomain } from '../../../../services/dataInterface';

export interface IFinancialTransactionGeneralInformationRef {
  handleSaveGeneralInformation(): Promise<any>;
  validateGeneralInformationForm(): Promise<void>;
}

interface IGeneralInformationProps {
  idFinancialTransaction: number;
  financialTransaction: IFinancialTransaction;
  selected: boolean;
  formRef: RefObject<FormHandles>;
  onSubmit(): void;
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  idFinancialTransaction,
  financialTransaction,
  selected,
  formRef,
  onSubmit,
}) => {
  const recurringPaymentsListRef = useRef<RecurringPaymentsListRef>(null);

  function amountStyleValidation(value: number | null) {
    if (!value) return '';

    return value > 0
      ? 'var(--sunasia-sectors-qualidade)'
      : 'var(--sunasia-red)';
  }

  const [idFinancialTransactionGroup, setIdFinancialTransactionGroup] =
    useState(financialTransaction?.idFinancialTransactionGroup);

  const [amountStyle, setAmountStyle] = useState({
    color: amountStyleValidation(financialTransaction?.amount),
  });

  const [groupValue, setGroupValue] = useState(
    financialTransaction?.idFinancialTransactionCategory2?.group,
  );

  const [referenceValue, setReferenceValue] = useState(
    financialTransaction?.reference,
  );

  const [shipmentValue, setShipmentValue] = useState(
    financialTransaction?.idSatForeignTrade2,
  );

  const [clientValue, setClientValue] = useState<IClient | null>(
    financialTransaction?.idClient2,
  );

  const [beneficiaryValue, setBeneficiaryValue] = useState(
    financialTransaction?.idBeneficiary2,
  );

  const [companyValue, setCompanyValue] = useState(
    financialTransaction?.idFinancialAccount2?.idCompany2,
  );

  const [paymentSituationValue, setPaymentSituationValue] = useState(
    {} as IDomain,
  );

  const [recurringPaymentValue, setRecurringPaymentValue] = useState(
    financialTransaction?.idRecurringPayment2 ?? {
      idDomain: RecurringPaymentsType.NO,
      description: RecurringPaymentsType[RecurringPaymentsType.NO],
    },
  );

  const isPaymentSituationCanceledOrStandBy =
    paymentSituationValue.idDomain === FinancialPaymentSituation.CANCELED ||
    paymentSituationValue.idDomain === FinancialPaymentSituation.STAND_BY;

  const [supplierValue, setSupplierValue] = useState<ISupplier | null>(
    financialTransaction?.idSupplier2,
  );

  function updatePaymentSituationValue(cashflowRegime: string) {
    if (cashflowRegime) {
      setPaymentSituationValue({
        idDomain: FinancialPaymentSituation.OUTSTANDING_PAYMENT,
        description: FinancialPaymentSituation[
          FinancialPaymentSituation.OUTSTANDING_PAYMENT
        ].replaceAll('_', ' '),
      });

      return;
    }

    setPaymentSituationValue({
      idDomain: FinancialPaymentSituation.FORECAST,
      description:
        FinancialPaymentSituation[FinancialPaymentSituation.FORECAST],
    });
  }

  // Por ser readonly, o Payment Situation é inicializado aqui no lugar do state para quando
  // precisar recarregar a tela ele atualize
  useEffect(() => {
    setPaymentSituationValue(
      financialTransaction?.idPaymentSituation2 ?? {
        idDomain: FinancialPaymentSituation.FORECAST,
        description:
          FinancialPaymentSituation[FinancialPaymentSituation.FORECAST],
      },
    );
  }, [financialTransaction]);

  useEffect(() => {
    if (!shipmentValue) {
      setClientValue(null);
      setSupplierValue(null);
    }
  }, [shipmentValue]);

  return (
    <PageTabContainer className="grid gap-5" selected={selected}>
      <Form
        className="col-12 grid"
        ref={formRef}
        onSubmit={onSubmit}
        initialData={financialTransaction}
        style={{ maxWidth: '82rem' }}
      >
        <FormTitle className="col-12" name="General Information" />
        <ReadOnlyInput
          className="col-3"
          label="Bill Number"
          value={financialTransaction?.idFinancialTransaction}
        />
        <ReadOnlyInput
          className="col-3"
          label="Payment Situation"
          value={paymentSituationValue.description}
        />
        <FormAsyncSelect
          className="col-3"
          name="idCountry"
          label="Country"
          loadOptions={asyncSelectLoadCountries}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idCountry}
          noOptionsMessage={() => 'No countries found'}
          initialValue={financialTransaction?.idCountry2}
          required
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <div className="col-3" />
        <FormAsyncSelect
          className="col-3"
          name="idCompany"
          label="Company"
          loadOptions={asyncSelectLoadCompanies}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idCompany}
          noOptionsMessage={() => 'No companies found'}
          initialValue={financialTransaction?.idFinancialAccount2?.idCompany2}
          onValueChange={value => {
            setCompanyValue(value);
            const currencyRef = formRef.current?.getFieldRef('idCurrency');
            currencyRef.setValue(null);
          }}
          required
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormAsyncSelect
          className="col-3"
          name="idCurrency"
          label="Currency"
          loadOptions={asyncSelectLoadCurrenciesUsedInFinancialAccounts}
          getOptionLabel={option => option.currency}
          getOptionValue={option => option.idCurrency}
          noOptionsMessage={() => 'No currencies found'}
          initialValue={financialTransaction?.idFinancialAccount2?.idCurrency2}
          additional={{ idCompany: companyValue?.idCompany }}
          disabled={!companyValue}
          required
          cacheUniqs={[companyValue]}
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormInputNumber
          className="col-3"
          label="Amount"
          name="amount"
          style={amountStyle}
          onChange={e =>
            setAmountStyle({
              color: amountStyleValidation(Number(e.currentTarget.value)),
            })
          }
          required
          disabled={!!financialTransaction?.idFinancialTransactionPaymentType}
          readOnly={financialTransaction?.isReconciled}
        />
        <ReadOnlyInput
          className="col-3"
          label="Bill Rate"
          type="number"
          value={
            financialTransaction?.billRate ||
            financialTransaction?.idFinancialAccount2?.idCurrency2
              ?.usdExchangeRate
          }
        />
        <FormAsyncSelect
          className="col-3"
          name="idCostCenterByCountry"
          label="Cost Center By Country"
          loadOptions={asyncSelectLoadCountries}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idCountry}
          noOptionsMessage={() => 'No countries found'}
          initialValue={financialTransaction?.idCostCenterByCountry2}
          additional={{
            showFinancialTransactionCountriesFirst: true,
          }}
          required
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormAsyncSelect
          className="col-3"
          name="idFinancialTransactionCategory"
          label="Account Type"
          loadOptions={asyncSelectLoadFinancialTransactionCategories}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idFinancialTransactionCategory}
          noOptionsMessage={() => 'No categories found'}
          initialValue={financialTransaction?.idFinancialTransactionCategory2}
          required
          onValueChange={e => setGroupValue(e.group)}
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <ReadOnlyInput className="col-3" label="Group" value={groupValue} />
        <div className="col-3" />
        <FormInput
          type="date"
          className="col-3"
          name="paymentForecast"
          label="Payment Forecast"
          required={recurringPaymentValue.idDomain !== RecurringPaymentsType.NO}
          disabled={!!financialTransaction?.idFinancialTransactionPaymentType}
          readOnly={financialTransaction?.isReconciled}
        />
        <FormInput
          type="date"
          className="col-3"
          name="competencyRegime"
          label="Competency Regime"
          disabled={!!financialTransaction?.idFinancialTransactionPaymentType}
          readOnly={financialTransaction?.isReconciled}
        />
        <FormInput
          type="date"
          className="col-3"
          name="cashflowRegime"
          label="Cashflow Regime"
          onChange={e => updatePaymentSituationValue(e.currentTarget.value)}
          disabled={!!financialTransaction?.idFinancialTransactionPaymentType}
          readOnly={financialTransaction?.isReconciled}
        />
        <div className="col-3" />
        <FormInput
          className="col-3"
          label="Reference"
          name="reference"
          onChange={e => setReferenceValue(e.currentTarget.value)}
          required={!shipmentValue}
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormAsyncSelect
          className="col-3"
          name="idSatForeignTrade"
          label="Shipment"
          loadOptions={asyncSelectLoadSatForeignTradesToFinancialTransaction}
          getOptionLabel={option => option.satForeignTradeNumber}
          getOptionValue={option => option.idSatForeignTrade}
          noOptionsMessage={() => 'No shipments found'}
          initialValue={shipmentValue}
          onValueChange={e => {
            setShipmentValue(e);
            setClientValue(e?.idSat2?.idClient2);
            setSupplierValue(e?.satForeignTradeSuppliers[0]?.idSupplier2);
          }}
          required={!referenceValue}
          isClearable
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormInput
          className="col-6"
          label="Product"
          name="product"
          defaultValue={
            shipmentValue?.idSat2?.mainProduct ?? financialTransaction?.product
          }
          readOnly={!!shipmentValue || financialTransaction?.isReconciled}
        />
        <FormAsyncSelect
          className="col-6"
          name="idClient"
          label="Client"
          loadOptions={asyncSelectLoadClients}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idClient}
          noOptionsMessage={() => 'No clients found'}
          value={clientValue}
          onValueChange={setClientValue}
          readOnly={!!shipmentValue || financialTransaction?.isReconciled}
          isClearable={!shipmentValue}
        />
        <FormAsyncSelect
          className="col-6"
          name="idSupplier"
          label="Supplier"
          loadOptions={asyncSelectLoadSuppliersBySatForeignTrade}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idSupplier}
          noOptionsMessage={() => 'No suppliers found'}
          additional={{ idSatForeignTrade: shipmentValue?.idSatForeignTrade }}
          value={supplierValue}
          onValueChange={setSupplierValue}
          required={!!shipmentValue}
          cacheUniqs={[shipmentValue]}
          isClearable={!shipmentValue}
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        <FormAsyncSelect
          className="col-6"
          name="idBeneficiary"
          label="Beneficiary"
          loadOptions={asyncSelectLoadBeneficiaries}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.idBeneficiary}
          noOptionsMessage={() => 'No beneficiaries found'}
          initialValue={beneficiaryValue}
          onValueChange={setBeneficiaryValue}
          required={!shipmentValue}
          isClearable
          readOnly={
            financialTransaction?.isReconciled ||
            isPaymentSituationCanceledOrStandBy ||
            !!financialTransaction?.idSatForeignTradeSupplier
          }
        />
        {!!idFinancialTransaction && (
          <ReadOnlyInput
            className="col-6"
            label="Description"
            value={financialTransaction?.description}
          />
        )}
        {!idFinancialTransaction && (
          <>
            <FormAsyncSelect
              className="col-3"
              name="idRecurringPayment"
              label="Recurring Payments"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              noOptionsMessage={() => 'No recurring payments found'}
              additional={{ id: DomainGroup.RECURRING_PAYMENTS }}
              initialValue={recurringPaymentValue}
              onValueChange={setRecurringPaymentValue}
              required
              readOnly={financialTransaction?.isReconciled}
            />
            <FormInputNumber
              className="col-3"
              label="Schedule"
              name="schedule"
              required={
                recurringPaymentValue.idDomain !== RecurringPaymentsType.NO
              }
              disabled={
                recurringPaymentValue.idDomain === RecurringPaymentsType.NO
              }
              readOnly={financialTransaction?.isReconciled}
            />
          </>
        )}
      </Form>

      {!!idFinancialTransaction &&
        !financialTransaction.idSatForeignTradeSupplier && (
          <div className="col-12">
            <div className="flex flex-row gap-5">
              <FormTitle name="Recurring Payments" />
              <CreateRecurringPayment
                idFinancialTransaction={idFinancialTransaction}
                onSuccess={e => {
                  if (
                    e.idFinancialTransactionGroup !==
                    idFinancialTransactionGroup
                  ) {
                    setIdFinancialTransactionGroup(
                      e.idFinancialTransactionGroup,
                    );
                  } else {
                    recurringPaymentsListRef.current?.refetch();
                  }
                }}
                financialTransactionIsReconciled={
                  financialTransaction?.isReconciled
                }
              />
            </div>

            <RecurringPaymentsList
              ref={recurringPaymentsListRef}
              idFinancialTransaction={idFinancialTransaction}
              idFinancialTransactionGroup={idFinancialTransactionGroup}
            />
          </div>
        )}
    </PageTabContainer>
  );
};

export default GeneralInformation;
