import { gql } from '@apollo/client';

export const getCompanyCashflowDataQuery = gql`
  query getCompanyCashflowData($idCompany: Int!) {
    getCompanyCashflowData(idCompany: $idCompany) {
      idCompany
      balanceAmount
      financialAccounts {
        idFinancialAccount
        balance
        idCurrency2 {
          idCurrency
          abbreviation
        }
      }
    }
  }
`;

export const getFinancialTransactionCashflowDataByIdCompanyQuery = gql`
  query getFinancialTransactionCashflowDataByIdCompany($idCompany: Int!) {
    getFinancialTransactionCashflowDataByIdCompany(idCompany: $idCompany) {
      toPayReceivedFromHavanAdvance
      toPayReceivedFromHavanBalance
      toPayReceivedFromOtherClientsAdvance
      toPayReceivedFromOtherClientsBalance
      toReceiveAdvance
      toReceiveBalance
      toPayAdvance
      toPayBalance
      otherExpenses
    }
  }
`;
