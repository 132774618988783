import { gql } from '@apollo/client';

export const listSatAttachmentsBySatIdQuery = gql`
  query ListSatAttachmentsBySatId($data: ListSatAttachmentsBySatIdInput!) {
    listSatAttachmentsBySatId(data: $data) {
      items
      data {
        idSatAttachment
        idAttachment2 {
          url
          nameGivenByUser
          createdAt
          idType
        }
      }
    }
  }
`;

export const downloadSatRncAttachmentsQuery = gql`
  query Query($idsSatAttachments: [Int]!) {
    downloadSatRncFiles(idsSatAttachments: $idsSatAttachments)
  }
`;
