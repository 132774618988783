import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;

  .red {
    color: red;
  }
`;

export const GridActions = styled.div`
  display: flex;
  gap: 0.57rem;
  margin-left: 0.57rem;

  button {
    background: none;
    border: none;
    color: #7f7f80;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.4, '#7f7f80')};
    }
  }

  .trash-button {
    color: var(--red);

    &:hover {
      color: ${shade(0.2, '#FD2D2D')};
    }
  }
`;
