import { ProductPackageReference } from '../../../interfaces';

// eslint-disable-next-line no-shadow
export enum AddReferenceReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export interface IAddReferenceRef {
  toggleDialog(lastReferenceIndex: number): void;
}

export type AddReferenceReducer = {
  displayDialog: boolean;
  lastReferenceIndex: number;
  packageReference?: ProductPackageReference;
};

export type AddReferenceReducerAction = {
  type: AddReferenceReducerActionKind;
  payload?: {
    lastReferenceIndex?: number;
    idProductPackage: number;
  };
};
