import { gql } from '@apollo/client';

/**
 * Query para listar SAT Suppliers que nao possuem uma incoterm especifica
 */
export const listSatSuppliersBySatIdAndWithDifferentIncoterm = gql`
  query ListSatSuppliersBySatIdAndWithDifferentIncoterm(
    $idSat: Int!
    $idIncoterm: Int!
  ) {
    listSatSuppliersBySatIdAndWithDifferentIncoterm(
      idSat: $idSat
      idIncoterm: $idIncoterm
    ) {
      idSupplier2 {
        sunNumber
      }
    }
  }
`;

/**
 * Query para listar SATs
 */
export const listAllSatsQuery = gql`
  query ListAllSats($listAllSatsInput: ListAllSatsInput!) {
    listAllSats(listAllSatsInput: $listAllSatsInput) {
      items
      data {
        idSat
        satNumber
      }
    }
  }
`;

/**
 * Query para listar Usuarios por Role
 */
export const listUsersByRolesQuery = gql`
  query ListUsersByRoles($listUsersByRolesInput: ListUsersByRolesInput!) {
    listUsersByRoles(listUsersByRolesInput: $listUsersByRolesInput) {
      items
      data {
        idUser
        firstName
        lastName
        username
        email
      }
    }
  }
`;

export const countForeignTradesNotCanceledQuery = gql`
  query CountSatForeignTradesNotCanceledBySat($idSat: Int!) {
    countSatForeignTradesNotCanceledBySat(idSat: $idSat)
  }
`;
