import { gql } from '@apollo/client';

export const createUserGroupQuery = gql`
  mutation CreateUserGroup($data: CreateUserGroupInput!) {
    createUserGroup(data: $data) {
      idUserGroup
    }
  }
`;

export const updateUserGroupQuery = gql`
  mutation UpdateUserGroup($data: UpdateUserGroupInput!) {
    updateUserGroup(data: $data) {
      idUserGroup
    }
  }
`;
