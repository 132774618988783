import { gql } from '@apollo/client';

export const listCommentsByIdParentQuery = gql`
  query ListCommentsByIdParent($data: ListCommentsByIdParentInput!) {
    listCommentsByIdParent(data: $data) {
      idComment
      message
      isRestrictInfo
      createdBy
      createdBy2 {
        username
        avatarUrl
        fullName
        firstName
      }
      createdAt
      updatedAt
      updatedBy2 {
        fullName
      }
      idAttachment2 {
        nameGivenByUser
        url
      }
    }
  }
`;

export const deleteChildCommentQuery = gql`
  mutation DeleteChildComment($idComment: Int!) {
    deleteChildComment(idComment: $idComment)
  }
`;
