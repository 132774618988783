import { gql } from '@apollo/client';

export const listAvailableSatItemsToRncQuery = gql`
  query ListAvailableSatItemsToRnc($idSat: Int!, $idSatRnc: Int!) {
    listAvailableSatItemsToRnc(idSat: $idSat, idSatRnc: $idSatRnc) {
      idSatItem
      stCode
      name
      idImage2 {
        url
      }
    }
  }
`;

export const createSatRncItemsQuery = gql`
  mutation CreateSatRncItems($data: CreateSatRncItemsInput!) {
    createSatRncItems(data: $data) {
      idSatRncItem
    }
  }
`;
