import styled, { css } from 'styled-components';
import { Button, ButtonProps } from 'primereact/button';
import { shade } from 'polished';

// Interface das propriedades do componente
interface CustomButtonProps extends ButtonProps {
  action?: string;
}

export const Container = styled(Button)<CustomButtonProps>`
  background: var(--blue);
  height: 36px;

  border-radius: 4px;
  font-weight: 500;
  color: ${props =>
    props.severity === 'danger' ? 'var(--red)' : 'var(--blue)'} !important;
  background-color: #fff !important;
  border-color: ${props =>
    props.severity === 'danger' ? 'var(--red)' : 'var(--blue)'} !important;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  place-content: center;

  svg {
    margin-right: 7px;
  }

  &:hover {
    background: ${props =>
      shade(
        0.01,
        props.severity === 'danger' ? '#ffe6e6' : '#f0f8ff',
      )} !important;
  }

  ${props =>
    props.action === 'approve' &&
    css`
      background-color: var(--success) !important;
      border-color: var(--success) !important;
      color: var(--white) !important;

      &:hover {
        background-color: ${() => shade(0.2, '#56C300')} !important;
      }
    `}

  ${props =>
    props.action === 'reject' &&
    css`
      background-color: var(--sunasia-red) !important;
      border-color: var(--sunasia-red) !important;
      color: var(--white) !important;

      &:hover {
        background-color: ${() => shade(0.2, '#B1251B')} !important;
      }
    `}
`;
