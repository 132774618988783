import { gql } from '@apollo/client';

export const artworkDashboardQuery = gql`
  query GetSatArtworkProcessDashboard(
    $data: GetSatArtworkProcessDashboardInput
  ) {
    getSatArtworkProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatForeignTrade
        satForeignTradeNumber
        satNumber
        mainProduct
        importerConsignee
        clientNotify
        categoryIcon
        countryOrigin
        dueDate
        category
        newLayout
        realEtd
        satGroup
        typeOfOrder
      }
      items
    }
  }
`;
