import styled from 'styled-components';

interface IBarcodeContainerProps {
  barcodeCursorPointer?: boolean;
  svgVisible?: boolean;
  svgWidth?: number;
  svgHeight?: number;
}

export const Container = styled.div<IBarcodeContainerProps>`
  cursor: ${props => (props.barcodeCursorPointer ? 'pointer' : 'initial')};

  svg {
    border-radius: 6px;
    padding: 4px;
    display: ${props => (props.svgVisible ? 'block' : 'none')};
    width: ${props => (props.svgWidth ? `${props.svgWidth}px` : 'initial')};
    height: ${props => (props.svgHeight ? `${props.svgHeight}px` : 'initial')};
    transition: background-color 0.2s;

    &:hover {
      background: var(--grey);
    }
  }

  .s-empty {
    color: var(--error);
  }
`;
