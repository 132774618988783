import React from 'react';

import { Card } from 'primereact/card';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import PrcWidget from './PrcWidget';

import { prcRoles } from '../../shared/roles/prc';
import ClientQuotationWidget from './ClientQuotationWidget';
import { clientQuotationRoles } from '../../shared/roles/clientQuotation';
import useTitle from '../../hooks/useTitle';
import MainButton from '../../components/MainButton';

const Home: React.FC = () => {
  useTitle('Home');

  const { user, roles } = useAuth();
  const history = useHistory();

  const cqFooter = () => {
    return (
      <MainButton
        label="View All CQs"
        onClick={() => history.push('/client-quotations/list')}
      />
    );
  };

  const prcFooter = () => {
    return (
      <MainButton
        label="View All PRCs"
        onClick={() => history.push('/prcs/list')}
      />
    );
  };

  return (
    <div className="grid p-4 mr-0">
      <h1 className="col-12">{`Welcome, ${user.firstName}`}</h1>

      {roles.entities.includes(prcRoles.idEntity) && (
        <span className="col w-min">
          <Card
            title="PRC Pool"
            subTitle="Showing items that are in the PRC pool or are assigned directly to you."
            footer={prcFooter}
          >
            <PrcWidget />
          </Card>
        </span>
      )}

      {roles.entities.includes(clientQuotationRoles.idEntity) && (
        <span className="col w-min">
          <Card
            title="CQ Pool"
            subTitle="Showing items that are in the CQ pool or are assigned directly to you."
            footer={cqFooter}
          >
            <ClientQuotationWidget />
          </Card>
        </span>
      )}
    </div>
  );
};

export default Home;
