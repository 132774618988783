import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

type ListAllSatForeignTradeSuppliersInput = {
  page: any;
  idSupplier?: number;
  satForeignTradeStatus?: number;
  satStatus?: number;
  notToConsiderId?: number;
  idClient?: number;
};

/**
 * Carrega Sat Foreign Trade Suppliers Resumidos
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados de Domain
 */
export const asyncSelectLoadAllSatForeignTradeSupplier: LoadOptions<
  any,
  GroupBase<any>,
  ListAllSatForeignTradeSuppliersInput
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllSatForeignTradeSuppliersQuery(
        $data: ListAllSatForeignTradeSuppliersInput!
      ) {
        listAllSatForeignTradeSuppliers(data: $data) {
          data {
            idSat
            idSatForeignTrade
            idSatForeignTradeSupplier
            satForeignTradeSupplierNumber
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        idSupplier: pageData?.idSupplier,
        satForeignTradeStatus: pageData?.satForeignTradeStatus,
        satStatus: pageData?.satStatus,
        notToConsiderId: pageData?.notToConsiderId,
        idClient: pageData?.idClient,
        satForeignTradeSupplierNumber: search,
      },
    },
  });

  const filteredOptions = res.data.listAllSatForeignTradeSuppliers.data;
  const quantity = res.data.listAllSatForeignTradeSuppliers.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idSupplier: pageData?.idSupplier,
      satForeignTradeStatus: pageData?.satForeignTradeStatus,
      satStatus: pageData?.satStatus,
      notToConsiderId: pageData?.notToConsiderId,
      idClient: pageData?.idClient,
      satForeignTradeSupplierNumber: search,
    },
  };
};
