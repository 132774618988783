import styled from 'styled-components';

export const Container = styled.div`
  .subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    span {
      color: #7f7f80;
    }
  }

  .flex {
    display: flex;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .paperclip-icon {
    padding: 0.5rem;
  }

  .paperclip-icon {
    background: none !important;
    border: none;
    color: #000000 !important;
    box-shadow: none !important;
  }

  .paperclip-icon:hover {
    opacity: 0.7;
  }

  .paperclip-icon .pi-paperclip {
    font-size: 1.5rem;
    transform: rotate(-46deg);
    margin: 0;
  }
`;

export const Attachments = styled.div`
  .file-extension {
    width: 120px;
    height: 80px;
    color: #6e7a8f;
    font-weight: bold;
    border-radius: 3px;
    background-color: #eaecf0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  margin-bottom: 10px;
`;

export const Attachment = styled.div`
  display: flex;

  img {
    width: 112px;
    height: 80px;
    border: none;
  }

  button {
    background: none;
    border: none;
    color: #7f7f80;
    font-weight: 400;
    text-decoration: underline;
  }

  .image-name {
    font-weight: 500;
    color: #7f7f80;
  }

  .image-info {
    padding: 10px 0px;
    font-weight: 400;
    line-height: 120%;
    color: #7f7f80;
  }
`;

export const Comments = styled.div`
  i {
    font-size: 25px;
    margin-right: 10px;
  }

  .new-comment-box {
    display: flex;
  }

  .button-hide {
    background: none;
    border: none;
    font-weight: 400;
    color: #4c85c8;
  }

  .user-avatar-wrapper {
    margin-right: 8px;

    .round-user-avatar {
      img,
      div {
        width: 32px;
        height: 32px;
        font-size: unset;
      }
    }
  }
`;
