import { useQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import { getSumDataToBankReconciliationListQuery } from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';
import { renderNumber } from '../../../../utils/formatLocale';
import { FinancialTransactionsAsBankReconciliationsFieldsSearch } from '../interfaces';
import { Container } from './styles';

type TotalField = {
  field: string;
  header: string;
};

interface ITotalDetailsProps {
  fieldsSearch: FinancialTransactionsAsBankReconciliationsFieldsSearch;
}

const TotalDetails: React.FC<ITotalDetailsProps> = ({ fieldsSearch }) => {
  const { showError } = useRefHook();

  const { data: totalsData, loading } = useQuery(
    getSumDataToBankReconciliationListQuery,
    {
      variables: {
        data: fieldsSearch,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Bank Reconciliation SUM data',
          detail: errorData.message,
        });
      },
    },
  );

  const totalFieldsConstants: TotalField[] = [
    {
      field: 'reconciledBalanceSoFar',
      header: 'Reconciled Balance So Far',
    },
    {
      field: 'reconciledBalanceSoFarUsd',
      header: 'Reconciled Balance So Far (USD)',
    },
    {
      field: 'provisionalBalanceForToday',
      header: 'Provisional Balance For Today',
    },
    {
      field: 'provisionalBalanceForTodayUsd',
      header: 'Provisional Balance For Today',
    },
    {
      field: 'availableBalance',
      header: 'Available Balance',
    },
  ];

  const renderFields = totalFieldsConstants.map(field => {
    const currenctAbbreviation =
      field.field === 'reconciledBalanceSoFarUsd' ||
      field.field === 'provisionalBalanceForTodayUsd' ||
      field.field === 'availableBalance'
        ? 'USD'
        : totalsData?.getSumDataToBankReconciliationList?.currencyAbbreviation;

    return (
      <span key={field.field}>
        <h4>{field.header}</h4>
        <p>
          {renderNumber(
            totalsData?.getSumDataToBankReconciliationList[field.field],
            'currency',
            currenctAbbreviation,
          )}
        </p>
      </span>
    );
  });

  return (
    <Container>
      {totalsData?.getSumDataToBankReconciliationList && !loading && (
        <div>{renderFields}</div>
      )}
      {loading && (
        <div className="flex gap-2">
          <Skeleton width="9rem" height="2rem" />
          <Skeleton width="9rem" height="2rem" />
          <Skeleton width="9rem" height="2rem" />
          <Skeleton width="9rem" height="2rem" />
          <Skeleton width="9rem" height="2rem" />
        </div>
      )}
    </Container>
  );
};

export default TotalDetails;
