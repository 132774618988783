import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  IPackageReferencesRef,
  ProductPackageReferenceLazyParams,
} from './interfaces';
import {
  deletePackageReferenceQuery,
  listPackageReferencesQuery,
} from './queries';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../../../components/CommentPagination';
import FormTitle from '../../../../../../components/FormTitle';
import MainButton from '../../../../../../components/MainButton';
import Card from '../../../../../../components/Card';
import PackageReferenceContent from './PackageReferenceContent';
import {
  IPackageReferenceContentRef,
  PackageReferenceContentData,
} from './PackageReferenceContent/interfaces';
import {
  PackageReferenceFieldsPermissions,
  ProductPackage,
  ProductPackageReferenceState,
} from '../../interfaces';
import AddReference from './AddReference';
import Loading from '../../../../../../components/Loading';
import { IAddReferenceRef } from './AddReference/interfaces';
import { ProductPackageUnitData } from './PackageReferenceContent/PackageSizeBarcode/PackageTypeCalculated/interfaces';

interface IPackageReferencesProps {
  productPackage: ProductPackage;
  ref: Ref<IPackageReferencesRef>;
  stCode: string;
  idTypePackage?: number;
  userCanChangeFields: boolean;
  fieldsPermissions: PackageReferenceFieldsPermissions;
}

const PackageReferences: React.FC<IPackageReferencesProps> = forwardRef(
  (
    {
      productPackage,
      stCode,
      idTypePackage,
      userCanChangeFields,
      fieldsPermissions,
    },
    ref,
  ) => {
    const packageUnitData: ProductPackageUnitData = {
      packageUnit: productPackage.packageUnit2?.description,
      unitInnerBox: productPackage.unitInnerBox ?? 'CX-???',
      unitMasterBox: productPackage.unitMasterBox ?? 'CX-???',
    };

    const referencesContentRef = useRef<IPackageReferenceContentRef[] | null[]>(
      [],
    );

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        for (
          let index = 0;
          index < referencesContentRef.current.length;
          index += 1
        ) {
          //* Regra do ESLint desabilitada, pois nesse caso ha necessidade
          //* de aguardar a validacao de cada package para indicar ao usuario
          //* qual possui erro
          // eslint-disable-next-line no-await-in-loop
          await referencesContentRef.current[index]?.validateForm();
        }

        return true;
      },
      getDataIfChanged: () => {
        const referencesToUpdate: PackageReferenceContentData[] = [];

        referencesContentRef.current.forEach(contentRef => {
          const referenceData = contentRef?.getDataIfChanged();

          if (referenceData) {
            referencesToUpdate.push(referenceData);
          }
        });

        if (!referencesToUpdate.length) {
          return undefined;
        }

        return referencesToUpdate;
      },
    }));

    const [deletePackageReferenceMutation] = useMutation(
      deletePackageReferenceQuery,
    );

    const addReferenceRef = useRef<IAddReferenceRef>(null);

    const { showError, showSuccess } = useRefHook();

    const [lazyParams, setLazyParams] =
      useState<ProductPackageReferenceLazyParams>({
        first: 0,
        rows: 10,
        page: 0,
      });
    const [references, setReferences] =
      useState<ProductPackageReferenceState>();

    const [loading, setLoading] = useState(false);

    const { loading: queryLoading, refetch } = useQuery(
      listPackageReferencesQuery,
      {
        variables: {
          idProductPackage: productPackage.idProductPackage,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
          },
        },
        errorPolicy: 'all',
        onCompleted: response => {
          if (response.listProductPackageReferencesByIdProductPackage) {
            const { data, items } =
              response.listProductPackageReferencesByIdProductPackage;
            setReferences(previous => {
              return {
                items,
                data: previous?.data ? [...previous.data, ...data] : data,
              };
            });
          }
        },
        onError: err => {
          showError({
            summary: 'Error while getting Product Package references',
            detail: err.message,
          });
        },
      },
    );

    function onPage(e: ICommentPaginationPageParams) {
      setLazyParams(prev => {
        return { ...prev, ...e };
      });
    }

    function setReferenceNumbers() {
      let referenceIndex = 1;
      referencesContentRef.current.forEach(contentRef => {
        contentRef?.setSizeBarcodesReferences(referenceIndex, stCode);

        referenceIndex += contentRef?.getPackageSizeBarcodesCount() || 0;
      });
    }

    async function referencesRefetch() {
      setReferences(undefined);

      // Se usuario ja tiver carregado mais paginas,
      // eh necessario voltar para a pripeira pagina para evitar registros
      // duplicados
      if (lazyParams.page) {
        setLazyParams(prev => {
          return {
            ...prev,
            page: 0,
          };
        });
      } else {
        await refetch();
      }
    }

    async function handleRemoveReference(idPackageReference: number | string) {
      setLoading(true);

      try {
        await deletePackageReferenceMutation({
          variables: {
            idPackageReference,
          },
        });

        showSuccess({ summary: 'Reference removed' });

        await referencesRefetch();
      } catch (error) {
        showError({
          summary: 'Error while removing reference',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    }

    function handleAddReference() {
      let lastReferenceIndex = 0;

      referencesContentRef.current.forEach(content => {
        lastReferenceIndex += content?.getPackageSizeBarcodesCount() || 0;
      });

      addReferenceRef.current?.toggleDialog(lastReferenceIndex);
    }

    return (
      <div className="col-12">
        <span className="flex">
          <FormTitle name="References x Sizes" />

          <MainButton
            className="ml-4"
            label="Add Reference"
            type="button"
            onClick={() => handleAddReference()}
            disabled={!userCanChangeFields}
          />
        </span>
        {references && (
          <>
            {references.data.map((item, index) => {
              return (
                <Card
                  className="mb-2"
                  key={item.idPackageReference}
                  showRemoveButton={userCanChangeFields}
                  removeButtonPosition="top"
                  onRemove={() =>
                    confirmDialog({
                      style: { maxWidth: '350px' },
                      message: `Are you sure you want to delete this item?
                      If you have unsaved changes on any references at this
                      package, removing a reference from it will cause data loss`,
                      header: 'Delete Confirmation',
                      icon: 'pi pi-info-circle',
                      acceptClassName: 'p-button-danger',
                      accept: () =>
                        handleRemoveReference(item.idPackageReference),
                    })
                  }
                >
                  <PackageReferenceContent
                    ref={el => {
                      referencesContentRef.current[index] = el;
                    }}
                    packageReference={item}
                    idTypePackage={idTypePackage}
                    setReferenceNumbers={setReferenceNumbers}
                    packageUnitData={packageUnitData}
                    fieldsPermissions={fieldsPermissions}
                  />
                </Card>
              );
            })}

            <CommentPagination
              first={lazyParams.first}
              rows={lazyParams.rows}
              page={lazyParams.page}
              totalRecords={references.items}
              loading={queryLoading}
              onPage={e => onPage(e)}
            />
          </>
        )}

        <AddReference
          ref={addReferenceRef}
          productPackage={productPackage}
          referencesRefetch={referencesRefetch}
          stCode={stCode}
          packageUnitData={packageUnitData}
          fieldsPermissions={fieldsPermissions}
        />

        {queryLoading && !lazyParams.page && (
          <Card>
            <div className="col grid">
              <Skeleton className="col mr-4" height="47px" />
              <Skeleton className="col mr-4" height="47px" />
              <Skeleton className="col mr-4" height="47px" />
            </div>
          </Card>
        )}

        {loading && <Loading />}
      </div>
    );
  },
);

export default PackageReferences;
