import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import {
  SatForeignTradePayment,
  SellerAdvanceReceivedLazyParams,
} from './interfaces';
import pagination from '../../../../../../config/pagination';
import { getSumAdvanceReceived, listAdvanceReceivedQuery } from './queries';
import {
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
} from '../interfaces';
import { FinancialSellerPaymentType } from '../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import {
  parseCurrencyPtBrNumberColumn,
  parsePercentageColumnPt,
} from '../../../../../../utils/gridColumnsParse';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import { SatFinancialAdvanceReceivedFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface ISellerAdvanceReceivedProps {
  idSat: number;
  selected: boolean;
  totalDispatch: Dispatch<ForeignTradeSupplierTotalReducerAction>;
  fieldsPermissions: SatFinancialAdvanceReceivedFieldsPermissions;
  satCurrency?: string;
}

const SellerAdvanceReceived: React.FC<ISellerAdvanceReceivedProps> = ({
  idSat,
  selected,
  totalDispatch,
  fieldsPermissions,
  satCurrency,
}) => {
  const { toastRef } = useRefHook();

  const userCanSeeBlock =
    fieldsPermissions.percentage.view ||
    fieldsPermissions.amount.view ||
    fieldsPermissions.paymentDate.view ||
    fieldsPermissions.rollingFrom.view ||
    fieldsPermissions.rollingTo.view ||
    fieldsPermissions.shipment.view;

  const [payments, setPayments] = useState<SatForeignTradePayment[]>();

  const [lazyParams, setLazyParams] = useState<SellerAdvanceReceivedLazyParams>(
    pagination.initialLazyParams,
  );

  const [
    loadForeignTradePaymentsData,
    {
      loading: loadingForeignTradePaymentsData,
      data: foreignTradePaymentsData,
    },
  ] = useLazyQuery(listAdvanceReceivedQuery(fieldsPermissions), {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
        idSat,
        idsTypes: [
          FinancialSellerPaymentType.ADVANCE,
          FinancialSellerPaymentType.ROLLING,
        ],
      },
    },
    onCompleted: response => {
      if (response.listSatForeignTradeSupplierPaymentsByIdSat.data) {
        setPayments(response.listSatForeignTradeSupplierPaymentsByIdSat.data);
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Total Seller Advance Received',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [loadGridSumData, { loading: gridSumLoading, data: gridSumSumData }] =
    useLazyQuery(getSumAdvanceReceived, {
      variables: {
        idSat,
        typeIdDomainGroup: DomainGroup.FINANCIAL_SELLER_PAYMENT_TYPES,
      },
      onCompleted: response => {
        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_ADVANCE_RECEIVED_TOTAL,
          payload: {
            sellerAdvanceReceivedTotal:
              response.getSumDataFromSatForeignTradeSupplierAdvanceByIdSat,
          },
        });
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Total Seller Advance Received',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });

        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_ADVANCE_RECEIVED_TOTAL_LOADING,
        });
      },
    });

  const loadGridData = useCallback(async () => {
    totalDispatch({
      type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_ADVANCE_RECEIVED_TOTAL_LOADING,
    });

    loadForeignTradePaymentsData();

    loadGridSumData();
  }, [loadForeignTradePaymentsData, loadGridSumData, totalDispatch]);

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageEvent) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  const totalColumnFooter = (key: string, type?: 'currency' | 'percentage') => {
    if (gridSumLoading) return <Skeleton />;

    const sumData =
      gridSumSumData?.getSumDataFromSatForeignTradeSupplierAdvanceByIdSat[key];

    if (!sumData) {
      return '';
    }

    const formattedSumData = numberToPtString(sumData, type ? 2 : 0);

    switch (type) {
      case 'currency':
        return `${getCurrencySymbol(satCurrency)} ${formattedSumData}`;
      case 'percentage':
        return `${formattedSumData}%`;
      default:
        return formattedSumData;
    }
  };

  function paymentIsRollingFromOrigin(rowData: SatForeignTradePayment) {
    return (
      rowData.idType === FinancialSellerPaymentType.ROLLING &&
      rowData.idSat === idSat &&
      !rowData.isDestination
    );
  }

  function paymentIsRollingFromDestination(rowData: SatForeignTradePayment) {
    return (
      rowData.idType === FinancialSellerPaymentType.ROLLING &&
      rowData.idSatDestination === idSat &&
      rowData.isDestination
    );
  }

  function getRowClassName(rowData: SatForeignTradePayment) {
    return {
      'ar-grid-rolling-from-origin': paymentIsRollingFromOrigin(rowData),
      'ar-grid-rolling-from-destination':
        paymentIsRollingFromDestination(rowData),
    };
  }

  useEffect(() => {
    if (!payments && selected) {
      loadGridData();
    }
  }, [loadGridData, payments, selected]);

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Advance Received" />

      <Grid
        className="col-12 p-datatable-sm"
        emptyMessage="No advance received found"
        loading={loadingForeignTradePaymentsData}
        value={payments}
        lazy
        rows={lazyParams.rows}
        first={lazyParams.first}
        totalRecords={
          !foreignTradePaymentsData
            ? 0
            : foreignTradePaymentsData
                .listSatForeignTradeSupplierPaymentsByIdSat?.items
        }
        scrollable
        scrollHeight="calc(100vh - 272px)"
        onPage={onPage}
        rowClassName={getRowClassName}
      >
        {fieldsPermissions.shipment.view && (
          <Column
            field="idSatForeignTradeSupplier2.satForeignTradeSupplierNumber"
            header="Shipment"
            style={{ width: '16rem' }}
          />
        )}

        {fieldsPermissions.percentage.view && (
          <Column
            field="percentage"
            header="Advance %"
            style={{ width: '8rem' }}
            body={parsePercentageColumnPt}
            footer={() => totalColumnFooter('totalPercentage', 'percentage')}
          />
        )}

        {fieldsPermissions.amount.view && (
          <Column
            field="amount"
            header="Amount"
            style={{ width: '8rem' }}
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
            }
            footer={() => totalColumnFooter('totalAmount', 'currency')}
          />
        )}

        {fieldsPermissions.paymentDate.view && (
          <Column
            field="paymentDate"
            header="Payment Date"
            style={{ width: '8rem' }}
            body={e => parseToLocaleFormat(e.paymentDate)}
          />
        )}

        {fieldsPermissions.rollingFrom.view && (
          <Column
            field="idSatForeignTrade2.satForeignTradeNumber"
            header="Rolling From"
            style={{ width: '8rem' }}
          />
        )}

        {fieldsPermissions.rollingTo.view && (
          <Column
            field="idSatForeignTradeDestination2.satForeignTradeNumber"
            header="Rolling To"
            style={{ width: '8rem' }}
          />
        )}
      </Grid>
    </div>
  ) : (
    <div />
  );
};

export default SellerAdvanceReceived;
