import { gql } from '@apollo/client';

export const getSumDataToBankReconciliationListQuery = gql`
  query GetSumDataToBankReconciliationList(
    $data: ListAllFinancialTransactionsFieldsSearch!
  ) {
    getSumDataToBankReconciliationList(data: $data) {
      reconciledBalanceSoFar
      reconciledBalanceSoFarUsd
      provisionalBalanceForToday
      provisionalBalanceForTodayUsd
      availableBalance
      currencyAbbreviation
    }
  }
`;
