import { DocumentNode, gql } from '@apollo/client';
import { PackageDetailsFieldsPermissions } from './interfaces';

export const listProductPackagesQuery = (
  fieldsPermissions: PackageDetailsFieldsPermissions,
): DocumentNode => gql`
  query ListProductPackagesByProductId(
    $data: ListProductPackagesByProductIdInput!
  ) {
    listProductPackagesByProductId(data: $data) {
      items
      data {
        idProductPackage
        idTypePackage
        idTypePackage2 {
          idDomain
          description
        }
        isDefault
        ${fieldsPermissions.packageCostPrice.view ? 'packageCostPrice' : ''}
        packageUnit
        ${
          fieldsPermissions.packageUnit.view
            ? `
        packageUnit2 {
          idDomain
          description
        }
        `
            : ''
        }
        packagePcQuantity
        ${fieldsPermissions.packageLength.view ? 'packageLength' : ''}
        ${fieldsPermissions.packageWidth.view ? 'packageWidth' : ''}
        ${fieldsPermissions.packageHeight.view ? 'packageHeight' : ''}
        ${fieldsPermissions.packageGrossWeight.view ? 'packageGrossWeight' : ''}
        ${fieldsPermissions.innerQuantity.view ? 'innerQuantity' : ''}
        ${fieldsPermissions.innerLength.view ? 'innerLength' : ''}
        ${fieldsPermissions.innerWidth.view ? 'innerWidth' : ''}
        ${fieldsPermissions.innerHeight.view ? 'innerHeight' : ''}
        ${fieldsPermissions.innerGrossWeight.view ? 'innerGrossWeight' : ''}
        ${fieldsPermissions.isInnerDisplay.view ? 'isInnerDisplay' : ''}
        ${fieldsPermissions.innerBox.view ? 'innerBox' : ''}
        ${fieldsPermissions.masterQuantity.view ? 'masterQuantity' : ''}
        ${fieldsPermissions.masterLength.view ? 'masterLength' : ''}
        ${fieldsPermissions.masterWidth.view ? 'masterWidth' : ''}
        ${fieldsPermissions.masterHeight.view ? 'masterHeight' : ''}
        ${fieldsPermissions.masterCbm.view ? 'masterCbm' : ''}
        ${fieldsPermissions.masterNetWeight.view ? 'masterNetWeight' : ''}
        ${fieldsPermissions.masterGrossWeight.view ? 'masterGrossWeight' : ''}
        ${fieldsPermissions.palletQuantity.view ? 'palletQuantity' : ''}
        ${fieldsPermissions.palletLength.view ? 'palletLength' : ''}
        ${fieldsPermissions.palletWidth.view ? 'palletWidth' : ''}
        ${fieldsPermissions.palletHeight.view ? 'palletHeight' : ''}
        ${fieldsPermissions.palletGrossWeight.view ? 'palletGrossWeight' : ''}
        ${fieldsPermissions.palletDisplay.view ? 'palletDisplay' : ''}
        ${fieldsPermissions.packageNote.view ? 'packageNote' : ''}
        unitInnerBox
        unitMasterBox
      }
    }
  }
`;

export const updateProductPackagesQuery = gql`
  mutation Mutation($data: [UpdateProductPackagesInput]!) {
    updateProductPackages(data: $data)
  }
`;

export const deleteProductPackageQuery = gql`
  mutation DeleteProductPackage($idProductPackage: Int!) {
    deleteProductPackage(idProductPackage: $idProductPackage)
  }
`;
