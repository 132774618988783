import { Column, ColumnEditorOptions } from 'primereact/column';
import { useCallback, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import GridInput from '../../../../../../components/Grid/GridInput';
import { removeTimeFromIsoDate } from '../../../../../../utils/convertDate';
import { gridConstants } from '../../../../../../components/Grid/constants';

interface IReferenceSizeGridProps {
  data: any;
  columns: any[];
  handleColumnSize(column: string): { width: string };
  handleReplication: (field: string, value: string) => void;
  onReferenceEditorValueChange: (pvalue: any) => void;
}

const ReferenceSizeGrid: React.FC<IReferenceSizeGridProps> = ({
  data,
  columns,
  handleColumnSize,
  handleReplication,
  onReferenceEditorValueChange,
}) => {
  const [references, setReferences] = useState(data);

  const onEditorValueChange = useCallback(
    (options: ColumnEditorOptions, value: any) => {
      const newReferences = options.props.value;
      const newReference = newReferences[options.rowIndex];
      newReference[options.field] = value;

      onReferenceEditorValueChange(newReference);
      setReferences([...newReferences]);
    },
    [onReferenceEditorValueChange],
  );

  const parseStCodeColumn = (rowData: any) => {
    return rowData.reference && <span>{rowData.reference}</span>;
  };

  const parseDateInputField = useCallback(
    (options: ColumnEditorOptions, field: string) => {
      return (
        <GridInput
          field={field}
          gridName="satArtworkGridItems"
          defaultValue={
            options.rowData[field]
              ? removeTimeFromIsoDate(options.rowData[field])
              : ''
          }
          onReplicate={handleReplication}
          onChange={e => onEditorValueChange(options, e.currentTarget.value)}
          onClick={e => e.stopPropagation()}
          showReplicateButton
          type="date"
        />
      );
    },
    [handleReplication, onEditorValueChange],
  );

  const handleReferenceEditor = useCallback(
    (options: ColumnEditorOptions, column: string) => {
      switch (true) {
        case column === 'stCode':
          return parseStCodeColumn(options.rowData);
        case column === 'commercialReview':
        case column === 'designReview':
        case column === 'finalArtwork':
        case column === 'sketches':
          return parseDateInputField(options, column);
        default:
          return options.rowData[column];
      }
    },
    [parseDateInputField],
  );

  const dynamicColumns = columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          style={handleColumnSize(col.field)}
          editor={options => handleReferenceEditor(options, col.field)}
        />
      )
    );
  });

  return (
    <DataTable
      value={references}
      scrollHeight={gridConstants.internalGridScrollHeight}
      editMode="row"
      editingRows={references}
      onRowEditChange={() => ''}
      paginator={false}
    >
      {dynamicColumns}
    </DataTable>
  );
};

export default ReferenceSizeGrid;
