import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import FormInput from '../../../../components/FormInput';
import MainButton from '../../../../components/MainButton';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { Container, PricingItem } from './styles';
import FormCheckbox from '../../../../components/FormCheckbox';
import { IProduct, IProductSupplier } from '../interfaces';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormInputNumber from '../../../../components/FormInputNumber';
import generateRandomString from '../../../../utils/generateRandomString';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { productRoles } from '../../../../shared/roles/product';
import { showField } from '../../../../utils/showField';
import { definePermissionAndDisableField } from '../../../../utils/disableField';
import { asyncSelectLoadSuppliers } from '../../../../shared/querys/supplier';
import { asyncSelectLoadCurrencies } from '../../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import ReadOnlyInput from '../../../../components/ReadOnlyInput';
import { parseToLocaleFormat } from '../../../../utils/dateUtil';

interface IPricingProps extends PageTabContainerProps {
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  roleEntityFields: IRoleEntityField[];
  userCanChangeFields: boolean;
}

const Pricing: React.FC<IPricingProps> = ({
  selected,
  product,
  changePropertyValue,
  roleEntityFields,
  userCanChangeFields,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  const [cotPriceHasValue, setCotPriceHasValue] = useState(false);

  /**
   * Adiciona novo pricing
   */
  function addPricing() {
    // Cria novo array de pricings com novo pricing incluso
    let newPricing: IProductSupplier[];
    if (product.productSuppliers) {
      newPricing = [
        ...product.productSuppliers,
        {
          idProductSupplier: generateRandomString(4),
          isDefault: !product.productSuppliers.length,
        } as IProductSupplier,
      ];
    } else {
      newPricing = [
        {
          idProductSupplier: generateRandomString(4),
          isDefault: true,
        } as IProductSupplier,
      ];
    }

    // Altera array atual pelo novo no objeto de product
    changePropertyValue('productSuppliers', newPricing);
  }

  /**
   * Remove pricing
   * @param index Indice do pricing
   */
  function removePricing(index: number) {
    // Copia array de pricings
    const newPricing = [...product.productSuppliers];
    // Remove item do array copiado
    newPricing.splice(index, 1);
    // Altera array atual pelo novo no objeto de product
    changePropertyValue('productSuppliers', newPricing);
  }

  /**
   * Efetua calculo para campos SQM
   * @param index Indice do campo
   * @param originField Campo de origem
   * @param destinationField Campo de destino
   */
  function handleSqmField(
    index: number,
    originField: string,
    destinationField: string,
  ) {
    // Valor de m2
    const m2PerPiece = formRef.current?.getFieldValue('m2Pc');
    // Valida se produto é m2
    if (product.isM2 && m2PerPiece) {
      // Busca valor do campo
      const fieldValue = formRef.current?.getFieldValue(
        `productSuppliers[${index}].${originField}`,
      );

      // Valide se ha valor no campo
      if (fieldValue) {
        const calcResult = parseFloat((fieldValue / m2PerPiece).toFixed(4));

        // Adiciona valor resultante no campo sqm
        formRef.current?.setFieldValue(
          `productSuppliers[${index}].${destinationField}`,
          calcResult,
        );
      }
    }
  }

  /**
   * Atualiza valores de profit
   * @param index Indice do input
   */
  function updateProfit(index: number) {
    // Valor sun price
    const sunPrice = formRef.current?.getFieldValue(
      `productSuppliers[${index}].sunPrice`,
    );

    const cotPrice = formRef.current?.getFieldValue(
      `productSuppliers[${index}].cotPrice`,
    );

    if (!sunPrice || !cotPrice) {
      return;
    }

    const result = parseFloat(
      (((sunPrice - cotPrice) / sunPrice) * 100).toFixed(2),
    );

    // Adiciona valor resultante no campo sqm
    formRef.current?.setFieldValue(`productSuppliers[${index}].profit`, result);
  }

  /**
   * Atualiza valores de Markup
   * @param index Indice do input
   */
  function updateMarkup(index: number) {
    // Valor sun price
    const sunPrice = formRef.current?.getFieldValue(
      `productSuppliers[${index}].sunPrice`,
    );

    const cotPrice = formRef.current?.getFieldValue(
      `productSuppliers[${index}].cotPrice`,
    );

    if (!sunPrice || !cotPrice) {
      return;
    }

    const result = parseFloat(
      (((sunPrice - cotPrice) / cotPrice) * 100).toFixed(2),
    );

    // Adiciona valor resultante no campo sqm
    formRef.current?.setFieldValue(`productSuppliers[${index}].markup`, result);
  }

  function handleIsDefaultChange(
    e: CheckboxChangeEvent,
    index: number,
  ): boolean {
    if (product.productSuppliers.length === 1 && e.checked === true) {
      return true;
    }

    if (e.checked === true) {
      const foundIndex = product.productSuppliers.findIndex(
        productSupplier => productSupplier.isDefault === true,
      );

      const newProductSuppliers = [...product.productSuppliers];

      if (foundIndex !== -1) {
        formRef.current?.setFieldValue(
          `productSuppliers[${foundIndex}].isDefault`,
          false,
        );
        newProductSuppliers[foundIndex].isDefault = false;
      }

      newProductSuppliers[index].isDefault = e.checked;

      changePropertyValue('productSuppliers', newProductSuppliers);

      return true;
    }

    return false;
  }

  /**
   * Adiciona item de pricing automaticamente ao criar novo produto
   */
  useEffect(() => {
    if (!product.productSuppliers) {
      // Cria novo array de pricings com novo pricing incluso
      const newPricing = [
        {
          idProductSupplier: generateRandomString(4),
          isDefault: true,
        } as IProductSupplier,
      ];

      // Altera array atual pelo novo no objeto de product
      changePropertyValue('productSuppliers', newPricing);
    }
  }, [changePropertyValue, product.productSuppliers]);

  function handleIdSupplierChange(e: any, index: number) {
    const newProductSuppliers = [...product.productSuppliers];
    newProductSuppliers[index].idSupplier = e.idSupplier;

    // Altera array atual pelo novo no objeto de product
    changePropertyValue('productSuppliers', newProductSuppliers);
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        {product.productSuppliers &&
          product.productSuppliers.map((productSupplier, index) => {
            return (
              <PricingItem key={productSupplier.idProductSupplier}>
                <div>
                  <div className="supplierDiv">
                    {showField(
                      productRoles.fields.idFieldIdSupplier,
                      roleEntityFields,
                    ) && (
                      <>
                        <FormAsyncSelect
                          className="supplierInput"
                          name={`productSuppliers[${index}].idSupplier`}
                          label="Supplier"
                          required
                          debounceTimeout={1000}
                          loadOptions={asyncSelectLoadSuppliers}
                          initialValue={productSupplier.idSupplier2}
                          getOptionLabel={(option: any) => option.sunNumber}
                          getOptionValue={(option: any) => option.idSupplier}
                          additional={{
                            page: 1,
                          }}
                          noOptionsMessage={() => 'No suppliers found'}
                          onValueChange={e => handleIdSupplierChange(e, index)}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldIdSupplier,
                              roleEntityFields,
                              productSupplier.idProductSupplier,
                            ) || !userCanChangeFields
                          }
                        />
                        {product.productSuppliers[index].idSupplier && (
                          <Link
                            className="link"
                            to={`/suppliers/list/${product.productSuppliers[index].idSupplier}`}
                            target="_blank"
                          >
                            <FiExternalLink size={15} title="Open Supplier" />
                          </Link>
                        )}
                      </>
                    )}
                  </div>

                  {showField(
                    productRoles.fields.idFieldPurchaseIncoterm,
                    roleEntityFields,
                  ) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`productSuppliers[${index}].idIncoterm`}
                      label="Purchase Incoterm"
                      debounceTimeout={1000}
                      loadOptions={asyncSelectLoadDomains}
                      initialValue={productSupplier.idIncoterm2}
                      getOptionLabel={(option: any) => option.description}
                      getOptionValue={(option: any) => option.idDomain}
                      required={cotPriceHasValue}
                      additional={{
                        page: 1,
                        id: 4,
                      }}
                      noOptionsMessage={() => 'No Incoterms found'}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldPurchaseIncoterm,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}
                  {showField(
                    productRoles.fields.idFieldPurchaseCurrency,
                    roleEntityFields,
                  ) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`productSuppliers[${index}].idPurchaseCurrency`}
                      label="Purchase Currency"
                      debounceTimeout={1000}
                      loadOptions={asyncSelectLoadCurrencies}
                      initialValue={productSupplier.idPurchaseCurrency2}
                      getOptionLabel={(option: any) =>
                        `${option.abbreviation} - ${option.currency}`
                      }
                      getOptionValue={(option: any) => option.idCurrency}
                      required={cotPriceHasValue}
                      additional={{
                        page: 1,
                      }}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldPurchaseCurrency,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                      noOptionsMessage={() => 'No currencies found'}
                    />
                  )}
                  {showField(
                    productRoles.fields.idFieldCotPrice,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="generalInput"
                      name={`productSuppliers[${index}].cotPrice`}
                      label="Cot Price"
                      decimalScale={3}
                      onBlur={() => {
                        handleSqmField(index, 'cotPrice', 'cotPriceSqm');
                        updateProfit(index);
                        updateMarkup(index);
                      }}
                      onValueChange={e => setCotPriceHasValue(!!e.value)}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldCotPrice,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldCotPrice,
                    roleEntityFields,
                  ) &&
                    product.isM2 && (
                      <FormInputNumber
                        className="generalInput"
                        name={`productSuppliers[${index}].cotPriceSqm`}
                        label="Cot Price SQM"
                        readOnly
                        decimalScale={4}
                      />
                    )}

                  {showField(
                    productRoles.fields.idFieldCotPriceNotes,
                    roleEntityFields,
                  ) && (
                    <FormInputTextArea
                      name={`productSuppliers[${index}].cotPriceNotes`}
                      label="Cot Price Notes"
                      rows={5}
                      cols={35}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldCotPriceNotes,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldMarkup,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="generalInput"
                      name={`productSuppliers[${index}].markup`}
                      label="Markup"
                      decimalScale={2}
                      fixedDecimalScale
                      suffix="%"
                      readOnly
                    />
                  )}
                </div>
                <div>
                  {showField(
                    productRoles.fields.idFieldAging,
                    roleEntityFields,
                  ) && (
                    <FormInput
                      className="generalInput"
                      name={`productSuppliers[${index}].dtAging`}
                      label="Aging"
                      type="date"
                      required
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldAging,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldSalesIncoterm,
                    roleEntityFields,
                  ) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`productSuppliers[${index}].idSalesIncoterm`}
                      label="Sales Incoterm"
                      debounceTimeout={1000}
                      loadOptions={asyncSelectLoadDomains}
                      initialValue={productSupplier.idSalesIncoterm2}
                      getOptionLabel={(option: any) => option.description}
                      getOptionValue={(option: any) => option.idDomain}
                      required
                      additional={{
                        page: 1,
                        id: 4,
                      }}
                      noOptionsMessage={() => 'No Incoterms found'}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldSalesIncoterm,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldSalesCurrency,
                    roleEntityFields,
                  ) && (
                    <FormAsyncSelect
                      className="generalInput"
                      name={`productSuppliers[${index}].idSalesCurrency`}
                      label="Sales Currency"
                      debounceTimeout={1000}
                      loadOptions={asyncSelectLoadCurrencies}
                      initialValue={productSupplier.idSalesCurrency2}
                      getOptionLabel={(option: any) =>
                        `${option.abbreviation} - ${option.currency}`
                      }
                      getOptionValue={(option: any) => option.idCurrency}
                      required
                      additional={{
                        page: 1,
                      }}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldSalesCurrency,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                      noOptionsMessage={() => 'No currencies found'}
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldSunPrice,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="generalInput"
                      name={`productSuppliers[${index}].sunPrice`}
                      label="Sun Price"
                      required
                      decimalScale={3}
                      fixedDecimalScale
                      onBlur={() => {
                        handleSqmField(index, 'sunPrice', 'sunPriceSqm');
                        updateProfit(index);
                        updateMarkup(index);
                      }}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldSunPrice,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldSunPrice,
                    roleEntityFields,
                  ) &&
                    product.isM2 && (
                      <FormInputNumber
                        className="generalInput"
                        name={`productSuppliers[${index}].sunPriceSqm`}
                        label="Sun Price SQM"
                        readOnly
                        decimalScale={4}
                      />
                    )}

                  {showField(
                    productRoles.fields.idFieldSunPriceNotes,
                    roleEntityFields,
                  ) && (
                    <FormInputTextArea
                      name={`productSuppliers[${index}].sunPriceNotes`}
                      label="Sun Price Notes"
                      rows={5}
                      cols={35}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldSunPriceNotes,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}
                  {showField(
                    productRoles.fields.idFieldProfit,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="generalInput"
                      name={`productSuppliers[${index}].profit`}
                      label="Profit"
                      decimalScale={2}
                      fixedDecimalScale
                      readOnly
                    />
                  )}
                </div>
                <div>
                  {showField(
                    productRoles.fields.idFieldMoq,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="generalInput"
                      name={`productSuppliers[${index}].moq`}
                      label="MOQ"
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldMoq,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}
                  {showField(
                    productRoles.fields.idFieldMoqNote,
                    roleEntityFields,
                  ) && (
                    <FormInputTextArea
                      name={`productSuppliers[${index}].moqNote`}
                      label="MOQ Note"
                      rows={5}
                      cols={35}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldMoqNote,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                    />
                  )}
                  <ReadOnlyInput
                    label="Updated At"
                    value={parseToLocaleFormat(
                      product?.productSuppliers[index]?.updatedAt ||
                        product?.productSuppliers[index]?.createdAt ||
                        '',
                    )}
                  />
                  {showField(
                    productRoles.fields.idFieldIsDefault,
                    roleEntityFields,
                  ) && (
                    <FormCheckbox
                      name={`productSuppliers[${index}].isDefault`}
                      label="Default"
                      className="activeCheckbox"
                      initialValue={productSupplier.isDefault}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldIsDefault,
                          roleEntityFields,
                          productSupplier.idProductSupplier,
                        ) || !userCanChangeFields
                      }
                      onValueChange={e => handleIsDefaultChange(e, index)}
                    />
                  )}
                </div>
                <div className="button-div">
                  <button
                    type="button"
                    onClick={() => removePricing(index)}
                    disabled={!userCanChangeFields}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>
              </PricingItem>
            );
          })}

        <MainButton
          label="Add pricing"
          onClick={() => addPricing()}
          disabled={!userCanChangeFields}
          type="button"
        />
      </Container>
    </PageTabContainer>
  );
};

export default Pricing;
