export default function generateRandomString(length: number): string {
  // Lista de caracteres desejaveis na string
  const wishlist = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  // Gera string com base nos parametros acima
  const generatedString = Array.from(
    crypto.getRandomValues(new Uint32Array(length)),
  )
    .map(x => wishlist[x % wishlist.length])
    .join('');

  return generatedString;
}
