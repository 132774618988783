import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { confirmOrderOptions } from '../../../shared/options/confirmOrderOptions';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

export const registerFiltersData: ColumnData[] = [
  {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'confirmOrder',
    header: 'Confirm Order',
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  {
    field: 'suppliers',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'suppliers',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'clientOrder',
    header: 'Client Order',
    advancedFilterField: 'clientOrder',
  },
  {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'exportStatus',
    header: 'Export Status',
    value: undefined,
    advancedFilterField: 'idExportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.EXPORT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    value: undefined,
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    value: undefined,
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
  {
    field: 'exporter',
    header: 'Exporter',
    value: undefined,
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  {
    field: 'clientDepartment',
    header: 'Client Department',
    value: undefined,
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  {
    field: 'firstBuyer',
    header: 'First Buyer',
    value: undefined,
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  {
    field: 'secondBuyer',
    header: 'Second Buyer',
    value: undefined,
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'designUser',
    header: 'Design User',
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
];
