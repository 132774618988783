/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
import { MenuItem } from 'primereact/menuitem';
import { SplitButton } from 'primereact/splitbutton';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AiOutlineCheckCircle,
  AiOutlineMinusCircle,
  AiOutlineShoppingCart,
} from 'react-icons/ai';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { object, number, string, array, ValidationError } from 'yup';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import StatusTag from '../../../components/StatusTag';
import { IPrc, IPrcFormData, IPrcItem, IPrcRouteParams } from './interfaces';

import Loading from '../../../components/Loading';
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu';
import PickListProduct, {
  IPickListProductData,
  IPickListProductRef,
} from '../../../components/PickListProduct';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import { PrcFileType, PrcStatus, PrcType } from '../../../shared/enums/domains';
import {
  AddQuotationUserAction,
  AnalisarAction,
  AprovarAction,
  PurchaseAddQuotationUserAction,
} from '../../../shared/enums/prcUserAction';
import { Role } from '../../../shared/enums/role';
import {
  createClientQuotation,
  createClientQuotationQuery,
} from '../../../shared/mutations/clientQuotation';
import { toolbarOptions } from '../../../shared/options/editorToolbarOptions';
import { getAttachmentDownloadLinkQuery } from '../../../shared/querys/attachment';
import { prcRoles } from '../../../shared/roles/prc';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../utils/getValidationErrors';
import userHasRole, { userHasSomeRole } from '../../../utils/userHasRole';
import Attachments from './Attachments';
import GeneralInformation from './GeneralInformation';
import PrcQuotations from './PrcQuotations';
import { Container, CrudForm } from './styles';
import Updates from './Updates';
import useTitle from '../../../hooks/useTitle';
import {
  createPrcQuery,
  downloadPrcFileLogQuery,
  downloadPrcFileTemplateQuery,
  listPrcQuery,
  prcAddQuotationActionQuery,
  prcAnalisarActionQuery,
  prcAprovarActionQuery,
  prcElaborarActionQuery,
  prcPoolCommercialActionQuery,
  prcPoolManagerActionQuery,
  prcPoolProcurementActionQuery,
  prcPoolPurchaseActionQuery,
  prcPurchaseAddQuotationActionQuery,
  reactivatePrcQuery,
  updatePrcQuery,
  uploadPrcFileQuery,
} from './queries';
import userHasPermission from '../../../utils/userHasPermission';
import { FileUploadResponse } from '../../../components/FileUpload/interfaces';

// eslint-disable-next-line no-shadow
export enum PrcReducerActionKind {
  CHANGE_DISPLAY_ENTER_A_COMMENT = 'changeDisplayEnterAComment',
  CHANGE_DISPLAY_SELECT_PRC_ITEMS = 'changeDisplaySelectPrcItems',
  CHANGE_DISPLAY_FROM_SELECT_PRC_ITEMS_TO_ENTER_A_COMMENT = 'changeDisplayFromSelectPrcItemsToEnterAComment',
  CHANGE_DISPLAY_FROM_ENTER_A_COMMENT_TO_SELECT_PRC_ITEMS = 'changeDisplayFromEnterACommentToSelectPrcItems',
  CHANGE_USER_COMMENT = 'changeUserComment',
  SET_PRC_ITEMS = 'setPrcItems',
}

export interface IPrcReducerAction {
  type: PrcReducerActionKind;
  payload?: {
    userComment?: string;
    userAction?: string;
    productPickListData?: IPickListProductData[];
  };
}

interface IPrcReducer {
  displayEnterACommentDialog: boolean;
  displaySelectPrcItemsDialog: boolean;
  userComment?: string;
  userAction?: string;
  prcItems?: IPrcItem[];
  productPickListData?: IPickListProductData[];
}

const Prc: React.FC = () => {
  // ID da PRC
  const idPrc = parseInt(useParams<IPrcRouteParams>().idPrc, 10);

  const defaultPageTitle = `${idPrc ? 'Manage' : 'Create'} PRC`;

  // Estado de PRC
  const [prc, setPrc] = useState<IPrc>({} as IPrc);

  useTitle(
    prc?.prcCode ? `${prc.prcCode} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // ID do usuario logado
  const { idUser, user, roles } = useAuth();

  // URL params
  const location = useLocation();

  // Redirect
  const history = useHistory();

  // Referencia ao toast e formulario
  const { formRef, showWarn, showError, showSuccess } = useRefHook();

  // Referencia ao componente de product picklist
  const productPickListRef = useRef<IPickListProductRef>(null);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // Estado que define se usuario pode alterar valores
  const [userCanChange, setUserCanChange] = useState(true);

  const reducerInitialState: IPrcReducer = {
    displayEnterACommentDialog: false,
    displaySelectPrcItemsDialog: false,
  };

  const { idPermissionCancelPrc } = prcRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    prcRoles.idEntity,
  );

  const prcReducer = useCallback(
    (state: IPrcReducer, action: IPrcReducerAction) => {
      switch (action.type) {
        case PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT:
          return {
            ...state,
            displayEnterACommentDialog: !state.displayEnterACommentDialog,
            userAction: action.payload?.userAction,
            userComment: undefined,
            prcItems: undefined,
            productPickListData: undefined,
          };
        case PrcReducerActionKind.CHANGE_DISPLAY_SELECT_PRC_ITEMS:
          return {
            ...state,
            displaySelectPrcItemsDialog: !state.displaySelectPrcItemsDialog,
            userAction: action.payload?.userAction,
            prcItems: undefined,
            userComment: undefined,
            productPickListData: undefined,
          };
        case PrcReducerActionKind.CHANGE_DISPLAY_FROM_SELECT_PRC_ITEMS_TO_ENTER_A_COMMENT:
          if (!action.payload?.productPickListData?.length) {
            showWarn({
              summary: 'PRC Items',
              detail: 'You need to add at least one product to this PRC',
            });
            return state;
          }

          return {
            ...state,
            displaySelectPrcItemsDialog: false,
            displayEnterACommentDialog: true,
            prcItems: action.payload.productPickListData.map(item => {
              return { idSupplier: item.idSupplier, idProduct: item.idProduct };
            }),
            productPickListData: action.payload.productPickListData,
          };

        case PrcReducerActionKind.CHANGE_DISPLAY_FROM_ENTER_A_COMMENT_TO_SELECT_PRC_ITEMS:
          return {
            ...state,
            displayEnterACommentDialog: false,
            displaySelectPrcItemsDialog: true,
          };
        case PrcReducerActionKind.CHANGE_USER_COMMENT:
          return {
            ...state,
            userComment: action.payload?.userComment,
          };
        default:
          throw new Error();
      }
    },
    [showWarn],
  );

  const [prcState, prcDispatch] = useReducer(prcReducer, reducerInitialState);

  // Se usuario possui roles especificas da logica
  const userHasSpecificProfiles = userHasSomeRole(
    [Role.PROCUREMENT, Role.PURCHASE, Role.MANAGER],
    roles.rolesUser,
  );

  // Dados para retornar para listagem de PRCs
  const initialPage = parseInt(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'List of PRCs',
      path:
        initialPage && initialFirst
          ? `/prcs/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/prcs/list',
    },
    {
      name: defaultPageTitle,
      path: location.pathname,
    },
  ];

  // Itens do menu lateral
  const menuItems = useMemo(() => {
    const itemsList: IPageMenuItem[] = [];

    itemsList.push({
      key: 'generalInformation',
      label: 'General Information',
    });

    // Se PRC ja estiver criada, exibe demais itens do menu
    if (prc.idPrc) {
      if (prc.idPrcType !== PrcType.CATALOGUE) {
        itemsList.push({
          key: 'prcQuotations',
          label: 'PRC Quotations',
        });
      }
      itemsList.push({
        key: 'updates',
        label: 'Updates',
      });

      itemsList.push({
        key: 'attachments',
        label: 'Attachments',
      });
    }

    return itemsList;
  }, [prc.idPrc, prc.idPrcType]);

  /**
   * Valida se usuario pode alterar campos da PRC
   * @param idStatus ID do status da PRC
   * @param idResponsibleUser Usuario que esta com a PRC
   */
  function validateUserCanChange(idStatus: number, idResponsibleUser: number) {
    switch (idStatus) {
      case PrcStatus.DRAFT:
        setUserCanChange(idResponsibleUser === idUser);
        break;
      default:
        setUserCanChange(false);
        break;
    }
  }

  const [getFile, { loading: getFileLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting file',
          detail: error.message,
        });
      },
    },
  );

  /**
   * Query para buscar arquivo do storage pelo servidor
   */
  const [downloadPrcFile, { loading: downloadPrcFileLoading }] = useLazyQuery(
    downloadPrcFileLogQuery,
    {
      onCompleted: async response => {
        if (response.downloadPrcFileLog) {
          window.open(response.downloadPrcFileLog, '_blank');
        } else {
          showError({ summary: 'Error while getting PRC file' });
        }
      },
      onError: error => {
        showError({
          summary: 'Error while getting PRC file',
          detail: error.message,
        });
      },
    },
  );

  /**
   * Busca dados da PRC
   */
  const [loadPrcData, { loading: prcLoading, refetch: prcRefetch }] =
    useLazyQuery(listPrcQuery(userHasSpecificProfiles), {
      // Garante que loading sera atualizado mesmo no refetch
      notifyOnNetworkStatusChange: true,
      onCompleted: response => {
        if (response.listPrcById) {
          setPrc(response.listPrcById);
          validateUserCanChange(
            response.listPrcById.idStatus,
            response.listPrcById.idResponsibleUser,
          );
        } else {
          showError({ summary: 'PRC not found' });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting PRC data',
          detail: errorData.message,
        });
      },
    });

  /**
   * Query para buscar arquivo do storage pelo servidor
   */
  const [downloadPrcFileTemplate, { loading: downloadPrcFileTemplateLoading }] =
    useLazyQuery(downloadPrcFileTemplateQuery, {
      onCompleted: async response => {
        if (response.downloadPrcFileTemplate) {
          window.open(response.downloadPrcFileTemplate, '_blank');
        } else {
          showError({ summary: 'Error while getting PRC file template' });
        }
      },
      onError: error => {
        showError({
          summary: 'Error while getting PRC file template',
          detail: error.message,
        });
      },
    });

  // cria método para chamar a mutation
  const [createPrcMutation] = useMutation(createPrcQuery);
  const [updatePrcMutation] = useMutation(updatePrcQuery);
  const [prcElaborarActionMutation] = useMutation(prcElaborarActionQuery);
  const [prcPoolProcurementActionMutation] = useMutation(
    prcPoolProcurementActionQuery,
  );
  const [prcAddQuotationActionMutation] = useMutation(
    prcAddQuotationActionQuery,
  );
  const [prcPoolPurchaseActionMutation] = useMutation(
    prcPoolPurchaseActionQuery,
  );
  const [prcPurchaseAddQuotationActionMutation] = useMutation(
    prcPurchaseAddQuotationActionQuery,
  );
  const [prcPoolManagerActionMutation] = useMutation(prcPoolManagerActionQuery);
  const [prcAnalisarActionMutation] = useMutation(prcAnalisarActionQuery);
  const [prcPoolCommercialActionMutation] = useMutation(
    prcPoolCommercialActionQuery,
  );
  const [prcAprovarActionMutation] = useMutation(prcAprovarActionQuery);
  const [uploadPrcFileMutation] = useMutation(uploadPrcFileQuery);
  const [createClientQuotationMutation] = useMutation(
    createClientQuotationQuery,
  );

  const [reactivatePrcMutation] = useMutation(reactivatePrcQuery);

  const urlTabParam = new URLSearchParams(location.search).get('tab');

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);

  // Submit do formulario
  const handleSubmit = useCallback(
    async (data: IPrcFormData) => {
      setLoading(true);
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = object().shape({
          idPrcType: number().nullable().required(requiredFieldErrorMessage),
          mainProduct: string().required(requiredFieldErrorMessage),
          idCategory: string().required(requiredFieldErrorMessage),
          idClient: number().nullable().required(requiredFieldErrorMessage),
          prcOffices: array().min(1, requiredFieldErrorMessage),
          idPriority: number().nullable().required(requiredFieldErrorMessage),
          idPartnerLicensor: number()
            .nullable()
            .when('indLicensed', {
              is: true,
              then: number().nullable().required(requiredFieldErrorMessage),
            }),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Variavel de resposta
        let response;

        // Dados da PRC
        const prcData = {
          idPrcType: data.idPrcType,
          mainProduct: data.mainProduct,
          idClient: data.idClient,
          prcOffices: data.prcOffices,
          idPriority: data.idPriority,
          indLicensed: !!data.indLicensed,
          idPartnerLicensor: data.idPartnerLicensor ?? null,
          notes: data.notes,
          dtDeadline: data.dtDeadline,
        };

        // Se nao houver ID de PRC na pagina, cria PRC
        if (!idPrc) {
          response = await createPrcMutation({
            variables: {
              data: { ...prcData, idCategory: data.idCategory },
            },
          });
          // Se PRC for draft e o usuario da PRC for o mesmo que esta logado, atualiza dados
        } else if (
          prc.idStatus === PrcStatus.DRAFT &&
          idUser === prc.idResponsibleUser
        ) {
          response = await updatePrcMutation({
            variables: {
              data: {
                idPrc,
                ...prcData,
                versionLock: prc.versionLock,
              },
            },
          });
        }

        showSuccess({ summary: `PRC ${idPrc ? 'updated' : 'created'}` });

        if (!idPrc && response) {
          // Direciona usuario para listagem da PRC
          history.push(`/prcs/list/${response.data.createPrc.idPrc}`);
        } else {
          setPrc({} as IPrc);
          prcRefetch();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef.current?.focus) {
            inputWithError.inputRef.current.focus();
          }

          // Exibe toast sobre o erro
          showWarn({
            summary: 'Enter all required fields',
            detail: `${error.errors.length} fields with errors`,
          });
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          showError({
            summary: `Error while ${idPrc ? 'updating' : 'creating'} PRC`,
            detail: error.message,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createPrcMutation,
      formRef,
      history,
      idPrc,
      idUser,
      prc.idResponsibleUser,
      prc.idStatus,
      prc.versionLock,
      prcRefetch,
      showError,
      showSuccess,
      showWarn,
      updatePrcMutation,
    ],
  );

  /**
   * Funcao para enviar PRC para procurement
   */
  async function handleElaborarPrcAction() {
    // Valida se PRC possui pelo menos um item caso nao seja do tipo catalogue
    if (
      prc.idPrcType !== PrcType.CATALOGUE &&
      !prc.prcFileLogs?.filter(item => item.idType === PrcFileType.PRC_ITEMS)
        ?.length
    ) {
      showError({
        summary: 'Error while sending PRC',
        detail: 'You need to add at least one PRC Item',
      });

      // Seleciona aba de general information
      setSelectedMenuItem('generalInformation');
      return;
    }

    try {
      // Atualiza dados da PRC
      await handleSubmit(formRef.current?.getData() as IPrcFormData);

      // Define loading da pagina como true
      setLoading(true);

      // Chama mutation para enviar PRC para procurement
      await prcElaborarActionMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC sent' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: 'Error while sending PRC',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Funcao para assumir PRC em Procurement
   */
  async function handlePoolProcurementAction() {
    // Define loading da pagina como true
    setLoading(true);
    try {
      // Chama mutation para assumir PRC em procurement
      await prcPoolProcurementActionMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC assumed' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: 'Error while assuming PRC',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Funcao para executar acao de Add Quotation
   */
  async function handleAddQuotationAction() {
    if (!prcState.userAction) {
      // Valida se PRC possui pelo menos um Compare
      if (
        !prc.prcFileLogs?.filter(
          item => item.idType === PrcFileType.PRC_COMPARE,
        )?.length
      ) {
        showError({
          summary: 'Error while sending PRC to Purchase',
          detail: 'You need to add at least one PRC Compare file',
        });

        // Seleciona aba de general information
        setSelectedMenuItem('prcQuotations');
        return;
      }

      const prcQuotationBestPrice = prc.prcQuotations?.find(
        prcQuotation => prcQuotation.bestPrice,
      );

      // Valida se PRC possui pelo menos um Best Price
      if (
        !prc.prcFileLogs?.filter(
          item =>
            item.idType === PrcFileType.PRC_BEST_PRICE ||
            item.idPrcQuotation === prcQuotationBestPrice?.idPrcQuotation,
        )?.length
      ) {
        showError({
          summary: 'Error while sending PRC to Purchase',
          detail: 'You need to add at least one PRC Best Price file',
        });

        // Seleciona aba de general information
        setSelectedMenuItem('prcQuotations');
        return;
      }
    }

    // Define loading da pagina como true
    setLoading(true);

    // Variavel da mensagem do toast
    let message: string;

    // Varia mensagem de acordo com acao do usuario
    switch (prcState.userAction) {
      case AddQuotationUserAction.DEVOLVER_CRIADOR:
        message = 'back to creator';
        break;
      case AddQuotationUserAction.DEVOLVER_POOL_PROCUREMENT:
        message = 'back to procurement';
        break;
      default:
        message = 'to purchase';
        break;
    }

    try {
      // Chama mutation para assumir PRC em procurement
      await prcAddQuotationActionMutation({
        variables: {
          data: {
            idPrc,
            userAction:
              prcState.userAction || AddQuotationUserAction.ENVIAR_PURCHASE,
            comment: prcState.userComment,
          },
        },
      });

      showSuccess({ summary: `PRC sent ${message}` });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: `Error while sendind PRC ${message}`,
        detail: error.message,
      });
    } finally {
      setLoading(false);
      if (prcState.displayEnterACommentDialog) {
        // Oculta dialog de comentario
        prcDispatch({
          type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
        });
      }
    }
  }

  /**
   * Funcao para executar acao de Purchase Add Quotation
   */
  async function handlePurchaseAddQuotationAction(
    paramAction?: PurchaseAddQuotationUserAction,
  ) {
    // Define loading da pagina como true
    setLoading(true);

    // Variavel da mensagem do toast
    let message: string;

    // Varia mensagem de acordo com acao do usuario
    switch (prcState.userAction) {
      case PurchaseAddQuotationUserAction.DEVOLVER_CRIADOR:
        message = 'back to creator';
        break;
      case PurchaseAddQuotationUserAction.DEVOLVER_POOL_PURCHASE:
        message = 'back to purchase pool';
        break;
      case PurchaseAddQuotationUserAction.DEVOLVER_PROCUREMENT:
        message = 'back to procurement';
        break;
      default:
        message = '';
        break;
    }

    try {
      // Chama mutation para assumir PRC em procurement
      await prcPurchaseAddQuotationActionMutation({
        variables: {
          data: {
            idPrc,
            userAction: prcState.userAction || paramAction,
            comment: prcState.userComment,
          },
        },
      });

      showSuccess({ summary: `PRC sent ${message}` });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: `Error while sendind PRC ${message}`,
        detail: error.message,
      });
    } finally {
      setLoading(false);

      if (prcState.displayEnterACommentDialog) {
        // Oculta dialog de comentario
        prcDispatch({
          type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
        });
      }
    }
  }

  /**
   * Funcao para executar acao de Analisar
   */
  async function handleAnalisarAction() {
    // Define loading da pagina como true
    setLoading(true);

    // Variavel da mensagem do toast
    let message: string;

    // Varia mensagem de acordo com acao do usuario
    switch (prcState.userAction) {
      case AnalisarAction.DEVOLVER_CRIADOR:
        message = 'back to creator';
        break;
      case AnalisarAction.DEVOLVER_POOL_MANAGER:
        message = 'back to manager pool';
        break;
      case AnalisarAction.DEVOLVER_PROCUREMENT:
        message = 'back to procurement';
        break;
      case AnalisarAction.DEVOLVER_PURCHASE:
        message = 'back to purchase';
        break;
      default:
        message = 'to commercial';
        break;
    }

    try {
      // Chama mutation para assumir PRC em procurement
      await prcAnalisarActionMutation({
        variables: {
          data: {
            idPrc,
            userAction: prcState.userAction || AnalisarAction.ENVIAR_COMMERCIAL,
            comment: prcState.userComment,
          },
        },
      });

      showSuccess({ summary: `PRC sent ${message}` });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: `Error while sendind PRC ${message}`,
        detail: error.message,
      });
    } finally {
      setLoading(false);

      if (prcState.displayEnterACommentDialog) {
        // Oculta dialog de comentario
        prcDispatch({
          type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
        });
      }
    }
  }

  /**
   * Funcao para assumir PRC em Purchase
   */
  async function handlePoolPurchaseAction() {
    // Define loading da pagina como true
    setLoading(true);
    try {
      // Chama mutation para assumir PRC em purchase
      await prcPoolPurchaseActionMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC assumed' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: 'Error while assuming PRC',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Funcao para assumir PRC em Manager
   */
  async function handlePoolManagerAction() {
    // Define loading da pagina como true
    setLoading(true);
    try {
      // Chama mutation para assumir PRC em purchase
      await prcPoolManagerActionMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC assumed' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({ summary: 'Error while assuming PRC', detail: error.message });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Funcao para assumir PRC em Commercial
   */
  async function handlePoolCommercialAction() {
    // Define loading da pagina como true
    setLoading(true);
    try {
      // Chama mutation para assumir PRC em purchase
      await prcPoolCommercialActionMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC assumed' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({ summary: 'Error while assuming PRC', detail: error.message });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Funcao para executar acao de Aprovar
   */
  async function handlePrcAprovarAction() {
    // Define loading da pagina como true
    setLoading(true);

    // Variavel da mensagem do toast
    let successMessage = '';
    let errorMessage = '';

    // Varia mensagem de acordo com acao do usuario
    switch (prcState.userAction) {
      case AprovarAction.DEVOLVER_CRIADOR:
      case AprovarAction.DEVOLVER_MANAGER:
      case AprovarAction.DEVOLVER_POOL_COMMERCIAL:
      case AprovarAction.DEVOLVER_PROCUREMENT:
      case AprovarAction.DEVOLVER_PURCHASE:
        successMessage = 'PRC sent back';
        errorMessage = 'Error trying to send PRC back';
        break;
      case AprovarAction.APROVAR_PRC:
      case AprovarAction.APROVAR_PRC_CRIAR_CQ:
        successMessage = 'PRC approved';
        errorMessage = 'Error trying to approve PRC';
        break;
      case AprovarAction.CANCELAR_PRC:
        successMessage = 'PRC not approved';
        errorMessage = 'Error trying to cancel PRC';
        break;
      default:
        break;
    }

    try {
      // Chama mutation para aprovar PRC
      await prcAprovarActionMutation({
        variables: {
          data: {
            idPrc,
            userAction:
              prcState.userAction === AprovarAction.APROVAR_PRC_CRIAR_CQ
                ? AprovarAction.APROVAR_PRC
                : prcState.userAction,
            comment: prcState.userComment,
            prcItems: prcState.prcItems,
          },
        },
      });

      showSuccess({ summary: successMessage });

      // Oculta dialog de comentario
      prcDispatch({
        type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
      });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({ summary: errorMessage, detail: error.message });

      return;
    } finally {
      // Define loading da pagina como false
      setLoading(false);
    }

    if (prcState.userAction === AprovarAction.APROVAR_PRC_CRIAR_CQ) {
      // Define loading da pagina como true
      setLoading(true);
      try {
        // Cria Client Quotation
        const response = await createClientQuotation(
          createClientQuotationMutation,
          [idPrc],
        );

        showSuccess({
          summary: 'CQ Created',
          detail: (
            <>
              <p>PRCs products added in the new CQ: </p>
              <Link
                to={`/client-quotations/list/${response.data.createClientQuotation.idCq}`}
                className="transparent-link"
              >
                {response.data.createClientQuotation.cqNumber}
              </Link>
            </>
          ),
        });
      } catch (error) {
        showError({
          summary: 'Error while creating CQ',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    }
  }

  /**
   * Funcao para executar acao de Reactivate
   */
  async function handleReactivateAction() {
    // Define loading da pagina como true
    setLoading(true);

    try {
      // Chama mutation para reativar a PRC
      await reactivatePrcMutation({
        variables: {
          idPrc,
        },
      });

      showSuccess({ summary: 'PRC reactivated' });

      // Recarrega dados da PRC
      setPrc({} as IPrc);
      prcRefetch();
    } catch (error) {
      showError({
        summary: 'Error while reactivating PRC',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Valida se comment da PRC tem um status definido
   * @param idStatus ID do status a ser buscado
   * @returns True ou False
   */
  const commentHasThisStatus = useCallback(
    (idStatus: number) => {
      return !!prc.prcComments?.find(
        prcComment => prcComment.idComment2?.idStatus === idStatus,
      );
    },
    [prc.prcComments],
  );

  /**
   * Valida se botao para assumir PRC deve ser exibido
   */
  function shouldShowButtonAssumePrc() {
    switch (prc.idStatus) {
      case PrcStatus.WAITING_PROCUREMENT:
      case PrcStatus.WAITING_PURCHASE:
      case PrcStatus.WAITING_MANAGEMENT:
      case PrcStatus.WAITING_COMMERCIAL:
        return true;
      default:
        return false;
    }
  }

  /**
   * Valida se botao split para enviar PRC de volta deve ser exibido
   */
  function shouldShowSendBackToSplitButton() {
    switch (prc.idStatus) {
      case PrcStatus.AT_PROCUREMENT:
      case PrcStatus.AT_PURCHASE:
      case PrcStatus.AT_MANAGEMENT:
      case PrcStatus.AT_COMMERCIAL:
        return true;
      default:
        return false;
    }
  }

  /**
   * Valida se botao de assumir PRC deve ser desabilitado
   */
  function shouldButtonAssumePrcBeDisabled() {
    // Se houver usuario atribuido para PRC, retorna true
    if (prc.idResponsibleUser) return true;

    let checkOffice = false;

    if (user) {
      const valuesUserOffices = user.userOffices;

      const checkValueExists = (res: any) =>
        prc.prcOffices[0].idOffice2.idOffice === res.idOffice;

      checkOffice = valuesUserOffices.some(checkValueExists);
    }
    // Se escritorio da PRC e do Usuario logado forem diferentes, retorna true
    if (
      prc.idStatus !== PrcStatus.WAITING_MANAGEMENT &&
      prc.idStatus !== PrcStatus.WAITING_COMMERCIAL &&
      !checkOffice
    ) {
      return true;
    }
    // Efetua validacao pelo status
    switch (prc.idStatus) {
      case PrcStatus.WAITING_PROCUREMENT:
        // Se usuario nao possuir role de procurement
        if (!userHasRole(Role.PROCUREMENT, roles.rolesUser)) return true;
        break;
      case PrcStatus.WAITING_PURCHASE:
        // Se usuario nao possuir role de procurement
        if (!userHasRole(Role.PURCHASE, roles.rolesUser)) return true;
        break;
      case PrcStatus.WAITING_MANAGEMENT:
        // Se usuario nao possuir role de manager
        if (!userHasRole(Role.MANAGER, roles.rolesUser)) return true;
        break;
      case PrcStatus.WAITING_COMMERCIAL:
        // Se usuario nao possuir role de commercial
        if (!userHasRole(Role.COMMERCIAL, roles.rolesUser)) return true;
        break;
      default:
        break;
    }

    return false;
  }

  // Botoes de acao do split button
  const splitButtonItems = useMemo(() => {
    const buttomItems: MenuItem[] = [];

    if (prc.idStatus === PrcStatus.AT_PROCUREMENT) {
      buttomItems.push({
        label: 'Creator',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: { userAction: AddQuotationUserAction.DEVOLVER_CRIADOR },
          });
        },
      });

      buttomItems.push({
        label: 'Procurement',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AddQuotationUserAction.DEVOLVER_POOL_PROCUREMENT,
            },
          });
        },
      });
    } else if (prc.idStatus === PrcStatus.AT_PURCHASE) {
      buttomItems.push({
        label: 'Creator',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: PurchaseAddQuotationUserAction.DEVOLVER_CRIADOR,
            },
          });
        },
      });

      buttomItems.push({
        label: 'Procurement',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: PurchaseAddQuotationUserAction.DEVOLVER_PROCUREMENT,
            },
          });
        },
        disabled: !commentHasThisStatus(PrcStatus.AT_PROCUREMENT),
      });

      buttomItems.push({
        label: 'Purchase Pool',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: PurchaseAddQuotationUserAction.DEVOLVER_POOL_PURCHASE,
            },
          });
        },
      });
    } else if (prc.idStatus === PrcStatus.AT_MANAGEMENT) {
      buttomItems.push({
        label: 'Creator',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AnalisarAction.DEVOLVER_CRIADOR,
            },
          });
        },
      });

      buttomItems.push({
        label: 'Manager Pool',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AnalisarAction.DEVOLVER_POOL_MANAGER,
            },
          });
        },
      });

      buttomItems.push({
        label: 'Procurement',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AnalisarAction.DEVOLVER_PROCUREMENT,
            },
          });
        },
        disabled: !commentHasThisStatus(PrcStatus.AT_PROCUREMENT),
      });

      buttomItems.push({
        label: 'Purchase',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AnalisarAction.DEVOLVER_PURCHASE,
            },
          });
        },
      });
    } else if (prc.idStatus === PrcStatus.AT_COMMERCIAL) {
      buttomItems.push({
        label: 'Creator',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.DEVOLVER_CRIADOR,
            },
          });
        },
      });
      buttomItems.push({
        label: 'Commercial Pool',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.DEVOLVER_POOL_COMMERCIAL,
            },
          });
        },
      });
      buttomItems.push({
        label: 'Procurement',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.DEVOLVER_PROCUREMENT,
            },
          });
        },
        disabled: !commentHasThisStatus(PrcStatus.AT_PROCUREMENT),
      });
      buttomItems.push({
        label: 'Purchase',
        command: () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.DEVOLVER_PURCHASE,
            },
          });
        },
      });
      if (prc.idPrcType !== PrcType.CATALOGUE) {
        buttomItems.push({
          label: 'Management',
          command: () => {
            prcDispatch({
              type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
              payload: {
                userAction: AprovarAction.DEVOLVER_MANAGER,
              },
            });
          },
        });
      }
    }

    return buttomItems;
  }, [prc.idPrcType, prc.idStatus, commentHasThisStatus]);

  /**
   * Realiza submit de acordo com escolha do usuario
   */
  function handleSubmitComment() {
    // Validacao de Cancelar, que pode ser executada em qualquer etapa caso o usuario tenha permissao
    if (prcState.userAction === AprovarAction.CANCELAR_PRC) {
      handlePrcAprovarAction();
      return;
    }

    // Chama funcao de acordo com Acao do usuario
    switch (prc.idStatus) {
      case PrcStatus.AT_PROCUREMENT:
        handleAddQuotationAction();
        break;
      case PrcStatus.AT_PURCHASE:
        handlePurchaseAddQuotationAction();
        break;
      case PrcStatus.AT_MANAGEMENT:
        handleAnalisarAction();
        break;
      case PrcStatus.AT_COMMERCIAL:
        handlePrcAprovarAction();
        break;
      default:
        break;
    }
  }

  /**
   * Chama funcao de assumir de acordo com Status da PRC
   */
  function handleAssumePrcAction() {
    switch (prc.idStatus) {
      case PrcStatus.WAITING_PROCUREMENT:
        handlePoolProcurementAction();
        break;
      case PrcStatus.WAITING_PURCHASE:
        handlePoolPurchaseAction();
        break;
      case PrcStatus.WAITING_MANAGEMENT:
        handlePoolManagerAction();
        break;
      case PrcStatus.WAITING_COMMERCIAL:
        handlePoolCommercialAction();
        break;
      default:
        break;
    }
  }

  /**
   * Busca nome do pool em que a PR esta para exibir na tag com base no status da PRC
   */
  function getPoolNameByStatusId(idStatus: number) {
    switch (idStatus) {
      case PrcStatus.DRAFT:
        return prc.createdBy2.roleUsers
          ? prc.createdBy2.roleUsers[0]?.idRole2.name
          : '';
      case PrcStatus.WAITING_PROCUREMENT:
      case PrcStatus.AT_PROCUREMENT:
        return 'Procurement';
      case PrcStatus.WAITING_PURCHASE:
      case PrcStatus.AT_PURCHASE:
        return 'Purchase';
      case PrcStatus.WAITING_MANAGEMENT:
      case PrcStatus.AT_MANAGEMENT:
        return 'Management';
      case PrcStatus.WAITING_COMMERCIAL:
      case PrcStatus.AT_COMMERCIAL:
        return 'Commercial';
      default:
        return undefined;
    }
  }

  /**
   * Footer da modal de comentario
   * @returns Footer
   */
  const commentDialogFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            handleSubmitComment();
          }}
          disabled={
            prcState.userAction !== AprovarAction.APROVAR_PRC
              ? !prcState.userComment
              : false
          }
        />
        {!!prcState.prcItems?.length && (
          <Button
            label="Back"
            severity="danger"
            onClick={() =>
              prcDispatch({
                type: PrcReducerActionKind.CHANGE_DISPLAY_FROM_ENTER_A_COMMENT_TO_SELECT_PRC_ITEMS,
              })
            }
          />
        )}

        <Button
          label="Cancel"
          severity="danger"
          icon="pi pi-times"
          onClick={() =>
            prcDispatch({
              type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            })
          }
        />
      </div>
    );
  };

  /**
   * Footer da modal de PRC Items
   * @returns Footer
   */
  const prcItemsDialogFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            prcDispatch({
              type: PrcReducerActionKind.CHANGE_DISPLAY_FROM_SELECT_PRC_ITEMS_TO_ENTER_A_COMMENT,
              payload: {
                productPickListData: productPickListRef.current?.selectedItems,
              },
            });
          }}
        />
        <Button
          label="Cancel"
          severity="danger"
          icon="pi pi-times"
          onClick={() =>
            prcDispatch({
              type: PrcReducerActionKind.CHANGE_DISPLAY_SELECT_PRC_ITEMS,
            })
          }
        />
      </div>
    );
  };

  /**
   * Funcao para upload de arquivo
   */
  async function handleFileUpload(
    event: FileUploadResponse[],
    idType: number,
    idPrcQuotation?: number,
  ) {
    setLoading(true);
    try {
      await uploadPrcFileMutation({
        variables: {
          data: {
            uploadedFiles: event[0].serverName,
            idPrc,
            idType,
            idPrcQuotation,
          },
        },
      });

      showSuccess({ summary: 'File uploaded' });

      // Atualiza dados da PRC
      await handleSubmit(formRef.current?.getData() as IPrcFormData);
    } catch (err) {
      showError({ summary: 'Error while uploading file', detail: err.message });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        history.push(
          initialPage && initialFirst
            ? `/prcs/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
            : '/prcs/list',
        ),
    });
  }

  function handleApprove() {
    if (prc.idPrcType === PrcType.CATALOGUE) {
      confirmDialog({
        message: `Do you want to change the type of PRC and start a new flow?`,
        header: 'Approve Catalogue',
        icon: 'pi pi-info-circle',
        accept: async () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.DEVOLVER_CRIADOR,
            },
          });
        },
        reject: async () => {
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
            payload: {
              userAction: AprovarAction.APROVAR_PRC,
            },
          });
        },
      });
    } else {
      prcDispatch({
        type: PrcReducerActionKind.CHANGE_DISPLAY_SELECT_PRC_ITEMS,
        payload: {
          userAction: AprovarAction.APROVAR_PRC,
        },
      });
    }
  }

  // Validacao para o botao de Cancel estar visivel
  const userCanSeeCancelPrcButton = useMemo(() => {
    const userIsCreator = prc.createdBy === idUser;
    const prcIsAtCommercial =
      prc.idStatus === PrcStatus.AT_COMMERCIAL &&
      prc.idResponsibleUser === idUser;
    const userHasPermissionToCancel = userHasPermission(
      idPermissionCancelPrc,
      userPermissions.userPermissions,
    );
    const prcIsNotCanceledOrApproved =
      prc.idStatus !== PrcStatus.CANCELED &&
      prc.idStatus !== PrcStatus.APPROVED;
    const prcIsCreated = prc.idPrc;

    return (
      (userIsCreator || prcIsAtCommercial || userHasPermissionToCancel) &&
      prcIsNotCanceledOrApproved &&
      prcIsCreated
    );
  }, [
    idUser,
    prc.createdBy,
    prc.idStatus,
    prc.idResponsibleUser,
    prc.idPrc,
    idPermissionCancelPrc,
    userPermissions,
  ]);

  // Validacao para o botao de Reactivate estar visivel
  const userCanSeeReactviatePrcButton = useMemo(() => {
    const prcIsCanceled = prc.idStatus === PrcStatus.CANCELED;
    const userIsCreator = prc.createdBy === idUser;
    const userIsAdmin = userHasRole(Role.ADMINISTRATOR, roles.rolesUser);
    const userIsCommercialKeyUser = userHasRole(
      Role.COMMERCIAL_KEY_USER,
      roles.rolesUser,
    );

    return (
      (userIsCreator || userIsAdmin || userIsCommercialKeyUser) && prcIsCanceled
    );
  }, [idUser, prc.createdBy, prc.idStatus, roles.rolesUser]);

  /**
   * Seleciona aba de acordo com o parametro da URL
   */
  useEffect(() => {
    if (urlTabParam) {
      setSelectedMenuItem(urlTabParam);
    }
  }, [urlTabParam]);

  /**
   * Valida se pagina deve carregar dados de uma PRC
   */
  useEffect(() => {
    // Valida se ID de PRC foi informado na URL
    if (idPrc) {
      // Carrega dados da PRC
      loadPrcData({ variables: { idPrc } });
    }

    return () => setPrc({} as IPrc);
  }, [idPrc, loadPrcData]);

  return (
    <Container>
      <Dialog
        header="Enter a comment for this action"
        visible={prcState.displayEnterACommentDialog}
        onHide={() =>
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
          })
        }
        closable={false}
        footer={commentDialogFooter()}
      >
        <Editor
          headerTemplate={<></>}
          style={{ height: '320px' }}
          value={prcState.userComment}
          onTextChange={e => {
            prcDispatch({
              type: PrcReducerActionKind.CHANGE_USER_COMMENT,
              payload: {
                userComment: e.htmlValue || undefined,
              },
            });
          }}
          modules={{ toolbar: toolbarOptions }}
        />
      </Dialog>
      <Dialog
        header="Select PRC Items"
        visible={prcState.displaySelectPrcItemsDialog}
        onHide={() =>
          prcDispatch({
            type: PrcReducerActionKind.CHANGE_DISPLAY_SELECT_PRC_ITEMS,
          })
        }
        closable={false}
        footer={prcItemsDialogFooter()}
      >
        <PickListProduct
          ref={productPickListRef}
          initialData={prcState.productPickListData}
        />
      </Dialog>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          prc?.mainProduct
            ? `${prc.prcCode ?? 'PRC'} - ${prc.mainProduct}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {prc.idStatus && (
          <StatusTag
            domain={prc.idStatus}
            name={getPoolNameByStatusId(prc.idStatus)}
            status={prc.idStatus2.description}
            className="statusTag"
          />
        )}

        <p className={idPrc ? 'createdAt' : ''}>
          {prc.idPrc && !prcLoading
            ? `${new Date(prc.createdAt).toLocaleString()} by ${
                prc.createdBy2?.firstName
              } ${prc.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idPrc ? 'updatedAt' : ''}>
          {prc.idPrc && !prcLoading
            ? `${new Date(prc.updatedAt).toLocaleString()} by ${
                prc.updatedBy2?.firstName
              } ${prc.updatedBy2?.lastName}`
            : ''}
        </p>

        {!idPrc && (
          <MainButton
            className="mainButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        {prc.idStatus === PrcStatus.AT_COMMERCIAL && (
          <div className="secondaryButton">
            <Button
              action="approve"
              onClick={() => {
                handleApprove();
              }}
            >
              <AiOutlineCheckCircle size={22} />
              <p>Approve</p>
            </Button>
            <Button
              action="approve"
              onClick={() => {
                prcDispatch({
                  type: PrcReducerActionKind.CHANGE_DISPLAY_SELECT_PRC_ITEMS,
                  payload: {
                    userAction: AprovarAction.APROVAR_PRC_CRIAR_CQ,
                  },
                });
              }}
              disabled={prc.idPrcType === PrcType.CATALOGUE}
            >
              <IoCheckmarkDoneCircleOutline size={22} />
              <p>Approve and create CQ</p>
            </Button>
          </div>
        )}

        {idPrc && userCanChange && (
          <Button
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => handleCancel()}
        />

        {userCanSeeCancelPrcButton && (
          <Button
            className="secondaryButton"
            action="reject"
            onClick={() => {
              prcDispatch({
                type: PrcReducerActionKind.CHANGE_DISPLAY_ENTER_A_COMMENT,
                payload: {
                  userAction: AprovarAction.CANCELAR_PRC,
                },
              });
            }}
          >
            <AiOutlineMinusCircle size={22} />
            <p>Cancel PRC</p>
          </Button>
        )}

        {userCanSeeReactviatePrcButton && (
          <MainButton
            className="mainButton"
            type="button"
            label="Reactivate PRC"
            onClick={handleReactivateAction}
          />
        )}

        {prc.idResponsibleUser === idUser && prc.idStatus === PrcStatus.DRAFT && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handleElaborarPrcAction();
            }}
          >
            <AiOutlineShoppingCart size={17} />
            <p>Send</p>
          </MainButton>
        )}

        {shouldShowButtonAssumePrc() && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handleAssumePrcAction();
            }}
            disabled={shouldButtonAssumePrcBeDisabled()}
          >
            <AiOutlineShoppingCart size={17} />
            <p>Assume PRC</p>
          </MainButton>
        )}

        {prc.idStatus === PrcStatus.AT_PROCUREMENT && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handleAddQuotationAction();
            }}
            disabled={prc.idResponsibleUser !== idUser}
          >
            <AiOutlineShoppingCart size={17} />
            <p>Send to Purchase</p>
          </MainButton>
        )}

        {prc.idStatus === PrcStatus.AT_PURCHASE && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handlePurchaseAddQuotationAction(
                prc.idPrcType === PrcType.CATALOGUE
                  ? PurchaseAddQuotationUserAction.ENVIAR_COMMERCIAL
                  : PurchaseAddQuotationUserAction.ENVIAR_MANAGER,
              );
            }}
            disabled={prc.idResponsibleUser !== idUser}
          >
            <AiOutlineShoppingCart size={17} />
            <p>
              Send to{' '}
              {prc.idPrcType === PrcType.CATALOGUE
                ? 'Commercial'
                : 'Management'}
            </p>
          </MainButton>
        )}

        {prc.idStatus === PrcStatus.AT_MANAGEMENT && (
          <MainButton
            className="mainButton"
            onClick={() => {
              handleAnalisarAction();
            }}
            disabled={prc.idResponsibleUser !== idUser}
          >
            <AiOutlineShoppingCart size={17} />
            <p>Send to Commercial</p>
          </MainButton>
        )}

        {shouldShowSendBackToSplitButton() && (
          <SplitButton
            label="Send back to..."
            className="p-button-danger"
            model={splitButtonItems}
            onClick={() => {
              const element = document.getElementsByClassName(
                'p-splitbutton-menubutton',
              )[0] as HTMLButtonElement;

              element.click();
            }}
            disabled={
              prc.idResponsibleUser !== idUser &&
              !userHasRole(Role.ADMINISTRATOR, roles.rolesUser) &&
              !userHasRole(Role.MANAGER, roles.rolesUser) &&
              !(
                userHasRole(Role.PROCUREMENT_KEY_USER, roles.rolesUser) &&
                prc.idStatus === PrcStatus.AT_PROCUREMENT
              ) &&
              !(
                userHasRole(Role.PURCHASE_KEY_USER, roles.rolesUser) &&
                prc.idStatus === PrcStatus.AT_PURCHASE
              ) &&
              !(
                userHasRole(Role.COMMERCIAL_KEY_USER, roles.rolesUser) &&
                prc.idStatus === PrcStatus.AT_COMMERCIAL
              )
            }
          />
        )}
      </PageHeader>
      {((idPrc && !prcLoading && prc.idPrc !== undefined) || !idPrc) && (
        <CrudForm>
          <PageMenu
            menuWidth="195px"
            setSelectedMenuItem={setSelectedMenuItem}
            selectedMenuItem={selectedMenuItem}
            items={menuItems}
          />

          <Form ref={formRef} onSubmit={handleSubmit} initialData={prc}>
            <GeneralInformation
              selected={selectedMenuItem === 'generalInformation'}
              formRef={formRef}
              prc={prc}
              setPrc={setPrc}
              userCanChange={userCanChange}
              handleFileUpload={handleFileUpload}
              handleDownloadFile={downloadPrcFile}
              handleDownloadFileTemplate={downloadPrcFileTemplate}
              downloadFileTemplateLoading={downloadPrcFileTemplateLoading}
            />

            {prc.idPrc && (
              <>
                {prc.idPrcType !== PrcType.CATALOGUE && (
                  <PrcQuotations
                    selected={selectedMenuItem === 'prcQuotations'}
                    prc={prc}
                    handleDownloadFile={downloadPrcFile}
                    idUser={idUser}
                    handleFileUpload={handleFileUpload}
                    getFileQuery={getFile}
                    getFileLoading={getFileLoading}
                    setPrc={setPrc}
                    setLoading={setLoading}
                    loadPrcData={prcRefetch}
                    userHasSpecificProfiles={userHasSpecificProfiles}
                    handleDownloadFileTemplate={downloadPrcFileTemplate}
                    downloadFileTemplateLoading={downloadPrcFileTemplateLoading}
                  />
                )}
                <Updates
                  selected={selectedMenuItem === 'updates'}
                  prc={prc}
                  setPageLoading={setLoading}
                  pageLoading={loading}
                  userPermissions={userPermissions.userPermissions}
                />
                <Attachments
                  selected={selectedMenuItem === 'attachments'}
                  idPrc={prc.idPrc}
                  setPageLoading={setLoading}
                />
              </>
            )}
          </Form>
        </CrudForm>
      )}

      {(loading || downloadPrcFileLoading || prcLoading) && <Loading />}
    </Container>
  );
};

export default Prc;
