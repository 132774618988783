import { useLazyQuery } from '@apollo/client';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import FormTitle from '../../../../../../components/FormTitle';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { ISatRnc } from '../../../interfaces';
import { ISatRncHistoryInformations, ISatRncHistoryItem } from './interfaces';
import { getRncHistoryInformationsQuery } from './queries';
import {
  ColumnContent,
  ColumnTitle,
  Container,
  HistoryColumn,
  HistoryContent,
} from './styles';

interface IRncHistoryProps {
  satRnc?: ISatRnc;
  categoryId?: number;
  selected: boolean;
}

const RncHistory: React.FC<IRncHistoryProps> = ({
  satRnc,
  categoryId,
  selected,
}) => {
  const { toastRef } = useRefHook();

  const [rncHistoryInformation, setRncHistoryInformation] =
    useState<ISatRncHistoryInformations>();

  const [loadRncHistoryInformationsData] = useLazyQuery(
    getRncHistoryInformationsQuery,
    {
      variables: {
        data: {
          satRncId: satRnc?.idSatRnc,
          supplierId: satRnc?.idSupplier,
          categoryId,
        },
      },
      onCompleted: response => {
        if (response.getRncHistoryInformations) {
          setRncHistoryInformation(response.getRncHistoryInformations);
        }
      },
      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while loading history',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  const getNameBody = useCallback((item: ISatRncHistoryItem) => {
    const name = item.description;
    const clientName = name.split(' - ');
    const abbreviation = clientName[1]?.substring(0, 20);
    return (
      <div>
        <Tooltip target=".tooltip" />
        <span className="tooltip" data-pr-tooltip={name}>
          {clientName[0]} - {abbreviation}
        </span>
      </div>
    );
  }, []);

  /**
   * Retorna coluna RNC NUmber com Link para pagina de Rnc da Sat
   * @param item Dados da linha
   * @returns Coluna RNC Number com Link para pagina de Rnc
   */
  const parseRncHistoryColumnContent = (
    item: ISatRncHistoryItem,
    addTooltip: boolean,
  ) => {
    return (
      <Link
        to={`/commercial/sats/${item.idSat}?tab=rnc&rncId=${item.idSatRnc}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
        key={item.idSatRnc}
      >
        <ColumnContent key={item.idSatRnc}>
          {addTooltip ? getNameBody(item) : item.description}
          <FiExternalLink size={15} />
        </ColumnContent>
      </Link>
    );
  };

  useEffect(() => {
    if (selected) {
      loadRncHistoryInformationsData();
    }
  }, [loadRncHistoryInformationsData, selected]);

  return (
    <Container className="w-full">
      <FormTitle
        className="col-12 md:col-12 mt-5 content-header"
        name="History"
      />
      <HistoryContent className="w-full">
        <HistoryColumn className="col-4">
          <ColumnTitle>ST Code</ColumnTitle>
          {rncHistoryInformation?.rncHistoryItems?.map(item => {
            return parseRncHistoryColumnContent(item, true);
          })}
          {rncHistoryInformation?.rncHistoryItems?.length === 0 && (
            <span>No history found</span>
          )}
        </HistoryColumn>
        <HistoryColumn className="col-4">
          <ColumnTitle>SUN Number</ColumnTitle>
          {rncHistoryInformation?.rncHistorySuppliers?.map(supplier => {
            return parseRncHistoryColumnContent(supplier, false);
          })}
          {rncHistoryInformation?.rncHistorySuppliers?.length === 0 && (
            <span>No history found</span>
          )}
        </HistoryColumn>
        <HistoryColumn className="col-4">
          <ColumnTitle>Category</ColumnTitle>
          {rncHistoryInformation?.rncHistoryCategories?.map(category => {
            return parseRncHistoryColumnContent(category, false);
          })}
          {rncHistoryInformation?.rncHistoryCategories?.length === 0 && (
            <span>No history found</span>
          )}
        </HistoryColumn>
      </HistoryContent>
    </Container>
  );
};

export default RncHistory;
