import { gql } from '@apollo/client';

export const listUpdateLogArchivesQuery = gql`
  query listUpdateLogArchivesByTable(
    $data: ListUpdateLogArchivesByTableInput!
  ) {
    listUpdateLogArchivesByTable(data: $data) {
      items
      data {
        idUpdateLogArchive
        dataOperation
        dataBefore
        dataAfter
        updatedBy2 {
          username
        }
        updatedAt
      }
    }
  }
`;
