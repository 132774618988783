import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Forbidden from '../pages/Forbidden';

import ClientTypeOfBusiness from '../pages/Clients/TypeOfBusiness';
import ClientTypeOfBusinesses from '../pages/Clients/TypeOfBusinesses';
import { typeOfBusinessRoles } from '../shared/roles/typeOfBusiness';

import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Sats from '../pages/Commercial/Sats';
import Sat from '../pages/Commercial/Sat';
import { satsRoles } from '../shared/roles/sat';

import Products from '../pages/Products/Products';
import Product from '../pages/Products/Product';
import { productRoles } from '../shared/roles/product';

import Users from '../pages/Users';
import User from '../pages/User';
import { userRoles } from '../shared/roles/user';

import Ports from '../pages/List/Ports';
import Port from '../pages/List/Port';
import { portRoles } from '../shared/roles/port';

import Incoterms from '../pages/List/Incoterms';
import Incoterm from '../pages/List/Incoterm';
import { incotermRoles } from '../shared/roles/incoterm';

import Roles from '../pages/Roles';
import Role from '../pages/Role';
import RoleByFunctionality from '../pages/RoleByFunctionality';
import { roleRoles } from '../shared/roles/role';

import Finishings from '../pages/Finishings';
import Finishing from '../pages/Finishing';
import { finishingRoles } from '../shared/roles/finishing';

import Packages from '../pages/Packages';
import Package from '../pages/Package';
import { packageRoles } from '../shared/roles/package';

import Pantones from '../pages/Pantones';
import Pantone from '../pages/Pantone';
import { pantoneRoles } from '../shared/roles/pantone';

import PaymentConditions from '../pages/List/PaymentConditions';
import PaymentCondition from '../pages/List/PaymentCondition';
import { paymentConditionRoles } from '../shared/roles/paymentCondition';

import Couriers from '../pages/List/Couriers';
import Courier from '../pages/List/Courier';
import { courierRoles } from '../shared/roles/courier';

import ClientStatuses from '../pages/Clients/ClientStatuses';
import ClientStatus from '../pages/Clients/ClientStatus';
import { clientStatus } from '../shared/roles/clientStatus';

import NumberStores from '../pages/Clients/NumberStores';
import NumberStore from '../pages/Clients/NumberStore';
import { clientNumbersOfStoresRoles } from '../shared/roles/clientNumbersOfStores';

import ContactRoles from '../pages/List/ContactRoles';
import ContactRole from '../pages/List/ContactRole';
import { contactRoles } from '../shared/roles/contactRoles';

import ClientTypeOfOperation from '../pages/List/TypeOfOperation';
import ClientTypeOfOperations from '../pages/List/TypeOfOperations';
import { clientTypeOfOperations } from '../shared/roles/clientTypeOfOperations';

import ClientTaxBenefits from '../pages/List/TaxBenefits';
import ClientTaxBenefit from '../pages/List/TaxBenefit';
import { clientTaxBenefitRoles } from '../shared/roles/clientTaxBenefit';

import SpecialNegotiations from '../pages/List/SpecialNegotiations';
import SpecialNegotiation from '../pages/List/SpecialNegotiation';
import { specialNegotiationRoles } from '../shared/roles/specialNegotiation';

import { clientServicesRole } from '../shared/roles/clientServices';
import ClientService from '../pages/List/ClientService';
import ClientServices from '../pages/List/ClientServices';

import Client from '../pages/Clients/ClientDetail';
import ClientsList from '../pages/Clients/ClientsList';
import { clientRoles } from '../shared/roles/client';

import Supplier from '../pages/Suppliers/SupplierDetail';
import SuppliersList from '../pages/Suppliers/SuppliersList';
import { supplierRoles } from '../shared/roles/supplier';

import Partner from '../pages/Partner';
import PartnersList from '../pages/Partners';
import { partnersListRoles } from '../shared/roles/partners';

import Categories from '../pages/List/Categories';
import Category from '../pages/List/Category';
import { categoryRoles } from '../shared/roles/category';

import Checklists from '../pages/Products/Checklists';
import Checklist from '../pages/Products/Checklist';
import { checklistRoles } from '../shared/roles/checklist';

import IcmsList from '../pages/List/IcmsList';
import IcmsDetail from '../pages/List/IcmsDetail';
import { icmsRoles } from '../shared/roles/icms';

import Ncms from '../pages/Products/Ncm/Ncms';
import Ncm from '../pages/Products/Ncm/Ncm';
import { ncmRoles } from '../shared/roles/ncm';

import OceanFreight from '../pages/Logistics/OceanFreight';
import OceanFreights from '../pages/Logistics/OceanFreights';
import { oceanFreightRoles } from '../shared/roles/oceanFreight';

import PortTaxes from '../pages/Logistics/PortTaxes';
import PortTax from '../pages/Logistics/PortTax';
import { portTaxRoles } from '../shared/roles/portTax';

import OceanFreightTaxes from '../pages/Logistics/OceanFreightTaxes';
import OceanFreightTax from '../pages/Logistics/OceanFreightTax';
import { oceanFreightTaxRoles } from '../shared/roles/oceanFreightTax';

import AirFreight from '../pages/Logistics/AirFreight';
import AirFreights from '../pages/Logistics/AirFreights';
import { airFreightRoles } from '../shared/roles/airFreight';
import AirFreightTax from '../pages/Logistics/AirFreightTax';
import AirFreightTaxes from '../pages/Logistics/AirFreightTaxes';
import { airFreightTaxRoles } from '../shared/roles/airFreightTax';
import Prc from '../pages/Prc/Prc';
import Prcs from '../pages/Prc/Prcs';

import AirportTaxes from '../pages/Logistics/AirportTaxes';
import AirportTax from '../pages/Logistics/AirportTax';
import { airportTaxRoles } from '../shared/roles/airportTax';
import { inlandFreightRoles } from '../shared/roles/inlandFreight';
import InlandFreights from '../pages/Logistics/InlandFreights';
import InlandFreight from '../pages/Logistics/InlandFreight';
import { inlandFreightTaxRoles } from '../shared/roles/inlandFreightTax';
import InlandFreightTaxes from '../pages/Logistics/InlandFreightTaxes';
import InlandFreightTax from '../pages/Logistics/InlandFreighTax';
import Expenses from '../pages/Logistics/Expenses';
import Expense from '../pages/Logistics/Expense';
import { tableSiscomexRoles } from '../shared/roles/tableSiscomex';
import TableSiscomexes from '../pages/Logistics/TableSiscomexes';
import TableSiscomex from '../pages/Logistics/TableSiscomex';
import { expenseRoles } from '../shared/roles/expense';

import ClientQuotation from '../pages/ClientQuotation/ClientQuotation';
import { clientQuotationRoles } from '../shared/roles/clientQuotation';
import ClientQuotations from '../pages/ClientQuotation/ClientQuotationsList';
import ControlQuality from '../pages/Controls/Quality';
import Register from '../pages/Controls/Register';
import Import from '../pages/Controls/Import';
import { registerRoles } from '../shared/roles/registerRoles';
import { qualityRoles } from '../shared/roles/quality';
import ControlFinancial from '../pages/Controls/Financial';
import { satFinancialRoles } from '../shared/roles/satFinancial';
import { importRoles } from '../shared/roles/importRoles';
import UserGroup from '../pages/UserGroup/UserGroup';
import UserGroups from '../pages/UserGroup/UserGroups';
import { designRoles } from '../shared/roles/design';
import ControlDesign from '../pages/Controls/Design';
import ControlFollowUp from '../pages/Controls/FollowUp';
import { followUpRoles } from '../shared/roles/followUp';
import { userGroupRoles } from '../shared/roles/userGroup';
import Purchase from '../pages/Controls/Purchase';
import { satsPurchaseRoles } from '../shared/roles/satsPurchaseRoles';
import { commercialRoles } from '../shared/roles/commercial';
import ControlCommercial from '../pages/Controls/Commercial';
import Export from '../pages/Controls/Export';
import { satsExportRoles } from '../shared/roles/satsExportRoles';
import RegisterDashboad from '../pages/Dashboards/Register';
import ArtwordDashboard from '../pages/Dashboards/Artwork';
import ClientPaymentDashboard from '../pages/Dashboards/ClientPayment';
import SupplierPaymentDashboard from '../pages/Dashboards/SupplierPayment';
import ProductionDashboard from '../pages/Dashboards/Production';
import {
  artworkDashboardRoles,
  clientPaymentDashboardRoles,
  documentsDashboardRoles,
  productionDashboardRoles,
  registerDashboardRoles,
  shipmentDashboardRoles,
  rncDashboardRoles,
  supplierPaymentDashboardRoles,
} from '../shared/roles/dashboards';

import DocumentsDashboad from '../pages/Dashboards/Documents';
import ShipmentDashboad from '../pages/Dashboards/Shipment';
import RncDashboard from '../pages/Dashboards/Rnc';
import Beneficiaries from '../pages/List/Beneficiaries';
import { beneficiaryRoles } from '../shared/roles/beneficiary';
import Beneficiary from '../pages/List/Beneficiary';
import FinancialTransaction from '../pages/Financial/FinancialTransaction';
import FinancialTransactions from '../pages/Financial/FinancialTransactions';
import FinancialTransactionsAsBankReconciliations from '../pages/Financial/FinancialTransactionsAsBankReconciliations';
import { financialIdModule } from '../shared/roles/financial';
import FinancialTransactionBeneficiaryAcessPage from '../pages/Financial/FinancialTransactionBeneficiaryAcessPage';
import Cashflow from '../pages/Financial/Cashflow';

const Routes: React.FC = () => (
  <Switch>
    {/* Login */}
    <Route path="/login" exact component={SignIn} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path={['/home', '/']} exact component={Home} isPrivate />
    <Route path="/forbidden" component={Forbidden} isPrivate />

    <Route
      path="/commercial/sats/:satId"
      component={Sat}
      isPrivate
      idEntity={satsRoles.idEntity}
    />

    <Route
      path="/commercial/sats"
      component={Sats}
      isPrivate
      idEntity={satsRoles.idEntity}
    />

    <Route
      path="/controls/commercial"
      component={ControlCommercial}
      isPrivate
      idEntity={commercialRoles.idEntity}
    />

    <Route
      path="/controls/design"
      component={ControlDesign}
      isPrivate
      idEntity={designRoles.idEntity}
    />

    <Route
      path="/controls/financial"
      component={ControlFinancial}
      isPrivate
      idEntity={satFinancialRoles.idEntity}
    />

    <Route
      path="/controls/followUp"
      component={ControlFollowUp}
      isPrivate
      idEntity={followUpRoles.idEntity}
    />

    <Route
      path="/controls/quality"
      component={ControlQuality}
      isPrivate
      idEntity={qualityRoles.idEntity}
    />

    <Route
      path="/controls/purchase"
      component={Purchase}
      isPrivate
      idEntity={satsPurchaseRoles.idEntity}
    />

    <Route
      path="/controls/export"
      component={Export}
      isPrivate
      idEntity={satsExportRoles.idEntity}
    />

    {/* Products */}
    <Route
      path="/products/list"
      exact
      component={Products}
      isPrivate
      idEntity={productRoles.idEntity}
    />
    <Route
      path="/products/list/:productId"
      component={Product}
      isPrivate
      idEntity={productRoles.idEntity}
    />
    <Route
      path="/products/list/create"
      component={Product}
      isPrivate
      idEntity={productRoles.idEntity}
    />
    <Route
      path="/products/checklists"
      exact
      component={Checklists}
      isPrivate
      idEntity={checklistRoles.idEntity}
    />
    <Route
      path="/products/checklists/create"
      exact
      component={Checklist}
      isPrivate
      idEntity={checklistRoles.idEntity}
    />
    <Route
      path="/products/checklists/:checklistId"
      component={Checklist}
      isPrivate
      idEntity={checklistRoles.idEntity}
    />

    <Route
      path="/users"
      exact
      component={Users}
      isPrivate
      idEntity={userRoles.idEntity}
    />
    <Route
      path="/users/create"
      component={User}
      isPrivate
      idEntity={userRoles.idEntity}
    />
    <Route
      path="/users/:userId"
      component={User}
      isPrivate
      idEntity={userRoles.idEntity}
    />

    <Route
      path="/userGroups"
      exact
      component={UserGroups}
      isPrivate
      idEntity={userGroupRoles.idEntity}
    />
    <Route
      path="/userGroups/create"
      component={UserGroup}
      isPrivate
      idEntity={userGroupRoles.idEntity}
    />
    <Route
      path="/userGroups/:userGroupId"
      component={UserGroup}
      isPrivate
      idEntity={userGroupRoles.idEntity}
    />

    <Route
      path="/list/ports"
      exact
      component={Ports}
      isPrivate
      idEntity={portRoles.idEntity}
    />
    <Route
      path="/list/ports/create"
      component={Port}
      isPrivate
      idEntity={portRoles.idEntity}
    />
    <Route
      path="/list/ports/:portId"
      component={Port}
      isPrivate
      idEntity={portRoles.idEntity}
    />

    <Route
      path="/list/incoterms"
      exact
      component={Incoterms}
      isPrivate
      idEntity={incotermRoles.idEntity}
    />
    <Route
      path="/list/incoterms/create"
      component={Incoterm}
      isPrivate
      idEntity={incotermRoles.idEntity}
    />
    <Route
      path="/list/incoterms/:incotermId"
      component={Incoterm}
      isPrivate
      idEntity={incotermRoles.idEntity}
    />

    <Route
      path="/roles"
      exact
      component={Roles}
      isPrivate
      idEntity={roleRoles.idEntity}
    />
    <Route
      path="/roles/create"
      component={Role}
      isPrivate
      idEntity={roleRoles.idEntity}
    />
    <Route
      path="/roles/:roleId"
      exact
      component={Role}
      isPrivate
      idEntity={roleRoles.idEntity}
    />

    <Route
      path="/roles/entity/:idEntity"
      component={RoleByFunctionality}
      isPrivate
      idEntity={roleRoles.idEntity}
    />

    <Route
      path="/finishings"
      exact
      component={Finishings}
      isPrivate
      idEntity={finishingRoles.idEntity}
    />
    <Route
      path="/finishings/create"
      component={Finishing}
      isPrivate
      idEntity={finishingRoles.idEntity}
    />
    <Route
      path="/finishings/:finishingId"
      component={Finishing}
      isPrivate
      idEntity={finishingRoles.idEntity}
    />

    <Route
      path="/packages"
      exact
      component={Packages}
      isPrivate
      idEntity={packageRoles.idEntity}
    />
    <Route
      path="/packages/create"
      component={Package}
      isPrivate
      idEntity={packageRoles.idEntity}
    />
    <Route
      path="/packages/:packageId"
      component={Package}
      isPrivate
      idEntity={packageRoles.idEntity}
    />

    <Route
      path="/pantones"
      exact
      component={Pantones}
      isPrivate
      idEntity={pantoneRoles.idEntity}
    />
    <Route
      path="/pantones/create"
      component={Pantone}
      isPrivate
      idEntity={pantoneRoles.idEntity}
    />
    <Route
      path="/pantones/:pantoneId"
      component={Pantone}
      isPrivate
      idEntity={pantoneRoles.idEntity}
    />

    <Route
      path="/products/ncms"
      exact
      component={Ncms}
      isPrivate
      idEntity={ncmRoles.idEntity}
    />
    <Route
      path="/products/ncm/create"
      component={Ncm}
      isPrivate
      idEntity={ncmRoles.idEntity}
    />
    <Route
      path="/products/ncm/:ncmId"
      component={Ncm}
      isPrivate
      idEntity={ncmRoles.idEntity}
    />

    <Route
      path="/list/payment-conditions"
      exact
      component={PaymentConditions}
      isPrivate
      idEntity={paymentConditionRoles.idEntity}
    />
    <Route
      path="/list/payment-conditions/create"
      component={PaymentCondition}
      isPrivate
      idEntity={paymentConditionRoles.idEntity}
    />
    <Route
      path="/list/payment-conditions/:paymentConditionId"
      component={PaymentCondition}
      isPrivate
      idEntity={paymentConditionRoles.idEntity}
    />

    <Route
      path="/list/couriers"
      exact
      component={Couriers}
      isPrivate
      idEntity={courierRoles.idEntity}
    />
    <Route
      path="/list/couriers/create"
      component={Courier}
      isPrivate
      idEntity={courierRoles.idEntity}
    />
    <Route
      path="/list/couriers/:courierId"
      component={Courier}
      isPrivate
      idEntity={courierRoles.idEntity}
    />

    <Route
      path="/clients/status"
      exact
      component={ClientStatuses}
      isPrivate
      idEntity={clientStatus.idEntity}
    />
    <Route
      path="/clients/status/create"
      component={ClientStatus}
      isPrivate
      idEntity={clientStatus.idEntity}
    />
    <Route
      path="/clients/status/:clientStatusId"
      component={ClientStatus}
      isPrivate
      idEntity={clientStatus.idEntity}
    />

    <Route
      path="/list/type-of-operations"
      exact
      component={ClientTypeOfOperations}
      isPrivate
      idEntity={clientTypeOfOperations.idEntity}
    />
    <Route
      path="/list/type-of-operations/create"
      component={ClientTypeOfOperation}
      isPrivate
      idEntity={clientTypeOfOperations.idEntity}
    />
    <Route
      path="/list/type-of-operations/:clientTypeOfOperationId"
      component={ClientTypeOfOperation}
      isPrivate
      idEntity={clientTypeOfOperations.idEntity}
    />

    <Route
      path="/clients/type-of-business"
      exact
      component={ClientTypeOfBusinesses}
      isPrivate
      idEntity={typeOfBusinessRoles.idEntity}
    />
    <Route
      path="/clients/type-of-business/create"
      exact
      component={ClientTypeOfBusiness}
      isPrivate
      idEntity={typeOfBusinessRoles.idEntity}
    />
    <Route
      path="/clients/type-of-business/:clientTypeOfBusinessId"
      exact
      component={ClientTypeOfBusiness}
      isPrivate
      idEntity={typeOfBusinessRoles.idEntity}
    />

    <Route
      path="/list/tax-benefits"
      exact
      component={ClientTaxBenefits}
      isPrivate
      idEntity={clientTaxBenefitRoles.idEntity}
    />
    <Route
      path="/list/tax-benefits/create"
      exact
      component={ClientTaxBenefit}
      isPrivate
      idEntity={clientTaxBenefitRoles.idEntity}
    />
    <Route
      path="/list/tax-benefits/:clientTaxBenefitsId"
      exact
      component={ClientTaxBenefit}
      isPrivate
      idEntity={clientTaxBenefitRoles.idEntity}
    />

    <Route
      path="/clients/number-of-stores"
      exact
      component={NumberStores}
      isPrivate
      idEntity={clientNumbersOfStoresRoles.idEntity}
    />
    <Route
      path="/clients/number-of-stores/create"
      component={NumberStore}
      isPrivate
      idEntity={clientNumbersOfStoresRoles.idEntity}
    />
    <Route
      path="/clients/number-of-stores/:numberStoreId"
      component={NumberStore}
      isPrivate
      idEntity={clientNumbersOfStoresRoles.idEntity}
    />

    <Route
      path="/list/special-negotiations"
      exact
      component={SpecialNegotiations}
      isPrivate
      idEntity={specialNegotiationRoles.idEntity}
    />
    <Route
      path="/list/special-negotiations/create"
      component={SpecialNegotiation}
      isPrivate
      idEntity={specialNegotiationRoles.idEntity}
    />
    <Route
      path="/list/special-negotiations/:specialNegotiationId"
      component={SpecialNegotiation}
      isPrivate
      idEntity={specialNegotiationRoles.idEntity}
    />

    <Route
      path="/list/contact-roles"
      exact
      component={ContactRoles}
      isPrivate
      idEntity={contactRoles.idEntity}
    />

    <Route
      path="/list/contact-roles/create"
      component={ContactRole}
      isPrivate
      idEntity={contactRoles.idEntity}
    />
    <Route
      path="/list/contact-roles/:contactRoleId"
      component={ContactRole}
      isPrivate
      idEntity={contactRoles.idEntity}
    />

    <Route
      path="/list/services"
      exact
      component={ClientServices}
      isPrivate
      idEntity={clientServicesRole.idEntity}
    />
    <Route
      path="/list/services/create"
      component={ClientService}
      isPrivate
      idEntity={clientServicesRole.idEntity}
    />
    <Route
      path="/list/services/:clientServiceId"
      component={ClientService}
      isPrivate
      idEntity={clientServicesRole.idEntity}
    />

    <Route
      path="/clients/list"
      exact
      component={ClientsList}
      isPrivate
      idEntity={clientRoles.idEntity}
    />
    <Route
      path="/clients/list/:clientId"
      component={Client}
      isPrivate
      idEntity={clientRoles.idEntity}
    />
    <Route
      path="/clients/list/create"
      component={Client}
      isPrivate
      idEntity={clientRoles.idEntity}
    />

    <Route
      path="/suppliers/list"
      exact
      component={SuppliersList}
      isPrivate
      idEntity={supplierRoles.idEntity}
    />
    <Route
      path="/suppliers/list/:supplierId"
      component={Supplier}
      isPrivate
      idEntity={supplierRoles.idEntity}
    />
    <Route
      path="/suppliers/list/create"
      component={Supplier}
      isPrivate
      idEntity={supplierRoles.idEntity}
    />

    <Route
      path="/partners/list"
      exact
      component={PartnersList}
      isPrivate
      idEntity={partnersListRoles.idEntity}
    />
    <Route
      path="/partners/list/:partnerId"
      component={Partner}
      isPrivate
      idEntity={partnersListRoles.idEntity}
    />
    <Route
      path="/partners/list/create"
      component={Partner}
      isPrivate
      idEntity={partnersListRoles.idEntity}
    />

    <Route
      path="/controls/register"
      exact
      component={Register}
      isPrivate
      idEntity={registerRoles.idEntity}
    />
    <Route
      path="/controls/import"
      exact
      component={Import}
      isPrivate
      idEntity={importRoles.idEntity}
    />

    {/* Categories */}
    <Route
      path="/list/categories"
      exact
      component={Categories}
      isPrivate
      idEntity={categoryRoles.idEntity}
    />
    <Route
      path="/list/categories/create"
      component={Category}
      isPrivate
      idEntity={categoryRoles.idEntity}
    />
    <Route
      path="/list/categories/:categoryId"
      component={Category}
      isPrivate
      idEntity={categoryRoles.idEntity}
    />

    {/* ICMS */}
    <Route
      path="/list/icms"
      exact
      component={IcmsList}
      isPrivate
      idEntity={icmsRoles.idEntity}
    />
    <Route
      path="/list/icms/create"
      component={IcmsDetail}
      isPrivate
      idEntity={icmsRoles.idEntity}
    />
    <Route
      path="/list/icms/:icmsId"
      component={IcmsDetail}
      isPrivate
      idEntity={icmsRoles.idEntity}
    />

    <Route
      path="/list/beneficiaries"
      exact
      component={Beneficiaries}
      isPrivate
      idEntity={beneficiaryRoles.idEntity}
    />

    <Route
      path="/list/beneficiaries/:idBeneficiary"
      component={Beneficiary}
      isPrivate
      idEntity={beneficiaryRoles.idEntity}
    />
    <Route
      path="/list/beneficiaries/create"
      component={Beneficiary}
      isPrivate
      idEntity={beneficiaryRoles.idEntity}
    />

    {/* Ocean Freight */}
    <Route
      path="/logistics/oceanFreight"
      exact
      component={OceanFreights}
      isPrivate
      idEntity={oceanFreightRoles.idEntity}
    />

    <Route
      path="/logistics/oceanFreight/create"
      component={OceanFreight}
      isPrivate
      idEntity={oceanFreightRoles.idEntity}
    />

    <Route
      path="/logistics/oceanFreight/:idOceanFreight"
      component={OceanFreight}
      isPrivate
      idEntity={oceanFreightRoles.idEntity}
    />

    {/** Port Tax */}
    <Route
      path="/logistics/portTaxes"
      exact
      component={PortTaxes}
      isPrivate
      idEntity={portTaxRoles.idEntity}
    />

    <Route
      path="/logistics/portTaxes/create"
      component={PortTax}
      isPrivate
      idEntity={portTaxRoles.idEntity}
    />

    <Route
      path="/logistics/portTaxes/:idPortTax"
      component={PortTax}
      isPrivate
      idEntity={portTaxRoles.idEntity}
    />

    {/* Ocean Freight Taxes */}
    <Route
      path="/logistics/oceanFreightTaxes"
      exact
      component={OceanFreightTaxes}
      isPrivate
      idEntity={oceanFreightTaxRoles.idEntity}
    />

    <Route
      path="/logistics/oceanFreightTaxes/:idOceanFreightTax"
      component={OceanFreightTax}
      isPrivate
      idEntity={oceanFreightTaxRoles.idEntity}
    />

    {/* Air Freight */}
    <Route
      path="/logistics/airFreight"
      exact
      component={AirFreights}
      isPrivate
      idEntity={airFreightRoles.idEntity}
    />

    <Route
      path="/logistics/airFreight/create"
      component={AirFreight}
      isPrivate
      idEntity={airFreightRoles.idEntity}
    />

    <Route
      path="/logistics/airFreight/:idAirFreight"
      component={AirFreight}
      isPrivate
      idEntity={airFreightRoles.idEntity}
    />

    {/* Air Freight Taxes */}
    <Route
      path="/logistics/airFreightTaxes"
      exact
      component={AirFreightTaxes}
      isPrivate
      idEntity={airFreightTaxRoles.idEntity}
    />

    <Route
      path="/logistics/airFreightTaxes/:idAirFreightTax"
      component={AirFreightTax}
      isPrivate
      idEntity={airFreightTaxRoles.idEntity}
    />

    {/* PRCs */}
    <Route path="/prcs/list" exact component={Prcs} isPrivate />
    <Route path="/prcs/list/create" component={Prc} isPrivate />

    <Route path="/prcs/list/:idPrc" component={Prc} isPrivate />

    {/** Airport Tax */}
    <Route
      path="/logistics/airportTaxes"
      exact
      component={AirportTaxes}
      isPrivate
      idEntity={airportTaxRoles.idEntity}
    />

    <Route
      path="/logistics/airportTaxes/create"
      component={AirportTax}
      isPrivate
      idEntity={airportTaxRoles.idEntity}
    />

    <Route
      path="/logistics/airportTaxes/:idAirportTax"
      component={AirportTax}
      isPrivate
      idEntity={airportTaxRoles.idEntity}
    />

    {/** Inland Freight */}
    <Route
      path="/logistics/inlandFreights"
      exact
      component={InlandFreights}
      isPrivate
      idEntity={inlandFreightRoles.idEntity}
    />

    <Route
      path="/logistics/inlandFreights/create"
      component={InlandFreight}
      isPrivate
      idEntity={inlandFreightRoles.idEntity}
    />

    <Route
      path="/logistics/inlandFreights/:idInlandFreight"
      component={InlandFreight}
      isPrivate
      idEntity={inlandFreightRoles.idEntity}
    />

    {/** Inland Freight Tax */}
    <Route
      path="/logistics/inlandFreightTaxes"
      exact
      component={InlandFreightTaxes}
      isPrivate
      idEntity={inlandFreightTaxRoles.idEntity}
    />

    <Route
      path="/logistics/inlandFreightTaxes/create"
      component={InlandFreightTax}
      isPrivate
      idEntity={inlandFreightTaxRoles.idEntity}
    />

    <Route
      path="/logistics/inlandFreightTaxes/:idInlandFreightTax"
      component={InlandFreightTax}
      isPrivate
      idEntity={inlandFreightTaxRoles.idEntity}
    />

    {/* Expenses */}
    <Route
      path="/logistics/expenses"
      exact
      component={Expenses}
      isPrivate
      idEntity={expenseRoles.idEntity}
    />

    <Route
      path="/logistics/expenses/:idExpense"
      component={Expense}
      isPrivate
      idEntity={expenseRoles.idEntity}
    />

    {/* Table Siscomex */}
    <Route
      path="/logistics/tableSiscomexes"
      exact
      component={TableSiscomexes}
      isPrivate
      idEntity={tableSiscomexRoles.idEntity}
    />

    <Route
      path="/logistics/tableSiscomexes/:idTableSiscomex"
      component={TableSiscomex}
      isPrivate
      idEntity={tableSiscomexRoles.idEntity}
    />

    {/** Client Quotations */}
    <Route
      path="/client-quotations/list"
      exact
      component={ClientQuotations}
      isPrivate
      idEntity={clientQuotationRoles.idEntity}
    />
    <Route
      path="/client-quotations/list/:idCq"
      component={ClientQuotation}
      isPrivate
      idEntity={clientQuotationRoles.idEntity}
    />
    <Route
      path="/client-quotations/list/create"
      component={ClientQuotation}
      isPrivate
      idEntity={clientQuotationRoles.idEntity}
    />
    <Route
      path="/dashboards/register"
      exact
      component={RegisterDashboad}
      isPrivate
      idEntity={registerDashboardRoles.idEntity}
    />

    <Route
      path="/dashboards/artwork"
      exact
      component={ArtwordDashboard}
      isPrivate
      idEntity={artworkDashboardRoles.idEntity}
    />
    <Route
      path="/dashboards/clientPayment"
      exact
      component={ClientPaymentDashboard}
      isPrivate
      idEntity={clientPaymentDashboardRoles.idEntity}
    />
    <Route
      path="/dashboards/documents"
      exact
      component={DocumentsDashboad}
      isPrivate
      idEntity={documentsDashboardRoles.idEntity}
    />

    <Route
      path="/dashboards/shipment"
      exact
      component={ShipmentDashboad}
      isPrivate
      idEntity={shipmentDashboardRoles.idEntity}
    />

    <Route
      path="/dashboards/supplierPayment"
      exact
      component={SupplierPaymentDashboard}
      isPrivate
      idEntity={supplierPaymentDashboardRoles.idEntity}
    />

    <Route
      path="/dashboards/production"
      exact
      component={ProductionDashboard}
      isPrivate
      idEntity={productionDashboardRoles.idEntity}
    />

    <Route
      path="/dashboards/rnc"
      exact
      component={RncDashboard}
      isPrivate
      idEntity={rncDashboardRoles.idEntity}
    />

    <Route
      path="/financial/transactions"
      exact
      component={FinancialTransactions}
      isPrivate
      idModule={financialIdModule}
    />
    <Route
      path="/financial/transactionsAsBankReconciliations"
      exact
      component={FinancialTransactionsAsBankReconciliations}
      isPrivate
      idModule={financialIdModule}
    />
    <Route
      path="/financial/transactions/create"
      component={FinancialTransaction}
      isPrivate
      idModule={financialIdModule}
    />
    <Route
      path="/financial/transactions/:idFinancialTransaction"
      component={FinancialTransaction}
      isPrivate
      idModule={financialIdModule}
    />

    <Route
      path="/financial/transactionBeneficiaryAcessPage/:idFinancialTransaction"
      component={FinancialTransactionBeneficiaryAcessPage}
      isPrivate
    />

    <Route
      path="/financial/cashflow"
      exact
      component={Cashflow}
      isPrivate
      idModule={financialIdModule}
    />

    {/** Pagina nao encontrada */}
    <Route component={NotFound} isPrivate />
  </Switch>
);

export default Routes;
