export const portTaxRoles = {
  idEntity: 36,
  idModule: 10,
  permissions: {
    idPermissionCreatePortTax: 59,
    idPermissionUpdatePortTax: 60,
    idPermissionDeletePortTax: 61,
    idPermissionMassiveActionPortTax: 96,
    idPermissionExportListPortTax: 70,
  },
};
