import { gql } from '@apollo/client';

/**
 * Mutation para reorder de SAT Item row
 */
export const reorderSatItemQuery = gql`
  mutation ReorderSatItem($data: ReorderSatItemInput!) {
    reorderSatItem(data: $data)
  }
`;

/**
 * Mutation de atualizacao de dados de sat item
 */
export const updateSatItemsQuery = gql`
  mutation UpdateSatItems($data: UpdateSatItemsInput!) {
    updateSatItems(data: $data) {
      updatedSatItems {
        idSatItem
      }
      warnings
      errors
    }
  }
`;
