import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--grey);
  padding: 4px;

  border: solid 1px transparent;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.2s;
  * {
    box-shadow: none !important;
  }

  ${props =>
    props.readOnly &&
    css`
      background: none;
      opacity: unset !important;
      cursor: auto !important;
      [class*='indicatorContainer'] {
        display: none;
      }
      [class*='ValueContainer'] {
        * {
          color: #000;
          margin-left: 0;
        }

        svg {
          display: none;
        }
      }
    `}

  ${props =>
    props.hasError &&
    css`
      border-color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: var(--blue);
    `}

  .react-select {
    [aria-disabled] {
      opacity: 1 !important;
    }
    * {
      min-height: 0;
    }

    [class*='multiValue'] {
      background-color: #fff;
    }

    [class*='control'] {
      background: none;
      border: none;
      * {
        padding: 0 !important;
      }
    }

    [class*='menu'] {
      border: solid 1px var(--blue);
      z-index: 2;
    }

    [class*='Input'] {
      margin: 0;
    }

    [class*='indicatorContainer'] {
      padding: 0 8px;
    }
  }

  svg {
    margin-right: 4px;
  }

  span {
    display: flex;
  }

  p {
    color: var(--grey-scale);
    display: flex;

    ${props =>
      props.required &&
      css`
        &::after {
          margin-top: 4px;
          margin-left: 8px;
          width: 8px;
          height: 8px;
          background: var(--red);
          content: '';
          border-radius: 50%;
        }
      `}
  }
`;
