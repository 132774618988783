import { gql } from '@apollo/client';

export const listSatContainersByForeignTradeQuery = gql`
  query ListSatContainersByForeignTrade(
    $data: ListSatContainersByForeignTradeInput!
  ) {
    listSatContainersByForeignTrade(data: $data) {
      items
      data {
        idSatCtnr
        idSatForeignTrade
        idTypeCtnr2 {
          idDomain
          description
        }
        qtyCtnr
      }
    }
  }
`;

export const deleteSatContainerQuery = gql`
  mutation DeleteSatContainer($idSatCtnr: Int!) {
    deleteSatContainer(idSatCtnr: $idSatCtnr)
  }
`;
