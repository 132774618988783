import styled from 'styled-components';

export const Container = styled.div``;

export const Row = styled.div``;

export const Buttons = styled.div`
  padding: 4px;
  display: flex;
  justify-content: flex-end;

  button {
    margin: 4px;
  }
`;
