import {
  AdvancedFiltersFieldType,
  IAdvancedFiltersField,
} from '../../../../components/AdvancedFiltersPanel/interfaces';

export const commonFiltersFields: IAdvancedFiltersField[] = [
  { advancedFilterField: 'createdBy', header: 'Created By', value: '' },
  {
    advancedFilterField: 'createdCommentStartDateRange',
    header: 'Created update start date range',
    type: AdvancedFiltersFieldType.DATE,
  },
  {
    advancedFilterField: 'createdCommentEndDateRange',
    header: 'Created update end date range',
    type: AdvancedFiltersFieldType.DATE,
  },
  {
    advancedFilterField: 'isGeneratedSystem',
    header: 'System generated updates',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
];

export const restrictedFiltersField: IAdvancedFiltersField = {
  advancedFilterField: 'isRestrictInfo',
  header: 'Restrict info updates',
  type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
};
