import { Dialog } from 'primereact/dialog';
import React, { Ref, useImperativeHandle, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { gql } from '@apollo/client';
import { ValidationError, array, number, object } from 'yup';
import { useHistory } from 'react-router-dom';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import pagination from '../../../config/pagination';
import { Container } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';

export interface IPickFuncionalityAndRolesDialogRef {
  toggleDialog(): void;
}

interface IPickFuncionalityAndRolesDialogProps {
  ref: Ref<IPickFuncionalityAndRolesDialogRef>;
}

const PickFuncionalityAndRolesDialog: React.FC<IPickFuncionalityAndRolesDialogProps> =
  React.forwardRef((props, ref) => {
    const history = useHistory();

    const [displayDialog, setDisplayDialog] = useState(false);

    const formRef = useRef<FormHandles>(null);

    const loadEntitiesOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllEntities($listAllEntitiesInput: ListAllEntitiesInput!) {
            listAllEntities(listAllEntitiesInput: $listAllEntitiesInput) {
              data {
                idEntity
                name
              }
              items
            }
          }
        `,
        variables: {
          listAllEntitiesInput: {
            active: true,
            pagination: {
              _page: pageData?.page,
              _limit: pagination.itemsPerPage,
              _orderBy: 'name',
            },
            name: search,
          },
        },
      });

      const filteredOptions = res.data.listAllEntities.data;
      const quantity = res.data.listAllEntities.items;

      const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    const loadRolesOptions: LoadOptions<
      any,
      GroupBase<any>,
      { page: any }
    > = async (search: string, prevOptions: any, pageData) => {
      const res = await client.query({
        query: gql`
          query listAllRolesQuery(
            $listAllRolesInput: ListAllRolesInput
            $pagination: Pagination!
          ) {
            listAllRoles(
              listAllRolesInput: $listAllRolesInput
              pagination: $pagination
            ) {
              data {
                idRole
                name
                active
              }
              items
            }
          }
        `,
        variables: {
          listAllRolesInput: {
            name: search,
            active: true,
          },
          pagination: {
            _page: pageData?.page,
            _limit: pagination.itemsPerPage,
            _orderBy: 'name',
          },
        },
      });

      const filteredOptions = res.data.listAllRoles.data;
      const quantity = res.data.listAllRoles.items;

      const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

      return {
        options: filteredOptions,
        hasMore,
        additional: {
          page: pageData?.page + 1,
        },
      };
    };

    async function handleConfirm(formData: {
      idEntity: number;
      idsRoles: number[];
    }) {
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          idsRoles: array()
            .min(1, 'Required Field')
            .max(5, 'You can only select up to 5 roles')
            .required("You can't leave this blank")
            .nullable(),
          idEntity: number().nullable().required('Required Field'),
        });

        await schema.validate(formData, { abortEarly: false });

        history.push(
          `/roles/entity/${formData.idEntity}?roles=${formData.idsRoles}`,
        );
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
      }
    }

    useImperativeHandle(ref, () => ({
      toggleDialog: () => {
        setDisplayDialog(!displayDialog);
      },
    }));

    /**
     * Footer da modal
     * @returns Footer
     */
    const dialogFooter = () => {
      return (
        <div className="flex gap-2">
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => {
              setDisplayDialog(false);
            }}
            className="p-button-danger"
          />
        </div>
      );
    };

    return (
      <Dialog
        header="Select Functionality/Roles"
        visible={displayDialog}
        style={{ height: '490px' }}
        onHide={() => setDisplayDialog(false)}
        closable={false}
        footer={dialogFooter()}
      >
        <Form ref={formRef} onSubmit={handleConfirm} style={{ height: '100%' }}>
          <Container>
            <FormAsyncSelect
              id="roles-functionality-dropdown"
              name="idEntity"
              required
              label="Functionality"
              loadOptions={loadEntitiesOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.idEntity}
              noOptionsMessage={() => 'No functionalities found'}
              autoFocus
              openMenuOnFocus
            />

            <FormAsyncSelect
              name="idsRoles"
              required
              label="Roles"
              placeholder="Select up to 5 roles"
              loadOptions={loadRolesOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.idRole}
              noOptionsMessage={() => 'No roles found'}
              defaultMenuIsOpen
              isMulti
              closeMenuOnSelect={false}
              menuPosition="fixed"
            />
          </Container>
        </Form>
      </Dialog>
    );
  });

export default PickFuncionalityAndRolesDialog;
