import { gql } from '@apollo/client';

// Query para deletar
export const queryToDelete = gql`
  mutation Delete($domainsId: [Int]!) {
    deleteDomains(domainsId: $domainsId)
  }
`;

// Query para listar dominios
export const queryToListDomains = gql`
  query ListDomainsByGroupIdQuery(
    $id: Int!
    $pagination: Pagination!
    $description: String
  ) {
    listDomainsByGroupId(
      id: $id
      pagination: $pagination
      description: $description
    ) {
      data {
        idDomain
        description
        versionLock
      }
      items
    }
  }
`;

// Query para atualizar
export const queryToUpdate = gql`
  mutation UpdateMutation($domainData: UpdateDomain!) {
    updateDomain(domain: $domainData) {
      description
      versionLock
    }
  }
`;

// Query para listar dominios
export const queryToListIdDomain = gql`
  query ListDomainQuery($idDomain: Int!) {
    listDomainById(id: $idDomain) {
      description
      idGroup
      versionLock
    }
  }
`;

// Query para criar dominios
export const queryToCreateDomain = gql`
  mutation CreateDomainMutation($domainData: CreateDomain!) {
    createDomain(domain: $domainData) {
      description
    }
  }
`;

// Query para listar partners
export const queryToListPartners = gql`
  query listAllPartnersQuery($listAllPartnersInput: ListAllPartnersInput!) {
    listAllPartners(listAllPartnersInput: $listAllPartnersInput) {
      data {
        idPartner
        ptnNumber
        coresString
        name
        idRegion2 {
          idRegion
          name
        }
        idCountry2 {
          idCountry
          name
        }
        idCity2 {
          idCity
          name
        }
      }
      items
    }
  }
`;

// Query para listar roles
export const listRolesQuery = gql`
  query listAllRolesQuery(
    $listAllRolesInput: ListAllRolesInput
    $pagination: Pagination!
  ) {
    listAllRoles(
      listAllRolesInput: $listAllRolesInput
      pagination: $pagination
    ) {
      data {
        idRole
        name
        active
      }
      items
    }
  }
`;

// Query para listar departmentos contato do parceiro
export const listDepartmentQuery = gql`
  query listAllDepartmentsQuery(
    $listAllDepartmentsInput: ListAllDepartmentsInput!
  ) {
    listAllDepartments(listAllDepartmentsInput: $listAllDepartmentsInput) {
      data {
        idDepartment
        name
        createdBy
        createdAt
        updatedBy
        updatedAt
        versionLock
      }
      items
    }
  }
`;
