import { gql } from '@apollo/client';

/**
 * Mutation para criar SAT Quotation
 */
export const createSatQuotationQuery = gql`
  mutation CreateSatQuotation($data: CreateSatQuotationInput!) {
    createSatQuotation(data: $data) {
      idSatQuotation
    }
  }
`;

/**
 * Mutation para atualizar SAT Quotation
 */
export const updateSatQuotationQuery = gql`
  mutation UpdateSatQuotation($data: UpdateSatQuotationInput!) {
    updateSatQuotation(data: $data) {
      idSatQuotation
    }
  }
`;
