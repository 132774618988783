/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { useRefHook } from '../../../hooks/useRefHook';
import { Container, CrudForm } from './styles';

import {
  queryToUpdate,
  queryToListIdDomain,
  queryToCreateDomain,
} from '../../../services/querysGql';
import { IGeneralInformationFormData } from '../../../services/dataInterface';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface ClientTypeOfOperationRouteParams {
  clientTypeOfOperationId: string;
}
const idGroupClientTypeOfOperation = 9;

const ClientTypeOfOperation: React.FC = () => {
  // ID do tipo de operação
  const { clientTypeOfOperationId } =
    useParams<ClientTypeOfOperationRouteParams>();

  const defaultPageTitle = `${
    clientTypeOfOperationId ? 'Manage' : 'Create'
  } type of operations`;

  // Nome do tipo de operação
  const [clientTypeOfOperationName, setClientTypeOfOperationName] =
    useState('');

  useTitle(
    clientTypeOfOperationName
      ? `${clientTypeOfOperationName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Estado da versão lock otimista
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Type of operation',
      path: '/list/type-of-operations',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Carrega response do tipo(s) de operação(es)
  const [
    loadClientTypeOfOperationData,
    {
      called: clientTypeOfOperationCalled,
      loading: clientTypeOfOperationLoading,
      data: clientTypeOfOperationData,
      error: clientTypeOfOperationError,
    },
  ] = useLazyQuery(queryToListIdDomain, {
    variables: {
      idDomain: parseInt(clientTypeOfOperationId, 10),
    },
    onCompleted: async response => {
      // Valida se o tipo de operação nao foi encontrado
      if (
        !response.listDomainById ||
        response.listDomainById.idGroup !== idGroupClientTypeOfOperation
      ) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Type of operation not found',
          life: ToastLife.ERROR,
        });
        // Redireciona para listagem de tipo(s) de operação(es) cadastrado(s)
        history.push('/list/type-of-operations');
        return;
      }
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listDomainById.versionLock);

      // Define novo nome do tipo de operação
      setClientTypeOfOperationName(response.listDomainById.description);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting type of operation data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/list/type-of-operations');
    },
  });

  // Ao carregar a pagina, carrega o(s) tipo(s) de operação(es) cadastrado(s)
  useEffect(() => {
    if (clientTypeOfOperationId) {
      setPageLoading(true);
      loadClientTypeOfOperationData();
    }
  }, [clientTypeOfOperationId, loadClientTypeOfOperationData]);

  // cria método para chamar a mutation
  const [createClientTypeOfOperation] = useMutation(queryToCreateDomain);
  const [updateClientTypeOfOperation] = useMutation(queryToUpdate);

  // Submit para criação de tipo de operação
  const handleCreateClientTypeOfOperationSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria um tipos de operações
        await createClientTypeOfOperation({
          variables: {
            domainData: {
              description,
              idGroup: idGroupClientTypeOfOperation,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Type of operation created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem do(s) tipo(s) de operação(es)
        history.push('/list/type-of-operations');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);
          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating type of operation',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createClientTypeOfOperation, toastRef, history],
  );

  const handleEditClientTypeOfOperationSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes do tipo de operação
        const updateResponse = await updateClientTypeOfOperation({
          variables: {
            domainData: {
              idDomain: parseInt(clientTypeOfOperationId, 10),
              description,
              versionLock,
            },
          },
        });
        // atualiza estado de acordo novo registro
        setVersionLock(updateResponse.data.updateDomain.versionLock);

        // Define novo nome da tipos de operações
        setClientTypeOfOperationName(description);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Type of operation updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating type of operation',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      updateClientTypeOfOperation,
      clientTypeOfOperationId,
      versionLock,
      toastRef,
    ],
  );

  const handleCancel = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/type-of-operations'),
    });
  };

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          clientTypeOfOperationData
            ? `${defaultPageTitle} - ${clientTypeOfOperationName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            clientTypeOfOperationId
              ? handleEditClientTypeOfOperationSubmit
              : handleCreateClientTypeOfOperationSubmit
          }
          initialData={
            clientTypeOfOperationCalled &&
            !clientTypeOfOperationLoading &&
            !clientTypeOfOperationError
              ? clientTypeOfOperationData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
      </CrudForm>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default ClientTypeOfOperation;
