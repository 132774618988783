import { gql } from '@apollo/client';

/**
 * Query para listar o SAT Group para o Confirm Order
 */
export const listSatGroupToConfirmOrderCommercialQuery = gql`
  query ListSatGroupToConfirmOrderCommercial($idSatGroup: Int!) {
    listSatGroupToConfirmOrderCommercial(idSatGroup: $idSatGroup) {
      idSatGroup
      isConfirmOrderInGroupPossible
      sats {
        idSat
        satNumber
        mainProduct
        amountTotal
        productionTime
        estimatedInspectionDate
        estimatedShipmentDate
        estimatedArrivalDate
        estimatedArrivalClientDate
        clientDepartment
        idCategory
        idSatOrigin2 {
          satNumber
        }
        idClientIncoterm2 {
          description
        }
        idClient2 {
          name
        }
        idFirstBuyer2 {
          name
        }
        idSecondBuyer2 {
          name
        }
        idSalesUser2 {
          firstName
          lastName
        }
        idSalesSecondUser2 {
          firstName
          lastName
        }
        satCtnrs {
          qtyCtnr
          idTypeCtnr2 {
            description
          }
        }
        idSupplierExporter2 {
          name
        }
        idLoadingPort2 {
          name
        }
      }
    }
  }
`;

/**
 * Query para listar o SAT Group para o Confirm Order
 */
export const listSatGroupToConfirmOrderPurchaseQuery = gql`
  query ListSatGroupToConfirmOrderPurchase($idSatGroup: Int!) {
    listSatGroupToConfirmOrderPurchase(idSatGroup: $idSatGroup) {
      idSatGroup
      isConfirmOrderInGroupPossible
      sats {
        idSat
        satNumber
        mainProduct
        idCategory
        estimatedShipmentDate
        idClient2 {
          name
        }
        idSupplierExporter2 {
          name
        }
        satSuppliers {
          idSupplier2 {
            name
          }
        }
        typeOfOrder2 {
          description
        }
        idPurchaseUser2 {
          firstName
          lastName
        }
        idSalesUser2 {
          firstName
          lastName
        }
        idSalesSecondUser2 {
          firstName
          lastName
        }
        idCurrency2 {
          currency
        }
      }
    }
  }
`;

/**
 * Mutation para enviar o email de confirm order commercial de uma SAT
 */
export const confirmSatOrderCommercialInputQuery = gql`
  mutation ConfirmSatOrderCommercial($data: ConfirmSatOrderCommercialInput!) {
    confirmSatOrderCommercial(data: $data)
  }
`;

/**
 * Mutation para enviar o email de confirm order commercial de todas as SATs de um grupo
 */
export const confirmSatGroupOrderCommercialInputQuery = gql`
  mutation ConfirmSatGroupOrderCommercial(
    $data: ConfirmSatGroupOrderCommercialInput!
  ) {
    confirmSatGroupOrderCommercial(data: $data) {
      satsCompleted
      errors
    }
  }
`;

/**
 * Mutation para enviar o email de confirm order purchase de uma SAT
 */
export const sendSatConfirmOrderPurchaseEmailInputQuery = gql`
  mutation SendSatConfirmOrderPurchaseEmail(
    $sendSatConfirmOrderPurchaseEmailInput: SendSatConfirmOrderPurchaseEmailInput!
  ) {
    sendSatConfirmOrderPurchaseEmail(
      sendSatConfirmOrderPurchaseEmailInput: $sendSatConfirmOrderPurchaseEmailInput
    )
  }
`;

/**
 * Mutation para enviar o email de confirm order purchase de todas as SATs de um grupo
 */
export const confirmSatGroupOrderPurchaseInputQuery = gql`
  mutation ConfirmSatGroupOrderPurchase(
    $data: ConfirmSatGroupOrderPurchaseInput!
  ) {
    confirmSatGroupOrderPurchase(data: $data) {
      satsCompleted
      errors
    }
  }
`;
