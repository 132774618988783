import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function convertDate(date: Date): string {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formatedDate = format(utcToZonedTime(date, timezone), 'LLL dd, yyyy p');

  return formatedDate;
}

/**
 * Converte UTC para Time Zone
 * @param date Data a ser convertida
 * @returns Data convertida para Timezone
 */
export function convertUtcToZonedTime(date: Date): Date {
  // Busca timezone do usuario
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Converte data UTC para Time Zone
  const parsedDate = utcToZonedTime(date, timezone);

  return parsedDate;
}

/**
 * Remove informacoes de hora de data em ISO
 * @param date Data a ser modificada
 * @returns Data sem valor de horario
 */
export function removeTimeFromIsoDate(date: string): string {
  return date.split('T', 1)[0];
}

/**
 * Converte data em string para formato local
 * @param date Data a ser convertida para formato local
 * @returns String de data convertida em formato local
 */
export function parseToLocaleStringDate(
  date?: string | Date,
): string | undefined {
  if (!date) return undefined;

  return new Date(date).toLocaleDateString();
}
