import { gql } from '@apollo/client';

export const listSupplierAssessmentsQuery = gql`
  query ListSupplierAssesments($data: ListSupplierAssesmentsInput!) {
    listSupplierAssesments(data: $data) {
      items
      data {
        idSupplierAssesment
        idType
        idType2 {
          description
          idDomain
        }
        imageUrl
        name
        expirationDate
        versionLock
      }
    }
  }
`;

export const deleteSupplierAssessmentsQuery = gql`
  mutation DeleteSupplierAssesments($idsSupplierAssesments: [Int!]!) {
    deleteSupplierAssesments(idsSupplierAssesments: $idsSupplierAssesments)
  }
`;
