import { SortOrder } from 'primereact/datatable';
import ILazyParams from '../../../../../services/lazyParams';
import { IFieldPermissionResponse } from '../../../../../utils/getFieldPermission';
import {
  IListSatItem,
  ISatItemReference,
  ISatReferenceSizeBarcode,
} from '../../interfaces';
import { DataTableMultiSortMetaType } from '../../../../../components/Grid/interfaces';

// eslint-disable-next-line no-shadow
export enum SatItemsReducerActionKind {
  CHANGE_ADD_SAT_ITEM,
  SET_SAT_ITEMS,
  SET_SELECTED_SAT_ITEMS,
  CLEAN_SAT_ITEMS_RELATED_DATA,
  SET_EDITING_ROWS,
  SET_SAT_ITEMS_TO_UPDATE,
  SET_SAT_REFERENCES_TO_UPDATE,
  SET_SAT_REFERENCE_SIZE_BARCODE_TO_UPDATE,
  CHANGE_ALL_FIELDS_SELECTED,
  CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS,
  CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_ITEMS,
  CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS,
  CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS,
}

/**
 * Interface dos parametros do backend
 */
export interface ISatItemsLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | BigInt
    | SortOrder
    | DataTableMultiSortMetaType
    | boolean
    | undefined;

  // ST Code
  stCode?: string;

  // SUN
  idSupplier?: number;

  // Name
  name?: string;

  // Name (PT)
  namePt?: string;

  // Model
  model?: string;

  // Sku
  sku?: string;

  // Description;
  description?: string;

  // Colour
  colour?: string;

  // Material Composition
  materialComposition?: string;

  // Dimensions
  dimensions?: string;

  // Hs Code
  hsCode?: BigInt;

  // NCM
  ncm?: number;

  // Sun Price
  sunPrice?: number;

  // Target Sun
  targetSun?: number;

  // COT Price
  cotPrice?: number;

  // Target COT
  targetCot?: number;

  // Amount COT
  amountCot?: number;

  // Brand
  brand?: string;

  // Product Line
  productLine?: string;

  // Net Weight (KG)
  netWeight?: string;

  // Selling Package
  packageDescription?: string;

  // QSP
  sellingPackagePcQuantity?: number;

  // Selling Package Dimension
  sellingPackageDimensions?: string;

  // SPGW
  sellingPackageGrossWeight?: number;

  // Quantity
  quantity?: number;

  // Quantity Shipped
  quantityShipped?: number;

  // Loading Amount COT
  loadingAmountCot?: number;

  // PCS/CTR
  piecesContainer?: number;

  // Unit
  unit?: number;

  // Quantity Container
  quantityContainer?: number;

  // Length
  length?: string;

  // Width
  width?: string;

  // Height
  height?: string;

  // CBM
  cbm?: number;

  // Master Net Weight
  masterNetWeight?: number;

  // MNWT
  masterNetWeightTotal?: number;

  // PC (M²)
  m2Pc?: number;

  // Total Master (M²)
  totalMasterM2?: number;

  // Gross Weight
  grossWeight?: number;

  // Gross Weight Total
  grossWeightTotal?: number;

  // CBM Total
  cbmTotal?: number;

  // Price FOB
  priceFob?: number;

  // Amount
  amount?: number;

  // MOQ
  moq?: number;

  // Multiplicator
  multiplicator?: number;

  // FX
  fx?: number;

  // Notes
  notes?: string;

  // Import License
  generalLi?: boolean;

  // Estimate Profit
  estimateProfit?: number;

  // Estimate Margin
  estimateMargin?: number;

  // Gross Profit
  grossProfit?: number;

  // Gross Margin
  grossMargin?: number;

  refCode?: string;

  detailedDescriptionPt?: string;

  descriptionPt?: string;

  colourPt?: string;

  materialPt?: string;

  packageNotes?: string;

  packageEan13?: string;

  innerdun14?: string;

  dun14?: string;

  typeOfMaterial?: string;

  lining?: string;

  liningComposition?: string;

  typePackage?: string;

  remainQuantity?: number;

  totalSqmCi?: number;

  remainSqm?: number;

  cotPriceSqm?: number;

  sunPriceSqm?: number;

  purchaseIncoterm?: string;

  remainTotalCot?: number;

  sellingIncoterm?: string;

  totalSunCi?: number;

  remainTotalSun?: number;

  ncmDestaque?: number;

  foreignTradeReview?: boolean;

  ncmIi?: number;

  ncmIpi?: number;

  ncmPis?: number;

  ncmCofins?: number;

  ncmDumping?: boolean;

  containerNumber?: string;

  sample?: boolean;

  sampleLeadTime?: number;

  sampleNotes?: string;

  licensed?: boolean;

  licensor?: string;

  royalties?: number;

  artworkEn?: number;

  artworkPt?: number;

  samplesApproved?: boolean;

  unitPerContainer?: string;

  totalCotCi?: number;

  nve?: string;
}

export type SatReferenceSizeBarcodeToUpdate = {
  idSatReferenceSizeBarcode: number;
  sizeRange?: number;
};

export type SatItemReferenceToUpdate = {
  idSatItemReference: number;
  totalPiCollor?: number;
};

export type SatItemToUpdate = {
  idSatItem: number;
  cotPrice?: number;
  targetCot?: number;
  sunPrice?: number;
  targetSun?: number;
  quantity?: number;
  sample?: boolean;
  samplesApproved?: boolean;
  quantityShipped?: number;
  sampleNotes?: string;
};

export interface ISatItemsReducer {
  displayAddSatItemModal: boolean;
  satItems: IListSatItem[];
  selectedSatItems: IListSatItem[];
  satItemsToUpdate: SatItemToUpdate[];
  editingRows: IListSatItem[];
  referencesToUpdate: SatItemReferenceToUpdate[];
  referenceSizeBarcodesToUpdate: SatReferenceSizeBarcodeToUpdate[];
  allFieldsSelected: boolean;
  displayRefreshItemSelectFieldsModal: boolean;
  displayRefreshItemSelectItemsModal: boolean;
}

export interface ISatItemsReducerAction {
  type: SatItemsReducerActionKind;
  payload?: {
    satItems?: IListSatItem[];
    selectedSatItems?: IListSatItem[];
    itemToUpdate?: IListSatItem;
    referenceToUpdate?: ISatItemReference;
    referenceSizeBarcodeToUpdate?: ISatReferenceSizeBarcode;
    editingRows?: IListSatItem[];
  };
}

export type SatItemsReferenceSizeBarcodesFieldsPermissions = {
  size: IFieldPermissionResponse;
  reference: IFieldPermissionResponse;
  unit: IFieldPermissionResponse;
  sellingPackage: IFieldPermissionResponse;
  unitInnerBox: IFieldPermissionResponse;
  innerBox: IFieldPermissionResponse;
  unitMasterBox: IFieldPermissionResponse;
  masterBox: IFieldPermissionResponse;
  cotPiSize: IFieldPermissionResponse;
  sunPiSize: IFieldPermissionResponse;
  qtyCiSize: IFieldPermissionResponse;
  remainQuantityPiShipment: IFieldPermissionResponse;
  cotCiSize: IFieldPermissionResponse;
  sunCiSize: IFieldPermissionResponse;
  remainTotCotSize: IFieldPermissionResponse;
  remainTotSunSize: IFieldPermissionResponse;
  sizeRange: IFieldPermissionResponse;
  qtyPiSize: IFieldPermissionResponse;
};

export type SatItemsReferenceSizesFieldsPermissions = {
  imageColorUrl: IFieldPermissionResponse;
  colourEn: IFieldPermissionResponse;
  colourPt: IFieldPermissionResponse;
  gender: IFieldPermissionResponse;
  sizeGroup: IFieldPermissionResponse;
  totalPiCollor: IFieldPermissionResponse;
  totalCiCollor: IFieldPermissionResponse;
  remainCollorVar: IFieldPermissionResponse;
  referenceSizeBarcodes: SatItemsReferenceSizeBarcodesFieldsPermissions;
};

export type RefreshSelectFieldsPermissions = {
  sunPrice: IFieldPermissionResponse;
  cotPrice: IFieldPermissionResponse;
  sunNumber: IFieldPermissionResponse;
  moq: IFieldPermissionResponse;
  model: IFieldPermissionResponse;
  sku: IFieldPermissionResponse;
  netWeight: IFieldPermissionResponse;
  detailedDescriptionPt: IFieldPermissionResponse;
  name: IFieldPermissionResponse;
  namePt: IFieldPermissionResponse;
  materialComposition: IFieldPermissionResponse;
  materialPt: IFieldPermissionResponse;
  colour: IFieldPermissionResponse;
  colourPt: IFieldPermissionResponse;
  description: IFieldPermissionResponse;
  descriptionPt: IFieldPermissionResponse;
  dimensions: IFieldPermissionResponse;
  importantInformation: IFieldPermissionResponse;
  package: IFieldPermissionResponse;
  brand: IFieldPermissionResponse;
  artworkTechnique: IFieldPermissionResponse;
  m2Pc: IFieldPermissionResponse;
  image: IFieldPermissionResponse;
  colourImage: IFieldPermissionResponse;
  hsCode: IFieldPermissionResponse;
  ncm: IFieldPermissionResponse;
};

export type SatItemsFieldsPermissions = {
  imageUrl: IFieldPermissionResponse;
  idSupplier: IFieldPermissionResponse;
  stCode: IFieldPermissionResponse;
  refCode: IFieldPermissionResponse;
  name: IFieldPermissionResponse;
  description: IFieldPermissionResponse;
  namePt: IFieldPermissionResponse;
  detailedDescriptionPt: IFieldPermissionResponse;
  descriptionPt: IFieldPermissionResponse;
  extraInfoEn: IFieldPermissionResponse;
  extraInfoPt: IFieldPermissionResponse;
  model: IFieldPermissionResponse;
  sku: IFieldPermissionResponse;
  colour: IFieldPermissionResponse;
  colourPt: IFieldPermissionResponse;
  colourImageUrl: IFieldPermissionResponse;
  materialComposition: IFieldPermissionResponse;
  materialCompositionPt: IFieldPermissionResponse;
  dimensions: IFieldPermissionResponse;
  netWeight: IFieldPermissionResponse;
  packageDescription: IFieldPermissionResponse;
  packageNotes: IFieldPermissionResponse;
  sellingPackagePcQuantity: IFieldPermissionResponse;
  sellingPackageDimensions: IFieldPermissionResponse;
  sellingPackageGrossWeight: IFieldPermissionResponse;
  unitPerContainer: IFieldPermissionResponse;
  packageEan13: IFieldPermissionResponse;
  innerdun14: IFieldPermissionResponse;
  dun14: IFieldPermissionResponse;
  brand: IFieldPermissionResponse;
  productLine: IFieldPermissionResponse;
  artworkEn: IFieldPermissionResponse;
  artworkPt: IFieldPermissionResponse;
  techniqueEn: IFieldPermissionResponse;
  techniquePt: IFieldPermissionResponse;
  typeOfMaterial: IFieldPermissionResponse;
  lining: IFieldPermissionResponse;
  liningComposition: IFieldPermissionResponse;
  liningDescription: IFieldPermissionResponse;
  typePackageDescription: IFieldPermissionResponse;
  quantity: IFieldPermissionResponse;
  unitDescription: IFieldPermissionResponse;
  remainQuantity: IFieldPermissionResponse;
  moq: IFieldPermissionResponse;
  m2Pc: IFieldPermissionResponse;
  totalMasterM2: IFieldPermissionResponse;
  totalSqmCi: IFieldPermissionResponse;
  remainSqm: IFieldPermissionResponse;
  cotPriceSqm: IFieldPermissionResponse;
  sunPriceSqm: IFieldPermissionResponse;
  quantityContainer: IFieldPermissionResponse;
  piecesContainer: IFieldPermissionResponse;
  masterNetWeight: IFieldPermissionResponse;
  masterNetWeightTotal: IFieldPermissionResponse;
  grossWeight: IFieldPermissionResponse;
  grossWeightTotal: IFieldPermissionResponse;
  length: IFieldPermissionResponse;
  width: IFieldPermissionResponse;
  height: IFieldPermissionResponse;
  cbm: IFieldPermissionResponse;
  cbmTotal: IFieldPermissionResponse;
  purchaseIncotermDescription: IFieldPermissionResponse;
  targetCot: IFieldPermissionResponse;
  cotPrice: IFieldPermissionResponse;
  remainTotalCot: IFieldPermissionResponse;
  sellingIncotermDescription: IFieldPermissionResponse;
  targetSun: IFieldPermissionResponse;
  sunPrice: IFieldPermissionResponse;
  totalSunCi: IFieldPermissionResponse;
  remainTotalSun: IFieldPermissionResponse;
  grossProfit: IFieldPermissionResponse;
  grossMargin: IFieldPermissionResponse;
  hsCode: IFieldPermissionResponse;
  ncm: IFieldPermissionResponse;
  ncmDestaque: IFieldPermissionResponse;
  foreignTradeReview: IFieldPermissionResponse;
  generalLi: IFieldPermissionResponse;
  ncmIi: IFieldPermissionResponse;
  ncmIpi: IFieldPermissionResponse;
  ncmPis: IFieldPermissionResponse;
  ncmCofins: IFieldPermissionResponse;
  ncmDumping: IFieldPermissionResponse;
  containerNumber: IFieldPermissionResponse;
  sample: IFieldPermissionResponse;
  sampleLeadTime: IFieldPermissionResponse;
  samplesApproved: IFieldPermissionResponse;
  sampleNotes: IFieldPermissionResponse;
  licensed: IFieldPermissionResponse;
  licensorName: IFieldPermissionResponse;
  royalties: IFieldPermissionResponse;
  amountCot: IFieldPermissionResponse;
  quantityShipped: IFieldPermissionResponse;
  priceFob: IFieldPermissionResponse;
  amount: IFieldPermissionResponse;
  notes: IFieldPermissionResponse;
  estimateProfit: IFieldPermissionResponse;
  estimateMargin: IFieldPermissionResponse;
  qtyOfCtnr: IFieldPermissionResponse;
  package: IFieldPermissionResponse;
  totalMasterM2Shipped: IFieldPermissionResponse;
  marketSurvey: IFieldPermissionResponse;
  referenceSizes: SatItemsReferenceSizesFieldsPermissions;
  quantityInner: IFieldPermissionResponse;
  stickerDimensionsPt: IFieldPermissionResponse;
  centimeterDimensions: IFieldPermissionResponse;
  innerBox: IFieldPermissionResponse;
  totalCotCi: IFieldPermissionResponse;
  nve: IFieldPermissionResponse;
};

export interface IRefreshSatItemOptions {
  /**
   * Update SUN Price
   */
  sunPrice?: boolean;

  /**
   * SUN Number
   */
  sunNumber?: boolean;

  /**
   * Update Cot Price
   */
  cotPrice?: boolean;
  /**
   * MOQ
   */
  moq?: boolean;

  /**
   * Model
   */
  model?: boolean;

  /**
   * Net Weight
   */
  netWeight?: boolean;

  /**
   * Name (EN)
   */
  name?: boolean;
  /**
   * Material (EN) + Composition (%)
   */
  materialComposition?: boolean;
  /**
   * Colour (EN)
   */
  colour?: boolean;
  /**
   * Description (EN)
   */
  description?: boolean;
  /**
   * SKU
   */
  sku?: boolean;

  /**
   * Detailed description (PT):
   */
  detailedDescriptionPt?: boolean;
  /**
   * Name (PT)
   */
  namePt?: boolean;
  /**
   * Material + Composition (PT)
   */
  materialPt?: boolean;
  /**
   * Colour (PT)
   */
  colourPt?: boolean;
  /**
   * Description (PT)
   */
  descriptionPt?: boolean;

  /**
   * Important information
   */
  importantInformation?: boolean;

  /**
   * Branding
   */
  brand?: boolean;

  /**
   * Colour Image
   */
  colourImage?: boolean;
  /**
   * NCM
   */
  ncm?: boolean;

  /**
   * Dimensions
   */
  dimensions?: boolean;
  /**
   * Package
   */
  package?: boolean;

  /**
   * Artwork/Technique
   */
  artworkTechnique?: boolean;

  /**
   * Imagem do Produto
   */
  image?: boolean;

  /**
   * HS Code
   */
  hsCode?: boolean;

  /**
   * SQM Unit (M2 Pc)
   */
  m2Pc?: boolean;
}

export type DifferenceToRefreshSatItemsResponseData = {
  /**
   * Propriedade do objeto
   */
  property: string;

  /**
   * Label do objeto
   */
  label: string;

  /**
   * Valor anterior do objeto
   */
  oldValue: string | number | boolean | bigint;

  /**
   * Novo valor do objeto
   */
  newValue: string | number | boolean | bigint;

  /**
   * Indica se valor foi alterado
   */
  changed: boolean;
};

/**
 * Tipo de Response da busca por diferenca entre fields de SAT Item
 */
export type DifferenceToRefreshSatItemsResponse = {
  /**
   * ID de SAT item
   */
  idSatItem: number;

  /**
   * ST Code do produto
   */
  stCode: string;

  /**
   * Dados da atualizacao de cada field
   */
  data: DifferenceToRefreshSatItemsResponseData[];
};

export type HandleUpdateSatItemsResponse = {
  updatedSatItems: IListSatItem[];
  updatedSatItemReferences: ISatItemReference[];
  updatedSatReferenceSizeBarcodes: ISatReferenceSizeBarcode[];
  warnings: string[];
  errors?: string[];
};
