import { ApolloClient, DefaultOptions, from, HttpLink } from '@apollo/client';
import errorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SAT_API_URL}/graphql`,
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

const apiClient = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  link: from([errorLink, authLink, httpLink]),
  cache,
  credentials: 'include',
  defaultOptions,
});

export default apiClient;
