import { gql } from '@apollo/client';

export const listCategoriesQuery = gql`
  query listAllCategoriesQuery {
    listAllCategories {
      data
      items
    }
  }
`;
