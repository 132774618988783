export const ncmRoles = {
  idEntity: 32,
  idModule: 8,
  fields: {
    idFieldAnuencias: 635,
    idFieldBaseDumping: 636,
    idFieldDestaque: 639,
    idFieldDumping: 640,
    idFieldExclusaoTTD: 641,
    idFieldIcmsST: 642,
    idFieldLi: 645,
    idFieldLinkDumping: 646,
    idFieldListaCamex: 647,
    idFieldMva: 648,
    idFieldNotes: 650,
    idFieldNve1: 651,
    idFieldNve2: 652,
    idFieldNve3: 653,
    idFieldNve4: 654,
    idFieldNve5: 655,
    idFieldNve6: 656,
    idFieldPriceMin: 658,
  },
  permissions: {
    idPermissionCreateNcm: 46,
    idPermissionUpdateNcm: 47,
    idPermissionDeleteNcm: 48,
    idPermissionExportNcm: 49,
    idPermissionMassiveActionNcm: 50,
  },
};
