import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ISat } from '../../../interfaces';
import { FinancialFieldsCommonWithSatFieldPermissions } from '../../interfaces';

interface IPurchaseProps {
  sat: ISat;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
}

const Purchase: React.FC<IPurchaseProps> = ({
  sat,
  commonWithSatFieldsPermissions,
}) => {
  const userCanSeeBlock =
    commonWithSatFieldsPermissions.purchaseIncotermDescription.view ||
    commonWithSatFieldsPermissions.mainProduct.view ||
    commonWithSatFieldsPermissions.countryOriginName.view;

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12 purchase-form-title" name="Purchase" />

      {commonWithSatFieldsPermissions.purchaseIncotermDescription.view && (
        <ReadOnlyInput
          className="col-2"
          label="Purchase Incoterm"
          value={sat.idPurchaseIncoterm2?.description}
        />
      )}

      {commonWithSatFieldsPermissions.mainProduct.view && (
        <ReadOnlyInput
          className="col-2"
          label="Main Product"
          value={sat.mainProduct}
        />
      )}

      {commonWithSatFieldsPermissions.countryOriginName.view && (
        <ReadOnlyInput
          className="col-2"
          label="Country of Origin"
          value={sat.idCountryOrigin2?.name}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default Purchase;
