import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  clientOrder: {
    field: 'clientOrder',
    header: 'Client Order',
    value: undefined,
    advancedFilterField: 'clientOrder',
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    value: undefined,
    advancedFilterField: 'mainProduct',
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty/Type CTNR',
    value: undefined,
    advancedFilterField: '',
  },
  consolidatedWith: {
    field: 'consolidatedWith',
    header: 'Consolidated With',
    value: undefined,
    advancedFilterField: '',
  },
  sellingIncoterm: {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  totalSunPi: {
    field: 'totalSunPi',
    header: 'Total SUN PI',
    advancedFilterField: '',
  },
  totalSunCiShipment: {
    field: 'totalSunCiShipment',
    header: 'Total SUN CI Shipment',
    advancedFilterField: '',
  },
  estimatedTotalBalance: {
    field: 'estimatedTotalBalance',
    header: 'Estimated Total Balance',
    advancedFilterField: '',
  },
  totalBalanceReceived: {
    field: 'totalBalanceReceived',
    header: 'Total Balance Received',
    advancedFilterField: '',
  },
  statusSat: {
    field: 'statusSat',
    header: 'SAT Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: '',
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'idProductionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  documentsStatus: {
    field: 'documentsStatus',
    header: 'Documents Status',
    value: undefined,
    advancedFilterField: 'idDocumentsStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DOCUMENTS_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'idStatusSellingPayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    value: undefined,
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    value: undefined,
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    value: undefined,
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedArrivalDate: {
    field: 'estimatedArrivalDate',
    header: 'Estimated Arrival Date',
    value: undefined,
    advancedFilterField: 'estimatedArrivalDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    value: undefined,
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedArrivalClientDate: {
    field: 'estimatedArrivalClientDate',
    header: 'Estimated Arrival Client Date',
    value: undefined,
    advancedFilterField: 'estimatedArrivalClientDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realArrivalAtClient: {
    field: 'realArrivalAtClient',
    header: 'Real Arrival at Client',
    value: undefined,
    advancedFilterField: 'realArrivalAtClient',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  followUpPt: {
    field: 'followUpPt',
    header: 'Follow UP (PT)',
    value: undefined,
    advancedFilterField: '',
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    value: undefined,
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  firstBuyer: {
    field: 'firstBuyer',
    header: 'First Buyer',
    value: undefined,
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  secondBuyer: {
    field: 'secondBuyer',
    header: 'Second Buyer',
    value: undefined,
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    value: undefined,
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
