/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { FaTimes } from 'react-icons/fa';
import {
  Container,
  Row,
  BrokerFeeItem,
  SpecialNegotiationItem,
} from './styles';
import MainButton from '../../../../components/MainButton';
import FormCheckbox from '../../../../components/FormCheckbox';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import {
  IClient,
  IClientBrokerFee,
  IClientSpecialNegotiation,
} from '../interfaces';
import FormInputNumber from '../../../../components/FormInputNumber';
import { useRefHook } from '../../../../hooks/useRefHook';
import FormDropdown from '../../../../components/FormDropdown';
import { lazyParamsPaginationGrid } from '../../../../services/layoutsDefinitions';
import ILazyParams from '../../../../services/lazyParams';
import generateRandomString from '../../../../utils/generateRandomString';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { clientRoles } from '../../../../shared/roles/client';
import ToastLife from '../../../../shared/enums/toastLife';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  client: IClient;
  setClient: Dispatch<SetStateAction<IClient>>;
  roleEntityFields: IRoleEntityField[];
}

const CostStudies: React.FC<IGeneralInformationProps> = ({
  selected,
  client,
  setClient,
  roleEntityFields,
}) => {
  // Referencia ao toast
  const { toastRef, formRef } = useRefHook();

  // Parametros de paginacao/backend
  const [lazyParams] = useState<ILazyParams>(lazyParamsPaginationGrid);

  // Query para listar domains
  const listDomainQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  // Query para listar domains (value)
  const listDomainValueQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          value
          description
        }
        items
      }
    }
  `;

  /**
   * Busca icms modification
   */
  const [loadIcmsModification] = useLazyQuery(listDomainValueQuery, {
    variables: {
      id: 10,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting ICMS modification',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca Services
   */
  const [
    loadServices,
    {
      called: servicesCalled,
      loading: servicesLoading,
      data: servicesData,
      error: servicesError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 16,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting services',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca Incoterm
   */
  const [
    loadIncoterm,
    {
      called: incotermCalled,
      loading: incotermLoading,
      data: incotermData,
      error: incotermError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 4,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting incoterm',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca SpecialNegotiation
   */
  const [
    loadSpecialNegotiation,
    {
      called: specialNegotiationCalled,
      loading: specialNegotiationLoading,
      data: specialNegotiationData,
      error: specialNegotiationError,
    },
  ] = useLazyQuery(listDomainQuery, {
    variables: {
      id: 6,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting special negotiation',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  useEffect(() => {
    loadIncoterm();
    loadSpecialNegotiation();
    loadServices();
    loadIcmsModification();
  }, [
    loadIncoterm,
    loadSpecialNegotiation,
    loadServices,
    loadIcmsModification,
  ]);

  function addBrokerFee() {
    // Cria novo array com novo item
    let newBrokerFee: IClientBrokerFee[];
    if (client.brokerInformations) {
      newBrokerFee = [
        ...client.brokerInformations,
        {
          idBrokerInformation: generateRandomString(4),
        } as IClientBrokerFee,
      ];
    } else {
      newBrokerFee = [
        {
          idBrokerInformation: generateRandomString(4),
        } as IClientBrokerFee,
      ];
    }

    // Add objetc in array
    setClient({
      ...client,
      brokerInformations: newBrokerFee,
    });
  }

  function removeBrokerFee(index: number) {
    // Copy array
    const newBrokerFee = [...client.brokerInformations];
    // Remove item of array
    newBrokerFee.splice(index, 1);
    // Update array
    client.brokerInformations = newBrokerFee;

    setClient({
      ...client,
    });
  }
  function onTypeChange(index: number) {
    client.clientSpecialNegotiations[index].idSpecialNegotiation =
      formRef.current?.getFieldValue(
        `clientSpecialNegotiations[${index}].idSpecialNegotiation.idDomain`,
      );

    setClient({
      ...client,
    });
  }

  function addSpecialNegotiation() {
    // Cria novo array com novo item
    let newSpecialNegotiation: IClientSpecialNegotiation[];
    if (client.clientSpecialNegotiations) {
      newSpecialNegotiation = [
        ...client.clientSpecialNegotiations,
        {
          idClientSpecialNegotiation: generateRandomString(4),
          iiType: 450,
          iiValue: undefined,
          ipiType: 450,
          ipiValue: undefined,
          pisType: 450,
          pisValue: undefined,
          icmsType: 450,
          icmsValue: undefined,
          cofinsType: 450,
          cofinsValue: undefined,
        } as IClientSpecialNegotiation,
      ];
    } else {
      newSpecialNegotiation = [
        {
          idClientSpecialNegotiation: generateRandomString(4),
          iiType: 450,
          iiValue: undefined,
          ipiType: 450,
          ipiValue: undefined,
          pisType: 450,
          pisValue: undefined,
          icmsType: 450,
          icmsValue: undefined,
          cofinsType: 450,
          cofinsValue: undefined,
        } as IClientSpecialNegotiation,
      ];
    }

    // Add objetc in array
    setClient({
      ...client,
      clientSpecialNegotiations: newSpecialNegotiation,
    });
  }

  function removeSpecialNegotiation(index: number) {
    // Copy array
    const newSpecialNegotiation = [...client.clientSpecialNegotiations];
    // Remove item of array
    newSpecialNegotiation.splice(index, 1);
    // Update array
    client.clientSpecialNegotiations = newSpecialNegotiation;

    setClient({
      ...client,
    });
  }

  function changePropertyIi(index: number, source: string) {
    if (source === 'D') {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].iiValueModification`,
        '',
      );
    } else {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].iiValueDiscount`,
        '',
      );
    }

    const iiValueDiscount = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].iiValueDiscount`,
    );
    const iiValueModification = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].iiValueModification`,
    );

    if (!iiValueDiscount && !iiValueModification) {
      client.clientSpecialNegotiations[index].iiType = 450;
    } else if (iiValueDiscount) {
      client.clientSpecialNegotiations[index].iiType = 451;
      client.clientSpecialNegotiations[index].iiValue = iiValueDiscount;
    } else {
      client.clientSpecialNegotiations[index].iiType = 452;
      client.clientSpecialNegotiations[index].iiValue = iiValueModification;
    }

    setClient({
      ...client,
    });
  }

  function changePropertyIpi(index: number, source: string) {
    if (source === 'D') {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].ipiValueModification`,
        '',
      );
    } else {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].ipiValueDiscount`,
        '',
      );
    }

    const ipiValueDiscount = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].ipiValueDiscount`,
    );
    const ipiValueModification = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].ipiValueModification`,
    );

    if (!ipiValueDiscount && !ipiValueModification) {
      client.clientSpecialNegotiations[index].ipiType = 450;
    } else if (ipiValueDiscount) {
      client.clientSpecialNegotiations[index].ipiType = 451;
      client.clientSpecialNegotiations[index].ipiValue = ipiValueDiscount;
    } else {
      client.clientSpecialNegotiations[index].ipiType = 452;
      client.clientSpecialNegotiations[index].ipiValue = ipiValueModification;
    }
    setClient({
      ...client,
    });
  }

  function changePropertyPis(index: number, source: string) {
    if (source === 'D') {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].pisValueModification`,
        '',
      );
    } else {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].pisValueDiscount`,
        '',
      );
    }

    const pisValueDiscount = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].pisValueDiscount`,
    );
    const pisValueModification = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].pisValueModification`,
    );

    if (!pisValueDiscount && !pisValueModification) {
      client.clientSpecialNegotiations[index].pisType = 450;
    } else if (pisValueDiscount) {
      client.clientSpecialNegotiations[index].pisType = 451;
      client.clientSpecialNegotiations[index].pisValue = pisValueDiscount;
    } else {
      client.clientSpecialNegotiations[index].pisType = 452;
      client.clientSpecialNegotiations[index].pisValue = pisValueModification;
    }
    setClient({
      ...client,
    });
  }

  function changePropertyCofins(index: number, source: string) {
    if (source === 'D') {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].cofinsValueModification`,
        '',
      );
    } else {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].cofinsValueDiscount`,
        '',
      );
    }

    const cofinsValueDiscount = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].cofinsValueDiscount`,
    );
    const cofinsValueModification = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].cofinsValueModification`,
    );

    if (!cofinsValueDiscount && !cofinsValueModification) {
      client.clientSpecialNegotiations[index].cofinsType = 450;
    } else if (cofinsValueDiscount) {
      client.clientSpecialNegotiations[index].cofinsType = 451;
      client.clientSpecialNegotiations[index].cofinsValue = cofinsValueDiscount;
    } else {
      client.clientSpecialNegotiations[index].cofinsType = 452;
      client.clientSpecialNegotiations[index].cofinsValue =
        cofinsValueModification;
    }
    setClient({
      ...client,
    });
  }

  function changePropertyIcms(index: number, source: string) {
    if (source === 'D') {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].icmsValueModification`,
        '',
      );
    } else {
      formRef.current?.setFieldValue(
        `clientSpecialNegotiations[${index}].icmsValueDiscount`,
        '',
      );
    }

    const icmsValueDiscount = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].icmsValueDiscount`,
    );
    const icmsValueModification = formRef.current?.getFieldValue(
      `clientSpecialNegotiations[${index}].icmsValueModification`,
    );

    if (!icmsValueDiscount && !icmsValueModification) {
      client.clientSpecialNegotiations[index].icmsType = 450;
    } else if (icmsValueDiscount) {
      client.clientSpecialNegotiations[index].icmsType = 451;
      client.clientSpecialNegotiations[index].icmsValue = icmsValueDiscount;
    } else {
      client.clientSpecialNegotiations[index].icmsType = 452;
      client.clientSpecialNegotiations[index].icmsValue = icmsValueModification;
    }
    setClient({
      ...client,
    });
  }

  const {
    idFieldClientService,
    idFieldCostsMultiplier,
    idFieldIncoterm,
    idFieldSalesMultiplier,
  } = clientRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return client.idClient
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <h1>Cost Studies</h1>
        <Row>
          {showField(idFieldSalesMultiplier) && (
            <FormInputNumber
              className="generalInput"
              name="salesMultiplier"
              label="Sales Multiplier"
              decimalScale={5}
              readOnly={disableField(idFieldSalesMultiplier)}
            />
          )}
          {showField(idFieldCostsMultiplier) && (
            <FormInputNumber
              className="generalInput"
              name="costsMultiplier"
              label="Costs Multiplier"
              decimalScale={5}
              readOnly={disableField(idFieldCostsMultiplier)}
            />
          )}
          {showField(idFieldIncoterm) && (
            <FormDropdown
              className="generalInput"
              name="incoterm"
              label="Incoterm"
              placeholder={incotermLoading ? 'Loading' : ''}
              options={
                incotermCalled && !incotermLoading && !incotermError
                  ? incotermData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.incoterm2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldIncoterm)}
            />
          )}
        </Row>

        <Row>
          {showField(idFieldClientService) && (
            <FormDropdown
              className="generalInput"
              name="clientService"
              label="Services (%)"
              placeholder={servicesLoading ? 'Loading' : ''}
              options={
                servicesCalled && !servicesLoading && !servicesError
                  ? servicesData.listDomainsByGroupId.data
                  : undefined
              }
              optionLabel="description"
              initialValue={client.clientService2}
              filter
              filterBy="description"
              showClear
              readOnly={disableField(idFieldClientService)}
            />
          )}
        </Row>

        <div className="form-div-margin">
          <h1>Custom Broker</h1>
          {client.brokerInformations &&
            client.brokerInformations.map((brokerFee, index) => {
              return (
                <BrokerFeeItem key={brokerFee.idBrokerInformation}>
                  <div>
                    <FormCheckbox
                      name={`brokerInformations[${index}].isPrimary`}
                      label="Default"
                      className="my-2 activeCheckbox"
                      initialValue={brokerFee.isPrimary}
                    />
                    <FormInputNumber
                      className="generalInput"
                      name={`brokerInformations[${index}].brokerInformation`}
                      label="Custom Broker"
                      value={brokerFee.brokerInformation}
                      decimalScale={10}
                    />
                  </div>
                  <div className="button-div">
                    <button
                      type="button"
                      onClick={() => removeBrokerFee(index)}
                    >
                      <FaTimes size={18} />
                    </button>
                  </div>
                </BrokerFeeItem>
              );
            })}

          <MainButton
            label="Add Custom Broker"
            onClick={() => addBrokerFee()}
          />
        </div>

        <div className="form-div-margin">
          <h1>Special Negotiations</h1>
          {client.clientSpecialNegotiations &&
            client.clientSpecialNegotiations.map(
              (specialNegotiation, index) => {
                return (
                  <SpecialNegotiationItem
                    key={specialNegotiation.idClientSpecialNegotiation}
                  >
                    <div>
                      <Row>
                        <FormDropdown
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].idSpecialNegotiation`}
                          label="Special Negotiation"
                          placeholder={
                            specialNegotiationLoading ? 'Loading' : ''
                          }
                          options={
                            specialNegotiationCalled &&
                            !specialNegotiationLoading &&
                            !specialNegotiationError
                              ? specialNegotiationData.listDomainsByGroupId.data
                              : undefined
                          }
                          optionLabel="description"
                          initialValue={
                            specialNegotiation.idSpecialNegotiation2
                          }
                          filter
                          filterBy="description"
                          onValueChange={() => onTypeChange(index)}
                          showClear
                        />
                      </Row>

                      <Row>
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].iiValueDiscount`}
                          label="II Discount (%)"
                          type="tel"
                          value={specialNegotiation.iiValue}
                          onChange={() => changePropertyIi(index, 'D')}
                          decimalScale={2}
                        />
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].iiValueModification`}
                          label="II Modification (%)"
                          type="tel"
                          onChange={() => changePropertyIi(index, 'M')}
                          decimalScale={2}
                        />
                      </Row>
                      <Row>
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].ipiValueDiscount`}
                          label="IPI Discount (%)"
                          type="tel"
                          onChange={() => changePropertyIpi(index, 'D')}
                          decimalScale={2}
                        />
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].ipiValueModification`}
                          label="IPI Modification (%)"
                          type="tel"
                          onChange={() => changePropertyIpi(index, 'M')}
                          decimalScale={2}
                        />
                      </Row>
                      <Row>
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].pisValueDiscount`}
                          label="PIS Discount (%)"
                          type="tel"
                          onChange={() => changePropertyPis(index, 'D')}
                          decimalScale={2}
                        />
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].pisValueModification`}
                          label="PIS Modification (%)"
                          type="tel"
                          onChange={() => changePropertyPis(index, 'M')}
                          decimalScale={2}
                        />
                      </Row>
                      <Row>
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].cofinsValueDiscount`}
                          label="COFINS Discount (%)"
                          type="tel"
                          onChange={() => changePropertyCofins(index, 'D')}
                          decimalScale={2}
                        />
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].cofinsValueModification`}
                          label="COFINS Modification (%)"
                          type="tel"
                          onChange={() => changePropertyCofins(index, 'M')}
                          decimalScale={2}
                        />
                      </Row>
                      <Row>
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].icmsValueDiscount`}
                          label="ICMS Discount (%)"
                          type="tel"
                          onChange={() => changePropertyIcms(index, 'D')}
                          decimalScale={2}
                        />
                        <FormInputNumber
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].icmsValueModification`}
                          label="ICMS Modification (%)"
                          type="tel"
                          onChange={() => changePropertyIcms(index, 'M')}
                          decimalScale={2}
                        />

                        {/* <FormDropdown
                          className="generalInput"
                          name={`clientSpecialNegotiations[${index}].icmsValueModification`}
                          label="ICMS Modification (%)"
                          placeholder={icmsModificationLoading ? 'Loading' : ''}
                          options={
                            icmsModificationCalled &&
                            !icmsModificationLoading &&
                            !icmsModificationError
                              ? icmsModificationData.listDomainsByGroupId.data
                              : undefined
                          }
                          optionLabel="description"
                          // initialValue={specialNegotiation.idSpecialNegotiation2}
                          filter
                          filterBy="description"
                          // onValueChange={() => onTypeChange(index)}
                          onValueChange={() => changePropertyIcms(index, 'M')}
                          // onChange={() => changePropertyIcms(index, 'M')}
                          showClear
                        /> */}
                      </Row>
                    </div>
                    <div className="button-div">
                      <button
                        type="button"
                        onClick={() => removeSpecialNegotiation(index)}
                      >
                        <FaTimes size={18} />
                      </button>
                    </div>
                  </SpecialNegotiationItem>
                );
              },
            )}

          <MainButton
            label="Add Special Negotiation"
            onClick={() => addSpecialNegotiation()}
          />
        </div>
      </Container>
    </PageTabContainer>
  );
};

export default CostStudies;
