import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TabPanel, TabView } from 'primereact/tabview';
import { ISat, ISatGroup } from '../../interfaces';
import {
  ConfirmOrderPurchaseFormRef,
  ICheckboxMenu,
  IConfirmOrderPurchaseFormObject,
  IConfirmOrderPurchaseSupplierFormObject,
} from '../interfaces';
import { ICategoryTree } from '../../../../../shared/interfaces/category';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';
import PurchaseFormContent from './PurchaseFormContent';
import {
  confirmSatGroupOrderPurchaseInputQuery,
  listSatGroupToConfirmOrderPurchaseQuery,
  sendSatConfirmOrderPurchaseEmailInputQuery,
} from '../queries';
import {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../components/MutationResponseSummary';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../../components/SelectRecipientsModal/interfaces';

interface IConfirmOrderPurchaseFormProps {
  sat: ISat;
  onHide(): void;
  selectedUsers: IUserEmailsProps[] | undefined;
  userGroups: IUserGroupsProps[] | undefined;
  categories: ICategoryTree[];
  setEmailTitle(emailTitle: string): void;
  satRefetch(): Promise<void>;
  isConfirmOrderWithGroup: boolean;
  setConfirmOrderMutationResponse: React.Dispatch<
    React.SetStateAction<IMutationResponse[]>
  >;
  confirmOrderSummaryRef: React.RefObject<IMutationResponseSummaryRef>;
}

const ConfirmOrderPurchaseForm: React.FC<IConfirmOrderPurchaseFormProps> = ({
  sat,
  onHide,
  userGroups,
  selectedUsers,
  categories,
  setEmailTitle,
  satRefetch,
  isConfirmOrderWithGroup,
  setConfirmOrderMutationResponse,
  confirmOrderSummaryRef,
}) => {
  // Referencia do form
  const formRef = useRef<FormHandles>(null);
  // Referencia ao toast e formulario
  const { showError, showSuccess } = useRefHook();

  const [satGroup, setSatGroup] = useState<ISatGroup>();

  const [confirmOrderLoading, setConfirmOrderLoading] = useState(false);

  // Abas do SAT Group
  const [currentSatTabActiveIndex, setCurrentSatTabActiveIndex] =
    useState<number>(0);

  const [sendSatConfirmOrderPurchaseEmail] = useMutation(
    sendSatConfirmOrderPurchaseEmailInputQuery,
  );

  const [confirmSatGroupOrderPurchase] = useMutation(
    confirmSatGroupOrderPurchaseInputQuery,
  );

  const [listSatGroupToConfirmOrderPurchase, { loading: listSatGroupLoading }] =
    useLazyQuery(listSatGroupToConfirmOrderPurchaseQuery, {
      onCompleted: response => {
        if (response.listSatGroupToConfirmOrderPurchase) {
          const { isConfirmOrderInGroupPossible } =
            response.listSatGroupToConfirmOrderPurchase;

          // Valida se é possível fazer o Confirm Order em grupo sem dar erro de memoria
          if (!isConfirmOrderInGroupPossible) {
            showError({
              summary:
                'Confirm Order in group is not possible due to excess of SATs/Sat Items',
            });
            onHide();
          } else {
            setSatGroup(response.listSatGroupToConfirmOrderPurchase);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT Group',
          detail: errorData.message,
        });
      },
    });

  const concatValueCheckbox = (items: ICheckboxMenu[] | undefined) => {
    const getIdsGroups: any = items?.map(e => {
      return {
        label: `(${e.isChecked ? 'X' : ' '}) ${e.name}`,
        additionalValue: e.additionalValue,
      };
    });

    return getIdsGroups;
  };

  const handleSubmit = async (formData: ConfirmOrderPurchaseFormRef) => {
    const valuesArray: IConfirmOrderPurchaseFormObject[] = [];

    setConfirmOrderLoading(true);

    const getIdsUsers = selectedUsers?.map(e => e.idUser);
    const getIdsGroups = userGroups?.flatMap(e =>
      e.isChecked ? [e.idUserGroup] : [],
    );

    formData.data?.forEach((data, index) => {
      const suppliersData: IConfirmOrderPurchaseSupplierFormObject[] = [];
      const currentSat = satGroup?.sats[index] ?? sat;

      data.suppliersData?.forEach(supplierData => {
        suppliersData.push({
          client: supplierData.client,
          satSupplier: supplierData.satSupplier,
          mainProduct: supplierData.mainProduct,
          category: supplierData.category,
          purchaseUser: supplierData.purchaseUser,
          responsibleCommercialUser: supplierData.responsibleCommercialUser,
          assistentCommercialUser: supplierData.assistentCommercialUser,
          amountCot: supplierData.amountCot,
          amountSun: supplierData.amountSun,
          purchasePaymentTerm: supplierData.purchasePaymentTerm,
          purchaseDespositAmount: supplierData.purchaseDespositAmount,
          productionTime: supplierData.productionTime,
          estimatedShipmentDate: supplierData.estimatedShipmentDate,
          estimatedDepositDate: supplierData.estimatedDepositDate,
          exporter: supplierData.exporter,
          remarks: supplierData.remarks,
          dieCutHrpItems: concatValueCheckbox(supplierData.dieCutHrpItems),
          rfid: concatValueCheckbox(supplierData.rfid),
          currencySymbol: getCurrencySymbol(
            currentSat?.idCurrency2?.abbreviation,
          ),
        });
      });

      valuesArray.push({
        idSat: currentSat?.idSat,
        emailDescription: data.emailDescription,
        data: suppliersData,
      });
    });

    try {
      if (isConfirmOrderWithGroup) {
        const confirmOrderInGroup = await confirmSatGroupOrderPurchase({
          variables: {
            data: {
              idsUsers: getIdsUsers,
              idsUserGroups: getIdsGroups,
              emailsData: valuesArray,
            },
          },
        });

        const returnedData =
          confirmOrderInGroup.data.confirmSatGroupOrderPurchase;

        // Caso algum confirm order apresentar erro, cria um summary apresentando
        // os erros e as Sats que conseguiram completar
        if (returnedData?.errors?.length) {
          const satsCompletedStrings = returnedData.satsCompleted?.map(
            (item: string) => `${item} completed`,
          );

          setConfirmOrderMutationResponse([
            {
              moduleChanged: 'Purchase',
              errors: returnedData.errors,
              warnings: satsCompletedStrings,
            },
          ]);

          confirmOrderSummaryRef.current?.toggleDisplaySummary();
        }

        if (!returnedData.satsCompleted?.length) {
          throw new Error('No Confirm Orders were completed');
        }
      } else {
        await sendSatConfirmOrderPurchaseEmail({
          variables: {
            sendSatConfirmOrderPurchaseEmailInput: {
              idsUsers: getIdsUsers,
              idsUserGroups: getIdsGroups,
              ...valuesArray[0],
            },
          },
        });
      }

      await satRefetch();

      showSuccess({
        summary: 'Order confirmed',
      });

      setConfirmOrderLoading(false);

      onHide();
    } catch (error) {
      showError({
        summary: 'Error while confirming order',
        detail: error.message,
      });
      setConfirmOrderLoading(false);
    }
  };

  // Busca SAT Group se o usuario selecionar a opcao do Confirm Order em grupo
  useEffect(() => {
    if (isConfirmOrderWithGroup && sat.idSatGroup2 && !satGroup) {
      listSatGroupToConfirmOrderPurchase({
        variables: {
          idSatGroup: sat.idSatGroup2?.idSatGroup,
        },
      });
    }
  }, [
    isConfirmOrderWithGroup,
    listSatGroupToConfirmOrderPurchase,
    satGroup,
    sat.idSatGroup2,
  ]);

  return (
    <Form
      style={{ margin: '10px' }}
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={sat}
    >
      {isConfirmOrderWithGroup ? (
        !listSatGroupLoading && (
          <TabView
            activeIndex={currentSatTabActiveIndex}
            onTabChange={e => setCurrentSatTabActiveIndex(e.index)}
            renderActiveOnly={false}
            scrollable
          >
            {!listSatGroupLoading &&
              satGroup &&
              satGroup.sats?.length &&
              satGroup.sats.map((item: ISat, index: number) => {
                return (
                  <TabPanel key={item.idSat.toString()} header={item.satNumber}>
                    <PurchaseFormContent
                      sat={item}
                      setEmailTitle={setEmailTitle}
                      categories={categories}
                      index={index}
                      selected={currentSatTabActiveIndex === index}
                    />
                  </TabPanel>
                );
              })}
          </TabView>
        )
      ) : (
        <PurchaseFormContent
          sat={sat}
          setEmailTitle={setEmailTitle}
          categories={categories}
          index={0}
          selected
        />
      )}

      <Divider />
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => onHide()}
        className="p-button-text"
      />
      <Button
        label="Send"
        icon={confirmOrderLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
        type="submit"
        disabled={confirmOrderLoading}
        autoFocus
      />
    </Form>
  );
};

export default ConfirmOrderPurchaseForm;
