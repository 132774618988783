import styled from 'styled-components';

interface IContainerProps {
  removeButtonPosition?: 'top' | 'center' | 'bottom';
}

export const Container = styled.div<IContainerProps>`
  border: 2px solid var(--grey);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  .card-content {
    display: flex;
    width: 100%;
  }

  > .remove-button {
    display: flex;
    align-items: ${props => {
      switch (props.removeButtonPosition) {
        case 'top':
          return 'flex-start';
        case 'bottom':
          return 'flex-end';
        default:
          return 'center';
      }
    }};

    button {
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;
