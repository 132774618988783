export const clientQuotationLateralMenuItems = {
  generalInformation: {
    key: 'generalInformation',
    label: 'General Information',
  },
  suppliers: {
    key: 'suppliers',
    label: 'Suppliers',
  },
  financial: {
    key: 'financial',
    label: 'Financial',
  },
  updates: {
    key: 'updates',
    label: 'Updates',
  },
  cqItems: {
    key: 'cqItems',
    label: 'CQ Items',
  },
  attachments: {
    key: 'attachments',
    label: 'Attachments',
  },
};
