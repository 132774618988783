/* eslint-disable no-shadow */

/**
 * Enum para acoes de Add Quotation
 */
export enum AddQuotationUserAction {
  DEVOLVER_CRIADOR = 'devolverCriador',
  DEVOLVER_POOL_PROCUREMENT = 'devolverPoolProcurement',
  ENVIAR_PURCHASE = 'enviarPurchase',
}

/**
 * Enum para acoes de Purchase Add Quotation
 */
export enum PurchaseAddQuotationUserAction {
  DEVOLVER_CRIADOR = 'devolverCriador',
  DEVOLVER_POOL_PURCHASE = 'devolverPoolPurchase',
  DEVOLVER_PROCUREMENT = 'devolverProcurement',
  ENVIAR_MANAGER = 'enviarManager',
  ENVIAR_COMMERCIAL = 'enviarCommercial',
}

/**
 * Enum para acoes de Analisar
 */
export enum AnalisarAction {
  DEVOLVER_CRIADOR = 'devolverCriador',
  DEVOLVER_POOL_MANAGER = 'devolverPoolManager',
  DEVOLVER_PROCUREMENT = 'devolverProcurement',
  DEVOLVER_PURCHASE = 'devolverPurchase',
  ENVIAR_COMMERCIAL = 'enviarCommercial',
}

/**
 * Enum para acoes de Aprovar
 */
export enum AprovarAction {
  DEVOLVER_CRIADOR = 'devolverCriador',
  DEVOLVER_POOL_COMMERCIAL = 'devolverPoolCommercial',
  DEVOLVER_PROCUREMENT = 'devolverProcurement',
  DEVOLVER_PURCHASE = 'devolverPurchase',
  DEVOLVER_MANAGER = 'devolverManager',
  APROVAR_PRC = 'aprovarPRC',
  APROVAR_PRC_CRIAR_CQ = 'aprovarPRCCriarCq',
  CANCELAR_PRC = 'cancelarPRC',
}
