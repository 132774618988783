// eslint-disable-next-line no-shadow
export enum StoragePathsTypes {
  PRODUCT = 'product',
  CLIENT_QUOTATION = 'clientQuotation',
  DOWNLOADS = 'downloads',
  PRC = 'prc',
  SUPPLIER = 'supplier',
  CLIENT = 'client',
  PARTNER = 'partner',
  SAT = 'sat',
  FINANCIAL_TRANSACTION = 'financialTransaction',
}
