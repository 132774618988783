import { Form } from '@unform/web';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import React, { useCallback, useState } from 'react';

import imagePlaceholder from '../../../../../../assets/imagePlaceholder.svg';
import FormInput from '../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormTitle from '../../../../../../components/FormTitle';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { TypeOfPackage } from '../../../../../../shared/enums/domains';
import { ISatItemReference } from '../../../interfaces';
import {
  ISatItemsReducerAction,
  SatItemsReducerActionKind,
  SatItemsReferenceSizesFieldsPermissions,
} from '../interfaces';

import {
  OneSizePerBoxBarcodes,
  AssortmentSizesLayout,
  Container,
  OneSizePerBoxLayout,
  ReferenceContent,
  ReferenceStringData,
  OneSizePerBoxContent,
} from './styles';
import { parseCurrencyPtBrNumberColumn } from '../../../../../../utils/gridColumnsParse';

/**
 * Interface de Reference Sizes
 */
interface IReferenceSizesProps {
  /**
   * SAT Item references
   */
  satItemReferences: ISatItemReference[];

  idTypePackage: number;

  /**
   * Permissoes dos fields de SAT Item Reference Sizes
   */
  referenceSizesPermissions: SatItemsReferenceSizesFieldsPermissions;

  /**
   * Dispatch do reducer de SAT Item
   */
  satItemsDispatch: React.Dispatch<ISatItemsReducerAction>;

  /**
   * ID do item
   */
  idSatItem: number;

  /**
   * Indica se itens ficarao editaveis
   */
  editable: boolean;

  satCurrencyAbbreviation?: string;
}

const ReferenceSizes: React.FC<IReferenceSizesProps> = ({
  satItemReferences,
  idTypePackage,
  referenceSizesPermissions,
  satItemsDispatch,
  editable,
  satCurrencyAbbreviation,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  const [ciInfoVisible, setCiInfoVisibility] = useState(false);

  const typeOfPackageIsAssortmentSizes =
    idTypePackage === TypeOfPackage.ASSORTMENT_SIZES;

  const onItemReferenceChange = useCallback(
    (reference: ISatItemReference) => {
      satItemsDispatch({
        type: SatItemsReducerActionKind.SET_SAT_REFERENCES_TO_UPDATE,
        payload: {
          referenceToUpdate: reference,
        },
      });
    },
    [satItemsDispatch],
  );

  const onReferenceSizeBarcodeChange = useCallback(
    (props, value) => {
      const updatedItem = props.props.value[props.rowIndex];
      updatedItem[props.field] = value;

      satItemsDispatch({
        type: SatItemsReducerActionKind.SET_SAT_REFERENCE_SIZE_BARCODE_TO_UPDATE,
        payload: {
          referenceSizeBarcodeToUpdate: updatedItem,
        },
      });
    },
    [satItemsDispatch],
  );

  const numberEditor = useCallback(
    (props: any, field: string) => {
      return (
        <InputNumber
          value={props.rowData[field]}
          onChange={e => onReferenceSizeBarcodeChange(props, e.value)}
        />
      );
    },
    [onReferenceSizeBarcodeChange],
  );

  return (
    <Container ciInfoVisible={ciInfoVisible}>
      <div className="title-button-div">
        <FormTitle name="Reference x Sizes" />
        {typeOfPackageIsAssortmentSizes && (
          <MainButton
            label={ciInfoVisible ? 'Hide CI' : 'Show CI'}
            onClick={() => setCiInfoVisibility(!ciInfoVisible)}
          />
        )}
      </div>
      <Form
        ref={formRef}
        onSubmit={() => {
          ('');
        }}
      >
        {satItemReferences.map((reference, index) => {
          return (
            <ReferenceContent key={reference.idSatItemReference}>
              <ReferenceStringData>
                {referenceSizesPermissions.colourEn.view && (
                  <FormInput
                    name={`references[${index}].colourEn`}
                    label="Color (EN)"
                    value={reference.colourEn}
                    readOnly
                  />
                )}

                {referenceSizesPermissions.colourPt.view && (
                  <FormInput
                    name={`references[${index}].colourPt`}
                    label="Color (PT)"
                    value={reference.colourPt}
                    readOnly
                  />
                )}

                {referenceSizesPermissions.gender.view && (
                  <FormInput
                    name={`references[${index}].gender`}
                    label="Gender (EN)"
                    value={reference.idGender2?.description}
                    readOnly
                  />
                )}

                {referenceSizesPermissions.sizeGroup.view && (
                  <FormInput
                    name={`references[${index}].sizeGroup`}
                    label="Size Group (EN)"
                    value={reference.idSizeGroup2?.description}
                    readOnly
                  />
                )}

                {referenceSizesPermissions.totalPiCollor.view && (
                  <FormInputNumber
                    name={`references[${index}].totalPiCollor`}
                    label="Total PI/Color Variation"
                    defaultValue={reference.totalPiCollor}
                    onChange={e =>
                      onItemReferenceChange({
                        ...reference,
                        totalPiCollor: Number(e.currentTarget.value),
                      })
                    }
                    readOnly={
                      !editable || !referenceSizesPermissions.totalPiCollor.edit
                    }
                    decimalScale={2}
                  />
                )}

                {referenceSizesPermissions.totalCiCollor.view &&
                  (ciInfoVisible || !typeOfPackageIsAssortmentSizes) && (
                    <FormInputNumber
                      name={`references[${index}].totalCiCollor`}
                      label="Total CI/Color Variation"
                      defaultValue={reference.totalCiCollor}
                      readOnly
                      decimalScale={2}
                    />
                  )}

                {referenceSizesPermissions.remainCollorVar.view &&
                  (ciInfoVisible || !typeOfPackageIsAssortmentSizes) && (
                    <FormInput
                      name={`references[${index}].remainCollorVar`}
                      label="Remain/Color Variation"
                      value={reference.remainCollorVar}
                      readOnly
                    />
                  )}
              </ReferenceStringData>
              {typeOfPackageIsAssortmentSizes ? (
                <AssortmentSizesLayout>
                  {referenceSizesPermissions.imageColorUrl.view && (
                    <img
                      src={`${reference.idAttachment2?.url}`}
                      onError={e => {
                        e.currentTarget.src = imagePlaceholder;
                      }}
                      alt={reference.colourEn}
                    />
                  )}

                  <DataTable
                    value={reference.satReferenceSizeBarcodes}
                    editMode="row"
                    editingRows={
                      editable ? reference.satReferenceSizeBarcodes : undefined
                    }
                    onRowEditChange={() => ''}
                  >
                    {referenceSizesPermissions.referenceSizeBarcodes.unit
                      .view && (
                      <Column
                        field="unit2.description"
                        header="Unit"
                        style={{ width: '4rem' }}
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.size
                      .view && (
                      <Column
                        field="size2.description"
                        header="Size"
                        style={{ width: '4rem' }}
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.reference
                      .view && (
                      <Column
                        field="reference"
                        header="Color/Size Ref"
                        style={{ width: '7rem' }}
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.sizeRange
                      .view && (
                      <Column
                        field="sizeRange"
                        header="Size Range"
                        style={{ width: '8rem' }}
                        editor={
                          referenceSizesPermissions.referenceSizeBarcodes
                            .sizeRange.edit
                            ? props => numberEditor(props, 'sizeRange')
                            : undefined
                        }
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes
                      .sellingPackage.view && (
                      <Column
                        field="sellingPackage"
                        header="Selling Package (EAN-13)"
                        style={{ width: '10rem' }}
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.qtyPiSize
                      .view && (
                      <Column
                        field="qtyPiSize"
                        header="Qty PI/Size"
                        style={{ width: '5rem' }}
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.cotPiSize
                      .view && (
                      <Column
                        field="cotPiSize"
                        header="Cot PI/Size"
                        style={{ width: '7rem' }}
                        body={(data, props) =>
                          parseCurrencyPtBrNumberColumn(
                            data,
                            props,
                            2,
                            satCurrencyAbbreviation,
                          )
                        }
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.sunPiSize
                      .view && (
                      <Column
                        field="sunPiSize"
                        header="Sun Pi/Size"
                        style={{ width: '8rem' }}
                        body={(data, props) =>
                          parseCurrencyPtBrNumberColumn(
                            data,
                            props,
                            2,
                            satCurrencyAbbreviation,
                          )
                        }
                      />
                    )}

                    {referenceSizesPermissions.referenceSizeBarcodes.qtyCiSize
                      .view &&
                      ciInfoVisible && (
                        <Column
                          field="qtyCiSize"
                          header="Qty CI/Size"
                          style={{ width: '5rem' }}
                        />
                      )}

                    {referenceSizesPermissions.referenceSizeBarcodes
                      .remainQuantityPiShipment.view &&
                      ciInfoVisible && (
                        <Column
                          field="remainQuantityPiShipment"
                          header="Remain Qty PI Shipment"
                          style={{ width: '6rem' }}
                        />
                      )}

                    {referenceSizesPermissions.referenceSizeBarcodes.cotCiSize
                      .view &&
                      ciInfoVisible && (
                        <Column
                          field="cotCiSize"
                          header="COT CI/Size"
                          style={{ width: '7rem' }}
                          body={(data, props) =>
                            parseCurrencyPtBrNumberColumn(
                              data,
                              props,
                              2,
                              satCurrencyAbbreviation,
                            )
                          }
                        />
                      )}

                    {referenceSizesPermissions.referenceSizeBarcodes
                      .remainTotCotSize.view &&
                      ciInfoVisible && (
                        <Column
                          field="remainTotCotSize"
                          header="Remain Total COT/Size"
                          style={{ width: '8rem' }}
                          body={(data, props) =>
                            parseCurrencyPtBrNumberColumn(
                              data,
                              props,
                              2,
                              satCurrencyAbbreviation,
                            )
                          }
                        />
                      )}

                    {referenceSizesPermissions.referenceSizeBarcodes.sunCiSize
                      .view &&
                      ciInfoVisible && (
                        <Column
                          field="sunCiSize"
                          header="SUN CI/Size"
                          style={{ width: '7rem' }}
                          body={(data, props) =>
                            parseCurrencyPtBrNumberColumn(
                              data,
                              props,
                              2,
                              satCurrencyAbbreviation,
                            )
                          }
                        />
                      )}

                    {referenceSizesPermissions.referenceSizeBarcodes
                      .remainTotSunSize.view &&
                      ciInfoVisible && (
                        <Column
                          field="remainTotSunSize"
                          header="Remain Total SUN/Size"
                          style={{ width: '8rem' }}
                          body={(data, props) =>
                            parseCurrencyPtBrNumberColumn(
                              data,
                              props,
                              2,
                              satCurrencyAbbreviation,
                            )
                          }
                        />
                      )}
                  </DataTable>
                </AssortmentSizesLayout>
              ) : (
                <OneSizePerBoxLayout>
                  {referenceSizesPermissions.imageColorUrl.view && (
                    <img
                      src={`${reference.idAttachment2?.url}`}
                      onError={e => {
                        e.currentTarget.src = imagePlaceholder;
                      }}
                      alt={reference.colourEn}
                    />
                  )}
                  <div>
                    {reference.satReferenceSizeBarcodes?.map(
                      (barcode, barcodeIndex) => {
                        return (
                          <OneSizePerBoxContent
                            key={barcode.idPackageSizeBarcode}
                          >
                            {referenceSizesPermissions.referenceSizeBarcodes
                              .size.view && (
                              <FormInput
                                className="size"
                                name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].size`}
                                label="Size"
                                value={barcode.size2?.description}
                                readOnly
                              />
                            )}

                            {referenceSizesPermissions.referenceSizeBarcodes
                              .reference.view && (
                              <FormInput
                                name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].reference`}
                                label="Color/Size Ref"
                                value={barcode.reference}
                                readOnly
                              />
                            )}

                            <OneSizePerBoxBarcodes>
                              <div>
                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .unit.view && (
                                  <FormInput
                                    className="unit"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].sellingPackageUnit`}
                                    label="Unit"
                                    value={barcode.unit2?.description}
                                    readOnly
                                  />
                                )}

                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .sellingPackage.view && (
                                  <FormInput
                                    className="barcode"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].sellingPackage`}
                                    label="Selling Package (EAN-13)"
                                    value={barcode.sellingPackage?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                              <div>
                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .unitInnerBox.view && (
                                  <FormInput
                                    className="unit"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].unitInnerBox`}
                                    label="Unit Inner Box"
                                    value={barcode.unitInnerBox}
                                    readOnly
                                  />
                                )}

                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .innerBox.view && (
                                  <FormInput
                                    className="barcode"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].innerBox`}
                                    label="Inner Box (DUN-14)"
                                    value={barcode.innerBox?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                              <div>
                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .unitMasterBox.view && (
                                  <FormInput
                                    className="unit"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].unitMasterBox`}
                                    label="Unit"
                                    value={barcode.unitMasterBox}
                                    readOnly
                                  />
                                )}

                                {referenceSizesPermissions.referenceSizeBarcodes
                                  .masterBox.view && (
                                  <FormInput
                                    className="barcode"
                                    name={`references[${index}].satReferenceSizeBarcodes[${barcodeIndex}].masterBox`}
                                    label="Master Box (DUN-14)"
                                    value={barcode.masterBox?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                            </OneSizePerBoxBarcodes>
                          </OneSizePerBoxContent>
                        );
                      },
                    )}
                  </div>
                </OneSizePerBoxLayout>
              )}
            </ReferenceContent>
          );
        })}
      </Form>
    </Container>
  );
};

export default ReferenceSizes;
