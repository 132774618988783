import { DocumentNode, gql } from '@apollo/client';
import { ClientQuotationItemsPermissions } from './interfaces';

/**
 * Query para listar Client Quotation Items
 */
export const listClientQuotationItemsByClientQuotationIdQuery = (
  fieldsPermissions: ClientQuotationItemsPermissions,
): DocumentNode => {
  return gql`
  query ListClientQuotationItemsByClientQuotationId(
    $data: ListClientQuotationItemsByClientQuotationIdInput!
  ) {
    listClientQuotationItemsByClientQuotationId(data: $data) {
      items
      data {
        idCqItem
        ${
          fieldsPermissions.imageUrl.view
            ? `idImage2 {
              url
              name
            }`
            : ''
        }
        ${
          fieldsPermissions.sunNumber.view
            ? `idCqSupplier2 {
                idSupplier2 {
                  idSupplier
                  sunNumber
                }
              }`
            : ''
        }
        idProduct
        ${fieldsPermissions.stCode.view ? 'stCode' : ''}
        ${fieldsPermissions.refCode.view ? 'refCode' : ''}
        ${fieldsPermissions.name.view ? 'name' : ''}
        ${fieldsPermissions.description.view ? 'description' : ''}
        ${fieldsPermissions.namePt.view ? 'namePt' : ''}
        ${fieldsPermissions.descriptionPt.view ? 'descriptionPt' : ''}
        ${fieldsPermissions.model.view ? 'model' : ''}
        ${fieldsPermissions.sku.view ? 'sku' : ''}
        ${fieldsPermissions.colour.view ? 'colour' : ''}
        ${fieldsPermissions.colourPt.view ? 'colourPt' : ''}
        ${
          fieldsPermissions.colourImage.view
            ? `idColourImage2 {
              url
            }`
            : ''
        }
        ${
          fieldsPermissions.materialComposition.view
            ? 'materialComposition'
            : ''
        }
        ${fieldsPermissions.materialPt.view ? 'materialPt' : ''}
        ${fieldsPermissions.dimensions.view ? 'dimensions' : ''}
        ${fieldsPermissions.netWeight.view ? 'netWeight' : ''}
        ${fieldsPermissions.artworkEn.view ? 'artworkEn' : ''}
        ${fieldsPermissions.techniqueEn.view ? 'techniqueEn' : ''}
        ${fieldsPermissions.typeOfFabric.view ? 'typeOfFabric' : ''}
        ${fieldsPermissions.lining.view ? 'lining' : ''}
        ${fieldsPermissions.liningComposition.view ? 'liningComposition' : ''}
        ${fieldsPermissions.linedDescription.view ? 'linedDescription' : ''}
        ${
          fieldsPermissions.typeOfPackage.view
            ? `idTypePackage
              idTypePackage2 {
                description
              }`
            : ''
        }
        ${fieldsPermissions.packageDescription.view ? 'packageDescription' : ''}
        ${fieldsPermissions.packageNotes.view ? 'packageNotes' : ''}
        ${
          fieldsPermissions.sellingPackagePcQuantity.view
            ? 'sellingPackagePcQuantity'
            : ''
        }
        ${
          fieldsPermissions.sellingPackageDimensions.view
            ? 'sellingPackageDimensions'
            : ''
        }
        ${
          fieldsPermissions.sellingPackageGrossWeight.view
            ? 'sellingPackageGrossWeight'
            : ''
        }
        ${
          fieldsPermissions.stickerDimensionsPt.view
            ? 'stickerDimensionsPt'
            : ''
        }
        ${fieldsPermissions.quantityInner.view ? 'quantityInner' : ''}
        ${fieldsPermissions.boxInner.view ? 'boxInner' : ''}
        ${fieldsPermissions.packageEan13.view ? 'packageEan13' : ''}
        ${fieldsPermissions.innerDun14.view ? 'innerdun14' : ''}
        ${fieldsPermissions.dun14.view ? 'dun14' : ''}
        ${fieldsPermissions.brand.view ? 'brand' : ''}
        ${fieldsPermissions.productLine.view ? 'productLine' : ''}
        ${fieldsPermissions.quantity.view ? 'quantity' : ''}
        ${
          fieldsPermissions.unit.view
            ? `unit2 {
                description
              }`
            : ''
        }
        ${fieldsPermissions.moq.view ? 'moq' : ''}
        ${fieldsPermissions.m2Pc.view ? 'm2Pc' : ''}
        ${fieldsPermissions.totalMasterM2.view ? 'totalMasterM2' : ''}
        ${fieldsPermissions.cotPriceSqm.view ? 'cotPriceSqm' : ''}
        ${fieldsPermissions.sunPriceSqm.view ? 'sunPriceSqm' : ''}
        ${fieldsPermissions.quantityContainer.view ? 'quantityContainer' : ''}
        ${fieldsPermissions.piecesContainer.view ? 'piecesContainer' : ''}
        ${fieldsPermissions.masterNetWeight.view ? 'masterNetWeight' : ''}
        ${
          fieldsPermissions.masterNetWeightTotal.view
            ? 'masterNetWeightTotal'
            : ''
        }
        ${fieldsPermissions.grossWeight.view ? 'grossWeight' : ''}
        ${fieldsPermissions.grossWeightTotal.view ? 'grossWeightTotal' : ''}
        ${fieldsPermissions.length.view ? 'length' : ''}
        ${fieldsPermissions.width.view ? 'width' : ''}
        ${fieldsPermissions.height.view ? 'height' : ''}
        ${fieldsPermissions.cbm.view ? 'cbm' : ''}
        ${fieldsPermissions.cbmTotal.view ? 'cbmTotal' : ''}
        ${fieldsPermissions.targetCot.view ? 'targetCot' : ''}
        ${fieldsPermissions.cotPrice.view ? 'cotPrice' : ''}
        ${fieldsPermissions.amountCot.view ? 'amountCot' : ''}
        ${fieldsPermissions.targetSun.view ? 'targetSun' : ''}
        ${fieldsPermissions.sunPrice.view ? 'sunPrice' : ''}
        ${fieldsPermissions.amount.view ? 'amount' : ''}
        ${fieldsPermissions.estimateProfit.view ? 'estimateProfit' : ''}
        ${fieldsPermissions.estimateMargin.view ? 'estimateMargin' : ''}
        ${fieldsPermissions.hsCode.view ? 'hsCode' : ''}
        ${fieldsPermissions.ncm.view ? 'ncm' : ''}
        ${fieldsPermissions.ncmDestaque.view ? 'ncmDestaque' : ''}
        ${
          fieldsPermissions.ncmForeignTradeReview.view
            ? 'ncmForeignTradeReview'
            : ''
        }
        ${fieldsPermissions.ncmLi.view ? 'ncmLi' : ''}
        ${fieldsPermissions.ncmIi.view ? 'ncmIi' : ''}
        ${fieldsPermissions.ncmIpi.view ? 'ncmIpi' : ''}
        ${fieldsPermissions.ncmPis.view ? 'ncmPis' : ''}
        ${fieldsPermissions.ncmCofins.view ? 'ncmCofins' : ''}
        ${fieldsPermissions.ncmDumping.view ? 'ncmDumping' : ''}
        ${fieldsPermissions.samples.view ? 'samples' : ''}
        ${fieldsPermissions.sampleLeadTime.view ? 'sampleLeadTime' : ''}
        ${fieldsPermissions.samplesApproved.view ? 'samplesApproved' : ''}
        ${fieldsPermissions.sampleNotes.view ? 'sampleNotes' : ''}
        ${fieldsPermissions.licensed.view ? 'licensed' : ''}
        ${
          fieldsPermissions.licensor.view
            ? `idLicensor2 {
                name
              }`
            : ''
        }
        ${fieldsPermissions.royalties.view ? 'royalties' : ''}
        cqItemReferences {
          idCqItemReference
          ${fieldsPermissions.colour.view ? 'colourEn' : ''}
          ${fieldsPermissions.colourPt.view ? 'colourPt' : ''}
          idAttachment2 {
            idAttachment
            url
          }
          idSizeGroup2 {
            description
          }
          idGender2 {
            description
          }
          totalCollor
          masterBarcode
          cqReferenceSizeBarcodes {
            idCqReferenceSizeBarcode
            reference
            masterBarcode
            unit2 {
              description
            }
            sellingPackage
            unitInnerBox
            unitMasterBox
            masterBox
            quantityPiSize
            size2 {
              description
            }
            sizeRange
            quantityContainerSize
            totalCotPiSize
            totalSunPiSize
          }
        }
      }
    }
  }`;
};

/**
 * Mutation de exclusao de client quotation item
 */
export const deleteClientQuotationItemQuery = gql`
  mutation DeleteClientQuotationItems($idsClientQuotationItems: [Int]!) {
    deleteClientQuotationItems(
      idsClientQuotationItems: $idsClientQuotationItems
    )
  }
`;

export const updateClientQuotationItemsQuery = gql`
  mutation UpdateClientQuotationItems($data: UpdateClientQuotationItemsInput!) {
    updateClientQuotationItems(data: $data) {
      updatedClientQuotationItems {
        idCqItem
      }
      warnings
    }
  }
`;

export const getSumDataFromClientQuotationItemsQuery = gql`
  query GetSumDataFromClientQuotationItems($idCq: Int!) {
    getSumDataFromClientQuotationItems(idCq: $idCq) {
      masterNetWeightTotalItemsSum
      grossWeightTotalItemsSum
      totalCotPiItemsSum
      amountCotItemsSum
      amountItemsSum
      quantityContainerItemsSum
      cbmTotalItemsSum
      quantityOfItemsItemsSum
      marginSumItems
      quantityOfItemsSum
      quantityPiItemsSum
    }
  }
`;

export const findCqCurrencyByIdCqQuery = gql`
  query FindCqCurrencyByIdCq($idCq: Int!) {
    findCqCurrencyByIdCq(idCq: $idCq)
  }
`;
