import { gql } from '@apollo/client';

export const listClientAttachmentsQuery = gql`
  query ListClientAttachments($data: ListClientAttachmentsInput!) {
    listClientAttachments(data: $data) {
      items
      data {
        idClientAttachment
        idAttachment
        imageUrl
        name
      }
    }
  }
`;

export const deleteClientAttachmentsQuery = gql`
  mutation DeleteClientAttachments($idsClientAttachments: [Int]!) {
    deleteClientAttachments(idsClientAttachments: $idsClientAttachments)
  }
`;
