import { gql } from '@apollo/client';

export const listOtherExpensesTotal = gql`
  query GetSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat(
    $idSat: Int!
    $typeIdDomainGroup: Int!
  ) {
    getSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat(
      idSat: $idSat
      typeIdDomainGroup: $typeIdDomainGroup
    ) {
      totalOtherExpenses
      totalOnMargin
      totalOutOfMargin
      totalCreditToClient
      totalForApportionment
    }
  }
`;
