import React from 'react';
import { TagProps } from 'primereact/tag';
import { Container } from './styles';

export interface ITagProps extends Omit<TagProps, 'severity'> {
  severity?: 'success' | 'info' | 'warning' | 'danger' | 'secondary';
}

const Tag: React.FC<ITagProps> = ({ severity, ...rest }) => {
  return (
    <Container
      {...rest}
      severity={severity as 'success' | 'info' | 'warning' | 'danger'}
    />
  );
};

export default Tag;
