import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

export const asyncSelectLoadUsersOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllUsersQuery($listAllUsersInput: ListAllUsersInput) {
        listAllUsers(listAllUsersInput: $listAllUsersInput) {
          data {
            idUser
            firstName
            middleName
            lastName
            email
            username
            fullName
          }
          items
        }
      }
    `,
    variables: {
      listAllUsersInput: {
        _page: pageData?.page,
        _limit: pagination.itemsPerPage,
        _orderBy: 'firstName',
        globalSearch: search,
        active: 'S',
      },
    },
  });

  const filteredOptions = res.data.listAllUsers.data;
  const quantity = res.data.listAllUsers.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};

export const loadUsersByRolesOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; idsRoles?: number[] }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query ListUsersByRoles($listUsersByRolesInput: ListUsersByRolesInput!) {
        listUsersByRoles(listUsersByRolesInput: $listUsersByRolesInput) {
          items
          data {
            idUser
            firstName
            lastName
            username
            email
          }
        }
      }
    `,
    variables: {
      listUsersByRolesInput: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        name: search,
        idsRoles: pageData?.idsRoles,
        active: true,
      },
    },
  });

  const filteredOptions = res.data.listUsersByRoles.data;
  const quantity = res.data.listUsersByRoles.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idsRoles: pageData?.idsRoles,
    },
  };
};
