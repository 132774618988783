/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { confirmDialog } from 'primereact/confirmdialog';
import { Container, Row } from './styles';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import FormCheckbox from '../../../../components/FormCheckbox';
import {
  IChecklistItem,
  IProduct,
  ISelectCategory,
  IFinishing,
} from '../interfaces';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTreeSelect from '../../../../components/FormTreeSelect';
import { useRefHook } from '../../../../hooks/useRefHook';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { productRoles } from '../../../../shared/roles/product';
import { findParentsOnTree } from '../../../../utils/findParentsOnTree';
import { UnitType } from '../../../../shared/enums/unitType';
import { Language } from '../../../../shared/enums/language';
import ToastLife from '../../../../shared/enums/toastLife';
import { ProductStatus } from '../enums';
import { useDefaultProductPackage } from '../hooks/useDefaultProductPackage';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  setSelectedCategory: Dispatch<SetStateAction<ISelectCategory | undefined>>;
  setChecklistItems: Dispatch<SetStateAction<IChecklistItem[]>>;
  selectedCategory?: ISelectCategory;
  roleEntityFields: IRoleEntityField[];
  userCanChangeFields: boolean;
}

interface ICategory {
  key?: string | number;
  label: string;
  data: {
    name: string;
    idCategory: number;
    idParentCategory?: number;
    idCheckList?: number;
    indConfection?: boolean;
  };
  children: ICategory[];
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  product,
  changePropertyValue,
  setSelectedCategory,
  setChecklistItems,
  selectedCategory,
  roleEntityFields,
  userCanChangeFields,
}) => {
  const client = useApolloClient();

  const { getDefaultProductPackage } = useDefaultProductPackage();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia ao toast e formulario
  const { formRef } = useRefHook();

  // Categories
  const [categories, setCategories] = useState<ICategory[]>([]);

  // idFinishing
  const [finishingSelected, setFinishingSelected] = useState<IFinishing>(
    product.idFinishing2,
  );
  const [packageUnit, setPackageUnit] = useState<number>();

  const loadFinishings: LoadOptions<
    any,
    GroupBase<any>,
    { page: any }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllFinishingsQuery(
          $listAllFinishingsInput: ListAllFinishingsInput
          $pagination: Pagination!
        ) {
          listAllFinishings(
            listAllFinishingsInput: $listAllFinishingsInput
            pagination: $pagination
          ) {
            data {
              idFinishing
              name
              namePt
            }
            items
          }
        }
      `,
      variables: {
        listAllFinishingsInput: {
          name: search,
        },
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
      },
    });

    const filteredOptions = res.data.listAllFinishings.data;
    const quantity = res.data.listAllFinishings.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  const loadClients: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
          listAllClients(listAllClientsInput: $listAllClientsInput) {
            data {
              idClient
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllClientsInput: {
          pagination: {
            _page: pageData?.page,
            _limit: 25,
          },
          name: search,
          active: true,
        },
      },
    });

    const filteredOptions = res.data.listAllClients.data;
    const quantity = res.data.listAllClients.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar Partners com Licensor = true
  const loadPartnersLicensor: LoadOptions<
    any,
    GroupBase<any>,
    { page: any }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllPartnersQuery(
          $listAllPartnersInput: ListAllPartnersInput!
        ) {
          listAllPartners(listAllPartnersInput: $listAllPartnersInput) {
            data {
              ptnNumber
              idPartner
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPartnersInput: {
          _page: 1,
          _limit: 25,
          name: search,
          core: 'Licensor',
          active: 1,
        },
      },
    });

    const filteredOptions = res.data.listAllPartners.data;
    const quantity = res.data.listAllPartners.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar categories
  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  /**
   * Busca Categories
   */
  useQuery(listCategoriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting categories',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  function clearDropDown(field: string) {
    const nameInput = formRef.current?.getFieldRef(field);
    nameInput.clearValue();
  }

  function clearInput(field: string) {
    const nameInput = formRef.current?.getFieldRef(field);
    nameInput.value = null;
  }

  function acceptCategory(
    _idCategory: number | string | undefined,
    _idChecklist: number | undefined,
    _indConfection: boolean | undefined,
  ) {
    setSelectedCategory({
      idCategory: _idCategory,
      idCheckList: _idChecklist,
      indConfection: _indConfection,
    });
    setChecklistItems([]);
    changePropertyValue('indConfection', _indConfection);
    product.indConfection = _indConfection;
    if (!product.indConfection) {
      clearInput('reference');
    }
  }
  /**
   * Atualiza categoria selecionada e checklist itens
   * @param e Evento de selecao de node
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSelectCategoryNode(e: any) {
    if (
      selectedCategory !== null &&
      e.node.data?.idCategory !== selectedCategory?.idCategory
    ) {
      if (
        (e.node.data?.indConfection !== 1 && selectedCategory?.indConfection) ||
        (e.node.data?.indConfection === 1 &&
          !selectedCategory?.indConfection &&
          selectedCategory?.indConfection !== undefined)
      ) {
        confirmDialog({
          message:
            'Changing category may lose information in the reference field. Are you sure about that?',
          header: 'Warning!',
          icon: 'pi pi-question-circle',
          accept: () => {
            acceptCategory(
              e.node.data?.idCategory,
              e.node.data?.idCheckList,
              e.node.data?.indConfection === 1,
            );
          },
          reject: () => {
            formRef.current?.setFieldValue(
              'idCategory',
              selectedCategory?.idCategory,
            );
          },
        });
      } else {
        acceptCategory(
          e.node.data?.idCategory,
          e.node.data?.idCheckList,
          e.node.data?.indConfection === 1,
        );
      }
    }
  }

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return product.idProduct
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  function getDimensionValueStr(
    value: number,
    measureUnit: string,
    suffix = '',
    prefix = '',
  ): string {
    if (value) {
      return `${prefix}${value} ${measureUnit}${suffix}`;
    }
    return '';
  }

  /**
   * Monta dimensions do campo description
   */
  const getProductDimensions = useCallback(
    (language: Language) => {
      const isPortuguese = language === Language.PT;
      let dimensionsStr = '';
      product.productDimensions?.forEach((dimension, index) => {
        const dimensionsArray: string[] = [];
        let allDimensions = '';

        dimensionsArray.push(
          getDimensionValueStr(dimension.length, dimension.lengthUnit),
        );

        dimensionsArray.push(
          getDimensionValueStr(dimension.width, dimension.widthUnit),
        );

        dimensionsArray.push(
          getDimensionValueStr(dimension.height, dimension.heightUnit),
        );

        if (dimension.diameterTop && dimension.diameterBottom) {
          dimensionsArray.push(
            getDimensionValueStr(
              dimension.diameterTop,
              dimension.diameterTopUnit,
              '',
              isPortuguese ? 'Ø(SUP.) ' : 'ØTOP ',
            ),
          );

          dimensionsArray.push(
            getDimensionValueStr(
              dimension.diameterBottom,
              dimension.diameterBottomUnit,
              '',
              isPortuguese ? 'Ø(INF.) ' : 'ØBOTTOM ',
            ),
          );
        } else if (dimension.diameterTop || dimension.diameterBottom) {
          dimensionsArray.push(
            getDimensionValueStr(
              dimension.diameterTop,
              dimension.diameterTopUnit,
              '',
              'Ø',
            ),
          );

          dimensionsArray.push(
            getDimensionValueStr(
              dimension.diameterBottom,
              dimension.diameterBottomUnit,
              '',
              'Ø',
            ),
          );
        }

        dimensionsArray.push(
          getDimensionValueStr(
            dimension.thickness,
            dimension.thicknessUnit,
            isPortuguese ? ' (ESPESSURA)' : ' THICKNESS',
          ),
        );

        dimensionsArray.push(
          getDimensionValueStr(
            dimension.depth,
            dimension.depthUnit,
            isPortuguese ? ' (PROF.)' : ' DEPTH',
          ),
        );

        dimensionsArray.push(
          getDimensionValueStr(
            dimension.grammage,
            dimension.grammageUnit,
            ' (GSM) ',
          ),
        );

        allDimensions += getDimensionValueStr(
          dimension.volume,
          dimension.volumeUnit,
          '',
          '\nVOLUME: ',
        );

        allDimensions += getDimensionValueStr(
          dimension.capacity,
          dimension.capacityUnit,
          '',
          isPortuguese ? '\nCAPACIDADE: ' : '\nCAPACITY: ',
        );

        if (dimension.quantity > 0) {
          dimensionsStr += `${dimension.quantity} `;
        }
        if (isPortuguese) {
          dimensionsStr += `${
            dimension.namePt ? dimension.namePt : ''
          } ${dimensionsArray.filter(Boolean).join(' X ')} ${allDimensions}`;
        } else {
          dimensionsStr += `${
            dimension.nameEn ? dimension.nameEn : ''
          } ${dimensionsArray.filter(Boolean).join(' X ')} ${allDimensions}`;
        }
        if (index < product.productDimensions.length - 1) {
          dimensionsStr += '\n';
        }
      });
      dimensionsStr = dimensionsStr.replace(/X \./g, '.');
      dimensionsStr = dimensionsStr.replace(/ .\n/g, '.\n');
      dimensionsStr = dimensionsStr.replace(/\) \n/g, '). \n');
      return `${dimensionsStr}`;
    },
    [product.productDimensions],
  );

  /**
   * Monta valor do campo description de acordo com o idioma
   * @param e Evento de selecao
   */
  const getDescription = useCallback(
    (
      language: Language,
      nameField: string,
      colourField: string,
      compositionLabel: string,
      dimensionLabel: string,
      materialField: string,
      extraInfoField: string,
    ) => {
      let description = '';
      if (formRef.current?.getFieldValue(nameField)) {
        description += `${formRef.current?.getFieldValue(nameField)}`;
      }
      if (
        !product.indConfection &&
        formRef.current?.getFieldValue(colourField)
      ) {
        description += `\n${formRef.current?.getFieldValue(colourField)}`;
      }
      if (finishingSelected) {
        description += `\n${
          language === Language.PT
            ? finishingSelected.namePt
            : finishingSelected.name
        }`;
      }
      if (packageUnit === UnitType.SET) {
        description += `\n${compositionLabel}: `;
        description += `\n${getProductDimensions(language)}`;
      } else {
        description += `\n${dimensionLabel}: `;
        description += getProductDimensions(language);
      }
      if (formRef.current?.getFieldValue(materialField)) {
        description += `\n${formRef.current?.getFieldValue(materialField)}`;
      }
      if (formRef.current?.getFieldValue('netWeight')) {
        description += language === Language.PT ? '\nPeso: ' : '\nNetWeight: ';
        description += `${formRef.current?.getFieldValue('netWeight')} Kg`;
      }
      if (formRef.current?.getFieldValue(extraInfoField)) {
        description += `\n${formRef.current?.getFieldValue(extraInfoField)}`;
      }
      return description;
    },
    [
      finishingSelected,
      formRef,
      getProductDimensions,
      packageUnit,
      product.indConfection,
    ],
  );

  const handleCreateDescription = useCallback(() => {
    const description = getDescription(
      Language.EN,
      'nameEn',
      'colourEn',
      'COMPOSED BY',
      'DIMENSIONS',
      'materialEn',
      'extraInfoEn',
    );
    formRef.current?.setFieldValue('descriptionEn', description);
  }, [formRef, getDescription]);

  const handleCreateDescriptionPt = useCallback(() => {
    const description = getDescription(
      Language.PT,
      'namePt',
      'colourPt',
      'COMPOSTO POR',
      'DIMENSÕES',
      'materialPt',
      'extraInfoPt',
    );
    formRef.current?.setFieldValue('descriptionPt', description);
  }, [formRef, getDescription]);

  // Atualiza description caso o produto seja alterado
  useEffect(() => {
    handleCreateDescription();
    handleCreateDescriptionPt();
  }, [
    product.productPackages,
    product.productDimensions,
    product.productBrands,
    handleCreateDescription,
    handleCreateDescriptionPt,
    product.indConfection,
  ]);

  /**
   * Efetua calculo para preencher campos de M2 com base no M2 per Piece
   */
  function handleChangeM2PerPiece() {
    const m2PerPiece = formRef.current?.getFieldValue('m2Pc');

    // Valida se ha valor informado
    if (
      m2PerPiece &&
      product.productSuppliers &&
      product.productSuppliers.length
    ) {
      // Para cada valor de pricing
      product.productSuppliers.forEach((_, index) => {
        // Busca informacao de cot price
        const cotPrice = formRef.current?.getFieldValue(
          `productSuppliers[${index}].cotPrice`,
        );

        // Se ha cotPrice informado, efetua calculo
        if (cotPrice) {
          const cotPriceSqm = parseFloat((cotPrice / m2PerPiece).toFixed(4));

          // Adiciona valor resultante no campo sqm
          formRef.current?.setFieldValue(
            `productSuppliers[${index}].cotPriceSqm`,
            cotPriceSqm,
          );
        }

        // Busca informacao de sun price
        const sunPrice = formRef.current?.getFieldValue(
          `productSuppliers[${index}].sunPrice`,
        );

        // Se ha cotPrice informado, efetua calculo
        if (sunPrice) {
          const sunPriceSqm = parseFloat((sunPrice / m2PerPiece).toFixed(4));

          // Adiciona valor resultante no campo sqm
          formRef.current?.setFieldValue(
            `productSuppliers[${index}].sunPriceSqm`,
            sunPriceSqm,
          );
        }
      });
    }
  }

  useEffect(() => {
    async function getPackageUnit() {
      const defaultPackage = await getDefaultProductPackage(product.idProduct);

      if (defaultPackage?.packageUnit) {
        setPackageUnit(defaultPackage.packageUnit);
      }
    }

    if (product.idProduct) getPackageUnit();
  }, [getDefaultProductPackage, product.idProduct]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <h1>General Information</h1>
        <Row>
          {showField(productRoles.fields.idFieldIdStatus) && (
            <FormCheckbox
              name="inactived"
              label="Inactived"
              className="activeCheckbox"
              initialValue={product?.idStatus === ProductStatus.INACTIVED}
              readOnly={
                disableField(productRoles.fields.idFieldIdStatus) ||
                !userCanChangeFields
              }
            />
          )}
          {showField(productRoles.fields.idFieldVerified) && (
            <FormCheckbox
              name="verified"
              label="Verified"
              className="activeCheckbox"
              initialValue={product ? product.verified : true}
              readOnly={
                disableField(productRoles.fields.idFieldVerified) ||
                !userCanChangeFields
              }
            />
          )}
        </Row>
        <Row>
          {showField(productRoles.fields.idFieldStCode) && (
            <FormInput
              className="generalInput"
              name="stCode"
              label="ST code"
              readOnly
            />
          )}
          {showField(productRoles.fields.idFieldModel) && (
            <FormInput
              className="generalInput"
              name="model"
              label="Model"
              readOnly={
                disableField(productRoles.fields.idFieldModel) ||
                !userCanChangeFields
              }
            />
          )}
          {showField(productRoles.fields.idFieldSku) && (
            <FormInput
              className="generalInput"
              name="sku"
              label="SKU"
              readOnly={
                disableField(productRoles.fields.idFieldSku) ||
                !userCanChangeFields
              }
            />
          )}
        </Row>

        <Row>
          {showField(productRoles.fields.idFieldNetWeight) && (
            <FormInputNumber
              className="generalInput"
              name="netWeight"
              label="Net Weight (Kg)"
              decimalScale={4}
              fixedDecimalScale
              required
              readOnly={
                disableField(productRoles.fields.idFieldNetWeight) ||
                !userCanChangeFields
              }
              onChange={() => {
                handleCreateDescription();
                handleCreateDescriptionPt();
              }}
            />
          )}

          {
            // Is M² Per Piece:
            showField(productRoles.fields.idFieldIsM2) && (
              <div
                className="radio-button-div generalInput"
                aria-disabled={
                  disableField(productRoles.fields.idFieldIsM2) ||
                  !userCanChangeFields
                }
              >
                <label>SQM</label>
                <div>
                  <RadioButton
                    inputId="yes"
                    name="isM2"
                    checked={product.isM2 === true}
                    onChange={() => {
                      changePropertyValue('isM2', true);
                    }}
                    disabled={
                      disableField(productRoles.fields.idFieldIsM2) ||
                      !userCanChangeFields
                    }
                  />
                  <label>Yes</label>
                  <RadioButton
                    inputId="no"
                    name="isM2"
                    checked={product.isM2 !== true}
                    onChange={() => {
                      changePropertyValue('isM2', false);
                      formRef.current?.clearField('m2Pc');
                    }}
                    disabled={
                      disableField(productRoles.fields.idFieldIsM2) ||
                      !userCanChangeFields
                    }
                  />
                  <label>No</label>
                </div>
              </div>
            )
          }
          {showField(productRoles.fields.idFieldM2Pc) && (
            <FormInputNumber
              className="generalInput"
              name="m2Pc"
              label="SQM Unit" // M² Per Piece
              readOnly={product.isM2 !== true}
              decimalScale={3}
              fixedDecimalScale
              onBlur={() => handleChangeM2PerPiece()}
            />
          )}
        </Row>

        <Row>
          {showField(productRoles.fields.idFieldLicensedProduct) && (
            <div
              className="radio-button-div generalInput"
              aria-disabled={
                disableField(productRoles.fields.idFieldLicensedProduct) ||
                !userCanChangeFields
              }
            >
              <label>Licensed product:</label>
              <div>
                <RadioButton
                  inputId="yes"
                  name="licensedProduct"
                  value="s"
                  checked={product.licensedProduct === true}
                  onChange={() => changePropertyValue('licensedProduct', true)}
                  disabled={
                    disableField(productRoles.fields.idFieldLicensedProduct) ||
                    !userCanChangeFields
                  }
                />
                <label>Yes</label>
                <RadioButton
                  inputId="no"
                  name="licensedProduct"
                  value="n"
                  checked={product.licensedProduct !== true}
                  onChange={() => {
                    changePropertyValue('licensedProduct', false);
                    clearDropDown('idLicensor');
                    clearInput('royaltiesLicenesed');
                  }}
                  disabled={
                    disableField(productRoles.fields.idFieldLicensedProduct) ||
                    !userCanChangeFields
                  }
                />
                <label>No</label>
              </div>
            </div>
          )}
          {showField(productRoles.fields.idFieldIdLicensor) && (
            <FormAsyncSelect
              className="generalInput"
              name="idLicensor"
              label="Licensor"
              loadOptions={loadPartnersLicensor}
              debounceTimeout={100}
              initialValue={product?.idLicensor2}
              getOptionLabel={(option: any) =>
                `${option.ptnNumber}-${option.name}`
              }
              getOptionValue={(option: any) => option.idPartner}
              additional={{
                page: 1,
              }}
              noOptionsMessage={() => 'Licensors not found'}
              readOnly={
                product.licensedProduct !== true ||
                disableField(productRoles.fields.idFieldIdLicensor) ||
                !userCanChangeFields
              }
            />
          )}
          {showField(productRoles.fields.idFieldRoyaltiesLicenesed) && (
            <FormInputNumber
              className="generalInput"
              name="royaltiesLicenesed"
              label="% Royalties"
              readOnly={
                product.licensedProduct !== true ||
                disableField(productRoles.fields.idFieldRoyaltiesLicenesed) ||
                !userCanChangeFields
              }
              decimalScale={2}
              fixedDecimalScale
            />
          )}
        </Row>

        <Row>
          {showField(productRoles.fields.idFieldStock) && (
            <div
              className="radio-button-div generalInput"
              aria-disabled={
                disableField(productRoles.fields.idFieldStock) ||
                !userCanChangeFields
              }
            >
              <label>Stock:</label>
              <div>
                <RadioButton
                  inputId="yes"
                  name="stock"
                  checked={product.stock === true}
                  onChange={() => changePropertyValue('stock', true)}
                  disabled={
                    disableField(productRoles.fields.idFieldStock) ||
                    !userCanChangeFields
                  }
                />
                <label>Yes</label>
                <RadioButton
                  inputId="no"
                  name="stock"
                  checked={product.stock !== true}
                  onChange={() => changePropertyValue('stock', false)}
                  disabled={
                    disableField(productRoles.fields.idFieldStock) ||
                    !userCanChangeFields
                  }
                />
                <label>No</label>
              </div>
            </div>
          )}
          {showField(productRoles.fields.idFieldExclusivity) && (
            <div
              className="radio-button-div generalInput"
              aria-disabled={
                disableField(productRoles.fields.idFieldExclusivity) ||
                !userCanChangeFields
              }
            >
              <label>Exclusivity:</label>
              <div>
                <RadioButton
                  inputId="yes"
                  name="exclusivity"
                  checked={product.exclusivity === true}
                  onChange={() => changePropertyValue('exclusivity', true)}
                  disabled={
                    disableField(productRoles.fields.idFieldExclusivity) ||
                    !userCanChangeFields
                  }
                />
                <label>Yes</label>
                <RadioButton
                  inputId="no"
                  name="exclusivity"
                  checked={product.exclusivity !== true}
                  onChange={() => {
                    changePropertyValue('exclusivity', false);
                    clearDropDown('idClient');
                  }}
                  disabled={
                    disableField(productRoles.fields.idFieldExclusivity) ||
                    !userCanChangeFields
                  }
                />
                <label>No</label>
              </div>
            </div>
          )}
          {showField(productRoles.fields.idFieldIdClient) && (
            <FormAsyncSelect
              name="idClient"
              className="generalInput"
              label="Client"
              debounceTimeout={1000}
              loadOptions={loadClients}
              initialValue={product.idClient2}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.idClient}
              additional={{
                page: 1,
              }}
              noOptionsMessage={() => 'No clients found'}
              readOnly={
                product.exclusivity !== true ||
                disableField(productRoles.fields.idFieldIdClient) ||
                !userCanChangeFields
              }
            />
          )}
        </Row>

        <Row>
          {showField(productRoles.fields.idFieldIdCategory) && (
            <FormTreeSelect
              className="generalInput categoryInput"
              name="idCategory"
              label="Category"
              options={categories}
              filter
              placeholder="Select a Category"
              initialValue={product.idCategory}
              onNodeSelect={e => {
                handleSelectCategoryNode(e);
              }}
              readOnly={
                disableField(productRoles.fields.idFieldIdCategory) ||
                !userCanChangeFields
              }
              valueTemplate={
                <span>
                  {selectedCategory
                    ? findParentsOnTree(
                        categories,
                        selectedCategory?.idCategory,
                      )
                    : 'Select...'}
                </span>
              }
              required
            />
          )}
          {showField(productRoles.fields.idFieldReference) && (
            <FormInput
              className="generalInput"
              name="reference"
              label="Reference"
              readOnly={
                !product.indConfection ||
                disableField(productRoles.fields.idFieldReference) ||
                !userCanChangeFields
              }
            />
          )}
        </Row>
        <div className="form-div">
          <div className="descriptions-div">
            <h1>English Descriptions</h1>
            {showField(productRoles.fields.idFieldNameEn) && (
              <FormInput
                className="secondFormInput"
                name="nameEn"
                label="Name (EN)"
                required
                onChange={() => handleCreateDescription()}
                readOnly={
                  disableField(productRoles.fields.idFieldNameEn) ||
                  !userCanChangeFields
                }
              />
            )}

            {showField(productRoles.fields.idFieldMaterialEn) && (
              <FormInput
                className="secondFormInput"
                name="materialEn"
                label="Material (EN) + Composition (%)"
                required
                onChange={() => handleCreateDescription()}
                readOnly={
                  disableField(productRoles.fields.idFieldMaterialEn) ||
                  !userCanChangeFields
                }
              />
            )}

            {!product.indConfection &&
              showField(productRoles.fields.idFieldColourEn) && (
                <FormInput
                  className="secondFormInput"
                  name="colourEn"
                  label="Colour (EN)"
                  required
                  onChange={() => handleCreateDescription()}
                  readOnly={
                    disableField(productRoles.fields.idFieldColourEn) ||
                    !userCanChangeFields
                  }
                />
              )}

            {showField(productRoles.fields.idFieldExtraInfoEn) && (
              <FormInputTextArea
                className="secondFormInput"
                name="extraInfoEn"
                label="Extra info (EN)"
                rows={5}
                cols={73}
                onChange={() => handleCreateDescription()}
                readOnly={
                  disableField(productRoles.fields.idFieldExtraInfoEn) ||
                  !userCanChangeFields
                }
              />
            )}

            <Row style={{ minHeight: '109px' }}>
              {showField(productRoles.fields.idFieldIdFinishing) && (
                <FormAsyncSelect
                  name="idFinishing"
                  label="Finishing (EN)"
                  debounceTimeout={1000}
                  loadOptions={loadFinishings}
                  initialValue={product.idFinishing2}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.idFinishing}
                  additional={{
                    page: 1,
                  }}
                  isClearable
                  noOptionsMessage={() => 'No finishings found'}
                  onValueChange={e => {
                    setFinishingSelected(e);
                    handleCreateDescription();
                  }}
                  readOnly={
                    disableField(productRoles.fields.idFieldIdFinishing) ||
                    !userCanChangeFields
                  }
                />
              )}
            </Row>

            <Row>
              {showField(productRoles.fields.idFieldDescriptionEn) && (
                <FormInputTextArea
                  name="descriptionEn"
                  label="Description (EN)"
                  rows={15}
                  cols={73}
                  tooltip="This field is auto-completed"
                  readOnly
                />
              )}
            </Row>
          </div>

          <div className="descriptions-div">
            <h1>Portuguese Descriptions</h1>
            {showField(productRoles.fields.idFieldNamePt) && (
              <FormInput
                className="secondFormInput"
                name="namePt"
                label="Name (PT)"
                onChange={() => handleCreateDescriptionPt()}
                readOnly={
                  disableField(productRoles.fields.idFieldNamePt) ||
                  !userCanChangeFields
                }
              />
            )}
            {showField(productRoles.fields.idFieldMaterialPt) && (
              <FormInput
                className="secondFormInput"
                name="materialPt"
                label="Material + Composition (PT)"
                onChange={() => handleCreateDescriptionPt()}
                readOnly={
                  disableField(productRoles.fields.idFieldMaterialPt) ||
                  !userCanChangeFields
                }
              />
            )}
            {!product.indConfection &&
              showField(productRoles.fields.idFieldColourPt) && (
                <FormInput
                  className="secondFormInput"
                  name="colourPt"
                  label="Colour (PT)"
                  onChange={() => handleCreateDescriptionPt()}
                  readOnly={
                    disableField(productRoles.fields.idFieldColourPt) ||
                    !userCanChangeFields
                  }
                />
              )}
            {showField(productRoles.fields.idFieldExtraInfoPt) && (
              <FormInputTextArea
                className="secondFormInput"
                name="extraInfoPt"
                label="Extra info (PT)"
                rows={5}
                cols={73}
                onChange={() => handleCreateDescriptionPt()}
                readOnly={
                  disableField(productRoles.fields.idFieldExtraInfoPt) ||
                  !userCanChangeFields
                }
              />
            )}
            {showField(productRoles.fields.idFieldDetailedDescriptionPt) && (
              <FormInputTextArea
                className="secondFormInput"
                name="detailedDescriptionPt"
                label="Detailed description (PT)"
                rows={5}
                cols={73}
                onChange={() => handleCreateDescriptionPt()}
                readOnly={
                  disableField(
                    productRoles.fields.idFieldDetailedDescriptionPt,
                  ) || !userCanChangeFields
                }
              />
            )}

            {showField(productRoles.fields.idFieldDescriptionPt) && (
              <FormInputTextArea
                name="descriptionPt"
                label="Description (PT)"
                rows={15}
                cols={73}
                tooltip="This field is auto-completed"
                readOnly
              />
            )}
          </div>
        </div>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
