import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';

interface ISellerPiProps {
  sat: ISat;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanSeeSellerPiBlock: boolean;
}

const SellerPi: React.FC<ISellerPiProps> = ({
  sat,
  satForeignTradeSupplier,
  fieldsPermissions,
  userCanSeeSellerPiBlock,
}) => {
  function convertSunPiToUsd() {
    if (!satForeignTradeSupplier.exchangeRate) return undefined;

    return (
      satForeignTradeSupplier.totalSunPiShipment *
      satForeignTradeSupplier.exchangeRate
    );
  }

  return userCanSeeSellerPiBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Seller PI" />

      {fieldsPermissions.sellerPiCurrency.view && (
        <ReadOnlyInput
          className="col-2"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalSunPiShipment.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total SUN PI"
          prefix={getCurrencySymbol(sat.idCurrency2?.abbreviation)}
          value={satForeignTradeSupplier.totalSunPiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
        />
      )}

      {fieldsPermissions.totalConvertedSunPi.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total Converted SUN PI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={convertSunPiToUsd()}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SellerPi;
