import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';
import { SatListImportPermissions } from './interfaces';

export const listAllSatsToImportControlsQuery = (
  fieldsPermissions: SatListImportPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );

  const fieldsString = fields.join('\n');

  return gql`
    query listAllSatsToImportControlsQuery(
      $listAllSatsToImportControlsInput: ListAllSatsToImportControlsInput!
    ) {
      listAllSatsToImportControls(
        listAllSatsToImportControlsInput: $listAllSatsToImportControlsInput
      ) {
        items
        data {
          idSat
          ${fieldsPermissions.shipment.view ? 'idSatForeignTrade' : ''}
          ${fieldsPermissions.exporter.view ? 'idSupplierExporter' : ''}
          ${fieldsPermissions.importerConsignee.view ? 'idImporter' : ''}
          ${fieldsPermissions.clientNotify.view ? 'idClient' : ''}
          ${fieldsPermissions.status.view ? 'statusSat' : ''}
          ${fieldsString}
          idCurrency
          satCurrency
        }
      }
    }
  `;
};

/**
 * Query para buscar os somatorios das colunas para adicionar no footer
 */
export const getSumDataFromSatImportListQuery = gql`
  query GetSumDataFromSatImportList($data: SatListImportViewFieldsSearch!) {
    getSumDataFromSatImportList(data: $data) {
      satCount
      sellerTotalSunPi
      sellerTotalSunPiUsd
      sellerTotalSunCi
      sellerTotalSunCiUsd
      freightCost
      freightExtraCost
      apportionment
      totalWithApportionment
      totalAdvance
      totalBalance
      totalRemainSun
    }
  }
`;
