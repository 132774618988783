import { AdvancedFiltersFieldType } from '../../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  ncmName: {
    field: 'ncmName',
    header: 'NCM Name',
    advancedFilterField: 'ncmName',
  },
  ncm: { field: 'ncm', header: 'NCM', advancedFilterField: 'ncm' },
  description: {
    field: 'description',
    header: 'Descrição',
    advancedFilterField: 'description',
  },
  destaque: {
    field: 'destaque',
    header: 'Destaque',
    advancedFilterField: 'destaque',
  },
  nve: {
    field: 'nve',
    header: 'NVE',
    advancedFilterField: 'nve',
  },
  nveDesc: {
    field: 'nveDesc',
    header: 'NVE Descrição',
    advancedFilterField: 'nveDesc',
  },
  nve1: { field: 'nve1', header: 'NVE1', advancedFilterField: '' },
  nve2: { field: 'nve2', header: 'NVE2', advancedFilterField: '' },
  nve3: { field: 'nve3', header: 'NVE3', advancedFilterField: '' },
  nve4: { field: 'nve4', header: 'NVE4', advancedFilterField: '' },
  nve5: { field: 'nve5', header: 'NVE5', advancedFilterField: '' },
  nve6: { field: 'nve6', header: 'NVE6', advancedFilterField: '' },
  mva: {
    field: 'mva',
    header: 'MVA',
    advancedFilterField: 'mva',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  li: {
    field: 'li',
    header: 'LI',
    advancedFilterField: 'li',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  anuencias: {
    field: 'anuencias',
    header: 'Anuências',
    advancedFilterField: 'anuencias',
  },
  dumping: {
    field: 'dumping',
    header: 'Dumping',
    advancedFilterField: 'dumping',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  baseDumping: {
    field: 'baseDumping2.description',
    header: 'Base Dumping',
    advancedFilterField: 'baseDumping',
  },
  linkDumping: {
    field: 'linkDumping',
    header: 'Link Dumping',
    advancedFilterField: 'linkDumping',
  },
  priceMin: {
    field: 'priceMin',
    header: 'Preço Mínimo',
    advancedFilterField: 'priceMin',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  listaCamex: {
    field: 'listaCamex',
    header: 'Lista Camex',
    advancedFilterField: 'listaCamex',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  exclusaoTtd: {
    field: 'exclusaoTtd',
    header: 'Exclusão TTD',
    advancedFilterField: 'exclusaoTtd',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  icmsSt: {
    field: 'icmsSt',
    header: 'ICMS ST',
    advancedFilterField: 'icmsSt',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  ii: { field: 'ii', header: '% II', advancedFilterField: '' },
  ipi: { field: 'ipi', header: '% IPI', advancedFilterField: '' },
  pis: { field: 'pis', header: '% PIS', advancedFilterField: '' },
  cofins: {
    field: 'cofins',
    header: '% COFINS',
    advancedFilterField: '',
  },
  notes: { field: 'notes', header: 'Notes', advancedFilterField: 'notes' },
  nveDesc1: {
    field: 'nveDesc1',
    header: 'Descrição 1',
    advancedFilterField: '',
  },
  nveDesc2: {
    field: 'nveDesc2',
    header: 'Descrição 2',
    advancedFilterField: '',
  },
  nveDesc3: {
    field: 'nveDesc3',
    header: 'Descrição 3',
    advancedFilterField: '',
  },
  nveDesc4: {
    field: 'nveDesc4',
    header: 'Descrição 4',
    advancedFilterField: '',
  },
  nveDesc5: {
    field: 'nveDesc5',
    header: 'Descrição 5',
    advancedFilterField: '',
  },
  nveDesc6: {
    field: 'nveDesc6',
    header: 'Descrição 6',
    advancedFilterField: '',
  },
};
