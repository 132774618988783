import { AdvancedFiltersFieldType } from '../../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../../components/Grid/interfaces';
import { ProductStatus } from '../../../Products/Product/enums';

interface IColumnData {
  [key: string]: ColumnData;
}

const statusFilterOptions = [
  { label: 'Actived', value: ProductStatus.ACTIVED },
  { label: 'Inactived', value: ProductStatus.INACTIVED },
];

export const gridColumnsData: IColumnData = {
  idStatus: {
    field: 'idStatus',
    header: 'Status',
    advancedFilterField: 'idStatus',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: statusFilterOptions,
  },
  imageUrl: { field: 'imageUrl', header: 'Image', advancedFilterField: '' },
  stCode: {
    field: 'stCode',
    header: 'ST Code',
    advancedFilterField: 'stCode',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  sunNumber: {
    field: 'idSupplier',
    header: 'SUN',
    advancedFilterField: 'sunNumber',
  },
  category: {
    field: 'idCategory2.name',
    header: 'Category',
    advancedFilterField: 'category',
  },
  model: { field: 'model', header: 'Model', advancedFilterField: 'model' },
  nameEn: {
    field: 'nameEn',
    header: 'Name (EN)',
    advancedFilterField: 'nameEn',
  },
  namePt: {
    field: 'namePt',
    header: 'Name (PT)',
    advancedFilterField: 'namePt',
  },
  colourEn: {
    field: 'colourEn',
    header: 'Color (EN)',
    advancedFilterField: 'colourEn',
  },
  colourPt: {
    field: 'colourPt',
    header: 'Color (PT)',
    advancedFilterField: 'colourPt',
  },
  licensedProduct: {
    field: 'licensedProduct',
    header: 'Licensed Product',
    advancedFilterField: 'licensedProduct',
  },
  stock: {
    field: 'stock',
    header: 'Stock',
    advancedFilterField: 'stock',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  exclusivity: {
    field: 'exclusivity',
    header: 'Exclusivity',
    advancedFilterField: 'exclusivity',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  unit: {
    field: 'unit2.description',
    header: 'Unit',
    advancedFilterField: 'unit',
  },
  sku: { field: 'sku', header: 'SKU', advancedFilterField: 'sku' },
  detailedDescriptionPt: {
    field: 'detailedDescriptionPt',
    header: 'Full Name (PT)',
    advancedFilterField: 'detailedDescriptionPt',
  },
  materialEn: {
    field: 'materialEn',
    header: 'Material (EN)',
    advancedFilterField: 'materialEn',
  },
  materialPt: {
    field: 'materialPt',
    header: 'Material (PT)',
    advancedFilterField: 'materialPt',
  },
  descriptionEn: {
    field: 'descriptionEn',
    header: 'Description (EN)',
    advancedFilterField: 'descriptionEn',
  },
  descriptionPt: {
    field: 'descriptionPt',
    header: 'Description (PT)',
    advancedFilterField: 'descriptionPt',
  },
  finishing: {
    field: 'idFinishing2.name',
    header: 'Finishing',
    advancedFilterField: 'finishing',
  },
  extraInfoPt: {
    field: 'extraInfoPt',
    header: 'Extra Info',
    advancedFilterField: 'extraInfoPt',
  },
  checklist: {
    field: 'idCheckList2.name',
    header: 'Checklist',
    advancedFilterField: 'checklist',
  },
  foreignTradeReview: {
    field: 'foreignTradeReview',
    header: 'Trade Review',
    advancedFilterField: 'foreignTradeReview',
  },
  ncm: { field: 'ncm', header: 'NCM', advancedFilterField: 'ncm' },
  hsCode: { field: 'hsCode', header: 'HS Code', advancedFilterField: 'hsCode' },
  importLicense: {
    field: 'importLicense',
    header: 'License',
    advancedFilterField: 'importLicense',
  },
  enhance: {
    field: 'enhance',
    header: 'Enhance',
    advancedFilterField: 'enhance',
  },
  idNcmEnhance: {
    field: 'idNcmEnhance',
    header: 'NCM Enhance',
    advancedFilterField: 'idNcmEnhance',
  },
  dumping: {
    field: 'dumping',
    header: 'Dumping',
    advancedFilterField: 'dumping',
  },
  purchaseCurrency: {
    field: 'purchaseCurrency',
    header: 'Purchase Currency',
    advancedFilterField: 'purchaseCurrency',
  },
  cotPrice: {
    field: 'cotPrice',
    header: 'Cot Price',
    advancedFilterField: 'cotPrice',
  },
  markup: { field: 'markup', header: 'Markup', advancedFilterField: 'markup' },
  aging: { field: 'aging', header: 'Aging', advancedFilterField: 'aging' },
  currency: {
    field: 'currency',
    header: 'Sales Currency',
    advancedFilterField: 'currency',
  },
  incoterm: {
    field: 'indIncoterm2.description',
    header: 'Sales Incoterm',
    advancedFilterField: 'incoterm',
  },
  profit: { field: 'profit', header: 'Profit', advancedFilterField: 'profit' },
  client: {
    field: 'clientsString',
    header: 'Clients Brand',
    advancedFilterField: 'client',
  },
  brand: {
    field: 'brandsString',
    header: 'Brands',
    advancedFilterField: 'brand',
  },
};
