import { gql } from '@apollo/client';

export const processRegisterProductDetailsDoneQuery = gql`
  mutation processRegisterProductDetailsDone($idSatProcessResult: Int!) {
    processRegisterProductDetailsDone(idSatProcessResult: $idSatProcessResult) {
      idSatProcessResult
    }
  }
`;

export const processRegisterHrpCheckQuery = gql`
  mutation processRegisterHrpCheck($idSat: Int!, $idSatProcessResult: Int!) {
    processRegisterHrpCheck(
      idSat: $idSat
      idSatProcessResult: $idSatProcessResult
    ) {
      idSatProcessResult
    }
  }
`;

export const processRegisterHrpNotRequiredQuery = gql`
  mutation processRegisterHrpNotRequired(
    $idSat: Int!
    $idSatProcessResult: Int!
  ) {
    processRegisterHrpNotRequired(
      idSat: $idSat
      idSatProcessResult: $idSatProcessResult
    ) {
      idSatProcessResult
    }
  }
`;

export const processRegisterClientRegisterRequestQuery = gql`
  mutation processRegisterClientRegisterRequest(
    $idSat: Int!
    $idSatProcessResult: Int!
  ) {
    processRegisterClientRegisterRequest(
      idSat: $idSat
      idSatProcessResult: $idSatProcessResult
    ) {
      idSat
      idSatProcessResult
    }
  }
`;

export const processRegisterClientRegisterNotRequiredQuery = gql`
  mutation processRegisterClientRegisterNotRequired($idSatProcessResult: Int!) {
    processRegisterClientRegisterNotRequired(
      idSatProcessResult: $idSatProcessResult
    ) {
      idSatProcessResult
    }
  }
`;

export const processArtworkProductInfoForAwDoneQuery = gql`
  mutation processArtworkProductInfoForAwDone($idSatProcessResult: Int!) {
    processArtworkProductInfoForAwDone(
      idSatProcessResult: $idSatProcessResult
    ) {
      idSatProcessResult
    }
  }
`;
