import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import { FormHandles } from '@unform/core';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { addDays } from 'date-fns';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormBooleanRadio from '../../../../components/FormBooleanRadio';
import FormDatePicker from '../../../../components/FormDatePicker';
import FormInput from '../../../../components/FormInput';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import FormRow from '../../../../components/FormRow';
import FormTitle from '../../../../components/FormTitle';
import FormTreeSelect from '../../../../components/FormTreeSelect';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { PartnerCores } from '../../../../shared/enums/partner';
import { ICategoryTree } from '../../../../shared/interfaces/category';
import { listCategoriesQuery } from '../../../../shared/querys/category';
import { asyncSelectLoadClients } from '../../../../shared/querys/client';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import { asyncSelectLoadOffices } from '../../../../shared/querys/office';
import { asyncSelectLoadPartners } from '../../../../shared/querys/partner';
import changeStateProperty from '../../../../utils/changeStateProperty';
import { findParentsOnTree } from '../../../../utils/findParentsOnTree';
import { IPrc, IPrcDeadline } from '../interfaces';
import { Container, LinkedPrcs } from './styles';
import { PrcType } from '../../../../shared/enums/domains';
import PrcItems from './PrcItems';
import Catalogue from './Catalogue';
import { useRefHook } from '../../../../hooks/useRefHook';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';
import { listPrcDeadlinesQuery } from '../queries';

interface IGeneralInformationProps extends PageTabContainerProps {
  /**
   * Referencia de formulario
   */
  formRef: RefObject<FormHandles>;

  /**
   * Estado de PRC
   */
  prc: IPrc;

  /**
   * Define estado de PRC
   */
  setPrc: Dispatch<SetStateAction<IPrc>>;

  /**
   * Se usuario pode modificar
   */
  userCanChange: boolean;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFile: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFileTemplate: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Indicador de loading para download de file template
   */
  downloadFileTemplateLoading: boolean;

  /**
   * Funcao de upload de arquivos
   */
  handleFileUpload: (
    event: FileUploadResponse[],
    idType: number,
    idPrcQuotation?: number,
  ) => void;
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  formRef,
  prc,
  setPrc,
  userCanChange,
  handleFileUpload,
  handleDownloadFile,
  handleDownloadFileTemplate,
  downloadFileTemplateLoading,
}) => {
  // Categories
  const [categories, setCategories] = useState<ICategoryTree[]>([]);

  // PRC Deadlines
  const [prcDeadlines, setPrcDeadlines] = useState<IPrcDeadline[]>([]);

  const { showError } = useRefHook();

  /**
   * Busca Categories
   */
  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  /**
   * Busca PRC Deadlines
   */
  const [listPrcDeadlines] = useLazyQuery(listPrcDeadlinesQuery, {
    onCompleted: response => {
      if (response.listPrcDeadlines) {
        setPrcDeadlines(response.listPrcDeadlines);
      }
    },
    onError: error => {
      showError({
        summary: 'Error while getting PRC Deadlines',
        detail: error.message,
      });
    },
  });

  /**
   * Atualiza valor de Licensed
   * @param value Valor de Licensed
   */
  function handleChangeLicensed(value: boolean) {
    // Atualiza estado de PRC com o valor
    changeStateProperty('indLicensed', value, setPrc);

    // Se valor for falso, remove informacao de licensor
    if (!value) {
      formRef.current?.clearField('idPartnerLicensor');
    }
  }

  /**
   * Calcula o campo Deadline do formulario
   * @param idPrcType ID de PRC Type
   * @param idPriority ID de Priority
   * @param indConfection Indicador de categoria Fashion
   */
  function recalculateDeadlineDate(
    idPrcType?: number,
    idPriority?: number,
    indConfection?: boolean,
  ) {
    if (idPrcType && idPriority && indConfection !== undefined) {
      const prcDeadline = prcDeadlines.find(
        deadline =>
          deadline.idPrcType === idPrcType &&
          deadline.idPriority === idPriority,
      );

      if (prcDeadline) {
        const daysToAdd = indConfection
          ? prcDeadline.numDayFashion
          : prcDeadline.numDay;

        const newDate = addDays(new Date(), daysToAdd);

        if (newDate) {
          formRef.current?.setFieldValue('dtDeadline', newDate);
        }
      }
    }
  }

  useEffect(() => {
    if (userCanChange && !prcDeadlines.length) {
      listPrcDeadlines();
    }
  }, [listPrcDeadlines, prcDeadlines, userCanChange]);

  return (
    <PageTabContainer selected={selected}>
      <Container id="prc-general-information">
        <FormTitle name="General Information" />
        <FormRow>
          <FormInput
            className="generalInput"
            name="prcCode"
            label="PRC Code"
            readOnly
          />
          <FormAsyncSelect
            name="idPrcType"
            label="PRC Type"
            required={userCanChange}
            loadOptions={asyncSelectLoadDomains}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            additional={{
              page: 1,
              id: DomainGroup.PRC_TYPE,
            }}
            noOptionsMessage={() => 'No PRC types found'}
            initialValue={prc.idPrcType2}
            readOnly={
              prc.idPrcType === PrcType.CATALOGUE
                ? !userCanChange
                : !userCanChange || !!prc.prcCode
            }
            onValueChange={e =>
              recalculateDeadlineDate(
                e.idDomain,
                formRef.current?.getFieldValue('idPriority'),
                prc.indConfection,
              )
            }
          />
        </FormRow>
        <FormRow>
          <FormInput
            className="generalInput"
            name="mainProduct"
            label="Main Product / Collection"
            required={userCanChange}
            readOnly={!userCanChange || !!prc.prcCode}
          />
        </FormRow>
        <FormRow>
          <FormTreeSelect
            className="generalInput categoryInput"
            name="idCategory"
            label="Category"
            required={!prc.idPrc}
            options={categories}
            filter
            placeholder="Select a Category"
            initialValue={prc.idCategory}
            onNodeSelect={(e: any) => {
              changeStateProperty('idCategory', e.node.key, setPrc);
              changeStateProperty(
                'indConfection',
                e.node.data?.indConfection,
                setPrc,
              );
              recalculateDeadlineDate(
                formRef.current?.getFieldValue('idPrcType'),
                formRef.current?.getFieldValue('idPriority'),
                e.node.data?.indConfection,
              );
            }}
            valueTemplate={
              <span>
                {prc.idCategory
                  ? findParentsOnTree(categories, prc.idCategory)
                  : 'Select...'}
              </span>
            }
            readOnly={!!prc.idPrc}
          />
          <FormAsyncSelect
            name="idClient"
            label="Client"
            required={userCanChange}
            loadOptions={asyncSelectLoadClients}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.idClient}
            additional={{
              page: 1,
            }}
            noOptionsMessage={() => 'No clients found'}
            initialValue={prc.idClient2}
            readOnly={!userCanChange}
          />
        </FormRow>
        <FormRow>
          <FormAsyncSelect
            name="prcOffices"
            label="SAT Office"
            required
            loadOptions={asyncSelectLoadOffices}
            isMulti
            closeMenuOnSelect={false}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.officeName}
            getOptionValue={(option: any) => option.idOffice}
            additional={{
              page: 1,
            }}
            noOptionsMessage={() => 'No offices found'}
            initialValue={() =>
              prc.prcOffices
                ? prc.prcOffices.map(prcOffice => {
                    return {
                      officeName: prcOffice.idOffice2.officeName,
                      idOffice: prcOffice.idOffice2.idOffice,
                    };
                  })
                : undefined
            }
            readOnly={!userCanChange || !!prc.prcCode}
          />
          <LinkedPrcs className="form-element">
            <span>
              <p className="linked-prc-label">PRC Linked:</p>
            </span>
            <div>
              {prc.linkedPrcsArray?.map(prcLinked => {
                return (
                  <Link
                    key={prcLinked.prcCode}
                    to={`/prcs/list/${prcLinked.idPrc}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      {prcLinked.prcCode}
                      <FiExternalLink size={15} />
                    </div>
                  </Link>
                );
              })}
            </div>
          </LinkedPrcs>
        </FormRow>
        <FormRow>
          <FormAsyncSelect
            name="idPriority"
            label="Priority"
            required
            loadOptions={asyncSelectLoadDomains}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            additional={{
              page: 1,
              id: DomainGroup.PRC_PRIORITY,
            }}
            noOptionsMessage={() => 'No PRC priorities found'}
            initialValue={prc.idPriority2}
            readOnly={!userCanChange}
            onValueChange={e =>
              recalculateDeadlineDate(
                formRef.current?.getFieldValue('idPrcType'),
                e.idDomain,
                prc.indConfection,
              )
            }
          />
          <FormDatePicker
            className="generalInput"
            name="dtDeadline"
            label="Deadline"
            readOnly
          />
        </FormRow>
        <FormRow>
          <FormBooleanRadio
            label="Licensed"
            name="indLicensed"
            onValueChange={e => handleChangeLicensed(e.value)}
            readOnly={!userCanChange}
          />
          <FormAsyncSelect
            name="idPartnerLicensor"
            label="Licensor"
            loadOptions={asyncSelectLoadPartners}
            debounceTimeout={1000}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.idPartner}
            additional={{
              page: 1,
              core: PartnerCores.IND_LICENSOR,
            }}
            noOptionsMessage={() => 'No licensors found'}
            initialValue={prc.idPartnerLicensor2}
            readOnly={!prc.indLicensed || !userCanChange}
          />
        </FormRow>
        <FormRow>
          <FormInput
            className="generalInput"
            name="createdBy"
            label="Created By"
            defaultValue={
              prc.createdBy2 &&
              `${prc.createdBy2.firstName} ${prc.createdBy2.lastName}`
            }
            readOnly
          />
          <FormInput
            className="generalInput"
            name="approvedBy"
            label="Approved by"
            defaultValue={
              prc.approvedBy2 &&
              `${prc.approvedBy2.firstName} ${prc.approvedBy2.lastName}`
            }
            readOnly
          />
        </FormRow>
        <FormRow>
          <FormInputTextArea
            className="generalInput"
            name="notes"
            label="Notes"
            rows={5}
            readOnly={!userCanChange}
          />
        </FormRow>

        {prc.idPrc &&
          (prc.idPrcType === PrcType.CATALOGUE ? (
            <Catalogue
              prc={prc}
              handleDownloadFile={handleDownloadFile}
              handleFileUpload={handleFileUpload}
            />
          ) : (
            <PrcItems
              prc={prc}
              handleDownloadFile={handleDownloadFile}
              handleDownloadFileTemplate={handleDownloadFileTemplate}
              downloadFileTemplateLoading={downloadFileTemplateLoading}
            />
          ))}
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
