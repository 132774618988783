import React, { Dispatch, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  ForeignTradeSupplierTotalReducer,
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
} from '../interfaces';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { listOtherExpensesTotal } from './queries';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import ToastLife from '../../../../../../shared/enums/toastLife';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { SatFinancialSellerOtherExpensesTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface ISellerOtherExpensesTotalProps {
  idSat: number;
  selected: boolean;
  totalDispatch: Dispatch<ForeignTradeSupplierTotalReducerAction>;
  totalState: ForeignTradeSupplierTotalReducer;
  fieldsPermissions: SatFinancialSellerOtherExpensesTotalFieldsPermissions;
  satCurrency?: string;
}

const SellerOtherExpensesTotal: React.FC<ISellerOtherExpensesTotalProps> = ({
  idSat,
  selected,
  totalDispatch,
  totalState,
  fieldsPermissions,
  satCurrency,
}) => {
  const { toastRef } = useRefHook();

  const satCurrencySymbol = getCurrencySymbol(satCurrency);

  const userCanSeeBlock =
    fieldsPermissions.totalOtherExpenses.view ||
    fieldsPermissions.totalOnMargin.view ||
    fieldsPermissions.totalOutOfMargin.view ||
    fieldsPermissions.totalCreditToClient.view ||
    fieldsPermissions.totalForApportionment.view;

  const [
    loadData,
    { loading: otherExpensesTotalLoading, data: otherExpensesTotalData },
  ] = useLazyQuery(listOtherExpensesTotal, {
    variables: {
      idSat,
      typeIdDomainGroup: DomainGroup.FINANCIAL_SELLER_OTHER_EXPENSES,
    },
    onCompleted: response => {
      if (response.getSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat) {
        totalDispatch({
          type: ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_OTHER_EXPENSES_TOTAL,
          payload: {
            sellerOtherExpensesTotal:
              response.getSumDataFromSatForeignTradeSupplierOtherExpensesByIdSat,
          },
        });
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Total Seller Other Expenses Total',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });

      totalDispatch({
        type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
      });
    },
  });

  useEffect(() => {
    if (!otherExpensesTotalData && selected) {
      totalDispatch({
        type: ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
      });

      loadData();
    }
  }, [otherExpensesTotalData, selected, loadData, totalDispatch]);

  return userCanSeeBlock ? (
    <div>
      <FormTitle name="Other Expenses Total" />

      <div className="grid">
        {fieldsPermissions.totalOtherExpenses.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Other Expenses"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.sellerOtherExpensesTotal?.totalOtherExpenses}
            loading={otherExpensesTotalLoading}
          />
        )}

        {fieldsPermissions.totalOnMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total On Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.sellerOtherExpensesTotal?.totalOnMargin}
            loading={otherExpensesTotalLoading}
          />
        )}

        {fieldsPermissions.totalOutOfMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Out Of Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.sellerOtherExpensesTotal?.totalOutOfMargin}
            loading={otherExpensesTotalLoading}
          />
        )}
      </div>

      <div className="grid">
        {fieldsPermissions.totalCreditToClient.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Credit To Client"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.sellerOtherExpensesTotal?.totalCreditToClient}
            loading={otherExpensesTotalLoading}
          />
        )}

        {fieldsPermissions.totalForApportionment.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total For Apportionment"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={4}
            minimumFractionDigits={4}
            value={totalState.sellerOtherExpensesTotal?.totalForApportionment}
            loading={otherExpensesTotalLoading}
          />
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default SellerOtherExpensesTotal;
