export const importRoles = {
  idEntity: 49,
  idModule: 12,
  permissions: {
    idPermissionExportSatsImport: 179,
  },
  fields: {
    status: 1696,
    satNumber: 1697,
    shipment: 1698,
    clientOrder: 1699,
    mainProduct: 1700,
    exporter: 1701,
    importerConsignee: 1702,
    clientNotify: 1703,
    ctnrs: 1704,
    importStatus: 1705,
    registerStatus: 1780,
    artworkStatus: 1779,
    productionStatus: 1778,
    shipmentStatus: 2206,
    documentsStatus: 1781,
    supplierPaymentStatus: 2207,
    clientPaymentStatus: 2208,
    startDate: 1706,
    productionTime: 1707,
    samples: 1708,
    estimatedInspectionDate: 1709,
    realInspectionDate: 1710,
    inspectionScheduled: 1711,
    inspectionApproval: 1712,
    rework: 1713,
    reworkDate: 1714,
    authorizationRequested: 1715,
    shipmentAuthorized: 1716,
    importLicenseNumber: 1717,
    importLicenseExpireDate: 1718,
    estimatedShipmentDate: 1719,
    realEtd: 1720,
    portLoading: 1721,
    freightForwarderOrigin: 1722,
    estimatedArrivalDate: 1723,
    realEta: 1724,
    portDischarge: 1725,
    freightForwarderDestination: 1726,
    trakingNumber: 1727,
    courier: 1728,
    estimatedArrivalClientDate: 1729,
    realArrivalAtClient: 1730,
    diNumber: 1731,
    paymentTermAdvance: 1732,
    paymentTermBalance: 1733,
    paymentTermCondition: 1734,
    paymentSellingIncoterm: 1735,
    sellerTotalSunPi: 1736,
    sellerTotalSunPiUsd: 1737,
    sellerTotalSunCi: 1738,
    sellerTotalSunCiUsd: 1739,
    freightCost: 1740,
    freightExtraCost: 1741,
    apportionment: 1742,
    totalWithApportionment: 1743,
    rncCreditNote: 1744,
    creditToClient: 1745,
    totalAdvance: 1746,
    maxAdvanceReceiveDate: 1747,
    maxAdvancePaymentDate: 1748,
    totalBalance: 1749,
    maxBalanceReceiveDate: 1750,
    maxBalancePaymentDate: 1751,
    totalRemainSun: 1752,
    importLevel: 1753,
    typeOfImportService: 1754,
    typeOperation: 1755,
    replacementFrom: 1756,
    satGroup: 1757,
    category: 1758,
    countryOrigin: 1759,
    clientDepartment: 1760,
    firstBuyer: 1761,
    secondBuyer: 1762,
    firstSalesUser: 1763,
    secondSalesUser: 1764,
    exportUser: 1765,
    importUser: 1766,
    authorizationTiming: 1767,
    authorizationBookingTiming: 1768,
    authorizationShipmentTiming: 1769,
    bookingShipmentTiming: 1770,
    preShipmentTiming: 1771,
    transitTime: 1772,
    hblFinalTiming: 1773,
    deliveryAtClientTiming: 1774,
    totalForeignTradeTiming: 1775,
    importNotes: 1776,
    remarks: 1777,
    startProductionTiming: 1782,
    realProductionTime: 1783,
    consolidatedWith: 1784,
    originalDocsTiming: 1785,
    updatesEn: 1786,
    followUpPt: 1787,
    shipmentResume: 2143,
    exportStatus: 2144,
  },
};
