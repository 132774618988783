import {
  ManageSatContainerReducer,
  ManageSatContainerReducerAction,
  ManageSatContainerReducerActionKind,
} from './interfaces';

export const manageSatContainerInitialState: ManageSatContainerReducer = {
  displayDialog: false,
};

export const manageSatContainerReducer = (
  state: ManageSatContainerReducer,
  action: ManageSatContainerReducerAction,
): ManageSatContainerReducer => {
  switch (action.type) {
    case ManageSatContainerReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        containerToEdit: undefined,
      };

    case ManageSatContainerReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.satContainer) {
        return {
          ...state,
          containerToEdit: action.payload.satContainer,
          displayDialog: !state.displayDialog,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    default:
      throw new Error();
  }
};
