import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { Container } from './styles';

interface ICardProps {
  /**
   * Acao a ser executada ao remover o card
   */
  onRemove?(): void;

  /**
   * Nome da classe do componente
   */
  className?: string;

  /**
   * Posicao do botao para remover o card
   */
  removeButtonPosition?: 'top' | 'center' | 'bottom';

  /**
   * Define se o botão de remover deve ser exibido ou não
   */
  showRemoveButton?: boolean;
}

const Card: React.FC<ICardProps> = ({
  onRemove,
  className,
  removeButtonPosition,
  showRemoveButton,
  children,
}) => {
  return (
    <Container
      className={`card-component ${className ?? ''}`}
      removeButtonPosition={removeButtonPosition}
    >
      <div className="card-content">{children}</div>
      {showRemoveButton && onRemove && (
        <div className="remove-button">
          <button type="button" onClick={() => onRemove()}>
            <FaTimes size={18} />
          </button>
        </div>
      )}
    </Container>
  );
};

export default Card;
