import { gql } from '@apollo/client';

export const listAllSupplierRncsQuery = gql`
  query ListAllSupplierRncs($data: ListAllSupplierRncsInput!) {
    listAllSupplierRncs(data: $data) {
      items
      data {
        idSatRnc
        rncNumber
        idSat
        idSat2 {
          satNumber
          mainProduct
          idClient2 {
            name
          }
        }
        idSupplier2 {
          sunNumber
        }
        idStatus2 {
          description
        }
        idRncResponsible2 {
          description
        }
        idProblemType2 {
          description
        }
        problemDescription
        proposedSolution
        totalCostAmountBr
        totalCostAmountSat
        exchangeRate
        refund
        finalDebitNote
      }
    }
  }
`;

export const listRncTotalsQuery = gql`
  query listRncCostsAndOtherExpensesTotalsBySupplier($idSupplier: Int!) {
    listRncCostsAndOtherExpensesTotalsBySupplier(idSupplier: $idSupplier) {
      idSatRnc
      totalCostAmountBr
      totalCostAmountSat
      exchangeRate
      refund
      finalDebitNote
    }
  }
`;
