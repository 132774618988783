import { gql } from '@apollo/client';

export const createAirportTaxesBulkQuery = gql`
  mutation createLogisticAirportTaxesBulk($uploadedFile: String!) {
    createLogisticAirportTaxesBulk(uploadedFile: $uploadedFile) {
      airportTaxesTotal
      createdAirportTaxes
      airportTaxesWithError
    }
  }
`;

export const updateAirportTaxesBulkQuery = gql`
  mutation updateLogisticAirportTaxesBulk($uploadedFile: String!) {
    updateLogisticAirportTaxesBulk(uploadedFile: $uploadedFile) {
      airportTaxesTotal
      updatedAirportTaxes
      airportTaxesWithError
    }
  }
`;
