import styled from 'styled-components';

export const Container = styled.div`
  min-height: 31rem;
  min-width: 55rem;

  .warn-text {
    margin-top: 20px;
    color: var(--grey-scale);
  }

  .p-picklist {
    margin-top: 20px;

    .p-picklist-header {
      min-height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .supplier-spinner {
    display: flex;
  }

  .p-progress-spinner {
    margin-top: 20px;
    display: flex;
  }
`;
