/**
 * Busca simbolo de moeda
 * @param currencyCode Codigo da moeda
 * @returns Simbolo de moeda
 */
function getCurrencySymbol(currencyCode?: string, locale = 'en-US'): string {
  if (!currencyCode) {
    return '';
  }

  const symbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  return symbol;
}

export { getCurrencySymbol };
