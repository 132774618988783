import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import {
  booleanNumberValueOptions,
  booleanStringValueOptions,
} from '../../../shared/options/boolean-options';
import { confirmOrderOptions } from '../../../shared/options/confirmOrderOptions';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

export const artworkFiltersData: ColumnData[] = [
  {
    field: 'status',
    header: 'Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  {
    field: 'confirmOrder',
    header: 'Confirm Order',
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  {
    field: 'mainProduct',
    header: 'Main Product',
    value: '',
    advancedFilterField: 'mainProduct',
  },
  {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'typeOfOrder',
    header: 'Type of Order',
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  {
    field: 'newLayout',
    header: 'New Layout',
    advancedFilterField: 'newLayout',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanStringValueOptions,
  },
  {
    field: 'designLevel',
    header: 'Design Level',
    advancedFilterField: 'idDesignLevel',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DESIGN_LEVEL,
    },
  },
  {
    field: 'dueDate',
    header: 'Artwork Due Date',
    advancedFilterField: 'dueDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'realEtd',
    header: 'Real ETD',
    value: undefined,
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'samples',
    header: 'Samples',
    advancedFilterField: 'samples',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanNumberValueOptions,
  },
  {
    field: 'artworkStartDate',
    header: 'Artwork Start Date',
    advancedFilterField: 'artworkStartDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'supportResponsible',
    header: 'Support Responsible',
    advancedFilterField: 'idSupportResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'designResponsible',
    header: 'Design Responsible',
    advancedFilterField: 'idDesignResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'reviewResponsible',
    header: 'Review Responsible',
    advancedFilterField: 'idReviewResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'commercialReviewResponsible',
    header: 'Commercial Review Responsible',
    advancedFilterField: 'idCommercialReviewResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'commercialResponsible',
    header: 'Commercial Responsible',
    advancedFilterField: 'idCommercialResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'finalArtWorkResponsible',
    header: 'Final Artwork Responsible',
    advancedFilterField: 'idFinalArtWorkResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'sketchesResponsible',
    header: 'Sketches Responsible',
    advancedFilterField: 'idSketchesResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'artworkDone',
    header: 'Artwork Done',
    advancedFilterField: 'artworkDone',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'countryOrigin',
    header: 'Country Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
  {
    field: 'designNotes',
    header: 'Design Notes',
    advancedFilterField: 'designNotes',
  },
];
