import styled from 'styled-components';

export const Container = styled.div`
  textarea {
    resize: vertical;
  }

  .manage-other-expense-checkboxes {
    display: flex;
    flex-wrap: wrap;

    .form-checkbox {
      width: unset;
      white-space: nowrap;
    }
  }
`;
