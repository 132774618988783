import styled from 'styled-components';

export const SunNumber = styled.span`
  color: var(--sunasia-comex);
  height: 60px;
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
`;
