import styled from 'styled-components';

export const CurrencyCard = styled.div`
  border: 1px solid var(--grey-border);
  border-radius: 12px;
  width: 8.5em;
  height: 6.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
