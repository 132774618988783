import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 28px;
  max-width: 1154px;

  button {
    margin: 12px 0;
  }
`;

export const GridActions = styled.div`
  display: flex;
  gap: 0.57rem;
  margin-left: 0.57rem;

  button {
    background: none;
    border: none;
    color: #7f7f80;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.4, '#7f7f80')};
    }
  }

  .trash-button {
    color: var(--red);

    &:hover {
      color: ${shade(0.2, '#FD2D2D')};
    }
  }
`;

export const FileColumn = styled.div`
  display: flex;
  align-items: center;

  .p-fileupload {
    .p-button {
      background-color: transparent;
      color: var(--blue);
      border-color: transparent;
      transition: color 0.2s;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: var(--blue);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .sat-quotation-file-log {
    .p-overlaypanel {
      left: unset !important;
    }
  }

  .items-log-button {
    background: none;
    border: none;
    color: var(--grey-scale);
    transition: background-color 0.2s;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--grey);
    }
  }
`;
