import { gql } from '@apollo/client';

export const productionDashboardQuery = gql`
  query GetSatProductionProcessDashboard(
    $data: GetSatProductionProcessDashboardInput
  ) {
    getSatProductionProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatForeignTradeSupplier
        satNumber
        mainProduct
        sunNumber
        shipment
        importerConsignee
        clientNotify
        countryOfOrigin
        realInspectionDate
        realEtd
        category
        categoryIcon
      }
      items
    }
  }
`;
