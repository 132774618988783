import { DocumentNode, gql } from '@apollo/client';
import { SatListQualityPermissions } from './interfaces';

export const listSatListQualityViewQuery = (
  fieldsPermissions: SatListQualityPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListQualityView($data: ListSatListQualityViewInput!) {
      listSatListQualityView(data: $data) {
        items
        data {
          idSat
          ${fieldsPermissions.rncNumber.view ? 'idSatRnc' : ''}
          ${fieldsPermissions.shipment.view ? 'idSatForeignTrade' : ''}
          ${fieldsPermissions.clientNotify.view ? 'idClient' : ''}
          ${fieldsPermissions.importerConsignee.view ? 'idImporter' : ''}
          satCurrency
          ${fieldsString}
        }
      }
    }
  `;
};

export const getSumDataFromSatQualityListQuery = gql`
  query getSumDataFromSatQualityList($data: SatListQualityViewFieldsSearch!) {
    getSumDataFromSatQualityList(data: $data) {
      satCount
      totalCostFinal
      refund
      itemsTotalUsd
      itemsTotalBrl
      totalCostCurrencySat
      totalCostBrl
    }
  }
`;
