import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { ProductListViewPermissions } from './interfaces';

export const listProductListViewQuery = (
  fieldsPermissions: ProductListViewPermissions,
): DocumentNode => {
  /**
   * Fields que possuem permissao de visualizacao pelo usuario
   */
  const fields = Object.keys(fieldsPermissions).filter(key => {
    return fieldsPermissions[key].view;
  });
  const fieldsString = fields.join('\n');

  return gql`
    query listProductListViewQuery($data: ListProductListViewInput!) {
      listProductListView(data: $data) {
        items
        data {
          idProduct
          imageUrl
          status
          outdated
          taxmissing
          indConfection
          verified
          idSupplier
          idCategory
          idStatus
          ${fieldsString}
        }
      }
    }
  `;
};

export const exportProductListQuery = gql`
  query Query($data: ExportProductListViewInput!) {
    exportProductListView(data: $data)
  }
`;

export const createProductsBulkQuery = gql`
  mutation createProductsBulkMutation($uploadedFile: String!) {
    createProductsBulk(uploadedFile: $uploadedFile) {
      productsTotal
      createdProducts
      productsWithError
    }
  }
`;

export const updateProductsBulkQuery = gql`
  mutation updateProductsBulkMutation($uploadedFile: String!) {
    updateProductsBulk(uploadedFile: $uploadedFile) {
      productsTotal
      updatedProducts
      productsWithError
    }
  }
`;
