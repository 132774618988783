import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { ForeignTradeSupplierContentReducer } from '../interfaces';

interface ISellerCiProps {
  sat: ISat;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanSeeSellerCiBlock: boolean;
  contentState: ForeignTradeSupplierContentReducer;
}

const SellerCi: React.FC<ISellerCiProps> = ({
  sat,
  satForeignTradeSupplier,
  fieldsPermissions,
  userCanSeeSellerCiBlock,
  contentState,
}) => {
  function convertSunCiToUsd() {
    const { exchangeRate, totalSunCiShipment } = satForeignTradeSupplier;

    if (!totalSunCiShipment || !exchangeRate) return undefined;

    const cotTotalConverted = totalSunCiShipment * exchangeRate;

    return cotTotalConverted;
  }

  const satCurrencySymbol = getCurrencySymbol(sat.idCurrency2?.abbreviation);

  return userCanSeeSellerCiBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Seller CI" />

      {fieldsPermissions.sellerCiCurrency.view && (
        <ReadOnlyInput
          className="col-2"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalSunCiShipment.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total SUN CI"
          prefix={satCurrencySymbol}
          value={satForeignTradeSupplier.totalSunCiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          tooltip="Only Product Cost"
          tooltipPosition="bottom"
        />
      )}

      {fieldsPermissions.totalWithApportionment.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total With Apportionment"
          prefix={satCurrencySymbol}
          value={contentState.totalWithApportionment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={contentState.sellerOtherExpensesTotalLoading}
        />
      )}

      {fieldsPermissions.totalConvertedSunCi.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total Converted SUN CI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={convertSunCiToUsd()}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SellerCi;
