import styled from 'styled-components';

export const Container = styled.div`
  .sat-apportionment-ft-number {
    color: var(--blue);
  }

  .sat-apportionment-calculation-basis {
    background-color: #cadbef !important;
  }

  .sat-apportionment-freight {
    background-color: #f9deb8 !important;
  }

  .sat-apportionment-sun {
    background-color: #d3eae5 !important;
  }

  .p-datatable {
    max-width: 95vw;
  }
`;
