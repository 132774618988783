import { divide, multiply, subtract } from '../../../../../utils/mathUtil';
import {
  ISatItemsReducer,
  ISatItemsReducerAction,
  SatReferenceSizeBarcodeToUpdate,
  SatItemReferenceToUpdate,
  SatItemsReducerActionKind,
  SatItemToUpdate,
} from './interfaces';

export const satItemsReducerInitialState: ISatItemsReducer = {
  displayAddSatItemModal: false,
  satItems: [],
  selectedSatItems: [],
  satItemsToUpdate: [],
  editingRows: [],
  referencesToUpdate: [],
  referenceSizeBarcodesToUpdate: [],
  allFieldsSelected: false,
  displayRefreshItemSelectFieldsModal: false,
  displayRefreshItemSelectItemsModal: false,
};

export const satItemsReducer = (
  state: ISatItemsReducer,
  action: ISatItemsReducerAction,
): ISatItemsReducer => {
  switch (action.type) {
    case SatItemsReducerActionKind.CHANGE_ADD_SAT_ITEM:
      return {
        ...state,
        displayAddSatItemModal: !state.displayAddSatItemModal,
        satItems: action.payload?.satItems ?? state.satItems,
      };
    case SatItemsReducerActionKind.SET_SAT_ITEMS:
      return {
        ...state,
        satItems: action.payload?.satItems ?? [],
        satItemsToUpdate: [],
        referencesToUpdate: [],
        referenceSizeBarcodesToUpdate: [],
      };
    case SatItemsReducerActionKind.SET_SELECTED_SAT_ITEMS:
      return {
        ...state,
        selectedSatItems: action.payload?.selectedSatItems ?? [],
      };
    case SatItemsReducerActionKind.CLEAN_SAT_ITEMS_RELATED_DATA:
      return {
        ...state,
        satItems: [],
        selectedSatItems: [],
        satItemsToUpdate: [],
        referencesToUpdate: [],
        referenceSizeBarcodesToUpdate: [],
        editingRows: [],
      };
    case SatItemsReducerActionKind.SET_SAT_ITEMS_TO_UPDATE:
      if (action.payload?.itemToUpdate) {
        const updatedItemObject: SatItemToUpdate = {
          idSatItem: action.payload?.itemToUpdate.idSatItem,
          cotPrice: action.payload?.itemToUpdate.cotPrice,
          targetCot: action.payload?.itemToUpdate.targetCot,
          sunPrice: action.payload?.itemToUpdate.sunPrice,
          targetSun: action.payload?.itemToUpdate.targetSun,
          quantity: action.payload?.itemToUpdate.quantity,
          sample: action.payload?.itemToUpdate.sample,
          samplesApproved: action.payload?.itemToUpdate.samplesApproved,
          quantityShipped: action.payload?.itemToUpdate.quantityShipped,
          sampleNotes: action.payload?.itemToUpdate.sampleNotes,
        };

        const itemToUpdateIndex = state.satItemsToUpdate.findIndex(
          item => item.idSatItem === updatedItemObject.idSatItem,
        );

        const satItem = state.satItems.find(
          item => item.idSatItem === updatedItemObject.idSatItem,
        );

        if (satItem) {
          satItem.cbmTotal =
            (updatedItemObject.quantity
              ? updatedItemObject.quantity / satItem.piecesContainer
              : 0) * satItem.cbm;

          const quantityContainer = divide(
            updatedItemObject.quantity,
            satItem.piecesContainer,
          );

          satItem.quantityContainer = quantityContainer ?? 0;

          const masterNetWeightTotal = multiply(
            satItem.masterNetWeight,
            quantityContainer,
          );

          satItem.masterNetWeightTotal = masterNetWeightTotal
            ? Number(masterNetWeightTotal.toFixed(2))
            : 0;

          const grossWeightTotal = multiply(
            satItem.grossWeight,
            quantityContainer,
          );

          satItem.grossWeightTotal = grossWeightTotal
            ? Number(grossWeightTotal.toFixed(2))
            : 0;

          const amountCot = multiply(
            updatedItemObject.quantity,
            updatedItemObject.cotPrice,
          );

          satItem.amountCot = amountCot ? Number(amountCot.toFixed(2)) : 0;

          const amount = multiply(
            updatedItemObject.quantity,
            updatedItemObject.sunPrice,
          );

          satItem.amount = amount ? Number(amount.toFixed(2)) : 0;

          const estimatedGrossProfit = subtract(
            satItem.amount,
            satItem.amountCot,
          );

          satItem.estimateProfit = estimatedGrossProfit
            ? Number(estimatedGrossProfit.toFixed(2))
            : 0;

          const totalSunCi = multiply(
            satItem.quantityShipped,
            updatedItemObject.sunPrice,
          );

          satItem.totalSunCi = totalSunCi ? Number(totalSunCi.toFixed(2)) : 0;

          const totalCotCi = multiply(
            satItem.quantityShipped,
            updatedItemObject.cotPrice,
          );

          satItem.totalCotCi = totalCotCi ? Number(totalCotCi.toFixed(2)) : 0;

          const grossProfit = subtract(satItem.totalSunCi, satItem.totalCotCi);

          satItem.grossProfit = grossProfit
            ? Number(grossProfit.toFixed(2))
            : 0;

          const totalPisDivision = divide(satItem.amount, satItem.amountCot);
          const estimatedGrossMargin = totalPisDivision
            ? (totalPisDivision - 1) * 100
            : undefined;

          satItem.estimateMargin = estimatedGrossMargin
            ? Number(estimatedGrossMargin.toFixed(2))
            : 0;

          const totalCisDivision = divide(
            satItem.totalSunCi,
            satItem.totalCotCi,
          );
          const grossMargin = totalCisDivision
            ? (totalCisDivision - 1) * 100
            : undefined;

          satItem.grossMargin = grossMargin
            ? Number(grossMargin.toFixed(2))
            : 0;
        }

        if (itemToUpdateIndex !== -1) {
          state.satItemsToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          state.satItemsToUpdate.push(updatedItemObject);
        }
      }

      return { ...state };
    case SatItemsReducerActionKind.SET_EDITING_ROWS:
      return {
        ...state,
        editingRows: action.payload?.editingRows ?? [],
      };
    case SatItemsReducerActionKind.SET_SAT_REFERENCES_TO_UPDATE:
      if (action.payload?.referenceToUpdate) {
        const { idSatItemReference, totalPiCollor } =
          action.payload.referenceToUpdate;

        const updatedItemObject: SatItemReferenceToUpdate = {
          idSatItemReference,
          totalPiCollor,
        };

        // Busca indice item que possui a referenca modificada
        const itemToUpdateIndex = state.referencesToUpdate.findIndex(
          item => item.idSatItemReference === idSatItemReference,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToUpdateIndex !== -1) {
          state.referencesToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          // Se nao estiver, adiciona no array
          state.referencesToUpdate.push(updatedItemObject);
        }
      }

      return state;
    case SatItemsReducerActionKind.SET_SAT_REFERENCE_SIZE_BARCODE_TO_UPDATE:
      if (action.payload?.referenceSizeBarcodeToUpdate) {
        const { idSatReferenceSizeBarcode, sizeRange } =
          action.payload.referenceSizeBarcodeToUpdate;

        const updatedItemObject: SatReferenceSizeBarcodeToUpdate = {
          idSatReferenceSizeBarcode,
          sizeRange,
        };

        const referenceSizeBarcodeToUpdateIndex =
          state.referenceSizeBarcodesToUpdate.findIndex(
            item =>
              item.idSatReferenceSizeBarcode === idSatReferenceSizeBarcode,
          );

        if (referenceSizeBarcodeToUpdateIndex !== -1) {
          state.referenceSizeBarcodesToUpdate.splice(
            referenceSizeBarcodeToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          state.referenceSizeBarcodesToUpdate.push(updatedItemObject);
        }
      }

      return state;
    case SatItemsReducerActionKind.CHANGE_ALL_FIELDS_SELECTED:
      return {
        ...state,
        allFieldsSelected: !state.allFieldsSelected,
      };
    case SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_FIELDS:
      return {
        ...state,
        displayRefreshItemSelectFieldsModal:
          !state.displayRefreshItemSelectFieldsModal,
        allFieldsSelected: false,
        selectedSatItems:
          action.payload?.selectedSatItems ?? state.selectedSatItems,
      };
    case SatItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_SAT_ITEM_SELECT_ITEMS:
      return {
        ...state,
        displayRefreshItemSelectItemsModal:
          !state.displayRefreshItemSelectItemsModal,
        satItems: action.payload?.satItems ?? state.satItems,
        selectedSatItems:
          action.payload?.selectedSatItems ?? state.selectedSatItems,
      };
    case SatItemsReducerActionKind.CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS:
      return {
        ...state,
        displayRefreshItemSelectItemsModal: false,
        displayRefreshItemSelectFieldsModal: true,
      };
    case SatItemsReducerActionKind.CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS:
      return {
        ...state,
        displayRefreshItemSelectFieldsModal: false,
        displayRefreshItemSelectItemsModal: true,
        allFieldsSelected: false,
      };
    default:
      throw new Error();
  }
};
