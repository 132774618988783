import React, { useEffect, useRef, useState } from 'react';
import { TreeSelect, TreeSelectProps } from 'primereact/treeselect';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Wrapper, Container } from './styles';

export interface FormTreeSelectProps extends TreeSelectProps {
  name: string;
  label: string;
  initialValue?: any;
  required?: boolean;
  className?: string;
  readOnly?: boolean;
}

const FormTreeSelect: React.FC<FormTreeSelectProps> = ({
  name,
  label,
  initialValue,
  required,
  className,
  onChange,
  disabled,
  readOnly,
  ...rest
}) => {
  const treeSelectRef = useRef<TreeSelect>(null);
  const [selectedData, setSelectedData] = useState<any>(initialValue);
  const { fieldName, error, registerField } = useField(name);

  // Registra o campo
  useEffect(() => {
    registerField({
      name: fieldName, // Nome do campo
      ref: treeSelectRef.current, // Referência
      getValue: () => {
        return selectedData;
      },
      setValue: (ref, value: any) => {
        setSelectedData(value);
        ref.props.onChange({ value });
      },
    });
  }, [fieldName, registerField, selectedData]);

  return (
    <Wrapper
      className={`${
        className
          ? `${className} form-element form-tree-select`
          : 'form-element form-tree-select'
      }`}
    >
      <Container
        hasError={!!error}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>
        <TreeSelect
          {...rest}
          ref={treeSelectRef} // Referêcia ao dropdown
          value={selectedData}
          onChange={e => {
            if (onChange) onChange(e);
            setSelectedData(e.value);
          }}
          disabled={disabled || readOnly}
        />
      </Container>
      {/* Se houver erro, exibe texto indicativo */}
      {error && (
        <small id="username2-help" className="p-error block">
          {error}
        </small>
      )}
    </Wrapper>
  );
};

export default FormTreeSelect;
