/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { LoadOptions } from 'react-select-async-paginate';
import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { Container, NcmItem } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import { INcm, IProduct, IProductNcm } from '../interfaces';
import FormInputNumber from '../../../../components/FormInputNumber';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { productRoles } from '../../../../shared/roles/product';
import FormInputMask from '../../../../components/FormInputMask';
import MainButton from '../../../../components/MainButton';
import generateRandomString from '../../../../utils/generateRandomString';
import FormInputBooleanCircle from '../../../../components/FormInputBooleanCircle';
import FormDropdown from '../../../../components/FormDropdown';
import client from '../../../../services/apollo/client';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import userHasPermission from '../../../../utils/userHasPermission';
import { IRoleEntityPermission } from '../../../../interfaces/IRoleEntityPermission';
import { useRefHook } from '../../../../hooks/useRefHook';
import { showField } from '../../../../utils/showField';
import { definePermissionAndDisableField } from '../../../../utils/disableField';
import { useAuth } from '../../../../hooks/useAuth';
import { Role } from '../../../../shared/enums/role';

interface IForeignTradeProps extends PageTabContainerProps {
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  roleEntityFields: IRoleEntityField[];
  userPermissions: IRoleEntityPermission[];
  userCanChangeFields: boolean;
}

const ForeignTrade: React.FC<IForeignTradeProps> = ({
  selected,
  product,
  changePropertyValue,
  roleEntityFields,
  userPermissions,
  userCanChangeFields,
}) => {
  const foreignTradeSelectOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const { roles } = useAuth();

  // Referencia ao formulario
  const { formRef } = useRefHook();

  // Permissoes tela de produto
  const { idPermissionAddNcm } = productRoles.permissions;

  // Load Partners Certiying Entities = True
  const loadPartnersCE: LoadOptions<
    any,
    GroupBase<any>,
    { page: any }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllPartnersQuery(
          $listAllPartnersInput: ListAllPartnersInput!
        ) {
          listAllPartners(listAllPartnersInput: $listAllPartnersInput) {
            data {
              ptnNumber
              idPartner
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPartnersInput: {
          _page: 1,
          _limit: 25,
          name: search,
          core: 'Certifying Entity',
          active: 1,
        },
      },
    });

    const filteredOptions = res.data.listAllPartners.data;
    const quantity = res.data.listAllPartners.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  /**
   * Adiciona novo NCM
   */
  function addNcm() {
    // Cria novo array de ncm com novo ncm incluso
    let newNcm: IProductNcm[];
    if (product.productNcms) {
      newNcm = [
        ...product.productNcms,
        {
          idProductNcm: generateRandomString(4),
        } as IProductNcm,
      ];
    } else {
      newNcm = [
        {
          idProductNcm: generateRandomString(4),
        } as IProductNcm,
      ];
    }

    // Altera array atual pelo novo no objeto de product
    changePropertyValue('productNcms', newNcm);
  }

  /**
   * Remove NCM
   * @param index Indice do ncm
   */
  function removeNcm(index: number) {
    if (product.productNcms) {
      // Copia array de ncms
      const newNcm = [...product.productNcms];

      // Remove item do array copiado
      newNcm.splice(index, 1);

      // Altera array atual pelo novo no objeto de ncm
      changePropertyValue('productNcms', newNcm);
    }
  }

  /**
   * Altera dados de NCM
   * @param ncm Novo NCM
   * @param index Indice do NCM atual
   */
  function handleChangeNcm(ncm: INcm, index: number) {
    if (product.productNcms) {
      // Copia array de ncms
      const newNcm = [...product.productNcms];

      // Remove item do array copiado
      newNcm.splice(index, 1, { ...newNcm[index], idNcm2: ncm });

      // Altera array atual pelo novo no objeto de ncm
      changePropertyValue('productNcms', newNcm);

      // Altera valor de foreignTradeReview para falso
      formRef.current?.setFieldValue(
        `productNcms[${index}].foreignTradeReview`,
        false,
      );
    }
  }

  /**
   * Carrega lista de Clientes
   * @param search Parametro de busca
   * @param prevOptions Opcoes Anteriores
   * @param pageData Paginacao
   * @returns Lista de Clientes
   */
  const loadClients: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
          listAllClients(listAllClientsInput: $listAllClientsInput) {
            data {
              idClient
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllClientsInput: {
          pagination: {
            _page: pageData?.page,
            _limit: 25,
          },
          name: search,
          active: true,
        },
      },
    });

    const filteredOptions = res.data.listAllClients.data;
    const quantity = res.data.listAllClients.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  /**
   * Carrega lista de NCMs
   * @param search Parametro de busca
   * @param prevOptions Opcoes Anteriores
   * @param pageData Paginacao
   * @returns Lista de NCMs
   */
  const loadNcms: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllNcmsQuery($listAllNcmInput: ListAllNcmInput!) {
          listAllNcm(listAllNcmInput: $listAllNcmInput) {
            data {
              idNcm
              ${
                showField(productRoles.fields.idFieldNcmName, roleEntityFields)
                  ? 'ncmName'
                  : ''
              }
              ${
                showField(productRoles.fields.idFieldNcm, roleEntityFields)
                  ? 'ncm'
                  : ''
              }
              ${
                showField(productRoles.fields.idFieldDestaque, roleEntityFields)
                  ? 'destaque'
                  : ''
              }
              ${
                showField(productRoles.fields.idFieldLi, roleEntityFields)
                  ? 'li'
                  : ''
              }
              ${
                showField(
                  productRoles.fields.idFieldNveDe1Ate6,
                  roleEntityFields,
                )
                  ? `nve1
                    nve2
                    nve3
                    nve4
                    nve5
                    nve6`
                  : ''
              }
              ${
                showField(productRoles.fields.idFieldDumping, roleEntityFields)
                  ? 'dumping'
                  : ''
              }
              ${
                showField(
                  productRoles.fields.idFieldBaseDumping,
                  roleEntityFields,
                )
                  ? `baseDumping
              baseDumping2 {
                description
              }`
                  : ''
              }
              ${
                showField(
                  productRoles.fields.idFieldPrecoMinimo,
                  roleEntityFields,
                )
                  ? 'priceMin'
                  : ''
              }
            }
            items
          }
        }
      `,
      variables: {
        listAllNcmInput: {
          _page: pageData?.page,
          _limit: 25,
          _orderBy: 'ncmName',
          ncmName: search,
          active: true,
        },
      },
    });

    const filteredOptions = res.data.listAllNcm.data;
    const quantity = res.data.listAllNcm.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  return (
    <PageTabContainer className="overflow-hidden" selected={selected}>
      <Container>
        {showField(productRoles.fields.idFieldHsCode, roleEntityFields) && (
          <FormInput
            className="generalInput"
            name="hsCode"
            label="HS Code"
            readOnly={
              definePermissionAndDisableField(
                productRoles.fields.idFieldHsCode,
                roleEntityFields,
                product.idProduct,
              ) || !userCanChangeFields
            }
          />
        )}
        {showField(
          productRoles.fields.idFieldidCertifyingEntity,
          roleEntityFields,
        ) && (
          <FormAsyncSelect
            className="generalInput"
            name="idCertifyingEntity"
            label="Certifying Entity"
            loadOptions={loadPartnersCE}
            debounceTimeout={100}
            initialValue={product?.idCertifyingEntity2}
            getOptionLabel={(option: any) =>
              `${option.ptnNumber}-${option.name}`
            }
            getOptionValue={(option: any) => option.idPartner}
            additional={{
              page: 1,
            }}
            isClearable
            noOptionsMessage={() => 'Certifying Entities not found'}
            readOnly={
              definePermissionAndDisableField(
                productRoles.fields.idFieldidCertifyingEntity,
                roleEntityFields,
                product.idProduct,
              ) || !userCanChangeFields
            }
            menuPortalTarget={document.body}
          />
        )}
        {showField(
          productRoles.fields.idFieldRegisterNumber,
          roleEntityFields,
        ) && (
          <FormInputNumber
            className="generalInput"
            name="registerNumber"
            label="Registration No."
            readOnly={
              definePermissionAndDisableField(
                productRoles.fields.idFieldRegisterNumber,
                roleEntityFields,
                product.idProduct,
              ) || !userCanChangeFields
            }
          />
        )}

        {showField(
          productRoles.fields.idFieldValidityDate,
          roleEntityFields,
        ) && (
          <FormInput
            className="generalInput"
            name="validityDate"
            label="Validity Date"
            type="date"
            readOnly={
              definePermissionAndDisableField(
                productRoles.fields.idFieldValidityDate,
                roleEntityFields,
                product.idProduct,
              ) || !userCanChangeFields
            }
          />
        )}
      </Container>

      {product.productNcms &&
        product.productNcms.map((productNcm, index) => {
          return (
            <NcmItem key={productNcm.idProductNcm}>
              <div className="row">
                {showField(
                  productRoles.fields.idFieldNcmName,
                  roleEntityFields,
                ) && (
                  <FormAsyncSelect
                    className="input size-one"
                    name={`productNcms[${index}].idNcm`}
                    label="NCM Name"
                    loadOptions={loadNcms}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.ncmName}
                    getOptionValue={(option: any) => option.idNcm}
                    disabled={
                      productNcm.foreignTradeReview &&
                      roles.rolesUser.every(
                        role => role.idRole2.idRole !== Role.COMEX_IMPORT,
                      )
                    }
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No NCMs found'}
                    initialValue={productNcm?.idNcm2}
                    required
                    onValueChange={e => handleChangeNcm(e, index)}
                    readOnly={
                      !userHasPermission(idPermissionAddNcm, userPermissions) ||
                      definePermissionAndDisableField(
                        productRoles.fields.idFieldNcmName,
                        roleEntityFields,
                        productNcm.idProductNcm,
                      ) ||
                      !userCanChangeFields
                    }
                    menuPortalTarget={document.body}
                  />
                )}

                {showField(
                  productRoles.fields.idFieldNcm,
                  roleEntityFields,
                ) && (
                  <FormInputMask
                    className="input size-two"
                    name={`productNcms[${index}].idNcm2.ncm`}
                    label="NCM"
                    mask="9999.99.99"
                    readOnly
                    value={productNcm.idNcm2?.ncm.toString()}
                  />
                )}

                {showField(
                  productRoles.fields.idFieldDestaque,
                  roleEntityFields,
                ) && (
                  <FormInputNumber
                    className="input size-three"
                    name={`productNcms[${index}].idNcm2.destaque`}
                    label="Destaque"
                    readOnly
                  />
                )}

                {showField(
                  productRoles.fields.idFieldNcmClient,
                  roleEntityFields,
                ) && (
                  <FormAsyncSelect
                    className="input size-one"
                    name={`productNcms[${index}].idClient`}
                    label="NCM Client"
                    loadOptions={loadClients}
                    debounceTimeout={1000}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.idClient}
                    additional={{
                      page: 1,
                    }}
                    noOptionsMessage={() => 'No clients found'}
                    disabled={
                      productNcm.foreignTradeReview &&
                      roles.rolesUser.every(
                        role => role.idRole2.idRole !== Role.COMEX_IMPORT,
                      )
                    }
                    initialValue={productNcm?.idClient2}
                    isClearable
                    placeholder={<p>All Clients</p>}
                    readOnly={
                      !userHasPermission(idPermissionAddNcm, userPermissions) ||
                      definePermissionAndDisableField(
                        productRoles.fields.idFieldNcmClient,
                        roleEntityFields,
                        productNcm.idProductNcm,
                      ) ||
                      !userCanChangeFields
                    }
                    menuPortalTarget={document.body}
                  />
                )}

                {showField(
                  productRoles.fields.idFieldTradeReview,
                  roleEntityFields,
                ) && (
                  <FormDropdown
                    className="input size-two"
                    name={`productNcms[${index}].foreignTradeReview`}
                    label="Foreign Trade Review"
                    showClear
                    options={foreignTradeSelectOptions}
                    initialValue={productNcm.foreignTradeReview}
                    disabled={
                      productNcm.foreignTradeReview &&
                      roles.rolesUser.every(
                        role => role.idRole2.idRole !== Role.COMEX_IMPORT,
                      )
                    }
                    readOnly={
                      definePermissionAndDisableField(
                        productRoles.fields.idFieldTradeReview,
                        roleEntityFields,
                        productNcm.idProductNcm,
                      ) || !userCanChangeFields
                    }
                  />
                )}

                {showField(
                  productRoles.fields.idFieldNcm,
                  roleEntityFields,
                ) && (
                  <span className="link-span">
                    {productNcm.idNcm2?.idNcm && (
                      <Link
                        to={`/products/ncm/${productNcm.idNcm2?.idNcm}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open NCM
                        <FiExternalLink size={15} />
                      </Link>
                    )}
                  </span>
                )}

                <div className="button-div">
                  <button
                    type="button"
                    onClick={() => removeNcm(index)}
                    disabled={!userCanChangeFields}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>
              </div>
              <div className="row">
                {showField(productRoles.fields.idFieldLi, roleEntityFields) && (
                  <FormInputBooleanCircle
                    value={productNcm.idNcm2?.li}
                    label="LI"
                    className="input-bottom size-three"
                  />
                )}

                {showField(
                  productRoles.fields.idFieldDumping,
                  roleEntityFields,
                ) && (
                  <FormInputBooleanCircle
                    value={productNcm.idNcm2?.dumping}
                    label="Dumping"
                    className="input-bottom  size-three"
                  />
                )}

                {showField(
                  productRoles.fields.idFieldBaseDumping,
                  roleEntityFields,
                ) && (
                  <FormInput
                    className="input-bottom size-four"
                    name={`productNcms[${index}].idNcm2.baseDumping2.description`}
                    label="Base Dumping"
                    readOnly
                    value={productNcm.idNcm2?.baseDumping2?.description || ''}
                  />
                )}

                {showField(
                  productRoles.fields.idFieldPrecoMinimo,
                  roleEntityFields,
                ) && (
                  <FormInputBooleanCircle
                    value={productNcm.idNcm2?.priceMin}
                    label="Preço Mínimo"
                    className="input-bottom size-two"
                  />
                )}

                {showField(
                  productRoles.fields.idFieldNveDe1Ate6,
                  roleEntityFields,
                ) && (
                  <>
                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve1`}
                      label="NVE 1"
                      value={productNcm.idNcm2?.nve1 || ''}
                      readOnly
                    />

                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve2`}
                      label="NVE 2"
                      value={productNcm.idNcm2?.nve2 || ''}
                      readOnly
                    />
                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve3`}
                      label="NVE 3"
                      value={productNcm.idNcm2?.nve3 || ''}
                      readOnly
                    />
                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve4`}
                      label="NVE 4"
                      value={productNcm.idNcm2?.nve4 || ''}
                      readOnly
                    />
                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve5`}
                      label="NVE 5"
                      value={productNcm.idNcm2?.nve5 || ''}
                      readOnly
                    />
                    <FormInput
                      className="input-bottom size-three"
                      name={`productNcms[${index}].idNcm2.nve6`}
                      label="NVE 6"
                      value={productNcm.idNcm2?.nve6 || ''}
                      readOnly
                    />
                  </>
                )}
              </div>
            </NcmItem>
          );
        })}

      {userHasPermission(idPermissionAddNcm, userPermissions) && (
        <MainButton
          label="Add NCM"
          onClick={() => addNcm()}
          disabled={!userCanChangeFields}
          type="button"
        />
      )}
    </PageTabContainer>
  );
};

export default ForeignTrade;
