/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { useRefHook } from '../../../hooks/useRefHook';
import { Container, CrudForm } from './styles';

import {
  queryToUpdate,
  queryToListIdDomain,
  queryToCreateDomain,
} from '../../../services/querysGql';
import { IGeneralInformationFormData } from '../../../services/dataInterface';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface ContactRoleRouteParams {
  contactRoleId: string;
}
const idGroupToContactRolesClient = 17;

const ContactRole: React.FC = () => {
  // ID do supplier contact role
  const { contactRoleId } = useParams<ContactRoleRouteParams>();

  const defaultPageTitle = `${
    contactRoleId ? 'Manage' : 'Create'
  } Contact Role`;

  // Nome da contact role
  const [contactRoleName, setContactRoleName] = useState('');

  useTitle(
    contactRoleName
      ? `${contactRoleName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Estado da versão lock otimista
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Contact Role',
      path: '/list/contact-roles',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Carrega response do econtact role
  const [
    loadContactRoleData,
    {
      called: ContactRoleCalled,
      loading: ContactRoleLoading,
      data: ContactRoleData,
      error: ContactRoleError,
    },
  ] = useLazyQuery(queryToListIdDomain, {
    variables: {
      idDomain: parseInt(contactRoleId, 10),
    },
    onCompleted: async response => {
      // Valida se o contact role nao foi encontrado
      if (
        !response.listDomainById ||
        response.listDomainById.idGroup !== idGroupToContactRolesClient
      ) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Client Contact Role not found',
          life: ToastLife.ERROR,
        });
        // Redireciona para listagem de contact role cadastrados
        history.push('/list/contact-roles');
        return;
      }
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listDomainById.versionLock);

      // Define novo nome da contact role
      setContactRoleName(response.listDomainById.description);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Client Contact Role data',
        detail: error.message,
        life: ToastLife.SUCCESS,
      });

      setPageLoading(false);
      history.push('/list/contact-roles');
    },
  });

  // Ao carregar a pagina, carrega os Contact Role cadastrados
  useEffect(() => {
    if (contactRoleId) {
      setPageLoading(true);
      loadContactRoleData();
    }
  }, [contactRoleId, loadContactRoleData]);

  // cria método para chamar a mutation
  const [createContactRole] = useMutation(queryToCreateDomain);
  const [updateContactRole] = useMutation(queryToUpdate);

  // Submit para criação de um suppliers Contact Role
  const handleCreateContactRoleSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria um special-negotiation
        await createContactRole({
          variables: {
            domainData: {
              description,
              idGroup: idGroupToContactRolesClient,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client Contact Role created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem dos status
        history.push('/list/contact-roles');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);
          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating suppliers contact role',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createContactRole, toastRef, history],
  );

  const handleEditContactRoleSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes do status
        const updateResponse = await updateContactRole({
          variables: {
            domainData: {
              idDomain: parseInt(contactRoleId, 10),
              description,
              versionLock,
            },
          },
        });
        // atualiza estado de acordo com retorno do servidor
        setVersionLock(updateResponse.data.updateDomain.versionLock);

        // Define novo nome da contact role
        setContactRoleName(description);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client contact role updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating suppliers status',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updateContactRole, contactRoleId, versionLock, toastRef],
  );

  const handleCancel = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/contact-roles'),
    });
  };

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          ContactRoleData
            ? `${defaultPageTitle} - ${contactRoleName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            contactRoleId
              ? handleEditContactRoleSubmit
              : handleCreateContactRoleSubmit
          }
          initialData={
            ContactRoleCalled && !ContactRoleLoading && !ContactRoleError
              ? ContactRoleData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
      </CrudForm>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default ContactRole;
