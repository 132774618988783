/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import FormInputTextArea from '../../../components/FormInputTextArea';
import {
  IOceanFreightTaxFormData,
  OceanFreightTaxRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { oceanFreightTaxRoles } from '../../../shared/roles/oceanFreightTax';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const defaultPageTitle = 'Manage Ocean Freight Tax';

const OceanFreightTax: React.FC = () => {
  useTitle(defaultPageTitle);

  // Redirect
  const history = useHistory();

  // ID de ocean freight tax
  const idOceanFreightTax = parseInt(
    useParams<OceanFreightTaxRouteParams>().idOceanFreightTax,
    10,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de bcCif
  const [bcCif, setBcCif] = useState<boolean>();

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsOceanFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    oceanFreightTaxRoles.idEntity,
  );

  // Permissions de Ocean Freight Tax
  const { idPermissionUpdateOceanFreightTax } =
    oceanFreightTaxRoles.permissions;

  // Valida se usuario pode atualizar Ocean Freight Tax
  const userCanChange = userHasPermission(
    idPermissionUpdateOceanFreightTax,
    userPermissionsOceanFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Ocean Freight Taxes',
      path:
        initialPage && initialFirst
          ? `/logistics/oceanFreightTaxes?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/oceanFreightTaxes',
    },
    {
      name: 'Manage Ocean Freight Tax',
      path: pathname,
    },
  ];

  // Query para listar ocean freight tax
  const listOceanFreightTaxQuery = gql`
    query listLogisticOceanFreightTaxById($idOceanFreightTax: Int!) {
      listLogisticOceanFreightTaxById(idOceanFreightTax: $idOceanFreightTax) {
        idOceanFreightTax
        idTaxName2 {
          description
        }
        taxDescription
        taxValue
        idCurTax2 {
          idCurrency
          abbreviation
        }
        idBcValue2 {
          idDomain
          description
        }
        bcCif
        versionLock
        createdAt
        createdBy2 {
          firstName
          lastName
        }
        updatedAt
        updatedBy2 {
          firstName
          lastName
        }
      }
    }
  `;

  /**
   * Busca dados de Ocean Freight Tax
   */
  const [
    loadOceanFreightTaxData,
    { loading: oceanFreightTaxLoading, data: oceanFreightTaxData },
  ] = useLazyQuery(listOceanFreightTaxQuery, {
    variables: {
      idOceanFreightTax,
    },
    onCompleted: response => {
      if (response.listLogisticOceanFreightTaxById) {
        setBcCif(response.listLogisticOceanFreightTaxById.bcCif);
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Ocean Freight Tax not found',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting ocean freight tax data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/oceanFreightTaxes'),
    });
  }

  // Mutation de atualizacao de ocean freight tax
  const updateOceanFreightTaxQuery = gql`
    mutation UpdateOceanFreightTaxMutation(
      $data: UpdateLogisticsOceanFreightTaxInput!
    ) {
      updateLogisticsOceanFreightTax(data: $data) {
        idOceanFreightTax
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [updateOceanFreightTaxMutation] = useMutation(
    updateOceanFreightTaxQuery,
  );

  // Submit do formulario de ocean freight
  const handleOceanFreightSubmit = useCallback(
    async (data: IOceanFreightTaxFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idCurTax: Yup.string().nullable().required('Required Field'),
          idBcValue: Yup.string().nullable().required('Required Field'),
          taxValue: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Chamada atualizacao de ocean freight tax
        await updateOceanFreightTaxMutation({
          variables: {
            data: {
              idOceanFreightTax,
              versionLock:
                oceanFreightTaxData.listLogisticOceanFreightTaxById
                  ?.versionLock,
              idCurTax: data.idCurTax,
              idBcValue: data.idBcValue,
              taxValue: data.taxValue,
              bcCif,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Ocean Freight tax updated',
          life: ToastLife.SUCCESS,
        });

        // Atualiza dados
        loadOceanFreightTaxData();
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating ocean freight tax',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      bcCif,
      formRef,
      idOceanFreightTax,
      loadOceanFreightTaxData,
      oceanFreightTaxData,
      toastRef,
      updateOceanFreightTaxMutation,
    ],
  );

  // Carrega dados de ocean freight tax ao montar a tela
  useEffect(() => {
    loadOceanFreightTaxData();
  }, [loadOceanFreightTaxData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className="createdAt">
          {oceanFreightTaxData && !oceanFreightTaxLoading
            ? `${new Date(
                oceanFreightTaxData?.listLogisticOceanFreightTaxById?.createdAt,
              ).toLocaleString()} by ${
                oceanFreightTaxData?.listLogisticOceanFreightTaxById?.createdBy2
                  ?.firstName
              } ${
                oceanFreightTaxData?.listLogisticOceanFreightTaxById?.createdBy2
                  ?.lastName
              }`
            : ''}
        </p>
        <p className="updatedAt">
          {oceanFreightTaxData && !oceanFreightTaxLoading
            ? `${new Date(
                oceanFreightTaxData?.listLogisticOceanFreightTaxById
                  ?.updatedAt ??
                  oceanFreightTaxData?.listLogisticOceanFreightTaxById
                    ?.createdAt,
              ).toLocaleString()} by ${
                oceanFreightTaxData?.listLogisticOceanFreightTaxById.updatedBy2
                  ?.firstName ??
                oceanFreightTaxData?.listLogisticOceanFreightTaxById.createdBy2
                  ?.firstName
              } ${
                oceanFreightTaxData?.listLogisticOceanFreightTaxById.updatedBy2
                  ?.lastName ??
                oceanFreightTaxData?.listLogisticOceanFreightTaxById.createdBy2
                  ?.lastName
              }`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {!oceanFreightTaxLoading &&
        oceanFreightTaxData?.listLogisticOceanFreightTaxById
          ?.idOceanFreightTax && (
          <CrudForm>
            <Form
              ref={formRef}
              onSubmit={handleOceanFreightSubmit}
              initialData={oceanFreightTaxData.listLogisticOceanFreightTaxById}
            >
              <Row>
                <FormInput
                  name="taxName"
                  label="Tax Name"
                  defaultValue={
                    oceanFreightTaxData?.listLogisticOceanFreightTaxById
                      .idTaxName2?.description
                  }
                  readOnly
                />
              </Row>

              <FormInputTextArea
                name="taxDescription"
                className="text-area"
                label="Description"
                defaultValue={
                  oceanFreightTaxData?.listLogisticOceanFreightTaxById
                    .taxDescription
                }
                readOnly
              />

              <Row>
                <FormInputNumber
                  required
                  name="taxValue"
                  label="Tax Value"
                  decimalScale={2}
                  min={0}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />
              </Row>

              <Row>
                <FormAsyncSelect
                  name="idCurTax"
                  className="currency-dropdown"
                  label="Currency"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.abbreviation}
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={
                    oceanFreightTaxData.listLogisticOceanFreightTaxById
                      .idCurTax2
                  }
                  readOnly={!userCanChange}
                  required
                />
              </Row>

              <Row>
                <FormAsyncSelect
                  name="idBcValue"
                  className="generalInput"
                  label="BC Value"
                  required
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: 29,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={
                    oceanFreightTaxData?.listLogisticOceanFreightTaxById
                      .idBcValue2
                  }
                  readOnly={!userCanChange}
                />
              </Row>

              <Row>
                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCif"
                      value
                      checked={bcCif === true}
                      onChange={() => setBcCif(true)}
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCif"
                      value={false}
                      checked={bcCif !== true}
                      onChange={() => setBcCif(false)}
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
            </Form>
          </CrudForm>
        )}
      {(oceanFreightTaxLoading || loading) && <Loading />}
    </Container>
  );
};

export default OceanFreightTax;
