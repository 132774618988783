import { useEffect, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useQuery } from '@apollo/client';
import { InputText } from 'primereact/inputtext';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IAdvancedFiltersPanelRef } from '../../../components/AdvancedFiltersPanel/interfaces';
import Dashboard, { IDashboardRef } from '../../../components/Dashboard';
import { useRefHook } from '../../../hooks/useRefHook';
import {
  ClientPaymentDashboard,
  ClientPaymentDashboardLazyParams,
  SatForeignTradeData,
} from './interfaces';
import useTitle from '../../../hooks/useTitle';
import { clientPaymentDashboardQuery } from './queries';
import { parseToLocaleFormat } from '../../../utils/dateUtil';
import { expandOrCollapseHeader } from '../../../services/layoutsDefinitions';
import DynamicIcon from '../../../components/DynamicIcon';
import { Container, DashboardCardTemplate } from './styles';
import { searchDelayMiliseconds } from '../../../config/pagination';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import { clientPaymentFiltersData } from './constants';

const pageTitle = 'Client Payment Dashboard';
const advancedFiltersName = '@SAT:clientPaymentDashboardAdvancedFilters';

const ClientPayment: React.FC = () => {
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);
  const advancedFiltersStorageRef = useRef<IAdvancedFiltersPanelRef>(null);
  const dashboardRef = useRef<IDashboardRef>(null);
  const isMounted = useRef(false);
  const { showError, showWarn } = useRefHook();

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);
  const [statusLoadingMore, setStatusLoadingMore] = useState<number>();
  const [lazyParams, setLazyParams] =
    useState<ClientPaymentDashboardLazyParams>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [clientPaymentData, setClientPaymentData] = useState<
    ClientPaymentDashboard[]
  >([]);
  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  useTitle(pageTitle);

  const hasFilterApplied =
    lazyParams?.fieldsSearch &&
    Object.values(lazyParams?.fieldsSearch).some(value => !!value);

  const { loading, fetchMore } = useQuery(clientPaymentDashboardQuery, {
    // Impede que useQuery execute novamente apos paginacao
    nextFetchPolicy: 'network-only',
    variables: {
      data: lazyParams,
    },
    // Impede que query seja executada enquanto
    // parametros de busca nao forem definidos pelo useEffect
    skip: !lazyParams,
    onCompleted: response => {
      setClientPaymentData(response.getSatClientPaymentProcessDashboard);
    },
    onError: err => {
      showError({
        summary: 'Error loading data',
        detail: err.message,
      });
    },
  });

  function expandCollapsePageHeader() {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  }

  function renderDashboardCardTitle(
    satNumber: string | null,
    mainProduct: string | null,
    shipment: string | null,
  ) {
    return (
      <span className="dashboard-card-title">
        {mainProduct
          ? `${shipment || satNumber} - ${mainProduct}`
          : shipment || satNumber}
      </span>
    );
  }

  function renderDate(date: string | null) {
    if (!date) return '--/--/--';

    const [dateString] = date.split('T');

    return parseToLocaleFormat(dateString);
  }

  async function handleLoadMore(idDomain: number, offset: number) {
    setStatusLoadingMore(idDomain);

    try {
      const response = await fetchMore({
        variables: {
          data: {
            ...lazyParams,
            idClientPaymentStatus: idDomain,
            offset,
          },
        },
      });

      if (!response.data.getSatClientPaymentProcessDashboard[0].data.length) {
        showWarn({
          summary: 'No more data',
          detail: 'There is no more data to load for this status.',
        });

        return;
      }

      const newData = clientPaymentData.map(status => {
        if (status.idDomain === idDomain) {
          return {
            ...status,
            data: [
              ...status.data,
              ...response.data.getSatClientPaymentProcessDashboard[0].data,
            ],
          };
        }
        return status;
      });

      setClientPaymentData(newData);
    } catch (err) {
      showError({
        summary: 'Error loading more',
        detail: err.message,
      });
    } finally {
      setStatusLoadingMore(undefined);
    }
  }

  function handleCardClick(
    idSat: number | null,
    idSatForeignTrade: number | null,
  ) {
    if (!idSat) return;

    const tabFinancial = idSatForeignTrade ? `?tab=financial` : '';

    const url = `/commercial/sats/${idSat}${tabFinancial}`;
    window.open(url, '_blank');
  }

  const cardTemplate = (data: SatForeignTradeData, index: number) => {
    const exporterAndClientNotifyAreNotEqual =
      data.exporter !== data.clientNotify;

    return (
      <DashboardCardTemplate
        key={data.idSatForeignTrade || index}
        onClick={() => handleCardClick(data.idSat, data.idSatForeignTrade)}
      >
        {renderDashboardCardTitle(
          data.satNumber,
          data.mainProduct,
          data.shipment,
        )}
        <div className="dashboard-card-details">
          <div className="dashboard-card-details-text">
            <div className="highligted-text">{data.suppliers}</div>
            <div className="highligted-text">{data.exporter}</div>
            <div className="highligted-text">
              {exporterAndClientNotifyAreNotEqual && data.clientNotify}
            </div>
            <div>Origin: {data.countryOrigin}</div>
            <div className="currency-row">
              <span>Currency: {data.satCurrency}</span>
              <span>Incoterm: {data.paymentSellingIncoterm}</span>
            </div>
          </div>
          {data.categoryIcon && (
            <DynamicIcon
              icon={data.categoryIcon}
              title={data.category ?? undefined}
              size="48"
            />
          )}
        </div>
        <div className="dashboard-card-dates">
          <div>
            <DynamicIcon
              icon="sat/inspection"
              title="Real Inspection Date"
              size="18"
            />
            <p>{renderDate(data.realInspectionDate)}</p>
          </div>
          <div>
            <DynamicIcon icon="sat/ship" title="Real ETD" size="18" />
            <p>{renderDate(data.realEtd)}</p>
          </div>
        </div>
      </DashboardCardTemplate>
    );
  };

  /**
   * Atualiza o filtro global
   */
  useEffect(() => {
    // Evita que o filtro seja atualizado na montagem do componente
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        setLazyParams(current => {
          const newLazyParams = {
            ...current,
            offset: 0,
            globalSearch: globalFilter,
          };

          return newLazyParams;
        });
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }

    return undefined;
  }, [globalFilter]);

  /**
   * Busca os filtros salvos no local storage na primeira montagem do componente
   */
  useEffect(() => {
    if (!isMounted.current) {
      const dashboardData = dashboardRef.current?.getLocalStorageData();
      setGlobalFilter(dashboardData?.globalSearch ?? '');

      setLazyParams({
        fieldsSearch: advancedFiltersStorageRef.current?.getFieldsValues(),
        offset: 0,
        globalSearch: dashboardData?.globalSearch,
      });

      isMounted.current = true;
    }
  }, []);

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an item"
        />
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          ref={advancedFiltersStorageRef}
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={clientPaymentFiltersData}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            setLazyParams(current => {
              const newLazyParams: ClientPaymentDashboardLazyParams = {
                ...current,
                fieldsSearch: e,
              };

              return newLazyParams;
            })
          }
          onClear={() =>
            setLazyParams(current => {
              return {
                ...current,
                fieldsSearch: undefined,
              };
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>

      <Dashboard
        ref={dashboardRef}
        name="clientPayment"
        loading={loading}
        columns={clientPaymentData}
        columnIdKey="idDomain"
        columnTitleKey="description"
        columnCardsKey="data"
        loadMore={(idColumn, cardsLength) =>
          handleLoadMore(idColumn, cardsLength)
        }
        columnLoadingMore={statusLoadingMore}
        cardTemplate={cardTemplate}
        globalSearch={lazyParams?.globalSearch}
      />
    </Container>
  );
};

export default ClientPayment;
