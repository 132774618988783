import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  cqNumber: {
    field: 'cqNumber',
    header: 'CQ Number',
    advancedFilterField: 'cqNumber',
  },
  status: {
    field: 'idStatus2.description',
    header: 'Status',
    advancedFilterField: 'status',
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'MainProduct/Collection',
    advancedFilterField: 'mainProduct',
  },
  countryOrigin: {
    field: 'idCountryOrigin2.name',
    header: 'Country Origin',
    advancedFilterField: 'countryOrigin',
  },
  responsibleUser: {
    field: 'idResponsibleUser2.firstName',
    header: 'Current User',
    advancedFilterField: 'responsibleUser',
  },
  category: {
    field: 'idCategory2.name',
    header: 'Category',
    advancedFilterField: 'category',
  },
  clientCompanyName: {
    field: 'clientCompanyName',
    header: 'Client/Notify',
    advancedFilterField: 'clientCompanyName',
  },
  importer: {
    field: 'idImporter2.name',
    header: 'Importer/Consignee',
    advancedFilterField: 'importer',
  },
  exporter: {
    field: 'idExporter2.name',
    header: 'Exporter',
    advancedFilterField: 'exporter',
  },
  sellingIncoterm: {
    field: 'idSellingIncoterm2.description',
    header: 'Selling Incoterm',
    advancedFilterField: 'sellingIncoterm',
  },
  portOfLoading: {
    field: 'idPortOfLoading2.name',
    header: 'Port/AirPort of Loading',
    advancedFilterField: 'portOfLoading',
  },
  portOfDischarge: {
    field: 'idPortOfDischarge2.name',
    header: 'Port of Discharge',
    advancedFilterField: 'portOfDischarge',
  },
  createdBy2: {
    field: 'createdBy2.firstName',
    header: 'Created By',
    advancedFilterField: '',
  },
};
