import { gql } from '@apollo/client';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import client from '../../../../../services/apollo/client';

export const createProductBrandQuery = gql`
  mutation CreateProductBrand($data: CreateProductBrandInput!) {
    createProductBrand(data: $data) {
      idProductBrand
    }
  }
`;

export const updateProductBrandQuery = gql`
  mutation UpdateProductBrand($data: UpdateProductBrandInput!) {
    updateProductBrand(data: $data) {
      idProductBrand
    }
  }
`;

export const loadBrandingOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; idClient?: number }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listClientBrandsByCliendIdQuery(
        $listClientBrandsByCliendIdInput: ListClientBrandsByCliendIdInput!
      ) {
        listClientBrandsByCliendId(
          listClientBrandsByCliendIdInput: $listClientBrandsByCliendIdInput
        ) {
          data {
            idClientBrand
            brand
            idClient
            notes
            idAttachment
            createdBy
            createdAt
            updatedBy
            updatedAt
            versionLock
          }
          items
        }
      }
    `,
    variables: {
      listClientBrandsByCliendIdInput: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
        idClient: pageData?.idClient,
      },
    },
  });

  const filteredOptions = res.data.listClientBrandsByCliendId.data;
  const quantity = res.data.listClientBrandsByCliendId.items;

  const hasMore = quantity > prevOptions.length + 25;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idClient: pageData?.idClient,
    },
  };
};
