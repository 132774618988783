import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Container, Dot } from './styles';

interface BooleanCircleInputProps {
  label: string;
  value?: boolean;
  className?: string;
}

const FormInputBooleanCircle: React.FC<BooleanCircleInputProps> = ({
  label,
  value,
  className,
}) => {
  return (
    <Container
      className={`${
        className
          ? `${className} formInputBooleanCircle`
          : 'formInputBooleanCircle'
      }`}
      disabled
    >
      <span>
        <p>{label}:</p>
      </span>
      {value !== undefined && value !== null && (
        <>
          <Tooltip target=".boolean-dot" />
          <Dot
            className="boolean-dot"
            value={value}
            data-pr-tooltip={value ? 'Yes' : 'No'}
          />
        </>
      )}
    </Container>
  );
};

export default FormInputBooleanCircle;
