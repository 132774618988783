import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { number, object, string, ValidationError } from 'yup';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormInput from '../../../../components/FormInput';
import Button from '../../../../components/Button';
import { createRecurringPaymentQuery } from './queries';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import { useRefHook } from '../../../../hooks/useRefHook';

type CreateFinancialRecurringPaymentResponse = {
  idFinancialTransaction: number;
  idFinancialTransactionGroup: number;
};

interface ICreateRecurringPaymentProps {
  idFinancialTransaction: number;
  onSuccess?: (e: CreateFinancialRecurringPaymentResponse) => void;
  financialTransactionIsReconciled: boolean;
}

interface ICreateRecurringPaymentFormData {
  idOriginFinancialTransaction: number;
  amount: number;
  paymentForecast: string;
  competencyRegime?: string;
}

const CreateRecurringPayment: React.FC<ICreateRecurringPaymentProps> = ({
  idFinancialTransaction,
  onSuccess,
  financialTransactionIsReconciled,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { showError, showSuccess } = useRefHook();

  const [displayModal, setDisplayModal] = useState(false);

  const [createRecurringPaymentMutation, { loading }] = useMutation(
    createRecurringPaymentQuery,
  );

  function handleModalClose() {
    setDisplayModal(false);
  }

  async function handleSubmit(data: ICreateRecurringPaymentFormData) {
    try {
      formRef.current?.setErrors({});

      const schema = object().shape({
        amount: number()
          .not([0], 'Amount must be different than 0')
          .nullable()
          .required(requiredFieldErrorMessage),
        paymentForecast: string().required(requiredFieldErrorMessage),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await createRecurringPaymentMutation({
        variables: {
          data,
        },
      });

      showSuccess({
        summary: 'Recurring Payment created successfully',
      });

      if (onSuccess) onSuccess(response.data.createFinancialRecurringPayment);

      handleModalClose();
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      } else {
        showError({
          summary: 'Error while creating Recurring Payment',
          detail: error.message,
        });
      }
    }
  }

  const renderFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => handleModalClose()}
          disabled={loading}
        />

        <Button
          label="Save"
          icon="pi pi-check"
          type="submit"
          form="create-recurring-payment-form"
          loading={loading}
        />
      </div>
    );
  };

  return (
    <div>
      <Button
        type="button"
        label="Add Item"
        onClick={() => setDisplayModal(true)}
        disabled={financialTransactionIsReconciled}
      />

      <Dialog
        header="Create Recurring Payment"
        visible={displayModal}
        onHide={() => handleModalClose()}
        footer={renderFooter}
        closable={false}
      >
        <Form
          id="create-recurring-payment-form"
          className="formgrid"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <FormInputNumber
            label=""
            name="idOriginFinancialTransaction"
            hidden
            value={idFinancialTransaction}
          />

          <FormInputNumber
            className="field"
            label="Amount"
            name="amount"
            required
          />

          <FormInput
            className="field"
            type="date"
            name="paymentForecast"
            label="Payment Forecast"
            required
          />
          <FormInput
            className="field"
            type="date"
            name="competencyRegime"
            label="Competency Regime"
          />
        </Form>
      </Dialog>
    </div>
  );
};

export default CreateRecurringPayment;
