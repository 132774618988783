import styled from 'styled-components';
import { shade } from 'polished';

interface SidebarProps {
  visible: boolean;
  sysAdminButtonVisible: boolean;
}

interface ItemsGroupItemsProps {
  collapse: boolean;
}

interface ItemsGroupHeaderProps {
  iconBackground?: string;
}

interface AdminButtonContentProps {
  collapse: boolean;
}

export const Container = styled.aside<SidebarProps>`
  grid-area: structure-sidebar;
  margin-top: var(--header-height);
  position: relative;
  background-color: var(--grey);
  width: var(--sidebar-width);
  border: 1px solid #d7d6d6;
  overflow-y: auto;
  overflow-x: hidden;
  display: ${props => (props.visible ? 'block' : 'none')};

  /** Largura do scroll */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }

  /** Firefox  */
  scrollbar-width: thin;

  input {
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

export const SidebarItems = styled.div`
  nav {
    display: flex;
    flex-direction: column;
    background: #fff;

    a {
      height: 48px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #000000;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#29ABE2')};
      }
    }

    svg {
      margin-left: 22px;
    }

    svg + p {
      margin-left: 14px;
    }

    p {
      margin-left: 56px;
    }

    .selected {
      color: var(--blue);
      border-left: 4px solid var(--blue);
      background-color: var(--grey);

      svg {
        margin-left: 18px;
      }

      svg + p {
        margin-left: 14px;
      }

      p {
        margin-left: 52px;
      }
    }
  }
`;

export const ItemsGroup = styled.div``;

export const ItemsGroupHeader = styled.div<ItemsGroupHeaderProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 2px solid #e5e5e5;
  div {
    display: flex;
    align-items: center;
    span {
      background-color: ${p => p.iconBackground};
      margin-left: 16px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      place-content: center;
      border-radius: 50%;
      svg {
        color: white;
        margin: 0;
      }
    }
    p {
      margin-left: 6px;
    }
  }

  svg {
    margin-right: 4px;
  }
`;

export const ItemsGroupItems = styled.div<ItemsGroupItemsProps>`
  display: ${p => (p.collapse ? `none` : `visible`)};
  p {
    font-size: 13px;
  }
`;

export const AdminButton = styled.button`
  background: #fff;
  align-items: center;

  position: sticky;
  top: 100vw;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  font-size: 14px;

  .adminButtonHeader {
    display: flex;
    flex-direction: row;
    min-height: 48px;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;

      align-items: center;
      svg {
        margin-left: 22px;
        margin-right: 12px;
      }
    }

    .chevronIcon {
      margin-right: 4px;
    }
  }
`;

export const AdminButtonContent = styled.div<AdminButtonContentProps>`
  display: ${p => (p.collapse ? `none` : `visible`)} !important;

  a {
    height: 32px;
    display: flex;
    flex: 1;
    align-items: center;
    text-decoration: none;
    color: #000000;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#29ABE2')};
    }

    p {
      margin-left: 59px;
    }
  }

  .selected {
    color: var(--blue);
    border-left: 4px solid var(--blue);
    background-color: var(--grey);

    p {
      margin-left: 55px;
    }
  }
`;
