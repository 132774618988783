import { ensureEnvAuthorization } from '../../utils/ensureEnvAuthorization';

export const financialIdModule = 6;

export const financialTransactionsRoles = {
  idEntity: 68,
  idModule: financialIdModule,
};

/**
 * Valida se usuario possui permissao para acessar o modulo financeiro
 * @param idUser ID do usuario
 * @returns True se usuario possui permissao
 */
export function isFinancialModuleAllowed(idUser: number): boolean {
  return ensureEnvAuthorization(
    'REACT_APP_FINANCIAL_MODULE_ALLOWED_USERS',
    idUser,
  );
}
