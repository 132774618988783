import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 5px 0px;

  .user-avatar-wrapper {
    margin-right: 8px;

    .round-user-avatar {
      img,
      div {
        width: 32px;
        height: 32px;
        font-size: unset;
      }
    }
  }

  .button-div {
    height: 18px;
    margin: 0px 15px;
    button {
      border: none;
      background: none;
      color: var(--blue);
      transition: color 0.2s;
      &:hover {
        color: var(--red);
      }
    }
  }
`;

export const CommentContent = styled.div`
  border: 1px solid #ced4da;
  border-radius: 3px;
  width: 100%;

  .paperclip-icon .pi-paperclip {
    font-size: 1rem !important;
  }
`;
