import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import React, { Ref, useImperativeHandle, useState } from 'react';
import { useRefHook } from '../../../../hooks/useRefHook';
import { createFinancialTrasnsactionAttachmentsQuery } from '../../FinancialTransaction/queries';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';
import { AttachmentTypes } from '../../../../shared/enums/attachmentType';
import { FileType } from '../../../../shared/enums/fileType';
import FileUpload from '../../../../components/FileUpload';

export interface IFilesUploadRef {
  toggleModal(): void;
}

interface IFilesUploadProps {
  ref: Ref<IFilesUploadRef>;
  idFinancialTransaction: number;
  financialTransactionAttachmentsRefetch(): void;
}

const FilesUpload: React.FC<IFilesUploadProps> = React.forwardRef(
  ({ idFinancialTransaction, financialTransactionAttachmentsRefetch }, ref) => {
    const { showSuccess, showError } = useRefHook();
    const [displayModal, setDisplayModal] = useState(false);

    const [createFinancialTransactionAttachments, { loading }] = useMutation(
      createFinancialTrasnsactionAttachmentsQuery,
    );

    function handleCloseModal() {
      setDisplayModal(false);
    }

    async function handleUploadConfirm(e: FileUploadResponse[]) {
      try {
        await createFinancialTransactionAttachments({
          variables: {
            data: {
              idFinancialTransaction,
              uploadedFiles: e.map(file => file.serverName),
              idType: AttachmentTypes.ATTACHMENT_FILE_FINANCIAL_TRANSACTION,
            },
          },
        });

        showSuccess({
          summary: 'Files created',
        });

        financialTransactionAttachmentsRefetch();

        handleCloseModal();
      } catch (error) {
        showError({
          summary: 'Error while creating files',
          detail: error.message,
        });
      }
    }

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        if (displayModal) {
          handleCloseModal();
        } else {
          setDisplayModal(true);
        }
      },
    }));

    return (
      <Dialog
        header="Upload Bill To Pay / To Receive Files"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => handleCloseModal()}
        closable
        contentStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <FileUpload
          multiple
          accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.TXT},${FileType.ZIP}`}
          onConfirm={e => handleUploadConfirm(e)}
          uploadInProgress={loading}
        />
      </Dialog>
    );
  },
);

export default FilesUpload;
