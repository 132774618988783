import { gql } from '@apollo/client';

export const shipmentDashboardQuery = gql`
  query Data($data: GetSatShipmentProcessDashboardInput) {
    getSatShipmentProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatForeignTrade
        satNumber
        shipment
        mainProduct
        suppliers
        importerConsignee
        clientNotify
        exporter
        category
        categoryIcon
        countryOrigin
        realEtd
        realEta
      }
      items
    }
  }
`;
