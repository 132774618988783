import { gql } from '@apollo/client';

export const createProductPackageReferenceQuery = gql`
  mutation CreateProductPackageReference(
    $data: CreateProductPackageReferenceInput!
  ) {
    createProductPackageReference(data: $data) {
      idPackageReference
    }
  }
`;
