/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm, GeneralInformation } from './styles';
import PageHeader from '../../../components/PageHeader';

import Button from '../../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import MainButton from '../../../components/MainButton';

import { useRefHook } from '../../../hooks/useRefHook';
import Loading from '../../../components/Loading';
import FormInput from '../../../components/FormInput';
import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

// Parametros da rota de condicao de pagamento
interface PaymentConditionRouteParams {
  paymentConditionId: string;
}

interface IGeneralInformationFormData {
  description: string;
}

const PaymentCondition: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID da condicao de pagamento
  const { paymentConditionId } = useParams<PaymentConditionRouteParams>();

  const defaultPageTitle = `${
    paymentConditionId ? 'Manage' : 'Create'
  } Payment Condition`;

  // Nome da condicao de pagamento
  const [paymentConditionName, setPaymentConditionName] = useState('');
  const [versionLock, setVersionLock] = useState();

  useTitle(
    paymentConditionName
      ? `${paymentConditionName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Payment Conditions',
      path: '/list/payment-conditions',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Query para criar condicao de pagamento
  const createPaymentConditionQuery = gql`
    mutation CreateDomainMutation($domainData: CreateDomain!) {
      createDomain(domain: $domainData) {
        description
      }
    }
  `;

  // Query para atualizar condicao de pagamento
  const updatePaymentConditionQuery = gql`
    mutation UpdatePaymentConditionMutation($domainData: UpdateDomain!) {
      updateDomain(domain: $domainData) {
        description
        versionLock
      }
    }
  `;

  // Query para listar dados da condicao de pagamento
  const listPaymentConditionQuery = gql`
    query ListDomainQuery($idDomain: Int!) {
      listDomainById(id: $idDomain) {
        description
        idGroup
        versionLock
      }
    }
  `;

  // Carrega dados da condicao de pagamento
  const [
    loadPaymentConditionData,
    {
      called: paymentConditionCalled,
      loading: paymentConditionLoading,
      data: paymentConditionData,
      error: paymentConditionError,
    },
  ] = useLazyQuery(listPaymentConditionQuery, {
    variables: {
      idDomain: parseInt(paymentConditionId, 10),
    },
    onCompleted: async response => {
      // Valida se condicao de pagamento nao foi encontrado
      if (!response.listDomainById || response.listDomainById.idGroup !== 13) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Payment condition not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de condicoes de pagamento
        history.push('/list/payment-conditions');
        return;
      }

      // Define novo nome da condicao de pagamento
      setPaymentConditionName(response.listDomainById.description);
      setVersionLock(response.listDomainById.versionLock);

      setPageLoading(false);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting payment condition data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/list/payment-conditions');
    },
  });

  // Ao carregar a pagina, carrega dados de condicao de pagamento
  useEffect(() => {
    if (paymentConditionId) {
      setPageLoading(true);
      loadPaymentConditionData();
    }
  }, [loadPaymentConditionData, paymentConditionId]);

  // cria método para chamar a mutation
  const [createPaymentCondition] = useMutation(createPaymentConditionQuery);
  const [updatePaymentCondition] = useMutation(updatePaymentConditionQuery);

  // Submit do formulario
  const handleCreatePaymentConditionSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria condicao de pagamento
        await createPaymentCondition({
          variables: {
            domainData: {
              description,
              idGroup: 13,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Payment condition created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de condicao de pagamento
        history.push('/list/payment-conditions');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating payment condition',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createPaymentCondition, toastRef, history],
  );

  // Submit do formulario
  const handleEditPaymentConditionSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes de condicao de pagamento
        const updtPaymentResponse = await updatePaymentCondition({
          variables: {
            domainData: {
              idDomain: parseInt(paymentConditionId, 10),
              description,
              versionLock,
            },
          },
        });

        // Define novo nome da condicao de pagamento
        setPaymentConditionName(description);
        setVersionLock(updtPaymentResponse.data.updateDomain.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Payment condition updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating payment condition',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      updatePaymentCondition,
      paymentConditionId,
      versionLock,
      toastRef,
    ],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/payment-conditions'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          paymentConditionData
            ? `${defaultPageTitle} - ${paymentConditionName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <GeneralInformation>
          <Form
            ref={formRef}
            onSubmit={
              paymentConditionId
                ? handleEditPaymentConditionSubmit
                : handleCreatePaymentConditionSubmit
            }
            initialData={
              paymentConditionCalled &&
              !paymentConditionLoading &&
              !paymentConditionError
                ? paymentConditionData.listDomainById
                : undefined
            }
          >
            <FormInput
              className="generalInput"
              name="description"
              label="Name"
              required
            />
          </Form>
          {pageLoading && <Loading />}
        </GeneralInformation>
      </CrudForm>
    </Container>
  );
};
export default PaymentCondition;
