import React from 'react';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInput from '../../../../../../components/FormInput';
import FormTitle from '../../../../../../components/FormTitle';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';

interface IOriginalDocsProps {
  shipmentIsCanceled?: boolean;
  foreignTrade: SatForeignTrade;
  fieldsPermissions: ForeignTradeFieldsPermissions;
}

const OriginalDocs: React.FC<IOriginalDocsProps> = ({
  shipmentIsCanceled,
  foreignTrade,
  fieldsPermissions,
}) => {
  return (
    <div className="form-group grid">
      <FormTitle className="col-12" name="Original Docs" />

      {fieldsPermissions.trakingNumber.view && (
        <FormInput
          className="col-3"
          name="trakingNumber"
          label="Tracking Number"
          readOnly={shipmentIsCanceled || !fieldsPermissions.trakingNumber.edit}
        />
      )}

      {fieldsPermissions.idCourier.view && (
        <FormAsyncSelect
          className="col-3"
          name="idCourier"
          label="Courier"
          loadOptions={asyncSelectLoadDomains}
          additional={{
            id: DomainGroup.COURIER,
          }}
          getOptionLabel={option => option.description}
          getOptionValue={option => option.idDomain}
          noOptionsMessage={() => 'No couriers found'}
          initialValue={foreignTrade.idCourier2}
          readOnly={shipmentIsCanceled || !fieldsPermissions.idCourier.edit}
        />
      )}

      {fieldsPermissions.received.view && (
        <FormInput
          className="col-3"
          name="received"
          label="Received"
          type="date"
          readOnly={shipmentIsCanceled || !fieldsPermissions.received.edit}
        />
      )}

      {fieldsPermissions.deliveredToClient.view && (
        <FormInput
          className="col-3"
          name="deliveredToClient"
          label="Delivered To Client"
          type="date"
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.deliveredToClient.edit
          }
        />
      )}
    </div>
  );
};

export default OriginalDocs;
