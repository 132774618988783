import { IRoleEntityField } from '../interfaces/IRoleEntityField';

export interface IFieldPermissionResponse {
  edit: boolean;
  view: boolean;
  create: boolean;
}

export function isInstanceOfFieldPermissionResponse(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
): data is IFieldPermissionResponse {
  return 'view' in data;
}

/**
 * Obtem permissoes para field especifico
 * @param idField ID do field
 * @param roleEntityFields Lista de entities fields
 * @returns Objeto com permissoes para o field
 */
function getFieldPermission(
  idField: number,
  roleEntityFields: IRoleEntityField[],
): IFieldPermissionResponse {
  // Se usuario nao tiver roleEntityFields, retorna objeto com permissoes false
  if (!roleEntityFields || !roleEntityFields.length) {
    return {
      edit: false,
      view: false,
      create: false,
    };
  }

  // Busca fields da entity
  const orignalPermissions = roleEntityFields.filter(
    field => field.idField === idField,
  );

  // Retorna objeto com as permissoes do usuario
  return {
    edit: !!orignalPermissions?.find(permission => permission.edit === true),
    view: !!orignalPermissions?.find(permission => permission.view === true),
    create: !!orignalPermissions?.find(
      permission => permission.create === true,
    ),
  };
}

export default getFieldPermission;
