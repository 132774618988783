/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'react';

/**
 *
 * @param property Propriedade a ser alterada
 * @param value Novo valor
 * @param setState Funcao SetState
 */
const changeStateProperty = (
  property: string,
  value: any,
  setState: Dispatch<React.SetStateAction<any>>,
): void => {
  setState((prevState: any) => ({
    ...prevState,
    [property]: value,
  }));
};

export default changeStateProperty;
