import React, { Ref, useImperativeHandle, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { FileType } from '../../../../../shared/enums/fileType';
import ToastLife from '../../../../../shared/enums/toastLife';
import FileUpload from '../../../../../components/FileUpload';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { createCqAttachmentQuery } from '../../UpdatesContent/queries';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

export interface IFilesUploadRef {
  toggleModal(): void;
}
interface IFilesUploadProps {
  ref: Ref<IFilesUploadRef>;
  idCq: number;
  clientQuotationFilesRefetch(): void;
}

const AttachmentsUpload: React.FC<IFilesUploadProps> = React.forwardRef(
  ({ idCq, clientQuotationFilesRefetch }, ref) => {
    // Referencia ao formulario
    const { toastRef } = useRefHook();

    const [uploadInProgress, setUploadInProgress] = useState(false);

    const [displayModal, setDisplayModal] = useState(false);

    const [createCqAttachmentMutation] = useMutation(createCqAttachmentQuery);

    function handleCloseModal() {
      setDisplayModal(false);
    }

    async function handleUploadConfirm(e: FileUploadResponse[]) {
      const filesObject = e.map(file => {
        return {
          idCq,
          idType: AttachmentTypes.ATTACHMENT_FILE_CQ,
          uploadedFile: file.serverName,
        };
      });

      try {
        setUploadInProgress(true);

        await createCqAttachmentMutation({
          variables: { data: filesObject },
        });

        toastRef.current?.show({
          severity: 'success',
          summary: 'Files created',
          life: ToastLife.SUCCESS,
        });

        clientQuotationFilesRefetch();

        handleCloseModal();
      } catch (error) {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while creating files',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      } finally {
        setUploadInProgress(false);
      }
    }

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        if (displayModal) {
          handleCloseModal();
        } else {
          setDisplayModal(true);
        }
      },
    }));

    return (
      <Container>
        <Dialog
          header="Add an Attachment"
          visible={displayModal}
          style={{ width: '40vw' }}
          onHide={() => handleCloseModal()}
          closable
        >
          <FileUpload
            multiple
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.TXT},${FileType.ZIP}`}
            onConfirm={e => handleUploadConfirm(e)}
            uploadInProgress={uploadInProgress}
          />
        </Dialog>
      </Container>
    );
  },
);

export default AttachmentsUpload;
