/**
 * Valida se objeto eh vazio ou nulo
 * @param obj Objeto a ser validado
 * @returns True
 */
function isEmptyOrNull<T>(obj: T | null | string): obj is null {
  return obj === null || obj === '';
}

export default isEmptyOrNull;
