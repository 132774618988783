import isEmptyNullOrUndefined from './isEmptyNullOrUndefined';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function isArray(obj: any): obj is any[] {
  return Array.isArray(obj);
}

function isObject(obj: any): obj is Record<string, unknown> {
  return obj !== null && typeof obj === 'object';
}

function someHaveContent(firstObject: any, secondObject: any) {
  return (
    !isEmptyNullOrUndefined(firstObject) ||
    !isEmptyNullOrUndefined(secondObject)
  );
}

/**
 * Compara se todas as propriedades do segundo objeto que sao iguais as do primeiro
 * possuem o mesmo valor
 * @param firstObject Primeiro objeto a ser comparado
 * @param secondObject Segundo objeto a ser comparado
 * @returns Se objetos possuem os mesmos valores
 */
export function objectsAreEqual(firstObject: any, secondObject: any): boolean {
  if (firstObject === secondObject) return true;

  // Busca Keys dos dois objetos
  const keys1 = Object.keys(firstObject);
  const keys2 = Object.keys(secondObject);

  // Se o primeiro objeto for vazio e o segundo existir, retorna falso
  if (!keys1.length && keys2.length) return false;

  for (let index = 0; index < keys1.length; index += 1) {
    if (!keys2.includes(keys1[index])) return false;

    if (
      isArray(firstObject[keys1[index]]) &&
      isArray(secondObject[keys1[index]])
    ) {
      if (
        !objectsAreEqual(firstObject[keys1[index]], secondObject[keys1[index]])
      ) {
        return false;
      }
    } else if (
      firstObject[keys1[index]] !== secondObject[keys1[index]] &&
      someHaveContent(firstObject[keys1[index]], secondObject[keys1[index]])
    ) {
      if (
        isObject(firstObject[keys1[index]]) &&
        isObject(secondObject[keys1[index]])
      ) {
        if (
          !objectsAreEqual(
            firstObject[keys1[index]],
            secondObject[keys1[index]],
          )
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  return true;
}
