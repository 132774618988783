export const portRoles = {
  idEntity: 2,
  idModule: 8,
  fields: {
    idFieldType: 13,
    idFieldRegion: 15,
    idFieldCity: 16,
    idFieldInitial: 18,
  },
  permissions: {
    idPermissionDeletePort: 26,
  },
};
