import { useLazyQuery, useMutation } from '@apollo/client';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Checkbox } from 'primereact/checkbox';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import {
  CheckboxItem,
  FilesHeader,
  FilesList,
  RecipientsNDI,
  Section,
} from './styles';
import Button from '../../../../../../../components/Button';
import {
  IShipmentRequest,
  IShipmentRequestProps,
  IUploadedFile,
} from './interfaces';
import FormInputTextArea from '../../../../../../../components/FormInputTextArea';
import FormInput from '../../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import SelectRecipientsModal from '../../../../../../../components/SelectRecipientsModal';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../../../../components/SelectRecipientsModal/interfaces';
import Empty from '../../../../../../../components/Empty';
import { getToShipmentRequestQuery, sendShipmentRequestQuery } from './queries';
import { asyncSelectLoadClientContactsOptions } from '../../../../../../../shared/querys/clientContacts';
import { IClientContact } from '../../../../../../Clients/ClientDetail/interfaces';
import FilesUpload from './FilesUpload';
import FormBooleanRadio from '../../../../../../../components/FormBooleanRadio';

const ShipmentRequestMenu: React.FC<IShipmentRequestProps> = ({
  sat,
  foreignTrade,
  isVisibleShipmentRequestMenu,
  setIsVisibleShipmentRequestMenu,
  foreignTradeRef,
  consolidationData,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { showError, showSuccess } = useRefHook();

  const [isVisibleRecipientsModal, setIsVisibleRecipientsModal] =
    useState(false);

  const [isVisibleFilesUploadModal, setIsVisibleFilesUploadModal] =
    useState(false);

  const [selectedUsersNdi, setSelectedUsersNdi] = useState<IUserEmailsProps[]>(
    [],
  );

  const [userGroups, setUserGroups] = useState<IUserGroupsProps[]>([]);

  const [selectedClientContacts, setSelectedClientContacts] = useState<
    IClientContact[]
  >([]);

  const [shipmentRequestData, setShipmentRequestData] =
    useState<IShipmentRequest>();

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);

  const [shipmentRequestLoading, setShipmentRequestLoading] = useState(false);

  const [listShipmentRequestData, { loading: listShipmentRequestLoading }] =
    useLazyQuery(getToShipmentRequestQuery, {
      variables: {
        idSatForeignTrade: foreignTrade.idSatForeignTrade,
      },
      onCompleted: response => {
        if (response.getSatForeignTradeToShipmentRequest) {
          setShipmentRequestData(response.getSatForeignTradeToShipmentRequest);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Shipment Request data',
          detail: errorData.message,
        });
      },
    });

  const [sendShipmentRequestMutation] = useMutation(sendShipmentRequestQuery);

  const [emailTitleValue, setEmailTitleValue] = useState(() => {
    const { satNumber } = sat;
    const satMainProduct = sat.mainProduct ?? '';
    const clientNotify = sat.idClient2?.name ?? '';
    const exporter = sat.idSupplierExporter2?.name ?? '';

    const consolidationSats = (consolidationData || []).map(
      item => item.satForeignTradeNumber.split('-')[0],
    );

    const validSatNumbers = [...consolidationSats, satNumber].filter(Boolean);
    const satNumbers = new Set<string>(validSatNumbers.map(String));

    return `[AUTORIZAÇÃO DE EMBARQUE] ${clientNotify} | ${Array.from(
      satNumbers,
    ).join(' | ')} | ${satMainProduct} | ${exporter}`;
  });

  const selectedGroupEmailString = useMemo((): string => {
    const selectedUserGroups = userGroups?.filter(groups => groups.isChecked);
    if (selectedUserGroups) {
      const userGroupsNames = selectedUserGroups.map(element => element.name);
      return userGroupsNames.join(', ');
    }
    return '';
  }, [userGroups]);

  const selectedUserString = useMemo((): string => {
    if (selectedUsersNdi) {
      const seledtedUsersNames = selectedUsersNdi.map(
        element => `${element.firstName} ${element.lastName}`,
      );
      return seledtedUsersNames.join(', ');
    }
    return '';
  }, [selectedUsersNdi]);

  const usersAndGroupsAreSelected =
    selectedGroupEmailString && selectedUserString;

  const onHide = () => {
    setIsVisibleShipmentRequestMenu(false);
  };

  async function handleSubmit(formData: IShipmentRequest) {
    setShipmentRequestLoading(true);

    const idsUsersNdi = selectedUsersNdi?.map(e => e.idUser);
    const idsUserGroups = userGroups?.flatMap(e =>
      e.isChecked ? [e.idUserGroup] : [],
    );
    const idsClientContacts = selectedClientContacts?.map(
      e => e.idClientContact,
    );

    try {
      await sendShipmentRequestMutation({
        variables: {
          data: {
            ...formData,
            idSatForeignTrade: foreignTrade.idSatForeignTrade,
            idsUsersNdi,
            idsUserGroups,
            idsClientContacts,
            uploadedFiles: uploadedFiles.map(file => file.serverName),
            emailSubject: emailTitleValue,
            ndiLogisticsService: formData.ndiLogisticsService ? 'Sim' : 'Não',
          },
        },
      });

      foreignTradeRef.current?.refreshForm();

      showSuccess({
        summary: 'Shipment Request sent',
      });

      onHide();
    } catch (error) {
      showError({
        summary: 'Error while sending Shipment Request',
        detail: error.message,
      });
    } finally {
      setShipmentRequestLoading(false);
    }
  }

  const handleSelectFile = (isSelected: boolean, fileKey: string) => {
    const updatedList = uploadedFiles.map(item => {
      if (item.key === fileKey) return { ...item, isSelected };
      return item;
    });

    setUploadedFiles(updatedList);
  };

  const checkIfExistSelectedFiles = () => {
    const selectedFiles = uploadedFiles.filter(
      file => file.isSelected === true,
    );

    return !!selectedFiles.length;
  };

  function removeSelectedFiles() {
    const notSelectedFiles = uploadedFiles.filter(
      item => item.isSelected === false,
    );

    setUploadedFiles(notSelectedFiles);
  }

  const renderUploadedFilesList = () => {
    if (!uploadedFiles.length) {
      return <Empty message="There is no files attached" position="left" />;
    }

    return (
      <FilesList>
        {uploadedFiles.map(file => {
          return (
            <CheckboxItem key={file.key}>
              <Checkbox
                onChange={e => handleSelectFile(!!e.checked, file.key)}
                checked={file.isSelected}
              />
              <p>{file.nameGivenByUser}</p>
            </CheckboxItem>
          );
        })}
      </FilesList>
    );
  };

  const footer = () => {
    return (
      <div className="flex justify-content-end">
        <Button
          label="Send"
          icon={shipmentRequestLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
          type="submit"
          onClick={() => formRef.current?.submitForm()}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          severity="danger"
        />
      </div>
    );
  };

  useEffect(() => {
    if (isVisibleShipmentRequestMenu && !shipmentRequestData) {
      listShipmentRequestData();
    }
  }, [
    isVisibleShipmentRequestMenu,
    shipmentRequestData,
    listShipmentRequestData,
  ]);

  return (
    <div>
      <SelectRecipientsModal
        isVisibleRecipientsModal={isVisibleRecipientsModal}
        setIsVisibleRecipientsModal={setIsVisibleRecipientsModal}
        userGroups={userGroups}
        setUserGroups={setUserGroups}
        selectedUsers={selectedUsersNdi}
        setSelectedUsers={setSelectedUsersNdi}
      />
      <FilesUpload
        isVisibleFilesUploadModal={isVisibleFilesUploadModal}
        setIsVisibleFilesUploadModal={setIsVisibleFilesUploadModal}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
      <Dialog
        header="Shipment Request"
        visible={isVisibleShipmentRequestMenu}
        onHide={onHide}
        style={{ width: '45vw' }}
        footer={footer}
      >
        <Section>
          <h5>Título do Email</h5>
          <InputText
            name="emailTitle"
            aria-describedby="email-title"
            className="col-12"
            value={emailTitleValue}
            onChange={e => setEmailTitleValue(e.target.value)}
          />

          <RecipientsNDI>
            <h5>Destinatários NDI</h5>
            <div className="content">
              <InputText
                aria-describedby="recipients-help"
                className="col-10"
                value={`${selectedGroupEmailString}${
                  usersAndGroupsAreSelected ? ', ' : ''
                }${selectedUserString}`}
              />
              <Button
                label="Add"
                icon="pi pi-plus"
                onClick={() => {
                  setIsVisibleRecipientsModal(true);
                }}
                autoFocus
              />
            </div>
          </RecipientsNDI>

          <h5>Destinatários Client</h5>
          <AsyncPaginate
            loadOptions={asyncSelectLoadClientContactsOptions}
            debounceTimeout={1000}
            isMulti
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.idClientContact}
            additional={{
              page: 1,
              idClient: sat.idClient,
            }}
            value={selectedClientContacts}
            onChange={e => setSelectedClientContacts(e as IClientContact[])}
          />
        </Section>

        {!listShipmentRequestLoading && shipmentRequestData && (
          <Form
            className="grid"
            style={{ margin: '10px' }}
            ref={formRef}
            initialData={shipmentRequestData}
            onSubmit={handleSubmit}
          >
            <FormInputTextArea
              className="col-12"
              name="emailDescription"
              defaultValue=""
              label="Descrição do Email"
            />

            <FormInput className="col-6" name="mainProduct" label="Produto" />
            <FormInput
              className="col-6"
              name="realInspectionDate"
              label="Data Inspeção"
              type="date"
            />
            <FormInput
              className="col-6"
              name="qtyTypeCtnr"
              label="Qtde/Tipo Ctnr"
            />
            <FormInput
              className="col-6"
              name="portOfLoading"
              label="Porto Origem"
            />
            <FormInput
              className="col-6"
              name="portOfDischarge"
              label="Porto Chegada"
            />
            <FormInput className="col-6" name="buyer" label="Comprador" />
            <FormInput className="col-6" name="clientOrder" label="Nº Pedido" />
            <FormBooleanRadio
              className="col-6"
              name="ndiLogisticsService"
              label="Serviço NDI Logística"
            />
            <FormInputNumber
              className="col-6"
              name="totalSunPi"
              label="Total Pedido"
              currency={sat?.idCurrency2?.abbreviation ?? '$'}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
            />
          </Form>
        )}

        {listShipmentRequestLoading && <Skeleton height="2rem" />}

        <Section>
          <FilesHeader>
            <h2>Anexos</h2>
            <Button
              label="Add"
              icon="pi pi-plus"
              onClick={() => setIsVisibleFilesUploadModal(true)}
            />
            <Button
              label="Remove"
              icon="pi pi-times"
              onClick={() => removeSelectedFiles()}
              severity="danger"
              disabled={!checkIfExistSelectedFiles()}
            />
          </FilesHeader>

          {renderUploadedFilesList()}
        </Section>
      </Dialog>
    </div>
  );
};

export default ShipmentRequestMenu;
