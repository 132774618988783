import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  .success {
    background-color: #25f655;
  }

  .danger {
    background-color: #cd151c;
  }
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
