import React from 'react';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ForeignTradeSupplierTotalReducer } from '../interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import { SatFinancialTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface ISellerTotalReceivedProps {
  totalState: ForeignTradeSupplierTotalReducer;
  fieldsPermissions: SatFinancialTotalFieldsPermissions;
  satCurrency?: string;
}

const SellerTotalReceived: React.FC<ISellerTotalReceivedProps> = ({
  totalState,
  fieldsPermissions,
  satCurrency,
}) => {
  const userCanSeeBlock =
    fieldsPermissions.total.view || fieldsPermissions.totalRemain.view;

  const satCurrencySymbol = getCurrencySymbol(satCurrency);

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Total Received" />

      {fieldsPermissions.total.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Received"
          value={totalState.sellerTotalReceived}
          loading={
            totalState.sellerAdvanceReceivedTotalLoading ||
            totalState.sellerBalanceReceivedTotalLoading
          }
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
        />
      )}

      {fieldsPermissions.totalRemain.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Remain SUN"
          value={totalState.sellerTotalRemainSun}
          loading={
            totalState.sellerAdvanceReceivedTotalLoading ||
            totalState.sellerBalanceReceivedTotalLoading ||
            totalState.financialTotalLoading
          }
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
          severity={
            totalState.sellerTotalRemainSun &&
            totalState.sellerTotalRemainSun < 0
              ? 'danger'
              : undefined
          }
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SellerTotalReceived;
