import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  width: 300px;
  height: 350px;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 20px;
`;

export const CoreContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
`;

export const Row = styled.div`
  margin: 0 0 16px 0;
  width: 500px;
`;
