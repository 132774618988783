import styled from 'styled-components';

export const Container = styled.div`
  .p-multiselect-label-container {
    display: none;
  }
  .p-multiselect {
    border: none;
    height: 36px;
    background: none;
  }
  .pi {
    font-size: 18px;
    color: #000;
  }
`;
