import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTablePageEvent } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import { GridActions } from '../../../../../../components/Grid/styles';
import MainButton from '../../../../../../components/MainButton';
import pagination from '../../../../../../config/pagination';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { FinancialPurchaseOtherExpenses } from '../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { parseToLocaleFormat } from '../../../../../../utils/dateUtil';
import {
  parseCurrencyPtBrNumberColumn,
  parseXBooleanColumn,
} from '../../../../../../utils/gridColumnsParse';
import {
  SatFinancialPurchaseOtherExpenseFieldsPermissions,
  UserCanObject,
} from '../../interfaces';
import { SatOtherExpense, SatOtherExpensesLazyParams } from './interfaces';
import ManageOtherExpense, {
  IManageOtherExpenseRef,
} from './ManageOtherExpense';
import {
  addDebitNoteQuery,
  deleteOtherExpenseQuery,
  listOtherExpensesQuery,
  removeDebitNoteQuery,
} from './queries';
import { Container } from './styles';
import Button from '../../../../../../components/Button';
import { IPurchaseOtherExpensesTotalRef } from '../PurchaseOtherExpensesTotal';

interface IPurchaseOtherExpensesProps {
  idSatForeignTradeSupplier: number;
  selected: boolean;
  fieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions;
  satCurrency?: string;
  userCanObjects: UserCanObject;
  totalRef: RefObject<IPurchaseOtherExpensesTotalRef>;
}

const PurchaseOtherExpenses: React.FC<IPurchaseOtherExpensesProps> = ({
  idSatForeignTradeSupplier,
  selected,
  fieldsPermissions,
  satCurrency,
  userCanObjects,
  totalRef,
}) => {
  const { toastRef } = useRefHook();

  const manageOtherExpenseRef = useRef<IManageOtherExpenseRef>(null);

  const [lazyParams, setLazyParams] = useState<SatOtherExpensesLazyParams>(
    pagination.initialLazyParams,
  );

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  const [debitNoteBeingAdded, setDebitNoteBeingAdded] = useState<number>();

  const [otherExpenses, setOtherExpenses] = useState<SatOtherExpense[]>();

  const [deleteOtherExpenseMutation] = useMutation(deleteOtherExpenseQuery);

  const [addDebitNoteMutation] = useMutation(addDebitNoteQuery);

  const [removeDebitNoteMutation] = useMutation(removeDebitNoteQuery);

  const notAllowedToBeDeletedTypes = [
    FinancialPurchaseOtherExpenses.FREIGHT,
    FinancialPurchaseOtherExpenses.FREIGHT_TAXES,
  ];

  const [
    loadOtherExpensesData,
    { loading: otherExpensesLoading, data: otherExpensesData },
  ] = useLazyQuery(listOtherExpensesQuery(fieldsPermissions), {
    variables: {
      data: {
        idSatForeignTradeSupplier,
        typeIdDomainGroup: DomainGroup.FINANCIAL_PURCHASE_OTHER_EXPENSES,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
      },
    },
    onCompleted: response => {
      if (response.listSatForeignTradeSupplierOtherExpenses) {
        setOtherExpenses([
          ...response.listSatForeignTradeSupplierOtherExpenses.data,
        ]);
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Purchase Other Expenses',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  async function otherExpensesRelatedDataRefetch() {
    loadOtherExpensesData();

    totalRef.current?.refetch();
  }

  async function handleDeleteOtherExpense(idSatOtherExpense: number) {
    setItemBeingDeleted(idSatOtherExpense);

    try {
      await deleteOtherExpenseMutation({
        variables: {
          idSatOtherExpense,
        },
      });

      await otherExpensesRelatedDataRefetch();

      toastRef.current?.show({
        severity: 'success',
        summary: 'Purchase Other Expense deleted',
        life: ToastLife.SUCCESS,
      });
    } catch (errorData) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting Purchase Other Expense',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setItemBeingDeleted(undefined);
    }
  }

  async function handleAddDebitNote(idSatOtherExpense: number) {
    setDebitNoteBeingAdded(idSatOtherExpense);

    try {
      await addDebitNoteMutation({
        variables: {
          idSatOtherExpense,
        },
      });

      await otherExpensesRelatedDataRefetch();

      toastRef.current?.show({
        severity: 'success',
        summary: 'Debit note added',
        life: ToastLife.SUCCESS,
      });
    } catch (errorData) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while adding Debit Note',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setDebitNoteBeingAdded(undefined);
    }
  }

  async function handleRemoveDebitNote(idSatOtherExpense: number) {
    setItemBeingDeleted(idSatOtherExpense);

    try {
      await removeDebitNoteMutation({
        variables: {
          idSatOtherExpense,
        },
      });

      await otherExpensesRelatedDataRefetch();

      toastRef.current?.show({
        severity: 'success',
        summary: 'Debit note removed',
        life: ToastLife.SUCCESS,
      });
    } catch (errorData) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while removing Debit Note',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setItemBeingDeleted(undefined);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = (event: DataTablePageEvent) => {
    setLazyParams({ ...lazyParams, ...event });
  };

  function expenseIsDebitNoteToAdd(rowData: SatOtherExpense) {
    return (
      rowData.idType === FinancialPurchaseOtherExpenses.DEBIT_NOTE &&
      rowData.idSatForeignTradeSupplierDestination ===
        idSatForeignTradeSupplier &&
      !rowData.confirmationDate
    );
  }

  function expenseIsDebitNoteAdded(rowData: SatOtherExpense) {
    return (
      rowData.idType === FinancialPurchaseOtherExpenses.DEBIT_NOTE &&
      rowData.idSatForeignTradeSupplierDestination ===
        idSatForeignTradeSupplier &&
      rowData.confirmationDate
    );
  }

  function expenseIsDebitNoteAddedFromOrigin(rowData: SatOtherExpense) {
    return (
      rowData.idType === FinancialPurchaseOtherExpenses.DEBIT_NOTE &&
      rowData.idSatForeignTradeSupplier === idSatForeignTradeSupplier &&
      rowData.confirmationDate
    );
  }

  function checkIfUserCanDeleteItem(rowData: SatOtherExpense) {
    const { idType } = rowData;

    switch (true) {
      case !!expenseIsDebitNoteAddedFromOrigin(rowData):
      case idType === FinancialPurchaseOtherExpenses.DEBIT_NOTE &&
        !rowData.confirmationDate:
      case notAllowedToBeDeletedTypes.includes(idType):
      case !userCanObjects.userCanDeletePurchaseOtherExpenses:
        return false;
      default:
        return true;
    }
  }

  const gridActions = (rowData: SatOtherExpense) => {
    const isBeingDeleted = itemBeingDeleted === rowData.idSatOtherExpense;

    const userCanDeleteItem = checkIfUserCanDeleteItem(rowData);

    const userCanEditItem =
      userCanObjects.userCanEditPurchaseOtherExpenses &&
      rowData.idType !== FinancialPurchaseOtherExpenses.DEBIT_NOTE;

    return (
      <GridActions>
        {userCanEditItem && (
          <button
            type="button"
            onClick={() =>
              manageOtherExpenseRef.current?.toggleManageOtherExpenseDialog(
                rowData,
              )
            }
            disabled={isBeingDeleted}
          >
            <FiEdit2 size={20} />
          </button>
        )}
        {userCanDeleteItem && (
          <button
            type="button"
            className="trash-button"
            onClick={() =>
              confirmDialog({
                message: 'Do you confirm to remove purchase other expense?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () =>
                  expenseIsDebitNoteAdded(rowData)
                    ? handleRemoveDebitNote(rowData.idSatOtherExpense)
                    : handleDeleteOtherExpense(rowData.idSatOtherExpense),
              })
            }
            disabled={!!itemBeingDeleted}
          >
            {isBeingDeleted ? (
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: '1.43rem' }}
              />
            ) : (
              <FiTrash2 size={20} />
            )}
          </button>
        )}
      </GridActions>
    );
  };

  function getRowClassName(rowData: SatOtherExpense) {
    return {
      'oe-grid-debit-note-to-add': expenseIsDebitNoteToAdd(rowData),
      'oe-grid-debit-note-added': expenseIsDebitNoteAdded(rowData),
      'oe-grid-debit-note-added-from-origin':
        expenseIsDebitNoteAddedFromOrigin(rowData),
    };
  }

  const renderTypeColumn = (rowData: SatOtherExpense) => {
    const { idType2, idSatOtherExpense } = rowData;

    if (expenseIsDebitNoteToAdd(rowData)) {
      return (
        <Button
          label="Add Debit Note"
          type="button"
          onClick={() => {
            confirmDialog({
              message: 'Do you confirm to add Debit Note?',
              header: 'Adding a debit note',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => handleAddDebitNote(idSatOtherExpense),
            });
          }}
          loading={debitNoteBeingAdded === idSatOtherExpense}
          disabled={!!debitNoteBeingAdded}
        />
      );
    }

    return (
      <div>
        <span>{idType2?.description}</span>
      </div>
    );
  };

  const renderObsColumn = (rowData: SatOtherExpense) => {
    return (
      <>
        <Tooltip
          target={`.s-comment-${rowData.idSatOtherExpense}`}
          position="top"
        />
        <span
          className={`s-comment-${rowData.idSatOtherExpense} s-grid-break-text`}
          data-pr-tooltip={rowData.comment}
        >
          {rowData.comment}
        </span>
      </>
    );
  };

  useEffect(() => {
    if (!otherExpensesData && selected) {
      loadOtherExpensesData();
    }
  }, [loadOtherExpensesData, otherExpenses, otherExpensesData, selected]);

  return (
    <Container>
      <FormTitle name="Other Expenses">
        {userCanObjects.userCanAddPurchaseOtherExpenses && (
          <MainButton
            type="button"
            onClick={() =>
              manageOtherExpenseRef.current?.toggleManageOtherExpenseDialog()
            }
          >
            Add Item
          </MainButton>
        )}
        <Button
          icon="pi pi-refresh"
          onClick={() => otherExpensesRelatedDataRefetch()}
        />
      </FormTitle>

      <ManageOtherExpense
        ref={manageOtherExpenseRef}
        idSatForeignTradeSupplier={idSatForeignTradeSupplier}
        satCurrency={satCurrency}
        otherExpensesRefetch={otherExpensesRelatedDataRefetch}
        fieldsPermissions={fieldsPermissions}
      />

      <Grid
        emptyMessage="No Other Expenses found"
        loading={otherExpensesLoading}
        value={otherExpenses}
        lazy
        rows={lazyParams.rows}
        first={!otherExpensesData ? undefined : lazyParams.first}
        totalRecords={
          !otherExpensesData
            ? 0
            : otherExpensesData.listSatForeignTradeSupplierOtherExpenses?.items
        }
        scrollable
        scrollHeight="calc(100vh - 272px)"
        onPage={onPage}
        rowClassName={getRowClassName}
        tableStyle={{
          tableLayout: 'fixed',
        }}
      >
        {(userCanObjects.userCanDeletePurchaseOtherExpenses ||
          userCanObjects.userCanEditPurchaseOtherExpenses) && (
          <Column
            field="actions"
            style={{ width: '6rem' }}
            body={gridActions}
          />
        )}

        {fieldsPermissions.idType.view && (
          <Column
            field="idType2.description"
            header="Type"
            body={e => renderTypeColumn(e)}
            style={{ width: '10rem' }}
            bodyClassName="s-uppercase"
          />
        )}

        <Column
          field="idCurrency2.abbreviation"
          header="Currency"
          style={{ width: '6rem' }}
        />

        {fieldsPermissions.total.view && (
          <Column
            field="total"
            header="Total"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(
                data,
                props,
                2,
                data.idCurrency2.abbreviation,
              )
            }
            style={{ width: '8rem' }}
          />
        )}
        {fieldsPermissions.dateOtherExpense.view && (
          <Column
            header="Date"
            body={e => parseToLocaleFormat(e.dateOtherExpense)}
            style={{ width: '8rem' }}
          />
        )}
        {fieldsPermissions.linkToMargin.view && (
          <Column
            header="Link to Margin"
            body={e =>
              parseXBooleanColumn(e, {
                field: 'linkToMargin',
              })
            }
            bodyClassName="s-uppercase"
            style={{ width: '6rem' }}
          />
        )}
        {fieldsPermissions.discountFromSupplier.view && (
          <Column
            header="Discount From Supplier"
            style={{ width: '9rem' }}
            body={e =>
              parseXBooleanColumn(e, {
                field: 'discountFromSupplier',
              })
            }
            bodyClassName="s-uppercase"
          />
        )}
        {fieldsPermissions.payToSupplier.view && (
          <Column
            header="Pay To Supplier"
            style={{ width: '8rem' }}
            body={e =>
              parseXBooleanColumn(e, {
                field: 'payToSupplier',
              })
            }
            bodyClassName="s-uppercase"
          />
        )}
        {fieldsPermissions.comment.view && (
          <Column
            header="Obs"
            body={e => renderObsColumn(e)}
            style={{ width: '20rem' }}
          />
        )}
      </Grid>
    </Container>
  );
};

export default PurchaseOtherExpenses;
