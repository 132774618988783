import { gql } from '@apollo/client';

export const createAirFreightsBulkQuery = gql`
  mutation createLogisticsAirFreightsBulk($uploadedFile: String!) {
    createLogisticsAirFreightsBulk(uploadedFile: $uploadedFile) {
      airFreightsTotal
      createdAirFreights
      airFreightsWithError
    }
  }
`;

export const updateAirFreightsBulkQuery = gql`
  mutation updateLogisticsAirFreightsBulk($uploadedFile: String!) {
    updateLogisticsAirFreightsBulk(uploadedFile: $uploadedFile) {
      airFreightsTotal
      updatedAirFreights
      airFreightsWithError
    }
  }
`;
