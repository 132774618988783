/* eslint-disable no-shadow */
export enum CostType {
  NO_COST = 968,
  YES_NOT_REFUNDABLE = 969,
  YES_REFUNDABLE = 970,
  YES_REFUNDED = 971,
}

export enum StatusRnc {
  OPEN = 939,
  CLOSED = 940,
  CANCEL = 996,
}
