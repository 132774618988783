import { gql, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addDays } from 'date-fns';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { Dispatch, useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import Button from '../../../../components/Button';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormDatePicker from '../../../../components/FormDatePicker';
import FormInput from '../../../../components/FormInput';
import Loading from '../../../../components/Loading';
import MainButton from '../../../../components/MainButton';
import { useRefHook } from '../../../../hooks/useRefHook';
import { CostType } from '../../../../shared/enums/costType';
import { PriorityInDays } from '../../../../shared/enums/priorityInDays';
import { PriorityType } from '../../../../shared/enums/priorityType';
import { ProblemType } from '../../../../shared/enums/problemType';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import { asyncSelectLoadSatForeignTradeSupplierSummarized } from '../../../../shared/querys/satForeignTradeSuppliersSummarized';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import { ISat } from '../interfaces';
import { ISatRncRef } from '../Rnc';
import { Buttons, Row } from './styles';
import { ClientDebitNoteStatus } from './enum';

interface IOpenRncMenuProps {
  isVisibleOpenRncMenu: boolean;
  setIsVisibleOpenRncMenu(e: boolean): void;
  sat: ISat;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pageLoading: boolean;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  rncRef: React.RefObject<ISatRncRef>;
}

const OpenRncMenu: React.FC<IOpenRncMenuProps> = ({
  isVisibleOpenRncMenu,
  setIsVisibleOpenRncMenu,
  sat,
  setLoading,
  pageLoading,
  setSelectedMenuItem,
  rncRef,
}) => {
  const formRef = useRef<FormHandles>(null);

  const { showError, showSuccess } = useRefHook();

  const [
    isProblemTypeDescriptionRequired,
    setIsProblemTypeDescriptionRequired,
  ] = useState(false);

  const [isCostResponsibleRequired, setIsCostResponsibleRequired] =
    useState(false);

  const [deadline, setDeadline] = useState<Date | undefined>(undefined);

  const onHide = useCallback(() => {
    setIsVisibleOpenRncMenu(false);
  }, [setIsVisibleOpenRncMenu]);

  const createSatRncQuery = gql`
    mutation CreateSatRnc($data: CreateSatRncInput!) {
      createSatRnc(data: $data) {
        idSatRnc
      }
    }
  `;

  const [createSatRncMutation] = useMutation(createSatRncQuery);

  const createRnc = useCallback(
    async (data: any) => {
      setLoading(true);
      try {
        await createSatRncMutation({
          variables: {
            data: {
              idSat: sat.idSat,
              idSatForeignTrade: data.satShipment.idSatForeignTrade,
              idSatForeignTradeSupplier:
                data.satShipment.idSatForeignTradeSupplier,
              idSatSupplier: data.satShipment.idSatSupplier,
              idSupplier: data.satShipment.idSupplier,
              contactClient: data.contactClient
                ? data.contactClient
                : undefined,
              idRncResponsible: data.idRncResponsible,
              idPriority: data.idPriority,
              deadline,
              idProblemType: data.idProblemType,
              problemTypeDescription: data.problemTypeDescription
                ? data.problemTypeDescription
                : undefined,
              problemDescription: data.problemDescription,
              proposedSolution: data.proposedSolution,
              idReWork: data.idReWork,
              idCost: data.idCost,
              idCostResponsible: data.idCostResponsible,
              clientDebitNoteStatus: ClientDebitNoteStatus.NOT_REQUIRED,
            },
          },
        });
        setSelectedMenuItem('rnc');
        rncRef.current?.refetch();

        showSuccess({
          summary: 'SAT RNC created successfully',
        });
        setIsVisibleOpenRncMenu(false);
      } catch (error) {
        showError({
          summary: 'Error while creating SAT RNC',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      createSatRncMutation,
      deadline,
      rncRef,
      sat.idSat,
      setIsVisibleOpenRncMenu,
      setLoading,
      setSelectedMenuItem,
      showError,
      showSuccess,
    ],
  );

  const validateForm = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          satShipment: Yup.object()
            .nullable()
            .required(requiredFieldErrorMessage),
          idPriority: Yup.number()
            .nullable()
            .required(requiredFieldErrorMessage),
          idRncResponsible: Yup.number()
            .nullable()
            .required(requiredFieldErrorMessage),
          idProblemType: Yup.number()
            .nullable()
            .required(requiredFieldErrorMessage),
          problemTypeDescription: Yup.string().when('idProblemType', {
            is: ProblemType.OTHER,
            then: Yup.string().required(requiredFieldErrorMessage),
            otherwise: Yup.string().notRequired(),
          }),
          problemDescription: Yup.string().required(requiredFieldErrorMessage),
          proposedSolution: Yup.string().required(requiredFieldErrorMessage),
          idReWork: Yup.number().nullable().required(requiredFieldErrorMessage),
          idCost: Yup.number().nullable().required(requiredFieldErrorMessage),
          idCostResponsible: Yup.number()
            .nullable()
            .when('idCost', {
              is: CostType.YES_NOT_REFUNDABLE,
              then: Yup.number().nullable().required(requiredFieldErrorMessage),
              otherwise: Yup.number().nullable().notRequired(),
            })
            .when('idCost', {
              is: CostType.YES_REFUNDABLE,
              then: Yup.number().nullable().required(requiredFieldErrorMessage),
              otherwise: Yup.number().nullable().notRequired(),
            })
            .when('idCost', {
              is: CostType.YES_REFUNDED,
              then: Yup.number().nullable().required(requiredFieldErrorMessage),
              otherwise: Yup.number().nullable().notRequired(),
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          showError({
            summary: 'Error while creating RNC',
            detail: error.message,
          });
        }
        return false;
      }
    },
    [showError],
  );

  const handleCreateRnc = useCallback(
    async (data: any) => {
      const isFormValid = await validateForm(data);
      if (isFormValid) {
        await createRnc(data);
      }
    },
    [createRnc, validateForm],
  );

  const onPriorityChange = useCallback((idPriority: number) => {
    const today = new Date();
    let daysToAdd: PriorityInDays = 0;
    if (idPriority === PriorityType.NORMAL) {
      daysToAdd = PriorityInDays.NORMAL;
    } else if (idPriority === PriorityType.MEDIUM) {
      daysToAdd = PriorityInDays.MEDIUM;
    } else if (idPriority === PriorityType.URGENT) {
      daysToAdd = PriorityInDays.URGENT;
    }
    const deadlineDate =
      idPriority !== PriorityType.FOLLOW
        ? addDays(today, daysToAdd)
        : undefined;
    setDeadline(deadlineDate);
  }, []);

  const cancel = useCallback(() => {
    confirmDialog({
      message: `You can’t undo this action, confirm?`,
      header: 'Cancel SAT RNC Creation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        onHide();
      },
    });
  }, [onHide]);

  return (
    <Dialog
      header="Create RNC"
      visible={isVisibleOpenRncMenu}
      onHide={() => onHide()}
      style={{ width: '50vw' }}
    >
      <Form style={{ margin: '15px' }} ref={formRef} onSubmit={handleCreateRnc}>
        <Tooltip target=".tooltip" showDelay={400} position="top" />
        <Row className="flex flex-wrap">
          <FormAsyncSelect
            className="col-4"
            name="satShipment"
            label="SAT Shipment"
            loadOptions={asyncSelectLoadSatForeignTradeSupplierSummarized}
            getOptionLabel={(option: any) => option.satShipment}
            getOptionValue={(option: any) => option}
            noOptionsMessage={() => 'No shipment found'}
            additional={{ idSat: sat.idSat }}
            required
          />
          <FormInput
            className="col-4"
            name="client"
            label="Client/Notify"
            defaultValue={sat.idClient2?.name ?? ''}
            readOnly
          />
          <FormInput
            className="col-4"
            name="contactClient"
            label="Contact at Client"
          />
          <FormAsyncSelect
            className="col-4"
            name="idPriority"
            label="Priority"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No priority found'}
            onValueChange={e => onPriorityChange(e.idDomain)}
            additional={{ id: 81 }}
            required
          />
          <FormDatePicker
            className="col-4"
            name="deadline"
            label="Deadline"
            readOnly
            value={deadline?.toLocaleDateString()}
          />
          <FormAsyncSelect
            className="col-4"
            name="idRncResponsible"
            label="RNC Responsible"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No responsible found'}
            additional={{ id: 82 }}
            required
          />
          <span
            className="tooltip col-4"
            data-pr-tooltip={`Choose the description that suits your problem:
                              *SELLING PACKAGE - TYPING MISTAKES, WRONG INFORMATION PRINTED, ETC
                              *MASTER BOX - WRONG SHIPPING MARKS.
                              *PRODUCT QUALITY - WRONG COLOR, BAD QUALITY, DEFECTS.
                              *DOCUMENTS - INVOICE, HBL, IMPORT LICENSE, ETC
                              *PAYMENT
                              *MISSING QTY
                              *BROKEN ITEMS
                              *OTHER - NONE OF OTHER OPTIONS"`}
          >
            <FormAsyncSelect
              name="idProblemType"
              label="Problem Type"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={(option: any) => option.description}
              getOptionValue={(option: any) => option.idDomain}
              noOptionsMessage={() => 'No problem type found'}
              additional={{ id: 83 }}
              required
              onValueChange={e =>
                setIsProblemTypeDescriptionRequired(
                  e.idDomain === ProblemType.OTHER,
                )
              }
            />
          </span>
          <FormInput
            className="col-4"
            name="problemTypeDescription"
            label="Problem Type Description"
            required={isProblemTypeDescriptionRequired}
          />
        </Row>
        <Row className="flex flex-wrap">
          <FormAsyncSelect
            className="col-4"
            name="idReWork"
            label="Rework"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            additional={{ id: 87 }}
            required
          />
          <FormAsyncSelect
            className="col-4"
            name="idCost"
            label="RNC Cost"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            additional={{ id: 88 }}
            required
            onValueChange={e => {
              setIsCostResponsibleRequired(e.idDomain !== CostType.NO_COST);
            }}
          />
          <FormAsyncSelect
            className="col-4"
            name="idCostResponsible"
            label="Cost Responsible"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={(option: any) => option.description}
            getOptionValue={(option: any) => option.idDomain}
            noOptionsMessage={() => 'No option found'}
            additional={{ id: 89 }}
            disabled={!isCostResponsibleRequired}
            required={isCostResponsibleRequired}
          />
          <span
            className="tooltip col-12"
            data-pr-tooltip="Explain what happened with a lot of details and make very clear what the is ISSUE. Where did it appear/started, costs involved"
          >
            <FormInput
              name="problemDescription"
              label="Problem Description"
              required
            />
          </span>
          <span
            className="tooltip col-12"
            data-pr-tooltip="Add initially the suggested solution and update the final solution through the process, specifing how it was solve with client, with supplier and financially (credit and debit notes)"
          >
            <FormInput
              name="proposedSolution"
              label="Proposed Solution"
              required
            />
          </span>
        </Row>
        <Buttons>
          <MainButton label="Confirm" icon="pi pi-check" type="submit" />
          <Button
            type="button"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => cancel()}
            className="p-button-text"
          />
        </Buttons>
      </Form>
      {pageLoading && <Loading />}
    </Dialog>
  );
};

export default OpenRncMenu;
