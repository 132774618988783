import { DocumentNode, gql } from '@apollo/client';
import { SatListFinancialPermissions } from './interfaces';

export const listSatListFinancialViewQuery = (
  fieldsPermissions: SatListFinancialPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListFinancialView($data: ListSatListFinancialViewInput!) {
      listSatListFinancialView(data: $data) {
        items
        data {
          idSat
          idSatForeignTrade
          idSatForeignTradeSupplier
          idImporter
          idClient
          idSupplierExporter
          status
          ${fieldsString}
        }
      }
    }
  `;
};

/**
 * Busca os somatorios das colunas da Lista de Financial para adicionar no footer
 */
export const getSumDataFromSatFinancialListQuery = gql`
  query GetSumDataFromSatFinancialList(
    $data: GetSumDataFromSatFinancialListInput!
  ) {
    getSumDataFromSatFinancialList(data: $data) {
      satCount
      purchaseTotalCotPi
      purchaseTotalCotCi
      purchaseTotalConvertedCotPiUsd
      purchaseTotalConvertedCotCiUsd
      purchaseEstimatedTotalAdvance
      purchaseEstimatedTotalBalance
      purchaseTotalOtherExpenses
      purchaseTotalOnMargin
      purchaseTotalOutOfMargin
      paidTotalRolling
      paidTotalBalance
      paidTotalAdvance
      totalPaid
      totalCotRemain
      purchaseDiscountFormSupplier
      purchasePayToSupplier
      sellerTotalSunPi
      sellerTotalSunCi
      sellerTotalConvertedSunPi
      sellerTotalConvertedSunCi
      totalWithApportionment
      sellerEstimatedTotalAdvance
      sellerEstimatedTotalBalance
      sellerTotalOtherExpenses
      sellerTotalOnMargin
      sellerTotalOutOfMargin
      receivedTotalAdvance
      receivedTotalRolling
      receivedTotalBalance
      totalReceived
      totalRemainSun
      sellerTotalCreditToClient
      sellerTotalForApportionment
      estimatedGrossMargin
      estimatedNetMargin
      grossMargin
      netMargin
      grossProfit
      estimatedGrossProfit
      estimatedNetProfit
      netProfit
    }
  }
`;
