import { gql } from '@apollo/client';

export const getRncHistoryInformationsQuery = gql`
  query GetRncHistoryInformations($data: GetRncHistoryInformationsInput!) {
    getRncHistoryInformations(data: $data) {
      rncHistoryItems {
        idSat
        idSatRnc
        description
      }
      rncHistorySuppliers {
        idSat
        idSatRnc
        description
      }
      rncHistoryCategories {
        idSat
        idSatRnc
        description
      }
    }
  }
`;
