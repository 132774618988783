import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: 20px;
  width: 630px;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  #roles-functionality-dropdown {
    position: relative;
  }

  #roles-functionality-dropdown::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    height: 100%;
    width: 1px;
    background-color: var(--grey);
  }
`;
