export const commercialRoles = {
  idEntity: 54,
  idModule: 12,
  fields: {
    status: 1892,
    confirmOrder: 1893,
    satNumber: 1894,
    shipment: 1895,
    satGroup: 2134,
    mainProduct: 1896,
    typeOfOrder: 1897,
    replacementFrom: 1898,
    exporter: 1899,
    importerConsignee: 1900,
    clientNotify: 1901,
    prcLinked: 1902,
    cqLinked: 1903,
    suppliers: 1904,
    clientOrder: 1905,
    clientDepartment: 1906,
    firstBuyer: 1907,
    secondBuyer: 1908,
    shipmentResume: 2141,
    importStatus: 1909,
    artworkStatus: 2140,
    registerStatus: 2191,
    productionStatus: 2192,
    shipmentStatus: 2193,
    supplierPaymentStatus: 2194,
    clientPaymentStatus: 2195,
    ctnrs: 1910,
    portLoading: 1911,
    portDischarge: 1912,
    countryOrigin: 1913,
    sellerTotalSunPi: 1914,
    sellerTotalSunCi: 1915,
    paymentSellingIncoterm: 1916,
    quantitySatPi: 1917,
    quantityShipmentCi: 1918,
    paymentTermAdvance: 1919,
    paymentTermBalance: 1920,
    paymentTermCondition: 1921,
    createdAt: 1922,
    confirmOrderDate: 1923,
    samples: 1924,
    sampleLeadTime: 1925,
    commercialReview: 1926,
    commercialReviewResponsible: 1927,
    artworkDone: 1928,
    productionTime: 1929,
    estimatedInspectionDate: 1930,
    realInspectionDate: 1931,
    estimatedShipmentDate: 1932,
    realEtd: 1933,
    estimatedArrivalDate: 1934,
    realEta: 1935,
    estimatedArrivalClientDate: 1936,
    realArrivalAtClient: 1937,
    rncs: 1938,
    category: 1939,
    firstSalesUser: 1940,
    secondSalesUser: 1941,
    purchaseUser: 1942,
    exportUser: 1943,
    importUser: 1944,
    designUser: 1945,
    financialUser: 1946,
  },
  permissions: {
    idPermissionExportSatsCommercial: 189,
  },
};
