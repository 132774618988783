import React from 'react';
import { ISat } from '../../../interfaces';
import { ForeignTradeSupplierTotalReducer } from '../interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { FinancialFieldsPermissions } from '../../interfaces';

interface ISellerCiProps {
  sat: ISat;
  totalState: ForeignTradeSupplierTotalReducer;
  fieldsPermissions: FinancialFieldsPermissions;
}

const SellerCi: React.FC<ISellerCiProps> = ({
  sat,
  totalState,
  fieldsPermissions,
}) => {
  const satCurrencySymbol = getCurrencySymbol(sat.idCurrency2?.abbreviation);

  const userCanSeeBlock =
    fieldsPermissions.sellerCiCurrency.view ||
    fieldsPermissions.totalSunCiShipment.view ||
    fieldsPermissions.totalWithApportionment.view ||
    fieldsPermissions.totalConvertedSunCi.view;

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Seller CI" />

      {fieldsPermissions.sellerCiCurrency.view && (
        <ReadOnlyInput
          className="col-2"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalSunCiShipment.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total SUN CI"
          prefix={satCurrencySymbol}
          value={totalState.financialTotal?.totalSunCiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          tooltip="Only Product Cost"
          tooltipPosition="bottom"
          loading={totalState.financialTotalLoading}
        />
      )}

      {fieldsPermissions.totalWithApportionment.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total With Apportionment"
          prefix={satCurrencySymbol}
          value={totalState.sellerTotalWithApportionment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={4}
          loading={
            totalState.financialTotalLoading ||
            totalState.sellerOtherExpensesTotalLoading
          }
        />
      )}

      {fieldsPermissions.totalConvertedSunCi.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total Converted SUN CI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={totalState.financialTotal?.totalSunCiShipmentInDollar}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={totalState.financialTotalLoading}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default SellerCi;
