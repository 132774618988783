import { PackageSizeBarcodeFormData } from './PackageSizeBarcode/interfaces';
import { ProductPackageSizeBarcode } from '../../../interfaces';

// eslint-disable-next-line no-shadow
export enum PackageReferenceContentReducerActionKind {
  DELETE_SIZE,
  ADD_SIZE,
  CHANGE_SIZE_GROUP,
}

export interface IPackageReferenceContentRef {
  /**
   * Retorna os dados de Package size barcode se houver alguma alteracao
   * @returns Dados de Package
   */
  getDataIfChanged: () => PackageReferenceContentData | undefined;

  /**
   * Busca quantidade de package size barcodes na referencia
   * @returns Quantidade de package size barcodes na referencia
   */
  getPackageSizeBarcodesCount: () => number;

  /**
   * Define numeros de referencias de size barcodes
   * @param startFrom Numero o qual a referencia iniciara
   * @param stCode ST Code que sera o prefixo da referencia
   */
  setSizeBarcodesReferences: (startFrom: number, stCode: string) => void;

  /**
   * Valida os dados do formulario
   * @returns True, false ou erro
   */
  validateForm: () => Promise<boolean>;
}

export type PackageReferenceFormData = {
  colourEn: string;
  colourPt?: string;
  idGender: number;
  idSizeGroup: number;
  productPackageSizeBarcodes?: number[];
};

export type PackageReferenceContentData = {
  idPackageReference?: number | string;
  sizeBarcodesToCreate?: PackageSizeBarcodeFormData[];
  sizeBarcodesToUpdate?: PackageSizeBarcodeFormData[];
  sizeBarcodesToDelete?: number[];
  packageReferenceContent?: PackageReferenceFormData;
};

export type PackageReferenceContentReducer = {
  productPackageSizeBarcodes?: ProductPackageSizeBarcode[];
  sizesToDelete: number[];
  idSizeGroup?: number;
};

export type Domain = {
  idDomain: number;
  description: string;
};

export type PackageReferenceContentReducerAction = {
  type: PackageReferenceContentReducerActionKind;
  payload?: {
    sizeToDelete?: number | string;
    idPackageReference?: number | string;
    size?: { idDomain: number; description: string };
    idSizeGroup?: number;
  };
};
