import styled from 'styled-components';

export const Container = styled.div`
  .overlay-panel-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    width: 399px;

    h1 {
      font-size: 18px;
    }

    button {
      height: 18px;
      background: none;
      border: none;
      color: #9ba2aa;
      transition: background-color 0.2s;

      &:hover {
        background: var(--grey);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  width: 399px;

  h1 {
    font-size: 18px;
  }

  button {
    height: 18px;
    background: none;
    border: none;
    color: #9ba2aa;
    transition: background-color 0.2s;

    &:hover {
      background: var(--grey);
    }
  }
`;

export const Content = styled.div`
  .p-dropdown {
    display: flex;
    flex-direction: row;
  }

  .inputs {
    max-height: 342px;
    overflow-y: auto;
    input,
    .p-dropdown {
      margin-right: 4px;
    }

    .form-field {
      display: flex;
      flex-direction: column;
    }

    /** Largura do scroll */
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
    }

    /** Firefox  */
    scrollbar-width: thin;
  }

  .form-field + .form-field {
    margin-top: 16px;
  }

  .form-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 13px;
    padding-top: 13px;

    align-items: center;
    border-top: solid 1px #dee2e6;

    span {
      display: flex;

      button + button {
        margin-left: 4px;
      }
    }
  }
`;
