/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  SortOrder,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Skeleton } from 'primereact/skeleton';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';
import ILazyParams from '../../../services/lazyParams';
import { ISatListQualityView, SatListQualityPermissions } from './interfaces';
import {
  getSumDataFromSatQualityListQuery,
  listSatListQualityViewQuery,
} from './queries';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import pagination from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { ICategory } from '../../../interfaces/ICategory';
import { qualityRoles } from '../../../shared/roles/quality';
import { getUTC } from '../../../utils/dateUtil';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { gridColumnsData, groupedColumns } from './constants';
import userHasPermission from '../../../utils/userHasPermission';
import useTitle from '../../../hooks/useTitle';
import { renderNumber } from '../../../utils/formatLocale';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

interface ISatListQualityViewLazyParams
  extends ILazyParams,
    ISatListQualityView {
  [key: string]:
    | number
    | string
    | SortOrder
    | DataTableMultiSortMetaType
    | Date
    | undefined;
}

const pageTitle = 'Quality List';

const ControlQuality: React.FC = () => {
  useTitle(pageTitle);
  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  const gridColumnsName = '@SAT:satControlsQualityGridColumns';

  const lazyParamsName = '@SAT:satControlsQualityLazyParams';

  const advancedFiltersName = '@SAT:satControlsQualityAdvancedFilters';

  const location = useLocation();

  const { showError } = useRefHook();

  const isMounted = useRef(false);

  const gridRef = useRef<GridRef<any>>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.allColumns || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<ISatListQualityViewLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: ISatListQualityViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: ISatListQualityViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const { roles } = useAuth();

  const { idEntity } = qualityRoles;

  const { idPermissionExportSatsQuality } = qualityRoles.permissions;

  // Busca permissoes do usuario para a entity
  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(idPermissionExportSatsQuality, userPermissions),
    };
  }, [idPermissionExportSatsQuality, userPermissions]);

  const fieldsPermissions: SatListQualityPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = qualityRoles;

    const qualityPermissions = {} as SatListQualityPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      qualityPermissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return qualityPermissions;
  }, [userFields]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;

    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [categories, fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const lazyParamsCommonVariables = {
    allColumns: lazyParams.allColumns,
    idSatRnc: lazyParams.idSatRnc,
    idSat: lazyParams.idSat,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    mainProduct: lazyParams.mainProduct,
    idSupplier: lazyParams.idSupplier,
    idImporter: lazyParams.idImporter,
    idClient: lazyParams.idClient,
    contactAtClient: lazyParams.contactAtClient,
    idPriority: lazyParams.idPriority,
    idWaitingFor: lazyParams.idWaitingFor,
    idProblemType: lazyParams.idProblemType,
    idRncResponsible: lazyParams.idRncResponsible,
    coResponsible: lazyParams.coResponsible,
    idCost: lazyParams.idCost,
    idCostResponsible: lazyParams.idCostResponsible,
    idReWork: lazyParams.idReWork,
    clientDebitNoteNumber: lazyParams.clientDebitNoteNumber,
    idClientDebitNoteStatus: lazyParams.idClientDebitNoteStatus,
    clientNf: lazyParams.clientNf,
    realEtd: lazyParams.realEtd,
    idCountryOrigin: lazyParams.idCountryOrigin,
    idImportStatus: lazyParams.idImportStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    idStatus: lazyParams.idStatus,
    shipmentResume: lazyParams.shipmentResume,
    openingDate: lazyParams.openingDate,
    finishingDate: lazyParams.finishingDate,
    category: lazyParams.category
      ? findParentsOnTree(categories, lazyParams.category)
      : undefined,
  };

  const { loading: satListQualityViewLoading, data: satListQualityViewData } =
    useQuery(listSatListQualityViewQuery(fieldsPermissions), {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          fieldsSearch: lazyParamsCommonVariables,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Quality List',
          detail: errorData.message,
        });
      },
    });

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatQualityListQuery,
    {
      variables: {
        data: lazyParamsCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Quality List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  async function exportToXlsx(exportData: any) {
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    exportData.data.forEach((e: any) => {
      columnsToRemove.forEach(column => {
        delete e[column.field];
      });
      delete e.idSat;
      delete e.idSatRnc;
      delete e.idSatForeignTrade;
      delete e.idClient;
      delete e.idImporter;
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.columns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    const worksheet = xlsx.utils.json_to_sheet(exportData.data, {
      header: columnsOrder,
    });

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, 'satListQuality');
  }

  const [
    loadSatListQualityView,
    {
      loading: satListQualityViewExportLoading,
      data: satListQualityViewExportData,
    },
  ] = useLazyQuery(listSatListQualityViewQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satListQualityViewExportData.listSatListQualityView) {
        exportToXlsx(satListQualityViewExportData.listSatListQualityView);
      } else {
        showError({
          summary: 'Error while exporting Quality List',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Quality List to export',
        detail: errorData.message,
      });
    },
  });

  const loadExportData = useCallback(() => {
    loadSatListQualityView({
      variables: {
        data: {
          pagination: {
            _page: 0,
            _limit: 0,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          allColumns: lazyParams.allColumns,
        },
      },
    });
  }, [lazyParams, loadSatListQualityView]);

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.allColumns = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  const parseRncColumn = (rowData: any) => {
    if (rowData.idSat && rowData.idSatRnc) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=rnc&rncId=${rowData.idSatRnc}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.rncNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.rncNumber;
  };

  const parseSatColumn = (rowData: any) => {
    if (rowData.idSat) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satNumber;
  };

  const parseShipmentColumn = (rowData: any) => {
    if (rowData.idSat && rowData.idSatForeignTrade) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade&foreignTradeId=${rowData.idSatForeignTrade}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.shipment;
  };

  const parseSunColumn = (rowData: any) => {
    const sunNumbers: any[] = rowData.sunNumber?.split(', ');
    return sunNumbers?.map(sunNumber => {
      return (
        <Link
          key={rowData.idSupplier}
          to={`/suppliers/list/${parseInt(sunNumber.replace('SUN', ''), 10)}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {sunNumber}
        </Link>
      );
    });
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'deadline':
      case 'openingDate':
      case 'finishingDate':
      case 'realEtd':
        return parseDateColumm;
      case 'rncNumber':
        return parseRncColumn;
      case 'satNumber':
        return parseSatColumn;
      case 'shipment':
        return parseShipmentColumn;
      case 'sunNumber':
        return parseSunColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'shipment':
      case 'problemType':
      case 'totalCostBrl':
      case 'exchangeRate':
        return { width: '150px' };
      case 'mainProduct':
      case 'importerConsignee':
      case 'clientNotify':
      case 'contactAtClient':
        return { width: '200px' };
      case 'problemDescription':
        return { width: '185px' };
      case 'proposedSolution':
      case 'refund':
        return { width: '175px' };
      case 'rncResponsible':
      case 'finalCreditNote':
      case 'finalDebitNote':
      case 'countryOrigin':
        return { width: '165px' };
      case 'coResponsible':
      case 'itemsTotalUsd':
      case 'itemsTotalBrl':
        return { width: '155px' };
      case 'costResponsible':
        return { width: '195px' };
      case 'totalCostFinal':
        return { width: '215px' };
      case 'totalCostCurrencySat':
        return { width: '190px' };
      case 'rncDebitNote':
      case 'rncCreditNote':
        return { width: '500px' };
      case 'clientDebitNoteNumber':
        return { width: '220px' };
      case 'clientDebitNoteStatus':
        return { width: '210px' };
      case 'importStatus':
      case 'shipmentStatus':
        return { width: '160px' };
      case 'openingDate':
      case 'finishingDate':
        return { width: '145px' };
      case 'statusRNC':
        return { width: '125px' };
      case 'category':
        return { width: '185px' };
      default:
        return { width: '140px' };
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;

    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // SATs possuirem a mesma currency
    const currencyAbbreviation =
      satListQualityViewData?.listSatListQualityView?.data[0]?.satCurrency;

    const value = listSumData?.getSumDataFromSatQualityList[field];

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatQualityList.satCount,
          'int',
        );
      case 'rncNumber':
        return renderNumber(
          satListQualityViewData?.listSatListQualityView?.items,
          'int',
        );
      case 'totalCostFinal':
      case 'refund':
      case 'totalCostCurrencySat':
      case 'itemsTotalUsd':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'itemsTotalBrl':
      case 'totalCostBrl':
        return renderNumber(value, 'currency', 'BRL');
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  const history = useHistory();

  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/commercial/sats/${e.data.idSat}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}&tab=rnc&rncId=${e.data.idSatRnc}`,
    );
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    if (localStorageSelectedColumns) {
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(columnsObject);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
        {permissions.export && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={satListQualityViewExportLoading}
            onClick={() => loadExportData()}
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="p-datatable-hoverable-rows"
        name="controlsQuality"
        lazy
        totalRecords={
          !satListQualityViewData ||
          !satListQualityViewData.listSatListQualityView
            ? 0
            : satListQualityViewData.listSatListQualityView.items
        }
        value={
          !satListQualityViewData ||
          !satListQualityViewData.listSatListQualityView
            ? undefined
            : satListQualityViewData.listSatListQualityView.data
        }
        // globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
      >
        {dynamicColumns}
      </Grid>
      {satListQualityViewLoading && <Loading />}
    </div>
  );
};
export default ControlQuality;
