import styled from 'styled-components';

export const Container = styled.div``;

export const SatProcess = styled.div`
  width: 100%;
  margin: 10px 0px;
`;

export const SatProcessNumber = styled.div`
  font-family: Roboto;
  font-weight: 500;
  margin: 8px 0px;
`;

export const Processes = styled.div`
  .error {
    background: #fd2d2d1a;
  }

  .success .tag {
    background-color: var(--success);
    color: var(--white);
  }

  .warn .tag {
    background: var(--yellow);
    color: var(--black);
  }

  .error .tag {
    background: var(--red);
    color: var(--white);
  }

  .info .tag {
    background: var(--blue);
    color: var(--white);
  }
`;

export const Process = styled.div`
  margin: 6px;
  background: #ffffff;
  width: 180px;
  height: 65px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #0000001a;
`;

export const ProcessTitle = styled.div`
  font-family: Roboto;
  font-weight: 500;
`;

export const ProcessTag = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  margin: 5px 0px;
  height: 21px;
  border-radius: 12px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;

  i {
    margin-right: 3px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    max-width: 21ch;
    text-overflow: ellipsis;
  }
`;
