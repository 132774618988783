import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .export-xlsx {
    margin-left: 10px;
    background: #28a745 !important;
    border-color: #28a745 !important;
    color: #ffffff !important;
    transition: background-color 0.2s;

    &:hover {
      background-color: ${shade(0.2, '#28a745')} !important;
    }
  }

  .content-header {
    display: flex;
    justify-content: space-between;
  }
`;

export const Row = styled.div``;

export const TotalBlock = styled.div`
  margin-top: 2rem;
`;
