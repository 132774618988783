import { differenceInDays, sub, format } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const STRING_TIME_PATTERN = /T([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9].000Z/g;

export function dateStringHasTime(date: string): boolean {
  return STRING_TIME_PATTERN.test(date);
}

export function ifStringParseToDate(object: Date): Date {
  if (typeof object === 'string') {
    const dateString = `${object}${
      dateStringHasTime(object) ? '' : 'T00:00:00'
    }`;
    const date = new Date(dateString);
    return date;
  }

  return object;
}

export function subtractDaysFromDate(date: Date, days: number): Date {
  const dateToSubtract = ifStringParseToDate(date);
  return sub(dateToSubtract, { days });
}

export function dateDifferenceInDays(first: Date, second: Date): number {
  return differenceInDays(first, second);
}

export function getUTC(date: Date | string): Date {
  const dateToConvert = new Date(date);
  return new Date(
    dateToConvert.getUTCFullYear(),
    dateToConvert.getUTCMonth(),
    dateToConvert.getUTCDate(),
  );
}

export function convertDateToUTC(date: string): string {
  if (!date) {
    return '';
  }

  const [year, month, day] = date.split('-');
  const formattedDate = new Date(Number(year), Number(month) - 1, Number(day));

  return format(formattedDate, 'dd/MM/yyyy');
}

/**
 * Formata data em string para formato local
 * @param date Data a ser convertida
 * @param locale Local especifico para conversao
 * @returns Data formatada
 */
export function parseToLocaleFormat(date: string, locale?: string): string {
  if (!date || !dayjs(date).isValid() || date === '0000-00-00') {
    return '';
  }

  return dayjs(date).toDate().toLocaleDateString(locale);
}

export function getDifferenceBetweenDatesAtStart(
  startDate?: string | Date,
  endDate?: string | Date,
): number | undefined {
  if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
    return dayjs(startDate)
      .startOf('day')
      .diff(dayjs(endDate).startOf('day'), 'days');
  }
  return undefined;
}

export function parseDateToUTCStartOfDay(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utcOffset(0, true).toISOString();
}

export function parseDateToUTCEndOfDay(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utcOffset(0, true).endOf('day').toISOString();
}

/**
 * Remove uma quantidade de dias de uma data
 * @param days Dias a serem removidos da data
 * @param date Data que tera dias removidos
 * @param dateOnly Se o retorno sera apenas da data, sem horario
 * @returns Data em formato ISO
 */
export function removeDaysFromDate(
  days: number,
  date: Date | string,
  dateOnly?: boolean,
): string | undefined {
  if (!date) return undefined;

  const dateString = dayjs(date).subtract(days, 'days').toISOString();

  if (dateOnly) return dateString.split('T')[0];

  return dateString;
}

/**
 * Adiciona uma quantidade de dias em uma data
 * @param days Dias a serem adicionados na data
 * @param date Data que tera dias adicionados
 * @param dateOnly Se o retorno sera apenas da data, sem horario
 * @returns Data em formato ISO
 */
export function addDaysToDate(
  days: number,
  date: Date | string | undefined,
  dateOnly?: boolean,
): string | undefined {
  if (!date) return undefined;

  const dateString = dayjs(date).add(days, 'days').toISOString();

  if (dateOnly) return dateString.split('T')[0];

  return dateString;
}

/**
 * Valida se a data é anterior
 * @param start_date Data inicial
 * @param end_date Data final
 * @returns True/False
 */
export function compareIfBefore(
  startDate: Date | string,
  endDate: Date | string,
): boolean {
  return dayjs(startDate).isBefore(endDate);
}
