import React, { Dispatch, SetStateAction, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';
import { Skeleton } from 'primereact/skeleton';
import Button from '../Button';
import { UserNotification } from './interfaces';
import {
  listAllNotificationsQuery,
  markAllNotificationsAsReadQuery,
  markNotificationAsReadQuery,
  updateNotificationIsReadValueQuery,
} from './queries';
import RoundUserAvatar from '../RoundUserAvatar';
import { convertDate } from '../../utils/convertDate';
import { satsRoles } from '../../shared/roles/sat';
import { clientQuotationRoles } from '../../shared/roles/clientQuotation';
import { prcRoles } from '../../shared/roles/prc';
import Empty from '../Empty';
import {
  financialIdModule,
  isFinancialModuleAllowed,
} from '../../shared/roles/financial';
import { useAuth } from '../../hooks/useAuth';

interface INotificationProps {
  setQtyNotification: Dispatch<SetStateAction<number>>;
}

const Notifications: React.FC<INotificationProps> = ({
  setQtyNotification,
}) => {
  const { idUser } = useAuth();

  const financialModuleAllowed = isFinancialModuleAllowed(idUser);

  const [markNotificationAsReadMutation] = useMutation(
    markNotificationAsReadQuery,
  );
  const [updateNotificationIsReadValueMutation] = useMutation(
    updateNotificationIsReadValueQuery,
  );
  const [markAllNotificationsAsReadMutation] = useMutation(
    markAllNotificationsAsReadQuery,
  );

  const [notifications, setNotifications] = useState<UserNotification[]>([]);

  const { loading: notificationsLoading } = useQuery(
    listAllNotificationsQuery,
    {
      onCompleted: response => {
        setNotifications(response.listAllNotifications);
      },
    },
  );

  function removeHtmlAndMentionCharactersFromNotification(content: string) {
    const removeHtmlRegex = /(<([^>]+)>)/gi;
    const removeUserMentionsRegex = /@\(\d+\)/gi;
    return content
      .replace(removeHtmlRegex, '')
      .replace(removeUserMentionsRegex, '');
  }

  const openNewTab = (
    idComment: number,
    idModule: number,
    idRecord: number,
    idSatRnc?: number,
    idSatForeignTrade?: number,
    idParentComment?: number,
  ) => {
    const commentsIdsArray: string[] = [];

    if (idParentComment) {
      commentsIdsArray.push(`idComment=${idParentComment}`);
      commentsIdsArray.push(`idChildComment=${idComment}`);
    } else {
      commentsIdsArray.push(`idComment=${idComment}`);
    }

    const commentsParams = commentsIdsArray.join('&');

    switch (idModule) {
      case satsRoles.idModule:
        if (idSatRnc) {
          window.open(
            `/commercial/sats/${idRecord}?tab=rnc&rncId=${idSatRnc}`,
            '_blank',
          );
        } else if (idSatForeignTrade) {
          window.open(
            `/commercial/sats/${idRecord}?tab=updates&foreignTradeId=${idSatForeignTrade}&${commentsParams}`,
            '_blank',
          );
        } else {
          window.open(
            `/commercial/sats/${idRecord}?tab=updates&${commentsParams}`,
            '_blank',
          );
        }
        break;
      case clientQuotationRoles.idModule:
        window.open(
          `/client-quotations/list/${idRecord}?tab=updates`,
          '_blank',
        );
        break;
      case prcRoles.idModule:
        window.open(`/prcs/list/${idRecord}?tab=updates`, '_blank');
        break;
      case financialIdModule:
        if (financialModuleAllowed) {
          window.open(
            `/financial/transactions/${idRecord}?tab=updates&${commentsParams}`,
            '_blank',
          );
        } else {
          window.open(
            `/financial/transactionBeneficiaryAcessPage/${idRecord}?tab=updates&${commentsParams}`,
            '_blank',
          );
        }
        break;
      default:
        break;
    }
  };

  function changeNotificationReadValueInState(
    idNotification: number,
    isRead: boolean,
  ) {
    const newNotifications = notifications.map(item =>
      item.idNotification === idNotification ? { ...item, isRead } : item,
    );
    setNotifications(newNotifications);
  }

  function handleNotificationUnredIndicationClick(
    notification: UserNotification,
  ) {
    const newIsReadValue = !notification.isRead;

    updateNotificationIsReadValueMutation({
      variables: {
        idNotification: notification.idNotification,
        isRead: newIsReadValue,
      },
    });

    changeNotificationReadValueInState(
      notification.idNotification,
      newIsReadValue,
    );

    setQtyNotification(prev => (newIsReadValue ? prev - 1 : prev + 1));
  }

  function handleNotificationClick(notification: UserNotification) {
    if (!notification.isRead) {
      markNotificationAsReadMutation({
        variables: {
          idNotification: notification.idNotification,
        },
      });

      changeNotificationReadValueInState(notification.idNotification, true);

      setQtyNotification(prev => prev - 1);
    }

    openNewTab(
      notification.idComment,
      notification.idModule2.idModule,
      notification.idRecord,
      notification.idSatRnc,
      notification.idSatForeignTrade,
      notification.idComment2.idParentComment,
    );
  }

  function handleMarkAllAsReadClick() {
    const newNotifications = notifications.map(item => ({
      ...item,
      isRead: true,
    }));
    setNotifications(newNotifications);

    markAllNotificationsAsReadMutation({
      variables: {
        idUser: 0,
      },
    });

    setQtyNotification(0);
  }

  function renderNotificationItemSkeleton() {
    return (
      <div className="flex p-1 gap-2 align-items-center h-4rem">
        <Skeleton shape="circle" width="2rem" height="2rem" />
        <div>
          <Skeleton width="12rem" className="mb-2" />

          <Skeleton width="24rem" className="mb-2" />

          <Skeleton width="5rem" height="0.5rem" />
        </div>

        <Skeleton shape="circle" width="1rem" height="1rem" />
      </div>
    );
  }

  function renderNotificationItem(notification: UserNotification) {
    return (
      <div className="flex p-1 gap-2 align-items-center h-4rem hover:bg-blue-50">
        <RoundUserAvatar
          className="ml-1"
          image={notification.createdBy2.avatarUrl ?? ''}
          userFullName={`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}
          imageAlt={`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}
        />
        <div
          className="cursor-pointer"
          role="button"
          tabIndex={0}
          onClick={() => handleNotificationClick(notification)}
          onKeyDown={() => handleNotificationClick(notification)}
        >
          <div className="font-semibold white-space-nowrap text-overflow-ellipsis overflow-hidden w-24rem">
            <span>{`${notification.createdBy2.firstName} ${notification.createdBy2.lastName}`}</span>
            <span>&nbsp;-&nbsp;</span>
            <span className="text-blue-500">{notification.recordTitle}</span>
          </div>
          <div className="white-space-nowrap text-overflow-ellipsis overflow-hidden w-24rem">
            {removeHtmlAndMentionCharactersFromNotification(
              notification.idComment2.message,
            )}
          </div>
          <small>Added:&nbsp;{convertDate(notification.createdAt)}</small>
        </div>
        <div
          role="button"
          className="cursor-pointer"
          tabIndex={0}
          title={`Mark as ${notification.isRead ? 'unread' : 'read'}`}
          onClick={() => handleNotificationUnredIndicationClick(notification)}
          onKeyDown={() => handleNotificationUnredIndicationClick(notification)}
        >
          <Badge
            className={`w-1rem h-1rem ${
              notification.isRead
                ? 'surface-200 hover:surface-300'
                : 'bg-blue-400 hover:bg-blue-300'
            }`}
          />
        </div>
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className="flex justify-content-between align-items-center">
        <p className="text-xl font-medium">Notifications</p>
        <Button
          label="Mark all as read"
          onClick={() => handleMarkAllAsReadClick()}
          disabled={notifications.every(item => item.isRead)}
        />
      </div>
    );
  }

  return (
    <div className="w-30rem">
      {renderHeader()}
      <Divider />
      <ul
        className="h-30rem overflow-y-auto overflow-x-hidden list-none p-0"
        style={{ scrollbarWidth: 'thin' }}
      >
        {!notificationsLoading && !notifications.length && (
          <Empty message="You currently have no notifications" />
        )}
        {!notificationsLoading &&
          notifications.map(notification => (
            <li key={notification.idNotification} className="mb-2">
              {renderNotificationItem(notification)}
            </li>
          ))}
        {notificationsLoading &&
          [...Array(6)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className="mb-2">
              {renderNotificationItemSkeleton()}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Notifications;
