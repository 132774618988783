import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const confirmOrderOptions = [
  { label: 'ON NEGOTIATION', value: 'On Negotiation' },
  { label: 'CONFIRMED', value: 'Confirmed' },
  { label: 'CONFIRMED COMMERCIAL', value: 'Confirmed Commercial' },
];

export const gridColumnsData: IColumnData = {
  status: {
    field: 'status',
    header: 'Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  confirmOrder: {
    field: 'confirmOrder',
    header: 'Confirm Order',
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'registerStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'productionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'shipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  typeOfOrder: {
    field: 'typeOfOrder',
    header: 'Type of Order',
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    advancedFilterField: '',
  },
  newLayout: {
    field: 'newLayout',
    header: 'New Layout',
    advancedFilterField: 'newLayout',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  activities: {
    field: 'activities',
    header: 'Activities',
    advancedFilterField: '',
  },
  artworkQuantity: {
    field: 'artworkQuantity',
    header: 'Artwork Quantity',
    advancedFilterField: '',
  },
  designLevel: {
    field: 'designLevel',
    header: 'Design Level',
    advancedFilterField: 'idDesignLevel',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DESIGN_LEVEL,
    },
  },
  dueDate: {
    field: 'dueDate',
    header: 'Artwork Due Date',
    advancedFilterField: 'dueDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  satGroup: {
    field: 'satGroup',
    header: 'SAT Group',
    advancedFilterField: '',
  },
  samples: {
    field: 'samples',
    header: 'Samples',
    advancedFilterField: 'samples',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  artworkStartDate: {
    field: 'artworkStartDate',
    header: 'Artwork Start Date',
    advancedFilterField: 'artworkStartDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  internalInfo: {
    field: 'internalInfo',
    header: 'Internal Info',
    advancedFilterField: '',
  },
  clientInfo: {
    field: 'clientInfo',
    header: 'Client Info',
    advancedFilterField: '',
  },
  hrp: {
    field: 'hrp',
    header: 'HRP',
    advancedFilterField: '',
  },
  supportResponsible: {
    field: 'supportResponsible',
    header: 'Support Responsible',
    advancedFilterField: 'idSupportResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  startDoing: {
    field: 'startDoing',
    header: 'Start Doing',
    advancedFilterField: '',
  },
  sentLayout: {
    field: 'sentLayout',
    header: 'Sent Layout',
    advancedFilterField: '',
  },
  layoutApproved: {
    field: 'layoutApproved',
    header: 'Layout Approved',
    advancedFilterField: '',
  },
  designResponsible: {
    field: 'designResponsible',
    header: 'Design Responsible',
    advancedFilterField: 'idDesignResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  designReview: {
    field: 'designReview',
    header: 'Design Review',
    advancedFilterField: '',
  },
  reviewResponsible: {
    field: 'reviewResponsible',
    header: 'Review Responsible',
    advancedFilterField: 'idReviewResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  commercialReview: {
    field: 'commercialReview',
    header: 'Commercial Review',
    advancedFilterField: '',
  },
  commercialReviewResponsible: {
    field: 'commercialReviewResponsible',
    header: 'Commercial Review Responsible',
    advancedFilterField: 'idCommercialReviewResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  sentToClient: {
    field: 'sentToClient',
    header: 'Sent To Client',
    advancedFilterField: '',
  },
  approvedByClient: {
    field: 'approvedByClient',
    header: 'Approved By Client',
    advancedFilterField: '',
  },
  commercialResponsible: {
    field: 'commercialResponsible',
    header: 'Commercial Responsible',
    advancedFilterField: 'idCommercialResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  licensorApproval: {
    field: 'licensorApproval',
    header: 'Licensor Approval',
    advancedFilterField: '',
  },
  sentFinalArtWork: {
    field: 'sentFinalArtWork',
    header: 'Sent Final Artwork',
    advancedFilterField: '',
  },
  finalArtWorkResponsible: {
    field: 'finalArtWorkResponsible',
    header: 'Final Artwork Responsible',
    advancedFilterField: 'idFinalArtWorkResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  sketchesReceived: {
    field: 'sketchesReceived',
    header: 'Sketches Received',
    advancedFilterField: '',
  },
  sketchesResponsible: {
    field: 'sketchesResponsible',
    header: 'Sketches Responsible',
    advancedFilterField: 'idSketchesResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  artworkDone: {
    field: 'artworkDone',
    header: 'Artwork Done',
    advancedFilterField: 'artworkDone',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  timingHrp: {
    field: 'timingHrp',
    header: 'Timing Hrp',
    advancedFilterField: '',
  },
  timingStart: {
    field: 'timingStart',
    header: 'Timing Start',
    advancedFilterField: '',
  },
  timingLayout: {
    field: 'timingLayout',
    header: 'Timing Layout',
    advancedFilterField: '',
  },
  timingDoing: {
    field: 'timingDoing',
    header: 'Timing Doing',
    advancedFilterField: '',
  },
  timingApproval: {
    field: 'timingApproval',
    header: 'Timing Approval',
    advancedFilterField: '',
  },
  timingSketchesReplay: {
    field: 'timingSketchesReplay',
    header: 'Timing Sketches Replay',
    advancedFilterField: '',
  },
  timingSketchesApproval: {
    field: 'timingSketchesApproval',
    header: 'Timing Sketches Approval',
    advancedFilterField: '',
  },
  timingTotalArtwork: {
    field: 'timingTotalArtwork',
    header: 'Timing Total Artwork',
    advancedFilterField: '',
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },
  packagesNotes: {
    field: 'packagesNotes',
    header: 'Packages Notes',
    advancedFilterField: '',
  },
  remarks: {
    field: 'remarks',
    header: 'Remarks',
    advancedFilterField: '',
  },
  designNotes: {
    field: 'designNotes',
    header: 'Design Notes',
    advancedFilterField: 'designNotes',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.status.field,
          gridColumnsData.confirmOrder.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.activities.field,
          gridColumnsData.artworkQuantity.field,
          gridColumnsData.designLevel.field,
          gridColumnsData.dueDate.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.satGroup.field,
          gridColumnsData.samples.field,
          gridColumnsData.artworkStartDate.field,
          gridColumnsData.artworkDone.field,
          gridColumnsData.timingHrp.field,
          gridColumnsData.timingStart.field,
          gridColumnsData.timingLayout.field,
          gridColumnsData.timingDoing.field,
          gridColumnsData.timingApproval.field,
          gridColumnsData.timingSketchesReplay.field,
          gridColumnsData.timingSketchesApproval.field,
          gridColumnsData.timingTotalArtwork.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
