import styled from 'styled-components';

export const Container = styled.div`
  .fileNameSpan {
    width: 100%;
  }

  input {
    width: 100%;
  }
`;
