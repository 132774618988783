import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  prcCode: {
    field: 'prcCode',
    header: 'PRC Code',
    advancedFilterField: 'prcCode',
  },
  prcStatus: {
    field: 'idStatus2.description',
    header: 'Status',
    advancedFilterField: 'status',
  },
  prcType: {
    field: 'idPrcType2.description',
    header: 'PRC Type',
    advancedFilterField: 'prcType',
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product/Collection',
    advancedFilterField: 'mainProduct',
  },
  createdAt: {
    field: 'createdAt',
    header: 'Start Date',
    advancedFilterField: 'createdAt',
    type: AdvancedFiltersFieldType.DATE,
  },
  finishDate: {
    field: 'finishDate',
    header: 'Finish Date',
    advancedFilterField: 'finishDate',
    type: AdvancedFiltersFieldType.DATE,
  },
  responsibleUser: {
    field: 'idResponsibleUser2.firstName',
    header: 'Current User',
    advancedFilterField: 'responsibleUser',
  },
  priority: {
    field: 'idPriority2.description',
    header: 'Priority',
    advancedFilterField: 'priority',
  },
  Deadline: {
    field: 'dtDeadline',
    header: 'Deadline',
    advancedFilterField: 'dtDeadline',
    type: AdvancedFiltersFieldType.DATE,
  },
  category: {
    field: 'idCategory2.name',
    header: 'Category',
    advancedFilterField: 'category',
  },
  client: {
    field: 'idClient2.name',
    header: 'Client',
    advancedFilterField: 'client',
  },
  satOffice: {
    field: 'idOffice2.officeName',
    header: 'SAT Office',
    advancedFilterField: 'satOffice',
  },
  indLicensed: {
    field: 'indLicensed',
    header: 'Licensed',
    advancedFilterField: 'indLicensed',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  partnerLicensor: {
    field: 'idPartnerLicensor2.name',
    header: 'Licensor',
    advancedFilterField: 'partnerLicensor',
  },
  notes: { field: 'notes', header: 'Notes', advancedFilterField: 'notes' },
  createdBy: {
    field: 'createdBy2.firstName',
    header: 'Created By',
    advancedFilterField: 'createdBy',
  },
  approvedBy: {
    field: 'approvedBy2.firstName',
    header: 'Approved By',
    advancedFilterField: 'approvedBy',
  },
};
