import {
  PrcFileLogReducer,
  PrcFileLogReducerAction,
  PrcFileLogReducerActionKind,
} from './interfaces';

export const prcFileLogInitialState: PrcFileLogReducer = {
  displayDialog: false,
  data: [],
};

export const prcFileLogReducer = (
  state: PrcFileLogReducer,
  action: PrcFileLogReducerAction,
): PrcFileLogReducer => {
  switch (action.type) {
    case PrcFileLogReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        data: [],
        header: undefined,
      };

    case PrcFileLogReducerActionKind.OPEN_DIALOG: {
      if (!action.payload) {
        return { ...state, displayDialog: true };
      }

      return {
        ...state,
        displayDialog: true,
        data: action.payload.data,
        header: action.payload.header,
      };
    }

    default:
      throw new Error('Invalid PRC File Log reducer action');
  }
};
