import { gql } from '@apollo/client';

export const listAvailableSatItemsToForeignTradeQuery = gql`
  query ListAvailableSatItemsToForeignTrade(
    $idSat: Int!
    $idSatForeignTrade: Int
  ) {
    listAvailableSatItemsToForeignTrade(
      idSat: $idSat
      idSatForeignTrade: $idSatForeignTrade
    ) {
      idSatItem
      stCode
      name
      piecesContainer
      idImage2 {
        url
      }
      idProduct
      remainQuantity
      m2Pc
      cbm
      piecesContainer
      satItemReferences {
        idSatItemReference
        satReferenceSizeBarcodes {
          idSatItemReference
          idSatReferenceSizeBarcode
          reference
          remainQuantityPiShipment
        }
      }
    }
  }
`;
