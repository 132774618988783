import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';

import { FiDownload } from 'react-icons/fi';
import { useRefHook } from '../../../../../hooks/useRefHook';
import FormRow from '../../../../../components/FormRow';
import Button from '../../../../../components/Button';
import { ConfirmOrderFilesType } from '../../../../../shared/enums/domains';
import { listSatConfirmOrderFilesQuery } from './queries';

interface IConfirmOrderFilesProps {
  idSat: number;
  isConfirmedPurchase?: boolean;
  userCanSeeSunPi?: boolean;
  userCanSeeCotPi?: boolean;
}

const ConfirmOrderFiles: React.FC<IConfirmOrderFilesProps> = ({
  idSat,
  isConfirmedPurchase,
  userCanSeeSunPi,
  userCanSeeCotPi,
}) => {
  const { showError } = useRefHook();

  const [sunPiLoading, setSunPiLoading] = useState<boolean>(false);
  const [cotPiLoading, setCotPiLoading] = useState<boolean>(false);

  const confirmOrderFilesLazyParams = {
    idSat,
    _orderBy: 'idAttachment2.createdAt',
    _sortOrder: 'DESC',
  };

  const [getConfirmOrderFile] = useLazyQuery(listSatConfirmOrderFilesQuery, {
    onCompleted: response => {
      window.open(
        response.listSatConfirmOrderFilesBySatId[0].idAttachment2.url,
        '_blank',
      );
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Confirm Order files',
        detail: errorData.message,
      });
    },
  });

  async function handleDownloadFile(fileType: number) {
    switch (fileType) {
      case ConfirmOrderFilesType.SUN_PI:
        setSunPiLoading(true);
        await getConfirmOrderFile({
          variables: {
            data: {
              idTypes: [fileType],
              ...confirmOrderFilesLazyParams,
            },
          },
        });
        setSunPiLoading(false);
        break;
      case ConfirmOrderFilesType.COT_PI:
        setCotPiLoading(true);
        await getConfirmOrderFile({
          variables: {
            data: {
              idTypes: [fileType],
              ...confirmOrderFilesLazyParams,
            },
          },
        });
        setCotPiLoading(false);
        break;
      default:
        break;
    }
  }

  return (
    <div id="sat-confirm-order-files">
      <h2>Confirm Order Files</h2>

      <FormRow>
        {userCanSeeSunPi && (
          <Button
            type="button"
            onClick={() => handleDownloadFile(ConfirmOrderFilesType.SUN_PI)}
            label="SUN PI"
            icon={<FiDownload size={17} />}
            loading={sunPiLoading}
          />
        )}

        {isConfirmedPurchase && userCanSeeCotPi && (
          <Button
            type="button"
            onClick={() => handleDownloadFile(ConfirmOrderFilesType.COT_PI)}
            label="COT PI"
            icon={<FiDownload size={17} />}
            loading={cotPiLoading}
          />
        )}
      </FormRow>
    </div>
  );
};

export default ConfirmOrderFiles;
