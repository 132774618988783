import { Form } from '@unform/web';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import FormInput from '../../../../components/FormInput';
import FormTitle from '../../../../components/FormTitle';
import { useRefHook } from '../../../../hooks/useRefHook';
import { TypeOfPackage } from '../../../../shared/enums/domains';
import { IProductPackage } from '../interfaces';

import imagePlaceholder from '../../../../assets/imagePlaceholder.svg';

import {
  OneSizePerBoxBarcodes,
  AssortmentSizesLayout,
  Container,
  OneSizePerBoxLayout,
  ReferenceContent,
  ReferenceStringData,
  OneSizePerBoxContent,
} from './styles';
import { showField } from '../../../../utils/showField';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { productRoles } from '../../../../shared/roles/product';
import ToastLife from '../../../../shared/enums/toastLife';
import Empty from '../../../../components/Empty';

/**
 * Interface de Reference Sizes
 */
interface IReferenceSizesProps {
  /**
   * Fields do usuario
   */
  userFields: IRoleEntityField[];

  /**
   * ID do produto a ser expandido
   */
  idProduct: number;

  /**
   * Se a linha esta expandida
   */
  isExpanded: boolean;
}

const ReferenceSizes: React.FC<IReferenceSizesProps> = ({
  idProduct,
  userFields,
  isExpanded,
}) => {
  // Referencia ao toast e formulario
  const { formRef, toastRef } = useRefHook();

  const [productPackage, serProductPackage] = useState<IProductPackage>();

  const listReferenceSizeQuery = gql`
    query ListProductPackageReferenceSizesByProductId($idProduct: Int!) {
      listProductPackageReferenceSizesByProductId(idProduct: $idProduct) {
        idProductPackage
            ${
              showField(productRoles.fields.idFieldTypePackage, userFields)
                ? 'idTypePackage'
                : ''
            }
            productPackageReferences {
              idPackageReference
              ${
                showField(
                  productRoles.fields.idFieldProductPackageReferencesColourEn,
                  userFields,
                )
                  ? 'colourEn'
                  : ''
              }
              ${
                showField(
                  productRoles.fields.idFieldProductPackageReferencesColourPt,
                  userFields,
                )
                  ? 'colourPt'
                  : ''
              }
              ${
                showField(
                  productRoles.fields.idFieldProductPackageReferencesIdGender,
                  userFields,
                )
                  ? `idGender2 {
                        description
                      }`
                  : ''
              }
              ${
                showField(
                  productRoles.fields
                    .idFieldProductPackageReferencesIdSizeGroup,
                  userFields,
                )
                  ? `idSizeGroup2 {
                        description
                      }`
                  : ''
              }
              ${
                showField(
                  productRoles.fields
                    .idFieldProductPackageReferencesMasterBarcode,
                  userFields,
                )
                  ? 'masterBarcode'
                  : ''
              }
              idAttachment2 {
                idAttachment
                url
              }
              productPackageSizeBarcodes {
                idPackageSizeBarcode
                ${
                  showField(
                    productRoles.fields.idFieldProductPackageSizeBarcodesSize,
                    userFields,
                  )
                    ? `size2 {
                        description
                      }`
                    : ''
                }

                ${
                  showField(
                    productRoles.fields.idFieldProductPackageSizeBarcodesUnit,
                    userFields,
                  )
                    ? `unit2 {
                        description
                      }
                      unitInnerBox
                      unitMasterBox`
                    : ''
                }
                ${
                  showField(
                    productRoles.fields
                      .idFieldProductPackageSizeBarcodesReference,
                    userFields,
                  )
                    ? 'reference'
                    : ''
                }
                ${
                  showField(
                    productRoles.fields.idFieldProductPackageSizeBarcodesSize,
                    userFields,
                  )
                    ? 'sizeRange'
                    : ''
                }
                ${
                  showField(
                    productRoles.fields
                      .idFieldProductPackageSizeBarcodesSellingPackage,
                    userFields,
                  )
                    ? 'sellingPackage'
                    : ''
                }
                ${
                  showField(
                    productRoles.fields
                      .idFieldProductPackageSizeBarcodesInnerBox,
                    userFields,
                  )
                    ? 'innerBox'
                    : ''
                }
                ${
                  showField(
                    productRoles.fields
                      .idFieldProductPackageSizeBarcodesMasterBox,
                    userFields,
                  )
                    ? 'masterBox'
                    : ''
                }
              }
            }
   }
  }
  `;

  const [loadProductPackageData, { loading: productPackageLoading }] =
    useLazyQuery(listReferenceSizeQuery, {
      variables: {
        idProduct,
      },
      onCompleted: response => {
        if (response.listProductPackageReferenceSizesByProductId) {
          serProductPackage(
            response.listProductPackageReferenceSizesByProductId,
          );
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Reference x Sizes Data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  useEffect(() => {
    if (isExpanded && !productPackage) {
      loadProductPackageData();
    }
  }, [isExpanded, productPackage, loadProductPackageData]);

  return (
    <Container>
      <FormTitle name="Reference x Sizes" />
      <Form
        ref={formRef}
        onSubmit={() => {
          ('');
        }}
      >
        {productPackage &&
          productPackage.productPackageReferences.map(reference => {
            return (
              <ReferenceContent key={reference.idPackageReference}>
                <ReferenceStringData>
                  {showField(
                    productRoles.fields.idFieldProductPackageReferencesColourEn,
                    userFields,
                  ) && (
                    <FormInput
                      name="colourEn"
                      label="Color (EN)"
                      value={reference.colourEn}
                      readOnly
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldProductPackageReferencesColourPt,
                    userFields,
                  ) && (
                    <FormInput
                      name="colourPt"
                      label="Color (PT)"
                      value={reference.colourPt}
                      readOnly
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldProductPackageReferencesIdGender,
                    userFields,
                  ) && (
                    <FormInput
                      name="idGender"
                      label="Gender (EN)"
                      value={reference.idGender2?.description}
                      readOnly
                    />
                  )}

                  {showField(
                    productRoles.fields
                      .idFieldProductPackageReferencesIdSizeGroup,
                    userFields,
                  ) && (
                    <FormInput
                      name="idSizeGroup"
                      label="Size Group (EN)"
                      value={reference.idSizeGroup2?.description}
                      readOnly
                    />
                  )}
                </ReferenceStringData>
                {productPackage.idTypePackage ===
                TypeOfPackage.ASSORTMENT_SIZES ? (
                  <AssortmentSizesLayout>
                    {showField(
                      productRoles.fields
                        .idFieldProductPackageReferencesMasterBarcode,
                      userFields,
                    ) && (
                      <FormInput
                        name="masterBarcode"
                        label="Master Barcode"
                        value={reference.masterBarcode}
                        readOnly
                      />
                    )}

                    <DataTable value={reference.productPackageSizeBarcodes}>
                      {showField(
                        productRoles.fields
                          .idFieldProductPackageSizeBarcodesUnit,
                        userFields,
                      ) && (
                        <Column
                          field="unit2.description"
                          style={{ width: '4rem' }}
                          header="Unit"
                        />
                      )}

                      {showField(
                        productRoles.fields
                          .idFieldProductPackageSizeBarcodesSize,
                        userFields,
                      ) && (
                        <Column
                          field="size2.description"
                          header="Size"
                          style={{ width: '4rem' }}
                        />
                      )}

                      {showField(
                        productRoles.fields
                          .idFieldProductPackageSizeBarcodesReference,
                        userFields,
                      ) && (
                        <Column
                          field="reference"
                          header="Color/Size Ref"
                          style={{ width: '8rem' }}
                        />
                      )}

                      {showField(
                        productRoles.fields
                          .idFieldProductPackageSizeBarcodesSizeRange,
                        userFields,
                      ) && (
                        <Column
                          field="sizeRange"
                          header="Size Range"
                          style={{ width: '5rem' }}
                        />
                      )}

                      {showField(
                        productRoles.fields
                          .idFieldProductPackageSizeBarcodesSellingPackage,
                        userFields,
                      ) && (
                        <Column
                          field="sellingPackage"
                          header="Selling Package (EAN-13)"
                          style={{ width: '10rem' }}
                        />
                      )}
                    </DataTable>
                    {showField(
                      productRoles.fields
                        .idFieldProductPackageReferencesAttachment,
                      userFields,
                    ) && (
                      <img
                        src={`${reference.idAttachment2?.url}`}
                        onError={e => {
                          e.currentTarget.src = imagePlaceholder;
                        }}
                        alt={reference.masterBarcode}
                      />
                    )}
                  </AssortmentSizesLayout>
                ) : (
                  <OneSizePerBoxLayout>
                    <div>
                      {reference.productPackageSizeBarcodes?.map(barcode => {
                        return (
                          <OneSizePerBoxContent
                            key={barcode.idPackageSizeBarcode}
                          >
                            {showField(
                              productRoles.fields
                                .idFieldProductPackageSizeBarcodesSize,
                              userFields,
                            ) && (
                              <FormInput
                                className="size"
                                name="size"
                                label="Size"
                                value={barcode.size2?.description}
                                readOnly
                              />
                            )}

                            {showField(
                              productRoles.fields
                                .idFieldProductPackageSizeBarcodesReference,
                              userFields,
                            ) && (
                              <FormInput
                                name="reference"
                                label="Color/Size Ref"
                                value={barcode.reference}
                                readOnly
                              />
                            )}

                            <OneSizePerBoxBarcodes>
                              <div>
                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesUnit,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="unit"
                                    name="sellingPackageUnit"
                                    label="Unit"
                                    value={barcode.unit2?.description}
                                    readOnly
                                  />
                                )}

                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesSellingPackage,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="barcode"
                                    name="sellingPackage"
                                    label="Selling Package (EAN-13)"
                                    value={barcode.sellingPackage?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                              <div>
                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesUnit,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="unit"
                                    name="unitInnerBox"
                                    label="Unit"
                                    value={barcode.unitInnerBox}
                                    readOnly
                                  />
                                )}

                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesInnerBox,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="barcode"
                                    name="innerBox"
                                    label="Inner Box (DUN-14)"
                                    value={barcode.innerBox?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                              <div>
                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesUnit,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="unit"
                                    name="unitMasterBox"
                                    label="Unit"
                                    value={barcode.unitMasterBox}
                                    readOnly
                                  />
                                )}

                                {showField(
                                  productRoles.fields
                                    .idFieldProductPackageSizeBarcodesMasterBox,
                                  userFields,
                                ) && (
                                  <FormInput
                                    className="barcode"
                                    name="masterBox"
                                    label="Master Box (DUN-14)"
                                    value={barcode.masterBox?.toString()}
                                    readOnly
                                  />
                                )}
                              </div>
                            </OneSizePerBoxBarcodes>
                          </OneSizePerBoxContent>
                        );
                      })}
                    </div>
                    {showField(
                      productRoles.fields
                        .idFieldProductPackageReferencesAttachment,
                      userFields,
                    ) && (
                      <img
                        src={`${reference.idAttachment2?.url}`}
                        onError={e => {
                          e.currentTarget.src = imagePlaceholder;
                        }}
                        alt={reference.masterBarcode}
                      />
                    )}
                  </OneSizePerBoxLayout>
                )}
              </ReferenceContent>
            );
          })}
        {!productPackage && !productPackageLoading && (
          <Empty
            message="There is no Reference x Sizes data to show"
            position="left"
          />
        )}
        {productPackageLoading && (
          <ReferenceContent>
            <ReferenceStringData>
              <Skeleton width="100%" height="3rem" />
              <Skeleton width="100%" height="3rem" />
              <Skeleton width="100%" height="3rem" />
              <Skeleton width="100%" height="3rem" />
            </ReferenceStringData>
            <AssortmentSizesLayout>
              <Skeleton width="100%" height="8rem" />
              <Skeleton width="100%" height="8rem" />
            </AssortmentSizesLayout>
          </ReferenceContent>
        )}
      </Form>
    </Container>
  );
};

export default ReferenceSizes;
