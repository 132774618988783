import { ApolloClient, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { decode, sign } from 'jsonwebtoken';
import authConfig from '../../config/auth';
import cache from './cache';
import localStorageKeys from '../../config/localStorageKeys';

/**
 * Tempo de antecedencia para renovar o token de acesso
 */
const REFRESH_TOKEN_LEGROOM = 1 * 60;

/**
 * Promise que eh chamada quando o token de acesso esta sendo renovado
 */
let pendingAccessTokenPromise: any;

const refreshTokenQuery = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      refresh_token
    }
  }
`;

const listUserRolesQuery = gql`
  query ListUserRoles {
    listAuthenticatedUserRoles {
      modules
      entities
      rolesUser {
        idRoleUser
        idRole2 {
          idRole
          name
          roleEntities {
            idRoleEntity
            idEntity2 {
              idEntity
              name
              menuIcon
              menuName
              active
            }
            roleEntityFields {
              idRoleEntityField
              idField
              view
              create
              edit
            }
            roleEntityPermissions {
              idRoleEntityPermission
              idRoleEntity
              idPermission
            }
          }
        }
      }
    }
  }
`;

const renewTokenApiClient = new ApolloClient({
  link: createHttpLink({
    uri: `${process.env.REACT_APP_SAT_API_URL}/graphql`,
  }),
  cache,
  credentials: 'include',
});

/**
 * Funcao que verifica se o token de acesso esta valido e se precisa ser renovado
 * @param token token de acesso
 * @returns objeto com o estado do token
 */
function getTokenState(token?: string | null) {
  if (!token) {
    return { valid: false, needRefresh: true };
  }

  const decoded = decode(token, { json: true });

  if (!decoded || !decoded.exp) {
    return { valid: false, needRefresh: true };
  }

  const currentNumericDate = Math.round(Date.now() / 1000);

  if (currentNumericDate + REFRESH_TOKEN_LEGROOM <= decoded.exp) {
    return { valid: true, needRefresh: false };
  }

  return { valid: true, needRefresh: true };
}

/**
 * Funcao que eh chamada quando o usuario tenta fechar a janela para exibir
 * uma mensagem de confirmacao
 * @param e evento de fechamento da janela
 */
function onConfirmRefresh(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = '';
}

/**
 * Busca roles do usurio e as codifica em um token
 * @param token token de acesso
 */
async function getRoles(token: string) {
  try {
    const {
      data: { listAuthenticatedUserRoles: roles },
    } = await renewTokenApiClient.query({
      query: listUserRolesQuery,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      fetchPolicy: 'no-cache',
    });

    // Codifica roles
    const encodedRoles = sign({ roles }, authConfig.secretRolesToken);

    localStorage.setItem(localStorageKeys.roles, encodedRoles);
  } catch {
    // Caso sistema nao consiga buscar roles, remove do localStorage
    // para que o usuario seja redirecionado para a tela de login
    // ao atualizar a pagina ou fechar a janela
    localStorage.removeItem(localStorageKeys.roles);
  } finally {
    // Remove o listener de fechamento da janela
    window.removeEventListener('beforeunload', onConfirmRefresh, {
      capture: true,
    });
  }
}

/**
 * Funcao que faz a requisicao para renovar o token de acesso
 * @returns Promise que retorna o token de acesso
 */
async function getRefreshedAccessTokenPromise(): Promise<string | undefined> {
  const oldRenewalToken = localStorage.getItem(localStorageKeys.refreshToken);

  try {
    window.addEventListener('beforeunload', onConfirmRefresh, {
      capture: true,
    });

    const {
      data: {
        refreshToken: { token: accessToken, refresh_token: renewalToken },
      },
    } = await renewTokenApiClient.mutate({
      mutation: refreshTokenQuery,
      variables: { token: oldRenewalToken },
    });

    // Salva os dados no localStorage
    localStorage.setItem(localStorageKeys.refreshToken, renewalToken);
    localStorage.setItem(localStorageKeys.token, accessToken);

    getRoles(accessToken);

    return accessToken;
  } catch (error) {
    window.removeEventListener('beforeunload', onConfirmRefresh, {
      capture: true,
    });

    // Caso o erro seja de token invalido, limpa o localStorage e
    // redireciona para a tela de login
    if (error.message === 'Refresh token does not exists') {
      localStorage.clear();
      window.location.href = '/login?redirect=error';
    }

    return undefined;
  }
}

/**
 * Funcao que retorna o token de acesso
 * @returns Promise que retorna o token de acesso
 */
export async function getAccessTokenPromise(): Promise<any> {
  if (pendingAccessTokenPromise) return pendingAccessTokenPromise;

  // Garante que apenas uma requisicao de renovacao de token seja feita
  return navigator.locks.request('refresh_token', async () => {
    const token = localStorage.getItem(localStorageKeys.token);
    const authTokenState = getTokenState(token);

    if (authTokenState.valid) {
      if (!authTokenState.needRefresh) {
        // Caso o token esteja valido e nao precise ser renovado, retorna o token
        return new Promise(resolve => resolve(token));
      }

      // Caso o token esteja valido e precise ser renovado, faz a requisicao
      if (!pendingAccessTokenPromise) {
        pendingAccessTokenPromise = getRefreshedAccessTokenPromise().finally(
          () => {
            pendingAccessTokenPromise = undefined;
          },
        );
      }
    }

    return pendingAccessTokenPromise;
  });
}

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessTokenPromise();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

export default authLink;
