import React, { useEffect, useMemo, useState } from 'react';

import JsBarcode, { BaseOptions } from 'jsbarcode';
import Empty from '../Empty';
import generateRandomString from '../../utils/generateRandomString';
import { Container } from './styles';

type Format =
  | 'auto'
  | 'CODE128'
  | 'CODE128C'
  | 'CODE39'
  | 'EAN13'
  | 'EAN8'
  | 'EAN5'
  | 'EAN2'
  | 'ITF14'
  | 'UPC'
  | 'MSI'
  | 'MSI10'
  | 'MSI11'
  | 'MSI1010'
  | 'MSI1110'
  | 'pharmacode'
  | 'codabar';

interface IBarcodeProps extends BaseOptions {
  className?: string;
  value?: string;
  downloadOnClick?: boolean;
  format?: Format;
  svgWidth?: number;
  svgHeight?: number;
}

const Barcode: React.FC<IBarcodeProps> = ({
  className,
  value,
  downloadOnClick,
  svgWidth,
  svgHeight,
  ...rest
}) => {
  const barcodeClassName = `s-barcode ${className || ''}`;

  const barcodeId = useMemo(() => {
    return `barcode-${generateRandomString(4)}`;
  }, []);

  const [barcodeIsValid, setBarcodeIsValid] = useState<boolean>();

  function handleBarcodeClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (barcodeIsValid && downloadOnClick) {
      const blob = new Blob([e.currentTarget.innerHTML], {
        type: 'image/svg+xml',
      });
      const fileName = `${value}.svg`;

      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    }
  }

  useEffect(() => {
    try {
      JsBarcode(`#${barcodeId}`, value || '', {
        ...rest,
        valid: isValid => {
          setBarcodeIsValid(isValid);
        },
      });
    } catch {
      setBarcodeIsValid(false);
    }
  }, [barcodeId, rest, value]);

  return (
    <Container
      className={barcodeClassName}
      barcodeCursorPointer={barcodeIsValid && downloadOnClick}
      onClick={e => handleBarcodeClick(e)}
      svgVisible={barcodeIsValid}
      svgWidth={svgWidth}
      svgHeight={svgHeight}
    >
      <svg id={barcodeId} />

      {!barcodeIsValid && (
        <Empty message="Invalid barcode">
          <i className="pi pi-times" />
        </Empty>
      )}
    </Container>
  );
};

export default Barcode;
