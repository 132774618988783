import { gql } from '@apollo/client';

/**
 * Mutation para criacao de Attachments
 */
export const createFinancialTrasnsactionAttachmentsQuery = gql`
  mutation CreateFinancialTransactionAttachments(
    $data: CreateFinancialTransactionAttachmentsInput!
  ) {
    createFinancialTransactionAttachments(data: $data) {
      idFinancialTransactionAttachment
      idAttachment2 {
        nameGivenByUser
        idAttachment
        name
        url
      }
    }
  }
`;

/**
 * Mutation para deletar Attachments
 */
export const deleteFinancialTransactionAttachmentsQuery = gql`
  mutation DeleteFinancialTransactionAttachments(
    $idsFinancialTransactionAttachments: [Int]!
  ) {
    deleteFinancialTransactionAttachments(
      idsFinancialTransactionAttachments: $idsFinancialTransactionAttachments
    )
  }
`;

/**
 * Mutation para duplicar Financial Transaction
 */
export const duplicateFinancialTransactionQuery = gql`
  mutation DuplicateFinancialTransaction($idFinancialTransaction: Int!) {
    duplicateFinancialTransaction(
      idFinancialTransaction: $idFinancialTransaction
    ) {
      idFinancialTransaction
    }
  }
`;

/**
 * Query para listar Attachments
 */
export const listFinancialTransactionAttachmentsQuery = gql`
  query ListAttachmentsByIdTransactionFinancial(
    $data: ListFinancialTransactionAttachmentsInput!
  ) {
    listFinancialTransactionAttachments(data: $data) {
      items
      data {
        idFinancialTransactionAttachment
        idAttachment2 {
          idAttachment
          url
          name
          nameGivenByUser
          createdAt
          idType
        }
      }
    }
  }
`;

/**
 * Query para efetuar download dos Attachments
 */
export const downloadFinancialTransactionAttachmentsQuery = gql`
  query Query($idsFinancialTransactionAttachments: [Int]!) {
    downloadFinancialTransactionAttachments(
      idsFinancialTransactionAttachments: $idsFinancialTransactionAttachments
    )
  }
`;

/**
 * Query para listar Domain Group
 */
export const listDomainsByGroupIdQuery = gql`
  query ListDomainsByGroupId(
    $listDomainsByGroupIdId: Int!
    $pagination: Pagination!
    $isActive: Boolean
  ) {
    listDomainsByGroupId(
      id: $listDomainsByGroupIdId
      pagination: $pagination
      isActive: $isActive
    ) {
      data {
        idDomain
        description
      }
    }
  }
`;

// Query para buscar Financial Transaction
export const getFinancialTransactionByIdQuery = gql`
  query GetFinancialTransactionByIdResolver($idFinancialTransaction: Int!) {
    getFinancialTransactionById(
      idFinancialTransaction: $idFinancialTransaction
    ) {
      idFinancialTransaction
      idPaymentSituation
      idPaymentSituation2 {
        idDomain
        description
      }
      idCountry
      idCountry2 {
        idCountry
        name
      }
      idFinancialAccount
      idFinancialAccount2 {
        idFinancialAccount
        idCompany
        idCompany2 {
          idCompany
          name
        }
        idCurrency
        idCurrency2 {
          idCurrency
          currency
          abbreviation
          usdExchangeRate
        }
      }
      amount
      billRate
      reference
      idSatForeignTrade
      idSatForeignTrade2 {
        idSatForeignTrade
        satForeignTradeNumber
        idSat
        idSat2 {
          idSat
          mainProduct
          idClient
          idClient2 {
            idClient
            name
          }
        }
      }
      product
      idClient
      idClient2 {
        idClient
        name
      }
      idSupplier
      idSupplier2 {
        idSupplier
        name
      }
      idBeneficiary
      idBeneficiary2 {
        idBeneficiary
        name
      }
      description
      idCostCenterByCountry
      idCostCenterByCountry2 {
        idCountry
        name
      }
      idFinancialTransactionCategory
      idFinancialTransactionCategory2 {
        idFinancialTransactionCategory
        name
        group
      }
      paymentForecast
      competencyRegime
      cashflowRegime
      createdAt
      createdBy
      createdBy2 {
        idUser
        firstName
        lastName
      }
      updatedAt
      updatedBy
      updatedBy2 {
        idUser
        firstName
        lastName
      }
      idFinancialTransactionGroup
      versionLock
      idFinancialTransactionPaymentType
      isReconciled
      idSatForeignTradeSupplier
    }
  }
`;

// Mutation de criacao do Financial Transaction
export const createFinancialTransactionQuery = gql`
  mutation CreateFinancialTransaction($data: CreateFinancialTransactionInput!) {
    createFinancialTransaction(data: $data) {
      idFinancialTransaction
    }
  }
`;

// Mutation de update do Financial Transaction
export const updateFinancialTransactionQuery = gql`
  mutation UpdateFinancialTransaction($data: UpdateFinancialTransactionInput!) {
    updateFinancialTransaction(data: $data) {
      idFinancialTransaction
    }
  }
`;

// Mutation para cancelar reconciliation de uma Financial Transaction
export const cancelFinancialTransactionReconciliationQuery = gql`
  mutation CancelFinancialTransactionReconciliation(
    $idFinancialTransaction: Int!
  ) {
    cancelFinancialTransactionReconciliation(
      idFinancialTransaction: $idFinancialTransaction
    )
  }
`;
