/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm } from './styles';
import PageHeader from '../../components/PageHeader';

import Button from '../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import MainButton from '../../components/MainButton';

import { useRefHook } from '../../hooks/useRefHook';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import getValidationErrors from '../../utils/getValidationErrors';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

// Parametros da rota de pantone
interface PantoneRouteParams {
  pantoneId: string;
}

interface IGeneralInformationFormData {
  description: string;
}

const Pantone: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID da pantone
  const { pantoneId } = useParams<PantoneRouteParams>();

  const defaultPageTitle = `${pantoneId ? 'Manage' : 'Create'} Pantone`;

  // Nome da pantone
  const [pantoneName, setPantoneName] = useState('');
  const [versionLock, setVersionLock] = useState();

  useTitle(
    pantoneName ? `${pantoneName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Pantones',
      path: '/pantones',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Query para criar pantone
  const createPantoneQuery = gql`
    mutation CreateDomainMutation($domainData: CreateDomain!) {
      createDomain(domain: $domainData) {
        description
      }
    }
  `;

  // Query para atualizar pantone
  const updatePantoneQuery = gql`
    mutation UpdatePantoneMutation($domainData: UpdateDomain!) {
      updateDomain(domain: $domainData) {
        description
        versionLock
      }
    }
  `;

  // Query para listar dados da pantone
  const listPantoneQuery = gql`
    query ListDomainQuery($idDomain: Int!) {
      listDomainById(id: $idDomain) {
        description
        idGroup
        versionLock
      }
    }
  `;

  // Carrega dados do pantone
  const [
    loadPantoneData,
    {
      called: pantoneCalled,
      loading: pantoneLoading,
      data: pantoneData,
      error: pantoneError,
    },
  ] = useLazyQuery(listPantoneQuery, {
    variables: {
      idDomain: parseInt(pantoneId, 10),
    },
    onCompleted: async response => {
      // Valida se pantone nao foi encontrado
      if (!response.listDomainById || response.listDomainById.idGroup !== 12) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Pantone not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de pantones
        history.push('/pantones');
        return;
      }

      // Define novo nome da pantone
      setPantoneName(response.listDomainById.description);
      setVersionLock(response.listDomainById.versionLock);

      setPageLoading(false);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting pantone data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/pantones');
    },
  });

  // Ao carregar a pagina, carrega dados de pantone
  useEffect(() => {
    if (pantoneId) {
      setPageLoading(true);
      loadPantoneData();
    }
  }, [pantoneId, loadPantoneData]);

  // cria método para chamar a mutation
  const [createPantone] = useMutation(createPantoneQuery);
  const [updatePantone] = useMutation(updatePantoneQuery);

  // Submit do formulario
  const handleCreatePantoneSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria pantone
        await createPantone({
          variables: {
            domainData: {
              description,
              idGroup: 12,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Pantone created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de pantones
        history.push('/pantones');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating pantone',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createPantone, toastRef, history],
  );

  // Submit do formulario
  const handleEditPantoneSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes de pantone
        const updtPantoneResponse = await updatePantone({
          variables: {
            domainData: {
              idDomain: parseInt(pantoneId, 10),
              description,
              versionLock,
            },
          },
        });

        // Define novo nome da pantone
        setPantoneName(description);
        setVersionLock(updtPantoneResponse.data.updateDomain.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Pantone updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating pantone',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updatePantone, pantoneId, versionLock, toastRef],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/pantones'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          pantoneData
            ? `${defaultPageTitle} - ${pantoneName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            pantoneId ? handleEditPantoneSubmit : handleCreatePantoneSubmit
          }
          initialData={
            pantoneCalled && !pantoneLoading && !pantoneError
              ? pantoneData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
        {pageLoading && <Loading />}
      </CrudForm>
    </Container>
  );
};
export default Pantone;
