/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import Input from '../../components/Input';
import MainButton from '../../components/MainButton';
import logoSat from '../../assets/logo.svg';
import getValidationErrors from '../../utils/getValidationErrors';
import { useRefHook } from '../../hooks/useRefHook';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';
import localStorageKeys from '../../config/localStorageKeys';

interface IResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

const SignIn: React.FC = () => {
  useTitle('Reset password');

  const [loading, setLoading] = useState(false);

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL params
  const location = useLocation();

  // Referencia ao formulario
  const formRef = useRef<FormHandles>(null);

  // Mutation de sessão
  const retetPasswordQuery = gql`
    mutation ResetPassword($password: String!, $token: String!) {
      resetPassword(password: $password, token: $token)
    }
  `;

  // cria método para chamar a mutation
  const [resetPasswordMutation] = useMutation(retetPasswordQuery);

  // Submit do formulario
  const handleSubmit = useCallback(
    async (data: IResetPasswordFormData) => {
      setLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          password: Yup.string().required('Enter a password'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match',
          ),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        let token;

        token = location.search.replace('?token=', '');

        if (!token) {
          token = localStorage.getItem(localStorageKeys.refreshToken);
          if (!token) {
            throw new Error();
          }
        }

        // Chamada recuperação de senha
        await resetPasswordMutation({
          variables: { password: data.password, token },
        });

        toastRef.current?.show({
          severity: 'success',
          summary: 'Password changed',
          detail: 'You can log in now',
          life: ToastLife.SUCCESS,
        });

        history.push('/');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Password reset failed',
            detail: 'Please try again',
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, location.search, resetPasswordMutation, toastRef],
  );

  return (
    <div>
      <img src={logoSat} alt="SAT" />
      <Card style={{ height: '315px' }}>
        <h3>Password reset</h3>
        {location.pathname === '/reset-password/requested' ? (
          <h4>Reset your password to continue</h4>
        ) : undefined}
        {/* Formulario de reset */}
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* Senha */}
          <div className="field">
            <label htmlFor="password1" className="block">
              New password
            </label>
            <Input
              type="password"
              name="password"
              id="password1"
              aria-describedby="password1-help"
              className="block"
            />
          </div>

          {/* Confirmação Senha */}
          <div className="field">
            <label htmlFor="password_confirmation1" className="block">
              Password confirmation
            </label>
            <Input
              type="password"
              name="passwordConfirmation"
              id="password_confirmation1"
              aria-describedby="password_confirmation1-help"
              className="block"
            />
          </div>

          {/* Botão submit */}
          <MainButton type="submit" label="Change password" loading={loading} />
        </Form>
      </Card>
    </div>
  );
};

export default SignIn;
