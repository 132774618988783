import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Dispatch } from 'react';
import { numberToPtString } from '../../../../../../../utils/formatLocale';
import { SatItem, SatReferenceSizeBarcode } from '../interfaces';

import { Container } from './styles';

interface IReferenceSizesProps {
  satReferenceSizeBarcodes?: SatReferenceSizeBarcode[];

  satItem: SatItem;

  selectedSatReferenceSizeBarcodes: SatReferenceSizeBarcode[];

  setSelectedSatReferenceSizeBarcodes: Dispatch<SatReferenceSizeBarcode[]>;
}

const ReferenceSizes: React.FC<IReferenceSizesProps> = ({
  satReferenceSizeBarcodes,
  satItem,
  selectedSatReferenceSizeBarcodes,
  setSelectedSatReferenceSizeBarcodes,
}) => {
  const cbmAvailableColumn = (rowData: SatReferenceSizeBarcode) => {
    const result =
      ((rowData.remainQuantityPiShipment ?? 0) /
        (satItem?.piecesContainer ?? 0)) *
      (satItem?.cbm ?? 0);

    return numberToPtString(result);
  };

  return (
    <Container>
      {!!satReferenceSizeBarcodes?.length && (
        <DataTable
          value={satReferenceSizeBarcodes}
          selection={selectedSatReferenceSizeBarcodes}
          selectionMode="multiple"
          onSelectionChange={e => setSelectedSatReferenceSizeBarcodes(e.value)}
        >
          <Column selectionMode="multiple" style={{ width: '3rem' }} />
          <Column field="idImage2.url" style={{ width: '8rem' }} />
          <Column field="reference" style={{ width: '12rem' }} />
          <Column field="name" style={{ width: '12rem' }} />
          <Column field="piecesContainer" style={{ width: '12rem' }} />
          <Column
            field="remainQuantityPiShipment"
            style={{ width: '8.5rem' }}
          />
          <Column
            field="cbmAvailable"
            body={cbmAvailableColumn}
            style={{ width: '9rem' }}
          />
        </DataTable>
      )}
    </Container>
  );
};

export default ReferenceSizes;
