/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';

import { useRefHook } from '../../../hooks/useRefHook';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../hooks/useAuth';
import ILazyParams from '../../../services/lazyParams';
import { supplierRoles } from '../../../shared/roles/supplier';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';

import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';
import ToastLife from '../../../shared/enums/toastLife';
import getFieldPermission from '../../../utils/getFieldPermission';
import { parseNullableBooleanColumn } from '../../../utils/gridColumnsParse';
import { booleanToString } from '../../../utils/parseBoolean';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { gridColumnsData } from './constants';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

interface ICountry {
  name: string;
}

interface ICity {
  name: string;
}

interface ISupplier {
  idSupplier: number;
  name: string;
  country: ICountry;
  city: ICity;
}

interface ISuppliersLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  // active?: boolean;
  idSupplier?: string;
  sunNumber?: string;
  name?: string;
  companyName?: string;
  tax?: string;
  idCountry?: string;
  idRegion?: string;
  idCity?: string;
  productionTime?: string;
  samplesLeadTime?: string;
  idIncoterm?: string;
  idIncotermSecond?: string;
  paymentAdvance?: string;
  paymentBalance?: string;
  address?: string;
  zipCode?: string;
  organization?: string;
  exporter?: boolean;
  paymentTermCondition?: string;
  portAirportName?: string;
  category?: string;
}

const pageTitle = 'List of Suppliers';

const SuppliersList: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:suppliersGridColumns';

  const lazyParamsName = '@SAT:suppliersLazyParams';

  const advancedFiltersName = '@SAT:suppliersAdvancedFilters';

  // ID da entity de supplier
  const { idEntity } = supplierRoles;

  // Permissions do usuario
  const { idPermissionExportSupplierList, idPermissionDeleteSupplier } =
    supplierRoles.permissions;

  // URL params
  const location = useLocation();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia a grid
  const gridRef = useRef<GridRef<any>>(null);

  // Redirect
  const history = useHistory();

  const {
    idFieldAddress,
    idFieldCompanyName,
    idFieldIdCity,
    idFieldIdIncoterm,
    idFieldIdIncotermSecond,
    idFieldIdRegion,
    idFieldOrganization,
    idFieldPaymentAdvance,
    idFieldPaymentBalance,
    idFieldProductionTime,
    idFieldSamplesLeadTime,
    idFieldTax,
    idFieldZipCode,
    idFieldName,
    idFieldExporter,
    idFieldIdPayment,
  } = supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, userPermissions.userFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    columnList.push(gridColumnsData.sunNumber);
    if (showField(idFieldName)) {
      columnList.push(gridColumnsData.name);
    }
    if (showField(idFieldCompanyName)) {
      columnList.push(gridColumnsData.companyName);
    }
    if (showField(idFieldTax)) {
      columnList.push(gridColumnsData.tax);
    }
    columnList.push(gridColumnsData.idCountry);
    if (showField(idFieldIdRegion)) {
      columnList.push(gridColumnsData.idRegion);
    }
    if (showField(idFieldIdCity)) {
      columnList.push(gridColumnsData.idCity);
    }
    if (showField(idFieldProductionTime)) {
      columnList.push(gridColumnsData.productionTime);
    }
    if (showField(idFieldSamplesLeadTime)) {
      columnList.push(gridColumnsData.samplesLeadTime);
    }
    if (showField(idFieldIdIncoterm)) {
      columnList.push(gridColumnsData.idIncoterm);
    }
    if (showField(idFieldIdIncotermSecond)) {
      columnList.push(gridColumnsData.idIncotermSecond);
    }
    if (showField(idFieldPaymentAdvance)) {
      columnList.push(gridColumnsData.paymentAdvance);
    }
    if (showField(idFieldPaymentBalance)) {
      columnList.push(gridColumnsData.paymentBalance);
    }
    if (showField(idFieldAddress)) {
      columnList.push(gridColumnsData.address);
    }
    if (showField(idFieldZipCode)) {
      columnList.push(gridColumnsData.zipCode);
    }
    if (showField(idFieldOrganization)) {
      columnList.push(gridColumnsData.organization);
    }
    if (showField(idFieldIdPayment)) {
      columnList.push(gridColumnsData.paymentTermCondition);
    }
    if (showField(idFieldExporter)) {
      columnList.push(gridColumnsData.exporter);
    }
    columnList.push(gridColumnsData.portAirportName);
    columnList.push(gridColumnsData.category);

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Armazena os parceiros selecionados
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<ISuppliersLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: ISuppliersLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: ISuppliersLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(column =>
      Object.keys(lazyParams).find(key => {
        return lazyParams[key] && key === column.advancedFilterField;
      }),
    );
  }, [columns, lazyParams]);

  // Query para deletar parceiro
  const queryToDeleteSuppliers = gql`
    mutation DeleteSuppliersMutation($suppliersIds: [Int]!) {
      deleteSuppliers(suppliersIds: $suppliersIds)
    }
  `;
  // cria método para chamar a mutation
  const [deleteSuppliers] = useMutation(queryToDeleteSuppliers);

  // Query suppliers
  const queryToListSuppliers = gql`
    query listAllSuppliersQuery($data: ListAllSuppliersInput!) {
      listAllSuppliers(data: $data) {
        data {
          idSupplier
          sunNumber
          ${showField(idFieldName) ? 'name' : ''}
          ${showField(idFieldCompanyName) ? 'companyName' : ''}
          ${showField(idFieldTax) ? 'tax' : ''}
          idCountry
          idCountry2 {
            idCountry
            name
          }
          ${showField(idFieldIdRegion) ? 'idRegion' : ''}
          ${
            showField(idFieldIdRegion)
              ? `idRegion2 {
                  idRegion
                  name
                }`
              : ''
          }
          ${showField(idFieldIdCity) ? 'idCity' : ''}
          ${
            showField(idFieldIdCity)
              ? `idCity2 {
                  idCity
                  name
                }`
              : ''
          }
          ${showField(idFieldProductionTime) ? 'productionTime' : ''}
          ${showField(idFieldSamplesLeadTime) ? 'samplesLeadTime' : ''}
          ${showField(idFieldIdIncoterm) ? 'idIncoterm' : ''}
          ${
            showField(idFieldIdIncoterm)
              ? `idIncoterm2 {
                  idDomain
                  description
                }`
              : ''
          }
          ${showField(idFieldIdIncotermSecond) ? 'idIncotermSecond' : ''}
          ${
            showField(idFieldIdIncotermSecond)
              ? `idIncotermSecond2 {
                  idDomain
                  description
                }`
              : ''
          }
          ${showField(idFieldPaymentAdvance) ? 'paymentAdvance' : ''}
          ${showField(idFieldPaymentBalance) ? 'paymentBalance' : ''}
          ${showField(idFieldAddress) ? 'address' : ''}
          ${showField(idFieldZipCode) ? 'zipCode' : ''}
          ${showField(idFieldOrganization) ? 'organization' : ''}
          ${showField(idFieldExporter) ? 'exporter' : ''}
          ${
            showField(idFieldIdPayment)
              ? `idPayment2 {
                  idDomain
                  description
                }`
              : ''
          }
          portsAirportsString
          supplierCategoriesString
        }
        items
      }
    }
  `;

  // Query suppliers
  const {
    loading: suppliersLoading,
    data: suppliersData,
    error: suppliersError,
    refetch: suppliersRefetch,
  } = useQuery(queryToListSuppliers, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        idSupplier: lazyParams.idSupplier,
        name: lazyParams.name,
        companyName: lazyParams.companyName,
        tax: lazyParams.tax,
        country: lazyParams.idCountry,
        region: lazyParams.idRegion,
        city: lazyParams.idCity,
        productionTime: lazyParams.productionTime,
        samplesLeadTime: lazyParams.samplesLeadTime,
        incoterm: lazyParams.idIncoterm,
        incotermSecond: lazyParams.idIncotermSecond,
        paymentAdvance: lazyParams.paymentAdvance,
        paymentBalance: lazyParams.paymentBalance,
        address: lazyParams.address,
        zipCode: lazyParams.zipCode,
        organization: lazyParams.organization,
        sunNumber: lazyParams.sunNumber,
        exporter: lazyParams.exporter,
        paymentTermCondition: lazyParams.paymentTermCondition,
        portAirportName: lazyParams.portAirportName,
        category: lazyParams.category,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting suppliers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Delete selected suppliers
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedSuppliers.length} suppliers?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada supplier
        const idsToDelete = selectedSuppliers.map(
          (a: ISupplier) => a.idSupplier,
        );
        try {
          // Delete suppliers
          await deleteSuppliers({
            variables: {
              suppliersIds: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedSuppliers.length} suppliers`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de suppliers selecionadas
          setSelectedSuppliers([]);

          // Atualiza grid de suppliers
          await suppliersRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting suppliers',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;

        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Direciona usuario para pagina do supplier clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/suppliers/list/${e.data.idSupplier}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  const parseImageColumn = (rowData: any) => {
    return (
      <img
        src={`${rowData.imageUrl}`}
        onError={e => (e.currentTarget.src = imagePlaceholder)}
        alt={rowData.image}
        style={{ width: '90px' }}
      />
    );
  };

  /**
   * Retorna coluna com link para cadastro de Supplier
   * @param rowData Dados da Linha
   * @returns Coluna com link para cadastro de Supplier
   */
  const parseSunColumn = (rowData: any) => {
    return (
      <Link
        to={`/suppliers/list/${rowData.idSupplier}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.sunNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'exporter':
        return parseNullableBooleanColumn;
      case 'imageUrl':
        return parseImageColumn;
      case 'sunNumber':
        return parseSunColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'idSupplier':
        return { width: '7.14rem' };
      case 'tax':
      case 'idCountry2.name':
      case 'idRegion2.name':
      case 'idCity2.name':
      case 'productionTime':
      case 'samplesLeadTime':
      case 'idIncoterm2.description':
      case 'exporter':
        return { width: '14.29rem' };
      case 'name':
      case 'paymentAdvance':
      case 'paymentBalance':
      case 'zipCode':
      case 'organization':
      case 'idPayment2.description':
      case 'portsAirportsString':
      case 'supplierCategoriesString':
        return { width: '17.86rem' };
      case 'companyName':
      case 'idIncotermSecond2.description':
      case 'address':
        return { width: '25rem' };
      default:
        return {
          width: '8.57rem',
        };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map((col: any) => {
    return (
      <Column
        bodyClassName="white-space-nowrap overflow-hidden text-overflow-ellipsis"
        key={col.field}
        body={handleColumn(col?.field)}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'imageUrl'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Salva arquivo como xlsx
   * @param buffer
   * @param fileName Nome do arquivo
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(suppliersExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    suppliersExportData.listAllSuppliers.data.forEach((supplier: any) => {
      columnsToRemove.forEach(column => {
        delete supplier[column.field as keyof ISupplier];
      });

      // Converte valor booleano para yes ou no
      supplier.exporter = booleanToString(supplier.exporter);

      supplier.idCountry = supplier.idCountry2
        ? supplier.idCountry2.name
        : undefined;

      supplier.idRegion = supplier.idRegion2
        ? supplier.idRegion2.name
        : undefined;

      supplier.idCity = supplier.idCity2 ? supplier.idCity2.name : undefined;

      supplier.idIncoterm = supplier.idIncoterm2
        ? supplier.idIncoterm2.description
        : undefined;

      supplier.paymentTermCondition = supplier.idPayment2?.description;
      supplier.portAirportOfLoading = supplier.portsAirportsString;
      supplier.categories = supplier.supplierCategoriesString;

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'idCountry2.name':
            delete supplier.idCountry;
            break;
          case 'supplier.idSupplier':
            delete supplier.idSupplier;
            break;
          case 'idRegion2.name':
            delete supplier.idRegion;
            break;
          case 'idCity2.name':
            delete supplier.idCity;
            break;
          case 'idIncoterm2.description':
            delete supplier.idIncoterm;
            break;
          case 'idIncotermSecond2.description':
            delete supplier.idIncotermSecond;
            break;
          case 'idPayment2.description':
            delete supplier.paymentTermCondition;
            break;
          case 'portsAirportsString':
            delete supplier.portAirportOfLoading;
            break;
          case 'supplierCategoriesString':
            delete supplier.categories;
            break;
          default:
            delete supplier[column.field];
            break;
        }
      });

      // Remover colunas __typename e idSupplier
      delete supplier['__typename' as keyof ISupplier];
      delete supplier['idSupplier' as keyof ISupplier];
      delete supplier['idCountry2' as keyof ISupplier];
      delete supplier['idRegion2' as keyof ISupplier];
      delete supplier['idCity2' as keyof ISupplier];
      delete supplier['idIncoterm2' as keyof ISupplier];
      delete supplier['idIncotermSecond2' as keyof ISupplier];
      delete supplier['idPayment2' as keyof ISupplier];
      delete supplier['portsAirportsString' as keyof ISupplier];
      delete supplier['supplierCategoriesString' as keyof ISupplier];
      // delete supplier['idSupplier' as keyof ISupplier];
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idCountry2.name':
          columnsOrder[index] = 'idCountry';
          break;
        case 'idRegion2.name':
          columnsOrder[index] = 'idRegion';
          break;
        case 'idCity2.name':
          columnsOrder[index] = 'idCity';
          break;
        case 'idIncoterm2.description':
          columnsOrder[index] = 'idIncoterm';
          break;
        case 'idIncotermSecond2.description':
          columnsOrder[index] = 'idIncotermSecond';
          break;
        case 'idPayment2.description':
          columnsOrder[index] = 'paymentTermCondition';
          break;
        case 'portsAirportsString':
          columnsOrder[index] = 'portAirportOfLoading';
          break;
        case 'supplierCategoriesString':
          columnsOrder[index] = 'categories';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      suppliersExportData.listAllSuppliers.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveAsExcelFile(excelBuffer, 'suppliers');
  }

  /**
   * Busca usuarios para exportar para XLSX
   */
  const [
    loadSuppliersToExport,
    { loading: supplierToExportLoading, data: suppliersToExportData },
  ] = useLazyQuery(queryToListSuppliers, {
    onCompleted: () => {
      exportToXlsx(suppliersToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting suppliers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar parceiro */}
        <MainButton
          className="mainButton"
          label="New Supplier"
          onClick={() => history.push('/suppliers/list/create')}
        />

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />

        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir parceiros selecionados */}
        {userHasPermission(
          idPermissionDeleteSupplier,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedSuppliers.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {userHasPermission(
          idPermissionExportSupplierList,
          userPermissions.userPermissions,
        ) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={supplierToExportLoading}
            onClick={() => {
              loadSuppliersToExport({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    active: true,
                    globalSearch: lazyParams.globalFilter,
                    idSupplier: lazyParams.idSupplier,
                    name: lazyParams.name,
                    companyName: lazyParams.companyName,
                    tax: lazyParams.tax,
                    country: lazyParams.idCountry,
                    region: lazyParams.idRegion,
                    city: lazyParams.idCity,
                    productionTime: lazyParams.productionTime,
                    samplesLeadTime: lazyParams.samplesLeadTime,
                    incoterm: lazyParams.idIncoterm,
                    incotermSecond: lazyParams.idIncotermSecond,
                    paymentAdvance: lazyParams.paymentAdvance,
                    paymentBalance: lazyParams.paymentBalance,
                    address: lazyParams.address,
                    zipCode: lazyParams.zipCode,
                    organization: lazyParams.organization,
                    exporter: lazyParams.exporter,
                    paymentTermCondition: lazyParams.paymentTermCondition,
                    portAirportName: lazyParams.portAirportName,
                    category: lazyParams.category,
                  },
                },
              });
            }}
          />
        )}
        {/* Botao export para XLSX */}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a supplier"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="suppliers"
        lazy
        totalRecords={
          !suppliersData || suppliersError
            ? 0
            : suppliersData.listAllSuppliers.items
        }
        value={
          !suppliersData || suppliersError
            ? undefined
            : suppliersData.listAllSuppliers.data
        }
        globalFilter={globalFilter}
        emptyMessage="No suppliers found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedSuppliers}
        onSelectionChange={e => setSelectedSuppliers(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteSupplier,
          userPermissions.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || suppliersLoading) && <Loading />}
    </div>
  );
};

export default SuppliersList;
