import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .form-div-margin {
    margin-top: 32px;
  }

  .form-div {
    margin-top: 32px;
    display: flex;

    textarea {
      resize: vertical;
    }

    h1 + div,
    .formInput + .formInput,
    .form-dropdown + .formInput,
    .formInput + .form-dropdown,
    .formInput + .form-textarea-input {
      margin-top: 16px;
    }
    .form-dropdown {
      height: 46px;
      width: 100%;
    }
    .descriptions-div + .descriptions-div {
      margin-left: 20px;
    }
  }

  .generalInput {
    width: 263px;
  }

  .descriptions-div,
  .form-textarea-input {
    width: 540px;
  }
`;

export const Row = styled.div`
  display: flex;

  .generalInput + .generalInput {
    margin-left: 16px;
  }

  .form-checkbox {
    margin-left: 16px;
  }

  .form-checkbox + .form-checkbox {
    margin-top: 6px;
  }

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    width: 263px;
    padding: 4px;

    label + .p-radiobutton {
      margin-left: 12px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
  }

  && {
    margin-top: 16px;
  }
`;

export const BrokerFeeItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 540px;

  > div + div {
    margin-left: 20px;
  }

  div {
    .form-textarea-input + .form-checkbox,
    .form-textarea-input + .generalInput,
    .generalInput + .form-textarea-input,
    .generalInput + .generalInput {
      margin-top: 16px;
    }
  }

  .formInput,
  .formInputNumber {
    width: 450px;
  }

  .button-div {
    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;

export const SpecialNegotiationItem = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 12px;
  max-width: 540px;
  margin-bottom: 12px;

  .formInput,
  .form-dropdown,
  .formInputNumber {
    width: 218px;
  }

  .form-textarea-input {
    width: 100%;

    textarea {
      resize: vertical;
    }
  }

  .button-div {
    display: flex;
    align-items: center;
    margin-left: 25px;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;
      margin-top: -4px;
      &:hover {
        color: var(--red);
      }
    }
  }
`;
