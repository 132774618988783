import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px 0 40px 20px;
  padding: 12px;

  div {
    display: flex;
    margin-top: 10px;
    text-align: center;

    h4 {
      margin-bottom: 6px;
      padding-bottom: 6px;
      border-bottom: 1px solid #eee;
      min-height: 3em;
    }

    b {
      font-weight: 500;
    }

    span {
      width: 10em;
    }

    span + span {
      margin-left: 12px;
    }
  }
`;
