/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  DataTable,
  DataTableProps,
  DataTableValueArray,
} from 'primereact/datatable';
import React, {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import pagination from '../../config/pagination';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';

import { Container } from './styles';

export type GridRef<TValue extends DataTableValueArray> = DataTable<
  TValue[]
> & {
  columnOrder?: string[];
};

type GridProps = DataTableProps<any> & {
  ref?: Ref<GridRef<any>>;
  name?: string;
  onPageHeaderToggle?(): void;
};

const Grid: React.FC<GridProps> = forwardRef(
  (
    {
      children,
      scrollable,
      name,
      paginator = true,
      onRowClick,
      scrollHeight,
      ...rest
    },
    ref,
  ) => {
    const dataTableRef = useRef<DataTable<any>>(null);
    const [columnOrderChanged, setColumnOrderChanged] = useState(false);
    const [columnOrder, setColumnOrder] = useState<string[]>();

    // Ao ordenar coluna
    useEffect(() => {
      // Valida se ordem da coluna mudou
      if (columnOrderChanged && name) {
        // Define delay para buscar informacao do local storage
        const delayDebounceFn = setTimeout(() => {
          const data = localStorage.getItem(`@SAT:${name}Grid`);

          // Se houver informacao, atualiza em banco
          if (data) {
            const json = JSON.parse(data);
            // Zera array de linhas selecionadas
            json.selection = [];

            // Atualiza em banco
            updateLocalStorageInDb(`@SAT:${name}Grid`, json);
          }
          setColumnOrderChanged(false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
      }
      // Define que componente esta montado
      return undefined;
    }, [columnOrderChanged, name]);

    const customSaveState = useCallback(
      (e: any) => {
        const object = {
          ...e,
          selection: [],
          expandedRows: undefined,
        };

        localStorage.setItem(`@SAT:${name}Grid`, JSON.stringify(object));
      },
      [name],
    );

    const customRestoreState = useCallback((): any => {
      const data = localStorage.getItem(`@SAT:${name}Grid`);
      if (data) {
        return JSON.parse(data);
      }
      return data;
    }, [name]);

    function handleRowClick(e: any) {
      /*
       * Validando se o clique nao eh de deselecao da checkbox
       * Ha uma issue relacionada no github, que pode ter sido corrigida em uma
       * versao mais nova da biblioteca
       * https://github.com/primefaces/primereact/issues/900
       */
      if (
        typeof e?.originalEvent?.target?.className === 'string' &&
        e.originalEvent.target?.className?.includes('p-checkbox')
      ) {
        return;
      }

      if (onRowClick) onRowClick(e);
    }

    useImperativeHandle(ref, () => ({
      ...(dataTableRef.current as any),
      columnOrder,
      setState: (state: any) => {
        dataTableRef.current?.setState(state);
      },
      resetScroll: () => {
        dataTableRef.current?.resetScroll();
      },
      resetColumnOrder: () => {
        dataTableRef.current?.resetColumnOrder();
        setColumnOrderChanged(true);
      },
    }));

    return (
      <Container scrollHeight={scrollHeight}>
        <DataTable
          ref={dataTableRef}
          id="grid"
          paginator={paginator}
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {last} of {totalRecords} entries"
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          scrollable={scrollable}
          stateStorage="custom"
          customSaveState={name ? customSaveState : undefined}
          customRestoreState={name ? customRestoreState : undefined}
          scrollHeight={scrollHeight}
          {...rest}
          onStateSave={(e: any) => setColumnOrder(e.columnOrder)}
          onColReorder={() => setColumnOrderChanged(true)}
          onRowClick={e => handleRowClick(e)}
          tableStyle={{
            tableLayout: 'fixed',
            ...rest.tableStyle,
          }}
        >
          {children}
        </DataTable>
      </Container>
    );
  },
);

export default Grid;
