import { gql } from '@apollo/client';

export const listAllFinancialTransactionsQuery = gql`
  query ListAllFinancialTransactions(
    $data: ListAllFinancialTransactionsInput!
  ) {
    listAllFinancialTransactions(data: $data) {
      data {
        idFinancialTransaction
        idPaymentSituation
        idPaymentSituation2 {
          description
        }
        amount
        billRate
        idFinancialAccount2 {
          idCurrency2 {
            abbreviation
          }
        }
        paymentForecast
        competencyRegime
        cashflowRegime
        versionLock
      }
      items
    }
  }
`;

export const updateRecurringPaymentQuery = gql`
  mutation UpdateFinancialTransaction($data: UpdateFinancialTransactionInput!) {
    updateFinancialTransaction(data: $data) {
      idFinancialTransaction
      versionLock
    }
  }
`;

export const deleteRecurringPaymentQuery = gql`
  mutation DeleteFinancialRecurringPayment($idFinancialTransaction: Int!) {
    deleteFinancialRecurringPayment(
      idFinancialTransaction: $idFinancialTransaction
    )
  }
`;
