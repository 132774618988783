import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

export const asyncSelectLoadPortsOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; type?: number; idCountry?: number }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
        listAllPorts(listAllPortsInput: $listAllPortsInput) {
          data {
            idPort
            name
            idCountry
            idCountry2 {
              name
            }
          }
          items
        }
      }
    `,
    variables: {
      listAllPortsInput: {
        _page: pageData?.page,
        _limit: pagination.itemsPerPage,
        _orderBy: 'name',
        _sortOrder: 'ASC',
        name: search,
        type: pageData?.type,
        idCountry: pageData?.idCountry,
      },
    },
  });

  const filteredOptions = res.data.listAllPorts.data;
  const quantity = res.data.listAllPorts.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      type: pageData?.type,
      idCountry: pageData?.idCountry,
    },
  };
};
