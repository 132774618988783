import styled from 'styled-components';

export const Container = styled.div`
  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-supplier {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-grid-item {
    cursor: pointer;
    margin: 0.5em;
    border: 1px solid #dee2e6;
    padding: 2rem;
    transition: background-color 0.2s;
    flex: 1;
  }

  .product-grid-item:not(.product-item-highlight):hover {
    background: #e9ecef;
    color: #495057;
  }

  .product-item-highlight {
    background: #e3f2fd;
  }

  .product-grid-item img {
    width: 147px;
    height: 110px;

    border: solid 0.1px #d7d6d6;
    border-radius: 4px;
    object-fit: contain;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item .product-grid-item-content {
    text-align: center;
  }

  .header .header-field {
    height: 100%;
    font-weight: normal;

    input {
      height: 100%;
    }
  }
`;
