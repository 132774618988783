import { useLazyQuery, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { ICategoryTree } from '../../../../../shared/interfaces/category';
import { getCurrencySymbol } from '../../../../../utils/getCurrencySymbol';
import { ISat, ISatGroup } from '../../interfaces';
import {
  ConfirmOrderCommercialFormRef,
  ICheckboxMenu,
  IConfirmOrderCommercialFormObject,
} from '../interfaces';
import CommercialFormContent from './CommercialFormContent';
import {
  confirmSatGroupOrderCommercialInputQuery,
  confirmSatOrderCommercialInputQuery,
  listSatGroupToConfirmOrderCommercialQuery,
} from '../queries';
import {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../components/MutationResponseSummary';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../../components/SelectRecipientsModal/interfaces';

interface IConfirmOrderCommercialFormProps {
  sat: ISat;
  onHide(): void;
  selectedUsers: IUserEmailsProps[] | undefined;
  userGroups: IUserGroupsProps[] | undefined;
  categories: ICategoryTree[];
  setEmailTitle(emailTitle: string): void;
  satRefetch(): Promise<void>;
  isConfirmOrderWithGroup: boolean;
  setConfirmOrderMutationResponse: React.Dispatch<
    React.SetStateAction<IMutationResponse[]>
  >;
  confirmOrderSummaryRef: React.RefObject<IMutationResponseSummaryRef>;
}

const ConfirmOrderCommercialForm: React.FC<IConfirmOrderCommercialFormProps> =
  ({
    sat,
    onHide,
    userGroups,
    selectedUsers,
    categories,
    setEmailTitle,
    satRefetch,
    isConfirmOrderWithGroup,
    setConfirmOrderMutationResponse,
    confirmOrderSummaryRef,
  }) => {
    const formRef = useRef<FormHandles>(null);
    // Referencia ao toast e formulario
    const { showError, showSuccess } = useRefHook();

    const [satGroup, setSatGroup] = useState<ISatGroup>();

    const [confirmOrderLoading, setConfirmOrderLoading] = useState(false);

    // Abas do SAT Group
    const [currentSatTabActiveIndex, setCurrentSatTabActiveIndex] =
      useState<number>(0);

    const [
      listSatGroupToConfirmOrderCommercial,
      { loading: listSatGroupLoading },
    ] = useLazyQuery(listSatGroupToConfirmOrderCommercialQuery, {
      onCompleted: response => {
        if (response.listSatGroupToConfirmOrderCommercial) {
          const { isConfirmOrderInGroupPossible } =
            response.listSatGroupToConfirmOrderCommercial;

          // Valida se é possível fazer o Confirm Order em grupo sem dar erro de memoria
          if (!isConfirmOrderInGroupPossible) {
            showError({
              summary:
                'Confirm Order in group is not possible due to excess of SATs/Sat Items',
            });
            onHide();
          } else {
            setSatGroup(response.listSatGroupToConfirmOrderCommercial);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT Group',
          detail: errorData.message,
        });
      },
    });

    const [confirmSatOrderCommercial] = useMutation(
      confirmSatOrderCommercialInputQuery,
    );

    const [confirmSatGroupOrderCommercial] = useMutation(
      confirmSatGroupOrderCommercialInputQuery,
    );

    const concatValueCheckbox = (items: ICheckboxMenu[] | undefined) => {
      const getIdsGroups: any = items?.map(e => {
        return {
          label: `(${e.isChecked ? 'X' : ' '}) ${e.name}`,
          additionalValue: e.additionalValue,
        };
      });

      return getIdsGroups;
    };

    const handleSubmit = async (formData: ConfirmOrderCommercialFormRef) => {
      const valuesArray: IConfirmOrderCommercialFormObject[] = [];

      setConfirmOrderLoading(true);

      const getIdsUsers = selectedUsers?.map(e => e.idUser);
      const getIdsGroups = userGroups?.flatMap(e =>
        e.isChecked ? [e.idUserGroup] : [],
      );

      formData.data.forEach((data, index) => {
        const currentSat = satGroup?.sats[index];

        valuesArray.push({
          idSat: currentSat?.idSat ?? sat.idSat,
          satNumber: currentSat?.satNumber ?? sat.satNumber,
          emailDescription: data.emailDescription,
          satReplacement: data.idSatOriginSatNumber,
          product: data.mainProduct,
          piValue: data.amountTotal,
          incoterm: data.idClientIncotermName,
          client: data.idClientName,
          buyer: data.BuyerName,
          seller: data.sellersName,
          quantityContainer: data.satCtnrs,
          productionTime: data.productionTime,
          estimatedInspectionDate: data.estimatedInspectionDate,
          estimatedShipmentDate: data.estimatedShipmentDate,
          estimatedArrivalDate: data.estimatedArrivalDate,
          estimatedArrivalAtClient: data.estimatedArrivalClientDate,
          exporter: data.idSupplierExporterName,
          portLoading: data.idLoadingPortName,
          contractedModality: concatValueCheckbox(data.ContractedModality),
          awResponsible: concatValueCheckbox(data.awResponsible),
          ndiDistributionService: concatValueCheckbox(
            data.ndiDistributionService,
          ),
          rfid: concatValueCheckbox(data.rfid),
          clientDepartment: data.clientDepartment,
          currencySymbol: getCurrencySymbol(
            currentSat?.idCurrency2?.abbreviation ??
              sat.idCurrency2?.abbreviation,
          ),
        });
      });

      try {
        if (isConfirmOrderWithGroup) {
          const confirmOrderInGroup = await confirmSatGroupOrderCommercial({
            variables: {
              data: {
                idsUsers: getIdsUsers,
                idsUserGroups: getIdsGroups,
                emailsData: valuesArray,
              },
            },
          });

          const returnedData =
            confirmOrderInGroup.data.confirmSatGroupOrderCommercial;

          // Caso algum confirm order apresentar erro, cria um summary apresentando
          // os erros e as Sats que conseguiram completar
          if (returnedData?.errors?.length) {
            const satsCompletedStrings = returnedData.satsCompleted?.map(
              (item: string) => `${item} completed`,
            );

            setConfirmOrderMutationResponse([
              {
                moduleChanged: 'Commercial',
                errors: returnedData.errors,
                warnings: satsCompletedStrings,
              },
            ]);

            confirmOrderSummaryRef.current?.toggleDisplaySummary();
          }

          if (!returnedData.satsCompleted?.length) {
            throw new Error('No Confirm Orders were completed');
          }
        } else {
          await confirmSatOrderCommercial({
            variables: {
              data: {
                idsUsers: getIdsUsers,
                idsUserGroups: getIdsGroups,
                ...valuesArray[0],
              },
            },
          });
        }

        await satRefetch();

        showSuccess({
          summary: 'Order confirmed',
        });

        onHide();
      } catch (error) {
        showError({
          summary: 'Error while confirming order',
          detail: error.message,
        });
      } finally {
        setConfirmOrderLoading(false);
      }
    };

    // Busca SAT Group se o usuario selecionar a opcao do Confirm Order em grupo
    useEffect(() => {
      if (isConfirmOrderWithGroup && sat.idSatGroup2 && !satGroup) {
        listSatGroupToConfirmOrderCommercial({
          variables: {
            idSatGroup: sat.idSatGroup2?.idSatGroup,
          },
        });
      }
    }, [
      isConfirmOrderWithGroup,
      listSatGroupToConfirmOrderCommercial,
      satGroup,
      sat.idSatGroup2,
    ]);

    return (
      <Form
        style={{ margin: '10px' }}
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={sat}
      >
        {isConfirmOrderWithGroup ? (
          !listSatGroupLoading && (
            <TabView
              activeIndex={currentSatTabActiveIndex}
              onTabChange={e => setCurrentSatTabActiveIndex(e.index)}
              renderActiveOnly={false}
              scrollable
            >
              {satGroup &&
                satGroup.sats?.length &&
                satGroup.sats.map((item: ISat, index: number) => {
                  return (
                    <TabPanel
                      key={item.idSat.toString()}
                      header={item.satNumber}
                    >
                      <CommercialFormContent
                        sat={item}
                        categories={categories}
                        index={index}
                        setEmailTitle={setEmailTitle}
                        selected={currentSatTabActiveIndex === index}
                      />
                    </TabPanel>
                  );
                })}
            </TabView>
          )
        ) : (
          <CommercialFormContent
            sat={sat}
            categories={categories}
            index={0}
            setEmailTitle={setEmailTitle}
            selected
          />
        )}

        <Divider />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />
        <Button
          label="Send"
          icon={confirmOrderLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
          type="submit"
          disabled={confirmOrderLoading}
          autoFocus
        />
      </Form>
    );
  };

export default ConfirmOrderCommercialForm;
