export const partnersListRoles = {
  idEntity: 22,
  idModule: 9,
  fields: {
    idFieldActive: 27,
    idFieldFormalPartnerName: 28,
    idFieldTaxId: 29,
    idFieldAddress: 30,
    idFieldCity: 31,
    idFieldZipCode: 32,
    idFieldCourier: 33,
    idFieldRegistrationNumber: 34,
    idFieldValue: 35,
    idFieldPaymentTerms: 36,
    idFieldRegion: 37,
    idFieldIndAudit: 39,
    idFieldIndCertifyingEntity: 40,
    idFieldIndCourier: 41,
    idFieldIndCustomsBroker: 42,
    idFieldIndFinancialServices: 43,
    idFieldIndFreightForwarderDestination: 44,
    idFieldIndFreightForwarderOrigin: 45,
    idFieldIndInlandTransportsDestination: 46,
    idFieldIndInlandTransportsOrigin: 47,
    idFieldIndInsuranceCompany: 48,
    idFieldIndLicensor: 49,
    idFieldIndOthers: 50,
    idFieldIndRepresentative: 51,
    idFieldIndShipowner: 52,
    idFieldIndTrading: 53,
    idFieldIndWarehouse: 54,
  },
  permissions: {
    idPermissionDeletePartner: 39,
  },
};
