import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 38px;
  .sunButton {
    display: flex;
    background: none;
    border: none;
    color: var(--blue);
    transition: color 0.2s;

    svg {
      margin-left: 13px;
    }

    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }

  .p-paginator-current {
    margin-bottom: 12px;
  }
`;

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  .grid-filters {
    display: flex;
    .p-inputtext {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .grid-header-buttons {
    display: flex;
    button {
      margin-right: 12px;
    }
  }

  .advanced-filters-button {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .applied-filters-button {
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
`;

export const GridActions = styled.div`
  display: flex;
  button {
    background: none;
    border: none;
    color: #7f7f80;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.4, '#7f7f80')};
    }

    svg {
      margin: 0 8px;
    }
  }

  .trash-button {
    color: var(--red);

    &:hover {
      color: ${shade(0.2, '#FD2D2D')};
    }
  }
`;
