/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import { IExpenseFormData, ExpenseRouteParams } from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import ToastLife from '../../../shared/enums/toastLife';
import { expenseRoles } from '../../../shared/roles/expense';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const defaultPageTitle = 'Manage Expense';

const Expense: React.FC = () => {
  useTitle(defaultPageTitle);

  // Redirect
  const history = useHistory();

  // ID de air freight tax
  const idExpense = parseInt(useParams<ExpenseRouteParams>().idExpense, 10);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de bcCif
  const [bcCif, setBcCif] = useState<boolean>();

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    expenseRoles.idEntity,
  );

  // Permissions de Expense
  const { idPermissionUpdateExpense } = expenseRoles.permissions;

  // Valida se usuario pode atualizar Expense
  const userCanChange = userHasPermission(
    idPermissionUpdateExpense,
    userPermissionsAirFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Expenses',
      path:
        initialPage && initialFirst
          ? `/logistics/expenses?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/expenses',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Clients
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Clients
   */
  const loadClients: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
          listAllClients(listAllClientsInput: $listAllClientsInput) {
            data {
              idClient
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllClientsInput: {
          pagination: {
            _page: pageData?.page,
            _limit: 25,
            _orderBy: 'name',
            _sortOrder: 'DESC',
          },
          globalSearch: search,
        },
      },
    });

    const filteredOptions = res.data.listAllClients.data;
    const quantity = res.data.listAllClients.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar air freight tax
  const listExpenseQuery = gql`
    query listLogisticExpenseById($idExpense: Int!) {
      listLogisticExpenseById(idExpense: $idExpense) {
        idExpense
        idTaxName2 {
          description
        }
        taxValue
        idCurTax2 {
          idCurrency
          abbreviation
        }
        idBcValue2 {
          idDomain
          description
        }
        bcCif
        idClient2 {
          name
        }
        versionLock
        createdAt
        createdBy2 {
          firstName
          lastName
        }
        updatedAt
        updatedBy2 {
          firstName
          lastName
        }
      }
    }
  `;

  /**
   * Busca dados de Expense
   */
  const [loadExpenseData, { loading: expenseLoading, data: expenseData }] =
    useLazyQuery(listExpenseQuery, {
      variables: {
        idExpense,
      },
      onCompleted: response => {
        if (response.listLogisticExpenseById) {
          setBcCif(response.listLogisticExpenseById.bcCif);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Expense not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting expense',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/expenses'),
    });
  }

  // Mutation de atualizacao de air freight tax
  const updateExpenseQuery = gql`
    mutation UpdateExpenseMutation($data: UpdateLogisticExpenseInput!) {
      updateLogisticExpense(data: $data) {
        idExpense
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [updateExpenseMutation] = useMutation(updateExpenseQuery);

  // Submit do formulario de air freight
  const handleExpenseSubmit = useCallback(
    async (data: IExpenseFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idCurTax: Yup.string().nullable().required('Required Field'),
          idBcValue: Yup.string().nullable().required('Required Field'),
          taxValue: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Chamada atualizacao de expense
        await updateExpenseMutation({
          variables: {
            data: {
              idExpense,
              versionLock: expenseData.listLogisticExpenseById?.versionLock,
              idCurTax: data.idCurTax,
              idBcValue: data.idBcValue,
              taxValue: data.taxValue,
              bcCif,
              idClient: data.idClient ? data.idClient : null,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Expense updated',
          life: ToastLife.SUCCESS,
        });

        // Atualiza dados
        loadExpenseData();
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating expense',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      bcCif,
      formRef,
      idExpense,
      loadExpenseData,
      expenseData,
      toastRef,
      updateExpenseMutation,
    ],
  );

  // Carrega dados de air freight tax ao montar a tela
  useEffect(() => {
    loadExpenseData();
  }, [loadExpenseData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className="createdAt">
          {expenseData && !expenseLoading
            ? `${new Date(
                expenseData?.listLogisticExpenseById?.createdAt,
              ).toLocaleString()} by ${
                expenseData?.listLogisticExpenseById?.createdBy2?.firstName
              } ${expenseData?.listLogisticExpenseById?.createdBy2?.lastName}`
            : ''}
        </p>
        <p className="updatedAt">
          {expenseData && !expenseLoading
            ? `${new Date(
                expenseData?.listLogisticExpenseById?.updatedAt ??
                  expenseData?.listLogisticExpenseById?.createdAt,
              ).toLocaleString()} by ${
                expenseData?.listLogisticExpenseById.updatedBy2?.firstName ??
                expenseData?.listLogisticExpenseById.createdBy2?.firstName
              } ${
                expenseData?.listLogisticExpenseById.updatedBy2?.lastName ??
                expenseData?.listLogisticExpenseById.createdBy2?.lastName
              }`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {!expenseLoading && expenseData?.listLogisticExpenseById?.idExpense && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleExpenseSubmit}
            initialData={expenseData.listLogisticExpenseById}
          >
            <Row>
              <FormInput
                name="taxName"
                label="Tax Name"
                defaultValue={
                  expenseData?.listLogisticExpenseById.idTaxName2?.description
                }
                readOnly
              />
            </Row>

            <Row>
              <FormInputNumber
                required
                name="taxValue"
                label="Value"
                decimalScale={2}
                min={0}
                readOnly={!userCanChange}
                decimalSeparator=","
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idCurTax"
                className="currency-dropdown"
                label="Currency"
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.abbreviation}
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={expenseData.listLogisticExpenseById.idCurTax2}
                readOnly={!userCanChange}
                required
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idBcValue"
                className="generalInput"
                label="BC Value"
                required
                loadOptions={asyncSelectLoadDomains}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.description}
                getOptionValue={(option: any) => option.idDomain}
                additional={{
                  page: 1,
                  id: 29,
                }}
                noOptionsMessage={() => 'No BC values found'}
                initialValue={expenseData?.listLogisticExpenseById.idBcValue2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <div className="radio-button-div generalInput">
                <label>BC CIF:</label>
                <div>
                  <RadioButton
                    inputId="yes"
                    name="expense.bcCif"
                    value
                    checked={bcCif === true}
                    onChange={() => setBcCif(true)}
                    disabled={!userCanChange}
                  />
                  <label>Yes</label>
                  <RadioButton
                    inputId="no"
                    name="expense.bcCif"
                    value={false}
                    checked={bcCif !== true}
                    onChange={() => setBcCif(false)}
                    disabled={!userCanChange}
                  />
                  <label>No</label>
                </div>
              </div>
            </Row>

            <Row>
              <FormAsyncSelect
                name="idClient"
                className="client-dropdown"
                label="Client"
                loadOptions={loadClients}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idClient}
                additional={{
                  page: 1,
                  id: 29,
                }}
                noOptionsMessage={() => 'No Clients found'}
                isClearable
                placeholder={<p>All Clients</p>}
                initialValue={expenseData.listLogisticExpenseById.idClient2}
                readOnly={!userCanChange}
              />
            </Row>
          </Form>
        </CrudForm>
      )}
      {(expenseLoading || loading) && <Loading />}
    </Container>
  );
};

export default Expense;
