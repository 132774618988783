/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Menu } from 'primereact/menu';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import { gridConstants } from '../../../components/Grid/constants';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import { inlandFreightTaxRoles } from '../../../shared/roles/inlandFreightTax';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import {
  parseDateTimeColumm,
  parseFloatColumnPt,
  parseNullableBooleanColumn,
  parsePercentageColumnPt,
} from '../../../utils/gridColumnsParse';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './constants';
import { Container } from './styles';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import {
  createInlandFreightTaxesBulkQuery,
  updateInlandFreightTaxesBulkQuery,
} from './queries';
import { FileType } from '../../../shared/enums/fileType';
import FileUpload from '../../../components/FileUpload';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

/**
 * Interface dos parametros do backend
 */
interface IInlandFreightTaxesLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  inlandFreightTaxName?: string;
  idPortAirportDestination?: string;
  idCurPos?: string;
  taxValuePos?: number;
  idBcValuePos?: string;
  bcCifPos?: boolean;
  idCurPes?: string;
  taxValuePes?: number;
  idBcValuePes?: string;
  bcCifPes?: boolean;
  idCurArm?: string;
  taxValueArm?: number;
  idBcValueArm?: string;
  bcCifArm?: boolean;
  idCurAdm?: string;
  taxValueAdm?: number;
  idBcValueAdm?: string;
  bcCifAdm?: boolean;
}

interface IBulkResponse {
  inlandFreightTaxesTotal: number;
  inlandFreightTaxesWithError: string[];
  createdInlandFreightTaxes?: number;
  updatedInlandFreightTaxes?: number;
  type: 'CREATE' | 'UPDATE';
}

const screenSubject = 'Inland Freight Taxes';
const pageTitle = `List of ${screenSubject}`;

const InlandFreightTaxes: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:inlandFreightTaxesGridColumns';

  const lazyParamsName = '@SAT:inlandFreightTaxesLazyParams';

  const advancedFiltersName = '@SAT:inlandFreightTaxesAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { showError, showSuccess } = useRefHook();

  const gridRef = useRef<DataTable<any>>(null);
  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);
  const massiveActionMenuRef = useRef<Menu>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Estado de resposta da acao e massa
  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // URL params
  const location = useLocation();

  // Permissions de InlandFreight Taxes
  const {
    idPermissionCreateInlandFreightTax,
    idPermissionDeleteInlandFreightTax,
    idPermissionMassiveActionInlandFreightTax,
    idPermissionExportListInlandFreightTax,
  } = inlandFreightTaxRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsInlandFreightTax = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    inlandFreightTaxRoles.idEntity,
  );

  const roleEntityPermissions = userPermissionsInlandFreightTax.userPermissions;

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;
  // Busca
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'inland_freight_tax_bulk_template.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];
  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IInlandFreightTaxesLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: IInlandFreightTaxesLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: IInlandFreightTaxesLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // InlandFreightTaxes selecionados
  const [selectedInlandFreightTaxes, setSelectedInlandFreightTaxes] = useState(
    [],
  );

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de scrollHeight da grid
  const [gridScrollHeight, setGridScrollHeight] = useState(
    gridConstants.expandedHeaderScrollHeight,
  );
  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar InlandFreight Taxes
  const listInlandFreightTaxesQuery = gql`
    query listAllInlandFreightTaxesQuery(
      $listAllInlandFreightTaxesInput: ListAllInlandFreightTaxesInput!
    ) {
      listAllInlandFreightTaxes(
        listAllInlandFreightTaxesInput: $listAllInlandFreightTaxesInput
      ) {
        data {
          idInlandFreightTax
          inlandFreightTaxName
          idPortAirportDestination2 {
            name
          }
          idCurPos2 {
            abbreviation
          }
          taxValuePos
          idBcValuePos2 {
            description
          }
          bcCifPos
          updatedAtPos
          idCurPes2 {
            abbreviation
          }
          taxValuePes
          idBcValuePes2 {
            description
          }
          bcCifPes
          updatedAtPes
          idCurArm2 {
            abbreviation
          }
          taxValueArm
          idBcValueArm2 {
            description
          }
          bcCifArm
          updatedAtArm
          idCurAdm2 {
            abbreviation
          }
          taxValueAdm
          idBcValueAdm2 {
            description
          }
          bcCifAdm
          updatedAtAdm
        }
        items
      }
    }
  `;

  // Query para deletar InlandFreight Taxes
  const deleteInlandFreightTaxesQuery = gql`
    mutation DisableLogisticInlandFreightTaxes($ids: [Int]!) {
      disableLogisticInlandFreightTaxes(ids: $ids)
    }
  `;

  // cria método para chamar a mutation
  const [deleteInlandFreightTaxes] = useMutation(deleteInlandFreightTaxesQuery);

  /**
   * Busca InlandFreight Taxes
   */
  const {
    loading: inlandFreightTaxesLoading,
    data: inlandFreightTaxesData,
    refetch: inlandFreightTaxesRefetch,
  } = useQuery(listInlandFreightTaxesQuery, {
    variables: {
      listAllInlandFreightTaxesInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        idPortAirportDestination: lazyParams.idPortAirportDestination,
        inlandFreightTaxName: lazyParams.inlandFreightTaxName,
        idCurPos: lazyParams.idCurPos,
        taxValuePos: lazyParams.taxValuePos,
        idBcValuePos: lazyParams.idBcValuePos,
        bcCifPos: lazyParams.bcCifPos,
        taxValuePes: lazyParams.taxValuePes,
        idCurPes: lazyParams.idCurPes,
        idBcValuePes: lazyParams.idBcValuePes,
        bcCifPes: lazyParams.bcCifPes,
        taxValueArm: lazyParams.taxValueArm,
        idCurArm: lazyParams.idCurArm,
        idBcValueArm: lazyParams.idBcValueArm,
        bcCifArm: lazyParams.bcCifArm,
        taxValueAdm: lazyParams.taxValueAdm,
        idCurAdm: lazyParams.idCurAdm,
        idBcValueAdm: lazyParams.idBcValueAdm,
        bcCifAdm: lazyParams.bcCifAdm,
        active: true,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Inland Freight Taxes',
        detail: errorData.message,
      });
    },
  });

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'updatedAtPos':
      case 'updatedAtPes':
      case 'updatedAtArm':
      case 'updatedAtAdm':
        return parseDateTimeColumm;
      case 'taxValueArm':
        return parsePercentageColumnPt;
      case 'taxValuePos':
      case 'taxValuePes':
      case 'taxValueAdm':
        return parseFloatColumnPt;
      case 'bcCifPos':
      case 'bcCifPes':
      case 'bcCifArm':
      case 'bcCifAdm':
        return parseNullableBooleanColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'idCurArm2.abbreviation':
      case 'idCurPos2.abbreviation':
      case 'idCurPes2.abbreviation':
      case 'idCurAdm2.abbreviation':
        return { width: '150px' };
      case 'taxValueAdm':
      case 'taxValueArm':
      case 'taxValuePos':
      case 'taxValuePes':
        return { width: '160px' };
      case 'updatedAtArm':
      case 'updatedAtPes':
      case 'updatedAtAdm':
      case 'updatedAtPos':
        return { width: '190px' };
      case 'inlandFreightTaxName':
      case 'idPortAirportDestination2.name':
        return { width: '240px' };
      default:
        return { width: '145px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/logistics/inlandFreightTaxes/${e.data.idInlandFreightTax}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setGridScrollHeight(gridConstants.collapsedHeaderScrollHeight);
      setFixedStickyButtons(true);
    } else {
      setGridScrollHeight(gridConstants.expandedHeaderScrollHeight);
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta InlandFreight Taxes selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedInlandFreightTaxes.length} Inland Freight Taxes?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada InlandFreight Tax
        const idsToDelete = selectedInlandFreightTaxes.map(
          (a: any) => a.idInlandFreightTax,
        );
        try {
          await deleteInlandFreightTaxes({
            variables: {
              ids: idsToDelete,
            },
          });

          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedInlandFreightTaxes.length} Inland Freight Taxes`,
          });

          setSelectedInlandFreightTaxes([]);
          await inlandFreightTaxesRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting Inland Freight Taxes',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  async function exportToXlsx(portTaxesExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    portTaxesExportData.listAllInlandFreightTaxes.data.forEach(
      (inlandFreightTax: any) => {
        inlandFreightTax.name = inlandFreightTax.inlandFreightTaxName;
        inlandFreightTax.airportDestination =
          inlandFreightTax.idPortAirportDestination2?.name;
        inlandFreightTax.idCurPos = inlandFreightTax.idCurPos2?.abbreviation;
        inlandFreightTax.idBcValuePos =
          inlandFreightTax.idBcValuePos2?.description;
        inlandFreightTax.idCurPes = inlandFreightTax.idCurPes2?.abbreviation;
        inlandFreightTax.idBcValuePes =
          inlandFreightTax.idBcValuePes2?.description;
        inlandFreightTax.idCurArm = inlandFreightTax.idCurArm2?.abbreviation;
        inlandFreightTax.idBcValueArm =
          inlandFreightTax.idBcValueArm2?.description;
        inlandFreightTax.idCurAdm = inlandFreightTax.idCurAdm2?.abbreviation;
        inlandFreightTax.idBcValueAdm =
          inlandFreightTax.idBcValueAdm2?.description;
        if (inlandFreightTax.bcCifPos !== null) {
          inlandFreightTax.bcCifPos = inlandFreightTax.bcCifPos ? 'Yes' : 'No';
        }
        if (inlandFreightTax.bcCifPes !== null) {
          inlandFreightTax.bcCifPes = inlandFreightTax.bcCifPes ? 'Yes' : 'No';
        }
        if (inlandFreightTax.bcCifArm !== null) {
          inlandFreightTax.bcCifArm = inlandFreightTax.bcCifArm ? 'Yes' : 'No';
        }
        if (inlandFreightTax.bcCifAdm !== null) {
          inlandFreightTax.bcCifAdm = inlandFreightTax.bcCifAdm ? 'Yes' : 'No';
        }

        columnsToRemove.forEach(column => {
          switch (column.field) {
            case 'inlandFreightTaxName':
              delete inlandFreightTax.name;
              break;
            case 'idPortAirportDestination2.name':
              delete inlandFreightTax.airportDestination;
              break;
            case 'idCurPos2.abbreviation':
              delete inlandFreightTax.idCurPos;
              break;
            case 'idBcValuePos2.description':
              delete inlandFreightTax.idBcValuePos;
              break;
            case 'idCurPes2.abbreviation':
              delete inlandFreightTax.idCurPes;
              break;
            case 'idBcValuePes2.description':
              delete inlandFreightTax.idBcValuePes;
              break;
            case 'idCurArm2.abbreviation':
              delete inlandFreightTax.idCurArm;
              break;
            case 'idBcValueArm2.description':
              delete inlandFreightTax.idBcValueArm;
              break;
            case 'idCurAdm2.abbreviation':
              delete inlandFreightTax.idCurAdm;
              break;
            case 'idBcValueAdm2.description':
              delete inlandFreightTax.idBcValueAdm;
              break;
            default:
              delete inlandFreightTax[column.field];
              break;
          }
        });

        // Remover colunas __typename e idInlandFreightTax
        delete inlandFreightTax.__typename;
        delete inlandFreightTax.idInlandFreightTax;
        delete inlandFreightTax.inlandFreightTaxName;
        delete inlandFreightTax.idPortAirportDestination2;
        delete inlandFreightTax.idCurPos2;
        delete inlandFreightTax.idBcValuePos2;
        delete inlandFreightTax.idCurPes2;
        delete inlandFreightTax.idBcValuePes2;
        delete inlandFreightTax.idCurArm2;
        delete inlandFreightTax.idBcValueArm2;
        delete inlandFreightTax.idCurAdm2;
        delete inlandFreightTax.idBcValueAdm2;
      },
    );

    // Busca ordenacao das colunas da grid
    const gridColumns =
      gridRef.current?.state.columnOrder ??
      selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idInlandFreightTax' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idPortAirportDestination2.name':
          columnsOrder[index] = 'airportDestination';
          break;
        case 'inlandFreightTaxName':
          columnsOrder[index] = 'name';
          break;
        case 'idCurPos2.abbreviation':
          columnsOrder[index] = 'idCurPos';
          break;
        case 'idBcValuePos2.description':
          columnsOrder[index] = 'idBcValuePos';
          break;
        case 'idCurPes2.abbreviation':
          columnsOrder[index] = 'idCurPes';
          break;
        case 'idBcValuePes2.description':
          columnsOrder[index] = 'idBcValuePes';
          break;
        case 'idCurArm2.abbreviation':
          columnsOrder[index] = 'idCurArm';
          break;
        case 'idBcValueArm2.description':
          columnsOrder[index] = 'idBcValueArm';
          break;
        case 'idCurAdm2.abbreviation':
          columnsOrder[index] = 'idCurAdm';
          break;
        case 'idBcValueAdm2.description':
          columnsOrder[index] = 'idBcValueAdm';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      portTaxesExportData.listAllInlandFreightTaxes.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:AA1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'inlandFreightTaxes');
  }

  const [createInlandFreightTaxesBulkMutation] = useMutation(
    createInlandFreightTaxesBulkQuery,
  );
  const [updateInlandFreightTaxesBulkMutation] = useMutation(
    updateInlandFreightTaxesBulkQuery,
  );

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      // Cria aiport taxes em massa
      const response = await createInlandFreightTaxesBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createLogisticInlandFreightTaxesBulk,
        type: 'CREATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while creating Inland Freight Taxes',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      // atualiza aiport taxes em massa
      const response = await updateInlandFreightTaxesBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateLogisticInlandFreightTaxesBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while updating Inland Freight Taxes',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  /**
   * Busca InlandFreight Taxes para exportar para XLSX
   */
  const [
    loadInlandFreightTaxesToExport,
    {
      loading: inlandFreightTaxesToExportLoading,
      data: inlandFreightTaxesToExportData,
    },
  ] = useLazyQuery(listInlandFreightTaxesQuery, {
    onCompleted: () => {
      exportToXlsx(inlandFreightTaxesToExportData);
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting Inland Freight Taxes',
        detail: errorData.message,
      });
    },
  });

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <Container>
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>InlandFreight Taxes found in the spreadsheet:</b>{' '}
          {bulkResponse?.inlandFreightTaxesTotal}
        </p>
        <p>
          <b>
            InlandFreight Taxes{' '}
            {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.createdInlandFreightTaxes ||
            bulkResponse?.updatedInlandFreightTaxes}
        </p>
        {!!bulkResponse?.inlandFreightTaxesWithError?.length && (
          <p>
            <b>InlandFreight Taxes/Cells with error:</b>
            <br />
            {bulkResponse?.inlandFreightTaxesWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar InlandFreight Tax */}

        {userHasPermission(
          idPermissionCreateInlandFreightTax,
          userPermissionsInlandFreightTax.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Inland Freight Tax"
            onClick={() => history.push('/logistics/inlandFreightTaxes/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir InlandFreight Taxes selecionados */}
        {userHasPermission(
          idPermissionDeleteInlandFreightTax,
          userPermissionsInlandFreightTax.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedInlandFreightTaxes.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />
        {hasPermission(idPermissionMassiveActionInlandFreightTax) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}

        {userHasPermission(
          idPermissionExportListInlandFreightTax,
          userPermissionsInlandFreightTax.userPermissions,
        ) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={inlandFreightTaxesToExportLoading}
            onClick={() => {
              loadInlandFreightTaxesToExport({
                variables: {
                  listAllInlandFreightTaxesInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    inlandFreightTaxName: lazyParams.inlandFreightTaxName,
                    idPortAirportDestination:
                      lazyParams.idPortAirportDestination,
                    idCurPos: lazyParams.idCurPos,
                    taxValuePos: lazyParams.taxValuePos,
                    idBcValuePos: lazyParams.idBcValuePos,
                    bcCifPos: lazyParams.bcCifPos,
                    idCurPes: lazyParams.idCurPes,
                    taxValuePes: lazyParams.taxValuePes,
                    idBcValuePes: lazyParams.idBcValuePes,
                    bcCifPes: lazyParams.bcCifPes,
                    idCurArm: lazyParams.idCurArm,
                    taxValueArm: lazyParams.taxValueArm,
                    idBcValueArm: lazyParams.idBcValueArm,
                    bcCifArm: lazyParams.bcCifArm,
                    idCurAdm: lazyParams.idCurAdm,
                    taxValueAdm: lazyParams.taxValueAdm,
                    idBcValueAdm: lazyParams.idBcValueAdm,
                    bcCifAdm: lazyParams.bcCifAdm,
                    active: true,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an item"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="grid"
        name="inlandFreightTaxes"
        lazy
        totalRecords={
          !inlandFreightTaxesData
            ? 0
            : inlandFreightTaxesData.listAllInlandFreightTaxes?.items
        }
        value={
          !inlandFreightTaxesData
            ? undefined
            : inlandFreightTaxesData.listAllInlandFreightTaxes?.data
        }
        globalFilter={globalFilter}
        emptyMessage="No InlandFreight Taxes found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridScrollHeight}
        rows={lazyParams.rows}
        first={!inlandFreightTaxesData ? undefined : lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedInlandFreightTaxes}
        onSelectionChange={e => setSelectedInlandFreightTaxes(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteInlandFreightTax,
          userPermissionsInlandFreightTax.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || inlandFreightTaxesLoading) && <Loading />}
    </Container>
  );
};
export default InlandFreightTaxes;
