import styled from 'styled-components';
import { DashboardCard } from '../../../components/Dashboard/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DashboardCardTemplate = styled(DashboardCard)`
  cursor: pointer;

  .dashboard-card-title {
    font-weight: 500;
    font-size: 1.17em;
    line-height: 1.175rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dashboard-card-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
    line-height: 1.25rem;

    .dashboard-card-details-text {
      white-space: nowrap;
      overflow: hidden;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .highligted-text {
        color: var(--blue);
        height: 1.125rem;
      }
    }

    .currency-row {
      display: flex;
      gap: 0.3rem;
    }
  }

  .dashboard-card-dates {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1em;

    div {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }
`;
