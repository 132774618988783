import React, { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';

import { AuthProvider } from './useAuth';
import { MenuProvider } from './useMenu';
import { RefHookProvider } from './useRefHook';

const AppProvider: React.FC = ({ children }) => {
  // Importa formato de data de acordo com localizacao do usuario para componente
  useEffect(() => {
    const importLocaleFile = async () => {
      // This webpack option stops all of the date-fns files being imported and chunked.
      const localeToSet = await import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${
          Intl.DateTimeFormat().resolvedOptions().locale
        }/index.js`
      );
      registerLocale('locale', localeToSet.default);
    };

    importLocaleFile();
  }, []);

  return (
    <AuthProvider>
      <MenuProvider>
        <RefHookProvider>{children}</RefHookProvider>
      </MenuProvider>
    </AuthProvider>
  );
};

export default AppProvider;
