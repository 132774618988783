/* eslint-disable no-shadow */
import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { number, object, string, mixed } from 'yup';
import Button from '../../../../../../../components/Button';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import FormDatePicker from '../../../../../../../components/FormDatePicker';

import FormDropdown from '../../../../../../../components/FormDropdown';
import Input from '../../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../../components/FormInputNumber';
import Loading from '../../../../../../../components/Loading';
import MainButton from '../../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { RncOtherExpenseType } from '../../../../../../../shared/enums/rncOtherExpenseType';
import { asyncSelectLoadSatForeignTradeSupplierSummarized } from '../../../../../../../shared/querys/satForeignTradeSuppliersSummarized';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../../utils/getValidationErrors';
import { ISat, ISatRnc } from '../../../../interfaces';
import { SatOtherExpense } from '../interfaces';
import {
  createSatOtherExpenseQuery,
  updateSatOtherExpenseQuery,
} from './queries';
import { Buttons, Container, Row } from './styles';

interface IAddOtherExpenseModal {
  sat: ISat;
  title: string;
  satRnc: ISatRnc;
  fieldPrefix: string;
  satOtherExpense?: SatOtherExpense;
  otherExpenseType: RncOtherExpenseType;
  otherExpensesRefetch(): void;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

enum OtherExpensesStatus {
  CN_CANCELLED = 987,
  DN_CANCELLED = 994,
}

const AddOtherExpenseModal: React.FC<IAddOtherExpenseModal> = ({
  sat,
  title,
  satRnc,
  fieldPrefix,
  satOtherExpense,
  otherExpenseType,
  otherExpensesRefetch,
  setDisplayModal,
}) => {
  const { showError, showSuccess } = useRefHook();

  const formRef = useRef<FormHandles>(null);

  const [pageLoading, setPageLoading] = useState(false);

  const validateForm = useCallback(
    async (data: any) => {
      formRef.current?.setErrors({});
      const schema = object().shape({
        description: string().required(requiredFieldErrorMessage),
        idCurrency: string().nullable().required(requiredFieldErrorMessage),
        total: number().nullable().required(requiredFieldErrorMessage),
        appliedOn: mixed().test({
          name: 'isRequired',
          message: requiredFieldErrorMessage,
          test(value) {
            if (value === undefined || value === null) {
              return false;
            }
            const isObject =
              typeof value === 'object' && value.appliedOn !== undefined;
            return typeof value === 'string' || isObject;
          },
        }),
      });

      try {
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        const firstError = error.inner[0];
        const inputWithError = formRef.current?.getFieldRef(firstError.path);

        if (inputWithError.focus) {
          inputWithError.focus();
        } else if (inputWithError.inputRef?.current?.focus) {
          inputWithError.inputRef?.current?.focus();
        }

        showError({
          summary: 'Please fill all the required fields',
        });
        return false;
      }
    },
    [showError],
  );

  const [createSatOtherExpenseMutation] = useMutation(
    createSatOtherExpenseQuery,
  );
  const [updateSatOtherExpenseMutation] = useMutation(
    updateSatOtherExpenseQuery,
  );

  const saveOtherExpense = useCallback(
    async (data: any) => {
      setPageLoading(true);
      const idSatOtherExpense = satOtherExpense?.idSatOtherExpense;
      const otherExpenseToSave: SatOtherExpense = {
        idStatus: data.cancelDate
          ? otherExpenseType === RncOtherExpenseType.CREDIT_NOTE
            ? OtherExpensesStatus.CN_CANCELLED
            : OtherExpensesStatus.DN_CANCELLED
          : data.idStatus,
        description: data.description,
        idCurrency: data.idCurrency,
        total: data.total,
        idSatDestination: data.appliedOn.idSat,
        idSatForeignTradeDestination: data.appliedOn.idSatForeignTrade,
        idSatForeignTradeSupplierDestination:
          data.appliedOn.idSatForeignTradeSupplier,
        cancelDate: data.cancelDate,
      };

      try {
        if (!idSatOtherExpense) {
          await createSatOtherExpenseMutation({
            variables: {
              data: {
                ...otherExpenseToSave,
                idSat: satRnc.idSat,
                idSatRnc: satRnc.idSatRnc,
                idSatForeignTrade: satRnc.idSatForeignTrade,
                idSatForeignTradeSupplier: satRnc.idSatForeignTradeSupplier,
                idType: otherExpenseType,
              },
            },
          });
        } else {
          await updateSatOtherExpenseMutation({
            variables: {
              data: {
                ...otherExpenseToSave,
                idSatOtherExpense: satOtherExpense?.idSatOtherExpense,
              },
            },
          });
        }
        setPageLoading(false);
        otherExpensesRefetch();
        setDisplayModal(false);
        showSuccess({
          summary: `${title} ${!idSatOtherExpense ? 'created' : 'updated'}`,
        });
      } catch (error) {
        setPageLoading(false);
        showError({
          summary: `Error while ${
            !idSatOtherExpense ? 'creating' : 'updating'
          } ${title}`,
          detail: error.message,
        });
      }
    },
    [
      satOtherExpense?.idSatOtherExpense,
      otherExpensesRefetch,
      setDisplayModal,
      showSuccess,
      title,
      createSatOtherExpenseMutation,
      satRnc.idSat,
      satRnc.idSatRnc,
      satRnc.idSatForeignTrade,
      satRnc.idSatForeignTradeSupplier,
      otherExpenseType,
      updateSatOtherExpenseMutation,
      showError,
    ],
  );

  function handleCancel() {
    setDisplayModal(false);
  }

  async function handleSubmit() {
    const data = formRef.current?.getData();
    const isFormValid = await validateForm(data);
    if (isFormValid) {
      saveOtherExpense(data);
    }
  }

  return (
    <Container>
      <Form ref={formRef} initialData={satOtherExpense} onSubmit={handleSubmit}>
        <Row className="flex flex-wrap">
          <Input
            className="col-6"
            name="number"
            label={`${title} Number`}
            readOnly
          />
          <Input
            className="col-12"
            name="description"
            label={`${fieldPrefix} Description`}
            readOnly={!!satOtherExpense?.requestDate}
            required
          />
          <FormDropdown
            className="col-6"
            label={`${fieldPrefix} Currency`}
            name="idCurrency"
            options={[
              {
                value: sat.idCurrency2?.idCurrency,
                label: sat.idCurrency2?.abbreviation,
              },
            ]}
            optionLabel="label"
            optionValue="value"
            initialValue={sat?.idCurrency2?.idCurrency}
            readOnly
            required
          />
          <FormInputNumber
            className="col-6"
            name="total"
            label={`Total ${fieldPrefix}`}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            readOnly={!!satOtherExpense?.requestDate}
            required
          />
          <FormAsyncSelect
            className="col-6"
            name="appliedOn"
            label={`${fieldPrefix} Applied On`}
            loadOptions={asyncSelectLoadSatForeignTradeSupplierSummarized}
            getOptionLabel={(option: any) => option.appliedOn}
            getOptionValue={(option: any) => option}
            noOptionsMessage={() => 'No option found'}
            initialValue={{ appliedOn: satOtherExpense?.appliedOn }}
            readOnly={!!satOtherExpense?.requestDate}
            additional={
              otherExpenseType === RncOtherExpenseType.CREDIT_NOTE
                ? {
                    idClient: sat.idClient,
                    idSatForeignTrade: satRnc.idSatForeignTrade,
                  }
                : {
                    idSupplier: satRnc.idSupplier,
                    idSatForeignTrade: satRnc.idSatForeignTrade,
                  }
            }
            menuPosition="fixed"
            required
          />
          <FormDatePicker
            className="col-6"
            name="cancelDate"
            label="Cancellation Date"
            readOnly={!satOtherExpense?.idSatOtherExpense}
          />
        </Row>
        <Buttons
          className="mt-3"
          style={{ display: 'flex', placeContent: 'end' }}
        >
          <MainButton
            type="button"
            label="Confirm"
            className="mx-2"
            onClick={handleSubmit}
          />
          <Button
            type="button"
            label="Cancel"
            onClick={() => handleCancel()}
            className="p-button-danger"
          />
        </Buttons>
      </Form>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default AddOtherExpenseModal;
