import { gql } from '@apollo/client';

export const createSatForeignTradeContainerQuery = gql`
  mutation CreateSatForeignTradeContainer(
    $data: CreateSatForeignTradeContainerInput!
  ) {
    createSatForeignTradeContainer(data: $data) {
      idSatForeignTradeCtnr
    }
  }
`;

export const updateSatForeignTradeContainerQuery = gql`
  mutation UpdateSatForeignTradeContainer(
    $data: UpdateSatForeignTradeContainerInput!
  ) {
    updateSatForeignTradeContainer(data: $data) {
      idSatForeignTradeCtnr
    }
  }
`;
