import { gql } from '@apollo/client';

/**
 * Query para listar SAT Comments pelo ID da SAT RNC
 */
export const listSatCommentsByIdSatRncQuery = gql`
  query ListSatCommentsByIdSatRnc($data: ListSatCommentsByIdSatRncInput!) {
    listSatCommentsByIdSatRnc(data: $data) {
      items
      data {
        idSatComment
        idSat
        idComment2 {
          idComment
          message
          idAttachment
          idAttachment2 {
            idAttachment
            url
            name
            nameGivenByUser
            createdAt
          }
          isRestrictInfo
          isGeneratedSystem
          createdBy
          createdBy2 {
            idUser
            username
            firstName
            lastName
            avatarUrl
          }
          createdAt
          updatedAt
          updatedBy2 {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * Mutation para criacao de SAT Comment
 */
export const createSatCommentQuery = gql`
  mutation CreateSatComment($data: CreateSatCommentInput!) {
    createSatComment(data: $data) {
      idSatComment
    }
  }
`;

/**
 * Mutation de atualizacao de comentario
 */
export const updateSatCommentQuery = gql`
  mutation UpdateSatComment($data: UpdateSatCommentInput!) {
    updateSatComment(data: $data) {
      idSatComment
    }
  }
`;

/**
 * Mutation para apagar comentario
 */
export const deleteSatCommentQuery = gql`
  mutation DeleteSatComment($idSatComment: Int!) {
    deleteSatComment(idSatComment: $idSatComment)
  }
`;

/**
 * Query para listar SAT Attachments pelo ID da SAT
 */
export const listSatAttachmentsBySatIdQuery = gql`
  query ListSatAttachmentsBySatId($data: ListSatAttachmentsBySatIdInput!) {
    listSatAttachmentsBySatId(data: $data) {
      items
      data {
        idSatAttachment
        idSat
        idAttachment
        idAttachment2 {
          idAttachment
          url
          name
          nameGivenByUser
          createdAt
          idType
        }
      }
    }
  }
`;
