/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import PageHeader from '../../../components/PageHeader';
import Image from '../../../components/PageHeader/Image';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';
import { Container, CrudForm } from './styles';
import { useRefHook } from '../../../hooks/useRefHook';
import PageMenuButton from '../../../components/PageMenuButton';
import GeneralInformation from './GeneralInformation';
import ArtworkFormat from './ArtworkFormat';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Tag from '../../../components/Tag';
import Loading from '../../../components/Loading';
import Assessment from './Assessment';
import Contact from './Contact';
import BankDetails from './BankDetails';
import Products from './Products';
import Sats from './Sats';

import {
  ICreateSupplierFormData,
  ISupplier,
  ISupplierContact,
  SupplierRouteParams,
} from './interfaces';
import getValidationErrors from '../../../utils/getValidationErrors';
import { supplierRoles } from '../../../shared/roles/supplier';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import PageMenu from '../../../components/PageMenu';
import { productRoles } from '../../../shared/roles/product';
import { satsRoles } from '../../../shared/roles/sat';
import getFieldPermission from '../../../utils/getFieldPermission';
import { supplierBankAccountRoles } from '../../../shared/roles/supplierBankAccount';
import ToastLife from '../../../shared/enums/toastLife';
import { clientQuotationRoles } from '../../../shared/roles/clientQuotation';
import Rncs from './Rnc';
import useTitle from '../../../hooks/useTitle';
import isNullOrUndefined from '../../../utils/isNullOrUndefined';
import Attachments from './Attachments';

const Supplier: React.FC = () => {
  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Supplier ID
  const supplierId = parseInt(useParams<SupplierRouteParams>().supplierId, 10);

  const defaultPageTitle = `${supplierId ? 'Manage' : 'Create'} Supplier`;

  // Supplier
  const [supplier, setSupplier] = useState<ISupplier>({} as ISupplier);
  const [supplierName, setSupplierName] = useState('');

  useTitle(
    supplier?.sunNumber
      ? `${supplier.sunNumber} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // ID da entity de usuario
  const { idEntity } = supplierRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsSupplier = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Busca permissoes do usuario para a entity
  const userPermissionsProduct = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    productRoles.idEntity,
  );

  // Busca permissoes do usuario para a entity
  const userPermissionsSat = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    satsRoles.idEntity,
  );

  // Busca permissoes do usuario para a entity de Client Quotation
  const userPermissionsClientQuotation = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    clientQuotationRoles.idEntity,
  );

  // Busca permissoes do usuario para a entity
  const userPermissionsSupplierBankAccount =
    getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      supplierBankAccountRoles.idEntity,
    );

  // URL da imagem
  const [imageUrl] = useState('');

  // URL params
  const location = useLocation();

  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);

  const [supplierContacts, setSupplierContacts] = useState<ISupplierContact[]>(
    [],
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL temporaria para upload de arquivo
  const [uploadImageTempUrl] = useState('');

  // Dados para retornar para lista
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const {
    idFieldName,
    idFieldCompanyName,
    idFieldProductionTime,
    idFieldSamplesLeadTime,
    idFieldTax,
    idFieldAddress,
  } = supplierRoles.fields;

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Suppliers',
      path:
        initialPage && initialFirst
          ? `/suppliers/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/suppliers/list',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] =
    useState('GeneralInformation');

  function showField(idField: number): boolean {
    return getFieldPermission(idField, userPermissionsSupplier.userFields).view;
  }

  // Query para listar Supplier
  const listSupplierQuery = gql`
    query listSupplierQuery($idSupplier: Int!) {
      listSupplierById(idSupplier: $idSupplier) {
        idSupplier
        active
        sunNumber
        ${showField(idFieldName) ? 'name' : ''}
        idCountry
        idCountry2 {
          idCountry
          name
        }
        ${showField(idFieldCompanyName) ? 'companyName' : ''}
        ${showField(idFieldTax) ? 'tax' : ''}
        pdf
        ai
        corel17
        corel20
        jpeg
        eps
        psd
        indd
        xls
        otherFormat
        colorPantone
        colorCemik
        colorRgd
        colorOther
        idRegion
        idRegion2 {
          idRegion
          name
        }
        idCity
        idCity2 {
          idCity
          name
        }
        ${showField(idFieldProductionTime) ? 'productionTime' : ''}
        ${showField(idFieldSamplesLeadTime) ? 'samplesLeadTime' : ''}
        idIncoterm
        idIncoterm2 {
          idDomain
          description
        }
        idIncotermSecond
        idIncotermSecond2 {
          idDomain
          description
        }
        idCourier
        idCourier2 {
          idDomain
          description
        }
        website
        exporter
        paymentAdvance
        paymentBalance
        factoryAudit
        manufacturer
        factoryName
        factoryTax
        factoryAddress
        factoryZipCode
        idFactoryCountry
        idFactoryCountry2 {
          idCountry
          name
        }
        idFactoryRegion
        idFactoryRegion2 {
          idRegion
          name
        }
        idFactoryCity
        idFactoryCity2 {
          idCity
          name
        }
        idPayment2 {
          idDomain
          description
        }
        ${showField(idFieldAddress) ? 'address' : ''}
        zipCode
        organization
        supplierCurrencies {
          idCurrency
          idCurrency2 {
            idCurrency
            currency
            abbreviation
          }
        }
        supplierCategories {
          idSupplierCategory
          idSupplier
          idCategory
          idCategory2 {
            idCategory
            name
          }
        }
        supplierPorts {
          idSupplierPort
          idSupplier
          isPrimary
          idPort
          idPort2 {
            idPort
            name
          }
        }
        supplierContacts {
          idSupplierContact
          idSupplier
          idRole
          name
          idDepartment
          email
          telephone
          idRole2 {
            idDomain
            description
          }
          idDepartment2 {
            idDepartment
            name
          }
        }
        supplierBankAccounts {
          accountNumber
          bankCity
          beneficiaryName
          iban
          idCountry
          idRegion
          idSecondCountry
          idSecondRegion
          idCountry2 {
            idCountry
            name
          }
          idRegion2 {
            idRegion
            name
          }
          idSecondCountry2 {
            idCountry
            name
          }
          idSecondRegion2 {
            idRegion
            name
          }
          idSupplier
          idSupplierBankAccount
          intermediaryAccountNumber
          intermediaryBankAddress
          intermediaryBankCity
          intermediaryBeneficiaryName
          intermediaryIban
          intermediarySwift
          isIntermediaryBank
          isPrimary
          mainBankAddress
          mainBankName
          swift
          versionLock
        }
      }
    }
  `;

  /**
   * Get data Supplier
   */
  const [loadSupplierData, { loading: supplierLoading, data: supplierData }] =
    useLazyQuery(listSupplierQuery, {
      onCompleted: response => {
        if (response.listSupplierById) {
          setSupplier(response.listSupplierById);
          if (showField(idFieldName)) {
            setSupplierName(response.listSupplierById.name);
          }
          setSupplierContacts(response.listSupplierById.supplierContacts);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Supplier not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting supplier data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/suppliers/list'),
    });
  }

  // Mutation de criacao de supplier
  const createSupplierQuery = gql`
    mutation CreateSupplierMutation($supplier: CreateSupplierInput) {
      createSupplier(supplier: $supplier) {
        idSupplier
      }
    }
  `;

  // Mutation de atualizacao de supplier
  const updateSupplierQuery = gql`
    mutation UpdateSupplierMutation($supplier: UpdateSupplierInput!) {
      updateSupplier(supplier: $supplier) {
        idSupplier
      }
    }
  `;

  // cria método para chamar a mutation
  const [createSupplierMutation] = useMutation(createSupplierQuery);
  const [updateSupplierMutation] = useMutation(updateSupplierQuery);

  // Submit do formulario de criacao
  const handleCreateSupplierSubmit = useCallback(
    async (data: ICreateSupplierFormData) => {
      if (!shouldSubmit) return;
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        if (supplier.manufacturer && !data.idFactoryCountry2) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Factory information',
            detail: `Enter a Factory Country.`,
            life: ToastLife.WARN,
          });
          return;
        }

        if (data.supplierPorts) {
          const ports = data.supplierPorts.filter(
            port => port.isPrimary === true,
          );
          if (ports.length !== 1) {
            // Exibe toast sobre o erro
            toastRef.current?.show({
              severity: 'warn',
              summary: 'Primary Port/Airport of Loading',
              detail: `Enter one primary port. `,
              life: ToastLife.WARN,
            });
            return;
          }
        }

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().when('supplier.name', {
            is: supplier.idSupplier ? 'supplier.name' : undefined,
            then: Yup.string().required('Required Field'),
            otherwise: Yup.string().notRequired(),
          }),
          companyName: Yup.string().when('supplier.companyName', {
            is: supplier.idSupplier ? 'supplier.companyName' : undefined,
            then: Yup.string().required('Required Field'),
            otherwise: Yup.string().notRequired(),
          }),
          address: Yup.string().when('supplier.address', {
            is: supplier.idSupplier ? 'supplier.address' : undefined,
            then: Yup.string().required('Required Field'),
            otherwise: Yup.string().notRequired(),
          }),
          productionTime: Yup.string()
            .nullable()
            .when('supplier.productionTime', {
              is: supplier.idSupplier ? 'supplier.productionTime' : undefined,
              then: Yup.string().nullable().required('Required Field'),
              otherwise: Yup.string().notRequired(),
            }),
          supplierPorts: Yup.array().of(
            Yup.object().shape({
              idPort: Yup.string()
                .nullable()
                .required('Port/Airport of Loading Required Field'),
            }),
          ),
          country: Yup.object()
            .shape({
              idCountry: Yup.number(),
              name: Yup.string(),
            })
            .nullable()
            .required('Enter a country'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valida se usuario possui permissao para alterar campos de bankAccounts
        // Caso nao possua, mantem informacoes originais
        const bankAccountsToParse =
          userPermissionsSupplierBankAccount.userFields
            ? data.supplierBankAccounts
            : supplier.supplierBankAccounts;

        const parsedSupplierBankAccounts = bankAccountsToParse?.map(
          (bankAccount, index) => {
            return {
              idSupplierBankAccount: supplier.supplierBankAccounts
                ? typeof supplier.supplierBankAccounts[index]
                    .idSupplierBankAccount === 'number'
                  ? supplier.supplierBankAccounts[index].idSupplierBankAccount
                  : undefined
                : undefined,
              idSupplier: supplier.idSupplier,
              accountNumber: bankAccount.accountNumber,
              bankCity: bankAccount.bankCity,
              beneficiaryName: bankAccount.beneficiaryName,
              iban: bankAccount.iban,
              idCountry: bankAccount.idCountry,
              idRegion: bankAccount.idRegion,
              idSecondCountry: bankAccount.idSecondCountry,
              idSecondRegion: bankAccount.idSecondRegion,
              intermediaryAccountNumber: bankAccount.intermediaryAccountNumber,
              intermediaryBankAddress: bankAccount.intermediaryBankAddress,
              intermediaryBankCity: bankAccount.intermediaryBankCity,
              intermediaryBeneficiaryName:
                bankAccount.intermediaryBeneficiaryName,
              intermediaryIban: bankAccount.intermediaryIban,
              intermediarySwift: bankAccount.intermediarySwift,
              isIntermediaryBank: supplier.supplierBankAccounts
                ? supplier.supplierBankAccounts[index].isIntermediaryBank
                : false,
              isPrimary: bankAccount.isPrimary,
              mainBankAddress: bankAccount.mainBankAddress,
              mainBankName: bankAccount.mainBankName,
              swift: bankAccount.swift,
              versionLock: bankAccount.versionLock,
            };
          },
        );

        // // Valida se esta editando ou criando um novo registro
        if (!supplierId) {
          // Transforma objeto contacts para o backend
          const parsedSupplierContacts = supplierContacts.map(
            supplierContact => {
              return {
                name: supplierContact.name,
                telephone: supplierContact.telephone,
                email: supplierContact.email,
                idRole: supplierContact.idRole2?.idDomain,
                idDepartment: supplierContact.idDepartment2?.idDepartment,
              };
            },
          );

          // Chamada criacao do objeto
          const response = await createSupplierMutation({
            variables: {
              supplier: {
                // General Fields
                name: data.name,
                companyName: data.companyName,
                active: true,
                versionLock: 0,
                factoryName: data.factoryName,
                factoryTax: data.factoryTax,
                factoryAddress: data.factoryAddress,
                factoryZipCode: data.factoryZipCode,
                idFactoryCountry: data.idFactoryCountry2?.idCountry,
                idFactoryRegion: data.idFactoryRegion2?.idRegion,
                idFactoryCity2: data.idFactoryCity2?.idCity,
                tax: data.tax,
                paymentAdvance: supplier.paymentAdvance,
                paymentBalance: supplier.paymentBalance,
                idPayment: data.idPayment?.idDomain,
                website: data.website,
                exporter: data.exporter,
                supplierCurrencies: data.supplierCurrencies,
                productionTime: data.productionTime,
                samplesLeadTime: data.samplesLeadTime,
                idCourier: data.idCourier?.idDomain,
                idIncoterm: data.idIncoterm?.idDomain,
                idIncotermSecond: data.idIncotermSecond?.idDomain,
                manufacturer: supplier.manufacturer,
                // Address Info
                idCountry: data.country?.idCountry,
                idRegion: data.region.idRegion,
                idCity: data.cityName.idCity,
                address: data.address,
                zipCode: data.zipCode,
                // Categories
                supplierCategories: data.supplierCategories,
                // Port/Airport of Loading
                supplierPorts: data.supplierPorts,
                // Contacts
                supplierContacts: parsedSupplierContacts,
                // Artwork Format
                pdf: data.pdf,
                ai: data.ai,
                corel17: data.corel17,
                corel20: data.corel20,
                jpeg: data.jpeg,
                eps: data.eps,
                psd: data.psd,
                indd: data.indd,
                xls: data.xls,
                otherFormat: data.otherFormat,
                colorPantone: data.colorPantone,
                colorCemik: data.colorCemik,
                colorRgd: data.colorRgd,
                colorOther: data.colorOther,
                supplierBankAccounts: parsedSupplierBankAccounts,
              },
            },
          });

          // Direciona usuario para o cadastro
          history.push(
            `/suppliers/list/${response?.data.createSupplier.idSupplier}`,
          );
        } else {
          // Transforma objeto de contacts para o backend
          const parsedSupplierContacts = supplierContacts.map(
            supplierContact => {
              return {
                idSupplierContact:
                  typeof supplierContact.idSupplierContact === 'number'
                    ? supplierContact.idSupplierContact
                    : undefined,
                name: supplierContact.name,
                telephone: supplierContact.telephone,
                email: supplierContact.email,
                idRole: supplierContact.idRole2?.idDomain,
                idDepartment: supplierContact.idDepartment2?.idDepartment,
              };
            },
          );

          // Transforma objeto de supplierCategories para o backend
          const parsedSupplierCategories = data.supplierCategories?.map(
            (category, index) => {
              return {
                ...category,
                idSupplierCategory:
                  typeof supplier.supplierCategories[index]
                    .idSupplierCategory === 'number'
                    ? supplier.supplierCategories[index].idSupplierCategory
                    : undefined,
              };
            },
          );

          const parsedSupplierPorts = data.supplierPorts?.map(
            (supplierPort, index) => {
              return {
                ...supplierPort,
                idSupplierPort:
                  typeof supplier.supplierPorts[index].idSupplierPort ===
                  'number'
                    ? supplier.supplierPorts[index].idSupplierPort
                    : undefined,
              };
            },
          );

          // Chamada atualizacao
          await updateSupplierMutation({
            variables: {
              supplier: {
                // General Fields
                idSupplier: supplierId,
                name: data.name,
                active: isNullOrUndefined(data.active)
                  ? undefined
                  : data.active,
                companyName: data.companyName,
                versionLock: supplier.versionLock,
                tax: data.tax,
                paymentAdvance: supplier.paymentAdvance,
                paymentBalance: supplier.paymentBalance,
                idPayment: data.idPayment?.idDomain,
                website: data.website,
                exporter: data.exporter,
                supplierCurrencies: data.supplierCurrencies,
                productionTime: data.productionTime,
                samplesLeadTime: data.samplesLeadTime,
                idCourier: data.idCourier?.idDomain,
                idIncoterm: data.idIncoterm?.idDomain,
                idIncotermSecond: data.idIncotermSecond?.idDomain,
                factoryName: data.factoryName,
                factoryTax: data.factoryTax,
                factoryAddress: data.factoryAddress,
                factoryZipCode: data.factoryZipCode,
                idFactoryCountry: data.idFactoryCountry2?.idCountry,
                idFactoryRegion: data.idFactoryRegion2?.idRegion,
                idFactoryCity: data.idFactoryCity2?.idCity,
                manufacturer: supplier.manufacturer,
                // Address Info
                idCountry:
                  data.country?.idCountry || supplier.idCountry2?.idCountry,
                idRegion: data.region.idRegion,
                idCity: data.cityName.idCity,
                address: data.address,
                zipCode: data.zipCode,
                // Categories
                supplierCategories: parsedSupplierCategories,
                // Ports
                supplierPorts: parsedSupplierPorts,
                supplierContacts: parsedSupplierContacts,
                // Artwork Format
                pdf: data.pdf,
                ai: data.ai,
                corel17: data.corel17,
                corel20: data.corel20,
                jpeg: data.jpeg,
                eps: data.eps,
                psd: data.psd,
                indd: data.indd,
                xls: data.xls,
                otherFormat: data.otherFormat,
                colorPantone: data.colorPantone,
                colorCemik: data.colorCemik,
                colorRgd: data.colorRgd,
                colorOther: data.colorOther,
                supplierBankAccounts: parsedSupplierBankAccounts,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Supplier ${supplierId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Define guia selecionada de acordo com input com erro
          switch (true) {
            case firstError.path?.includes('name'):
              setSelectedMenuItem('GeneralInformation');
              break;
            default:
              break;
          }

          if (firstError.path === 'country') {
            firstError.path = 'name';
          }

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          if (!inputWithError) {
            // Exibe toast sobre o erro
            toastRef.current?.show({
              severity: 'error',
              summary: 'Permission error',
              detail:
                "There are some required fields, but you don't have permission to save or see them",
              life: ToastLife.ERROR,
            });
          } else {
            // Define foco no input
            inputWithError.focus();

            // Exibe toast sobre o erro
            toastRef.current?.show({
              severity: 'warn',
              summary: 'Enter all required fields',
              detail: `${error.errors.length} fields with errors`,
              life: ToastLife.WARN,
            });
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              supplierId ? 'updating' : 'creating'
            } supplier`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
        setShouldSubmit(false);
      }
    },
    [
      shouldSubmit,
      formRef,
      supplier.manufacturer,
      supplier.idSupplier,
      supplier.supplierBankAccounts,
      supplier.paymentAdvance,
      supplier.paymentBalance,
      supplier.versionLock,
      supplier.idCountry2?.idCountry,
      supplier.supplierCategories,
      supplier.supplierPorts,
      supplierId,
      toastRef,
      history,
      supplierContacts,
      createSupplierMutation,
      updateSupplierMutation,
      userPermissionsSupplierBankAccount.userFields,
    ],
  );

  useEffect(() => {
    if (supplierId) {
      loadSupplierData({
        variables: {
          idSupplier: supplierId,
        },
      });
    }
    return () => setSupplier({} as ISupplier);
  }, [loadSupplierData, supplierId]);

  useEffect(() => {
    if (shouldSubmit) {
      formRef.current?.submitForm();
    }
  }, [formRef, shouldSubmit]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          supplierName
            ? `${defaultPageTitle} - ${supplierName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
        hasImage={false}
      >
        {supplierData ? (
          supplier.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}

        {!!supplierId && supplier.taxmissing && (
          <Tag className="tag" value="Tax Missing" />
        )}

        {!!supplierId && supplier.outdated && (
          <Tag className="tag" value="Outdated" severity="info" />
        )}

        {!!supplierId && supplier.verified && (
          <Tag className="tag" value="Verified" severity="success" />
        )}

        <Image
          className="image"
          src={
            !uploadImageTempUrl
              ? imageUrl || imagePlaceholder
              : uploadImageTempUrl
          }
          alt="Supplier main image"
        />
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            setShouldSubmit(true);
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {((supplierId && !supplierLoading && supplier.active !== undefined) ||
        !supplierId) && (
        <CrudForm>
          <PageMenu menuWidth="183px">
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('GeneralInformation');
              }}
              selected={selectedMenuItem === 'GeneralInformation'}
              title="General Information"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Contact');
              }}
              selected={selectedMenuItem === 'Contact'}
              title="Contact"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Artwork');
              }}
              selected={selectedMenuItem === 'Artwork'}
              title="Artwork"
            />

            {!!supplierId && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('Assessment');
                }}
                selected={selectedMenuItem === 'Assessment'}
                title="Assessment"
              />
            )}

            {!!userPermissionsSupplierBankAccount.userFields && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('BankDetails');
                }}
                selected={selectedMenuItem === 'BankDetails'}
                title="Bank Details"
              />
            )}

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Products');
              }}
              selected={selectedMenuItem === 'Products'}
              title="Products"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Sats');
              }}
              selected={selectedMenuItem === 'Sats'}
              title="Sats"
            />
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('RNC');
              }}
              selected={selectedMenuItem === 'RNC'}
              title="RNC"
            />

            {supplier.idSupplier && (
              <PageMenuButton
                onClick={() => {
                  setSelectedMenuItem('Attachments');
                }}
                selected={selectedMenuItem === 'Attachments'}
                title="Attachments"
              />
            )}
          </PageMenu>
          <Form
            ref={formRef}
            initialData={supplier}
            onSubmit={handleCreateSupplierSubmit}
            className="overflow-hidden"
          >
            <GeneralInformation
              selected={selectedMenuItem === 'GeneralInformation'}
              supplier={supplier}
              setSupplier={setSupplier}
              roleEntityFields={userPermissionsSupplier.userFields}
            />

            <ArtworkFormat
              selected={selectedMenuItem === 'Artwork'}
              supplier={supplier}
              setSupplier={setSupplier}
              roleEntityFields={userPermissionsSupplier.userFields}
            />

            {!!supplierId && (
              <Assessment
                idSupplier={supplierId}
                selected={selectedMenuItem === 'Assessment'}
              />
            )}

            <Contact
              selected={selectedMenuItem === 'Contact'}
              supplierContacts={supplierContacts}
              setSupplierContacts={setSupplierContacts}
              roleEntityFields={userPermissionsSupplier.userFields}
            />

            <BankDetails
              selected={selectedMenuItem === 'BankDetails'}
              supplier={supplier}
              setSupplier={setSupplier}
              roleEntityFields={userPermissionsSupplierBankAccount.userFields}
            />

            <Products
              selected={selectedMenuItem === 'Products'}
              supplier={supplier}
              setSupplier={setSupplier}
              setPageLoading={setLoading}
              roleEntityFields={userPermissionsProduct.userFields}
              userPermissions={userPermissionsProduct.userPermissions}
              userPermissionsClientQuotation={
                userPermissionsClientQuotation.userPermissions
              }
            />

            <Sats
              selected={selectedMenuItem === 'Sats'}
              supplier={supplier}
              setSupplier={setSupplier}
              roleEntityFields={userPermissionsSat.userFields}
            />

            <Rncs selected={selectedMenuItem === 'RNC'} supplier={supplier} />

            <Attachments
              selected={selectedMenuItem === 'Attachments'}
              idSupplier={supplier.idSupplier}
              setPageLoading={setLoading}
            />
          </Form>
        </CrudForm>
      )}
      {(supplierLoading || loading) && <Loading />}
    </Container>
  );
};

export default Supplier;
