import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

export const supplierPaymentFiltersData: ColumnData[] = [
  {
    field: 'satCurrency',
    header: 'SAT Currency',
    value: undefined,
    advancedFilterField: 'idCurrency',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'abbreviation',
      optionValue: 'idCurrency',
      optionData: asyncSelectLoadCurrencies,
      isMulti: true,
    },
  },
  {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  {
    field: 'sunNumber',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'sunNumber',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  {
    field: 'nameSupplier',
    header: 'Supplier Name',
    advancedFilterField: 'nameSupplier',
  },
  {
    field: 'countryOfOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
  {
    field: 'exporter',
    header: 'Exporter',
    value: undefined,
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'clientOrder',
    header: 'Client Order',
    advancedFilterField: 'clientOrder',
  },
  {
    field: 'satStatus',
    header: 'SAT Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'startDate',
    header: 'Start Date',
    advancedFilterField: 'startDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    value: undefined,
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  {
    field: 'estimatedAdvanceRequest',
    header: 'Purchase Estimated Advance Request',
    advancedFilterField: 'estimatedAdvanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'estimatedBalanceRequest',
    header: 'Purchase Estimated Balance Request',
    advancedFilterField: 'estimatedBalanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'paidAdvancePaymentDate',
    header: 'Paid Advance Date',
    advancedFilterField: 'paidAdvancePaymentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'paidBalancePaymentDate',
    header: 'Paid Balance Date',
    advancedFilterField: 'paidBalancePaymentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'idStatusPurchasePayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    value: undefined,
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  {
    field: 'sellerEstimatedAdvanceRequest',
    header: 'Seller Estimated Advance Request',
    advancedFilterField: 'sellerEstimatedAdvanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'sellerEstimatedBalanceRequest',
    header: 'Seller Estimated Balance Request',
    advancedFilterField: 'sellerEstimatedBalanceRequest',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'receivedAdvanceReceivedDate',
    header: 'Received Advance Date',
    advancedFilterField: 'receivedAdvanceReceivedDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'receivedBalanceReceivedDate',
    header: 'Received Balance Date',
    advancedFilterField: 'receivedBalanceReceivedDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'idStatusSellingPayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
];
