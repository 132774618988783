import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ProgressBar } from 'primereact/progressbar';
import {
  FileUpload as PrimeFileUpload,
  FileUploadHandlerEvent,
  FileUploadProps,
  ItemTemplateOptions,
  FileUploadSelectEvent,
} from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import crypto from 'crypto';
import axios from 'axios';
import {
  fileExceedMaxSize,
  maxFileSizeInBytes,
} from '../../utils/uploadFileSize';
import { useRefHook } from '../../hooks/useRefHook';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';
import satApi from '../../services/axios/satApi';
import {
  FileUploadRef,
  FileUploadResponse,
  GetAttachmentUploadPresignedUrlResponse,
  SelectedFile,
} from './interfaces';
import Loading from '../Loading';

interface IFileUploadProps extends FileUploadProps {
  onConfirm?(data: FileUploadResponse[]): void;
  uploadInProgress?: boolean;
  ref?: Ref<FileUploadRef>;
  showFullscreenLoading?: boolean;
}

const FileUpload: React.FC<IFileUploadProps> = forwardRef(
  (
    {
      onConfirm,
      multiple,
      className,
      uploadInProgress,
      disabled,
      mode = 'advanced',
      chooseOptions = { label: 'Choose' },
      showFullscreenLoading,
      auto,
      ...rest
    },
    ref,
  ) => {
    const fileUploadRef = useRef<PrimeFileUpload>(null);
    const { toastRef, showError } = useRefHook();

    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

    useImperativeHandle(
      ref,
      () => ({
        choose: () => {
          const uploadInput = fileUploadRef.current?.getInput();
          if (uploadInput) uploadInput.click();
        },
        loading,
        clear: () => {
          fileUploadRef.current?.clear();
        },
      }),
      [loading],
    );

    function getUserFriendlyErrorMessage(error: string | null) {
      if (error && error.includes('$Content-Type')) {
        return 'Invalid file type';
      }

      return 'Unknown error. Please try again or contact support.';
    }

    /**
     * Cria os arquivos de attachment
     * @param event Evento de upload
     */
    async function myUploader(event: FileUploadHandlerEvent) {
      if (onConfirm && selectedFiles.length) {
        try {
          setLoading(true);
          // Busca apenas os que nao foram enviados para o bucket
          const filesToUpload = event.files.filter(
            (_, index) => !selectedFiles[index].serverName,
          );

          if (filesToUpload.length) {
            const typesToUpload = filesToUpload.map(file => file.type);

            const presignedUrlData = await satApi.post(
              'attachment/presigned-url/upload',
              {
                fileTypes: typesToUpload,
              },
            );

            const presignedUrlsByTypes = presignedUrlData.data;

            const uploadPromises = filesToUpload.map(async file => {
              const { fields, url } = presignedUrlsByTypes.find(
                (item: GetAttachmentUploadPresignedUrlResponse) =>
                  item.fileType === file.type,
              );

              // Busca indice do arquivo na listagem do evento, pois o array
              // do map esta filtrado
              const index = event.files.findIndex(item => item === file);

              // Limpa erro do arquivo para evitar que continue sendo exibido
              // durante novo upload
              selectedFiles[index].error = undefined;

              // Gera nome unico para o arquivo
              const fileHash = crypto.randomBytes(16).toString('hex');
              const hashedFileName = `${fileHash}.${file.name
                .split('.')
                .pop()}`;

              const formData = new FormData();
              Object.keys(fields).forEach(key => {
                formData.append(key, fields[key]);
              });

              // Altera o parametro ${filename} pelo nome do arquivo, para que seja
              // possivel enviar o arquivo com o nome correto para o bucket
              formData.set(
                'key',
                // eslint-disable-next-line no-template-curly-in-string
                fields.key.replace('${filename}', hashedFileName),
              );

              // Salva o nome original do arquivo para download posterior caso
              // necessario
              formData.append(
                'Content-Disposition ',
                `inline; filename=${file.name}`,
              );

              formData.append('Content-Type', file.type);
              formData.append('file', file);

              return axios
                .post(url, formData, {
                  responseType: 'text',
                  onUploadProgress: progressEvent => {
                    const progress = (
                      (progressEvent.progress ?? 0) * 100
                    ).toFixed(2);
                    selectedFiles[index].progress = progress;
                    // Garante que progresso seja atualizado na tela
                    setSelectedFiles([...selectedFiles]);
                  },
                })
                .then(() => {
                  selectedFiles[index].serverName = hashedFileName;
                  selectedFiles[index].originalName = file.name.replace(
                    /\.[^/.]+$/,
                    '',
                  );
                })
                .catch(err => {
                  // Como resposta do S3 vem em XML, eh necessario fazer o parse
                  // para buscar a mensagem de erro
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(
                    err.response.data,
                    'text/xml',
                  );

                  const errorMessage =
                    xmlDoc.getElementsByTagName('Message')[0].textContent;

                  const userFriendlyErrorMessage =
                    getUserFriendlyErrorMessage(errorMessage);
                  selectedFiles[index].error = userFriendlyErrorMessage;

                  // Remove valor de progress para que usuario saiba que arquivo
                  // nao foi enviado
                  selectedFiles[index].progress = undefined;
                  setSelectedFiles([...selectedFiles]);
                });
            });

            await axios.all(uploadPromises);

            if (selectedFiles.some(item => !item.serverName)) {
              showError({
                summary: 'Error while uploading files',
                detail: 'One or more files failed to upload. Please try again.',
              });

              return;
            }
          }

          const filesResponse = selectedFiles.map(file => {
            return {
              serverName: file.serverName,
              originalName: file.originalName,
              fileUrl: file.objectURL,
            };
          });

          onConfirm(filesResponse as FileUploadResponse[]);

          // Se o modo for basic, eh necessario limpar arquivos do componente
          // para evitar que sejam enviados novamente no proximo clique
          if (mode === 'basic') {
            event.options.clear();
          }
        } catch (error) {
          const summary = 'Error while uploading files';
          // Se for um erro da API
          if (error.response) {
            showError({
              summary,
              detail: error.response.data.error,
            });
          } else {
            showError({
              summary,
              detail: 'Please try again or contact support.',
            });
          }
        } finally {
          setLoading(false);
        }
      }
    }

    function handleRemoveFile(props: ItemTemplateOptions, e: React.MouseEvent) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles.splice(props.index, 1);
      setSelectedFiles(newSelectedFiles);

      // Comportamento padrao do componente
      props.onRemove(e);
    }

    /**
     * Renderiza lista de itens
     * @param file Dados do arquivo
     * @param props Props do componente
     * @returns Itens renderizados
     */
    const itemTemplate = (file: any, props: ItemTemplateOptions) => {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '90%' }}>
            <img
              alt={file.name}
              src={
                file.type?.includes('image') ? file.objectURL : imagePlaceholder
              }
              width={100}
            />
            <span className="w-full ml-3 mr-3">
              <InputText
                className="w-full"
                name="fileName"
                defaultValue={file.name.replace(/\.[^/.]+$/, '')}
                onChange={e => {
                  Object.defineProperty(file, 'name', {
                    writable: true,
                    value: e.target.value.trim()
                      ? `${e.target.value.trim()}.${e.currentTarget.placeholder
                          .split('.')
                          .pop()}`
                      : e.currentTarget.placeholder,
                  });
                }}
                placeholder={file.name}
                disabled={disabled || file.progress !== undefined}
              />
              {file.progress !== undefined && (
                <ProgressBar
                  className="mt-2"
                  value={file.progress}
                  style={{ height: '21px' }}
                />
              )}
              {!!file.error && <small className="p-error">{file.error}</small>}
            </span>
          </div>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
            onClick={e => handleRemoveFile(props, e)}
            disabled={disabled}
          />
        </div>
      );
    };

    function renderChooseOptions() {
      if (!loading || multiple) return chooseOptions;

      return {
        ...chooseOptions,
        icon: 'pi pi-spin pi-spinner',
      };
    }

    function onSelect(e: FileUploadSelectEvent) {
      if (!selectedFiles.length) {
        setSelectedFiles(e.files);
      } else {
        // Busca apenas os arquivos que nao estao na lista de arquivos
        // selecionados
        const newFiles = e.files.slice(selectedFiles.length);

        setSelectedFiles([...selectedFiles, ...newFiles]);
      }

      if (auto) {
        // Se o upload for automatico, eh necessario esperar o componente
        // atualizar o estado com os novos arquivos antes de iniciar o upload
        setTimeout(() => {
          fileUploadRef.current?.upload();
        }, 1);
      }
    }

    return (
      <>
        <PrimeFileUpload
          ref={fileUploadRef as any}
          multiple={multiple}
          maxFileSize={maxFileSizeInBytes}
          onValidationFail={e => fileExceedMaxSize(e, toastRef)}
          customUpload
          uploadHandler={myUploader}
          emptyTemplate={
            multiple ? (
              <p className="m-0">Drag and drop files to here to upload.</p>
            ) : undefined
          }
          itemTemplate={multiple ? itemTemplate : undefined}
          cancelLabel="Clear All"
          uploadLabel="Confirm"
          uploadOptions={{
            icon:
              uploadInProgress || loading
                ? 'pi pi-spin pi-spinner'
                : 'pi pi-check',
          }}
          auto={auto}
          mode={mode}
          {...rest}
          disabled={disabled || loading || uploadInProgress}
          className={`s-file-upload ${className ?? ''}`}
          chooseOptions={renderChooseOptions()}
          onSelect={e => onSelect(e)}
          onClear={() => setSelectedFiles([])}
        />
        {showFullscreenLoading && loading && <Loading />}
      </>
    );
  },
);

export default FileUpload;
