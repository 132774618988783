import styled from 'styled-components';

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  .grid-filters {
    display: flex;
    .p-inputtext {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .grid-header-buttons {
    display: flex;
    button {
      margin-right: 12px;
    }
  }

  .gridSearch {
    height: 36px;
    margin-right: 8px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    width: 180px;
  }
`;
