import { gql } from '@apollo/client';

// Query para listar Beneficiaries
export const listBeneficiariesQuery = gql`
  query listAllBeneficiaries(
    $listAllBeneficiariesInput: ListAllBeneficiariesInput!
  ) {
    listAllBeneficiaries(data: $listAllBeneficiariesInput) {
      data {
        idBeneficiary
        name
        email
        phone
        description
        active
      }
      items
    }
  }
`;

export const exportBeneficiariesQuery = gql`
  query exportBeneficiaries($data: ExportBeneficiariesInput!) {
    exportBeneficiaries(data: $data)
  }
`;
