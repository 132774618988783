import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  hasError: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--grey);
  padding: 4px;
  height: 46px;
  width: 100%;

  border: solid 1px transparent;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.2s;
  * {
    box-shadow: none !important;
  }
  ${props =>
    props.readOnly &&
    css`
      background: none;
      .p-dropdown-trigger {
        display: none;
      }

      .p-dropdown-label {
        color: #000 !important;
      }
    `}
  ${props =>
    props.hasError &&
    css`
      border-color: #c53030;
    `}
  ${props =>
    props.isFocused &&
    css`
      color: var(--blue);
      border-color: var(--blue);
    `}
  ${props =>
    props.isFilled &&
    css`
      color: var(--blue);
    `}
  .p-dropdown {
    display: flex !important;
    flex: 1;
    height: 19px;

    background: transparent;
    border: none;
    outline: none;
    color: #000;

    &::placeholder {
      color: var(--grey-scale);
    }
    * {
      padding: 0;
    }
  }

  svg {
    margin-right: 4px;
  }

  span {
    display: flex;
  }

  p {
    color: var(--grey-scale);
    display: flex;

    ${props =>
      props.required &&
      css`
        &::after {
          margin-top: 4px;
          margin-left: 8px;
          width: 8px;
          height: 8px;
          background: var(--red);
          content: '';
          border-radius: 50%;
        }
      `}
  }

  small {
    margin-top: 5px;
  }
`;
