export const dashboardsIdModule = 13;

export const registerDashboardRoles = {
  idEntity: 58,
  idModule: dashboardsIdModule,
};

export const artworkDashboardRoles = {
  idEntity: 60,
};

export const documentsDashboardRoles = {
  idEntity: 59,
  idModule: dashboardsIdModule,
};

export const clientPaymentDashboardRoles = {
  idEntity: 61,
  idModule: dashboardsIdModule,
};

export const shipmentDashboardRoles = {
  idEntity: 64,
  idModule: dashboardsIdModule,
};

export const supplierPaymentDashboardRoles = {
  idEntity: 62,
  idModule: dashboardsIdModule,
};

export const productionDashboardRoles = {
  idEntity: 63,
  idModule: dashboardsIdModule,
};

export const rncDashboardRoles = {
  idEntity: 65,
  idModule: dashboardsIdModule,
};
