import React from 'react';

import { Container } from './styles';

interface IEmptyProps {
  message?: string;
  className?: string;
  position?: 'left' | 'right' | 'center';
}

const Empty: React.FC<IEmptyProps> = ({
  children,
  message,
  className,
  position,
}) => {
  return (
    <Container className={`s-empty ${className ?? ''}`} position={position}>
      {children}
      <div>{message ?? 'No data'}</div>
    </Container>
  );
};

export default Empty;
