import { Toast } from 'primereact/toast';
import { RefObject } from 'react';
import ToastLife from '../shared/enums/toastLife';

// Tamanho maximo de arquivo em bytes
export const maxFileSizeInBytes = process.env.REACT_APP_LIMIT_SIZE_UPLOAD_FILE
  ? parseInt(process.env.REACT_APP_LIMIT_SIZE_UPLOAD_FILE, 10)
  : 314572800;

/**
 * Valida se arquivo ultrapassa limite maximo
 * @param file Arquivo
 */
export function fileExceedMaxSize(
  file: File,
  toastRef: RefObject<Toast>,
): boolean {
  if (file.size > maxFileSizeInBytes) {
    toastRef.current?.show({
      severity: 'error',
      summary: 'The file is too large',
      detail: `Allowed maximum size is ${maxFileSizeInBytes / (1024 * 1024)}MB`,
      life: ToastLife.ERROR,
    });
    return true;
  }
  return false;
}
