import pagination from '../../config/pagination';
import {
  UpdateLogArchivesReducer,
  UpdateLogArchivesReducerAction,
  UpdateLogArchivesReducerActionKind,
} from './interfaces';

export const updateLogArchivesReducerInitialState: UpdateLogArchivesReducer = {
  dialogVisible: false,
  lazyParams: {
    first: pagination.initialLazyParams.first,
    rows: pagination.initialLazyParams.rows,
    page: pagination.initialLazyParams.page,
    sortField: 'updatedAt',
    sortOrder: -1,
  },
  expandedRows: [],
};

export const updateLogArchivesReducer = (
  state: UpdateLogArchivesReducer,
  action: UpdateLogArchivesReducerAction,
): UpdateLogArchivesReducer => {
  switch (action.type) {
    case UpdateLogArchivesReducerActionKind.OPEN_DIALOG:
      return { ...state, dialogVisible: true };

    case UpdateLogArchivesReducerActionKind.CLOSE_DIALOG:
      return updateLogArchivesReducerInitialState;

    case UpdateLogArchivesReducerActionKind.SET_CONTENT:
      return { ...state, content: action.payload?.content };

    case UpdateLogArchivesReducerActionKind.CHANGE_PAGE:
      return {
        ...state,
        lazyParams: {
          ...state.lazyParams,
          ...action.payload?.pageParams,
        },
      };

    case UpdateLogArchivesReducerActionKind.CHANGE_SORT:
      return {
        ...state,
        lazyParams: {
          ...state.lazyParams,
          ...action.payload?.sortParams,
        },
      };

    case UpdateLogArchivesReducerActionKind.SET_EXPANDED_ROWS:
      if (Array.isArray(action.payload?.rowToggleParams?.data)) {
        return {
          ...state,
          expandedRows: action.payload?.rowToggleParams?.data,
        };
      }

      return {
        ...state,
        expandedRows: [],
      };
    default:
      throw new Error('Invalid action type');
  }
};
