import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { searchDelayMiliseconds } from '../../../config/pagination';
import { useRefHook } from '../../../hooks/useRefHook';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import getFieldPermission from '../../../utils/getFieldPermission';
import { userGroupRoles } from '../../../shared/roles/userGroup';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { useAuth } from '../../../hooks/useAuth';
import userHasPermission from '../../../utils/userHasPermission';

const UserGroups: React.FC = () => {
  // const gridColumnsName = '@SAT:userGroupsGridColumns';

  const { toastRef } = useRefHook();

  const history = useHistory();

  const isMounted = useRef(false);

  const [pageLoading, setPageLoading] = useState(false);

  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  const { roles } = useAuth();

  const userPermissions = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      userGroupRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const fieldsPermissions = useMemo(() => {
    return {
      name: getFieldPermission(
        userGroupRoles.fields.name,
        userPermissions.userFields,
      ),
      type: getFieldPermission(
        userGroupRoles.fields.type,
        userPermissions.userFields,
      ),
    };
  }, [userPermissions.userFields]);

  const permissions = useMemo(() => {
    return {
      create: userHasPermission(
        userGroupRoles.permissions.createUserGroup,
        userPermissions.userPermissions,
      ),
      delete: userHasPermission(
        userGroupRoles.permissions.deleteUserGroup,
        userPermissions.userPermissions,
      ),
    };
  }, [userPermissions.userPermissions]);

  const columns = useMemo(() => {
    const columnList = [];
    if (fieldsPermissions.name.view) {
      columnList.push({ field: 'name', header: 'User Group' });
    }
    if (fieldsPermissions.name.view) {
      columnList.push({ field: 'type2.description', header: 'Type' });
    }
    return columnList;
  }, [fieldsPermissions.name.view]);

  const [globalFilter, setGlobalFilter] = useState('');

  const [selectedUserGroups, setSelectedUserGroups] = useState([]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar User Groups
  const listUserGroupsQuery = gql`
    query listAllUserGroupsQuery($data: ListAllUserGroupsInput!) {
      listAllUserGroups(data: $data) {
        items
        data {
          idUserGroup
          ${fieldsPermissions.name.view ? 'name' : ''}
          ${
            fieldsPermissions.type.view
              ? `type
                 type2 {
                   idDomain
                   description
                 }`
              : ''
          }

        }
      }
    }
  `;

  const deleteUserGroupsQuery = gql`
    mutation DeleteUserGroups($userGroupIds: [Int]!) {
      deleteUserGroups(userGroupIds: $userGroupIds)
    }
  `;

  const [deleteUserGroupsMutation] = useMutation(deleteUserGroupsQuery);

  const {
    loading: userGroupsLoading,
    data: userGroupsData,
    refetch: userGroupsRefetch,
  } = useQuery(listUserGroupsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting User Groups',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function handleColumnHeader(headerName: string) {
    return <span className="custom-header">{headerName}</span>;
  }

  const dynamicColumns = columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          sortable
          style={{ width: '100%' }}
          header={handleColumnHeader(col.header)}
        />
      )
    );
  });

  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/userGroups/${e.data.idUserGroup}`);
  }

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedUserGroups.length} User Groups?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        const idsToDelete = selectedUserGroups.map((a: any) => a.idUserGroup);
        try {
          await deleteUserGroupsMutation({
            variables: {
              userGroupIds: idsToDelete,
            },
          });
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedUserGroups.length} User Groups`,
            life: ToastLife.SUCCESS,
          });

          setSelectedUserGroups([]);
          await userGroupsRefetch();
        } catch (error) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting User Groups',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader
        title="List of User Groups"
        fixedStickyButtons={fixedStickyButtons}
      >
        {permissions.create && (
          <MainButton
            className="mainButton"
            label="New User Group"
            onClick={() => history.push('/userGroups/create')}
          />
        )}

        {permissions.delete && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedUserGroups.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a User Group"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        name="userGroups"
        lazy
        totalRecords={
          !userGroupsData ? 0 : userGroupsData.listAllUserGroups?.items
        }
        value={
          !userGroupsData ? undefined : userGroupsData.listAllUserGroups?.data
        }
        globalFilter={globalFilter}
        emptyMessage="No User Group found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedUserGroups}
        onSelectionChange={e => setSelectedUserGroups(e.value)}
      >
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3rem' }}
          reorderable={false}
        />

        {dynamicColumns}
      </Grid>
      {(pageLoading || userGroupsLoading) && <Loading />}
    </div>
  );
};
export default UserGroups;
