export const satsExportRoles = {
  idEntity: 56,
  idModule: 12,
  fields: {
    importStatus: 2049,
    registerStatus: 2201,
    artworkStatus: 2148,
    productionStatus: 2077,
    shipmentStatus: 2205,
    documentsStatus: 2054,
    supplierPaymentStatus: 2203,
    clientPaymentStatus: 2204,
    shipmentResume: 2149,
    shipment: 2050,
    statusSat: 2051,
    satNumber: 2052,
    infoSupplier: 2053,
    satCreatedAt: 2055,
    confirmOrderPurchase: 2056,
    typeOfOrder: 2057,
    replacementFrom: 2058,
    consolidatedWith: 2059,
    exporter: 2060,
    manufacturer: 2061,
    countryOfOrigin: 2062,
    clientNotify: 2063,
    mainProduct: 2064,
    category: 2065,
    productionTime: 2066,
    sentFinalArtWork: 2067,
    sketchesReceived: 2068,
    artworkDone: 2069,
    packagesNotes: 2070,
    estimatedInspectionDate: 2071,
    realInspectionDate: 2072,
    inspectionScheduled: 2073,
    rework: 2074,
    reworkDate: 2075,
    reworkCost: 2076,
    inspectionApproval: 2078,
    estimatedShipmentDate: 2079,
    realEtd: 2080,
    portLoading: 2081,
    realEta: 2082,
    portDischarge: 2083,
    purchaseIncoterm: 2084,
    authorizationRequested: 2085,
    shipmentAuthorized: 2086,
    bookingConfirmed: 2087,
    shipmentConfirmed: 2088,
    freightForwarderOrigin: 2089,
    freightForwarderDestination: 2090,
    trakingNumber: 2091,
    courier: 2092,
    docsReceived: 2093,
    exportNotes: 2094,
    ctnrs: 2095,
    quantityPiBySun: 2096,
    quantityCiBySun: 2097,
    purchaseTotalCotPi: 2098,
    purchaseTotalCotCi: 2099,
    paymentSellingIncoterm: 2100,
    sellerTotalSunPi: 2101,
    sellerTotalSunCi: 2102,
    estimatedGrossMargin: 2103,
    grossMargin: 2104,
    estimatedNetMargin: 2105,
    estimatedNetProfit: 2106,
    purchasePaymentTermCondition: 2107,
    purchasePaidAdvance: 2108,
    purchasePaidBalance: 2109,
    totalPaid: 2110,
    totalCotRemain: 2111,
    freightCost: 2112,
    freightExtraCost: 2113,
    startProductionTiming: 2114,
    realProductionTime: 2115,
    authorizationTiming: 2116,
    authorizationBookingTiming: 2117,
    authorizationShipmentTiming: 2118,
    bookingShipmentTiming: 2119,
    preShipmentTiming: 2120,
    transitTime: 2121,
    hblFinalTiming: 2122,
    originalDocsTiming: 2123,
    deliveryAtClientTiming: 2124,
    totalForeignTradeTiming: 2125,
    samples: 2126,
    samplesLeadTime: 2127,
    updatesEn: 2128,
    purchaseUser: 2129,
    exportUser: 2130,
    importUser: 2131,
    designUser: 2132,
    financialUser: 2133,
    exportStatus: 2147,
    shipmentSunNumber: 2224,
    supplierName: 2237,
  },
  permissions: {
    idPermissionExportSatsExport: 191,
  },
};
