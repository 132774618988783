import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiDownload, FiEdit2 } from 'react-icons/fi';
import Button from '../../../../../../components/Button';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import Loading from '../../../../../../components/Loading';
import MainButton from '../../../../../../components/MainButton';
import TransparentButton from '../../../../../../components/TransparentButton';
import pagination from '../../../../../../config/pagination';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { RncOtherExpenseType } from '../../../../../../shared/enums/rncOtherExpenseType';
import { satRncOtherExpensesRoles } from '../../../../../../shared/roles/sat';
import getUserFieldsAndPermissionsByEntity from '../../../../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { getUTC } from '../../../../../../utils/dateUtil';
import { ISat, ISatRnc } from '../../../interfaces';
import AddOtherExpenseModal from './AddOtherExpenseModal';
import { SatOtherExpense } from './interfaces';
import {
  generateSatOtherExpenseDownloadQuery,
  listAllSatOtherExpensesQuery,
  requestSatOtherExpenseQuery,
} from './queries';
import { Container } from './styles';
import { GridActions } from '../../../../../../components/Grid/styles';

interface IRncOtherExpenseProps {
  sat: ISat;
  satRnc: ISatRnc;
  selected: boolean;
  isReadOnly: boolean;
  otherExpenses: SatOtherExpense[] | undefined;
  otherExpenseType: RncOtherExpenseType;
  setOtherExpenses: React.Dispatch<
    React.SetStateAction<SatOtherExpense[] | undefined>
  >;
}

const RncOtherExpense: React.FC<IRncOtherExpenseProps> = ({
  sat,
  satRnc,
  selected,
  isReadOnly,
  otherExpenses,
  setOtherExpenses,
  otherExpenseType,
}) => {
  const { showSuccess, showError } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de SAT
  const {
    idPermissionRncCreditNoteDownload,
    idPermissionRncDebitNoteDownload,
  } = satRncOtherExpensesRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsSat = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      satRncOtherExpensesRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const isCredit = otherExpenseType === RncOtherExpenseType.CREDIT_NOTE;

  const fieldPrefix =
    otherExpenseType === RncOtherExpenseType.CREDIT_NOTE ? 'CN' : 'DN';

  const title =
    otherExpenseType === RncOtherExpenseType.CREDIT_NOTE
      ? 'Credit Note'
      : 'Debit Note';

  const fullTitle =
    otherExpenseType === RncOtherExpenseType.CREDIT_NOTE
      ? 'Credit Note - Client'
      : 'Debit Note - Supplier';

  const [pageLoading, setPageLoading] = useState<boolean>();

  const [otherExpenseToEdit, setOtherExpenseToEdit] =
    useState<SatOtherExpense>();

  const [displayAddOtherExpenseModal, setDisplayAddItemModal] = useState(false);

  const [requestSatOtherExpenseMutation] = useMutation(
    requestSatOtherExpenseQuery,
  );

  const [
    loadOtherExpensesData,
    { loading: otherExpensesLoading, refetch: refetchOtherExpenses },
  ] = useLazyQuery(listAllSatOtherExpensesQuery, {
    variables: {
      data: {
        pagination: {
          _page: pagination.initialLazyParams.page + 1,
          _limit: 0,
        },
        idSatRnc: satRnc.idSatRnc,
        idType: otherExpenseType,
      },
    },
    onCompleted: response => {
      if (response.listAllSatOtherExpenses.data) {
        setOtherExpenses(response.listAllSatOtherExpenses.data);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting SAT Other Expenses',
        detail: errorData.message,
      });
    },
  });

  // Gera Other Expenses download
  const [generateSatOtherExpenseDownload, { loading: creditNoteLoading }] =
    useLazyQuery(generateSatOtherExpenseDownloadQuery, {
      onCompleted: response => {
        if (response.errors) {
          showError({
            summary: `Error while generating Sat RNC ${title} Download`,
            detail: response.errors[0].message,
          });
        } else {
          window.open(response.generateSatOtherExpenseDownload, '_blank');
        }
      },

      onError: error => {
        showError({
          summary: `Error while generating Sat RNC ${title} Download`,
          detail: error.message,
        });
      },
    });

  useEffect(() => {
    if (!otherExpenses && selected) {
      loadOtherExpensesData();
    }
  }, [loadOtherExpensesData, otherExpenses, selected]);

  useEffect(() => {
    if (!displayAddOtherExpenseModal) {
      setOtherExpenseToEdit({});
    }
  }, [displayAddOtherExpenseModal]);

  function onAddOtherExpenseClick() {
    setDisplayAddItemModal(true);
  }

  function handleEditItem(rowData: SatOtherExpense) {
    setOtherExpenseToEdit(rowData);
    setDisplayAddItemModal(true);
  }

  // function handleDownloadItem(rowData: SatOtherExpense) {
  //   alert('download');
  // }

  function validateRequest(otherExpense: SatOtherExpense): boolean {
    if (otherExpense.idSatForeignTradeSupplierDestination) {
      return true;
    }
    showError({
      summary: `Fields ${fieldPrefix} Applied On is required!`,
    });
    return false;
  }

  const sendRequest = useCallback(
    async (otherExpense: SatOtherExpense) => {
      setPageLoading(true);
      try {
        await requestSatOtherExpenseMutation({
          variables: {
            idSatOtherExpense: otherExpense.idSatOtherExpense,
          },
        });
        showSuccess({
          summary: `${title} Requested`,
        });
        refetchOtherExpenses();
      } catch (error) {
        showError({
          summary: `Error while sending request for ${title}`,
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      requestSatOtherExpenseMutation,
      refetchOtherExpenses,
      showError,
      showSuccess,
      title,
    ],
  );

  function handleRequest(rowData: SatOtherExpense) {
    if (validateRequest(rowData)) {
      confirmDialog({
        message: `Do you confirm to request ${title} from RNC?`,
        header: `Request ${title} Confirmation`,
        icon: 'pi pi-info-circle',
        accept: () => {
          sendRequest(rowData);
        },
      });
    }
  }

  const gridActions = (rowData: SatOtherExpense) => {
    return (
      <GridActions>
        <button
          type="button"
          onClick={() => handleEditItem(rowData)}
          disabled={!!rowData.cancelDate || isReadOnly}
        >
          <FiEdit2 size={20} />
        </button>
        {/* Export list of SATs */}
        {userHasPermission(
          isCredit
            ? idPermissionRncCreditNoteDownload
            : idPermissionRncDebitNoteDownload,
          userPermissionsSat.userPermissions,
        ) && (
          <TransparentButton
            type="button"
            onClick={() =>
              generateSatOtherExpenseDownload({
                variables: {
                  data: {
                    idSatOtherExpense: rowData.idSatOtherExpense,
                    idSat: sat.idSat,
                    idSatRnc: satRnc.idSatRnc,
                  },
                },
              })
            }
            disabled={!rowData.confirmationDate}
          >
            <FiDownload size={20} />
          </TransparentButton>
        )}
      </GridActions>
    );
  };

  function getTotalBody(rowData: any) {
    return `$${rowData.total?.toLocaleString('pt', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }

  function getDescriptionBody(rowData: SatOtherExpense) {
    const { description } = rowData;
    const abbreviation = description?.substring(0, 25);
    return (
      <div>
        <Tooltip target=".tooltip" />
        <span className="tooltip" data-pr-tooltip={description}>
          {abbreviation}
        </span>
      </div>
    );
  }

  function getDateFieldBody(rowData: SatOtherExpense) {
    if (rowData.cancelDate) {
      return new Date(rowData.cancelDate).toLocaleDateString();
    }
    return '';
  }

  function getRequestBody(rowData: SatOtherExpense) {
    if (rowData.requestDate) {
      return getUTC(rowData.requestDate).toLocaleDateString();
    }
    return (
      <Button
        type="button"
        label={title}
        onClick={() => handleRequest(rowData)}
        disabled={!!rowData.cancelDate || isReadOnly}
      />
    );
  }

  return (
    <Container>
      <Dialog
        header={`${
          otherExpenseToEdit?.idSatOtherExpense ? 'Manage' : 'Add'
        } ${fullTitle}`}
        visible={displayAddOtherExpenseModal}
        onHide={() => setDisplayAddItemModal(false)}
      >
        <AddOtherExpenseModal
          sat={sat}
          title={title}
          satRnc={satRnc}
          fieldPrefix={fieldPrefix}
          otherExpenseType={otherExpenseType}
          satOtherExpense={otherExpenseToEdit}
          otherExpensesRefetch={refetchOtherExpenses}
          setDisplayModal={setDisplayAddItemModal}
        />
      </Dialog>
      <FormTitle className="col-12 md:col-12 mt-5" name={title}>
        <MainButton
          type="button"
          label="Add Item"
          onClick={() => onAddOtherExpenseClick()}
          disabled={isReadOnly}
        />
      </FormTitle>
      <Grid
        className="s-internal-datatable"
        emptyMessage={`No ${title} Found`}
        loading={otherExpensesLoading}
        value={otherExpenses}
        paginator={false}
        responsiveLayout="scroll"
      >
        <Column field="actions" style={{ width: '6rem' }} body={gridActions} />
        <Column
          field="requestDate"
          header="Request"
          style={{ width: '12rem' }}
          body={getRequestBody}
        />
        <Column
          field="number"
          header={`${fieldPrefix} Number`}
          style={{ width: '8rem' }}
        />
        <Column
          field="idStatus2.description"
          header={`${fieldPrefix} Status`}
          style={{ width: '12rem' }}
        />
        <Column
          field="description"
          header={`${fieldPrefix} Description`}
          style={{ width: '14rem' }}
          body={getDescriptionBody}
        />
        <Column
          field="idCurrency2.abbreviation"
          header={`${fieldPrefix} Currency`}
          style={{ width: '8rem' }}
        />
        <Column
          field="total"
          header={`Total ${fieldPrefix}`}
          style={{ width: '8rem' }}
          body={getTotalBody}
        />
        <Column
          field="appliedOn"
          header={`${fieldPrefix} Applied On`}
          style={{ width: '15rem' }}
        />
        <Column
          field="cancelDate"
          header="Cancellation Date"
          style={{ width: '10rem' }}
          body={getDateFieldBody}
        />
      </Grid>
      {(pageLoading || creditNoteLoading) && <Loading />}
    </Container>
  );
};
export default RncOtherExpense;
