// eslint-disable-next-line no-shadow
export enum ProductImageType {
  HQ = 'hq',
  COLOUR_MAIN = 'colour_main',
  PRODUCTS = 'products',
  HRP_MAIN = 'hrp_main',
  HRP = 'hrp',
  COLOUR = 'colour',
  PRINTING_MAIN = 'printing_main',
  PRINTING = 'printing',
  MAIN_DIE_CUT = 'die_cut_main',
  DIE_CUT = 'die_cut',
  MAIN_PACKAGE = 'packages_main',
  TECH_PACK = 'tech_pack',
  TECHNICAL_FILE = 'technical_file',
}
