import { gql } from '@apollo/client';

export const createBeneficiaryQuery = gql`
  mutation CreateBeneficiary($data: CreateBeneficiaryInput!) {
    createBeneficiary(data: $data) {
      idBeneficiary
    }
  }
`;

export const getBeneficiaryQuery = gql`
  query GetBeneficiary($idBeneficiary: Int!) {
    getBeneficiary(idBeneficiary: $idBeneficiary) {
      active
      createdAt
      createdBy2 {
        firstName
        lastName
      }
      description
      idBeneficiary
      name
      phone
      email
      updatedAt
      updatedBy2 {
        firstName
        lastName
      }
      versionLock
    }
  }
`;

export const updateBeneficiaryQuery = gql`
  mutation UpdateBeneficiary($data: UpdateBeneficiaryInput!) {
    updateBeneficiary(data: $data) {
      idBeneficiary
    }
  }
`;
