import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { PortType } from '../../../shared/enums/domains';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadPortsOptions } from '../../../shared/querys/port';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IExportGridColumnData {
  importStatus: ColumnData;
  registerStatus: ColumnData;
  artworkStatus: ColumnData;
  productionStatus: ColumnData;
  shipmentStatus: ColumnData;
  documentsStatus: ColumnData;
  supplierPaymentStatus: ColumnData;
  clientPaymentStatus: ColumnData;
  exportStatus: ColumnData;
  shipmentResume: ColumnData;
  shipment: ColumnData;
  statusSat: ColumnData;
  satNumber: ColumnData;
  infoSupplier: ColumnData;
  satCreatedAt: ColumnData;
  confirmOrderPurchase: ColumnData;
  typeOfOrder: ColumnData;
  replacementFrom: ColumnData;
  consolidatedWith: ColumnData;
  exporter: ColumnData;
  manufacturer: ColumnData;
  countryOfOrigin: ColumnData;
  clientNotify: ColumnData;
  mainProduct: ColumnData;
  category: ColumnData;
  productionTime: ColumnData;
  sentFinalArtWork: ColumnData;
  sketchesReceived: ColumnData;
  artworkDone: ColumnData;
  packagesNotes: ColumnData;
  estimatedInspectionDate: ColumnData;
  realInspectionDate: ColumnData;
  inspectionScheduled: ColumnData;
  rework: ColumnData;
  reworkDate: ColumnData;
  reworkCost: ColumnData;
  inspectionApproval: ColumnData;
  estimatedShipmentDate: ColumnData;
  realEtd: ColumnData;
  portLoading: ColumnData;
  realEta: ColumnData;
  portDischarge: ColumnData;
  purchaseIncoterm: ColumnData;
  authorizationRequested: ColumnData;
  shipmentAuthorized: ColumnData;
  bookingConfirmed: ColumnData;
  shipmentConfirmed: ColumnData;
  freightForwarderOrigin: ColumnData;
  freightForwarderDestination: ColumnData;
  trakingNumber: ColumnData;
  courier: ColumnData;
  docsReceived: ColumnData;
  exportNotes: ColumnData;
  ctnrs: ColumnData;
  quantityPiBySun: ColumnData;
  quantityCiBySun: ColumnData;
  purchaseTotalCotPi: ColumnData;
  purchaseTotalCotCi: ColumnData;
  paymentSellingIncoterm: ColumnData;
  sellerTotalSunPi: ColumnData;
  sellerTotalSunCi: ColumnData;
  estimatedGrossMargin: ColumnData;
  grossMargin: ColumnData;
  estimatedNetMargin: ColumnData;
  estimatedNetProfit: ColumnData;
  purchasePaymentTermCondition: ColumnData;
  purchasePaidAdvance: ColumnData;
  purchasePaidBalance: ColumnData;
  totalPaid: ColumnData;
  totalCotRemain: ColumnData;
  freightCost: ColumnData;
  freightExtraCost: ColumnData;
  startProductionTiming: ColumnData;
  realProductionTime: ColumnData;
  authorizationTiming: ColumnData;
  authorizationBookingTiming: ColumnData;
  authorizationShipmentTiming: ColumnData;
  bookingShipmentTiming: ColumnData;
  preShipmentTiming: ColumnData;
  transitTime: ColumnData;
  hblFinalTiming: ColumnData;
  originalDocsTiming: ColumnData;
  deliveryAtClientTiming: ColumnData;
  totalForeignTradeTiming: ColumnData;
  samples: ColumnData;
  samplesLeadTime: ColumnData;
  updatesEn: ColumnData;
  purchaseUser: ColumnData;
  exportUser: ColumnData;
  importUser: ColumnData;
  designUser: ColumnData;
  financialUser: ColumnData;
  shipmentSunNumber: ColumnData;
  supplierName: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IExportGridColumnData = {
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'idProductionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  documentsStatus: {
    field: 'documentsStatus',
    header: 'Documents Status',
    value: undefined,
    advancedFilterField: 'idDocumentsStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DOCUMENTS_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'idStatusPurchasePayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'idStatusSellingPayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  exportStatus: {
    field: 'exportStatus',
    header: 'Export Status',
    value: undefined,
    advancedFilterField: 'idExportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.EXPORT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  shipmentSunNumber: {
    field: 'shipmentSunNumber',
    header: 'SUN Number',
    advancedFilterField: 'shipmentIdSupplier',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  supplierName: {
    field: 'supplierName',
    header: 'Supplier Name',
    advancedFilterField: '',
  },
  statusSat: {
    field: 'statusSat',
    header: 'Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  infoSupplier: {
    field: 'infoSupplier',
    header: 'Info Supplier',
    advancedFilterField: '',
  },
  satCreatedAt: {
    field: 'satCreatedAt',
    header: 'Created At',
    advancedFilterField: 'satCreatedAt',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  confirmOrderPurchase: {
    field: 'confirmOrderPurchase',
    header: 'Confirm Order Purchase',
    advancedFilterField: 'confirmOrderPurchase',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  typeOfOrder: {
    field: 'typeOfOrder',
    header: 'Type Of Order',
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    advancedFilterField: '',
  },
  consolidatedWith: {
    field: 'consolidatedWith',
    header: 'Consolidated With',
    advancedFilterField: '',
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  manufacturer: {
    field: 'manufacturer',
    header: 'Manufacturer',
    advancedFilterField: 'manufacturer',
  },
  countryOfOrigin: {
    field: 'countryOfOrigin',
    header: 'Country of Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
  productionTime: {
    field: 'productionTime',
    header: 'Production Time',
    advancedFilterField: '',
  },
  sentFinalArtWork: {
    field: 'sentFinalArtWork',
    header: 'Sent Final Artwork',
    advancedFilterField: 'sentFinalArtWork',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  sketchesReceived: {
    field: 'sketchesReceived',
    header: 'Sketches Received',
    advancedFilterField: 'sketchesReceived',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  artworkDone: {
    field: 'artworkDone',
    header: 'Artwork Done',
    advancedFilterField: 'artworkDone',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  packagesNotes: {
    field: 'packagesNotes',
    header: 'Packages Notes',
    advancedFilterField: 'packagesNotes',
  },
  estimatedInspectionDate: {
    field: 'estimatedInspectionDate',
    header: 'Estimated Inspection Date',
    advancedFilterField: 'estimatedInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  inspectionScheduled: {
    field: 'inspectionScheduled',
    header: 'Inspection Scheduled',
    advancedFilterField: 'inspectionScheduled',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  rework: {
    field: 'rework',
    header: 'Rework',
    advancedFilterField: 'idRework',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_REWORK,
    },
  },
  reworkDate: {
    field: 'reworkDate',
    header: 'Rework Date',
    advancedFilterField: '',
  },
  reworkCost: {
    field: 'reworkCost',
    header: 'Rework Cost',
    advancedFilterField: '',
  },
  inspectionApproval: {
    field: 'inspectionApproval',
    header: 'Inspection Approval',
    advancedFilterField: 'inspectionApproval',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  portLoading: {
    field: 'portLoading',
    header: 'Port of Loading',
    advancedFilterField: 'idLoadingPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
      additional: {
        type: PortType.PORT,
      },
    },
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  portDischarge: {
    field: 'portDischarge',
    header: 'Port of Discharge',
    advancedFilterField: 'idPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
      additional: {
        type: PortType.PORT,
      },
    },
  },
  purchaseIncoterm: {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  authorizationRequested: {
    field: 'authorizationRequested',
    header: 'Authorization Requested',
    advancedFilterField: '',
  },
  shipmentAuthorized: {
    field: 'shipmentAuthorized',
    header: 'Shipment Authorized',
    advancedFilterField: 'shipmentAuthorized',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  bookingConfirmed: {
    field: 'bookingConfirmed',
    header: 'Booking Confirmed',
    advancedFilterField: 'bookingConfirmed',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  shipmentConfirmed: {
    field: 'shipmentConfirmed',
    header: 'Shipment Confirmed',
    advancedFilterField: 'shipmentConfirmed',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  freightForwarderOrigin: {
    field: 'freightForwarderOrigin',
    header: 'Freight Forwarder Origin',
    advancedFilterField: '',
  },
  freightForwarderDestination: {
    field: 'freightForwarderDestination',
    header: 'Freight Forwarder Destination',
    advancedFilterField: '',
  },
  trakingNumber: {
    field: 'trakingNumber',
    header: 'Traking Number',
    advancedFilterField: 'trakingNumber',
  },
  courier: {
    field: 'courier',
    header: 'Courier',
    advancedFilterField: '',
  },
  docsReceived: {
    field: 'docsReceived',
    header: 'Docs Received',
    advancedFilterField: '',
  },
  exportNotes: {
    field: 'exportNotes',
    header: 'Export Notes',
    advancedFilterField: '',
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty/Type CTNR',
    advancedFilterField: '',
  },
  quantityPiBySun: {
    field: 'quantityPiBySun',
    header: 'Quantity PI Shipment by SUN',
    advancedFilterField: '',
  },
  quantityCiBySun: {
    field: 'quantityCiBySun',
    header: 'Quantity CI by Sun',
    advancedFilterField: '',
  },
  purchaseTotalCotPi: {
    field: 'purchaseTotalCotPi',
    header: 'Purchase Total COT PI',
    advancedFilterField: '',
  },
  purchaseTotalCotCi: {
    field: 'purchaseTotalCotCi',
    header: 'Purchase Total COT CI',
    advancedFilterField: '',
  },
  paymentSellingIncoterm: {
    field: 'paymentSellingIncoterm',
    header: 'Selling Incoterm',
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  sellerTotalSunPi: {
    field: 'sellerTotalSunPi',
    header: 'Seller Total SUN PI',
    advancedFilterField: '',
  },
  sellerTotalSunCi: {
    field: 'sellerTotalSunCi',
    header: 'Seller Total SUN CI',
    advancedFilterField: '',
  },
  estimatedGrossMargin: {
    field: 'estimatedGrossMargin',
    header: 'Estimated Gross Margin',
    advancedFilterField: '',
  },
  grossMargin: {
    field: 'grossMargin',
    header: 'Gross Margin',
    advancedFilterField: '',
  },
  estimatedNetMargin: {
    field: 'estimatedNetMargin',
    header: 'Estimated Net Margin',
    advancedFilterField: '',
  },
  estimatedNetProfit: {
    field: 'estimatedNetProfit',
    header: 'Estimated Net Profit',
    advancedFilterField: '',
  },
  purchasePaymentTermCondition: {
    field: 'purchasePaymentTermCondition',
    header: 'Purchase Payment Term Condition',
    advancedFilterField: '',
  },
  purchasePaidAdvance: {
    field: 'purchasePaidAdvance',
    header: 'Purchase Advance Paid',
    advancedFilterField: '',
  },
  purchasePaidBalance: {
    field: 'purchasePaidBalance',
    header: 'Purchase Balance Paid',
    advancedFilterField: '',
  },
  totalPaid: {
    field: 'totalPaid',
    header: 'Total Paid',
    advancedFilterField: '',
  },
  totalCotRemain: {
    field: 'totalCotRemain',
    header: 'Total Remain COT',
    advancedFilterField: '',
  },
  freightCost: {
    field: 'freightCost',
    header: 'Freight Cost',
    advancedFilterField: '',
  },
  freightExtraCost: {
    field: 'freightExtraCost',
    header: 'Freight Extra Cost',
    advancedFilterField: '',
  },
  startProductionTiming: {
    field: 'startProductionTiming',
    header: 'Start Production Timing',
    advancedFilterField: '',
  },
  realProductionTime: {
    field: 'realProductionTime',
    header: 'Real Production Time',
    advancedFilterField: '',
  },
  authorizationTiming: {
    field: 'authorizationTiming',
    header: 'Authorization Timing',
    advancedFilterField: '',
  },
  authorizationBookingTiming: {
    field: 'authorizationBookingTiming',
    header: 'Authorization Booking Timing',
    advancedFilterField: '',
  },
  authorizationShipmentTiming: {
    field: 'authorizationShipmentTiming',
    header: 'Authorization Shipment Timing',
    advancedFilterField: '',
  },
  bookingShipmentTiming: {
    field: 'bookingShipmentTiming',
    header: 'Booking Shipment Timing',
    advancedFilterField: '',
  },
  preShipmentTiming: {
    field: 'preShipmentTiming',
    header: 'Pre Shipment Timing',
    advancedFilterField: '',
  },
  transitTime: {
    field: 'transitTime',
    header: 'Transit Time',
    advancedFilterField: '',
  },
  hblFinalTiming: {
    field: 'hblFinalTiming',
    header: 'HBL Final Timing',
    advancedFilterField: '',
  },
  originalDocsTiming: {
    field: 'originalDocsTiming',
    header: 'Original Docs Timing',
    advancedFilterField: '',
  },
  deliveryAtClientTiming: {
    field: 'deliveryAtClientTiming',
    header: 'Delivery At Client Timing',
    advancedFilterField: '',
  },
  totalForeignTradeTiming: {
    field: 'totalForeignTradeTiming',
    header: 'Total Foreign Trade Timing',
    advancedFilterField: '',
  },
  samples: {
    field: 'samples',
    header: 'Samples',
    advancedFilterField: '',
  },
  samplesLeadTime: {
    field: 'samplesLeadTime',
    header: 'Samples Lead Time',
    advancedFilterField: '',
  },
  updatesEn: {
    field: 'updatesEn',
    header: 'Updates (EN)',
    advancedFilterField: '',
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  designUser: {
    field: 'designUser',
    header: 'Design User',
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: 'preview',
    label: 'Preview',
    data: [],
    children: [
      {
        key: 'preview-resume',
        label: 'Resume',
        data: [
          gridColumnsData.importStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.documentsStatus.field,
          gridColumnsData.supplierPaymentStatus.field,
          gridColumnsData.clientPaymentStatus.field,
          gridColumnsData.shipmentResume.field,
          gridColumnsData.shipment.field,
          gridColumnsData.statusSat.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.satCreatedAt.field,
          gridColumnsData.confirmOrderPurchase.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.replacementFrom.field,
          gridColumnsData.consolidatedWith.field,
          gridColumnsData.exporter.field,
          gridColumnsData.countryOfOrigin.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.productionTime.field,
          gridColumnsData.sketchesReceived.field,
          gridColumnsData.realInspectionDate.field,
          gridColumnsData.inspectionScheduled.field,
          gridColumnsData.rework.field,
          gridColumnsData.reworkDate.field,
          gridColumnsData.reworkCost.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.inspectionApproval.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.portLoading.field,
          gridColumnsData.realEta.field,
          gridColumnsData.portDischarge.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.shipmentAuthorized.field,
          gridColumnsData.bookingConfirmed.field,
          gridColumnsData.shipmentConfirmed.field,
          gridColumnsData.exportNotes.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.quantityPiBySun.field,
          gridColumnsData.quantityCiBySun.field,
          gridColumnsData.paymentSellingIncoterm.field,
          gridColumnsData.sellerTotalSunPi.field,
          gridColumnsData.sellerTotalSunCi.field,
          gridColumnsData.estimatedGrossMargin.field,
          gridColumnsData.grossMargin.field,
          gridColumnsData.freightCost.field,
          gridColumnsData.freightExtraCost.field,
          gridColumnsData.samples.field,
          gridColumnsData.updatesEn.field,
          gridColumnsData.purchaseUser.field,
          gridColumnsData.exportUser.field,
          gridColumnsData.importUser.field,
          gridColumnsData.designUser.field,
          gridColumnsData.financialUser.field,
        ],
      },
      {
        key: 'preview-full',
        label: 'Full',
        data: Object.values(gridColumnsData).map(column => column.field),
      },
    ],
  },
];
