/**
 * Converte boolean para Yes ou No
 * @param value Valor em boolean
 * @returns String
 */
export function booleanToString(value?: boolean): string | undefined {
  switch (value) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      break;
  }
  return undefined;
}
