import { gql } from '@apollo/client';

export const listPartnerAttachmentsQuery = gql`
  query ListPartnerAttachments($data: ListPartnerAttachmentsInput!) {
    listPartnerAttachments(data: $data) {
      items
      data {
        idPartnerAttachment
        idAttachment
        imageUrl
        name
      }
    }
  }
`;

export const deletePartnerAttachmentsQuery = gql`
  mutation DeletePartnerAttachments($idsPartnerAttachments: [Int]!) {
    deletePartnerAttachments(idsPartnerAttachments: $idsPartnerAttachments)
  }
`;
