import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialSellerOtherExpenseFieldsPermissions } from '../../interfaces';

export const listOtherExpensesQuery = (
  fieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions,
): DocumentNode => gql`
  query ListSatForeignTradeSupplierOtherExpenses(
    $data: ListSatForeignTradeSupplierOtherExpensesInput!
  ) {
    listSatForeignTradeSupplierOtherExpenses(data: $data) {
      items
      data {
        idSatOtherExpense
        idType
        ${
          fieldsPermissions.idType.view
            ? `idType2 {
                idDomain
                description
              } `
            : ''
        }
        idCurrency2 {
          abbreviation
        }
        ${fieldsPermissions.total.view ? 'total' : ''}
        ${fieldsPermissions.dateOtherExpense.view ? 'dateOtherExpense' : ''}
        ${fieldsPermissions.linkToMargin.view ? 'linkToMargin' : ''}
        ${fieldsPermissions.comment.view ? 'comment' : ''}
        ${fieldsPermissions.creditToClient.view ? 'creditToClient' : ''}
        ${fieldsPermissions.apportionment.view ? 'apportionment' : ''}
        ${
          fieldsPermissions.calculationBasis.view
            ? `calculationBasis2 {
                idDomain
                description
              }
          `
            : ''
        }
        idSatForeignTradeSupplierDestination
        confirmationDate
        idSatForeignTradeSupplier
        idSatForeignTradeDestination2 {
          satForeignTradeNumber
        }
        idSatRnc2 {
          rncNumber
        }
        number
        idSatForeignTrade2 {
          satForeignTradeNumber
        }
      }
    }
  }
`;

export const deleteOtherExpenseQuery = gql`
  mutation Mutation($idSatOtherExpense: Int!) {
    deleteSatForeignTradeSupplierOtherExpense(
      idSatOtherExpense: $idSatOtherExpense
    )
  }
`;

export const addCreditNoteQuery = gql`
  mutation Mutation($idSatOtherExpense: Int!) {
    addSatForeignTradeSupplierCreditNote(
      idSatOtherExpense: $idSatOtherExpense
    ) {
      idSatOtherExpense
    }
  }
`;

export const removeCreditNoteQuery = gql`
  mutation RemoveSatForeignTradeSupplierCreditNote($idSatOtherExpense: Int!) {
    removeSatForeignTradeSupplierCreditNote(
      idSatOtherExpense: $idSatOtherExpense
    ) {
      idSatOtherExpense
    }
  }
`;
