import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  airPortLoading: {
    field: 'idAirportLoading2.name',
    header: 'Airport of Loading',
    advancedFilterField: 'airPortLoading',
  },
  airPortDestination: {
    field: 'idAirportDestination2.name',
    header: 'Airport of Destination',
    advancedFilterField: 'airPortDestination',
  },
  valueFreight: {
    field: 'valueFreight',
    header: 'Air Freight Value',
    advancedFilterField: 'valueFreight',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  curValue: {
    field: 'idCurValue2.abbreviation',
    header: 'Currency',
    advancedFilterField: 'curValue',
  },
  bcValue: {
    field: 'idBcValue2.description',
    header: 'BC Value',
    advancedFilterField: 'bcValue',
  },
  bcCif: {
    field: 'bcCif',
    header: 'BC CIF',
    advancedFilterField: 'bcCif',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAt: {
    field: 'updatedAt',
    header: 'Last Updated',
    advancedFilterField: '',
  },
};
