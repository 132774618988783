export const satSuppliersRoles = {
  idEntity: 26,
  idModule: 4,
  fields: {
    idFieldIdSupplier: 1058,
    idFieldName: 1059,
    idFieldAddress: 1060,
    idFieldProductionTime: 1061,
    idFieldIdLoadingPort: 1062,
    idFieldEstimatedInspectionDate: 1063,
    idFieldEstimatedShipmentDate: 1064,
    idFieldEstimatedArrivalDate: 1065,
    idFieldIncoterm: 1066,
    idFieldArtWork: 1067,
    idFieldPaymentTermAdvance: 1068,
    idFieldPaymentTermBalance: 1069,
    idFieldPaymentTermCondition: 1070,
    idFieldEstimatedAdvancePaymentDate: 1071,
    idFieldPurchasePI: 1072,
    idFieldPurchaseCI: 1073,
    idFieldAdvancePaymentPercentage: 1074,
    idFieldBalancePaymentPercentage: 1075,
    idFieldGrossMargin: 1076,
    idFieldNetMargin: 1077,
    idFieldEstAdvancePaymentAmount: 1078,
    idFieldEstimatedBalancePaymentDate: 1079,
    idFieldEstBalancePaymentAmount: 1080,
    idFieldSupplierLoadingAmountCot: 1081,
    idFieldRemainTotalCot: 1082,
    idFieldBeneficiaryName: 1083,
    idFieldMainBankName: 1084,
    idFieldMainBankAddress: 1085,
    idFieldIdBankCountry: 1086,
    idFieldIdBankRegion: 1087,
    idFieldBankCity: 1088,
    idFieldSwift: 1089,
    idFieldAccountNumber: 1090,
    idFieldIban: 1091,
    idFieldIntermediaryBeneficiaryName: 1092,
    idFieldIntermediaryBankAddress: 1093,
    idFieldIdBankSecondCountry: 1094,
    idFieldIdBankSecondRegion: 1095,
    idFieldIntermediaryBankCity: 1096,
    idFieldIntermediarySwift: 1097,
    idFieldIntermediaryAccountNumber: 1098,
    idFieldIntermediaryIban: 1099,
  },
  permissions: {
    idPermissionLinkSupplierDetails: 118,
  },
};
