import { IPrcFileLog } from '../interfaces';

// eslint-disable-next-line no-shadow
export enum PrcFileLogReducerActionKind {
  OPEN_DIALOG,
  CLOSE_DIALOG,
}

export interface IPrcFileLogRef {
  toggleDialog(data: IPrcFileLog[], tempHeader?: string): void;
}

export type PrcFileLogReducer = {
  displayDialog: boolean;
  data: IPrcFileLog[];
  header?: string;
};

export type PrcFileLogReducerAction = {
  type: PrcFileLogReducerActionKind;
  payload?: {
    data: IPrcFileLog[];
    header?: string;
  };
};
