/* eslint-disable no-shadow */
import { ICategory } from '../../../interfaces/ICategory';
import { IClient } from '../../../interfaces/IClient';
import { ICountry } from '../../../interfaces/ICountry';
import { IDomain } from '../../../interfaces/IDomain';
import { IDomainGroup } from '../../../interfaces/IDomainGroup';
import { IPort } from '../../../interfaces/IPort';
import { IUser } from '../../../interfaces/IUser';
import ILazyParams from '../../../services/lazyParams';
import {
  IAttachment,
  IAttachment as IAttachmentWithUrl,
} from '../../../shared/interfaces/attachment';
import { IComment } from '../../../shared/interfaces/comment';
import { IClientContact } from '../../Clients/ClientDetail/interfaces';

export enum ClientQuotationTypeOfUpdate {
  USUARIO = 800,
  SISTEMA_FLUXO = 799,
}

/**
 * Enum de Client Quotation Status
 */
export enum ClientQuotationStatus {
  DRAFT = 772,
  APPROVED = 773,
  WAITING_PURCHASE = 775,
  AT_PURCHASE = 776,
  AT_COMMERCIAL = 777,
  CANCELLED = 774,
}

/**
 * Acoes de Elaborar Client Quotation
 */
export enum ClientQuotationElaborarUserAction {
  ENVIAR_PURCHASE = 'enviarPurchase',
  CANCELAR_CQ = 'cancelarCQ',
}

/**
 * Acoes de Client Quotation Purchase
 */
export enum ClientQuotationPurchaseAction {
  ENVIAR_COMMERCIAL = 'enviarCommercial',
  DEVOLVER_POOL_PURCHASE = 'devolverPoolPurchase',
}

/**
 * Acoes de Client Quotation Aprovar
 */
export enum ClientQuotationAprovarAction {
  CANCELAR_CQ = 'cancelarCQ',
  APROVAR_CQ = 'aprovarCQ',
  ENVIAR_PURCHASE = 'enviarPurchase',
  APROVAR_CQ_CRIAR_SAT = 'aprovarCqCriarSat',
}

/**
 * Parametros de Rota da tela de Client Quotation
 */
export interface IClientQuotationRouteParams {
  idCq: string;
}

export interface IClientQuotationUpdate {
  idCqUpdate: number;
  idCq: number;
  idComment: number;
  idComment2: IComment;
}

export interface IClientQuotationContainer {
  idCqCtnr?: number | string;
  idCq?: number;
  idCq2?: IClientQuotation;
  qtyCtnr?: number;
  idTypeCtnr?: number;
  idTypeCtnr2?: IDomain;
}

export interface IClientQuotation {
  idCq: number;
  idStatus: number;
  idStatus2: IDomain;
  idWfProcess: number;
  wfInstance: string;
  idResponsibleUser: number;
  idResponsibleUser2: IUser;
  cqNumber: string;
  mainProduct: string;
  idCategory: number;
  idCategory2: ICategory;
  remarks: string;
  packageNotes: string;
  indSamples: number;
  samplesLeadTime: number;
  idClient: number;
  idClient2: IClient;
  clientCompanyName: string;
  clientCnpj: string;
  clientAddress: string;
  idFirstBuyer: number;
  idFirstBuyer2: IClientContact;
  idSecondBuyer: number;
  idSecondBuyer2: IClientContact;
  idSellingIncoterm: number;
  idPaymentTermCondition: number;
  idImporter: number;
  idImporter2: IClient;
  importerCompanyName: string;
  importerCnpj: string;
  importerAddress: string;
  idExporter: number;
  idExporter2: ISupplier;
  exporterAddress: string;
  idPurchaseIncoterm: number;
  productionTime: number;
  idPortOfLoading: number;
  idPortOfLoading2: IPort;
  idPortOfDischarge: number;
  idPortOfDischarge2: IPort;
  idCountryOrigin: number;
  idCountryOrigin2: ICountry;
  idCountryAcquisition: number;
  idCountryAcquisition2: ICountry;
  idCountryProcedence: number;
  idCountryProcedence2: ICountry;
  estimatedInspection: Date;
  estimatedShipment: Date;
  estimatedArrival: Date;
  estimatedArrivalAtClient: Date;
  purchaseUser: number;
  purchaseUser2: IUser;
  salesUser: number;
  salesUser2: IUser;
  productLine: string;
  approvedOn: number;
  approvedAt: Date;
  createdBy: number;
  createdBy2: IUser;
  createdAt: Date;
  updatedBy: number;
  updatedBy2: IUser;
  updatedAt: Date;
  active: number;
  versionLock: number;
  cqUpdates: IClientQuotationUpdate[];

  cqCtnrs: IClientQuotationContainer[];
  cqSuppliers: IClientQuotationSupplier[];

  /**
   * Incoterm informado pelo usuário na criação da CQ
   */
  idPurchaseIncoterm2?: IDomain;

  /**
   * Referente ao idClient (Client/Notify)
   */
  idSellingIncoterm2?: IDomain;

  /**
   * Indicador de CQ de confecção
   */
  indConfection?: boolean;

  /**
   * Referente ao idClient (Client/Notify)
   */
  paymentTermAdvance?: number;

  /**
   * Referente ao idClient (Client/Notify)
   */
  paymentTermBalance?: number;

  /**
   * Referente ao idClient (Client/Notify)
   */
  idPaymentTermCondition2?: IDomain;

  /**
   * Preço total de compra da CQ
   */
  amountCot?: number;

  /**
   * Preço total de venda da CQ
   */
  amountSun?: number;

  /**
   * Margin total da CQ
   */
  grossMargin?: number;

  suppliersPurchaseIncoterms: IDomain[];
}

interface ISupplierPorts {
  idPort2: IPort;
}

interface ISupplierBankAccount {
  beneficiaryName?: string;
  mainBankName?: string;
  mainBankAddress?: string;
  idCountry2?: { name: string };
  idRegion2?: { name: string };
  bankCity?: string;
  swift?: string;
  accountNumber?: string;
  iban?: string;
  intermediaryBeneficiaryName?: string;
  intermediaryBankAddress?: string;
  idSecondCountry2?: { name: string };
  idSecondRegion2?: { name: string };
  intermediaryBankCity?: string;
  intermediarySwift?: string;
  intermediaryAccountNumber?: string;
  intermediaryIban?: string;
  isIntermediaryBank?: boolean;
}

/**
 * Interface de Supplier
 */
export interface ISupplier {
  /**
   * SUN Number
   */
  sunNumber: string;

  /**
   * Nome do Supplier
   */
  name: string;

  /**
   * ID do Supplier
   */
  idSupplier: number;

  address?: string;

  idIncoterm?: number;
  idIncotermSecond?: number;
  idIncoterm2?: IDomain;

  idIncotermSecond2?: IDomain;

  productionTime?: number;

  supplierPorts?: ISupplierPorts[];

  pdf?: boolean;
  ai?: boolean;
  corel17?: boolean;
  corel20?: boolean;
  jpeg?: boolean;
  eps?: boolean;
  psd?: boolean;
  indd?: boolean;
  xls?: boolean;
  otherFormat?: string;

  supplierBankAccounts?: ISupplierBankAccount[];
  idCountry: number;
  idCountry2: ICountry;
}

/**
 * Interface de Client Quotation Supplier
 * Referência a tabela CqSupplier do banco
 */
export interface IClientQuotationSupplier {
  idCqSupplier: number;
  idCq: number;
  idSupplier: number;
  idSupplier2: ISupplier;
  paymentTermAdvance: number;
  paymentTermBalance: number;
  idPaymentTermCondition: number;
  idPaymentTermCondition2?: IDomain;
  amountCot: number;
  estimatedTotalAdvance: number;
  estimatedTotalBalance: number;
  amountSun: number;
  estimatedTotalAdvanceSeller: number;
  estimatedTotalBalanceSeller: number;
  grossMargin: number;
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  active: number;
  versionLock: number;
}

export interface IClientQuotationReferenceSizeBarcode {
  idCqReferenceSizeBarcode: number;

  /**
   * Origem productPackageSizeBarcode - Reference Size
   */
  reference: string;

  /**
   * Master barcode
   */
  masterBarcode?: bigint;

  /**
   * Unit
   */
  unit2?: IDomain;

  /**
   * Selling Package Ean-13   - pack e calculado
   */
  sellingPackage?: bigint;

  /**
   * unitInnerBox
   */
  unitInnerBox?: string;

  /**
   * Inner Box Dun-14  - calculado
   */
  innerBox?: bigint;

  /**
   * unitMasterBox
   */
  unitMasterBox?: string;

  /**
   * Mater Box Dun-14  - calculado
   */
  masterBox?: bigint;

  /**
   * Calculo: QUANTITY * SIZE RANGE / PCS/CTN
   */
  quantityPiSize?: number;

  /**
   * Origem productPackageSizeBarcode - FK tabela domain (idDomain) - Size selecionado
   */
  size2?: IDomain;

  /**
   * Origem productPackageSizeBarcode - Size Range informado
   */
  sizeRange?: number;

  /**
   * quantity Container Size
   */
  quantityContainerSize?: number;

  /**
   * totalCotPiSize
   */
  totalCotPiSize?: number;

  /**
   * total Sun Pi/Size
   */
  totalSunPiSize?: number;
}

/**
 * Interface de Client Quotation Item Reference
 */
export interface IClientQuotationItemReference {
  /**
   * ID de ClientQuotationItemReference
   */
  idCqItemReference: number;

  /**
   * Color da reference em inglês
   */
  colourEn: string;

  /**
   * Descrição da cor em português
   */
  colourPt?: string;

  /**
   * Imagem da referencia
   */
  idAttachment2?: IAttachment;

  /**
   * Origem productPackageReference - Size Group
   */
  idSizeGroup2: IDomainGroup;

  /**
   * Origem productPackageReference - Gender
   */
  idGender2: IDomain;

  /**
   * Master barcode
   */
  masterBarcode?: bigint;

  totalCollor?: number;

  cqReferenceSizeBarcodes: IClientQuotationReferenceSizeBarcode[];
}

/**
 * Interface de Partner
 */
interface IPartner {
  /**
   * Nome do partner
   */
  name: string;
}

/**
 * Interface de Client Quotation
 */
export interface IClientQuotationItem {
  /**
   * ID de Client Quotation Item
   */
  idCqItem: number;

  /**
   * Objeto de attachment
   */
  idImage2: IAttachment;

  /**
   * Objeto de Client Quotation Supplier
   */
  idCqSupplier2: IClientQuotationSupplier;

  /**
   * Product / General Information Reference
   */
  refCode?: string;

  /**
   * Client Quotation Item References
   */
  cqItemReferences: IClientQuotationItemReference[];

  /**
   * Nome
   */
  name?: string;

  /**
   * Description
   */
  description?: string;

  /**
   * Nome (PT)
   */
  namePt?: string;

  /**
   * Descricao (PT)
   */
  descriptionPt?: string;

  /**
   * Model
   */
  model?: string;

  /**
   * SKU
   */
  sku?: string;

  /**
   * Color
   */
  colour?: string;

  /**
   * Color (PT)
   */
  colourPt?: string;

  /**
   * Objeto de Color Image
   */
  idColourImage2: IAttachment;

  /**
   * material Composition
   */
  materialComposition?: string;

  /**
   * Material (PT)
   */
  materialPt?: string;

  /**
   * Dimensions
   */
  dimensions?: string;

  /**
   * netWeight
   */
  netWeight?: number;

  /**
   * SELLING PACKAGE
   */
  packageDescription?: string;

  /**
   * package Notes
   */
  packageNotes?: string;

  /**
   * QSP
   */
  sellingPackagePcQuantity?: number;

  /**
   * SELLING PACKAGE DIMENSION
   */
  sellingPackageDimensions?: string;

  /**
   * SPGW
   */
  sellingPackageGrossWeight?: number;

  /**
   * STICKERS DIMENSIONS
   */
  stickerDimensionsPt?: string;

  /**
   * quantity Inner
   */
  quantityInner?: number;

  /**
   * INNER PER CTN
   */
  innerBox?: string;

  /**
   * PACKAGE EAN 13
   */
  packageEan13?: string;

  /**
   * INNER DUN 14
   */
  innerdun14?: string;

  /**
   * DUN 14
   */
  dun14?: string;

  /**
   * Brand
   */
  brand?: string;

  /**
   * Product Line
   */
  // TODO: Product line?

  /**
   * Itens selecionados na aba artwork do produto em Inglês
   */
  artworkEn?: string;

  /**
   * Itens selecionados na aba artwork do produto em Português
   */
  artworkPt?: string;

  /**
   * Itens selecionados na aba technique do produto em Inglês
   */
  techniqueEn?: string;

  /**
   * Itens selecionados na aba technique do produto em Português
   */
  techniquePt?: string;

  /**
   * Resposta do checklist - Fabric / Tecido + Type of Fabric / Tipo de Tecido
   */
  typeOfFabric?: string;

  /**
   * Resposta do checklist - Lining
   */
  lining?: string;

  /**
   * Resposta do checklist - Lining Composition % / Composição Forro
   */
  liningComposition?: string;

  /**
   * Resposta do checklist - Type of lining
   */
  linedDescription?: string;

  /**
   * ID de TypePackage
   */

  idTypePackage?: number;

  /**
   * TYPE OF PACKAGE
   */
  idTypePackage2?: IDomain;

  /**
   * Unit
   */
  unit2?: IDomain;

  /**
   * MOQ
   */
  moq?: number;

  /**
   * m2Pc
   */
  m2Pc?: number;

  /**
   * TOTAL MASTER (M2)
   */
  totalMasterM2?: number;

  /**
   * Cot Price SQM
   */
  cotPriceSqm?: number;

  /**
   * sun Price SQM
   */
  sunPriceSqm?: number;

  /**
   * Quantity of Container
   */
  quantityContainer?: number;

  /**
   * pieces Container
   */
  piecesContainer?: number;

  /**
   * master Net Weight
   */
  masterNetWeight?: number;

  /**
   * master Net Weight Total
   */
  masterNetWeightTotal?: number;

  /**
   * gross Weight
   */
  grossWeight?: number;

  /**
   * gross Weight Total
   */
  grossWeightTotal?: number;

  /**
   * length
   */
  length?: string;

  /**
   * width
   */
  width?: string;

  /**
   * height
   */
  height?: string;

  /**
   * CBM
   */
  cbm?: number;

  /**
   * cbm Total
   */
  cbmTotal?: number;

  /**
   * target Cot
   */
  targetCot?: number;

  /**
   * cot Price
   */
  cotPrice?: number;

  /**
   * amount Cot
   */
  amountCot?: number;

  /**
   * targetSun
   */
  targetSun?: number;

  /**
   * sunPrice
   */
  sunPrice?: number;

  /**
   * amount
   */
  amount?: number;

  /**
   * estimate Profit
   */
  estimateProfit?: number;

  /**
   * estimate Margin
   */
  estimateMargin?: number;

  /**
   * hsCode
   */
  hsCode?: bigint;

  /**
   * NCM
   */
  ncm?: number;

  /**
   * Cadastro do NCM/Produto verificado/validado
   */
  ncmForeignTradeReview?: boolean;

  /**
   * Licença de Importação
   */
  ncmLi?: boolean;

  /**
   * (%) - Imposto de importação
   */
  ncmIi?: number;

  /**
   * (%) - Imposto sobre Produtos Industrializados
   */
  ncmIpi?: number;

  /**
   * (%) - Tributo não-cumulativo que tem como destino o financiamento da seguridade social
   */
  ncmPis?: number;

  /**
   * (%) - Tributo não-cumulativo que tem como destino o financiamento da seguridade social
   */
  ncmCofins?: number;

  /**
   * Ação protecionista para evitar exportação/importação de um produto a preço inferior ao realizado no mercado interno do país exportador
   */
  ncmDumping?: boolean;

  /**
   * samples
   */
  samples?: number;

  /**
   * sample Lead Time
   */
  sampleLeadTime?: number;

  /**
   * Amostra aprovada
   */
  samplesApproved?: boolean;

  /**
   * sample Notes
   */
  sampleNotes?: string;

  /**
   * quantity
   */
  quantity?: number;

  /**
   * Produto licensiado
   */
  licensed?: boolean;

  /**
   * Objeto de Parceiro
   */
  idLicensor2?: IPartner;

  /**
   * royalties
   */
  royalties?: number;

  /**
   * ST Code
   */
  stCode: string;

  /**
   * ID do Produto
   */
  idProduct: number;
}

/**
 * Interface de Client Quotation Items
 */
export interface IClientQuotationItemsLazyParams extends ILazyParams {
  /**
   * ID da Client Quotation
   */
  idCq: number;
}
export interface IClientQuotationAttachment {
  /**
   * ID de CQ Attachment
   */
  idCqAttachment?: number;

  /**
   * Nome do arquivo
   */

  name: string;

  /**
   *  Objeto de Attachment
   */
  idAttachment2?: IAttachmentWithUrl;

  /**
   * Objeto de usuario
   */
  createdBy2?: IUser;

  /**
   * Data de Criacao
   */
  createdAt?: Date;

  /**
   * Arquivo
   */
  file?: File;

  /**
   * URL do arquivo
   */
  url?: string;
}

/**
 * Interface de Client Quotation Product ao adicionar produto
 */
export interface IClientQuotationProductAdd {
  /**
   * ID de produto
   */
  idProduct: number;

  /**
   * URL da imagem do produto
   */
  imageUrl?: string;

  /**
   * Nome do Produto em Ingles
   */
  nameEn: string;

  /**
   * Codigo do produto
   */
  stCode: string;

  /**
   * ID do Supplier
   */
  idSupplier: number;
}

/**
 * Interface para opcoes de Refresh de Client Quotation Item
 */
export interface IRefreshClientQuotationItemOptions {
  /**
   * Update SUN Price
   */
  sunPrice: boolean;
  /**
   * SUN Number
   */
  sunNumber: boolean;
  /**
   * Update Cot Price
   */
  cotPrice: boolean;
  /**
   * MOQ
   */
  moq: boolean;

  /**
   * Model
   */
  model: boolean;
  /**
   * Net Weight
   */
  netWeight: boolean;
  /**
   * Name (EN)
   */
  name: boolean;
  /**
   * Material (EN) + Composition (%)
   */
  materialComposition: boolean;
  /**
   * Colour (EN)
   */
  colour: boolean;
  /**
   * Description (EN)
   */
  description: boolean;
  /**
   * SKU
   */
  sku: boolean;
  /**
   * Name (PT)
   */
  namePt: boolean;
  /**
   * Material + Composition (PT)
   */
  materialPt: boolean;
  /**
   * Colour (PT)
   */
  colourPt: boolean;
  /**
   * Description (PT)
   */
  descriptionPt: boolean;

  /**
   * Important information
   */
  importantInformation: boolean;

  /**
   * Branding
   */
  brand: boolean;

  /**
   * Colour Image
   */
  colourImage: boolean;
  /**
   * NCM
   */
  ncm: boolean;

  /**
   * Dimensions
   */
  dimensions: boolean;
  /**
   * Package
   */
  package: boolean;

  /**
   * Artwork/Technique
   */
  artworkTechnique: boolean;

  /**
   * SQM Unit (M2 Pc)
   */
  m2Pc: boolean;

  /**
   * Imagem do Produto
   */
  image: boolean;

  /**
   * HS Code
   */
  hsCode: boolean;
}

export type DifferenceToRefreshClientQuotationItemsResponseData = {
  /**
   * Propriedade do objeto
   */
  property: string;

  /**
   * Label do objeto
   */
  label: string;

  /**
   * Valor anterior do objeto
   */
  oldValue: string | number | boolean | bigint;

  /**
   * Novo valor do objeto
   */
  newValue: string | number | boolean | bigint;

  /**
   * Indica se valor foi alterado
   */
  changed: boolean;
};

/**
 * Tipo de Response da busca por diferenca entre fields de Client Quotation Item
 */
export type DifferenceToRefreshClientQuotationItemsResponse = {
  /**
   * ID de client quotation item
   */
  idClientQuotationItem: number;

  /**
   * ST Code do produto
   */
  stCode: string;

  /**
   * Dados da atualizacao de cada field
   */
  data: DifferenceToRefreshClientQuotationItemsResponseData[];
};

export type UpdateClientQuotationSupplierFormData = {
  data: {
    idCqSupplier: string;

    paymentTermAdvance?: number;

    paymentTermBalance?: number;

    idPaymentTermCondition?: number;
  }[];
};
