/**
 * Converte valor de mm para cm
 * @param value Valor em mm
 * @returns Valor em cm
 */
export function mmToCm(value: number): number {
  const response = value / 10;

  return response;
}

/**
 * Converte valor de mm para m
 * @param value Valor em mm
 * @returns Valor em m
 */
export function mmToM(value: number): number {
  const response = value / 1000;

  return response;
}

/**
 * Converte valor de mm para inches
 * @param value Valor em mm
 * @returns Valor em inches
 */
export function mmToIn(value: number): number {
  const response = value / 25.4;

  return response;
}

/**
 * Converte valor de cm para mm
 * @param value Valor em cm
 * @returns Valor em mm
 */
export function cmToMm(value: number): number {
  const response = value * 10;

  return response;
}

/**
 * Converte valor de cm para m
 * @param value Valor em cm
 * @returns Valor em m
 */
export function cmToM(value: number): number {
  const response = value / 100;

  return response;
}

/**
 * Converte valor de cm para inches
 * @param value Valor em cm
 * @returns Valor em inches
 */
export function cmToIn(value: number): number {
  const response = value / 2.54;

  return response;
}

/**
 * Converte valor de m para mm
 * @param value Valor em m
 * @returns Valor em mm
 */
export function mToMm(value: number): number {
  const response = value * 1000;

  return response;
}

/**
 * Converte valor de m para cm
 * @param value Valor em m
 * @returns Valor em cm
 */
export function mToCm(value: number): number {
  const response = value * 100;

  return response;
}

/**
 * Converte valor de m para inches
 * @param value Valor em m
 * @returns Valor em inches
 */
export function mToIn(value: number): number {
  const response = value * 39.37;

  return response;
}

/**
 * Converte valor de inches para mm
 * @param value Valor em inches
 * @returns Valor em mm
 */
export function inToMm(value: number): number {
  const response = value * 25.4;

  return response;
}

/**
 * Converte valor de inches para cm
 * @param value Valor em inches
 * @returns Valor em cm
 */
export function inToCm(value: number): number {
  const response = value * 2.54;

  return response;
}

/**
 * Converte valor de inches para m
 * @param value Valor em inches
 * @returns Valor em m
 */
export function inToM(value: number): number {
  const response = value / 39.37;

  return response;
}

/**
 * Converte valor de ml para l
 * @param value Valor em ml
 * @returns Valor em l
 */
export function mlToL(value: number): number {
  const response = value / 1000;

  return response;
}

/**
 * Converte valor de l para ml
 * @param value Valor em l
 * @returns Valor em ml
 */
export function lToMl(value: number): number {
  const response = value * 1000;

  return response;
}

/**
 * Converte valor de mg para kg
 * @param value Valor em mg
 * @returns Valor em kg
 */
export function mgToKg(value: number): number {
  const response = value / 1000000;

  return response;
}

/**
 * Converte valor de mg para g
 * @param value Valor em mg
 * @returns Valor em g
 */
export function mgToG(value: number): number {
  const response = value / 1000;

  return response;
}

/**
 * Converte valor de kg para mg
 * @param value Valor em kg
 * @returns Valor em mg
 */
export function kgToMg(value: number): number {
  const response = value * 1000000;

  return response;
}

/**
 * Converte valor de kg para g
 * @param value Valor em kg
 * @returns Valor em g
 */
export function kgToG(value: number): number {
  const response = value * 1000;

  return response;
}

/**
 * Converte valor de g para mg
 * @param value Valor em g
 * @returns Valor em mg
 */
export function gToMg(value: number): number {
  const response = value * 1000;

  return response;
}
/**
 * Converte valor de g para kg
 * @param value Valor em g
 * @returns Valor em kg
 */
export function gToKg(value: number): number {
  const response = value / 1000;

  return response;
}
