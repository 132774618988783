import { useApolloClient } from '@apollo/client';

import React, { Ref, useCallback, useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { IUser } from '../../interfaces/IUser';
import { IUserGroup } from '../../interfaces/IUserGroup';
import { IClientQuotation } from '../../pages/ClientQuotation/ClientQuotation/interfaces';
import { ISat } from '../../pages/Commercial/Sat/interfaces';
import { IPrc } from '../../pages/Prc/Prc/interfaces';
import { IProduct } from '../../pages/Products/Product/interfaces';
import { UserGroupType } from '../../shared/enums/userGroup';
import { listAllUserGroupsQuery } from '../../shared/querys/userGroups';
import {
  listClientQuotationsQuery,
  listPrcsQuery,
  listProductsQuery,
  listSatsQuery,
  listUsersQuery,
} from './queries';
import { Container } from './styles';

interface MentionSuggestion {
  id: number | string;
  display: string;
}

interface MentionsProps {
  // ref: Ref<IMentionRef>;
  inputRef: Ref<HTMLTextAreaElement>;
  value: string;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  onChange?(e: string): void;
  suggestionsPortalHost?: Element;
}

const mentionClassName = 's-mentions-mention';

const Mentions: React.FC<MentionsProps> = ({
  inputRef,
  value,
  onClick,
  onChange,
  suggestionsPortalHost,
}) => {
  const client = useApolloClient();

  const [userSuggestions, setUserSuggestions] = useState<MentionSuggestion[]>(
    [],
  );

  const [userGroupSuggestions, setUserGroupSuggestions] = useState<
    MentionSuggestion[]
  >([]);

  const loadUsersData = useCallback(async () => {
    const res = await client.query({
      query: listUsersQuery,
      variables: {
        listAllUsersInput: {
          _page: 1,
          _limit: 0,
          _orderBy: 'firstName, lastName',
          _sortOrder: 'ASC',
          active: 'S',
        },
      },
    });
    if (res.data.listAllUsers.data) {
      const usersData = res.data.listAllUsers.data;
      const usersMapped = usersData.map((user: IUser) => {
        return {
          id: user.idUser,
          display: user.username,
        };
      });
      setUserSuggestions(usersMapped);
    }
  }, [client]);

  const loadUserGroupsData = useCallback(async () => {
    const res = await client.query({
      query: listAllUserGroupsQuery,
      variables: {
        data: {
          type: UserGroupType.NOTIFICATION_GROUP,
          active: true,
          onlyWithActiveUsers: true,
        },
      },
    });
    if (res.data.listAllUserGroups) {
      const userGroupsData = res.data.listAllUserGroups.data;
      const userGroupssMapped = userGroupsData.map((userGroup: IUserGroup) => {
        return {
          id: userGroup.idUserGroup,
          display: userGroup.name,
          // display: `Team - ${userGroup.name}`,
        };
      });
      setUserGroupSuggestions(userGroupssMapped);
    }
  }, [client]);

  const loadClientQuotationsData = useCallback(
    async (cqNumber: string, callback: (callbackValue: any) => any) => {
      client
        .query({
          query: listClientQuotationsQuery,
          variables: {
            listAllClientQuotationsInput: {
              pagination: {
                _page: 1,
                _limit: 10,
                _orderBy: 'cqNumber',
                _sortOrder: 'ASC',
              },
              cqNumber,
            },
          },
        })
        .then(res =>
          res.data.listAllClientQuotations.data?.map((cq: IClientQuotation) => {
            return {
              id: cq.idCq,
              display: `CQ${cq.cqNumber}`,
            };
          }),
        )
        .then(callback);
    },
    [client],
  );

  const loadPrcsData = useCallback(
    async (prcCode: string, callback: (callbackValue: any) => any) => {
      client
        .query({
          query: listPrcsQuery,
          variables: {
            data: {
              pagination: {
                _page: 1,
                _limit: 10,
                _orderBy: 'prcCode',
                _sortOrder: 'ASC',
              },
              prcCode,
            },
          },
        })
        .then(res =>
          res.data.listAllPrcsByPrcCodeAndPrcCodeNotNull?.map((prc: IPrc) => {
            return { id: prc.idPrc, display: prc.prcCode };
          }),
        )
        .then(callback);
    },
    [client],
  );

  const loadSatsData = useCallback(
    async (satNumber: string, callback: (callbackValue: any) => any) => {
      client
        .query({
          query: listSatsQuery,
          variables: {
            listAllSatsInput: {
              pagination: {
                _page: 1,
                _limit: 10,
                _orderBy: 'satNumber',
                _sortOrder: 'ASC',
              },
              satNumber,
            },
          },
        })
        .then(res =>
          res.data.listAllSats.data?.map((sat: ISat) => {
            return { id: sat.idSat, display: sat.satNumber };
          }),
        )
        .then(callback);
    },
    [client],
  );

  const loadProductsData = useCallback(
    async (stCode: string, callback: (callbackValue: any) => any) => {
      client
        .query({
          query: listProductsQuery,
          variables: {
            listAllProductsInput: {
              pagination: {
                _page: 1,
                _limit: 10,
                _orderBy: 'stCode',
                _sortOrder: 'ASC',
              },
              stCode,
            },
          },
        })
        .then(res =>
          res.data.listAllProducts.data?.map((product: IProduct) => {
            return { id: product.idProduct, display: product.stCode };
          }),
        )
        .then(callback);
    },
    [client],
  );

  useEffect(() => {
    loadUsersData();
    loadUserGroupsData();
  }, [loadUserGroupsData, loadUsersData]);

  return (
    <Container
      onClick={e => {
        if (onClick) onClick(e);
      }}
    >
      <MentionsInput
        className="mention"
        inputRef={inputRef}
        value={value}
        onChange={e => {
          if (onChange) onChange(e.target.value);
        }}
        placeholder="Write a comment"
        a11ySuggestionsListLabel="Suggested mentions"
        suggestionsPortalHost={suggestionsPortalHost}
        style={{
          suggestions: {
            border: '1px solid #cac8c9',
            borderRadius: '3px',
            maxHeight: '200px',
            overflowY: 'auto',
            item: {
              padding: '5px 10px',
              '&focused': {
                backgroundColor: '#f5f5f5',
              },
            },
          },
        }}
      >
        <Mention
          trigger="@"
          data={userSuggestions}
          markup="@[__display__]@(__id__)"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="@Team"
          data={userGroupSuggestions}
          markup="@[__display__]@Team(__id__)"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="@team"
          data={userGroupSuggestions}
          markup="@[__display__]@Team(__id__)"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="#CQ"
          data={loadClientQuotationsData}
          markup="<a href='#/client-quotations/list/__id__'>__display__</a>"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="#PRC"
          data={loadPrcsData}
          markup="<a href='#/prcs/list/__id__'>__display__</a>"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="#SAT"
          data={loadSatsData}
          markup="<a href='#/commercial/sats/__id__'>__display__</a>"
          className={mentionClassName}
          appendSpaceOnAdd
        />
        <Mention
          trigger="#ST"
          data={loadProductsData}
          markup="<a href='#/products/list/__id__'>__display__</a>"
          className={mentionClassName}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </Container>
  );
};

export default Mentions;
