import styled from 'styled-components';
import { DashboardCard } from '../../../components/Dashboard/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DashboardCardTemplate = styled(DashboardCard)`
  cursor: pointer;
  font-size: 0.9rem;
  --default-text-height: 1.125rem;

  .dashboard-card-title,
  .dashboard-card-details-text,
  .dashboard-card-middle-text,
  .dashboard-card-sat-group {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dashboard-card-title {
    font-weight: 500;
    font-size: 1.17rem;
    line-height: 1.175rem;
  }

  .dashboard-card-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.25rem;

    .dashboard-card-details-text {
      .highligted-text {
        color: var(--blue);
        height: var(--default-text-height);
      }
    }
  }

  .dashboard-card-middle-text {
    display: flex;
    justify-content: space-between;
    font-size: 0.8125rem;
    height: var(--default-text-height);
  }

  .dashboard-card-sat-group {
    height: var(--default-text-height);
  }

  .dashboard-card-dates {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1rem;

    div {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }
`;
