// eslint-disable-next-line no-shadow
export enum RncCreditNoteStatus {
  WAITING_SAT_TO_APPLY = 981,
  REQUEST_CREDIT_NOTE = 982,
  WAITING_FINANCIAL_TO_CREATE = 983,
  CN_CREATED_WAITING_BALANCE = 984,
  CN_CREATED_APPLIED = 985,
  NOT_REQUIRED = 986,
  CANCELLED = 987,
}
