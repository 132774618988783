import {
  ReplyToCommentDialogActionKind,
  ReplyToCommentDialogReducer,
  ReplyToCommentDialogReducerAction,
} from './interfaces';

export const replyToCommentDialogReducerInitialState: ReplyToCommentDialogReducer =
  {
    dialogVisible: false,
  };

export const replyToCommentDialogReducer = (
  state: ReplyToCommentDialogReducer,
  action: ReplyToCommentDialogReducerAction,
): ReplyToCommentDialogReducer => {
  switch (action.type) {
    case ReplyToCommentDialogActionKind.CLOSE_DIALOG:
      return {
        ...state,
        dialogVisible: false,
        commentToReply: undefined,
        replyInitialValue: undefined,
      };

    case ReplyToCommentDialogActionKind.OPEN_DIALOG: {
      if (!action.payload) {
        throw new Error('You need to provide the comment to reply');
      }

      return {
        ...state,
        dialogVisible: true,
        commentToReply: action.payload.commentToReply,
        replyInitialValue: action.payload.replyInitialValue,
      };
    }

    default:
      throw new Error();
  }
};
