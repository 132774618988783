import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTablePageEvent } from 'primereact/datatable';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { confirmDialog } from 'primereact/confirmdialog';
import { SatContainer, SatContainersLazyParams } from './interfaces';
import pagination from '../../../../../../config/pagination';
import {
  deleteSatContainerQuery,
  listSatContainersByForeignTradeQuery,
} from './queries';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import FormTitle from '../../../../../../components/FormTitle';
import Grid from '../../../../../../components/Grid';
import { gridConstants } from '../../../../../../components/Grid/constants';
import MainButton from '../../../../../../components/MainButton';
import ManageContainer, { IManageContainerRef } from './ManageContainer';
import { GridActions } from '../../../../../../components/Grid/styles';

interface ISatContainersProps {
  idSatForeignTrade: number;
  selected: boolean;
  satCanceled: boolean;
}

const SatContainers: React.FC<ISatContainersProps> = ({
  idSatForeignTrade,
  selected,
  satCanceled,
}) => {
  const [deleteSatContainerMutation] = useMutation(deleteSatContainerQuery);

  const manageContainerRef = useRef<IManageContainerRef>(null);
  const { showError, showSuccess } = useRefHook();

  const [lazyParams, setLazyParams] = useState<SatContainersLazyParams>(
    pagination.initialLazyParams,
  );
  const [satContainers, setSatContainers] = useState<SatContainer[]>();
  const [containerBeingDeleted, setContainerBeingDeleted] = useState<number>();

  const [
    loadSatContainers,
    {
      loading: containersLoading,
      data: containersData,
      refetch: containersRefetch,
    },
  ] = useLazyQuery(listSatContainersByForeignTradeQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        idSatForeignTrade,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
        },
      },
    },
    onCompleted: response => {
      if (response.listSatContainersByForeignTrade) {
        setSatContainers([...response.listSatContainersByForeignTrade.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Foreign Trade Container Items',
        detail: errorData.message,
      });
    },
  });

  async function handleDeleteContainer(idSatCtnr: number) {
    setContainerBeingDeleted(idSatCtnr);

    try {
      await deleteSatContainerMutation({
        variables: {
          idSatCtnr,
        },
      });

      await containersRefetch();

      showSuccess({
        summary: 'Container deleted',
      });
    } catch (error) {
      showError({
        summary: 'Error while deleting  Container',
        detail: error.message,
      });
    } finally {
      setContainerBeingDeleted(undefined);
    }
  }

  const onPage = (event: DataTablePageEvent) => {
    setLazyParams(current => {
      return {
        ...current,
        first: event.first,
        page: event.page ?? current.page,
        rows: event.rows,
      };
    });
  };

  const gridActions = (rowData: SatContainer) => {
    const isBeingDeleted = containerBeingDeleted === rowData.idSatCtnr;

    return (
      <GridActions>
        <button
          type="button"
          onClick={() => manageContainerRef.current?.toggle(rowData)}
          disabled={isBeingDeleted}
        >
          <FiEdit2 size={20} />
        </button>
        <button
          type="button"
          className="trash-button"
          onClick={() =>
            confirmDialog({
              message: 'Are you sure you want to delete this container?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => handleDeleteContainer(rowData.idSatCtnr),
            })
          }
          disabled={isBeingDeleted || satCanceled}
        >
          {isBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <FiTrash2 size={20} />
          )}
        </button>
      </GridActions>
    );
  };

  useEffect(() => {
    if (!satContainers && selected) {
      loadSatContainers();
    }
  }, [loadSatContainers, satContainers, selected]);

  return (
    <div className="form-group">
      <FormTitle className="col-12" name="CTNR">
        <MainButton
          label="Add Container"
          onClick={() => manageContainerRef.current?.toggle()}
          disabled={satCanceled}
        />
      </FormTitle>

      <Grid
        className="col-6"
        size="small"
        emptyMessage="No containers found"
        loading={containersLoading}
        value={satContainers}
        lazy
        rows={lazyParams.rows}
        first={!satContainers ? undefined : lazyParams.first}
        totalRecords={
          !containersData
            ? 0
            : containersData?.listSatContainersByForeignTrade?.items
        }
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
        onPage={onPage}
        editMode="row"
      >
        <Column style={{ width: '6rem' }} field="actions" body={gridActions} />
        <Column style={{ flex: '1' }} field="qtyCtnr" header="Qty of CTNR" />
        <Column
          style={{ flex: '1' }}
          field="idTypeCtnr2.description"
          header="Type of CTNR"
        />
      </Grid>

      <ManageContainer
        ref={manageContainerRef}
        idSatForeignTrade={idSatForeignTrade}
        containersRefetch={containersRefetch}
      />
    </div>
  );
};

export default SatContainers;
