import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChecklistData = styled.div`
  padding: 26px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  form {
    width: 400px;
  }

  .add-item-button {
    max-width: 100px;
  }

  .checklist-items-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 587px;
    margin-top: 12px;
  }

  .item-and-preview {
    display: flex;
    width: 100%;

    .checklist-item {
      margin-left: 100px;
      margin-top: 12px;
    }
  }

  .buttons {
    max-width: 389px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    button + button {
      margin-left: 10px;
    }
    .load-other-checklists {
      width: 140px;
      .p-button-icon {
        margin-right: 2px;
      }
    }
  }
`;

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--grey);
  border-radius: 8px;

  padding: 12px;
  max-width: 100%;
  margin-top: 12px;

  .button-div {
    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;
      margin-top: -4px;
      &:hover {
        color: var(--red);
      }
    }
  }

  .field-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
      margin-right: 8px;
    }
  }

  .field-detail + .field-detail {
    margin-top: 8px;
  }

  input,
  .p-dropdown {
    min-width: 300px;
  }
`;

export const OtherChecklistsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const OtherChecklistsHeader = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  width: 50%;
  border-bottom: 1px solid var(--grey-scale);
  font-size: 16px;
  margin-bottom: 4px;
  margin-top: 12px;
`;

export const OtherChecklistsItem = styled.div`
  padding: 4px 12px;
`;
