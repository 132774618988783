/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import {
  IAirFreight,
  IAirFreightFormData,
  AirFreightRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { airFreightRoles } from '../../../shared/roles/airFreight';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const AirFreight: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de air freight
  const idAirFreight = parseInt(
    useParams<AirFreightRouteParams>().idAirFreight,
    10,
  );

  const defaultPageTitle = `${idAirFreight ? 'Manage' : 'Create'} Air Freight`;

  useTitle(defaultPageTitle);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de Air Freight
  const [airFreight, setAirFreight] = useState<IAirFreight>({} as IAirFreight);

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    airFreightRoles.idEntity,
  );

  // Permissions de Air Freight
  const { idPermissionUpdateAirFreight, idPermissionCreateAirFreight } =
    airFreightRoles.permissions;

  // Valida se usuario pode criar ou atualizar Air Freight
  const userCanChange = userHasPermission(
    idAirFreight ? idPermissionUpdateAirFreight : idPermissionCreateAirFreight,
    userPermissionsAirFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Air Freights',
      path:
        initialPage && initialFirst
          ? `/logistics/airFreight?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/airFreight',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
          type: 1,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar air freight
  const listAirFreightQuery = gql`
    query ListLogisticAirFreightById($idAirFreight: Int!) {
      listLogisticAirFreightById(idAirFreight: $idAirFreight) {
        idAirFreight
        idAirportLoading2 {
          idPort
          name
        }
        idAirportDestination2 {
          idPort
          name
        }
        valueFreight
        idCurValue2 {
          idCurrency
          currency
          abbreviation
        }
        idBcValue2 {
          idDomain
          description
        }
        versionLock
        bcCif
        createdAt
        updatedAt
        createdBy2 {
          firstName
          lastName
        }
        updatedBy2 {
          firstName
          lastName
        }
      }
    }
  `;

  /**
   * Busca dados de Air Freight
   */
  const [loadAirFreightData, { loading: airFreightLoading }] = useLazyQuery(
    listAirFreightQuery,
    {
      variables: {
        idAirFreight,
      },
      onCompleted: response => {
        if (response.listLogisticAirFreightById) {
          setAirFreight(response.listLogisticAirFreightById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Air Freight not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting air freight data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/airFreight'),
    });
  }

  // Mutation de criacao de air freight
  const createAirFreightQuery = gql`
    mutation CreateAirFreightMutation($data: CreateLogisticsAirFreightInput!) {
      createLogisticsAirFreight(data: $data) {
        idAirFreight
      }
    }
  `;

  // Mutation de atualizacao de air freight
  const updateAirFreightQuery = gql`
    mutation UpdateAirFreightMutation($data: UpdateLogisticsAirFreightInput!) {
      updateLogisticsAirFreight(data: $data) {
        idAirFreight
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createAirFreightMutation] = useMutation(createAirFreightQuery);
  const [updateAirFreightMutation] = useMutation(updateAirFreightQuery);

  // Submit do formulario de air freight
  const handleAirFreightSubmit = useCallback(
    async (data: IAirFreightFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idAirportLoading: Yup.string().nullable().required('Required Field'),
          idAirportDestination: Yup.string()
            .nullable()
            .required('Required Field'),
          idBcValue: Yup.string().nullable().required('Required Field'),
          idCurValue: Yup.string().nullable().required('Required Field'),
          valueFreight: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        // Valida se esta editando ou criando um novo air freight
        if (!idAirFreight) {
          // Chamada criacao de air freight
          response = await createAirFreightMutation({
            variables: {
              data: {
                idAirportLoading: data.idAirportLoading,
                idAirportDestination: data.idAirportDestination,
                idCurValue: data.idCurValue,
                valueFreight: data.valueFreight,
                idBcValue: data.idBcValue,
                bcCif: !!airFreight.bcCif,
              },
            },
          });
        } else {
          // Chamada atualizacao de air freight
          response = await updateAirFreightMutation({
            variables: {
              data: {
                idAirFreight,
                versionLock: airFreight.versionLock,
                idAirportLoading: data.idAirportLoading,
                idAirportDestination: data.idAirportDestination,
                idCurValue: data.idCurValue,
                valueFreight: data.valueFreight,
                idBcValue: data.idBcValue,
                bcCif: !!airFreight.bcCif,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Air Freight ${idAirFreight ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!idAirFreight) {
          // Direciona usuario para listagem do air freight
          history.push(
            `/logistics/airFreight/${response.data.createLogisticsAirFreight.idAirFreight}`,
          );
        } else {
          setAirFreight({} as IAirFreight);
          loadAirFreightData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idAirFreight ? 'updating' : 'creating'
            } air freight`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createAirFreightMutation,
      formRef,
      history,
      idAirFreight,
      loadAirFreightData,
      airFreight.bcCif,
      airFreight.versionLock,
      toastRef,
      updateAirFreightMutation,
    ],
  );

  useEffect(() => {
    if (idAirFreight) {
      loadAirFreightData();
    }
  }, [idAirFreight, loadAirFreightData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className={idAirFreight ? 'createdAt' : ''}>
          {airFreight && !airFreightLoading
            ? `${new Date(airFreight?.createdAt).toLocaleString()} by ${
                airFreight?.createdBy2?.firstName
              } ${airFreight?.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idAirFreight ? 'updatedAt' : ''}>
          {airFreight && !airFreightLoading
            ? `${new Date(airFreight?.updatedAt).toLocaleString()} by ${
                airFreight?.updatedBy2?.firstName
              } ${airFreight?.updatedBy2?.lastName}`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {((idAirFreight && airFreight.idAirFreight) || !idAirFreight) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleAirFreightSubmit}
            initialData={
              airFreight && !airFreightLoading ? airFreight : undefined
            }
          >
            <Row>
              <FormAsyncSelect
                name="idAirportLoading"
                label="Airport of Loading"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={airFreight?.idAirportLoading2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idAirportDestination"
                label="Airport of Destination"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={airFreight?.idAirportDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormInputNumber
                name="valueFreight"
                label="Air Freight Value"
                decimalScale={2}
                required
                min={0}
                decimalSeparator=","
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idCurValue"
                className="currency-dropdown"
                label="Currency"
                required
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) =>
                  `${option.abbreviation} - ${option.currency}`
                }
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={airFreight?.idCurValue2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idBcValue"
                className="generalInput"
                label="BC Value"
                required
                loadOptions={asyncSelectLoadDomains}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.description}
                getOptionValue={(option: any) => option.idDomain}
                additional={{
                  page: 1,
                  id: 29,
                }}
                noOptionsMessage={() => 'No BC values found'}
                initialValue={airFreight.idBcValue2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <div className="radio-button-div generalInput">
                <p>BC CIF:</p>
                <div>
                  <RadioButton
                    required
                    inputId="yes"
                    name="airFreight.bcCif"
                    value
                    checked={airFreight.bcCif === true}
                    onChange={() =>
                      changeStateProperty('bcCif', true, setAirFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>Yes</label>
                  <RadioButton
                    inputId="no"
                    name="airFreight.bcCif"
                    value={false}
                    checked={airFreight.bcCif !== true}
                    onChange={() =>
                      changeStateProperty('bcCif', false, setAirFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>No</label>
                </div>
              </div>
            </Row>
          </Form>
        </CrudForm>
      )}
      {(airFreightLoading || loading) && <Loading />}
    </Container>
  );
};

export default AirFreight;
