export const designRoles = {
  idEntity: 51,
  idModule: 12,
  fields: {
    status: 1831,
    confirmOrder: 1832,
    satNumber: 1833,
    mainProduct: 1834,
    registerStatus: 2196,
    productionStatus: 2197,
    shipmentStatus: 2198,
    typeOfOrder: 1835,
    replacementFrom: 1836,
    newLayout: 1837,
    activities: 1838,
    artworkQuantity: 1839,
    designLevel: 1840,
    artworkStatus: 1841,
    dueDate: 1842,
    estimatedShipmentDate: 1843,
    importerConsignee: 1844,
    clientNotify: 1845,
    satGroup: 1846,
    samples: 1847,
    artworkStartDate: 1848,
    internalInfo: 1849,
    clientInfo: 1850,
    hrp: 1851,
    supportResponsible: 1852,
    startDoing: 1853,
    sentLayout: 1854,
    layoutApproved: 1855,
    designResponsible: 1856,
    designReview: 1857,
    reviewResponsible: 1858,
    commercialReview: 1859,
    commercialReviewResponsible: 1860,
    sentToClient: 1861,
    approvedByClient: 1862,
    commercialResponsible: 1863,
    licensorApproval: 1864,
    sentFinalArtWork: 1865,
    finalArtWorkResponsible: 1866,
    sketchesReceived: 1867,
    sketchesResponsible: 1868,
    artworkDone: 1869,
    timingHrp: 1870,
    timingStart: 1871,
    timingLayout: 1872,
    timingDoing: 1873,
    timingApproval: 1874,
    timingSketchesReplay: 1875,
    timingSketchesApproval: 1876,
    timingTotalArtwork: 1877,
    countryOrigin: 1878,
    category: 1879,
    packagesNotes: 1880,
    remarks: 1881,
    designNotes: 1882,
  },
  permissions: {
    idPermissionExportSatsDesign: 183,
  },
};
