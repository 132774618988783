import { gql } from '@apollo/client';

export const listSatRncCostsBySatRncIdQuery = gql`
  query ListSatRncCostsBySatRncId($idSatRnc: Int!) {
    listSatRncCostsBySatRncId(idSatRnc: $idSatRnc) {
      idSatRncCost
      description
      idCurrency
      idCurrency2 {
        abbreviation
      }
      amount
    }
  }
`;

export const deleteSatRncCostQuery = gql`
  mutation DeleteSatRncCost($satRncCostId: Int!) {
    deleteSatRncCost(satRncCostId: $satRncCostId)
  }
`;
