import generateRandomString from '../../../../../../../utils/generateRandomString';
import { ProductPackageReference } from '../../../interfaces';
import {
  AddReferenceReducer,
  AddReferenceReducerAction,
  AddReferenceReducerActionKind,
} from './interfaces';

export const addReferenceReducerInitialState: AddReferenceReducer = {
  displayDialog: false,
  lastReferenceIndex: 0,
};

export const addReferenceReducer = (
  state: AddReferenceReducer,
  action: AddReferenceReducerAction,
): AddReferenceReducer => {
  switch (action.type) {
    case AddReferenceReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        lastReferenceIndex: 0,
      };

    case AddReferenceReducerActionKind.OPEN_DIALOG: {
      if (!action.payload) {
        throw new Error('You need to provide the idProductPackage');
      }

      const packageReference: ProductPackageReference = {
        idPackageReference: generateRandomString(4),
        idProductPackage: action.payload.idProductPackage,
      };

      if (!state.displayDialog && action.payload?.lastReferenceIndex) {
        return {
          ...state,
          lastReferenceIndex: action.payload?.lastReferenceIndex,
          displayDialog: !state.displayDialog,
          packageReference,
        };
      }

      return {
        ...state,
        displayDialog: !state.displayDialog,
        packageReference,
      };
    }

    default:
      throw new Error();
  }
};
