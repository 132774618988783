import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const LinkedPrcs = styled.div`
  padding: 4px;
  height: 46px;

  .linked-prc-label {
    color: var(--grey-scale);
  }

  div {
    display: flex;
  }

  a {
    text-decoration: none;
    color: var(--blue);
    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }

    svg {
      margin-left: 2px;
    }
  }

  a + a {
    margin-left: 12px;
  }
`;
