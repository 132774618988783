import { satsRoles } from './sat';

export const satItemsRoles = {
  idEntity: satsRoles.idEntity,
  idModule: satsRoles.idModule,
  fields: {
    idFieldAmount: 375,
    idFieldAmountCot: 376,
    idFieldBrand: 379,
    idFieldCbm: 380,
    idFieldCbmTotal: 381,
    idFieldColour: 384,
    idFieldCotPrice: 386,
    idFieldCotPriceFinal: 387,
    idFieldDescription: 391,
    idFieldDimensions: 393,
    idFieldDisbursement: 395,
    idFieldEstimateMargin: 398,
    idFieldEstimateProfit: 399,
    idFieldFx: 400,
    idFieldGeneralLI: 401,
    idFieldGrossMargin: 402,
    idFieldGrossProfit: 403,
    idFieldGrossWeight: 404,
    idFieldGrossWeightTotal: 405,
    idFieldHeight: 406,
    idFieldHsCode: 407,
    idFieldIdColourImage: 408,
    idFieldIdImage: 409,
    idFieldIdPackage: 410,
    idFieldIdSatSupplier: 414,
    idFieldLength: 426,
    idFieldLoadingAmountCot: 428,
    idFieldM2Pc: 429,
    idFieldMarketSurvey: 430,
    idFieldMasterM2: 431,
    idFieldMasterNetWeight: 432,
    idFieldMasterNetWeightTotal: 433,
    idFieldMaterialComposition: 434,
    idFieldModel: 436,
    idFieldMoq: 437,
    idFieldMultiplicator: 438,
    idFieldName: 439,
    idFieldNamePT: 441,
    idFieldNcm: 442,
    idFieldNetWeight: 443,
    idFieldNotes: 444,
    idFieldPackageDescription: 445,
    idFieldPiecesContainer: 447,
    idFieldPriceFob: 448,
    idFieldProductLine: 449,
    idFieldQuantity: 451,
    idFieldQuantityContainer: 452,
    idFieldQuantityShipped: 455,
    idFieldSample: 456,
    idFieldSampleNotes: 457,
    idFieldSellingPackageDimensions: 458,
    idFieldSellingPackageGrossWeight: 460,
    idFieldSellingPackagePCQuantity: 461,
    idFieldSku: 462,
    idFieldStCode: 463,
    idFieldSunPrice: 465,
    idFieldTargetCot: 467,
    idFieldTargetSun: 468,
    idFieldTotalMasterM2: 470,
    idFieldTotalMasterM2Shipped: 471,
    idFieldUnit: 472,
    idFieldUnitCost: 473,
    idFieldWidth: 476,
    idFieldTotalQuantity: 659,
    idFieldTotalQuantitySatItems: 660,
    idFieldTotalQuantityCtn: 661,
    idFieldTotalMNWT: 662,
    idFieldTotalMGWT: 663,
    idFieldTotalCBM: 664,
    idFieldTotalAmountCot: 665,
    idFieldTotalAmountSun: 666,
    idFieldTotalEstimatedMargin: 667,
    idFieldRefCode: 1128,
    idFieldDetailedDescriptionPt: 1130,
    extraInfoEn: 1788,
    extraInfoPt: 1790,
    idFieldDescriptionPt: 1131,
    idFieldColorPt: 1132,
    idFieldMaterialCompositionPt: 1133,
    idFieldPackageNotes: 1134,
    idFieldUnitPerContainer: 1135,
    idFieldPackageEan13: 1136,
    idFieldInnerdun14: 1137,
    idFieldDun14: 1138,
    idFieldArtworkEn: 1139,
    idFieldArtworkPt: 1140,
    idFieldTechniqueEn: 1141,
    idFieldTechniquePt: 1142,
    idFieldTypeOfMaterial: 1143,
    idFieldLining: 1144,
    idFieldLiningComposition: 1145,
    idFieldLiningDescription: 1146,
    idFieldTypePackageDescription: 1147,
    idFieldRemainQuantity: 1148,
    idFieldTotalSqmCi: 1149,
    idFieldRemainSqm: 1150,
    idFieldCotPriceSqm: 1151,
    idFieldSunPriceSqm: 1152,
    idFieldPurchaseIncotermDescription: 1153,
    idFieldRemainTotalCot: 1154,
    idFieldSellingIncotermDescription: 1155,
    idFieldTotalSunCi: 1156,
    idFieldRemainTotalSun: 1157,
    idFieldNcmDestaque: 1158,
    idFieldForeignTradeReview: 1159,
    idFieldNcmIi: 1160,
    idFieldNcmIpi: 1161,
    idFieldNcmPis: 1162,
    idFieldNcmCofins: 1163,
    idFieldNcmDumping: 1164,
    idFieldContainerNumber: 1165,
    idFieldSampleLeadTime: 1166,
    idFieldSamplesApproved: 1167,
    idFieldLicensed: 1168,
    idFieldLicensorName: 1169,
    idFieldRoyalties: 1170,
    idFieldQtyOfCtnr: 1180,
    idFieldSatItemReferencesAttachment: 1182,
    idFieldSatItemReferencesColourEn: 1183,
    idFieldSatItemReferencesColourPt: 1184,
    idFieldSatItemReferencesGenderEn: 1185,
    idFieldSatItemReferencesSizeGroupEn: 1186,
    idFieldSatItemReferencesTotalPiColor: 1187,
    idFieldSatItemReferencesTotalCiColor: 1188,
    idFieldSatItemReferencesRemailColorVar: 1189,
    idFieldSatItemReferenceSizeBarcodeSize: 1190,
    idFieldSatItemReferenceSizeBarcodeReference: 1191,
    idFieldSatItemReferenceSizeBarcodeUnit: 1192,
    idFieldSatItemReferenceSizeBarcodeSellingPackage: 1193,
    idFieldSatItemReferenceSizeBarcodeUnitInnerBox: 1194,
    idFieldSatItemReferenceSizeBarcodeInnerBox: 1195,
    idFieldSatItemReferenceSizeBarcodeUnitMasterBox: 1196,
    idFieldSatItemReferenceSizeBarcodeMasterBox: 1197,
    idFieldSatItemReferenceSizeBarcodeCotPiSize: 1198,
    idFieldSatItemReferenceSizeBarcodeSunPiSize: 1199,
    idFieldSatItemReferenceSizeBarcodeQtyCiSize: 1200,
    idFieldSatItemReferenceSizeBarcodeRemainQtySize: 1201,
    idFieldSatItemReferenceSizeBarcodeCotCiSize: 1202,
    idFieldSatItemReferenceSizeBarcodeSunCiSize: 1203,
    idFieldSatItemReferenceSizeBarcodeRemainTotCotSize: 1204,
    idFieldSatItemReferenceSizeBarcodeRemainTotSunSize: 1205,
    idFieldSatItemReferenceSizeBarcodeSizeRange: 1206,
    idFieldSatItemReferenceSizeBarcodeQtyPiSize: 1207,
    idFieldSatItemQuantityInner: 1211,
    idFieldTotalMargin: 1208,
    idFieldTotalSumCotCi: 1209,
    idFieldTotalSumSunCi: 1210,
    idFieldRefreshSunPrice: 1212,
    idFieldRefreshCotPrice: 1213,
    idFieldRefreshSunNumber: 1214,
    idFieldRefreshMoq: 1215,
    idFieldRefreshModel: 1216,
    idFieldRefreshSku: 1217,
    idFieldRefreshNetWeight: 1218,
    idFieldRefreshDetailedDescriptionPt: 1219,
    idFieldRefreshName: 1220,
    idFieldRefreshNamePt: 1221,
    idFieldRefreshMaterialComposition: 1222,
    idFieldRefreshMaterialPt: 1223,
    idFieldRefreshColour: 1224,
    idFieldRefreshColourPt: 1225,
    idFieldRefreshDescription: 1226,
    idFieldRefreshDescriptionPt: 1227,
    idFieldRefreshDimensions: 1228,
    idFieldRefreshImportantInformation: 1229,
    idFieldRefreshPackage: 1230,
    idFieldRefreshBrand: 1231,
    idFieldRefreshArtworkTechnique: 1232,
    idFieldRefreshM2Pc: 1233,
    idFieldRefreshImage: 1234,
    idFieldRefreshColourImage: 1235,
    idFieldRefreshHsCode: 1236,
    idFieldRefreshNcm: 1237,
    idFieldStickerDimensionsPt: 1262,
    idFieldCentimeterDimensions: 1789,
    idFieldInnerBox: 1263,
    idFieldMainProduct: 1238,
    idFieldCategory: 1239,
    idFieldTypeOfOrder: 1240,
    idFieldSatOrigin: 1241,
    idFieldSatPackageNotes: 1242,
    idFieldRemarks: 1243,
    idFieldIndSamples: 1244,
    idFieldSamplesLeadTime: 1245,
    idFieldClient: 1246,
    idFieldImporter: 1247,
    idFieldClientIncoterm: 1248,
    idFieldPaymentTermAdvance: 1249,
    idFieldPaymentTermBalance: 1250,
    idFieldPaymentTermCondition: 1251,
    idFieldPurchaseIncoterm: 1252,
    idFieldSupplierExporter: 1253,
    idFieldExporterAddress: 1254,
    idFieldProductionTime: 1255,
    idFieldSatSuppliers: 1256,
    idFieldEstimatedArrivalClientDate: 1257,
    idFieldEstimatedInspectionDate: 1258,
    idFieldEstimatedShipmentDate: 1259,
    idFieldEstimatedArrivalDate: 1260,
    idFieldSatSupplierNames: 1264,
    idFieldTotalCotCi: 1336,
    idFieldTotalCotPiShipmentSum: 2219,
    idFieldTotalSunPiShipmentSum: 2220,
    idFieldMarginShipmentSum: 2221,
    idFieldNve: 2236,
  },
};
