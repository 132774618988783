/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BiCommentX } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';
import { IoMdAttach } from 'react-icons/io';
import { isEqual } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import FormTitle from '../../../../../components/FormTitle';

import { Attachment, Attachments, Comments, Container } from './styles';

import AdvancedFiltersPanel from '../../../../../components/AdvancedFiltersPanel';
import {
  AdvancedFiltersFieldType,
  IAdvancedFiltersField,
} from '../../../../../components/AdvancedFiltersPanel/interfaces';
import Button from '../../../../../components/Button';
import CommentAdding, {
  ICommentAddingRef,
} from '../../../../../components/CommentAdding';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../../components/CommentPagination';
import CommentSkeleton from '../../../../../components/CommentSkeleton';
import Empty from '../../../../../components/Empty';
import Loading from '../../../../../components/Loading';
import Image from '../../../../../components/PageHeader/Image';
import pagination, {
  searchDelayMiliseconds,
} from '../../../../../config/pagination';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IRoleEntityPermission } from '../../../../../interfaces/IRoleEntityPermission';
import { ISatAttachment } from '../../../../../interfaces/ISatAttachment';
import { FileType } from '../../../../../shared/enums/fileType';
import { IComment } from '../../../../../shared/interfaces/comment';
import { satsRoles } from '../../../../../shared/roles/sat';

import userHasPermission from '../../../../../utils/userHasPermission';
import { ISatCommentsLazyParams, SatComment } from '../interfaces';
import {
  createSatCommentQuery,
  deleteSatCommentQuery,
  listSatAttachmentsToUpdatesBySatIdQuery,
  listSatCommentsBySatIdQuery,
  updateSatCommentQuery,
} from '../queries';
import { IAttachment } from '../../../../../shared/interfaces/attachment';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { imageExtensions } from '../../../../../shared/constants/imageExtensions';
import {
  createSatAttachmentsQuery,
  deleteSatAttachmentsQuery,
} from '../../queries';
import { parseCommentMessage } from '../../../../../utils/parseCommentMessage';
import FileUpload from '../../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';
import CommentAndReplies, {
  commentSearchParams,
} from '../../../../../components/Comments/CommentAndReplies';

interface IUpdatesContentProps {
  selected: boolean;
  idSat: number;
  roleEntityPermissions: IRoleEntityPermission[];
  idSatForeignTrade?: number;
  refetchUpdatesTabs(): void;
}

const UpdatesContent: React.FC<IUpdatesContentProps> = ({
  idSat,
  selected,
  roleEntityPermissions,
  idSatForeignTrade,
  refetchUpdatesTabs,
}) => {
  const isMounted = useRef(false);
  const { idPermissionShowUpdatesRestrictInfo, idPermissionManageUpdate } =
    satsRoles.permissions;

  const { search } = useLocation();

  const [createSatCommentMutation] = useMutation(createSatCommentQuery);
  const [updateSatCommentMutation] = useMutation(updateSatCommentQuery);
  const [deleteSatCommentMutation] = useMutation(deleteSatCommentQuery);
  const [createSatAttachmentMutation] = useMutation(createSatAttachmentsQuery);
  const [deleteSatAttachmentsMutation] = useMutation(deleteSatAttachmentsQuery);

  const { showError, showSuccess } = useRefHook();

  const commentAddingRef = useRef<ICommentAddingRef>(null);

  // Estado de update selecionado
  const [selectedUpdate, setSelectedUpdate] = useState<SatComment>(
    {} as SatComment,
  );

  // Estado do checkbox All SAT
  const [allSatChecked, setAllSatChecked] = useState<boolean>(false);
  const [highlightedComment, setHighlightedComment] = useState<number>();

  const userCanSeeRestrictInfo = useMemo(() => {
    return userHasPermission(
      idPermissionShowUpdatesRestrictInfo,
      roleEntityPermissions,
    );
  }, [idPermissionShowUpdatesRestrictInfo, roleEntityPermissions]);

  const initialLazyParams = useMemo(() => {
    return {
      ...pagination.initialLazyParams,
      fetchAutomaticComments: true,
    };
  }, []);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] =
    useState<ISatCommentsLazyParams>(initialLazyParams);

  const [pageLoading, setPageLoading] = useState(false);

  const cleanSelected = useCallback(() => {
    setSelectedUpdate({} as SatComment);
    setAllSatChecked(false);
  }, []);

  const handleEditUpdate = useCallback(
    (update: SatComment) => {
      setSelectedUpdate(update);
      commentAddingRef.current?.editComment({
        ...update.idComment2,
      });
      if (idSatForeignTrade && !update.idSatForeignTrade) {
        setAllSatChecked(true);
      }
    },
    [idSatForeignTrade],
  );

  const [updates, setUpdates] =
    useState<{ data: SatComment[]; items: number }>();

  const [satAttachments, setSatAttachments] =
    useState<(ISatAttachment | undefined)[]>();

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  // Busca global
  const [globalFilter, setGlobalFilter] = useState('');

  const advancedFiltersFields: IAdvancedFiltersField[] = useMemo(() => {
    const items = [];

    items.push(
      { advancedFilterField: 'createdBy', header: 'Created By', value: '' },
      {
        advancedFilterField: 'createdCommentStartDateRange',
        header: 'Created update start date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'createdCommentEndDateRange',
        header: 'Created update end date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'isGeneratedSystem',
        header: 'System generated updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      },
    );

    if (userCanSeeRestrictInfo) {
      items.push({
        advancedFilterField: 'isRestrictInfo',
        header: 'Restrict info updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      });
    }

    return items;
  }, [userCanSeeRestrictInfo]);

  const [loadUpdatesData, { loading: updatesDataLoading }] = useLazyQuery(
    listSatCommentsBySatIdQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idSat,
          isFollowUp: false,
          globalSearch: lazyParams.globalFilter,
          createdBy: lazyParams.createdBy,
          createdCommentStartDateRange: lazyParams.createdCommentStartDateRange,
          createdCommentEndDateRange: lazyParams.createdCommentEndDateRange,
          isGeneratedSystem: lazyParams.isGeneratedSystem,
          isRestrictInfo: lazyParams.isRestrictInfo,
          fetchAutomaticComments: lazyParams.fetchAutomaticComments,
          idSatForeignTrade,
          highlightedComment: highlightedComment || undefined,
        },
      },
      onCompleted: response => {
        if (response.listSatCommentsBySatId) {
          if (lazyParams.page && updates) {
            setUpdates({
              items: response.listSatCommentsBySatId.items,
              data: [...updates.data, ...response.listSatCommentsBySatId.data],
            });
          } else {
            setUpdates(response.listSatCommentsBySatId);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT updates',
          detail: errorData.message,
        });
      },
    },
  );

  function changeSearchLazyParams(newValue: ISatCommentsLazyParams) {
    // O comentario em foco deve perder destaque quando o usuario altera
    // parametros de pesquisa
    setHighlightedComment(undefined);
    setLazyParams(newValue);
  }

  const handleRefreshUpdates = useCallback(() => {
    if (!isEqual(lazyParams, initialLazyParams)) {
      changeSearchLazyParams(initialLazyParams);
    } else {
      loadUpdatesData();
    }
  }, [initialLazyParams, lazyParams, loadUpdatesData]);

  const handleSubmitUpdate = useCallback(
    async (e: IComment) => {
      setPageLoading(true);
      try {
        if (!selectedUpdate.idSat) {
          await createSatCommentMutation({
            variables: {
              data: {
                idSat,
                message: e.message,
                isRestrictInfo: e.isRestrictInfo,
                isFollowUp: false,
                idAttachment: e.idAttachment,
                idSatForeignTrade: allSatChecked
                  ? undefined
                  : idSatForeignTrade,
              },
            },
          });

          if (idSatForeignTrade && allSatChecked) {
            refetchUpdatesTabs();
          } else {
            handleRefreshUpdates();
          }
        } else {
          // Editar comentario
          await updateSatCommentMutation({
            variables: {
              data: {
                idSatComment: selectedUpdate.idSatComment,
                message: e.message,
                isRestrictInfo: e.isRestrictInfo,
                idAttachment: e.idAttachment,
                idSatForeignTrade: allSatChecked ? null : idSatForeignTrade,
              },
            },
          });

          const allSatCheckboxChanged =
            !!selectedUpdate.idSatForeignTrade !== !allSatChecked;

          if (idSatForeignTrade && allSatCheckboxChanged) {
            refetchUpdatesTabs();
          } else {
            handleRefreshUpdates();
          }
        }

        showSuccess({
          summary: 'Update published',
        });

        setSelectedUpdate({} as SatComment);
      } catch (err) {
        showError({
          summary: `Error while ${
            selectedUpdate.idSat ? 'updating' : 'creating'
          } update`,
          detail: err.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      createSatCommentMutation,
      handleRefreshUpdates,
      idSat,
      selectedUpdate.idSat,
      selectedUpdate.idSatComment,
      selectedUpdate.idSatForeignTrade,
      allSatChecked,
      idSatForeignTrade,
      showError,
      showSuccess,
      updateSatCommentMutation,
      refetchUpdatesTabs,
    ],
  );

  const handleDeleteUpdate = useCallback(
    async (idSatComment: number, updateIdSatForeignTrade?: number) => {
      setPageLoading(true);
      try {
        await deleteSatCommentMutation({
          variables: {
            idSatComment,
          },
        });

        showSuccess({
          summary: 'Update deleted',
        });

        if (idSatForeignTrade && !updateIdSatForeignTrade) {
          refetchUpdatesTabs();
        } else {
          handleRefreshUpdates();
        }
      } catch (error) {
        showError({
          summary: 'Error while deleting update',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      idSatForeignTrade,
      deleteSatCommentMutation,
      handleRefreshUpdates,
      refetchUpdatesTabs,
      showError,
      showSuccess,
    ],
  );

  const [loadAttachments] = useLazyQuery(
    listSatAttachmentsToUpdatesBySatIdQuery,
    {
      variables: {
        data: {
          idSat,
          isFollowUp: false,
          idSatForeignTrade,
          idTypes: idSatForeignTrade
            ? [
                AttachmentTypes.UPDATE_FILE,
                AttachmentTypes.UPDATE_SAT_FOREIGN_TRADE,
              ]
            : [AttachmentTypes.UPDATE_FILE],
        },
      },
      onCompleted: response => {
        if (response.listSatAttachmentsToUpdatesBySatId?.data) {
          setSatAttachments(response.listSatAttachmentsToUpdatesBySatId.data);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT updates',
          detail: errorData.message,
        });
      },
    },
  );

  const handleDeleteAttachment = useCallback(
    async (satAttachment: ISatAttachment | undefined) => {
      setPageLoading(true);
      try {
        await deleteSatAttachmentsMutation({
          variables: {
            satAttachmentIds: satAttachment?.idSatAttachment,
          },
        });

        showSuccess({
          summary: 'Update deleted',
        });
        loadAttachments();
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting sat attachment',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      deleteSatAttachmentsMutation,
      loadAttachments,
      loadUpdatesData,
      showError,
      showSuccess,
    ],
  );

  const userCanManageComment = useMemo(() => {
    return userHasPermission(idPermissionManageUpdate, roleEntityPermissions);
  }, [idPermissionManageUpdate, roleEntityPermissions]);

  useEffect(() => {
    if (selected && !updates) {
      loadUpdatesData();
      loadAttachments();
    }
  }, [loadAttachments, loadUpdatesData, selected, updates]);

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        setLazyParams(currentLazyParams => {
          return {
            ...currentLazyParams,
            globalFilter,
            first: pagination.initialLazyParams.first,
            page: pagination.initialLazyParams.page,
            rows: pagination.initialLazyParams.rows,
          };
        });
        setHighlightedComment(undefined);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }

    isMounted.current = true;
    return undefined;
  }, [globalFilter]);

  const onPage = useCallback(
    (e: ICommentPaginationPageParams) => {
      setLazyParams({ ...lazyParams, ...e });
    },
    [lazyParams],
  );

  const handleAddAttachmentComment = (attachment: IAttachment | undefined) => {
    if (attachment) {
      commentAddingRef.current?.addCommentFromAttachment(attachment);
    }
  };

  async function uploadAttachment(e: FileUploadResponse[]) {
    setPageLoading(true);
    try {
      const response = await createSatAttachmentMutation({
        variables: {
          data: {
            idSat,
            idSatForeignTrade,
            uploadedFiles: e.map(file => file.serverName),
            idType: idSatForeignTrade
              ? AttachmentTypes.UPDATE_SAT_FOREIGN_TRADE
              : AttachmentTypes.UPDATE_FILE,
          },
        },
      });

      showSuccess({
        summary: 'Update published',
      });
      loadAttachments();
      return response.data?.createSatAttachments[0]?.idAttachment2;
    } catch (err) {
      showError({
        summary: `Error while creating attachment`,
        detail: err.message,
      });
      return undefined;
    } finally {
      setPageLoading(false);
    }
  }

  const chooseOptions = {
    icon: 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  const toggleAutomaticComments = () => {
    changeSearchLazyParams({
      ...initialLazyParams,
      fetchAutomaticComments: !lazyParams.fetchAutomaticComments,
    });
  };

  const allSatCheckbox = () => {
    return (
      idSatForeignTrade && (
        <>
          <Checkbox
            className="mr-2"
            inputId="allSat"
            onChange={e => setAllSatChecked(!!e.checked)}
            checked={allSatChecked}
          />
          <label className="p-checkbox-label ml-auto mr-3" htmlFor="allSat">
            All SAT
          </label>
        </>
      )
    );
  };

  useEffect(() => {
    function getHiglightedComment() {
      const value = parseInt(
        new URLSearchParams(search).get(commentSearchParams.idComment) ?? '',
        10,
      );

      setHighlightedComment(value || undefined);
    }

    getHiglightedComment();
  }, [search]);

  return (
    <Container>
      <AdvancedFiltersPanel
        innerRef={advancedFiltersPanelRef}
        fields={advancedFiltersFields}
        onApply={e =>
          changeSearchLazyParams({
            ...lazyParams,
            ...e,
            first: pagination.initialLazyParams.first,
            page: pagination.initialLazyParams.page,
            rows: pagination.initialLazyParams.rows,
          })
        }
        onClear={() =>
          changeSearchLazyParams({
            ...pagination.initialLazyParams,
            globalFilter,
          })
        }
      />
      <div className="content-header">
        <FormTitle name="Updates (EN)" />

        <span className="content-header-options">
          {userCanManageComment && (
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => uploadAttachment(e)}
            />
          )}
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              placeholder="Search an update"
            />
          </IconField>
          <Button
            label="Filters"
            onClick={e => advancedFiltersPanelRef.current?.toggle(e, e.target)}
          />
        </span>
      </div>
      {satAttachments && satAttachments.length > 0 && (
        <Attachments>
          <div className="col-12 subtitle">
            <IoMdAttach className="attachment-icon" size={25} />
            <span>Attachment</span>
          </div>
          {
            // eslint-disable-next-line array-callback-return, consistent-return
            satAttachments?.map(satAttachment => {
              const attachment = satAttachment?.idAttachment2;
              const fileExtension = attachment?.name?.split('.').pop();
              return (
                <Attachment key={satAttachment?.idSatAttachment}>
                  <div className="col-3">
                    {fileExtension &&
                    imageExtensions.includes(fileExtension) ? (
                      <Image
                        src={attachment?.url ?? ''}
                        alt={`Comment image ${attachment?.nameGivenByUser}`}
                      />
                    ) : (
                      <div className="file-extension">{fileExtension}</div>
                    )}
                  </div>
                  <div className="col-9 image-info">
                    <div className="col-12 flex align-items-center">
                      <span className="image-name">
                        {attachment?.nameGivenByUser}
                      </span>
                      <a
                        href={attachment?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="attachment"
                      >
                        <FiExternalLink className="mx-2" />
                      </a>
                    </div>
                    <div className="col-12">
                      {`Added: ${
                        attachment?.createdAt
                          ? new Date(attachment.createdAt).toLocaleString()
                          : ''
                      } - `}
                      <button
                        onClick={() =>
                          confirmDialog({
                            message: 'Are you sure you want to delete?',
                            header: 'Delete Confirmation',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button-danger',
                            accept: () => handleDeleteAttachment(satAttachment),
                          })
                        }
                        type="button"
                      >
                        Delete
                      </button>
                      {' - '}
                      <button
                        onClick={() =>
                          handleAddAttachmentComment(
                            satAttachment?.idAttachment2,
                          )
                        }
                        type="button"
                      >
                        Add Comment
                      </button>
                    </div>
                  </div>
                </Attachment>
              );
            })
          }
        </Attachments>
      )}
      <Comments>
        <div className="my-4">
          <div className="flex justify-content-between my-2">
            <div className="subtitle">
              <i className="pi pi-comments" />
              <span>Comments</span>
            </div>
            <button
              className="button-hide"
              onClick={() => toggleAutomaticComments()}
              type="button"
            >
              {lazyParams.fetchAutomaticComments ? 'Hide' : 'Show'} automatic
              comments
            </button>
          </div>
          <CommentAdding
            ref={commentAddingRef}
            onSaveComment={handleSubmitUpdate}
            cleanSelected={cleanSelected}
            showRestrictInfo={userCanSeeRestrictInfo}
            onUploadAttachment={uploadAttachment}
            additionalOptions={allSatCheckbox()}
          />
        </div>
        {((updatesDataLoading && !!lazyParams.page) || !updatesDataLoading) &&
          updates &&
          updates.data.map(update => {
            return (
              <CommentAndReplies
                idComment={update.idComment}
                highlighted={highlightedComment === update.idComment}
                showRestrictInfo={userCanSeeRestrictInfo}
                comment={update.idComment2}
                userName={`${update.idComment2.createdBy2?.firstName} ${update.idComment2.createdBy2?.lastName}`}
                creation={update.idComment2.createdAt ?? new Date()}
                editedData={
                  update.idComment2.updatedBy2
                    ? {
                        editedAt: update.idComment2.updatedAt,
                        editedBy: `${update.idComment2.updatedBy2?.firstName} ${update.idComment2.updatedBy2?.lastName}`,
                      }
                    : undefined
                }
                commonTag={{
                  className: 'mr-2',
                  rounded: true,
                  isGeneratedSystem: update.idComment2.isGeneratedSystem,
                  isRestrictInfo: update.idComment2.isRestrictInfo,
                  value: update.isStatusCfr ? 'CFR' : undefined,
                  severity: update.isStatusCfr ? 'success' : undefined,
                }}
                onEdit={
                  userCanManageComment
                    ? () => handleEditUpdate(update)
                    : undefined
                }
                onDelete={
                  userCanManageComment
                    ? () =>
                        confirmDialog({
                          message:
                            idSatForeignTrade && !update.idSatForeignTrade
                              ? 'This update belongs to the entire SAT, are you sure you want to delete it?'
                              : 'Are you sure you want to delete this update?',
                          header: 'Delete Confirmation',
                          icon: 'pi pi-info-circle',
                          acceptClassName: 'p-button-danger',
                          accept: () =>
                            handleDeleteUpdate(
                              update.idSatComment,
                              update.idSatForeignTrade,
                            ),
                        })
                    : undefined
                }
                userImageUrl={update.idComment2.createdBy2?.avatarUrl}
                key={update.idSatComment}
                attachmentUrl={update.idComment2.idAttachment2?.url}
                idCreatedBy={update.idComment2.createdBy}
                onUploadAttachment={uploadAttachment}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: parseCommentMessage(update.idComment2),
                  }}
                  className="comment"
                />
              </CommentAndReplies>
            );
          })}
      </Comments>

      <CommentPagination
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={updates?.items ?? 0}
        onPage={onPage}
        loading={updatesDataLoading}
        page={lazyParams.page}
      />

      {updatesDataLoading && !lazyParams.page && (
        <>
          <CommentSkeleton />
          <CommentSkeleton />
        </>
      )}

      {!updatesDataLoading && !updates?.items && (
        <Empty message="No updates found">
          <BiCommentX size={25} />
        </Empty>
      )}

      {pageLoading && <Loading />}
    </Container>
  );
};

export default UpdatesContent;
