import styled, { css } from 'styled-components';

interface ContainerProps {
  readOnly?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--grey);
  padding: 4px;
  min-height: 46px;
  border: solid 1px transparent;
  border-radius: 2px;

  ${props =>
    props.readOnly &&
    css`
      background: none;
      opacity: unset !important;
      cursor: auto !important;
      input {
        opacity: unset !important;
        cursor: auto !important;
      }
    `}

  div {
    display: flex;
    align-items: center;
  }

  > p {
    color: var(--grey-scale);
  }

  p + .p-radiobutton {
    margin-left: 12px;
  }

  .p-radiobutton + p {
    margin-left: 4px;
  }
`;
