import { TComment } from '../CommentAndReplies/interfaces';

// eslint-disable-next-line no-shadow
export enum EditReplyDialogActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export type EditReplyDialogReducer = {
  commentToEdit?: TComment;
  dialogVisible: boolean;
};

export type EditReplyDialogReducerAction = {
  type: EditReplyDialogActionKind;
  payload?: {
    commentToEdit: TComment;
  };
};
