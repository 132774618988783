import React, { RefObject } from 'react';
import { OverlayPanel, OverlayPanelProps } from 'primereact/overlaypanel';
import { FiX } from 'react-icons/fi';
import { Container, Header, Content } from './styles';

interface AdvancedFiltersPanelProps extends OverlayPanelProps {
  innerRef: RefObject<OverlayPanel>;
}

const AdvancedFiltersPanel: React.FC<AdvancedFiltersPanelProps> = ({
  innerRef,
  children,
  ...rest
}) => {
  return (
    <Container>
      <OverlayPanel
        ref={innerRef}
        dismissable
        {...rest}
        className="overlay-panel"
      >
        <Header>
          <h1> Advanced Filters</h1>
          <button type="button" onClick={() => innerRef?.current?.hide()}>
            <FiX size={18} />
          </button>
        </Header>
        <Content>{children}</Content>
      </OverlayPanel>
    </Container>
  );
};

export default AdvancedFiltersPanel;
