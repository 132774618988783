import {
  TreeSelect,
  TreeSelectEventNodeEvent,
  TreeSelectProps,
} from 'primereact/treeselect';
import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Category } from './interfaces';
import { listCategoriesQuery } from './queries';
import { useRefHook } from '../../hooks/useRefHook';
import { findParentsOnTree } from '../../utils/findParentsOnTree';

const placeHolder = 'Select a Category';

const TreeSelectCategory: React.FC<TreeSelectProps> = ({
  onNodeSelect,
  valueTemplate,
  ...rest
}) => {
  const { showError } = useRefHook();

  const [categories, setCategories] = useState<Category[]>([]);

  const [loadCategories, { loading }] = useLazyQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        const categoryList: Category[] = JSON.parse(
          response.listAllCategories.data,
        );
        setCategories(categoryList);
      }
    },
  });

  function handleNodeSelect(e: TreeSelectEventNodeEvent) {
    if (onNodeSelect) {
      e.node.label = findParentsOnTree(categories, e.node.key);
      onNodeSelect(e);
    }
  }

  function loadCategoriesLazy() {
    if (!categories.length) {
      loadCategories();
    }
  }

  return (
    <TreeSelect
      {...rest}
      options={categories}
      placeholder={placeHolder}
      onNodeSelect={handleNodeSelect}
      valueTemplate={valueTemplate ?? placeHolder}
      emptyMessage={loading ? 'Loading...' : 'No Categories Found'}
      dropdownIcon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'}
      onShow={() => loadCategoriesLazy()}
    />
  );
};

export default TreeSelectCategory;
