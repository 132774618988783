import styled from 'styled-components';

export const Container = styled.div`
  .generalInput {
    height: 46px;
  }
`;

export const PricingItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 940px;

  textarea {
    resize: vertical;
  }

  .supplierDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 274px;
    height: 46px;
  }

  .form-textarea-input {
    width: 274px;
  }

  > div + div {
    margin-left: 20px;
  }

  div {
    .s-readonly-input,
    .form-checkbox,
    .form-textarea-input + .generalInput,
    .generalInput + .form-textarea-input,
    .generalInput + .generalInput,
    .supplierDiv + .generalInput,
    .link + .generalInput {
      margin-top: 16px;
    }
  }
  .formInput {
    width: 274px;
  }

  .button-div {
    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }

  .supplierInput {
    width: 250px;
  }

  .link {
    color: var(--blue);
  }
`;
