import React, {
  Dispatch,
  Ref,
  RefObject,
  SetStateAction,
  useImperativeHandle,
  useRef,
} from 'react';
import { gql, useMutation } from '@apollo/client';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import {
  ISatItemsReducer,
  ISatItemsReducerAction,
  SatItemsReducerActionKind,
} from '../interfaces';
import {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../../components/MutationResponseSummary';
import ProductDataViewSelect, {
  IProductDataViewSelectRef,
} from '../../../../../../components/ProductDataViewSelect';

export interface IAddSatItemRef {
  handleAddItems(): void;
}

interface AddSatItemProps {
  idSat: number;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  satItemsRefetch(): void;
  satItemsDispatch: Dispatch<ISatItemsReducerAction>;
  setShouldRefetchSatSuppliers: Dispatch<SetStateAction<boolean>>;
  ref: Ref<IAddSatItemRef>;
  addItemsSummaryRef: RefObject<IMutationResponseSummaryRef>;
  setAddSatItemMutationResponse: Dispatch<
    React.SetStateAction<IMutationResponse[]>
  >;
  satItemsState: ISatItemsReducer;
  satRefetch(): Promise<void>;
  cleanSat: () => void;
  updateSatVersionLock(): void;
}

const AddSatItem: React.FC<AddSatItemProps> = React.forwardRef(
  (
    {
      idSat,
      setPageLoading,
      satItemsRefetch,
      satItemsDispatch,
      setShouldRefetchSatSuppliers,
      addItemsSummaryRef,
      setAddSatItemMutationResponse,
      satItemsState,
      satRefetch,
      cleanSat,
      updateSatVersionLock,
    },
    ref,
  ) => {
    // Mutation de atualizacao de dados de sat item
    const createSatItemQuery = gql`
      mutation CreateSatItems($idSat: Int!, $data: [CreateSatItemInput]!) {
        createSatItems(idSat: $idSat, data: $data) {
          createdSatItems {
            idSatItem
          }
          warnings
        }
      }
    `;

    const [createSatItemMutation] = useMutation(createSatItemQuery);

    const productDataViewRef = useRef<IProductDataViewSelectRef>(null);

    // Referencia ao toast
    const { toastRef } = useRefHook();

    useImperativeHandle(ref, () => ({
      handleAddItems: async () => {
        const mutationResponse: IMutationResponse[] = [];
        const selectedProducts = productDataViewRef.current?.selectedProducts;

        // Valida se existem itens adicionados
        if (!selectedProducts?.length) {
          toastRef.current?.show({
            severity: 'warn',
            summary: 'You need to add at least one item',
            life: ToastLife.WARN,
          });

          return;
        }

        setPageLoading(true);

        try {
          // Converte itens selecionados para formato esperado no backend
          const itemsParsed = selectedProducts?.map(item => {
            return { idSupplier: item.idSupplier, idProduct: item.idProduct };
          });

          // Cria itens
          const createSatItemsResponse = await createSatItemMutation({
            variables: {
              idSat,
              data: itemsParsed,
            },
          });

          if (createSatItemsResponse.data?.createSatItems?.warnings?.length) {
            mutationResponse.push({
              moduleChanged: 'SAT Items',
              warnings: createSatItemsResponse.data.createSatItems.warnings,
            });
          }

          // Fecha a modal e atualiza estado de itens
          satItemsDispatch({
            type: SatItemsReducerActionKind.CHANGE_ADD_SAT_ITEM,
          });

          if (satItemsState.satItems.length === 0) {
            cleanSat();
            satRefetch();
          } else {
            // Ao adicionar um item, atualiza o version lock para nao precisar recarregar toda a sat,
            // pois quando adiciona o item, no banco atualiza e na tela nao, entao evita
            // erros caso o usuario queira atualizar a Sat logo depois
            updateSatVersionLock();
          }

          satItemsRefetch();

          setShouldRefetchSatSuppliers(true);

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Items created',
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while adding SAT items',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
          if (mutationResponse.length) {
            setAddSatItemMutationResponse(mutationResponse);
            addItemsSummaryRef.current?.toggleDisplaySummary();
          }
        }
      },
    }));

    return <ProductDataViewSelect ref={productDataViewRef} idSat={idSat} />;
  },
);

export default AddSatItem;
