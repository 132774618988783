import { DocumentNode, gql } from '@apollo/client';

export const deletePrcAttachmentsQuery = gql`
  mutation DeletePrcAttachments($idsPrcAttachments: [Int]!) {
    deletePrcAttachments(idsPrcAttachments: $idsPrcAttachments)
  }
`;

// Mutation de criacao de prc
export const createPrcQuery = gql`
  mutation CreatePrcMutation($data: CreatePrcInput!) {
    createPrc(data: $data) {
      idPrc
    }
  }
`;

// Mutation de atualizacao de prc
export const updatePrcQuery = gql`
  mutation UpdatePrcMutation($data: UpdatePrcInput!) {
    updatePrc(data: $data) {
      idPrc
    }
  }
`;

// Mutation de acao elaborar prc
export const prcElaborarActionQuery = gql`
  mutation PrcElaborarAction($idPrc: Int!) {
    prcElaborarAction(idPrc: $idPrc)
  }
`;

// Mutation de acao pool procurement
export const prcPoolProcurementActionQuery = gql`
  mutation PrcPoolProcurementAction($idPrc: Int!) {
    prcPoolProcurementAction(idPrc: $idPrc)
  }
`;

// Mutation de acao add quotation
export const prcAddQuotationActionQuery = gql`
  mutation PrcAddQuotationAction($data: AddQuotationActionInput!) {
    prcAddQuotationAction(data: $data)
  }
`;

// Mutation de acao pool purchase
export const prcPoolPurchaseActionQuery = gql`
  mutation PrcPoolPurchaseAction($idPrc: Int!) {
    prcPoolPurchaseAction(idPrc: $idPrc)
  }
`;

// Mutation de acao purchase add quotation
export const prcPurchaseAddQuotationActionQuery = gql`
  mutation PrcPurchaseAddQuotationAction(
    $data: PurchaseAddQuotationActionInput!
  ) {
    prcPurchaseAddQuotationAction(data: $data)
  }
`;

// Mutation de acao pool manager
export const prcPoolManagerActionQuery = gql`
  mutation PrcPoolManagerAction($idPrc: Int!) {
    prcPoolManagerAction(idPrc: $idPrc)
  }
`;

// Mutation de acao analisar
export const prcAnalisarActionQuery = gql`
  mutation PrcAnalisarAction($data: AnalisarActionInput!) {
    prcAnalisarAction(data: $data)
  }
`;

// Mutation de acao pool commercial
export const prcPoolCommercialActionQuery = gql`
  mutation PrcPoolCommercialAction($idPrc: Int!) {
    prcPoolCommercialAction(idPrc: $idPrc)
  }
`;

// Mutation de acao aprovar prc
export const prcAprovarActionQuery = gql`
  mutation PrcAprovarAction($data: PrcAprovarActionInput!) {
    prcAprovarAction(data: $data)
  }
`;

// Mutation para upload de arquivos
export const uploadPrcFileQuery = gql`
  mutation CreatePrcFileLogs($data: CreatePrcFileLogsInput!) {
    createPrcFileLogs(data: $data) {
      idPrcFileLog
    }
  }
`;

// Query para baixar arquivo de PRC
export const downloadPrcFileLogQuery = gql`
  query DownloadPrcFileLog($idPrcFileLog: Int!) {
    downloadPrcFileLog(idPrcFileLog: $idPrcFileLog)
  }
`;

// Query para baixar arquivo template de PRC
export const downloadPrcFileTemplateQuery = gql`
  query Query($idPrc: Int!, $fileType: Int!) {
    downloadPrcFileTemplate(idPrc: $idPrc, fileType: $fileType)
  }
`;

// Mutation para reativar prc
export const reactivatePrcQuery = gql`
  mutation ReactivatePrc($idPrc: Int!) {
    reactivatePrc(idPrc: $idPrc)
  }
`;

// Query para listar PRC
export const listPrcQuery = (
  userHasSpecificProfiles: boolean,
): DocumentNode => gql`
query ListPrcById($idPrc: Int!) {
  listPrcById(idPrc: $idPrc) {
    idPrc
    idPrcType
    idPrcType2 {
      idDomain
      description
    }
    idStatus
    idStatus2 {
      idDomain
      description
    }
    idPriority2 {
      idDomain
      description
    }
    idCategory
    idClient2 {
      idClient
      name
    }
    prcOffices {
      idOffice2 {
        idOffice
        officeName
      }
    }
    prcCode
    prcLinked
    mainProduct
    notes
    dtDeadline
    indLicensed
    idPartnerLicensor2 {
      idPartner
      name
    }
    createdBy
    createdBy2 {
      firstName
      lastName
      roleUsers {
        idRole2 {
          name
        }
      }
    }
    approvedBy2 {
      firstName
      lastName
    }
    createdAt
    updatedAt
    updatedBy2 {
      firstName
      lastName
    }
    idResponsibleUser
    versionLock
    indConfection
    linkedPrcsArray {
      idPrc
      prcCode
    }
    prcComments {
      idPrcComment
      idPrc
      idComment
      idComment2 {
        idStatus
        idStatus2 {
          description
          idDomain
        }
        createdBy2 {
          firstName
          lastName
          avatarUrl
        }
        createdAt
      }
    }
    prcFileLogs {
      idPrcFileLog
      idType
      createdAt
      idPrcQuotation
      idAttachment
      createdBy2 {
        firstName
        lastName
      }
      idAttachment2 {
        name
      }
    }
    prcQuotations {
      idPrcQuotation
      validDate
      ${
        userHasSpecificProfiles
          ? `
        bestPrice
        comment
        createdBy2 {
          firstName
          lastName
        }
        idAttachment2 {
          name
        }
      `
          : ''
      }
      idSupplier2 {
        idSupplier
        sunNumber
        name
      }

    }
  }
}
`;

// Query para listar PRC
export const listPrcDeadlinesQuery = gql`
  query ListPrcDeadlines {
    listPrcDeadlines {
      idPrcDeadLine
      idPrcType
      idPriority
      numDay
      numDayFashion
    }
  }
`;
