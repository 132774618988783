import { gql } from '@apollo/client';

export const createPortTaxesBulkQuery = gql`
  mutation createLogisticPortTaxesBulk($uploadedFile: String!) {
    createLogisticPortTaxesBulk(uploadedFile: $uploadedFile) {
      portTaxesTotal
      createdPortTaxes
      portTaxesWithError
    }
  }
`;

export const updatePortTaxesBulkQuery = gql`
  mutation updateLogisticPortTaxesBulk($uploadedFile: String!) {
    updateLogisticPortTaxesBulk(uploadedFile: $uploadedFile) {
      portTaxesTotal
      updatedPortTaxes
      portTaxesWithError
    }
  }
`;
