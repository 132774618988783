import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import React, { useRef } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import Button from '../../../../../components/Button';
import FormRow from '../../../../../components/FormRow';
import FormTitle from '../../../../../components/FormTitle';
import TransparentButton from '../../../../../components/TransparentButton';
import { useAuth } from '../../../../../hooks/useAuth';
import { PrcFileType, PrcStatus } from '../../../../../shared/enums/domains';
import { IPrc } from '../../interfaces';
import FilesUpload, { IFilesUploadRef } from '../../PrcUploadFiles';
import PrcFileLog from '../../PrcFileLog';
import { IPrcFileLogRef } from '../../PrcFileLog/interfaces';

/**
 * Props de PRC Items
 */
interface IPrcItemsProps {
  /**
   * Estado de PRC
   */
  prc: IPrc;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFile: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFileTemplate: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Indicador de loading para download de file template
   */
  downloadFileTemplateLoading: boolean;
}

const PrcItems: React.FC<IPrcItemsProps> = ({
  prc,
  handleDownloadFile,
  handleDownloadFileTemplate,
  downloadFileTemplateLoading,
}) => {
  // Array de PRC Items
  const prcItems = prc.prcFileLogs?.filter(
    item => item.idType === PrcFileType.PRC_ITEMS,
  );

  // ID do usuario logado
  const { idUser } = useAuth();

  const filesUploadRef = useRef<IFilesUploadRef>(null);
  const prcFileLogRef = useRef<IPrcFileLogRef>(null);

  return (
    <>
      <FormTitle name="PRC Items" />

      <FormRow>
        {prcItems && prcItems?.length > 0 && (
          <TransparentButton
            type="button"
            onClick={() =>
              handleDownloadFile({
                variables: {
                  idPrcFileLog: prcItems[prcItems?.length - 1].idPrcFileLog,
                },
              })
            }
          >
            <FiDownload size={18} className="svg-left" />
            PRC ITEMS
          </TransparentButton>
        )}

        {prc.idResponsibleUser === idUser &&
          (prc.idStatus === PrcStatus.DRAFT ||
            prc.idStatus === PrcStatus.AT_PURCHASE ||
            prc.idStatus === PrcStatus.AT_MANAGEMENT ||
            prc.idStatus === PrcStatus.AT_COMMERCIAL) && (
            <>
              <Button
                type="button"
                label="Upload"
                icon="pi pi-upload"
                onClick={() => filesUploadRef.current?.toggleModal()}
              />

              <Button
                type="button"
                onClick={() =>
                  handleDownloadFileTemplate({
                    variables: {
                      idPrc: prc.idPrc,
                      fileType: PrcFileType.PRC_ITEMS,
                    },
                  })
                }
                label="Download Template"
                icon={<FiDownload size={17} />}
                loading={downloadFileTemplateLoading}
              />
            </>
          )}

        <FilesUpload
          idPrc={prc.idPrc}
          idType={PrcFileType.PRC_ITEMS}
          ref={filesUploadRef}
        />

        <button
          type="button"
          className="items-log"
          onClick={() => prcFileLogRef.current?.toggleDialog(prcItems ?? [])}
        >
          <AiOutlineUnorderedList size={24} />
        </button>
      </FormRow>

      <PrcFileLog
        ref={prcFileLogRef}
        header="PRC Items - Log"
        position="bottom-left"
      />
    </>
  );
};

export default PrcItems;
