/* eslint-disable no-shadow */
/**
 * Enum com ID de Roles
 */
export enum Role {
  ANONYMOUS = 1,
  USER = 2,
  ADMINISTRATOR = 3,
  MANAGER = 4,
  PROCUREMENT = 5,
  FINANCIAL = 6,
  PURCHASE = 7,
  COMMERCIAL = 8,
  SUPPLIER = 9,
  COMEX_IMPORT = 10,
  COMEX_EXPORT = 11,
  PRODUCT_REGISTER = 12,
  DESIGN = 13,
  QUALITY = 14,
  PROCUREMENT_KEY_USER = 15,
  FINANCIAL_KEY_USER = 16,
  PURCHASE_KEY_USER = 17,
  COMMERCIAL_KEY_USER = 18,
  COMEX_IMPORT_KEY_USER = 19,
  COMEX_EXPORT_KEY_USER = 20,
  DESIGN_KEY_USER = 21,
  QUALITY_KEY_USER = 22,
  PRODUCT_REGISTER_1 = 23,
  PRODUCT_REGISTER_2 = 24,
  PRODUCT_REGISTER_3 = 25,
  TESTE = 31,
  TEST_EDITING = 32,
  SUPPLIERS = 33,
  QA = 35,
  NEW_ROLE_LOCK = 36,
  INFRA_ADMIN = 37,
  INFRA2 = 38,
  TECLOGICA = 42,
  LICENSING = 43,
}
