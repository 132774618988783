import { isValid } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TreeSelect } from 'primereact/treeselect';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { AsyncPaginate } from 'react-select-async-paginate';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { booleanOptions } from '../../shared/options/boolean-options';
import { findParentsOnTree } from '../../utils/findParentsOnTree';
import Button from '../Button';
import MainButton from '../MainButton';
import {
  AdvancedFiltersFieldType,
  AdvancedFiltersPanelProps,
  AdvancedFiltersPanelReducerActionKind,
  IAdvancedFiltersField,
} from './interfaces';
import { advancedFiltersPanelReducer } from './reducers';

import {
  parseDateToUTCEndOfDay,
  parseDateToUTCStartOfDay,
} from '../../utils/dateUtil';
import isEmptyNullOrUndefined from '../../utils/isEmptyNullOrUndefined';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';
import TreeSelectCategory from '../TreeSelectCategory';
import { Container, Content, Header } from './styles';

const AdvancedFiltersPanel: React.FC<AdvancedFiltersPanelProps> = forwardRef(
  (
    {
      innerRef,
      fields,
      appliedFiltersOnly,
      advancedFiltersName,
      onApply,
      onClear,
      ...rest
    },
    ref,
  ) => {
    const [localStorageAdvancedFilters] = useState(
      localStorage.getItem(advancedFiltersName || ''),
    );

    const initialFields = useMemo(() => {
      if (advancedFiltersName && localStorageAdvancedFilters) {
        const advancedFiltersObject = JSON.parse(localStorageAdvancedFilters);
        // eslint-disable-next-line no-restricted-syntax
        for (const filter of advancedFiltersObject) {
          const field = fields.find(f => f.field === filter.field);
          if (
            field &&
            Array.isArray(filter.value) &&
            filter.value.every((date: string | number | Date) =>
              isValid(new Date(date)),
            )
          ) {
            field.value = filter.value.map(
              (date: string | number | Date) => new Date(date),
            );
          } else if (field) {
            field.value = filter.value;
          }
        }
      }
      return fields;
    }, [advancedFiltersName, fields, localStorageAdvancedFilters]);

    const [advancedFiltersPanelState, advancedFiltersPanelDispatch] =
      useReducer(advancedFiltersPanelReducer, {
        fields: initialFields,
        filteredFields: initialFields,
        fieldsSearch: '',
      });

    const handleValidateDateValues = (dates: Date[]) => {
      // Coloca a data incial no comeco do dia e a final no fim
      // Se a pessoa escolher so uma data considera como o dia todo
      const startDate = parseDateToUTCStartOfDay(dates[0]);
      const finishDate = parseDateToUTCEndOfDay(dates[1] ?? dates[0]);

      return [startDate, finishDate];
    };

    const getFieldsValues = useCallback(() => {
      const object = Object.assign(
        {},
        ...advancedFiltersPanelState.fields.map(item => {
          let value;

          if (item.type === AdvancedFiltersFieldType.NUMBER) {
            value = item.value === undefined ? item.value : Number(item.value);
          } else if (
            item.type === AdvancedFiltersFieldType.ASYNC_SELECT &&
            item.value &&
            item.asyncSelectOptions?.isMulti
          ) {
            value = item.value.map(
              (inputValue: any) =>
                inputValue[item.asyncSelectOptions?.optionValue],
            );
          } else if (
            item.type === AdvancedFiltersFieldType.ASYNC_SELECT &&
            item.value &&
            !item.asyncSelectOptions?.isMulti
          ) {
            value = item.value[item.asyncSelectOptions?.optionValue];
          } else if (
            item.type === AdvancedFiltersFieldType.DATE_RANGE &&
            item.value
          ) {
            value = handleValidateDateValues(item.value);
          } else if (
            item.type === AdvancedFiltersFieldType.CATEGORY_TREE_SELECT
          ) {
            value = item.value?.label;
          } else {
            value = item.value;
          }

          return { [item.advancedFilterField]: value };
        }),
      );

      return object;
    }, [advancedFiltersPanelState.fields]);

    const saveFilters = useCallback(() => {
      if (advancedFiltersName) {
        const filters = advancedFiltersPanelState.fields
          .filter(field => field.value)
          .map(field => {
            return {
              field: field.field,
              value: field.value,
            };
          });
        localStorage.setItem(advancedFiltersName, JSON.stringify(filters));
        updateLocalStorageInDb(advancedFiltersName, filters);
      }
    }, [advancedFiltersName, advancedFiltersPanelState.fields]);

    const clearFilters = useCallback(() => {
      if (advancedFiltersName) {
        localStorage.setItem(advancedFiltersName, JSON.stringify([]));
        updateLocalStorageInDb(advancedFiltersName, []);
      }
    }, [advancedFiltersName]);

    const handleApplyFilters = useCallback(() => {
      if (onApply) {
        const object = getFieldsValues();

        innerRef?.current?.hide();

        onApply(object);
        saveFilters();
      }
    }, [getFieldsValues, innerRef, onApply, saveFilters]);

    const handleClearAdvancedFilters = useCallback(() => {
      innerRef?.current?.hide();

      advancedFiltersPanelDispatch({
        type: AdvancedFiltersPanelReducerActionKind.CLEAR_FIELDS,
        payload: {
          fields,
        },
      });

      if (onClear) onClear();
      clearFilters();
    }, [fields, innerRef, onClear, clearFilters]);

    const renderInput = useCallback((filter: IAdvancedFiltersField) => {
      return (
        <InputText
          type={filter.type?.toString() ?? 'search'}
          name={filter.advancedFilterField}
          id={filter.advancedFilterField}
          placeholder={`Search by ${filter.header}`}
          value={filter.value}
          onChange={e =>
            advancedFiltersPanelDispatch({
              type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
              payload: {
                field: { ...filter, value: e.target.value },
              },
            })
          }
        />
      );
    }, []);

    const renderBooleanDropdown = useCallback(
      (filter: IAdvancedFiltersField) => {
        return (
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={filter.value}
            options={booleanOptions}
            onChange={e =>
              advancedFiltersPanelDispatch({
                type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
                payload: {
                  field: { ...filter, value: e.target.value },
                },
              })
            }
            placeholder={`Search by ${filter.header}`}
            showClear={
              filter.type === AdvancedFiltersFieldType.BOOLEAN_CLEARABLE
            }
          />
        );
      },
      [],
    );

    const renderDropdownWithOptions = useCallback(
      (filter: IAdvancedFiltersField) => {
        return (
          <Dropdown
            optionLabel="label"
            optionValue="value"
            value={filter.value}
            options={filter.options}
            onChange={e =>
              advancedFiltersPanelDispatch({
                type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
                payload: {
                  field: { ...filter, value: e.target.value },
                },
              })
            }
            placeholder={`Search by ${filter.header}`}
            showClear
          />
        );
      },
      [],
    );

    const renderAsyncSelectDropdown = useCallback(
      (filter: IAdvancedFiltersField) => {
        return (
          <AsyncPaginate
            loadOptionsOnMenuOpen
            value={filter.value}
            className="react-select"
            loadOptions={filter?.asyncSelectOptions?.optionData}
            getOptionLabel={(option: any) =>
              option[filter.asyncSelectOptions?.optionLabel]
            }
            getOptionValue={(option: any) =>
              option[filter.asyncSelectOptions?.optionValue]
            }
            onFocus={e => {
              const inputPageHeight = e.target.getBoundingClientRect().top + 60;
              const pageHeightTop = window.innerHeight;
              e.target.scrollIntoView({
                behavior: 'smooth',
                block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
                inline: 'nearest',
              });
            }}
            debounceTimeout={1000}
            isMulti={filter.asyncSelectOptions?.isMulti ?? false}
            onChange={e => {
              if (e) {
                advancedFiltersPanelDispatch({
                  type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
                  payload: {
                    field: { ...filter, value: e },
                  },
                });
              }
            }}
            placeholder={`Search by ${filter.header}`}
            noOptionsMessage={() => 'No options found'}
            additional={{
              page: 1,
              id: filter.asyncSelectOptions?.domainGroup,
              ...filter.asyncSelectOptions?.additional,
            }}
            options={filter.options}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            menuPlacement="auto"
          />
        );
      },
      [],
    );

    const renderTreeSelect = useCallback((filter: IAdvancedFiltersField) => {
      return (
        <TreeSelect
          options={filter.options}
          placeholder="Select...."
          filter
          onNodeSelect={(e: any) => {
            advancedFiltersPanelDispatch({
              type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
              payload: {
                field: { ...filter, value: e.node.key },
              },
            });
          }}
          valueTemplate={
            <span>
              {filter.value
                ? findParentsOnTree(filter.options, filter.value)
                : 'Select...'}
            </span>
          }
        />
      );
    }, []);

    const renderCategoryTreeSelect = useCallback(
      (filter: IAdvancedFiltersField) => {
        return (
          <TreeSelectCategory
            filter
            onNodeSelect={({ node }) => {
              advancedFiltersPanelDispatch({
                type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
                payload: {
                  field: {
                    ...filter,
                    value: { label: node.label, idCategory: node.key },
                  },
                },
              });
            }}
            value={filter.value?.idCategory}
            valueTemplate={filter.value?.label}
          />
        );
      },
      [],
    );

    const renderDateRange = useCallback((filter: IAdvancedFiltersField) => {
      return (
        <Calendar
          value={filter.value}
          selectionMode="range"
          dateFormat="dd/mm/yy"
          onChange={e => {
            advancedFiltersPanelDispatch({
              type: AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE,
              payload: {
                field: { ...filter, value: e.target.value },
              },
            });
          }}
          placeholder={`Search by ${filter.header}`}
        />
      );
    }, []);

    const renderSwitch = useCallback(
      (filter: IAdvancedFiltersField) => {
        switch (filter.type) {
          case AdvancedFiltersFieldType.BOOLEAN:
          case AdvancedFiltersFieldType.BOOLEAN_CLEARABLE:
            return renderBooleanDropdown(filter);
          case AdvancedFiltersFieldType.DROPDOWN:
            return renderDropdownWithOptions(filter);
          case AdvancedFiltersFieldType.ASYNC_SELECT:
            return renderAsyncSelectDropdown(filter);
          case AdvancedFiltersFieldType.DATE_RANGE:
            return renderDateRange(filter);
          case AdvancedFiltersFieldType.TREE_SELECT:
            return renderTreeSelect(filter);
          case AdvancedFiltersFieldType.CATEGORY_TREE_SELECT:
            return renderCategoryTreeSelect(filter);
          default:
            return renderInput(filter);
        }
      },
      [
        renderBooleanDropdown,
        renderDropdownWithOptions,
        renderAsyncSelectDropdown,
        renderDateRange,
        renderTreeSelect,
        renderCategoryTreeSelect,
        renderInput,
      ],
    );

    function showFilter(filter: IAdvancedFiltersField) {
      if (appliedFiltersOnly) {
        return (
          (!Array.isArray(filter.value) &&
            !isEmptyNullOrUndefined(filter.value)) ||
          (Array.isArray(filter.value) && filter.value.length > 0)
        );
      }
      return filter.advancedFilterField && filter.header;
    }

    useImperativeHandle(ref, () => ({
      getFieldsValues: () => {
        return getFieldsValues();
      },
    }));

    return (
      <Container className="s-advanced-filters-panel">
        <OverlayPanel
          {...rest}
          ref={innerRef}
          onShow={() =>
            advancedFiltersPanelDispatch({
              type: AdvancedFiltersPanelReducerActionKind.SAVE_PANEL_STATE,
            })
          }
        >
          <Header>
            <h1>{appliedFiltersOnly ? 'Applied ' : ''}Advanced Filters</h1>
            <button type="button" onClick={() => innerRef?.current?.hide()}>
              <FiX size={18} />
            </button>
          </Header>
          <Content>
            {/* Busca nos filtros */}
            {!appliedFiltersOnly && (
              <IconField iconPosition="left" className="mb-3">
                <InputIcon className="pi pi-search" />
                <InputText
                  className="w-full"
                  type="search"
                  placeholder="Search available filters"
                  value={advancedFiltersPanelState.fieldsSearch}
                  onChange={e =>
                    advancedFiltersPanelDispatch({
                      type: AdvancedFiltersPanelReducerActionKind.FILTER_FIELDS,
                      payload: { filter: e.target.value },
                    })
                  }
                  variant="filled"
                />
              </IconField>
            )}

            <div className="inputs">
              {advancedFiltersPanelState.filteredFields.map(filter => {
                return showFilter(filter) ? (
                  <div key={filter.advancedFilterField} className="form-field">
                    <label htmlFor={filter.advancedFilterField}>
                      {filter.header}
                    </label>
                    {renderSwitch(filter)}
                  </div>
                ) : undefined;
              })}
            </div>

            <div className="form-footer">
              {/* Botão submit */}
              <span>
                <MainButton
                  type="submit"
                  label="Apply Filters"
                  onClick={() => handleApplyFilters()}
                />
                <Button
                  type="button"
                  label="Cancel"
                  onClick={() => innerRef?.current?.hide()}
                />
              </span>
              <Button
                type="button"
                severity="danger"
                className="p-button-danger"
                label="Clear Filters"
                onClick={handleClearAdvancedFilters}
              />
            </div>
          </Content>
        </OverlayPanel>
      </Container>
    );
  },
);

export default AdvancedFiltersPanel;
