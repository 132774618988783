import { onError } from '@apollo/client/link/error';
import localStorageKeys from '../../config/localStorageKeys';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let index = 0; index < graphQLErrors.length; index += 1) {
      const err = graphQLErrors[index];
      if (
        err.message === 'Invalid token' ||
        err.extensions?.code === 'UNAUTHENTICATED'
      ) {
        localStorage.clear();
        window.location.href = '/login?redirect=error';
      }
      if (err.message === 'You need to reset your password.') {
        localStorage.removeItem(localStorageKeys.user);
        window.location.href = '/reset-password/requested';
      }
    }
  }
});

export default errorLink;
