import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 12px;

  div {
    display: flex;
    margin-top: 10px;
    h3 {
      margin-bottom: 6px;
    }
    b {
      font-weight: 500;
    }

    span + span {
      margin-left: 12px;
      border-left: 1px solid #eee;
      padding-left: 12px;
    }
  }
`;
