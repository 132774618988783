export const qualityRoles = {
  idEntity: 48,
  idModule: 12,
  fields: {
    statusRNC: 1541,
    rncNumber: 1542,
    satNumber: 1543,
    shipment: 1544,
    mainProduct: 1545,
    sunNumber: 1546,
    importerConsignee: 1547,
    clientNotify: 1548,
    contactAtClient: 1549,
    priority: 1550,
    deadline: 1551,
    waitingFor: 1552,
    problemType: 1553,
    problemDescription: 1554,
    proposedSolution: 1555,
    rncResponsible: 1556,
    coResponsible: 1557,
    cost: 1558,
    costResponsible: 1559,
    reWork: 1560,
    totalCostFinal: 1561,
    refund: 1562,
    itemsTotalUsd: 1563,
    itemsTotalBrl: 1564,
    totalCostCurrencySat: 1565,
    totalCostBrl: 1566,
    exchangeRate: 1567,
    finalCreditNote: 1568,
    rncCreditNote: 1569,
    finalDebitNote: 1570,
    rncDebitNote: 1571,
    clientDebitNoteNumber: 1572,
    clientDebitNoteStatus: 1573,
    clientNf: 1574,
    realEtd: 1575,
    countryOrigin: 1576,
    importStatus: 1577,
    shipmentStatus: 2215,
    daysOpen: 1578,
    openingDate: 1579,
    finishingDate: 1580,
    openedBy: 1581,
    category: 1582,
    updatesRnc: 1583,
    shipmentResume: 2145,
  },
  permissions: {
    idPermissionExportSatsQuality: 180,
  },
};
