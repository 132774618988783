import { FinancialPurchaseOtherExpenses } from '../../../../../../../shared/enums/domains';
import {
  ManageOtherExpenseReducer,
  ManageOtherExpenseReducerAction,
  ManageOtherExpenseReducerActionKind,
} from './interfaces';

export const manageOtherExpenseInitialState: ManageOtherExpenseReducer = {
  displayDialog: false,
  checkboxesAreDisabled: false,
};

export const manageOtherExpenseReducer = (
  state: ManageOtherExpenseReducer,
  action: ManageOtherExpenseReducerAction,
): ManageOtherExpenseReducer => {
  switch (action.type) {
    case ManageOtherExpenseReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        otherExpenseToEdit: undefined,
        checkboxesAreDisabled: false,
      };

    case ManageOtherExpenseReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.otherExpense) {
        const { idType } = action.payload?.otherExpense;

        const checkboxesAreDisabled =
          idType !== FinancialPurchaseOtherExpenses.SAMPLES &&
          idType !== FinancialPurchaseOtherExpenses.OTHERS;

        return {
          ...state,
          otherExpenseToEdit: action.payload?.otherExpense,
          displayDialog: true,
          checkboxesAreDisabled,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    case ManageOtherExpenseReducerActionKind.SET_CHECKBOXES_ARE_DISABLED:
      return {
        ...state,
        checkboxesAreDisabled: !!action.payload?.checkboxesAreDisabled,
      };
    default:
      throw new Error();
  }
};
