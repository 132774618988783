import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../../../../../config/pagination';
import client from '../../../../../../services/apollo/client';

export const asyncSelectLoadSatForeignTradesAvailable: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; idSatForeignTradeToExclude?: number }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query ListAvailableSatForeignTradesToAddToForeignTradeGroup(
        $data: ListAvailableSatForeignTradesToAddToForeignTradeGroupInput!
      ) {
        listAvailableSatForeignTradesToAddToForeignTradeGroup(data: $data) {
          items
          data {
            idSatForeignTrade
            satForeignTradeNumber
            realEtd
            realEta
            realArrivalAtClient
            idLoadingPort
            idLoadingPort2 {
              name
            }
            idPort
            idPort2 {
              name
            }
            bookingConfirmed
            shipmentConfirmed
            freightForwarderOrigin
            freightForwarderOrigin2 {
              name
            }
            freightForwarderDestination
            freightForwarderDestination2 {
              name
            }
          }
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        satForeignTradeNumber: search,
        idSatForeignTradeToExclude: pageData?.idSatForeignTradeToExclude,
      },
    },
  });

  const filteredOptions =
    res.data.listAvailableSatForeignTradesToAddToForeignTradeGroup.data;
  const quantity =
    res.data.listAvailableSatForeignTradesToAddToForeignTradeGroup.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idSatForeignTradeToExclude: pageData?.idSatForeignTradeToExclude,
    },
  };
};

/**
 * Query para adicionar SAT Foreign Trade a um grupo
 */
export const addSatForeignTradeToGroupQuery = gql`
  mutation AddSatForeignTradeToSameGroupAsTheOther(
    $data: AddSatForeignTradeToSameGroupAsTheOtherInput!
  ) {
    addSatForeignTradeToSameGroupAsTheOther(data: $data)
  }
`;

/**
 * Query para listar SAT Foreign Trades Consolidadas
 */
export const listSatForeignTradesConsolidatedQuery = gql`
  query ListSatForeignTradesConsolidatedByIdSatForeignTradeGroup(
    $idSatForeignTradeGroup: Int!
  ) {
    listSatForeignTradesConsolidatedByIdSatForeignTradeGroup(
      idSatForeignTradeGroup: $idSatForeignTradeGroup
    ) {
      satForeignTradesData {
        cbmCi
        idSatForeignTrade
        qtyAndTypeOfCtnr
        qtyCi
        satForeignTradeNumber
      }
      totalCbmCi
      totalQtyCi
    }
  }
`;

/**
 * Query para remover SAT Foreign Trade de um grupo
 */
export const removeSatForeignTradeFromGroupQuery = gql`
  mutation RemoveSatForeignTradeFromSatForeignTradeGroup(
    $idSatForeignTrade: Int!
  ) {
    removeSatForeignTradeFromSatForeignTradeGroup(
      idSatForeignTrade: $idSatForeignTrade
    )
  }
`;
