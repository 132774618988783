import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 28px;
  max-width: 1154px;

  button {
    margin: 12px 0;
  }

  .grid {
    margin-bottom: 7px;
  }

  .col-6 {
    margin-right: 7px;
  }
`;
