import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialPurchaseOtherExpenseFieldsPermissions } from '../../interfaces';

export const listOtherExpensesQuery = (
  fieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions,
): DocumentNode => gql`
  query Data($data: ListSatForeignTradeSupplierOtherExpensesByIdSatInput!) {
    listSatForeignTradeSupplierOtherExpensesByIdSat(data: $data) {
      items
      data {
        idSatOtherExpense
        idType
        ${
          fieldsPermissions.idType.view
            ? `idType2 {
                description
              }
            `
            : ''
        }
        idCurrency2 {
          abbreviation
        }
        ${fieldsPermissions.total.view ? 'total' : ''}
        ${fieldsPermissions.dateOtherExpense.view ? 'dateOtherExpense' : ''}
        ${fieldsPermissions.linkToMargin.view ? 'linkToMargin' : ''}
        ${
          fieldsPermissions.discountFromSupplier.view
            ? 'discountFromSupplier'
            : ''
        }
        ${fieldsPermissions.payToSupplier.view ? 'payToSupplier' : ''}
        ${fieldsPermissions.comment.view ? 'comment' : ''}
        confirmationDate
        idSat
        idSatDestination
        number
        idSatForeignTrade2 {
          satForeignTradeNumber
        }
        idSatForeignTradeDestination2 {
          satForeignTradeNumber
        }
        idSatForeignTradeSupplier2 {
          idSupplier2 {
            sunNumber
          }
        }
        isDestination
      }
    }
  }
`;
