/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import {
  TreeSelectChangeEvent,
  TreeSelectEventNodeEvent,
} from 'primereact/treeselect';
import { useRefHook } from '../../hooks/useRefHook';
import ToastLife from '../../shared/enums/toastLife';
import { findParentsOnTree } from '../../utils/findParentsOnTree';
import FormTreeSelect, { FormTreeSelectProps } from '../FormTreeSelect';

interface ICategory {
  key?: string | number;
  label: string;
  data: {
    name: string;
    idCategory: number;
    idParentCategory?: number;
    idCheckList?: number;
    indConfection?: boolean;
  };
  children: ICategory[];
}

export interface ISelectCategory {
  idCategory?: string | number;
  idCheckList?: number;
  indConfection?: boolean;
}

// Interface das propriedades do componente
interface IFormTreeSelectCategoryProps extends FormTreeSelectProps {
  name: string;
  label: string;
  initialValue?: number;
}

const FormTreeSelectCategory: React.FC<IFormTreeSelectCategoryProps> = ({
  name,
  label,
  onNodeSelect,
  initialValue,
  ...rest
}) => {
  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Categories
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    initialValue,
  );

  // Query para listar categories
  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  /**
   * Busca Categories
   */
  useQuery(listCategoriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting categories',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        const categoryList: ICategory[] = JSON.parse(
          response.listAllCategories.data,
        );
        setCategories(categoryList);
      }
    },
  });

  /**
   * Atualiza categoria selecionada
   * @param e Evento de selecao de node
   */
  function selectCategoryNode(e: TreeSelectEventNodeEvent) {
    if (selectedCategory && e.node.data?.idCategory !== selectedCategory) {
      setSelectedCategory(e.node.data?.idCategory);
    }
  }

  function handleCategoryChange(e: TreeSelectChangeEvent) {
    if (typeof e.value === 'number') setSelectedCategory(e.value);
  }

  return (
    <FormTreeSelect
      {...rest}
      placeholder="Select a Category"
      name={name}
      label={label}
      options={categories}
      onNodeSelect={e => {
        selectCategoryNode(e);
        if (onNodeSelect) onNodeSelect(e);
      }}
      initialValue={selectedCategory}
      onChange={e => handleCategoryChange(e)}
      valueTemplate={
        <span>
          {selectedCategory
            ? findParentsOnTree(categories, selectedCategory)
            : 'Select...'}
        </span>
      }
    />
  );
};

export default FormTreeSelectCategory;
