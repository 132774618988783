import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

export const asyncSelectLoadPartners: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; core?: string }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllPartners($listAllPartnersInput: ListAllPartnersInput!) {
        listAllPartners(listAllPartnersInput: $listAllPartnersInput) {
          data {
            idPartner
            name
          }
          items
        }
      }
    `,
    variables: {
      listAllPartnersInput: {
        _page: pageData?.page,
        _limit: pagination.itemsPerPage,
        _orderBy: 'name',
        name: search,
        active: 1,
        core: pageData?.core,
      },
    },
  });

  const filteredOptions = res.data.listAllPartners.data;
  const quantity = res.data.listAllPartners.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      core: pageData?.core,
    },
  };
};
