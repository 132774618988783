import { gql } from '@apollo/client';

export const createProductPackageBarcodeQuery = gql`
  mutation CreateProductPackageBarcode(
    $data: CreateProductPackageBarcodeInput!
  ) {
    createProductPackageBarcode(data: $data) {
      idProductPackageBarcode
    }
  }
`;
