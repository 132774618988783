import styled from 'styled-components';
import { Button, ButtonProps } from 'primereact/button';
import { shade } from 'polished';

export const Container = styled(Button)<ButtonProps>`
  background: var(--blue);
  height: 36px;

  border-radius: 4px;
  border: 0;
  font-weight: 500;
  color: #fff;
  transition: background-color 0.2s;

  display: flex;
  align-items: center;
  place-content: center;

  &:hover {
    background: ${shade(0.2, '#4C85C8')};
  }

  svg {
    margin-right: 10px;
  }
`;
