/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Creatable from 'react-select/creatable';
import { OptionProps } from 'react-select';
import { useField } from '@unform/core';
import {
  AsyncPaginate,
  AsyncPaginateProps,
  withAsyncPaginate,
} from 'react-select-async-paginate';

import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';
import isNullOrUndefined from '../../utils/isNullOrUndefined';

interface FormAsyncSelectProps extends AsyncPaginateProps<any, any, any, any> {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  initialValue?: any;
  disabled?: boolean;
  onValueChange?(value: any): void;
  creatable?: boolean;
  visible?: boolean;
  readOnly?: boolean;
  additional?: any;
  id?: string;

  /**
   * Acao ao remover valor do input
   * @param removedValue Valor removido do input
   * @param value Novo valor do input
   */
  onValueRemove?(removedValue: any, value: any): void;

  /**
   * Acao ao incluir novo valor no input
   * @param addedValue Valor adicionado ao input
   * @param value Novo valor do input
   */
  onValueAdd?(addedValue: any, value: any): void;
}
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const FormAsyncSelect: React.FC<FormAsyncSelectProps> = ({
  name,
  label,
  required,
  className,
  initialValue,
  disabled,
  onValueChange,
  creatable,
  readOnly,
  additional,
  id,
  onValueRemove,
  onValueAdd,
  menuPlacement = 'auto',
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const [selectedData, setSelectedData] = useState<any>(initialValue);
  const { fieldName, registerField, error } = useField(name);
  const [isFilled, setIsFilled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.selectValue) {
            return [];
          }
          return ref.state.selectValue.map((option: OptionProps) =>
            ref.getOptionValue(option),
          );
        }
        if (!ref.state.selectValue.length) {
          return null;
        }
        return ref.getOptionValue(ref.state.selectValue[0]);
      },
      clearValue: (ref: any) => {
        ref.clearValue();
      },
      setValue: (_, value: any) => {
        setSelectedData(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  function changeSelectedData(e: any) {
    if (e) {
      setSelectedData(e);
    } else {
      setSelectedData(null);
    }

    if (onValueChange) {
      onValueChange(e);
    }

    if (onValueRemove) {
      if (rest.isMulti && selectedData) {
        const newValues = e as unknown[];
        const currentValues = selectedData as unknown[];
        const valueRemoved = currentValues.find(
          value => !newValues.includes(value),
        );

        if (valueRemoved !== undefined) onValueRemove(valueRemoved, e);
      } else if (e === null && !isNullOrUndefined(selectedData)) {
        onValueRemove(selectedData, e);
      }
    }

    if (onValueAdd) {
      if (rest.isMulti) {
        const newValues = e as unknown[];
        const currentValues = selectedData as unknown[];

        if (currentValues) {
          const valueAdded = newValues.find(
            value => !currentValues.includes(value),
          );

          if (valueAdded !== undefined) onValueAdd(valueAdded, e);
        } else {
          // Se nao houverem valores atualmente, significa que o proprio
          // item selecionado eh o novo valor do input
          onValueAdd(e[0], e);
        }
      } else if (isNullOrUndefined(selectedData) && e !== null) {
        onValueAdd(e, e);
      }
    }
  }

  const handleDropdownBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!selectRef.current?.props.value);
  }, []);

  const handleDropdownFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <div
      id={id}
      className={`${
        className
          ? `${className} form-element form-dropdown`
          : 'form-element form-dropdown'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>
        {creatable ? (
          <CreatableAsyncPaginate
            value={selectedData}
            selectRef={selectRef}
            className="react-select"
            onFocus={e => {
              const inputPageHeight = e.target.getBoundingClientRect().top + 60;
              const pageHeightTop = window.innerHeight;
              e.target.scrollIntoView({
                behavior: 'smooth',
                block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
                inline: 'nearest',
              });
              handleDropdownFocus();
            }}
            onBlur={handleDropdownBlur}
            isDisabled={disabled || readOnly}
            debounceTimeout={1000}
            {...rest}
            onChange={e => changeSelectedData(e)}
            additional={{
              page: 1,
              ...additional,
            }}
            menuPlacement={menuPlacement}
          />
        ) : (
          <AsyncPaginate
            loadOptionsOnMenuOpen
            value={selectedData}
            selectRef={selectRef}
            className="react-select"
            onFocus={e => {
              const inputPageHeight = e.target.getBoundingClientRect().top + 60;
              const pageHeightTop = window.innerHeight;
              e.target.scrollIntoView({
                behavior: 'smooth',
                block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
                inline: 'nearest',
              });
              handleDropdownFocus();
            }}
            onBlur={handleDropdownBlur}
            isDisabled={disabled || readOnly}
            debounceTimeout={1000}
            {...rest}
            onChange={e => changeSelectedData(e)}
            additional={{
              page: 1,
              ...additional,
            }}
            menuPlacement={menuPlacement}
          />
        )}
      </Container>
      {/* Se houver erro, exibe texto indicativo */}
      {error && (
        <small id="username2-help" className="p-error block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormAsyncSelect;
