import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;

  form {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 435px;

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    width: 263px;
    padding: 4px;

    label + .p-radiobutton {
      margin-left: 12px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
  }

  && {
    margin-top: 16px;
  }

  .generalInput,
  .form-dropdown {
    width: 100%;
  }

  .formInputNumber {
    width: 225px;
    height: 48px;
  }

  .currency-dropdown {
    width: 200px;
    margin-left: 10px;
  }
`;
