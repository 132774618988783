import { gql } from '@apollo/client';

export const listClientBrandsQuery = gql`
  query ListClientBrandsAndAttachments(
    $data: ListClientBrandsAndAttachmentsInput!
  ) {
    listClientBrandsAndAttachments(data: $data) {
      data {
        idClientBrand
        createdByUserFullName
        updatedByUserFullName
        createdAt
        updatedAt
        versionLock
        brand
        imageUrl
        notes
      }
      items
    }
  }
`;

export const deleteClientBrandsQuery = gql`
  mutation DeleteClientBrands($idsClientBrands: [Int]!) {
    deleteClientBrands(idsClientBrands: $idsClientBrands)
  }
`;
