export const supplierRoles = {
  idEntity: 27,
  idModule: 2,
  fields: {
    idFieldAmountCotTotal: 57,
    idFieldAmountTotal: 58,
    idFieldIdCurrency: 130,
    idFieldQtyTypeCtnr: 171,
    idFieldAddress: 521,
    idFieldAi: 522,
    idFieldColorCemik: 525,
    idFieldColorOther: 526,
    idFieldColorPantone: 527,
    idFieldColorRgd: 528,
    idFieldCompanyName: 529,
    idFieldCorel17: 530,
    idFieldCorel20: 531,
    idFieldEps: 534,
    idFieldExporter: 535,
    idFieldFactoryAudit: 536,
    idFieldIdCity: 537,
    idFieldIdCourier: 539,
    idFieldIdIncoterm: 542,
    idFieldIdIncotermSecond: 543,
    idFieldIdPayment: 544,
    idFieldIdRegion: 545,
    idFieldIndd: 547,
    idFieldJpeg: 548,
    idFieldOrganization: 550,
    idFieldOtherFormat: 551,
    idFieldPaymentAdvance: 552,
    idFieldPaymentBalance: 553,
    idFieldPdf: 554,
    idFieldProductionTime: 555,
    idFieldSamplesLeadTime: 849,
    idFieldPsd: 556,
    idFieldTax: 557,
    idFieldWebsite: 561,
    idFieldXls: 562,
    idFieldZipCode: 563,
    idFieldEmail: 566,
    idFieldIdDepartment: 567,
    idFieldIdRole: 568,
    idFieldTelephone: 572,
    idFieldManufacturer: 604,
    idFieldFactoryAddress: 605,
    idFieldFactoryName: 606,
    idFieldFactoryTax: 607,
    idFieldFactoryZipCode: 608,
    idFieldIdFactoryCity: 609,
    idFieldIdFactoryCountry: 610,
    idFieldIdFactoryRegion: 611,
    idFieldName: 612,
    idFieldIdCountry: 613,
    idFieldActive: 1410,
  },
  permissions: {
    idPermissionExportSupplierList: 6,
    idPermissionDeleteSupplier: 40,
  },
};
