import { gql } from '@apollo/client';

export const getToBalancePaymentRequestQuery = gql`
  query GetSatForeignTradeToBalancePaymentRequest($idSatForeignTrade: Int!) {
    getSatForeignTradeToBalancePaymentRequest(
      idSatForeignTrade: $idSatForeignTrade
    ) {
      mainProduct
      currency
      totalSunCi
      totalAdvance
      totalRemainSun
      paymentTerm
      clientOrder
      clientCnpj
      realEta
      portOfDischarge
    }
  }
`;

export const senBalancePaymentRequestQuery = gql`
  mutation SendBalancePaymentRequest($data: SendBalancePaymentRequestInput!) {
    sendBalancePaymentRequest(data: $data)
  }
`;
