import { gql } from '@apollo/client';

export const createPartnerAttachmentsQuery = gql`
  mutation CreatePartnerAttachments($data: CreatePartnerAttachmentsInput!) {
    createPartnerAttachments(data: $data) {
      idPartnerAttachment
      idAttachment
      imageUrl
      name
    }
  }
`;
