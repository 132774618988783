import styled from 'styled-components';

export const Container = styled.div``;

export const HistoryContent = styled.div`
  display: flex;
`;

export const HistoryColumn = styled.div``;

export const ColumnTitle = styled.div`
  padding: 8px;
  font-weight: bold;
  border-bottom: 2px solid #e6e6e6;
`;

export const ColumnContent = styled.div`
  padding: 8px;
  font-family: 'Roboto';
  color: #4c85c8;
  cursor: pointer;
  border-bottom: 2px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
`;
