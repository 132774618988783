import { DocumentNode, gql } from '@apollo/client';
import { SatListFollowUpPermissions } from './interfaces';

export const listSatListFollowUpViewQuery = (
  fieldsPermissions: SatListFollowUpPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListFollowUpView($data: ListSatListFollowUpViewInput!) {
      listSatListFollowUpView(data: $data) {
        items
        data {
          idSat
          idSatForeignTrade
          idImporter
          idClient
          ${fieldsString}
          satCurrency
        }
      }
    }
  `;
};
