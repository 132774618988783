import React, {
  Dispatch,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import {
  ForeignTradeSupplierContentReducerAction,
  ForeignTradeSupplierContentReducerActionKind,
  PurchaseOtherExpensesTotalReducerObject,
} from '../interfaces';
import { listOtherExpensesTotal } from './queries';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import FormTitle from '../../../../../../components/FormTitle';
import {
  SatFinancialPurchaseOtherExpensesTotalFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

export interface IPurchaseOtherExpensesTotalRef {
  refetch(): void;
}

interface IPurchaseOtherExpensesTotalProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  purchaseOtherExpensesTotal?: PurchaseOtherExpensesTotalReducerObject;
  foreignTradeSupplierContentDispatch: Dispatch<ForeignTradeSupplierContentReducerAction>;
  selected: boolean;
  ref: Ref<IPurchaseOtherExpensesTotalRef>;
  fieldsPermissions: SatFinancialPurchaseOtherExpensesTotalFieldsPermissions;
  userCanSeeBlock: boolean;
  satCurrency?: string;
  loadPurchaseTotalPaidData(): void;
}

const PurchaseOtherExpensesTotal: React.FC<IPurchaseOtherExpensesTotalProps> =
  React.forwardRef(
    (
      {
        purchaseOtherExpensesTotal,
        satForeignTradeSupplier,
        foreignTradeSupplierContentDispatch,
        selected,
        fieldsPermissions,
        userCanSeeBlock,
        satCurrency,
        loadPurchaseTotalPaidData,
      },
      ref,
    ) => {
      const { toastRef } = useRefHook();

      const satCurrencySymbol = getCurrencySymbol(satCurrency);

      const [
        loadData,
        { loading: otherExpensesTotalLoading, data: otherExpensesTotalData },
      ] = useLazyQuery(listOtherExpensesTotal, {
        variables: {
          idSatForeignTradeSupplier:
            satForeignTradeSupplier.idSatForeignTradeSupplier,
          typeIdDomainGroup: DomainGroup.FINANCIAL_PURCHASE_OTHER_EXPENSES,
        },
        onCompleted: response => {
          if (response.getSumDataFromSatForeignTradeSupplierOtherExpenses) {
            foreignTradeSupplierContentDispatch({
              type: ForeignTradeSupplierContentReducerActionKind.SET_PURCHASE_OTHER_EXPENSES_TOTAL,
              payload: {
                purchaseOtherExpensesTotal:
                  response.getSumDataFromSatForeignTradeSupplierOtherExpenses,
                satForeignTradeSupplier,
              },
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting Purchase Other Expenses Total',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });

          foreignTradeSupplierContentDispatch({
            type: ForeignTradeSupplierContentReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
          });
        },
      });

      const loadOtherExpensesTotalData = useCallback(async () => {
        foreignTradeSupplierContentDispatch({
          type: ForeignTradeSupplierContentReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
        });

        loadData();
      }, [foreignTradeSupplierContentDispatch, loadData]);

      useImperativeHandle(ref, () => ({
        refetch: () => {
          loadOtherExpensesTotalData();
          loadPurchaseTotalPaidData();
        },
      }));

      useEffect(() => {
        if (!otherExpensesTotalData && selected) {
          loadOtherExpensesTotalData();
        }
      }, [otherExpensesTotalData, selected, loadOtherExpensesTotalData]);

      return userCanSeeBlock ? (
        <div>
          <FormTitle name="Other Expenses Total" />
          <div className="grid">
            {fieldsPermissions.totalOtherExpenses.view && (
              <ReadOnlyInput
                className="col-3"
                label="Total Other Expenses"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={purchaseOtherExpensesTotal?.totalOtherExpenses}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalOnMargin.view && (
              <ReadOnlyInput
                className="col-3"
                label="Total On Margin"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={purchaseOtherExpensesTotal?.totalOnMargin}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalOutOfMargin.view && (
              <ReadOnlyInput
                className="col-3"
                label="Total Out Of Margin"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={purchaseOtherExpensesTotal?.totalOutOfMargin}
                loading={otherExpensesTotalLoading}
              />
            )}
          </div>

          <div className="grid">
            {fieldsPermissions.totalDiscountFromSupplier.view && (
              <ReadOnlyInput
                className="col-3"
                label="Total Discount From Supplier"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={purchaseOtherExpensesTotal?.totalDiscountFromSupplier}
                loading={otherExpensesTotalLoading}
              />
            )}

            {fieldsPermissions.totalPayToSupplier.view && (
              <ReadOnlyInput
                className="col-3"
                label="Total Pay To Supplier"
                type="number"
                locale="pt-BR"
                prefix={satCurrencySymbol}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={purchaseOtherExpensesTotal?.totalPayToSupplier}
                loading={otherExpensesTotalLoading}
              />
            )}
          </div>
        </div>
      ) : (
        <div />
      );
    },
  );

export default PurchaseOtherExpensesTotal;
