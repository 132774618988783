import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const confirmOrderOptions = [
  { label: 'ON NEGOTIATION', value: 'On Negotiation' },
  { label: 'CONFIRMED', value: 'Confirmed' },
  { label: 'CONFIRMED COMMERCIAL', value: 'Confirmed Commercial' },
];

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    value: undefined,
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  category: {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    value: undefined,
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    value: undefined,
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  designUser: {
    field: 'designUser',
    header: 'Design User',
    value: undefined,
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    value: undefined,
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    value: undefined,
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    value: undefined,
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  firstBuyer: {
    field: 'firstBuyer',
    header: 'First Buyer',
    value: undefined,
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    value: undefined,
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    value: undefined,
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    value: undefined,
    advancedFilterField: 'mainProduct',
  },
  purchaseIncoterm: {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    value: undefined,
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  sellingIncoterm: {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    value: undefined,
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    value: undefined,
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  satForeignTradeNumber: {
    field: 'satForeignTradeNumber',
    header: 'SAT Shipment',
    value: undefined,
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  secondBuyer: {
    field: 'secondBuyer',
    header: 'Second Buyer',
    value: undefined,
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    value: undefined,
    advancedFilterField: 'idSecondSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'productionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  documentsStatus: {
    field: 'documentsStatus',
    header: 'Documents Status',
    value: undefined,
    advancedFilterField: 'idDocumentsStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DOCUMENTS_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'supplierPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'clientPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  exportStatus: {
    field: 'exportStatus',
    header: 'Export Status',
    value: undefined,
    advancedFilterField: 'idExportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.EXPORT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  status: {
    field: 'status',
    header: 'Status',
    value: undefined,
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  confirmOrder: {
    field: 'confirmOrder',
    header: 'Confirm Order',
    value: undefined,
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    value: undefined,
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    value: undefined,
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  suppliers: {
    field: 'suppliers',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'suppliers',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  resultTotalConvertedCotPi: {
    field: 'resultTotalConvertedCotPi',
    header: 'Total Converted COT PI (USD)',
    value: undefined,
    advancedFilterField: '',
  },
  resultTotalConvertedCotCi: {
    field: 'resultTotalConvertedCotCi',
    header: 'Total Converted COT CI (USD)',
    value: undefined,
    advancedFilterField: '',
  },
  resultTotalConvertedSunPi: {
    field: 'resultTotalConvertedSunPi',
    header: 'Total Converted SUN PI (USD)',
    value: undefined,
    advancedFilterField: '',
  },
  resultTotalConvertedSunCi: {
    field: 'resultTotalConvertedSunCi',
    header: 'Total Converted SUN CI (USD)',
    value: undefined,
    advancedFilterField: '',
  },
};
