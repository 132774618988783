export const containerColumns = [
  {
    field: 'quantityCtnr',
    header: '',
    fractionDigits: 0,
  },
  {
    field: 'grossWeight',
    header: 'G.W.',
    fractionDigits: 2,
  },
  {
    field: 'netWeight',
    header: 'N.W.',
    fractionDigits: 2,
  },
  {
    field: 'cbm',
    header: 'CBM',
    fractionDigits: 3,
  },
];
