import { gql } from '@apollo/client';

export const listAllSatOtherExpensesQuery = gql`
  query ListAllSatOtherExpenses($data: ListAllSatOtherExpensesInput!) {
    listAllSatOtherExpenses(data: $data) {
      data {
        idSatOtherExpense
        cancelDate
        requestDate
        number
        idStatus
        idStatus2 {
          idDomain
          description
        }
        description
        idCurrency
        idCurrency2 {
          idCurrency
          abbreviation
        }
        total
        appliedOn
        idSatForeignTradeSupplierDestination
        confirmationDate
      }
    }
  }
`;

export const requestSatOtherExpenseQuery = gql`
  mutation RequestSatOtherExpense($idSatOtherExpense: Int!) {
    requestSatOtherExpense(idSatOtherExpense: $idSatOtherExpense)
  }
`;

export const generateSatOtherExpenseDownloadQuery = gql`
  query GenerateSatOtherExpenseDownload(
    $data: GenerateSatOtherExpenseDownloadInput!
  ) {
    generateSatOtherExpenseDownload(data: $data)
  }
`;
