import { useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { number, object, string } from 'yup';
import Button from '../../../../../../components/Button';
import FormDatePicker from '../../../../../../components/FormDatePicker';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import Loading from '../../../../../../components/Loading';

import MainButton from '../../../../../../components/MainButton';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../../utils/getValidationErrors';
import { SatForeignTradeSupplier } from '../../interfaces';
import {
  createSatForeignTradePaymentQuery,
  updateSatForeignTradePaymentQuery,
} from '../../queries';
import { SatForeignTradePayment } from '../AdvancePaid/interfaces';
import { Buttons, Container, Row } from './styles';
import {
  FinancialPurchasePaymentType,
  FinancialSellerPaymentType,
} from '../../../../../../shared/enums/domains';

interface IAddAdvanceModal {
  estimatedTotalAdvance?: number;
  totalAmount?: number;
  paymentType: FinancialPurchasePaymentType | FinancialSellerPaymentType;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  satForeignTradePayment?: SatForeignTradePayment;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchForeignTradePaymentsData(): void;
  estimatedTotalBalance?: number;
  totalRemain?: number;
}

const AddAdvanceModal: React.FC<IAddAdvanceModal> = ({
  estimatedTotalAdvance,
  totalAmount,
  paymentType,
  satForeignTradeSupplier,
  satForeignTradePayment,
  setDisplayModal,
  refetchForeignTradePaymentsData,
  estimatedTotalBalance,
  totalRemain = 0,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [percentage, setPercentage] = useState<number>();

  const { showError, showSuccess } = useRefHook();

  const [pageLoading, setPageLoading] = useState(false);

  const validateForm = useCallback(
    async (data: any) => {
      formRef.current?.setErrors({});
      const schema = object().shape({
        amount: number().nullable().required(requiredFieldErrorMessage),
        paymentDate: string().nullable().required(requiredFieldErrorMessage),
      });

      try {
        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        const firstError = error.inner[0];
        const inputWithError = formRef.current?.getFieldRef(firstError.path);

        if (inputWithError.focus) {
          inputWithError.focus();
        } else if (inputWithError.inputRef?.current?.focus) {
          inputWithError.inputRef?.current?.focus();
        }

        showError({
          summary: 'Please fill all the required fields',
        });
        return false;
      }
    },
    [showError],
  );

  const [createSatForeignTradePaymentMutation] = useMutation(
    createSatForeignTradePaymentQuery,
  );
  const [updateSatForeignTradePaymentMutation] = useMutation(
    updateSatForeignTradePaymentQuery,
  );

  const savePayment = useCallback(
    async (data: any) => {
      setPageLoading(true);
      const idSatForeignTradePayment =
        satForeignTradePayment?.idSatForeignTradePayment;
      const paymentToSave: SatForeignTradePayment = {
        percentage,
        amount: data.amount,
        paymentDate: data.paymentDate,
      };

      try {
        if (!idSatForeignTradePayment) {
          await createSatForeignTradePaymentMutation({
            variables: {
              data: {
                ...paymentToSave,
                idSat: satForeignTradeSupplier.idSat,
                idSatForeignTrade: satForeignTradeSupplier.idSatForeignTrade,
                idSatForeignTradeSupplier:
                  satForeignTradeSupplier.idSatForeignTradeSupplier,
                idType: paymentType,
              },
            },
          });
        } else {
          await updateSatForeignTradePaymentMutation({
            variables: {
              data: {
                ...paymentToSave,
                idSatForeignTradePayment,
              },
            },
          });
        }
        setPageLoading(false);
        refetchForeignTradePaymentsData();
        setDisplayModal(false);
        showSuccess({
          summary: `Sat Foreign Trade Payment ${
            !idSatForeignTradePayment ? 'created' : 'updated'
          }`,
        });
      } catch (error) {
        setPageLoading(false);
        showError({
          summary: `Error while ${
            !idSatForeignTradePayment ? 'creating' : 'updating'
          } Sat Foreign Trade Payment`,
          detail: error.message,
        });
      }
    },
    [
      satForeignTradePayment?.idSatForeignTradePayment,
      percentage,
      refetchForeignTradePaymentsData,
      setDisplayModal,
      showSuccess,
      createSatForeignTradePaymentMutation,
      satForeignTradeSupplier.idSat,
      satForeignTradeSupplier.idSatForeignTrade,
      satForeignTradeSupplier.idSatForeignTradeSupplier,
      paymentType,
      updateSatForeignTradePaymentMutation,
      showError,
    ],
  );

  async function handleSubmit() {
    const data = formRef.current?.getData();
    const isFormValid = await validateForm(data);
    if (isFormValid) {
      savePayment(data);
    }
  }

  function handleCancel() {
    setDisplayModal(false);
  }

  const calculatePercentage = useCallback(
    (amount: number) => {
      const percentageValue =
        (amount /
          ((estimatedTotalAdvance ?? 0) + (estimatedTotalBalance ?? 0))) *
        100;

      // Quando o estimated total eh 0, a conta resulta em Infinity, entao valida e salva como undefined
      setPercentage(
        Number.isFinite(percentageValue) ? percentageValue : undefined,
      );
    },
    [estimatedTotalAdvance, estimatedTotalBalance],
  );

  useEffect(() => {
    const suggestedAmount = (estimatedTotalAdvance || 0) - (totalAmount || 0);

    // Impede que o valor sugerido seja maior do que o total remain
    if (suggestedAmount > totalRemain) return;

    const amountValue = formRef.current?.getFieldValue('amount');

    if (!amountValue && suggestedAmount > 0) {
      formRef.current?.setFieldValue('amount', suggestedAmount);
      calculatePercentage(suggestedAmount);
    }
  }, [calculatePercentage, estimatedTotalAdvance, totalRemain, totalAmount]);

  return (
    <Container>
      <Form
        ref={formRef}
        initialData={satForeignTradePayment}
        onSubmit={handleSubmit}
      >
        <Row className="flex flex-wrap">
          <ReadOnlyInput
            className="col-12"
            label="Advance %"
            type="number"
            locale="pt-BR"
            value={percentage}
            minimumFractionDigits={2}
          />
          <FormInputNumber
            className="col-12"
            name="amount"
            label="Advance Amount"
            decimalScale={2}
            thousandSeparator="."
            decimalSeparator=","
            required
            onValueChange={e => calculatePercentage(e.floatValue || 0)}
          />
          <FormDatePicker
            className="col-12"
            name="paymentDate"
            label="Advance Payment Date"
            required
          />
        </Row>
        <Buttons
          className="mt-3"
          style={{ display: 'flex', placeContent: 'end' }}
        >
          <MainButton
            type="button"
            label="Confirm"
            className="mx-2"
            onClick={handleSubmit}
          />
          <Button
            type="button"
            label="Cancel"
            onClick={() => handleCancel()}
            className="p-button-danger"
          />
        </Buttons>
      </Form>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default AddAdvanceModal;
