import React, { Ref, useImperativeHandle, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { FileType } from '../../../../../shared/enums/fileType';
import FileUpload from '../../../../../components/FileUpload';
import { createPrcAttachmentsQuery } from '../../Updates/queries';
import { IFilesUploadRef } from '../../PrcUploadFiles';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

interface IFilesUploadProps {
  ref: Ref<IFilesUploadRef>;
  idPrc: number;
  prcFilesRefetch(): void;
}

const AttachmentsUpload: React.FC<IFilesUploadProps> = React.forwardRef(
  ({ idPrc, prcFilesRefetch }, ref) => {
    const { showSuccess, showError } = useRefHook();

    const [uploadInProgress, setUploadInProgress] = useState(false);

    const [displayModal, setDisplayModal] = useState(false);

    const [createPrcAttachmentsMutation] = useMutation(
      createPrcAttachmentsQuery,
    );

    function handleCloseModal() {
      setDisplayModal(false);
    }

    async function handleUploadConfirm(data: FileUploadResponse[]) {
      try {
        setUploadInProgress(true);

        await createPrcAttachmentsMutation({
          variables: {
            data: {
              idPrc,
              idType: AttachmentTypes.ATTACHMENT_FILE_PRC,
              uploadedFiles: data.map(file => file.serverName),
            },
          },
        });

        showSuccess({
          summary: 'Files created',
        });

        prcFilesRefetch();

        handleCloseModal();
      } catch (error) {
        showError({
          summary: 'Error while creating files',
          detail: error.message,
        });
      } finally {
        setUploadInProgress(false);
      }
    }

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        if (displayModal) {
          handleCloseModal();
        } else {
          setDisplayModal(true);
        }
      },
    }));

    return (
      <Container>
        <Dialog
          header="Add an Attachment"
          visible={displayModal}
          style={{ width: '40vw' }}
          onHide={() => handleCloseModal()}
          closable
        >
          <FileUpload
            multiple
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.TXT},${FileType.ZIP}`}
            onConfirm={e => handleUploadConfirm(e)}
            uploadInProgress={uploadInProgress}
          />
        </Dialog>
      </Container>
    );
  },
);

export default AttachmentsUpload;
