import styled from 'styled-components';

export const Container = styled.div`
  margin: 1pc 0;

  .s-comment-pagination-content {
    display: flex;
    background: rgba(9, 30, 66, 0.04);
    transition: background-color 0.2s;
    padding: 8px;
    border-radius: 4px;
    &:hover {
      background: var(--grey);
    }

    button {
      width: 100%;
      background: none;
      border: none;
      color: #42526e;
      font-weight: 500;
    }
  }
`;
