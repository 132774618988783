import styled from 'styled-components';

interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  label {
    margin-left: 4px;
  }
`;
