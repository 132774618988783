/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  CheckboxChangeEvent,
  CheckboxProps as CheckboxPropsPrime,
} from 'primereact/checkbox';
import { useField } from '@unform/core';
import { Container } from './styles';
import DynamicIcon from '../DynamicIcon';
import isNullOrUndefined from '../../utils/isNullOrUndefined';

interface CheckboxProps extends Omit<CheckboxPropsPrime, 'checked'> {
  name: string;
  label: string;
  initialValue?: boolean;
  value?: boolean;
  onValueChange?(e: CheckboxChangeEvent): boolean;
  className?: string;
  iconUrl?: string;
  returnType?: 'boolean' | 'number';
}

const FormCheckbox: React.FC<CheckboxProps> = ({
  name,
  label,
  initialValue = false,
  value,
  onValueChange,
  className,
  disabled,
  iconUrl,
  returnType,
  ...rest
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [checked, setChecked] = useState<boolean>(
    !!defaultValue || value || initialValue,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkboxRef.current,
      getValue: (ref: any) => {
        if (returnType === 'number') {
          return ref.checked ? 1 : 0;
        }

        return ref.checked;
      },
      setValue: (_, newValue: boolean) => {
        setChecked(newValue);
      },
    });
  }, [fieldName, registerField, returnType]);

  function onChange(e: CheckboxChangeEvent) {
    if (onValueChange) {
      const shouldContinue = onValueChange(e);
      if (!shouldContinue) return;
    }

    setChecked(!!e.checked);
  }

  return (
    <Container
      className={`${
        className ? `${className} form-checkbox` : 'form-checkbox'
      }`}
      disabled={disabled}
    >
      <Checkbox
        inputRef={checkboxRef}
        {...rest}
        checked={!isNullOrUndefined(value) ? value : checked}
        onChange={e => onChange(e)}
        name={name}
        disabled={disabled}
      />
      {iconUrl && <DynamicIcon icon={`sat/${iconUrl}`} size="30" />}
      <label>{label}</label>
    </Container>
  );
};

export default FormCheckbox;
