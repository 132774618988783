import styled from 'styled-components';

export const Container = styled.div`
  .p-button {
    white-space: nowrap;
  }

  .trash-button {
    margin-left: auto;
  }
`;
