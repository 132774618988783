/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import Input from '../../components/Input';
import MainButton from '../../components/MainButton';
import logoSat from '../../assets/logo.svg';
import getValidationErrors from '../../utils/getValidationErrors';
import { useRefHook } from '../../hooks/useRefHook';
import useTitle from '../../hooks/useTitle';

interface ISignInFormData {
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  useTitle('Login');

  const [loading, setLoading] = useState(false);

  const { showError } = useRefHook();

  const location = useLocation();

  // Redirect
  const history = useHistory();

  // Referencia ao formulario
  const formRef = useRef<FormHandles>(null);

  const redirectedFromError = new URLSearchParams(location.search).get(
    'redirect',
  );

  const { signIn } = useAuth();

  useEffect(() => {
    if (redirectedFromError) {
      showError({
        summary: 'You have been logged out',
        detail: 'An error occurred, please login again',
      });
    }
  }, [redirectedFromError, showError]);

  // Submit do formulario
  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      setLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          username: Yup.string().required('Enter an username'),
          password: Yup.string().required('Enter a password'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        await signIn({ username: data.username, password: data.password });

        history.push('/home');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          showError({
            summary: 'Login failed',
            detail: error.message,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [signIn, history, showError],
  );

  return (
    <div>
      <img src={logoSat} alt="SAT" />
      <Card style={{ height: '315px' }}>
        {/* Formulario de login */}
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* Usuario */}
          <div className="field">
            <label htmlFor="username1" className="block">
              Username
            </label>
            <Input
              name="username"
              id="username1"
              aria-describedby="username1-help"
              className="block"
            />
          </div>

          {/* Senha */}
          <div className="field">
            <label htmlFor="password1" className="block">
              Password
            </label>
            <Input
              type="password"
              name="password"
              id="password1"
              aria-describedby="password1-help"
              className="block"
            />
          </div>

          {/* Botão submit */}
          <MainButton
            type="submit"
            label="Login"
            icon="pi pi-sign-in"
            loading={loading}
          />

          {/* Recuperar senha */}
          <Link to="/forgot-password">Forgot password?</Link>
        </Form>
      </Card>
    </div>
  );
};

export default SignIn;
