import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { Skeleton } from 'primereact/skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import PageTabContainer from '../../../../components/PageTabContainer';
import FollowUp from './FollowUp';
import { IUpdatesProps } from './interfaces';

import { Container } from './styles';
import UpdatesContent from './UpdatesContent';
import { listSatForeignTradesNumbersQuery } from '../ForeignTrade/queries';
import { ForeignTradeTab } from '../ForeignTrade/interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import { commentSearchParams } from '../../../../components/Comments/CommentAndReplies';

const Updates: React.FC<IUpdatesProps> = ({
  selected,
  idSat,
  displayFollowUp,
  displayUpdatesContent,
  roleEntityPermissions,
  confirmOrderCommercialDone,
}) => {
  const { showError } = useRefHook();

  const [tabs, setTabs] = useState<ForeignTradeTab[]>([]);

  const [currentTabActiveIndex, setCurrentTabActiveIndex] = useState<number>(0);

  const history = useHistory();
  const { search } = useLocation();

  const foreignTradeUrlParam = new URLSearchParams(search).get(
    'foreignTradeId',
  );

  /**
   * Busca abas de SAT Foreign Trades
   */
  const [loadForeignTradesNumbers, { loading: foreignTradesLoading }] =
    useLazyQuery(listSatForeignTradesNumbersQuery, {
      variables: {
        data: {
          idSat,
          pagination: {
            _page: 1,
            _limit: 0,
            _sortOrder: 'DESC',
          },
        },
      },
      onCompleted: response => {
        if (response.listSatForeignTradesNumbersBySatId?.items) {
          setTabs(response.listSatForeignTradesNumbersBySatId.data);

          // Se tiver o parametro de Foreign Trade na URL e as abas nunca
          // tiverem sido carregadas, seta a respectiva aba como ativa
          if (foreignTradeUrlParam && !tabs.length) {
            const foreignTradeIndex =
              response.listSatForeignTradesNumbersBySatId.data.findIndex(
                (ft: ForeignTradeTab) =>
                  ft.idSatForeignTrade === parseInt(foreignTradeUrlParam, 10),
              );

            // Se o parametro de Foreign Trade valido
            if (foreignTradeIndex >= 0) {
              setCurrentTabActiveIndex(foreignTradeIndex);
            }
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Foreign Trades',
          detail: errorData.message,
        });
      },
    });

  function handleTabChange(e: TabViewTabChangeEvent) {
    setCurrentTabActiveIndex(e.index);

    const searchParams = new URLSearchParams(search);

    // Remove parametros de comentarios da URL, para evitar que comentarios
    // continuem sendo destacados quando usuario interagir com o menu
    if (
      searchParams.has(commentSearchParams.idComment) ||
      searchParams.has(commentSearchParams.idChildComment)
    ) {
      searchParams.delete(commentSearchParams.idComment);
      searchParams.delete(commentSearchParams.idChildComment);

      history.replace({
        search: searchParams.toString(),
      });
    }
  }

  useEffect(() => {
    if (confirmOrderCommercialDone && !tabs.length && selected) {
      loadForeignTradesNumbers();
    }
  }, [
    tabs.length,
    loadForeignTradesNumbers,
    selected,
    confirmOrderCommercialDone,
  ]);

  return (
    <PageTabContainer selected={selected}>
      {confirmOrderCommercialDone && !foreignTradesLoading && !!tabs.length && (
        <TabView
          scrollable
          activeIndex={currentTabActiveIndex}
          onTabChange={e => handleTabChange(e)}
          renderActiveOnly={false}
        >
          {tabs.map((tab: ForeignTradeTab, index: number) => {
            return (
              <TabPanel
                key={tab.idSatForeignTrade}
                header={tab.satForeignTradeNumber}
              >
                <Container>
                  {displayUpdatesContent && (
                    <UpdatesContent
                      selected={currentTabActiveIndex === index}
                      idSat={idSat}
                      roleEntityPermissions={roleEntityPermissions}
                      idSatForeignTrade={tab.idSatForeignTrade}
                      refetchUpdatesTabs={loadForeignTradesNumbers}
                    />
                  )}
                  {displayFollowUp && (
                    <FollowUp
                      selected={currentTabActiveIndex === index}
                      idSat={idSat}
                      roleEntityPermissions={roleEntityPermissions}
                      idSatForeignTrade={tab.idSatForeignTrade}
                      refetchUpdatesTabs={loadForeignTradesNumbers}
                    />
                  )}
                </Container>
              </TabPanel>
            );
          })}
        </TabView>
      )}

      {!confirmOrderCommercialDone && !tabs.length && (
        <Container>
          {displayUpdatesContent && (
            <UpdatesContent
              selected={selected}
              idSat={idSat}
              roleEntityPermissions={roleEntityPermissions}
              refetchUpdatesTabs={loadForeignTradesNumbers}
            />
          )}
          {displayFollowUp && (
            <FollowUp
              selected={selected}
              idSat={idSat}
              roleEntityPermissions={roleEntityPermissions}
              refetchUpdatesTabs={loadForeignTradesNumbers}
            />
          )}
        </Container>
      )}

      {foreignTradesLoading && (
        <>
          <Skeleton height="3rem" className="mb-3" />
        </>
      )}
    </PageTabContainer>
  );
};

export default Updates;
