import { gql } from '@apollo/client';

export const listAllFinancialTransactionsQuery = gql`
  query ListAllFinancialTransactions(
    $data: ListAllFinancialTransactionsInput!
  ) {
    listAllFinancialTransactions(data: $data) {
      data {
        idFinancialTransaction
        status
        idPaymentSituation
        idPaymentSituation2 {
          description
        }
        reference
        idFinancialAccount2 {
          idCompany2 {
            name
          }
          idCurrency2 {
            abbreviation
            usdExchangeRate
          }
        }
        competencyRegime
        cashflowRegime
        amount
        amountUSD
        billRate
        idFinancialTransactionCategory2 {
          name
          group
        }
        idCountry2 {
          name
        }
        idCostCenterByCountry2 {
          name
        }
        idClient2 {
          name
        }
        idSupplier2 {
          name
        }
        product
        description
        idSatForeignTrade2 {
          shipmentStatus
        }
        idBeneficiary2 {
          name
        }
        hasSystemGeneratedComment
      }
      items
    }
  }
`;

export const exportFinancialTransactionsQuery = gql`
  query exportFinancialTransactionsList(
    $data: ExportFinancialTransactionsListInput!
  ) {
    exportFinancialTransactionsList(data: $data)
  }
`;
