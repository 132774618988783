export const clientQuotationRoles = {
  idEntity: 46,
  idModule: 11,
  permissions: {
    idPermissionCreateClientQuotation: 97,
    idPermissionApproveClientQuotation: 192,
    idPermissionCreateSatFromCQ: 193,
    idPermissionUpdateRestrictInfo: 98,
    idPermissionDuplicateClientQuotation: 99,
    idPermissionExportSellerPi: 100,
    idPermissionExportPurchasePi: 101,
    idPermissionUpdateClientQuotation: 105,
    idPermissionDeleteClientQuotation: 106,
    idPermissionExportListClientQuotation: 107,
    idPermissionSupplierLinkClientQuotation: 108,
    idPermissionAddClientQuotationItem: 109,
    idPermissionRefreshClientQuotationItems: 110,
  },
  fields: {
    idFieldCqNumber: 870,
    idFieldMainProduct: 871,
    idFieldCategory: 872,
    idFieldRemarks: 873,
    idFieldPackageNotes: 874,
    idFieldIndSamples: 875,
    idFieldSamplesLeadTime: 876,
    idFieldIdClient: 877,
    idFieldClientCompanyName: 878,
    idFieldClientCnpj: 879,
    idFieldClientAddress: 880,
    idFieldIdFirstBuyer: 881,
    idFieldIdSecondBuyer: 882,
    idFieldIdImporter: 883,
    idFieldImporterCompanyName: 884,
    idFieldImporterCnpj: 885,
    idFieldImporterAddress: 886,
    idFieldIdSellingIncoterm: 887,
    idFieldPaymentTermAdvance: 888,
    idFieldPaymentTermBalance: 889,
    idFieldIdPaymentTermCondition: 890,
    idFieldIdExporter: 891,
    idFieldExporterAddress: 892,
    idFieldIdPurchaseIncoterm: 893,
    idFieldProductionTime: 894,
    idFieldIdSupplier: 895,
    idFieldIdPortOfLoading: 896,
    idFieldIdPortOfDischarge: 897,
    idFieldIdCountryOrigin: 898,
    idFieldIdCountryAcquisition: 899,
    idFieldIdCountryProcedence: 900,
    idFieldEstimatedInspection: 901,
    idFieldEstimatedShipment: 902,
    idFieldEstimatedArrival: 903,
    idFieldEtimatedArrivalAtClient: 904,
    idFieldPurchaseUser: 905,
    idFieldSalesUser: 906,
    idFieldApprovedOn: 907,
    idFieldQtyCtnr: 908,
    idFieldIdTypeCtnr: 909,
    idFieldCompanyName: 921,
    idFieldIdPort: 923,
    idFieldIdIncoterm: 924,
    idFieldIdIncotermSecond: 925,
    idFieldAtwork: 926,
    idFieldEstimatedTotalAdvance: 930,
    idFieldEstimatedTotalBalance: 931,
    idFieldAmountCot: 932,
    idFieldGrossMargin: 933,
    idFieldBeneficiaryName: 934,
    idFieldMainBankName: 935,
    idFieldMainBankAddress: 936,
    idFieldIdCountry: 937,
    idFieldIdRegion: 938,
    idFieldIdSecondCountry: 939,
    idFieldSwift: 940,
    idFieldAccountNumber: 941,
    idFieldIban: 942,
    idFieldAmountSun: 950,
    idFieldEstimatedAdvance: 952,
    idFieldEstimatedBalance: 954,
    idFieldAmountSunSeller: 958,
    idFieldIdPurchaseIncotermSeller: 959,
    idFieldEstimatedAdvanceSeller: 960,
    idFieldEstimatedTotalAdvanceSeller: 961,
    idFieldEstimatedBalanceSeller: 962,
    idFieldEstimatedTotalBalanceSeller: 963,
    idFieldIdPaymentTermConditionSeller: 964,
    idFieldAmountSunTot: 965,
    idFieldAmountSunSellerTot: 966,
    idFieldGrossMarginTot: 967,
    idFieldIdImage: 970,
    idFieldSumNumber: 971,
    idFieldStCode: 972,
    idFieldReference: 973,
    idFieldNamePT: 976,
    idFieldNameComplete: 977,
    idFieldDescriptionPT: 978,
    idFieldModel: 979,
    idFieldSku: 980,
    idFieldColorEN: 981,
    idFieldColorPT: 982,
    idFieldIdColourImage: 984,
    idFieldMaterialCompositionEN: 985,
    idFieldMaterialCompositionPT: 986,
    idFieldDimension: 987,
    idFieldNetWeight: 988,
    idFieldArtworkEN: 989,
    idFieldTechnique: 990,
    idFieldIdTypePackage: 991,
    idFieldLinning: 992,
    idFieldLinningComposition: 993,
    idFieldLinningDescription: 994,
    idFieldIdTypeOfPackage: 995,
    idFieldSellingPackage: 996,
    idFieldQtySellingPackage: 998,
    idFieldQtySellingPackageDimension: 999,
    idFieldQtySellingPackageGross: 1000,
    idFieldStickerDimensions: 1001,
    idFieldUnSetInner: 1002,
    idFieldInner: 1003,
    idFieldPackageEan13: 1004,
    idFieldInnerDun14: 1005,
    idFieldDun14: 1006,
    idFieldBrand: 1007,
    idFieldProductLine: 1008,
    idFieldQtyPi: 1009,
    idFieldUnit: 1010,
    idFieldMoq: 1011,
    idFieldSqmUnit: 1012,
    idFieldTotalSqm: 1013,
    idFieldCotPriceSqm: 1014,
    idFieldSunPriceSqm: 1015,
    idFieldQtyOfCtn: 1016,
    idFieldPcsCtn: 1017,
    idFieldNetWeightCtn: 1018,
    idFieldNetWeightTotal: 1019,
    idFieldGrosstWeight: 1020,
    idFieldGrosstWeightTotall: 1021,
    idFieldLength: 1022,
    idFieldWidth: 1023,
    idFieldHeight: 1024,
    idFieldCbm: 1025,
    idFieldCbmTotal: 1026,
    idFieldPurchaseIncoterm: 1027,
    idFieldTargetCot: 1028,
    idFieldCotPrice: 1029,
    idFieldTotalCot: 1030,
    idFieldSellingIncoterm: 1031,
    idFieldTargetSun: 1032,
    idFieldSunPrice: 1033,
    idFieldTotalSunPi: 1034,
    idFieldEstimateProfit: 1035,
    idFieldEstimateMargin: 1036,
    idFieldHsCode: 1037,
    idFieldNcm: 1038,
    idFieldDestaque: 1039,
    idFieldForeingTrade: 1040,
    idFieldImportLicense: 1041,
    idFieldLi: 1042,
    idFieldIpi: 1043,
    idFieldPis: 1044,
    idFieldCofins: 1045,
    idFieldDumping: 1046,
    idFieldSamples: 1047,
    idFieldSamplesApproved: 1049,
    idFieldSamplesNotes: 1050,
    idFieldLicensed: 1051,
    idFieldlicensor: 1052,
    idFieldRoyalties: 1053,
    idFieldTypeOfFabric: 1055,
    idFieldMargin: 1056,
    idFieldIdSupplierInfomation: 1057,
    idFieldIntermediaryBeneficiaryName: 1120,
    idFieldIntermediaryBankAddress: 1121,
    idFieldIntermediaryBankCountry: 1122,
    idFieldIntermediaryBankState: 1123,
    idFieldIntermediaryBankCity: 1124,
    idFieldIntermediarySwift: 1125,
    idFieldIntermediaryAccountNumber: 1126,
    idFieldIntermediaryIban: 1127,
    idFieldIi: 2222,
  },
};
