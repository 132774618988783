import React from 'react';

import { Container } from './styles';
import logoLargeColor from '../../assets/logoLargeColor.svg';
import useTitle from '../../hooks/useTitle';

const NotFound: React.FC = () => {
  useTitle('Page not found');

  return (
    <Container>
      <img src={logoLargeColor} alt="SAT" />
      <h1>Page not found</h1>
    </Container>
  );
};

export default NotFound;
