/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { useLazyQuery, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  SortOrder,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { isValid } from 'date-fns';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import Loading from '../../../components/Loading';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { listSatListFollowUpViewQuery } from './queries';

import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import pagination from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { getUTC } from '../../../utils/dateUtil';
import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { gridColumnsData, groupedColumns } from './constants';
import { ISatListFollowUpView, SatListFollowUpPermissions } from './interfaces';
import userHasPermission from '../../../utils/userHasPermission';
import { followUpRoles } from '../../../shared/roles/followUp';
import { parseCurrencyPtBrNumberColumn } from '../../../utils/gridColumnsParse';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

interface ISatListFollowUpViewLazyParams
  extends ILazyParams,
    ISatListFollowUpView {
  [key: string]:
    | number
    | string
    | SortOrder
    | DataTableMultiSortMetaType
    | Date
    | undefined;
}

const ControlFollowUp: React.FC = () => {
  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  const gridColumnsName = '@SAT:satControlsFollowUpGridColumns';

  const lazyParamsName = '@SAT:satControlsFollowUpLazyParams';

  const advancedFiltersName = '@SAT:satControlsFollowUpAdvancedFilters';

  const location = useLocation();

  const { toastRef } = useRefHook();

  const isMounted = useRef(false);

  const gridRef = useRef<GridRef<any>>(null);

  const [pageLoading /* , setPageLoading */] = useState(false);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  const [lazyParams, setLazyParams] = useState<ISatListFollowUpViewLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: ISatListFollowUpViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: ISatListFollowUpViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const { roles } = useAuth();

  const { idEntity } = followUpRoles;

  const { idPermissionExportSatsFollowUp } = followUpRoles.permissions;

  // Busca permissoes do usuario para a entity
  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        idPermissionExportSatsFollowUp,
        userPermissions,
      ),
    };
  }, [idPermissionExportSatsFollowUp, userPermissions]);

  const fieldsPermissions: SatListFollowUpPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = followUpRoles;

    const followUpPermissions = {} as SatListFollowUpPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      followUpPermissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return followUpPermissions;
  }, [userFields]);

  const columns = useMemo(() => {
    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const satListFollowUpCommonVariables = {
    allColumns: lazyParams.globalFilter,
    idImportStatus: lazyParams.idImportStatus,
    importStatus: lazyParams.importStatus,
    idRegisterStatus: lazyParams.idRegisterStatus,
    registerStatus: lazyParams.registerStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    artworkStatus: lazyParams.artworkStatus,
    idProductionStatus: lazyParams.idProductionStatus,
    productionStatus: lazyParams.productionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    shipmentStatus: lazyParams.shipmentStatus,
    idDocumentsStatus: lazyParams.idDocumentsStatus,
    documentsStatus: lazyParams.documentsStatus,
    idStatusSellingPayment: lazyParams.idStatusSellingPayment,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    shipment: lazyParams.shipment,
    status: lazyParams.status,
    statusSat: lazyParams.statusSat,
    idSat: lazyParams.idSat,
    satNumber: lazyParams.satNumber,
    clientOrder: lazyParams.clientOrder,
    clientNotify: lazyParams.clientNotify,
    idClient: lazyParams.idClient,
    idImporter: lazyParams.idImporter,
    importerConsignee: lazyParams.importerConsignee,
    mainProduct: lazyParams.mainProduct,
    idClientIncoterm: lazyParams.idClientIncoterm,
    sellingIncoterm: lazyParams.sellingIncoterm,
    ctnrs: lazyParams.ctnrs,
    consolidatedWith: lazyParams.consolidatedWith,
    totalSunPi: lazyParams.totalSunPi,
    totalSunCiShipment: lazyParams.totalSunCiShipment,
    estimatedTotalBalance: lazyParams.estimatedTotalBalance,
    totalBalanceReceived: lazyParams.totalBalanceReceived,
    realInspectionDate: lazyParams.realInspectionDate,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    realEtd: lazyParams.realEtd,
    estimatedArrivalDate: lazyParams.estimatedArrivalDate,
    realEta: lazyParams.realEta,
    estimatedArrivalClientDate: lazyParams.estimatedArrivalClientDate,
    realArrivalAtClient: lazyParams.realArrivalAtClient,
    followUpPt: lazyParams.followUpPt,
    clientDepartment: lazyParams.clientDepartment,
    idFirstBuyer: lazyParams.idFirstBuyer,
    firstBuyer: lazyParams.firstBuyer,
    idSecondBuyer: lazyParams.idSecondBuyer,
    secondBuyer: lazyParams.secondBuyer,
    idSalesUser: lazyParams.idSalesUser,
    firstSalesUser: lazyParams.firstSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    secondSalesUser: lazyParams.secondSalesUser,
    idComexImportUser: lazyParams.idComexImportUser,
    importUser: lazyParams.importUser,
  };

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const { loading: satListFollowUpViewLoading, data: satListFollowUpViewData } =
    useQuery(listSatListFollowUpViewQuery(fieldsPermissions), {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          ...satListFollowUpCommonVariables,
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Follow Up List',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });

    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  async function exportToXlsx(exportData: any) {
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    exportData.data.forEach((e: any) => {
      columnsToRemove.forEach(column => {
        delete e[column.field];
      });
      delete e.idSat;
      delete e.idSatForeignTrade;
      delete e.idClient;
      delete e.idImporter;
      delete e.satCurrency;

      const colunsNotRemoved = columns.filter(
        col => !columnsToRemove.includes(col),
      );

      // Formata campos de data
      colunsNotRemoved.forEach(column => {
        if (
          e[column.field] &&
          isValid(new Date(e[column.field])) &&
          typeof e[column.field] === 'string'
        ) {
          const date = getUTC(e[column.field]).toLocaleString().split(',')[0];
          e[column.field] = date;
        }
      });
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.columns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    const worksheet = xlsx.utils.json_to_sheet(exportData.data, {
      header: columnsOrder,
    });

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, 'satListFollowUp');
  }

  const [
    loadSatListFollowUpView,
    {
      loading: satListFollowUpViewExportLoading,
      data: satListFollowUpViewExportData,
    },
  ] = useLazyQuery(listSatListFollowUpViewQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satListFollowUpViewExportData.listSatListFollowUpView) {
        exportToXlsx(satListFollowUpViewExportData.listSatListFollowUpView);
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while exporting Follow Up List',
          detail: 'Please try again later',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Follow Up List to export',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  const parseSatColumn = (rowData: any) => {
    if (rowData.idSat) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.satNumber}
          <FiExternalLink size={15} />
        </Link>
      );
    }
    return rowData.satNumber;
  };

  const parseCurrencyColumn = (rowData: any, field: any) => {
    return parseCurrencyPtBrNumberColumn(
      rowData,
      field,
      2,
      rowData.satCurrency,
    );
  };

  const parseUpdatesColumn = (rowData: any) => {
    return (
      rowData.idSat && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=updates`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
          style={{ justifyContent: 'space-between' }}
        >
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseSatShipmentColumn = (rowData: any) => {
    return (
      rowData.idSatForeignTrade && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  function handleColumn(field: string) {
    switch (field) {
      case 'satNumber':
        return parseSatColumn;
      case 'shipment':
        return parseSatShipmentColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'realInspectionDate':
      case 'estimatedShipmentDate':
      case 'realEtd':
      case 'estimatedArrivalDate':
      case 'realEta':
      case 'estimatedArrivalClientDate':
      case 'realArrivalAtClient':
        return parseDateColumm;
      case 'followUpPt':
        return parseUpdatesColumn;
      case 'totalSunPi':
      case 'totalSunCiShipment':
        return parseCurrencyColumn;
      default:
        return undefined;
    }
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'statusSat':
        return { width: '115px' };
      case 'shipment':
      case 'estimatedTotalBalance':
      case 'totalBalanceReceived':
      case 'clientDepartment':
        return { width: '180px' };
      case 'clientNotify':
      case 'importerConsignee':
        return { width: '255px' };
      case 'ctnrs':
        return { width: '160px' };
      case 'consolidatedWith':
      case 'totalSunCiShipment':
        return { width: '200px' };
      case 'sellingIncoterm':
      case 'realInspectionDate':
      case 'estimatedShipmentDate':
      case 'estimatedArrivalClientDate':
      case 'realArrivalAtDate':
      case 'realArrivalAtClient':
      case 'estimatedArrivalDate':
        return { width: '190px' };
      case 'firstSalesUser':
      case 'secondSalesUser':
      case 'importUser':
        return { width: '200px' };
      default:
        return { width: '140px' };
    }
  }

  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
        />
      )
    );
  });

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  const history = useHistory();

  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/commercial/sats/${e.data.idSat}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  useEffect(() => {
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    if (localStorageSelectedColumns) {
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      setSelectedColumns(columnsObject);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader
        title="Follow Up List"
        fixedStickyButtons={fixedStickyButtons}
      >
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
        {permissions.export && (
          <Button
            type="button"
            label="Export Grid"
            className="export-xlsx"
            loading={satListFollowUpViewExportLoading}
            onClick={() =>
              loadSatListFollowUpView({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    ...satListFollowUpCommonVariables,
                  },
                },
              })
            }
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        className="p-datatable-hoverable-rows"
        name="controlsFollowUp"
        lazy
        totalRecords={
          !satListFollowUpViewData ||
          !satListFollowUpViewData.listSatListFollowUpView
            ? 0
            : satListFollowUpViewData.listSatListFollowUpView.items
        }
        value={
          !satListFollowUpViewData ||
          !satListFollowUpViewData.listSatListFollowUpView
            ? undefined
            : satListFollowUpViewData.listSatListFollowUpView.data
        }
        // globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
      >
        {dynamicColumns}
      </Grid>
      {(pageLoading || satListFollowUpViewLoading) && <Loading />}
    </div>
  );
};
export default ControlFollowUp;
