import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .form-div-margin {
    margin-top: 32px;
  }

  .form-div {
    margin-top: 32px;
    display: flex;

    textarea {
      resize: vertical;
    }

    h1 + div,
    .formInput + .formInput,
    .form-dropdown + .formInput,
    .formInput + .form-dropdown,
    .formInput + .form-textarea-input {
      margin-top: 16px;
    }
    .form-dropdown {
      height: 46px;
      width: 100%;
    }
    .descriptions-div + .descriptions-div {
      margin-left: 20px;
    }
  }

  .generalInput {
    width: 263px;
  }

  .descriptions-div,
  .form-textarea-input {
    width: 540px;
  }
`;

export const Row = styled.div`
  display: flex;

  h1 {
    margin-bottom: 22px;
  }

  .generalInput + .generalInput {
    margin-left: 16px;
  }

  .form-checkbox {
    margin-left: 16px;
  }

  .form-checkbox + .form-checkbox {
    margin-top: 6px;
  }

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    width: 263px;
    padding: 4px;

    label + .p-radiobutton {
      margin-left: 12px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
  }

  && {
    margin-top: 16px;
  }
`;
