import { gql } from '@apollo/client';

export const processArtworkUpdateMissingInfoQuery = gql`
  mutation processArtworkProductInfoForAwUpdateMissingInfo(
    $data: ProcessArtworkProductInfoForAwUpdateMissingInfoInput!
  ) {
    processArtworkProductInfoForAwUpdateMissingInfo(data: $data) {
      idSatProcessResult
    }
  }
`;
