export const satLateralMenuItems = {
  generalInformation: {
    key: 'generalInformation',
    label: 'General Information',
  },
  financial: {
    key: 'financial',
    label: 'Financial',
  },
  suppliers: {
    key: 'suppliers',
    label: 'Suppliers',
  },
  artwork: {
    key: 'artwork',
    label: 'Artwork',
  },
  foreignTrade: {
    key: 'foreignTrade',
    label: 'Foreign Trade',
  },
  rnc: {
    key: 'rnc',
    label: 'RNC',
  },
  satItems: {
    key: 'satItems',
    label: 'SAT Items',
  },
  updates: {
    key: 'updates',
    label: 'Updates',
  },
  status: {
    key: 'status',
    label: 'Status',
  },
};
