import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 455px;
  display: flex;
  flex-direction: row;

  .searchAll {
    height: 36px;
    width: 100%;
    max-width: 387px;
    border-radius: 4px;

    [class*='indicator'] {
      display: none;
    }

    [class*='control'] {
      border-radius: 4px;
      min-height: 36px;
    }
  }
`;
