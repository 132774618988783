import styled, { css } from 'styled-components';

interface ContainerProps {
  hasError: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 46px;
  small {
    margin-top: 2px;
  }
`;

export const Container = styled.div<ContainerProps>`
  background: var(--grey);
  padding: 4px;
  height: 46px;

  border: solid 1px transparent;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.2s;
  * {
    box-shadow: none !important;
  }
  ${props =>
    props.readOnly &&
    css`
      background: none;
      opacity: unset !important;
      cursor: auto !important;
      * {
        opacity: unset !important;
      }
      .p-treeselect-trigger {
        display: none;
      }

      .p-treeselect-label {
        color: #000 !important;
      }
    `}
  ${props =>
    props.hasError &&
    css`
      border-color: #c53030;
    `}

  .p-treeselect {
    background: transparent;
    border: none;
    outline: none;
    color: #000;

    .p-treeselect-label-empty {
      visibility: visible !important;
    }

    &::placeholder {
      color: var(--grey-scale);
    }
    * {
      padding: 0;
    }
  }

  svg {
    margin-right: 4px;
  }

  span {
    display: flex;
  }

  p {
    color: var(--grey-scale);
    display: flex;

    ${props =>
      props.required &&
      css`
        &::after {
          margin-top: 4px;
          margin-left: 8px;
          width: 8px;
          height: 8px;
          background: var(--red);
          content: '';
          border-radius: 50%;
        }
      `}
  }
`;
