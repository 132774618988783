import styled from 'styled-components';

interface IContainerProps {
  ciInfoVisible: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: ${props => (props.ciInfoVisible ? '1700px' : '1300px')};
  margin-left: 84px;

  img {
    width: 90px;
    object-fit: scale-down;
    vertical-align: top;
  }

  .title-button-div {
    display: flex;
    gap: 12px;
  }
`;

export const ReferenceContent = styled.div`
  & + & {
    padding-top: 20px;
  }
`;

export const ReferenceStringData = styled.div`
  display: flex;
  gap: 12px;

  .form-element {
    width: 240px;
  }

  padding-bottom: 8px;
  border-bottom: 2px solid var(--grey-scale);
`;

export const AssortmentSizesLayout = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 40px;
  align-items: center;

  .formInput {
    width: 167px;
  }
`;

export const OneSizePerBoxLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 8px;
  align-items: center;
`;

export const OneSizePerBoxContent = styled.div`
  display: flex;
  gap: 12px;

  .size {
    width: 63px;
  }

  & + & {
    padding-top: 20px;
  }
`;

export const OneSizePerBoxBarcodes = styled.div`
  display: flex;
  flex-direction: column;
  width: 377px;
  gap: 5px;
  div {
    display: flex;
    .unit {
      width: 118px;
    }

    .barcode {
      width: 200px;
    }
  }
`;
