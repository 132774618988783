import { createContext, useCallback, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getDefaultProductPackageQuery } from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';

type ProductPackage = {
  idProductPackage: number;
  packagePcQuantity?: number;
  isDefault?: boolean;
  packageUnit?: number;
};

interface IDefaultProductPackageContext {
  getDefaultProductPackage: (
    idProduct: number,
  ) => Promise<ProductPackage | undefined>;
  setDefaultProductPackage: (packageSent: ProductPackage) => void;
}

const DefaultProductPackageContext =
  createContext<IDefaultProductPackageContext>(
    {} as IDefaultProductPackageContext,
  );

const DefaultProductPackageProvider: React.FC = ({ children }) => {
  const [productPackage, setProductPackage] = useState<ProductPackage>();

  const { showError } = useRefHook();

  const [loadDefaultPackage] = useLazyQuery(getDefaultProductPackageQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting default product package',
        detail: errorData.message,
      });
    },
  });

  const getDefaultProductPackage = useCallback(
    async (idProduct: number) => {
      if (productPackage) return productPackage;

      const response = await loadDefaultPackage({
        variables: {
          idProduct,
        },
      });

      if (response.data) {
        setProductPackage(response.data.listDefaultProductPackageByIdProduct);
      }

      return undefined;
    },
    [loadDefaultPackage, productPackage],
  );

  const setDefaultProductPackage = useCallback(
    (packageSent: ProductPackage) => {
      // Nao permite sobrescrever o valor de product package ja existente
      if (productPackage) return;

      if (!packageSent.isDefault) {
        throw new Error(
          'This product package has the property isDefault as false',
        );
      }

      setProductPackage(packageSent);
    },
    [productPackage],
  );

  return (
    <DefaultProductPackageContext.Provider
      value={{
        getDefaultProductPackage,
        setDefaultProductPackage,
      }}
    >
      {children}
    </DefaultProductPackageContext.Provider>
  );
};

function useDefaultProductPackage(): IDefaultProductPackageContext {
  const context = useContext(DefaultProductPackageContext);

  if (!context) {
    throw new Error(
      'useDefaultProductPackage must be used within an DefaultProductPackageProvider',
    );
  }

  // retorna contexto
  return context;
}

export { DefaultProductPackageProvider, useDefaultProductPackage };
