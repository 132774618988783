import { gql } from '@apollo/client';

// Query para buscar arquivo template
export const listAllUserGroupsQuery = gql`
  query ListAllUserGroups($data: ListAllUserGroupsInput!) {
    listAllUserGroups(data: $data) {
      data {
        idUserGroup
        name
      }
    }
  }
`;
