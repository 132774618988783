import { gql } from '@apollo/client';

export const createRecurringPaymentQuery = gql`
  mutation CreateFinancialRecurringPayment(
    $data: CreateFinancialRecurringPaymentInput!
  ) {
    createFinancialRecurringPayment(data: $data) {
      idFinancialTransaction
      idFinancialTransactionGroup
    }
  }
`;
