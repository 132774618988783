import { gql } from '@apollo/client';

export const ListProcessAndSatProcessesBySatForeignTradeIdQuery = gql`
  query ListProcessAndSatProcessesBySatForeignTradeId(
    $idSatForeignTrade: Int!
  ) {
    listProcessAndSatProcessesBySatForeignTradeId(
      idSatForeignTrade: $idSatForeignTrade
    ) {
      idProcess
      name
      order
      satProcesses {
        idSatProcess
        idSat2 {
          idSat
          idClient2 {
            idClient
            name
          }
        }
        idSatForeignTrade
        idSatForeignTradeSupplier
        idSatForeignTradeSupplier2 {
          idSupplier2 {
            sunNumber
          }
        }
        idSatRnc
        idSatRnc2 {
          rncNumber
        }
        satProcessResults {
          idSatProcessResult
          description
          done
          idStepStatus2 {
            idDomain
            description
            statusLayout {
              idStatusLayout
              idLayout
              idGroupOptions
            }
          }
          idProcessStep2 {
            idProcessStep
            name
            order
          }
        }
      }
    }
  }
`;
