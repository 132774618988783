import styled, { css } from 'styled-components';

interface ContainerProps {
  justifyContent?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 16px;
  align-items: center;

  && {
    margin-top: 10px;
  }

  textarea {
    resize: vertical;
  }

  .form-element {
    width: 100%;
  }

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;
