import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  portTaxName: {
    field: 'portTaxName',
    header: 'Name',
    advancedFilterField: 'portTaxName',
  },
  portDestination: {
    field: 'idPortDestination2.name',
    header: 'Port Destination',
    advancedFilterField: 'portDestination',
  },
  taxValueArm: {
    field: 'taxValueArm',
    header: 'Armaz Porto (10 dias)',
    advancedFilterField: 'taxValueArm',
  },
  idCurArm: {
    field: 'idCurArm2.abbreviation',
    header: 'Currency Arm',
    advancedFilterField: 'idCurArm',
  },
  idBcValueArm: {
    field: 'idBcValueArm2.description',
    header: 'BC Value Arm',
    advancedFilterField: 'idBcValueArm',
  },
  bcCifArm: {
    field: 'bcCifArm',
    header: 'BC CIF Arm',
    advancedFilterField: 'bcCifArm',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtArm: {
    field: 'updatedAtArm',
    header: 'Last Updated Arm',
    advancedFilterField: '',
  },
  taxValueLev: {
    field: 'taxValueLev',
    header: 'Levante / CTNR',
    advancedFilterField: 'taxValueLev',
  },
  idCurLev: {
    field: 'idCurLev2.abbreviation',
    header: 'Currency Lev',
    advancedFilterField: 'idCurLev',
  },
  idBcValueLev: {
    field: 'idBcValueLev2.description',
    header: 'BC Value Lev',
    advancedFilterField: 'idBcValueLev',
  },
  bcCifLev: {
    field: 'bcCifLev',
    header: 'BC CIF Lev',
    advancedFilterField: 'bcCifLev',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtLev: {
    field: 'updatedAtLev',
    header: 'Last Updated Lev',
    advancedFilterField: '',
  },
  taxValuePes: {
    field: 'taxValuePes',
    header: 'Pesagem / CTNR',
    advancedFilterField: 'taxValuePes',
  },
  idCurPes: {
    field: 'idCurPes2.abbreviation',
    header: 'Currency Pes',
    advancedFilterField: 'idCurPes',
  },
  idBcValuePes: {
    field: 'idBcValuePes2.description',
    header: 'BC Value Pes',
    advancedFilterField: 'idBcValuePes',
  },
  bcCifPes: {
    field: 'bcCifPes',
    header: 'BC CIF Pes',
    advancedFilterField: 'bcCifPes',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtPes: {
    field: 'updatedAtPes',
    header: 'Last Updated Pes',
    advancedFilterField: '',
  },
  taxValueSca: {
    field: 'taxValueSca',
    header: 'Scanner / CTNR',
    advancedFilterField: 'taxValueSca',
  },
  idCurSca: {
    field: 'idCurSca2.abbreviation',
    header: 'Currency Sca',
    advancedFilterField: 'idCurSca',
  },
  idBcValueSca: {
    field: 'idBcValueSca2.description',
    header: 'BC Value Sca',
    advancedFilterField: 'idBcValueSca',
  },
  bcCifSca: {
    field: 'bcCifSca',
    header: 'BC CIF Sca',
    advancedFilterField: 'bcCifSca',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtSca: {
    field: 'updatedAtSca',
    header: 'Last Updated Sca',
    advancedFilterField: '',
  },
  taxValuePos: {
    field: 'taxValuePos',
    header: 'Posicionamento / CTNR',
    advancedFilterField: 'taxValuePos',
  },
  idCurPos: {
    field: 'idCurPos2.abbreviation',
    header: 'Currency Pos',
    advancedFilterField: 'idCurPos',
  },
  idBcValuePos: {
    field: 'idBcValuePos2.description',
    header: 'BC Value Pos',
    advancedFilterField: 'idBcValuePos',
  },
  bcCifPos: {
    field: 'bcCifPos',
    header: 'BC CIF Pos',
    advancedFilterField: 'bcCifPos',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtPos: {
    field: 'updatedAtPos',
    header: 'Last Updated Pos',
    advancedFilterField: '',
  },
};
