import { AdvancedFiltersFieldType } from '../../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  satNumber: {
    field: 'idSat2.satNumber',
    header: 'SAT Number',
    advancedFilterField: 'satNumber',
  },
  companyName: {
    field: 'idSat2.companyName',
    header: 'Client',
    advancedFilterField: 'companyName',
  },
  clientCnpj: {
    field: 'idSat2.clientCnpj',
    header: 'Tax ID/CNPJ',
    advancedFilterField: 'clientCnpj',
  },
  importer: {
    field: 'idSat2.idImporter2.name',
    header: 'Importer',
    advancedFilterField: 'importer',
  },
  importerCnpj: {
    field: 'idSat2.importerCnpj',
    header: 'Tax ID',
    advancedFilterField: 'importerCnpj',
  },
  idSales: {
    field: 'idSat2.idSalesUser2.username',
    header: 'Sales',
    advancedFilterField: 'username',
  },
  port: {
    field: 'idSat2.idPort2.name',
    header: 'Port of Discharge',
    advancedFilterField: 'port',
  },
  paymentTermAdvance: {
    field: 'idSat2.paymentTermAdvance',
    header: 'Payment Term Advance',
    advancedFilterField: 'paymentTermAdvance',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  paymentTermBalance: {
    field: 'idSat2.paymentTermBalance',
    header: 'Payment Term Balance',
    advancedFilterField: 'paymentTermBalance',
    type: AdvancedFiltersFieldType.NUMBER,
  },
};
