import { Dialog } from 'primereact/dialog';
import FileUpload from '../../../../../../../../components/FileUpload';
import { FileType } from '../../../../../../../../shared/enums/fileType';
import { IUploadedFile } from '../interfaces';
import generateRandomString from '../../../../../../../../utils/generateRandomString';
import { FileUploadResponse } from '../../../../../../../../components/FileUpload/interfaces';

interface IFilesUploadProps {
  isVisibleFilesUploadModal: boolean;
  setIsVisibleFilesUploadModal(e: boolean): void;
  uploadedFiles: IUploadedFile[];
  setUploadedFiles(e: IUploadedFile[]): void;
}

const FilesUpload: React.FC<IFilesUploadProps> = ({
  isVisibleFilesUploadModal,
  setIsVisibleFilesUploadModal,
  uploadedFiles,
  setUploadedFiles,
}) => {
  const onHide = () => {
    setIsVisibleFilesUploadModal(false);
  };

  function handleUploadConfirm(data: FileUploadResponse[]) {
    const filesObject = data.map(file => {
      return {
        key: generateRandomString(4),
        nameGivenByUser: file.originalName ?? file.serverName,
        serverName: file.serverName,
        isSelected: false,
      };
    });

    setUploadedFiles([...uploadedFiles, ...filesObject]);

    onHide();
  }

  return (
    <Dialog
      header="Upload Financial Files"
      visible={isVisibleFilesUploadModal}
      style={{ width: '40vw' }}
      onHide={() => onHide()}
      closable
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <FileUpload
        multiple
        accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX}`}
        onConfirm={e => handleUploadConfirm(e)}
      />
    </Dialog>
  );
};

export default FilesUpload;
