import React, { useEffect, useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Container, CrudForm } from './styles';
import PageHeader from '../../components/PageHeader';
import GeneralInformation from './GeneralInformation';
import MainButton from '../../components/MainButton';
import Button from '../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import { useRefHook } from '../../hooks/useRefHook';
import Loading from '../../components/Loading';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

export interface IListRoleResponse {
  idRole: number;
  name: string;
  active: boolean;
}

// Parametros da rota de role
interface RoleRouteParams {
  roleId: string;
}

const Role: React.FC = () => {
  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // Nome da role
  const [roleName, setRoleName] = useState();

  // URL
  const { pathname } = useLocation();

  // ID da role
  const { roleId } = useParams<RoleRouteParams>();

  const defaultPageTitle = `${roleId ? 'Manage' : 'Create'} Role`;

  useTitle(roleName ? `${roleName} - ${defaultPageTitle}` : defaultPageTitle);

  // Dados da role
  const [role, setRole] = useState<IListRoleResponse>();

  // Itens do breadcrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'List of Roles',
      path: '/roles',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Mutation de criacao de role
  const createRoleQuery = gql`
    mutation CreateRoleMutation($createRoleInput: CreateRoleInput) {
      createRole(createRoleInput: $createRoleInput)
    }
  `;

  // Mutation de update de role
  const updateRoleQuery = gql`
    mutation UpdateRoleMutation($updateRoleInput: UpdateRoleInput) {
      updateRole(role: $updateRoleInput)
    }
  `;

  // cria método para chamar a mutation
  const [createRoleMutation] = useMutation(createRoleQuery);
  const [updateRoleMutation] = useMutation(updateRoleQuery);

  // Estado de version lock
  const [versionLock, setVersionLock] = useState<number>();

  const listRoleQuery = gql`
    query listRole($id: Int!) {
      listRole(id: $id) {
        idRole
        name
        active
        versionLock
      }
    }
  `;

  // Carrega dados da role
  const [loadRoleData, { loading: roleLoading }] = useLazyQuery(listRoleQuery, {
    variables: {
      id: parseInt(roleId, 10),
    },
    onCompleted: response => {
      setRole(response.listRole);
      setRoleName(response.listRole.name);
      setVersionLock(response.listRole.versionLock);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting role data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Salva role
   * @returns
   */
  async function handleSaveRole() {
    const generalInformation = formRef.current?.getData();
    formRef.current?.submitForm();
    if (generalInformation?.name.trim() === '') {
      return;
    }

    try {
      setLoading(true);

      // Valida se e criacao ou atualizacao de role
      if (roleId) {
        await updateRoleMutation({
          variables: {
            updateRoleInput: {
              idRole: parseInt(roleId, 10),
              name: generalInformation?.name,
              active: generalInformation?.active,
              versionLock,
            },
          },
        });
      } else {
        await createRoleMutation({
          variables: {
            createRoleInput: {
              name: generalInformation?.name,
              active: generalInformation?.active,
            },
          },
        });
      }

      // Em caso de sucesso exibe toast
      toastRef.current?.show({
        severity: 'success',
        summary: `Role ${roleId ? 'updated' : 'created'}`,
        life: ToastLife.SUCCESS,
      });

      // Direciona usuario para listagem de roles
      history.push('/roles');
    } catch (error) {
      if (error instanceof Error) {
        toastRef.current?.show({
          severity: 'error',
          summary: `Error while ${roleId ? 'updating' : 'creating'} role`,
          detail: error.message,
          life: ToastLife.ERROR,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/roles'),
    });
  }

  // Ao carregar a pagina, carrega dados da role
  useEffect(() => {
    if (roleId) {
      loadRoleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRoleData, roleId]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          roleName ? `${defaultPageTitle} - ${roleName}` : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            handleSaveRole();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      {((roleId && !roleLoading && role) || !roleId) && (
        <CrudForm>
          <GeneralInformation selected role={role} />
        </CrudForm>
      )}
      {(loading || roleLoading) && <Loading />}
    </Container>
  );
};
export default Role;
