import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { asyncSelectLoadProductsStCodes } from '../../../shared/querys/product';

interface IColumnData {
  [key: string]: ColumnData;
}

const booleanFilterOptions = [
  { label: 'YES', value: 'Yes' },
  { label: 'NO', value: 'No' },
];

const booleanNAFilterOptions = [
  ...booleanFilterOptions,
  { label: 'N/A', value: 'N/A' },
];

const statusFilterOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

export const gridColumnsData: IColumnData = {
  status: {
    field: 'status',
    header: 'Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: statusFilterOptions,
  },
  outdated: {
    field: 'outdated',
    header: 'Outdated',
    advancedFilterField: 'outdated',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  taxmissing: {
    field: 'taxmissing',
    header: 'Tax Missing',
    advancedFilterField: 'taxmissing',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  verified: {
    field: 'verified',
    header: 'Verified',
    advancedFilterField: 'verified',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  imageUrl: {
    field: 'imageUrl',
    header: 'Image',
    advancedFilterField: '',
  },
  stCode: {
    field: 'stCode',
    header: 'ST Code',
    advancedFilterField: 'idProduct',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'stCode',
      optionValue: 'idProduct',
      optionData: asyncSelectLoadProductsStCodes,
      isMulti: true,
    },
  },
  sunNumber: {
    field: 'sunNumber',
    header: 'SUN',
    advancedFilterField: 'sunNumber',
  },
  model: {
    field: 'model',
    header: 'Model',
    advancedFilterField: 'model',
  },
  nameEn: {
    field: 'nameEn',
    header: 'Name (EN)',
    advancedFilterField: 'nameEn',
  },
  descriptionEn: {
    field: 'descriptionEn',
    header: 'Description (EN)',
    advancedFilterField: 'descriptionEn',
  },
  materialEn: {
    field: 'materialEn',
    header: 'Material (EN)',
    advancedFilterField: 'materialEn',
  },
  colourEn: {
    field: 'colourEn',
    header: 'Colour (EN)',
    advancedFilterField: 'colourEn',
  },
  unit: {
    field: 'unit',
    header: 'Unit',
    advancedFilterField: 'unit',
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
  hsCode: {
    field: 'hsCode',
    header: 'HS Code',
    advancedFilterField: 'hsCode',
  },
  purchaseCurrency: {
    field: 'purchaseCurrency',
    header: 'Purchase Currency',
    advancedFilterField: 'purchaseCurrency',
  },
  cotPrice: {
    field: 'cotPrice',
    header: 'Cot Price',
    advancedFilterField: 'cotPrice',
  },
  salesCurrency: {
    field: 'salesCurrency',
    header: 'Sales Currency',
    advancedFilterField: 'salesCurrency',
  },
  salesIncoterm: {
    field: 'salesIncoterm',
    header: 'Sales Incorterm',
    advancedFilterField: 'salesIncoterm',
  },
  sunPrice: {
    field: 'sunPrice',
    header: 'SUN Price',
    advancedFilterField: 'sunPrice',
  },
  markup: {
    field: 'markup',
    header: 'Markup',
    advancedFilterField: 'markup',
  },
  importLicense: {
    field: 'importLicense',
    header: 'License',
    advancedFilterField: 'importLicense',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanFilterOptions,
  },
  namePt: {
    field: 'namePt',
    header: 'Name (PT)',
    advancedFilterField: 'namePt',
  },
  colourPt: {
    field: 'colourPt',
    header: 'Colour (PT)',
    advancedFilterField: 'colourPt',
  },
  licensedProduct: {
    field: 'licensedProduct',
    header: 'Licensed Product',
    advancedFilterField: 'licensedProduct',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanFilterOptions,
  },
  stock: {
    field: 'stock',
    header: 'Stock',
    advancedFilterField: 'stock',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanFilterOptions,
  },
  exclusivity: {
    field: 'exclusivity',
    header: 'Exclusivity',
    advancedFilterField: 'exclusivity',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanFilterOptions,
  },
  sku: {
    field: 'sku',
    header: 'SKU',
    advancedFilterField: 'sku',
  },
  detailedDescriptionPt: {
    field: 'detailedDescriptionPt',
    header: 'Detailed Description (PT)',
    advancedFilterField: 'detailedDescriptionPt',
  },
  materialPt: {
    field: 'materialPt',
    header: 'Material (PT)',
    advancedFilterField: 'materialPt',
  },
  descriptionPt: {
    field: 'descriptionPt',
    header: 'Description (PT)',
    advancedFilterField: 'descriptionPt',
  },
  finishing: {
    field: 'finishing',
    header: 'Finishing',
    advancedFilterField: 'finishing',
  },
  extraInfo: {
    field: 'extraInfo',
    header: 'Extra Info',
    advancedFilterField: 'extraInfo',
  },
  dtAging: {
    field: 'dtAging',
    header: 'Aging',
    advancedFilterField: 'dtAging',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  profit: {
    field: 'profit',
    header: 'Profit',
    advancedFilterField: '',
  },
  clientsBrand: {
    field: 'clientsBrand',
    header: 'Clients Brand',
    advancedFilterField: '',
  },
  brands: {
    field: 'brands',
    header: 'Brands',
    advancedFilterField: 'brands',
  },
  ncmClient: {
    field: 'ncmClient',
    header: 'NCM Client',
    advancedFilterField: 'ncmClient',
  },
  dumping: {
    field: 'dumping',
    header: 'Dumping',
    advancedFilterField: 'dumping',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanNAFilterOptions,
  },
  li: {
    field: 'li',
    header: 'LI',
    advancedFilterField: 'li',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanNAFilterOptions,
  },
  tradeReview: {
    field: 'tradeReview',
    header: 'Trade Review',
    advancedFilterField: 'tradeReview',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanNAFilterOptions,
  },
  ncm: {
    field: 'ncm',
    header: 'NCM',
    advancedFilterField: 'ncm',
  },
  ncmName: {
    field: 'ncmName',
    header: 'NCM Name',
    advancedFilterField: 'ncmName',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: booleanNAFilterOptions,
  },
  precoMinimo: {
    field: 'precoMinimo',
    header: 'Preço Minimo',
    advancedFilterField: 'precoMinimo',
  },
  reference: {
    field: 'reference',
    header: 'Reference',
    advancedFilterField: 'reference',
  },
  sellingPackageDescription: {
    field: 'sellingPackageDescription',
    header: 'Selling Package Description',
    advancedFilterField: 'sellingPackageDescription',
  },
  responsible: {
    field: 'responsible',
    header: 'Responsible',
    advancedFilterField: 'responsible',
  },
  typeOfPackage: {
    field: 'typeOfPackage',
    header: 'Type Of Package (EN)',
    advancedFilterField: 'typeOfPackage',
  },
  packageEan13: {
    field: 'packageEan13',
    header: 'Selling Package (EAN13)',
    advancedFilterField: 'packageEan13',
  },
  innerDun14: {
    field: 'innerDun14',
    header: 'Inner Box (DUN14)',
    advancedFilterField: 'innerDun14',
  },
  masterDun14: {
    field: 'masterDun14',
    header: 'Master Box (DUN14)',
    advancedFilterField: 'masterDun14',
  },
};
