import { ColumnData } from '../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  ptnNumber: {
    field: 'ptnNumber',
    header: 'PTN Number',
    advancedFilterField: 'ptnNumber',
  },
  core: { field: 'coresString', header: 'Core', advancedFilterField: 'core' },
  name: { field: 'name', header: 'Name', advancedFilterField: 'name' },
  country: {
    field: 'idCountry2.name',
    header: 'Country',
    advancedFilterField: 'country',
  },
  state: {
    field: 'idRegion2.name',
    header: 'State',
    advancedFilterField: 'state',
  },
  city: { field: 'idCity2.name', header: 'City', advancedFilterField: 'city' },
};
