import { SatForeignTradeSupplier } from '../interfaces';
import {
  getEstimatedNetMargin,
  getEstimatedNetProfit,
  getNetMargin,
  getNetProfit,
} from '../utils';
import {
  ForeignTradeSupplierContentReducer,
  ForeignTradeSupplierContentReducerAction,
  ForeignTradeSupplierContentReducerActionKind,
  PurchaseOtherExpensesTotalReducerObject,
  SellerOtherExpensesTotalReducerObject,
} from './interfaces';

/**
 * Chama funcoes que buscam NET Values
 * @param sellerOtherExpensesTotal Objeto de Total de Seller Other Expenses
 * @param purchaseOtherExpensesTotal Objeto de Total de Purchase Other Expenses
 * @param financialTotal Objeto de Total de Financial
 * @returns NET Values
 */
function getNetValues(
  sellerOtherExpensesTotal: SellerOtherExpensesTotalReducerObject,
  purchaseOtherExpensesTotal: PurchaseOtherExpensesTotalReducerObject,
  satForeignTradeSupplier: SatForeignTradeSupplier,
) {
  const estimatedNetMargin = getEstimatedNetMargin(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    satForeignTradeSupplier.totalCotPiShipment,
    satForeignTradeSupplier.totalSunPiShipment,
  );

  const netMargin = getNetMargin(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    satForeignTradeSupplier.totalSunCiShipment,
    satForeignTradeSupplier.totalCotCiShipment,
  );

  const estimatedNetProfit = getEstimatedNetProfit(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    satForeignTradeSupplier.totalCotPiShipment,
    satForeignTradeSupplier.totalSunPiShipment,
  );

  const netProfit = getNetProfit(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    satForeignTradeSupplier.totalSunCiShipment,
    satForeignTradeSupplier.totalCotCiShipment,
  );

  return { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit };
}

export const foreignTradeSupplierContentReducer = (
  state: ForeignTradeSupplierContentReducer,
  action: ForeignTradeSupplierContentReducerAction,
): ForeignTradeSupplierContentReducer => {
  switch (action.type) {
    case ForeignTradeSupplierContentReducerActionKind.SET_PURCHASE_OTHER_EXPENSES_TOTAL:
      if (!action.payload) return state;

      {
        const { satForeignTradeSupplier, purchaseOtherExpensesTotal } =
          action.payload;

        if (purchaseOtherExpensesTotal) {
          if (!satForeignTradeSupplier) {
            throw new Error(
              'You need to inform the SAT Foreign Trade Supplier being modified',
            );
          }

          const { sellerOtherExpensesTotal } = state;
          let { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit } =
            state;

          if (sellerOtherExpensesTotal) {
            const netValues = getNetValues(
              sellerOtherExpensesTotal,
              purchaseOtherExpensesTotal,
              satForeignTradeSupplier,
            );

            estimatedNetMargin = netValues.estimatedNetMargin;
            netMargin = netValues.netMargin;
            estimatedNetProfit = netValues.estimatedNetProfit;
            netProfit = netValues.netProfit;
          }

          return {
            ...state,
            estimatedNetMargin,
            netMargin,
            estimatedNetProfit,
            netProfit,
            purchaseOtherExpensesTotal:
              action.payload.purchaseOtherExpensesTotal,
            sellerOtherExpensesTotalLoading: false,
            purchaseOtherExpensesTotalLoading: false,
          };
        }

        return {
          ...state,
          purchaseOtherExpensesTotal: undefined,
          totalWithApportionment: undefined,
          estimatedNetMargin: undefined,
          netMargin: undefined,
          estimatedNetProfit: undefined,
          netProfit: undefined,
          sellerOtherExpensesTotalLoading: false,
          purchaseOtherExpensesTotalLoading: false,
        };
      }

    case ForeignTradeSupplierContentReducerActionKind.SET_SELLER_OTHER_EXPENSES_TOTAL:
      if (!action.payload) return state;

      {
        const { satForeignTradeSupplier, sellerOtherExpensesTotal } =
          action.payload;

        if (sellerOtherExpensesTotal) {
          if (!satForeignTradeSupplier) {
            throw new Error(
              'You need to inform the SAT Foreign Trade Supplier being modified',
            );
          }

          const totalWithApportionment =
            (satForeignTradeSupplier.totalSunCiShipment ?? 0) +
            sellerOtherExpensesTotal.totalForApportionment;

          const { purchaseOtherExpensesTotal } = state;
          let { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit } =
            state;

          if (purchaseOtherExpensesTotal) {
            const netValues = getNetValues(
              sellerOtherExpensesTotal,
              purchaseOtherExpensesTotal,
              satForeignTradeSupplier,
            );

            estimatedNetMargin = netValues.estimatedNetMargin;
            netMargin = netValues.netMargin;
            estimatedNetProfit = netValues.estimatedNetProfit;
            netProfit = netValues.netProfit;
          }

          return {
            ...state,
            estimatedNetMargin,
            netMargin,
            estimatedNetProfit,
            netProfit,
            sellerOtherExpensesTotal: action.payload?.sellerOtherExpensesTotal,
            totalWithApportionment,
            sellerOtherExpensesTotalLoading: false,
            purchaseOtherExpensesTotalLoading: false,
          };
        }

        return {
          ...state,
          sellerOtherExpensesTotal: undefined,
          totalWithApportionment: undefined,
          estimatedNetMargin: undefined,
          netMargin: undefined,
          estimatedNetProfit: undefined,
          netProfit: undefined,
          sellerOtherExpensesTotalLoading: false,
          purchaseOtherExpensesTotalLoading: false,
        };
      }

    case ForeignTradeSupplierContentReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING:
      return {
        ...state,
        sellerOtherExpensesTotalLoading: !state.sellerOtherExpensesTotalLoading,
      };

    case ForeignTradeSupplierContentReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING:
      return {
        ...state,
        purchaseOtherExpensesTotalLoading:
          !state.purchaseOtherExpensesTotalLoading,
      };

    case ForeignTradeSupplierContentReducerActionKind.SET_PURCHASE_ADVANCE_PAID_TOTAL:
      if (!action.payload) return state;

      {
        const { satForeignTradeSupplier, purchaseAdvancePaidTotal } =
          action.payload;

        if (!satForeignTradeSupplier) {
          throw new Error(
            'You need to inform the SAT Foreign Trade Supplier being used',
          );
        }

        return {
          ...state,
          purchaseAdvancePaidTotal,
        };
      }

    case ForeignTradeSupplierContentReducerActionKind.SET_SELLER_ADVANCE_RECEIVED_TOTAL:
      if (!action.payload) return state;

      {
        const { satForeignTradeSupplier, sellerAdvanceReceivedTotal } =
          action.payload;

        if (!satForeignTradeSupplier) {
          throw new Error(
            'You need to inform the SAT Foreign Trade Supplier being used',
          );
        }

        return {
          ...state,
          sellerAdvanceReceivedTotal,
        };
      }

    default:
      throw new Error();
  }
};
