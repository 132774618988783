import dayjs from 'dayjs';
import { Tooltip } from 'primereact/tooltip';
import React, { Ref, useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import generateRandomString from '../../utils/generateRandomString';
import isNullOrUndefined from '../../utils/isNullOrUndefined';

import { Container } from './styles';

export interface IReadOnlyInputRef {
  setValue: (value: string) => void;
}

interface IReadOnlyInputProps {
  ref?: Ref<IReadOnlyInputRef>;
  label: string;
  className?: string;
  value?: string | number;
  type?: 'text' | 'date' | 'number';
  locale?: string;
  prefix?: string;
  suffix?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  tooltip?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  loading?: boolean;
  severity?: 'danger';
  allowOverflow?: boolean;
}

const ReadOnlyInput: React.FC<IReadOnlyInputProps> = ({
  label,
  className,
  value,
  type,
  prefix,
  suffix,
  locale,
  maximumFractionDigits,
  minimumFractionDigits,
  tooltip,
  tooltipPosition,
  loading,
  severity,
  allowOverflow,
}) => {
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  function parseValue() {
    if (isNullOrUndefined(valueState)) return '';

    const prefixValue = prefix ?? '';
    const suffixValue = suffix ?? '';

    let valueParsed = valueState;

    switch (type) {
      case 'date':
        valueParsed = dayjs(valueState).toDate().toLocaleDateString(locale);
        break;
      case 'number':
        valueParsed = valueState?.toLocaleString(locale, {
          maximumFractionDigits,
          minimumFractionDigits,
        });
        break;
      default:
        break;
    }

    return `${prefixValue} ${valueParsed}${suffixValue}`;
  }

  const tooltipClass = `s-readonly-input-tooltip-${generateRandomString(4)}`;

  return (
    <>
      <Tooltip target={`.${tooltipClass}`} mouseTrack />
      <div
        className={`s-readonly-input ${tooltipClass} ${className ?? ''}`}
        data-pr-tooltip={tooltip}
        data-pr-position={tooltipPosition ?? 'right'}
      >
        {!loading ? (
          <Container severity={severity} allowOverflow={allowOverflow}>
            <span>
              <p>{label}:</p>
            </span>
            <div className="s-readonly-input-content">{parseValue()}</div>
          </Container>
        ) : (
          <Container>
            <Skeleton height="100%" />
          </Container>
        )}
      </div>
    </>
  );
};

export default ReadOnlyInput;
