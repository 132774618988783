import axios from 'axios';
import localStorageKeys from '../../../config/localStorageKeys';
import { getAccessTokenPromise } from '../../apollo/authLink';

const satApi = axios.create({
  baseURL: `${process.env.REACT_APP_SAT_API_URL}/`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(localStorageKeys.token)}`,
  },
});

satApi.interceptors.request.use(
  async config => {
    const accessToken = await getAccessTokenPromise();

    const newConfig = config;

    newConfig.headers.Authorization = `Bearer ${accessToken}`;

    return newConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

export default satApi;
