import { subtract, sum } from '../../../../../utils/mathUtil';
import {
  getEstimatedNetMargin,
  getEstimatedNetProfit,
  getNetMargin,
  getNetProfit,
} from '../utils';
import {
  FinancialTotal,
  ForeignTradeSupplierTotalReducer,
  ForeignTradeSupplierTotalReducerAction,
  ForeignTradeSupplierTotalReducerActionKind,
  TotalPurchaseOtherExpensesTotal,
  TotalSellerOtherExpensesTotal,
} from './interfaces';

/**
 * Chama funcoes que buscam NET Values
 * @param sellerOtherExpensesTotal Objeto de Total de Seller Other Expenses
 * @param purchaseOtherExpensesTotal Objeto de Total de Purchase Other Expenses
 * @param financialTotal Objeto de Total de Financial
 * @returns NET Values
 */
function getNetValues(
  sellerOtherExpensesTotal: TotalSellerOtherExpensesTotal,
  purchaseOtherExpensesTotal: TotalPurchaseOtherExpensesTotal,
  financialTotal: FinancialTotal,
) {
  const estimatedNetMargin = getEstimatedNetMargin(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    financialTotal.totalCotPiShipment,
    financialTotal.totalSunPiShipment,
  );

  const netMargin = getNetMargin(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    financialTotal.totalSunCiShipment,
    financialTotal.totalCotCiShipment,
  );

  const estimatedNetProfit = getEstimatedNetProfit(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    financialTotal.totalCotPiShipment,
    financialTotal.totalSunPiShipment,
  );

  const netProfit = getNetProfit(
    sellerOtherExpensesTotal.totalOnMargin,
    purchaseOtherExpensesTotal.totalOnMargin,
    financialTotal.totalSunCiShipment,
    financialTotal.totalCotCiShipment,
  );

  return { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit };
}

export const foreignTradeSupplierTotalReducer = (
  state: ForeignTradeSupplierTotalReducer,
  action: ForeignTradeSupplierTotalReducerAction,
): ForeignTradeSupplierTotalReducer => {
  switch (action.type) {
    case ForeignTradeSupplierTotalReducerActionKind.SET_FINANCIAL_TOTAL:
      if (!action.payload) return state;

      {
        const { financialTotal } = action.payload;
        const { purchaseOtherExpensesTotal, sellerOtherExpensesTotal } = state;
        let { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit } =
          state;

        if (financialTotal) {
          if (purchaseOtherExpensesTotal && sellerOtherExpensesTotal) {
            const netValues = getNetValues(
              sellerOtherExpensesTotal,
              purchaseOtherExpensesTotal,
              financialTotal,
            );

            estimatedNetMargin = netValues.estimatedNetMargin;
            netMargin = netValues.netMargin;
            estimatedNetProfit = netValues.estimatedNetProfit;
            netProfit = netValues.netProfit;
          }

          return {
            ...state,
            financialTotal,
            financialTotalLoading: false,
            purchaseTotalRemainCot: subtract(
              financialTotal.totalCotPiShipment,
              state.purchaseTotalPaid,
            ),
            sellerTotalWithApportionment: sum(
              financialTotal.totalSunCiShipment,
              sellerOtherExpensesTotal?.totalForApportionment,
            ),
            sellerTotalRemainSun: subtract(
              financialTotal.totalSunCiShipment,
              state.sellerTotalReceived,
            ),
            estimatedNetMargin,
            netMargin,
            estimatedNetProfit,
            netProfit,
          };
        }
      }

      return {
        ...state,
        financialTotal: undefined,
        financialTotalLoading: false,
        purchaseTotalRemainCot: undefined,
        sellerTotalWithApportionment: undefined,
        sellerTotalRemainSun: undefined,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_PURCHASE_ADVANCE_PAID_TOTAL:
      if (!action.payload) return state;

      {
        const purchaseTotalPaid = sum(
          action.payload.purchaseAdvancePaidTotal?.totalAmount,
          state.purchaseBalancePaidTotal?.totalAmount,
        );

        return {
          ...state,
          purchaseAdvancePaidTotal: action.payload.purchaseAdvancePaidTotal,
          purchaseAdvancePaidTotalLoading: false,
          purchaseTotalPaid,
          purchaseTotalRemainCot: subtract(
            state.financialTotal?.totalCotPiShipment,
            state.purchaseTotalPaid,
          ),
        };
      }

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_ADVANCE_PAID_TOTAL_LOADING:
      return {
        ...state,
        purchaseAdvancePaidTotalLoading: !state.purchaseAdvancePaidTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_PURCHASE_BALANCE_PAID_TOTAL:
      if (!action.payload) return state;

      {
        const purchaseTotalPaid = sum(
          action.payload.purchaseBalancePaidTotal?.totalAmount,
          state.purchaseAdvancePaidTotal?.totalAmount,
        );

        return {
          ...state,
          purchaseBalancePaidTotal: action.payload.purchaseBalancePaidTotal,
          purchaseBalancePaidTotalLoading: false,
          purchaseTotalPaid,
          purchaseTotalRemainCot: subtract(
            state.financialTotal?.totalCotPiShipment,
            state.purchaseTotalPaid,
          ),
        };
      }

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_BALANCE_PAID_TOTAL_LOADING:
      return {
        ...state,
        purchaseBalancePaidTotalLoading: !state.purchaseBalancePaidTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_PURCHASE_OTHER_EXPENSES_TOTAL:
      if (!action.payload) return state;

      {
        const { purchaseOtherExpensesTotal } = action.payload;
        const { financialTotal, sellerOtherExpensesTotal } = state;
        let { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit } =
          state;

        if (purchaseOtherExpensesTotal) {
          if (sellerOtherExpensesTotal && financialTotal) {
            const netValues = getNetValues(
              sellerOtherExpensesTotal,
              purchaseOtherExpensesTotal,
              financialTotal,
            );

            estimatedNetMargin = netValues.estimatedNetMargin;
            netMargin = netValues.netMargin;
            estimatedNetProfit = netValues.estimatedNetProfit;
            netProfit = netValues.netProfit;
          }

          return {
            ...state,
            purchaseOtherExpensesTotal,
            purchaseOtherExpensesTotalLoading: false,
            purchaseTotalRemainCot: subtract(
              financialTotal?.totalCotPiShipment,
              state.purchaseTotalPaid,
            ),
            estimatedNetMargin,
            netMargin,
            estimatedNetProfit,
            netProfit,
          };
        }
      }

      return {
        ...state,
        purchaseOtherExpensesTotal: undefined,
        purchaseOtherExpensesTotalLoading: false,
        purchaseTotalRemainCot: undefined,
      };

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING:
      return {
        ...state,
        purchaseOtherExpensesTotalLoading:
          !state.purchaseOtherExpensesTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_FINANCIAL_TOTAL_LOADING:
      return {
        ...state,
        financialTotalLoading: !state.financialTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_OTHER_EXPENSES_TOTAL:
      if (!action.payload) return state;

      {
        const { sellerOtherExpensesTotal } = action.payload;
        const { financialTotal, purchaseOtherExpensesTotal } = state;
        let { estimatedNetMargin, netMargin, estimatedNetProfit, netProfit } =
          state;

        if (sellerOtherExpensesTotal) {
          if (purchaseOtherExpensesTotal && financialTotal) {
            const netValues = getNetValues(
              sellerOtherExpensesTotal,
              purchaseOtherExpensesTotal,
              financialTotal,
            );

            estimatedNetMargin = netValues.estimatedNetMargin;
            netMargin = netValues.netMargin;
            estimatedNetProfit = netValues.estimatedNetProfit;
            netProfit = netValues.netProfit;
          }

          return {
            ...state,
            sellerOtherExpensesTotal,
            sellerOtherExpensesTotalLoading: false,
            sellerTotalWithApportionment: sum(
              financialTotal?.totalSunCiShipment,
              sellerOtherExpensesTotal.totalForApportionment,
            ),
            estimatedNetMargin,
            netMargin,
            estimatedNetProfit,
            netProfit,
          };
        }
      }

      return {
        ...state,
        sellerOtherExpensesTotal: undefined,
        sellerOtherExpensesTotalLoading: false,
        sellerTotalWithApportionment: undefined,
      };

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING:
      return {
        ...state,
        sellerOtherExpensesTotalLoading: !state.sellerOtherExpensesTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_ADVANCE_RECEIVED_TOTAL:
      if (!action.payload) return state;

      {
        const sellerTotalReceived = sum(
          action.payload.sellerAdvanceReceivedTotal?.totalAmount,
          state.sellerBalanceReceivedTotal?.totalAmount,
        );

        const sellerTotalRemainSun = subtract(
          state.financialTotal?.totalSunCiShipment,
          sellerTotalReceived,
        );

        return {
          ...state,
          sellerAdvanceReceivedTotal: action.payload.sellerAdvanceReceivedTotal,
          sellerTotalReceived,
          sellerTotalRemainSun,
          sellerAdvanceReceivedTotalLoading: false,
        };
      }

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_ADVANCE_RECEIVED_TOTAL_LOADING:
      return {
        ...state,
        sellerAdvanceReceivedTotalLoading:
          !state.sellerAdvanceReceivedTotalLoading,
      };

    case ForeignTradeSupplierTotalReducerActionKind.SET_SELLER_BALANCE_RECEIVED_TOTAL:
      if (!action.payload) return state;

      {
        const sellerTotalReceived = sum(
          action.payload.sellerBalanceReceivedTotal?.totalAmount,
          state.sellerAdvanceReceivedTotal?.totalAmount,
        );

        const sellerTotalRemainSun = subtract(
          state.financialTotal?.totalSunCiShipment,
          sellerTotalReceived,
        );

        return {
          ...state,
          sellerBalanceReceivedTotal: action.payload.sellerBalanceReceivedTotal,
          sellerTotalReceived,
          sellerTotalRemainSun,
          sellerBalanceReceivedTotalLoading: false,
        };
      }

    case ForeignTradeSupplierTotalReducerActionKind.CHANGE_SELLER_BALANCE_RECEIVED_TOTAL_LOADING:
      return {
        ...state,
        sellerBalanceReceivedTotalLoading:
          !state.sellerBalanceReceivedTotalLoading,
      };

    default:
      throw new Error();
  }
};
