/* eslint-disable no-shadow */

import { SatOtherExpense } from '../interfaces';

export enum ManageOtherExpenseReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_CHECKBOXES_ARE_DISABLED,
}

export type ManageOtherExpenseReducer = {
  displayDialog: boolean;
  otherExpenseToEdit?: SatOtherExpense;
  checkboxesAreDisabled: boolean;
};

export type ManageOtherExpenseReducerAction = {
  type: ManageOtherExpenseReducerActionKind;
  payload?: {
    otherExpense?: SatOtherExpense;
    checkboxesAreDisabled?: boolean;
  };
};

export type ManageOtherExpenseFormData = {
  idType: number;
  total: number;
  dateOtherExpense: string;
  comment?: string;
  linkToMargin?: number;
  discountFromSupplier?: number;
  payToSupplier?: number;
};
