import styled from 'styled-components';
import { lighten } from 'polished';

interface ContainerProps {
  sidebarVisible?: boolean;
}

export const Container = styled.div<ContainerProps>`
  .p-datatable {
    max-width: ${props =>
      props.sidebarVisible ? 'calc(100vw - 197px)' : '100vw'};
  }

  a {
    display: flex;

    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    svg {
      margin-left: 12px;
    }
    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;
      &:hover {
        color: var(--blue);
      }
    }
  }

  .p-paginator {
    justify-content: flex-start;
    border: none;
    .p-paginator-current {
      color: var(--grey-scale);
      right: 0;
      margin-right: 30px;
      position: absolute;
    }
  }

  th {
    background: none !important;

    .custom-header {
      i {
        margin-left: 0.5rem;
      }
    }
  }

  .imageWithTextDiv {
    display: flex;
    align-items: center;
    img {
      margin-right: 22px;
    }
  }
`;
