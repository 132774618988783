export const inlandFreightTaxRoles = {
  idEntity: 43,
  idModule: 10,
  permissions: {
    idPermissionCreateInlandFreightTax: 87,
    idPermissionUpdateInlandFreightTax: 88,
    idPermissionDeleteInlandFreightTax: 89,
    idPermissionMassiveActionInlandFreightTax: 90,
    idPermissionExportListInlandFreightTax: 91,
  },
};
