/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm, GeneralInformation, Row } from './styles';
import PageHeader from '../../../components/PageHeader';

import Button from '../../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import MainButton from '../../../components/MainButton';

import { useRefHook } from '../../../hooks/useRefHook';
import Loading from '../../../components/Loading';
import FormInput from '../../../components/FormInput';
import FormDropdown from '../../../components/FormDropdown';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/useAuth';

import { portRoles } from '../../../shared/roles/port';

import getFieldPermission from '../../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface IGeneralInformationFormData {
  name: string;
  initial: string;
  type: ITypeResponse;
  country: ICountryResponse;
  region: IRegionResponse;
  city: ICityResponse;
}

interface ITypeResponse {
  idDomain: number;
  description: string;
}

interface ICountryResponse {
  idCountry: number;
  name: string;
}

interface IRegionResponse {
  idRegion: number;
  name: string;
}

interface ICityResponse {
  idCity: number;
  name: string;
}

// Parametros da rota de porto
interface PortRouteParams {
  portId: string;
}

const Port: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Busca ID da entity de port
  const { idEntity } = portRoles;

  // Busca IDs dos fields de port
  const { idFieldCity, idFieldInitial, idFieldRegion, idFieldType } =
    portRoles.fields;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID do porto
  const { portId } = useParams<PortRouteParams>();

  const defaultPageTitle = `${portId ? 'Manage' : 'Create'} Airport/Port`;

  // Nome do porto
  const [portName, setPortName] = useState('');

  useTitle(portName ? `${portName} - ${defaultPageTitle}` : defaultPageTitle);

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Airports/Ports',
      path: '/list/ports',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  const [versionLock, setVersionLock] = useState<number>();

  // ID do pais selecionado
  const [selectedCountryId, setSelectedCountryId] = useState<any>();

  // ID da regiao selecionada
  const [selectedRegionId, setSelectedRegionId] = useState<any>();

  // Query para listar tipos de porto
  const listTypesQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $isActive: Boolean
      $pagination: Pagination!
    ) {
      listDomainsByGroupId(
        id: $id
        isActive: $isActive
        pagination: $pagination
      ) {
        data {
          idDomain
          description
        }
      }
    }
  `;

  // Query de listagem de paises
  const listAllCountriesQuery = gql`
    query ListAllCountriesQuery {
      listAllCountries {
        idCountry
        name
      }
    }
  `;

  // Query para listar regioes
  const listRegionsQuery = gql`
    query ListRegionsByCountryIdQuery($id: Int!) {
      listRegionsByCountryId(id: $id) {
        idRegion
        name
      }
    }
  `;

  // Query para listar cidades
  const listCitiesQuery = gql`
    query ListCitiesByRegionIdQuery($id: Int!) {
      listCitiesByRegionId(id: $id) {
        idCity
        name
      }
    }
  `;

  // Query para listar porto
  const createPortQuery = gql`
    mutation CreatePortMutation($portData: CreatePort!) {
      createPort(port: $portData) {
        idPort
      }
    }
  `;

  // Query para atualizar porto
  const updatePortQuery = gql`
    mutation UpdatePortMutation($portData: UpdatePort!) {
      updatePort(port: $portData) {
        idPort
        versionLock
      }
    }
  `;

  // Query para listar dados do porto
  const listPortQuery = gql`
    query ListPortQuery($listPortIdPort: Int!) {
      listPort(idPort: $listPortIdPort) {
        idPort
        name
        versionLock
        idCountry
        idCountry2 {
          idCountry
          name
        }
        ${
          getFieldPermission(idFieldCity, userPermissions.userFields).view
            ? `
              idCity
              idCity2 {
                idCity
                name
              }
              `
            : ''
        }
        ${
          getFieldPermission(idFieldType, userPermissions.userFields).view
            ? `
              type
              type2 {
                idDomain
                description
              }
              `
            : ''
        }
        ${
          getFieldPermission(idFieldInitial, userPermissions.userFields).view
            ? 'initial'
            : ''
        }
        ${
          getFieldPermission(idFieldRegion, userPermissions.userFields).view
            ? `
              idRegion
              idRegion2 {
                idRegion
                name
              }
              `
            : ''
        }
      }
    }
  `;

  /**
   * Busca Tipos
   */
  const {
    loading: typesLoading,
    data: typesData,
    error: typesError,
  } = useQuery(listTypesQuery, {
    variables: {
      id: 1,
      isActive: true,
      pagination: {
        _page: 1,
        _limit: 0,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting types',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca Paises
   */
  const {
    loading: countriesLoading,
    data: countriesData,
    error: countriesError,
  } = useQuery(listAllCountriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting countries',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca regioes
   */
  const [
    loadRegions,
    {
      called: regionsCalled,
      loading: regionsLoading,
      data: regionsData,
      error: regionsError,
    },
  ] = useLazyQuery(listRegionsQuery, {
    variables: {
      id: selectedCountryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting regions',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca cidades
   */
  const [
    loadCities,
    {
      called: citiesCalled,
      loading: citiesLoading,
      data: citiesData,
      error: citiesError,
    },
  ] = useLazyQuery(listCitiesQuery, {
    variables: {
      id: selectedRegionId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting cities',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Carrega dados do porto
  const [
    loadPortData,
    {
      called: portCalled,
      loading: portLoading,
      data: portData,
      error: portError,
    },
  ] = useLazyQuery(listPortQuery, {
    variables: {
      listPortIdPort: parseInt(portId, 10),
    },
    onCompleted: async response => {
      // Valida se porto nao foi encontrado
      if (!response.listPort) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Airport/Port not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de portos
        history.push('/list/ports');
        return;
      }

      // Define valor de tipo
      formRef.current?.setFieldValue('type', response.listPort.type2);
      // Define valor de pais
      formRef.current?.setFieldValue('country', response.listPort.idCountry2);
      // Define nome do porto
      setPortName(response.listPort.name);
      // Define ID de pais selecionado
      setSelectedCountryId(response.listPort.idCountry2.idCountry);
      // Carrega regioes de acordo com o pais selecionado
      loadRegions();

      // Define informacao da cidade
      formRef.current?.setFieldValue(
        'city',
        response.listPort.idCity2 ? response.listPort.idCity2.name : undefined,
      );

      if (response.listPort.idRegion2) {
        formRef.current?.setFieldValue('region', response.listPort.idRegion2);
        setSelectedRegionId(response.listPort.idRegion2.idRegion);
        loadCities();
      }

      setPageLoading(false);
      setVersionLock(response.listPort.versionLock);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting port data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/list/ports');
    },
  });

  // Ao carregar a pagina, carrega dados do porto
  useEffect(() => {
    if (portId) {
      setPageLoading(true);
      loadPortData();
    }
  }, [loadPortData, portId]);

  // cria método para chamar a mutation
  const [createPort] = useMutation(createPortQuery);
  const [updatePort] = useMutation(updatePortQuery);

  // Submit do formulario
  const handleCreatePortSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
          country: Yup.object()
            .shape({
              idCountry: Yup.number(),
              name: Yup.string(),
            })
            .nullable()
            .required('Enter a country'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { name, initial, type, country, region, city } = formData;

        // Cria porto
        await createPort({
          variables: {
            portData: {
              name,
              initial,
              type: type ? type.idDomain : undefined,
              idCountry: country.idCountry,
              idRegion: getFieldPermission(
                idFieldRegion,
                userPermissions.userFields,
              ).view
                ? region.idRegion
                : null,
              idCity: getFieldPermission(
                idFieldCity,
                userPermissions.userFields,
              ).view
                ? city.idCity
                : null,
              cityName: city && city.idCity ? undefined : city,
              versionLock,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Airport/Port created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de portos
        history.push('/list/ports');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating port',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      createPort,
      idFieldRegion,
      userPermissions.userFields,
      idFieldCity,
      versionLock,
      toastRef,
      history,
    ],
  );

  // Submit do formulario
  const handleEditPortSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { name, initial, type, country, region, city } = formData;

        // Atualiza informacoes do porto
        const updtPortResponse = await updatePort({
          variables: {
            portData: {
              idPort: parseInt(portId, 10),
              name,
              initial,
              type: type ? type.idDomain : undefined,
              idCountry: country.idCountry,
              idRegion: getFieldPermission(
                idFieldRegion,
                userPermissions.userFields,
              ).view
                ? region.idRegion
                : null,
              idCity: getFieldPermission(
                idFieldCity,
                userPermissions.userFields,
              ).view
                ? city.idCity
                : null,
              cityName: city && city.idCity ? undefined : city,
              versionLock,
            },
          },
        });

        // Atualiza nome do porto no header
        setPortName(name);
        setVersionLock(updtPortResponse.data.updatePort.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Airport/Port updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating port',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      updatePort,
      portId,
      idFieldRegion,
      userPermissions.userFields,
      idFieldCity,
      versionLock,
      toastRef,
    ],
  );

  function onSelectCountry(country: Record<string, unknown>) {
    setSelectedCountryId(country.idCountry);
    formRef.current?.setFieldValue('region', undefined);
    loadRegions();
    formRef.current?.setFieldValue('city', undefined);
  }

  function onSelectRegion(region: Record<string, unknown>) {
    formRef.current?.setFieldValue('city', undefined);
    if (region) {
      setSelectedRegionId(region.idRegion);
      loadCities();
    }
  }

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/ports'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          portData ? `${defaultPageTitle} - ${portName}` : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <GeneralInformation>
          <Form
            ref={formRef}
            onSubmit={portId ? handleEditPortSubmit : handleCreatePortSubmit}
            initialData={
              portCalled && !portLoading && !portError
                ? portData.listPort
                : undefined
            }
          >
            <h1>General Information</h1>
            <Row>
              {getFieldPermission(idFieldType, userPermissions.userFields)
                .view && (
                <FormDropdown
                  name="type"
                  label="Type"
                  readOnly={
                    portId
                      ? !getFieldPermission(
                          idFieldType,
                          userPermissions.userFields,
                        ).edit
                      : !getFieldPermission(
                          idFieldType,
                          userPermissions.userFields,
                        ).create ||
                        typesLoading ||
                        typesError !== undefined
                  }
                  options={
                    typesLoading || typesError
                      ? undefined
                      : typesData.listDomainsByGroupId.data
                  }
                  optionLabel="description"
                  placeholder={typesLoading ? 'Loading' : 'Select a Type'}
                />
              )}

              <FormDropdown
                name="country"
                label="Country"
                required
                readOnly={countriesLoading || countriesError !== undefined}
                options={
                  countriesLoading || countriesError
                    ? undefined
                    : countriesData.listAllCountries
                }
                optionLabel="name"
                placeholder={countriesLoading ? 'Loading' : 'Select a Country'}
                filter
                filterBy="name"
                onValueChange={onSelectCountry}
              />
            </Row>

            <Row>
              {getFieldPermission(idFieldRegion, userPermissions.userFields)
                .view && (
                <FormDropdown
                  name="region"
                  label="State/Provincy"
                  placeholder={regionsLoading ? 'Loading' : 'Select a State'}
                  readOnly={
                    portId
                      ? !getFieldPermission(
                          idFieldRegion,
                          userPermissions.userFields,
                        ).edit
                      : !getFieldPermission(
                          idFieldRegion,
                          userPermissions.userFields,
                        ).create ||
                        regionsData === undefined ||
                        regionsLoading ||
                        regionsError !== undefined
                  }
                  options={
                    regionsCalled && !regionsLoading && !regionsError
                      ? regionsData.listRegionsByCountryId
                      : undefined
                  }
                  optionLabel="name"
                  filter
                  filterBy="name"
                  onValueChange={onSelectRegion}
                  showClear
                />
              )}

              {getFieldPermission(idFieldCity, userPermissions.userFields)
                .view && (
                <FormDropdown
                  name="city"
                  label="City"
                  placeholder={citiesLoading ? 'Loading' : 'Select a City'}
                  readOnly={
                    portId
                      ? !getFieldPermission(
                          idFieldCity,
                          userPermissions.userFields,
                        ).edit
                      : !getFieldPermission(
                          idFieldCity,
                          userPermissions.userFields,
                        ).create ||
                        selectedCountryId === undefined ||
                        citiesLoading ||
                        citiesError !== undefined
                  }
                  options={
                    citiesCalled && !citiesLoading && !citiesError
                      ? citiesData.listCitiesByRegionId
                      : undefined
                  }
                  optionLabel="name"
                  filter
                  filterBy="name"
                  editable
                  showClear
                />
              )}
            </Row>
            <Row>
              <FormInput
                className="generalInput"
                name="name"
                label="Name"
                required
              />
              {getFieldPermission(idFieldInitial, userPermissions.userFields)
                .view && (
                <FormInput
                  className="generalInput"
                  name="initial"
                  label="Initial"
                  readOnly={
                    portId
                      ? !getFieldPermission(
                          idFieldInitial,
                          userPermissions.userFields,
                        ).edit
                      : !getFieldPermission(
                          idFieldInitial,
                          userPermissions.userFields,
                        ).create
                  }
                />
              )}
            </Row>
          </Form>
          {pageLoading && <Loading />}
        </GeneralInformation>
      </CrudForm>
    </Container>
  );
};
export default Port;
