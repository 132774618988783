import { gql } from '@apollo/client';

// Query para listar categories
export const listCategoriesQuery = gql`
  query listAllCategoriesQuery {
    listAllCategories {
      data
      items
    }
  }
`;
