/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { Container, CrudForm, GeneralInformation } from './styles';
import PageHeader from '../../../components/PageHeader';

import Button from '../../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import MainButton from '../../../components/MainButton';

import { useRefHook } from '../../../hooks/useRefHook';
import Loading from '../../../components/Loading';
import FormInput from '../../../components/FormInput';
import getValidationErrors from '../../../utils/getValidationErrors';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import client from '../../../services/apollo/client';
import FormInputNumber from '../../../components/FormInputNumber';
import Tag from '../../../components/Tag';
import { useAuth } from '../../../hooks/useAuth';
import { icmsRoles } from '../../../shared/roles/icms';
import userHasPermission from '../../../utils/userHasPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

// Parametros da rota de ICMS
interface IcmsRouteParams {
  icmsId: string;
}

interface IIcmsFormData {
  icmsName: string;
  idRegion: number;
  idClient?: number;
  idDestinacao: number;
  icmsBase: number;
  icmsRecolhido: number;
}

const IcmsDetail: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID de ICMS
  const icmsId = parseInt(useParams<IcmsRouteParams>().icmsId, 10);

  const defaultPageTitle = `${icmsId ? 'Manage' : 'Create'} ICMS`;

  // Nome do ICMS
  const [icmsName, setIcmsName] = useState('');

  useTitle(icmsName ? `${icmsName} - ${defaultPageTitle}` : defaultPageTitle);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Controle de versao
  const [versionLock, setVersionLock] = useState();

  // Permissions de ICMS
  const { idPermissionUpdateIcms, idPermissionCreateIcms } =
    icmsRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsIcms = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    icmsRoles.idEntity,
  );

  // Valida se usuario pode criar ou atualizar ICMS
  const userCanChange = userHasPermission(
    icmsId ? idPermissionUpdateIcms : idPermissionCreateIcms,
    userPermissionsIcms.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'ICMS',
      path:
        initialPage && initialFirst
          ? `/list/icms?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/list/icms',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  const loadClients: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
          listAllClients(listAllClientsInput: $listAllClientsInput) {
            data {
              idClient
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllClientsInput: {
          pagination: {
            _page: pageData?.page,
            _limit: 25,
          },
          name: search,
          active: true,
        },
      },
    });

    const filteredOptions = res.data.listAllClients.data;
    const quantity = res.data.listAllClients.items;

    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  /**
   * Carrega Regioes
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Regiao
   */
  const loadRegions: LoadOptions<
    any,
    GroupBase<any>,
    { page: any; idCountry?: number }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllRegions(
          $pagination: Pagination!
          $idCountry: Int
          $name: String
        ) {
          listAllRegions(
            pagination: $pagination
            idCountry: $idCountry
            name: $name
          ) {
            data {
              idRegion
              name
            }
            items
          }
        }
      `,
      variables: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
          _orderBy: 'name',
        },
        idCountry: 24,
        name: search,
      },
    });

    // Opcoes filtradas
    const filteredOptions = res.data.listAllRegions.data;
    // Quantidade de itens
    const quantity = res.data.listAllRegions.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
        idCountry: 24,
      },
    };
  };

  // Query para criar icms
  const createIcmsQuery = gql`
    mutation CreateIcmsMutation($icms: CreateIcmsInput!) {
      createIcms(icms: $icms) {
        idIcms
      }
    }
  `;

  // Query para atualizar ICMS
  const updateIcmsQuery = gql`
    mutation UpdateIcmsMutation($icms: UpdateIcmsInput!) {
      updateIcms(icms: $icms) {
        idIcms
        versionLock
      }
    }
  `;

  // Query para listar dados de ICMS
  const listIcmsQuery = gql`
    query ListIcmsQuery($idIcms: Int!) {
      listIcmsById(id: $idIcms) {
        idIcms
        icmsName
        idRegion
        idRegion2 {
          idRegion
          name
        }
        idDestinacao
        idDestinacao2 {
          idDomain
          description
        }
        idClient
        idClient2 {
          idClient
          name
        }
        icmsBase
        icmsRecolhido
        createdBy
        createdBy2 {
          firstName
          lastName
        }
        updatedBy
        updatedBy2 {
          firstName
          lastName
        }
        createdAt
        updatedAt
        versionLock
        active
      }
    }
  `;

  // Carrega dados de ICMS
  const [
    loadIcmsData,
    {
      called: icmsCalled,
      loading: icmsLoading,
      data: icmsData,
      error: icmsError,
    },
  ] = useLazyQuery(listIcmsQuery, {
    variables: {
      idIcms: icmsId,
    },
    onCompleted: async response => {
      if (response.listIcmsById) {
        // Define novo nome do ICMS
        setIcmsName(response.listIcmsById.icmsName);
        setVersionLock(response.listIcmsById.versionLock);
        setPageLoading(false);
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'ICMS not Found',
          life: ToastLife.ERROR,
        });
        setPageLoading(false);
        history.push('/list/icms');
      }
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting ICMS data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/list/icms');
    },
  });

  // Ao carregar a pagina, carrega dados de ICMS
  useEffect(() => {
    if (icmsId) {
      setPageLoading(true);
      loadIcmsData();
    }
  }, [icmsId, loadIcmsData]);

  // cria método para chamar a mutation
  const [createIcms] = useMutation(createIcmsQuery);
  const [updateIcms] = useMutation(updateIcmsQuery);

  // Submit do formulario
  const handleIcmsSubmit = useCallback(
    async (formData: IIcmsFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          icmsName: Yup.string().required('Required Field'),
          idRegion: Yup.string().nullable().required('Required Field'),
          idDestinacao: Yup.string().nullable().required('Required Field'),
          icmsBase: Yup.string().nullable().required('Required Field'),
          icmsRecolhido: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Valor de resposta da API
        let response;

        if (icmsId) {
          // Atualiza informacoes de ICMS
          response = await updateIcms({
            variables: {
              icms: {
                idIcms: icmsId,
                icmsName: formData.icmsName,
                idRegion: formData.idRegion,
                idClient: formData.idClient,
                idDestinacao: formData.idDestinacao,
                icmsBase: formData.icmsBase,
                icmsRecolhido: formData.icmsRecolhido,
                versionLock,
              },
            },
          });
        } else {
          // Cria ICMS
          response = await createIcms({
            variables: {
              icms: {
                icmsName: formData.icmsName,
                idRegion: formData.idRegion,
                idClient: formData.idClient,
                idDestinacao: formData.idDestinacao,
                icmsBase: formData.icmsBase,
                icmsRecolhido: formData.icmsRecolhido,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `ICMS ${icmsId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!icmsId) {
          // Direciona usuario para listagem do ICMS
          history.push(`/list/icms/${response.data.createIcms.idIcms}`);
        } else {
          loadIcmsData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating ICMS',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      createIcms,
      formRef,
      history,
      icmsId,
      loadIcmsData,
      toastRef,
      updateIcms,
      versionLock,
    ],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        history.push(
          initialPage && initialFirst
            ? `/list/icms?initialPage=${initialPage}&initialFirst=${initialFirst}`
            : '/list/icms',
        ),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          icmsData ? `${defaultPageTitle} - ${icmsName}` : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {icmsData ? (
          icmsData?.listIcmsById?.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}
        <p className={icmsId ? 'createdAt' : ''}>
          {icmsData && !icmsLoading
            ? `${new Date(
                icmsData?.listIcmsById?.createdAt,
              ).toLocaleString()} by ${
                icmsData?.listIcmsById?.createdBy2?.firstName
              } ${icmsData?.listIcmsById?.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={icmsId ? 'updatedAt' : ''}>
          {icmsData && !icmsLoading
            ? `${new Date(
                icmsData?.listIcmsById?.updatedAt,
              ).toLocaleString()} by ${
                icmsData?.listIcmsById?.updatedBy2?.firstName
              } ${icmsData?.listIcmsById?.updatedBy2?.lastName}`
            : ''}
        </p>

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      {((icmsId && icmsData) || !icmsId) && (
        <CrudForm>
          <GeneralInformation>
            <Form
              ref={formRef}
              onSubmit={handleIcmsSubmit}
              initialData={
                icmsCalled && !icmsLoading && !icmsError
                  ? icmsData.listIcmsById
                  : undefined
              }
            >
              <FormInput
                className="textInput"
                name="icmsName"
                label="ICMS Name"
                required
                readOnly={!userCanChange}
              />
              <FormAsyncSelect
                name="idClient"
                label="ICMS Client"
                loadOptions={loadClients}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idClient}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No clients found'}
                initialValue={icmsData?.listIcmsById?.idClient2}
                isClearable
                placeholder={<p>All Clients</p>}
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idRegion"
                label="Estado"
                required
                loadOptions={loadRegions}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idRegion}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No regions found'}
                initialValue={icmsData?.listIcmsById?.idRegion2}
                readOnly={!userCanChange}
              />
              <FormAsyncSelect
                name="idDestinacao"
                label="Destinação"
                className="small-input"
                loadOptions={asyncSelectLoadDomains}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.description}
                getOptionValue={(option: any) => option.idDomain}
                additional={{
                  page: 1,
                  id: 27,
                }}
                noOptionsMessage={() => 'No destinations found'}
                required
                initialValue={icmsData?.listIcmsById?.idDestinacao2}
                readOnly={!userCanChange}
              />
              <FormInputNumber
                name="icmsBase"
                label="Alíquota Base ICMS"
                className="small-input"
                required
                max={100}
                decimalScale={2}
                readOnly={!userCanChange}
                decimalSeparator=","
              />
              <FormInputNumber
                name="icmsRecolhido"
                label="Alíquota ICMS Recolhido"
                className="small-input"
                required
                max={100}
                decimalScale={2}
                readOnly={!userCanChange}
                decimalSeparator=","
              />
            </Form>
          </GeneralInformation>
        </CrudForm>
      )}
      {(pageLoading || icmsLoading) && <Loading />}
    </Container>
  );
};
export default IcmsDetail;
