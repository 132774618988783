/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import {
  IAirportTax,
  IAirportTaxFormData,
  IAirportTaxRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { airportTaxRoles } from '../../../shared/roles/airportTax';
import Tag from '../../../components/Tag';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';
import { DomainGroup } from '../../../shared/enums/domainGroup';

const AirportTax: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de Airport Tax
  const idAirportTax = parseInt(
    useParams<IAirportTaxRouteParams>().idAirportTax,
    10,
  );

  const defaultPageTitle = `${idAirportTax ? 'Manage' : 'Create'} Airport Tax`;

  // Estado de Airport Tax
  const [airportTax, setAiportTax] = useState<IAirportTax>({} as IAirportTax);

  useTitle(
    airportTax?.airportTaxName
      ? `${airportTax.airportTaxName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirportTax = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    airportTaxRoles.idEntity,
  );

  // Permissions de Airport Tax
  const { idPermissionCreateAirportTax, idPermissionUpdateAirportTax } =
    airportTaxRoles.permissions;

  // Valida se usuario pode criar ou atualizar Airport Tax
  const userCanChange = userHasPermission(
    idAirportTax ? idPermissionUpdateAirportTax : idPermissionCreateAirportTax,
    userPermissionsAirportTax.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Airport Taxes',
      path:
        initialPage && initialFirst
          ? `/logistics/airportTaxes?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/airportTaxes',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadAirports: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllAirportsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
          type: 1,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar airport tax
  const listLogisticAirportTaxQuery = gql`
    query listLogisticAirportTaxById($idAirportTax: Int!) {
      listLogisticAirportTaxById(idAirportTax: $idAirportTax) {
        idAirportTax
        airportTaxName
        idAirportDestination2 {
          idPort
          name
        }
        idCurDes2 {
          idCurrency
          abbreviation
          currency
        }
        taxValueDes
        idBcValueDes2 {
          idDomain
          description
        }
        bcCifDes
        updatedAtDes
        idCurArm2 {
          idCurrency
          abbreviation
          currency
        }
        taxValueArm
        idBcValueArm2 {
          idDomain
          description
        }
        bcCifArm
        updatedAtArm
        createdBy2 {
          firstName
          lastName
        }
        createdAt
        updatedBy2 {
          firstName
          lastName
        }
        updatedAt
        versionLock
        active
      }
    }
  `;

  /**
   * Busca dados de Airport Tax
   */
  const [loadAirportTaxData, { loading: airportTaxLoading }] = useLazyQuery(
    listLogisticAirportTaxQuery,
    {
      variables: {
        idAirportTax,
      },
      onCompleted: response => {
        if (response.listLogisticAirportTaxById) {
          setAiportTax(response.listLogisticAirportTaxById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Airport Tax not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting airport tax data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/airportTaxes'),
    });
  }

  // Mutation de criacao de Airport Tax
  const createAirportTaxQuery = gql`
    mutation CreateLogisticAirportTaxMutation(
      $data: CreateLogisticAirportTaxInput!
    ) {
      createLogisticAirportTax(data: $data) {
        idAirportTax
      }
    }
  `;

  // Mutation de atualizacao de airport tax
  const updateAirportTaxQuery = gql`
    mutation UpdateAirportTaxMutation($data: UpdateLogisticAirportTaxInput!) {
      updateLogisticAirportTax(data: $data) {
        idAirportTax
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createAirportTaxMutation] = useMutation(createAirportTaxQuery);
  const [updateAirportTaxMutation] = useMutation(updateAirportTaxQuery);

  // Submit do formulario de airport tax
  const handleAirportTaxSubmit = useCallback(
    async (data: IAirportTaxFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          airportTaxName: Yup.string().required('Required Field'),
          idAirportDestination: Yup.string()
            .nullable()
            .required('Required Field'),
          idCurDes: Yup.number().nullable().required('Required Field'),
          taxValueDes: Yup.string().nullable().required('Required Field'),
          idBcValueDes: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        const apiValues = {
          airportTaxName: data.airportTaxName,
          idAirportDestination: data.idAirportDestination,
          idCurArm: data.idCurArm,
          taxValueArm: data.taxValueArm,
          idBcValueArm: data.idBcValueArm,
          bcCifArm: !!airportTax.bcCifArm,
          idCurDes: data.idCurDes,
          taxValueDes: data.taxValueDes,
          idBcValueDes: data.idBcValueDes,
          bcCifDes: !!airportTax.bcCifDes,
        };

        // Valida se esta editando ou criando um novo airport tax
        if (!idAirportTax) {
          // Chamada criacao de airport tax
          response = await createAirportTaxMutation({
            variables: {
              data: { ...apiValues },
            },
          });
        } else {
          // Chamada atualizacao de airport tax
          response = await updateAirportTaxMutation({
            variables: {
              data: {
                idAirportTax,
                versionLock: airportTax.versionLock,
                ...apiValues,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Airport Tax ${idAirportTax ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!idAirportTax) {
          // Direciona usuario para listagem da airport tax
          history.push(
            `/logistics/airportTaxes/${response.data.createLogisticAirportTax.idAirportTax}`,
          );
        } else {
          setAiportTax({} as IAirportTax);
          loadAirportTaxData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idAirportTax ? 'updating' : 'creating'
            } airport tax`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createAirportTaxMutation,
      formRef,
      history,
      idAirportTax,
      loadAirportTaxData,
      airportTax.bcCifArm,
      airportTax.bcCifDes,
      airportTax.versionLock,
      toastRef,
      updateAirportTaxMutation,
    ],
  );

  useEffect(() => {
    if (idAirportTax) {
      loadAirportTaxData();
    }
  }, [idAirportTax, loadAirportTaxData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader
        title={
          airportTax?.idAirportTax
            ? `${defaultPageTitle} - ${airportTax.airportTaxName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {airportTax.idAirportTax ? (
          airportTax.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}
        <p className={idAirportTax ? 'createdAt' : ''}>
          {airportTax.idAirportTax && !airportTaxLoading
            ? `${new Date(airportTax.createdAt).toLocaleString()} by ${
                airportTax.createdBy2?.firstName
              } ${airportTax.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idAirportTax ? 'updatedAt' : ''}>
          {airportTax && !airportTaxLoading
            ? `${new Date(airportTax.updatedAt).toLocaleString()} by ${
                airportTax.updatedBy2?.firstName
              } ${airportTax.updatedBy2?.lastName}`
            : ''}
        </p>

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {/* Formulario */}
      {((idAirportTax && !airportTaxLoading && airportTax.idAirportTax) ||
        !idAirportTax) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleAirportTaxSubmit}
            initialData={airportTax}
          >
            <Row>
              <FormInput
                name="airportTaxName"
                label="Airport Tax Name"
                required
                className="form-input"
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idAirportDestination"
                label="Airport of Destination"
                required
                className="form-input"
                loadOptions={loadAirports}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No Airports found'}
                initialValue={airportTax?.idAirportDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueDes"
                  label="Despesas aeroportuárias"
                  required
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurDes"
                  required
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={airportTax.idCurDes2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueDes"
                  required
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={airportTax.idBcValueDes2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div radio-button-required generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      required
                      inputId="yes"
                      name="airportTax.bcCifDes"
                      value
                      checked={airportTax.bcCifDes === true}
                      onChange={() =>
                        changeStateProperty('bcCifDes', true, setAiportTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="airportTax.bcCifDes"
                      value={false}
                      checked={airportTax.bcCifDes !== true}
                      onChange={() =>
                        changeStateProperty('bcCifDes', false, setAiportTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Despesas aeroportuárias</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueArm"
                  label="Armazenagem Aeroporto (5 dias)"
                  max={100}
                  min={0}
                  suffix="%"
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurArm"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={airportTax.idCurArm2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueArm"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={airportTax.idBcValueArm2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="airportTax.bcCifArm"
                      value
                      checked={airportTax.bcCifArm === true}
                      onChange={() =>
                        changeStateProperty('bcCifArm', true, setAiportTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="airportTax.bcCifArm"
                      value={false}
                      checked={airportTax.bcCifArm !== true}
                      onChange={() =>
                        changeStateProperty('bcCifArm', false, setAiportTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">
                Taxa cobrada pela armazenagem da mercadoria no aeroporto (5
                dias)
              </p>
            </span>
          </Form>
        </CrudForm>
      )}
      {(airportTaxLoading || loading) && <Loading />}
    </Container>
  );
};

export default AirportTax;
