import { gql } from '@apollo/client';

export const createCqAttachmentQuery = gql`
  mutation CreateClientQuotationAttachment(
    $data: [CreateClientQuotationAttachmentsInput]!
  ) {
    createClientQuotationAttachments(data: $data) {
      idCqAttachment
      idAttachment2 {
        idAttachment
        name
        url
        nameGivenByUser
      }
    }
  }
`;

export const deleteCqAttachmentsQuery = gql`
  mutation DeleteClientQuotationAttachments(
    $idsClientQuotationAttachments: [Int]!
  ) {
    deleteClientQuotationAttachments(
      idsClientQuotationAttachments: $idsClientQuotationAttachments
    )
  }
`;

export const createCqUpdateQuery = gql`
  mutation CreateClientQuotationUpdate(
    $data: CreateClientQuotationUpdateInput!
  ) {
    createClientQuotationUpdate(data: $data) {
      idCqUpdate
    }
  }
`;

export const updateCqUpdateQuery = gql`
  mutation UpdateClientQuotationUpdate(
    $data: UpdateClientQuotationUpdateInput!
  ) {
    updateClientQuotationUpdate(data: $data) {
      idCqUpdate
    }
  }
`;

export const listCqUpdatesByIdCqQuery = gql`
  query ListClientQuotationUpdatesByIdClientQuotation(
    $data: ListCqUpdatesByCqIdInput!
  ) {
    listClientQuotationUpdatesByIdClientQuotation(data: $data) {
      idCqUpdate
      idComment
      idComment2 {
        idAttachment2 {
          url
          name
          nameGivenByUser
        }
        message
        createdBy
        createdBy2 {
          idUser
          firstName
          lastName
          username
          avatarUrl
        }
        createdAt
        updatedAt
        idStatus
        idStatus2 {
          idDomain
          description
        }
        isRestrictInfo
        isGeneratedSystem
      }
    }
  }
`;

export const deleteCqUpdateQuery = gql`
  mutation DeleteClientQuotationUpdate($idCqUpdate: Int!) {
    deleteClientQuotationUpdate(idCqUpdate: $idCqUpdate)
  }
`;
