import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useRef } from 'react';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import FormRow from '../../../../../components/FormRow';
import FormTitle from '../../../../../components/FormTitle';
import TransparentButton from '../../../../../components/TransparentButton';
import { useAuth } from '../../../../../hooks/useAuth';
import { PrcFileType, PrcStatus } from '../../../../../shared/enums/domains';
import { FileType } from '../../../../../shared/enums/fileType';
import { IPrc } from '../../interfaces';
import FileUpload from '../../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

/**
 * Props de PRC Catalogue
 */
interface ICatalogueProps {
  /**
   * Estado de PRC
   */
  prc: IPrc;

  /**
   * Funcao para download de arquivo de PRC pelo servidor
   */
  handleDownloadFile: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;

  /**
   * Funcao de upload de arquivos
   */
  handleFileUpload: (
    event: FileUploadResponse[],
    idType: number,
    idPrcQuotation?: number,
  ) => void;
}

const Catalogue: React.FC<ICatalogueProps> = ({
  prc,
  handleDownloadFile,
  handleFileUpload,
}) => {
  // Array de Catalogues
  const catalogues = prc.prcFileLogs?.filter(
    item => item.idType === PrcFileType.PRC_CATALOGUE,
  );

  // ID do usuario logado
  const { idUser } = useAuth();

  // Referencia do Painel de Catalogues
  const prcCataloguesPanelRef = useRef<OverlayPanel>(null);

  return (
    <>
      <FormTitle name="Catalogue" />

      <FormRow>
        {catalogues && catalogues?.length > 0 && (
          <TransparentButton
            type="button"
            onClick={() =>
              handleDownloadFile({
                variables: {
                  idPrcFileLog: catalogues[catalogues?.length - 1].idPrcFileLog,
                },
              })
            }
          >
            <FiDownload size={18} className="svg-left" />
            CATALOGUE
          </TransparentButton>
        )}

        {prc.idResponsibleUser === idUser &&
          (prc.idStatus === PrcStatus.DRAFT ||
            prc.idStatus === PrcStatus.AT_PURCHASE) && (
            <FileUpload
              mode="basic"
              auto
              onConfirm={e => handleFileUpload(e, PrcFileType.PRC_CATALOGUE)}
              chooseLabel="Upload"
              chooseOptions={{ icon: 'pi-upload' }}
              accept={`${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.ALL_IMAGES},${FileType.PDF}`}
            />
          )}

        <button
          type="button"
          className="items-log"
          onClick={e => prcCataloguesPanelRef.current?.toggle(e, e.target)}
        >
          <AiOutlineUnorderedList size={24} />
        </button>

        <OverlayPanel
          ref={prcCataloguesPanelRef}
          showCloseIcon
          dismissable
          appendTo={document.getElementById('prc-general-information')}
        >
          <h2>PRC Catalogue - Log</h2>
          <DataTable
            value={catalogues}
            style={{ maxWidth: '400px', marginTop: '12px' }}
            removableSort
            emptyMessage="No items to show"
          >
            <Column
              field="createdAt"
              header="Created At"
              body={e => new Date(e.createdAt).toLocaleString()}
              sortable
            />
            <Column
              field="createdBy2.firstName"
              header="Created By"
              body={e => `${e.createdBy2.firstName} ${e.createdBy2.lastName}`}
              sortable
            />
            <Column
              field="file"
              header="File"
              body={e => (
                <TransparentButton
                  type="button"
                  onClick={() =>
                    handleDownloadFile({
                      variables: {
                        idPrcFileLog: e.idPrcFileLog,
                      },
                    })
                  }
                >
                  <FiDownload size={18} />
                </TransparentButton>
              )}
            />
          </DataTable>
        </OverlayPanel>
      </FormRow>
    </>
  );
};

export default Catalogue;
