import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { Messages } from 'primereact/messages';
import { satStatusHandlerLogQuery } from './queries';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { SatStatusHandlerLogType } from '../../../../../shared/enums/domains';

interface IStatusHandlerLogProps {
  idSat: number;
}

const StatusHandlerLog: React.FC<IStatusHandlerLogProps> = ({ idSat }) => {
  const messagesRef = useRef<Messages>(null);
  const { showError } = useRefHook();

  function showMessages(types: number[]) {
    if (types.some(type => type === SatStatusHandlerLogType.ERROR)) {
      messagesRef.current?.show({
        sticky: true,
        severity: 'error',
        detail:
          'Some statuses failed to update correctly. If this error persists, please contact support.',
      });
    }

    if (
      types.some(type => type === SatStatusHandlerLogType.SAT_BEING_UPDATED)
    ) {
      messagesRef.current?.show({
        sticky: true,
        severity: 'warn',
        detail:
          'Some statuses are in the process of being updated and may not yet reflect the latest changes.',
      });
    }
  }

  useQuery(satStatusHandlerLogQuery, {
    variables: {
      idSat,
    },
    onCompleted: response => {
      showMessages(response.getSatStatusHandlerLogTypesBySat);
    },
    onError: () => {
      showError({
        summary: 'Error geting status logs',
        detail: 'Some status might be wrong or missing',
      });
    },
  });

  return <Messages ref={messagesRef} />;
};

export default StatusHandlerLog;
