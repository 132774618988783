import styled from 'styled-components';

export const Section = styled.div`
  margin: 16px;
`;

export const RecipientsNDI = styled.div`
  margin: 10px 0;

  .content {
    display: flex;
    justify-content: space-between;
  }
`;

export const FilesHeader = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

export const FilesList = styled.div`
  margin: 16px;
`;

export const CheckboxItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;
