import { DocumentNode, gql } from '@apollo/client';
import { ForeignTradeFieldsPermissions } from '../interfaces';

/**
 * Query para listar SAT Foreign Trade containers
 */
export const listSatForeignTradeContainersQuery = gql`
  query ListSatForeignTradeContainersBySatForeignTradeId(
    $idSatForeignTrade: Int!
  ) {
    listSatForeignTradeContainersBySatForeignTradeId(
      idSatForeignTrade: $idSatForeignTrade
    ) {
      idSatForeignTradeCtnr
      containerName
    }
  }
`;

/**
 * Query para listar SAT Foreign Trade
 */
export const listSatForeignTradeQuery = (
  fieldsPermissions: ForeignTradeFieldsPermissions,
): DocumentNode => gql`
  query ListSatForeignTradeById(
    $idSatForeignTrade: Int!
  ) {
    listSatForeignTradeById(idSatForeignTrade: $idSatForeignTrade) {
        idSatForeignTrade
        satForeignTradeNumber
        ${fieldsPermissions.realInspectionDate.view ? 'realInspectionDate' : ''}
        ${fieldsPermissions.realEtd.view ? 'realEtd' : ''}
        ${fieldsPermissions.realEta.view ? 'realEta' : ''}
        ${
          fieldsPermissions.realArrivalAtClient.view
            ? 'realArrivalAtClient'
            : ''
        }
        ${
          fieldsPermissions.idImportStatus.view
            ? `idImportStatus
            idImportStatus2 {
                idDomain
                description
              }`
            : ''
        }
        ${
          fieldsPermissions.authorizationRequested.view
            ? 'authorizationRequested'
            : ''
        }
        ${fieldsPermissions.shipmentAuthorized.view ? 'shipmentAuthorized' : ''}
        ${fieldsPermissions.bookingConfirmed.view ? 'bookingConfirmed' : ''}
        ${fieldsPermissions.shipmentConfirmed.view ? 'shipmentConfirmed' : ''}
        ${fieldsPermissions.freightCost.view ? 'freightCost' : ''}
        ${fieldsPermissions.freightExtraCost.view ? 'freightExtraCost' : ''}
        ${
          fieldsPermissions.importLicenseNumber.view
            ? 'importLicenseNumber'
            : ''
        }
        ${
          fieldsPermissions.importLicenseExpireDate.view
            ? 'importLicenseExpireDate'
            : ''
        }
        ${fieldsPermissions.diNumber.view ? 'diNumber' : ''}
        ${
          fieldsPermissions.freightForwarderOrigin.view
            ? `freightForwarderOrigin
                freightForwarderOrigin2 {
                  idPartner
                  name
                }`
            : ''
        }
        ${
          fieldsPermissions.freightForwarderDestination.view
            ? `freightForwarderDestination
                freightForwarderDestination2 {
                  idPartner
                  name
                }`
            : ''
        }
        satForeignTradeSuppliers {
          idSatForeignTradeSupplier
          idSatSupplier2 {
            idSupplier2 {
              sunNumber
            }
          }
          ${
            fieldsPermissions.inspectionScheduled.view
              ? 'inspectionScheduled'
              : ''
          }
          ${
            fieldsPermissions.inspectionApproval.view
              ? 'inspectionApproval'
              : ''
          }
          ${
            fieldsPermissions.idInspectionApprovedBy.view
              ? `idInspectionApprovedBy
                  idInspectionApprovedBy2 {
                    idUser
                    firstName
                    lastName
                  }`
              : ''
          }
          ${
            fieldsPermissions.idInspectionApprovedBy.view
              ? `idReworkApprovedBy
                  idReworkApprovedBy2 {
                    idUser
                    firstName
                    lastName
                  }`
              : ''
          }
          ${
            fieldsPermissions.rework.view
              ? `rework
                  rework2 {
                    idDomain
                    description
                  }`
              : ''
          }
          ${fieldsPermissions.reworkDate.view ? 'reworkDate' : ''}
          ${fieldsPermissions.reworkCost.view ? 'reworkCost' : ''}
          ${fieldsPermissions.startProduction.view ? 'startProduction' : ''}
          ${fieldsPermissions.inspector.view ? 'inspector' : ''}
        }
        noReport
        noLoadingPictures
        ${
          fieldsPermissions.typeOfImportService.view
            ? `  idTypeOfImportService
                idTypeOfImportService2 {
                  idDomain
                  description
                }`
            : ''
        }
        ${
          fieldsPermissions.importLevel.view
            ? `idImportLevel
                idImportLevel2 {
                  idDomain
                  description
                }`
            : ''
        }
        ${fieldsPermissions.importNotes.view ? 'importNotes' : ''}
        ${fieldsPermissions.exportNotes.view ? 'exportNotes' : ''}
        idSatForeignTradeGroup
        ${fieldsPermissions.trakingNumber.view ? 'trakingNumber' : ''}
        ${
          fieldsPermissions.idCourier.view
            ? ` idCourier
                idCourier2 {
                  idDomain
                  description
                }`
            : ''
        }
        ${fieldsPermissions.received.view ? 'received' : ''}
        ${fieldsPermissions.deliveredToClient.view ? 'deliveredToClient' : ''}
        ${
          fieldsPermissions.authorizationBookingTiming.view
            ? 'authorizationBookingTiming'
            : ''
        }
        ${
          fieldsPermissions.authorizationShippingTiming.view
            ? 'authorizationShippingTiming'
            : ''
        }
        ${
          fieldsPermissions.bookingShippingTiming.view
            ? 'bookingShippingTiming'
            : ''
        }
        ${fieldsPermissions.transitTime.view ? 'transitTime' : ''}
        ${fieldsPermissions.hblFinalTiming.view ? 'hblFinalTiming' : ''}
        ${
          fieldsPermissions.deliveryAtClientTiming.view
            ? 'deliveryAtClientTiming'
            : ''
        }
        ${
          fieldsPermissions.totalForeignTradeTiming.view
            ? 'totalForeignTradeTiming'
            : ''
        }
        ${
          fieldsPermissions.idExportStatus.view
            ? `idExportStatus
              idExportStatus2 {
                idDomain
                description
              }`
            : ''
        }
        ${
          fieldsPermissions.estimatedInspectionDate.view
            ? 'estimatedInspectionDate'
            : ''
        }
        ${
          fieldsPermissions.estimatedShipmentDate.view
            ? 'estimatedShipmentDate'
            : ''
        }
        ${
          fieldsPermissions.estimatedArrivalDate.view
            ? 'estimatedArrivalDate'
            : ''
        }
        ${
          fieldsPermissions.estimatedArrivalClientDate.view
            ? 'estimatedArrivalClientDate'
            : ''
        }
        idLoadingPort
        idLoadingPort2 {
          idPort
          name
        }
        idPort
        idPort2 {
          idPort
          name
        }
        ${fieldsPermissions.clientOrder.view ? 'clientOrder' : ''}
        ${
          fieldsPermissions.idPurchaseUser.view
            ? `
              idPurchaseUser
              idPurchaseUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idSalesUser.view
            ? `idSalesUser
              idSalesUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idSalesSecondUser.view
            ? `idSalesSecondUser
              idSalesSecondUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idFinancialUser.view
            ? `idFinancialUser
              idFinancialUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idComexExportUser.view
            ? `idComexExportUser
              idComexExportUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idComexImportUser.view
            ? `idComexImportUser
              idComexImportUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${
          fieldsPermissions.idDesignerUser.view
            ? `idDesignerUser
              idDesignerUser2 {
                idUser
                firstName
                lastName
                email
                username
              }`
            : ''
        }
        ${fieldsPermissions.arrivalConfirmed.view ? 'arrivalConfirmed' : ''}
        advancePaymentRequestDate
        balancePaymentRequestDate
        ${fieldsPermissions.shipmentDetails.view ? 'shipmentDetails' : ''}
        shipmentRequestDate
        ${
          fieldsPermissions.shipmentRequestNotRequired.view
            ? 'shipmentRequestNotRequired'
            : ''
        }
    }
  }
`;
