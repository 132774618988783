import { lighten } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  highlighted?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  padding: 8px;

  animation: ${({ highlighted }) =>
    highlighted ? 'highlight 2s ease-out' : 'none'};
  @keyframes highlight {
    0% {
      background-color: #ffedcc;
    }
    100% {
      background-color: transparent;
    }
  }

  .user-avatar-wrapper {
    margin-right: 8px;

    .round-user-avatar {
      img,
      div {
        width: 32px;
        height: 32px;
        font-size: unset;
      }
    }
  }
`;

export const CommentContent = styled.div`
  width: 100%;
  .comment-header {
    display: flex;
    gap: 8px;
    .user-name {
      font-weight: 500;
    }
    p {
      color: #42526e;
    }

    .status-tag,
    .p-tag {
      margin-left: auto;
    }

    .commonTags {
      margin-left: auto;
    }
  }

  .comment-text {
    margin: 4px 0;
    white-space: pre-line;
    word-break: break-word;

    .comment-mention {
      color: blue;
      text-decoration: underline;
      pointer-events: none;
    }
  }

  .attachment {
    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);

    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }

    svg {
      vertical-align: top;
    }
  }
`;

export const Actions = styled.div`
  margin-top: 6px;

  button {
    border: none;
    background: none;
    font-weight: 500;
    transition: color 0.2s;
    color: #42526e;

    &:hover {
      color: var(--grey-scale);
    }
  }

  span + span::before {
    display: inline-block;
    width: 16px;
    color: #42526e;
    content: '·';
    text-align: center;
    vertical-align: middle;
  }
`;
