import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  SortOrder,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';
import { checklistRoles } from '../../../shared/roles/checklist';

import { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import useTitle from '../../../hooks/useTitle';
import ToastLife from '../../../shared/enums/toastLife';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';

interface ICategory {
  idCategory: number;
  name: string;
}

/**
 * Interface de Checklist
 */
interface IChecklist {
  idCheckList: number;
  name: string;
  categoriesString: string;
  categories: ICategory[];
}

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: SortOrder;
  globalFilter?: string;
  name?: string;
  categoryName?: string;
}

const pageTitle = 'List of Checklists';

const Checklists: React.FC = () => {
  useTitle(pageTitle);

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Checklist
  const { idPermissionDeleteChecklist } = checklistRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsChecklist = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    checklistRoles.idEntity,
  );

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Checklists selecionadas
  const [selectedChecklists, setSelectedChecklists] = useState([]);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Colunas da grid
  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'categoriesString', header: 'Categories' },
  ];

  /**
   * Obtem nome correto para ordenacao por coluna no backend
   * @returns Parametro para ordenacao por coluna
   */
  function getOrderBy(): string {
    switch (lazyParams.sortField) {
      case 'name':
        return 'checkList.name';
      case 'categoriesString':
        return 'category.name';
      default:
        return '';
    }
  }

  // Query para listar incoterms
  const listChecklistsQuery = gql`
    query ListChecklistsWithCategoriesQuery(
      $pagination: Pagination!
      $categoryName: String
      $name: String
      $globalSearch: String
    ) {
      listChecklistsWithCategories(
        pagination: $pagination
        name: $name
        categoryName: $categoryName
        globalSearch: $globalSearch
      ) {
        data {
          idCheckList
          name
          categoriesString
          categories {
            idCategory
            name
          }
        }
        items
      }
    }
  `;

  /**
   * Busca Checklists
   */
  const {
    data: checklistsData,
    error: checklistsError,
    loading: checklistsLoading,
    refetch: checklistsRefetch,
  } = useQuery(listChecklistsQuery, {
    variables: {
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: getOrderBy(),
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
      name: lazyParams.name,
      categoryName: lazyParams.categoryName,
      globalSearch: lazyParams.globalFilter,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting checklists',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Query para remover checklists
  const deleteChecklistsQuery = gql`
    mutation DeleteChecklistsWithItemsMutation($ids: [Int]!) {
      deleteChecklistsWithItems(ids: $ids)
    }
  `;

  // Cria mutation
  const [deleteChecklists] = useMutation(deleteChecklistsQuery);

  /**
   * Deleta checklists selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedChecklists.length} checklists?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada checklist
        const idsToDelete = selectedChecklists.map(
          (a: IChecklist) => a.idCheckList,
        );
        try {
          // Deleta checklists
          await deleteChecklists({
            variables: {
              ids: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedChecklists.length} checklists`,
            life: ToastLife.SUCCESS,
          });

          // Atualiza grid de checklists
          await checklistsRefetch();

          // Zera estado de checklists selecionadas
          setSelectedChecklists([]);
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting checklists',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Direciona usuario para pagina da checklist clicada
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/products/checklists/${e.data.idCheckList}`);
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return <span className="custom-header">{headerName}</span>;
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = columns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={{ width: '100px', flex: 'auto' }}
        sortable
      />
    );
  });

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar checklist */}
        <MainButton
          className="mainButton"
          label="New Checklist"
          onClick={() => history.push('/products/checklists/create')}
        />

        {/* Botao para excluir checklists selecionadas */}
        {userHasPermission(
          idPermissionDeleteChecklist,
          userPermissionsChecklist.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={!selectedChecklists.length}
            onClick={() => handleDeleteSelected()}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a checklist"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        name="checklists"
        lazy
        totalRecords={
          !checklistsData || checklistsError
            ? 0
            : checklistsData.listChecklistsWithCategories.items
        }
        value={
          !checklistsData || checklistsError
            ? undefined
            : checklistsData.listChecklistsWithCategories.data
        }
        globalFilter={globalFilter}
        emptyMessage="No checklists found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedChecklists}
        onSelectionChange={e => setSelectedChecklists(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteChecklist,
          userPermissionsChecklist.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || checklistsLoading) && <Loading />}
    </div>
  );
};

export default Checklists;
