/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormHandles } from '@unform/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ValidationError } from 'yup';
import * as Yup from 'yup';
import { financialTransactionMenuItems } from './constants';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu';
import { FinancialTransactionRouteParams } from './interfaces';
import {
  createFinancialTransactionQuery,
  getFinancialTransactionByIdQuery,
  updateFinancialTransactionQuery,
  duplicateFinancialTransactionQuery,
  cancelFinancialTransactionReconciliationQuery,
} from './queries';
import GeneralInformation from './GeneralInformation';
import Loading from '../../../components/Loading';
import { RecurringPaymentsType } from '../../../shared/enums/domains';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useRefHook } from '../../../hooks/useRefHook';
import Updates from './Updates';
import FinancialTransactionAttachmentsList from '../FinancialTransactionAttachmentsList';

const FinancialTransaction: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);

  const [duplicateFinancialTransactionMutation] = useMutation(
    duplicateFinancialTransactionQuery,
  );

  const idFinancialTransaction = parseInt(
    useParams<FinancialTransactionRouteParams>().idFinancialTransaction,
    10,
  );

  // Referencia ao toast e formulario
  const { showError, showSuccess, showWarn } = useRefHook();

  // Estado de loading
  const [loading, setLoading] = useState(false);

  const defaultPageTitle = `${
    idFinancialTransaction ? 'Manage' : 'Create'
  } Bill To Pay / To Receive`;

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage') ?? '0',
    10,
  );
  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst') ?? '0',
    10,
  );

  const urlTabParam = new URLSearchParams(location.search).get('tab');

  const menuItems = useMemo(() => {
    const itemsList: IPageMenuItem[] = [];

    itemsList.push({
      key: financialTransactionMenuItems.generalInformation.key,
      label: financialTransactionMenuItems.generalInformation.label,
    });

    if (idFinancialTransaction) {
      itemsList.push(
        {
          key: financialTransactionMenuItems.attachments.key,
          label: financialTransactionMenuItems.attachments.label,
        },
        {
          key: financialTransactionMenuItems.updates.key,
          label: financialTransactionMenuItems.updates.label,
        },
      );
    }

    return itemsList;
  }, [idFinancialTransaction]);

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].key);

  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Bill To Pay / To Receive',
      path:
        initialPage && initialFirst
          ? `/financial/transactions?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/financial/transactions',
    },
    {
      name: defaultPageTitle,
      path: location.pathname,
    },
  ];

  const [createFinancialTransactionMutation] = useMutation(
    createFinancialTransactionQuery,
  );

  const [updateFinancialTransactionMutation] = useMutation(
    updateFinancialTransactionQuery,
  );

  const [cancelFinancialTransactionReconciliationMutation] = useMutation(
    cancelFinancialTransactionReconciliationQuery,
  );

  /**
   * Busca dados da Financial Transaction
   */
  const [
    loadFinancialTransactionData,
    {
      loading: financialTransactionLoading,
      data: financialTransactionData,
      refetch: refetchFinancialTransaction,
    },
  ] = useLazyQuery(getFinancialTransactionByIdQuery, {
    variables: {
      idFinancialTransaction,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Financial Transaction data',
        detail: errorData.message,
      });
    },
  });

  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/financial/transactions'),
    });
  }

  async function handleDuplicateFinancialTransaction() {
    setLoading(true);
    try {
      const response = await duplicateFinancialTransactionMutation({
        variables: {
          idFinancialTransaction,
        },
      });

      showSuccess({
        summary: 'Duplicated successfully',
        detail: (
          <>
            <p>New Bill To Pay / To Receive: </p>
            <Link
              to={`/financial/transactions/${response.data.duplicateFinancialTransaction.idFinancialTransaction}`}
              className="transparent-link"
            >
              {
                response.data.duplicateFinancialTransaction
                  .idFinancialTransaction
              }
            </Link>
          </>
        ),
      });
    } catch (error) {
      showError({
        summary: 'Error while duplicating Bill To Pay / To Receive',
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  function confirmDuplicateFinancialTransaction() {
    confirmDialog({
      message:
        'Are you sure you want to duplicate this Bill To Pay / To Receive?',
      header: 'Duplicate Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-primary',
      accept: handleDuplicateFinancialTransaction,
    });
  }

  async function validateForm() {
    try {
      formRef.current?.setErrors({});

      const formData = formRef.current?.getData();

      const schema = Yup.object().shape({
        idCountry: Yup.number().nullable().required('Required field'),
        idCompany: Yup.number().nullable().required('Required field'),
        idCurrency: Yup.number().nullable().required('Required field'),
        amount: Yup.number().nullable().required('Required field'),
        idCostCenterByCountry: Yup.number()
          .nullable()
          .required('Required field'),
        idFinancialTransactionCategory: Yup.number()
          .nullable()
          .required('Required field'),
        paymentForecast:
          formData?.idRecurringPayment !== RecurringPaymentsType.NO &&
          !idFinancialTransaction
            ? Yup.string().required('Required field')
            : Yup.string().nullable().notRequired(),
        reference: !formData?.idSatForeignTrade
          ? Yup.string().required('Required field')
          : Yup.string().nullable().notRequired(),
        idSatForeignTrade: !formData?.reference
          ? Yup.number().nullable().required('Required field')
          : Yup.number().nullable().notRequired(),
        idBeneficiary: !formData?.idSatForeignTrade
          ? Yup.number().nullable().required('Required field')
          : Yup.number().nullable().notRequired(),
        schedule:
          !idFinancialTransaction &&
          formData?.idRecurringPayment !== RecurringPaymentsType.NO
            ? Yup.number().nullable().required('Required field')
            : Yup.number().nullable().notRequired(),
        idSupplier: formData?.idSatForeignTrade
          ? Yup.number().nullable().required('Required field')
          : Yup.number().nullable().notRequired(),
      });

      await schema.validate(formData, { abortEarly: false });
    } catch (error) {
      const errors = getValidationErrors(error);
      formRef.current?.setErrors(errors);
      const firstError = error.inner[0];
      const inputWithError = formRef.current?.getFieldRef(firstError.path!);

      if (inputWithError) {
        inputWithError.focus();
      }

      showWarn({
        summary: 'Please fill all required fields',
        detail: error.message,
      });

      throw error;
    }
  }

  async function handleSubmit() {
    setLoading(true);

    const formData = formRef.current?.getData();

    try {
      await validateForm();

      if (!idFinancialTransaction) {
        const response = await createFinancialTransactionMutation({
          variables: {
            data: formData,
          },
        });

        if (response?.data.createFinancialTransaction) {
          // Direciona usuario para o cadastro
          history.push(
            `/financial/transactions/${response?.data.createFinancialTransaction.idFinancialTransaction}`,
          );
        }
      } else {
        await updateFinancialTransactionMutation({
          variables: {
            data: {
              ...formData,
              idFinancialTransaction,
              versionLock:
                financialTransactionData?.getFinancialTransactionById
                  .versionLock,
            },
          },
        });

        await refetchFinancialTransaction();
      }

      showSuccess({
        summary: `Financial Transaction ${
          idFinancialTransaction ? 'updated' : 'created'
        }`,
      });
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        showError({
          summary: `Error while ${
            idFinancialTransaction ? 'updating' : 'creating'
          } Financial Transaction`,
          detail: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCancelReconciliation() {
    confirmDialog({
      message:
        'This action will change the account balance. Are you sure you want to continue?',
      header: 'Cancel Reconciliation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        try {
          setLoading(true);

          await cancelFinancialTransactionReconciliationMutation({
            variables: {
              idFinancialTransaction,
            },
          });

          await refetchFinancialTransaction();

          showSuccess({
            summary: 'Reconciliation canceled successfully',
          });
        } catch (error) {
          showError({
            summary: `Error while canceling Financial Transaction reconciliation`,
            detail: error.message,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  }

  function renderChangedByUserInfo(field: 'createdAt' | 'updatedAt') {
    if (
      !idFinancialTransaction ||
      !financialTransactionData?.getFinancialTransactionById
    ) {
      return undefined;
    }
    const financialTransaction =
      financialTransactionData.getFinancialTransactionById;

    const userField = field === 'createdAt' ? 'createdBy2' : 'updatedBy2';

    return (
      <p className={field}>
        {new Date(financialTransaction[field]).toLocaleString()} by&nbsp;
        {financialTransaction[userField]?.firstName}{' '}
        {financialTransaction[userField]?.lastName}
      </p>
    );
  }

  const shouldRenderGeneralInformation =
    financialTransactionData?.getFinancialTransactionById
      ?.idFinancialTransaction || !idFinancialTransaction;

  // Valida se a pagina deve carregar os dados
  useEffect(() => {
    if (idFinancialTransaction) {
      loadFinancialTransactionData();
    }
  }, [loadFinancialTransactionData, idFinancialTransaction]);

  useEffect(() => {
    if (urlTabParam) {
      setSelectedMenuItem(urlTabParam);
    }
  }, [urlTabParam]);

  return (
    <div>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        <MainButton
          className="mainButton"
          label="Save"
          onClick={() => formRef.current?.submitForm()}
          disabled={
            financialTransactionData?.getFinancialTransactionById?.isReconciled
          }
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />

        {renderChangedByUserInfo('createdAt')}
        {renderChangedByUserInfo('updatedAt')}

        {idFinancialTransaction && (
          <Button
            className="secondaryButton"
            label="Duplicate"
            onClick={() => confirmDuplicateFinancialTransaction()}
          />
        )}

        {idFinancialTransaction && (
          <Button
            className="secondaryButton"
            label="Cancel Reconciliation"
            disabled={
              !financialTransactionData?.getFinancialTransactionById
                ?.isReconciled
            }
            onClick={handleCancelReconciliation}
          />
        )}
      </PageHeader>
      <div className="flex flex-column pb-2">
        <PageMenu
          menuFlexDirection="line"
          menuWidth="100%"
          setSelectedMenuItem={setSelectedMenuItem}
          selectedMenuItem={selectedMenuItem}
          items={menuItems}
          menuMarginBottom="30px"
          stickyMenuTopDistance="1260px"
        />
        {shouldRenderGeneralInformation && (
          <GeneralInformation
            idFinancialTransaction={idFinancialTransaction}
            financialTransaction={
              financialTransactionData?.getFinancialTransactionById
            }
            selected={
              selectedMenuItem ===
              financialTransactionMenuItems.generalInformation.key
            }
            formRef={formRef}
            onSubmit={handleSubmit}
          />
        )}

        <FinancialTransactionAttachmentsList
          idFinancialTransaction={idFinancialTransaction}
          selected={
            selectedMenuItem === financialTransactionMenuItems.attachments.key
          }
          showUploadButton
          showDeleteButton
          showFilesUpload
        />

        <Updates
          idFinancialTransaction={idFinancialTransaction}
          selected={
            selectedMenuItem === financialTransactionMenuItems.updates.key
          }
        />
      </div>
      {(loading || financialTransactionLoading) && <Loading />}
    </div>
  );
};

export default FinancialTransaction;
