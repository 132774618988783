import { gql } from '@apollo/client';

export const processRegisterUpdateMissingInfoQuery = gql`
  mutation processRegisterProductDetailsUpdateMissingInfo(
    $data: ProcessRegisterUpdateMissingInfoInput!
  ) {
    processRegisterProductDetailsUpdateMissingInfo(data: $data) {
      idSatProcessResult
      idStepStatus
      description
    }
  }
`;
