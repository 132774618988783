import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 48px;
  padding: 15px;
  display: flex;
  align-items: center;

  svg {
    color: var(--grey-scale);
  }

  a {
    margin: 0 7px;
    text-decoration: none;
    color: var(--blue);
    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }
  .active {
    color: #6c757d;
  }
`;
