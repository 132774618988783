import styled from 'styled-components';

export const Container = styled.div`
  padding: 7px 7px 0 7px;

  .mention__input {
    margin: 1em 0;
    border: none;
    outline: 0px;
  }

  .mention__suggestions {
    margin-top: 24px !important;
  }

  .mention__suggestions__item {
    padding: 5px 10px;
  }

  .mention__suggestions__item--focused {
    background-color: #f5f6f8;
  }

  .mention--multiLine .mention__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
  }

  .mention--multiLine .mention__input {
    border: 1px solid white;
    padding: 9px;
    outline: 0;
  }

  .s-mentions-mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
  }
`;
