import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { SatListPurchasePermissions } from './interfaces';

export const listSatListPurchaseViewQuery = (
  fieldsPermissions: SatListPurchasePermissions,
): DocumentNode => {
  /**
   * Fields que possuem permissao de visualizacao pelo usuario
   */
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListPurchaseView($data: ListSatListPurchaseViewInput!) {
      listSatListPurchaseView(data: $data) {
        items
        data {
          idImportStatus
          idSatForeignTrade
          satForeignTradeSupplierNumber
          status
          idSat
          idSupplierShipment
          idTypeOfOrder
          idSupplierExporter
          idClient
          idImporter
          idCountryOrigin
          idLoadingPort
          idPort
          idCategory
          idEstimatedTermCondition
          idPurchaseIncoterm
          idPaymentTermCondition
          idClientIncoterm
          idSalesUser
          idSalesSecondUser
          idComexExportUser
          idComexImportUser
          idDesignerUser
          idFinancialUser
          satCurrency
          ${fieldsString}
        }
      }
    }
  `;
};

export const exportListQuery = gql`
  query Query($data: ExportSatListPurchaseViewInput!) {
    exportSatListPurchaseView(data: $data)
  }
`;

/**
 * Busca os somatorios das colunas da Lista de Purchase para adicionar no
 * footer
 */
export const getSumDataFromSatPurchaseListQuery = gql`
  query GetSumDataFromSatPurchaseList(
    $data: ListSatListPurchaseViewFieldsSearch
  ) {
    getSumDataFromSatPurchaseList(data: $data) {
      satCount
      quantityPiBySun
      purchaseEstimatedAdvance
      purchaseEstimatedBalance
      totalCotPi
      totalSunCi
      purchaseEstimatedTotalAdvance
      purchaseEstimatedTotalBalance
      totalSunPi
      estimatedGrossMargin
      estimatedNetMargin
      grossMargin
      netMargin
      totalCotCi
      grossProfit
      estimatedGrossProfit
      estimatedNetProfit
      netProfit
    }
  }
`;
