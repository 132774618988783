import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .user-avatar-wrapper {
    margin-right: 8px;
  }
`;

export const CommentContent = styled.div`
  width: 100%;
  .comment-header {
    display: flex;
    gap: 8px;
  }

  .comment-text {
    margin-top: 4px;
  }
`;

export const Actions = styled.div`
  margin-top: 6px;
  display: flex;
  gap: 12px;
`;
