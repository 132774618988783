import { ISatCtnr } from '../interfaces';

// eslint-disable-next-line no-shadow
export enum SatGeneralInformationActionKind {
  ADD_CTNR,
  REMOVE_CTNR,
  SET_CHANGED_CNTR,
  RESET_CTNRS_STATES,
}

export interface ISatGeneralInformationReducer {
  satCtnrs: ISatCtnr[];
  satCtnrsToUpdate: number[];
  satCtnrsToRemove: number[];
}

export interface ISatGeneralInformationReducerAction {
  type: SatGeneralInformationActionKind;
  payload?: {
    idCtnr?: number | string;
    satCtnrs?: ISatCtnr[];
  };
}

type GeneralInformationCommonProps = {
  mainProduct?: string;
  productLine?: string;
  idCategory: number;
  idTypeOperation: number;
  typeOfOrder: number;
  idSatOrigin: number;
  indSamples: boolean;
  samplesLeadTime?: number;
  packagesNotes?: string;
  remarks?: string;
  idClient: number;
  clientDepartment: number;
  idFirstBuyer?: number;
  idSecondBuyer?: number;
  idImporter?: number;
  idClientIncoterm: number;
  paymentTermAdvance?: number;
  idPaymentTermCondition: number;
  idPurchaseIncoterm: number;
  idSupplierExporter?: number;
  productionTime?: number;
  manufacture?: string;
  idLoadingPort: number;
  idPort: number;
  estimatedInspectionDate: Date;
  estimatedShipmentDate: Date;
  estimatedArrivalDate: Date;
  estimatedArrivalClientDate: Date;
  satGroup?: number[];
  idPurchaseUser: number;
  idSalesUser: number;
  idSalesSecondUser: number;
  idComexExportUser?: number;
  idComexImportUser?: number;
  idDesignerUser?: number;
  idRegisterUser?: number;
  stock?: boolean;
};

export interface ISatScheduleRelatedItems {
  [key: string]: number | boolean | undefined;
  indSamples?: boolean;
  samplesLeadTime?: number;
  productionTime?: number;
}

type SatCtnrFormData = {
  idSatCtnr: number | string;
  qtyCtnr: number;
  idTypeCtnr: number;
};

export type UpdateSatGeneralInformationFormData =
  GeneralInformationCommonProps & {
    satCtnrs?: SatCtnrFormData[];
    companyName: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    clientCnpj: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    clientAddress: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    importerName?: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    importerCnpj?: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    importerAddress?: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    exporterAddress?: string; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    satSuppliers?: number[]; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    idCountryOrigin?: number; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    idCountryAcquisition?: number; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    idCountryProcedence?: number; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
    paymentTermBalance?: number; // Propriedade enviada pelo formulario mas populada automaticamente pelo backend
  };

export type SatCtnrToCreate = {
  qtyCtnr: number;
  idTypeCtnr: number;
};

export type SatCtnrToUpdate = {
  idSatCtnr: number;
  qtyCtnr: number;
  idTypeCtnr: number;
};

export type GeneralInformationFormSubmitResponse =
  GeneralInformationCommonProps & {
    satCtnrsToCreate: SatCtnrToCreate[];
    satCtnrsToUpdate: SatCtnrToUpdate[];
    satCtnrsToRemove: number[];
  };
