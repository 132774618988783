import styled from 'styled-components';

export const Container = styled.div`
  .grid-input {
    input {
      width: 100%;
    }
  }

  .field-radiobutton {
    label {
      display: flex;
      align-items: center;

      .p-radiobutton {
        margin-left: 2px;
      }
    }
  }

  textarea {
    resize: vertical;
  }
`;

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  .grid-filters {
    display: flex;
    .p-inputtext {
      margin-right: 12px;
    }
  }

  .grid-header-buttons {
    display: flex;
    button {
      margin-right: 12px;
    }
  }
`;

export const TotalDetails = styled.div`
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 12px;

  div {
    display: flex;
    margin-top: 10px;
    h3 {
      margin-bottom: 6px;
    }
    b {
      font-weight: 500;
    }

    span + span {
      margin-left: 12px;
      border-left: 1px solid #eee;
      padding-left: 12px;
    }
  }
`;
