import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  airportTaxName: {
    field: 'airportTaxName',
    header: 'Airport Tax Name',
    advancedFilterField: 'airportTaxName',
  },
  airportDestination: {
    field: 'idAirportDestination2.name',
    header: 'Airport Destination',
    advancedFilterField: 'airportDestination',
  },
  taxValueDes: {
    field: 'taxValueDes',
    header: 'Desp Aeroportuárias',
    advancedFilterField: 'taxValueDes',
  },
  idCurDes: {
    field: 'idCurDes2.abbreviation',
    header: 'Currency Des',
    advancedFilterField: 'idCurDes',
  },
  idBcValueDes: {
    field: 'idBcValueDes2.description',
    header: 'BC Value Des',
    advancedFilterField: 'idBcValueDes',
  },
  bcCifDes: {
    field: 'bcCifDes',
    header: 'BC CIF Des',
    advancedFilterField: 'bcCifDes',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtDes: {
    field: 'updatedAtDes',
    header: 'Last Updated Des',
    advancedFilterField: '',
  },
  taxValueArm: {
    field: 'taxValueArm',
    header: 'Armaz Porto (10 dias)',
    advancedFilterField: 'taxValueArm',
  },
  idCurArm: {
    field: 'idCurArm2.abbreviation',
    header: 'Currency Arm',
    advancedFilterField: 'idCurArm',
  },
  idBcValueArm: {
    field: 'idBcValueArm2.description',
    header: 'BC Value Arm',
    advancedFilterField: 'idBcValueArm',
  },
  bcCifArm: {
    field: 'bcCifArm',
    header: 'BC CIF Arm',
    advancedFilterField: 'bcCifArm',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAtArm: {
    field: 'updatedAtArm',
    header: 'Last Updated Arm',
    advancedFilterField: '',
  },
};
