import { gql } from '@apollo/client';

export const createSupplierAssessmentQuery = gql`
  mutation CreateSupplierAssesment($data: CreateSupplierAssesmentInput!) {
    createSupplierAssesment(data: $data) {
      idSupplierAssesment
    }
  }
`;

export const updateSupplierAssessmentQuery = gql`
  mutation UpdateSupplierAssesment($data: UpdateSupplierAssesmentInput!) {
    updateSupplierAssesment(data: $data) {
      idSupplierAssesment
    }
  }
`;
