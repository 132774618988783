import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

/**
 * Carrega Financial Transaction Categories
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados de Financial Transaction Categories
 */
export const asyncSelectLoadFinancialTransactionCategories: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query ListAllFinancialTransactionCategories(
        $data: ListAllFinancialTransactionCategoriesInput!
      ) {
        listAllFinancialTransactionCategories(data: $data) {
          items
          data {
            idFinancialTransactionCategory
            name
            group
          }
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
          _orderBy: 'name',
        },
        name: search,
      },
    },
  });

  const filteredOptions = res.data.listAllFinancialTransactionCategories.data;
  const quantity = res.data.listAllFinancialTransactionCategories.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};

/**
 * Carrega Financial Transaction Category Groups
 * @param search Valor escrito no input
 * @returns Dados de Financial Transaction Category Groups
 */
export const asyncSelectLoadDistinctFinancialTransactionCategoryGroups: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string) => {
  const res = await client.query({
    query: gql`
      query ListDistinctFinancialTransactionCategoryGroups($group: String) {
        listDistinctFinancialTransactionCategoryGroups(group: $group)
      }
    `,
    variables: {
      group: search,
    },
  });

  const filteredOptions =
    res.data.listDistinctFinancialTransactionCategoryGroups.map(
      (group: string) => ({
        label: group,
        value: group,
      }),
    );

  return {
    options: filteredOptions,
    hasMore: false,
  };
};
