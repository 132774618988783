/* eslint-disable no-shadow */

import { SatOtherExpense } from '../interfaces';

export enum ManageOtherExpenseReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_CALCULATION_BASIS_REQUIRED,
}

export type ManageOtherExpenseReducer = {
  displayDialog: boolean;
  otherExpenseToEdit?: SatOtherExpense;
  flagsAndCalcBasisFieldsDisabled?: boolean;
  totalFieldDisabled?: boolean;
  calculationBasisRequired?: boolean;
};

export type ManageOtherExpenseReducerAction = {
  type: ManageOtherExpenseReducerActionKind;
  payload?: {
    otherExpense?: SatOtherExpense;
    calculationBasisRequired?: boolean;
  };
};

export type ManageOtherExpenseFormData = {
  idType: number;
  total: number;
  dateOtherExpense: string;
  comment?: string;
  linkToMargin?: number;
  creditToClient?: number;
  apportionment?: number;
  calculationBasis?: number;
};
