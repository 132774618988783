import { gql } from '@apollo/client';

export const createNcmBulkQuery = gql`
  mutation createNcmBulkMutation($uploadedFile: String!) {
    createNcmBulk(uploadedFile: $uploadedFile) {
      total
      created
      errors
    }
  }
`;

export const updateNcmBulkQuery = gql`
  mutation updateNcmBulkMutation($uploadedFile: String!) {
    updateNcmBulk(uploadedFile: $uploadedFile) {
      total
      updated
      errors
    }
  }
`;
