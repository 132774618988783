import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

type ListSatForeignTradeSuppliersSummarizedInput = {
  page: any;
  idSat?: number;
  idClient?: number;
  idSupplier?: number;
  idSatForeignTrade?: number;
};

/**
 * Carrega Sat Foreign Trade Suppliers Resumidos
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados de Domain
 */
export const asyncSelectLoadSatForeignTradeSupplierSummarized: LoadOptions<
  any,
  GroupBase<any>,
  ListSatForeignTradeSuppliersSummarizedInput
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listSatForeignTradeSuppliersSummarizedQuery(
        $data: ListSatForeignTradeSuppliersSummarizedInput!
      ) {
        listSatForeignTradeSuppliersSummarized(data: $data) {
          data {
            idSatForeignTradeSupplier
            idSatForeignTrade
            idSatSupplier
            idSupplier
            idSat
            satShipment
            appliedOn
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
          _orderBy: 'satForeignTradeSupplier.idSat',
          _sortOrder: 'ASC',
        },
        idSat: pageData?.idSat,
        idClient: pageData?.idClient,
        idSupplier: pageData?.idSupplier,
        idSatForeignTrade: pageData?.idSatForeignTrade,
        searchInput: search,
      },
    },
  });

  const filteredOptions = res.data.listSatForeignTradeSuppliersSummarized.data;
  const quantity = res.data.listSatForeignTradeSuppliersSummarized.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idSat: pageData?.idSat,
      idClient: pageData?.idClient,
      idSupplier: pageData?.idSupplier,
      idSatForeignTrade: pageData?.idSatForeignTrade,
    },
  };
};
