/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-return-assign */

/**
 * Busca no na arvore
 * @param root Raiz da arvore
 * @param key Key do no a ser encontrado
 * @returns No encontrado
 */
export function findNodeOnTree(root: any[], key: any): any {
  let node;

  root.some(function a(n) {
    if (n.key === key) {
      return (node = n);
    }
    if (n.children) {
      return (node = findNodeOnTree(n.children, key));
    }
  });
  return node || null;
}

/**
 * Busca hierarquia do no
 * @param root Raiz da arvore
 * @param key Key do no a ser encontrado
 * @returns Nome da hierarquia
 */
export function findParentsOnTree(root: any[], key: any): any {
  const names = [];
  let idParent = key;
  while (idParent !== 0) {
    const node: any = findNodeOnTree(root, idParent);
    if (node) {
      names.unshift(node.label);
      if (node.data.idParentCategory) {
        idParent = node.data.idParentCategory;
      } else {
        idParent = 0;
      }
    } else {
      idParent = 0;
    }
  }

  return names.join(' - ');
}
