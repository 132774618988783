import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  name: { field: 'name', header: 'Name', advancedFilterField: 'name' },
  country: {
    field: 'idCountry2.name',
    header: 'Country',
    advancedFilterField: 'country',
  },
  city: { field: 'idCity2.name', header: 'City', advancedFilterField: 'city' },
  type: {
    field: 'type2.description',
    header: 'Type',
    advancedFilterField: 'type',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: false,
      domainGroup: DomainGroup.TYPE_OF_PORT,
    },
  },
};
