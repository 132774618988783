import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .svg-left {
    margin-right: 12px;
  }

  .svg-right {
    margin-left: 12px;
  }

  button {
    display: flex;
    align-items: center;
    place-content: center;
    border: none;
    background: none;
    color: var(--blue);
    transition: color 0.2s;
    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }
`;
