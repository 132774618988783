/* eslint-disable no-shadow */
export enum FileType {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = '.pdf',
  ALL_IMAGES = 'image/*',
  TXT = 'text/plain',
  ZIP = 'application/x-zip-compressed',
}
