import isNullOrUndefined from './isNullOrUndefined';

/**
 * Divide um valor por outro
 * @param dividend Dividendo
 * @param divisor Divisor
 * @returns Valor dividido ou undefined se o valor ou o divisor forem nulos ou indefinidos
 */
export function divide(
  dividend?: number,
  divisor?: number,
): number | undefined {
  if (isNullOrUndefined(dividend) || !divisor) return undefined;

  return dividend / divisor;
}

/**
 * Subtrai um valor de outro
 * @param minuend Minuendo
 * @param subtrahend Subtraendo
 * @returns
 */
export function subtract(
  minuend?: number,
  subtrahend?: number,
): number | undefined {
  if (isNullOrUndefined(minuend) || isNullOrUndefined(subtrahend)) {
    return undefined;
  }

  return minuend - subtrahend;
}

/**
 * Efetua soma de dois valores
 * @param a Primeiro valor
 * @param b Segundo valor
 * @returns Soma ou undefined
 */
export function sum(a?: number, b?: number): number | undefined {
  if (isNullOrUndefined(a) || isNullOrUndefined(b)) return undefined;

  return a + b;
}

/**
 * Multiplica dois valores
 * @param a Primeiro valor
 * @param b Segundo valor
 * @returns Multiplicacao ou undefined
 */
export function multiply(a?: number, b?: number): number | undefined {
  if (isNullOrUndefined(a) || isNullOrUndefined(b)) return undefined;

  return a * b;
}
