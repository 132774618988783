import React, { Dispatch } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import {
  SatForeignTradeItem,
  SatForeignTradeItemContainer,
  SatItemReference,
} from '../interfaces';
import {
  ForeignTradeContainerItemsPermissions,
  ForeignTradeReducerAction,
  ForeignTradeReducerActionKind,
} from '../../../interfaces';
import { parseCurrencyPtBrNumberColumn } from '../../../../../../../utils/gridColumnsParse';
import { SatForeignTradeShipmentContainer } from '../../interfaces';
import { getAllRowsAsTrue } from '../../../../../../../components/Grid/utils';
import ReferenceSizes from './ReferenceSizes';
import { numberToPtString } from '../../../../../../../utils/formatLocale';
import { Container } from './styles';
import { containerColumns } from '../constants';

interface ISatItemReferencesProps {
  foreignTradeItem: SatForeignTradeItem;
  userCanDeleteContainerItem: boolean;
  shipmentIsCanceledOrStandBy: boolean;
  containerItemsPermissions: ForeignTradeContainerItemsPermissions;
  satCurrency?: string;
  shipmentContainers?: SatForeignTradeShipmentContainer[];
  foreignTradesDispatch: Dispatch<ForeignTradeReducerAction>;
  containerItemsRowIndex: number;
}

const SatItemReferences: React.FC<ISatItemReferencesProps> = ({
  foreignTradeItem,
  userCanDeleteContainerItem,
  shipmentIsCanceledOrStandBy,
  containerItemsPermissions,
  satCurrency,
  shipmentContainers,
  foreignTradesDispatch,
  containerItemsRowIndex,
}) => {
  const gridDataKey = 'idSatItemReference';

  const allGridRowsAsTrue = getAllRowsAsTrue(
    gridDataKey,
    foreignTradeItem.satItemReferences,
  );

  function getContainerQuantitySum(
    satItemReference: SatItemReference,
    idSatForeignTradeCtnr: number,
    field: string,
  ) {
    const ftReferencesIds = satItemReference.satForeignTradeItemReferences.map(
      ftItemReference => ftItemReference.idSatForeignTradeItemReference,
    );

    const containerReferences =
      foreignTradeItem.satForeignTradeItemCtnrs?.filter(
        ctnr =>
          ctnr.idSatForeignTradeCtnr === idSatForeignTradeCtnr &&
          ctnr.idSatForeignTradeItemReference &&
          ftReferencesIds.includes(ctnr.idSatForeignTradeItemReference),
      );

    const itemReferenceContainerQuantitySum = containerReferences?.reduce(
      (acc, curr) => {
        const quantity = curr[field as keyof SatForeignTradeItemContainer] ?? 0;

        return acc + quantity;
      },
      0,
    );

    return numberToPtString(itemReferenceContainerQuantitySum);
  }

  const onNumberEditorValueChange = (props: any, value: number | null) => {
    const updatedReference = props.props.value[props.rowIndex];
    updatedReference[props.field] = Number(value);

    foreignTradesDispatch({
      type: ForeignTradeReducerActionKind.SET_GRID_SAT_ITEM_REFERENCE_TO_UPDATE,
      payload: {
        satItemReferenceToUpdate: {
          idSatForeignTradeItem: foreignTradeItem.idSatForeignTradeItem,
          idSatItemReference: updatedReference.idSatItemReference,
          quantityPiShipment: updatedReference.totalQuantityPiShipment,
        },
      },
    });
  };

  /**
   * Gera ID do input para a grid
   * @param field Coluna da grid
   * @param rowIndex Indice do objeto da grid
   * @param outerTableRowIndex Indice da linha da tabela que envolve a grid
   * @returns ID para o Input da grid
   */
  function getInputId(
    field: string,
    rowIndex: number,
    outerTableRowIndex = containerItemsRowIndex,
  ) {
    return `containerItems-${foreignTradeItem.idSatForeignTrade}-satItemReferences-${outerTableRowIndex}-${field}-${rowIndex}`;
  }

  /**
   * Gera foco no proximo input ao pressionar ENTER
   * @param e Evento do teclado
   * @param field Coluna da grid
   * @param index Indice da grid
   */
  function handleGoToNextField(
    e: React.KeyboardEvent<HTMLInputElement>,
    field: string,
    index: number,
  ) {
    if (e.key === 'Enter') {
      // Busca o input diretamente na proxima linha
      const inputId = getInputId(field, index + 1);

      const inputBelow = document.getElementById(inputId);

      if (inputBelow) {
        inputBelow.focus();
      } else {
        const inputOnOuterTable = getInputId(
          field,
          0, // O input deve estar na primeira linha
          containerItemsRowIndex + 1,
        );

        document.getElementById(inputOnOuterTable)?.focus();
      }
    }
  }

  const rowExpansionTemplate = (
    satItemReference: SatItemReference,
    props: { index: number },
  ) => {
    return (
      <ReferenceSizes
        satItemReference={satItemReference}
        shipmentContainers={shipmentContainers}
        foreignTradesDispatch={foreignTradesDispatch}
        containerItemsPermissions={containerItemsPermissions}
        userCanDeleteContainerItem={userCanDeleteContainerItem}
        shipmentIsCanceledOrStandBy={shipmentIsCanceledOrStandBy}
        satCurrency={satCurrency}
        foreignTradeItem={foreignTradeItem}
        containerItemsRowIndex={containerItemsRowIndex}
        satItemReferenceRowIndex={props.index}
      />
    );
  };

  const renderNumberEditor = (props: any, decimalPlaces?: number) => {
    const isCancelledOrWithoutPermission =
      !containerItemsPermissions[
        props.field as keyof ForeignTradeContainerItemsPermissions
      ].edit || shipmentIsCanceledOrStandBy;

    if (isCancelledOrWithoutPermission) return props.rowData[props.field];

    return (
      <InputNumber
        inputId={getInputId(props.field, props.rowIndex)}
        value={props.rowData[props.field]}
        onChange={e => {
          onNumberEditorValueChange(props, e.value);
        }}
        onKeyDown={e => handleGoToNextField(e, props.field, props.rowIndex)}
        locale="pt-BR"
        min={0}
        maxFractionDigits={decimalPlaces || 0}
        allowEmpty={false}
      />
    );
  };

  const renderContainerColuns = shipmentContainers?.map(container => {
    return containerColumns.map(column => {
      if (
        !containerItemsPermissions[
          column.field as keyof ForeignTradeContainerItemsPermissions
        ].view
      ) {
        return undefined;
      }

      return (
        <Column
          key={`${container.idSatForeignTradeCtnr}-${column.field}`}
          field={column.field}
          style={{ width: '8rem' }}
          editor={options =>
            getContainerQuantitySum(
              options.rowData,
              container.idSatForeignTradeCtnr,
              column.field,
            )
          }
        />
      );
    });
  });

  return (
    <Container>
      {!!foreignTradeItem.satItemReferences?.length && (
        <DataTable
          value={foreignTradeItem.satItemReferences}
          editMode="row"
          editingRows={allGridRowsAsTrue}
          expandedRows={allGridRowsAsTrue}
          onRowEditChange={() => ''}
          dataKey={gridDataKey}
          rowExpansionTemplate={rowExpansionTemplate}
          rowClassName={() => 'item-references-table'}
          tableStyle={{
            tableLayout: 'fixed',
          }}
        >
          {userCanDeleteContainerItem && !shipmentIsCanceledOrStandBy && (
            <Column
              field="multiple"
              style={{
                width: '2rem',
              }}
            />
          )}
          {userCanDeleteContainerItem && !shipmentIsCanceledOrStandBy && (
            <Column field="actions" style={{ width: '3rem' }} />
          )}

          {containerItemsPermissions.stCode.view && (
            <Column field="colourEn" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.quantity.view && (
            <Column field="totalQtyPiSize" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.quantityPiShipment.view && (
            <Column
              field="totalQuantityPiShipment"
              style={{ width: '7rem' }}
              editor={props => renderNumberEditor(props)}
            />
          )}

          {containerItemsPermissions.sizeRange.view && (
            <Column field="sizeRange" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.quantityCi.view && (
            <Column field="totalQuantityCi" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.cotPrice.view && (
            <Column field="cotPrice" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.sunPrice.view && (
            <Column field="sunPrice" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.totalCotCi.view && (
            <Column
              field="totalCotCi"
              style={{ width: '8rem' }}
              body={(data, props) =>
                parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
              }
            />
          )}

          {containerItemsPermissions.totalSunCi.view && (
            <Column
              field="totalSunCi"
              style={{ width: '8rem' }}
              body={(data, props) =>
                parseCurrencyPtBrNumberColumn(data, props, 2, satCurrency)
              }
            />
          )}

          {containerItemsPermissions.piecesContainer.view && (
            <Column field="piecesContainer" style={{ width: '7rem' }} />
          )}

          {containerItemsPermissions.remainQuantityPiShipment.view && (
            <Column
              field="remainQuantityPiShipment"
              style={{ width: '7rem' }}
            />
          )}

          {renderContainerColuns}
        </DataTable>
      )}
    </Container>
  );
};

export default SatItemReferences;
