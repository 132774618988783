import { gql } from '@apollo/client';

export const listSatArtworkBySatIdQuery = gql`
  query listSatArtworkBySatId($idSat: Int!) {
    listSatArtworkBySatId(idSat: $idSat) {
      idSatArtwork
      dueDate
      idDesignLevel
      idDesignLevel2 {
        idDomain
        description
      }
      newLayout
      activities
      artworkQuantity
      designNotes
      internalInfo
      clientInfo
      hrp
      idSupportResponsible
      startDoing
      sentLayout
      layoutApproved
      idDesignResponsible
      designReview
      idReviewResponsible
      commercialReview
      idCommercialReviewResponsible
      sentToClient
      approvedByClient
      idCommercialResponsible
      licensorApproval
      sentFinalArtWork
      idFinalArtWorkResponsible
      sketchesReceived
      idSketchesResponsible
      artworkDone
      waitingRevisedSketches
      hrpNotRequired
      diecutNotRequired
      sampleNotRequired
      clientInfoNotRequired
      idSupportResponsible2 {
        idUser
        firstName
        lastName
      }
      idDesignResponsible2 {
        idUser
        firstName
        lastName
      }
      idReviewResponsible2 {
        idUser
        firstName
        lastName
      }
      idCommercialReviewResponsible2 {
        idUser
        firstName
        lastName
      }
      idCommercialResponsible2 {
        idUser
        firstName
        lastName
      }
      idFinalArtWorkResponsible2 {
        idUser
        firstName
        lastName
      }
      idSketchesResponsible2 {
        idUser
        firstName
        lastName
      }
      idStatusArtwork
      idStatusArtwork2 {
        idDomain
        description
      }
    }
  }
`;

export const updateSatArtworkQuery = gql`
  mutation UpdateSatArtwork($data: UpdateSatArtworkInput!) {
    updateSatArtwork(data: $data) {
      idSatArtwork
    }
  }
`;

/**
 * Query para baixar SAT Artwork Files
 */
export const downloadSatArtworkFilesQuery = gql`
  query Query($idsSatAttachments: [Int]!) {
    downloadSatArtworkFiles(idsSatAttachments: $idsSatAttachments)
  }
`;
