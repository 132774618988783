/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';

import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { useHistory, useLocation } from 'react-router-dom';
import ILazyParams from '../../../../services/lazyParams';
import { Container } from './styles';
import Grid from '../../../../components/Grid';
import Loading from '../../../../components/Loading';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { ISupplier } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { satsRoles } from '../../../../shared/roles/sat';
import getFieldPermission from '../../../../utils/getFieldPermission';
import ToastLife from '../../../../shared/enums/toastLife';
import { parseSatColumn } from '../../../../utils/gridColumnsParse';
import { supplierRoles } from '../../../../shared/roles/supplier';
import { gridConstants } from '../../../../components/Grid/constants';

/**
 * Interface do input de filtros
 */
interface IFilterInput {
  field?: string;
  header?: string;
  value?: any;
  type?: string;
}

/**
 * Interface dos parametros do backend
 */
interface IProductsLazyParams extends ILazyParams {
  globalFilter?: string;
  satNumber?: string;
  client?: string;
  taxId?: string;
  productionTime?: number;
  salesUser?: string;
  expireDate?: Date;
  portOfDischarge?: string;
  currency?: string;
}

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  supplier: ISupplier;
  setSupplier: Dispatch<SetStateAction<ISupplier>>;
  roleEntityFields: IRoleEntityField[];
}

const Sats: React.FC<IGeneralInformationProps> = ({
  selected,
  supplier,
  roleEntityFields,
}) => {
  // Redirect
  const history = useHistory();

  // URL params
  const location = useLocation();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Estado de loading
  const [pageLoading] = useState(false);

  const {
    idFieldIdClient,
    idFieldIdImporter,
    idFieldIdSalesUser,
    idFieldSatNumber,
    idFieldTypeOfOrder,
  } = satsRoles.fields;

  const {
    idFieldAmountCotTotal,
    idFieldAmountTotal,
    idFieldIdCurrency,
    idFieldQtyTypeCtnr,
  } = supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    if (showField(idFieldSatNumber)) {
      columnList.push({ field: 'satNumber', header: 'SAT Number' });
    }

    if (showField(idFieldIdClient)) {
      columnList.push({ field: 'idClient2.name', header: 'Client' });
    }

    if (showField(idFieldIdImporter)) {
      columnList.push({ field: 'idImporter2.name', header: 'Importer' });
    }

    if (showField(idFieldIdSalesUser)) {
      columnList.push({ field: 'idSalesUser2.firstName', header: 'Sales' });
    }

    if (showField(idFieldIdCurrency)) {
      columnList.push({ field: 'idCurrency2.currency', header: 'Currency' });
    }

    if (showField(idFieldQtyTypeCtnr)) {
      columnList.push({ field: 'qtyTypeCtnr', header: 'Qty Type Ctnr' });
    }

    if (showField(idFieldTypeOfOrder)) {
      columnList.push({ field: 'typeOfOrder', header: 'Type of Order' });
    }

    if (showField(idFieldAmountTotal)) {
      columnList.push({ field: 'amountTotal', header: 'Amount Total' });
    }

    if (showField(idFieldAmountCotTotal)) {
      columnList.push({ field: 'amountCotTotal', header: 'Amount Cot Total' });
    }

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Colunas da grid que possuem filtro aplicado
  const [filteredColumnsHeader] = useState<IFilterInput[]>([]);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
    _orderBy: 'idSat',
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] =
    useState<IProductsLazyParams>(initialLazyParams);

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Query para listar sats
  const listSatsQuery = gql`
    query listAllProductsBySupplierQuery($listAllSatsInput: ListAllSatsInput!) {
      listAllSatsBySupplier(listAllSatsInput: $listAllSatsInput) {
        items
        data {
          idSat
          ${showField(idFieldSatNumber) ? 'satNumber' : ''}
          ${
            showField(idFieldIdClient)
              ? `idClient2 {
                  name
                  cnpj
                }`
              : ''
          }
          ${
            showField(idFieldIdImporter)
              ? `idImporter2 {
                  name
                  cnpj
                }`
              : ''
          }
          satSuppliers {
            productionTime
          }
          ${
            showField(idFieldIdSalesUser)
              ? `idSalesUser2 {
                  firstName
                  lastName
                }`
              : ''
          }
          expireDate
          idPort2 {
            name
          }
          ${
            showField(idFieldIdCurrency)
              ? `idCurrency2 {
                  currency
                }`
              : ''
          }
          ${showField(idFieldQtyTypeCtnr) ? 'qtyTypeCtnr' : ''}
          ${showField(idFieldTypeOfOrder) ? 'typeOfOrder' : ''}
          ${showField(idFieldAmountTotal) ? 'amountTotal' : ''}
          ${showField(idFieldAmountCotTotal) ? 'amountCotTotal' : ''}
        }
      }
    }
  `;

  /**
   * Busca dados de production Time
   * @param rowData
   * @returns Informacao de production Time
   */
  const parseProductionTimeColumn = (rowData: any) => {
    if (rowData.satSuppliers && rowData.satSuppliers.length) {
      return <p>{rowData.satSuppliers[0].productionTime}</p>;
    }
    return undefined;
  };

  const parseSalesUserNameColumn = (rowData: any) => {
    if (rowData.idSalesUser2) {
      return (
        <p>
          {rowData.idSalesUser2?.firstName} {rowData.idSalesUser2?.lastName}
        </p>
      );
    }

    return undefined;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'satSuppliers[0].productionTime':
        return parseProductionTimeColumn;
      case 'idSalesUser2.firstName':
        return parseSalesUserNameColumn;
      case 'satNumber':
        return parseSatColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'active':
        return { width: '100px' };
      case 'indVerified':
        return { width: '110px' };
      case 'licensedProduct':
        return { width: '190px' };
      case 'unit2.description':
        return { width: '110px' };
      case 'detailedDescriptionPt':
      case 'materialEn':
      case 'materialPt':
      case 'clientsString':
      case 'nameEn':
      case 'namePt':
        return { width: '200px' };
      case 'descriptionEn':
      case 'descriptionPt':
      case 'brandsString':
        return { width: '300px' };
      case 'model':
        return { width: '150px' };
      case 'foreignTradeReview':
        return { width: '160px' };
      case 'idNcmEnhance':
        return { width: '170px' };
      default:
        return { width: '140px' };
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
        />
      )
    );
  });

  /**
   * Busca SATs
   */
  const { loading: satsLoading, data: satsData } = useQuery(listSatsQuery, {
    variables: {
      listAllSatsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        idSupplier: supplier.idSupplier,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting SATs',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Direciona usuario para pagina do item clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/commercial/sats/${e.data.idSat}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(
      '@SAT:satsGridColumns',
    );

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <Grid
          name="sats"
          lazy
          totalRecords={
            !satsData || !satsData.listAllSatsBySupplier
              ? 0
              : satsData.listAllSatsBySupplier.items
          }
          value={
            !satsData || !satsData.listAllSatsBySupplier
              ? undefined
              : satsData.listAllSatsBySupplier.data
          }
          emptyMessage="No SATs found."
          onRowClick={onRowClick}
          reorderableColumns
          removableSort
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          rows={lazyParams.rows}
          first={lazyParams.first}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
        >
          {dynamicColumns}
        </Grid>
        {(pageLoading || satsLoading) && <Loading />}
      </Container>
    </PageTabContainer>
  );
};

export default Sats;
