import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  portAirPortLoading: {
    field: 'idPortAirportLoading2.name',
    header: 'Port/Airport Of Loading',
    advancedFilterField: 'portAirPortLoading',
  },
  portAirPortDestination: {
    field: 'idPortAirportDestination2.name',
    header: 'Port/Airport Of Destination',
    advancedFilterField: 'portAirPortDestination',
  },
  valor20Gp: {
    field: 'valor20Gp',
    header: 'Value 20’ GP',
    advancedFilterField: 'valor20Gp',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  curValor20Gp: {
    field: 'idCurValor20Gp2.abbreviation',
    header: 'Currency',
    advancedFilterField: 'curValor20Gp',
  },
  valor40Hc: {
    field: 'valor40Hc',
    header: 'Value 40’ HC',
    advancedFilterField: 'valor40Hc',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  curValor40Hc: {
    field: 'idCurValor40Hc2.abbreviation',
    header: 'Currency',
    advancedFilterField: 'curValor40Hc',
  },
  bcValor: {
    field: 'idBcValor2.description',
    header: 'BC Value',
    advancedFilterField: 'bcValor',
  },
  bcCif: {
    field: 'bcCif',
    header: 'BC CIF',
    advancedFilterField: 'bcCif',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAt: {
    field: 'updatedAt',
    header: 'Last Updated',
    advancedFilterField: '',
  },
};
