import { DocumentNode, gql } from '@apollo/client';
import { SatListCommercialPermissions } from './interfaces';

export const listSatListCommercialViewQuery = (
  fieldsPermissions: SatListCommercialPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListCommercialView($data: ListSatListCommercialViewInput!) {
      listSatListCommercialView(data: $data) {
        items
        data {
          idSat
          satCurrency
          ${fieldsPermissions.shipment.view ? 'idSatForeignTrade' : ''}
          ${fieldsPermissions.exporter.view ? 'idSupplierExporter' : ''}
          ${fieldsPermissions.importerConsignee.view ? 'idImporter' : ''}
          ${fieldsPermissions.clientNotify.view ? 'idClient' : ''}
          ${fieldsPermissions.status.view ? 'statusSat' : ''}
          ${fieldsString}
        }
      }
    }
  `;
};

/**
 * Busca os somatorios das colunas da Lista de Commercial para adicionar no
 * footer
 */
export const getSumDataFromSatCommercialListQuery = gql`
  query GetSumDataFromSatCommercialList(
    $data: SatListCommercialViewFieldsSearch
  ) {
    getSumDataFromSatCommercialList(data: $data) {
      quantitySatPi
      quantityShipmentCi
      satCount
      sellerTotalSunCi
      sellerTotalSunPi
    }
  }
`;
