import { AdvancedFiltersFieldType } from '../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  firstName: {
    field: 'firstName',
    header: 'First Name',
    advancedFilterField: 'firstName',
  },
  lastName: {
    field: 'lastName',
    header: 'Last Name',
    advancedFilterField: 'lastName',
  },
  email: { field: 'email', header: 'Email', advancedFilterField: 'email' },
  roles: { field: 'roles', header: 'Roles', advancedFilterField: '' },
  username: {
    field: 'username',
    header: 'Username',
    advancedFilterField: 'username',
  },
  active: {
    field: 'active',
    header: 'Active',
    advancedFilterField: 'active',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  createdAt: {
    field: 'createdAt',
    header: 'Created At',
    advancedFilterField: 'createdAt',
    type: AdvancedFiltersFieldType.DATE,
  },
  updatedAt: {
    field: 'updatedAt',
    header: 'Updated At',
    advancedFilterField: 'updatedAt',
    type: AdvancedFiltersFieldType.DATE,
  },
};
