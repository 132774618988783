import { gql } from '@apollo/client';

export const createPrcAttachmentsQuery = gql`
  mutation CreatePrcAttachments($data: CreatePrcAttachmentsInput!) {
    createPrcAttachments(data: $data) {
      idPrcAttachment
      idAttachment2 {
        idAttachment
        name
        url
        nameGivenByUser
      }
    }
  }
`;

export const listPrcAttachmentsByIdPrcQuery = gql`
  query listAllPrcAttachments($data: ListAllPrcAttachmentsInput!) {
    listAllPrcAttachments(data: $data) {
      items
      data {
        idPrcAttachment
        idPrc
        idAttachment
        idAttachment2 {
          idAttachment
          url
          name
          nameGivenByUser
          idType
          createdBy2 {
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;

export const listAllPrcCommentsQuery = gql`
  query listAllPrcComments($data: ListAllPrcCommentsInput!) {
    listAllPrcComments(data: $data) {
      data {
        idPrcComment
        idPrc
        idComment
        versionLock
        idComment2 {
          idComment
          message
          isRestrictInfo
          isGeneratedSystem
          createdAt
          createdBy
          createdBy2 {
            idUser
            username
            avatarUrl
            firstName
            lastName
          }
          idStatus
          idStatus2 {
            idDomain
            description
          }
          updatedAt
          updatedBy2 {
            username
          }
          idAttachment2 {
            url
            name
            nameGivenByUser
          }
        }
      }
      items
    }
  }
`;

export const createPrcCommentQuery = gql`
  mutation CreatePrcComment($data: CreatePrcCommentInput!) {
    createPrcComment(data: $data) {
      idPrcComment
    }
  }
`;

export const updatePrcCommentQuery = gql`
  mutation UpdatePrcComment($data: UpdatePrcCommentInput!) {
    updatePrcComment(data: $data) {
      idPrcComment
    }
  }
`;

export const deletePrcCommentQuery = gql`
  mutation DeletePrcComment($idPrcComment: Int!) {
    deletePrcComment(idPrcComment: $idPrcComment)
  }
`;
