/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TreeTable, TreeTableProps } from 'primereact/treetable';
import React, { LegacyRef, useEffect } from 'react';
import { useMenu } from '../../hooks/useMenu';

import { Container } from './styles';

interface TreeGridProps extends TreeTableProps {
  innerRef?: LegacyRef<TreeTable>;
  onTreeGridScroll?(scrollTop: number): void;
}

const TreeGrid: React.FC<TreeGridProps> = ({
  innerRef,
  onTreeGridScroll,
  children,
  scrollable,
  ...rest
}) => {
  // Busca visibilidade do menu
  const { visible } = useMenu();

  /**
   * Chama funcao de scroll passada por props
   */
  function handleScroll() {
    // Busca elemento da treeGrid
    if (onTreeGridScroll) {
      const element = document.getElementsByClassName(
        'p-treetable-scrollable-body',
      );

      // Chama funcao de scroll passada por props
      onTreeGridScroll(element[0]!.scrollTop);
    }
  }

  /**
   * A cada evento de scroll, chama função para atualizar estado
   */
  useEffect(() => {
    if (scrollable) {
      document
        .getElementById('treeGrid')
        ?.addEventListener('scroll', handleScroll, true);
    }
  }, []);

  return (
    <Container sidebarVisible={visible}>
      <TreeTable
        ref={innerRef}
        id="treeGrid"
        paginator
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {last} of {totalRecords} entries"
        rowsPerPageOptions={[25, 50, 100]}
        scrollable={scrollable}
        {...rest}
      >
        {children}
      </TreeTable>
    </Container>
  );
};

export default TreeGrid;
