import { InputTextProps } from 'primereact/inputtext';
import React from 'react';

import { Circle, Container } from './styles';

interface IGridCircleProps extends InputTextProps {
  isPositiveValue: boolean;
}

const GridCircle: React.FC<IGridCircleProps> = ({ isPositiveValue }) => {
  return (
    <Container>
      <Circle className={isPositiveValue ? 'success' : 'danger'} />
    </Container>
  );
};

export default GridCircle;
