import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 12px;

  .s-expanded-datatable {
    .quantityCtnr {
      font-weight: 900;
    }
  }
`;
