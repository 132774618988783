import { gql } from '@apollo/client';

export const createSatRncCostQuery = gql`
  mutation CreateSatRncCost($data: CreateSatRncCostInput!) {
    createSatRncCost(data: $data) {
      idSatRncCost
    }
  }
`;

export const updateSatRncCostQuery = gql`
  mutation UpdateSatRncCost($data: UpdateSatRncCostInput!) {
    updateSatRncCost(data: $data) {
      idSatRncCost
    }
  }
`;
