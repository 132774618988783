// eslint-disable-next-line no-shadow
export enum CompanyRegister {
  SAT = 1,
  FLA = 2,
  SAT_FZC = 3,
  SAT_AMERICAS_GROUP_CORP = 4,
}

export interface ICurrency {
  idCurrency: number;
  abbreviation: string;
  usdExchangeRate: number;
  usdExchangeRateUpdatedAt: Date;
}

export interface ICompany {
  idCompany: number;
  name: string;
}
