import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

export const gridColumnsData: ColumnData[] = [
  {
    field: 'name',
    header: 'Name',
    advancedFilterField: 'name',
  },
  {
    field: 'email',
    header: 'Email',
    advancedFilterField: 'email',
  },
  {
    field: 'phone',
    header: 'Phone Number',
    advancedFilterField: '',
  },
  {
    field: 'description',
    header: 'Description',
    advancedFilterField: 'description',
  },
  {
    field: 'active',
    header: 'Active',
    advancedFilterField: 'active',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
];
