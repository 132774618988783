export const clientRoles = {
  idEntity: 24,
  idModule: 3,
  fields: {
    idFieldAddress: 344,
    idFieldBusinessType: 345,
    idFieldClientService: 347,
    idFieldCnpj: 348,
    idFieldCompanyName: 349,
    idFieldCostsMultiplier: 350,
    idFieldCourier: 351,
    idFieldIdCity: 354,
    idFieldIdCountry: 356,
    idFieldIdRegion: 358,
    idFieldIncoterm: 359,
    idFieldMeetingAddress: 360,
    idFieldNumberOfStores: 362,
    idFieldOperationType: 363,
    idFieldPayment: 364,
    idFieldPaymentAdvance: 365,
    idFieldPaymentBalance: 366,
    idFieldPaymentDeadline: 367,
    idFieldSalesMultiplier: 368,
    idFieldStatus: 369,
    idFieldWebsite: 373,
    idFieldZipCode: 374,
  },
  permissions: {
    idPermissionExportClientList: 7,
    idPermissionDeleteClient: 38,
  },
};
