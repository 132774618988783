import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useRef } from 'react';
import Button from '../../../../../../components/Button';
import FilesByTypes, {
  IFilesByTypesRef,
} from '../../../../../../components/FilesByTypes';
import { IFileType } from '../../../../../../components/FilesByTypes/interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { deleteSatAttachmentsQuery } from '../../../queries';
import { SatFinancialFilesPermissions } from '../../interfaces';
import FilesUpload, { IFilesUploadRef } from './FilesUpload';
import { downloadSatForeignTradeSupplierAttachmentsQuery } from './queries';
import { Container } from './styles';
import { IFinancialFileData } from '../interfaces';

interface IFilesProps {
  idSat: number;
  idSatForeignTradeSupplier: number;
  financialFileTypesData: IFileType[];
  financialFileTypesLoading: boolean;
  financialFilesGroupId:
    | DomainGroup.FINANCIAL_PURCHASE_TYPE_FILES
    | DomainGroup.FINANCIAL_SELLER_TYPE_FILES;
  selected: boolean;
  permissions: SatFinancialFilesPermissions;
  financialFilesData: IFinancialFileData[];
  financialFilesLoading: boolean;
  loadFinancialFiles: () => void;
}

const Files: React.FC<IFilesProps> = ({
  idSat,
  idSatForeignTradeSupplier,
  financialFileTypesData,
  financialFileTypesLoading,
  financialFilesGroupId,
  permissions,
  financialFilesData,
  financialFilesLoading,
  loadFinancialFiles,
}) => {
  const { toastRef } = useRefHook();

  const filesUploadRef = useRef<IFilesUploadRef>(null);
  const filesByTypesRef = useRef<IFilesByTypesRef>(null);

  const [deleteSatAttachmentsMutation, { loading: fileDeleteInProgress }] =
    useMutation(deleteSatAttachmentsQuery);

  const [
    downloadFinancialFiles,
    { loading: financialFilesDownloadInProgress },
  ] = useLazyQuery(downloadSatForeignTradeSupplierAttachmentsQuery, {
    onCompleted: async response => {
      if (response.downloadSatForeignTradeSupplierFiles) {
        window.open(response.downloadSatForeignTradeSupplierFiles, '_blank');
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while downloading files',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while downloading files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function handleDownloadFiles() {
    const filesToDownload = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDownload?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do download',
        life: ToastLife.ERROR,
      });

      return;
    }

    downloadFinancialFiles({
      variables: {
        idsSatAttachments: filesToDownload,
      },
    });
  }

  async function handleDeleteFiles() {
    const filesToDelete = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDelete?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do delete',
        life: ToastLife.ERROR,
      });

      return;
    }

    try {
      await deleteSatAttachmentsMutation({
        variables: {
          satAttachmentIds: filesToDelete,
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'Files deleted',
        life: ToastLife.SUCCESS,
      });

      loadFinancialFiles();
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    }
  }

  return (
    <Container className="form-group grid flex-column">
      <FormTitle className="col-12" name="Financial Files">
        {permissions.upload && (
          <Button
            type="button"
            label="Upload"
            icon="pi pi-upload"
            onClick={() => filesUploadRef.current?.toggleModal()}
          />
        )}

        <Button
          type="button"
          label="Download"
          onClick={() => handleDownloadFiles()}
          disabled={financialFilesDownloadInProgress}
          icon={
            financialFilesDownloadInProgress
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-download'
          }
        />

        {permissions.delete && (
          <Button
            type="button"
            label="Delete"
            onClick={() =>
              confirmDialog({
                message: 'Are you sure you want to delete the selected files?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () => handleDeleteFiles(),
              })
            }
            disabled={fileDeleteInProgress}
            icon={
              fileDeleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'
            }
            severity="danger"
          />
        )}
      </FormTitle>

      <FilesUpload
        ref={filesUploadRef}
        idSat={idSat}
        idSatForeignTradeSupplier={idSatForeignTradeSupplier}
        financialFilesGroupId={financialFilesGroupId}
        financialFilesRefetch={loadFinancialFiles}
      />

      <FilesByTypes
        ref={filesByTypesRef}
        className="col-12"
        fileTypes={financialFileTypesData}
        files={financialFilesData}
        typesLoading={financialFileTypesLoading}
        fileIdKey="idSatAttachment"
        filesLoading={financialFilesLoading}
        fileDescriptionKey="idAttachment2.nameGivenByUser"
        fileTypeKey="idAttachment2.idType"
        fileCreatedAtKey="idAttachment2.createdAt"
      />
    </Container>
  );
};

export default Files;
