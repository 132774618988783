import { gql } from '@apollo/client';

export const createPartnerBrandingQuery = gql`
  mutation CreatePartnerBranding($data: CreatePartnerBrandingInput!) {
    createPartnerBranding(data: $data) {
      idPartnerBranding
    }
  }
`;

export const updatePartnerBrandingQuery = gql`
  mutation UpdatePartnerBranding($data: UpdatePartnerBrandingInput!) {
    updatePartnerBranding(data: $data) {
      idPartnerBranding
    }
  }
`;
