import { DocumentNode, gql } from '@apollo/client';
import { PackageBarcodeFieldsPermissions } from '../../interfaces';

export const listPackageBarcodesQuery = (
  fieldsPermissions: PackageBarcodeFieldsPermissions,
): DocumentNode => gql`
  query Query($idProductPackage: Int!, $pagination: Pagination!) {
    listProductPackageBarcodesByIdProductPackage(
      idProductPackage: $idProductPackage
      pagination: $pagination
    ) {
      items
      data {
        idProductPackageBarcode
        ${fieldsPermissions.packageEan13.view ? 'packageEan13' : ''}
        ${fieldsPermissions.innerDun14.view ? 'innerDun14' : ''}
        ${fieldsPermissions.masterDun14.view ? 'masterDun14' : ''}
        ${fieldsPermissions.auto.view ? 'auto' : ''}
        ${
          fieldsPermissions.idClient.view
            ? `
        idClient
        idClient2 {
          name
        }`
            : ''
        }
      }
    }
  }
`;

export const deleteProductPackageBarcodeQuery = gql`
  mutation DeleteProductPackageBarcode($idProductPackageBarcode: Int!) {
    deleteProductPackageBarcode(
      idProductPackageBarcode: $idProductPackageBarcode
    )
  }
`;
