import { DocumentNode, gql } from '@apollo/client';
import { RncItemsFieldsPermissions } from '../../interfaces';

export const listSatRncItemsBySatRncIdQuery = (
  fieldsPermissions: RncItemsFieldsPermissions,
): DocumentNode => gql`
  query ListSatRncItemsBySatRncId($idSatRnc: Int!) {
    listSatRncItemsBySatRncId(idSatRnc: $idSatRnc) {
      idSatRncItem
      idSatItem
      idSatItem2 {
        idSatItem
        ${fieldsPermissions.stCode.view ? 'stCode' : ''}
        ${fieldsPermissions.name.view ? 'name' : ''}
        ${fieldsPermissions.qtyBuyed.view ? 'quantity' : ''}
        ${fieldsPermissions.pcsCtn.view ? 'piecesContainer' : ''}
        ${fieldsPermissions.fobUnit.view ? 'sunPrice' : ''}
      }
      ${fieldsPermissions.qtyComplained.view ? 'quantityComplained' : ''}
      ${fieldsPermissions.nationalizedUnit.view ? 'nationalizedUnit' : ''}
      ${fieldsPermissions.clientAware.view ? 'clientAware' : ''}
      ${
        fieldsPermissions.type.view
          ? `idType
             idType2 {
              idDomain
              description
            }`
          : ''
      }
      ${
        fieldsPermissions.debitCategory.view
          ? `idDebitCategory
             idDebitCategory2 {
              idDomain
              description
            }`
          : ''
      }
      ${fieldsPermissions.notes.view ? 'notes' : ''}
      
    }
  }
`;

export const listDomainsByGroupIdQuery = gql`
  query ListDomainsByGroupIdQuery(
    $id: Int!
    $pagination: Pagination!
    $description: String
  ) {
    listDomainsByGroupId(
      id: $id
      pagination: $pagination
      description: $description
    ) {
      data {
        idDomain
        description
      }
    }
  }
`;

export const deleteSatRncItemsQuery = gql`
  mutation DeleteSatRncItems($satRncItemIds: [Int]!) {
    deleteSatRncItems(satRncItemIds: $satRncItemIds)
  }
`;
