import React, { useEffect, useRef, useState } from 'react';

import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import Button from '../../../../components/Button';
import AddAssessment from './AddAssessment';
import { ISupplierAssessment } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  deleteSupplierAssessmentsQuery,
  listSupplierAssessmentsQuery,
} from './queries';
import { SupplierAssessmentLazyParams } from './interfaces';
import pagination from '../../../../config/pagination';
import Grid from '../../../../components/Grid';
import { gridConstants } from '../../../../components/Grid/constants';
import { parseDateColumm } from '../../../../utils/gridColumnsParse';
import { IAddAssessmentRef } from './AddAssessment/interfaces';

interface ISupplierAssessmentProps extends PageTabContainerProps {
  idSupplier: number;
}

const Assessment: React.FC<ISupplierAssessmentProps> = ({
  selected,
  idSupplier,
}) => {
  const [deleteSupplierAssessmentsMutation, { loading: deleteInProgress }] =
    useMutation(deleteSupplierAssessmentsQuery);
  const addAssessmentRef = useRef<IAddAssessmentRef>(null);
  const { showError, showSuccess } = useRefHook();

  const [lazyParams, setLazyParams] = useState<SupplierAssessmentLazyParams>(
    pagination.initialLazyParams,
  );
  const [selectedAssesments, setSelectedAssesments] = useState<
    ISupplierAssessment[]
  >([]);
  const [assessments, setAssessments] = useState<ISupplierAssessment[]>();

  const [
    loadAssessmentsData,
    {
      loading: assessmentsLoading,
      data: assessmentsData,
      refetch: assessmentsRefetch,
    },
  ] = useLazyQuery(listSupplierAssessmentsQuery, {
    // Garante que loading sera atualizado mesmo no refetch
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        idSupplier,
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
      },
    },
    onCompleted: response => {
      if (response.listSupplierAssesments) {
        setAssessments([...response.listSupplierAssesments.data]);
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Supplier Assessments',
        detail: errorData.message,
      });
    },
  });

  const imageBodyTemplate = (rowData: { imageUrl: string | undefined }) => {
    return rowData.imageUrl ? (
      <a
        href={`${rowData.imageUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p> - </p>
    );
  };

  async function handleDeleteSelected() {
    try {
      await deleteSupplierAssessmentsMutation({
        variables: {
          idsSupplierAssesments: selectedAssesments.map(
            a => a.idSupplierAssesment,
          ),
        },
      });

      showSuccess({
        summary: 'Assessments deleted',
        detail: `${selectedAssesments.length} assessments deleted`,
      });

      setSelectedAssesments([]);

      await assessmentsRefetch();
    } catch (error) {
      showError({
        summary: 'Error while deleting assessments',
        detail: error.message,
      });
    }
  }

  /**
   * Direciona usuario para pagina clicada
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    addAssessmentRef.current?.toggleModal(e.data);
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button
        label="Add Assessment"
        onClick={() => addAssessmentRef.current?.toggleModal()}
      />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() =>
          confirmDialog({
            message: `Are you sure you want to delete ${selectedAssesments.length} assessments?`,
            header: 'Delete Confirmation',
            icon: 'pi pi-question-circle',
            accept: () => handleDeleteSelected(),
          })
        }
        disabled={!selectedAssesments.length}
        loading={deleteInProgress}
      />
    </div>
  );

  useEffect(() => {
    if (selected && !assessments) {
      loadAssessmentsData();
    }
  }, [assessments, loadAssessmentsData, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AddAssessment
        ref={addAssessmentRef}
        idSupplier={idSupplier}
        assessmentsRefetch={assessmentsRefetch}
      />
      <Container>
        <Grid
          value={assessments}
          header={gridHeader}
          selection={selectedAssesments}
          onSelectionChange={e => setSelectedAssesments(e.value)}
          emptyMessage="No assessments found"
          onRowClick={onRowClick}
          selectionMode="checkbox"
          loading={assessmentsLoading}
          lazy
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={assessmentsData?.listSupplierAssesments?.items ?? 0}
          scrollable
          scrollHeight={gridConstants.internalGridScrollHeight}
          onSort={e => setLazyParams({ ...lazyParams, ...e })}
          onPage={e => setLazyParams({ ...lazyParams, ...e })}
          removableSort
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
        >
          <Column
            selectionMode="multiple"
            style={{ width: '1rem' }}
            reorderable={false}
          />
          <Column field="name" header="Name" sortable />
          <Column
            field="idType2.description"
            header="Certificaty Type"
            sortable
          />
          <Column
            header="Expiration Date"
            field="expirationDate"
            body={parseDateColumm}
            sortable
          />
          <Column header="Attachment" body={imageBodyTemplate} />
        </Grid>
      </Container>
    </PageTabContainer>
  );
};

export default Assessment;
