import { gql } from '@apollo/client';

export const createSatForeignTradeSupplierOtherExpenseQuery = gql`
  mutation createSatForeignTradeSupplierOtherExpense(
    $data: CreateSatForeignTradeSupplierOtherExpenseInput!
  ) {
    createSatForeignTradeSupplierOtherExpense(data: $data) {
      idSatOtherExpense
    }
  }
`;

export const updateSatForeignTradeSupplierOtherExpenseQuery = gql`
  mutation updateSatForeignTradeSupplierOtherExpense(
    $data: UpdateSatForeignTradeSupplierOtherExpenseInput!
  ) {
    updateSatForeignTradeSupplierOtherExpense(data: $data) {
      idSatOtherExpense
    }
  }
`;
