import { ColumnData } from '../../../../components/Grid/interfaces';

type ReconcilePaymentsGridColumnData = {
  billNumber: ColumnData;
  clientName: ColumnData;
  supplierName: ColumnData;
  beneficiaryName: ColumnData;
  billRate: ColumnData;
  competencyRegime: ColumnData;
  cashflowRegime: ColumnData;
  amount: ColumnData;
  amountUsd: ColumnData;
};

export const gridColumnsData: ReconcilePaymentsGridColumnData = {
  billNumber: {
    header: 'Bill Number',
    field: 'idFinancialTransaction',
    advancedFilterField: '',
  },
  clientName: {
    header: 'Client',
    field: 'idClient2.name',
    advancedFilterField: '',
  },
  supplierName: {
    header: 'Supplier',
    field: 'idSupplier2.name',
    advancedFilterField: '',
  },
  beneficiaryName: {
    header: 'Beneficiary',
    field: 'idBeneficiary2.name',
    advancedFilterField: '',
  },
  billRate: {
    header: 'Bill Rate',
    field: 'billRate',
    advancedFilterField: '',
  },
  competencyRegime: {
    header: 'Competency Regime',
    field: 'competencyRegime',
    advancedFilterField: '',
  },
  cashflowRegime: {
    header: 'Cashflow Regime',
    field: 'cashflowRegime',
    advancedFilterField: '',
  },
  amount: {
    header: 'Amount',
    field: 'amount',
    advancedFilterField: '',
  },
  amountUsd: {
    header: 'Amount (USD)',
    field: 'amountUsd',
    advancedFilterField: '',
  },
};
