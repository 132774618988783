import { gql } from '@apollo/client';

export const listSatRncsByIdSatQuery = gql`
  query ListSatRncsByIdSat($data: ListSatRncsByIdSatInput!) {
    listSatRncsByIdSat(data: $data) {
      items
      data {
        idSat
        idSupplier
        idSatSupplier
        idSatForeignTrade
        idSatForeignTradeSupplier
        idSatRnc
        rncNumber
        idStatus
        contactClient
        idRncResponsible
        idPriority
        deadline
        idProblemType
        problemTypeDescription
        problemDescription
        proposedSolution
        idReWork
        idCost
        idCostResponsible
        createdAt
        createdBy
        closedAt
        idWaitingFor
        exchangeRate
        costApprovedBy
        clientDebitNoteNumber
        clientNf
        clientDebitNoteStatus
        daysOpen
        idStatus2 {
          idDomain
          description
        }
        idSupplier2 {
          sunNumber
        }
        idSatForeignTrade2 {
          satForeignTradeNumber
        }
        idRncResponsible2 {
          idDomain
          description
        }
        idPriority2 {
          idDomain
          description
        }
        idProblemType2 {
          idDomain
          description
        }
        idReWork2 {
          idDomain
          description
        }
        idCost2 {
          idDomain
          description
        }
        idCostResponsible2 {
          idDomain
          description
        }
        createdBy2 {
          idUser
          firstName
          lastName
        }
        idWaitingFor2 {
          idDomain
          description
        }
        costApprovedBy2 {
          idUser
          firstName
          lastName
        }
        clientDebitNoteStatus2 {
          idDomain
          description
        }
        satRncCoResponsibles {
          idRncCoResponsible
          idRncCoResponsible2 {
            idDomain
            description
          }
        }
      }
    }
  }
`;

export const updateSatRncsQuery = gql`
  mutation UpdateSatRncs($data: [UpdateSatRncInput]!) {
    updateSatRncs(data: $data) {
      warnings
    }
  }
`;

export const updateSatRncItemsQuery = gql`
  mutation UpdateSatRncItems($data: UpdateSatRncItemsInput!) {
    updateSatRncItems(data: $data) {
      warnings
    }
  }
`;

export const listDomainsByGroupIdQuery = gql`
  query ListDomainsByGroupId(
    $pagination: Pagination!
    $id: Int!
    $isActive: Boolean
  ) {
    listDomainsByGroupId(
      pagination: $pagination
      id: $id
      isActive: $isActive
    ) {
      data {
        idDomain
        description
      }
    }
  }
`;

// Query para buscar arquivo template
export const generateSatRncExportQuery = gql`
  query generateSatRncExport($data: GenerateSatRncExportInput!) {
    generateSatRncExport(data: $data)
  }
`;
