import styled from 'styled-components';

interface ILoadMoreButtonProps {
  disabled?: boolean;
}

interface IDashboardCardProps {
  key: number;
}

export const Container = styled.div`
  flex-grow: 1;
  position: relative;
  --columns-space: 8px;
  --column-width: 272px;
  --card-margin-sides: 0.375rem;
  --default-border-radius: 8px;
`;

export const BoardContainer = styled.ol`
  display: flex;
  flex-direction: row;
  padding: var(--columns-space);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  gap: var(--columns-space);
  list-style: none;
`;

export const DashboardColumn = styled.li`
  display: block;
  height: 100%;
  white-space: nowrap;
  background-color: var(--grey);
  border-radius: var(--default-border-radius);

  .p-skeleton {
    border-radius: 8px;
    height: 100% !important;
    width: var(--column-width) !important;
  }
`;

export const DashboardColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  flex-direction: column;
  padding-bottom: 8px;
  position: relative;
  scroll-margin: 8px;
  white-space: normal;
  width: var(--column-width);
  box-sizing: border-box;
  vertical-align: top;

  .dashboard-column-title {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    padding: 1rem;
  }
`;

export const DashboardCardList = styled.ol`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 4px;
  gap: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;

  /** Largura do scroll */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--c2);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--c5);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--c6);
  }

  /** Firefox  */
  scrollbar-width: thin;
`;

export const DashboardCard = styled.li<IDashboardCardProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: var(--default-border-radius);
  background-color: var(--white);
  margin: 0 var(--card-margin-sides);
  padding: 1rem;
  padding-bottom: 0.5rem;
  border: 1px solid var(--c2);
`;

export const LoadMoreButton = styled.div<ILoadMoreButtonProps>`
  margin: 0 var(--card-margin-sides);

  .s-load-more-content {
    display: flex;
    height: 36px;
    background: var(--c2);
    transition: background-color 0.2s;
    padding: 8px;
    border-radius: var(--default-border-radius);
    &:hover {
      background: ${props => (props.disabled ? undefined : 'var(--c3)')};
    }

    button {
      width: 100%;
      background: none;
      border: none;
      color: var(--black);
      font-weight: 500;
    }
  }
`;
