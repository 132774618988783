import { Dialog } from 'primereact/dialog';
import React, { Ref, forwardRef, useImperativeHandle, useReducer } from 'react';
import { Divider } from 'primereact/divider';
import { useMutation } from '@apollo/client';
import { TComment } from '../CommentAndReplies/interfaces';
import { parseCommentMessage } from '../../../utils/parseCommentMessage';
import ReplyToComment from '../ReplyToComment';
import Comment from '../../Comment';
import { useRefHook } from '../../../hooks/useRefHook';
import { createChildCommentQuery } from './queries';
import { FileUploadResponse } from '../../FileUpload/interfaces';
import { IAttachment } from '../../../shared/interfaces/attachment';
import {
  replyToCommentDialogReducer,
  replyToCommentDialogReducerInitialState,
} from './reducers';
import { ReplyToCommentDialogActionKind } from './interfaces';

export type ReplyToCommentDialogRef = {
  setCommentToReply: (comment: TComment, initialValue: string) => void;
};

interface IReplyToCommentDialogProps {
  ref: Ref<ReplyToCommentDialogRef>;
  idParentComment: number;
  parentIsRestrictInfo?: boolean;
  showRestrictInfo?: boolean;
  onSave?: () => void;
  onUploadAttachment(e: FileUploadResponse[]): Promise<IAttachment | undefined>;
}

const ReplyToCommentDialog: React.FC<IReplyToCommentDialogProps> = forwardRef(
  (
    {
      idParentComment,
      onSave,
      parentIsRestrictInfo = false,
      showRestrictInfo = false,
      onUploadAttachment,
    },
    ref,
  ) => {
    const [createChildCommentMutation, { loading }] = useMutation(
      createChildCommentQuery,
    );

    const [state, stateDispatch] = useReducer(
      replyToCommentDialogReducer,
      replyToCommentDialogReducerInitialState,
    );
    const { showError, showSuccess } = useRefHook();

    useImperativeHandle(
      ref,
      () => ({
        setCommentToReply: (comment: TComment, initialValue: string) => {
          stateDispatch({
            type: ReplyToCommentDialogActionKind.OPEN_DIALOG,
            payload: {
              commentToReply: comment,
              replyInitialValue: initialValue,
            },
          });
        },
      }),
      [],
    );

    function handleCloseDialog() {
      stateDispatch({
        type: ReplyToCommentDialogActionKind.CLOSE_DIALOG,
      });
    }

    async function onSaveComment(
      reply: string,
      isRestrictInfo: boolean,
      idAttachment?: number,
    ) {
      try {
        await createChildCommentMutation({
          variables: {
            data: {
              idParentComment,
              isRestrictInfo,
              message: reply,
              idAttachment,
            },
          },
        });

        showSuccess({
          summary: 'Comment added successfully',
        });
      } catch (error) {
        showError({
          summary: 'Error adding comment',
          detail: error.message,
        });
      }

      if (onSave) onSave();
      handleCloseDialog();
    }

    return (
      <Dialog
        header={`Replying to ${state.commentToReply?.createdBy2.firstName}`}
        visible={state.dialogVisible}
        style={{ width: '520px' }}
        onHide={() => handleCloseDialog()}
      >
        {state.commentToReply && (
          <Comment
            idComment={state.commentToReply.idComment}
            userName={state.commentToReply.createdBy2.fullName}
            creation={state.commentToReply.createdAt}
            userImageUrl={state.commentToReply.createdBy2?.avatarUrl}
            idCreatedBy={state.commentToReply.createdBy}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: parseCommentMessage(state.commentToReply),
              }}
              className="comment"
            />
          </Comment>
        )}

        <Divider layout="vertical" className="mb-2" />

        <ReplyToComment
          initialValue={state.replyInitialValue}
          onSave={e => onSaveComment(e.reply, e.isRestrictInfo, e.idAttachment)}
          saveLoading={loading}
          showRestrictInfo={showRestrictInfo}
          isRestrictInfoValue={parentIsRestrictInfo}
          onUploadAttachment={onUploadAttachment}
        />
      </Dialog>
    );
  },
);

export default ReplyToCommentDialog;
