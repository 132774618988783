import { useLazyQuery } from '@apollo/client';
import { TabPanel } from 'primereact/tabview';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import Loading from '../../../../components/Loading';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import TabViewAsync, {
  ITabViewAsyncPageParams,
} from '../../../../components/TabViewAsync';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import ILazyParams from '../../../../services/lazyParams';
import { ForeignTradeTab } from '../ForeignTrade/interfaces';
import { listSatForeignTradesNumbersQuery } from '../ForeignTrade/queries';
import { ISat } from '../interfaces';
import StatusContent, { IStatusContentRef } from './StatusContent';
import { Container } from './styles';

interface IStatusProps extends PageTabContainerProps {
  sat: ISat;
}

const Status: React.FC<IStatusProps> = ({ selected, sat }) => {
  const statusContentRefs = useRef<(IStatusContentRef | null)[]>([]);
  const { showError } = useRefHook();

  const [tabActiveIndex, setTabActiveIndex] = useState<number>(1);

  const [tabs, setTabs] = useState<{
    data: ForeignTradeTab[];
    items: number;
  }>({ data: [], items: 0 });

  const initialLazyParams = {
    ...pagination.initialLazyParams,
  };

  const [lazyParams, setLazyParams] = useState<ILazyParams>(initialLazyParams);

  const [loadForeignTradesData, { loading: foreignTradesLoading }] =
    useLazyQuery(listSatForeignTradesNumbersQuery, {
      variables: {
        data: {
          idSat: sat.idSat,
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _sortOrder: 'DESC',
          },
        },
      },
      onCompleted: response => {
        if (response.listSatForeignTradesNumbersBySatId?.items) {
          setTabs(response.listSatForeignTradesNumbersBySatId);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Shipments',
          detail: errorData.message,
        });
      },
    });

  const onPage = useCallback(
    (e: ITabViewAsyncPageParams) => {
      setLazyParams({ ...lazyParams, ...e });
    },
    [lazyParams],
  );

  const reloadTabButton = () => {
    return (
      <Button
        type="button"
        label="Refresh"
        icon="pi pi-refresh"
        className="mt-1 mx-1 p-button-text p-button-plain"
        aria-label="Refresh status tab"
        onClick={() => {
          statusContentRefs.current[tabActiveIndex - 1]?.refreshData();
        }}
      />
    );
  };

  useEffect(() => {
    if (!tabs.data.length && selected) {
      loadForeignTradesData();
    }
  }, [tabs.data.length, loadForeignTradesData, selected]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        {!foreignTradesLoading && (
          <TabViewAsync
            scrollable
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={tabs.items}
            loading={foreignTradesLoading}
            page={lazyParams.page}
            itemsToRenderLength={tabs?.data?.length + 1 || 0}
            onPage={e => onPage(e)}
            activeIndex={tabActiveIndex}
            onTabChange={e => setTabActiveIndex(e.index)}
          >
            <TabPanel headerTemplate={reloadTabButton} />

            {tabs?.data?.map((tab: ForeignTradeTab, index: number) => {
              return (
                <TabPanel
                  key={tab.idSatForeignTrade}
                  header={tab.satForeignTradeNumber}
                >
                  <StatusContent
                    ref={ref => {
                      statusContentRefs.current[index] = ref;
                    }}
                    idSatForeignTrade={tab.idSatForeignTrade}
                    sat={sat}
                  />
                </TabPanel>
              );
            })}
          </TabViewAsync>
        )}
        {foreignTradesLoading && <Loading />}
      </Container>
    </PageTabContainer>
  );
};

export default Status;
