import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { PrimeReactProvider } from 'primereact/api';

import Routes from './routes';
import AppProvider from './hooks';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import client from './services/apollo/client';
import { GlobalStyle } from './styles/global';

const primeReactProviderValue = {
  zIndex: {
    modal: 1100, // dialog, sidebar
    overlay: 1000, // dropdown, overlaypanel
    menu: 1000, // overlay menus
    tooltip: 1100, // tooltip
    toast: 1200, // toast
  },
  autoZIndex: false,
};

const App: React.FC = () => (
  <Router>
    <PrimeReactProvider value={primeReactProviderValue}>
      <ApolloProvider client={client}>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ApolloProvider>
    </PrimeReactProvider>
    <GlobalStyle />
  </Router>
);

export default App;
