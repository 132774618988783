import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

export const asyncSelectLoadClientContactsOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; idClient?: number }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllClientsQuery(
        $listClientContactsByCliendIdInput: ListClientContactsByCliendIdInput!
      ) {
        listClientContactsByCliendId(
          listClientContactsByCliendIdInput: $listClientContactsByCliendIdInput
        ) {
          data {
            idClientContact
            department
            name
          }
          items
        }
      }
    `,
    variables: {
      listClientContactsByCliendIdInput: {
        idClient: pageData?.idClient,
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        name: search,
      },
    },
  });

  const filteredOptions = res.data.listClientContactsByCliendId.data;
  const quantity = res.data.listClientContactsByCliendId.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      idClient: pageData?.idClient,
    },
  };
};

export const asyncSelectLoadClientContactsDepartmentOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; idClient?: number }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await asyncSelectLoadClientContactsOptions(
    search,
    prevOptions,
    pageData,
  );

  const departments = res.options.filter(
    (option, index, arr) =>
      option.department &&
      arr.findIndex(option2 => option2.department === option.department) ===
        index,
  );

  return {
    ...res,
    options: departments,
  };
};

export const asyncSelectLoadAllClientContacts: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllClientContactsQuery($data: ListAllClientContactsInput!) {
        listAllClientContacts(data: $data) {
          data {
            idClientContact
            name
            clientNameWithContactName
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
        },
        name: search,
      },
    },
  });

  const filteredOptions = res.data.listAllClientContacts.data;
  const quantity = res.data.listAllClientContacts.items;

  const hasMore = quantity > prevOptions.length + 25;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};

export const asyncSelectLoadAllClientContactsDepartments: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listClientContactsDepartmentsQuery(
        $data: ListClientContactsDepartmentsInput!
      ) {
        listClientContactsDepartments(data: $data) {
          data {
            idClientContact
            department
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: 25,
          _orderBy: 'department',
          _sortOrder: 'ASC',
        },
        department: search,
      },
    },
  });

  const filteredOptions = res.data.listClientContactsDepartments.data;
  const quantity = res.data.listClientContactsDepartments.items;

  const hasMore = quantity > prevOptions.length + 25;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};
