/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm, GeneralInformation, Row } from './styles';
import PageHeader from '../../components/PageHeader';

import Button from '../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import MainButton from '../../components/MainButton';

import { useRefHook } from '../../hooks/useRefHook';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import getValidationErrors from '../../utils/getValidationErrors';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

interface IGeneralInformationFormData {
  name: string;
  namePt: string;
}

// Parametros da rota de finalizacao
interface FinishingRouteParams {
  finishingId: string;
}

const Finishing: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID da finalizacao
  const { finishingId } = useParams<FinishingRouteParams>();

  const defaultPageTitle = `${finishingId ? 'Manage' : 'Create'} Finishing`;

  // Nome da finalizacao
  const [finishingName, setFinishingName] = useState('');

  useTitle(
    finishingName ? `${finishingName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Finishings',
      path: '/finishings',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);
  const [versionLock, setVersionLock] = useState();

  // Query para criar finalizacao
  const createFinishingQuery = gql`
    mutation CreateFinishingMutation($name: String!, $namePt: String!) {
      createFinishing(name: $name, namePt: $namePt) {
        idFinishing
      }
    }
  `;

  // Query para atualizar finalizacao
  const updateFinishingQuery = gql`
    mutation UpdateFinishingMutation($updateFinishing: UpdateFinishing!) {
      updateFinishing(updateFinishing: $updateFinishing) {
        idFinishing
        versionLock
      }
    }
  `;

  // Query para listar dados da finalizacao
  const listFinishingQuery = gql`
    query ListFinishingQuery($idFinishing: Int!) {
      listFinishingById(id: $idFinishing) {
        idFinishing
        name
        namePt
        versionLock
      }
    }
  `;

  // Carrega dados da finalizacao
  const [
    loadFinishingData,
    {
      called: finishingCalled,
      loading: finishingLoading,
      data: finishingData,
      error: finishingError,
    },
  ] = useLazyQuery(listFinishingQuery, {
    variables: {
      idFinishing: parseInt(finishingId, 10),
    },
    onCompleted: async response => {
      // Valida se finalizacao nao foi encontrada
      if (!response.listFinishingById) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Finishing not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de finalizacoes
        history.push('/finishings');
        return;
      }

      setFinishingName(response.listFinishingById.name);
      setVersionLock(response.listFinishingById.versionLock);

      setPageLoading(false);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting finishing data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/finishings');
    },
  });

  // Ao carregar a pagina, carrega dados da finalizacao
  useEffect(() => {
    if (finishingId) {
      setPageLoading(true);
      loadFinishingData();
    }
  }, [finishingId, loadFinishingData]);

  // cria método para chamar a mutation
  const [createFinishing] = useMutation(createFinishingQuery);
  const [updateFinishing] = useMutation(updateFinishingQuery);

  // Submit do formulario
  const handleCreateFinishingSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
          namePt: Yup.string().required('Enter a name in portuguese'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { name, namePt } = formData;

        // Cria finalizacao
        await createFinishing({
          variables: {
            name,
            namePt,
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Finishing created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de finalizacoes
        history.push('/finishings');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating finishing',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createFinishing, toastRef, history],
  );

  // Submit do formulario
  const handleEditFinishingSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
          namePt: Yup.string().required('Enter a name in portuguese'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { name, namePt } = formData;

        // Atualiza informacoes da finalizacao
        const updateFinishingResponse = await updateFinishing({
          variables: {
            updateFinishing: {
              idFinishing: parseInt(finishingId, 10),
              name,
              namePt,
              versionLock,
            },
          },
        });

        // Atualiza nome da finalizacao no header
        setFinishingName(name);
        setVersionLock(
          updateFinishingResponse.data.updateFinishing.versionLock,
        );

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Finishing updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while editing finishing',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updateFinishing, finishingId, versionLock, toastRef],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/finishings'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          finishingData
            ? `${defaultPageTitle} - ${finishingName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <GeneralInformation>
          <Form
            ref={formRef}
            onSubmit={
              finishingId
                ? handleEditFinishingSubmit
                : handleCreateFinishingSubmit
            }
            initialData={
              finishingCalled && !finishingLoading && !finishingError
                ? finishingData.listFinishingById
                : undefined
            }
          >
            <Row>
              <FormInput
                className="generalInput"
                name="name"
                label="Name"
                required
              />
              <FormInput
                className="generalInput"
                name="namePt"
                label="Name (PT)"
                required
              />
            </Row>
          </Form>
          {pageLoading && <Loading />}
        </GeneralInformation>
      </CrudForm>
    </Container>
  );
};
export default Finishing;
