import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { InputText } from 'primereact/inputtext';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { confirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';

import { useMutation, useQuery } from '@apollo/client';

import { useRefHook } from '../../../hooks/useRefHook';

import {
  expandOrCollapseHeader,
  dynamicColumns,
} from '../../../services/layoutsDefinitions';
import ILazyParams from '../../../services/lazyParams';
import { IDomain } from '../../../services/dataInterface';
import { queryToDelete, queryToListDomains } from '../../../services/querysGql';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../hooks/useAuth';
import { clientServicesRole } from '../../../shared/roles/clientServices';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import ToastLife from '../../../shared/enums/toastLife';
import { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';

// ID do grupo clientServices
const idGroupToServicesClient = 16;

const pageTitle = 'List of Client Services';

const ClientServices: React.FC = () => {
  useTitle(pageTitle);

  // Redirect
  const history = useHistory();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Client Service
  const { idPermissionDeleteClientService } = clientServicesRole.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsClientService = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    clientServicesRole.idEntity,
  );

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Services selecionados
  const [selectedClientServices, setSelectedClientServices] = useState([]);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // cria método para chamar a mutation
  const [deleteClientServices] = useMutation(queryToDelete);

  // Busca Status Cadastrados
  const {
    data: clientServicesData,
    error: clientServicesError,
    loading: clientServicesLoading,
    refetch: clientServicesRefetch,
  } = useQuery(queryToListDomains, {
    variables: {
      id: idGroupToServicesClient,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting client services',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const expandCollapsePageHeader = () => {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  };

  // Direciona usuario para pagina do client service clicado
  // @param e Evento de clique na linha da tabela
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/list/services/${e.data.idDomain}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Deleta client services selecionados
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedClientServices.length} client services?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada client service
        const idsToDelete = selectedClientServices.map(
          (a: IDomain) => a.idDomain,
        );
        try {
          // Deleta client status
          await deleteClientServices({
            variables: {
              domainsId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedClientServices.length} client services`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado dos services selecionadas
          setSelectedClientServices([]);

          // Atualiza grid de client services
          await clientServicesRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting a client service',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar um client service */}
        <MainButton
          className="mainButton"
          label="New Client Service"
          onClick={() => history.push('/list/services/create')}
        />
        {/* Botao para excluir os cliente services selecionados */}
        {userHasPermission(
          idPermissionDeleteClientService,
          userPermissionsClientService.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={!selectedClientServices.length}
            onClick={handleDeleteSelected}
          />
        )}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search items"
        />
        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        name="clientServices"
        lazy
        totalRecords={
          !clientServicesData || clientServicesError
            ? 0
            : clientServicesData.listDomainsByGroupId.items
        }
        value={
          !clientServicesData || clientServicesError
            ? undefined
            : clientServicesData.listDomainsByGroupId.data
        }
        globalFilter={globalFilter}
        emptyMessage="No client services found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedClientServices}
        onSelectionChange={e => setSelectedClientServices(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteClientService,
          userPermissionsClientService.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}
        {dynamicColumns}
      </Grid>
      {(pageLoading || clientServicesLoading) && <Loading />}
    </div>
  );
};
export default ClientServices;
