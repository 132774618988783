/* eslint-disable no-shadow */

import { SatContainer } from '../interfaces';

export enum ManageSatContainerReducerActionKind {
  CLOSE_DIALOG,
  OPEN_DIALOG,
}

export type ManageSatContainerReducer = {
  displayDialog: boolean;
  containerToEdit?: SatContainer;
};

export type ManageSatContainerReducerAction = {
  type: ManageSatContainerReducerActionKind;
  payload?: {
    satContainer?: SatContainer;
  };
};

export type SatContainerFormData = {
  idSatCtnr?: number;
  idSatForeignTrade: number;
  idTypeCtnr: number;
  qtyCtnr: number;
};
