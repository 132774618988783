import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import client from '../../services/apollo/client';

export const loadDomainGroups: LoadOptions<
  any,
  GroupBase<any>,
  { ids?: number[] }
> = async (search: string, _prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listDomainGroupsByIdsQuery($ids: [Int]!) {
        listDomainGroupsByIds(ids: $ids) {
          data {
            idDomainGroup
            description
          }
        }
      }
    `,
    variables: {
      description: search,
      ids: pageData?.ids,
    },
  });

  const filteredOptions = res.data.listDomainGroupsByIds.data;
  return {
    options: filteredOptions,
  };
};
