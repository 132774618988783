import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useCallback } from 'react';

import { Container } from './styles';

export interface ICommentPaginationPageParams {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

interface ICommentPaginationProps {
  totalRecords: number;
  first: number;
  rows: number;
  onPage(e: ICommentPaginationPageParams): void;
  loading: boolean;
  page: number;
}

const CommentPagination: React.FC<ICommentPaginationProps> = ({
  rows,
  totalRecords,
  first,
  onPage,
  loading,
  page,
}) => {
  const pageCount = Math.ceil(totalRecords / rows);

  const handlePage = useCallback(() => {
    const onPageParams: ICommentPaginationPageParams = {
      first: first + rows,
      page: page + 1,
      pageCount,
      rows,
    };

    onPage(onPageParams);
  }, [first, rows, page, pageCount, onPage]);

  return (
    <>
      {!!totalRecords && (
        <Container className="s-comment-pagination">
          {!loading && page + 1 !== pageCount && (
            <span className="s-comment-pagination-content">
              <button type="button" onClick={handlePage}>
                Load more
              </button>
            </span>
          )}
          {loading && !!first && (
            <span className="s-comment-pagination-content">
              <ProgressSpinner
                style={{ width: '20px', height: '20px' }}
                strokeWidth="5"
              />
            </span>
          )}
        </Container>
      )}
    </>
  );
};

export default CommentPagination;
