import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog } from 'primereact/dialog';
import { useMutation } from '@apollo/client';
import { IAddPackageRef } from './interfaces';
import Button from '../../../../../components/Button';
import FormTitle from '../../../../../components/FormTitle';
import { IPackageDescriptionContentRef } from '../PackageContent/PackageDescriptions/PackageDescriptionContent/interfaces';
import PackageDescriptionContent from '../PackageContent/PackageDescriptions/PackageDescriptionContent';
import PackageDetails from '../PackageContent/PackageDetails';
import { IPackageDetailsRef } from '../PackageContent/PackageDetails/interfaces';
import PackageReferenceContent from '../PackageContent/PackageReferences/PackageReferenceContent';
import { IPackageReferenceContentRef } from '../PackageContent/PackageReferences/PackageReferenceContent/interfaces';
import PackageBarcodeContent from '../PackageContent/PackageBarcodes/PackageBarcodeContent';
import { IPackageBarcodeContentRef } from '../PackageContent/PackageBarcodes/PackageBarcodeContent/interfaces';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { createProductPackageQuery } from './queries';
import {
  PackageBarcodeFieldsPermissions,
  PackageDescriptionFieldsPermissions,
  PackageDetailsFieldsPermissions,
  PackageReferenceFieldsPermissions,
} from '../interfaces';

interface IAddPackageProps {
  ref: Ref<IAddPackageRef>;
  packagesRefetch(): Promise<void>;
  idProduct: number;
  stCode: string;
  indConfection?: boolean;
  userCanChangeFields: boolean;
  descriptionsFieldsPermissions: PackageDescriptionFieldsPermissions;
  detailsFieldsPermissions: PackageDetailsFieldsPermissions;
  barcodesFieldsPermissions: PackageBarcodeFieldsPermissions;
  referencesFieldsPermissions: PackageReferenceFieldsPermissions;
}

const AddPackage: React.FC<IAddPackageProps> = forwardRef(
  (
    {
      idProduct,
      packagesRefetch,
      stCode,
      indConfection,
      userCanChangeFields,
      descriptionsFieldsPermissions,
      detailsFieldsPermissions,
      barcodesFieldsPermissions,
      referencesFieldsPermissions,
    },
    ref,
  ) => {
    const [createProductPackageMutation] = useMutation(
      createProductPackageQuery,
    );

    const descriptionRef = useRef<IPackageDescriptionContentRef>(null);
    const detailsRef = useRef<IPackageDetailsRef>(null);
    const referenceRef = useRef<IPackageReferenceContentRef>(null);
    const barcodeRef = useRef<IPackageBarcodeContentRef>(null);

    const { showWarn, showSuccess, showError } = useRefHook();

    const [displayDialog, setDisplayDialog] = useState(false);
    const [idTypePackage, setIdTypePackage] = useState<number>();
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      toggleDialog: () => {
        setDisplayDialog(!displayDialog);
      },
    }));

    function closeDialog() {
      setIdTypePackage(undefined);
      setDisplayDialog(false);
    }

    function setReferenceNumbers() {
      referenceRef.current?.setSizeBarcodesReferences(1, stCode);
    }

    async function handleSavePackage() {
      try {
        await descriptionRef.current?.validateForm();

        await detailsRef.current?.validateForm();

        await referenceRef.current?.validateForm();
      } catch (error) {
        showWarn({ summary: 'Please fill all required fields' });

        return;
      }

      const description = descriptionRef.current?.getData();

      const details = detailsRef.current?.getDataIfChanged();

      const reference = referenceRef.current?.getDataIfChanged();

      const packageBarcode = barcodeRef.current?.getDataIfChanged();

      setLoading(true);

      try {
        await createProductPackageMutation({
          variables: {
            data: {
              idProduct,
              ...details,
              description,
              packageBarcode,
              packageReference: reference
                ? {
                    packageReferenceContent: reference.packageReferenceContent,
                    sizeBarcodesToCreate: reference.sizeBarcodesToCreate,
                  }
                : undefined,
            },
          },
        });

        await packagesRefetch();

        showSuccess({ summary: 'Package created' });

        closeDialog();
      } catch (error) {
        showError({
          summary: 'Error while creating package',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    }

    const dialogFooter = () => {
      return (
        <div className="grid">
          <small className="col-8 s-warning-text">
            <p className="p-text-left">
              If you have <b>unsaved changes</b> on any packages at this
              product, adding a new package to it will cause <b>data loss</b>
            </p>
          </small>
          <span className="col" />
          <span className="col-3 flex gap-2">
            <Button
              label="Save"
              icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
              onClick={() => handleSavePackage()}
              disabled={loading}
            />
            <Button
              label="Cancel"
              severity="danger"
              icon="pi pi-times"
              onClick={() => closeDialog()}
              disabled={loading}
            />
          </span>
        </div>
      );
    };

    return (
      <Dialog
        header="Add Package"
        visible={displayDialog}
        style={{ width: '800px' }}
        modal
        onHide={() => closeDialog()}
        footer={dialogFooter()}
        closable={false}
      >
        <section>
          <FormTitle className="flex-column" name="Description" noGap>
            <small className="s-secondary-info-text">
              You will be able to create more descriptions once this package is
              added
            </small>
          </FormTitle>

          <PackageDescriptionContent
            ref={descriptionRef}
            fieldsPermissions={descriptionsFieldsPermissions}
            userCanChangeFields={userCanChangeFields}
          />
        </section>

        <section>
          <PackageDetails
            ref={detailsRef}
            indConfection={indConfection}
            idTypePackage={idTypePackage}
            setIdTypePackage={setIdTypePackage}
            fieldsPermissions={detailsFieldsPermissions}
            userCanChangeFields={userCanChangeFields}
          />
        </section>

        {indConfection && (
          <section>
            <FormTitle className="flex-column" name="References x Sizes" noGap>
              <small className="s-secondary-info-text">
                You will be able to create more references once this package is
                added
              </small>
            </FormTitle>

            <PackageReferenceContent
              ref={referenceRef}
              setReferenceNumbers={setReferenceNumbers}
              idTypePackage={idTypePackage}
              fieldsPermissions={referencesFieldsPermissions}
            />
          </section>
        )}

        {!indConfection && (
          <section>
            <FormTitle className="flex-column" name="Barcodes" noGap>
              <small className="s-secondary-info-text">
                You will be able to create more barcodes once this package is
                added
              </small>
            </FormTitle>
            <PackageBarcodeContent
              ref={barcodeRef}
              fieldsPermissions={barcodesFieldsPermissions}
              userCanChangeFields={userCanChangeFields}
            />
          </section>
        )}
      </Dialog>
    );
  },
);

export default AddPackage;
