import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { InputText } from 'primereact/inputtext';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { confirmDialog } from 'primereact/confirmdialog';
import { Column } from 'primereact/column';

import { useMutation, useQuery } from '@apollo/client';

import { useRefHook } from '../../../hooks/useRefHook';

import {
  expandOrCollapseHeader,
  dynamicColumns,
} from '../../../services/layoutsDefinitions';
import ILazyParams from '../../../services/lazyParams';
import { IDomain } from '../../../services/dataInterface';
import { queryToDelete, queryToListDomains } from '../../../services/querysGql';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { useAuth } from '../../../hooks/useAuth';
import { clientTaxBenefitRoles } from '../../../shared/roles/clientTaxBenefit';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import ToastLife from '../../../shared/enums/toastLife';
import { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';

const idGroupTaxBenefitsClient = 10;

const pageTitle = 'List of Tax Benefits';

const ClientTaxBenefits: React.FC = () => {
  useTitle(pageTitle);

  // Redirect
  const history = useHistory();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Tax Benefits
  const { idPermissionDeleteTaxBenefit } = clientTaxBenefitRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsTaxBenefit = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    clientTaxBenefitRoles.idEntity,
  );

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Armazena a(s) taxa(s) de beneficio(s) selecionados
  const [selectedClientTaxBenefits, setSelectedClientTaxBenefits] = useState(
    [],
  );

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // cria método para chamar a mutation
  const [deleteClientTaxBenefits] = useMutation(queryToDelete);

  // Busca taxa de beneficios Cadastrados
  const {
    data: clientTaxBenefitsData,
    error: clientTaxBenefitsError,
    loading: clientTaxBenefitsLoading,
    refetch: clientTaxBenefitsRefetch,
  } = useQuery(queryToListDomains, {
    variables: {
      id: idGroupTaxBenefitsClient,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting tax benefits',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const expandCollapsePageHeader = () => {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  };

  // Direciona usuario para pagina de taxa de beneficio fiscal clicado
  // @param e Evento de clique na linha da tabela
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/list/tax-benefits/${e.data.idDomain}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Deleta a taxa(s) de beneficio(s) selecionado(s)
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedClientTaxBenefits.length} tax benefits?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada taxa de beneficio
        const idsToDelete = selectedClientTaxBenefits.map(
          (a: IDomain) => a.idDomain,
        );
        try {
          // Deleta a(s) taxa(s) de beneficio(s)
          await deleteClientTaxBenefits({
            variables: {
              domainsId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedClientTaxBenefits.length} tax benefits`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado da(s) taxa(s) selecionada(s)
          setSelectedClientTaxBenefits([]);

          // Atualiza grid de taxas de beneficios
          await clientTaxBenefitsRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting tax benefits',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar um taxa de beneficio */}
        <MainButton
          className="mainButton"
          label="New Tax Benefits"
          onClick={() => history.push('/list/tax-benefits/create')}
        />
        {/* Botao para excluir a(s) taxa(s) de beneficio(s) selecionado(s) */}
        {userHasPermission(
          idPermissionDeleteTaxBenefit,
          userPermissionsTaxBenefit.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedClientTaxBenefits.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search items"
        />
        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        name="taxBenefits"
        lazy
        totalRecords={
          !clientTaxBenefitsData || clientTaxBenefitsError
            ? 0
            : clientTaxBenefitsData.listDomainsByGroupId.items
        }
        value={
          !clientTaxBenefitsData || clientTaxBenefitsError
            ? undefined
            : clientTaxBenefitsData.listDomainsByGroupId.data
        }
        globalFilter={globalFilter}
        emptyMessage="No tax benefits found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedClientTaxBenefits}
        onSelectionChange={e => setSelectedClientTaxBenefits(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteTaxBenefit,
          userPermissionsTaxBenefit.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || clientTaxBenefitsLoading) && <Loading />}
    </div>
  );
};
export default ClientTaxBenefits;
