import styled from 'styled-components';

export const GridHeader = styled.div`
  display: flex;
  place-content: end;
  margin-bottom: 2px;

  .p-inputtext {
    margin-right: 12px;
  }

  .advanced-filters-button {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .applied-filters-button {
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
`;
