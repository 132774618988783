/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError, object, string } from 'yup';
import FormInput from '../../../../../components/FormInput';
import { useRefHook } from '../../../../../hooks/useRefHook';
import FormInputTextArea from '../../../../../components/FormInputTextArea';
import { createClientBrandQuery, updateClientBrandQuery } from './queries';
import FileUpload from '../../../../../components/FileUpload';
import { FileType } from '../../../../../shared/enums/fileType';
import Button from '../../../../../components/Button';
import { ClientBrandFormData } from './interfaces';
import FormInputNumber from '../../../../../components/FormInputNumber';

import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../../utils/getValidationErrors';
import { ClientBrand } from '../interfaces';

export interface IAddBrandRef {
  toggleModal(data?: ClientBrand): void;
}

interface IAddBrandProps {
  ref: Ref<IAddBrandRef>;
  idClient: number;
  brandsRefetch(): Promise<ApolloQueryResult<any>>;
}

const AddBrand: React.FC<IAddBrandProps> = forwardRef(
  ({ idClient, brandsRefetch }, ref) => {
    const formRef = useRef<FormHandles>(null);
    const { showSuccess, showError } = useRefHook();

    const [createClientBrandMutation, { loading: createLoading }] = useMutation(
      createClientBrandQuery,
    );
    const [updateClientBrandMutation, { loading: updateLoading }] = useMutation(
      updateClientBrandQuery,
    );

    const [displayModal, setDisplayModal] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<string | undefined>();
    const [initialFormData, setInitialFormData] = useState<ClientBrand>();

    const loading = createLoading || updateLoading;
    const uploadFileButtonDisabled =
      !!uploadedFile || loading || !!initialFormData?.imageUrl;

    function closeModal() {
      setDisplayModal(false);
      setUploadedFile(undefined);
      setInitialFormData(undefined);
    }

    useImperativeHandle(ref, () => ({
      toggleModal: (data?: ClientBrand) => {
        if (displayModal) {
          closeModal();
        } else {
          setDisplayModal(true);
          if (data) {
            setInitialFormData(data);
          }
        }
      },
    }));

    async function onSubmit(formData: ClientBrandFormData) {
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          brand: string().nullable().required(requiredFieldErrorMessage),
        });

        await schema.validate(formData, { abortEarly: false });

        if (initialFormData) {
          await updateClientBrandMutation({
            variables: {
              data: {
                ...formData,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand updated' });
        } else {
          await createClientBrandMutation({
            variables: {
              data: {
                idClient,
                brand: formData.brand,
                notes: formData.notes,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand created' });
        }

        brandsRefetch();

        closeModal();
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];
          if (firstError.path) {
            const inputWithError = formRef.current?.getFieldRef(
              firstError.path,
            );
            inputWithError.focus();
          }
        } else {
          showError({
            summary: `Error while ${
              initialFormData ? 'updating' : 'creating'
            } brand`,
            detail: error.message,
          });
        }
      }
    }

    const dialogFooter = () => {
      return (
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
            loading={loading}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => closeModal()}
            className="p-button-danger"
            severity="danger"
            disabled={loading}
          />
        </div>
      );
    };

    return (
      <Dialog
        header="Manage Brand"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
        footer={dialogFooter()}
      >
        <Form ref={formRef} onSubmit={onSubmit} initialData={initialFormData}>
          <FormInputNumber name="idClientBrand" label="" hidden />
          <FormInputNumber name="versionLock" label="" hidden />
          <FormInput className="field" name="brand" label="Name" required />
          <FormInputTextArea
            className="field"
            name="notes"
            label="Notes"
            rows={5}
            cols={73}
          />

          <FileUpload
            className="field"
            mode="basic"
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}}`}
            auto
            chooseLabel={
              !!uploadedFile || !!initialFormData?.imageUrl
                ? 'File uploaded'
                : 'Upload a file'
            }
            disabled={uploadFileButtonDisabled}
            onConfirm={e => setUploadedFile(e[0].serverName)}
          />
        </Form>
      </Dialog>
    );
  },
);

export default AddBrand;
