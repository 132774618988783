import { IRoleUser } from '../hooks/useAuth';

/**
 * Valida se usuario possui role especificada
 * @param idRole ID de Role a ser validada
 * @returns Se usuario possui role
 */
function useHasRole(idRole: number, rolesUser: IRoleUser[]): boolean {
  const userHasRole = rolesUser.find(role => role.idRole2.idRole === idRole);

  return !!userHasRole;
}

/**
 * Valida se usuario possui alguma das roles especificadas
 * @param idsRoles IDs de Roles a serem validadas
 * @param rolesUser Roles do usuario
 * @returns Se usuario possui role
 */
export function userHasSomeRole(
  idsRoles: number[],
  rolesUser: IRoleUser[],
): boolean {
  const userHasRole = rolesUser.some(role => {
    return idsRoles.includes(role.idRole2.idRole);
  });
  return userHasRole;
}

export default useHasRole;
