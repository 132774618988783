import { gql, useLazyQuery } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import React, { useImperativeHandle, useMemo, Ref } from 'react';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../../../interfaces/IRoleEntityField';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { satItemsRoles } from '../../../../../../shared/roles/satItems';
import getFieldPermission from '../../../../../../utils/getFieldPermission';
import { SatItemsSumData } from './interfaces';

import { Container } from './styles';
import { renderNumber } from '../../../../../../utils/formatLocale';

interface ITotalDetailsProps {
  idSat: number;
  userFields: IRoleEntityField[];
  ref: Ref<ITotalDetailsRef>;
  satCurrencyAbbreviation?: string;
}

export interface ITotalDetailsRef {
  loadItemsSumData(): void;
}

const TotalDetails: React.FC<ITotalDetailsProps> = React.forwardRef(
  ({ idSat, userFields, satCurrencyAbbreviation }, ref) => {
    // Referencia ao toast
    const { toastRef } = useRefHook();

    const fieldsPermissions: SatItemsSumData = useMemo(() => {
      return {
        amount: getFieldPermission(
          satItemsRoles.fields.idFieldTotalAmountSun,
          userFields,
        ),
        amountCot: getFieldPermission(
          satItemsRoles.fields.idFieldTotalAmountCot,
          userFields,
        ),
        cbmTotal: getFieldPermission(
          satItemsRoles.fields.idFieldTotalCBM,
          userFields,
        ),
        estimatedMarginItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalEstimatedMargin,
          userFields,
        ),
        grossWeightTotal: getFieldPermission(
          satItemsRoles.fields.idFieldTotalMGWT,
          userFields,
        ),
        marginItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalMargin,
          userFields,
        ),
        masterNetWeightTotal: getFieldPermission(
          satItemsRoles.fields.idFieldTotalMNWT,
          userFields,
        ),
        quantityOfItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalQuantitySatItems,
          userFields,
        ),
        quantityPiItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalQuantity,
          userFields,
        ),
        totalCotCiItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalSumCotCi,
          userFields,
        ),
        totalSunCiItems: getFieldPermission(
          satItemsRoles.fields.idFieldTotalSumSunCi,
          userFields,
        ),
        quantityContainer: getFieldPermission(
          satItemsRoles.fields.idFieldTotalQuantityCtn,
          userFields,
        ),
        totalCotPiShipmentSum: getFieldPermission(
          satItemsRoles.fields.idFieldTotalCotPiShipmentSum,
          userFields,
        ),
        totalSunPiShipmentSum: getFieldPermission(
          satItemsRoles.fields.idFieldTotalSunPiShipmentSum,
          userFields,
        ),
        marginShipmentSum: getFieldPermission(
          satItemsRoles.fields.idFieldMarginShipmentSum,
          userFields,
        ),
      };
    }, [userFields]);

    const getSumDataFromSatItemsQuery = gql`
    query GetSumDataFromSatItems($idSat: Int!) {
      getSumDataFromSatItems(idSat: $idSat) {
        ${
          fieldsPermissions.masterNetWeightTotal.view
            ? 'masterNetWeightTotalItemsSum'
            : ''
        }
        ${
          fieldsPermissions.grossWeightTotal.view
            ? 'grossWeightTotalItemsSum'
            : ''
        }
        ${fieldsPermissions.amount.view ? 'amountItemsSum' : ''}
        ${fieldsPermissions.amountCot.view ? 'amountCotItemsSum' : ''}
        ${fieldsPermissions.cbmTotal.view ? 'cbmTotalItemsSum' : ''}
        quantityOfItemsSum
        ${fieldsPermissions.marginItems.view ? 'marginItemsSum' : ''}
        ${fieldsPermissions.quantityPiItems.view ? 'quantityPiItemsSum' : ''}
        ${
          fieldsPermissions.quantityContainer.view
            ? 'quantityContainerItemsSum'
            : ''
        }
        ${fieldsPermissions.totalSunCiItems.view ? 'totalSunCiItemsSum' : ''}
        ${fieldsPermissions.totalCotCiItems.view ? 'totalCotCiItemsSum' : ''}
        ${
          fieldsPermissions.estimatedMarginItems.view
            ? 'estimatedMarginItemsSum'
            : ''
        }
        ${fieldsPermissions.marginShipmentSum.view ? 'marginShipmentSum' : ''}
        ${
          fieldsPermissions.totalCotPiShipmentSum.view
            ? 'totalCotPiShipmentSum'
            : ''
        }
        ${
          fieldsPermissions.totalSunPiShipmentSum.view
            ? 'totalSunPiShipmentSum'
            : ''
        }
      }
    }
  `;

    /**
     * Busca SAT Items Sum
     */
    const [loadItemsSumData, { data: satItemsSumData, loading }] = useLazyQuery(
      getSumDataFromSatItemsQuery,
      {
        variables: {
          idSat,
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting SAT Items SUM data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      },
    );

    useImperativeHandle(ref, () => ({
      loadItemsSumData: () => {
        loadItemsSumData();
      },
    }));

    return (
      <Container>
        {satItemsSumData && !loading && (
          <>
            {fieldsPermissions.quantityPiItems.view && (
              <h1>
                Quantity:{' '}
                {renderNumber(
                  satItemsSumData.getSumDataFromSatItems.quantityPiItemsSum,
                  'int',
                )}
              </h1>
            )}
            {fieldsPermissions.quantityOfItems.view && (
              <h3>
                Quantity of SAT Items:
                {renderNumber(
                  satItemsSumData.getSumDataFromSatItems.quantityOfItemsSum,
                  'int',
                )}
              </h3>
            )}
            <div>
              <span>
                <h3>Selling Package</h3>

                {fieldsPermissions.quantityContainer.view && (
                  <p>
                    <b>Quantity of CTN:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .quantityContainerItemsSum,
                      'int',
                    )}
                  </p>
                )}
                {fieldsPermissions.masterNetWeightTotal.view && (
                  <p
                    className="custom-header"
                    data-pr-tooltip="Master Net Weigth Total"
                    data-pr-position="right"
                  >
                    <b>Net Weight Total:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .masterNetWeightTotalItemsSum,
                      'float',
                    )}
                  </p>
                )}
                {fieldsPermissions.grossWeightTotal.view && (
                  <p
                    className="custom-header"
                    data-pr-tooltip="Gross Weigth Total"
                    data-pr-position="right"
                  >
                    <b>Gross Weight Total:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .grossWeightTotalItemsSum,
                      'float',
                    )}
                  </p>
                )}
              </span>

              <span>
                <h3>Total</h3>

                {fieldsPermissions.cbmTotal.view && (
                  <p>
                    <b>CBM:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.cbmTotalItemsSum,
                      'float',
                    )}
                  </p>
                )}
              </span>
              <span>
                <h3>Amount PI</h3>

                {fieldsPermissions.amountCot.view && (
                  <p>
                    <b>Total COT PI:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.amountCotItemsSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.amount.view && (
                  <p>
                    <b>Total SUN PI:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.amountItemsSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.estimatedMarginItems.view && (
                  <p>
                    <b>Estimated Margin:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .estimatedMarginItemsSum,
                      'percent',
                    )}
                  </p>
                )}
              </span>
              <span>
                <h3>Amount PI Shipment</h3>

                {fieldsPermissions.totalCotPiShipmentSum.view && (
                  <p>
                    <b>Total COT PI Shipment:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .totalCotPiShipmentSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.totalSunPiShipmentSum.view && (
                  <p>
                    <b>Total SUN PI Shipment:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems
                        .totalSunPiShipmentSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.marginShipmentSum.view && (
                  <p>
                    <b>Margin Shipment:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.marginShipmentSum,
                      'percent',
                    )}
                  </p>
                )}
              </span>
              <span>
                <h3>Amount CI</h3>

                {fieldsPermissions.totalCotCiItems.view && (
                  <p>
                    <b>Total COT CI:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.totalCotCiItemsSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.totalSunCiItems.view && (
                  <p>
                    <b>Total SUN CI:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.totalSunCiItemsSum,
                      'currency',
                      satCurrencyAbbreviation,
                    )}
                  </p>
                )}
                {fieldsPermissions.marginItems.view && (
                  <p>
                    <b>Margin:</b>{' '}
                    {renderNumber(
                      satItemsSumData.getSumDataFromSatItems.marginItemsSum,
                      'percent',
                    )}
                  </p>
                )}
              </span>
            </div>
          </>
        )}
        {loading && (
          <>
            <Skeleton width="15rem" height="4rem" />
            <Skeleton width="20rem" height="2rem" />
            <div>
              <span>
                <Skeleton width="10rem" height="2rem" />

                <Skeleton width="5rem" height="1rem" />
                <Skeleton width="11rem" height="1rem" />
                <Skeleton width="12rem" height="1rem" />
              </span>
              <span>
                <Skeleton width="10rem" height="2rem" />

                <Skeleton width="5rem" height="1rem" />
                <Skeleton width="11rem" height="1rem" />
                <Skeleton width="12rem" height="1rem" />
              </span>

              <span>
                <Skeleton width="10rem" height="2rem" />

                <Skeleton width="5rem" height="1rem" />
                <Skeleton width="11rem" height="1rem" />
                <Skeleton width="12rem" height="1rem" />
              </span>

              <span>
                <Skeleton width="10rem" height="2rem" />

                <Skeleton width="5rem" height="1rem" />
                <Skeleton width="11rem" height="1rem" />
                <Skeleton width="12rem" height="1rem" />
              </span>
              <span>
                <Skeleton width="10rem" height="2rem" />

                <Skeleton width="5rem" height="1rem" />
                <Skeleton width="11rem" height="1rem" />
                <Skeleton width="12rem" height="1rem" />
              </span>
            </div>
          </>
        )}
      </Container>
    );
  },
);

export default TotalDetails;
