export const satStatusRoles = {
  idEntity: 57,
  idModule: 4,
  fields: {},
  permissions: {
    registerProductDetailsUpdateMissingInfo: 201,
    registerProductDetailsDone: 202,
    registerClassificacaoFiscalRequestClassificacaoFiscal: 203,
    registerHRPCheck: 204,
    registerHRPNotRequired: 205,
    registerClientRegisterRequest: 206,
    registerClientRegisterNotRequired: 207,
    artworkProductInfoForAwUpdateMissingInfo: 208,
    artworkProductInfoForAwDone: 209,
  },
};
