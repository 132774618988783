/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm } from './styles';
import PageHeader from '../../components/PageHeader';

import Button from '../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import MainButton from '../../components/MainButton';

import { useRefHook } from '../../hooks/useRefHook';
import Loading from '../../components/Loading';
import FormInput from '../../components/FormInput';
import getValidationErrors from '../../utils/getValidationErrors';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

// Parametros da rota de package
interface PackageRouteParams {
  packageId: string;
}

interface IGeneralInformationFormData {
  description: string;
}

const Package: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID da package
  const { packageId } = useParams<PackageRouteParams>();

  const defaultPageTitle = `${packageId ? 'Manage' : 'Create'} Package`;

  // Nome da package
  const [packageName, setPackageName] = useState('');
  const [versionLock, setVersionLock] = useState();

  useTitle(
    packageName ? `${packageName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Packages',
      path: '/packages',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Query para criar package
  const createPackageQuery = gql`
    mutation CreateDomainMutation($domainData: CreateDomain!) {
      createDomain(domain: $domainData) {
        description
      }
    }
  `;

  // Query para atualizar Package
  const updatePackageQuery = gql`
    mutation UpdatePackageMutation($domainData: UpdateDomain!) {
      updateDomain(domain: $domainData) {
        description
        versionLock
      }
    }
  `;

  // Query para listar dados da Package
  const listPackageQuery = gql`
    query ListDomainQuery($idDomain: Int!) {
      listDomainById(id: $idDomain) {
        description
        idGroup
        versionLock
      }
    }
  `;

  // Carrega dados do Package
  const [
    loadPackageData,
    {
      called: packageCalled,
      loading: packageLoading,
      data: packageData,
      error: packageError,
    },
  ] = useLazyQuery(listPackageQuery, {
    variables: {
      idDomain: parseInt(packageId, 10),
    },
    onCompleted: async response => {
      // Valida se Package nao foi encontrado
      if (!response.listDomainById || response.listDomainById.idGroup !== 11) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Package not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de Packages
        history.push('/Package');
        return;
      }

      // Define novo nome da Package
      setPackageName(response.listDomainById.description);
      setVersionLock(response.listDomainById.versionLock);

      setPageLoading(false);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting package data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/packages');
    },
  });

  // Ao carregar a pagina, carrega dados de Package
  useEffect(() => {
    if (packageId) {
      setPageLoading(true);
      loadPackageData();
    }
  }, [packageId, loadPackageData]);

  // cria método para chamar a mutation
  const [createPackage] = useMutation(createPackageQuery);
  const [updatePackage] = useMutation(updatePackageQuery);

  // Submit do formulario
  const handleCreatePackageSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria Package
        await createPackage({
          variables: {
            domainData: {
              description,
              idGroup: 11,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Package created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de Packages
        history.push('/packages');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating package',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createPackage, toastRef, history],
  );

  // Submit do formulario
  const handleEditPackageSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes de Package
        const updtpckResponse = await updatePackage({
          variables: {
            domainData: {
              idDomain: parseInt(packageId, 10),
              description,
              versionLock,
            },
          },
        });

        // Define novo nome da Package
        setPackageName(description);
        setVersionLock(updtpckResponse.data.updateDomain.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Package updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating package',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updatePackage, packageId, versionLock, toastRef],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/packages'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          packageData
            ? `${defaultPageTitle} - ${packageName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            packageId ? handleEditPackageSubmit : handleCreatePackageSubmit
          }
          initialData={
            packageCalled && !packageLoading && !packageError
              ? packageData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
        {pageLoading && <Loading />}
      </CrudForm>
    </Container>
  );
};
export default Package;
