import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

/**
 * Carrega Domains
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados de Domain
 */
export const asyncSelectLoadDomains: LoadOptions<
  any,
  GroupBase<any>,
  { page: any; id?: number; orderByDescription?: boolean }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listDomainsByGroupIdQuery(
        $id: Int!
        $pagination: Pagination!
        $isActive: Boolean
        $description: String
      ) {
        listDomainsByGroupId(
          id: $id
          pagination: $pagination
          isActive: $isActive
          description: $description
        ) {
          data {
            idDomain
            description
          }
          items
        }
      }
    `,
    variables: {
      pagination: {
        _page: pageData?.page,
        _limit: pagination.itemsPerPage,
        _orderBy: pageData?.orderByDescription ? 'description' : 'idDomain',
      },
      description: search,
      id: pageData?.id,
      isActive: true,
    },
  });

  const filteredOptions = res.data.listDomainsByGroupId.data;
  const quantity = res.data.listDomainsByGroupId.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
      id: pageData?.id,
      orderByDescription: pageData?.orderByDescription,
    },
  };
};
