import { gql, useMutation } from '@apollo/client';
import { decode, sign, verify } from 'jsonwebtoken';
import React, { createContext, useCallback, useContext, useState } from 'react';
import authConfig from '../config/auth';
import { IRoleEntityField } from '../interfaces/IRoleEntityField';
import { IRoleEntityPermission } from '../interfaces/IRoleEntityPermission';
import localStorageKeys from '../config/localStorageKeys';

// Interface das credenciais de login
interface ISignInCredentials {
  username: string;
  password: string;
}

interface IModule {
  idModule: number;
  name: string;
}

interface IEntity {
  idEntity: number;
  name: string;
  menuName: string;
  menuIcon: string;
  active: boolean;
  idModule2: IModule;
}

interface IRoleEntity {
  idRoleEntity: number;
  idEntity2: IEntity;
  roleEntityFields: IRoleEntityField[];
  roleEntityPermissions: IRoleEntityPermission[];
}

interface IRoleUserData {
  idRole: number;
  name: string;
  roleEntities: IRoleEntity[];
}

export interface IRoleUser {
  idRoleUser: number;
  idRole2: IRoleUserData;
}

interface IRole {
  modules: number[];
  entities: number[];
  rolesUser: IRoleUser[];
}

interface IPayload {
  // Subject do token
  sub: string;
}

/**
 * Interface para request
 */
interface IPayload {
  // Subject do token
  roles: IRole;
}

// Interface do contexto de autenticação
interface IAuthContext {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  roles: IRole;
  idUser: number;
}

interface IUser {
  idUser: number;
  firstName: string;
  lastName: string;
  username: string;
  avatarUrl: string;
  idOffice?: number;
  userOffices: {
    idOffice: number;
  }[];
}

// Interface do estado de autenticação
interface IAuthState {
  token: string;
  refreshToken: string;
  user: IUser;
  roles: IRole;
  idUser: number;
}

// Cria o contexto
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

function getRoles(encodedRoles?: string) {
  // Se encontrar as roles
  if (encodedRoles) {
    try {
      // Valida se roles nao foram alteradas no localStorage
      const { roles } = verify(
        encodedRoles,
        authConfig.secretRolesToken,
      ) as IPayload;
      // Retorna roles
      return roles;
    } catch (error) {
      // Se roles foram manipuladas, desloga usuario
      return {} as IRole;
    }
  }
  // Se nao encontrar roles, desloga usuario
  return {} as IRole;
}

const AuthProvider: React.FC = ({ children }) => {
  // Estado com dados de autenticação
  const [data, setData] = useState<IAuthState>(() => {
    // Busca token e usuário do localStorage
    const token = localStorage.getItem(localStorageKeys.token);
    const refreshToken = localStorage.getItem(localStorageKeys.refreshToken);
    const user = localStorage.getItem(localStorageKeys.user);
    const encodedRoles = localStorage.getItem(localStorageKeys.roles);

    // Se já houverem dados, os retorna
    if (token && refreshToken && user && encodedRoles) {
      const roles = getRoles(encodedRoles);
      // Busca ID do user que esta fazendo a requisicao
      const { sub: idUser } = decode(token) as IPayload;
      return {
        token,
        refreshToken,
        user: JSON.parse(user),
        roles,
        idUser: parseInt(idUser, 10),
      };
    }

    // Se não, retorna vazio
    return {} as IAuthState;
  });

  // Mutation de sessão
  const createSessionQuery = gql`
    mutation CreateSession($username: String!, $password: String!) {
      createSession(username: $username, password: $password) {
        user {
          idUser
          firstName
          lastName
          username
          idOffice
          avatarUrl
          userOffices {
            idOffice
          }
        }
        token
        refresh_token
        needPasswordReset
        roles {
          modules
          entities
          rolesUser {
            idRoleUser
            idRole2 {
              idRole
              name
              roleEntities {
                idRoleEntity
                idEntity2 {
                  idEntity
                  name
                  menuIcon
                  menuName
                  active
                  idModule2 {
                    idModule
                    name
                  }
                }
                roleEntityFields {
                  idRoleEntityField
                  idField
                  view
                  create
                  edit
                }
                roleEntityPermissions {
                  idRoleEntityPermission
                  idRoleEntity
                  idPermission
                }
              }
            }
          }
        }
        localStorageData {
          key
          value
        }
      }
    }
  `;

  // cria método para chamar a mutation
  const [createSession] = useMutation(createSessionQuery);

  // Efetua login na aplicação
  const signIn = useCallback(
    // Chama a mutation
    async ({ username, password }) => {
      const response = await createSession({
        variables: { username, password },
      });

      // Obtem as informações de retorno
      const {
        token,
        refresh_token: refreshToken,
        user,
        needPasswordReset,
        roles,
        localStorageData,
      } = response.data.createSession;

      // Salva refresh token no localStorage
      localStorage.setItem(localStorageKeys.refreshToken, refreshToken);

      // Valida se usuario precisa de reset de senha
      if (needPasswordReset) {
        localStorage.removeItem(localStorageKeys.user);
        window.location.href = '/reset-password/requested';
        return;
      }

      // Gera token com base nas roles
      const encodedRoles = sign({ roles }, authConfig.secretRolesToken);

      // Salva os dados no localStorage
      localStorage.setItem(localStorageKeys.roles, encodedRoles);
      localStorage.setItem(localStorageKeys.token, token);
      localStorage.setItem(localStorageKeys.user, JSON.stringify(user));

      // Se usuario possuir dados de local storage
      if (localStorageData?.length) {
        // Salva todos no local storage do navegador
        localStorageData.forEach((userLocalStorageData: any) => {
          localStorage.setItem(
            userLocalStorageData.key,
            JSON.stringify(userLocalStorageData.value),
          );
        });
      }

      const userRoles = getRoles(encodedRoles);

      // Busca ID do user que esta fazendo a requisicao
      const { sub: idUser } = decode(token) as IPayload;

      setData({
        token,
        refreshToken,
        user,
        roles: userRoles,
        idUser: parseInt(idUser, 10),
      });
    },
    [createSession],
  );

  // Efetua logout na aplicação
  const signOut = useCallback(() => {
    // Remove as informações do localStorage
    localStorage.clear();

    // Limpa o estado
    setData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        roles: data.roles,
        idUser: data.idUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  // Busca contexto
  const context = useContext(AuthContext);

  // Valida o uso no arquivo app.tsx
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  // retorna contexto
  return context;
}

export { AuthProvider, useAuth };
