import { gql } from '@apollo/client';

export const listProductImagesQuery = gql`
  query ListProductImages($data: ListProductImagesInput!) {
    listProductImages(data: $data) {
      items
      data {
        idProductImage
        title
        imageUrl
        idAttachment
        idAttachment2 {
          name
        }
        productImageTypes {
          idProductImageType
          type
        }
      }
    }
  }
`;

export const updateProductImageTypesQuery = gql`
  mutation UpdateProductImageTypes($data: [UpdateProductImageTypesInput]!) {
    updateProductImageTypes(data: $data) {
      idProductImageType
    }
  }
`;

export const deleteProductImagesQuery = gql`
  mutation DeleteProductImages($idsProductImages: [Int]!) {
    deleteProductImages(idsProductImages: $idsProductImages)
  }
`;
