import {
  ProductAttachmentsReducer,
  ProductAttachmentsReducerAction,
  ProductAttachmentsReducerActionKind,
} from './interfaces';

export const productAttachmentsReducerInitialState: ProductAttachmentsReducer =
  {
    productAttachments: [],
    selectedProductAttachments: [],
    productAttachmentsToUpdate: new Set<number>(),
  };

export const productAttachmentsReducer = (
  state: ProductAttachmentsReducer,
  action: ProductAttachmentsReducerAction,
): ProductAttachmentsReducer => {
  switch (action.type) {
    case ProductAttachmentsReducerActionKind.SET_PRODUCT_ATTACHMENTS:
      state.productAttachmentsToUpdate.clear();

      return {
        ...state,
        productAttachments: action.payload?.productAttachments ?? [],
        selectedProductAttachments: [],
      };
    case ProductAttachmentsReducerActionKind.SET_SELECTED_PRODUCT_ATTACHMENTS:
      return {
        ...state,
        selectedProductAttachments:
          action.payload?.selectedProductAttachments ?? [],
      };
    case ProductAttachmentsReducerActionKind.SET_PRODUCT_ATTACHMENTS_TO_UPDATE:
      if (
        !action.payload?.productAttachments ||
        !action.payload?.productAttachments.length
      ) {
        throw new Error(
          'You need to inform the updated product attachments array',
        );
      }
      if (!action.payload.idProductImageUpdated) {
        throw new Error('You need to inform the idProductImageUpdated');
      }

      state.productAttachmentsToUpdate.add(
        action.payload.idProductImageUpdated,
      );

      return {
        ...state,
        productAttachments: action.payload.productAttachments,
      };

    case ProductAttachmentsReducerActionKind.CLEAN_PRODUCT_ATTACHMENTS_RELATED_DATA:
      state.productAttachmentsToUpdate.clear();

      return {
        ...state,
        productAttachments: [],
        selectedProductAttachments: [],
      };
    default:
      return state;
  }
};
