import { gql } from '@apollo/client';

export const createOceanFreightsBulkQuery = gql`
  mutation createLogisticsOceanFreightsBulk($uploadedFile: String!) {
    createLogisticsOceanFreightsBulk(uploadedFile: $uploadedFile) {
      oceanFreightsTotal
      createdOceanFreights
      oceanFreightsWithError
    }
  }
`;

export const updateOceanFreightsBulkQuery = gql`
  mutation updateLogisticsOceanFreightsBulk($uploadedFile: String!) {
    updateLogisticsOceanFreightsBulk(uploadedFile: $uploadedFile) {
      oceanFreightsTotal
      updatedOceanFreights
      oceanFreightsWithError
    }
  }
`;
