/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import {
  IInlandFreight,
  IInlandFreightFormData,
  InlandFreightRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { inlandFreightRoles } from '../../../shared/roles/inlandFreight';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const InlandFreight: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de inland freight
  const idInlandFreight = parseInt(
    useParams<InlandFreightRouteParams>().idInlandFreight,
    10,
  );

  const defaultPageTitle = `${
    idInlandFreight ? 'Manage' : 'Create'
  } Inland Freight`;

  useTitle(defaultPageTitle);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de Inland Freight
  const [inlandFreight, setInlandFreight] = useState<IInlandFreight>(
    {} as IInlandFreight,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsInlandFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    inlandFreightRoles.idEntity,
  );

  // Permissions de Inland Freight
  const { idPermissionUpdateInlandFreight, idPermissionCreateInlandFreight } =
    inlandFreightRoles.permissions;

  // Valida se usuario pode criar ou atualizar Inland Freight
  const userCanChange = userHasPermission(
    idInlandFreight
      ? idPermissionUpdateInlandFreight
      : idPermissionCreateInlandFreight,
    userPermissionsInlandFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Inland Freights',
      path:
        initialPage && initialFirst
          ? `/logistics/inlandFreights?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/inlandFreights',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: pageData?.page,
          _limit: 25,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar inland freight
  const listInlandFreightQuery = gql`
    query listLogisticInlandFreightById($idInlandFreight: Int!) {
      listLogisticInlandFreightById(idInlandFreight: $idInlandFreight) {
        idInlandFreight
        idPortAirportLoading2 {
          idPort
          name
        }
        idPortAirportDestination2 {
          idPort
          name
        }
        valor20Gp
        idCurValor20Gp2 {
          idCurrency
          currency
          abbreviation
        }
        valor40Hc
        idCurValor40Hc2 {
          idCurrency
          currency
          abbreviation
        }
        idBcValor2 {
          idDomain
          description
        }
        versionLock
        createdAt
        updatedAt
        updatedBy2 {
          firstName
          lastName
        }
        createdBy2 {
          firstName
          lastName
        }
        bcCif
      }
    }
  `;

  /**
   * Busca dados de Inland Freight
   */
  const [loadInlandFreightData, { loading: inlandFreightLoading }] =
    useLazyQuery(listInlandFreightQuery, {
      variables: {
        idInlandFreight,
      },
      onCompleted: response => {
        if (response.listLogisticInlandFreightById) {
          setInlandFreight(response.listLogisticInlandFreightById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Inland Freight not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting inland freight data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/inlandFreights'),
    });
  }

  // Mutation de criacao de inland freight
  const createInlandFreightQuery = gql`
    mutation CreateInlandFreightMutation(
      $data: CreateLogisticsInlandFreightInput!
    ) {
      createLogisticInlandFreight(data: $data) {
        idInlandFreight
      }
    }
  `;

  // Mutation de atualizacao de inland freight
  const updateInlandFreightQuery = gql`
    mutation UpdateInlandFreightMutation(
      $data: UpdateLogisticsInlandFreightInput!
    ) {
      updateLogisticInlandFreight(data: $data) {
        idInlandFreight
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createInlandFreightMutation] = useMutation(createInlandFreightQuery);
  const [updateInlandFreightMutation] = useMutation(updateInlandFreightQuery);

  // Submit do formulario de inland freight
  const handleInlandFreightSubmit = useCallback(
    async (data: IInlandFreightFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idPortAirportLoading: Yup.string()
            .nullable()
            .required('Required Field'),
          idPortAirportDestination: Yup.string()
            .nullable()
            .required('Required Field'),
          idBcValor: Yup.string().nullable().required('Required Field'),
          idCurValor20Gp: Yup.string()
            .nullable()
            .notRequired()
            .when(['valor20Gp'], {
              is: null,
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required('Required Field'),
            }),
          idCurValor40Hc: Yup.string()
            .nullable()
            .notRequired()
            .when(['valor40Hc'], {
              is: null,
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required('Required Field'),
            }),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        // Valida se esta editando ou criando um novo inland freight
        if (!idInlandFreight) {
          // Chamada criacao de inland freight
          response = await createInlandFreightMutation({
            variables: {
              data: {
                idPortAirportLoading: data.idPortAirportLoading,
                idPortAirportDestination: data.idPortAirportDestination,
                idCurValor20Gp: data.idCurValor20Gp,
                valor20Gp: data.valor20Gp,
                idCurValor40Hc: data.idCurValor40Hc,
                valor40Hc: data.valor40Hc,
                idBcValor: data.idBcValor,
                bcCif: !!inlandFreight.bcCif,
              },
            },
          });
        } else {
          // Chamada atualizacao de inland freight
          response = await updateInlandFreightMutation({
            variables: {
              data: {
                idInlandFreight,
                versionLock: inlandFreight.versionLock,
                idPortAirportLoading: data.idPortAirportLoading,
                idPortAirportDestination: data.idPortAirportDestination,
                idCurValor20Gp: data.idCurValor20Gp,
                valor20Gp: data.valor20Gp,
                idCurValor40Hc: data.idCurValor40Hc,
                valor40Hc: data.valor40Hc,
                idBcValor: data.idBcValor,
                bcCif: !!inlandFreight.bcCif,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Inland Freight ${idInlandFreight ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!idInlandFreight) {
          // Direciona usuario para listagem do inland freight
          history.push(
            `/logistics/inlandFreights/${response.data.createLogisticInlandFreight.idInlandFreight}`,
          );
        } else {
          setInlandFreight({} as IInlandFreight);
          loadInlandFreightData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idInlandFreight ? 'updating' : 'creating'
            } inland freight`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createInlandFreightMutation,
      formRef,
      history,
      idInlandFreight,
      loadInlandFreightData,
      inlandFreight.bcCif,
      inlandFreight.versionLock,
      toastRef,
      updateInlandFreightMutation,
    ],
  );

  useEffect(() => {
    if (idInlandFreight) {
      loadInlandFreightData();
    }
  }, [idInlandFreight, loadInlandFreightData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className={idInlandFreight ? 'createdAt' : ''}>
          {inlandFreight && !inlandFreightLoading
            ? `${new Date(inlandFreight?.createdAt).toLocaleString()} by ${
                inlandFreight?.createdBy2?.firstName
              } ${inlandFreight?.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idInlandFreight ? 'updatedAt' : ''}>
          {inlandFreight && !inlandFreightLoading
            ? `${new Date(inlandFreight?.updatedAt).toLocaleString()} by ${
                inlandFreight?.updatedBy2?.firstName
              } ${inlandFreight?.updatedBy2?.lastName}`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {((idInlandFreight && inlandFreight.idInlandFreight) ||
        !idInlandFreight) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleInlandFreightSubmit}
            initialData={
              inlandFreight && !inlandFreightLoading ? inlandFreight : undefined
            }
          >
            <Row>
              <FormAsyncSelect
                name="idPortAirportLoading"
                label="Port/Airport Of Loading"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={inlandFreight?.idPortAirportLoading2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idPortAirportDestination"
                label="Port/Airport Of Destination"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={inlandFreight?.idPortAirportDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormInputNumber
                name="valor20Gp"
                label="Value 20’ GP"
                decimalScale={2}
                min={0}
                decimalSeparator=","
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idCurValor20Gp"
                className="currency-dropdown"
                label="Currency"
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) =>
                  `${option.abbreviation} - ${option.currency}`
                }
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={inlandFreight.idCurValor20Gp2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormInputNumber
                name="valor40Hc"
                label="Value 40’ HC"
                decimalScale={2}
                min={0}
                decimalSeparator=","
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idCurValor40Hc"
                className="currency-dropdown"
                label="Currency"
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) =>
                  `${option.abbreviation} - ${option.currency}`
                }
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={inlandFreight.idCurValor40Hc2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idBcValor"
                className="generalInput"
                label="BC Value"
                required
                loadOptions={asyncSelectLoadDomains}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.description}
                getOptionValue={(option: any) => option.idDomain}
                additional={{
                  page: 1,
                  id: 29,
                }}
                noOptionsMessage={() => 'No BC values found'}
                initialValue={inlandFreight.idBcValor2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <div className="radio-button-div generalInput">
                <label>BC CIF:</label>
                <div>
                  <RadioButton
                    inputId="yes"
                    name="inlandFreight.bcCif"
                    value
                    checked={inlandFreight.bcCif === true}
                    onChange={() =>
                      changeStateProperty('bcCif', true, setInlandFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>Yes</label>
                  <RadioButton
                    inputId="no"
                    name="inlandFreight.bcCif"
                    value={false}
                    checked={inlandFreight.bcCif !== true}
                    onChange={() =>
                      changeStateProperty('bcCif', false, setInlandFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>No</label>
                </div>
              </div>
            </Row>
          </Form>
        </CrudForm>
      )}
      {(inlandFreightLoading || loading) && <Loading />}
    </Container>
  );
};

export default InlandFreight;
