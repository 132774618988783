/* eslint-disable no-param-reassign */
import { Link, useHistory } from 'react-router-dom';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import xlsx from 'xlsx';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../hooks/useRefHook';
import getFieldPermission from '../../../utils/getFieldPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import PageHeader from '../../../components/PageHeader';
import Grid, { GridRef } from '../../../components/Grid';
import Loading from '../../../components/Loading';
import { satsRoles } from '../../../shared/roles/sat';
import { ISatsLazyParams } from './interfaces';
import Tag from '../../../components/Tag';
import Button from '../../../components/Button';
import {
  parseCurrencyPtBrNumberColumn,
  parseSatColumn,
} from '../../../utils/gridColumnsParse';
import userHasPermission from '../../../utils/userHasPermission';
import { getUTC } from '../../../utils/dateUtil';
import { parseToLocaleStringDate } from '../../../utils/convertDate';
import { ColumnData } from '../../../components/Grid/interfaces';
import { gridColumnsData } from './constants';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import MultiSelect from '../../../components/Grid/MultiSelect';
import { ICategory } from '../../../interfaces/ICategory';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import useTitle from '../../../hooks/useTitle';
import { SatStatus } from '../../../components/SatStatusTag';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';

const pageTitle = 'Resume List';

const Sats: React.FC = () => {
  useTitle(pageTitle);
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:satListGridColumns';

  const lazyParamsName = '@SAT:satListLazyParams';

  const advancedFiltersName = '@SAT:satListAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { showError } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<GridRef<any>>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // ID da entity de SAT
  const { idEntity } = satsRoles;

  // Permissions de SAT
  const { idPermissionExportListOfSATs } = satsRoles.permissions;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const {
    idFieldStatusSat,
    idFieldConfirmOrder,
    idFieldSatNumber,
    idFieldSatForeignTradeNumber,
    idFieldMainProduct,
    idFieldSuppliers,
    idFieldImporterConsignee,
    idFieldClientNotify,
    idFieldRealEtd,
    idFieldRealEta,
    idFieldStatusImport,
    idFieldStatusExport,
    idFieldStatusArtwork,
    idFieldShipmentStatus,
    idFieldCountryOrigin,
    idFieldPurchaseIncoterm,
    idFieldSellingIncoterm,
    idFieldResultTotalConvertedCotPi,
    idFieldResultTotalConvertedCotCi,
    idFieldResultTotalConvertedSunPi,
    idFieldResultTotalConvertedSunCi,
    idFieldCategory,
    idFieldClientDepartmentSat,
    idFieldExporter,
    idFieldFirstBuyer,
    idFieldSecondBuyer,
    idFieldFirstSalesUser,
    idFieldSecondSalesUser,
    idFieldPurchaseUser,
    idFieldExportUser,
    idFieldImportUser,
    idFieldDesignUser,
    idFieldFinancialUser,
    idFieldRegisterStatus,
    idFieldProductionStatus,
    idFieldShipmentResume,
    idFieldDocumentsStatus,
    idFieldSupplierPaymentStatus,
    idFieldClientPaymentStatus,
  } = satsRoles.fields;

  const showField = useCallback(
    (idField: number) => {
      return getFieldPermission(idField, userPermissions.userFields).view;
    },
    [userPermissions.userFields],
  );

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  // Colunas da grid
  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;
    const columnList: ColumnData[] = [];

    if (showField(idFieldStatusSat)) {
      columnList.push(gridColumnsData.status);
    }
    if (showField(idFieldConfirmOrder)) {
      columnList.push(gridColumnsData.confirmOrder);
    }
    if (showField(idFieldSatNumber)) {
      columnList.push(gridColumnsData.satNumber);
    }
    if (showField(idFieldSatForeignTradeNumber)) {
      columnList.push(gridColumnsData.satForeignTradeNumber);
    }
    if (showField(idFieldMainProduct)) {
      columnList.push(gridColumnsData.mainProduct);
    }
    if (showField(idFieldSuppliers)) {
      columnList.push(gridColumnsData.suppliers);
    }
    if (showField(idFieldImporterConsignee)) {
      columnList.push(gridColumnsData.importerConsignee);
    }
    if (showField(idFieldClientNotify)) {
      columnList.push(gridColumnsData.clientNotify);
    }
    if (showField(idFieldRealEtd)) {
      columnList.push(gridColumnsData.realEtd);
    }
    if (showField(idFieldRealEta)) {
      columnList.push(gridColumnsData.realEta);
    }
    if (showField(idFieldShipmentResume)) {
      columnList.push(gridColumnsData.shipmentResume);
    }
    if (showField(idFieldStatusImport)) {
      columnList.push(gridColumnsData.importStatus);
    }
    if (showField(idFieldRegisterStatus)) {
      columnList.push(gridColumnsData.registerStatus);
    }
    if (showField(idFieldStatusArtwork)) {
      columnList.push(gridColumnsData.artworkStatus);
    }
    if (showField(idFieldProductionStatus)) {
      columnList.push(gridColumnsData.productionStatus);
    }
    if (showField(idFieldShipmentStatus)) {
      columnList.push(gridColumnsData.shipmentStatus);
    }
    if (showField(idFieldDocumentsStatus)) {
      columnList.push(gridColumnsData.documentsStatus);
    }
    if (showField(idFieldSupplierPaymentStatus)) {
      columnList.push(gridColumnsData.supplierPaymentStatus);
    }
    if (showField(idFieldClientPaymentStatus)) {
      columnList.push(gridColumnsData.clientPaymentStatus);
    }
    if (showField(idFieldStatusExport)) {
      columnList.push(gridColumnsData.exportStatus);
    }
    if (showField(idFieldCountryOrigin)) {
      columnList.push(gridColumnsData.countryOrigin);
    }
    if (showField(idFieldPurchaseIncoterm)) {
      columnList.push(gridColumnsData.purchaseIncoterm);
    }
    if (showField(idFieldSellingIncoterm)) {
      columnList.push(gridColumnsData.sellingIncoterm);
    }
    if (showField(idFieldResultTotalConvertedCotPi)) {
      columnList.push(gridColumnsData.resultTotalConvertedCotPi);
    }
    if (showField(idFieldResultTotalConvertedCotCi)) {
      columnList.push(gridColumnsData.resultTotalConvertedCotCi);
    }
    if (showField(idFieldResultTotalConvertedSunPi)) {
      columnList.push(gridColumnsData.resultTotalConvertedSunPi);
    }
    if (showField(idFieldResultTotalConvertedSunCi)) {
      columnList.push(gridColumnsData.resultTotalConvertedSunCi);
    }
    if (showField(idFieldCategory)) {
      columnList.push(gridColumnsData.category);
    }
    if (showField(idFieldExporter)) {
      columnList.push(gridColumnsData.exporter);
    }
    if (showField(idFieldClientDepartmentSat)) {
      columnList.push(gridColumnsData.clientDepartment);
    }
    if (showField(idFieldFirstBuyer)) {
      columnList.push(gridColumnsData.firstBuyer);
    }
    if (showField(idFieldSecondBuyer)) {
      columnList.push(gridColumnsData.secondBuyer);
    }
    if (showField(idFieldFirstSalesUser)) {
      columnList.push(gridColumnsData.firstSalesUser);
    }
    if (showField(idFieldSecondSalesUser)) {
      columnList.push(gridColumnsData.secondSalesUser);
    }
    if (showField(idFieldPurchaseUser)) {
      columnList.push(gridColumnsData.purchaseUser);
    }
    if (showField(idFieldExportUser)) {
      columnList.push(gridColumnsData.exportUser);
    }
    if (showField(idFieldImportUser)) {
      columnList.push(gridColumnsData.importUser);
    }
    if (showField(idFieldDesignUser)) {
      columnList.push(gridColumnsData.designUser);
    }
    if (showField(idFieldFinancialUser)) {
      columnList.push(gridColumnsData.financialUser);
    }
    return { columnList };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams: ISatsLazyParams = {
    ...pagination.initialLazyParams,
  };

  const [lazyParams, setLazyParams] = useState<ISatsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: ISatsLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: ISatsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns.columnList);

  const filteredColumnsHeader = useMemo(() => {
    return columns.columnList.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns.columnList, lazyParams]);

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.columnList.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do porto clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/commercial/sats/${e.data.idSat}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  const onSort = (event: DataTableSortEvent) => {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField ?? initialLazyParams.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = useCallback(
    (event: DataTablePageEvent) => {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    },
    [changeLazyParams, lazyParams],
  );

  // Query para listar sats
  const listSatsQuery = gql`
    query listSatListResumeViewQuery($data: ListSatListResumeViewInput!) {
      listSatListResumeView(data: $data) {
        items
        data {
          idSat
          ${showField(idFieldStatusSat) ? 'status' : ''}
          ${showField(idFieldConfirmOrder) ? 'confirmOrder' : ''}
          ${showField(idFieldSatNumber) ? 'satNumber' : ''}
          ${
            showField(idFieldSatForeignTradeNumber)
              ? `idSatForeignTrade
              satForeignTradeNumber`
              : ''
          }
          ${showField(idFieldMainProduct) ? 'mainProduct' : ''}
          ${
            showField(idFieldExporter)
              ? `idSupplierExporter
              exporter`
              : ''
          }
          ${
            showField(idFieldImporterConsignee)
              ? `idImporter
              importerConsignee`
              : ''
          }
          ${
            showField(idFieldClientNotify)
              ? `idClient
              clientNotify`
              : ''
          }
          ${showField(idFieldCountryOrigin) ? 'countryOrigin' : ''}
          ${showField(idFieldSellingIncoterm) ? 'sellingIncoterm' : ''}
          ${showField(idFieldPurchaseIncoterm) ? 'purchaseIncoterm' : ''}
          ${showField(idFieldRealEtd) ? 'realEtd' : ''}
          ${showField(idFieldRealEta) ? 'realEta' : ''}
          ${showField(idFieldShipmentResume) ? 'shipmentResume' : ''}
          ${showField(idFieldStatusImport) ? 'importStatus' : ''}
          ${showField(idFieldRegisterStatus) ? 'registerStatus' : ''}
          ${showField(idFieldStatusArtwork) ? 'artworkStatus' : ''}
          ${showField(idFieldProductionStatus) ? 'productionStatus' : ''}
          ${showField(idFieldShipmentStatus) ? 'shipmentStatus' : ''}
          ${showField(idFieldDocumentsStatus) ? 'documentsStatus' : ''}
          ${
            showField(idFieldSupplierPaymentStatus)
              ? 'supplierPaymentStatus'
              : ''
          }
          ${showField(idFieldClientPaymentStatus) ? 'clientPaymentStatus' : ''}
          ${showField(idFieldStatusExport) ? 'exportStatus' : ''}
          ${showField(idFieldSuppliers) ? 'suppliers' : ''}
          ${showField(idFieldFirstBuyer) ? 'firstBuyer' : ''}
          ${showField(idFieldSecondBuyer) ? 'secondBuyer' : ''}
          ${showField(idFieldFirstSalesUser) ? 'firstSalesUser' : ''}
          ${showField(idFieldSecondSalesUser) ? 'secondSalesUser' : ''}
          ${showField(idFieldPurchaseUser) ? 'purchaseUser' : ''}
          ${showField(idFieldExportUser) ? 'exportUser' : ''}
          ${showField(idFieldImportUser) ? 'importUser' : ''}
          ${showField(idFieldDesignUser) ? 'designUser' : ''}
          ${showField(idFieldFinancialUser) ? 'financialUser' : ''}
          ${
            showField(idFieldResultTotalConvertedCotPi)
              ? 'resultTotalConvertedCotPi'
              : ''
          }
          ${
            showField(idFieldResultTotalConvertedCotCi)
              ? 'resultTotalConvertedCotCi'
              : ''
          }
          ${
            showField(idFieldResultTotalConvertedSunPi)
              ? 'resultTotalConvertedSunPi'
              : ''
          }
          ${
            showField(idFieldResultTotalConvertedSunCi)
              ? 'resultTotalConvertedSunCi'
              : ''
          }
          ${showField(idFieldCategory) ? 'category' : ''}
          ${showField(idFieldClientDepartmentSat) ? 'clientDepartment' : ''}
        }
      }
    }
  `;

  const lazyParamsVariables = {
    idSat: lazyParams.idSat,
    status: lazyParams.status,
    statusSat: lazyParams.statusSat,
    confirmOrder: lazyParams.confirmOrder,
    satNumber: lazyParams.satNumber,
    satForeignTradeNumber: lazyParams.satForeignTradeNumber,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    mainProduct: lazyParams.mainProduct,
    suppliers: lazyParams.suppliers,
    idSupplierExporter: lazyParams.idSupplierExporter,
    exporter: lazyParams.exporter,
    idClient: lazyParams.idClient,
    clientNotify: lazyParams.clientNotify,
    idImporter: lazyParams.idImporter,
    importerConsignee: lazyParams.importerConsignee,
    realEtd: lazyParams.realEtd,
    realEta: lazyParams.realEta,
    shipmentResume: lazyParams.shipmentResume,
    idImportStatus: lazyParams.idImportStatus,
    importStatus: lazyParams.importStatus,
    idRegisterStatus: lazyParams.idRegisterStatus,
    registerStatus: lazyParams.registerStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    artworkStatus: lazyParams.artworkStatus,
    productionStatus: lazyParams.productionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    shipmentStatus: lazyParams.shipmentStatus,
    idDocumentsStatus: lazyParams.idDocumentsStatus,
    documentsStatus: lazyParams.documentsStatus,
    supplierPaymentStatus: lazyParams.supplierPaymentStatus,
    clientPaymentStatus: lazyParams.clientPaymentStatus,
    idExportStatus: lazyParams.idExportStatus,
    exportStatus: lazyParams.exportStatus,
    idCountryOrigin: lazyParams.idCountryOrigin,
    countryOrigin: lazyParams.countryOrigin,
    idCategory: lazyParams.idCategory,
    category: findParentsOnTree(categories, lazyParams.category),
    idPurchaseIncoterm: lazyParams.idPurchaseIncoterm,
    purchaseIncoterm: lazyParams.purchaseIncoterm,
    resultTotalConvertedCotPi: lazyParams.resultTotalConvertedCotPi,
    resultTotalConvertedCotCi: lazyParams.resultTotalConvertedCotCi,
    idClientIncoterm: lazyParams.idClientIncoterm,
    sellingIncoterm: lazyParams.sellingIncoterm,
    resultTotalConvertedSunPi: lazyParams.resultTotalConvertedSunPi,
    resultTotalConvertedSunCi: lazyParams.resultTotalConvertedSunCi,
    clientDepartment: lazyParams.clientDepartment,
    idFirstBuyer: lazyParams.idFirstBuyer,
    firstBuyer: lazyParams.firstBuyer,
    idSecondBuyer: lazyParams.idSecondBuyer,
    secondBuyer: lazyParams.secondBuyer,
    idSalesUser: lazyParams.idSalesUser,
    firstSalesUser: lazyParams.firstSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    secondSalesUser: lazyParams.secondSalesUser,
    idPurchaseUser: lazyParams.idPurchaseUser,
    purchaseUser: lazyParams.purchaseUser,
    idComexExportUser: lazyParams.idComexExportUser,
    exportUser: lazyParams.exportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    importUser: lazyParams.importUser,
    idDesignerUser: lazyParams.idDesignerUser,
    designUser: lazyParams.designUser,
    idFinancialUser: lazyParams.idFinancialUser,
    financialUser: lazyParams.financialUser,
  };

  /**
   * Busca SATs
   */
  const { loading: satsLoading, data: satsData } = useQuery(listSatsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        allColumns: lazyParams.globalFilter,
        ...lazyParamsVariables,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting SATs',
        detail: errorData.message,
      });
    },
  });

  /**
   * Query para buscar os somatorios das colunas para adicionar no footer
   */
  const getSumDataFromSatResumeListQuery = gql`
    query GetSumDataFromSatResumeList(
      $data: GetSumDataFromSatResumeListInput!
    ) {
      getSumDataFromSatResumeList(data: $data) {
        satCount
        resultTotalConvertedCotPi
        resultTotalConvertedCotCi
        resultTotalConvertedSunPi
        resultTotalConvertedSunCi
      }
    }
  `;

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatResumeListQuery,
    {
      variables: {
        data: {
          allColumns: lazyParams.globalFilter,
          fieldsSearch: lazyParamsVariables,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Resume List totals',
          detail: errorData.message,
        });
      },
    },
  );

  /**
   * Exporta dados para xlsx
   */
  async function exportToXlsx(satsExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.columnList.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    satsExportData.listSatListResumeView.data.forEach((sat: any) => {
      columnsToRemove.forEach(column => {
        delete sat[column.field];
      });

      // Remover colunas
      delete sat.idSat;
      delete sat.idSatForeignTrade;
      delete sat.idSupplierExporter;
      delete sat.idImporter;
      delete sat.idClient;
      delete sat.idImportStatus;
      delete sat.idExportStatus;
      delete sat.idStatusArtwork;
      delete sat.idCountryOrigin;
      delete sat.idCategory;
      delete sat.idPurchaseIncoterm;
      delete sat.idClientIncoterm;
      delete sat.idFirstBuyer;
      delete sat.idSecondBuyer;
      delete sat.idSalesUser;
      delete sat.idSalesSecondUser;
      delete sat.idPurchaseUser;
      delete sat.idComexExportUser;
      delete sat.idComexImportUser;
      delete sat.idDesignerUser;
      delete sat.idFinancialUser;

      sat.realEta = sat.realEta
        ? parseToLocaleStringDate(getUTC(sat.realEta))
        : '';
      sat.realEtd = sat.realEtd
        ? parseToLocaleStringDate(getUTC(sat.realEtd))
        : '';
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: any) =>
        item !== null &&
        item !== columnsToRemove.find(a => a.field === item)?.field,
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'status':
          columnsOrder[index] = 'status';
          break;
        case 'confirmOrder':
          columnsOrder[index] = 'confirmOrder';
          break;
        case 'satNumber':
          columnsOrder[index] = 'satNumber';
          break;
        case 'satForeignTradeNumber':
          columnsOrder[index] = 'satForeignTradeNumber';
          break;
        case 'mainProduct':
          columnsOrder[index] = 'mainProduct';
          break;
        case 'suppliers':
          columnsOrder[index] = 'suppliers';
          break;
        case 'importerConsignee':
          columnsOrder[index] = 'importerConsignee';
          break;
        case 'clientNotify':
          columnsOrder[index] = 'clientNotify';
          break;
        case 'realEtd':
          columnsOrder[index] = 'realEtd';
          break;
        case 'realEta':
          columnsOrder[index] = 'realEta';
          break;
        case 'shipmentResume':
          columnsOrder[index] = 'shipmentResume';
          break;
        case 'importStatus':
          columnsOrder[index] = 'importStatus';
          break;
        case 'registerStatus':
          columnsOrder[index] = 'registerStatus';
          break;
        case 'artworkStatus':
          columnsOrder[index] = 'artworkStatus';
          break;
        case 'productionStatus':
          columnsOrder[index] = 'productionStatus';
          break;
        case 'shipmentStatus':
          columnsOrder[index] = 'shipmentStatus';
          break;
        case 'documentsStatus':
          columnsOrder[index] = 'documentsStatus';
          break;
        case 'supplierPaymentStatus':
          columnsOrder[index] = 'supplierPaymentStatus';
          break;
        case 'clientPaymentStatus':
          columnsOrder[index] = 'clientPaymentStatus';
          break;
        case 'exportStatus':
          columnsOrder[index] = 'exportStatus';
          break;
        case 'countryOrigin':
          columnsOrder[index] = 'countryOrigin';
          break;
        case 'purchaseIncoterm':
          columnsOrder[index] = 'purchaseIncoterm';
          break;
        case 'sellingIncoterm':
          columnsOrder[index] = 'sellingIncoterm';
          break;
        case 'resultTotalConvertedCotPi':
          columnsOrder[index] = 'resultTotalConvertedCotPi';
          break;
        case 'resultTotalConvertedCotCi':
          columnsOrder[index] = 'resultTotalConvertedCotCi';
          break;
        case 'resultTotalConvertedSunPi':
          columnsOrder[index] = 'resultTotalConvertedSunPi';
          break;
        case 'resultTotalConvertedSunCi':
          columnsOrder[index] = 'resultTotalConvertedSunCi';
          break;
        case 'category':
          columnsOrder[index] = 'category';
          break;
        case 'exporter':
          columnsOrder[index] = 'exporter';
          break;
        case 'clientDepartment':
          columnsOrder[index] = 'clientDepartment';
          break;
        case 'firstBuyer':
          columnsOrder[index] = 'firstBuyer';
          break;
        case 'secondBuyer':
          columnsOrder[index] = 'secondBuyer';
          break;
        case 'firstSalesUser':
          columnsOrder[index] = 'firstSalesUser';
          break;
        case 'secondSalesUser':
          columnsOrder[index] = 'secondSalesUser';
          break;
        case 'purchaseUser':
          columnsOrder[index] = 'purchaseUser';
          break;
        case 'exportUser':
          columnsOrder[index] = 'exportUser';
          break;
        case 'importUser':
          columnsOrder[index] = 'importUser';
          break;
        case 'designUser':
          columnsOrder[index] = 'designUser';
          break;
        case 'financialUser':
          columnsOrder[index] = 'financialUser';
          break;

        default:
          break;
      }
    });

    // Gera arquivo xlsx

    const worksheet = xlsx.utils.json_to_sheet(
      satsExportData.listSatListResumeView.data,
      {
        header: columnsOrder,
      },
    );

    worksheet['!autofilter'] = { ref: 'A1:AD1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'sats');
  }

  /**
   * Busca sats para exportar para XLSX
   */
  const [
    loadSatsToExport,
    { loading: satsExportLoading, data: satsToExportData },
  ] = useLazyQuery(listSatsQuery, {
    onCompleted: () => {
      if (satsToExportData.listSatListResumeView) {
        exportToXlsx(satsToExportData);
      } else {
        showError({
          summary: 'Error while exporting sats',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting sats',
        detail: errorData.message,
      });
    },
  });

  const parseSatForeignTradeNameColumn = (rowData: any) => {
    return (
      rowData.idSatForeignTrade && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
          style={{ justifyContent: 'space-between' }}
        >
          {rowData.satForeignTradeNumber}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseExporterColumn = (rowData: any) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseSatStatusColumn = (rowData: any) => {
    switch (rowData.status) {
      case SatStatus.ACTIVE:
        return <Tag className="tag" value="Active" severity="success" />;
      case SatStatus.CANCELED:
        return <Tag className="tag" value="Canceled" severity="danger" />;
      case SatStatus.STAND_BY:
        return <Tag className="tag" value="Stand by" severity="warning" />;
      case SatStatus.FINISHED:
        return <Tag className="tag" value="Finished" severity="secondary" />;
      default:
        return undefined;
    }
  };

  const parseDateColumns = (rowData: any, field: any) => {
    return rowData[field.field]
      ? parseToLocaleStringDate(getUTC(rowData[field.field]))
      : '';
  };

  const parseCurrencyColumns = (rowData: any, field: any) => {
    return rowData[field.field]
      ? parseCurrencyPtBrNumberColumn(rowData, field, 2)
      : '';
  };

  const parseSuppliersColumn = (rowData: any) => {
    const suppliersIds = rowData.suppliers?.replaceAll('SUN', '').split(', ');

    const suppliersStringWithLinks = suppliersIds?.map((supplierId: number) => {
      return (
        rowData.suppliers && (
          <Link
            to={`/suppliers/list/${supplierId}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            {`SUN${supplierId}`}
          </Link>
        )
      );
    });

    return suppliersStringWithLinks;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'status':
        return parseSatStatusColumn;
      case 'satNumber':
        return parseSatColumn;
      case 'satForeignTradeNumber':
        return parseSatForeignTradeNameColumn;
      case 'exporter':
        return parseExporterColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'resultTotalConvertedCotPi':
      case 'resultTotalConvertedCotCi':
      case 'resultTotalConvertedSunPi':
      case 'resultTotalConvertedSunCi':
        return parseCurrencyColumns;
      case 'realEtd':
        return parseDateColumns;
      case 'realEta':
        return parseDateColumns;
      case 'suppliers':
        return parseSuppliersColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string): CSSProperties {
    switch (column) {
      case 'status':
        return { width: '6.79rem' };
      case 'confirmOrder':
        return { width: '10.71rem' };
      case 'countryOrigin':
      case 'purchaseIncoterm':
      case 'clientDepartment':
        return { width: '12.86rem' };
      case 'satNumber':
      case 'mainProduct':
      case 'suppliers':
        return {
          width: '10rem',
        };
      case 'realEtd':
      case 'realEta':
        return { width: '7.86rem' };
      case 'ctnrs':
      case 'category':
        return { width: '13.28rem' };
      case 'firstSalesUser':
      case 'secondSalesUser':
      case 'exporter':
      case 'importerConsignee':
      case 'clientNotify':
        return { width: '14.29rem' };
      case 'resultTotalConvertedCotPi':
      case 'resultTotalConvertedCotCi':
      case 'resultTotalConvertedSunPi':
      case 'resultTotalConvertedSunCi':
        return { width: '17.86rem' };
      default:
        return { width: '11.43rem' };
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  const totalColumnFooter = (field: string, currency: string) => {
    const floatFractionDigits = 2;
    const locale = 'pt';

    if (listSumLoading) return <Skeleton />;

    const value = listSumData?.getSumDataFromSatResumeList[field];

    if (!value) {
      return '';
    }

    return value.toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: floatFractionDigits,
      maximumFractionDigits: floatFractionDigits,
    });
  };

  function handleColumnFooter(field: string) {
    switch (field) {
      case 'satNumber':
        return listSumData?.getSumDataFromSatResumeList?.satCount ?? 0;
      case 'resultTotalConvertedCotPi':
      case 'resultTotalConvertedCotCi':
      case 'resultTotalConvertedSunPi':
      case 'resultTotalConvertedSunCi':
        return totalColumnFooter(field, 'USD');
      default:
        return undefined;
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns.columnList,
      );
      setSelectedColumns(allowedColumns);
    } else {
      setSelectedColumns(columns.columnList);
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns.columnList).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns.columnList}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Export list of SATs */}
        {userHasPermission(
          idPermissionExportListOfSATs,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={satsExportLoading}
            onClick={() =>
              loadSatsToExport({
                variables: {
                  data: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    allColumns: lazyParams.globalFilter,
                    ...lazyParamsVariables,
                  },
                },
              })
            }
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.columnList}
          onChange={onColumnToggle}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="p-datatable-hoverable-rows"
        name="sat"
        lazy
        totalRecords={
          !satsData || !satsData.listSatListResumeView
            ? 0
            : satsData.listSatListResumeView.items
        }
        value={
          !satsData || !satsData.listSatListResumeView
            ? undefined
            : satsData.listSatListResumeView.data
        }
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onRowClick={onRowClick}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        {dynamicColumns}
      </Grid>
      {satsLoading && <Loading />}
    </div>
  );
};
export default Sats;
