import { Toast } from 'primereact/toast';
import React from 'react';
import { useRefHook } from '../../../hooks/useRefHook';

import { Wrapper, Content } from './styles';

const AuthLayout: React.FC = ({ children }) => {
  // Referencia ao toast
  const { toastRef } = useRefHook();
  return (
    <Wrapper>
      <Content>{children}</Content>
      <Toast ref={toastRef} />
    </Wrapper>
  );
};

export default AuthLayout;
