/* eslint-disable no-shadow */
export enum UnitType {
  PC = 453,
  SET = 454,
  SQM = 455,
}

export enum EnumTypeofPackage {
  PACK = 758,
  CALCULADO = 759,
}

export enum ProductStatus {
  ACTIVED = 1017,
  INACTIVED = 1018,
}
