import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';
import { IClientQuotation } from '../../pages/ClientQuotation/ClientQuotation/interfaces';

// Mutation de criacao de Client Quotation
export const createClientQuotationQuery = gql`
  mutation CreateClientQuotation($data: CreateClientQuotationInput!) {
    createClientQuotation(data: $data) {
      idCq
      cqNumber
    }
  }
`;

// Mutation de atualização de Client Quotation
export const updateClientQuotationQuery = gql`
  mutation UpdateClientQuotation($data: UpdateClientQuotationInput!) {
    updateClientQuotation(data: $data) {
      idCq
      cqNumber
      paymentTermBalance
      paymentTermAdvance
      updatedAt
      amountCot
      amountSun
      grossMargin
    }
  }
`;

/**
 * Cria Client Quotation
 * @param mutation Mutation para criacao de client quotation
 * @param idsPrcs IDs de PRCs
 * @param idsSatItems IDs de SAT Items
 * @param idsProducts IDs de Produtos
 * @returns Dados de Client Quotation
 */
export async function createClientQuotation(
  mutation: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
  idsPrcs?: number[],
  idsSatItems?: number[],
  idsProducts?: number[],
  clientQuotation?: IClientQuotation,
): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
  return mutation({
    variables: {
      data: clientQuotation || {
        idsPrcs,
        idsSatItems,
        idsProducts,
      },
    },
  });
}

/**
 * Atializa Client Quotation
 * @param mutation Mutation para atualizacao de client quotation
 * @param clientQuotation Client Quotation
 * @returns Dados de Client Quotation
 */
export async function updateClientQuotation(
  mutation: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
  clientQuotation?: IClientQuotation,
): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
  return mutation({
    variables: {
      data: clientQuotation,
    },
  });
}
