import React, { Fragment } from 'react';
import { FiHome, FiChevronRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { Container } from './styles';

export interface IBreadCrumbItem {
  path: string;
  name: string;
}

interface BreadCrumbProps {
  items: IBreadCrumbItem[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ items }) => {
  return (
    <Container>
      <FiHome />
      {items.map((item, index, array) => {
        return (
          <Fragment key={item.name}>
            <NavLink to={item.path} exact activeClassName="active">
              {item.name}
            </NavLink>
            {array[index + 1] ? <FiChevronRight /> : null}
          </Fragment>
        );
      })}
    </Container>
  );
};

export default BreadCrumb;
