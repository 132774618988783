import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';

interface IPurchasePiProps {
  sat: ISat;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanSeePurchasePiBlock: boolean;
}

const PurchasePi: React.FC<IPurchasePiProps> = ({
  sat,
  satForeignTradeSupplier,
  fieldsPermissions,
  userCanSeePurchasePiBlock,
}) => {
  function convertCotTotalToUsd() {
    const cotTotal = satForeignTradeSupplier.totalCotPiShipment;

    if (!cotTotal || !satForeignTradeSupplier.exchangeRate) return undefined;

    const cotTotalConverted = cotTotal * satForeignTradeSupplier.exchangeRate;

    return cotTotalConverted;
  }

  return userCanSeePurchasePiBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Purchase PI" />

      {fieldsPermissions.purchasePiCurrency.view && (
        <ReadOnlyInput
          className="col-4"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalCotPiShipment.view && (
        <ReadOnlyInput
          className="col-4"
          label="Total COT PI"
          prefix={getCurrencySymbol(sat.idCurrency2?.abbreviation)}
          value={satForeignTradeSupplier.totalCotPiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
        />
      )}

      {fieldsPermissions.totalConvertedCotPi.view && (
        <ReadOnlyInput
          className="col-4"
          label="Total Converted COT PI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={convertCotTotalToUsd()}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default PurchasePi;
