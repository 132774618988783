import { gql } from '@apollo/client';

export const createInlandFreightsBulkQuery = gql`
  mutation createLogisticInlandFreightBulk($uploadedFile: String!) {
    createLogisticInlandFreightBulk(uploadedFile: $uploadedFile) {
      inlandFreightsTotal
      createdInlandFreights
      inlandFreightsWithError
    }
  }
`;

export const updateInlandFreightsBulkQuery = gql`
  mutation updateLogisticInlandFreightBulk($uploadedFile: String!) {
    updateLogisticInlandFreightBulk(uploadedFile: $uploadedFile) {
      inlandFreightsTotal
      updatedInlandFreights
      inlandFreightsWithError
    }
  }
`;
