import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  form {
    width: 100%;
    max-width: 816px;

    .form-group {
      display: flex;

      .formInput {
        max-width: 100%;
      }

      .formInput + .formInput {
        margin-left: 12px;
      }
    }

    .formInput {
      margin-top: 16px;
      width: 100%;

      display: block;
    }

    .activeCheckbox {
      margin-top: 38px;
    }

    .form-group + h1,
    .formInput + h1 {
      margin-top: 26px;
    }

    button {
      margin-top: 16px;
    }
  }
`;

export const AvatarInput = styled.div`
  margin: 16px 0;
  display: flex;
  position: relative;

  place-content: center;
  width: 100%;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    border: solid 1px var(--grey-scale);
    object-fit: cover;
  }
  label {
    position: absolute;
    right: 35px;
    bottom: 0px;
    width: 48px;
    height: 48px;
    background: #ff9000;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: background 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }
    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;
