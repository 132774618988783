import React, { createContext, useCallback, useState, useContext } from 'react';

// Interface do contexto de menu
interface IMenuContext {
  visible: boolean;
  changeVisibility(): void;
}

// Cria o contexto
const MenuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuProvider: React.FC = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);

  // Muda visibilidade do menu
  const changeVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <MenuContext.Provider value={{ visible, changeVisibility }}>
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): IMenuContext {
  // Busca contexto
  const context = useContext(MenuContext);

  // Valida o uso no arquivo app.tsx
  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }

  // retorna contexto
  return context;
}

export { MenuProvider, useMenu };
