/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container, CrudForm, GeneralInformation } from './styles';
import PageHeader from '../../../components/PageHeader';

import Button from '../../../components/Button';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import MainButton from '../../../components/MainButton';

import { useRefHook } from '../../../hooks/useRefHook';
import Loading from '../../../components/Loading';
import FormInput from '../../../components/FormInput';
import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

// Parametros da rota de courier
interface CourierRouteParams {
  courierId: string;
}

interface IGeneralInformationFormData {
  description: string;
}

const Courier: React.FC = () => {
  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // ID do courier
  const { courierId } = useParams<CourierRouteParams>();

  const defaultPageTitle = `${courierId ? 'Manage' : 'Create'} Courier`;

  // Nome do courier
  const [courierName, setCourierName] = useState('');
  const [versionLock, setVersionLock] = useState();

  useTitle(
    courierName ? `${courierName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // URL
  const { pathname } = useLocation();

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Couriers',
      path: '/list/couriers',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Query para criar courier
  const createCourierQuery = gql`
    mutation CreateDomainMutation($domainData: CreateDomain!) {
      createDomain(domain: $domainData) {
        description
        versionLock
      }
    }
  `;

  // Query para atualizar courier
  const updateCourierQuery = gql`
    mutation UpdateCourierMutation($domainData: UpdateDomain!) {
      updateDomain(domain: $domainData) {
        description
        versionLock
      }
    }
  `;

  // Query para listar dados do courier
  const listCourierQuery = gql`
    query ListDomainQuery($idDomain: Int!) {
      listDomainById(id: $idDomain) {
        description
        idGroup
        versionLock
      }
    }
  `;

  // Carrega dados do courier
  const [
    loadCourierData,
    {
      called: courierCalled,
      loading: courierLoading,
      data: courierData,
      error: courierError,
    },
  ] = useLazyQuery(listCourierQuery, {
    variables: {
      idDomain: parseInt(courierId, 10),
    },
    onCompleted: async response => {
      // Valida se courier nao foi encontrado
      if (!response.listDomainById || response.listDomainById.idGroup !== 3) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Courier not found',
          life: ToastLife.ERROR,
        });
        // Redireciona usuario para listagem de couriers
        history.push('/list/couriers');
        return;
      }

      // Define novo nome do courier
      setCourierName(response.listDomainById.description);

      setVersionLock(response.listDomainById.versionLock);
      setPageLoading(false);
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting courier data',
        detail: error.message,
        life: ToastLife.ERROR,
      });
      setPageLoading(false);
      history.push('/list/couriers');
    },
  });

  // Ao carregar a pagina, carrega dados de courier
  useEffect(() => {
    if (courierId) {
      setPageLoading(true);
      loadCourierData();
    }
  }, [loadCourierData, courierId]);

  // cria método para chamar a mutation
  const [createCourier] = useMutation(createCourierQuery);
  const [updateCourier] = useMutation(updateCourierQuery);

  // Submit do formulario
  const handleCreateCourierSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria Courier
        await createCourier({
          variables: {
            domainData: {
              description,
              idGroup: 3,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Courier created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de couriers
        history.push('/list/couriers');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating couriers',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createCourier, toastRef, history],
  );

  // Submit do formulario
  const handleEditCourierSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a description'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes de courier
        const updateCourierResponse = await updateCourier({
          variables: {
            domainData: {
              idDomain: parseInt(courierId, 10),
              description,
              versionLock,
            },
          },
        });

        // Define novo nome do courier
        setCourierName(description);
        setVersionLock(updateCourierResponse.data.updateDomain.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Courier updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating courier',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updateCourier, courierId, versionLock, toastRef],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/couriers'),
    });
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          courierData
            ? `${defaultPageTitle} - ${courierName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      {/* Formulario */}
      <CrudForm>
        <GeneralInformation>
          <Form
            ref={formRef}
            onSubmit={
              courierId ? handleEditCourierSubmit : handleCreateCourierSubmit
            }
            initialData={
              courierCalled && !courierLoading && !courierError
                ? courierData.listDomainById
                : undefined
            }
          >
            <FormInput
              className="generalInput"
              name="description"
              label="Name"
              required
            />
          </Form>
          {pageLoading && <Loading />}
        </GeneralInformation>
      </CrudForm>
    </Container>
  );
};
export default Courier;
