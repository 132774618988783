import React, { Ref, useImperativeHandle, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { useRefHook } from '../../../../hooks/useRefHook';
import FileUpload from '../../../../components/FileUpload';
import { FileType } from '../../../../shared/enums/fileType';
import { PrcFileType } from '../../../../shared/enums/domains';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';
import { createPrcFileLogsQuery } from './queries';

export interface IFilesUploadRef {
  toggleModal(): void;
}

interface IFilesUploadProps {
  ref: Ref<IFilesUploadRef>;
  idType: PrcFileType;
  idPrc: number;
}

const FilesUpload: React.FC<IFilesUploadProps> = React.forwardRef(
  ({ idPrc, idType }, ref) => {
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const [displayModal, setDisplayModal] = useState(false);

    const [uploadPrcFileMutation] = useMutation(createPrcFileLogsQuery);

    const { showError, showSuccess, formRef } = useRefHook();

    function handleCloseModal() {
      setDisplayModal(false);
    }

    async function handleUploadConfirm(e: FileUploadResponse[]) {
      try {
        setUploadInProgress(true);

        await uploadPrcFileMutation({
          variables: {
            data: {
              idPrc,
              idType,
              uploadedFiles: e.map(file => file.serverName),
            },
          },
        });

        formRef.current?.submitForm();

        showSuccess({
          summary: 'File uploaded',
        });

        handleCloseModal();
      } catch (error) {
        showError({
          summary: 'Error while uploading files',
          detail: error.message,
        });
      } finally {
        setUploadInProgress(false);
      }
    }

    useImperativeHandle(ref, () => {
      return {
        toggleModal: () => {
          if (displayModal) {
            handleCloseModal();
          } else {
            setDisplayModal(true);
          }
        },
      };
    });

    return (
      <div>
        <Dialog
          header="Upload PRC Files"
          visible={displayModal}
          style={{ width: '40vw' }}
          onHide={() => handleCloseModal()}
          closable
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <FileUpload
            multiple
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX}`}
            onConfirm={e => handleUploadConfirm(e)}
            uploadInProgress={uploadInProgress}
          />
        </Dialog>
      </div>
    );
  },
);

export default FilesUpload;
