import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  noLabel?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  required,
  disabled,
  className,
  onBlur,
  noLabel,
  readOnly,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div
      className={`${
        className
          ? `${className} form-element formInput`
          : 'form-element formInput'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
        type={type}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          {!noLabel && <p>{label}:</p>}
        </span>

        <input
          onFocus={e => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={e => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          defaultValue={defaultValue}
          ref={inputRef}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          type={type}
          {...rest}
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error block">
          {error}
        </small>
      )}
    </div>
  );
};

export default Input;
