import { IRoleEntityField } from '../interfaces/IRoleEntityField';
import getFieldPermission from './getFieldPermission';

/**
 * Exibe o campo
 * @param idField ID do field validado
 * @param roleEntityFields Permissoes de fields do usuario
 * @returns Se usuario tem permissao para visualizar o campo
 */
export function showField(
  idField: number,
  roleEntityFields: IRoleEntityField[],
): boolean {
  return getFieldPermission(idField, roleEntityFields).view;
}
