export const inlandFreightRoles = {
  idEntity: 41,
  idModule: 10,
  permissions: {
    idPermissionCreateInlandFreight: 82,
    idPermissionUpdateInlandFreight: 83,
    idPermissionDeleteInlandFreight: 84,
    idPermissionMassiveActionInlandFreight: 85,
    idPermissionExportListInlandFreight: 86,
  },
};
