import React, { useRef, ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

// Interface das propriedades do componente
interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  title: string;
  buttonDirection?: string;
}

const PageMenuButton: React.FC<CustomButtonProps> = ({
  title,
  buttonDirection,
  ...rest
}) => {
  const buttonRef = useRef(null);
  return (
    <Container
      buttonDirection={buttonDirection || 'column'}
      {...rest}
      ref={buttonRef}
    >
      <p>{title}</p>
    </Container>
  );
};

export default PageMenuButton;
