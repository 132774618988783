import { useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';

import FilesUpload, { IFilesUploadRef } from './FilesUpload';
import { useRefHook } from '../../../hooks/useRefHook';
import FilesByTypes, {
  IFilesByTypesRef,
} from '../../../components/FilesByTypes';
import {
  deleteFinancialTransactionAttachmentsQuery,
  downloadFinancialTransactionAttachmentsQuery,
  listDomainsByGroupIdQuery,
  listFinancialTransactionAttachmentsQuery,
} from '../FinancialTransaction/queries';
import { AttachmentTypes } from '../../../shared/enums/attachmentType';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import PageTabContainer from '../../../components/PageTabContainer';
import FormTitle from '../../../components/FormTitle';
import Button from '../../../components/Button';

interface IFilesProps {
  idFinancialTransaction: number;
  selected: boolean;
  showUploadButton?: boolean;
  showDeleteButton?: boolean;
  showFilesUpload?: boolean;
}

const FinancialTransactionAttachmentsList: React.FC<IFilesProps> = ({
  idFinancialTransaction,
  selected,
  showUploadButton,
  showDeleteButton,
  showFilesUpload,
}) => {
  const { showSuccess, showError } = useRefHook();
  const filesUploadRef = useRef<IFilesUploadRef>(null);
  const filesByTypesRef = useRef<IFilesByTypesRef>(null);

  const [
    deleteFinancialTransactionAttachmentsMutation,
    { loading: fileDeleteInProgress },
  ] = useMutation(deleteFinancialTransactionAttachmentsQuery);

  const [
    loadFinancialTransactionAttachments,
    {
      loading: financialTransactionAttachmentsLoading,
      data: financialTransactionAttachmentsData,
    },
  ] = useLazyQuery(listFinancialTransactionAttachmentsQuery, {
    variables: {
      data: {
        idFinancialTransaction,
        idTypes: AttachmentTypes.ATTACHMENT_FILE_FINANCIAL_TRANSACTION,
        _orderBy: 'attachment.createdAt',
        _sortOrder: 'DESC',
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Financial Transaction Files',
        detail: errorData.message,
      });
    },
  });

  const [
    loadFinancialTransactionAttachmentsTypesData,
    {
      loading: financialTransactionAttachmentsTypesLoading,
      data: financialTransactionAttachmentsTypesData,
    },
  ] = useLazyQuery(listDomainsByGroupIdQuery, {
    variables: {
      listDomainsByGroupIdId: DomainGroup.FINANCIAL_TRANSACTION_TYPE_FILES,
      pagination: {
        _limit: 0,
        _page: 1,
      },
      isActive: true,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Transaction File Types',
        detail: errorData.message,
      });
    },
  });

  const [
    downloadFinancialTransactionAttachments,
    { loading: financialTransactinoDownloadInProgress },
  ] = useLazyQuery(downloadFinancialTransactionAttachmentsQuery, {
    onCompleted: async response => {
      if (response.downloadFinancialTransactionAttachments) {
        window.open(response.downloadFinancialTransactionAttachments, '_blank');
      } else {
        showError({
          summary: 'Error while downloading attachments',
        });
      }
    },
    onError: error => {
      showError({
        summary: 'Error while downloading attachments',
        detail: error.message,
      });
    },
  });

  function downloadAttachments() {
    const filesToDownload = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDownload?.length) {
      showError({
        summary: 'You need to select at least one item do download',
      });
      return;
    }

    downloadFinancialTransactionAttachments({
      variables: {
        idsFinancialTransactionAttachments: filesToDownload,
      },
    });
  }

  async function handleDeleteAttachments() {
    const attachmentsToDelete = filesByTypesRef.current?.getSelectedItems();

    if (!attachmentsToDelete?.length) {
      showError({
        summary: 'You need to select at least one item do delete',
      });
      return;
    }

    try {
      await deleteFinancialTransactionAttachmentsMutation({
        variables: {
          idsFinancialTransactionAttachments: attachmentsToDelete,
        },
      });

      showSuccess({
        summary: 'Files created',
      });

      loadFinancialTransactionAttachments();
    } catch (error) {
      showError({
        summary: 'Error while deleting attachments',
        detail: error.message,
      });
    }
  }

  useEffect(() => {
    if (selected && !financialTransactionAttachmentsData) {
      loadFinancialTransactionAttachments();
    }
    if (selected && !financialTransactionAttachmentsTypesData) {
      loadFinancialTransactionAttachmentsTypesData();
    }
  }, [
    selected,
    financialTransactionAttachmentsData,
    loadFinancialTransactionAttachments,
    financialTransactionAttachmentsTypesData,
    loadFinancialTransactionAttachmentsTypesData,
  ]);

  return (
    <div className="form-group grid">
      <PageTabContainer selected={selected}>
        <FormTitle className="col-12" name="Bill Files">
          {showUploadButton && (
            <Button
              type="button"
              label="Upload"
              icon="pi pi-upload"
              onClick={() => filesUploadRef.current?.toggleModal()}
            />
          )}

          <Button
            type="button"
            label="Download"
            onClick={() => downloadAttachments()}
            disabled={financialTransactinoDownloadInProgress}
            icon={
              financialTransactinoDownloadInProgress
                ? 'pi pi-spin pi-spinner'
                : 'pi pi-download'
            }
          />

          {showDeleteButton && (
            <Button
              type="button"
              label="Delete"
              onClick={() =>
                confirmDialog({
                  message:
                    'Are you sure you want to delete the selected files?',
                  header: 'Delete Confirmation',
                  icon: 'pi pi-info-circle',
                  acceptClassName: 'p-button-danger',
                  accept: () => handleDeleteAttachments(),
                })
              }
              disabled={fileDeleteInProgress}
              icon={
                fileDeleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'
              }
              severity="danger"
            />
          )}
        </FormTitle>

        {showFilesUpload && (
          <FilesUpload
            ref={filesUploadRef}
            idFinancialTransaction={idFinancialTransaction}
            financialTransactionAttachmentsRefetch={
              loadFinancialTransactionAttachments
            }
          />
        )}

        <FilesByTypes
          ref={filesByTypesRef}
          className="col-12"
          fileTypes={
            financialTransactionAttachmentsTypesData?.listDomainsByGroupId.data
          }
          files={
            financialTransactionAttachmentsData
              ?.listFinancialTransactionAttachments.data
          }
          typesLoading={financialTransactionAttachmentsTypesLoading}
          fileIdKey="idFinancialTransactionAttachment"
          filesLoading={financialTransactionAttachmentsLoading}
          fileDescriptionKey="idAttachment2.nameGivenByUser"
          fileTypeKey="idAttachment2.idType"
          fileCreatedAtKey="idAttachment2.createdAt"
        />
      </PageTabContainer>
    </div>
  );
};

export default FinancialTransactionAttachmentsList;
