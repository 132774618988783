import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1154px;

  .group + .group {
    margin-top: 12px;
  }

  .container-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(23rem, 1fr));
    gap: 12px;

    .add-container-button {
      max-width: 93px;
    }
  }

  .container-content :first-child {
    grid-column: 1 / -1;
  }

  .container-content :last-child {
    grid-column: 1;
  }

  .container-card-content {
    width: 100%;
  }

  .form-title {
    margin-bottom: 12px;
  }

  .link-span {
    width: 200px;
    align-items: center;
    place-content: center;

    a {
      text-decoration: none;
      color: var(--blue);
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#4C85C8')};
      }
    }

    svg {
      margin-left: 5px;
    }
  }
`;

export const Group = styled.div`
  div + & {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;
