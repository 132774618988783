import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Dialog } from 'primereact/dialog';
import { Form } from '@unform/web';
import { ValidationError, object, string } from 'yup';
import FormInput from '../../../../components/FormInput';
import { IPartnerBranding } from '../interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  createPartnerBrandingQuery,
  updatePartnerBrandingQuery,
} from './queries';
import Button from '../../../../components/Button';
import { PartnerBrandingFormData } from './interfaces';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import FileUpload from '../../../../components/FileUpload';
import { FileType } from '../../../../shared/enums/fileType';
import FormInputNumber from '../../../../components/FormInputNumber';

export interface IAddBrandRef {
  toggleModal(data?: IPartnerBranding): void;
}

interface IAddBrandProps {
  ref: Ref<IAddBrandRef>;
  idPartner: number;
  brandsRefetch(): Promise<ApolloQueryResult<any>>;
}

const AddBrand: React.FC<IAddBrandProps> = forwardRef(
  ({ idPartner, brandsRefetch }, ref) => {
    const formRef = useRef<FormHandles>(null);
    const { showSuccess, showError } = useRefHook();

    const [createPartnerBrandingMutation, { loading: createLoading }] =
      useMutation(createPartnerBrandingQuery);
    const [updatePartnerBrandingMutation, { loading: updateLoading }] =
      useMutation(updatePartnerBrandingQuery);

    const [displayModal, setDisplayModal] = useState(false);
    const [initialFormData, setInitialFormData] = useState<IPartnerBranding>();
    const [uploadedFile, setUploadedFile] = useState<string | undefined>();

    const loading = createLoading || updateLoading;
    const uploadFileButtonDisabled =
      !!uploadedFile || loading || !!initialFormData?.imageUrl;

    function closeModal() {
      setDisplayModal(false);
      setUploadedFile(undefined);
      setInitialFormData(undefined);
    }

    useImperativeHandle(ref, () => ({
      toggleModal: (data?: IPartnerBranding) => {
        if (displayModal) {
          closeModal();
        } else {
          setDisplayModal(true);
          if (data) {
            setInitialFormData(data);
          }
        }
      },
    }));

    const dialogFooter = () => {
      return (
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Confirm"
            icon="pi pi-check"
            onClick={() => formRef.current?.submitForm()}
            loading={loading}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => closeModal()}
            className="p-button-danger"
            severity="danger"
            disabled={loading}
          />
        </div>
      );
    };

    async function onSubmit(formData: PartnerBrandingFormData) {
      try {
        formRef.current?.setErrors({});

        const schema = object().shape({
          name: string().nullable().required(requiredFieldErrorMessage),
        });

        await schema.validate(formData, { abortEarly: false });

        if (initialFormData) {
          await updatePartnerBrandingMutation({
            variables: {
              data: {
                ...formData,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand updated' });
        } else {
          await createPartnerBrandingMutation({
            variables: {
              data: {
                idPartner,
                name: formData.name,
                expirationDate: formData.expirationDate,
                uploadedFile,
              },
            },
          });

          showSuccess({ summary: 'Brand created' });
        }

        brandsRefetch();

        closeModal();
      } catch (error) {
        if (error instanceof ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];
          if (firstError.path) {
            const inputWithError = formRef.current?.getFieldRef(
              firstError.path,
            );
            inputWithError.focus();
          }
        } else {
          showError({
            summary: 'Error while creating brand',
            detail: error.message,
          });
        }
      }
    }

    return (
      <Dialog
        header="Manage Brand"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => setDisplayModal(false)}
        closable={!loading}
        footer={dialogFooter()}
      >
        <Form ref={formRef} onSubmit={onSubmit} initialData={initialFormData}>
          <FormInputNumber name="idPartnerBranding" label="" hidden />
          <FormInputNumber name="versionLock" label="" hidden />
          <FormInput className="field" name="name" label="Name" required />
          <FormInput
            className="field"
            name="expirationDate"
            label="Expiration Date"
            type="date"
          />
          <FileUpload
            className="field"
            mode="basic"
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}}`}
            auto
            customUpload
            chooseLabel={
              !!uploadedFile || !!initialFormData?.imageUrl
                ? 'File uploaded'
                : 'Upload a file'
            }
            disabled={uploadFileButtonDisabled}
            onConfirm={e => setUploadedFile(e[0].serverName)}
          />
        </Form>
      </Dialog>
    );
  },
);

export default AddBrand;
