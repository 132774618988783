/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BiCommentX } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';
import { IoMdAttach } from 'react-icons/io';
import { isEqual } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { useLocation } from 'react-router-dom';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import FormTitle from '../../../../../components/FormTitle';

import AdvancedFiltersPanel from '../../../../../components/AdvancedFiltersPanel';
import {
  AdvancedFiltersFieldType,
  IAdvancedFiltersField,
} from '../../../../../components/AdvancedFiltersPanel/interfaces';
import Button from '../../../../../components/Button';
import CommentAdding, {
  ICommentAddingRef,
} from '../../../../../components/CommentAdding';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../../components/CommentPagination';
import CommentSkeleton from '../../../../../components/CommentSkeleton';
import Empty from '../../../../../components/Empty';
import Loading from '../../../../../components/Loading';
import Image from '../../../../../components/PageHeader/Image';
import pagination from '../../../../../config/pagination';
import { useAuth } from '../../../../../hooks/useAuth';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IRoleEntityPermission } from '../../../../../interfaces/IRoleEntityPermission';
import { ISatAttachment } from '../../../../../interfaces/ISatAttachment';
import { imageExtensions } from '../../../../../shared/constants/imageExtensions';
import { FileType } from '../../../../../shared/enums/fileType';
import { IAttachment } from '../../../../../shared/interfaces/attachment';
import { IComment } from '../../../../../shared/interfaces/comment';
import { satsRoles } from '../../../../../shared/roles/sat';

import userHasPermission from '../../../../../utils/userHasPermission';
import { ISatCommentsLazyParams, SatComment } from '../interfaces';
import {
  createSatCommentQuery,
  deleteSatCommentQuery,
  listSatAttachmentsToUpdatesBySatIdQuery,
  listSatCommentsBySatIdQuery,
  updateSatCommentQuery,
} from '../queries';
import { Attachment, Attachments, Comments, Container } from './styles';
import {
  createSatAttachmentsQuery,
  deleteSatAttachmentsQuery,
} from '../../queries';
import { AttachmentTypes } from '../../../../../shared/enums/attachmentType';
import { parseCommentMessage } from '../../../../../utils/parseCommentMessage';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';
import FileUpload from '../../../../../components/FileUpload';
import CommentAndReplies, {
  commentSearchParams,
} from '../../../../../components/Comments/CommentAndReplies';

interface IFollowUpProps {
  selected: boolean;
  idSat: number;
  roleEntityPermissions: IRoleEntityPermission[];
  idSatForeignTrade?: number;
  refetchUpdatesTabs(): void;
}

const FollowUp: React.FC<IFollowUpProps> = ({
  idSat,
  selected,
  roleEntityPermissions,
  idSatForeignTrade,
  refetchUpdatesTabs,
}) => {
  const isMounted = useRef(false);
  const { idPermissionManageFollowUp } = satsRoles.permissions;

  const { search } = useLocation();

  const [createSatCommentMutation] = useMutation(createSatCommentQuery);
  const [updateSatCommentMutation] = useMutation(updateSatCommentQuery);
  const [deleteSatCommentMutation] = useMutation(deleteSatCommentQuery);

  const [createSatAttachmentMutation] = useMutation(createSatAttachmentsQuery);
  const [deleteSatAttachmentsMutation] = useMutation(deleteSatAttachmentsQuery);

  const { showError, showSuccess } = useRefHook();

  const commentAddingRef = useRef<ICommentAddingRef>(null);

  // ID do usuario logado
  const { idUser } = useAuth();

  // Estado de follow up selecionado
  const [selectedFollowUp, setSelectedFollowUp] = useState<SatComment>(
    {} as SatComment,
  );

  // Estado do checkbox All SAT
  const [allSatChecked, setAllSatChecked] = useState<boolean>(false);
  const [highlightedComment, setHighlightedComment] = useState<number>();

  const initialLazyParams = useMemo(() => {
    return {
      ...pagination.initialLazyParams,
      fetchAutomaticComments: true,
    };
  }, []);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] =
    useState<ISatCommentsLazyParams>(initialLazyParams);

  const [pageLoading, setPageLoading] = useState(false);

  const handleEditFollowUp = useCallback(
    (followUp: SatComment) => {
      setSelectedFollowUp(followUp);
      commentAddingRef.current?.editFollowUp(
        followUp.idComment2,
        !!followUp.isStatusCfr,
      );
      if (idSatForeignTrade && !followUp.idSatForeignTrade) {
        setAllSatChecked(true);
      }
    },
    [idSatForeignTrade],
  );

  const [followUps, setFollowUps] =
    useState<{ data: SatComment[]; items: number }>();

  const [satAttachments, setSatAttachments] =
    useState<(ISatAttachment | undefined)[]>();

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  // Busca global
  const [globalFilter, setGlobalFilter] = useState('');

  const advancedFiltersFields: IAdvancedFiltersField[] = useMemo(() => {
    return [
      { advancedFilterField: 'createdBy', header: 'Created By', value: '' },
      {
        advancedFilterField: 'createdCommentStartDateRange',
        header: 'Created follow up start date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'createdCommentEndDateRange',
        header: 'Created follow up end date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'isGeneratedSystem',
        header: 'System generated follow up',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      },
      {
        advancedFilterField: 'isStatusCfr',
        header: 'CFR',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      },
    ];
  }, []);

  const [loadFollowUpsData, { loading: followUpsDataLoading }] = useLazyQuery(
    listSatCommentsBySatIdQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idSat,
          isFollowUp: true,
          globalSearch: lazyParams.globalFilter,
          createdBy: lazyParams.createdBy,
          createdCommentStartDateRange: lazyParams.createdCommentStartDateRange,
          createdCommentEndDateRange: lazyParams.createdCommentEndDateRange,
          isGeneratedSystem: lazyParams.isGeneratedSystem,
          isStatusCfr: lazyParams.isStatusCfr,
          fetchAutomaticComments: lazyParams.fetchAutomaticComments,
          idSatForeignTrade,
          highlightedComment: highlightedComment || undefined,
        },
      },
      onCompleted: response => {
        if (response.listSatCommentsBySatId) {
          if (lazyParams.page && followUps) {
            setFollowUps({
              items: response.listSatCommentsBySatId.items,
              data: [
                ...followUps.data,
                ...response.listSatCommentsBySatId.data,
              ],
            });
          } else {
            setFollowUps(response.listSatCommentsBySatId);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT Follow Ups',
          detail: errorData.message,
        });
      },
    },
  );

  function changeSearchLazyParams(newValue: ISatCommentsLazyParams) {
    // O comentario em foco deve perder destaque quando o usuario altera
    // parametros de pesquisa
    setHighlightedComment(undefined);
    setLazyParams(newValue);
  }

  const handleRefreshFollowUps = useCallback(() => {
    if (!isEqual(lazyParams, initialLazyParams)) {
      changeSearchLazyParams(initialLazyParams);
    } else {
      loadFollowUpsData();
    }
  }, [initialLazyParams, lazyParams, loadFollowUpsData]);

  const handleSubmitFollowUp = useCallback(
    async (e: IComment) => {
      setPageLoading(true);
      try {
        if (!selectedFollowUp.idSat) {
          await createSatCommentMutation({
            variables: {
              data: {
                idSat,
                message: e.message,
                isStatusCfr: e.isStatusCfr,
                isFollowUp: true,
                idAttachment: e.idAttachment,
                idSatForeignTrade: allSatChecked
                  ? undefined
                  : idSatForeignTrade,
              },
            },
          });

          if (idSatForeignTrade && allSatChecked) {
            refetchUpdatesTabs();
          } else {
            handleRefreshFollowUps();
          }
        } else {
          // Editar comentario
          await updateSatCommentMutation({
            variables: {
              data: {
                idSatComment: selectedFollowUp.idSatComment,
                message: e.message,
                isStatusCfr: e.isStatusCfr,
                idAttachment: e.idAttachment,
                idSatForeignTrade: allSatChecked ? null : idSatForeignTrade,
              },
            },
          });

          const allSatCheckboxChanged =
            !!selectedFollowUp.idSatForeignTrade !== !allSatChecked;

          if (idSatForeignTrade && allSatCheckboxChanged) {
            refetchUpdatesTabs();
          } else {
            handleRefreshFollowUps();
          }
        }

        showSuccess({
          summary: 'Follow up published',
        });
      } catch (err) {
        showError({
          summary: `Error while ${
            selectedFollowUp.idSat ? 'updating' : 'creating'
          } follow up`,
          detail: err.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      createSatCommentMutation,
      handleRefreshFollowUps,
      idSat,
      selectedFollowUp.idSat,
      selectedFollowUp.idSatComment,
      selectedFollowUp.idSatForeignTrade,
      allSatChecked,
      idSatForeignTrade,
      showError,
      showSuccess,
      updateSatCommentMutation,
      refetchUpdatesTabs,
    ],
  );

  const handleDeleteFollowUp = useCallback(
    async (idSatComment: number, followUpIdSatForeignTrade?: number) => {
      setPageLoading(true);
      try {
        await deleteSatCommentMutation({
          variables: {
            idSatComment,
          },
        });

        showSuccess({
          summary: 'Follow up deleted',
        });

        if (idSatForeignTrade && !followUpIdSatForeignTrade) {
          refetchUpdatesTabs();
        } else {
          handleRefreshFollowUps();
        }
      } catch (error) {
        showError({
          summary: 'Error while deleting follow up',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      idSatForeignTrade,
      deleteSatCommentMutation,
      handleRefreshFollowUps,
      refetchUpdatesTabs,
      showError,
      showSuccess,
    ],
  );

  const currentUserCreatedComment = useCallback(
    (createdBy: number) => {
      return createdBy === idUser;
    },
    [idUser],
  );

  const userCanManageComment = useMemo(() => {
    return userHasPermission(idPermissionManageFollowUp, roleEntityPermissions);
  }, [idPermissionManageFollowUp, roleEntityPermissions]);

  const [loadAttachments] = useLazyQuery(
    listSatAttachmentsToUpdatesBySatIdQuery,
    {
      variables: {
        data: {
          idSat,
          isFollowUp: true,
          idSatForeignTrade,
          idTypes: idSatForeignTrade
            ? [
                AttachmentTypes.FOLLOW_UP_FILE,
                AttachmentTypes.FOLLOW_UP_SAT_FOREIGN_TRADE,
              ]
            : [AttachmentTypes.FOLLOW_UP_FILE],
        },
      },
      onCompleted: response => {
        if (response.listSatAttachmentsToUpdatesBySatId?.data) {
          setSatAttachments(response.listSatAttachmentsToUpdatesBySatId.data);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SAT updates',
          detail: errorData.message,
        });
      },
    },
  );

  useEffect(() => {
    if (selected && !followUps) {
      loadFollowUpsData();
      loadAttachments();
    }
  }, [followUps, loadAttachments, loadFollowUpsData, selected]);

  useEffect(() => {
    if (isMounted.current) {
      const delayDebounceFn = setTimeout(() => {
        setLazyParams(currentLazyParams => {
          return {
            ...currentLazyParams,
            globalFilter,
            first: pagination.initialLazyParams.first,
            page: pagination.initialLazyParams.page,
            rows: pagination.initialLazyParams.rows,
          };
        });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }

    isMounted.current = true;
    return undefined;
  }, [globalFilter]);

  const onPage = useCallback(
    (e: ICommentPaginationPageParams) => {
      setLazyParams({ ...lazyParams, ...e });
    },
    [lazyParams],
  );

  const handleDeleteAttachment = useCallback(
    async (satAttachment: ISatAttachment | undefined) => {
      setPageLoading(true);
      try {
        await deleteSatAttachmentsMutation({
          variables: {
            satAttachmentIds: [satAttachment?.idSatAttachment],
          },
        });

        showSuccess({
          summary: 'Update deleted',
        });
        loadAttachments();
        loadFollowUpsData();
      } catch (error) {
        showError({
          summary: 'Error while deleting sat attachment',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      deleteSatAttachmentsMutation,
      loadAttachments,
      loadFollowUpsData,
      showError,
      showSuccess,
    ],
  );

  const handleAddAttachmentComment = (attachment: IAttachment | undefined) => {
    if (attachment) {
      commentAddingRef.current?.addCommentFromAttachment(attachment);
    }
  };

  async function uploadAttachment(e: FileUploadResponse[]) {
    setPageLoading(true);
    try {
      const response = await createSatAttachmentMutation({
        variables: {
          data: {
            idSat,
            idSatForeignTrade,
            uploadedFiles: e.map(file => file.serverName),
            isFollowUp: true,
            idType: idSatForeignTrade
              ? AttachmentTypes.FOLLOW_UP_SAT_FOREIGN_TRADE
              : AttachmentTypes.FOLLOW_UP_FILE,
          },
        },
      });

      showSuccess({
        summary: 'Update published',
      });
      loadAttachments();
      return response.data?.createSatAttachments[0]?.idAttachment2;
    } catch (err) {
      showError({
        summary: `Error while creating attachment`,
        detail: err.message,
      });
      return undefined;
    } finally {
      setPageLoading(false);
    }
  }

  const cleanSelected = useCallback(() => {
    setSelectedFollowUp({} as SatComment);
  }, []);

  const chooseOptions = {
    icon: 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  const toggleAutomaticComments = () => {
    changeSearchLazyParams({
      ...initialLazyParams,
      fetchAutomaticComments: !lazyParams.fetchAutomaticComments,
    });
  };

  const allSatCheckbox = () => {
    return (
      idSatForeignTrade && (
        <>
          <Checkbox
            className="mr-2"
            inputId="allSat"
            onChange={e => setAllSatChecked(!!e.checked)}
            checked={allSatChecked}
          />
          <label className="p-checkbox-label ml-auto mr-3" htmlFor="allSat">
            All SAT
          </label>
        </>
      )
    );
  };

  useEffect(() => {
    function getHiglightedComment() {
      const value = parseInt(
        new URLSearchParams(search).get(commentSearchParams.idComment) ?? '',
        10,
      );

      setHighlightedComment(value || undefined);
    }

    getHiglightedComment();
  }, [search]);

  return (
    <Container>
      <AdvancedFiltersPanel
        innerRef={advancedFiltersPanelRef}
        fields={advancedFiltersFields}
        onApply={e =>
          changeSearchLazyParams({
            ...lazyParams,
            ...e,
            first: pagination.initialLazyParams.first,
            page: pagination.initialLazyParams.page,
            rows: pagination.initialLazyParams.rows,
          })
        }
        onClear={() =>
          changeSearchLazyParams({
            ...pagination.initialLazyParams,
            globalFilter,
          })
        }
      />
      <div className="content-header">
        <FormTitle name="Follow Up (PT)" />

        <span className="content-header-options">
          {userCanManageComment && (
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => uploadAttachment(e)}
            />
          )}

          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              placeholder="Search a follow up"
            />
          </IconField>
          <Button
            label="Filters"
            onClick={e => advancedFiltersPanelRef.current?.toggle(e, e.target)}
          />
        </span>
      </div>
      {satAttachments && satAttachments.length > 0 && (
        <Attachments>
          <div className="col-12 subtitle">
            <IoMdAttach className="attachment-icon" size={25} />
            <span>Attachment</span>
          </div>
          {
            // eslint-disable-next-line array-callback-return, consistent-return
            satAttachments?.map(satAttachment => {
              const attachment = satAttachment?.idAttachment2;
              const fileExtension = attachment?.name?.split('.').pop();
              return (
                <Attachment key={satAttachment?.idSatAttachment}>
                  <div className="col-3">
                    {fileExtension &&
                    imageExtensions.includes(fileExtension) ? (
                      <Image
                        src={attachment?.url ?? ''}
                        alt={`Comment image ${attachment?.name}`}
                      />
                    ) : (
                      <div className="file-extension">{fileExtension}</div>
                    )}
                  </div>
                  <div className="col-9 image-info">
                    <div className="col-12 flex align-items-center">
                      <span className="image-name">{attachment?.name}</span>
                      <a
                        href={attachment?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="attachment"
                      >
                        <FiExternalLink className="mx-2" />
                      </a>
                    </div>
                    <div className="col-12">
                      {`Added: ${
                        attachment?.createdAt
                          ? new Date(attachment.createdAt).toLocaleString()
                          : ''
                      } - `}
                      <button
                        onClick={() =>
                          confirmDialog({
                            message: 'Are you sure you want to delete?',
                            header: 'Delete Confirmation',
                            icon: 'pi pi-info-circle',
                            acceptClassName: 'p-button-danger',
                            accept: () => handleDeleteAttachment(satAttachment),
                          })
                        }
                        type="button"
                      >
                        Delete
                      </button>
                      {' - '}
                      <button
                        onClick={() =>
                          handleAddAttachmentComment(
                            satAttachment?.idAttachment2,
                          )
                        }
                        type="button"
                      >
                        Add Comment
                      </button>
                    </div>
                  </div>
                </Attachment>
              );
            })
          }
        </Attachments>
      )}
      <Comments>
        <div className="my-4">
          <div className="flex justify-content-between my-2">
            <div className="subtitle">
              <i className="pi pi-comments" />
              <span>Comments</span>
            </div>
            <button
              className="button-hide"
              onClick={() => toggleAutomaticComments()}
              type="button"
            >
              {lazyParams.fetchAutomaticComments ? 'Hide' : 'Show'} automatic
              comments
            </button>
          </div>
          <CommentAdding
            ref={commentAddingRef}
            onSaveComment={handleSubmitFollowUp}
            cleanSelected={cleanSelected}
            onUploadAttachment={uploadAttachment}
            showStatusCfr
            additionalOptions={allSatCheckbox()}
          />
        </div>
        {((followUpsDataLoading && !!lazyParams.page) ||
          !followUpsDataLoading) &&
          followUps &&
          followUps.data.map(followUp => {
            return (
              <CommentAndReplies
                idComment={followUp.idComment}
                highlighted={highlightedComment === followUp.idComment}
                comment={followUp.idComment2}
                userName={`${followUp.idComment2.createdBy2?.firstName} ${followUp.idComment2.createdBy2?.lastName}`}
                creation={followUp.idComment2.createdAt ?? new Date()}
                editedData={
                  followUp.idComment2.updatedBy2
                    ? {
                        editedAt: followUp.idComment2.updatedAt,
                        editedBy: `${followUp.idComment2.updatedBy2?.firstName} ${followUp.idComment2.updatedBy2?.lastName}`,
                      }
                    : undefined
                }
                commonTag={{
                  className: 'mr-2',
                  rounded: true,
                  isGeneratedSystem: followUp.idComment2.isGeneratedSystem,
                  isRestrictInfo: followUp.idComment2.isRestrictInfo,
                  value: followUp.isStatusCfr ? 'CFR' : undefined,
                  severity: followUp.isStatusCfr ? 'success' : undefined,
                }}
                onEdit={
                  userCanManageComment &&
                  currentUserCreatedComment(followUp.idComment2.createdBy)
                    ? () => handleEditFollowUp(followUp)
                    : undefined
                }
                onDelete={
                  userCanManageComment &&
                  currentUserCreatedComment(followUp.idComment2.createdBy)
                    ? () =>
                        confirmDialog({
                          message:
                            idSatForeignTrade && !followUp.idSatForeignTrade
                              ? 'This follow up belongs to the entire SAT, are you sure you want to delete it?'
                              : 'Are you sure you want to delete this follow up?',
                          header: 'Delete Confirmation',
                          icon: 'pi pi-info-circle',
                          acceptClassName: 'p-button-danger',
                          accept: () =>
                            handleDeleteFollowUp(
                              followUp.idSatComment,
                              followUp.idSatForeignTrade,
                            ),
                        })
                    : undefined
                }
                userImageUrl={followUp.idComment2.createdBy2?.avatarUrl}
                key={followUp.idSatComment}
                attachmentUrl={followUp.idComment2.idAttachment2?.url}
                idCreatedBy={followUp.idComment2.createdBy}
                onUploadAttachment={uploadAttachment}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: parseCommentMessage(followUp.idComment2),
                  }}
                  className="comment"
                />
              </CommentAndReplies>
            );
          })}
      </Comments>
      <CommentPagination
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={followUps?.items ?? 0}
        onPage={onPage}
        loading={followUpsDataLoading}
        page={lazyParams.page}
      />

      {followUpsDataLoading && !lazyParams.page && (
        <>
          <CommentSkeleton />
          <CommentSkeleton />
        </>
      )}

      {!followUpsDataLoading && !followUps?.items && (
        <Empty message="No follow ups found">
          <BiCommentX size={25} />
        </Empty>
      )}

      {pageLoading && <Loading />}
    </Container>
  );
};

export default FollowUp;
