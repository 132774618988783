/**
 * Opcoes da barra de ferramentas do editor de texto
 */
export const toolbarOptions = [
  [{ header: [] }],
  [{ font: [] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean'], // remove formatting button
];
