import { AdvancedFiltersFieldType } from '../../../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../../../components/Grid/interfaces';
import MultiSelectGroup from '../../../../../components/Grid/MultiSelectGroup';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  imageUrl: {
    header: 'Image',
    field: 'idImage2.url',
    advancedFilterField: '',
  },
  idSupplier: {
    header: 'SUN Number',
    field: 'idSatSupplier2.idSupplier',
    advancedFilterField: 'idSupplier',
  },
  stCode: {
    header: 'ST Code',
    field: 'stCode',
    advancedFilterField: 'stCode',
  },
  refCode: {
    header: 'Ref Code',
    field: 'refCode',
    advancedFilterField: 'refCode',
  },
  name: {
    header: 'Name (EN)',
    field: 'name',
    advancedFilterField: 'name',
  },
  description: {
    header: 'Description (EN)',
    field: 'description',
    advancedFilterField: 'description',
  },
  extraInfoEn: {
    header: 'Extra Info (EN)',
    field: 'extraInfoEn',
    advancedFilterField: 'extraInfoEn',
  },
  extraInfoPt: {
    header: 'Extra Info (PT)',
    field: 'extraInfoPt',
    advancedFilterField: 'extraInfoPt',
  },
  namePt: {
    header: 'Name (PT)',
    field: 'namePt',
    advancedFilterField: 'namePt',
  },
  detailedDescriptionPt: {
    header: 'Detailed description (PT)',
    field: 'detailedDescriptionPt',
    advancedFilterField: 'detailedDescriptionPt',
  },
  descriptionPt: {
    header: 'Description (PT)',
    field: 'descriptionPt',
    advancedFilterField: 'descriptionPt',
  },
  model: {
    header: 'Model',
    field: 'model',
    advancedFilterField: 'model',
  },
  sku: {
    header: 'SKU',
    field: 'sku',
    advancedFilterField: 'sku',
  },
  colour: {
    header: 'Color (EN)',
    field: 'colour',
    advancedFilterField: 'colour',
  },
  colourPt: {
    header: 'Color (PT)',
    field: 'colourPt',
    advancedFilterField: 'colourPt',
  },
  colourImageUrl: {
    header: 'Colour Image',
    field: 'idColourImage2.url',
    advancedFilterField: '',
  },
  materialComposition: {
    header: 'Material/Composition (EN)',
    field: 'materialComposition',
    advancedFilterField: 'materialComposition',
  },
  materialCompositionPt: {
    header: 'Material/Composition (PT)',
    field: 'materialPt',
    advancedFilterField: 'materialPt',
  },
  dimensions: {
    header: 'Dimensions',
    field: 'dimensions',
    advancedFilterField: 'dimensions',
  },
  netWeight: {
    header: 'Net Weight (KG)',
    field: 'netWeight',
    advancedFilterField: 'netWeight',
  },
  packageDescription: {
    header: 'Selling Package',
    field: 'packageDescription',
    advancedFilterField: 'packageDescription',
  },
  packageNotes: {
    header: 'Package Notes',
    field: 'packageNotes',
    advancedFilterField: 'packageNotes',
  },
  sellingPackagePcQuantity: {
    header: 'Quantity/Selling Package',
    field: 'sellingPackagePcQuantity',
    advancedFilterField: 'sellingPackagePcQuantity',
    type: AdvancedFiltersFieldType.NUMBER,
    tooltip: 'QTY per Selling Package',
  },
  sellingPackageDimensions: {
    header: 'Selling Package Dimension',
    field: 'sellingPackageDimensions',
    advancedFilterField: 'sellingPackageDimensions',
  },
  sellingPackageGrossWeight: {
    header: 'Selling Package Gross Weight',
    field: 'sellingPackageGrossWeight',
    advancedFilterField: 'sellingPackageGrossWeight',
    tooltip: 'Selling Package Gross Weight',
  },
  quantityInner: {
    header: 'UN OR SET/INNER',
    field: 'quantityInner',
    advancedFilterField: '',
  },
  unitPerContainer: {
    header: 'Un/SET Per CTN',
    field: 'unitPerContainer',
    advancedFilterField: 'unitPerContainer',
  },
  packageEan13: {
    header: 'Package EAN 13',
    field: 'packageEan13',
    advancedFilterField: 'packageEan13',
  },
  innerdun14: {
    header: 'Inner DUN 14',
    field: 'innerdun14',
    advancedFilterField: 'innerdun14',
  },
  dun14: {
    header: 'DUN 14',
    field: 'dun14',
    advancedFilterField: 'dun14',
  },
  brand: {
    header: 'Brand',
    field: 'brand',
    advancedFilterField: 'brand',
  },
  productLine: {
    header: 'Product Line',
    field: 'productLine',
    advancedFilterField: 'productLine',
  },
  artworkEn: {
    header: 'Artwork (EN)',
    field: 'artworkEn',
    advancedFilterField: 'artworkEn',
  },
  artworkPt: {
    header: 'Artwork (PT)',
    field: 'artworkPt',
    advancedFilterField: 'artworkPt',
  },
  techniqueEn: {
    header: 'Technique (EN)',
    field: 'techniqueEn',
    advancedFilterField: 'techniqueEn',
  },
  techniquePt: {
    header: 'Technique (PT)',
    field: 'techniquePt',
    advancedFilterField: 'techniquePt',
  },
  typeOfMaterial: {
    header: 'Type of Material',
    field: 'typeOfMaterial',
    advancedFilterField: 'typeOfMaterial',
  },
  lining: {
    header: 'Lining',
    field: 'lining',
    advancedFilterField: 'lining',
  },
  liningComposition: {
    header: 'Lining Composition (%)',
    field: 'liningComposition',
    advancedFilterField: 'liningComposition',
  },
  liningDescription: {
    header: 'Lined Description',
    field: 'liningDescription',
    advancedFilterField: 'liningDescription',
  },
  typePackageDescription: {
    header: 'Type of Package',
    field: 'idTypePackage2.description',
    advancedFilterField: 'typePackage',
  },
  quantity: {
    header: 'Quantity',
    field: 'quantity',
    advancedFilterField: 'quantity',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  unitDescription: {
    header: 'Unit',
    field: 'unit2.description',
    advancedFilterField: 'unit',
  },
  remainQuantity: {
    header: 'Remain Quantity',
    field: 'remainQuantity',
    advancedFilterField: 'remainQuantity',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  totalCotCi: {
    header: 'Total COT CI',
    field: 'totalCotCi',
    advancedFilterField: '',
  },
  moq: {
    header: 'MOQ',
    field: 'moq',
    advancedFilterField: 'moq',
  },
  m2Pc: {
    header: 'SQM UNIT',
    field: 'm2Pc',
    advancedFilterField: 'm2Pc',
  },
  totalMasterM2: {
    header: 'TOTAL SQM',
    field: 'totalMasterM2',
    advancedFilterField: 'totalMasterM2',
  },
  totalSqmCi: {
    header: 'Total SQM CI',
    field: 'totalSqmCi',
    advancedFilterField: 'totalSqmCi',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  remainSqm: {
    header: 'Remain SQM',
    field: 'remainSqm',
    advancedFilterField: 'remainSqm',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  cotPriceSqm: {
    header: 'Cot Price SQM',
    field: 'cotPriceSqm',
    advancedFilterField: 'cotPriceSqm',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  sunPriceSqm: {
    header: 'SUN Price SQM',
    field: 'sunPriceSqm',
    advancedFilterField: 'sunPriceSqm',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  quantityContainer: {
    header: 'Quantity of CTN',
    field: 'quantityContainer',
    advancedFilterField: 'quantityContainer',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  piecesContainer: {
    header: 'PCS/CTN',
    field: 'piecesContainer',
    advancedFilterField: 'piecesContainer',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  masterNetWeight: {
    header: 'Net Weight (CTN)',
    field: 'masterNetWeight',
    advancedFilterField: 'masterNetWeight',
  },
  masterNetWeightTotal: {
    header: 'Net Weight Total',
    field: 'masterNetWeightTotal',
    advancedFilterField: 'masterNetWeightTotal',
    tooltip: 'Master Net Weight Total',
  },
  grossWeight: {
    header: 'Gross Weight',
    field: 'grossWeight',
    advancedFilterField: 'grossWeight',
  },
  grossWeightTotal: {
    header: 'Gross Weight Total',
    field: 'grossWeightTotal',
    advancedFilterField: 'grossWeightTotal',
  },
  length: {
    header: 'Length',
    field: 'length',
    advancedFilterField: 'length',
  },
  width: {
    header: 'Width',
    field: 'width',
    advancedFilterField: 'width',
  },
  height: {
    header: 'Height',
    field: 'height',
    advancedFilterField: 'height',
  },
  cbm: {
    header: 'CBM',
    field: 'cbm',
    advancedFilterField: 'cbm',
  },
  cbmTotal: {
    header: 'CBM Total',
    field: 'cbmTotal',
    advancedFilterField: 'cbmTotal',
  },
  purchaseIncotermDescription: {
    header: 'Purchase Incoterm',
    field: 'idPurchaseIncoterm2.description',
    advancedFilterField: 'purchaseIncoterm',
  },
  targetCot: {
    header: 'Target COT',
    field: 'targetCot',
    advancedFilterField: 'targetCot',
  },
  cotPrice: {
    header: 'COT Price',
    field: 'cotPrice',
    advancedFilterField: 'cotPrice',
  },
  remainTotalCot: {
    header: 'Remain Total COT',
    field: 'remainTotalCot',
    advancedFilterField: 'remainTotalCot',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  sellingIncotermDescription: {
    header: 'Selling Incoterm',
    field: 'idSellingIncoterm2.description',
    advancedFilterField: 'sellingIncoterm',
  },
  targetSun: {
    header: 'Target Sun',
    field: 'targetSun',
    advancedFilterField: 'targetSun',
  },
  sunPrice: {
    header: 'Sun Price',
    field: 'sunPrice',
    advancedFilterField: 'sunPrice',
  },
  totalSunCi: {
    header: 'Total SUN CI',
    field: 'totalSunCi',
    advancedFilterField: 'totalSunCi',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  remainTotalSun: {
    header: 'Remain Total SUN',
    field: 'remainTotalSun',
    advancedFilterField: 'remainTotalSun',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  grossProfit: {
    header: 'Gross Profit',
    field: 'grossProfit',
    advancedFilterField: 'grossProfit',
  },
  grossMargin: {
    header: 'Gross Margin',
    field: 'grossMargin',
    advancedFilterField: 'grossMargin',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  hsCode: {
    header: 'HS Code',
    field: 'hsCode',
    advancedFilterField: 'hsCode',
  },
  ncm: {
    header: 'NCM',
    field: 'ncm',
    advancedFilterField: 'ncm',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  ncmDestaque: {
    header: 'Destaque',
    field: 'ncmDestaque',
    advancedFilterField: 'ncmDestaque',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  foreignTradeReview: {
    header: 'Foreign Trade Review',
    field: 'foreignTradeReview',
    advancedFilterField: 'foreignTradeReview',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  generalLi: {
    header: 'Import License',
    field: 'generalLi',
    advancedFilterField: 'generalLi',
  },
  ncmIi: {
    header: '% II',
    field: 'ncmIi',
    advancedFilterField: 'ncmIi',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  ncmIpi: {
    header: '% IPI',
    field: 'ncmIpi',
    advancedFilterField: 'ncmIpi',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  ncmPis: {
    header: '% PIS',
    field: 'ncmPis',
    advancedFilterField: 'ncmPis',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  ncmCofins: {
    header: '% COFINS',
    field: 'ncmCofins',
    advancedFilterField: 'ncmCofins',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  ncmDumping: {
    header: 'Dumping',
    field: 'ncmDumping',
    advancedFilterField: 'ncmDumping',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  containerNumber: {
    header: 'Container Number',
    field: 'containerNumber',
    advancedFilterField: 'containerNumber',
  },
  sample: {
    header: 'Samples',
    field: 'sample',
    advancedFilterField: 'sample',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  sampleLeadTime: {
    header: 'Sample Lead Time',
    field: 'sampleLeadTime',
    advancedFilterField: 'sampleLeadTime',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  samplesApproved: {
    header: 'Samples Approved',
    field: 'samplesApproved',
    advancedFilterField: 'samplesApproved',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  sampleNotes: {
    header: 'Sample Notes',
    field: 'sampleNotes',
    advancedFilterField: 'sampleNotes',
  },
  licensed: {
    header: 'Licensed',
    field: 'licensed',
    advancedFilterField: 'licensed',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  licensorName: {
    header: 'Licensor',
    field: 'idLicensor2.name',
    advancedFilterField: 'licensor',
  },
  royalties: {
    header: 'Royalties',
    field: 'royalties',
    advancedFilterField: 'royalties',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  amountCot: {
    header: 'Total COT PI',
    field: 'amountCot',
    advancedFilterField: 'amountCot',
  },
  quantityShipped: {
    header: 'Quantity CI',
    field: 'quantityShipped',
    advancedFilterField: 'quantityShipped',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  priceFob: {
    header: 'Price FOB',
    field: 'priceFob',
    advancedFilterField: 'priceFob',
  },
  amount: {
    header: 'Total SUN PI',
    field: 'amount',
    advancedFilterField: 'amount',
  },
  notes: {
    header: 'Notes',
    field: 'notes',
    advancedFilterField: 'notes',
  },
  estimateProfit: {
    header: 'Estimated Gross Profit',
    field: 'estimateProfit',
    advancedFilterField: 'estimateProfit',
  },
  estimateMargin: {
    header: 'Estimated Gross Margin',
    field: 'estimateMargin',
    advancedFilterField: 'estimateMargin',
  },
  qtyOfCtnr: {
    header: 'Qty Of Ctnr',
    field: 'qtyOfCtnr',
    advancedFilterField: 'qtyOfCtnr',
  },
  stickerDimensionsPt: {
    header: 'Sticker Dimensions',
    field: 'stickerDimensionsPt',
    advancedFilterField: '',
  },
  centimeterDimensions: {
    header: 'DI Dimensions',
    field: 'centimeterDimensions',
    advancedFilterField: 'DI Dimensions',
  },
  innerBox: {
    header: 'INNER/CTN',
    field: 'innerBox',
    advancedFilterField: '',
  },
  nve: {
    header: 'NVE',
    field: 'nve',
    advancedFilterField: 'nve',
  },
};

export const groupedColoumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typePackageDescription.field,

          gridColumnsData.packageDescription.field,

          gridColumnsData.refCode.field,
          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.targetCot.field,

          gridColumnsData.m2Pc.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,
          gridColumnsData.ncm.field,
          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-1',
        label: 'Purchase',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.model.field,
          gridColumnsData.sku.field,
          gridColumnsData.colour.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,
          gridColumnsData.innerBox.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.quantityInner.field,

          gridColumnsData.refCode.field,
          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.purchaseIncotermDescription.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.remainTotalCot.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.grossMargin.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,

          gridColumnsData.sample.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,

          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-2',
        label: 'Commercial',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialCompositionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,
          gridColumnsData.innerBox.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.quantityInner.field,
          gridColumnsData.brand.field,
          gridColumnsData.productLine.field,

          gridColumnsData.refCode.field,
          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,
          gridColumnsData.ncm.field,
          gridColumnsData.ncmDestaque.field,
          gridColumnsData.foreignTradeReview.field,
          gridColumnsData.generalLi.field,
          gridColumnsData.ncmIi.field,
          gridColumnsData.ncmIpi.field,
          gridColumnsData.ncmPis.field,
          gridColumnsData.ncmCofins.field,
          gridColumnsData.ncmDumping.field,
          gridColumnsData.containerNumber.field,

          gridColumnsData.sample.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.royalties.field,

          gridColumnsData.estimateMargin.field,
          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-3',
        label: 'Export',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.model.field,
          gridColumnsData.sku.field,
          gridColumnsData.colour.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,
          gridColumnsData.innerBox.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.quantityInner.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.purchaseIncotermDescription.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.remainTotalCot.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,
          gridColumnsData.containerNumber.field,

          gridColumnsData.sample.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,

          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-4',
        label: 'Import',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.description.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.materialCompositionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,
          gridColumnsData.innerBox.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.quantityInner.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,
          gridColumnsData.ncm.field,
          gridColumnsData.ncmDestaque.field,
          gridColumnsData.foreignTradeReview.field,
          gridColumnsData.generalLi.field,
          gridColumnsData.ncmIi.field,
          gridColumnsData.ncmIpi.field,
          gridColumnsData.ncmPis.field,
          gridColumnsData.ncmCofins.field,
          gridColumnsData.ncmDumping.field,
          gridColumnsData.containerNumber.field,

          gridColumnsData.sample.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.royalties.field,

          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-5',
        label: 'Design',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.model.field,
          gridColumnsData.sku.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.colourImageUrl.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialCompositionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.stickerDimensionsPt.field,
          gridColumnsData.centimeterDimensions.field,
          gridColumnsData.innerBox.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.sellingPackageDimensions.field,
          gridColumnsData.sellingPackageGrossWeight.field,
          gridColumnsData.quantityInner.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerdun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.brand.field,
          gridColumnsData.productLine.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.length.field,
          gridColumnsData.width.field,
          gridColumnsData.height.field,

          gridColumnsData.sample.field,
          gridColumnsData.samplesApproved.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
        ],
      },
      {
        key: '0-6',
        label: 'Quality',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.description.field,
          gridColumnsData.descriptionPt.field,

          gridColumnsData.packageDescription.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,

          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-7',
        label: 'Financial',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.description.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.sku.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.purchaseIncotermDescription.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.remainTotalCot.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.grossMargin.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.royalties.field,

          gridColumnsData.estimateMargin.field,
          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '0-8',
        label: 'Register',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.model.field,
          gridColumnsData.sku.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.colourImageUrl.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialCompositionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.sellingPackageDimensions.field,
          gridColumnsData.sellingPackageGrossWeight.field,
          gridColumnsData.quantityInner.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerdun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.brand.field,
          gridColumnsData.productLine.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.sunPrice.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.length.field,
          gridColumnsData.width.field,
          gridColumnsData.height.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,

          gridColumnsData.hsCode.field,
          gridColumnsData.ncm.field,
          gridColumnsData.ncmDestaque.field,
          gridColumnsData.foreignTradeReview.field,
          gridColumnsData.generalLi.field,
          gridColumnsData.ncmIi.field,
          gridColumnsData.ncmIpi.field,
          gridColumnsData.ncmPis.field,
          gridColumnsData.ncmCofins.field,
          gridColumnsData.ncmDumping.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.quantity.field,
          gridColumnsData.innerBox.field,
        ],
      },
      {
        key: '0-9',
        label: 'SQM',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.netWeight.field,

          gridColumnsData.packageDescription.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.sunPrice.field,

          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.quantityShipped.field,

          gridColumnsData.quantity.field,
        ],
      },
      {
        key: '0-10',
        label: 'Fashion',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.refCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.colour.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,

          gridColumnsData.packageDescription.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerdun14.field,
          gridColumnsData.dun14.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.purchaseIncotermDescription.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.remainTotalCot.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.grossMargin.field,

          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.quantity.field,
          gridColumnsData.quantityShipped.field,

          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.royalties.field,
          gridColumnsData.estimateMargin.field,
        ],
      },
    ],
  },
  {
    key: '1',
    label: 'Field Groups',
    data: [],
    children: [
      {
        key: '1-0',
        label: 'Produto',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.idSupplier.field,
          gridColumnsData.stCode.field,
          gridColumnsData.refCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.detailedDescriptionPt.field,
          gridColumnsData.extraInfoEn.field,
          gridColumnsData.extraInfoPt.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.model.field,
          gridColumnsData.sku.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.colourImageUrl.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialCompositionPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfMaterial.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.liningDescription.field,
          gridColumnsData.typePackageDescription.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.artworkPt.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.techniquePt.field,
        ],
      },
      {
        key: '1-1',
        label: 'Package',
        data: [
          gridColumnsData.packageDescription.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.sellingPackageDimensions.field,
          gridColumnsData.sellingPackageGrossWeight.field,
          gridColumnsData.quantityInner.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerdun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.brand.field,
          gridColumnsData.productLine.field,

          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.stickerDimensionsPt.field,
          gridColumnsData.centimeterDimensions.field,
          gridColumnsData.innerBox.field,
        ],
      },
      {
        key: '1-2',
        label: 'Qty',
        data: [
          gridColumnsData.quantity.field,
          gridColumnsData.unitDescription.field,
          gridColumnsData.remainQuantity.field,
          gridColumnsData.moq.field,
          gridColumnsData.quantityShipped.field,
        ],
      },
      {
        key: '1-3',
        label: 'SQM',
        data: [
          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.totalSqmCi.field,
          gridColumnsData.remainSqm.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,
        ],
      },
      {
        key: '1-4',
        label: 'Carton',
        data: [
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.length.field,
          gridColumnsData.width.field,
          gridColumnsData.height.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
        ],
      },
      {
        key: '1-5',
        label: 'Prices',
        data: [
          gridColumnsData.purchaseIncotermDescription.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.remainTotalCot.field,
          gridColumnsData.sellingIncotermDescription.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.remainTotalSun.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.grossMargin.field,
          gridColumnsData.estimateMargin.field,
        ],
      },
      {
        key: '1-6',
        label: 'Foreign Trade',
        data: [
          gridColumnsData.hsCode.field,
          gridColumnsData.ncm.field,
          gridColumnsData.ncmDestaque.field,
          gridColumnsData.foreignTradeReview.field,
          gridColumnsData.generalLi.field,
          gridColumnsData.ncmIi.field,
          gridColumnsData.ncmIpi.field,
          gridColumnsData.ncmPis.field,
          gridColumnsData.ncmCofins.field,
          gridColumnsData.ncmDumping.field,
          gridColumnsData.containerNumber.field,
          gridColumnsData.nve.field,
        ],
      },
      {
        key: '1-7',
        label: 'Samples',
        data: [
          gridColumnsData.sample.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '1-8',
        label: 'Licensing',
        data: [
          gridColumnsData.licensed.field,
          gridColumnsData.licensorName.field,
          gridColumnsData.royalties.field,
        ],
      },
    ],
  },
];
