import { gql } from '@apollo/client';

export const supplierPaymentDashboardQuery = gql`
  query GetSatSupplierPaymentProcessDashboard(
    $data: GetSatSupplierPaymentProcessDashboardInput
  ) {
    getSatSupplierPaymentProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatForeignTradeSupplier
        satNumber
        shipment
        mainProduct
        sunNumber
        clientNotify
        exporter
        countryOfOrigin
        satCurrency
        sellingIncoterm
        realInspectionDate
        realEtd
        category
        categoryIcon
      }
      items
    }
  }
`;
