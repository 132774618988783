import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { TabPanel, TabView } from 'primereact/tabview';
import { Skeleton } from 'primereact/skeleton';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import ToastLife from '../../../../shared/enums/toastLife';
import {
  FinancialApportionmentFieldsPermissions,
  SatForeignTrade,
} from './interfaces';
import Empty from '../../../../components/Empty';
import Content from './Content';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { satFinancialApportionmentRoles } from '../../../../shared/roles/sat';
import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import { listSatForeignTradesNumbersQuery } from '../ForeignTrade/queries';

export interface ISatFinancialApportionmentRef {
  show(idSatForeignTrade: number): Promise<void>;
}

interface IFinancialApportionmentProps extends PageTabContainerProps {
  idSat: number;
  pageMenuItemKey: string;
  ref: Ref<ISatFinancialApportionmentRef>;
  setSelectedMenuItem: Dispatch<SetStateAction<string>>;
  userPermissions: IUserFieldsAndPermissionsResponse;
}

const FinancialApportionment: React.FC<IFinancialApportionmentProps> =
  React.forwardRef(
    (
      {
        selected,
        pageMenuItemKey,
        setSelectedMenuItem,
        idSat,
        userPermissions,
      },
      ref,
    ) => {
      const { toastRef } = useRefHook();

      const [activeTabIndex, setActiveTabIndex] = React.useState(0);

      /**
       * Busca SAT Foreign Trades
       */
      const [
        loadForeignTradesData,
        { loading: foreignTradesLoading, data: foreignTradesData },
      ] = useLazyQuery(listSatForeignTradesNumbersQuery, {
        variables: {
          data: {
            idSat,
            pagination: {
              _page: 1,
              _limit: 0,
            },
          },
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting Foreign Trades',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

      useImperativeHandle(ref, () => ({
        async show(idSatForeignTrade: number) {
          setSelectedMenuItem(pageMenuItemKey);

          let data =
            foreignTradesData?.listSatForeignTradesNumbersBySatId?.data;

          if (!data) {
            const response = await loadForeignTradesData();
            data = response.data?.listSatForeignTradesNumbersBySatId.data;
          }

          const itemIndex = data.findIndex(
            (ft: SatForeignTrade) => ft.idSatForeignTrade === idSatForeignTrade,
          );

          setActiveTabIndex(itemIndex);
        },
      }));

      const fieldsPermissions: FinancialApportionmentFieldsPermissions =
        useMemo(() => {
          return {
            stCode: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldStCode,
              userPermissions.userFields,
            ),
            quantityPi: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldQuantityPi,
              userPermissions.userFields,
            ),
            quantityCi: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldQuantityCi,
              userPermissions.userFields,
            ),
            sunPrice: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldSunPrice,
              userPermissions.userFields,
            ),
            sunPriceTotal: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldSunPriceTotal,
              userPermissions.userFields,
            ),
            netWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldNetWeight,
              userPermissions.userFields,
            ),
            netWeightTotal: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldNetWeightTotal,
              userPermissions.userFields,
            ),
            grossWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldGrossWeight,
              userPermissions.userFields,
            ),
            grossWeightTotal: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldGrossWeightTotal,
              userPermissions.userFields,
            ),
            calculationBasisNetWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields
                .idFieldCalculationBasisNetWeight,
              userPermissions.userFields,
            ),
            calculationBasisGrossWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields
                .idFieldCalculationBasisGrossWeight,
              userPermissions.userFields,
            ),
            calculationBasisSunPrice: getFieldPermission(
              satFinancialApportionmentRoles.fields
                .idFieldCalculationBasisSunPrice,
              userPermissions.userFields,
            ),
            freight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldFreight,
              userPermissions.userFields,
            ),
            freightTaxes: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldFreightTaxes,
              userPermissions.userFields,
            ),
            fobCharges: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldFobCharges,
              userPermissions.userFields,
            ),
            yiwu: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldYiwu,
              userPermissions.userFields,
            ),
            samplesNetWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldSamplesNetWeight,
              userPermissions.userFields,
            ),
            samplesGrossWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldSamplesGrossWeight,
              userPermissions.userFields,
            ),
            samplesSunPrice: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldSamplesSunPrice,
              userPermissions.userFields,
            ),
            otherNetWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldOtherNetWeight,
              userPermissions.userFields,
            ),
            otherGrossWeight: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldOtherGrossWeight,
              userPermissions.userFields,
            ),
            otherSunPrice: getFieldPermission(
              satFinancialApportionmentRoles.fields.idFieldOtherSunPrice,
              userPermissions.userFields,
            ),
            sunWithApportionmentTotal: getFieldPermission(
              satFinancialApportionmentRoles.fields
                .idFieldSunWithApportionmentTotal,
              userPermissions.userFields,
            ),
            sunWithApportionmentUnit: getFieldPermission(
              satFinancialApportionmentRoles.fields
                .idFieldSunWithApportionmentUnit,
              userPermissions.userFields,
            ),
          };
        }, [userPermissions.userFields]);

      const userCanSeeGrid = Object.keys(fieldsPermissions).some(
        key => fieldsPermissions[key].view,
      );

      return (
        <PageTabContainer selected={selected}>
          {!foreignTradesLoading &&
            !!foreignTradesData?.listSatForeignTradesNumbersBySatId?.items && (
              <TabView
                scrollable
                activeIndex={activeTabIndex}
                onTabChange={e => setActiveTabIndex(e.index)}
              >
                {foreignTradesData?.listSatForeignTradesNumbersBySatId.data.map(
                  (item: SatForeignTrade, index: number) => {
                    return (
                      <TabPanel
                        key={item.idSatForeignTrade}
                        header={item.satForeignTradeNumber}
                      >
                        {userCanSeeGrid ? (
                          <Content
                            satForeignTrade={item}
                            selected={activeTabIndex === index}
                            fieldsPermissions={fieldsPermissions}
                          />
                        ) : (
                          <Empty message="You don't have enough permissions to see this content">
                            <i
                              className="pi pi-exclamation-circle"
                              style={{ fontSize: '1.2rem' }}
                            />
                          </Empty>
                        )}
                      </TabPanel>
                    );
                  },
                )}
              </TabView>
            )}

          {foreignTradesLoading && <Skeleton height="3rem" className="mb-3" />}

          {!foreignTradesLoading &&
            !foreignTradesData?.listSatForeignTradesNumbersBySatId.items && (
              <Empty message="There is no Foreign Trades for this SAT" />
            )}
        </PageTabContainer>
      );
    },
  );

export default FinancialApportionment;
