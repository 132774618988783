import styled from 'styled-components';

export const Process = styled.div``;

export const ProcessTitle = styled.div`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  margin: 8px 0px;
`;

export const StepsTitle = styled.div`
  font-family: Roboto;
  font-weight: 500;
`;

export const Steps = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 1rem;
  margin: 6px 0px 16px 0px;
  background: var(--grey);
  border-radius: 6px;

  .error {
    background: #fd2d2d1a;
  }

  .success .tag {
    background-color: var(--success);
    color: var(--white);
  }

  .warn .tag {
    background: var(--yellow);
    color: var(--black);
  }

  .error .tag {
    background: var(--error);
    color: var(--white);
  }

  .status-dropdown {
    padding: 5px 0px;
  }

  /** Largura do scroll */
  ::-webkit-scrollbar {
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }

  /** Firefox  */
  scrollbar-width: thin;
`;

export const Step = styled.div`
  background: var(--white);
  padding: 8px;
  border-radius: 8px;
`;

export const StepTitle = styled.div`
  font-family: Roboto;
  font-weight: 500;
`;

export const StepTag = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  margin: 5px 0px;
  height: 21px;
  border-radius: 12px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 500;

  i {
    margin-right: 3px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    max-width: 21ch;
    text-overflow: ellipsis;
  }
`;
