/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Menu } from 'primereact/menu';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import ToastLife from '../../../shared/enums/toastLife';
import { airFreightRoles } from '../../../shared/roles/airFreight';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './constants';
import FileUpload from '../../../components/FileUpload';
import { FileType } from '../../../shared/enums/fileType';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import {
  createAirFreightsBulkQuery,
  updateAirFreightsBulkQuery,
} from './queries';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

/**
 * Interface dos parametros do backend
 */
interface IAirFreightLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  airPortLoading?: string;
  airPortDestination?: string;
  valueFreight?: number;
  curValue?: string;
  bcValue?: string;
  bcCif?: boolean;
}

interface IBulkResponse {
  airFreightsTotal: number;
  airFreightsWithError: string[];
  createdAirFreights?: number;
  updatedAirFreights?: number;
  type: 'CREATE' | 'UPDATE';
}

const screenSubject = 'Air Freights';
const pageTitle = `List of ${screenSubject}`;

const AirFreights: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:airFreightGridColumns';

  const lazyParamsName = '@SAT:airFreightLazyParams';

  const advancedFiltersName = '@SAT:airFreightAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<GridRef<any>>(null);
  const massiveActionMenuRef = useRef<Menu>(null);

  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de resposta da acao e massa
  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  // URL params
  const location = useLocation();

  // Permissions de Air Freight
  const {
    idPermissionDeleteAirFreight,
    idPermissionCreateAirFreight,
    idPermissionMassiveActionAirFreight,
    idPermissionExportListAirFreight,
  } = airFreightRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    airFreightRoles.idEntity,
  );

  const roleEntityPermissions = userPermissionsAirFreight.userPermissions;

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;
  // Busca
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'air_freight_bulk_template_v1.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting template',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IAirFreightLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IAirFreightLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IAirFreightLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // AirFreights selecionados
  const [selectedAirFreights, setSelectedAirFreights] = useState([]);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar Ocen Freights
  const listAirFreightsQuery = gql`
    query ListAllAirFreights(
      $listAllAirFreightsInput: ListAllAirFreightsInput!
    ) {
      listAllAirFreights(listAllAirFreightsInput: $listAllAirFreightsInput) {
        data {
          idAirFreight
          idAirportLoading2 {
            idPort
            name
          }
          idAirportDestination2 {
            idPort
            name
          }
          valueFreight
          idCurValue2 {
            abbreviation
          }
          idBcValue2 {
            description
          }
          bcCif
          updatedAt
        }
        items
      }
    }
  `;

  // Query para deletar Air Freight
  const deleteAirFreightQuery = gql`
    mutation DisableLogisticsAirFreight($ids: [Int]!) {
      disableLogisticsAirFreight(ids: $ids)
    }
  `;

  // cria método para chamar a mutation
  const [deleteAirFreight] = useMutation(deleteAirFreightQuery);

  /**
   * Busca Air Freight
   */
  const {
    loading: airFreightLoading,
    data: airFreightData,
    refetch: airFreightRefetch,
  } = useQuery(listAirFreightsQuery, {
    variables: {
      listAllAirFreightsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        airPortDestination: lazyParams.airPortDestination,
        airPortLoading: lazyParams.airPortLoading,
        valueFreight: lazyParams.valueFreight,
        curValue: lazyParams.curValue,
        bcValue: lazyParams.bcValue,
        bcCif: lazyParams.bcCif,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Air Freight data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const parseDoubleColumns = (rowData: any, field: any) => {
    let value;
    if (field.field === 'valueFreight' && rowData.valueFreight) {
      value = rowData.valueFreight?.toLocaleString('pt-BR');
    }

    return <p>{value}</p>;
  };

  /**
   * Efetua parse de coluna de Data
   * @param rowData dados da linha
   * @param field dados do campo
   * @returns Coluna em formato de data
   */
  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (field.field === 'updatedAt' && rowData.updatedAt) {
      date = new Date(rowData.updatedAt).toLocaleString();
    }

    return <p>{date}</p>;
  };

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    if (field.field === 'bcCif') {
      return <p>{rowData.bcCif ? 'Yes' : 'No'}</p>;
    }
    return undefined;
  };

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }
  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'valueFreight':
        return parseDoubleColumns;
      case 'updatedAt':
        return parseDateColumm;
      case 'bcCif':
        return parseBooleanColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'idAirportLoading2.name':
      case 'idAirportDestination2.name':
        return { width: '240px' };
      case 'idBcValue2.description':
        return { width: '170px' };
      case 'idCurValue2.abbreviation':
        return { width: '110px' };
      default:
        return { width: '140px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/logistics/airFreight/${e.data.idAirFreight}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta Air Freights selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedAirFreights.length} Air Freights?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada Air Freights
        const idsToDelete = selectedAirFreights.map((a: any) => a.idAirFreight);
        try {
          // Deleta Air Freights
          await deleteAirFreight({
            variables: {
              ids: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedAirFreights.length} Air Freights`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de Air Freights selecionadas
          setSelectedAirFreights([]);

          // Atualiza grid de Air Freights
          await airFreightRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting Air Freights',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(airFreightExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    airFreightExportData.listAllAirFreights.data.forEach((airFreight: any) => {
      airFreight.airPortLoading = airFreight.idAirportLoading2
        ? airFreight.idAirportLoading2.name
        : undefined;
      airFreight.airPortDestination = airFreight.idAirportDestination2
        ? airFreight.idAirportDestination2.name
        : undefined;
      airFreight.idCurValue = airFreight.idCurValue2
        ? airFreight.idCurValue2.abbreviation
        : undefined;
      airFreight.idBcValue = airFreight.idBcValue2
        ? airFreight.idBcValue2.description
        : undefined;
      airFreight.bcCif = !!airFreight.bcCif;

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'idAirportLoading2.name':
            delete airFreight.airPortLoading;
            break;
          case 'idAirportDestination2.name':
            delete airFreight.airPortDestination;
            break;
          case 'idCurValue2.abbreviation':
            delete airFreight.idCurValue;
            break;
          case 'idBcValue2.description':
            delete airFreight.idBcValue;
            break;
          default:
            delete airFreight[column.field];
            break;
        }
      });

      // Remover colunas __typename e idIcms
      delete airFreight.__typename;
      delete airFreight.idAirFreight;
      delete airFreight.idAirportLoading2;
      delete airFreight.idAirportDestination2;
      delete airFreight.idCurValue2;
      delete airFreight.idBcValue2;
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idAirFreight' &&
        !columnsToRemove.find(a => a.field === item)?.field &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idAirportLoading2.name':
          columnsOrder[index] = 'airPortLoading';
          break;
        case 'idAirportDestination2.name':
          columnsOrder[index] = 'airPortDestination';
          break;
        case 'idCurValue2.abbreviation':
          columnsOrder[index] = 'idCurValue';
          break;
        case 'idBcValue2.description':
          columnsOrder[index] = 'idBcValue';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      airFreightExportData.listAllAirFreights.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:I1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'airFreight');
  }

  const [createAirFreightsBulkMutation] = useMutation(
    createAirFreightsBulkQuery,
  );
  const [updateAirFreightsBulkMutation] = useMutation(
    updateAirFreightsBulkQuery,
  );

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    setPageLoading(true);

    try {
      const response = await createAirFreightsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createLogisticsAirFreightsBulk,
        type: 'CREATE',
      });
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while creating air freights',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    setPageLoading(true);

    try {
      const response = await updateAirFreightsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateLogisticsAirFreightsBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while updating air freights',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setPageLoading(false);
    }
  }

  /**
   * Busca Air Freights para exportar para XLSX
   */
  const [
    loadAirFreightsToExport,
    { loading: airFreightsToExportLoading, data: airFreightsToExportData },
  ] = useLazyQuery(listAirFreightsQuery, {
    onCompleted: () => {
      exportToXlsx(airFreightsToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting Air Freights',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>Air Freights found in the spreadsheet:</b>{' '}
          {bulkResponse?.airFreightsTotal}
        </p>
        <p>
          <b>
            Air Freights{' '}
            {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.createdAirFreights || bulkResponse?.updatedAirFreights}
        </p>
        {!!bulkResponse?.airFreightsWithError?.length && (
          <p>
            <b>Air Freights/Cells with error:</b>
            <br />
            {bulkResponse?.airFreightsWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar Air Freights */}

        {userHasPermission(
          idPermissionCreateAirFreight,
          userPermissionsAirFreight.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Air Freight"
            onClick={() => history.push('/logistics/airFreight/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir Air Freights selecionados */}
        {userHasPermission(
          idPermissionDeleteAirFreight,
          userPermissionsAirFreight.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedAirFreights.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {hasPermission(idPermissionMassiveActionAirFreight) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}

        {hasPermission(idPermissionExportListAirFreight) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={airFreightsToExportLoading}
            onClick={() => {
              loadAirFreightsToExport({
                variables: {
                  listAllAirFreightsInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    airPortLoading: lazyParams.airPortLoading,
                    airPortDestination: lazyParams.airPortDestination,
                    valueFreight: lazyParams.valueFreight,
                    curValue: lazyParams.curValue,
                    bcValue: lazyParams.bcValue,
                    bcCif: lazyParams.bcCif,
                  },
                },
              });
            }}
          />
        )}
        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an Item"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="airFreight"
        lazy
        totalRecords={
          !airFreightData ? 0 : airFreightData.listAllAirFreights.items
        }
        value={
          !airFreightData ? undefined : airFreightData.listAllAirFreights.data
        }
        globalFilter={globalFilter}
        emptyMessage="No Air Freights found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedAirFreights}
        onSelectionChange={e => setSelectedAirFreights(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteAirFreight,
          userPermissionsAirFreight.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || airFreightLoading) && <Loading />}
    </div>
  );
};
export default AirFreights;
