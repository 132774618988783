import { IRoleEntityPermission } from '../interfaces/IRoleEntityPermission';

function userHasPermission(
  idPermission: number,
  roleEntityPermissions: IRoleEntityPermission[],
): boolean {
  const hasPermission = roleEntityPermissions?.find(
    permission => permission.idPermission === idPermission,
  );

  if (hasPermission) {
    return true;
  }
  return false;
}

export default userHasPermission;
