import { gql } from '@apollo/client';

/**
 * Query para listar SAT Quotations pelo ID da SAT
 */
export const listSatQuotationsBySatId = gql`
  query ListSatQuotationsBySatId($data: ListSatQuotationsBySatIdInput!) {
    listSatQuotationsBySatId(data: $data) {
      items
      data {
        idSatQuotation
        bestPrice
        idSupplier2 {
          idSupplier
          sunNumber
          name
        }
        comment
        validDate
        idAttachment2 {
          url
        }
        satQuotationFileLogs {
          createdAt
          createdBy2 {
            firstName
            lastName
          }
          idAttachment2 {
            url
          }
        }
      }
    }
  }
`;

/**
 * Mutation para criar SAT Quotation File Log
 */
export const createSatQuotationFileLog = gql`
  mutation CreateSatQuotationFileLog($data: CreateSatQuotationFileLogInput!) {
    createSatQuotationFileLog(data: $data) {
      idSatQuotation
    }
  }
`;

/**
 * Mutation para deletar SAT Quotation
 */
export const deleteSatQuotationQuery = gql`
  mutation DeleteSatQuotation($idSatQuotation: Int!) {
    deleteSatQuotation(idSatQuotation: $idSatQuotation)
  }
`;
