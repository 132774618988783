import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;

  form {
    width: 100%;
  }
`;
