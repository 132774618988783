// eslint-disable-next-line no-shadow
export enum SatForeignTradePaymentType {
  ADVANCE_PURCHASE = 870,
  ROLLING_PURCHASE = 871,
  BALANCE_PURCHASE = 872,
  ADVANCE_SELLER = 1003,
  ROLLING_SELLER = 1004,
  BALANCE_SELLER = 1005,
}

export type SatForeignTradePayment = {
  idSatForeignTradePayment?: number;
  idSatForeignTradeSupplier?: number;
  idType?: number;
  idSatDestination?: number;
  idSatForeignTradeDestination?: number;
  idSatForeignTradeSupplierDestination?: number;
  percentage?: number;
  amount?: number;
  paymentDate?: Date;
  hasFinancialTransactionReconciled?: boolean;
};
