import { IFieldPermissionResponse } from '../../../../utils/getFieldPermission';
import { SatForeignTradeConsolidatedData } from './ForeignTradeContent/Consolidation/interfaces';

/* eslint-disable no-shadow */
export enum ForeignTradeReducerActionKind {
  SET_ACTIVE_TAB_INDEX,
  RESET_STATE,
  SET_ITEM_CONTAINER_TO_UPDATE,
  SET_ITEM_CONTAINER_TO_CREATE,
  REMOVE_UPDATES_AND_CREATES_FROM_FOREIGN_TRADE,
  SET_DIVERGENT_DATA,
  SET_GRID_ITEM_TO_UPDATE,
  SET_CONSOLIDATION_DATA,
  SET_GRID_SAT_ITEM_REFERENCE_TO_UPDATE,
}

export type ForeignTradeLazyParams = {
  first: number;

  rows: number;

  page: number;
};

export type Domain = {
  idDomain: number;
  description: string;
};

type User = {
  idUser: number;
  firstName: string;
  lastName: string;
  email?: string;
  username?: string;
};

type Port = {
  idPort: number;
  name: string;
};

export type SatForeignTradeSupplier = {
  idSatForeignTradeSupplier: number;
  idSatSupplier2: {
    idSupplier2: {
      sunNumber: string;
    };
  };
  inspectionScheduled?: string;
  inspectionApproval?: string;
  idInspectionApprovedBy?: number;
  idInspectionApprovedBy2?: User;
  rework?: number;
  rework2?: Domain;
  reworkDate?: string;
  reworkCost?: number;
  idReworkApprovedBy?: number;
  idReworkApprovedBy2?: User;
  startProduction?: boolean;
  inspector?: string;
};

type Partner = {
  idPartner: number;
  name: string;
};

export type SatForeignTrade = {
  idSatForeignTrade: number;
  satForeignTradeNumber: string;
  idImportStatus?: number;
  idImportStatus2?: Domain;
  realInspectionDate?: string;
  realEtd?: string;
  realEta?: string;
  realArrivalAtClient?: string;
  authorizationRequested?: string;
  shipmentAuthorized?: string;
  bookingConfirmed?: string;
  shipmentConfirmed?: string;
  freightCost?: number;
  freightExtraCost?: number;
  importLicenseNumber?: string;
  importLicenseExpireDate?: string;
  diNumber?: string;
  satForeignTradeSuppliers?: SatForeignTradeSupplier[];
  freightForwarderOrigin?: number;
  freightForwarderOrigin2?: Partner;
  freightForwarderDestination?: number;
  freightForwarderDestination2?: Partner;
  noReport?: boolean;
  noLoadingPictures?: boolean;
  idTypeOfImportService?: number;
  idTypeOfImportService2?: Domain;
  importNotes?: string;
  idImportLevel?: number;
  idImportLevel2?: Domain;
  exportNotes?: string;
  idSatForeignTradeGroup?: number;
  trakingNumber?: string;
  idCourier?: number;
  idCourier2?: Domain;
  received?: string;
  deliveredToClient?: string;
  authorizationBookingTiming?: number;
  authorizationShippingTiming?: number;
  bookingShippingTiming?: number;
  transitTime?: number;
  hblFinalTiming?: number;
  deliveryAtClientTiming?: number;
  totalForeignTradeTiming?: number;
  idExportStatus?: number;
  idExportStatus2?: Domain;
  idLoadingPort2?: Port;
  idPort2?: Port;
  idPurchaseUser2?: User;
  idSalesUser2?: User;
  idSalesSecondUser2?: User;
  idFinancialUser2?: User;
  idComexExportUser2?: User;
  idComexImportUser2?: User;
  idDesignerUser2?: User;
  advancePaymentRequestDate?: string;
  balancePaymentRequestDate?: string;
  shipmentDetails?: boolean;
  shipmentRequestDate?: string;
  shipmentRequestNotRequired?: boolean;
};

type SatForeignTradeSupplierFormData = {
  idSatForeignTradeSupplier: number;
  inspectionScheduled?: string;
  inspectionApproval?: string;
  idInspectionApprovedBy?: number;
  rework?: number;
  reworkDate?: string;
  reworkCost?: number;
  idReworkApprovedBy?: number;
  startProduction?: boolean;
  inspector?: string;
};

export type SatForeignTradeFormData = {
  idSatForeignTrade: number;
  idImportStatus?: number;
  realInspectionDate?: string;
  realEtd: string;
  realEta: string;
  realArrivalAtClient: string;
  authorizationRequested?: string;
  shipmentAuthorized?: string;
  bookingConfirmed?: string;
  shipmentConfirmed?: string;
  freightCost?: number;
  freightExtraCost?: number;
  importLicenseNumber?: string;
  importLicenseExpireDate?: string;
  diNumber?: string;
  freightForwarderOrigin?: number;
  freightForwarderDestination?: number;
  satForeignTradeSuppliers?: SatForeignTradeSupplierFormData[];
  noReport?: boolean;
  noLoadingPictures?: boolean;
  idExportStatus?: number;
  idLoadingPort: number;
  idPort: number;
};

export type UpdateSatForeignTradesResponse = {
  warnings: string[];
  errors: string[];
};

export type SelectedDivergentData = {
  idSatForeignTrade?: number;
  realEtd?: string;
  realEta?: string;
  realArrivalAtClient?: string;
  idLoadingPort?: number;
  idPort?: number;
  bookingConfirmed?: string;
  shipmentConfirmed?: string;
  freightForwarderOrigin?: number;
  freightForwarderDestination?: number;
};

export type ForeignTradeReducer = {
  tabActiveIndex: number;
  itemsToUpdate: SatForeignTradeItemToUpdate[];
  itemContainersToUpdate: SatForeignTradeItemContainer[];
  itemContainersToCreate: SatForeignTradeItemContainerToCreate[];
  divergentData: SelectedDivergentData[];
  consolidationData: SatForeignTradeConsolidatedData[];
  satItemReferencesToUpdate: SatItemReferenceToUpdate[];
};

export type SatForeignTradeItemContainer = {
  idSatForeignTrade: number;
  idSatForeignTradeItemCtnr: number;
  quantityCtnr: number;
  grossWeight: number;
  netWeight: number;
  cbm: number;
};

export type SatForeignTradeItemContainerToUpdatePayload =
  SatForeignTradeItemContainer & {
    fieldUpdated: string;
  };

export type SatForeignTradeItemContainerToCreate = {
  idSatForeignTrade: number;
  idSatForeignTradeCtnr: number;
  idSatForeignTradeItem: number;
  idSatForeignTradeItemReference?: number;
  quantityCtnr: number;
  grossWeight: number;
  netWeight: number;
  cbm: number;
};

export type SatForeignTradeItemContainerToCreatePayload =
  SatForeignTradeItemContainerToCreate & {
    fieldUpdated: string;
  };

export type SatForeignTradeItemToUpdate = {
  idSatForeignTrade: number;

  idSatForeignTradeItem: number;

  quantityPiShipment?: number;
};

export type SatForeignTradeItemReference = {
  idSatForeignTrade: number;

  idSatForeignTradeItemReference: number;

  quantityCi: number;
};

export type SatItemReferenceToUpdate = {
  idSatItemReference: number;
  idSatForeignTradeItem: number;
  quantityPiShipment: number;
};

export type ForeignTradeReducerAction = {
  type: ForeignTradeReducerActionKind;
  payload?: {
    tabActiveIndex?: number;
    itemToUpdate?: SatForeignTradeItemToUpdate;
    itemContainerToUpdate?: SatForeignTradeItemContainerToUpdatePayload;
    itemContainerToCreate?: SatForeignTradeItemContainerToCreatePayload;
    idForeignTradeToRemoveEntries?: number;
    divergentData?: SelectedDivergentData[];
    consolidationData?: SatForeignTradeConsolidatedData[];
    satItemReferenceToUpdate?: SatItemReferenceToUpdate;
  };
};

export type ForeignTradeFieldsPermissions = {
  idImportStatus: IFieldPermissionResponse;
  realInspectionDate: IFieldPermissionResponse;
  realEtd: IFieldPermissionResponse;
  realEta: IFieldPermissionResponse;
  realArrivalAtClient: IFieldPermissionResponse;
  inspectionScheduled: IFieldPermissionResponse;
  inspectionApproval: IFieldPermissionResponse;
  idInspectionApprovedBy: IFieldPermissionResponse;
  rework: IFieldPermissionResponse;
  reworkDate: IFieldPermissionResponse;
  reworkCost: IFieldPermissionResponse;
  idReworkApprovedBy: IFieldPermissionResponse;
  authorizationRequested: IFieldPermissionResponse;
  shipmentAuthorized: IFieldPermissionResponse;
  bookingConfirmed: IFieldPermissionResponse;
  shipmentConfirmed: IFieldPermissionResponse;
  freightForwarderOrigin: IFieldPermissionResponse;
  freightForwarderDestination: IFieldPermissionResponse;
  freightCost: IFieldPermissionResponse;
  freightExtraCost: IFieldPermissionResponse;
  importLicenseNumber: IFieldPermissionResponse;
  importLicenseExpireDate: IFieldPermissionResponse;
  diNumber: IFieldPermissionResponse;
  typeOfImportService: IFieldPermissionResponse;
  importNotes: IFieldPermissionResponse;
  exportNotes: IFieldPermissionResponse;
  importLevel: IFieldPermissionResponse;
  trakingNumber: IFieldPermissionResponse;
  idCourier: IFieldPermissionResponse;
  received: IFieldPermissionResponse;
  deliveredToClient: IFieldPermissionResponse;
  startProductionTiming: IFieldPermissionResponse;
  realProductionTime: IFieldPermissionResponse;
  authorizationBookingTiming: IFieldPermissionResponse;
  authorizationShippingTiming: IFieldPermissionResponse;
  bookingShippingTiming: IFieldPermissionResponse;
  preShipmentTiming: IFieldPermissionResponse;
  transitTime: IFieldPermissionResponse;
  hblFinalTiming: IFieldPermissionResponse;
  originalDocsTiming: IFieldPermissionResponse;
  deliveryAtClientTiming: IFieldPermissionResponse;
  totalForeignTradeTiming: IFieldPermissionResponse;
  idExportStatus: IFieldPermissionResponse;
  estimatedInspectionDate: IFieldPermissionResponse;
  estimatedShipmentDate: IFieldPermissionResponse;
  estimatedArrivalDate: IFieldPermissionResponse;
  estimatedArrivalClientDate: IFieldPermissionResponse;
  idLoadingPort: IFieldPermissionResponse;
  idPort: IFieldPermissionResponse;
  clientOrder: IFieldPermissionResponse;
  idPurchaseUser: IFieldPermissionResponse;
  idSalesUser: IFieldPermissionResponse;
  idSalesSecondUser: IFieldPermissionResponse;
  idFinancialUser: IFieldPermissionResponse;
  idComexExportUser: IFieldPermissionResponse;
  idComexImportUser: IFieldPermissionResponse;
  idDesignerUser: IFieldPermissionResponse;
  arrivalConfirmed: IFieldPermissionResponse;
  shipmentDetails: IFieldPermissionResponse;
  startProduction: IFieldPermissionResponse;
  shipmentRequestNotRequired: IFieldPermissionResponse;
  inspector: IFieldPermissionResponse;
};

export type ForeignTradeContainerItemsPermissions = {
  stCode: IFieldPermissionResponse;
  quantity: IFieldPermissionResponse;
  quantityCi: IFieldPermissionResponse;
  cotPrice: IFieldPermissionResponse;
  sunPrice: IFieldPermissionResponse;
  totalCotCi: IFieldPermissionResponse;
  totalSunCi: IFieldPermissionResponse;
  quantityCtnr: IFieldPermissionResponse;
  piecesContainer: IFieldPermissionResponse;
  remainQuantityPiShipment: IFieldPermissionResponse;
  grossWeight: IFieldPermissionResponse;
  netWeight: IFieldPermissionResponse;
  cbm: IFieldPermissionResponse;
  quantityPiShipment: IFieldPermissionResponse;
  totalQuantityPiShipment: IFieldPermissionResponse;
  sizeRange: IFieldPermissionResponse;
};

export interface IUserCanObjects {
  userCanSeeShipmentBlock: boolean;
  userCanSeeShipmentContainersBlock: boolean;
  userCanSeeContainerItemsBlock: boolean;
  userCanDeleteContainerItem: boolean;
  userCanAddContainerItem: boolean;
  userCanSeeForeignTradeBlock: boolean;
  userCanUploadFiles: boolean;
  userCanDeleteFiles: boolean;
  userCanSeeOriginalDocsBlock: boolean;
  userCanSeeTimingBlock: boolean;
}

export type ForeignTradeTab = {
  idSatForeignTrade: number;
  satForeignTradeNumber: string;
};
