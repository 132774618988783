import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .buttons-header {
    display: flex;
    justify-content: space-between;
  }

  .attachment {
    display: flex;

    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    svg {
      margin-left: 12px;
    }
    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }
  }
`;
