import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const BankDetailContainer = styled.div`
  margin-bottom: 20px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 898px;

  textarea {
    resize: vertical;
  }

  .form-div-margin {
    margin-top: 32px;
  }

  .mainBankAddress {
    width: 99%;
  }

  .generalInput {
    width: 276.45px;
  }

  .mediumInput {
    width: 354px;
  }

  .largeInput {
    width: 489px;
  }

  .generalInput + .generalInput,
  .largeInput + .mediumInput,
  .mediumInput + .largeInput {
    margin-left: 16px;
  }

  .header-div {
    display: flex;
    justify-content: space-between;
  }

  .button-div {
    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;

  .form-checkbox {
    margin-left: 16px;
  }

  .form-checkbox + .form-checkbox {
    margin-top: 6px;
  }

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    padding: 4px;
    padding-bottom: 15px;
    margin-bottom: 20px;

    label + .p-radiobutton {
      margin-left: 24px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
  }

  && {
    margin-top: 16px;
  }
`;
