import React, { ReactNode, ReactElement, useState, useEffect } from 'react';
import { getChild, getChildren } from 'react-nanny';
import { BsSearch } from 'react-icons/bs';
import {
  Container,
  Details,
  Tags,
  DateInfo,
  Buttons,
  Statuses,
  ButtonsAndGridOptions,
  GridOptions,
} from './styles';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';
import { useMenu } from '../../hooks/useMenu';

interface Props {
  title: string;
  hasImage?: boolean;
  minScrollStickyButtons?: number;
  fixedStickyButtons?: boolean;
  isGridHeader?: boolean;
  gridHeaderMarginBottom?: number;
  children?: ReactNode;
}

const PageHeader: React.FC<Props> = ({
  title,
  hasImage,
  minScrollStickyButtons,
  children,
  fixedStickyButtons,
}) => {
  // Busca visibilidade do menu
  const { visible } = useMenu();

  // Estado de scroll
  const [scroll, setScroll] = useState(
    document.getElementById('site-content')?.scrollTop || 0,
  );

  /**
   * Atualiza estado do scroll vertical
   */
  function handleScroll() {
    setScroll(document.getElementById('site-content')?.scrollTop || 0);
  }

  /**
   * A cada evento de scroll, chama função para atualizar estado
   */
  useEffect(() => {
    document
      .getElementById('site-content')
      ?.addEventListener('scroll', handleScroll, true);

    return () => {
      document
        .getElementById('site-content')
        ?.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  // Created at
  const logo = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'image',
  );

  // Tags de componente tag
  const tags = getChildren(
    children,
    (child: ReactElement) => child.props?.className === 'tag',
  );

  // Created at
  const createdAt = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'createdAt',
  );

  // Updated at
  const updatedAt = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'updatedAt',
  );

  // Tags de status
  const statusTags = getChildren(
    children,
    (child: ReactElement) => child.props?.className === 'statusTag',
  );

  // Botoes principais
  const mainButtons = getChildren(
    children,
    (child: ReactElement) => child.props?.className === 'mainButton',
  );

  // Botoes secundarios
  const secondaryButtons = getChildren(children, (child: ReactElement) =>
    child.props?.className?.includes('secondaryButton'),
  );

  // Botoes destrutivos
  const destructiveButtons = getChildren(
    children,
    (child: ReactElement) => child.props?.className === 'p-button-danger',
  );

  // Botao Grid Multi select
  const gridAdvancedFiltersButton = getChild(
    children,
    (child: ReactElement) =>
      child.props?.className === 'advanced-filters-button',
  );

  const gridAppliedFiltersButton = getChild(
    children,
    (child: ReactElement) =>
      child.props?.className === 'applied-filters-button',
  );

  // Painel Grid Multi select
  const gridMultiSelectPanel = getChild(
    children,
    (child: ReactElement) =>
      child.props?.className === 'grid-multiselect-panel',
  );

  // Grid Advanced Filters Form
  const gridAdvancedFiltersForm = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'advanced-filters-form',
  );

  // Export to xlsx
  const exportXlsxButton = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'export-xlsx',
  );

  // Xls split button
  const xlsSplitButton = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'xls-split-button',
  );

  // Xls split button
  const massiveActionButton = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'massive-action-button',
  );

  // Grid Search
  const gridSearch = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'gridSearch',
  );

  // Page header collapse
  const headerCollapse = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'collapseHeader',
  );

  const historyButton = getChild(
    children,
    (child: ReactElement) => child.props?.className === 'history-button',
  );

  return (
    <Container
      stickyButtons={
        minScrollStickyButtons ? scroll >= minScrollStickyButtons : false
      }
      fixedStickyButtons={fixedStickyButtons}
      sidebarVisible={visible}
    >
      {hasImage && (logo || <img src={imagePlaceholder} alt="Placeholder" />)}
      <Details>
        <div className="mainDetails">
          <div>
            <h1>{title}</h1>
            <Tags>{tags}</Tags>
            {statusTags.length > 0 ? (
              <Statuses className="statuses">{statusTags}</Statuses>
            ) : null}
          </div>
          <DateInfo>
            {createdAt ? (
              <div>
                <p className="label">Created At:</p>
                {createdAt}
              </div>
            ) : null}

            {updatedAt ? (
              <div>
                <p className="label">Updated At:</p>
                {updatedAt}
              </div>
            ) : null}
          </DateInfo>
        </div>

        <ButtonsAndGridOptions
          stickyButtons={
            minScrollStickyButtons ? scroll >= minScrollStickyButtons : false
          }
          fixedStickyButtons={fixedStickyButtons}
          sidebarVisible={visible}
        >
          <Buttons>
            <div>
              {mainButtons}
              {secondaryButtons}
            </div>
            <div>
              {historyButton}
              <div className="destructive-buttons">{destructiveButtons}</div>
            </div>
          </Buttons>

          {gridSearch ||
          gridMultiSelectPanel ||
          gridAdvancedFiltersButton ||
          massiveActionButton ? (
            <GridOptions>
              {gridSearch ? (
                <div className="search">
                  <BsSearch size={19} />
                  {gridSearch}
                </div>
              ) : null}
              {gridAdvancedFiltersButton}
              {gridAppliedFiltersButton}
              {gridAdvancedFiltersForm}
              {exportXlsxButton}
              {xlsSplitButton}
              {massiveActionButton}
              {gridMultiSelectPanel}
            </GridOptions>
          ) : null}
          {headerCollapse}
        </ButtonsAndGridOptions>
      </Details>
    </Container>
  );
};

export default PageHeader;
