import { IRoleEntityField } from '../interfaces/IRoleEntityField';
import getFieldPermission, {
  IFieldPermissionResponse,
} from './getFieldPermission';

// eslint-disable-next-line no-shadow
export enum FieldPermissionEnum {
  EDIT = 'edit',
  CREATE = 'create',
}

/**
 * Verifica se campo deve ser desabilitado de acordo com permissao do usuario
 * @param idField ID do Field
 * @param roleEntityFields Permissoes de fields do usuario
 * @param permission Permissao de edicao ou criacao
 * @returns Se campo deve ser desabilitado
 */
export function disableField(
  idField: number,
  roleEntityFields: IRoleEntityField[],
  permission: FieldPermissionEnum,
): boolean {
  return !getFieldPermission(idField, roleEntityFields)[permission];
}

/**
 * Define a permissao com base na existencia do ID do item e se campo deve ser desabilitado de acordo com permissao do usuario
 * @param idField ID do Field
 * @param roleEntityFields Permissoes de fields do usuario
 * @param idItem ID do Item a ser validado para permissao de edicao ou criacao
 * @returns Se campo deve ser desabilitado
 */
export function definePermissionAndDisableField(
  idField: number,
  roleEntityFields: IRoleEntityField[],
  idItem?: number | string,
): boolean {
  return typeof idItem === 'number'
    ? // Se ID do item existir, permissao deve ser de edicao
      !getFieldPermission(idField, roleEntityFields).edit
    : // Se ID do item nao existir, permissao deve ser de criacao
      !getFieldPermission(idField, roleEntityFields).create;
}

/**
 * Valida se campo deve ser desabilitado
 * @param fieldPermission Permissoes do field
 * @param idItem ID do Item a ser validado para permissao de edicao ou criacao
 * @returns Se campo deve ser desabilitado
 */
export function shouldDisableField(
  fieldPermission: IFieldPermissionResponse,
  idItem?: number | string,
): boolean {
  return typeof idItem === 'number'
    ? // Se ID do item existir, permissao deve ser de edicao
      !fieldPermission.edit
    : // Se ID do item nao existir, permissao deve ser de criacao
      !fieldPermission.create;
}
