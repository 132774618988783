/**
 * Busca apenas os valores numericos de um Enum
 * @param enumObj Objeto de Enum
 * @returns Valores numericos do Enum
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getEnumNumericValues(enumObj: any): number[] {
  const enumValues = Object.values(enumObj);

  const numericValues = enumValues.filter(
    value => typeof value === 'number',
  ) as number[];

  return numericValues;
}
