import { ColumnData } from './interfaces';

/**
 * Cria objecto que define todas as linhas da tabela como true
 * para uso em selecao, edicao, expanded, etc
 * @param dataKey Valor definido em dataKey do componente de grid/datatable
 * @param items Itens da tabela
 * @returns Retorna um objeto que define todas as linhas da tabela como true
 */
export function getAllRowsAsTrue(
  dataKey: string,
  items?: any[],
): Record<string, boolean> {
  const editingRows: Record<string, boolean> = {};

  if (items) {
    items.forEach(item => {
      if (item[dataKey]) {
        editingRows[item[dataKey]] = true;
      }
    });
  }

  return editingRows;
}

/**
 * Filtra apenas colunas do local storage permitidas para visualizacao do usuario
 * @param localStorageColumns Colunas resgatadas do Local Storage
 * @param originalColumns Colunas sem alteracao de selecao do usuario
 * @returns Apenas colunas permitidas pelas roles
 */
export function filterOnlyAllowedColumnsFromLocalStorage(
  localStorageColumns: ColumnData[],
  originalColumns: ColumnData[],
): ColumnData[] {
  const allowedColumnsFields = new Set(
    originalColumns.map(column => column.field),
  );

  return localStorageColumns.filter(lsColumn =>
    allowedColumnsFields.has(lsColumn.field),
  );
}
