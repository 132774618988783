import React, { useEffect, useRef } from 'react';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { useField } from '@unform/core';

// Interface das propriedades do componente
interface InputProps extends InputTextProps {
  name: string;
}

export const Input: React.FC<InputProps> = ({ name, prefix, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  // Registra o campo
  useEffect(() => {
    registerField({
      name: fieldName, // Nome do campo
      ref: inputRef.current, // Referência
      path: 'value', // Caminho
    });
  }, [fieldName, registerField]);

  return (
    <>
      {/* Componente input de texto */}
      {prefix && prefix}
      <InputText
        {...rest} // Propriedades padrão do componente
        defaultValue={defaultValue} // Valor inicial padrão
        ref={inputRef} // Referêcia ao input
        className={error ? 'p-invalid block' : 'block'} // Define classe de erro se houver
      />

      {/* Se houver erro, exibe texto indicativo */}
      {error && (
        <small id="username2-help" className="p-error block">
          {error}
        </small>
      )}
    </>
  );
};

export default Input;
