import React from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Container } from './styles';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import { useRefHook } from '../../../hooks/useRefHook';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import getValidationErrors from '../../../utils/getValidationErrors';
import { IListRoleResponse } from '..';

interface IGeneralInformationProps extends PageTabContainerProps {
  role?: IListRoleResponse;
}

interface IFormData {
  name: string;
  active: boolean;
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  role,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  async function handleSubmit(data: IFormData) {
    try {
      // Esvazia possíveis erros já existentes no formulário
      formRef.current?.setErrors({});

      // Define requisitos de preenchimento do formulario
      const schema = Yup.object().shape({
        name: Yup.string().required('Enter a name'),
      });

      // Efetua validação
      await schema.validate(data, { abortEarly: false });
    } catch (error) {
      // Verifica se são erros de validação
      if (error instanceof Yup.ValidationError) {
        // Pega os erros de cada input
        const errors = getValidationErrors(error);

        // Define os erros para cada input
        formRef.current?.setErrors(errors);
      }
    }
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={role}>
          <h1>General Information</h1>

          <FormInput name="name" label="Name" required />

          <FormCheckbox
            name="active"
            label="Active"
            className="activeCheckbox"
            initialValue={role ? role.active : true}
          />
        </Form>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
