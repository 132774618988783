/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';

import { Avatar, AvatarProps } from 'primereact/avatar';
/**
 * Propriedades do componente
 */
interface RoundUserAvatarProps extends AvatarProps {
  /**
   * Nome completo do usuario
   */
  userFullName: string;
}

/**
 * Componente de imagem do usuario
 * @returns
 */
const RoundUserAvatar: React.FC<RoundUserAvatarProps> = ({
  image,
  className,
  userFullName,
  ...rest
}) => {
  const [userInitials, setUserInitials] = useState<string>();

  // Busca iniciais do usuario de acordo com nome informado
  useEffect(() => {
    const img = new Image();
    img.src = image ?? '';
    img.onerror = function getUserInitials() {
      const fullName = userFullName.split(' ');
      const initials = fullName.shift()!.charAt(0) + fullName.pop()?.charAt(0);
      setUserInitials(initials.toUpperCase());
    };
  }, [image, userFullName]);

  return (
    <Avatar
      {...rest}
      image={image}
      label={userInitials}
      style={
        userInitials
          ? { backgroundColor: 'var(--blue)', color: 'var(--white)' }
          : {}
      }
      className={`font-bold ${className ?? ''}`}
      shape="circle"
    />
  );
};

export default RoundUserAvatar;
