import styled from 'styled-components';

interface WrapperProps {
  menuWidth: string;
  menuMarginBottom: string;
}

interface ContainerProps {
  stickyMenu: boolean;
  menuFlexDirection: string;
  stickyMenuTopDistance: string;
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: ${props => props.menuWidth};
  margin-bottom: ${props => props.menuMarginBottom};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => props.menuFlexDirection};

  ${props =>
    props.stickyMenu
      ? `position: fixed;
        top: ${props.stickyMenuTopDistance};
        z-index:10;`
      : ''};
`;
