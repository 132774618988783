import { SortOrder } from 'primereact/datatable';
import { IProductImage } from '../interfaces';
import { IMutationResponse } from '../../../../components/MutationResponseSummary';

export type ProductImagesLazyParams = {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: SortOrder;
};

// eslint-disable-next-line no-shadow
export enum ProductAttachmentsReducerActionKind {
  SET_PRODUCT_ATTACHMENTS,
  SET_SELECTED_PRODUCT_ATTACHMENTS,
  SET_PRODUCT_ATTACHMENTS_TO_UPDATE,
  CLEAN_PRODUCT_ATTACHMENTS_RELATED_DATA,
}

export type ProductAttachmentsReducer = {
  productAttachments: IProductImage[];
  selectedProductAttachments: IProductImage[];
  productAttachmentsToUpdate: Set<number>;
};

export type ProductAttachmentToUpdate = {
  idProductImage: number;
  productImageTypes: string[];
};

export type ProductAttachmentsReducerAction = {
  type: ProductAttachmentsReducerActionKind;
  payload?: {
    productAttachments?: IProductImage[];
    idProductImageUpdated?: number;
    selectedProductAttachments?: IProductImage[];
  };
};

export type AttachmentsRef = {
  handleUpdateAttachments(): Promise<IMutationResponse | undefined>;
};
