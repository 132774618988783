import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { productRoles } from '../../../../shared/roles/product';
import getFieldPermission from '../../../../utils/getFieldPermission';
import {
  PackageBarcodeFieldsPermissions,
  PackageDescriptionFieldsPermissions,
  PackageDetailsFieldsPermissions,
  PackageReferenceFieldsPermissions,
} from './interfaces';

export const packageDescriptionFieldsPermissions = (
  userFields: IRoleEntityField[],
): PackageDescriptionFieldsPermissions => {
  return {
    idPackageDescription: getFieldPermission(
      productRoles.fields.idFieldSellingPackageDescription,
      userFields,
    ),
    idResponsible: getFieldPermission(
      productRoles.fields.idFieldResponsible,
      userFields,
    ),
    comments: getFieldPermission(
      productRoles.fields.idFieldComments,
      userFields,
    ),
  };
};

export const packageDetailsFieldsPermissions = (
  userFields: IRoleEntityField[],
): PackageDetailsFieldsPermissions => {
  return {
    idTypePackage: getFieldPermission(
      productRoles.fields.idFieldTypePackage,
      userFields,
    ),
    isDefault: getFieldPermission(
      productRoles.fields.idFieldSellingPackageDefault,
      userFields,
    ),
    packageCostPrice: getFieldPermission(
      productRoles.fields.idFieldSellingPackageCostPrice,
      userFields,
    ),
    packageUnit: getFieldPermission(
      productRoles.fields.idFieldSellingPackageUnit,
      userFields,
    ),
    packagePcQuantity: getFieldPermission(
      productRoles.fields.idFieldSellingPackageQty,
      userFields,
    ),
    packageLength: getFieldPermission(
      productRoles.fields.idFieldSellingPackageLength,
      userFields,
    ),
    packageWidth: getFieldPermission(
      productRoles.fields.idFieldSellingPackageWidth,
      userFields,
    ),
    packageHeight: getFieldPermission(
      productRoles.fields.idFieldSellingPackageHeight,
      userFields,
    ),
    packageGrossWeight: getFieldPermission(
      productRoles.fields.idFieldSellingPackageGrossWeight,
      userFields,
    ),
    innerQuantity: getFieldPermission(
      productRoles.fields.idFieldInnerBoxUnSetPerIner,
      userFields,
    ),
    innerLength: getFieldPermission(
      productRoles.fields.idFieldInnerBoxLength,
      userFields,
    ),
    innerWidth: getFieldPermission(
      productRoles.fields.idFieldInnerBoxWidth,
      userFields,
    ),
    innerHeight: getFieldPermission(
      productRoles.fields.idFieldInnerBoxHeight,
      userFields,
    ),
    innerGrossWeight: getFieldPermission(
      productRoles.fields.idFieldInnerBoxGrossWeight,
      userFields,
    ),
    isInnerDisplay: getFieldPermission(
      productRoles.fields.idFieldInnerBoxDisplay,
      userFields,
    ),
    innerBox: getFieldPermission(
      productRoles.fields.idFieldMasterBoxInnerPerCtn,
      userFields,
    ),
    masterQuantity: getFieldPermission(
      productRoles.fields.idFieldMasterBoxUnSetPerCtn,
      userFields,
    ),
    masterLength: getFieldPermission(
      productRoles.fields.idFieldMasterBoxLength,
      userFields,
    ),
    masterWidth: getFieldPermission(
      productRoles.fields.idFieldMasterBoxWidth,
      userFields,
    ),
    masterHeight: getFieldPermission(
      productRoles.fields.idFieldMasterBoxHeight,
      userFields,
    ),
    masterCbm: getFieldPermission(
      productRoles.fields.idFieldMasterBoxCbm,
      userFields,
    ),
    masterNetWeight: getFieldPermission(
      productRoles.fields.idFieldMasterBoxNetWeight,
      userFields,
    ),
    masterGrossWeight: getFieldPermission(
      productRoles.fields.idFieldMasterBoxGrossWeight,
      userFields,
    ),
    palletQuantity: getFieldPermission(
      productRoles.fields.idFieldPalletUnSetPerInner,
      userFields,
    ),
    palletLength: getFieldPermission(
      productRoles.fields.idFieldPalletLength,
      userFields,
    ),
    palletWidth: getFieldPermission(
      productRoles.fields.idFieldPalletWidth,
      userFields,
    ),
    palletHeight: getFieldPermission(
      productRoles.fields.idFieldPalletHeight,
      userFields,
    ),
    palletGrossWeight: getFieldPermission(
      productRoles.fields.idFieldPalletGrossWeight,
      userFields,
    ),
    palletDisplay: getFieldPermission(
      productRoles.fields.idFieldPalletDisplay,
      userFields,
    ),
    packageNote: getFieldPermission(
      productRoles.fields.idFieldPackageNote,
      userFields,
    ),
  };
};

export const packageBarcodeFieldsPermissions = (
  userFields: IRoleEntityField[],
): PackageBarcodeFieldsPermissions => {
  return {
    idClient: getFieldPermission(
      productRoles.fields.idFieldBarcodeClient,
      userFields,
    ),
    packageEan13: getFieldPermission(
      productRoles.fields.idFieldBarcodeSellingPackage,
      userFields,
    ),
    innerDun14: getFieldPermission(
      productRoles.fields.idFieldBarcodeInnerBox,
      userFields,
    ),
    masterDun14: getFieldPermission(
      productRoles.fields.idFieldBarcodeMasterBox,
      userFields,
    ),
    auto: getFieldPermission(
      productRoles.fields.idFieldBarcodeAuto,
      userFields,
    ),
  };
};

export const packageReferenceFieldsPermissions = (
  userFields: IRoleEntityField[],
): PackageReferenceFieldsPermissions => {
  return {
    colourEn: getFieldPermission(
      productRoles.fields.idFieldProductPackageReferencesColourEn,
      userFields,
    ),
    colourPt: getFieldPermission(
      productRoles.fields.idFieldProductPackageReferencesColourPt,
      userFields,
    ),
    idGender: getFieldPermission(
      productRoles.fields.idFieldProductPackageReferencesIdGender,
      userFields,
    ),
    idSizeGroup: getFieldPermission(
      productRoles.fields.idFieldProductPackageReferencesIdSizeGroup,
      userFields,
    ),
    masterBarcode: getFieldPermission(
      productRoles.fields.idFieldProductPackageReferencesMasterBarcode,
      userFields,
    ),
    unit: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesUnit,
      userFields,
    ),
    size: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesSize,
      userFields,
    ),
    reference: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesReference,
      userFields,
    ),
    sizeRange: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesSizeRange,
      userFields,
    ),
    sellingPackage: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesSellingPackage,
      userFields,
    ),
    auto: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesAuto,
      userFields,
    ),
    innerBox: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesInnerBox,
      userFields,
    ),
    masterBox: getFieldPermission(
      productRoles.fields.idFieldProductPackageSizeBarcodesMasterBox,
      userFields,
    ),
  };
};
