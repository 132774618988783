/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import DynamicIcon from '../DynamicIcon';

interface Option {
  id: string | number;
  label: string;
  icon: string;
}

interface Props {
  name: string;
  options: Option[];
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Radio: React.FC<InputProps> = ({
  name,
  className,
  options,
  onChange,
}) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: { checked: boolean }) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref: { value: number }) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
      clearValue(refs: any[]) {
        const item = refs.find((ref: { checked: boolean }) => ref.checked);
        if (item) {
          item.checked = false;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className={className}>
      {options.map((option, index) => (
        <label key={option.id}>
          <input
            ref={elRef => (inputRefs.current[index] = elRef)}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
            onChange={e => {
              if (onChange) {
                onChange(e);
              }
            }}
          />
          {option.icon && <DynamicIcon icon={`sat/${option.icon}`} size="30" />}
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default Radio;
