/* eslint-disable no-shadow */
import React from 'react';

import { Container } from './styles';

type JustifyContent = 'space-between';

/**
 * Props do componente de Form Row
 */
interface IFormRowProps {
  justifyContent?: JustifyContent;
}

// Componente de linha para formulario
const FormRow: React.FC<IFormRowProps> = ({ children, justifyContent }) => {
  return (
    <Container className="form-row" justifyContent={justifyContent}>
      {children}
    </Container>
  );
};

export default FormRow;
