import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
`;

export const ActionButtons = styled.div`
  display: flex;
  button {
    margin-right: 8px;
  }
`;
