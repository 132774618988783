/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import MainButton from '../../components/MainButton';
import Button from '../../components/Button';
import PageHeader from '../../components/PageHeader';
import Image from '../../components/PageHeader/Image';
import imagePlaceholder from '../../assets/imagePlaceholder.svg';
import { Container, CrudForm } from './styles';
import { useRefHook } from '../../hooks/useRefHook';
import PageMenuButton from '../../components/PageMenuButton';
import GeneralInformation from './GeneralInformation';
import BreadCrumb, { IBreadCrumbItem } from '../../components/BreadCrumb';
import Tag from '../../components/Tag';
import Loading from '../../components/Loading';
import Attachments from './Attachments';
import Branding from './Branding';
import Contact from './Contact';

import {
  ICreatePartnerFormData,
  IPartner,
  IPartnerContact,
  PartnerRouteParams,
  ISelectCategory,
} from './interfaces';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../utils/getUserFieldsAndPermissionsByEntity';
import { partnersListRoles } from '../../shared/roles/partners';
import { IRolesRef } from '../User/Roles';
import PageMenu from '../../components/PageMenu';
import ToastLife from '../../shared/enums/toastLife';
import useTitle from '../../hooks/useTitle';

const Partner: React.FC = () => {
  // ID da entity de usuario
  const { idEntity } = partnersListRoles;

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // ID do Partner
  const partnerId = parseInt(useParams<PartnerRouteParams>().partnerId, 10);

  const defaultPageTitle = `${partnerId ? 'Manage' : 'Create'} Partner`;

  const [partner, setPartner] = useState<IPartner>({} as IPartner);

  // Nome do Partner
  const [partnerName, setPartnerName] = useState('');

  useTitle(
    partner?.ptnNumber
      ? `${partner.ptnNumber} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const rolesRef = useRef<IRolesRef>(null);

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // URL da imagem
  const [imageUrl, setImageUrl] = useState('');

  // URL params
  const location = useLocation();

  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);

  const [partnerContacts, setPartnerContacts] = useState<IPartnerContact[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<ISelectCategory>();

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL temporaria para upload de arquivo
  const [uploadImageTempUrl, setUploadImageTempUrl] = useState('');

  // Dados para retornar para listagem de partner
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Partners',
      path:
        initialPage && initialFirst
          ? `/partners/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/partners/list',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] =
    useState('GeneralInformation');

  // Query para listar partner
  const listPartnerQuery = gql`
    query listPartnerQuery($idPartner: Int!) {
      listPartnerById(idPartner: $idPartner) {
        coresString
        idPartner
        ptnNumber
        active
        courier
        courier2 {
          idDomain
          description
        }
        versionLock
        name
        formalName
        address
        zipCode
        idTax
        registrationNumber
        value
        paymentTerm
        indInlandTransportsOrigin
        indInlandTransportsDestination
        indFreightForwarderOrigin
        indFreightForwarderDestination
        indCustomsBroker
        indAudit
        indWarehouse
        indInsuranceCompany
        indRepresentative
        indFinancialServices
        indShipowner
        indCourier
        indCertifyingEntity
        indLicensor
        indOthers
        idCountry
        idCountry2 {
          idCountry
          name
        }
        idRegion
        idRegion2 {
          idRegion
          name
        }
        idCity
        idCity2 {
          idCity
          name
        }
        idImage
        imageUrl
        partnerContacts {
          idPartnerContact
          name
          idRole
          idDepartment
          email
          telephone
          idRole2 {
            idDomain
            description
          }
          idDepartment2 {
            idDepartment
            name
          }
        }
      }
    }
  `;

  /**
   * Busca dados do partner
   */
  const [loadPartnerData, { loading: partnerLoading, data: partnerData }] =
    useLazyQuery(listPartnerQuery, {
      onCompleted: response => {
        if (response.listPartnerById) {
          setPartner(response.listPartnerById);
          setPartnerName(response.listPartnerById.name);
          setImageUrl(response.listPartnerById.imageUrl);
          setPartnerContacts(response.listPartnerById.partnerContacts);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Partner not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting partner data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/partners/list'),
    });
  }

  // Mutation de criacao de partner
  const createPartnerQuery = gql`
    mutation CreatePartnerMutation($partner: CreatePartnerInput) {
      createPartner(partner: $partner) {
        idPartner
      }
    }
  `;

  // Mutation de atualizacao de partner
  const updatePartnerQuery = gql`
    mutation UpdatePartnerMutation($partner: UpdatePartnerInput!) {
      updatePartner(partner: $partner) {
        idPartner
      }
    }
  `;

  // cria método para chamar a mutation
  const [createPartnerMutation] = useMutation(createPartnerQuery);
  const [updatePartnerMutation] = useMutation(updatePartnerQuery);

  // Submit do formulario de criacao de Partner
  const handleCreatePartnerSubmit = useCallback(
    async (data: ICreatePartnerFormData) => {
      if (!shouldSubmit) return;
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        if (
          !formRef.current?.getFieldValue('indInlandTransportsOrigin') &&
          !formRef.current?.getFieldValue('indInlandTransportsDestination') &&
          !formRef.current?.getFieldValue('indFreightForwarderOrigin') &&
          !formRef.current?.getFieldValue('indFreightForwarderDestination') &&
          !formRef.current?.getFieldValue('indCustomsBroker') &&
          !formRef.current?.getFieldValue('indAudit') &&
          !formRef.current?.getFieldValue('indWarehouse') &&
          !formRef.current?.getFieldValue('indInsuranceCompany') &&
          !formRef.current?.getFieldValue('indRepresentative') &&
          !formRef.current?.getFieldValue('indFinancialServices') &&
          !formRef.current?.getFieldValue('indShipowner') &&
          !formRef.current?.getFieldValue('indCourier') &&
          !formRef.current?.getFieldValue('indCertifyingEntity') &&
          !formRef.current?.getFieldValue('indLicensor') &&
          !formRef.current?.getFieldValue('indOthers')
        ) {
          formRef.current?.setFieldError(
            'indInlandTransportsOrigin',
            'Core is required',
          );

          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Enter Core fields',
            detail: `Core fields are required`,
            life: ToastLife.WARN,
          });
          return;
        }

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Required Field'),
          formalName: Yup.string().required('Required Field'),
          idTax: Yup.string().required('Required Field'),
          country: Yup.object()
            .shape({
              idCountry: Yup.number(),
              name: Yup.string(),
            })
            .nullable()
            .required('Enter a country'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valida se esta editando ou criando um novo Partner
        if (!partnerId) {
          // Transforma objeto de partner contacts para o backend
          const parsedPartnerContacts = partnerContacts.map(partnerContact => {
            return {
              name: partnerContact.name,
              telephone: partnerContact.telephone,
              email: partnerContact.email,
              idRole: partnerContact.idRole2?.idDomain,
              idDepartment: partnerContact.idDepartment2?.idDepartment,
            };
          });

          // Chamada criacao de Partner
          await createPartnerMutation({
            variables: {
              partner: {
                partnerContacts: parsedPartnerContacts,
                name: data.name,
                formalName: data.formalName,
                courier: data.courier.idDomain,
                idCountry: data.country.idCountry,
                idRegion: data.region.idRegion,
                idCity: data.cityName.idCity,
                active: 1,
                idTax: data.idTax,
                address: data.address,
                zipCode: data.zipCode,
                registrationNumber: data.registrationNumber,
                value: data.value,
                paymentTerm: data.paymentTerm,
                indInlandTransportsOrigin: data.indInlandTransportsOrigin
                  ? 1
                  : 0,
                indInlandTransportsDestination:
                  data.indInlandTransportsDestination ? 1 : 0,
                indFreightForwarderOrigin: data.indFreightForwarderOrigin
                  ? 1
                  : 0,
                indFreightForwarderDestination:
                  data.indFreightForwarderDestination ? 1 : 0,
                indCustomsBroker: data.indCustomsBroker ? 1 : 0,
                indAudit: data.indAudit ? 1 : 0,
                indWarehouse: data.indWarehouse ? 1 : 0,
                indInsuranceCompany: data.indInsuranceCompany ? 1 : 0,
                indRepresentative: data.indRepresentative ? 1 : 0,
                indFinancialServices: data.indFinancialServices ? 1 : 0,
                indShipowner: data.indShipowner ? 1 : 0,
                indCourier: data.indCourier ? 1 : 0,
                indCertifyingEntity: data.indCertifyingEntity ? 1 : 0,
                indLicensor: data.indLicensor ? 1 : 0,
                indOthers: data.indOthers ? 1 : 0,
                versionLock: 0,
                idImage: partner.idImage,
              },
            },
          });
        } else {
          // Transforma objeto de partner contacts para o backend
          const parsedPartnerContacts = partnerContacts.map(partnerContact => {
            return {
              idPartnerContact:
                typeof partnerContact.idPartnerContact === 'number'
                  ? partnerContact.idPartnerContact
                  : undefined,
              name: partnerContact.name,
              telephone: partnerContact.telephone,
              email: partnerContact.email,
              idRole: partnerContact.idRole2?.idDomain,
              idDepartment: partnerContact.idDepartment2?.idDepartment,
            };
          });

          // Chamada atualizacao de partner
          await updatePartnerMutation({
            variables: {
              partner: {
                idPartner: partnerId,
                versionLock: partner.versionLock,
                partnerContacts: parsedPartnerContacts,
                name: data.name,
                formalName: data.formalName,
                courier: data.courier.idDomain,
                idCountry: data.country.idCountry,
                idRegion: data.region.idRegion,
                idCity: data.cityName.idCity,
                idTax: data.idTax,
                address: data.address,
                zipCode: data.zipCode,
                registrationNumber: data.registrationNumber,
                value: data.value,
                paymentTerm: data.paymentTerm,
                indInlandTransportsOrigin: data.indInlandTransportsOrigin
                  ? 1
                  : 0,
                indInlandTransportsDestination:
                  data.indInlandTransportsDestination ? 1 : 0,
                indFreightForwarderOrigin: data.indFreightForwarderOrigin
                  ? 1
                  : 0,
                indFreightForwarderDestination:
                  data.indFreightForwarderDestination ? 1 : 0,
                indCustomsBroker: data.indCustomsBroker ? 1 : 0,
                indAudit: data.indAudit ? 1 : 0,
                indWarehouse: data.indWarehouse ? 1 : 0,
                indInsuranceCompany: data.indInsuranceCompany ? 1 : 0,
                indRepresentative: data.indRepresentative ? 1 : 0,
                indFinancialServices: data.indFinancialServices ? 1 : 0,
                indShipowner: data.indShipowner ? 1 : 0,
                indCourier: data.indCourier ? 1 : 0,
                indCertifyingEntity: data.indCertifyingEntity ? 1 : 0,
                indLicensor: data.indLicensor ? 1 : 0,
                indOthers: data.indOthers ? 1 : 0,
                idImage: partner.idImage,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Partner ${partnerId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de Partners
        history.push('/partners/list');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Define guia selecionada de acordo com input com erro
          switch (true) {
            case firstError.path?.includes('name'):
              // case firstError.path?.includes('idCountry'):
              setSelectedMenuItem('GeneralInformation');
              break;
            case firstError.path?.includes('partnerPackages'):
              setSelectedMenuItem('Attachments');
              break;
            case firstError.path?.includes('organName'):
              setSelectedMenuItem('Branding');
              break;
            case firstError.path?.includes('partnerSuppliers'):
              setSelectedMenuItem('Contact');
              break;
            default:
              break;
          }

          if (firstError.path === 'country') {
            firstError.path = 'name';
          }

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);
          // Define foco no input
          inputWithError.focus();

          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Enter all required fields',
            detail: `${error.errors.length} fields with errors`,
            life: ToastLife.WARN,
          });
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              partnerId ? 'updating' : 'creating'
            } partner`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
        setShouldSubmit(false);
      }
    },
    [
      createPartnerMutation,
      formRef,
      history,
      partner.versionLock,
      partner.idImage,
      partnerId,
      partnerContacts,
      shouldSubmit,
      toastRef,
      updatePartnerMutation,
    ],
  );

  const changePropertyValue = (property: string, value: any) => {
    setPartner(prevPartner => ({
      ...prevPartner,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (partnerId) {
      loadPartnerData({
        variables: {
          idPartner: partnerId,
        },
      });
    }
    return () => setPartner({} as IPartner);
  }, [loadPartnerData, partnerId]);

  useEffect(() => {
    if (shouldSubmit) {
      formRef.current?.submitForm();
    }
  }, [formRef, shouldSubmit]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          partnerName
            ? `${defaultPageTitle} - ${partnerName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
        hasImage
      >
        {partnerData ? (
          partner.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}

        {!!partnerId && partner.taxmissing && (
          <Tag className="tag" value="Tax Missing" />
        )}

        {!!partnerId && partner.outdated && (
          <Tag className="tag" value="Outdated" severity="info" />
        )}

        {!!partnerId && partner.verified && (
          <Tag className="tag" value="Verified" severity="success" />
        )}

        <Image
          className="image"
          src={
            !uploadImageTempUrl
              ? imageUrl || imagePlaceholder
              : uploadImageTempUrl
          }
          alt="Partner main image"
        />
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            setShouldSubmit(true);
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {((partnerId && !partnerLoading && partner.active !== undefined) ||
        !partnerId) && (
        <CrudForm>
          <PageMenu menuWidth="183px">
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('GeneralInformation');
              }}
              selected={selectedMenuItem === 'GeneralInformation'}
              title="General Information"
            />

            {!!partnerId && (
              <>
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Attachments');
                  }}
                  selected={selectedMenuItem === 'Attachments'}
                  title="Attachments"
                />
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Branding');
                  }}
                  selected={selectedMenuItem === 'Branding'}
                  title="Branding"
                />
              </>
            )}

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Contact');
              }}
              selected={selectedMenuItem === 'Contact'}
              title="Contact"
            />
          </PageMenu>
          <Form
            ref={formRef}
            initialData={partner}
            onSubmit={handleCreatePartnerSubmit}
          >
            <GeneralInformation
              selected={selectedMenuItem === 'GeneralInformation'}
              partner={partner}
              changePropertyValue={changePropertyValue}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              rolesRef={rolesRef}
              roleEntityFields={userPermissions.userFields}
            />
            {!!partnerId && (
              <>
                <Attachments
                  selected={selectedMenuItem === 'Attachments'}
                  changePropertyValue={changePropertyValue}
                  idMainImage={partner.idImage}
                  setUploadImageTempUrl={setUploadImageTempUrl}
                  idPartner={partnerId}
                />
                <Branding
                  selected={selectedMenuItem === 'Branding'}
                  idPartner={partnerId}
                />
              </>
            )}

            <Contact
              selected={selectedMenuItem === 'Contact'}
              partnerContacts={partnerContacts}
              setPartnerContacts={setPartnerContacts}
            />
          </Form>
        </CrudForm>
      )}
      {(partnerLoading || loading) && <Loading />}
    </Container>
  );
};

export default Partner;
