/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { useRefHook } from '../../../hooks/useRefHook';
import { Container, CrudForm } from './styles';

import {
  queryToUpdate,
  queryToListIdDomain,
  queryToCreateDomain,
} from '../../../services/querysGql';
import { IGeneralInformationFormData } from '../../../services/dataInterface';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface NumberStoreRouteParams {
  numberStoreId: string;
}
const idGroupToNumberStoresClient = 8;

const NumberStore: React.FC = () => {
  // ID do cliente number of store
  const { numberStoreId } = useParams<NumberStoreRouteParams>();

  const defaultPageTitle = `${
    numberStoreId ? 'Manage' : 'Create'
  } Number of Stores`;

  // Nome da number of store
  const [numberStoreName, setNumberStoreName] = useState('');

  useTitle(
    numberStoreName
      ? `${numberStoreName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Estado da versão lock otimista
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Number of Store',
      path: '/clients/number-of-stores',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Carrega response do enumber of store
  const [
    loadNumberStoreData,
    {
      called: NumberStoreCalled,
      loading: NumberStoreLoading,
      data: NumberStoreData,
      error: NumberStoreError,
    },
  ] = useLazyQuery(queryToListIdDomain, {
    variables: {
      idDomain: parseInt(numberStoreId, 10),
    },
    onCompleted: async response => {
      // Valida se o number of store nao foi encontrado
      if (
        !response.listDomainById ||
        response.listDomainById.idGroup !== idGroupToNumberStoresClient
      ) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Client Number of Store not found',
          life: ToastLife.ERROR,
        });
        // Redireciona para listagem de number of store cadastrados
        history.push('/clients/number-of-stores');
        return;
      }
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listDomainById.versionLock);

      // Define novo nome da number of store
      setNumberStoreName(response.listDomainById.description);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Client Number of Store data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/clients/number-of-stores');
    },
  });

  // Ao carregar a pagina, carrega os Number of Store cadastrados
  useEffect(() => {
    if (numberStoreId) {
      setPageLoading(true);
      loadNumberStoreData();
    }
  }, [numberStoreId, loadNumberStoreData]);

  // cria método para chamar a mutation
  const [createNumberStore] = useMutation(queryToCreateDomain);
  const [updateNumberStore] = useMutation(queryToUpdate);

  // Submit para criação de um cliente Number of Store
  const handleCreateNumberStoreSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria um special-negotiation
        await createNumberStore({
          variables: {
            domainData: {
              description,
              idGroup: idGroupToNumberStoresClient,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client Number of Store created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem dos status
        history.push('/clients/number-of-stores');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);
          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating client number of store',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createNumberStore, toastRef, history],
  );

  const handleEditNumberStoreSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes do status
        const updateResponse = await updateNumberStore({
          variables: {
            domainData: {
              idDomain: parseInt(numberStoreId, 10),
              description,
              versionLock,
            },
          },
        });
        // atualiza estado de acordo com retorno do servidor
        setVersionLock(updateResponse.data.updateDomain.versionLock);

        // Define novo nome da number of store
        setNumberStoreName(description);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client number of store updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating client status',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, updateNumberStore, numberStoreId, versionLock, toastRef],
  );

  const handleCancel = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/clients/number-of-stores'),
    });
  };

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          NumberStoreData
            ? `${defaultPageTitle} - ${numberStoreName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            numberStoreId
              ? handleEditNumberStoreSubmit
              : handleCreateNumberStoreSubmit
          }
          initialData={
            NumberStoreCalled && !NumberStoreLoading && !NumberStoreError
              ? NumberStoreData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
      </CrudForm>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default NumberStore;
