import { gql } from '@apollo/client';

export const createSatContainerQuery = gql`
  mutation CreateSatContainer($data: CreateSatContainerInput!) {
    createSatContainer(data: $data) {
      idSatCtnr
    }
  }
`;

export const updateSatContainerQuery = gql`
  mutation UpdateSatContainer($data: UpdateSatContainerInput!) {
    updateSatContainer(data: $data) {
      idSatCtnr
    }
  }
`;
