/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import {
  NumericFormat,
  NumericFormatProps,
  useNumericFormat,
} from 'react-number-format';
import { Container } from './styles';
import { getCurrencySymbol } from '../../utils/getCurrencySymbol';

interface FormInputNumberProps extends NumericFormatProps {
  name: string;
  label: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  currency?: string;
  max?: number;
}

const FormInputNumber: React.FC<FormInputNumberProps> = ({
  name,
  required,
  label,
  className,
  disabled,
  onBlur,
  currency,
  prefix,
  decimalSeparator,
  max,
  onValueChange,
  readOnly,
  hidden,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [inputValue, setInputValue] = useState<string | number | null>(
    defaultValue || null,
  );
  const { removeFormatting } = useNumericFormat({ decimalSeparator, ...rest });

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  /**
   * Define valor maximo para o input
   * @returns
   */
  const withValueCap = (inputObj: any) => {
    const { value } = inputObj;
    if (value <= (max ?? 99999999)) return true;
    return false;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        if (!ref || ref.value === '') return null;

        if (type === 'text') return ref.value;

        if (!removeFormatting) return null;

        const value = parseFloat(removeFormatting(ref.value));

        if (Number.isNaN(value)) return null;

        return value;
      },
      setValue: (_, value: number | undefined | null) => {
        setInputValue(value === undefined || value === null ? '0' : value);
      },
      clearValue: () => {
        setInputValue(null);
      },
    });
  }, [decimalSeparator, fieldName, registerField, removeFormatting, type]);

  return (
    <div
      className={`${
        className
          ? `${className} form-element formInputNumber`
          : 'form-element formInputNumber'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        onClick={handleClick}
        disabled={disabled}
        readOnly={readOnly}
        hidden={hidden}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>

        <NumericFormat
          getInputRef={inputRef}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleInputFocus();
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (onBlur) onBlur(e);
            handleInputBlur();
          }}
          defaultValue={defaultValue}
          disabled={disabled}
          prefix={
            currency
              ? `${getCurrencySymbol(currency)} `
              : prefix
              ? `${prefix} `
              : undefined
          }
          decimalSeparator={decimalSeparator}
          onValueChange={(values, event) => {
            if (onValueChange) onValueChange(values, event);
            setInputValue(values.formattedValue);
          }}
          value={inputValue}
          fixedDecimalScale
          isAllowed={withValueCap}
          readOnly={readOnly}
          type={type}
          {...rest}
        />
      </Container>
      {error && (
        <small id="username2-help" className="p-error block">
          {error}
        </small>
      )}
    </div>
  );
};

export default FormInputNumber;
