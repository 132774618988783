/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ValidationError } from 'yup';

export const requiredFieldErrorMessage = 'Required field';

// Interface genérica para erros
interface Errors {
  [key: string]: string;
}

/*
 * @param {err} erros do Unform
 * @returns Erros de validaçao
 */
export default function getValidationErrors(err: ValidationError): Errors {
  // Array de erros
  const validationErrors: Errors = {};

  // Percorre erros do parâmetro
  err.inner.forEach(error => {
    // Cria propriedade com o caminho de cada erro e recebe a respectiva mensagem
    validationErrors[error.path!] = error.message;
  });

  return validationErrors;
}

/**
 * Busca qual indice de um array de objetos do formulario esta com erro de validacao
 * @param path Caminho do input/objeto com erro
 * @returns Indice que esta com erro de validacao
 */
export function getIndexPositionFromErrorPath(
  path: string,
): number | undefined {
  const result = path.match(/(?<=\[).+?(?=\])/g);

  if (result?.length) {
    const parsed = parseInt(result[0], 10);

    if (!Number.isNaN(parsed)) return parsed;
  }

  return undefined;
}
