export const followUpRoles = {
  idEntity: 52,
  idModule: 12,
  fields: {
    satNumber: 1798,
    shipment: 1799,
    clientOrder: 1800,
    mainProduct: 1801,
    importerConsignee: 1802,
    clientNotify: 1803,
    ctnrs: 1804,
    consolidatedWith: 1805,
    sellingIncoterm: 1806,
    totalSunPi: 1807,
    totalSunCiShipment: 1808,
    estimatedTotalBalance: 1809,
    totalBalanceReceived: 1810,
    statusSat: 1811,
    importStatus: 1813,
    registerStatus: 1812,
    artworkStatus: 2216,
    productionStatus: 1814,
    shipmentStatus: 2199,
    documentsStatus: 1816,
    clientPaymentStatus: 1815,
    realInspectionDate: 1817,
    estimatedShipmentDate: 1818,
    realEtd: 1819,
    estimatedArrivalDate: 1820,
    realEta: 1821,
    estimatedArrivalClientDate: 1822,
    realArrivalAtClient: 1823,
    followUpPt: 1824,
    clientDepartment: 1825,
    firstBuyer: 1826,
    secondBuyer: 1827,
    firstSalesUser: 1828,
    secondSalesUser: 1829,
    importUser: 1830,
  },
  permissions: {
    idPermissionExportSatsFollowUp: 182,
  },
};
