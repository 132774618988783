import { gql } from '@apollo/client';

export const rncDashboardQuery = gql`
  query GetSatRncProcessDashboard($data: GetSatRncProcessDashboardInput) {
    getSatRncProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatRnc
        rncNumber
        satNumber
        shipment
        mainProduct
        sunNumber
        clientNotify
        countryOrigin
        problemType
        deadline
        idCost
        categoryIcon
        category
      }
      items
    }
  }
`;
