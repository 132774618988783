import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;

  form {
    width: 100%;
  }
`;

export const GeneralInformation = styled.div`
  margin-left: 16px;
  width: 100%;

  .textInput + .form-dropdown,
  .form-dropdown + .form-dropdown,
  .form-dropdown + .small-input,
  .small-input + .small-input {
    margin-top: 7px;
  }

  .textInput {
    width: 650px;
  }

  .form-dropdown {
    width: 370px;
  }

  .small-input {
    width: 200px;
  }
`;
