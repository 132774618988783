import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import client from '../../../services/apollo/client';
import pagination from '../../../config/pagination';

export const asyncSelectLoadSearchAllOptions: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  // Separa cada palavra para pesquisar elas e trazer os resultados de cada
  const searchKeywords = search.split(' ');

  const res = await client.query({
    query: gql`
      query listSearchAllView($data: ListSearchAllViewInput!) {
        listSearchAllView(data: $data) {
          data {
            idEntity
            id
            description
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        allColumns: searchKeywords,
      },
    },
  });

  const filteredOptions = res.data.listSearchAllView.data;
  const quantity = res.data.listSearchAllView.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};
