import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
`;

export const SupplierGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-columns: 100px;
  gap: 16px;

  .fill-all {
    grid-column: 1/4;
  }

  .fill-from-second,
  .supplier-address {
    grid-column: 2/4;
    width: unset !important;
  }

  .fill-to-second {
    grid-column: 1/3;
  }

  .link {
    text-decoration: none;
    display: flex;
    align-items: center;

    .formInput {
      width: 100px;
    }

    a {
      text-decoration: none;
      color: var(--blue);
      &:hover {
        color: ${shade(0.2, '#4C85C8')};
      }

      svg {
        margin-left: 2px;
      }
    }
  }

  textarea {
    resize: vertical;
  }

  & + & {
    margin-top: 46px;
  }
`;

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  margin-bottom: 32px;

  svg {
    margin: 0 11px;
    color: var(--grey-scale);
  }

  button {
    font-size: 14px;
    background: none;
    border: none;
    color: var(--blue);
  }
`;
