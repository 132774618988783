/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import { Column } from 'primereact/column';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import xlsx from 'xlsx';
import AdvancedFiltersPanel from '../../components/AdvancedFiltersPanel';
import Button from '../../components/Button';
import MultiSelect from '../../components/Grid/MultiSelect';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';
import PageHeader from '../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../config/pagination';
import { IRoleUser, useAuth } from '../../hooks/useAuth';
import { useRefHook } from '../../hooks/useRefHook';
import useTitle from '../../hooks/useTitle';
import ILazyParams from '../../services/lazyParams';
import ToastLife from '../../shared/enums/toastLife';
import { userRoles } from '../../shared/roles/user';
import getFieldPermission from '../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';
import { gridColumnsData } from './constants';
import { DataTableMultiSortMetaType } from '../../components/Grid/interfaces';
import Grid, { GridRef } from '../../components/Grid';

/**
 * Interface de usuario
 */
interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  roleUsers: IRoleUser[];
  username: string;
  active: string;
  createdAt: Date;
  updatedAt: Date;
}

/**
 * Interface dos parametros do backend
 */
interface IUsersLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | Date
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  createdAt?: Date;
  updatedAt?: Date;
  active?: boolean;
}

const pageTitle = 'List of Users';

const Users: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:usersGridColumns';

  const lazyParamsName = '@SAT:usersLazyParams';

  const advancedFiltersName = '@SAT:usersAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<GridRef<any>>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // ID da entity de usuario
  const { idEntity } = userRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const { idFieldActive } = userRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, userPermissions.userFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    columnList.push(gridColumnsData.firstName);
    columnList.push(gridColumnsData.lastName);
    columnList.push(gridColumnsData.email);
    columnList.push(gridColumnsData.roles);
    columnList.push(gridColumnsData.username);
    if (showField(idFieldActive)) {
      columnList.push(gridColumnsData.active);
    }
    columnList.push(gridColumnsData.createdAt);
    columnList.push(gridColumnsData.updatedAt);

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: 0,
    rows: 25,
    page: 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IUsersLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IUsersLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IUsersLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar usuarios
  const listUsersQuery = gql`
    query listAllUsersGrid($listAllUsersInput: ListAllUsersInput) {
      listAllUsersGrid(listAllUsersInput: $listAllUsersInput) {
        data {
          idUser
          firstName
          lastName
          email
          roleUsers {
            idRoleUser
            idRole
            idRole2 {
              idRole
              name
            }
          }
          username
          ${showField(idFieldActive) ? 'active' : ''}
          createdAt
          updatedAt
        }
        items
      }
    }
  `;

  /**
   * Busca Usuarios
   */
  const {
    loading: usersLoading,
    data: usersData,
    error: usersError,
  } = useQuery(listUsersQuery, {
    variables: {
      listAllUsersInput: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        globalSearch: lazyParams.globalFilter,
        email: lazyParams.email,
        username: lazyParams.username,
        firstName: lazyParams.firstName,
        lastName: lazyParams.lastName,
        updatedAt: lazyParams.updatedAt,
        createdAt: lazyParams.createdAt,
        active:
          lazyParams.active === null || lazyParams.active === undefined
            ? undefined
            : lazyParams.active === true
            ? 'S'
            : 'N',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting users',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Define os produtos no estado
   */
  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Colunas com informacoes que direcionam para outra pagina
   * @param rowData Dados da linha
   * @returns Dado em forma de link
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseDateColumm = (rowData: IUser, field: any) => {
    const date =
      field.field === 'createdAt'
        ? new Date(rowData.createdAt).toLocaleString()
        : new Date(rowData.updatedAt).toLocaleString();

    return <p>{date}</p>;
  };

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseActiveColumn = (rowData: IUser) => {
    return <p>{rowData.active === 'S' ? 'True' : 'False'}</p>;
  };

  /**
   * Coluna de roles do usuário
   * @param rowData Dados da linha
   * @returns Campo com String de roles
   */
  const parseRolesColumn = (rowData: IUser) => {
    const rolesData: any = [];

    if (rowData.roleUsers) {
      rowData.roleUsers.forEach(role => {
        rolesData.push(role.idRole2.name);
      });
    }

    const response = rolesData.join(', ');

    return <p>{response}</p>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    if (field === 'createdAt' || field === 'updatedAt') return parseDateColumm;
    if (field === 'active') return parseActiveColumn;
    if (field === 'roles') return parseRolesColumn;
    return undefined;
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'active':
        return { width: '70px' };
      case 'updatedAt':
        return { width: '90px' };
      case 'createdAt':
        return { width: '90px' };
      case 'email':
        return { width: '250px', overflow: 'hidden' };
      default:
        return { width: '120px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      <Column
        key={col.field}
        // Valida o body da coluna (caso precise ser um link)
        body={handleColumn(col.field)}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'roles'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do usuario clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/users/${e.data.idUser}`);
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Salva arquivo como xlsx
   * @param buffer
   * @param fileName Nome do arquivo
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`,
    );
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(usersExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    usersExportData.listAllUsersGrid.data.forEach((user: IUser) => {
      columnsToRemove.forEach(column => {
        delete user[column.field as keyof IUser];
      });
      // Remover colunas __typename e idUser
      delete user['__typename' as keyof IUser];
      delete user['idUser' as keyof IUser];
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idUser' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      usersExportData.listAllUsersGrid.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveAsExcelFile(excelBuffer, 'users');
  }

  /**
   * Busca usuarios para exportar para XLSX
   */
  const [
    loadUsersToExport,
    { loading: userToExportLoading, data: usersToExportData },
  ] = useLazyQuery(listUsersQuery, {
    onCompleted: () => {
      exportToXlsx(usersToExportData);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while exporting users',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar usuario */}
        <MainButton
          className="mainButton"
          label="New User"
          onClick={() => history.push('/users/create')}
        />

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns}
          onChange={onColumnToggle}
        />

        {/* Botao export para XLSX */}
        <Button
          type="button"
          className="export-xlsx"
          label="Export Grid"
          loading={userToExportLoading}
          onClick={() => {
            loadUsersToExport({
              variables: {
                listAllUsersInput: {
                  _page: 0,
                  _limit: 0,
                  _orderBy: lazyParams.sortField,
                  _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                  globalSearch: lazyParams.globalFilter,
                  email: lazyParams.email,
                  username: lazyParams.username,
                  firstName: lazyParams.firstName,
                  lastName: lazyParams.lastName,
                  updatedAt: lazyParams.updatedAt,
                  createdAt: lazyParams.createdAt,
                  active: lazyParams.active,
                },
              },
            });
          }}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a user"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="users"
        lazy
        totalRecords={
          !usersData || usersError ? 0 : usersData.listAllUsersGrid?.items
        }
        value={
          !usersData || usersError
            ? undefined
            : usersData.listAllUsersGrid?.data
        }
        globalFilter={globalFilter}
        emptyMessage="No users found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode="single"
      >
        {dynamicColumns}
      </Grid>
      {usersLoading && <Loading />}
    </div>
  );
};
export default Users;
