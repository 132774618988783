/* eslint-disable no-shadow */
export enum ProcessType {
  REGISTER = 1,
  ARTWORK = 2,
  SUPPLIER_PAYMENT = 3,
  CLIENT_PAYMENT = 4,
  PRODUCTION = 5,
  SHIPMENT = 6,
  DOCUMENTS = 7,
  RNC = 8,
}

export enum SupplierPaymentStep {
  CONFIRMED_ORDER = 19,
  ADVANCE = 20,
  BALANCE = 21,
  SUPPLIERS_PAYMENT = 22,
}

export enum ProcessStepStatus {
  REGISTER_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1163,
  REGISTER_CONFIRMED_ORDER_DONE = 1164,
  REGISTER_PRODUCT_DETAILS_CHECKING = 1165,
  REGISTER_PRODUCT_DETAILS_UPDATE_MISSING_INFO = 1166,
  REGISTER_PRODUCT_DETAILS_DONE = 1167,
  REGISTER_BARCODES_DUPLICITY = 1168,
  REGISTER_BARCODES_MISSING = 1169,
  REGISTER_BARCODES_DONE = 1170,
  REGISTER_CLASSIFICACAO_FISCAL_MISSING = 1171,
  REGISTER_CLASSIFICACAO_FISCAL_REQUESTED = 1172,
  REGISTER_CLASSIFICACAO_FISCAL_DONE = 1173,
  REGISTER_HRP_MISSING = 1174,
  REGISTER_HRP_DONE = 1175,
  REGISTER_HRP_NOT_REQUIRED = 1176,
  REGISTER_CLIENT_REGISTER_MISSING = 1177,
  REGISTER_CLIENT_REGISTER_REQUESTED = 1178,
  REGISTER_CLIENT_REGISTER_DONE = 1179,
  REGISTER_CLIENT_REGISTER_NOT_REQUIRED = 1180,
  REGISTER_REGISTER_DONE_CANCELLED = 1181,
  REGISTER_REGISTER_DONE_STAND_BY = 1182,
  REGISTER_REGISTER_DONE_MISSING = 1183,
  REGISTER_REGISTER_DONE_DONE = 1184,
  SUPPLIER_PAYMENT_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1185,
  SUPPLIER_PAYMENT_CONFIRMED_ORDER_DONE = 1186,
  SUPPLIER_PAYMENT_ADVANCE_WAITING_PAYMENT_TERMS = 1187,
  SUPPLIER_PAYMENT_ADVANCE_ADVANCE_TO_PAY = 1188,
  SUPPLIER_PAYMENT_ADVANCE_ADVANCE_REQUESTED = 1189,
  SUPPLIER_PAYMENT_ADVANCE_NO_ADVANCE = 1190,
  SUPPLIER_PAYMENT_ADVANCE_ADVANCE_PAID = 1191,
  SUPPLIER_PAYMENT_ADVANCE_ROLLING_ADVANCE = 1192,
  SUPPLIER_PAYMENT_BALANCE_WAITING_PAYMENT_TERMS = 1193,
  SUPPLIER_PAYMENT_BALANCE_WAITING_COT_CI = 1194,
  SUPPLIER_PAYMENT_BALANCE_BALANCE_TO_PAY = 1195,
  SUPPLIER_PAYMENT_BALANCE_NO_BALANCE = 1196,
  SUPPLIER_PAYMENT_BALANCE_BALANCE_PAID = 1197,
  SUPPLIER_PAYMENT_SUPPLIERS_PAYMENT_CANCELLED = 1198,
  SUPPLIER_PAYMENT_SUPPLIERS_PAYMENT_STAND_BY = 1199,
  SUPPLIER_PAYMENT_SUPPLIERS_PAYMENT_REMAIN_COT = 1200,
  SUPPLIER_PAYMENT_SUPPLIERS_PAYMENT_DONE = 1201,
  CLIENT_PAYMENT_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1202,
  CLIENT_PAYMENT_CONFIRMED_ORDER_DONE = 1203,
  CLIENT_PAYMENT_ADVANCE_WAITING_PAYMENT_TERMS = 1204,
  CLIENT_PAYMENT_ADVANCE_ADVANCE_TO_RECEIVE = 1205,
  CLIENT_PAYMENT_ADVANCE_ADVANCE_REQUESTED = 1206,
  CLIENT_PAYMENT_ADVANCE_NO_ADVANCE = 1207,
  CLIENT_PAYMENT_ADVANCE_ADVANCE_RECEIVED = 1208,
  CLIENT_PAYMENT_ADVANCE_ROLLING_ADVANCE = 1209,
  CLIENT_PAYMENT_BALANCE_WAITING_PAYMENT_TERMS = 1210,
  CLIENT_PAYMENT_BALANCE_WAITING_SUN_CI = 1211,
  CLIENT_PAYMENT_BALANCE_BALANCE_TO_RECEIVE = 1212,
  CLIENT_PAYMENT_BALANCE_BALANCE_REQUESTED = 1213,
  CLIENT_PAYMENT_BALANCE_BALANCE_RECEIVED = 1214,
  CLIENT_PAYMENT_BALANCE_NO_BALANCE = 1215,
  CLIENT_PAYMENT_CLIENTS_PAYMENT_CANCELLED = 1216,
  CLIENT_PAYMENT_CLIENTS_PAYMENT_STAND_BY = 1217,
  CLIENT_PAYMENT_CLIENTS_PAYMENT_REMAIN_SUN = 1218,
  CLIENT_PAYMENT_CLIENTS_PAYMENT_DONE = 1219,
  PRODUCTION_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1220,
  PRODUCTION_CONFIRMED_ORDER_DONE = 1221,
  PRODUCTION_WAITING_SCHEDULE = 1222,
  PRODUCTION_WAITING_ADVANCE = 1223,
  PRODUCTION_WAITING_ARTWORK = 1224,
  PRODUCTION_WAITING_SAMPLE = 1225,
  PRODUCTION_WAITING_DONE = 1226,
  PRODUCTION_PRODUCTION_WAITING = 1227,
  PRODUCTION_PRODUCTION_ON_PRODUCTION = 1228,
  PRODUCTION_PRODUCTION_STOCK = 1229,
  PRODUCTION_PRODUCTION_DONE = 1230,
  PRODUCTION_INSPECTION_WAITING = 1231,
  PRODUCTION_INSPECTION_SCHEDULED = 1232,
  PRODUCTION_INSPECTION_INSPECTION_REPORT = 1233,
  PRODUCTION_INSPECTION_RECEIVED = 1234,
  PRODUCTION_INSPECTION_REWORK = 1235,
  PRODUCTION_INSPECTION_NO_REPORT = 1236,
  PRODUCTION_INSPECTION_APPROVED = 1237,
  PRODUCTION_PRODUCT_DONE_WAITING = 1238,
  PRODUCTION_PRODUCT_DONE_CANCELLED = 1239,
  PRODUCTION_PRODUCT_DONE_STAND_BY = 1240,
  PRODUCTION_PRODUCT_DONE_DONE = 1241,
  SHIPMENT_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1242,
  SHIPMENT_CONFIRMED_ORDER_DONE = 1243,
  SHIPMENT_SHIPMENT_AUTHORIZATION_SHIPMENT_DETAILS = 1244,
  SHIPMENT_SHIPMENT_AUTHORIZATION_WAITING = 1245,
  SHIPMENT_SHIPMENT_AUTHORIZATION_REQUESTED = 1246,
  SHIPMENT_SHIPMENT_AUTHORIZATION_APPROVED = 1247,
  SHIPMENT_SHIPMENT_AUTHORIZATION_NOT_REQUIRED = 1248,
  SHIPMENT_SHIPPING_INSTRUCTION_WAITING = 1249,
  SHIPMENT_SHIPPING_INSTRUCTION_SI_SENT = 1250,
  SHIPMENT_BOOKING_WAITING = 1251,
  SHIPMENT_BOOKING_CONFIRMED = 1252,
  SHIPMENT_SHIPMENT_WAITING_LI = 1253,
  SHIPMENT_SHIPMENT_WAITING = 1254,
  SHIPMENT_SHIPMENT_CONFIRMED = 1255,
  SHIPMENT_ARRIVAL_WAITING = 1256,
  SHIPMENT_ARRIVAL_CONFIRMED = 1257,
  SHIPMENT_SHIPMENT_DONE_CANCELLED = 1258,
  SHIPMENT_SHIPMENT_DONE_STAND_BY = 1259,
  SHIPMENT_SHIPMENT_DONE_WAITING = 1260,
  SHIPMENT_SHIPMENT_DONE_DONE = 1261,
  DOCUMENTS_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1262,
  DOCUMENTS_CONFIRMED_ORDER_DONE = 1263,
  DOCUMENTS_IMPORTANT_LICENSE_WAITING = 1264,
  DOCUMENTS_IMPORTANT_LICENSE_APPROVED = 1265,
  DOCUMENTS_BL_WAITING = 1266,
  DOCUMENTS_BL_DONE = 1267,
  DOCUMENTS_CIPL_CFR_FREIGHT_COST = 1268,
  DOCUMENTS_CIPL_COT_CI = 1269,
  DOCUMENTS_CIPL_WAITING_CTNR_ITEMS = 1270,
  DOCUMENTS_CIPL_DRAFTS = 1271,
  DOCUMENTS_CIPL_DRAFTS_REVIEW = 1272,
  DOCUMENTS_CIPL_APPROVED = 1273,
  DOCUMENTS_ORIGINAL_DOCS_WAITING_SIGNED_COPIES = 1274,
  DOCUMENTS_ORIGINAL_DOCS_BALANCE = 1275,
  DOCUMENTS_ORIGINAL_DOCS_WAITING = 1276,
  DOCUMENTS_ORIGINAL_DOCS_POSTED = 1277,
  DOCUMENTS_ORIGINAL_DOCS_RECEIVED = 1278,
  DOCUMENTS_ORIGINAL_DOCS_DELIVERED_TO_CLIENT = 1279,
  DOCUMENTS_DOCUMENTS_DONE_CANCELLED = 1280,
  DOCUMENTS_DOCUMENTS_DONE_STAND_BY = 1281,
  DOCUMENTS_DOCUMENTS_DONE_WAITING = 1282,
  DOCUMENTS_DOCUMENTS_DONE_DONE = 1283,
  ARTWORK_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1284,
  ARTWORK_CONFIRMED_ORDER_DONE = 1285,
  ARTWORK_REGISTER_WAITING = 1286,
  ARTWORK_REGISTER_BARCODES = 1287,
  ARTWORK_REGISTER_HRP = 1288,
  ARTWORK_REGISTER_DONE = 1289,
  ARTWORK_PRODUCT_INFO_FOR_AW_CHECKING = 1290,
  ARTWORK_PRODUCT_INFO_FOR_AW_MISSING = 1291,
  ARTWORK_PRODUCT_INFO_FOR_AW_DONE = 1292,
  ARTWORK_PACKAGE_INFO_FOR_AW_CHECKING = 1293,
  ARTWORK_PACKAGE_INFO_FOR_AW_CHECK_DIECUT = 1294,
  ARTWORK_PACKAGE_INFO_FOR_AW_CHECK_HRP = 1295,
  ARTWORK_PACKAGE_INFO_FOR_AW_CHECK_SAMPLE = 1296,
  ARTWORK_PACKAGE_INFO_FOR_AW_CHECK_CLIENT_INFO = 1297,
  ARTWORK_PACKAGE_INFO_FOR_AW_DONE = 1298,
  ARTWORK_DOING_WAITING = 1299,
  ARTWORK_DOING_TO_DO = 1300,
  ARTWORK_DOING_DOING = 1301,
  ARTWORK_DOING_DONE = 1302,
  ARTWORK_LAYOUT_WAITING = 1303,
  ARTWORK_LAYOUT_APPROVAL = 1304,
  ARTWORK_LAYOUT_APPROVED = 1305,
  ARTWORK_LAYOUT_NOT_REQUIRED = 1306,
  ARTWORK_REVIEW_DESIGN_REVIEW = 1307,
  ARTWORK_REVIEW_COMMERCIAL_REVIEW = 1308,
  ARTWORK_REVIEW_DONE = 1309,
  ARTWORK_CLIENT_APPROVAL_WAITING_REGISTER = 1310,
  ARTWORK_CLIENT_APPROVAL_WAITING = 1311,
  ARTWORK_CLIENT_APPROVAL_APPROVAL = 1312,
  ARTWORK_CLIENT_APPROVAL_APPROVED = 1313,
  ARTWORK_FINAL_AW_SHIPPING_MARKS = 1314,
  ARTWORK_FINAL_AW_REVIEW = 1315,
  ARTWORK_FINAL_AW_DONE = 1316,
  ARTWORK_SKETCHES_FINAL_AW = 1317,
  ARTWORK_SKETCHES_WAITING = 1318,
  ARTWORK_SKETCHES_RECEIVED = 1319,
  ARTWORK_SKETCHES_REVIEW = 1320,
  ARTWORK_SKETCHES_WAITING_REVISED = 1321,
  ARTWORK_SKETCHES_DONE = 1322,
  ARTWORK_ARTWORKS_DONE_CANCELLED = 1323,
  ARTWORK_ARTWORKS_DONE_STAND_BY = 1324,
  ARTWORK_ARTWORKS_DONE_WAITING = 1325,
  ARTWORK_ARTWORKS_DONE_DONE = 1326,
  RNC_CONFIRMED_ORDER_CONFIRMED_COMMERCIAL = 1327,
  RNC_CONFIRMED_ORDER_DONE = 1328,
  RNC_COST_YES_REFUNDABLE = 1329,
  RNC_COST_YES_NOT_REFUNDABLE = 1330,
  RNC_COST_YES_REFUNDED = 1331,
  RNC_COST_NO_COST = 1332,
  RNC_CLIENT_DEBIT_NOTE_DEBIT_CATEGORY = 1333,
  RNC_CLIENT_DEBIT_NOTE_OPEN = 1334,
  RNC_CLIENT_DEBIT_NOTE_CLOSED = 1335,
  RNC_CLIENT_DEBIT_NOTE_NOT_REQUIRED = 1336,
  RNC_CREDIT_NOTE_REQUEST = 1337,
  RNC_CREDIT_NOTE_CREATE = 1338,
  RNC_CREDIT_NOTE_WAITING_BALANCE = 1339,
  RNC_CREDIT_NOTE_APPLIED = 1340,
  RNC_CREDIT_NOTE_NOT_REQUIRED = 1341,
  RNC_DEBIT_NOTE_REQUEST = 1342,
  RNC_DEBIT_NOTE_CREATE = 1343,
  RNC_DEBIT_NOTE_WAITING_BALANCE = 1344,
  RNC_DEBIT_NOTE_APPLIED = 1345,
  RNC_DEBIT_NOTE_NOT_REQUIRED = 1346,
  RNC_RNC_OPEN = 1347,
  RNC_RNC_CLOSED = 1348,
  RNC_RNC_CANCELLED = 1349,
}
