import { DocumentNode, gql } from '@apollo/client';
import { PackageDescriptionFieldsPermissions } from '../../interfaces';

export const listPackageDescriptionsQuery = (
  fieldsPermissions: PackageDescriptionFieldsPermissions,
): DocumentNode => gql`
  query ListProductPackageDescriptionsByIdProductPackage(
    $idProductPackage: Int!
    $pagination: Pagination!
  ) {
    listProductPackageDescriptionsByIdProductPackage(
      idProductPackage: $idProductPackage
      pagination: $pagination
    ) {
      items
      data {
        idProductPackage
        ${
          fieldsPermissions.idPackageDescription.view
            ? `
          idPackageDescription
          idPackageDescription2 {
            idDomain
            description
          }
        `
            : ''
        }
        ${
          fieldsPermissions.idResponsible.view
            ? `
          idResponsible
          idResponsible2 {
            description
          }
        `
            : ''
        }
        ${fieldsPermissions.comments.view ? 'comments' : ''}
      }
    }
  }
`;

export const deleteProductPackageDescriptionQuery = gql`
  mutation DeleteProductPackageDescription(
    $data: DeleteProductPackageDescriptionInput!
  ) {
    deleteProductPackageDescription(data: $data)
  }
`;
