import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;
  padding: 8px;

  .form-checkbox {
    margin-left: 8px;
  }
`;
