/* eslint-disable no-return-assign */
import React from 'react';
import { Container } from './styles';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';
import FileUpload from '../../FileUpload';
import { FileType } from '../../../shared/enums/fileType';
import { FileUploadResponse } from '../../FileUpload/interfaces';

interface ImageProps {
  className?: string;
  src: string;
  alt: string;
  onChange?(data: FileUploadResponse[]): void;
  openOnClick?: boolean;
}

const Image: React.FC<ImageProps> = ({
  className,
  src,
  alt,
  onChange,
  openOnClick,
}) => {
  const isPlaceHolder = src === imagePlaceholder;

  function openFull() {
    if (openOnClick && !isPlaceHolder) window.open(src, '_blank');
  }

  return (
    <Container className={className ?? ''}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        className={openOnClick && !isPlaceHolder ? 'clickable' : ''}
        src={src}
        alt={alt}
        onError={e => (e.currentTarget.src = imagePlaceholder)}
        onClick={openFull}
        onKeyDown={openFull}
      />
      {onChange && (
        <FileUpload
          mode="basic"
          chooseOptions={{
            icon: 'pi pi-camera',
            iconOnly: true,
            className: 'upload-new-image-icon',
          }}
          accept={`${FileType.ALL_IMAGES}`}
          auto
          onConfirm={e => onChange(e)}
        />
      )}
    </Container>
  );
};

export default Image;
