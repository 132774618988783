import styled from 'styled-components';

export const Container = styled.div`
  .formInput {
    margin-top: 10px;
    max-width: 816px;
  }

  .form-checkbox {
    margin-top: 27px;
  }
`;
