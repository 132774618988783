import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .p-treeselect {
    border-right: none;

    .p-placeholder {
      color: var(--blue) !important;
    }

    .p-treeselect-trigger {
      display: none;
    }

    .p-treeselect-label {
      padding: 0;
    }
  }

  .p-button {
    height: 100%;
    font-weight: 500;
    background-color: #fff !important;
    border-color: var(--blue) !important;
    transition: background-color 0.2s;
    color: var(--blue) !important;

    &:hover {
      background: ${shade(0.01, '#f0f8ff')} !important;
    }
  }

  .s-checkbox-menu-panel-button {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .s-checkbox-menu-get-selected-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;
