import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1154px;
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 577px));
  gap: 41px;

  .content-header {
    display: flex;
    margin-bottom: 12px;

    .form-title {
      margin-bottom: unset;
    }

    .content-header-options {
      margin-left: auto;
      display: flex;
      gap: 16px;
      margin-bottom: 12px;
    }
  }

  .s-comment + .s-comment {
    margin-top: 18px;
  }
`;
