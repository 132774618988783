import { Form } from '@unform/web';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import FormInput from '../../../../../components/FormInput';
import FormTitle from '../../../../../components/FormTitle';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { TypeOfPackage } from '../../../../../shared/enums/domains';
import imagePlaceholder from '../../../../../assets/imagePlaceholder.svg';

import {
  OneSizePerBoxBarcodes,
  AssortmentSizesLayout,
  Container,
  OneSizePerBoxLayout,
  ReferenceContent,
  ReferenceStringData,
  OneSizePerBoxContent,
} from './styles';
import {
  IClientQuotationItem,
  IClientQuotationItemReference,
} from '../../interfaces';
import { parsePtBrNumberColumn } from '../../../../../utils/gridColumnsParse';
import FormInputNumber from '../../../../../components/FormInputNumber';
import {
  ClientQuotationItemsReducerActionKind,
  IClientQuotationItemsReducerAction,
} from '../interfaces';

/**
 * Interface de Reference Sizes
 */
interface IReferenceSizesProps {
  /**
   * Client Quotation
   */
  cqItem: IClientQuotationItem;

  /**
   * Indica se itens ficarao editaveis
   */
  editable: boolean;

  /**
   * Dispatch do reducer de Client Quotation Item
   */
  clientQuotationItemsDispatch: React.Dispatch<IClientQuotationItemsReducerAction>;
}

const ReferenceSizes: React.FC<IReferenceSizesProps> = ({
  cqItem,
  editable,
  clientQuotationItemsDispatch,
}) => {
  // Referencia ao toast e formulario
  const { formRef } = useRefHook();

  const onItemReferenceChange = (reference: IClientQuotationItemReference) => {
    clientQuotationItemsDispatch({
      type: ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_REFERENCES_TO_UPDATE,
      payload: {
        referenceToUpdate: reference,
      },
    });
  };

  const onReferenceSizeBarcodeChange = (
    options: ColumnEditorOptions,
    value: any,
  ) => {
    const updatedItem = options.rowData;
    updatedItem[options.field] = value;

    clientQuotationItemsDispatch({
      type: ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_REFERENCE_SIZE_BARCODE_TO_UPDATE,
      payload: {
        referenceSizeBarcodeToUpdate: updatedItem,
      },
    });
  };

  const numberEditor = (options: ColumnEditorOptions, field: string) => {
    return (
      <InputNumber
        value={options.rowData[field]}
        onChange={e => onReferenceSizeBarcodeChange(options, e.value)}
      />
    );
  };

  return (
    <Container>
      <FormTitle name="Reference x Sizes" />
      <Form ref={formRef} onSubmit={() => ''}>
        {cqItem.cqItemReferences.map(reference => {
          return (
            <ReferenceContent key={reference.idCqItemReference}>
              <ReferenceStringData>
                <FormInput
                  name="colourEn"
                  label="Color (EN)"
                  value={reference.colourEn}
                  readOnly
                />

                <FormInput
                  name="colourPt"
                  label="Color (PT)"
                  value={reference.colourPt}
                  readOnly
                />

                <FormInput
                  name="idGender"
                  label="Gender (EN)"
                  value={reference.idGender2?.description}
                  readOnly
                />

                <FormInput
                  name="idSizeGroup"
                  label="Size Group (EN)"
                  value={reference.idSizeGroup2?.description}
                  readOnly
                />

                <FormInputNumber
                  name="totalCollor"
                  label="Total PI/Color Variation"
                  defaultValue={reference.totalCollor}
                  onChange={e =>
                    onItemReferenceChange({
                      ...reference,
                      totalCollor: Number(e.currentTarget.value),
                    })
                  }
                  readOnly={!editable}
                  decimalScale={2}
                />
              </ReferenceStringData>
              {cqItem.idTypePackage === TypeOfPackage.ASSORTMENT_SIZES ? (
                <AssortmentSizesLayout>
                  <FormInput
                    name="masterBarcode"
                    label="Master Barcode"
                    value={reference.masterBarcode?.toString()}
                    readOnly
                  />

                  <DataTable
                    value={reference.cqReferenceSizeBarcodes}
                    editMode="row"
                    editingRows={
                      editable ? reference.cqReferenceSizeBarcodes : undefined
                    }
                    onRowEditChange={() => ''}
                  >
                    <Column
                      field="unit2.description"
                      header="Unit"
                      headerStyle={{ width: '2rem' }}
                    />

                    <Column
                      field="size2.description"
                      header="Size"
                      headerStyle={{ width: '2rem' }}
                    />

                    <Column
                      field="reference"
                      header="Color/Size Ref"
                      headerStyle={{ width: '9rem' }}
                    />

                    <Column
                      field="sizeRange"
                      header="Size Range"
                      headerStyle={{ width: '7rem' }}
                      editor={props => numberEditor(props, 'sizeRange')}
                    />

                    <Column
                      field="sellingPackage"
                      header="Selling Package (EAN-13)"
                      headerStyle={{ width: '10rem' }}
                    />

                    <Column
                      field="quantityPiSize"
                      header="Qty PI/Size"
                      headerStyle={{ width: '7rem' }}
                      body={parsePtBrNumberColumn}
                    />

                    <Column
                      field="totalCotPiSize"
                      header="Tot Cot PI/Size"
                      headerStyle={{ width: '6rem' }}
                      body={parsePtBrNumberColumn}
                    />

                    <Column
                      field="totalSunPiSize"
                      header="Tot Sun PI/Size"
                      headerStyle={{ width: '7rem' }}
                      body={parsePtBrNumberColumn}
                    />
                  </DataTable>

                  <img
                    src={`${reference.idAttachment2?.url}`}
                    onError={e => {
                      e.currentTarget.src = imagePlaceholder;
                    }}
                    alt={reference.colourEn}
                  />
                </AssortmentSizesLayout>
              ) : (
                <OneSizePerBoxLayout>
                  <div>
                    {reference.cqReferenceSizeBarcodes?.map(barcode => {
                      return (
                        <OneSizePerBoxContent
                          key={barcode.idCqReferenceSizeBarcode}
                        >
                          <FormInput
                            className="size"
                            name="size"
                            label="Size"
                            value={barcode.size2?.description || ''}
                            readOnly
                          />

                          <FormInput
                            name="cqItemReferenceSize"
                            label="Color/Size Ref"
                            value={barcode.reference || ''}
                            readOnly
                          />

                          <OneSizePerBoxBarcodes>
                            <div>
                              <FormInput
                                className="unit"
                                name="sellingPackageUnit"
                                label="Unit"
                                value={barcode.unit2?.description || ''}
                                readOnly
                              />

                              <FormInput
                                className="barcode"
                                name="sellingPackage"
                                label="Selling Package (EAN-13)"
                                value={barcode.sellingPackage?.toString() || ''}
                                readOnly
                              />
                            </div>
                            <div>
                              <FormInput
                                className="unit"
                                name="unitInnerBox"
                                label="Unit"
                                value={barcode.unitInnerBox || ''}
                                readOnly
                              />

                              <FormInput
                                className="barcode"
                                name="innerBox"
                                label="Inner Box (DUN-14)"
                                value={barcode.innerBox?.toString() || ''}
                                readOnly
                              />
                            </div>
                            <div>
                              <FormInput
                                className="unit"
                                name="unitMasterBox"
                                label="Unit"
                                value={barcode.unitMasterBox || ''}
                                readOnly
                              />

                              <FormInput
                                className="barcode"
                                name="masterBox"
                                label="Master Box (DUN-14)"
                                value={barcode.masterBox?.toString() || ''}
                                readOnly
                              />
                            </div>
                          </OneSizePerBoxBarcodes>
                        </OneSizePerBoxContent>
                      );
                    })}
                  </div>

                  <img
                    src={`${reference.idAttachment2?.url}`}
                    onError={e => {
                      e.currentTarget.src = imagePlaceholder;
                    }}
                    alt={reference.colourEn}
                  />
                </OneSizePerBoxLayout>
              )}
            </ReferenceContent>
          );
        })}
      </Form>
    </Container>
  );
};

export default ReferenceSizes;
