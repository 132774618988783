import React, {
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Skeleton } from 'primereact/skeleton';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { ValidationError } from 'yup';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import {
  deleteProductPackageQuery,
  listProductPackagesQuery,
  updateProductPackagesQuery,
} from './queries';
import {
  IProductPackagesRef,
  ProductPackageState,
  ProductPackagesLazyParams,
} from './interfaces';
import { useRefHook } from '../../../../hooks/useRefHook';
import Empty from '../../../../components/Empty';
import Card from '../../../../components/Card';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../components/CommentPagination';
import PackageContent from './PackageContent';
import {
  IPackageContentRef,
  PackageContentFormData,
} from './PackageContent/interfaces';
import MainButton from '../../../../components/MainButton';
import { IAddPackageRef } from './AddPackage/interfaces';
import AddPackage from './AddPackage';
import Loading from '../../../../components/Loading';
import { useDefaultProductPackage } from '../hooks/useDefaultProductPackage';
import {
  packageBarcodeFieldsPermissions,
  packageDescriptionFieldsPermissions,
  packageDetailsFieldsPermissions,
  packageReferenceFieldsPermissions,
} from './constants';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';

interface IPackagesProps extends PageTabContainerProps {
  ref: Ref<IProductPackagesRef>;
  idProduct: number;
  indConfection?: boolean;
  stCode: string;
  pageMenuItemKey: string;
  setSelectedMenuItem: (value: SetStateAction<string>) => void;
  userCanChangeFields: boolean;
  roleEntityFields: IRoleEntityField[];
}

const Packages: React.FC<IPackagesProps> = forwardRef(
  (
    {
      selected,
      idProduct,
      indConfection,
      stCode,
      pageMenuItemKey,
      setSelectedMenuItem,
      userCanChangeFields,
      roleEntityFields,
    },
    ref,
  ) => {
    const { setDefaultProductPackage } = useDefaultProductPackage();

    const descriptionsFieldsPermissions = useMemo(() => {
      return packageDescriptionFieldsPermissions(roleEntityFields);
    }, [roleEntityFields]);

    const detailsFieldsPermissions = useMemo(() => {
      return packageDetailsFieldsPermissions(roleEntityFields);
    }, [roleEntityFields]);

    const barcodesFieldsPermissions = useMemo(() => {
      return packageBarcodeFieldsPermissions(roleEntityFields);
    }, [roleEntityFields]);

    const referencesFieldsPermissions = useMemo(() => {
      return packageReferenceFieldsPermissions(roleEntityFields);
    }, [roleEntityFields]);

    const [updateProductPackagesMutation] = useMutation(
      updateProductPackagesQuery,
    );
    const [deleteProductPackageMutation] = useMutation(
      deleteProductPackageQuery,
    );

    const { showError, showSuccess } = useRefHook();

    const packagesRef = useRef<IPackageContentRef[] | null[]>([]);
    const addPackageRef = useRef<IAddPackageRef>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState<ProductPackagesLazyParams>({
      first: 0,
      rows: 1,
      page: 0,
    });
    const [productPackages, setProductPackages] =
      useState<ProductPackageState>();

    async function packagesRefetch() {
      // Se usuario ja tiver carregado mais paginas,
      // eh necessario voltar para a primeira pagina para evitar registros
      // duplicados
      if (lazyParams.page) {
        setLazyParams(prev => {
          return {
            ...prev,
            page: 0,
          };
        });
      } else {
        setProductPackages(undefined);
      }
    }

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        try {
          for (let index = 0; index < packagesRef.current.length; index += 1) {
            //* Regra do ESLint desabilitada, pois nesse caso ha necessidade
            //* de aguardar a validacao de cada package para indicar ao usuario
            //* qual possui erro
            // eslint-disable-next-line no-await-in-loop
            await packagesRef.current[index]?.validateForm();
          }

          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          throw new ValidationError(error);
        }
      },
      submitForm: async () => {
        const packagesToUpdate: PackageContentFormData[] = [];

        for (let index = 0; index < packagesRef.current.length; index += 1) {
          const data = packagesRef.current[index]?.getDataIfChanged();
          if (data) packagesToUpdate.push(data);
        }

        if (packagesToUpdate.length) {
          try {
            await updateProductPackagesMutation({
              variables: {
                data: packagesToUpdate,
              },
            });

            packagesRefetch();
          } catch (error) {
            return {
              moduleChanged: 'Packages',
              errors: [error.message],
            };
          }
        }

        return undefined;
      },
    }));

    const [loadPackagesData, { loading: packagesLoading }] = useLazyQuery(
      listProductPackagesQuery(detailsFieldsPermissions),
      {
        variables: {
          data: {
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
              _orderBy: 'productPackage.isDefault',
              _sortOrder: 'DESC',
            },
            idProduct,
          },
        },
        // fetchPolicy: 'network-only', // A primeira vez que a query for executada, ela vai no servidor
        // nextFetchPolicy: 'cache-first', // Na proxima execucao, prioriza cache se nao houver mudancas
        errorPolicy: 'all',
        onCompleted: response => {
          if (response.listProductPackagesByProductId) {
            const { data, items } = response.listProductPackagesByProductId;
            const isRefetch = !lazyParams.page && productPackages;
            // Se for um refetch, devemos sobrescrever os itens que ja estao
            // no estado para nao ter registros duplicados exibidos em tela
            if (isRefetch) {
              setProductPackages({
                data,
                items,
              });
            } else {
              setProductPackages(previous => {
                return {
                  items,
                  data: previous?.data ? [...previous.data, ...data] : data,
                };
              });
            }
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting product packages',
            detail: errorData.message,
          });
        },
      },
    );

    function onPage(e: ICommentPaginationPageParams) {
      setLazyParams(prev => {
        return { ...prev, ...e };
      });
    }

    function handleChangeDefault(
      idProductPackage: number,
      e: CheckboxChangeEvent,
    ) {
      // Nao permite desmarcar item default
      if (e.checked === false) return false;

      if (!productPackages) throw new Error('Product packages not loaded');

      const { data } = productPackages;

      packagesRef.current.forEach((packageRef, index) => {
        const isDefault = data[index].idProductPackage === idProductPackage;
        packageRef?.setIsDefault(isDefault);
      });

      return true;
    }

    async function handleRemovePackage(idProductPackage: number) {
      setDeleteLoading(true);

      try {
        await deleteProductPackageMutation({
          variables: {
            idProductPackage,
          },
        });

        showSuccess({ summary: 'Package removed' });

        await packagesRefetch();
      } catch (error) {
        showError({
          summary: 'Error while removing package',
          detail: error.message,
        });
      } finally {
        setDeleteLoading(false);
      }
    }

    useEffect(() => {
      if (selected && !productPackages) {
        loadPackagesData();
      }
    }, [loadPackagesData, productPackages, selected]);

    useEffect(() => {
      if (productPackages) {
        const defaultPackage = productPackages.data.find(pp => pp.isDefault);

        if (defaultPackage) setDefaultProductPackage(defaultPackage);
      }
    }, [productPackages, setDefaultProductPackage]);

    return (
      <PageTabContainer selected={selected}>
        <MainButton
          className="mb-4"
          label="Add Package"
          type="button"
          onClick={() => addPackageRef.current?.toggleDialog()}
          disabled={!userCanChangeFields}
        />
        {productPackages && (
          <>
            {productPackages.data.map((item, index) => {
              return (
                <Card
                  className="mb-3"
                  key={item.idProductPackage}
                  onRemove={() =>
                    confirmDialog({
                      style: { maxWidth: '350px' },
                      message: `Are you sure you want to delete this item?
                    If you have unsaved changes on any packages at this
                    product, removing a package from it will cause data loss`,
                      header: 'Delete Confirmation',
                      icon: 'pi pi-info-circle',
                      acceptClassName: 'p-button-danger',
                      accept: () => handleRemovePackage(item.idProductPackage),
                    })
                  }
                  showRemoveButton
                  removeButtonPosition="top"
                >
                  <PackageContent
                    ref={el => {
                      packagesRef.current[index] = el;
                    }}
                    productPackage={item}
                    indConfection={indConfection}
                    handleChangeDefault={handleChangeDefault}
                    stCode={stCode}
                    userCanChangeFields={userCanChangeFields}
                    descriptionsFieldsPermissions={
                      descriptionsFieldsPermissions
                    }
                    detailsFieldsPermissions={detailsFieldsPermissions}
                    barcodesFieldsPermissions={barcodesFieldsPermissions}
                    referencesFieldsPermissions={referencesFieldsPermissions}
                  />
                </Card>
              );
            })}

            <CommentPagination
              first={lazyParams.first}
              rows={lazyParams.rows}
              page={lazyParams.page}
              totalRecords={productPackages.items}
              loading={packagesLoading}
              onPage={e => onPage(e)}
            />
          </>
        )}

        <AddPackage
          ref={addPackageRef}
          idProduct={idProduct}
          stCode={stCode}
          indConfection={indConfection}
          packagesRefetch={packagesRefetch}
          userCanChangeFields={userCanChangeFields}
          descriptionsFieldsPermissions={descriptionsFieldsPermissions}
          detailsFieldsPermissions={detailsFieldsPermissions}
          barcodesFieldsPermissions={barcodesFieldsPermissions}
          referencesFieldsPermissions={referencesFieldsPermissions}
        />

        {packagesLoading && !lazyParams.page && (
          <Card>
            <div className="formgrid grid" style={{ width: '886px' }}>
              <span className="col-3">
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
              </span>
              <span className="col-3">
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
              </span>
              <span className="col-3">
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
              </span>
              <span className="col-3">
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
                <Skeleton height="47px" className="field" />
              </span>
            </div>
          </Card>
        )}

        {!packagesLoading && !productPackages?.items && (
          <Empty message="There is no packages for this product" />
        )}

        {deleteLoading && <Loading />}
      </PageTabContainer>
    );
  },
);

export default Packages;
