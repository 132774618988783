import React from 'react';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ISat } from '../../../interfaces';
import { SatFinancialUsersPurchaseFieldsPermissions } from '../../interfaces';

import { Container } from './styles';

interface IUsersPurchaseProps {
  itemIndex: number;
  sat: ISat;
  fieldsPermissions: SatFinancialUsersPurchaseFieldsPermissions;
  userCanSee: boolean;
}

const UsersPurchase: React.FC<IUsersPurchaseProps> = ({
  itemIndex,
  sat,
  fieldsPermissions,
  userCanSee,
}) => {
  return userCanSee ? (
    <Container className="grid">
      <FormTitle className="col-12" name="Users" />
      {fieldsPermissions.idPurchaseUser && (
        <ReadOnlyInput
          className="col-4"
          label="Purchase User"
          value={
            sat.idPurchaseUser2
              ? `${sat.idPurchaseUser2?.firstName} ${sat.idPurchaseUser2?.lastName}`
              : ''
          }
        />
      )}
      {fieldsPermissions.idComexExportUser && (
        <ReadOnlyInput
          className="col-4"
          label="Exporter User"
          value={
            sat.idComexExportUser2
              ? `${sat.idComexExportUser2?.firstName} ${sat.idComexExportUser2?.lastName}`
              : undefined
          }
        />
      )}
      {fieldsPermissions.idFinancialUser && (
        <ReadOnlyInput
          className="col-4"
          label="Financial User"
          value={
            sat.idFinancialUser2
              ? `${sat.idFinancialUser2?.firstName} ${sat.idFinancialUser2?.lastName}`
              : ''
          }
        />
      )}
      {fieldsPermissions.notes && (
        <FormInputTextArea
          className="col-12"
          label="Notes"
          name={`data[${itemIndex}].notes`}
          rows={4}
          cols={73}
          disabled={!fieldsPermissions.notes.edit}
        />
      )}
    </Container>
  ) : (
    <div />
  );
};

export default UsersPurchase;
