import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { confirmOrderOptions } from '../../../shared/options/confirmOrderOptions';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadPortsOptions } from '../../../shared/querys/port';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  status: {
    field: 'status',
    header: 'Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  confirmOrder: {
    field: 'confirmOrder',
    header: 'Confirm Order',
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'productionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'supplierPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'clientPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  typeOfOrder: {
    field: 'typeOfOrder',
    header: 'Type of Order',
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    advancedFilterField: 'replacementFrom',
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  satGroup: {
    field: 'satGroup',
    header: 'SAT Group',
    advancedFilterField: '',
  },
  prcLinked: {
    field: 'prcLinked',
    header: 'PRC Linked',
    advancedFilterField: '',
    value: '',
  },
  cqLinked: {
    field: 'cqLinked',
    header: 'CQ Linked',
    advancedFilterField: '',
    value: '',
  },
  suppliers: {
    field: 'suppliers',
    header: 'SUN Number',
    advancedFilterField: 'suppliers',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  clientOrder: {
    field: 'clientOrder',
    header: 'Client Order',
    advancedFilterField: 'clientOrder',
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  firstBuyer: {
    field: 'firstBuyer',
    header: 'First Buyer',
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  secondBuyer: {
    field: 'secondBuyer',
    header: 'Second Buyer',
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty/Type CTNR',
    advancedFilterField: '',
  },
  portLoading: {
    field: 'portLoading',
    header: 'Port of Loading',
    advancedFilterField: 'idLoadingPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  portDischarge: {
    field: 'portDischarge',
    header: 'Port of Discharge',
    advancedFilterField: 'idPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  sellerTotalSunPi: {
    field: 'sellerTotalSunPi',
    header: 'Total SUN PI',
    advancedFilterField: '',
  },
  sellerTotalSunCi: {
    field: 'sellerTotalSunCi',
    header: 'Total SUN CI',
    advancedFilterField: '',
  },
  paymentSellingIncoterm: {
    field: 'paymentSellingIncoterm',
    header: 'Selling Incoterm',
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  quantitySatPi: {
    field: 'quantitySatPi',
    header: 'Quantity PI Shipment',
    advancedFilterField: '',
  },
  quantityShipmentCi: {
    field: 'quantityShipmentCi',
    header: 'Quantity Shipment CI',
    advancedFilterField: '',
  },
  paymentTermAdvance: {
    field: 'paymentTermAdvance',
    header: 'Payment Term Advance',
    advancedFilterField: '',
  },
  paymentTermBalance: {
    field: 'paymentTermBalance',
    header: 'Payment Term Balance',
    advancedFilterField: '',
  },
  paymentTermCondition: {
    field: 'paymentTermCondition',
    header: 'Payment Term Condition',
    advancedFilterField: '',
  },
  createdAt: {
    field: 'createdAt',
    header: 'Created At',
    advancedFilterField: '',
  },
  confirmOrderDate: {
    field: 'confirmOrderDate',
    header: 'Confirmation Date',
    advancedFilterField: 'confirmOrderDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  samples: {
    field: 'samples',
    header: 'Samples',
    advancedFilterField: '',
  },
  sampleLeadTime: {
    field: 'sampleLeadTime',
    header: 'Sample Lead Time',
    advancedFilterField: '',
  },
  commercialReview: {
    field: 'commercialReview',
    header: 'Commercial Review',
    advancedFilterField: '',
  },
  commercialReviewResponsible: {
    field: 'commercialReviewResponsible',
    header: 'Commercial Review Responsible',
    advancedFilterField: 'idCommercialReviewResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  artworkDone: {
    field: 'artworkDone',
    header: 'Artwork Done',
    advancedFilterField: '',
  },
  productionTime: {
    field: 'productionTime',
    header: 'Production Time',
    advancedFilterField: '',
  },
  estimatedInspectionDate: {
    field: 'estimatedInspectionDate',
    header: 'Estimated Inspection Date',
    advancedFilterField: 'estimatedInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedArrivalDate: {
    field: 'estimatedArrivalDate',
    header: 'Estimated Arrival Date',
    advancedFilterField: 'estimatedArrivalDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedArrivalClientDate: {
    field: 'estimatedArrivalClientDate',
    header: 'Estimated Arrival Client Date',
    advancedFilterField: 'estimatedArrivalClientDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realArrivalAtClient: {
    field: 'realArrivalAtClient',
    header: 'Real Arrival at Client',
    advancedFilterField: 'realArrivalAtClient',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  rncs: {
    field: 'rncs',
    header: 'RNCs',
    advancedFilterField: '',
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  designUser: {
    field: 'designUser',
    header: 'Design User',
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.status.field,
          gridColumnsData.confirmOrder.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.shipment.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.replacementFrom.field,
          gridColumnsData.satGroup.field,
          gridColumnsData.exporter.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.suppliers.field,
          gridColumnsData.clientDepartment.field,
          gridColumnsData.firstBuyer.field,
          gridColumnsData.secondBuyer.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.supplierPaymentStatus.field,
          gridColumnsData.clientPaymentStatus.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.countryOrigin.field,
          gridColumnsData.sellerTotalSunPi.field,
          gridColumnsData.sellerTotalSunCi.field,
          gridColumnsData.paymentSellingIncoterm.field,
          gridColumnsData.createdAt.field,
          gridColumnsData.confirmOrderDate.field,
          gridColumnsData.samples.field,
          gridColumnsData.artworkDone.field,
          gridColumnsData.productionTime.field,
          gridColumnsData.realInspectionDate.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.realEta.field,
          gridColumnsData.realArrivalAtClient.field,
          gridColumnsData.firstSalesUser.field,
          gridColumnsData.secondSalesUser.field,
          gridColumnsData.purchaseUser.field,
          gridColumnsData.exportUser.field,
          gridColumnsData.importUser.field,
          gridColumnsData.designUser.field,
          gridColumnsData.financialUser.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
