import { IFieldPermissionResponse } from '../../../../utils/getFieldPermission';
import {
  IClientQuotationItem,
  IClientQuotationItemReference,
  IClientQuotationReferenceSizeBarcode,
} from '../interfaces';

// eslint-disable-next-line no-shadow
export enum ClientQuotationItemsReducerActionKind {
  CHANGE_ADD_CLIENT_QUOTATION_ITEM,
  CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS,
  CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS,
  CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS,
  CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS,
  CHANGE_ALL_FIELDS_SELECTED,
  SET_CLIENT_QUOTATION_ITEMS,
  SET_SELECTED_CLIENT_QUOTATION_ITEMS,
  SET_CLIENT_QUOTATION_ITEMS_TO_UPDATE,
  CLEAN_CLIENT_QUOTATION_ITEMS_RELATED_DATA,
  SET_EDITING_ROWS,
  SET_CLIENT_QUOTATION_REFERENCES_TO_UPDATE,
  SET_CLIENT_QUOTATION_REFERENCE_SIZE_BARCODE_TO_UPDATE,
}

export interface IClientQuotationItemsReducer {
  displayAddClientQuotationItemModal: boolean;
  displayRefreshItemSelectFieldsModal: boolean;
  displayRefreshItemSelectItemsModal: boolean;
  allFieldsSelected: boolean;
  clientQuotationItems: IClientQuotationItem[];
  selectedClientQuotationItems: IClientQuotationItem[];
  clientQuotationItemsToUpdate: ClientQuotationItemToUpdate[];
  editingRows: IClientQuotationItem[];
  referencesToUpdate: ClientQuotationItemReferenceToUpdate[];
  referenceSizeBarcodesToUpdate: ClientQuotationReferenceSizeBarcodeToUpdate[];
}

export interface IClientQuotationItemsReducerAction {
  type: ClientQuotationItemsReducerActionKind;
  payload?: {
    clientQuotationItems?: IClientQuotationItem[];
    selectedClientQuotationItems?: IClientQuotationItem[];
    itemsToupdate?: IClientQuotationItem;
    editingRows?: IClientQuotationItem[];
    referenceToUpdate?: IClientQuotationItemReference;
    referenceSizeBarcodeToUpdate?: IClientQuotationReferenceSizeBarcode;
  };
}

export interface IClientQuotationItemsSum {
  masterNetWeightTotalItemsSum: number;
  grossWeightTotalItemsSum: number;
  totalCotPiItemsSum: number;
  quantityContainerItemsSum: number;
  cbmTotalItemsSum: number;
  quantityOfItemsSum: number;
  amountItemsSum: number;
  amountCotItemsSum: number;
  marginSumItems: number;
  quantityPiItemsSum?: number;
}

export type ClientQuotationItemReferenceToUpdate = {
  idCqItemReference: number;
  totalCollor?: number;
};

export type ClientQuotationReferenceSizeBarcodeToUpdate = {
  idCqReferenceSizeBarcode: number;
  sizeRange?: number;
};

export type ClientQuotationItemToUpdate = {
  idCqItem: number;
  quantity?: number;
  sampleLeadTime?: number;
  cotPrice?: number;
  targetSun?: number;
  targetCot?: number;
  sunPrice?: number;
  samples?: number;
  samplesApproved?: boolean;
  sampleNotes?: string;
};

export type HandleUpdateClientQuotationItemsResponse = {
  updatedSatItems: IClientQuotationItem[];
  updatedSatItemReferences: IClientQuotationItemReference[];
  updatedSatReferenceSizeBarcodes: IClientQuotationReferenceSizeBarcode[];
  warnings: string[];
  errors?: string[];
};

export type ClientQuotationItemsPermissions = {
  [key: string]: IFieldPermissionResponse;
  imageUrl: IFieldPermissionResponse;
  sunNumber: IFieldPermissionResponse;
  stCode: IFieldPermissionResponse;
  refCode: IFieldPermissionResponse;
  name: IFieldPermissionResponse;
  description: IFieldPermissionResponse;
  namePt: IFieldPermissionResponse;
  descriptionPt: IFieldPermissionResponse;
  model: IFieldPermissionResponse;
  sku: IFieldPermissionResponse;
  colour: IFieldPermissionResponse;
  colourPt: IFieldPermissionResponse;
  colourImage: IFieldPermissionResponse;
  materialComposition: IFieldPermissionResponse;
  materialPt: IFieldPermissionResponse;
  dimensions: IFieldPermissionResponse;
  netWeight: IFieldPermissionResponse;
  artworkEn: IFieldPermissionResponse;
  techniqueEn: IFieldPermissionResponse;
  typeOfFabric: IFieldPermissionResponse;
  lining: IFieldPermissionResponse;
  liningComposition: IFieldPermissionResponse;
  linedDescription: IFieldPermissionResponse;
  typeOfPackage: IFieldPermissionResponse;
  packageDescription: IFieldPermissionResponse;
  packageNotes: IFieldPermissionResponse;
  sellingPackagePcQuantity: IFieldPermissionResponse;
  sellingPackageDimensions: IFieldPermissionResponse;
  sellingPackageGrossWeight: IFieldPermissionResponse;
  stickerDimensionsPt: IFieldPermissionResponse;
  quantityInner: IFieldPermissionResponse;
  boxInner: IFieldPermissionResponse;
  packageEan13: IFieldPermissionResponse;
  innerDun14: IFieldPermissionResponse;
  dun14: IFieldPermissionResponse;
  brand: IFieldPermissionResponse;
  productLine: IFieldPermissionResponse;
  quantity: IFieldPermissionResponse;
  unit: IFieldPermissionResponse;
  moq: IFieldPermissionResponse;
  m2Pc: IFieldPermissionResponse;
  totalMasterM2: IFieldPermissionResponse;
  cotPriceSqm: IFieldPermissionResponse;
  sunPriceSqm: IFieldPermissionResponse;
  quantityContainer: IFieldPermissionResponse;
  piecesContainer: IFieldPermissionResponse;
  masterNetWeight: IFieldPermissionResponse;
  masterNetWeightTotal: IFieldPermissionResponse;
  grossWeight: IFieldPermissionResponse;
  grossWeightTotal: IFieldPermissionResponse;
  length: IFieldPermissionResponse;
  width: IFieldPermissionResponse;
  height: IFieldPermissionResponse;
  cbm: IFieldPermissionResponse;
  cbmTotal: IFieldPermissionResponse;
  purchaseIncoterm: IFieldPermissionResponse;
  targetCot: IFieldPermissionResponse;
  cotPrice: IFieldPermissionResponse;
  amountCot: IFieldPermissionResponse;
  sellingIncoterm: IFieldPermissionResponse;
  targetSun: IFieldPermissionResponse;
  sunPrice: IFieldPermissionResponse;
  amount: IFieldPermissionResponse;
  estimateProfit: IFieldPermissionResponse;
  estimateMargin: IFieldPermissionResponse;
  hsCode: IFieldPermissionResponse;
  ncm: IFieldPermissionResponse;
  ncmDestaque: IFieldPermissionResponse;
  ncmForeignTradeReview: IFieldPermissionResponse;
  ncmLi: IFieldPermissionResponse;
  ncmIi: IFieldPermissionResponse;
  ncmIpi: IFieldPermissionResponse;
  ncmPis: IFieldPermissionResponse;
  ncmCofins: IFieldPermissionResponse;
  ncmDumping: IFieldPermissionResponse;
  samples: IFieldPermissionResponse;
  sampleLeadTime: IFieldPermissionResponse;
  samplesApproved: IFieldPermissionResponse;
  sampleNotes: IFieldPermissionResponse;
  licensed: IFieldPermissionResponse;
  licensor: IFieldPermissionResponse;
  royalties: IFieldPermissionResponse;
  margin: IFieldPermissionResponse;
};
