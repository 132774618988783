import {
  ClientQuotationItemReferenceToUpdate,
  ClientQuotationItemsReducerActionKind,
  ClientQuotationItemToUpdate,
  ClientQuotationReferenceSizeBarcodeToUpdate,
  IClientQuotationItemsReducer,
  IClientQuotationItemsReducerAction,
} from './interfaces';

export const clientQuotationItemsReducerInitialState: IClientQuotationItemsReducer =
  {
    displayAddClientQuotationItemModal: false,
    displayRefreshItemSelectFieldsModal: false,
    displayRefreshItemSelectItemsModal: false,
    allFieldsSelected: false,
    clientQuotationItems: [],
    selectedClientQuotationItems: [],
    clientQuotationItemsToUpdate: [],
    editingRows: [],
    referencesToUpdate: [],
    referenceSizeBarcodesToUpdate: [],
  };

export const clientQuotationItemsReducer = (
  state: IClientQuotationItemsReducer,
  action: IClientQuotationItemsReducerAction,
): IClientQuotationItemsReducer => {
  switch (action.type) {
    case ClientQuotationItemsReducerActionKind.CHANGE_ADD_CLIENT_QUOTATION_ITEM:
      return {
        ...state,
        displayAddClientQuotationItemModal:
          !state.displayAddClientQuotationItemModal,
        clientQuotationItems:
          action.payload?.clientQuotationItems ?? state.clientQuotationItems,
      };
    case ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_FIELDS:
      return {
        ...state,
        displayRefreshItemSelectFieldsModal:
          !state.displayRefreshItemSelectFieldsModal,
        allFieldsSelected: false,
      };
    case ClientQuotationItemsReducerActionKind.CHANGE_DISPLAY_REFRESH_CLIENT_QUOTATION_ITEM_SELECT_ITEMS:
      return {
        ...state,
        displayRefreshItemSelectItemsModal:
          !state.displayRefreshItemSelectItemsModal,
        clientQuotationItems:
          action.payload?.clientQuotationItems ?? state.clientQuotationItems,
        selectedClientQuotationItems:
          action.payload?.selectedClientQuotationItems ??
          state.selectedClientQuotationItems,
      };
    case ClientQuotationItemsReducerActionKind.CHANGE_FROM_SELECT_FIELDS_TO_SELECT_ITEMS:
      return {
        ...state,
        displayRefreshItemSelectFieldsModal: false,
        displayRefreshItemSelectItemsModal: true,
        allFieldsSelected: false,
      };
    case ClientQuotationItemsReducerActionKind.CHANGE_FROM_SELECT_ITEMS_TO_SELECT_FIELDS:
      return {
        ...state,
        displayRefreshItemSelectItemsModal: false,
        displayRefreshItemSelectFieldsModal: true,
      };
    case ClientQuotationItemsReducerActionKind.CHANGE_ALL_FIELDS_SELECTED:
      return {
        ...state,
        allFieldsSelected: !state.allFieldsSelected,
      };
    case ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_ITEMS:
      return {
        ...state,
        clientQuotationItems: action.payload?.clientQuotationItems ?? [],
        clientQuotationItemsToUpdate: [],
        referencesToUpdate: [],
        referenceSizeBarcodesToUpdate: [],
      };
    case ClientQuotationItemsReducerActionKind.SET_SELECTED_CLIENT_QUOTATION_ITEMS:
      return {
        ...state,
        selectedClientQuotationItems:
          action.payload?.selectedClientQuotationItems ?? [],
      };
    case ClientQuotationItemsReducerActionKind.CLEAN_CLIENT_QUOTATION_ITEMS_RELATED_DATA:
      return {
        ...state,
        clientQuotationItems: [],
        selectedClientQuotationItems: [],
        clientQuotationItemsToUpdate: [],
        editingRows: [],
        referencesToUpdate: [],
        referenceSizeBarcodesToUpdate: [],
      };
    case ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_ITEMS_TO_UPDATE:
      if (action.payload?.itemsToupdate) {
        const {
          idCqItem,
          quantity,
          sampleLeadTime,
          cotPrice,
          targetSun,
          targetCot,
          sunPrice,
          samples,
          samplesApproved,
          sampleNotes,
        } = action.payload.itemsToupdate;

        const updatedItemObject: ClientQuotationItemToUpdate = {
          idCqItem,
          quantity,
          sampleLeadTime,
          cotPrice,
          targetSun,
          targetCot,
          sunPrice,
          samples,
          samplesApproved,
          sampleNotes,
        };

        const itemToUpdateIndex = state.clientQuotationItemsToUpdate.findIndex(
          item => item.idCqItem === updatedItemObject.idCqItem,
        );

        if (itemToUpdateIndex !== -1) {
          state.clientQuotationItemsToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          state.clientQuotationItemsToUpdate.push(updatedItemObject);
        }
      }

      return state;
    case ClientQuotationItemsReducerActionKind.SET_EDITING_ROWS:
      return {
        ...state,
        editingRows: action.payload?.editingRows ?? [],
      };
    case ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_REFERENCES_TO_UPDATE:
      if (action.payload?.referenceToUpdate) {
        const { idCqItemReference, totalCollor } =
          action.payload.referenceToUpdate;

        const updatedItemObject: ClientQuotationItemReferenceToUpdate = {
          idCqItemReference,
          totalCollor,
        };

        // Busca indice item que possui a referenca modificada
        const itemToUpdateIndex = state.referencesToUpdate.findIndex(
          item => item.idCqItemReference === idCqItemReference,
        );

        // Se estiver, subsitui pela que ja esta com as novas informacoes
        if (itemToUpdateIndex !== -1) {
          state.referencesToUpdate.splice(
            itemToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          // Se nao estiver, adiciona no array
          state.referencesToUpdate.push(updatedItemObject);
        }
      }
      return state;
    case ClientQuotationItemsReducerActionKind.SET_CLIENT_QUOTATION_REFERENCE_SIZE_BARCODE_TO_UPDATE:
      if (action.payload?.referenceSizeBarcodeToUpdate) {
        const { idCqReferenceSizeBarcode, sizeRange } =
          action.payload.referenceSizeBarcodeToUpdate;

        const updatedItemObject: ClientQuotationReferenceSizeBarcodeToUpdate = {
          idCqReferenceSizeBarcode,
          sizeRange,
        };

        const referenceSizeBarcodeToUpdateIndex =
          state.referenceSizeBarcodesToUpdate.findIndex(
            item => item.idCqReferenceSizeBarcode === idCqReferenceSizeBarcode,
          );

        if (referenceSizeBarcodeToUpdateIndex !== -1) {
          state.referenceSizeBarcodesToUpdate.splice(
            referenceSizeBarcodeToUpdateIndex,
            1,
            updatedItemObject,
          );
        } else {
          state.referenceSizeBarcodesToUpdate.push(updatedItemObject);
        }
      }

      return state;
    default:
      throw new Error();
  }
};
