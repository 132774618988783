import React, {
  Dispatch,
  Ref,
  RefObject,
  SetStateAction,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  ApolloQueryResult,
  OperationVariables,
  gql,
  useMutation,
} from '@apollo/client';
import { useRefHook } from '../../../../../hooks/useRefHook';
import ToastLife from '../../../../../shared/enums/toastLife';
import { IClientQuotationProductAdd } from '../../interfaces';

import {
  ClientQuotationItemsReducerActionKind,
  IClientQuotationItemsReducer,
  IClientQuotationItemsReducerAction,
} from '../interfaces';
import {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../components/MutationResponseSummary';
import ProductDataViewSelect, {
  IProductDataViewSelectRef,
} from '../../../../../components/ProductDataViewSelect';

/**
 * Interface do componente para adicionar itens
 */
interface IAddItemProps {
  /**
   * ID da Client Quotation
   */
  idCq: number;

  /**
   * Define estado de loading
   */
  setPageLoading: Dispatch<SetStateAction<boolean>>;

  /**
   * Refetch de Client Quotation Items
   */
  clientQuotationItemsRefetch(): void;

  /**
   * Reducer de client quotation items
   */
  clientQuotationItemsDispatch: Dispatch<IClientQuotationItemsReducerAction>;

  /**
   * Referencia do componente de Add Item
   */
  ref: Ref<IAddClientQuotationItemRef>;

  /**
   * Referencias do componente de Summary
   */
  addItemsSummaryRef: RefObject<IMutationResponseSummaryRef>;

  setAddCqItemMutationResponse: Dispatch<
    React.SetStateAction<IMutationResponse[]>
  >;
  clientQuotationItemsState: IClientQuotationItemsReducer;
  cleanClientQuotation: () => void;
  clientQuotationRefetch(
    variables?: Partial<OperationVariables> | undefined,
  ): Promise<ApolloQueryResult<any>>;
}

export interface IAddClientQuotationItemRef {
  handleAddItems(): void;
}

const AddItem: React.FC<IAddItemProps> = React.forwardRef(
  (
    {
      idCq,
      setPageLoading,
      clientQuotationItemsRefetch,
      clientQuotationItemsDispatch,
      addItemsSummaryRef,
      setAddCqItemMutationResponse,
      clientQuotationItemsState,
      cleanClientQuotation,
      clientQuotationRefetch,
    },
    ref,
  ) => {
    // Referencia ao toast
    const { toastRef } = useRefHook();

    const productDataViewRef = useRef<IProductDataViewSelectRef>(null);

    // Mutation de criacao de items
    const createClientQuotationItemsQuery = gql`
      mutation CreateClientQuotationItems(
        $idCq: Int!
        $data: [CreateClientQuotationItemInput]!
      ) {
        createClientQuotationItems(idCq: $idCq, data: $data) {
          createdCqItems {
            idCqItem
          }
          warnings
        }
      }
    `;

    // Metodo para chamar a mutation
    const [createClientQuotationItemsMutation] = useMutation(
      createClientQuotationItemsQuery,
    );

    useImperativeHandle(ref, () => ({
      handleAddItems: async () => {
        const mutationResponse: IMutationResponse[] = [];
        const selectedProducts = productDataViewRef.current?.selectedProducts;

        // Valida se existem itens adicionados
        if (!selectedProducts?.length) {
          toastRef.current?.show({
            severity: 'warn',
            summary: 'You need to add at least one item',
            life: ToastLife.WARN,
          });

          return;
        }

        setPageLoading(true);
        try {
          // Converte itens selecionados para formato esperado no backend
          const itemsParsed = selectedProducts?.map(
            (item: IClientQuotationProductAdd) => {
              return { idSupplier: item.idSupplier, idProduct: item.idProduct };
            },
          );

          // Cria itens
          const createCqItemsResponse =
            await createClientQuotationItemsMutation({
              variables: {
                idCq,
                data: itemsParsed,
              },
            });

          if (
            createCqItemsResponse.data?.createClientQuotationItems?.warnings
              ?.length
          ) {
            mutationResponse.push({
              moduleChanged: 'CQ Items',
              warnings:
                createCqItemsResponse.data.createClientQuotationItems.warnings,
            });
          }

          if (clientQuotationItemsState.clientQuotationItems.length === 0) {
            cleanClientQuotation();
            clientQuotationRefetch();
          }

          // Fecha a modal e atualiza estado de itens
          clientQuotationItemsDispatch({
            type: ClientQuotationItemsReducerActionKind.CHANGE_ADD_CLIENT_QUOTATION_ITEM,
          });

          // Atualiza lista de Itens
          clientQuotationItemsRefetch();

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Items created',
            life: ToastLife.SUCCESS,
          });
        } catch (error) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while adding CQ items',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
          if (mutationResponse.length) {
            setAddCqItemMutationResponse(mutationResponse);
            addItemsSummaryRef.current?.toggleDisplaySummary();
          }
        }
      },
    }));

    return <ProductDataViewSelect ref={productDataViewRef} idCq={idCq} />;
  },
);

export default AddItem;
