import isNullOrUndefined from './isNullOrUndefined';

/**
 * Converte valor numerico para padrao em portugues
 * @param value Valor a ser convertido para padrao em portugues
 * @param decimal Indica se valor possui casas decimais
 * @returns Valor convertido para padrao em portugues
 */
export function numberToPtString(
  value?: number,
  decimalPlaces?: number,
): string {
  if (isNullOrUndefined(value)) return '';

  return value.toLocaleString(
    'pt',
    decimalPlaces
      ? {
          minimumFractionDigits: decimalPlaces ?? 2,
          maximumFractionDigits: decimalPlaces ?? 4,
        }
      : {},
  );
}

/**
 * Renderiza numero com formatacao com local fixo
 * @param value Valor a ser renderizado
 * @param type Tipo do valor
 * @param currencyAbbreviation Abreviacao da moeda (ex: BRL, USD)
 * @returns Numero a ser renderizado em string
 */
export function renderNumber(
  value: number | undefined = 0,
  type?: 'int' | 'currency' | 'float' | 'percent',
  currencyAbbreviation?: string,
): string {
  const floatFractionDigits = 2;
  const locale = 'pt';

  switch (type) {
    case 'int':
      return value?.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    case 'currency':
      return value?.toLocaleString(locale, {
        style: currencyAbbreviation ? type : undefined,
        currency: currencyAbbreviation ?? undefined,
        minimumFractionDigits: floatFractionDigits,
        maximumFractionDigits: floatFractionDigits,
      });
    case 'percent':
      return value?.toLocaleString(locale, {
        style: type,
        minimumFractionDigits: floatFractionDigits,
        maximumFractionDigits: floatFractionDigits,
      });
    case 'float':
      return value?.toLocaleString(locale, {
        minimumFractionDigits: floatFractionDigits,
        maximumFractionDigits: floatFractionDigits,
      });
    default:
      return value?.toLocaleString(locale);
  }
}
