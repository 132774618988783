import { saveAs } from 'file-saver';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Salva arquivo como xlsx
 * @param buffer
 * @param fileName Nome do arquivo
 */
export default function saveFileAsExcel(buffer: any, fileName: string): void {
  const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const EXCEL_EXTENSION = '.xlsx';
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
}
