import { Form } from '@unform/web';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { FiChevronRight, FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import FormInput from '../../../../../components/FormInput';
import FormInputNumber from '../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../components/FormInputTextArea';
import FormTitle from '../../../../../components/FormTitle';
import { IClientQuotationSupplier } from '../../interfaces';
import getFieldPermission from '../../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../../interfaces/IRoleEntityField';
import { clientQuotationRoles } from '../../../../../shared/roles/clientQuotation';

import { BreadCrumb, Container, SupplierGroup } from './styles';

interface ISupplierProps {
  supplierToDetail: IClientQuotationSupplier;
  setSupplierToDetail: Dispatch<
    SetStateAction<IClientQuotationSupplier | undefined>
  >;
  roleEntityFields: IRoleEntityField[];
}

const Supplier: React.FC<ISupplierProps> = ({
  supplierToDetail,
  setSupplierToDetail,
  roleEntityFields,
}) => {
  const renderArtworkValue = useCallback(() => {
    const artworkValuesArray: string[] = [];

    if (supplierToDetail.idSupplier2.pdf) artworkValuesArray.push('PDF');
    if (supplierToDetail.idSupplier2.ai) artworkValuesArray.push('AI');
    if (supplierToDetail.idSupplier2.corel17) {
      artworkValuesArray.push('Corel17');
    }
    if (supplierToDetail.idSupplier2.corel20) {
      artworkValuesArray.push('Corel20');
    }
    if (supplierToDetail.idSupplier2.jpeg) artworkValuesArray.push('JPEG');
    if (supplierToDetail.idSupplier2.psd) artworkValuesArray.push('PSD');
    if (supplierToDetail.idSupplier2.indd) artworkValuesArray.push('INDD');
    if (supplierToDetail.idSupplier2.xls) artworkValuesArray.push('xls');
    if (supplierToDetail.idSupplier2.otherFormat) {
      artworkValuesArray.push(supplierToDetail.idSupplier2.otherFormat);
    }

    return artworkValuesArray.join(', ');
  }, [supplierToDetail]);

  const supplierBankAccounts = supplierToDetail.idSupplier2.supplierBankAccounts
    ?.length
    ? supplierToDetail.idSupplier2.supplierBankAccounts[0]
    : undefined;

  const {
    idFieldPaymentTermAdvance,
    idFieldPaymentTermBalance,
    idFieldIdPaymentTermCondition,
    idFieldProductionTime,
    idFieldIdSupplier,
    idFieldCompanyName,
    idFieldIdPort,
    idFieldIdIncoterm,
    idFieldIdIncotermSecond,
    idFieldAtwork,
    idFieldEstimatedTotalAdvance,
    idFieldEstimatedTotalBalance,
    idFieldAmountCot,
    idFieldGrossMargin,
    idFieldBeneficiaryName,
    idFieldMainBankName,
    idFieldMainBankAddress,
    idFieldIdCountry,
    idFieldIdRegion,
    idFieldIdSecondCountry,
    idFieldSwift,
    idFieldAccountNumber,
    idFieldIban,
    idFieldIntermediaryBeneficiaryName,
    idFieldIntermediaryBankAddress,
    idFieldIntermediaryBankCountry,
    idFieldIntermediaryBankState,
    idFieldIntermediaryBankCity,
    idFieldIntermediarySwift,
    idFieldIntermediaryAccountNumber,
    idFieldIntermediaryIban,
  } = clientQuotationRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  return (
    <Container>
      <BreadCrumb>
        <button type="button" onClick={() => setSupplierToDetail(undefined)}>
          List of Suppliers
        </button>
        <FiChevronRight />
        <p>{supplierToDetail.idSupplier2.sunNumber}</p>
      </BreadCrumb>

      <Form initialData={supplierToDetail} onSubmit={() => ''}>
        <SupplierGroup>
          <FormTitle className="fill-all" name="Supplier Info" />

          <Link
            className="link"
            to={`/suppliers/list/${supplierToDetail.idSupplier2.idSupplier}`}
            target="_blank"
          >
            {showField(idFieldIdSupplier) && (
              <FormInput
                name="idSupplier2.sunNumber"
                label="Supplier"
                readOnly
              />
            )}
            <FiExternalLink size={15} title="Open Supplier" />
          </Link>

          {showField(idFieldCompanyName) && (
            <FormInputTextArea
              className="supplier-address"
              name="supplierInfo"
              label="Supplier Info"
              readOnly
              value={`${supplierToDetail.idSupplier2.name}${
                supplierToDetail.idSupplier2.address
                  ? `/${supplierToDetail.idSupplier2.address}`
                  : ''
              }`}
              rows={3}
            />
          )}

          {showField(idFieldProductionTime) && (
            <FormInputNumber
              name="idSupplier2.productionTime"
              label="Production Time (days)"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldIdPort) && (
            <FormInput
              name="portOfLoading"
              label="Port/Airport Of Loading"
              readOnly
              value={
                supplierToDetail.idSupplier2.supplierPorts?.length
                  ? supplierToDetail.idSupplier2.supplierPorts[0]?.idPort2.name
                  : ''
              }
            />
          )}
          {showField(idFieldIdIncoterm) && (
            <FormInput
              name="idSupplier2.idIncoterm2.description"
              label="Incoterm"
              readOnly
            />
          )}

          {showField(idFieldIdIncotermSecond) && (
            <FormInput
              name="idSupplier2.idIncotermSecond2.description"
              label="Second Incoterm"
              readOnly
            />
          )}

          {showField(idFieldAtwork) && (
            <FormInput
              className="fill-from-second"
              name="artwork"
              label="Artwork Format"
              readOnly
              value={renderArtworkValue()}
            />
          )}
        </SupplierGroup>

        <SupplierGroup>
          <FormTitle className="fill-all" name="Supplier Payment Details" />

          {showField(idFieldPaymentTermAdvance) && (
            <FormInputNumber
              name="paymentTermAdvance"
              label="Payment Term Advance (%)"
              suffix="%"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldPaymentTermBalance) && (
            <FormInputNumber
              name="paymentTermBalance"
              label="Payment Term Balance (%)"
              suffix="%"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldIdPaymentTermCondition) && (
            <FormInput
              name="paymentTermCondition"
              label="Payment Term condition"
              readOnly
              value={supplierToDetail.idPaymentTermCondition2?.description}
            />
          )}

          {showField(idFieldEstimatedTotalAdvance) && (
            <FormInputNumber
              name="estimatedTotalAdvance"
              label="Estimated Total Advance"
              prefix="$"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldEstimatedTotalBalance) && (
            <FormInputNumber
              name="estimatedTotalBalance"
              label="Estimated Total Balance"
              prefix="$"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldAmountCot) && (
            <FormInputNumber
              name="amountCot"
              label="Total Purchase PI"
              prefix="$"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}

          {showField(idFieldGrossMargin) && (
            <FormInputNumber
              name="grossMargin"
              label="Gross Margin (%)"
              suffix="%"
              readOnly
              thousandSeparator="."
              decimalSeparator=","
            />
          )}
        </SupplierGroup>

        <SupplierGroup>
          <FormTitle className="fill-all" name="Supplier Bank Details" />

          {showField(idFieldBeneficiaryName) && (
            <FormInput
              className="fill-to-second"
              name="beneficiaryName"
              label="Beneficiary Name"
              readOnly
              value={supplierBankAccounts?.beneficiaryName || ''}
            />
          )}

          {showField(idFieldMainBankName) && (
            <FormInput
              name="mainBankName"
              label="Main Bank Name"
              readOnly
              value={supplierBankAccounts?.mainBankName || ''}
            />
          )}

          {showField(idFieldMainBankAddress) && (
            <FormInput
              className="fill-all"
              name="mainBankAddress"
              label="Main Bank Address"
              readOnly
              value={supplierBankAccounts?.mainBankAddress || ''}
            />
          )}

          {showField(idFieldIdCountry) && (
            <FormInput
              name="mainBankCountry"
              label="Country"
              readOnly
              value={supplierBankAccounts?.idCountry2?.name || ''}
            />
          )}

          {showField(idFieldIdRegion) && (
            <FormInput
              name="mainBankRegion"
              label="State/Provincy"
              readOnly
              value={supplierBankAccounts?.idRegion2?.name || ''}
            />
          )}

          {showField(idFieldIdSecondCountry) && (
            <FormInput
              name="mainBankCity"
              label="City"
              readOnly
              value={supplierBankAccounts?.bankCity || ''}
            />
          )}

          {showField(idFieldSwift) && (
            <FormInput
              name="swift"
              label="Swift"
              readOnly
              value={supplierBankAccounts?.swift || ''}
            />
          )}

          {showField(idFieldAccountNumber) && (
            <FormInput
              name="accountNumber"
              label="Account Number"
              readOnly
              value={supplierBankAccounts?.accountNumber || ''}
            />
          )}

          {showField(idFieldIban) && (
            <FormInput
              name="iban"
              label="Iban"
              readOnly
              value={supplierBankAccounts?.iban || ''}
            />
          )}
        </SupplierGroup>

        {supplierBankAccounts?.isIntermediaryBank && (
          <SupplierGroup>
            <FormTitle className="fill-all" name="Intermediary" />

            {showField(idFieldIntermediaryBeneficiaryName) && (
              <FormInput
                name="intermediaryBeneficiaryName"
                label="Intermediary Beneficiary Name"
                readOnly
                value={supplierBankAccounts?.intermediaryBeneficiaryName || ''}
              />
            )}

            {showField(idFieldIntermediaryBankAddress) && (
              <FormInput
                className="fill-from-second"
                name="intermediaryBankAddress"
                label="Intermediary Bank Address"
                readOnly
                value={supplierBankAccounts?.intermediaryBankAddress || ''}
              />
            )}

            {showField(idFieldIntermediaryBankCountry) && (
              <FormInput
                name="intermediaryBankCountry"
                label="Intermediary Bank Country"
                readOnly
                value={supplierBankAccounts?.idSecondCountry2?.name || ''}
              />
            )}

            {showField(idFieldIntermediaryBankState) && (
              <FormInput
                name="intermediaryBankState"
                label="Intermediary Bank State/Provincy"
                readOnly
                value={supplierBankAccounts?.idSecondRegion2?.name || ''}
              />
            )}

            {showField(idFieldIntermediaryBankCity) && (
              <FormInput
                name="intermediaryBankCity"
                label="Intermediary Bank City"
                readOnly
                value={supplierBankAccounts?.intermediaryBankCity || ''}
              />
            )}

            {showField(idFieldIntermediarySwift) && (
              <FormInput
                name=" intermediarySwift"
                label="Intermediary Swift"
                readOnly
                value={supplierBankAccounts?.intermediarySwift || ''}
              />
            )}

            {showField(idFieldIntermediaryAccountNumber) && (
              <FormInput
                name="intermediaryAccountNumber"
                label="Intermediary Account Number"
                readOnly
                value={supplierBankAccounts?.intermediaryAccountNumber || ''}
              />
            )}

            {showField(idFieldIntermediaryIban) && (
              <FormInput
                name="intermediaryIban"
                label="Intermediary Iban"
                readOnly
                value={supplierBankAccounts?.intermediaryIban || ''}
              />
            )}
          </SupplierGroup>
        )}
      </Form>
    </Container>
  );
};

export default Supplier;
