import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .p-inputtext {
    margin-right: 10px;
  }
`;

export const Row = styled.div`
  width: 100%;

  .p-inputtext {
    width: 100%;
  }
`;
