import React from 'react';
import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';
import { useRefHook } from '../../../../../hooks/useRefHook';

import { FileType } from '../../../../../shared/enums/fileType';
import FileUpload from '../../../../../components/FileUpload';
import { createSupplierAttachmentsQuery } from '../queries';
import { FileUploadResponse } from '../../../../../components/FileUpload/interfaces';

interface IAttachmentsUploadProps {
  idSupplier: number;
  displayModal: boolean;
  supplierAttachmentsRefetch(): void;
  setDisplayModal(e: boolean): void;
}

const AttachmentsUpload: React.FC<IAttachmentsUploadProps> = ({
  idSupplier,
  displayModal,
  supplierAttachmentsRefetch,
  setDisplayModal,
}) => {
  const { showError, showSuccess } = useRefHook();

  const [createSupplierAttachmentsMutation, { loading }] = useMutation(
    createSupplierAttachmentsQuery,
  );

  function handleCloseModal() {
    setDisplayModal(false);
  }

  async function handleConfirmUpload(e: FileUploadResponse[]) {
    try {
      await createSupplierAttachmentsMutation({
        variables: {
          data: {
            idSupplier,
            uploadedFiles: e.map(file => file.serverName),
          },
        },
      });

      showSuccess({
        summary: 'Files created',
      });

      supplierAttachmentsRefetch();

      handleCloseModal();
    } catch (error) {
      showError({
        summary: 'Error while creating files',
        detail: error.message,
      });
    }
  }

  return (
    <Container>
      <Dialog
        header="Add an Attachment"
        visible={displayModal}
        style={{ width: '40vw' }}
        onHide={() => handleCloseModal()}
        closable
      >
        <FileUpload
          multiple
          accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.TXT},${FileType.ZIP}`}
          onConfirm={e => handleConfirmUpload(e)}
          uploadInProgress={loading}
        />
      </Dialog>
    </Container>
  );
};

export default AttachmentsUpload;
