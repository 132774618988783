import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  // columnList.push({ field: 'sunNumber', header: 'SUN' });
  // columnList.push({ field: 'companyName', header: 'Formal Company Name' });
  // columnList.push({ field: 'tax', header: 'Tax' });
  // columnList.push({ field: 'idCountry2.name', header: 'Country' });
  // columnList.push({ field: 'idRegion2.name', header: 'Region' });
  // columnList.push({ field: 'idCity2.name', header: 'City' });
  // columnList.push({ field: 'productionTime', header: 'Production Time' });
  // columnList.push({ field: 'samplesLeadTime', header: 'Samples Lead Time' });
  // columnList.push({ field: 'idIncoterm2.description', header: 'Incoterm' });
  // columnList.push({ field: 'idIncotermSecond2.description', header: 'Second Incoterm' });
  // columnList.push({ field: 'paymentAdvance', header: 'Payment Term Advance' });
  // columnList.push({ field: 'paymentBalance', header: 'Payment Term Balance' });
  // columnList.push({ field: 'address', header: 'Address' });
  // columnList.push({ field: 'zipCode', header: 'Zip Code' });
  // columnList.push({ field: 'organization', header: 'Organization' });
  // columnList.push({ field: 'idPayment2.description', header: 'Payment Term Condition' });
  // columnList.push({ field: 'exporter', header: 'Exporter' });
  // columnList.push({ field: 'portsAirportsString', header: 'Port/Airport of Loading' });
  // columnList.push({ field: 'supplierCategoriesString', header: 'Categories' });
  sunNumber: {
    field: 'sunNumber',
    header: 'SUN',
    advancedFilterField: 'sunNumber',
  },
  name: { field: 'name', header: 'Supplier Name', advancedFilterField: 'name' },
  companyName: {
    field: 'companyName',
    header: 'Formal Company Name',
    advancedFilterField: 'companyName',
  },
  tax: { field: 'tax', header: 'Tax', advancedFilterField: 'tax' },
  idCountry: {
    field: 'idCountry2.name',
    header: 'Country',
    advancedFilterField: 'idCountry',
  },
  idRegion: {
    field: 'idRegion2.name',
    header: 'State/Province',
    advancedFilterField: 'idRegion',
  },
  idCity: {
    field: 'idCity2.name',
    header: 'City',
    advancedFilterField: 'idCity',
  },
  productionTime: {
    field: 'productionTime',
    header: 'Production Time',
    advancedFilterField: 'productionTime',
  },
  samplesLeadTime: {
    field: 'samplesLeadTime',
    header: 'Samples Lead Time',
    advancedFilterField: 'samplesLeadTime',
  },
  idIncoterm: {
    field: 'idIncoterm2.description',
    header: 'Incoterm',
    advancedFilterField: 'idIncoterm',
  },
  idIncotermSecond: {
    field: 'idIncotermSecond2.description',
    header: 'Incoterm Second',
    advancedFilterField: 'idIncotermSecond',
  },
  paymentAdvance: {
    field: 'paymentAdvance',
    header: 'Payment Term Advance',
    advancedFilterField: 'paymentAdvance',
  },
  paymentBalance: {
    field: 'paymentBalance',
    header: 'Payment Term Balance',
    advancedFilterField: 'paymentBalance',
  },
  address: {
    field: 'address',
    header: 'Address',
    advancedFilterField: 'address',
  },
  zipCode: {
    field: 'zipCode',
    header: 'Zip Code',
    advancedFilterField: 'zipCode',
  },
  organization: {
    field: 'organization',
    header: 'Organization',
    advancedFilterField: 'organization',
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    advancedFilterField: 'exporter',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  paymentTermCondition: {
    field: 'idPayment2.description',
    header: 'Payment Term Condition',
    advancedFilterField: 'paymentTermCondition',
  },
  portAirportName: {
    field: 'portAirportName',
    header: 'Port/Airport of Loading',
    advancedFilterField: 'portAirportName',
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
  },
};
