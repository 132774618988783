import { gql } from '@apollo/client';

// Query para buscar arquivo template
export const getAttachmentDownloadLinkQuery = gql`
  query getAttachmentDownloadLinkQuery($name: String!) {
    getAttachmentDownloadLink(name: $name)
  }
`;

// Query para baixar arquivo do storage pelo servidor
export const downloadAttachmentFromServerQuery = gql`
  query downloadAttachmentFromServer(
    $name: String!
    $folder: String!
    $newName: String
  ) {
    downloadAttachmentFromServer(
      name: $name
      folder: $folder
      newName: $newName
    )
  }
`;

// Query para baixar arquivo do storage pelo servidor
export const downloadAttachmentsFromServerQuery = gql`
  query downloadAttachmentsFromServer(
    $data: [DownloadAttachmentsFromServerInput]!
    $fileName: String
  ) {
    downloadAttachmentsFromServer(data: $data, fileName: $fileName)
  }
`;
