/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react';
import { gql, useQuery } from '@apollo/client';
import FormInput from '../../../../../components/FormInput';
import { Container } from './styles';
import { ISupplierContact } from '../../interfaces';
import { IDomain } from '../../../../../services/dataInterface';
import FormDropdown from '../../../../../components/FormDropdown';
import FormInputTextArea from '../../../../../components/FormInputTextArea';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../../interfaces/IRoleEntityField';
import { supplierRoles } from '../../../../../shared/roles/supplier';
import getFieldPermission from '../../../../../utils/getFieldPermission';

interface IAddContactProps {
  newContact: ISupplierContact;
  setNewContact: Dispatch<SetStateAction<ISupplierContact>>;
  roleEntityFields: IRoleEntityField[];
}
const AddContact: React.FC<IAddContactProps> = ({
  setNewContact,
  newContact,
  roleEntityFields,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  const listRoleQuery = gql`
    query ListDomainsByGroupIdQuery($id: Int!, $pagination: Pagination!) {
      listDomainsByGroupId(id: $id, pagination: $pagination) {
        data {
          idDomain
          description
          versionLock
        }
        items
      }
    }
  `;

  const {
    loading: roleLoading,
    data: roleData,
    error: roleError,
  } = useQuery(listRoleQuery, {
    variables: {
      id: 17,
      pagination: {
        _page: 1,
        _limit: 25,
      },
    },
  });

  function onRoleChange(pRole: Record<string, unknown>) {
    const newRole: IDomain = {
      idDomain: parseInt(`${pRole.idDomain}`, 10),
      description: `${pRole.description}`,
    };

    setNewContact({
      ...newContact,
      idRole2: newRole,
    });
  }

  const changePropertyValue = () => {
    const name = formRef.current?.getFieldValue('nameContact');
    if (!name) {
      formRef.current?.setFieldError('nameContact', 'Contact name is required');
      return;
    }
    // Esvazia possíveis erros já existentes no formulário
    formRef.current?.setErrors({});
    setNewContact({
      ...newContact,
      name,
    });
  };

  const { idFieldEmail, idFieldIdRole, idFieldTelephone } =
    supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    const id = newContact.idSupplierContact;
    return id && typeof id === 'number'
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <Container>
      {showField(idFieldIdRole) && (
        <FormDropdown
          name="role"
          label="Role"
          readOnly={
            roleLoading ||
            roleError !== undefined ||
            disableField(idFieldIdRole)
          }
          options={
            roleLoading || roleError
              ? undefined
              : roleData.listDomainsByGroupId.data
          }
          optionLabel="description"
          placeholder={roleLoading ? 'Loading' : ''}
          initialValue={newContact.idRole2}
          filter
          filterBy="description"
          onValueChange={onRoleChange}
        />
      )}

      <FormInput
        name="nameContact"
        label="Name"
        defaultValue={newContact.name}
        onChange={changePropertyValue}
        required
      />

      {showField(idFieldEmail) && (
        <FormInput
          name="email"
          label="E-mail"
          defaultValue={newContact.email}
          onChange={e =>
            setNewContact({ ...newContact, email: e.target.value })
          }
          readOnly={disableField(idFieldEmail)}
        />
      )}

      {showField(idFieldTelephone) && (
        <FormInputTextArea
          name="telephone"
          label="Telephone"
          defaultValue={newContact.telephone}
          onChange={e =>
            setNewContact({ ...newContact, telephone: e.target.value })
          }
          rows={5}
          cols={73}
          readOnly={disableField(idFieldTelephone)}
        />
      )}
    </Container>
  );
};

export default AddContact;
