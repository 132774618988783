/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useParams, useLocation, useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';

import { useRefHook } from '../../../hooks/useRefHook';
import { Container, CrudForm } from './styles';

import {
  queryToUpdate,
  queryToListIdDomain,
  queryToCreateDomain,
} from '../../../services/querysGql';
import { IGeneralInformationFormData } from '../../../services/dataInterface';

import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Loading from '../../../components/Loading';

import getValidationErrors from '../../../utils/getValidationErrors';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

interface ClientTypeOfBusinessRouteParams {
  clientTypeOfBusinessId: string;
}
const idGroupToTypeOfBusiness = 7;

const ClientTypeOfBusiness: React.FC = () => {
  // ID do tipo de negocio do cliente
  const { clientTypeOfBusinessId } =
    useParams<ClientTypeOfBusinessRouteParams>();

  const defaultPageTitle = `${
    clientTypeOfBusinessId ? 'Manage' : 'Create'
  } Type of Business`;

  // Nome do tipo de negocio do cliente
  const [clientTypeOfBusinessName, setClientTypeOfBusinessName] = useState('');

  useTitle(
    clientTypeOfBusinessName
      ? `${clientTypeOfBusinessName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao formulario
  const { formRef, toastRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Estado da versão lock otimista
  const [versionLock, setVersionLock] = useState();

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Type of Business',
      path: '/clients/type-of-business',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Carrega response do tipo de negocio do cliente
  const [
    loadClientTypeOfBusinessData,
    {
      called: clientTypeOfBusinessCalled,
      loading: clientTypeOfBusinessLoading,
      data: clientTypeOfBusinessData,
      error: clientTypeOfBusinessError,
    },
  ] = useLazyQuery(queryToListIdDomain, {
    variables: {
      idDomain: parseInt(clientTypeOfBusinessId, 10),
    },
    onCompleted: async response => {
      // Valida se o tipo de negocio do cliente foi encontrado
      if (
        !response.listDomainById ||
        response.listDomainById.idGroup !== idGroupToTypeOfBusiness
      ) {
        // Exibe erro
        toastRef.current?.show({
          severity: 'error',
          summary: 'Client type of business not found',
          life: ToastLife.ERROR,
        });
        // Redireciona para listagem de tipos de negocio do cliente
        history.push('/clients/type-of-business');
        return;
      }
      // atualiza estado lock otimista ao carregar a pagina
      setVersionLock(response.listDomainById.versionLock);

      // Define novo nome do tipo de negocio do cliente
      setClientTypeOfBusinessName(response.listDomainById.description);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Client type of business data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/clients/type-of-business');
    },
  });

  // Ao carregar a pagina, carrega os tipos de negocio do cliente cadastrados
  useEffect(() => {
    if (clientTypeOfBusinessId) {
      setPageLoading(true);
      loadClientTypeOfBusinessData();
    }
  }, [clientTypeOfBusinessId, loadClientTypeOfBusinessData]);

  // cria método para chamar a mutation
  const [createClientTypeOfBusiness] = useMutation(queryToCreateDomain);
  const [updateClientTypeOfBusiness] = useMutation(queryToUpdate);

  // Submit para criação de um tipo de negocio do cliente
  const handleCreateClientTypeOfBusinessSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Cria um tipo de negocio do cliente
        await createClientTypeOfBusiness({
          variables: {
            domainData: {
              description,
              idGroup: idGroupToTypeOfBusiness,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client type of business created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem dos tipos de negocio do cliente
        history.push('/clients/type-of-business');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);
          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating Client type of business',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [formRef, createClientTypeOfBusiness, toastRef, history],
  );

  const handleEditClientTypeOfBusinessSubmit = useCallback(
    async (formData: IGeneralInformationFormData) => {
      setPageLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          description: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // Separa cada propriedade
        const { description } = formData;

        // Atualiza informacoes do tipo de negocio do cliente
        const updateResponse = await updateClientTypeOfBusiness({
          variables: {
            domainData: {
              idDomain: parseInt(clientTypeOfBusinessId, 10),
              description,
              versionLock,
            },
          },
        });
        // atualiza estado de acordo novo registro
        setVersionLock(updateResponse.data.updateDomain.versionLock);

        // Define novo nome do tipo de negocio do cliente
        setClientTypeOfBusinessName(description);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Client type of business updated',
          life: ToastLife.SUCCESS,
        });
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating Client type of business',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      formRef,
      updateClientTypeOfBusiness,
      clientTypeOfBusinessId,
      versionLock,
      toastRef,
    ],
  );

  const handleCancel = () => {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/clients/type-of-business'),
    });
  };

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          clientTypeOfBusinessData
            ? `${defaultPageTitle} - ${clientTypeOfBusinessName}`
            : defaultPageTitle
        }
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />
      </PageHeader>
      <CrudForm>
        <Form
          ref={formRef}
          onSubmit={
            clientTypeOfBusinessId
              ? handleEditClientTypeOfBusinessSubmit
              : handleCreateClientTypeOfBusinessSubmit
          }
          initialData={
            clientTypeOfBusinessCalled &&
            !clientTypeOfBusinessLoading &&
            !clientTypeOfBusinessError
              ? clientTypeOfBusinessData.listDomainById
              : undefined
          }
        >
          <FormInput
            className="generalInput"
            name="description"
            label="Name"
            required
          />
        </Form>
      </CrudForm>
      {pageLoading && <Loading />}
    </Container>
  );
};
export default ClientTypeOfBusiness;
