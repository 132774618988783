/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

const TransparentButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  type,
  ...props
}) => {
  return (
    <Container>
      <button type={type} {...props}>
        {children}
      </button>
    </Container>
  );
};

export default TransparentButton;
