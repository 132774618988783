import { gql } from '@apollo/client';

export const getToShipmentRequestQuery = gql`
  query GetSatForeignTradeToShipmentRequest($idSatForeignTrade: Int!) {
    getSatForeignTradeToShipmentRequest(idSatForeignTrade: $idSatForeignTrade) {
      mainProduct
      realInspectionDate
      qtyTypeCtnr
      portOfLoading
      portOfDischarge
      buyer
      clientOrder
      totalSunPi
    }
  }
`;

export const sendShipmentRequestQuery = gql`
  mutation SendShipmentRequest($data: SendShipmentRequestInput!) {
    sendShipmentRequest(data: $data)
  }
`;
