import { FinancialSellerOtherExpenses } from '../../../../../../../shared/enums/domains';
import {
  ManageOtherExpenseReducer,
  ManageOtherExpenseReducerAction,
  ManageOtherExpenseReducerActionKind,
} from './interfaces';

export const manageOtherExpenseInitialState: ManageOtherExpenseReducer = {
  displayDialog: false,
};

export const manageOtherExpenseReducer = (
  state: ManageOtherExpenseReducer,
  action: ManageOtherExpenseReducerAction,
): ManageOtherExpenseReducer => {
  switch (action.type) {
    case ManageOtherExpenseReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        otherExpenseToEdit: undefined,
        flagsAndCalcBasisFieldsDisabled: undefined,
        totalFieldDisabled: undefined,
        calculationBasisRequired: undefined,
      };

    case ManageOtherExpenseReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.otherExpense) {
        let flagsAndCalcBasisFieldsDisabled = false;
        let totalFieldDisabled = false;
        let calculationBasisRequired = false;

        const { idType, calculationBasis2 } = action.payload.otherExpense;

        switch (idType) {
          case FinancialSellerOtherExpenses.FREIGHT:
          case FinancialSellerOtherExpenses.FREIGHT_TAXES:
            flagsAndCalcBasisFieldsDisabled = true;
            break;
          case FinancialSellerOtherExpenses.FOB_CHARGES:
          case FinancialSellerOtherExpenses.YIWU_COMISSION:
            flagsAndCalcBasisFieldsDisabled = true;
            totalFieldDisabled = true;
            break;
          default:
            break;
        }

        if (!flagsAndCalcBasisFieldsDisabled && calculationBasis2) {
          calculationBasisRequired = true;
        }

        return {
          ...state,
          otherExpenseToEdit: action.payload?.otherExpense,
          displayDialog: true,
          flagsAndCalcBasisFieldsDisabled,
          totalFieldDisabled,
          calculationBasisRequired,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    case ManageOtherExpenseReducerActionKind.SET_CALCULATION_BASIS_REQUIRED:
      if (!action.payload) return state;

      return {
        ...state,
        calculationBasisRequired: action.payload.calculationBasisRequired,
      };

    default:
      throw new Error();
  }
};
