/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Menu } from 'primereact/menu';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import { airportTaxRoles } from '../../../shared/roles/airportTax';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import {
  parseDateTimeColumm,
  parseFloatColumnPt,
  parseNullableBooleanColumn,
} from '../../../utils/gridColumnsParse';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './constants';
import {
  createAirportTaxesBulkQuery,
  updateAirportTaxesBulkQuery,
} from './queries';
import FileUpload from '../../../components/FileUpload';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import { FileType } from '../../../shared/enums/fileType';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

/**
 * Interface dos parametros do backend
 */
interface IAirportTaxesLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  airportTaxName?: string;
  airportDestination?: string;
  idCurArm?: string;
  taxValueArm?: number;
  idBcValueArm?: string;
  bcCifArm?: boolean;
  idCurDes?: string;
  taxValueDes?: number;
  idBcValueDes?: string;
  bcCifDes?: boolean;
}

interface IBulkResponse {
  airportTaxesTotal: number;
  airportTaxesWithError: string[];
  createdAirportTaxes?: number;
  updatedAirportTaxes?: number;
  type: 'CREATE' | 'UPDATE';
}

const screenSubject = 'Airport Taxes';
const pageTitle = `List of ${screenSubject}`;

const AirportTaxes: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:airportTaxesGridColumns';

  const lazyParamsName = '@SAT:airportTaxesLazyParams';

  const advancedFiltersName = '@SAT:airportTaxesAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { showError, showSuccess } = useRefHook();

  const gridRef = useRef<GridRef<any>>(null);
  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);
  const massiveActionMenuRef = useRef<Menu>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Estado de resposta da acao e massa
  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // URL params
  const location = useLocation();

  // Permissions de Airport Taxes
  const {
    idPermissionCreateAirportTax,
    idPermissionDeleteAirportTax,
    idPermissionMassiveActionAirportTax,
    idPermissionExportListAirportTax,
  } = airportTaxRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirportTax = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    airportTaxRoles.idEntity,
  );

  const roleEntityPermissions = userPermissionsAirportTax.userPermissions;

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;
  // Busca
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'airport_bulk_template.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IAirportTaxesLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: IAirportTaxesLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: IAirportTaxesLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // AirportTaxes selecionados
  const [selectedAirportTaxes, setSelectedAirportTaxes] = useState([]);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar Airport Taxes
  const listAirportTaxesQuery = gql`
    query listAllAirportTaxesQuery(
      $listAllAirportTaxesInput: ListAllAirportTaxesInput!
    ) {
      listAllAirportTaxes(listAllAirportTaxesInput: $listAllAirportTaxesInput) {
        data {
          idAirportTax
          airportTaxName
          idAirportDestination2 {
            name
          }
          idCurDes2 {
            abbreviation
          }
          taxValueDes
          idBcValueDes2 {
            description
          }
          bcCifDes
          updatedAtDes
          idCurArm2 {
            abbreviation
          }
          taxValueArm
          idBcValueArm2 {
            description
          }
          bcCifArm
          updatedAtArm
        }
        items
      }
    }
  `;

  // Query para deletar Airport Taxes
  const deleteAirportTaxesQuery = gql`
    mutation DisableLogisticAirportTaxes($ids: [Int]!) {
      disableLogisticAirportTaxes(ids: $ids)
    }
  `;

  // cria método para chamar a mutation
  const [deleteAirportTaxes] = useMutation(deleteAirportTaxesQuery);

  /**
   * Busca Airport Taxes
   */
  const {
    loading: airportTaxesLoading,
    data: airportTaxesData,
    refetch: airportTaxesRefetch,
  } = useQuery(listAirportTaxesQuery, {
    variables: {
      listAllAirportTaxesInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        airportDestination: lazyParams.airportDestination,
        airportTaxName: lazyParams.airportTaxName,
        taxValueDes: lazyParams.taxValueDes,
        idCurDes: lazyParams.idCurDes,
        idBcValueDes: lazyParams.idBcValueDes,
        bcCifDes: lazyParams.bcCifDes,
        taxValueArm: lazyParams.taxValueArm,
        idCurArm: lazyParams.idCurArm,
        idBcValueArm: lazyParams.idBcValueArm,
        bcCifArm: lazyParams.bcCifArm,
        active: true,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Airport Taxes',
        detail: errorData.message,
      });
    },
  });

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'updatedAtDes':
      case 'updatedAtArm':
        return parseDateTimeColumm;
      case 'taxValueDes':
      case 'taxValueArm':
        return parseFloatColumnPt;
      case 'bcCifDes':
      case 'bcCifArm':
        return parseNullableBooleanColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'idCurArm2.abbreviation':
        return { width: '150px' };
      case 'taxValueDes':
      case 'taxValueArm':
        return { width: '160px' };
      case 'updatedAtDes':
      case 'updatedAtArm':
      case 'updatedAtPes':
      case 'updatedAtSca':
      case 'updatedAtPos':
        return { width: '190px' };
      case 'airportTaxName':
      case 'idAirportDestination2.name':
        return { width: '240px' };
      default:
        return { width: '145px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/logistics/airportTaxes/${e.data.idAirportTax}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta Airport Taxes selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedAirportTaxes.length} Airport Taxes?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada Airport Tax
        const idsToDelete = selectedAirportTaxes.map(
          (a: any) => a.idAirportTax,
        );
        try {
          // Deleta Airport Taxes
          await deleteAirportTaxes({
            variables: {
              ids: idsToDelete,
            },
          });

          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedAirportTaxes.length} Airport Taxes`,
          });

          // Zera estado de Airport Taxes selecionadas
          setSelectedAirportTaxes([]);

          // Atualiza grid de Airport Taxes
          await airportTaxesRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting Airport Taxes',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  async function exportToXlsx(portTaxesExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    portTaxesExportData.listAllAirportTaxes.data.forEach((airportTax: any) => {
      airportTax.name = airportTax.airportTaxName;
      airportTax.airportDestination = airportTax.idAirportDestination2?.name;
      airportTax.idCurDes = airportTax.idCurDes2?.abbreviation;
      airportTax.bcValueDes = airportTax.idBcValueDes2?.description;
      airportTax.idCurArm = airportTax.idCurArm2?.abbreviation;
      airportTax.bcValueArm = airportTax.idBcValueArm2?.description;
      if (airportTax.bcCifArm !== null) {
        airportTax.bcCifArm = airportTax.bcCifArm ? 'Yes' : 'No';
      }
      if (airportTax.bcCifDes !== null) {
        airportTax.bcCifDes = airportTax.bcCifDes ? 'Yes' : 'No';
      }

      columnsToRemove.forEach(column => {
        switch (column.field) {
          case 'airportTaxName':
            delete airportTax.name;
            break;
          case 'idAirportDestination2.name':
            delete airportTax.airportDestination;
            break;
          case 'idCurDes2.abbreviation':
            delete airportTax.idCurDes;
            break;
          case 'idBcValueDes2.description':
            delete airportTax.bcValueDes;
            break;
          case 'idCurArm2.abbreviation':
            delete airportTax.idCurArm;
            break;
          case 'idBcValueArm2.description':
            delete airportTax.bcValueArm;
            break;
          default:
            delete airportTax[column.field];
            break;
        }
      });

      // Remover colunas __typename e idAirportTax
      delete airportTax.__typename;
      delete airportTax.idAirportTax;
      delete airportTax.airportTaxName;
      delete airportTax.idAirportDestination2;
      delete airportTax.idCurDes2;
      delete airportTax.idBcValueDes2;
      delete airportTax.idCurArm2;
      delete airportTax.idBcValueArm2;
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idAirportTax' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idAirportDestination2.name':
          columnsOrder[index] = 'airportDestination';
          break;
        case 'airportTaxName':
          columnsOrder[index] = 'name';
          break;
        case 'idCurDes2.abbreviation':
          columnsOrder[index] = 'idCurDes';
          break;
        case 'idBcValueDes2.description':
          columnsOrder[index] = 'bcValueDes';
          break;
        case 'idCurArm2.abbreviation':
          columnsOrder[index] = 'idCurArm';
          break;
        case 'idBcValueArm2.description':
          columnsOrder[index] = 'bcValueArm';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      portTaxesExportData.listAllAirportTaxes.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:AA1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'airportTaxes');
  }

  const [createAirportTaxesBulkMutation] = useMutation(
    createAirportTaxesBulkQuery,
  );
  const [updateAirportTaxesBulkMutation] = useMutation(
    updateAirportTaxesBulkQuery,
  );

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      const response = await createAirportTaxesBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createLogisticAirportTaxesBulk,
        type: 'CREATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while creating aiport taxes',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      const response = await updateAirportTaxesBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateLogisticAirportTaxesBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while updating aiport taxes',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  /**
   * Busca Airport Taxes para exportar para XLSX
   */
  const [
    loadAirportTaxesToExport,
    { loading: airportTaxesToExportLoading, data: airportTaxesToExportData },
  ] = useLazyQuery(listAirportTaxesQuery, {
    onCompleted: () => {
      exportToXlsx(airportTaxesToExportData);
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting Airport Taxes',
        detail: errorData.message,
      });
    },
  });

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>Airport Taxes found in the spreadsheet:</b>{' '}
          {bulkResponse?.airportTaxesTotal}
        </p>
        <p>
          <b>
            Airport Taxes{' '}
            {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.createdAirportTaxes ||
            bulkResponse?.updatedAirportTaxes}
        </p>
        {!!bulkResponse?.airportTaxesWithError?.length && (
          <p>
            <b>Airport Taxes/Cells with error:</b>
            <br />
            {bulkResponse?.airportTaxesWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar Airport Tax */}

        {userHasPermission(
          idPermissionCreateAirportTax,
          userPermissionsAirportTax.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Airport Tax"
            onClick={() => history.push('/logistics/airportTaxes/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir Airport Taxes selecionados */}
        {userHasPermission(
          idPermissionDeleteAirportTax,
          userPermissionsAirportTax.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedAirportTaxes.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {hasPermission(idPermissionMassiveActionAirportTax) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}

        {userHasPermission(
          idPermissionExportListAirportTax,
          userPermissionsAirportTax.userPermissions,
        ) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={airportTaxesToExportLoading}
            onClick={() => {
              loadAirportTaxesToExport({
                variables: {
                  listAllAirportTaxesInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    airportTaxName: lazyParams.airportTaxName,
                    airportDestination: lazyParams.airportDestination,
                    idCurArm: lazyParams.idCurArm,
                    taxValueArm: lazyParams.taxValueArm,
                    idBcValueArm: lazyParams.idBcValueArm,
                    bcCifArm: lazyParams.bcCifArm,
                    idCurDes: lazyParams.idCurDes,
                    taxValueDes: lazyParams.taxValueDes,
                    idBcValueDes: lazyParams.idBcValueDes,
                    bcCifDes: lazyParams.bcCifDes,
                    active: true,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a Airport Tax"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="airportTaxes"
        lazy
        totalRecords={
          !airportTaxesData ? 0 : airportTaxesData.listAllAirportTaxes?.items
        }
        value={
          !airportTaxesData
            ? undefined
            : airportTaxesData.listAllAirportTaxes?.data
        }
        globalFilter={globalFilter}
        emptyMessage="No Airport Taxes found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedAirportTaxes}
        onSelectionChange={e => setSelectedAirportTaxes(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteAirportTax,
          userPermissionsAirportTax.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || airportTaxesLoading) && <Loading />}
    </div>
  );
};
export default AirportTaxes;
