import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  TreeTableEvent,
  TreeTablePageEvent,
  TreeTableSelectionEvent,
} from 'primereact/treetable';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import TreeGrid from '../../../components/TreeGrid';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';
import { categoryRoles } from '../../../shared/roles/category';
import { Container } from './styles';
import { useAuth } from '../../../hooks/useAuth';
import userHasPermission from '../../../utils/userHasPermission';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: 1 | 0 | -1 | null;
  globalFilter?: string;
  category?: string;
  checklist?: string;
}

const pageTitle = 'List of Categories';

const Categories: React.FC = () => {
  useTitle(pageTitle);

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Category
  const { idPermissionDeleteCategory } = categoryRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsCategory = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    categoryRoles.idEntity,
  );

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Categories
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState(0);

  // Categories selecionadas
  // TreeTableSelectionParams [key: string]: boolean
  const [selectedCategories, setSelectedCategories] = useState([] as any);

  // Busca global na treeGrid
  const [globalFilter, setGlobalFilter] = useState('');

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de scrollHeight da treeGrid
  const [treeGridScrollHeight, setTreeGridScrollHeight] = useState(
    'calc(100vh - 294px)',
  );

  /**
   * Obtem nome correto para ordenacao por coluna no backend
   * @returns Parametro para ordenacao por coluna
   */
  function getOrderBy(): string {
    switch (lazyParams.sortField) {
      case 'name':
        return 'c.name';
      case 'idCheckList2.name':
        return 'checkList';
      default:
        return '';
    }
  }

  // Query para listar categories
  const listCategoriesQuery = gql`
    query listAllCategoriesQuery(
      $listAllCategoriesInput: ListAllCategoriesInput
    ) {
      listAllCategories(listAllCategoriesInput: $listAllCategoriesInput) {
        data
        items
      }
    }
  `;

  /**
   * Busca Categories
   */
  const {
    error: categoriesError,
    loading: categoriesLoading,
    refetch: categoriesRefetch,
  } = useQuery(listCategoriesQuery, {
    variables: {
      listAllCategoriesInput: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: getOrderBy(),
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        category: lazyParams.category,
        checklist: lazyParams.category,
        // globalSearch: lazyParams.globalFilter,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting categories',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
        setItems(response.listAllCategories.items);
      } else {
        setCategories([]);
        setItems(0);
      }
    },
  });

  // Query para remover categories
  const deleteCategoriesQuery = gql`
    mutation DeleteCategoriesMutation($ids: [Int]!) {
      deleteCategories(ids: $ids)
    }
  `;

  // Cria mutation
  const [deleteCategories] = useMutation(deleteCategoriesQuery);

  /**
   * Deleta categories selecionados
   */
  function handleDeleteSelected() {
    const selectCat = Object.entries(selectedCategories)
      .filter(item => (item[1] as any).checked)
      .map(r => r[0])
      .map(Number)
      .reverse();

    confirmDialog({
      message: `Are you sure you want to delete ${selectCat.length} categories?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        try {
          // Deleta categories
          await deleteCategories({
            variables: {
              ids: selectCat,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectCat.length} categories`,
            life: ToastLife.SUCCESS,
          });

          // Atualiza treeGrid de categories
          await categoriesRefetch().then(response => {
            if (response.data.listAllCategories) {
              setCategories(JSON.parse(response.data.listAllCategories.data));
              setItems(response.data.listAllCategories.items);
            } else {
              setCategories([]);
              setItems(0);
            }
          });

          // Zera estado de categories selecionadas
          setSelectedCategories([]);
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting categories',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Define scrollHeight da treeGrid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setTreeGridScrollHeight('calc(100vh - 202px)');
      setFixedStickyButtons(true);
    } else {
      setTreeGridScrollHeight('calc(100vh - 294px)');
      setFixedStickyButtons(false);
    }
  }

  /**
   * Direciona usuario para pagina da category clicada
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: TreeTableEvent) {
    history.push(`/list/categories/${e.node.data.idCategory}`);
  }
  /**
   * Direciona usuario para pagina da category clicada
   * @param e Evento de clique na linha da tabela
   */
  function onSelectionChange(e: TreeTableSelectionEvent) {
    e.originalEvent.stopPropagation();
    setSelectedCategories(e.value);
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: TreeTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  return (
    <Container>
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar category */}
        <MainButton
          className="mainButton"
          label="New Category"
          onClick={() => history.push('/list/categories/create')}
        />

        {/* Botao para excluir categories selecionadas */}
        {userHasPermission(
          idPermissionDeleteCategory,
          userPermissionsCategory.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={!Object.keys(selectedCategories).length}
            onClick={() => handleDeleteSelected()}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a category"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <TreeGrid
        className="treeGrid"
        totalRecords={!categories || categoriesError ? 0 : items}
        value={!categories || categoriesError ? undefined : categories}
        globalFilter={globalFilter}
        emptyMessage="No categories found."
        onRowClick={onRowClick}
        scrollable
        scrollHeight={treeGridScrollHeight}
        rows={lazyParams.rows}
        first={!categories || categoriesError ? undefined : lazyParams.first}
        onPage={onPage}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selectionMode={
          userHasPermission(
            idPermissionDeleteCategory,
            userPermissionsCategory.userPermissions,
          )
            ? 'checkbox'
            : undefined
        }
        selectionKeys={selectedCategories}
        onSelectionChange={onSelectionChange}
        propagateSelectionUp={false}
        sortMode="single"
        removableSort
        paginator={false}
      >
        <Column field="name" header="Category" expander sortable />
        <Column field="idCheckList2.name" header="CheckList" sortable />
      </TreeGrid>
      {(pageLoading || categoriesLoading) && <Loading />}
    </Container>
  );
};

export default Categories;
