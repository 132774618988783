import React from 'react';
import { ISat } from '../../../interfaces';
import { FinancialTotal } from '../interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import { FinancialFieldsPermissions } from '../../interfaces';

interface IPurchaseCiProps {
  sat: ISat;
  totalData?: FinancialTotal;
  loading: boolean;
  fieldsPermissions: FinancialFieldsPermissions;
}

const PurchaseCi: React.FC<IPurchaseCiProps> = ({
  sat,
  totalData,
  loading,
  fieldsPermissions,
}) => {
  const satCurrencySymbol = getCurrencySymbol(sat.idCurrency2?.abbreviation);

  const userCanSeeBlock =
    fieldsPermissions.purchaseCiCurrency.view ||
    fieldsPermissions.totalCotCiShipment.view ||
    fieldsPermissions.totalConvertedCotCi.view;

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Purchase CI" />

      {fieldsPermissions.purchaseCiCurrency.view && (
        <ReadOnlyInput
          className="col-2"
          label="Currency"
          value={sat.idCurrency2?.abbreviation}
        />
      )}

      {fieldsPermissions.totalCotCiShipment.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total COT CI"
          prefix={satCurrencySymbol}
          value={totalData?.totalCotCiShipment}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={loading}
          tooltip="Only Product Cost"
          tooltipPosition="bottom"
        />
      )}

      {fieldsPermissions.totalConvertedCotCi.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Converted COT CI (USD)"
          prefix={getCurrencySymbol('USD')}
          value={totalData?.totalCotCiShipmentInDollar}
          type="number"
          locale="pt-BR"
          minimumFractionDigits={2}
          loading={loading}
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default PurchaseCi;
