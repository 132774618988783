/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import {
  IOceanFreight,
  IOceanFreightFormData,
  OceanFreightRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { oceanFreightRoles } from '../../../shared/roles/oceanFreight';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const OceanFreight: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de ocean freight
  const idOceanFreight = parseInt(
    useParams<OceanFreightRouteParams>().idOceanFreight,
    10,
  );

  const defaultPageTitle = `${
    idOceanFreight ? 'Manage' : 'Create'
  } Ocean Freight`;

  useTitle(defaultPageTitle);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de Ocean Freight
  const [oceanFreight, setOceanFreight] = useState<IOceanFreight>(
    {} as IOceanFreight,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsOceanFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    oceanFreightRoles.idEntity,
  );

  // Permissions de Ocean Freight
  const { idPermissionUpdateOceanFreight, idPermissionCreateOceanFreight } =
    oceanFreightRoles.permissions;

  // Valida se usuario pode criar ou atualizar Ocean Freight
  const userCanChange = userHasPermission(
    idOceanFreight
      ? idPermissionUpdateOceanFreight
      : idPermissionCreateOceanFreight,
    userPermissionsOceanFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Ocean Freights',
      path:
        initialPage && initialFirst
          ? `/logistics/oceanFreight?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/oceanFreight',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
          type: 2,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar ocean freight
  const listOceanFreightQuery = gql`
    query listLogisticOceanFreightById($idOceanFreight: Int!) {
      listLogisticOceanFreightById(idOceanFreight: $idOceanFreight) {
        idOceanFreight
        idPortLoading2 {
          idPort
          name
        }
        idPortDestination2 {
          idPort
          name
        }
        valor20Gp
        idCurValor20Gp2 {
          idCurrency
          currency
          abbreviation
        }
        valor40Hc
        idCurValor40Hc2 {
          idCurrency
          currency
          abbreviation
        }
        idBcValor2 {
          idDomain
          description
        }
        versionLock
        createdAt
        updatedAt
        updatedBy2 {
          firstName
          lastName
        }
        createdBy2 {
          firstName
          lastName
        }
        bcCif
      }
    }
  `;

  /**
   * Busca dados de Ocean Freight
   */
  const [loadOceanFreightData, { loading: oceanFreightLoading }] = useLazyQuery(
    listOceanFreightQuery,
    {
      variables: {
        idOceanFreight,
      },
      onCompleted: response => {
        if (response.listLogisticOceanFreightById) {
          setOceanFreight(response.listLogisticOceanFreightById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Ocean Freight not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting ocean freight data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/oceanFreight'),
    });
  }

  // Mutation de criacao de ocean freight
  const createOceanFreightQuery = gql`
    mutation CreateOceanFreightMutation(
      $data: CreateLogisticsOceanFreightInput!
    ) {
      createLogisticsOceanFreight(data: $data) {
        idOceanFreight
      }
    }
  `;

  // Mutation de atualizacao de ocean freight
  const updateOceanFreightQuery = gql`
    mutation UpdateOceanFreightMutation(
      $data: UpdateLogisticsOceanFreightInput!
    ) {
      updateLogisticsOceanFreight(data: $data) {
        idOceanFreight
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createOceanFreightMutation] = useMutation(createOceanFreightQuery);
  const [updateOceanFreightMutation] = useMutation(updateOceanFreightQuery);

  // Submit do formulario de ocean freight
  const handleOceanFreightSubmit = useCallback(
    async (data: IOceanFreightFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idPortLoading: Yup.string().nullable().required('Required Field'),
          idPortDestination: Yup.string().nullable().required('Required Field'),
          idBcValor: Yup.string().nullable().required('Required Field'),
          idCurValor20Gp: Yup.string()
            .nullable()
            .notRequired()
            .when(['valor20Gp'], {
              is: null,
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required('Required Field'),
            }),
          idCurValor40Hc: Yup.string()
            .nullable()
            .notRequired()
            .when(['valor40Hc'], {
              is: null,
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required('Required Field'),
            }),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        // Valida se esta editando ou criando um novo ocean freight
        if (!idOceanFreight) {
          // Chamada criacao de ocean freight
          response = await createOceanFreightMutation({
            variables: {
              data: {
                idPortLoading: data.idPortLoading,
                idPortDestination: data.idPortDestination,
                idCurValor20Gp: data.idCurValor20Gp,
                valor20Gp: data.valor20Gp,
                idCurValor40Hc: data.idCurValor40Hc,
                valor40Hc: data.valor40Hc,
                idBcValor: data.idBcValor,
                bcCif: !!oceanFreight.bcCif,
              },
            },
          });
        } else {
          // Chamada atualizacao de ocean freight
          response = await updateOceanFreightMutation({
            variables: {
              data: {
                idOceanFreight,
                versionLock: oceanFreight.versionLock,
                idPortLoading: data.idPortLoading,
                idPortDestination: data.idPortDestination,
                idCurValor20Gp: data.idCurValor20Gp,
                valor20Gp: data.valor20Gp,
                idCurValor40Hc: data.idCurValor40Hc,
                valor40Hc: data.valor40Hc,
                idBcValor: data.idBcValor,
                bcCif: !!oceanFreight.bcCif,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Ocean Freight ${idOceanFreight ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!idOceanFreight) {
          // Direciona usuario para listagem do ocean freight
          history.push(
            `/logistics/oceanFreight/${response.data.createLogisticsOceanFreight.idOceanFreight}`,
          );
        } else {
          setOceanFreight({} as IOceanFreight);
          loadOceanFreightData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idOceanFreight ? 'updating' : 'creating'
            } ocean freight`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createOceanFreightMutation,
      formRef,
      history,
      idOceanFreight,
      loadOceanFreightData,
      oceanFreight.bcCif,
      oceanFreight.versionLock,
      toastRef,
      updateOceanFreightMutation,
    ],
  );

  useEffect(() => {
    if (idOceanFreight) {
      loadOceanFreightData();
    }
  }, [idOceanFreight, loadOceanFreightData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className={idOceanFreight ? 'createdAt' : ''}>
          {oceanFreight && !oceanFreightLoading
            ? `${new Date(oceanFreight?.createdAt).toLocaleString()} by ${
                oceanFreight?.createdBy2?.firstName
              } ${oceanFreight?.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idOceanFreight ? 'updatedAt' : ''}>
          {oceanFreight && !oceanFreightLoading
            ? `${new Date(oceanFreight?.updatedAt).toLocaleString()} by ${
                oceanFreight?.updatedBy2?.firstName
              } ${oceanFreight?.updatedBy2?.lastName}`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {((idOceanFreight && oceanFreight.idOceanFreight) || !idOceanFreight) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleOceanFreightSubmit}
            initialData={
              oceanFreight && !oceanFreightLoading ? oceanFreight : undefined
            }
          >
            <Row>
              <FormAsyncSelect
                name="idPortLoading"
                label="Port of Loading"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={oceanFreight?.idPortLoading2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idPortDestination"
                label="Port of Destination"
                required
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={oceanFreight?.idPortDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormInputNumber
                name="valor20Gp"
                label="Value 20’ GP"
                decimalScale={2}
                min={0}
                decimalSeparator=","
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idCurValor20Gp"
                className="currency-dropdown"
                label="Currency"
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) =>
                  `${option.abbreviation} - ${option.currency}`
                }
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={oceanFreight.idCurValor20Gp2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormInputNumber
                name="valor40Hc"
                label="Value 40’ HC"
                decimalScale={2}
                min={0}
                decimalSeparator=","
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idCurValor40Hc"
                className="currency-dropdown"
                label="Currency"
                loadOptions={asyncSelectLoadCurrencies}
                debounceTimeout={1000}
                getOptionLabel={(option: any) =>
                  `${option.abbreviation} - ${option.currency}`
                }
                getOptionValue={(option: any) => option.idCurrency}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No currencies found'}
                initialValue={oceanFreight.idCurValor40Hc2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <FormAsyncSelect
                name="idBcValor"
                className="generalInput"
                label="BC Value"
                required
                loadOptions={asyncSelectLoadDomains}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.description}
                getOptionValue={(option: any) => option.idDomain}
                additional={{
                  page: 1,
                  id: 29,
                }}
                noOptionsMessage={() => 'No BC values found'}
                initialValue={oceanFreight.idBcValor2}
                readOnly={!userCanChange}
              />
            </Row>

            <Row>
              <div className="radio-button-div generalInput">
                <label>BC CIF:</label>
                <div>
                  <RadioButton
                    inputId="yes"
                    name="oceanFreight.bcCif"
                    value
                    checked={oceanFreight.bcCif === true}
                    onChange={() =>
                      changeStateProperty('bcCif', true, setOceanFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>Yes</label>
                  <RadioButton
                    inputId="no"
                    name="oceanFreight.bcCif"
                    value={false}
                    checked={oceanFreight.bcCif !== true}
                    onChange={() =>
                      changeStateProperty('bcCif', false, setOceanFreight)
                    }
                    disabled={!userCanChange}
                  />
                  <label>No</label>
                </div>
              </div>
            </Row>
          </Form>
        </CrudForm>
      )}
      {(oceanFreightLoading || loading) && <Loading />}
    </Container>
  );
};

export default OceanFreight;
