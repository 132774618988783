import styled from 'styled-components';

export const Container = styled.div`
  table {
    width: 100%;
    border-spacing: 10px;

    .p-checkbox {
      margin-right: 8px;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff; // any bg-color to overlap
    box-shadow: inset 10px 10px #fff, 0 10px #fff;
  }

  th {
    padding: 12px 25px;
    color: #444444;
    min-width: 135px;
    box-shadow: inset 10px 10px #fff, 0 10px #fff;
  }

  tbody td {
    text-align: center;
    height: 57px;
    white-space: pre-wrap;
    word-wrap: break-word;
    background: #dee2e6;
    padding: 0 12px;
  }

  .sticky-col {
    position: sticky;
    background-color: white;
    left: 0px;
  }

  .changed {
    color: var(--sunasia-red);
    font-weight: bold;
  }
`;
