import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { SatListExportPermissions } from './interfaces';

export const listSatListExportViewQuery = (
  fieldsPermissions: SatListExportPermissions,
): DocumentNode => {
  /**
   * Fields que possuem permissao de visualizacao pelo usuario
   */
  const fields = Object.keys(fieldsPermissions)
    .filter(
      key => fieldsPermissions[key as keyof SatListExportPermissions].view,
    )
    .join('\n');

  return gql`
    query ListSatListExportView($data: ListSatListExportViewInput!) {
      listSatListExportView(data: $data) {
        items
        data {
          idSat
          idSatForeignTrade
          status
          idSupplierExporter
          idCountryOrigin
          idClient
          idFreightForwarderOrigin
          idFreightForwarderDestination
          shipmentIdSupplier
          satCurrency
          ${fields}
        }
      }
    }
  `;
};

export const exportListQuery = gql`
  query Query($data: ExportSatListExportViewInput!) {
    exportSatListExportView(data: $data)
  }
`;

/**
 * Busca os somatorios das colunas da Lista de Export para adicionar no
 * footer
 */
export const getSumDataFromSatExportListQuery = gql`
  query GetSumDataFromSatExportList($data: ListSatListExportViewFieldsSearch) {
    getSumDataFromSatExportList(data: $data) {
      satCount
      quantityPiBySun
      quantityCiBySun
      purchaseTotalCotPi
      purchaseTotalCotCi
      sellerTotalSunPi
      sellerTotalSunCi
      estimatedGrossMargin
      estimatedNetMargin
      grossMargin
      purchasePaidAdvance
      purchasePaidBalance
      totalPaid
      totalCotRemain
      freightCost
      freightExtraCost
      estimatedNetProfit
    }
  }
`;
