/* eslint-disable no-return-assign */
import React, { Dispatch, SetStateAction, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import Button from '../../../components/Button';
import AddContact from './AddContact';
import { IPartnerContact } from '../interfaces';
import generateRandomString from '../../../utils/generateRandomString';
import { useRefHook } from '../../../hooks/useRefHook';

interface IContactProps extends PageTabContainerProps {
  partnerContacts: IPartnerContact[];
  setPartnerContacts: Dispatch<SetStateAction<IPartnerContact[]>>;
}

const Contact: React.FC<IContactProps> = ({
  selected,
  partnerContacts,
  setPartnerContacts,
}) => {
  // Referencia ao formulario
  const { formRef } = useRefHook();

  // Contacts selecionados
  const [selectedContacts, setSelectedContacts] = useState<IPartnerContact[]>(
    [],
  );

  // Contact utilizado na modal
  const [newContact, setNewContact] = useState<IPartnerContact>(
    {} as IPartnerContact,
  );

  // Estado para exibir modal
  const [displayImageModal, setDisplayImageModal] = useState(false);

  /**
   * Exibe modal
   */
  const onClick = () => {
    setNewContact({ ...newContact, idPartnerContact: generateRandomString(4) });
    setDisplayImageModal(true);
  };

  /**
   * Oculta modal
   */
  const onHide = () => {
    setDisplayImageModal(false);
  };

  function onConfirmDialog() {
    if (!newContact.name) {
      formRef.current?.setFieldError('nameContact', 'Contact name is required');
      return;
    }

    // Busca indice do item editado
    const itemAlreadExists = partnerContacts.findIndex(
      item => item.idPartnerContact === newContact.idPartnerContact,
    );

    // Valida se item ja existe na lista
    if (itemAlreadExists !== -1) {
      // Se existir, atualiza informacoes
      const newItems = [...partnerContacts];
      newItems[itemAlreadExists] = newContact;
      setPartnerContacts(newItems);
    } else {
      // Se nao, adiciona na lista
      setPartnerContacts([...partnerContacts, newContact]);
    }
    setNewContact({} as IPartnerContact);
    onHide();
  }

  /**
   * Footer da modal
   * @returns Footer
   */
  const dialogFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end">
        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => {
            onConfirmDialog();
          }}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => {
            setNewContact({} as IPartnerContact);
            onHide();
          }}
          className="p-button-danger"
          severity="danger"
        />
      </div>
    );
  };

  /**
   * Deleta contacts selecionados
   */
  function handleDeleteSelected() {
    const newContacts = partnerContacts.filter(
      contact => !selectedContacts.includes(contact),
    );

    setPartnerContacts(newContacts);
    setSelectedContacts([]);
  }

  /**
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: any) {
    if (e.originalEvent.target.className.includes('p-checkbox')) {
      return;
    }

    setNewContact(e.data);
    setDisplayImageModal(true);
  }

  const gridHeader = (
    <div className="buttons-header">
      <Button label="Add Contact" onClick={() => onClick()} />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={() => handleDeleteSelected()}
        disabled={!selectedContacts.length}
      />
    </div>
  );

  return (
    <PageTabContainer selected={selected}>
      <Dialog
        header="Add a Contact"
        visible={displayImageModal}
        style={{ width: '40vw' }}
        onHide={() => onHide()}
        closable={false}
        footer={dialogFooter()}
      >
        <AddContact setNewContact={setNewContact} newContact={newContact} />
      </Dialog>
      <Container>
        <DataTable
          value={partnerContacts}
          header={gridHeader}
          selection={selectedContacts}
          onSelectionChange={e => setSelectedContacts(e.value)}
          emptyMessage="No contacts found"
          onRowClick={onRowClick}
          selectionMode="checkbox"
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column field="idDepartment2.name" header="Department" sortable />
          <Column field="idRole2.description" header="Role" sortable />
          <Column field="name" header="Name" />
          <Column field="email" header="E-mail" />
          <Column field="telephone" header="Telephone" />
        </DataTable>
      </Container>
    </PageTabContainer>
  );
};

export default Contact;
