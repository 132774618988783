import { gql, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Grid from '../../../../components/Grid';
import MultiSelect from '../../../../components/Grid/MultiSelect';

import { gridConstants } from '../../../../components/Grid/constants';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { searchDelayMiliseconds } from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import ILazyParams from '../../../../services/lazyParams';
import ToastLife from '../../../../shared/enums/toastLife';
import { productRoles } from '../../../../shared/roles/product';
import { parseToLocaleFormat } from '../../../../utils/dateUtil';
import { parseDateTimeColumm } from '../../../../utils/gridColumnsParse';
import { showField } from '../../../../utils/showField';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';

interface IPriceHistoryProps extends PageTabContainerProps {
  idProduct?: number;
  roleEntityFields: IRoleEntityField[];
}

/**
 * Interface dos parametros do backend
 */
interface IPriceHistoryLazyParams extends ILazyParams {
  cotPrice?: string;
  sunPrice?: string;
  moq?: number;
  markup?: string;
  profit?: string;
  supplier?: string;
  updatedBy?: string;
  recordedAt?: Date;
}

const PriceHistory: React.FC<IPriceHistoryProps> = ({
  selected,
  idProduct,
  roleEntityFields,
}) => {
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:productPriceHistoryGridColumns';

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<DataTable<any>>(null);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IPriceHistoryLazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Query para listar price history
  const listPriceHistoryQuery = gql`
    query listProductPricesHistoryByProductIdQuery(
      $data: ListProductPricesHistoryByProductIdInput!
    ) {
      listProductPricesHistoryByProductId(data: $data) {
        data {
          idProductPriceHistory
          action
          ${
            showField(
              productRoles.fields.idFieldPriceHistoryCotPrice,
              roleEntityFields,
            )
              ? 'cotPrice'
              : ''
          }
          ${
            showField(
              productRoles.fields.idFieldPriceHistorySunPrice,
              roleEntityFields,
            )
              ? 'sunPrice'
              : ''
          }
          ${
            showField(
              productRoles.fields.idFieldPriceHistoryMoq,
              roleEntityFields,
            )
              ? 'moq'
              : ''
          }
          ${
            showField(
              productRoles.fields.idFieldPriceHistoryMarkup,
              roleEntityFields,
            )
              ? 'markup'
              : ''
          }
          ${
            showField(
              productRoles.fields.idFieldPriceHistoryProfit,
              roleEntityFields,
            )
              ? 'profit'
              : ''
          }

            ${
              showField(
                productRoles.fields.idFieldPriceHistorySupplier,
                roleEntityFields,
              )
                ? 'idSupplier2 { sunNumber }'
                : ''
            }
            ${
              showField(
                productRoles.fields.idFieldPriceHistorySupplierName,
                roleEntityFields,
              )
                ? 'idSupplier2 { name }'
                : ''
            }

            ${
              showField(productRoles.fields.idFieldRecordedBy, roleEntityFields)
                ? 'updatedBy2 { idUser username }'
                : ''
            }
          ${
            showField(productRoles.fields.idFieldRecordedAt, roleEntityFields)
              ? 'updatedAt'
              : ''
          }
          dtAging
          idIncoterm2 {
            idDomain
            description
          }
          idSalesIncoterm2 {
            idDomain
            description
          }
          idPurchaseCurrency2 {
            idCurrency
            abbreviation
          }
          idSalesCurrency2 {
            idCurrency
            abbreviation
          }
        }
        items
      }
    }
  `;

  /**
   * Busca Price history
   */
  const { loading: priceHistoryLoading, data: priceHistoryData } = useQuery(
    listPriceHistoryQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idProduct,
          cotPrice: lazyParams.cotPrice,
          sunPrice: lazyParams.sunPrice,
          moq: lazyParams.moq,
          markup: lazyParams.markup,
          profit: lazyParams.profit,
          supplier: lazyParams.supplier,
          updatedBy: lazyParams.updatedBy,
          recordedAt: lazyParams.recordedAt,
          globalSearch: lazyParams.globalFilter,
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting price history',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    if (
      showField(productRoles.fields.idFieldPriceHistoryAction, roleEntityFields)
    ) {
      columnList.push({ field: 'action', header: 'Action' });
    }
    if (
      showField(productRoles.fields.idFieldPriceHistoryAging, roleEntityFields)
    ) {
      columnList.push({ field: 'dtAging', header: 'Aging' });
    }
    if (
      showField(
        productRoles.fields.idFieldPriceHistoryIncoterm,
        roleEntityFields,
      )
    ) {
      columnList.push({
        field: 'idIncoterm2.description',
        header: 'Purchase Incoterm',
      });
    }

    if (
      showField(
        productRoles.fields.idFieldPriceHistoryPurchaseCurrency,
        roleEntityFields,
      )
    ) {
      columnList.push({
        field: 'idPurchaseCurrency2.abbreviation',
        header: 'Purchase Currency',
      });
    }

    if (
      showField(
        productRoles.fields.idFieldPriceHistoryCotPrice,
        roleEntityFields,
      )
    ) {
      columnList.push({ field: 'cotPrice', header: 'COT Price' });
    }
    if (
      showField(productRoles.fields.idFieldPriceHistoryMarkup, roleEntityFields)
    ) {
      columnList.push({ field: 'markup', header: 'Markup(%)' });
    }
    if (
      showField(productRoles.fields.idFieldPriceHistoryProfit, roleEntityFields)
    ) {
      columnList.push({ field: 'profit', header: 'Profit(%)' });
    }
    if (
      showField(
        productRoles.fields.idFieldPriceHistorySalesIncoterm,
        roleEntityFields,
      )
    ) {
      columnList.push({
        field: 'idSalesIncoterm2.description',
        header: 'Sales Incoterm',
      });
    }
    if (
      showField(
        productRoles.fields.idFieldPriceHistorySalesCurrency,
        roleEntityFields,
      )
    ) {
      columnList.push({
        field: 'idSalesCurrency2.abbreviation',
        header: 'Sales Currency',
      });
    }
    if (
      showField(
        productRoles.fields.idFieldPriceHistorySunPrice,
        roleEntityFields,
      )
    ) {
      columnList.push({ field: 'sunPrice', header: 'SUN Price' });
    }

    if (
      showField(productRoles.fields.idFieldPriceHistoryMoq, roleEntityFields)
    ) {
      columnList.push({ field: 'moq', header: 'Moq' });
    }

    if (showField(productRoles.fields.idFieldSat, roleEntityFields)) {
      columnList.push({ field: 'sat', header: 'SAT(%)' });
    }

    if (
      showField(
        productRoles.fields.idFieldPriceHistorySupplier,
        roleEntityFields,
      )
    ) {
      columnList.push({ field: 'idSupplier2.sunNumber', header: 'Supplier' });
    }

    if (
      showField(
        productRoles.fields.idFieldPriceHistorySupplierName,
        roleEntityFields,
      )
    ) {
      columnList.push({ field: 'idSupplier2.name', header: 'Supplier Name' });
    }

    if (showField(productRoles.fields.idFieldRecordedBy, roleEntityFields)) {
      columnList.push({ field: 'updatedBy2.username', header: 'Recorded by' });
    }

    if (showField(productRoles.fields.idFieldRecordedAt, roleEntityFields)) {
      columnList.push({ field: 'updatedAt', header: 'Recorded At' });
    }

    return columnList;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  const parseCurrencyColumn = (rowData: any, field: any) => {
    if (field.field === 'cotPrice' && rowData.cotPrice) {
      return <p>{`$ ${rowData.cotPrice}`}</p>;
    }
    if (field.field === 'sunPrice' && rowData.sunPrice) {
      return <p>{`$ ${rowData.sunPrice}`}</p>;
    }
    return undefined;
  };

  const parseColumnDate = (rowData: any, field: any) => {
    const value = rowData[field.field];
    let date;
    if (value) {
      date = parseToLocaleFormat(value);
    }
    return <p>{date}</p>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'cotPrice':
      case 'sunPrice':
        return parseCurrencyColumn;
      case 'updatedAt':
        return parseDateTimeColumm;
      case 'dtAging':
        return parseColumnDate;
      default:
        return undefined;
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return <span className="custom-header">{headerName}</span>;
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'markup':
      case 'idSupplier2.name':
      case 'idSalesIncoterm2.description':
      case 'idSalesCurrency2.abbreviation':
        return { width: '11.43rem' };
      case 'idIncoterm2.description':
      case 'idPurchaseCurrency2.abbreviation':
        return { width: '12.86rem' };
      case 'updatedAt':
        return { width: '500rem' };
      default:
        return { width: '8.57rem' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          style={handleColumnSize(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          sortable
        />
      )
    );
  });

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <PageTabContainer className="overflow-hidden" selected={selected}>
      <div className="flex justify-content-end py-2">
        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search a price history"
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />
      </div>

      <Grid
        ref={gridRef}
        name="productPriceHistory"
        lazy
        totalRecords={
          !priceHistoryData ||
          !priceHistoryData.listProductPricesHistoryByProductId
            ? 0
            : priceHistoryData.listProductPricesHistoryByProductId.items
        }
        value={
          !priceHistoryData ||
          !priceHistoryData.listProductPricesHistoryByProductId
            ? undefined
            : priceHistoryData.listProductPricesHistoryByProductId.data
        }
        globalFilter={globalFilter}
        loading={priceHistoryLoading}
        emptyMessage="No price history found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        {dynamicColumns}
      </Grid>
    </PageTabContainer>
  );
};

export default PriceHistory;
