export const supplierBankAccountRoles = {
  idEntity: 28,
  idModule: 4,
  fields: {
    idFieldAccountNumber: 614,
    idFieldBankCity: 615,
    idFieldBeneficiaryName: 616,
    idFieldIban: 617,
    idFieldIdCountry: 618,
    idFieldIdRegion: 619,
    idFieldIdSecondCountry: 620,
    idFieldIdSecondRegion: 621,
    idFieldIntermediaryAccountNumber: 624,
    idFieldIntermediaryBankAddress: 625,
    idFieldIntermediaryBankCity: 626,
    idFieldIntermediaryBeneficiaryName: 627,
    idFieldIntermediaryIban: 628,
    idFieldIntermediarySwift: 629,
    idFieldIsIntermediaryBank: 630,
    idFieldIsPrimary: 631,
    idFieldMainBankAddress: 632,
    idFieldMainBankName: 633,
    idFieldSwift: 634,
  },
};
