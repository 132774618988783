import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useMemo, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import Button from '../../../../../../components/Button';
import SelectRecipientsModal from '../../../../../../components/SelectRecipientsModal';
import {
  IUserEmailsProps,
  IUserGroupsProps,
} from '../../../../../../components/SelectRecipientsModal/interfaces';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { asyncSelectLoadClientContactsOptions } from '../../../../../../shared/querys/clientContacts';
import { IClientContact } from '../../../../../Clients/ClientDetail/interfaces';
import { IRegisterRequestClassificacaoFiscalDialogProps } from './interfaces';
import { processRegisterClassificacaoFiscalQuery } from './queries';
import { RecipientsNDI, Section } from './styles';

const RegisterRequestClassificacaoFiscalDialog: React.FC<IRegisterRequestClassificacaoFiscalDialogProps> =
  ({
    sat,
    idSatProcessResult,
    statusProcessRefech,
    idSatForeignTrade,
    displayDialog,
    setDisplayDialog,
  }) => {
    const { showError, showSuccess } = useRefHook();

    const [isVisibleRecipientsModal, setIsVisibleRecipientsModal] =
      useState(false);
    const initialText = `Bom dia,
    Poderiam confirmar as NCM’s a serem consideradas para este pedido?
    Segue Order Details em anexo com as imagens dos produtos e PI para referência.
    Poderiam também confirmar se o CNPJ do pedido está de acordo com a NCM escolhida?

    Atenciosamente;`;

    const [emailText, setEmailText] = useState<string>(initialText);

    const [selectedUsersNdi, setSelectedUsersNdi] = useState<
      IUserEmailsProps[]
    >([]);

    const [userGroups, setUserGroups] = useState<IUserGroupsProps[]>([]);

    const [selectedClientContacts, setSelectedClientContacts] = useState<
      IClientContact[]
    >([]);

    const [requestLoading, setRequestLoading] = useState(false);

    const [sendRequestClassificacaoFiscalMutation] = useMutation(
      processRegisterClassificacaoFiscalQuery,
    );

    const [emailSubject, setEmailTitleValue] = useState(() => {
      const { satNumber } = sat;
      const satMainProduct = sat.mainProduct ?? '';
      const clientNotify = sat.idClient2?.name ?? '';
      const exporter = sat.idSupplierExporter2?.name ?? '';
      return `[CLASSIFICAÇÃO FISCAL] SAT GENERAL INFORMATION - ${clientNotify} | ${satNumber} | ${satMainProduct} | SAT GENERAL INFORMATION - EXPORTER - ${exporter}`;
    });

    const selectedGroupEmailString = useMemo((): string => {
      const selectedUserGroups = userGroups?.filter(groups => groups.isChecked);
      if (selectedUserGroups) {
        const userGroupsNames = selectedUserGroups.map(element => element.name);
        return userGroupsNames.join(', ');
      }
      return '';
    }, [userGroups]);

    const selectedUserString = selectedUsersNdi
      ? selectedUsersNdi
          .map(element => `${element.firstName} ${element.lastName}`)
          .join(', ')
      : '';

    const usersAndGroupsAreSelected =
      selectedGroupEmailString && selectedUserString;

    const onHide = () => {
      setDisplayDialog(false);
    };

    async function handleSubmit() {
      setRequestLoading(true);

      const idsUsersNdi = selectedUsersNdi?.map(e => e.idUser);
      const idsUserGroups = userGroups?.flatMap(e =>
        e.isChecked ? [e.idUserGroup] : [],
      );
      const idsClientContacts = selectedClientContacts?.map(
        e => e.idClientContact,
      );

      try {
        await sendRequestClassificacaoFiscalMutation({
          variables: {
            data: {
              idSatProcessResult,
              idSat: sat.idSat,
              idSatForeignTrade,
              idsUsersNdi,
              idsUserGroups,
              idsClientContacts,
              emailSubject,
              emailMessage: emailText,
            },
          },
        });

        await statusProcessRefech();

        showSuccess({
          summary: 'Request classificação fiscal sent',
        });

        onHide();
      } catch (error) {
        showError({
          summary: 'Error while sending Request classificação fiscal',
          detail: error.message,
        });
      } finally {
        setRequestLoading(false);
      }
    }

    const footer = () => {
      return (
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Send"
            icon={requestLoading ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
            type="button"
            onClick={() => handleSubmit()}
          />
          <Button
            label="Cancel"
            icon="pi pi-times"
            onClick={() => onHide()}
            severity="danger"
          />
        </div>
      );
    };

    return (
      <div>
        <SelectRecipientsModal
          isVisibleRecipientsModal={isVisibleRecipientsModal}
          setIsVisibleRecipientsModal={setIsVisibleRecipientsModal}
          userGroups={userGroups}
          setUserGroups={setUserGroups}
          selectedUsers={selectedUsersNdi}
          setSelectedUsers={setSelectedUsersNdi}
        />
        <Dialog
          header="Register Request Classificação Fiscal"
          visible={displayDialog}
          onHide={onHide}
          style={{ width: '45vw' }}
          footer={footer}
        >
          <Section>
            <h5>Email title</h5>
            <InputText
              name="emailTitle"
              aria-describedby="email-title"
              className="col-12"
              value={emailSubject}
              onChange={e => setEmailTitleValue(e.target.value)}
            />

            <RecipientsNDI>
              <h5>NDI Recipients</h5>
              <div className="content">
                <InputText
                  aria-describedby="recipients-help"
                  className="col-10"
                  value={`${selectedGroupEmailString}${
                    usersAndGroupsAreSelected ? ', ' : ''
                  }${selectedUserString}`}
                />
                <Button
                  label="Add"
                  icon="pi pi-plus"
                  onClick={() => {
                    setIsVisibleRecipientsModal(true);
                  }}
                  autoFocus
                />
              </div>
            </RecipientsNDI>

            <h5>Client Recipients</h5>
            <AsyncPaginate
              loadOptions={asyncSelectLoadClientContactsOptions}
              debounceTimeout={1000}
              isMulti
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.idClientContact}
              additional={{
                page: 1,
                idClient: sat.idClient,
              }}
              value={selectedClientContacts}
              onChange={e => setSelectedClientContacts(e as IClientContact[])}
            />
            <h5 className="mt-3">Email text</h5>
            <InputTextarea
              style={{ boxShadow: 'none' }}
              className="w-full"
              rows={5}
              value={emailText}
              onChange={e => setEmailText(e.target.value)}
              autoResize
            />
          </Section>
        </Dialog>
      </div>
    );
  };

export default RegisterRequestClassificacaoFiscalDialog;
