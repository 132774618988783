import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isEqual } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { IoMdAttach } from 'react-icons/io';
import { FiExternalLink } from 'react-icons/fi';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../../../components/PageTabContainer';
import { IRoleEntityPermission } from '../../../../../../interfaces/IRoleEntityPermission';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import CommentAdding, {
  ICommentAddingRef,
} from '../../../../../../components/CommentAdding';
import { satsRoles } from '../../../../../../shared/roles/sat';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { IRncUpdate } from './interfaces';
import pagination from '../../../../../../config/pagination';
import { ISatCommentsLazyParams } from '../../../Updates/interfaces';
import {
  AdvancedFiltersFieldType,
  IAdvancedFiltersField,
} from '../../../../../../components/AdvancedFiltersPanel/interfaces';
import { AttachmentTypes } from '../../../../../../shared/enums/attachmentType';
import { IAttachment } from '../../../../../../shared/interfaces/attachment';
import { IComment } from '../../../../../../shared/interfaces/comment';
import { Attachment, Attachments, Comments, Container } from './styles';
import AdvancedFiltersPanel from '../../../../../../components/AdvancedFiltersPanel';
import FormTitle from '../../../../../../components/FormTitle';
import { FileType } from '../../../../../../shared/enums/fileType';
import Button from '../../../../../../components/Button';
import Comment from '../../../../../../components/Comment';
import { parseCommentMessage } from '../../../../../../utils/parseCommentMessage';
import { imageExtensions } from '../../../../../../shared/constants/imageExtensions';
import Loading from '../../../../../../components/Loading';
import Image from '../../../../../../components/PageHeader/Image';
import { ISatAttachment } from '../../../../../../interfaces/ISatAttachment';
import {
  createSatCommentQuery,
  deleteSatCommentQuery,
  listSatAttachmentsBySatIdQuery,
  listSatCommentsByIdSatRncQuery,
  updateSatCommentQuery,
} from './queries';
import {
  createSatAttachmentsQuery,
  deleteSatAttachmentsQuery,
} from '../../../queries';
import { FileUploadResponse } from '../../../../../../components/FileUpload/interfaces';
import FileUpload from '../../../../../../components/FileUpload';

interface IRncUpdatesProps extends PageTabContainerProps {
  idSatRnc?: number;
  idSat?: number;
  userPermissions: IRoleEntityPermission[];
}

const RncUpdates: React.FC<IRncUpdatesProps> = ({
  selected,
  idSatRnc,
  idSat,
  userPermissions,
}) => {
  const { user } = useAuth();

  const { showSuccess, showError } = useRefHook();

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const commentAddingRef = useRef<ICommentAddingRef>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [updates, setUpdates] =
    useState<{ data: IRncUpdate[]; items: number }>();

  const [selectedUpdate, setSelectedUpdate] = useState<IRncUpdate>();

  const [satAttachments, setSatAttachments] = useState<ISatAttachment[]>();

  const { idPermissionShowUpdatesRestrictInfo } = satsRoles.permissions;

  const userCanSeeRestrictInfo = useMemo(() => {
    return userHasPermission(
      idPermissionShowUpdatesRestrictInfo,
      userPermissions,
    );
  }, [idPermissionShowUpdatesRestrictInfo, userPermissions]);

  const initialLazyParams = useMemo(() => {
    return {
      ...pagination.initialLazyParams,
      fetchAutomaticComments: true,
    };
  }, []);

  const [lazyParams, setLazyParams] =
    useState<ISatCommentsLazyParams>(initialLazyParams);

  const [globalFilter, setGlobalFilter] = useState('');

  const cleanSelected = useCallback(() => {
    setSelectedUpdate({} as IRncUpdate);
  }, []);

  const toggleAutomaticComments = () => {
    setLazyParams({
      ...initialLazyParams,
      fetchAutomaticComments: !lazyParams.fetchAutomaticComments,
    });
  };

  const advancedFiltersFields: IAdvancedFiltersField[] = useMemo(() => {
    const items = [];

    items.push(
      { advancedFilterField: 'createdBy', header: 'Created By', value: '' },
      {
        advancedFilterField: 'createdCommentStartDateRange',
        header: 'Created update start date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'createdCommentEndDateRange',
        header: 'Created update end date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'isGeneratedSystem',
        header: 'System generated updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      },
    );

    if (userCanSeeRestrictInfo) {
      items.push({
        advancedFilterField: 'isRestrictInfo',
        header: 'Restrict info updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      });
    }

    return items;
  }, [userCanSeeRestrictInfo]);

  const [createSatCommentMutation] = useMutation(createSatCommentQuery);
  const [updateSatCommentMutation] = useMutation(updateSatCommentQuery);
  const [deleteSatCommentMutation] = useMutation(deleteSatCommentQuery);

  const [createSatAttachmentMutation] = useMutation(createSatAttachmentsQuery);
  const [deleteSatAttachmentsMutation] = useMutation(deleteSatAttachmentsQuery);

  const [loadUpdatesData, { loading: updatesDataLoading }] = useLazyQuery(
    listSatCommentsByIdSatRncQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idSatRnc,
          idSat,
          globalSearch: lazyParams.globalFilter,
          createdBy: lazyParams.createdBy,
          createdCommentStartDateRange: lazyParams.createdCommentStartDateRange,
          createdCommentEndDateRange: lazyParams.createdCommentEndDateRange,
          isGeneratedSystem: lazyParams.isGeneratedSystem,
          isRestrictInfo: lazyParams.isRestrictInfo,
          fetchAutomaticComments: lazyParams.fetchAutomaticComments,
        },
      },
      onCompleted: response => {
        if (response.listSatCommentsByIdSatRnc) {
          if (lazyParams.page && updates) {
            setUpdates({
              items: response.listSatCommentsByIdSatRnc.items,
              data: [
                ...updates.data,
                ...response.listSatCommentsByIdSatRnc.data,
              ],
            });
          } else {
            setUpdates(response.listSatCommentsByIdSatRnc);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting RNC updates',
          detail: errorData.message,
        });
      },
    },
  );

  const handleRefreshUpdates = useCallback(() => {
    if (!isEqual(lazyParams, initialLazyParams)) {
      setLazyParams(initialLazyParams);
    } else {
      loadUpdatesData();
    }
  }, [initialLazyParams, lazyParams, loadUpdatesData]);

  const handleSubmitUpdate = useCallback(
    async (e: IComment) => {
      setLoading(true);
      try {
        if (!selectedUpdate?.idSatComment) {
          await createSatCommentMutation({
            variables: {
              data: {
                idSat,
                idSatRnc,
                message: e.message,
                file: e.idAttachment2?.file,
                isRestrictInfo: e.isRestrictInfo,
                idAttachment: e.idAttachment,
                isFollowUp: false,
              },
            },
          });
        } else {
          // Editar comentario
          await updateSatCommentMutation({
            variables: {
              data: {
                idSatComment: selectedUpdate.idSatComment,
                message: e.message,
                file: e.idAttachment2?.file,
                isRestrictInfo: e.isRestrictInfo,
                idAttachment: e.idAttachment,
              },
            },
          });
        }

        handleRefreshUpdates();

        showSuccess({ summary: 'Update published' });

        setSelectedUpdate({} as IRncUpdate);
      } catch (err) {
        showError({
          summary: `Error while ${
            selectedUpdate?.idSatComment ? 'updating' : 'creating'
          } update`,
          detail: err.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      idSatRnc,
      idSat,
      selectedUpdate?.idSatComment,
      createSatCommentMutation,
      updateSatCommentMutation,
      handleRefreshUpdates,
      setLoading,
      showError,
      showSuccess,
    ],
  );

  const handleDeleteUpdate = useCallback(
    async (idSatComment: number) => {
      setLoading(true);
      try {
        await deleteSatCommentMutation({
          variables: {
            idSatComment,
          },
        });

        showSuccess({ summary: 'Comment deleted' });

        // Atualiza lista de updates
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting comment',
          detail: error.message,
        });
      } finally {
        setLoading(false);
        setSelectedUpdate({} as IRncUpdate);
      }
    },
    [
      deleteSatCommentMutation,
      loadUpdatesData,
      setLoading,
      showError,
      showSuccess,
    ],
  );

  const [loadAttachments] = useLazyQuery(listSatAttachmentsBySatIdQuery, {
    variables: {
      data: {
        idSat,
        idSatRnc,
        idTypes: [AttachmentTypes.UPDATE_FILE_RNC],
      },
    },
    onCompleted: response => {
      if (response.listSatAttachmentsBySatId?.data) {
        setSatAttachments(response.listSatAttachmentsBySatId.data);
      }
    },
    onError: error => {
      showError({
        summary: 'Error while getting RNC updates',
        detail: error.message,
      });
    },
  });

  async function uploadAttachment(e: FileUploadResponse[]) {
    setLoading(true);
    try {
      const response = await createSatAttachmentMutation({
        variables: {
          data: {
            idSat,
            idSatRnc,
            idType: AttachmentTypes.UPDATE_FILE_RNC,
            uploadedFiles: e.map(file => file.serverName),
          },
        },
      });

      showSuccess({
        summary: 'Update published',
      });
      loadAttachments();
      return response.data?.createSatAttachments[0]?.idAttachment2;
    } catch (err) {
      showError({
        summary: `Error while creating attachment`,
        detail: err.message,
      });
      return undefined;
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteAttachment = useCallback(
    async (satAttachment: ISatAttachment | undefined) => {
      setLoading(true);
      try {
        await deleteSatAttachmentsMutation({
          variables: {
            satAttachmentIds: satAttachment?.idSatAttachment,
          },
        });

        showSuccess({
          summary: 'Update deleted',
        });
        loadAttachments();
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting sat attachment',
          detail: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      deleteSatAttachmentsMutation,
      loadAttachments,
      loadUpdatesData,
      setLoading,
      showSuccess,
      showError,
    ],
  );

  const handleAddAttachmentComment = (attachment: IAttachment | undefined) => {
    if (attachment) {
      commentAddingRef.current?.addCommentFromAttachment(attachment);
    }
  };

  const handleEditUpdate = useCallback((update: IRncUpdate) => {
    setSelectedUpdate(update);
    commentAddingRef.current?.editComment(update.idComment2);
  }, []);

  const handleReplyUpdate = useCallback((update: IRncUpdate) => {
    if (update.idComment2?.createdBy2) {
      commentAddingRef.current?.replyComment({
        userToReply: update.idComment2?.createdBy2,
        isRestrictComment: !!update.idComment2?.isRestrictInfo,
      });
    }
  }, []);

  const handleReplyAllUpdate = useCallback((update: IRncUpdate) => {
    if (update.idComment2?.createdBy2) {
      commentAddingRef.current?.replyAll({
        userToReply: update.idComment2?.createdBy2,
        isRestrictComment: !!update.idComment2?.isRestrictInfo,
        message: update.idComment2?.message,
      });
    }
  }, []);

  const currentUserCreatedComment = useCallback(
    (createdBy: number) => {
      return createdBy === user.idUser;
    },
    [user.idUser],
  );

  const chooseOptions = {
    icon: 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLazyParams(currentLazyParams => {
        return {
          ...currentLazyParams,
          globalFilter,
          first: pagination.initialLazyParams.first,
          page: pagination.initialLazyParams.page,
          rows: pagination.initialLazyParams.rows,
        };
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [globalFilter]);

  useEffect(() => {
    if (selected && !updates) {
      loadUpdatesData();
      loadAttachments();
    }
  }, [loadAttachments, loadUpdatesData, selected, updates]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <AdvancedFiltersPanel
          innerRef={advancedFiltersPanelRef}
          fields={advancedFiltersFields}
          onApply={e =>
            setLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            setLazyParams({ ...pagination.initialLazyParams, globalFilter })
          }
        />
        <div className="content-header mt-5">
          <FormTitle name="Updates (EN)" />

          <span className="content-header-options">
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => uploadAttachment(e)}
            />
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={globalFilter}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search an update"
              />
            </span>
            <Button
              label="Filters"
              type="button"
              onClick={e =>
                advancedFiltersPanelRef.current?.toggle(e, e.target)
              }
            />
          </span>
        </div>
        <div className="flex">
          <Comments className="col-6">
            <div className="my-4">
              <div className="flex justify-content-between my-2">
                <div className="subtitle">
                  <i className="pi pi-comments" />
                  <span>Comments</span>
                </div>
                <button
                  className="button-hide"
                  onClick={() => toggleAutomaticComments()}
                  type="button"
                >
                  {lazyParams.fetchAutomaticComments ? 'Hide' : 'Show'}{' '}
                  automatic comments
                </button>
              </div>
              <CommentAdding
                ref={commentAddingRef}
                onSaveComment={handleSubmitUpdate}
                cleanSelected={cleanSelected}
                showRestrictInfo={userCanSeeRestrictInfo}
                onUploadAttachment={uploadAttachment}
              />
            </div>
            {((updatesDataLoading && !!lazyParams.page) ||
              !updatesDataLoading) &&
              updates &&
              updates.data?.map(update => {
                return (
                  <Comment
                    idComment={update.idComment2.idComment}
                    userName={`${update.idComment2.createdBy2?.firstName} ${update.idComment2.createdBy2?.lastName}`}
                    creation={update.idComment2.createdAt ?? new Date()}
                    editedData={
                      update.idComment2.updatedBy2
                        ? {
                            editedAt: update.idComment2.updatedAt,
                            editedBy: `${update.idComment2.updatedBy2?.firstName} ${update.idComment2.updatedBy2?.lastName}`,
                          }
                        : undefined
                    }
                    commonTag={{
                      className: 'mr-2',
                      rounded: true,
                      isGeneratedSystem: update.idComment2.isGeneratedSystem,
                      isRestrictInfo: update.idComment2.isRestrictInfo,
                      value: undefined,
                      severity: undefined,
                    }}
                    onEdit={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleEditUpdate(update)
                        : undefined
                    }
                    onReply={
                      !currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleReplyUpdate(update)
                        : undefined
                    }
                    onReplyAll={
                      !currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleReplyAllUpdate(update)
                        : undefined
                    }
                    onDelete={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () =>
                            confirmDialog({
                              message:
                                'Are you sure you want to delete this update?',
                              header: 'Delete Confirmation',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-danger',
                              accept: () =>
                                handleDeleteUpdate(update.idSatComment),
                            })
                        : undefined
                    }
                    userImageUrl={update.idComment2.createdBy2?.avatarUrl}
                    key={update.idSatComment}
                    attachmentUrl={update.idComment2.idAttachment2?.url}
                    idCreatedBy={update.idComment2.createdBy}
                  >
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: parseCommentMessage(update.idComment2),
                      }}
                      className="comment"
                    />
                  </Comment>
                );
              })}
          </Comments>
          {satAttachments && satAttachments.length > 0 ? (
            <Attachments className="col-6">
              <div className="col-12 subtitle">
                <IoMdAttach className="attachment-icon" size={25} />
                <span>Attachment</span>
              </div>
              {satAttachments?.map(satAttachment => {
                const attachment = satAttachment?.idAttachment2;
                const fileExtension = attachment?.name?.split('.').pop();
                return (
                  <Attachment key={satAttachment?.idSatAttachment}>
                    <div className="col-3">
                      {fileExtension &&
                      imageExtensions.includes(fileExtension) ? (
                        <Image
                          src={attachment?.url ?? ''}
                          alt={`Comment image ${attachment?.nameGivenByUser}`}
                        />
                      ) : (
                        <div className="flex justify-content-center">
                          <div className="file-extension">{fileExtension}</div>
                        </div>
                      )}
                    </div>
                    <div className="col-9 image-info">
                      <div className="col-12 flex align-items-center">
                        <span className="image-name">
                          {attachment?.nameGivenByUser}
                        </span>
                        <a
                          href={attachment?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="attachment"
                        >
                          <FiExternalLink className="mx-2" />
                        </a>
                      </div>
                      <div className="col-12">
                        {`Added: ${
                          attachment?.createdAt
                            ? new Date(attachment.createdAt).toLocaleString()
                            : ''
                        } - `}
                        <button
                          onClick={() =>
                            confirmDialog({
                              message: 'Are you sure you want to delete?',
                              header: 'Delete Confirmation',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-danger',
                              accept: () =>
                                handleDeleteAttachment(satAttachment),
                            })
                          }
                          type="button"
                        >
                          Delete
                        </button>
                        {' - '}
                        <button
                          onClick={() =>
                            handleAddAttachmentComment(
                              satAttachment?.idAttachment2,
                            )
                          }
                          type="button"
                        >
                          Add Comment
                        </button>
                      </div>
                    </div>
                  </Attachment>
                );
              })}
            </Attachments>
          ) : (
            <div className="w-full p-5 flex justify-content-center">
              <span>No attachments found</span>
            </div>
          )}
        </div>
      </Container>
      {(loading || updatesDataLoading) && <Loading />}
    </PageTabContainer>
  );
};

export default RncUpdates;
