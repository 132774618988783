import styled from 'styled-components';

export const Container = styled.div`
  .w-full {
    width: 100%;
  }

  .form-tree-select {
    width: 33.3333%;
  }

  .form-dropdown {
    width: 33.3333%;
  }

  .purchase-incoterm .form-dropdown {
    width: 100%;
  }
`;

export const CtnrBox = styled.div`
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 85%;

  .form-dropdown {
    width: 100%;
  }

  .button-div {
    right: 0;

    display: flex;
    justify-content: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;
