import styled from 'styled-components';

interface ReadOnlyInputProps {
  severity?: 'danger';
  allowOverflow?: boolean;
}

export const Container = styled.div<ReadOnlyInputProps>`
  padding: 4px;
  height: 46px;
  display: flex;
  flex-direction: column;
  border: solid 1px transparent;
  border-radius: 2px;
  background-color: ${props =>
    props.severity === 'danger' ? '#FFE3E3' : 'initial'};

  span {
    display: flex;
  }

  p {
    color: var(--grey-scale);
    white-space: nowrap;
    margin-bottom: 1px;
  }

  .s-readonly-input-content {
    overflow: ${props => (props.allowOverflow ? 'unset' : 'hidden')};
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
