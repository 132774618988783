import React, { Dispatch, useEffect, useRef, useState } from 'react';
import {
  DataTablePageEvent,
  SortOrder,
  DataTableSortEvent,
} from 'primereact/datatable';
import { gql, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRefHook } from '../../../../hooks/useRefHook';
import Grid from '../../../../components/Grid';
import { IRole } from '..';
import { Container } from './styles';
import ToastLife from '../../../../shared/enums/toastLife';
import { searchDelayMiliseconds } from '../../../../config/pagination';

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: SortOrder;
  globalFilter?: string;
  name?: string;
  active?: boolean;
}

interface IListOfRolesParams {
  selectedRoles: IRole[];
  setSelectedRoles: Dispatch<React.SetStateAction<IRole[]>>;
}

const ListOfRoles: React.FC<IListOfRolesParams> = ({
  selectedRoles,
  setSelectedRoles,
}) => {
  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Colunas da grid
  const columns = [{ field: 'name', header: 'Name' }];

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Query para listar roles
  const listRolesQuery = gql`
    query listAllRolesQuery(
      $listAllRolesInput: ListAllRolesInput
      $pagination: Pagination!
    ) {
      listAllRoles(
        listAllRolesInput: $listAllRolesInput
        pagination: $pagination
      ) {
        data {
          idRole
          name
          active
        }
        items
      }
    }
  `;

  /**
   * Busca Roles
   */
  const {
    loading: rolesLoading,
    data: rolesData,
    error: rolesError,
  } = useQuery(listRolesQuery, {
    variables: {
      listAllRolesInput: {
        globalSearch: lazyParams.globalFilter,
        name: lazyParams.name,
        active: true,
      },
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting roles',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = columns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        header={col.header}
        sortable
      />
    );
  });

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  return (
    <Container>
      {/* Busca global */}
      <InputText
        className="gridSearch"
        type="search"
        value={globalFilter}
        onChange={e => setGlobalFilter(e.target.value)}
        placeholder="Search for a role"
      />
      <Grid
        name="userRoles"
        lazy
        totalRecords={
          !rolesData || rolesError ? 0 : rolesData.listAllRoles.items
        }
        value={
          !rolesData || rolesError ? undefined : rolesData.listAllRoles.data
        }
        globalFilter={globalFilter}
        emptyMessage="No roles found."
        reorderableColumns
        selectionPageOnly
        removableSort
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedRoles}
        onSelectionChange={e => {
          if (isMounted.current) {
            setSelectedRoles(e.value);
          }
        }}
        loading={rolesLoading}
      >
        <Column
          columnKey="multiple"
          selectionMode="multiple"
          style={{ width: '3em' }}
          reorderable={false}
        />
        {dynamicColumns}
      </Grid>
    </Container>
  );
};

export default ListOfRoles;
