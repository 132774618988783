import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { useMutation } from '@apollo/client';
import FileUpload from '../../../../components/FileUpload';
import { FileType } from '../../../../shared/enums/fileType';
import { useRefHook } from '../../../../hooks/useRefHook';
import { createProductsAttachmentsBulkQuery } from './queries';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';

export interface ICreateProductsAttachmentsBulkRef {
  toggleModal(): void;
}

interface ICreateProductsAttachmentsBulkProps {
  ref: Ref<ICreateProductsAttachmentsBulkRef>;
}

const CreateProductsAttachmentsBulk: React.FC<ICreateProductsAttachmentsBulkProps> =
  forwardRef((_, ref) => {
    const [createProductsAttachmentsBulkMutation] = useMutation(
      createProductsAttachmentsBulkQuery,
    );

    const { showSuccess, showError } = useRefHook();

    const [displayModal, setDisplayModal] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    function handleCloseModal() {
      setDisplayModal(false);
    }

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        if (displayModal) {
          handleCloseModal();
        } else {
          setDisplayModal(true);
        }
      },
    }));

    async function handleUploadConfirm(data: FileUploadResponse[]) {
      setUploadInProgress(true);

      try {
        const response = await createProductsAttachmentsBulkMutation({
          variables: {
            uploadedFiles: data.map(file => file.serverName),
          },
        });

        showSuccess({
          summary: 'Attachments created',
          detail: `${response.data.createProductsAttachmentsBulk.length} attachments created`,
        });

        handleCloseModal();
      } catch (error) {
        showError({
          summary: 'Error while creating attachments',
          detail: error.message,
        });
      } finally {
        setUploadInProgress(false);
      }
    }

    return (
      <Dialog
        className="flex"
        header="Create Products Attachments"
        visible={displayModal}
        style={{ minWidth: '632px' }}
        onHide={() => handleCloseModal()}
        resizable
        closable
      >
        <FileUpload
          className="mb-2"
          multiple
          accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.TXT},${FileType.ZIP}`}
          onConfirm={e => handleUploadConfirm(e)}
          disabled={uploadInProgress}
          uploadInProgress={uploadInProgress}
        />

        <Panel header="Instructions" toggleable collapsed>
          <div className="flex">
            <div>
              <b>File Naming Pattern Guidelines:</b>
              <ul className="mt-2">
                <li>
                  Each file name must adhere to this pattern:
                  <ul>
                    <li>
                      STXXXXX<b>-</b>optional file name<b>-</b>TYPE_1<b>-</b>
                      TYPE_2
                    </li>
                  </ul>
                </li>
                <li>
                  Use a hyphen (<b>-</b>) to separate each piece of information
                </li>
                <li>
                  You can include as many types as you want in the file name
                </li>
                <li>
                  Example file name:
                  <ul>
                    <li>
                      ST12345<b>-</b>blue carpet<b>-</b>HRP<b>-</b>DIE_CUT
                      <b>-</b>COLOUR
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <Divider layout="vertical" />
            <div>
              <b className="white-space-nowrap">Accepted Data Types:</b>
              <ul className="mt-2">
                <li>PRODUCTS</li>
                <li>HRP</li>
                <li>COLOUR</li>
                <li>PRINTING</li>
                <li>DIE_CUT</li>
                <li>MAIN</li>
                <li>HRP_MAIN</li>
                <li>COLOUR_MAIN</li>
                <li>PRINTING_MAIN</li>
                <li>DIE_CUT_MAIN</li>
              </ul>
            </div>
          </div>
        </Panel>
      </Dialog>
    );
  });

export default CreateProductsAttachmentsBulk;
