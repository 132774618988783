import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import Grid from '../../../../components/Grid';
import Loading from '../../../../components/Loading';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import ILazyParams from '../../../../services/lazyParams';
import { ISupplier } from '../interfaces';
import { Container } from '../styles';
import { gridConstants } from '../../../../components/Grid/constants';
import { listAllSupplierRncsQuery, listRncTotalsQuery } from './queries';
import { calculateFinalRncCost } from '../../../Commercial/Sat/Rnc/utils';
import { renderNumber } from '../../../../utils/formatLocale';

interface IGeneralInformationProps extends PageTabContainerProps {
  supplier: ISupplier;
  selected: boolean;
}

interface IRncTotals {
  totalCostAmountBr: number;
  totalCostAmountSat: number;
  exchangeRate: number;
  refund: number;
  finalDebitNote: number;
}

interface IRncTotalsResponse {
  finalRncCost: number;
  refund: number;
  finalDebitNote: number;
}

const Rncs: React.FC<IGeneralInformationProps> = ({ supplier, selected }) => {
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:supplierRncsGridColumns';

  // Referencia ao toast
  const { showError } = useRefHook();

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: 0,
    rows: 25,
    page: 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>(initialLazyParams);

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = useCallback(
    (event: DataTablePageEvent) => {
      const newLazyParams = { ...lazyParams, ...event };
      setLazyParams(newLazyParams);
    },
    [lazyParams],
  );

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  const onSort = useCallback(
    (event: DataTableSortEvent) => {
      const newLazyParams = { ...lazyParams, ...event };
      setLazyParams(newLazyParams);
    },
    [lazyParams],
  );

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    columnList.push({ field: 'rncNumber', header: 'RNC Number' });
    columnList.push({ field: 'idSat2.satNumber', header: 'SAT Number' });
    columnList.push({
      field: 'idSat2.idClient2.name',
      header: 'Client/Notify',
    });
    columnList.push({ field: 'idStatus2.description', header: 'RNC Status' });
    columnList.push({
      field: 'idProblemType2.description',
      header: 'Problem Type',
    });
    columnList.push({
      field: 'idRncResponsible2.description',
      header: 'RNC Responsible',
    });
    columnList.push({
      field: 'problemDescription',
      header: 'Problem Description',
    });
    columnList.push({ field: 'proposedSolution', header: 'Problem Solution' });
    columnList.push({ field: 'idSat2.mainProduct', header: 'Main Product' });
    columnList.push({
      field: 'finalRncCost',
      header: 'Final RNC Cost (USD/EUR)',
    });
    columnList.push({ field: 'refund', header: 'Refund (USD/EUR)' });
    columnList.push({ field: 'finalDebitNote', header: 'Final Debit Note' });

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  const { loading: supplierRncsLoading, data: supplierRncsData } = useQuery(
    listAllSupplierRncsQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idSupplier: supplier.idSupplier,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting supplier RNCs',
          detail: errorData.message,
        });
      },
    },
  );

  const { loading: rncTotalsLoading, data: rncTotalsData } = useQuery(
    listRncTotalsQuery,
    {
      variables: {
        idSupplier: supplier.idSupplier,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting RNCs totals',
          detail: errorData.message,
        });
      },
    },
  );

  /**
   * Retorna coluna RNC Number com Link para pagina de Rnc da Sat
   * @param rowData Dados da linha
   * @returns Coluna RNC Number com Link para pagina de Rnc
   */
  const parseRncNumberColumn = (rowData: any) => {
    return (
      rowData.rncNumber && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=rnc&rncId=${rowData.idSatRnc}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.rncNumber}
          <FiExternalLink size={15} />
        </Link>
      )
    );
  };

  /**
   * Retorna coluna SAT NUmber com Link para pagina da Sat
   * @param rowData Dados da linha
   * @returns Coluna SAT Number com Link para pagina da SAT
   */
  const parseSatNumberColumn = (rowData: any) => {
    return (
      rowData?.idSat2?.satNumber && (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.idSat2.satNumber}
          <FiExternalLink size={15} />
        </Link>
      )
    );
  };

  /**
   * Retorna coluna Final Rnc Cost com o calculo realizado
   * @param rowData Dados da linha
   * @returns Coluna Final RNC Cost calculada
   */
  const parseFinalRncCostColumn = (rowData: any) => {
    return calculateFinalRncCost(
      rowData?.totalCostAmountBr,
      rowData?.totalCostAmountSat,
      rowData?.exchangeRate,
    );
  };

  /**
   * Soma os totais de RNC
   * @returns Totais de RNC
   */
  const getRncTotalsSum = (data: IRncTotals[]) => {
    return data.reduce(
      (acc: IRncTotalsResponse, val: IRncTotals) => {
        const finalRncCost = calculateFinalRncCost(
          val.totalCostAmountBr,
          val.totalCostAmountSat,
          val.exchangeRate,
        );

        return {
          finalRncCost: acc.finalRncCost + (finalRncCost ?? 0),
          refund: acc.refund + (val.refund ?? 0),
          finalDebitNote: acc.finalDebitNote + (val.finalDebitNote ?? 0),
        };
      },
      {
        finalRncCost: 0,
        refund: 0,
        finalDebitNote: 0,
      },
    );
  };

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'rncNumber':
      case 'idSat2.satNumber':
      case 'idStatus2.description':
      case 'idProblemType2.description':
      case 'idRncResponsible2.description':
        return { width: '150px' };
      case 'idSat2.idClient2.name':
      case 'idSat2.mainProduct':
        return { width: '200px' };
      case 'problemDescription':
      case 'proposedSolution':
        return { width: '250px' };
      default:
        return { width: '140px' };
    }
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'rncNumber':
        return parseRncNumberColumn;
      case 'idSat2.satNumber':
        return parseSatNumberColumn;
      case 'finalRncCost':
        return parseFinalRncCostColumn;
      default:
        return undefined;
    }
  }

  function handleColumnFooter(field: string) {
    if (rncTotalsLoading) return <Skeleton />;

    if (!rncTotalsData?.listRncCostsAndOtherExpensesTotalsBySupplier?.length) {
      return undefined;
    }

    const rncTotalsSum = getRncTotalsSum(
      rncTotalsData.listRncCostsAndOtherExpensesTotalsBySupplier,
    );

    switch (field) {
      case 'finalRncCost':
      case 'finalDebitNote':
      case 'refund':
        return renderNumber(rncTotalsSum[field], 'float');
      default:
        return undefined;
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          header={col.header}
          style={handleColumnSize(col.field)}
          footer={handleColumnFooter(col.field)}
          sortable={
            col.field !== 'finalRncCost' &&
            col.field !== 'refund' &&
            col.field !== 'finalDebitNote'
          }
        />
      )
    );
  });

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <Grid
          name="supplierRncs"
          lazy
          totalRecords={supplierRncsData?.listAllSupplierRncs?.items ?? 0}
          value={
            !supplierRncsData || !supplierRncsData.listAllSupplierRncs
              ? 0
              : supplierRncsData.listAllSupplierRncs.data
          }
          emptyMessage="No Rnc found"
          reorderableColumns
          removableSort
          scrollable
          rows={lazyParams.rows}
          first={lazyParams.first}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          scrollHeight={gridConstants.internalGridScrollHeight}
        >
          {dynamicColumns}
        </Grid>
        {supplierRncsLoading && <Loading />}
      </Container>
    </PageTabContainer>
  );
};

export default Rncs;
