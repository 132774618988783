import { gql } from '@apollo/client';

export const createClientBrandQuery = gql`
  mutation CreateClientBrand($data: CreateClientBrandInput!) {
    createClientBrand(data: $data) {
      idClientBrand
    }
  }
`;

export const updateClientBrandQuery = gql`
  mutation UpdateClientBrand($data: UpdateClientBrandInput!) {
    updateClientBrand(data: $data) {
      idClientBrand
    }
  }
`;
