export const productRoles = {
  idEntity: 37,
  idModule: 1,
  fields: {
    idFieldStCode: 670,
    idFieldModel: 671,
    idFieldSku: 672,
    idFieldNetWeight: 673,
    idFieldIsM2: 674,
    idFieldM2Pc: 675,
    idFieldLicensedProduct: 676,
    idFieldIdLicensor: 677,
    idFieldRoyaltiesLicenesed: 678,
    idFieldStock: 679,
    idFieldExclusivity: 680,
    idFieldIdClient: 681,
    idFieldIdCategory: 682,
    idFieldIdStatus: 683,
    idFieldVerified: 684,
    idFieldNameEn: 685,
    idFieldIdFinishing: 686,
    idFieldMaterialEn: 687,
    idFieldColourEn: 688,
    idFieldExtraInfoEn: 689,
    idFieldDescriptionEn: 690,
    idFieldNamePt: 691,
    idFieldDetailedDescriptionPt: 692,
    idFieldMaterialPt: 693,
    idFieldColourPt: 694,
    idFieldExtraInfoPt: 695,
    idFieldDescriptionPt: 696,
    idFieldDimensionNameEn: 697,
    idFieldDimensionNamePt: 698,
    idFieldQuantity: 699,
    idFieldVolume: 700,
    idFieldVolumeUnit: 701,
    idFieldCapacity: 702,
    idFieldLength: 703,
    idFieldUnitLength: 704,
    idFieldWidth: 705,
    idFieldUnitWidth: 706,
    idFieldHeight: 707,
    idFieldUnitHeight: 708,
    idFieldTopDiameter: 709,
    idFieldUnitTopDiameter: 710,
    idFieldBottomDiameter: 711,
    idFieldUnitBottomDiameter: 712,
    idFieldThickness: 713,
    idFieldUnitThickness: 714,
    idFieldDepth: 715,
    idFieldUnitDepth: 716,
    idFieldGrammage: 717,
    idFieldUnitGrammage: 718,
    // Aba Package
    // Package Description
    idFieldSellingPackageDescription: 719,
    idFieldResponsible: 850,
    idFieldComments: 865,
    // Type
    idFieldTypePackage: 851,
    // References x Sizes
    idFieldProductPackageReferencesColourEn: 852,
    idFieldProductPackageReferencesColourPt: 853,
    idFieldProductPackageReferencesIdGender: 854,
    idFieldProductPackageReferencesIdSizeGroup: 855,
    idFieldProductPackageReferencesMasterBarcode: 856,
    idFieldProductPackageReferencesAttachment: 864,
    // Size Barcode
    idFieldProductPackageSizeBarcodesUnit: 857,
    idFieldProductPackageSizeBarcodesSize: 858,
    idFieldProductPackageSizeBarcodesSizeRange: 859,
    idFieldProductPackageSizeBarcodesReference: 860,
    idFieldProductPackageSizeBarcodesSellingPackage: 861,
    idFieldProductPackageSizeBarcodesInnerBox: 862,
    idFieldProductPackageSizeBarcodesMasterBox: 863,
    idFieldProductPackageSizeBarcodesAuto: 866,
    idFieldSellingPackageDefault: 720,
    idFieldSellingPackageCostPrice: 721,
    idFieldSellingPackageUnit: 722,
    idFieldSellingPackageQty: 723,
    idFieldSellingPackageLength: 724,
    idFieldSellingPackageWidth: 725,
    idFieldSellingPackageHeight: 726,
    idFieldSellingPackageGrossWeight: 727,
    idFieldInnerBoxUnSetPerIner: 728,
    idFieldInnerBoxLength: 729,
    idFieldInnerBoxWidth: 730,
    idFieldInnerBoxHeight: 731,
    idFieldInnerBoxGrossWeight: 732,
    idFieldInnerBoxDisplay: 733,
    idFieldMasterBoxInnerPerCtn: 734,
    idFieldMasterBoxUnSetPerCtn: 735,
    idFieldMasterBoxLength: 736,
    idFieldMasterBoxWidth: 737,
    idFieldMasterBoxHeight: 738,
    idFieldMasterBoxCbm: 739,
    idFieldMasterBoxNetWeight: 740,
    idFieldMasterBoxGrossWeight: 741,
    idFieldPalletUnSetPerInner: 742,
    idFieldPalletLength: 743,
    idFieldPalletWidth: 744,
    idFieldPalletHeight: 745,
    idFieldPalletGrossWeight: 746,
    idFieldPalletDisplay: 747,
    idFieldPackageNote: 748,
    idFieldBarcodeClient: 749,
    idFieldBarcodeSellingPackage: 750,
    idFieldBarcodeInnerBox: 751,
    idFieldBarcodeMasterBox: 752,
    idFieldBarcodeAuto: 753,
    idFieldIdSupplier: 754,
    idFieldAging: 755,
    idFieldMoq: 756,
    idFieldPurchaseIncoterm: 757,
    idFieldSalesIncoterm: 758,
    idFieldMoqNote: 759,
    idFieldPurchaseCurrency: 760,
    idFieldSalesCurrency: 761,
    idFieldCotPrice: 762,
    idFieldSunPrice: 763,
    idFieldIsDefault: 764,
    idFieldCotPriceNotes: 765,
    idFieldSunPriceNotes: 766,
    idFieldMarkup: 767,
    idFieldProfit: 768,
    idFieldSat: 769,
    idFieldRecordedBy: 770,
    idFieldRecordedAt: 771,
    idFieldHsCode: 772,
    idFieldCertifyingEntity: 773,
    idFieldRegisterNumber: 774,
    idFieldValidityDate: 775,
    idFieldNcmName: 776,
    idFieldNcm: 777,
    idFieldDestaque: 778,
    idFieldNcmClient: 779,
    idFieldTradeReview: 780,
    idFieldLi: 781,
    idFieldDumping: 782,
    idFieldBaseDumping: 783,
    idFieldPrecoMinimo: 784,
    idFieldNveDe1Ate6: 785,
    idFieldSatNumber: 786,
    idFieldSatClient: 787,
    idFieldClientCnpj: 788,
    idFieldImporter: 789,
    idFieldImporterCnpj: 790,
    idFieldSales: 791,
    idFieldPortOfDischarge: 792,
    idFieldPaymentTermAdvance: 793,
    idFieldPaymentTermBalance: 794,
    idFieldClients: 795,
    idFieldUnitCapacity: 796,

    idFieldDumpingDutyCurrency: 235,
    idFieldDumpingDutyValue: 236,
    idFieldEnhance: 238,
    idFieldFreightCurrency: 249,
    idFieldFreightValue: 250,
    idFieldUnitsPerContainer: 334,

    // Tela de produtos
    idFieldidCertifyingEntity: 210,
    idFieldIdCheckList: 259,
    idFieldImportLicense: 273,
    idFieldIndIncoterm: 277,
    idFieldUnit: 333,

    idFieldPriceHistoryCotPrice: 797,
    idFieldPriceHistoryMarkup: 798,
    idFieldPriceHistoryMoq: 799,
    idFieldPriceHistoryProfit: 800,
    idFieldPriceHistorySupplier: 801,
    idFieldPriceHistorySunPrice: 802,
    idFieldPriceHistorySupplierName: 803,
    idFieldPriceHistoryAction: 1792,
    idFieldPriceHistoryAging: 1793,
    idFieldPriceHistoryIncoterm: 1794,
    idFieldPriceHistorySalesIncoterm: 1795,
    idFieldPriceHistoryPurchaseCurrency: 1796,
    idFieldPriceHistorySalesCurrency: 1797,

    idFieldReference: 804,
    // Aba Artwork / Technique
    idFieldArtworkTechniqueNamePcEn: 805,
    idFieldArtworkTechniqueNamePcPt: 806,
    idFieldArtworkTechniqueIndAopArtw: 807,
    idFieldArtworkTechniqueCodeAopArtw: 808,
    idFieldArtworkTechniqueIndLocPrintArtw: 809,
    idFieldArtworkTechniqueCodeLocPrintArtw: 810,
    idFieldArtworkTechniqueIndEmbPatArtw: 811,
    idFieldArtworkTechniqueCodEmbPatArtw: 812,
    idFieldArtworkTechniqueIndYarnDyeTech: 813,
    idFieldArtworkTechniqueIndRotPrintTech: 814,
    idFieldArtworkTechniqueIndSilkScreenTech: 815,
    idFieldArtworkTechniqueIndJacquardTech: 816,
    idFieldArtworkTechniqueIndSublimationTech: 817,
    idFieldArtworkTechniqueIndEmbroideryTech: 818,
    idFieldArtworkTechniqueIndSolidColorTech: 819,
    idFieldArtworkTechniqueIndOthersTech: 820,
    idFieldArtworkTechniqueDsOthersTech: 821,
  },
  permissions: {
    idPermissionExportProductList: 65,
    idPermissionMassiveAction: 66,
    idPermissionDeleteProduct: 64,
    idPermissionCreateSat: 67,
    idPermissionDuplicateProduct: 68,
    idPermissionAddNcm: 69,
    idPermissionCreateProduct: 62,
    idPermissionUpdateProduct: 63,
    idPermissionMassiveActionChecklist: 102,
    idPermissionCreateClientQuotation: 211,
  },
};

export const productViewRoles = {
  idEntity: 37,
  idModule: 1,
  fields: {
    stCode: 2007,
    sunNumber: 2008,
    model: 2009,
    nameEn: 2010,
    descriptionEn: 2011,
    materialEn: 2012,
    colourEn: 2013,
    category: 2014,
    hsCode: 2015,
    purchaseCurrency: 2016,
    cotPrice: 2017,
    salesCurrency: 2018,
    sunPrice: 2019,
    markup: 2020,
    namePt: 2021,
    colourPt: 2022,
    licensedProduct: 2023,
    stock: 2024,
    exclusivity: 2025,
    sku: 2026,
    detailedDescriptionPt: 2027,
    materialPt: 2028,
    descriptionPt: 2029,
    finishing: 2030,
    extraInfo: 2031,
    dtAging: 2032,
    profit: 2033,
    clientsBrand: 2034,
    brands: 2035,
    ncmClient: 2036,
    dumping: 2037,
    li: 2038,
    tradeReview: 2039,
    ncm: 2040,
    ncmName: 2041,
    precoMinimo: 2042,
    reference: 2043,
    sellingPackageDescription: 2044,
    responsible: 2045,
    typeOfPackage: 2046,
    unit: 2047,
    salesIncoterm: 2048,
    packageEan13: 2229,
    innerDun14: 2230,
    masterDun14: 2231,
  },
};
