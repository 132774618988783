import { useField } from '@unform/core';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import React, { useEffect, useRef, useState } from 'react';

import { Container } from './styles';

/**
 * Interface do Componente de Boolean Radio
 */
interface IFormBooleanRadioProps {
  /**
   * Label do campo
   */
  label: string;

  /**
   * Nome do campo
   */
  name: string;

  /**
   * Valor padrao
   */
  defaultValue?: boolean;

  /**
   * Valor fixo
   */
  value?: boolean;

  /**
   * Indica se componente nao tera background
   */
  readOnly?: boolean;

  /**
   * Indica se componente nao permite alteracao
   */
  disabled?: boolean;
  /**
   * Funcao executada ao ocorrer mudanca de valor
   * @param e Parametro de evento
   */
  onValueChange?(e: RadioButtonChangeEvent): void;

  /**
   * Classe do componente
   */
  className?: string;
}

const FormBooleanRadio: React.FC<IFormBooleanRadioProps> = ({
  label,
  name,
  onValueChange,
  readOnly,
  disabled,
  className,
  value,
}) => {
  // Valores dos fields
  const { fieldName, registerField, defaultValue } = useField(name);

  const trueInputRef = useRef<HTMLInputElement>(null);

  // Valor do componente
  const [booleanValue, setBooleanValue] = useState<boolean>(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: trueInputRef.current,
      getValue: ref => {
        if (readOnly) return booleanValue;

        return ref.checked;
      },
      setValue: (_, newValue: boolean) => {
        setBooleanValue(newValue);
      },
    });
  }, [booleanValue, fieldName, readOnly, registerField]);

  /**
   * Funcao executada ao ocorrer mudanca de valor
   * @param e Evento de mudanca de valor
   */
  function handleValueChange(e: RadioButtonChangeEvent) {
    // Define valor do componente
    setBooleanValue(e.value);

    // Valida se usuario informou funcao para mudanca de valor no componente
    if (onValueChange) {
      // Repassa evento para funcao informada pelo usuario
      onValueChange(e);
    }
  }

  // Se usuario informar o parametro value, utiliza o valor fixo informado
  // Caso contrario, utiliza o valor do state do componente
  const componentValue = value !== undefined ? value : booleanValue;

  return (
    <div
      className={`${className ? `${className} form-element` : 'form-element'}`}
    >
      <Container readOnly={readOnly}>
        <p>{label}:</p>
        {readOnly ? (
          <div>{componentValue ? <p>Yes</p> : <p>No</p>}</div>
        ) : (
          <div>
            <RadioButton
              inputRef={trueInputRef}
              inputId="yes"
              value
              checked={componentValue}
              onChange={e => handleValueChange(e)}
              disabled={disabled}
            />
            <p>Yes</p>
            <RadioButton
              inputId="no"
              value={false}
              checked={!componentValue}
              onChange={e => handleValueChange(e)}
              disabled={disabled}
            />
            <p>No</p>
          </div>
        )}
      </Container>
    </div>
  );
};

export default FormBooleanRadio;
