import {
  EditReplyDialogActionKind,
  EditReplyDialogReducer,
  EditReplyDialogReducerAction,
} from './interfaces';

export const editReplyDialogReducerInitialState: EditReplyDialogReducer = {
  dialogVisible: false,
};

export const editReplyDialogReducer = (
  state: EditReplyDialogReducer,
  action: EditReplyDialogReducerAction,
): EditReplyDialogReducer => {
  switch (action.type) {
    case EditReplyDialogActionKind.CLOSE_DIALOG:
      return {
        ...state,
        dialogVisible: false,
        commentToEdit: undefined,
      };

    case EditReplyDialogActionKind.OPEN_DIALOG: {
      if (!action.payload) {
        throw new Error('You need to provide the comment to edit');
      }

      return {
        ...state,
        dialogVisible: true,
        commentToEdit: action.payload.commentToEdit,
      };
    }

    default:
      throw new Error();
  }
};
