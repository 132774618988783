import { gql } from '@apollo/client';

export const processRegisterClassificacaoFiscalQuery = gql`
  mutation processRegisterClassificacaoFiscalRequest(
    $data: ProcessRegisterClassificacaoFiscalRequestInput!
  ) {
    processRegisterClassificacaoFiscalRequest(data: $data) {
      idSatProcessResult
    }
  }
`;
