import { gql } from '@apollo/client';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';
import pagination from '../../config/pagination';
import client from '../../services/apollo/client';

export const asyncSelectLoadClients: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllClientsQuery($listAllClientsInput: ListAllClientsInput!) {
        listAllClients(listAllClientsInput: $listAllClientsInput) {
          data {
            idClient
            name
            companyName
            cnpj
            address
            paymentAdvance
            paymentBalance
            payment2 {
              idDomain
              description
            }
            clientPorts {
              idPort2 {
                idPort
                name
              }
            }
          }
          items
        }
      }
    `,
    variables: {
      listAllClientsInput: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
          _orderBy: 'name',
          _sortOrder: 'ASC',
        },
        name: search,
        active: true,
      },
    },
  });

  const filteredOptions = res.data.listAllClients.data;
  const quantity = res.data.listAllClients.items;

  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};
