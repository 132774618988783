import styled from 'styled-components';

export const Container = styled.div`
  .p-datatable {
    max-width: 85rem;
  }

  .p-row-expanded > td {
    padding: 0 !important;
  }

  .p-datatable-tfoot > tr > td {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 1300px) {
    .p-datatable {
      max-width: 80vw;
    }
  }
`;
