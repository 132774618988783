/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import PageHeader from '../../../components/PageHeader';
import Image from '../../../components/PageHeader/Image';
import imagePlaceholder from '../../../assets/imagePlaceholder.svg';
import { Container, CrudForm } from './styles';
import { useRefHook } from '../../../hooks/useRefHook';
import PageMenuButton from '../../../components/PageMenuButton';
import GeneralInformation from './GeneralInformation';
import CostStudies from './CostStudies';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Tag from '../../../components/Tag';
import Loading from '../../../components/Loading';
import Attachments from './Attachments';
import Branding from './Branding';
import Contact from './Contact';

import {
  ICreateClientFormData,
  IClient,
  IClientContact,
  ClientRouteParams,
} from './interfaces';
import getValidationErrors from '../../../utils/getValidationErrors';
import { clientRoles } from '../../../shared/roles/client';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import PageMenu from '../../../components/PageMenu';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';

const Client: React.FC = () => {
  // Redirect
  const history = useHistory();

  // URL
  const { pathname } = useLocation();

  // Client ID
  const clientId = parseInt(useParams<ClientRouteParams>().clientId, 10);

  const defaultPageTitle = `${clientId ? 'Manage' : 'Create'} Client`;

  // Client
  const [client, setClient] = useState<IClient>({} as IClient);
  const [clientName, setClientName] = useState('');

  useTitle(
    client?.cltNumber
      ? `${client.cltNumber} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // URL da imagem
  const [imageUrl, setImageUrl] = useState('');

  // URL params
  const location = useLocation();

  // ID da entity de usuario
  const { idEntity } = clientRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);

  const [clientContacts, setClientContacts] = useState<IClientContact[]>([]);

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL temporaria para upload de arquivo
  const [uploadImageTempUrl, setUploadImageTempUrl] = useState('');

  // Dados para retornar para lista
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Clients',
      path:
        initialPage && initialFirst
          ? `/clients/list?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/clients/list',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Item de menu selecionado
  const [selectedMenuItem, setSelectedMenuItem] =
    useState('GeneralInformation');

  // Query para listar client
  const listClientQuery = gql`
    query listClientQuery($idClient: Int!) {
      listClientById(idClient: $idClient) {
        idClient
        cltNumber
        status
        businessType
        operationType
        numberOfStores
        payment
        courier
        incoterm
        name
        companyName
        cnpj
        idCountry
        idRegion
        idCity
        address
        zipCode
        paymentAdvance
        paymentBalance
        paymentDeadline
        website
        salesMultiplier
        costsMultiplier
        createdBy
        createdAt
        updatedBy
        updatedAt
        cityText
        versionLock
        active
        idLogo
        clientService
        imageUrl
        meetingAddress
        clientSpecialNegotiations {
          idClientSpecialNegotiation
          idSpecialNegotiation
          iiType
          iiValue
          ipiType
          ipiValue
          pisType
          pisValue
          cofinsType
          cofinsValue
          icmsType
          icmsValue
          idSpecialNegotiation2 {
            idDomain
            description
          }
        }
        clientPorts {
          idClientPort
          idPort
          isPrimary
          idPort2 {
            idPort
            name
          }
        }
        brokerInformations {
          idBrokerInformation
          isPrimary
          brokerInformation
          versionLock
        }
        clientCustomerServices {
          idUser
          idUser2 {
            idUser
            username
          }
        }
        clientService2 {
          idDomain
          description
        }
        incoterm2 {
          idDomain
          description
        }
        payment2 {
          idDomain
          description
        }
        numberOfStores2 {
          idDomain
          description
        }
        operationType2 {
          idDomain
          description
        }
        businessType2 {
          idDomain
          description
        }
        status2 {
          idDomain
          description
        }
        courier2 {
          idDomain
          description
        }
        idCountry2 {
          idCountry
          name
        }
        idRegion2 {
          idRegion
          name
        }
        idCity2 {
          idCity
          name
        }
        clientContacts {
          idClientContact
          idRoleContact
          name
          department
          email
          area
          telephone
          idRoleContact2 {
            idDomain
            description
          }
        }
      }
    }
  `;

  /**
   * Get data client
   */
  const [loadClientData, { loading: clientLoading, data: clientData }] =
    useLazyQuery(listClientQuery, {
      onCompleted: response => {
        if (response.listClientById) {
          setClient(response.listClientById);
          /* setClient({
            ...response.listClientById,
            cnpj: response.listClientById.cnpj
              ? response.listClientById.cnpj.replace(/[./-]/g, '')
              : undefined,
          }); */
          setClientName(response.listClientById.name);
          setImageUrl(response.listClientById.imageUrl);
          setClientContacts(response.listClientById.clientContacts);
          if (response.listClientById.clientSpecialNegotiations) {
            response.listClientById.clientSpecialNegotiations.forEach(
              (specialNegotiation: any) => {
                if (specialNegotiation.iiType === 451) {
                  specialNegotiation.iiValueDiscount =
                    specialNegotiation.iiValue;
                }
                if (specialNegotiation.iiType === 452) {
                  specialNegotiation.iiValueModification =
                    specialNegotiation.iiValue;
                }
                if (specialNegotiation.ipiType === 451) {
                  specialNegotiation.ipiValueDiscount =
                    specialNegotiation.ipiValue;
                }
                if (specialNegotiation.ipiType === 452) {
                  specialNegotiation.ipiValueModification =
                    specialNegotiation.ipiValue;
                }
                if (specialNegotiation.pisType === 451) {
                  specialNegotiation.pisValueDiscount =
                    specialNegotiation.pisValue;
                }
                if (specialNegotiation.pisType === 452) {
                  specialNegotiation.pisValueModification =
                    specialNegotiation.pisValue;
                }
                if (specialNegotiation.cofinsType === 451) {
                  specialNegotiation.cofinsValueDiscount =
                    specialNegotiation.cofinsValue;
                }
                if (specialNegotiation.cofinsType === 452) {
                  specialNegotiation.cofinsValueModification =
                    specialNegotiation.cofinsValue;
                }
                if (specialNegotiation.icmsType === 451) {
                  specialNegotiation.icmsValueDiscount =
                    specialNegotiation.icmsValue;
                }
                if (specialNegotiation.icmsType === 452) {
                  specialNegotiation.icmsValueModification =
                    specialNegotiation.icmsValue;
                }
              },
            );
          }
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Client not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting client data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/clients/list'),
    });
  }

  // Mutation de criacao de client
  const createClientQuery = gql`
    mutation CreateClientMutation($client: CreateClientInput) {
      createClient(client: $client) {
        idClient
      }
    }
  `;

  // Mutation de atualizacao de client
  const updateClientQuery = gql`
    mutation UpdateClientMutation($client: UpdateClientInput!) {
      updateClient(client: $client) {
        idClient
      }
    }
  `;

  // cria método para chamar a mutation
  const [createClientMutation] = useMutation(createClientQuery);
  const [updateClientMutation] = useMutation(updateClientQuery);

  // Submit do formulario de criacao
  const handleCreateClientSubmit = useCallback(
    async (data: ICreateClientFormData) => {
      if (!shouldSubmit) return;
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        if (data.clientPorts) {
          const ports = data.clientPorts.filter(
            port => port.isPrimary === true,
          );
          if (ports.length !== 1) {
            // Exibe toast sobre o erro
            toastRef.current?.show({
              severity: 'warn',
              summary: 'Primary Port/Airport',
              detail: `Enter one primary port. `,
              life: ToastLife.WARN,
            });
            return;
          }
        }

        if (data.brokerInformations) {
          const brokers = data.brokerInformations.filter(
            broker => broker.isPrimary === true,
          );
          if (brokers.length !== 1) {
            // Exibe toast sobre o erro
            toastRef.current?.show({
              severity: 'warn',
              summary: 'Primary Broker Fee',
              detail: `Enter one primary broker fee. `,
              life: ToastLife.WARN,
            });
            return;
          }
        }

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Required Field'),
          companyName: Yup.string().required('Required Field'),
          cnpj: Yup.string().required('Required Field'),
          clientPorts: Yup.array().of(
            Yup.object().shape({
              idPort: Yup.string()
                .nullable()
                .required('Port/Airport Required Field'),
            }),
          ),
          brokerInformations: Yup.array().of(
            Yup.object().shape({
              brokerInformation: Yup.number()
                .nullable()
                .required('Broker Fee Required Field'),
            }),
          ),
          country: Yup.object()
            .shape({
              idCountry: Yup.number(),
              name: Yup.string(),
            })
            .nullable()
            .required('Enter a country'),
          businessType: Yup.object()
            .shape({
              idDomain: Yup.number(),
              description: Yup.string(),
            })
            .nullable()
            .required('Enter a Type of Business'),
          status: Yup.object()
            .shape({
              idDomain: Yup.number(),
              description: Yup.string(),
            })
            .nullable()
            .required('Enter a Status'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valida se esta editando ou criando um novo client
        if (!clientId) {
          // Transforma objeto de client contacts para o backend
          const parsedClientContacts = clientContacts.map(clientContact => {
            return {
              name: clientContact.name,
              area: clientContact.area,
              telephone: clientContact.telephone,
              email: clientContact.email,
              idRoleContact: clientContact.idRoleContact2?.idDomain,
              department: clientContact.department,
            };
          });

          // Transforma objeto de client Special Negotiations para o backend
          const parsedClientSpecialNegotiations =
            client.clientSpecialNegotiations?.map(
              (specialNegotiation, index) => {
                return {
                  idClientSpecialNegotiation:
                    typeof client.clientSpecialNegotiations[index]
                      .idClientSpecialNegotiation === 'number'
                      ? client.clientSpecialNegotiations[index]
                          .idClientSpecialNegotiation
                      : undefined,
                  idSpecialNegotiation:
                    data.clientSpecialNegotiations[index].idSpecialNegotiation
                      .idDomain,
                  iiType: specialNegotiation.iiType,
                  iiValue: specialNegotiation.iiValue,
                  ipiType: specialNegotiation.ipiType,
                  ipiValue: specialNegotiation.ipiValue,
                  pisType: specialNegotiation.pisType,
                  pisValue: specialNegotiation.pisValue,
                  cofinsType: specialNegotiation.cofinsType,
                  cofinsValue: specialNegotiation.cofinsValue,
                  icmsType: specialNegotiation.icmsType,
                  icmsValue: specialNegotiation.icmsValue,
                };
              },
            );

          // Chamada criacao de client
          await createClientMutation({
            variables: {
              client: {
                // General Fields
                name: data.name,
                companyName: data.companyName,
                cnpj: data.cnpj, // ? data.cnpj.replace(/[./-]/g, '') : undefined,
                status: data.status?.idDomain,
                businessType: data.businessType?.idDomain,
                numberOfStores: data.numberOfStores?.idDomain,
                operationType: data.operationType?.idDomain,
                paymentAdvance: client.paymentAdvance,
                paymentBalance: client.paymentBalance,
                paymentDeadline: data.paymentDeadline,
                payment: data.payment?.idDomain,
                website: data.website,
                customerServices: data.customerServices,
                active: true,
                versionLock: 0,
                idLogo: client.idLogo,
                // Address Fields
                idCountry: data.country.idCountry,
                idRegion: data.region.idRegion,
                address: data.address,
                idCity: data.cityName.idCity,
                cityName:
                  data.cityName && data.cityName.idCity
                    ? undefined
                    : data.cityName,
                zipCode: data.zipCode,
                // Client Info Fields
                courier: data.courier?.idDomain,
                meetingAddress: data.meetingAddress,
                // Ports
                clientPorts: data.clientPorts,
                // Cost Studies Fiels
                salesMultiplier: data.salesMultiplier,
                costsMultiplier: data.costsMultiplier,
                incoterm: data.incoterm?.idDomain,
                clientService: data.clientService?.idDomain,
                brokerInformations: data.brokerInformations,
                clientSpecialNegotiations: parsedClientSpecialNegotiations,
                // Lists
                clientContacts: parsedClientContacts,
              },
            },
          });
        } else {
          // Transforma objeto de contacts para o backend
          const parsedClientContacts = clientContacts.map(clientContact => {
            return {
              idClientContact:
                typeof clientContact.idClientContact === 'number'
                  ? clientContact.idClientContact
                  : undefined,
              name: clientContact.name,
              area: clientContact.area,
              telephone: clientContact.telephone,
              email: clientContact.email,
              idRoleContact: clientContact.idRoleContact2?.idDomain,
              department: clientContact.department,
            };
          });

          // Transforma objeto de brokerFees para o backend
          const parsedBrokerFees = data.brokerInformations?.map(
            (brokerFee, index) => {
              return {
                ...brokerFee,
                idBrokerInformation:
                  typeof client.brokerInformations[index]
                    .idBrokerInformation === 'number'
                    ? client.brokerInformations[index].idBrokerInformation
                    : undefined,
              };
            },
          );

          const parsedClientPorts = data.clientPorts?.map(
            (clientPort, index) => {
              return {
                ...clientPort,
                idClientPort:
                  typeof client.clientPorts[index].idClientPort === 'number'
                    ? client.clientPorts[index].idClientPort
                    : undefined,
              };
            },
          );

          // Transforma objeto de client Special Negotiations para o backend
          const parsedClientSpecialNegotiations =
            client.clientSpecialNegotiations?.map(
              (specialNegotiation, index) => {
                return {
                  idClientSpecialNegotiation:
                    typeof client.clientSpecialNegotiations[index]
                      .idClientSpecialNegotiation === 'number'
                      ? client.clientSpecialNegotiations[index]
                          .idClientSpecialNegotiation
                      : undefined,
                  idSpecialNegotiation:
                    data.clientSpecialNegotiations[index].idSpecialNegotiation
                      .idDomain,
                  iiType: specialNegotiation.iiType,
                  iiValue: specialNegotiation.iiValue,
                  ipiType: specialNegotiation.ipiType,
                  ipiValue: specialNegotiation.ipiValue,
                  pisType: specialNegotiation.pisType,
                  pisValue: specialNegotiation.pisValue,
                  cofinsType: specialNegotiation.cofinsType,
                  cofinsValue: specialNegotiation.cofinsValue,
                  icmsType: specialNegotiation.icmsType,
                  icmsValue: specialNegotiation.icmsValue,
                };
              },
            );

          // Chamada atualizacao
          await updateClientMutation({
            variables: {
              client: {
                // General Fields
                idClient: clientId,
                name: data.name,
                companyName: data.companyName,
                cnpj: data.cnpj, // ? data.cnpj.replace(/[./-]/g, '') : undefined,
                status: data.status?.idDomain,
                businessType: data.businessType?.idDomain,
                numberOfStores: data.numberOfStores?.idDomain,
                operationType: data.operationType?.idDomain,
                paymentAdvance: data.paymentAdvance,
                paymentBalance: data.paymentBalance,
                paymentDeadline: data.paymentDeadline,
                payment: data.payment?.idDomain,
                website: data.website,
                customerServices: data.customerServices,
                versionLock: client.versionLock,
                idLogo: client.idLogo,
                // Address Fields
                idCountry: data.country.idCountry,
                idRegion: data.region.idRegion,
                address: data.address,
                idCity: data.cityName.idCity,
                cityName:
                  data.cityName && data.cityName.idCity
                    ? undefined
                    : data.cityName,
                zipCode: data.zipCode,
                // Client Info Fields
                courier: data.courier?.idDomain,
                meetingAddress: data.meetingAddress,
                // Ports
                clientPorts: parsedClientPorts,
                // Cost Studies Fiels
                salesMultiplier: data.salesMultiplier,
                costsMultiplier: data.costsMultiplier,
                incoterm: data.incoterm?.idDomain,
                clientService: data.clientService?.idDomain,
                brokerInformations: parsedBrokerFees,
                clientSpecialNegotiations: parsedClientSpecialNegotiations,
                // Lists
                clientContacts: parsedClientContacts,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Client ${clientId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem
        history.push('/clients/list');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Define guia selecionada de acordo com input com erro
          switch (true) {
            case firstError.path?.includes('name'):
              setSelectedMenuItem('GeneralInformation');
              break;
            case firstError.path?.includes('clientSpecialNegotiations'):
              setSelectedMenuItem('CostStudies');
              break;
            default:
              break;
          }

          if (
            firstError.path === 'country' ||
            firstError.path === 'businessType' ||
            firstError.path === 'status'
          ) {
            firstError.path = 'name';
          } else if (firstError.path?.includes('clientSpecialNegotiations')) {
            firstError.path = 'salesMultiplier';
          }

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);
          // Define foco no input
          inputWithError.focus();

          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Enter all required fields',
            detail: `${error.errors.length} fields with errors`,
            life: ToastLife.WARN,
          });
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${clientId ? 'updating' : 'creating'} client`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
        setShouldSubmit(false);
      }
    },
    [
      shouldSubmit,
      formRef,
      clientId,
      toastRef,
      history,
      clientContacts,
      client.clientSpecialNegotiations,
      client.paymentAdvance,
      client.paymentBalance,
      client.idLogo,
      client.versionLock,
      client.brokerInformations,
      client.clientPorts,
      createClientMutation,
      updateClientMutation,
    ],
  );

  const changePropertyValue = (property: string, value: any) => {
    setClient(prevClient => ({
      ...prevClient,
      [property]: value,
    }));
  };

  useEffect(() => {
    if (clientId) {
      loadClientData({
        variables: {
          idClient: clientId,
        },
      });
    }
    return () => setClient({} as IClient);
  }, [loadClientData, clientId]);

  useEffect(() => {
    if (shouldSubmit) {
      formRef.current?.submitForm();
    }
  }, [formRef, shouldSubmit]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          clientId ? `${defaultPageTitle} - ${clientName}` : defaultPageTitle
        }
        minScrollStickyButtons={160}
        hasImage
      >
        {clientData ? (
          client.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}

        {!!clientId && client.taxmissing && (
          <Tag className="tag" value="Tax Missing" />
        )}

        {!!clientId && client.outdated && (
          <Tag className="tag" value="Outdated" severity="info" />
        )}

        {!!clientId && client.verified && (
          <Tag className="tag" value="Verified" severity="success" />
        )}

        <Image
          className="image"
          src={
            !uploadImageTempUrl
              ? imageUrl || imagePlaceholder
              : uploadImageTempUrl
          }
          alt="Client main image"
        />
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            setShouldSubmit(true);
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {((clientId && !clientLoading && client.active !== undefined) ||
        !clientId) && (
        <CrudForm>
          <PageMenu menuWidth="183px">
            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('GeneralInformation');
              }}
              selected={selectedMenuItem === 'GeneralInformation'}
              title="General Information"
            />

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('CostStudies');
              }}
              selected={selectedMenuItem === 'CostStudies'}
              title="Cost Studies"
            />

            {!!clientId && (
              <>
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Attachments');
                  }}
                  selected={selectedMenuItem === 'Attachments'}
                  title="Attachments"
                />
                <PageMenuButton
                  onClick={() => {
                    setSelectedMenuItem('Branding');
                  }}
                  selected={selectedMenuItem === 'Branding'}
                  title="Branding"
                />
              </>
            )}

            <PageMenuButton
              onClick={() => {
                setSelectedMenuItem('Contact');
              }}
              selected={selectedMenuItem === 'Contact'}
              title="Contact"
            />
          </PageMenu>
          <Form
            ref={formRef}
            initialData={client}
            onSubmit={handleCreateClientSubmit}
          >
            <GeneralInformation
              selected={selectedMenuItem === 'GeneralInformation'}
              client={client}
              setClient={setClient}
              roleEntityFields={userPermissions.userFields}
            />
            <CostStudies
              selected={selectedMenuItem === 'CostStudies'}
              client={client}
              setClient={setClient}
              roleEntityFields={userPermissions.userFields}
            />
            {!!clientId && (
              <>
                <Attachments
                  idClient={clientId}
                  selected={selectedMenuItem === 'Attachments'}
                  changePropertyValue={changePropertyValue}
                  idMainImage={client.idLogo}
                  setUploadImageTempUrl={setUploadImageTempUrl}
                />

                <Branding
                  idClient={clientId}
                  selected={selectedMenuItem === 'Branding'}
                />
              </>
            )}

            <Contact
              selected={selectedMenuItem === 'Contact'}
              clientContacts={clientContacts}
              setClientContacts={setClientContacts}
            />
          </Form>
        </CrudForm>
      )}
      {(clientLoading || loading) && <Loading />}
    </Container>
  );
};

export default Client;
