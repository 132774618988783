import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import React, { Ref, useImperativeHandle, useMemo, useState } from 'react';
import Grid from '../../../../../../components/Grid';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import {
  parseImageColumn,
  parseStCodeColumn,
} from '../../../../../../utils/gridColumnsParse';
import { SatItem, SatReferenceSizeBarcode } from './interfaces';
import { listAvailableSatItemsToForeignTradeQuery } from './queries';
import ReferenceSizes from './ReferenceSizes';

import { Container } from './styles';

export interface IItemsGridRef {
  getItems(): {
    satItems: number[];
    satReferenceSizeBarcodes: number[];
  };
}

interface IItemsGridProps {
  ref: Ref<IItemsGridRef>;
  idSat: number;
  idSatForeignTrade?: number;
}

const ItemsGrid: React.FC<IItemsGridProps> = React.forwardRef(
  ({ idSat, idSatForeignTrade }, ref) => {
    const { toastRef } = useRefHook();

    const [satItems, setSatItems] = useState<SatItem[]>([]);

    const [selectedSatItems, setSelectedSatItems] = useState<SatItem[]>([]);
    const [
      selectedSatReferenceSizeBarcodes,
      setSelectedSatReferenceSizeBarcodes,
    ] = useState<SatReferenceSizeBarcode[]>([]);

    useImperativeHandle(ref, () => ({
      getItems: () => {
        return {
          satItems: selectedSatItems
            // Retorna apenas itens sem references
            .filter(item => !item.satItemReferences?.length)
            // Retorna apenas IDs
            .map(item => item.idSatItem),

          // Retorna apenas IDs
          satReferenceSizeBarcodes: selectedSatReferenceSizeBarcodes.map(
            item => item.idSatReferenceSizeBarcode,
          ),
        };
      },
    }));

    const { loading: satItemsLoading } = useQuery(
      listAvailableSatItemsToForeignTradeQuery,
      {
        variables: {
          idSat,
          idSatForeignTrade,
        },
        onCompleted: response => {
          setSatItems(response.listAvailableSatItemsToForeignTrade);
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary:
              'Error while getting SAT Items available for Foreign Trade',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      },
    );

    const remainQuantityColumn = (rowData: SatItem) => {
      if (
        rowData.satItemReferences?.length &&
        rowData.satItemReferences.some(
          itemReference => itemReference.satReferenceSizeBarcodes?.length,
        )
      ) {
        return undefined;
      }

      return rowData.remainQuantity;
    };

    const cbmAvailableColumn = (rowData: SatItem) => {
      const remainQuantity = remainQuantityColumn(rowData);

      const result =
        ((remainQuantity ?? 0) / (rowData.piecesContainer ?? 0)) *
        (rowData?.cbm ?? 0);

      return numberToPtString(result);
    };

    const rowExpansionTemplate = (rowData: SatItem) => {
      const satReferenceSizeBarcodes = rowData.satItemReferences?.reduce(
        (acc, val) => acc.concat(val.satReferenceSizeBarcodes ?? []),
        [] as SatReferenceSizeBarcode[],
      );

      return (
        <ReferenceSizes
          satReferenceSizeBarcodes={satReferenceSizeBarcodes}
          satItem={rowData}
          selectedSatReferenceSizeBarcodes={selectedSatReferenceSizeBarcodes}
          setSelectedSatReferenceSizeBarcodes={
            setSelectedSatReferenceSizeBarcodes
          }
        />
      );
    };

    const totalColumnsFooter = useMemo(() => {
      const columnsSumInitialValue = {
        totalRemainQuantity: 0,
        totalCbmAvailable: 0,
      };

      const valuesSum = satItems.reduce((acc, val) => {
        // Valida se existem references e algum barcode nos arrays
        if (
          val.satItemReferences?.length &&
          val.satItemReferences.some(
            itemReference => itemReference.satReferenceSizeBarcodes?.length,
          )
        ) {
          // Adiciona todos os arrays de reference size barcodes em um unico array
          // para facilitar o calculo
          const satReferenceSizeBarcodes: SatReferenceSizeBarcode[] =
            val.satItemReferences?.reduce(
              (refAcc, refVal) =>
                refAcc.concat(refVal.satReferenceSizeBarcodes ?? []),
              [] as SatReferenceSizeBarcode[],
            );

          const barcodesSum = satReferenceSizeBarcodes.reduce(
            (barcodeAcc, barcodeVal) => {
              const cbmAvailable: number =
                ((barcodeVal.remainQuantityPiShipment ?? 0) /
                  (val.piecesContainer ?? 0)) *
                (val.cbm ?? 0);

              return {
                totalRemainQuantity:
                  barcodeAcc.totalRemainQuantity +
                  (barcodeVal.remainQuantityPiShipment ?? 0),
                totalCbmAvailable:
                  barcodeAcc.totalCbmAvailable + Number(cbmAvailable ?? 0),
              };
            },
            columnsSumInitialValue,
          );

          return {
            totalRemainQuantity:
              acc.totalRemainQuantity + barcodesSum.totalRemainQuantity,
            totalCbmAvailable:
              acc.totalCbmAvailable + barcodesSum.totalCbmAvailable,
          };
        }

        const cbmAvailable: number =
          ((val.remainQuantity ?? 0) / (val.piecesContainer ?? 0)) *
          (val.cbm ?? 0);

        return {
          totalRemainQuantity:
            acc.totalRemainQuantity + (val.remainQuantity ?? 0),
          totalCbmAvailable: acc.totalCbmAvailable + Number(cbmAvailable ?? 0),
        };
      }, columnsSumInitialValue);

      return valuesSum;
    }, [satItems]);

    /**
     * Busca o Reference Size Barcodes de um SAT Item
     * @param item SAT Item
     * @returns array de reference Size Barcodes
     */
    function getItemBarcodes(item: SatItem) {
      const satReferenceSizeBarcodes = item.satItemReferences?.reduce(
        (acc, val) => acc.concat(val.satReferenceSizeBarcodes ?? []),
        [] as SatReferenceSizeBarcode[],
      );

      return satReferenceSizeBarcodes ?? [];
    }

    /**
     * Acao de selecionar todos os itens
     */
    function handleSelectAll() {
      const satReferenceSizeBarcodes = satItems.map(item =>
        getItemBarcodes(item),
      );

      setSelectedSatReferenceSizeBarcodes(satReferenceSizeBarcodes.flat());
    }

    /**
     * Acao de selecionar um item
     * @param item SAT Item
     */
    function handleSelectItem(item: SatItem) {
      // Se item possuir References, seleciona todos os barcodes na grid
      if (item.satItemReferences?.length) {
        const itemBarcodes = getItemBarcodes(item);

        // Remove possiveis itens duplicados na lista de barcodes selecionados
        const filteredItems = selectedSatReferenceSizeBarcodes.filter(
          selectedBarcode =>
            !itemBarcodes.some(
              itemBarcode =>
                itemBarcode.idSatReferenceSizeBarcode ===
                selectedBarcode.idSatReferenceSizeBarcode,
            ),
        );

        setSelectedSatReferenceSizeBarcodes([
          ...filteredItems,
          ...itemBarcodes,
        ]);
      }
    }

    /**
     * Acao de de-selecionar um item
     * @param item SAT Item
     */
    function handleUnselectItem(item: SatItem) {
      // Se item possuir References, seleciona todos os barcodes na grid
      if (item.satItemReferences?.length) {
        const itemBarcodes = getItemBarcodes(item);

        const filteredItems = selectedSatReferenceSizeBarcodes.filter(
          selectedBarcode =>
            !itemBarcodes.some(
              itemBarcode =>
                itemBarcode.idSatReferenceSizeBarcode ===
                selectedBarcode.idSatReferenceSizeBarcode,
            ),
        );

        setSelectedSatReferenceSizeBarcodes(filteredItems);
      }
    }

    return (
      <Container>
        <Grid
          className="s-expanded-datatable"
          loading={satItemsLoading}
          value={satItems}
          emptyMessage="No available items found"
          paginator={false}
          scrollable
          scrollHeight="50vh"
          expandedRows={satItems}
          rowExpansionTemplate={rowExpansionTemplate}
          selectionMode="checkbox"
          selection={selectedSatItems}
          onSelectionChange={e => setSelectedSatItems(e.value)}
          onRowSelect={e => handleSelectItem(e.data)}
          onRowUnselect={e => handleUnselectItem(e.data)}
          onAllRowsSelect={() => handleSelectAll()}
          onAllRowsUnselect={() => setSelectedSatReferenceSizeBarcodes([])}
        >
          <Column selectionMode="multiple" style={{ width: '3rem' }} />
          <Column
            field="idImage2.url"
            header="Image"
            body={e => parseImageColumn(e, { columnKey: 'idImage2.url' })}
            style={{ width: '8rem' }}
          />
          <Column
            field="stCode"
            header="ST Code"
            body={e => parseStCodeColumn(e, { columnKey: 'stCode' })}
            style={{ width: '12rem' }}
            footer="TOTAL SHIPMENT"
          />
          <Column field="name" header="Name (EN)" style={{ width: '12rem' }} />
          <Column
            field="piecesContainer"
            header="PCS/CTN"
            style={{ width: '12rem' }}
          />
          <Column
            field="remainQuantity"
            header="Remain Quantity"
            body={remainQuantityColumn}
            style={{ width: '8.5rem' }}
            footer={numberToPtString(totalColumnsFooter.totalRemainQuantity)}
          />
          <Column
            field="cbmAvailable"
            header="CBM Avaliable"
            body={cbmAvailableColumn}
            style={{ width: '9rem' }}
            footer={numberToPtString(totalColumnsFooter.totalCbmAvailable)}
          />
        </Grid>
      </Container>
    );
  },
);

export default ItemsGrid;
