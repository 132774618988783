/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import xlsx from 'xlsx';
import { Menu } from 'primereact/menu';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useAuth } from '../../../hooks/useAuth';
import { useRefHook } from '../../../hooks/useRefHook';
import useTitle from '../../../hooks/useTitle';
import ILazyParams from '../../../services/lazyParams';
import { oceanFreightRoles } from '../../../shared/roles/oceanFreight';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import userHasPermission from '../../../utils/userHasPermission';
import { gridColumnsData } from './constants';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import {
  createOceanFreightsBulkQuery,
  updateOceanFreightsBulkQuery,
} from './queries';
import FileUpload from '../../../components/FileUpload';
import { FileType } from '../../../shared/enums/fileType';
import { DataTableMultiSortMetaType } from '../../../components/Grid/interfaces';

/**
 * Interface dos parametros do backend
 */
interface IOceanFreightLazyParams extends ILazyParams {
  [key: string]:
    | number
    | string
    | boolean
    | DataTableMultiSortMetaType
    | undefined;
  portLoading?: string;
  portDestination?: string;
  valor20Gp?: number;
  valor40Hc?: number;
  curValor20Gp?: string;
  curValor40Hc?: string;
  bcValor?: string;
  bcCif?: boolean;
}

interface IBulkResponse {
  oceanFreightsTotal: number;
  oceanFreightsWithError: string[];
  createdOceanFreights?: number;
  updatedOceanFreights?: number;
  type: 'CREATE' | 'UPDATE';
}

const screenSubject = 'Ocean Freights';
const pageTitle = `List of ${screenSubject}`;

const OceanFreights: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:oceanFreightGridColumns';

  const lazyParamsName = '@SAT:oceanFreightLazyParams';

  const advancedFiltersName = '@SAT:oceanFreightAdvancedFilters';

  // Redirect
  const history = useHistory();

  const { showError, showSuccess } = useRefHook();

  const gridRef = useRef<GridRef<any>>(null);
  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);
  const massiveActionMenuRef = useRef<Menu>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de resposta da acao e massa
  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  // URL params
  const location = useLocation();

  // Permissions de Ocean Freight
  const {
    idPermissionDeleteOceanFreight,
    idPermissionCreateOceanFreight,
    idPermissionMassiveActionOceanFreight,
    idPermissionExportListOceanFreight,
  } = oceanFreightRoles.permissions;

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsOceanFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    oceanFreightRoles.idEntity,
  );

  const roleEntityPermissions = userPermissionsOceanFreight.userPermissions;

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;
  // Busca
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'ocean_freight_bulk_template_v1.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  // Colunas da grid
  const columns = useMemo(() => {
    return (
      Object.entries(gridColumnsData)
        // .filter(column => fieldsPermissions[column[1].field].view)
        .map(column => column[1])
    );
  }, []);

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
  ];

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IOceanFreightLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: IOceanFreightLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: IOceanFreightLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // OceanFreights selecionados
  const [selectedOceanFreights, setSelectedOceanFreights] = useState([]);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar Ocen Freights
  const listOceanFreightsQuery = gql`
    query listAllOceanFreightsQuery(
      $listAllOceanFreightsInput: ListAllOceanFreightsInput!
    ) {
      listAllOceanFreights(
        listAllOceanFreightsInput: $listAllOceanFreightsInput
      ) {
        data {
          idOceanFreight
          idPortLoading2 {
            name
          }
          idPortDestination2 {
            name
          }
          idCurValor20Gp2 {
            abbreviation
          }
          valor20Gp
          idCurValor40Hc2 {
            abbreviation
          }
          valor40Hc
          idBcValor2 {
            description
          }
          bcCif
          updatedAt
        }
        items
      }
    }
  `;

  // Query para deletar Ocean Freight
  const deleteOceanFreightQuery = gql`
    mutation DisableLogisticsOceanFreight($ids: [Int]!) {
      disableLogisticsOceanFreight(ids: $ids)
    }
  `;

  // cria método para chamar a mutation
  const [deleteOceanFreight] = useMutation(deleteOceanFreightQuery);

  /**
   * Busca Ocean Freight
   */
  const {
    loading: oceanFreightLoading,
    data: oceanFreightData,
    refetch: oceanFreightRefetch,
  } = useQuery(listOceanFreightsQuery, {
    variables: {
      listAllOceanFreightsInput: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        portDestination: lazyParams.portDestination,
        portLoading: lazyParams.portLoading,
        valor20Gp: lazyParams.valor20Gp,
        valor40Hc: lazyParams.valor40Hc,
        curValor20Gp: lazyParams.curValor20Gp,
        curValor40Hc: lazyParams.curValor40Hc,
        bcValor: lazyParams.bcValor,
        bcCif: lazyParams.bcCif,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Ocean Freight data',
        detail: errorData.message,
      });
    },
  });

  const parseDoubleColumns = (rowData: any, field: any) => {
    let value;
    if (field.field === 'valor20Gp' && rowData.valor20Gp) {
      value = rowData.valor20Gp?.toLocaleString('pt-BR');
    } else if (field.field === 'valor40Hc' && rowData.valor40Hc) {
      value = rowData.valor40Hc?.toLocaleString('pt-BR');
    }

    return <p>{value}</p>;
  };

  /**
   * Efetua parse de coluna de Data
   * @param rowData dados da linha
   * @param field dados do campo
   * @returns Coluna em formato de data
   */
  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (field.field === 'updatedAt' && rowData.updatedAt) {
      date = new Date(rowData.updatedAt).toLocaleString();
    }

    return <p>{date}</p>;
  };

  /**
   * Valida se coluna active é true ou false
   * @param rowData Dados da linha
   * @returns Campo com true ou false
   */
  const parseBooleanColumn = (rowData: any, field: any) => {
    if (field.field === 'bcCif') {
      return <p>{rowData.bcCif ? 'Yes' : 'No'}</p>;
    }
    return undefined;
  };

  function hasPermission(idPermission: number): boolean {
    return userHasPermission(idPermission, roleEntityPermissions);
  }
  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'valor20Gp':
      case 'valor40Hc':
        return parseDoubleColumns;
      case 'updatedAt':
        return parseDateColumm;
      case 'bcCif':
        return parseBooleanColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'idPortLoading2.name':
      case 'idPortDestination2.name':
        return { width: '240px' };
      case 'idBcValor2.description':
        return { width: '170px' };
      case 'idCurValor20Gp2.abbreviation':
      case 'idCurValor40Hc2.abbreviation':
        return { width: '110px' };
      default:
        return { width: '140px' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          body={handleColumn(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do icms clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/logistics/oceanFreight/${e.data.idOceanFreight}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta Ocean Freights selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedOceanFreights.length} Ocean Freights?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada Ocean Freights
        const idsToDelete = selectedOceanFreights.map(
          (a: any) => a.idOceanFreight,
        );
        try {
          // Deleta Ocean Freights
          await deleteOceanFreight({
            variables: {
              ids: idsToDelete,
            },
          });

          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedOceanFreights.length} Ocean Freights`,
          });

          // Zera estado de Ocean Freights selecionadas
          setSelectedOceanFreights([]);

          // Atualiza grid de Ocean Freights
          await oceanFreightRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting Ocean Freights',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Exporta dados para xlsx
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function exportToXlsx(oceanFreightExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    oceanFreightExportData.listAllOceanFreights.data.forEach(
      (oceanFreight: any) => {
        oceanFreight.portLoading = oceanFreight.idPortLoading2
          ? oceanFreight.idPortLoading2.name
          : undefined;
        oceanFreight.portDestination = oceanFreight.idPortDestination2
          ? oceanFreight.idPortDestination2.name
          : undefined;
        oceanFreight.idCurValor20Gp = oceanFreight.idCurValor20Gp2
          ? oceanFreight.idCurValor20Gp2.abbreviation
          : undefined;
        oceanFreight.idCurValor40Hc = oceanFreight.idCurValor40Hc2
          ? oceanFreight.idCurValor40Hc2.abbreviation
          : undefined;
        oceanFreight.idBcValor = oceanFreight.idBcValor2
          ? oceanFreight.idBcValor2.description
          : undefined;
        oceanFreight.bcCif = oceanFreight.bcCif ? 'Yes' : 'No';

        columnsToRemove.forEach(column => {
          switch (column.field) {
            case 'idPortLoading2.name':
              delete oceanFreight.portLoading;
              break;
            case 'idPortDestination2.name':
              delete oceanFreight.portDestination;
              break;
            case 'idCurValor20Gp2.abbreviation':
              delete oceanFreight.idCurValor20Gp;
              break;
            case 'idCurValor40Hc2.abbreviation':
              delete oceanFreight.idCurValor40Hc;
              break;
            case 'idBcValor2.description':
              delete oceanFreight.idBcValor;
              break;
            default:
              delete oceanFreight[column.field];
              break;
          }
        });

        // Remover colunas __typename e idIcms
        delete oceanFreight.__typename;
        delete oceanFreight.idOceanFreight;
        delete oceanFreight.idPortLoading2;
        delete oceanFreight.idPortDestination2;
        delete oceanFreight.idCurValor20Gp2;
        delete oceanFreight.idCurValor40Hc2;
        delete oceanFreight.idBcValor2;
      },
    );

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        item !== 'idOceanFreight' &&
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'idPortLoading2.name':
          columnsOrder[index] = 'portLoading';
          break;
        case 'idPortDestination2.name':
          columnsOrder[index] = 'portDestination';
          break;
        case 'idCurValor20Gp2.abbreviation':
          columnsOrder[index] = 'idCurValor20Gp';
          break;
        case 'idCurValor40Hc2.abbreviation':
          columnsOrder[index] = 'idCurValor40Hc';
          break;
        case 'idBcValor2.description':
          columnsOrder[index] = 'idBcValor';
          break;
        default:
          break;
      }
    });

    // Gera arquivo xlsx
    const worksheet = xlsx.utils.json_to_sheet(
      oceanFreightExportData.listAllOceanFreights.data,
      { header: columnsOrder },
    );
    worksheet['!autofilter'] = { ref: 'A1:I1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'oceanFreight');
  }

  const [createOceanFreightsBulkMutation] = useMutation(
    createOceanFreightsBulkQuery,
  );
  const [updateOceanFreightsBulkMutation] = useMutation(
    updateOceanFreightsBulkQuery,
  );

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);
      const response = await createOceanFreightsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createLogisticsOceanFreightsBulk,
        type: 'CREATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while creating ocean freights',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      // atualiza ocean freights em massa
      const response = await updateOceanFreightsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateLogisticsOceanFreightsBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while updating ocean freights',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  /**
   * Busca Ocean Freights para exportar para XLSX
   */
  const [
    loadOceanFreightsToExport,
    { loading: oceanFreightsToExportLoading, data: oceanFreightsToExportData },
  ] = useLazyQuery(listOceanFreightsQuery, {
    onCompleted: () => {
      exportToXlsx(oceanFreightsToExportData);
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting Ocean Freights',
        detail: errorData.message,
      });
    },
  });

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>Ocean Freights found in the spreadsheet:</b>{' '}
          {bulkResponse?.oceanFreightsTotal}
        </p>
        <p>
          <b>
            Ocean Freights{' '}
            {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.createdOceanFreights ||
            bulkResponse?.updatedOceanFreights}
        </p>
        {!!bulkResponse?.oceanFreightsWithError?.length && (
          <p>
            <b>Ocean Freights/Cells with error:</b>
            <br />
            {bulkResponse?.oceanFreightsWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader
        title="List of Ocean Freights"
        fixedStickyButtons={fixedStickyButtons}
      >
        {/* Botao para criar ICMS */}

        {userHasPermission(
          idPermissionCreateOceanFreight,
          userPermissionsOceanFreight.userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Ocean Freight"
            onClick={() => history.push('/logistics/oceanFreight/create')}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir Ocean Freights selecionados */}
        {userHasPermission(
          idPermissionDeleteOceanFreight,
          userPermissionsOceanFreight.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedOceanFreights.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {hasPermission(idPermissionMassiveActionOceanFreight) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}
        {hasPermission(idPermissionExportListOceanFreight) && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={oceanFreightsToExportLoading}
            onClick={() => {
              loadOceanFreightsToExport({
                variables: {
                  listAllOceanFreightsInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    globalSearch: lazyParams.globalFilter,
                    portLoading: lazyParams.portLoading,
                    portDestination: lazyParams.portDestination,
                    valor20Gp: lazyParams.valor20Gp,
                    valor40Hc: lazyParams.valor40Hc,
                    curValor20Gp: lazyParams.curValor20Gp,
                    curValor40Hc: lazyParams.curValor40Hc,
                    bcValor: lazyParams.bcValor,
                    bcCif: lazyParams.bcCif,
                  },
                },
              });
            }}
          />
        )}

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an Item"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="oceanFreight"
        lazy
        totalRecords={
          !oceanFreightData ? 0 : oceanFreightData.listAllOceanFreights.items
        }
        value={
          !oceanFreightData
            ? undefined
            : oceanFreightData.listAllOceanFreights.data
        }
        globalFilter={globalFilter}
        emptyMessage="No Ocean Freights found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedOceanFreights}
        onSelectionChange={e => setSelectedOceanFreights(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteOceanFreight,
          userPermissionsOceanFreight.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || oceanFreightLoading) && <Loading />}
    </div>
  );
};
export default OceanFreights;
