/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import xlsx from 'xlsx';
import { isValid } from 'date-fns';
import { Skeleton } from 'primereact/skeleton';
import { useRefHook } from '../../../hooks/useRefHook';
import getFieldPermission from '../../../utils/getFieldPermission';
import { gridColumnsData, groupedColumns } from './constants';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { IImportLazyParams, SatListImportPermissions } from './interfaces';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import Tag from '../../../components/Tag';
import { parseToLocaleStringDate } from '../../../utils/convertDate';
import { getUTC } from '../../../utils/dateUtil';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import userHasPermission from '../../../utils/userHasPermission';
import Grid, { GridRef } from '../../../components/Grid';
import Loading from '../../../components/Loading';
import {
  parseCurrencyPtBrNumberColumn,
  parseSatColumn,
} from '../../../utils/gridColumnsParse';
import saveFileAsExcel from '../../../utils/saveFileAsExcel';
import { importRoles } from '../../../shared/roles/importRoles';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { ICategory } from '../../../interfaces/ICategory';
import { findParentsOnTree } from '../../../utils/findParentsOnTree';
import {
  getSumDataFromSatImportListQuery,
  listAllSatsToImportControlsQuery,
} from './queries';
import useTitle from '../../../hooks/useTitle';
import { SatStatus } from '../../../components/SatStatusTag';
import { renderNumber } from '../../../utils/formatLocale';

const pageTitle = 'Import List';

const Import: React.FC = () => {
  useTitle(pageTitle);

  // Key das opcoes de coluna que resetam preferencias da grid
  const gridResetOptionKey = '0-1';

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:satControlsImportGridColumns';

  const lazyParamsName = '@SAT:satControlsImportLazyParams';

  const advancedFiltersName = '@SAT:satControlsImportAdvancedFilters';

  // Redirect
  const history = useHistory();

  // Referencia ao toast
  const { showError } = useRefHook();

  // Referencia a grid
  const gridRef = useRef<GridRef<any>>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // ID da entity de SAT
  const { idEntity } = importRoles;

  // Permissions de SAT
  const { idPermissionExportSatsImport } = importRoles.permissions;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Busca permissoes do usuario para a entity
  const { userFields } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const fieldsPermissions: SatListImportPermissions = useMemo(() => {
    const fieldPermissions = userFields;
    const { fields } = importRoles;

    const permissions = {} as SatListImportPermissions;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fields)) {
      permissions[key] = getFieldPermission(value, fieldPermissions);
    }

    return permissions;
  }, [userFields]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const listCategoriesQuery = gql`
    query listAllCategoriesQuery {
      listAllCategories {
        data
        items
      }
    }
  `;

  useQuery(listCategoriesQuery, {
    onError: errorData => {
      showError({
        summary: 'Error while getting categories',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        setCategories(JSON.parse(response.listAllCategories.data));
      }
    },
  });

  const columns = useMemo(() => {
    gridColumnsData.category.options = categories;

    return Object.entries(gridColumnsData)
      .filter(column => fieldsPermissions[column[1].field].view)
      .map(column => column[1]);
  }, [categories, fieldsPermissions]);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams: IImportLazyParams = {
    ...pagination.initialLazyParams,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IImportLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IImportLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IImportLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(
      (column: any) =>
        groupedColumns &&
        groupedColumns.length > 0 &&
        groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      '0-0': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectGroupData) => {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  };

  /**
   * Direciona usuario para pagina do porto clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/commercial/sats/${e.data.idSat}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  const onSort = (event: DataTableSortEvent) => {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField ?? initialLazyParams.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = useCallback(
    (event: DataTablePageEvent) => {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    },
    [changeLazyParams, lazyParams],
  );

  const lazyParamsCommonVariables = {
    allColumns: lazyParams.globalFilter,
    status: lazyParams.status,
    idSat: lazyParams.idSat,
    satNumber: lazyParams.satNumber,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    shipment: lazyParams.shipment,
    clientOrder: lazyParams.clientOrder,
    mainProduct: lazyParams.mainProduct,
    idSupplierExporter: lazyParams.idSupplierExporter,
    exporter: lazyParams.exporter,
    idImporter: lazyParams.idImporter,
    importerConsignee: lazyParams.importerConsignee,
    idClient: lazyParams.idClient,
    clientNotify: lazyParams.clientNotify,
    ctnrs: lazyParams.ctnrs,
    idImportStatus: lazyParams.idImportStatus,
    importStatus: lazyParams.importStatus,
    idRegisterStatus: lazyParams.idRegisterStatus,
    registerStatus: lazyParams.registerStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    artworkStatus: lazyParams.artworkStatus,
    productionStatus: lazyParams.productionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    idDocumentsStatus: lazyParams.idDocumentsStatus,
    documentsStatus: lazyParams.documentsStatus,
    supplierPaymentStatus: lazyParams.supplierPaymentStatus,
    clientPaymentStatus: lazyParams.clientPaymentStatus,
    idExportStatus: lazyParams.idExportStatus,
    exportStatus: lazyParams.exportStatus,
    shipmentResume: lazyParams.shipmentResume,
    startDate: lazyParams.startDate,
    productionTime: lazyParams.productionTime,
    samples: lazyParams.samples,
    estimatedInspectionDate: lazyParams.estimatedInspectionDate,
    realInspectionDate: lazyParams.realInspectionDate,
    inspectionScheduled: lazyParams.inspectionScheduled,
    inspectionApproval: lazyParams.inspectionApproval,
    rework: lazyParams.rework,
    reworkDate: lazyParams.reworkDate,
    authorizationRequested: lazyParams.authorizationRequested,
    shipmentAuthorized: lazyParams.shipmentAuthorized,
    importLicenseNumber: lazyParams.importLicenseNumber,
    importLicenseExpireDate: lazyParams.importLicenseExpireDate,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    realEtd: lazyParams.realEtd,
    idLoadingPort: lazyParams.idLoadingPort,
    portLoading: lazyParams.portLoading,
    idFreightForwarderOrigin: lazyParams.idFreightForwarderOrigin,
    freightForwarderOrigin: lazyParams.freightForwarderOrigin,
    estimatedArrivalDate: lazyParams.estimatedArrivalDate,
    realEta: lazyParams.realEta,
    idPort: lazyParams.idPort,
    portDischarge: lazyParams.portDischarge,
    idDreightForwarderDestination: lazyParams.idDreightForwarderDestination,
    freightForwarderDestination: lazyParams.freightForwarderDestination,
    trakingNumber: lazyParams.trakingNumber,
    idCourier: lazyParams.idCourier,
    courier: lazyParams.courier,
    estimatedArrivalClientDate: lazyParams.estimatedArrivalClientDate,
    realArrivalAtClient: lazyParams.realArrivalAtClient,
    diNumber: lazyParams.diNumber,
    paymentTermAdvance: lazyParams.paymentTermAdvance,
    paymentTermBalance: lazyParams.paymentTermBalance,
    idPaymentTermCondition: lazyParams.idPaymentTermCondition,
    paymentTermCondition: lazyParams.paymentTermCondition,
    idClientIncoterm: lazyParams.idClientIncoterm,
    paymentSellingIncoterm: lazyParams.paymentSellingIncoterm,
    sellerTotalSunPi: lazyParams.sellerTotalSunPi,
    sellerTotalSunPiUsd: lazyParams.sellerTotalSunPiUsd,
    sellerTotalSunCi: lazyParams.sellerTotalSunCi,
    sellerTotalSunCiUsd: lazyParams.sellerTotalSunCiUsd,
    freightCost: lazyParams.freightCost,
    freightExtraCost: lazyParams.freightExtraCost,
    apportionment: lazyParams.apportionment,
    totalWithApportionment: lazyParams.totalWithApportionment,
    rncCreditNote: lazyParams.rncCreditNote,
    creditToClient: lazyParams.creditToClient,
    totalAdvance: lazyParams.totalAdvance,
    maxAdvanceReceiveDate: lazyParams.maxAdvanceReceiveDate,
    maxAdvancePaymentDate: lazyParams.maxAdvancePaymentDate,
    totalBalance: lazyParams.totalBalance,
    maxBalanceReceiveDate: lazyParams.maxBalanceReceiveDate,
    maxBalancePaymentDate: lazyParams.maxBalancePaymentDate,
    totalRemainSun: lazyParams.totalRemainSun,
    idImportLevel: lazyParams.idImportLevel,
    importLevel: lazyParams.importLevel,
    idTypeOfImportService: lazyParams.idTypeOfImportService,
    typeOfImportService: lazyParams.typeOfImportService,
    idTypeOperation: lazyParams.idTypeOperation,
    typeOperation: lazyParams.typeOperation,
    replacementFrom: lazyParams.replacementFrom,
    consolidatedWith: lazyParams.consolidatedWith,
    satGroup: lazyParams.satGroup,
    idCategory: lazyParams.idCategory,
    category: findParentsOnTree(categories, lazyParams.category),
    idCountryOrigin: lazyParams.idCountryOrigin,
    countryOrigin: lazyParams.countryOrigin,
    clientDepartment: lazyParams.clientDepartment,
    idFirstBuyer: lazyParams.idFirstBuyer,
    firstBuyer: lazyParams.firstBuyer,
    idSecondBuyer: lazyParams.idSecondBuyer,
    secondBuyer: lazyParams.secondBuyer,
    idSalesUser: lazyParams.idSalesUser,
    firstSalesUser: lazyParams.firstSalesUser,
    idSalesSecondUser: lazyParams.idSalesSecondUser,
    secondSalesUser: lazyParams.secondSalesUser,
    idComexExportUser: lazyParams.idComexExportUser,
    exportUser: lazyParams.exportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    importUser: lazyParams.importUser,
    startProductionTiming: lazyParams.startProductionTiming,
    realProductionTime: lazyParams.realProductionTime,
    authorizationTiming: lazyParams.authorizationTiming,
    authorizationBookingTiming: lazyParams.authorizationBookingTiming,
    authorizationShipmentTiming: lazyParams.authorizationShipmentTiming,
    bookingShipmentTiming: lazyParams.bookingShipmentTiming,
    preShipmentTiming: lazyParams.preShipmentTiming,
    transitTime: lazyParams.transitTime,
    hblFinalTiming: lazyParams.hblFinalTiming,
    originalDocsTiming: lazyParams.originalDocsTiming,
    deliveryAtClientTiming: lazyParams.deliveryAtClientTiming,
    totalForeignTradeTiming: lazyParams.totalForeignTradeTiming,
    importNotes: lazyParams.importNotes,
    remarks: lazyParams.remarks,
    updatesEn: lazyParams.updatesEn,
    followUpPt: lazyParams.followUpPt,
  };

  /**
   * Busca SATs
   */
  const { loading: satsLoading, data: satsData } = useQuery(
    listAllSatsToImportControlsQuery(fieldsPermissions),
    {
      variables: {
        listAllSatsToImportControlsInput: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          fieldsSearch: lazyParamsCommonVariables,
        },
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting SATs',
          detail: errorData.message,
        });
      },
    },
  );

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatImportListQuery,
    {
      variables: {
        data: lazyParamsCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Import List totals',
          detail: errorData.message,
        });
      },
    },
  );

  /**
   * Exporta dados para xlsx
   */
  async function exportToXlsx(satsExportData: any) {
    // Busca colunas que foram ocultas da grid
    const columnsToRemove = columns.filter(
      col =>
        !selectedColumns.columns.some(
          (sCol: { field: string }) => sCol.field === col.field,
        ),
    );

    // Remove propriedades de acordo com as colunas ocultas
    satsExportData.listAllSatsToImportControls.data.forEach((sat: any) => {
      columnsToRemove.forEach(column => {
        delete sat[column.field];
      });

      // Remover colunas
      delete sat.status;
      delete sat.idImportStatus;
      delete sat.idStatusArtwork;
      delete sat.idExportStatus;
      delete sat.idSatForeignTrade;
      delete sat.idSat;
      delete sat.idClient;
      delete sat.idImporter;
      delete sat.idSupplierExporter;
      delete sat.idLoadingPort;
      delete sat.idFreightForwarderOrigin;
      delete sat.idPort;
      delete sat.idDreightForwarderDestination;
      delete sat.idCourier;
      delete sat.idPaymentTermCondition;
      delete sat.idClientIncoterm;
      delete sat.idImportLevel;
      delete sat.idTypeOperation;
      delete sat.idTypeOfImportService;
      delete sat.idCategory;
      delete sat.idCountryOrigin;
      delete sat.idFirstBuyer;
      delete sat.idSecondBuyer;
      delete sat.idSalesUser;
      delete sat.idSalesSecondUser;
      delete sat.idComexExportUser;
      delete sat.idComexImportUser;
      delete sat.idCurrency;
      delete sat.satCurrency;

      const colunsNotRemoved = columns.filter(
        col => !columnsToRemove.includes(col),
      );

      // Formata campos de data e status
      colunsNotRemoved.forEach(column => {
        if (
          sat[column.field] &&
          isValid(new Date(sat[column.field])) &&
          typeof sat[column.field] === 'string'
        ) {
          const date = getUTC(sat[column.field]).toLocaleString().split(',')[0];
          sat[column.field] = date;
        }
      });
    });

    const gridColumns = gridRef.current?.columnOrder?.length
      ? gridRef.current.columnOrder
      : selectedColumns.columns.map(column => column.field);

    const columnsOrder = gridColumns.filter(
      (item: string) =>
        !columnsToRemove.some(a => a.field === item) &&
        columns.some(column => column.field === item),
    );

    columnsOrder?.forEach((column: string, index: number) => {
      switch (column) {
        case 'status':
          columnsOrder[index] = 'statusSat';
          break;
        default:
          break;
      }
    });

    const worksheet = xlsx.utils.json_to_sheet(
      satsExportData.listAllSatsToImportControls.data,
      {
        header: columnsOrder,
      },
    );

    worksheet['!autofilter'] = { ref: 'A1:H1' };
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // Chama funcao para salva arquivo
    saveFileAsExcel(excelBuffer, 'sats');
  }

  /**
   * Busca sats para exportar para XLSX
   */
  const [
    loadSatsToExport,
    { loading: satsExportLoading, data: satsToExportData },
  ] = useLazyQuery(listAllSatsToImportControlsQuery(fieldsPermissions), {
    onCompleted: () => {
      if (satsToExportData.listAllSatsToImportControls) {
        exportToXlsx(satsToExportData);
      } else {
        showError({
          summary: 'Error while exporting sats',
          detail: 'Please try again later',
        });
      }
    },
    onError: errorData => {
      showError({
        summary: 'Error while exporting sats',
        detail: errorData.message,
      });
    },
  });

  const parseSatStatusColumn = (rowData: any) => {
    switch (rowData.status) {
      case SatStatus.ACTIVE:
        return <Tag className="tag" value="Active" severity="success" />;
      case SatStatus.CANCELED:
        return <Tag className="tag" value="Canceled" severity="danger" />;
      case SatStatus.STAND_BY:
        return <Tag className="tag" value="Stand by" severity="warning" />;
      case SatStatus.FINISHED:
        return <Tag className="tag" value="Finished" severity="secondary" />;
      default:
        return undefined;
    }
  };

  const parseSatShipmentColumn = (rowData: any) => {
    return (
      rowData.idSatForeignTrade && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseExporterColumn = (rowData: any) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseImporterColumn = (rowData: any) => {
    return (
      rowData.idImporter && (
        <Link
          to={`/clients/list/${rowData.idImporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.importerConsignee}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseClientNameColumn = (rowData: any) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseDateColumns = (rowData: any, field: any) => {
    return rowData[field.field]
      ? parseToLocaleStringDate(getUTC(rowData[field.field]))
      : '';
  };

  const parseUpdatesColumn = (rowData: any) => {
    return (
      rowData.idSat && (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=updates`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
          style={{ justifyContent: 'space-between' }}
        >
          <span>
            <FiExternalLink size={15} />
          </span>
        </Link>
      )
    );
  };

  const parseCurrencyColumn = (rowData: any, field: any) => {
    return parseCurrencyPtBrNumberColumn(
      rowData,
      field,
      2,
      rowData.satCurrency,
    );
  };

  const parseCurrencyColumnsPtBr = (rowData: any, field: any) => {
    return rowData[field.field]
      ? parseCurrencyPtBrNumberColumn(rowData, field, 2)
      : '';
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'status':
        return parseSatStatusColumn;
      case 'satNumber':
        return parseSatColumn;
      case 'shipment':
        return parseSatShipmentColumn;
      case 'exporter':
        return parseExporterColumn;
      case 'importerConsignee':
        return parseImporterColumn;
      case 'clientNotify':
        return parseClientNameColumn;
      case 'startDate':
      case 'estimatedInspectionDate':
      case 'realInspectionDate':
      case 'authorizationRequested':
      case 'shipmentAuthorized':
      case 'estimatedShipmentDate':
      case 'realEtd':
      case 'estimatedArrivalDate':
      case 'realEta':
      case 'estimatedArrivalClientDate':
      case 'realArrivalAtClient':
      case 'importLicenseExpireDate':
      case 'maxAdvanceReceiveDate':
      case 'maxAdvancePaymentDate':
      case 'maxBalanceReceiveDate':
      case 'maxBalancePaymentDate':
        return parseDateColumns;
      case 'paymentTermAdvance':
      case 'paymentTermBalance':
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'freightCost':
      case 'freightExtraCost':
      case 'apportionment':
      case 'totalWithApportionment':
      case 'creditToClient':
      case 'totalAdvance':
      case 'totalBalance':
      case 'totalRemainSun':
        return parseCurrencyColumn;
      case 'sellerTotalSunPiUsd':
      case 'sellerTotalSunCiUsd':
        return parseCurrencyColumnsPtBr;
      case 'updatesEn':
      case 'followUpPt':
        return parseUpdatesColumn;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'status':
        return { width: '6.79rem' };
      case 'satNumber':
      case 'realEtd':
      case 'realEta':
      case 'courier':
      case 'transitTime':
        return { width: '10rem' };
      case 'productionStatus':
      case 'trakingNumber':
      case 'portLoading':
      case 'portDischarge':
      case 'typeOperation':
        return { width: '13.57rem' };
      case 'totalAdvance':
        return { width: '210px' };
      case 'totalBalance':
      case 'firstSalesUser':
      case 'secondSalesUser':
      case 'exportUser':
      case 'importUser':
        return { width: '14.29rem' };
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'freightExtraCost':
      case 'totalRemainSun':
      case 'replacementFrom':
      case 'consolidatedWith':
      case 'countryOrigin':
      case 'clientDepartment':
      case 'hblFinalTiming':
        return { width: '12.86rem' };
      case 'inspectionScheduled':
      case 'inspectionApproval':
      case 'shipmentAuthorized':
      case 'realArrivalAtClient':
      case 'preShipmentTiming':
      case 'originalDocsTiming':
        return { width: '13.57rem' };
      case 'importLicenseNumber':
      case 'estimatedArrivalDate':
      case 'paymentTermAdvance':
      case 'paymentTermBalance':
      case 'paymentTermCondition':
      case 'paymentTermIncoterm':
      case 'sellerTotalSunPiUsd':
      case 'sellerTotalSunCiUsd':
      case 'typeOfImportService':
      case 'category':
        return { width: '13.21rem' };
      case 'startProductionTiming':
      case 'realProductionTime':
      case 'authorizationTiming':
      case 'deliveryAtClientTiming':
        return { width: '15rem' };
      case 'freightForwarderOrigin':
      case 'authorizationRequested':
      case 'estimatedInspectionDate':
      case 'realInspectionDate':
      case 'estimatedShipmentDate':
      case 'paymentSellingIncoterm':
      case 'totalWithApportionment':
      case 'bookingShipmentTiming':
      case 'totalForeignTradeTiming':
        return { width: '16.07rem' };
      case 'importLicenseExpireDate':
      case 'maxAdvanceReceiveDate':
      case 'maxAdvancePaymentDate':
      case 'maxBalanceReceiveDate':
      case 'maxBalancePaymentDate':
        return { width: '16.79rem' };
      case 'exporter':
        return { width: '10.71rem' };
      case 'importerConsignee':
      case 'clientNotify':
      case 'packagesNotes':
      case 'satGroup':
      case 'estimatedArrivalClientDate':
      case 'authorizationBookingTiming':
      case 'authorizationShipmentTiming':
        return { width: '18.21rem' };
      case 'rncCreditNote':
        return { width: '35.71rem' };
      default:
        return { width: '11.43rem' };
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;
    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // SATs possuirem a mesma currency
    const currencyAbbreviation =
      satsData?.listAllSatsToImportControls?.data[0]?.satCurrency;

    const value = listSumData?.getSumDataFromSatImportList[field];

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatImportList.satCount,
          'int',
        );
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'freightCost':
      case 'freightExtraCost':
      case 'apportionment':
      case 'totalWithApportionment':
      case 'totalAdvance':
      case 'totalBalance':
      case 'totalRemainSun':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'sellerTotalSunPiUsd':
      case 'sellerTotalSunCiUsd':
        return renderNumber(value, 'float', 'USD');
      default:
        return undefined;
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      // Validacao de objeto de colunas no formato 'legado', sem grupos de colunas
      const columnsObject = JSON.parse(localStorageSelectedColumns);
      if (columnsObject.treeSelectedData === undefined) {
        const newGroupedColumnsObject = {
          columns: columnsObject,
          treeSelectedData: {
            showFields: {
              checked: false,
              partialChecked: true,
            },
          },
        };

        setSelectedColumns(newGroupedColumnsObject);
        localStorage.setItem(
          gridColumnsName,
          JSON.stringify(newGroupedColumnsObject),
        );
        updateLocalStorageInDb(gridColumnsName, newGroupedColumnsObject);
      } else {
        setSelectedColumns(columnsObject);
      }
    }
  }, [columns]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title="Import List" fixedStickyButtons={fixedStickyButtons}>
        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Export list of SATs */}
        {userHasPermission(
          idPermissionExportSatsImport,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={satsExportLoading}
            onClick={() =>
              loadSatsToExport({
                variables: {
                  listAllSatsToImportControlsInput: {
                    pagination: {
                      _page: 0,
                      _limit: 0,
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    fieldsSearch: lazyParamsCommonVariables,
                  },
                },
              })
            }
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        className="p-datatable-hoverable-rows"
        name="controlsImport"
        lazy
        totalRecords={
          !satsData || !satsData.listAllSatsToImportControls
            ? 0
            : satsData.listAllSatsToImportControls.items
        }
        value={
          !satsData || !satsData.listAllSatsToImportControls
            ? undefined
            : satsData.listAllSatsToImportControls.data
        }
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onRowClick={onRowClick}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        {dynamicColumns}
      </Grid>
      {satsLoading && <Loading />}
    </div>
  );
};

export default Import;
