/**
 * Valida se objeto eh vazio, nulo ou undefined
 * @param obj Objeto a ser validado
 * @returns True
 */
function isEmptyNullOrUndefined<T>(
  obj: T | null | undefined | string,
): obj is null | undefined {
  return typeof obj === 'undefined' || obj === null || obj === '';
}

export default isEmptyNullOrUndefined;
