import { useLazyQuery, useMutation } from '@apollo/client';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoMdAttach } from 'react-icons/io';
import { FiExternalLink } from 'react-icons/fi';
import { isEqual } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import {
  IClientQuotation,
  IClientQuotationAttachment,
  IClientQuotationUpdate,
} from '../interfaces';

import AdvancedFiltersPanel from '../../../../components/AdvancedFiltersPanel';
import {
  AdvancedFiltersFieldType,
  IAdvancedFiltersField,
} from '../../../../components/AdvancedFiltersPanel/interfaces';
import Button from '../../../../components/Button';
import FormTitle from '../../../../components/FormTitle';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityPermission } from '../../../../interfaces/IRoleEntityPermission';
import { AttachmentTypes } from '../../../../shared/enums/attachmentType';
import { FileType } from '../../../../shared/enums/fileType';
import { IAttachment } from '../../../../shared/interfaces/attachment';
import { ISatCommentsLazyParams } from '../../../Commercial/Sat/Updates/interfaces';
import {
  createCqAttachmentQuery,
  createCqUpdateQuery,
  deleteCqAttachmentsQuery,
  deleteCqUpdateQuery,
  listCqUpdatesByIdCqQuery,
  updateCqUpdateQuery,
} from './queries';
import { Attachment, Attachments, Comments, Container } from './styles';
import { imageExtensions } from '../../../../shared/constants/imageExtensions';
import Image from '../../../../components/PageHeader/Image';
import CommentAdding, {
  ICommentAddingRef,
} from '../../../../components/CommentAdding';
import { useAuth } from '../../../../hooks/useAuth';
import { IComment } from '../../../../shared/interfaces/comment';
import Comment from '../../../../components/Comment';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';
import userHasPermission from '../../../../utils/userHasPermission';
import Loading from '../../../../components/Loading';
import { listCqAttachmentsByIdCqQuery } from '../queries';
import { parseCommentMessage } from '../../../../utils/parseCommentMessage';
import FileUpload from '../../../../components/FileUpload';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';

interface IClientQuotationUpdatesContentProps extends PageTabContainerProps {
  clientQuotation: IClientQuotation;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  pageLoading: boolean;
  userPermissions: IRoleEntityPermission[];
}

const UpdatesContent: React.FC<IClientQuotationUpdatesContentProps> = ({
  selected,
  clientQuotation,
  setPageLoading,
  pageLoading,
  userPermissions,
}) => {
  const { user } = useAuth();

  const { showError, showSuccess } = useRefHook();

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const commentAddingRef = useRef<ICommentAddingRef>(null);

  const [updates, setUpdates] =
    useState<{ data: IClientQuotationUpdate[]; items: number }>();

  const [selectedUpdate, setSelectedUpdate] =
    useState<IClientQuotationUpdate>();

  const [cqAttachments, setCqAttachments] =
    useState<IClientQuotationAttachment[]>();

  const { idPermissionUpdateRestrictInfo } = clientQuotationRoles.permissions;

  const userCanSeeRestrictInfo = useMemo(() => {
    return userHasPermission(idPermissionUpdateRestrictInfo, userPermissions);
  }, [idPermissionUpdateRestrictInfo, userPermissions]);

  const initialLazyParams = useMemo(() => {
    return {
      ...pagination.initialLazyParams,
      fetchAutomaticComments: true,
    };
  }, []);

  const [lazyParams, setLazyParams] =
    useState<ISatCommentsLazyParams>(initialLazyParams);

  const [globalFilter, setGlobalFilter] = useState('');

  const cleanSelected = useCallback(() => {
    setSelectedUpdate({} as IClientQuotationUpdate);
  }, []);

  const [createCqUpdateMutation] = useMutation(createCqUpdateQuery);
  const [updateCqUpdateMutation] = useMutation(updateCqUpdateQuery);
  const [deleteCqUpdateMutation] = useMutation(deleteCqUpdateQuery);

  const [createCqAttachmentMutation] = useMutation(createCqAttachmentQuery);
  const [deleteCqAttachmentsMutation] = useMutation(deleteCqAttachmentsQuery);

  const toggleAutomaticComments = () => {
    setLazyParams({
      ...initialLazyParams,
      fetchAutomaticComments: !lazyParams.fetchAutomaticComments,
    });
  };

  const advancedFiltersFields: IAdvancedFiltersField[] = useMemo(() => {
    const items = [];

    items.push(
      { advancedFilterField: 'createdBy', header: 'Created By', value: '' },
      {
        advancedFilterField: 'createdCommentStartDateRange',
        header: 'Created update start date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'createdCommentEndDateRange',
        header: 'Created update end date range',
        type: AdvancedFiltersFieldType.DATE,
      },
      {
        advancedFilterField: 'isGeneratedSystem',
        header: 'System generated updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      },
    );

    if (userCanSeeRestrictInfo) {
      items.push({
        advancedFilterField: 'isRestrictInfo',
        header: 'Restrict info updates',
        type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
      });
    }

    return items;
  }, [userCanSeeRestrictInfo]);

  const [loadAttachments] = useLazyQuery(listCqAttachmentsByIdCqQuery, {
    variables: {
      data: {
        idCq: clientQuotation.idCq,
        idTypes: [AttachmentTypes.UPDATE_FILE_CQ],
      },
    },
    onCompleted: response => {
      if (response.listAllCqAttachments?.data) {
        setCqAttachments(response.listAllCqAttachments.data);
      }
    },
    onError: error => {
      showError({
        summary: 'Error while getting CQ updates',
        detail: error.message,
      });
    },
  });

  async function onAttachmentUpload(data: FileUploadResponse[]) {
    setPageLoading(true);
    try {
      const response = await createCqAttachmentMutation({
        variables: {
          data: {
            idCq: clientQuotation.idCq,
            uploadedFile: data[0].serverName,
            idType: AttachmentTypes.UPDATE_FILE_CQ,
          },
        },
      });

      showSuccess({
        summary: 'Update published',
      });
      loadAttachments();
      return response.data?.createClientQuotationAttachments[0]?.idAttachment2;
    } catch (error) {
      showError({
        summary: 'Error while creating attachment',
        detail: error.message,
      });
      return undefined;
    } finally {
      setPageLoading(false);
    }
  }

  const [loadUpdatesData, { loading: updatesDataLoading }] = useLazyQuery(
    listCqUpdatesByIdCqQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idCq: clientQuotation.idCq,
          globalSearch: lazyParams.globalFilter,
          createdBy: lazyParams.createdBy,
          createdCommentStartDateRange: lazyParams.createdCommentStartDateRange,
          createdCommentEndDateRange: lazyParams.createdCommentEndDateRange,
          isGeneratedSystem: lazyParams.isGeneratedSystem,
          isRestrictInfo: lazyParams.isRestrictInfo,
          fetchAutomaticComments: lazyParams.fetchAutomaticComments,
        },
      },
      onCompleted: response => {
        if (response.listClientQuotationUpdatesByIdClientQuotation) {
          if (lazyParams.page && updates) {
            setUpdates({
              items:
                response.listClientQuotationUpdatesByIdClientQuotation.items,
              data: [
                ...updates.data,
                ...response.listClientQuotationUpdatesByIdClientQuotation.data,
              ],
            });
          } else {
            const list = response.listClientQuotationUpdatesByIdClientQuotation;
            setUpdates({
              data: list,
              items: list.length,
            });
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting CQ updates',
          detail: errorData.message,
        });
      },
    },
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLazyParams(currentLazyParams => {
        return {
          ...currentLazyParams,
          globalFilter,
          first: pagination.initialLazyParams.first,
          page: pagination.initialLazyParams.page,
          rows: pagination.initialLazyParams.rows,
        };
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [globalFilter]);

  const handleDeleteAttachment = useCallback(
    async (cqAttachment: IClientQuotationAttachment | undefined) => {
      setPageLoading(true);
      try {
        await deleteCqAttachmentsMutation({
          variables: {
            idsClientQuotationAttachments: [cqAttachment?.idCqAttachment],
          },
        });

        showSuccess({
          summary: 'Update deleted',
        });
        loadAttachments();
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting sat attachment',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      deleteCqAttachmentsMutation,
      loadAttachments,
      loadUpdatesData,
      setPageLoading,
      showError,
      showSuccess,
    ],
  );

  const handleRefreshUpdates = useCallback(() => {
    if (!isEqual(lazyParams, initialLazyParams)) {
      setLazyParams(initialLazyParams);
    } else {
      loadUpdatesData();
    }
  }, [initialLazyParams, lazyParams, loadUpdatesData]);

  const handleSubmitUpdate = useCallback(
    async (e: IComment) => {
      setPageLoading(true);
      try {
        if (!selectedUpdate?.idCqUpdate) {
          await createCqUpdateMutation({
            variables: {
              data: {
                idCq: clientQuotation.idCq,
                comment: {
                  message: e.message,
                  isRestrictInfo: e.isRestrictInfo,
                  idAttachment: e.idAttachment,
                },
              },
            },
          });
        } else {
          // Editar comentario
          await updateCqUpdateMutation({
            variables: {
              data: {
                idCqUpdate: selectedUpdate.idCqUpdate,
                comment: {
                  message: e.message,
                  isRestrictInfo: e.isRestrictInfo,
                  idAttachment: e.idAttachment,
                },
              },
            },
          });
        }

        handleRefreshUpdates();

        showSuccess({
          summary: 'Update published',
        });

        setSelectedUpdate({} as IClientQuotationUpdate);
      } catch (err) {
        showError({
          summary: `Error while ${
            selectedUpdate?.idCqUpdate ? 'updating' : 'creating'
          } update`,
          detail: err.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      clientQuotation.idCq,
      createCqUpdateMutation,
      handleRefreshUpdates,
      selectedUpdate?.idCqUpdate,
      setPageLoading,
      showError,
      showSuccess,
      updateCqUpdateMutation,
    ],
  );

  const handleDeleteUpdate = useCallback(
    async (idCqUpdate: number) => {
      setPageLoading(true);
      try {
        await deleteCqUpdateMutation({
          variables: {
            idCqUpdate,
          },
        });

        showSuccess({
          summary: 'Comment deleted',
        });

        // Atualiza lista de updates
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting comment',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
        setSelectedUpdate({} as IClientQuotationUpdate);
      }
    },
    [
      deleteCqUpdateMutation,
      loadUpdatesData,
      setPageLoading,
      showError,
      showSuccess,
    ],
  );

  const handleAddAttachmentComment = (attachment: IAttachment | undefined) => {
    if (attachment) {
      commentAddingRef.current?.addCommentFromAttachment(attachment);
    }
  };

  const handleEditUpdate = useCallback((update: IClientQuotationUpdate) => {
    setSelectedUpdate(update);
    commentAddingRef.current?.editComment(update.idComment2);
  }, []);

  const handleReplyUpdate = useCallback((update: IClientQuotationUpdate) => {
    if (update.idComment2?.createdBy2) {
      commentAddingRef.current?.replyComment({
        userToReply: update.idComment2?.createdBy2,
        isRestrictComment: !!update.idComment2?.isRestrictInfo,
      });
    }
  }, []);

  const handleReplyAllUpdate = useCallback((update: IClientQuotationUpdate) => {
    if (update.idComment2?.createdBy2) {
      commentAddingRef.current?.replyAll({
        userToReply: update.idComment2?.createdBy2,
        isRestrictComment: !!update.idComment2?.isRestrictInfo,
        message: update.idComment2?.message,
      });
    }
  }, []);

  const currentUserCreatedComment = useCallback(
    (createdBy: number) => {
      return createdBy === user.idUser;
    },
    [user.idUser],
  );

  useEffect(() => {
    if (selected && !updates) {
      loadUpdatesData();
      loadAttachments();
    }
  }, [loadAttachments, loadUpdatesData, selected, updates]);

  const chooseOptions = {
    icon: 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <AdvancedFiltersPanel
          innerRef={advancedFiltersPanelRef}
          fields={advancedFiltersFields}
          onApply={e =>
            setLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            setLazyParams({ ...pagination.initialLazyParams, globalFilter })
          }
        />
        <div className="content-header">
          <FormTitle name="Updates (EN)" />

          <span className="content-header-options">
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => onAttachmentUpload(e)}
            />
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search" />
              <InputText
                type="search"
                value={globalFilter}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search an update"
              />
            </IconField>
            <Button
              label="Filters"
              onClick={e =>
                advancedFiltersPanelRef.current?.toggle(e, e.target)
              }
            />
          </span>
        </div>
        <div className="flex">
          <Comments className="col-6">
            <div className="my-4">
              <div className="flex justify-content-between my-2">
                <div className="subtitle">
                  <i className="pi pi-comments" />
                  <span>Comments</span>
                </div>
                <button
                  className="button-hide"
                  onClick={() => toggleAutomaticComments()}
                  type="button"
                >
                  {lazyParams.fetchAutomaticComments ? 'Hide' : 'Show'}{' '}
                  automatic comments
                </button>
              </div>
              <CommentAdding
                ref={commentAddingRef}
                onSaveComment={handleSubmitUpdate}
                cleanSelected={cleanSelected}
                showRestrictInfo={userCanSeeRestrictInfo}
                onUploadAttachment={onAttachmentUpload}
              />
            </div>
            {((updatesDataLoading && !!lazyParams.page) ||
              !updatesDataLoading) &&
              updates &&
              updates.data?.map(update => {
                return (
                  <Comment
                    idComment={update.idComment}
                    userName={`${update.idComment2.createdBy2?.firstName} ${update.idComment2.createdBy2?.lastName}`}
                    creation={update.idComment2.createdAt ?? new Date()}
                    editedData={
                      update.idComment2.updatedBy2
                        ? {
                            editedAt: update.idComment2.updatedAt,
                            editedBy: `${update.idComment2.updatedBy2?.firstName} ${update.idComment2.updatedBy2?.lastName}`,
                          }
                        : undefined
                    }
                    commonTag={{
                      className: 'mr-2',
                      rounded: true,
                      isGeneratedSystem: update.idComment2.isGeneratedSystem,
                      isRestrictInfo: update.idComment2.isRestrictInfo,
                      value: undefined,
                      severity: undefined,
                    }}
                    onEdit={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleEditUpdate(update)
                        : undefined
                    }
                    onReply={
                      !currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleReplyUpdate(update)
                        : undefined
                    }
                    onReplyAll={
                      !currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleReplyAllUpdate(update)
                        : undefined
                    }
                    onDelete={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () =>
                            confirmDialog({
                              message:
                                'Are you sure you want to delete this update?',
                              header: 'Delete Confirmation',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-danger',
                              accept: () =>
                                handleDeleteUpdate(update.idCqUpdate),
                            })
                        : undefined
                    }
                    userImageUrl={update.idComment2.createdBy2?.avatarUrl}
                    key={update.idCqUpdate}
                    attachmentUrl={update.idComment2.idAttachment2?.url}
                    idCreatedBy={update.idComment2.createdBy}
                  >
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: parseCommentMessage(update.idComment2),
                      }}
                      className="comment"
                    />
                  </Comment>
                );
              })}
          </Comments>
          {cqAttachments && cqAttachments.length > 0 ? (
            <Attachments className="col-6">
              <div className="col-12 subtitle">
                <IoMdAttach className="attachment-icon" size={25} />
                <span>Attachment</span>
              </div>
              {cqAttachments?.map(cqAttachment => {
                const attachment = cqAttachment?.idAttachment2;
                const fileExtension = attachment?.name?.split('.').pop();
                return (
                  <Attachment key={cqAttachment?.idCqAttachment}>
                    <div className="col-3">
                      {fileExtension &&
                      imageExtensions.includes(fileExtension) ? (
                        <Image
                          src={attachment?.url ?? ''}
                          alt={`Comment image ${attachment?.nameGivenByUser}`}
                        />
                      ) : (
                        <div className="flex justify-content-center">
                          <div className="file-extension">{fileExtension}</div>
                        </div>
                      )}
                    </div>
                    <div className="col-9 image-info">
                      <div className="col-12 flex align-items-center">
                        <span className="image-name">
                          {attachment?.nameGivenByUser}
                        </span>
                        <a
                          href={attachment?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="attachment"
                        >
                          <FiExternalLink className="mx-2" />
                        </a>
                      </div>
                      <div className="col-12">
                        {`Added: ${
                          attachment?.createdAt
                            ? new Date(attachment.createdAt).toLocaleString()
                            : ''
                        } - `}
                        <button
                          onClick={() =>
                            confirmDialog({
                              message: 'Are you sure you want to delete?',
                              header: 'Delete Confirmation',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-danger',
                              accept: () =>
                                handleDeleteAttachment(cqAttachment),
                            })
                          }
                          type="button"
                        >
                          Delete
                        </button>
                        {' - '}
                        <button
                          onClick={() =>
                            handleAddAttachmentComment(
                              cqAttachment?.idAttachment2,
                            )
                          }
                          type="button"
                        >
                          Add Comment
                        </button>
                      </div>
                    </div>
                  </Attachment>
                );
              })}
            </Attachments>
          ) : (
            <div className="w-full p-5 flex justify-content-center">
              <span>No attachments found</span>
            </div>
          )}
        </div>
      </Container>
      {(pageLoading || updatesDataLoading) && <Loading />}
    </PageTabContainer>
  );
};

export default UpdatesContent;
