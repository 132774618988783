import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import isEmptyOrNull from '../../../utils/isEmptyOrNull';

// import { Container } from './styles';

interface ILogCompareProps {
  dataBefore?: [string, unknown][];
  dataAfter?: [string, unknown][];
}

type CompareObject = {
  field: string;
  before?: unknown;
  after?: unknown;
};

const LogCompare: React.FC<ILogCompareProps> = ({ dataBefore, dataAfter }) => {
  const [compareObjectArray, setCompareObjectArray] = useState<CompareObject[]>(
    [],
  );

  function renderGridDataValue(value: any) {
    if (isEmptyOrNull(value)) return 'empty';

    return value;
  }

  useEffect(() => {
    function getCompareObject() {
      const comparePartialArray: CompareObject[] = [];

      if (dataBefore) {
        dataBefore.forEach(([field, value], index) => {
          // Como os itens de after e before devem estar na mesma posicao,
          // busca o valor de after na mesma posicao em que se encontra o dado
          // no array de before
          const after = dataAfter?.[index][1];

          comparePartialArray.push({
            field,
            before: renderGridDataValue(value),
            after: renderGridDataValue(after),
          });
        });
      } else if (dataAfter) {
        dataAfter.forEach(([field, value]) => {
          comparePartialArray.push({
            field,
            after: renderGridDataValue(value),
          });
        });
      }

      setCompareObjectArray(comparePartialArray);
    }

    getCompareObject();
  }, [dataAfter, dataBefore]);

  return (
    <DataTable
      className="p-1 shadow-1"
      value={compareObjectArray}
      sortField="field"
      sortOrder={1}
      scrollable
      scrollHeight="52vh"
    >
      <Column
        field="field"
        style={{ width: '20rem' }}
        header="Field"
        sortable
        bodyClassName="font-bold"
      />
      <Column
        field="before"
        style={{ width: '30rem' }}
        header="Before"
        sortable
        bodyStyle={{ color: 'var(--yellow-700)' }}
      />
      <Column
        field="after"
        style={{ width: '30rem' }}
        header="After"
        sortable
        bodyStyle={{ color: 'var(--green-700)' }}
      />
    </DataTable>
  );
};

export default LogCompare;
