import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;
`;

export const GeneralInformation = styled.div`
  margin-left: 16px;
  width: 100%;

  .form-div {
    margin-top: 32px;
    display: flex;
    form {
      div + div,
      h1 + div {
        margin-top: 16px;
      }
    }
    form + form {
      margin-left: 20px;
    }
  }

  .tinyInput {
    width: 125px;
  }
  .smallInput {
    width: 238px;
  }
  .mediumInput {
    width: 350px;
  }
  .largeInput {
    width: 744px;
  }
  .fullInput {
    width: 1000px;
  }
  .link {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  textarea {
    resize: vertical;
  }
`;

export const Row = styled.div`
  display: flex;

  div + div,
  div + .input-link {
    margin-left: 16px;
  }

  && {
    margin-top: 16px;
  }

  .input-link {
    background-color: var(--grey);
    display: flex;
    width: 238px;

    .link-input {
      width: 85%;
    }
  }
`;
