import styled from 'styled-components';

export const FormContainer = styled.div`
  max-width: 1154px;
`;

export const Group = styled.div`
  div + & {
    margin-top: 12px;
  }
`;
