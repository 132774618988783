import { gql } from '@apollo/client';

export const listAllNotificationsQuery = gql`
  query listAllNotifications {
    listAllNotifications {
      idNotification
      idComment
      idUserNotified
      isRead
      sentEmail
      createdBy
      createdAt
      updatedBy
      updatedAt
      versionLock
      idRecord
      recordTitle
      createdBy2 {
        idUser
        username
        idAvatar
        namePrefix
        firstName
        middleName
        lastName
        nameSuffix
        avatarUrl
      }
      idComment2 {
        idComment
        idParentComment
        message
      }
      idModule2 {
        idModule
        name
        menuIcon
      }
      idSatRnc
      idSatForeignTrade
    }
  }
`;

export const markNotificationAsReadQuery = gql`
  mutation MarkNotificationAsRead($idNotification: Int!) {
    markNotificationAsRead(idNotification: $idNotification) {
      idNotification
    }
  }
`;

export const updateNotificationIsReadValueQuery = gql`
  mutation UpdateNotificationIsReadValue(
    $idNotification: Int!
    $isRead: Boolean!
  ) {
    updateNotificationIsReadValue(
      idNotification: $idNotification
      isRead: $isRead
    ) {
      isRead
    }
  }
`;

export const markAllNotificationsAsReadQuery = gql`
  mutation MarkAllNotificationsAsRead($idUser: Int!) {
    markAllNotificationsAsRead(idUser: $idUser) {
      idNotification
    }
  }
`;
