import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { isEqual } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { FiExternalLink } from 'react-icons/fi';
import { IoMdAttach } from 'react-icons/io';
import pagination from '../../../../config/pagination';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import AdvancedFiltersPanel from '../../../../components/AdvancedFiltersPanel';
import { IAdvancedFiltersField } from '../../../../components/AdvancedFiltersPanel/interfaces';
import { Attachment, Attachments, Comments, Container } from './styles';
import FormTitle from '../../../../components/FormTitle';
import FileUpload from '../../../../components/FileUpload';
import Button from '../../../../components/Button';
import { FileType } from '../../../../shared/enums/fileType';
import {
  IFinancialTransactionUpdatesLazyParams,
  IFinancialTransactionComment,
} from './interfaces';
import CommentAdding, {
  ICommentAddingRef,
} from '../../../../components/CommentAdding';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  createFinancialTransactionCommentQuery,
  deleteFinancialTransactionCommentQuery,
  listFinancialTransactionCommentsQuery,
  updateFinancialTransactionCommentQuery,
} from './queries';
import { IComment } from '../../../../shared/interfaces/comment';
import { AttachmentTypes } from '../../../../shared/enums/attachmentType';
import { FileUploadResponse } from '../../../../components/FileUpload/interfaces';
import { IAttachment } from '../../../../shared/interfaces/attachment';
import {
  createFinancialTrasnsactionAttachmentsQuery,
  deleteFinancialTransactionAttachmentsQuery,
  listFinancialTransactionAttachmentsQuery,
} from '../queries';
import { useAuth } from '../../../../hooks/useAuth';
import { parseCommentMessage } from '../../../../utils/parseCommentMessage';
import Image from '../../../../components/PageHeader/Image';
import { imageExtensions } from '../../../../shared/constants/imageExtensions';
import Loading from '../../../../components/Loading';
import CommentAndReplies from '../../../../components/Comments/CommentAndReplies';
import { commonFiltersFields, restrictedFiltersField } from './constants';
import { isFinancialModuleAllowed } from '../../../../shared/roles/financial';
import { IFinancialTransactionAttachment } from '../../FinancialTransactionAttachmentsList/interfaces';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../../components/CommentPagination';

interface IFinancialTransactionUpdates extends PageTabContainerProps {
  idFinancialTransaction: number;
}

const Updates: React.FC<IFinancialTransactionUpdates> = ({
  idFinancialTransaction,
  selected,
}) => {
  const { user, idUser } = useAuth();

  const { showError, showSuccess } = useRefHook();

  const [globalFilter, setGlobalFilter] = useState('');

  const [pageLoading, setPageLoading] = useState(false);

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const commentAddingRef = useRef<ICommentAddingRef>(null);

  const userCanSeeRestrictInfo = isFinancialModuleAllowed(idUser);

  const initialLazyParams = useMemo(() => {
    return {
      ...pagination.initialLazyParams,
      fetchAutomaticComments: true,
    };
  }, []);

  const advancedFiltersFields: IAdvancedFiltersField[] = useMemo(() => {
    const filters = [...commonFiltersFields];
    if (userCanSeeRestrictInfo) {
      filters.push(restrictedFiltersField);
    }
    return filters;
  }, [userCanSeeRestrictInfo]);

  const [lazyParams, setLazyParams] =
    useState<IFinancialTransactionUpdatesLazyParams>(initialLazyParams);

  const [updates, setUpdates] =
    useState<{ data: IFinancialTransactionComment[]; items: number }>();

  const [selectedUpdate, setSelectedUpdate] =
    useState<IFinancialTransactionComment>();

  const [financialTransactionAttachments, setFinancialTransactionAttachments] =
    useState<IFinancialTransactionAttachment[]>();

  const [createFinancialTransactionCommentMutation] = useMutation(
    createFinancialTransactionCommentQuery,
  );
  const [updateFinancialTransactionCommentMutation] = useMutation(
    updateFinancialTransactionCommentQuery,
  );
  const [deleteFinancialTransactionCommentMutation] = useMutation(
    deleteFinancialTransactionCommentQuery,
  );

  const [createFinancialTrasnsactionAttachmentsMutation] = useMutation(
    createFinancialTrasnsactionAttachmentsQuery,
  );

  const [deleteFinancialTransactionAttachmentsMutation] = useMutation(
    deleteFinancialTransactionAttachmentsQuery,
  );

  const cleanSelected = useCallback(() => {
    setSelectedUpdate({} as IFinancialTransactionComment);
  }, []);

  const toggleAutomaticComments = () => {
    setLazyParams({
      ...initialLazyParams,
      fetchAutomaticComments: !lazyParams.fetchAutomaticComments,
    });
  };

  const [loadUpdatesData, { loading: updatesDataLoading }] = useLazyQuery(
    listFinancialTransactionCommentsQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idFinancialTransaction,
          globalSearch: lazyParams.globalFilter,
          createdBy: lazyParams.createdBy,
          createdCommentStartDateRange: lazyParams.createdCommentStartDateRange,
          createdCommentEndDateRange: lazyParams.createdCommentEndDateRange,
          isGeneratedSystem: lazyParams.isGeneratedSystem,
          isRestrictInfo: lazyParams.isRestrictInfo,
          fetchAutomaticComments: lazyParams.fetchAutomaticComments,
        },
      },
      onCompleted: response => {
        if (response.listFinancialTransactionComments) {
          if (lazyParams.page && updates) {
            setUpdates({
              items: response.listFinancialTransactionComments.items,
              data: [
                ...updates.data,
                ...response.listFinancialTransactionComments.data,
              ],
            });
          } else {
            setUpdates(response.listFinancialTransactionComments);
          }
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Updates',
          detail: errorData.message,
        });
      },
    },
  );

  const handleRefreshUpdates = useCallback(() => {
    if (!isEqual(lazyParams, initialLazyParams)) {
      setLazyParams(initialLazyParams);
    } else {
      loadUpdatesData();
    }
  }, [initialLazyParams, lazyParams, loadUpdatesData]);

  const handleSubmitUpdate = useCallback(
    async (e: IComment) => {
      setPageLoading(true);
      try {
        if (!selectedUpdate?.idFinancialTransactionComment) {
          await createFinancialTransactionCommentMutation({
            variables: {
              data: {
                idFinancialTransaction,
                comment: {
                  message: e.message,
                  isRestrictInfo: e.isRestrictInfo,
                  idAttachment: e.idAttachment,
                },
              },
            },
          });
        } else {
          // Atualiza comentario pelo edit
          await updateFinancialTransactionCommentMutation({
            variables: {
              data: {
                idFinancialTransactionComment:
                  selectedUpdate.idFinancialTransactionComment,
                comment: {
                  message: e.message,
                  isRestrictInfo: e.isRestrictInfo,
                  idAttachment: e.idAttachment,
                },
              },
            },
          });
        }

        handleRefreshUpdates();

        showSuccess({
          summary: 'Update published',
        });

        setSelectedUpdate({} as IFinancialTransactionComment);
      } catch (err) {
        showError({
          summary: `Error while ${
            selectedUpdate?.idFinancialTransactionComment
              ? 'updating'
              : 'creating'
          } update`,
          detail: err.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      setPageLoading,
      selectedUpdate?.idFinancialTransactionComment,
      handleRefreshUpdates,
      showSuccess,
      createFinancialTransactionCommentMutation,
      idFinancialTransaction,
      updateFinancialTransactionCommentMutation,
      showError,
    ],
  );

  const handleDeleteUpdate = useCallback(
    async (idFinancialTransactionComment: number) => {
      try {
        await deleteFinancialTransactionCommentMutation({
          variables: {
            idFinancialTransactionComment,
          },
        });

        showSuccess({
          summary: 'Comment deleted',
        });

        // Atualiza lista de updates
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting comment',
          detail: error.message,
        });
      } finally {
        setSelectedUpdate({} as IFinancialTransactionComment);
      }
    },
    [
      deleteFinancialTransactionCommentMutation,
      loadUpdatesData,
      showError,
      showSuccess,
    ],
  );

  const handleAddAttachmentComment = (attachment: IAttachment | undefined) => {
    if (attachment) {
      commentAddingRef.current?.addCommentFromAttachment(attachment);
    }
  };

  const handleEditUpdate = useCallback(
    (update: IFinancialTransactionComment) => {
      setSelectedUpdate(update);
      commentAddingRef.current?.editComment(update.idComment2);
    },
    [],
  );

  const currentUserCreatedComment = useCallback(
    (createdBy: number) => {
      return createdBy === user.idUser;
    },
    [user.idUser],
  );

  const [loadAttachments] = useLazyQuery(
    listFinancialTransactionAttachmentsQuery,
    {
      variables: {
        data: {
          idFinancialTransaction,
          idTypes: [AttachmentTypes.UPDATES_FILE_FINANCIAL_TRANSACTION],
        },
      },
      onCompleted: response => {
        if (response.listFinancialTransactionAttachments?.data) {
          setFinancialTransactionAttachments(
            response.listFinancialTransactionAttachments.data,
          );
        }
      },
      onError: error => {
        showError({
          summary: 'Error while getting Updates Attachments',
          detail: error.message,
        });
      },
    },
  );

  const uploadAttachment = useCallback(
    async (data: FileUploadResponse[]): Promise<IAttachment | undefined> => {
      try {
        const response = await createFinancialTrasnsactionAttachmentsMutation({
          variables: {
            data: {
              idFinancialTransaction,
              idType: AttachmentTypes.UPDATES_FILE_FINANCIAL_TRANSACTION,
              uploadedFiles: data.map(file => file.serverName),
            },
          },
        });

        showSuccess({
          summary: 'Update published',
        });
        loadAttachments();
        return response.data?.createFinancialTransactionAttachments[0]
          ?.idAttachment2;
      } catch (err) {
        showError({
          summary: 'Error while creating Update Attachment',
          detail: err.message,
        });
        return undefined;
      } finally {
        setPageLoading(false);
      }
    },
    [
      createFinancialTrasnsactionAttachmentsMutation,
      idFinancialTransaction,
      loadAttachments,
      setPageLoading,
      showError,
      showSuccess,
    ],
  );

  const handleDeleteAttachment = useCallback(
    async (
      financialTransactionAttachment:
        | IFinancialTransactionAttachment
        | undefined,
    ) => {
      setPageLoading(true);
      try {
        await deleteFinancialTransactionAttachmentsMutation({
          variables: {
            idsFinancialTransactionAttachments: [
              financialTransactionAttachment?.idFinancialTransactionAttachment,
            ],
          },
        });

        showSuccess({
          summary: 'Update Attachment deleted',
        });
        loadAttachments();
        loadUpdatesData();
      } catch (error) {
        showError({
          summary: 'Error while deleting Update Attachment',
          detail: error.message,
        });
      } finally {
        setPageLoading(false);
      }
    },
    [
      deleteFinancialTransactionAttachmentsMutation,
      loadAttachments,
      loadUpdatesData,
      setPageLoading,
      showError,
      showSuccess,
    ],
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLazyParams(currentLazyParams => {
        return {
          ...currentLazyParams,
          globalFilter,
          first: pagination.initialLazyParams.first,
          page: pagination.initialLazyParams.page,
          rows: pagination.initialLazyParams.rows,
        };
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [globalFilter]);

  useEffect(() => {
    if (selected && !updates) {
      loadUpdatesData();
      loadAttachments();
    }
  }, [loadAttachments, loadUpdatesData, selected, updates]);

  const chooseOptions = {
    icon: 'pi pi-paperclip',
    iconOnly: true,
    className: 'paperclip-icon',
  };

  function onPage(e: ICommentPaginationPageParams) {
    setLazyParams(prev => {
      return { ...prev, ...e };
    });
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <AdvancedFiltersPanel
          innerRef={advancedFiltersPanelRef}
          fields={advancedFiltersFields}
          onApply={e =>
            setLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            setLazyParams({ ...pagination.initialLazyParams, globalFilter })
          }
        />
        <div className="content-header">
          <FormTitle name="Updates (EN)" />

          <span className="content-header-options">
            <FileUpload
              mode="basic"
              chooseOptions={chooseOptions}
              accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX}`}
              auto
              onConfirm={e => uploadAttachment(e)}
            />
            <IconField iconPosition="left">
              <InputIcon className="pi pi-search" />
              <InputText
                type="search"
                value={globalFilter}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="Search an update"
              />
            </IconField>
            <Button
              label="Filters"
              type="button"
              onClick={e =>
                advancedFiltersPanelRef.current?.toggle(e, e.target)
              }
            />
          </span>
        </div>
        <div className="flex">
          <Comments className="col-6">
            <div className="my-4">
              <div className="flex justify-content-between my-2">
                <div className="subtitle">
                  <i className="pi pi-comments" />
                  <span>Comments</span>
                </div>
                <button
                  className="button-hide"
                  onClick={() => toggleAutomaticComments()}
                  type="button"
                >
                  {lazyParams.fetchAutomaticComments ? 'Hide' : 'Show'}{' '}
                  automatic comments
                </button>
              </div>
              <CommentAdding
                ref={commentAddingRef}
                onSaveComment={handleSubmitUpdate}
                cleanSelected={cleanSelected}
                showRestrictInfo={userCanSeeRestrictInfo}
                onUploadAttachment={uploadAttachment}
              />
            </div>
            {((updatesDataLoading && !!lazyParams.page) ||
              !updatesDataLoading) &&
              updates &&
              updates.data?.map(update => {
                return (
                  <CommentAndReplies
                    idComment={update.idComment}
                    showRestrictInfo={userCanSeeRestrictInfo}
                    comment={update.idComment2}
                    userName={`${update.idComment2.createdBy2?.firstName} ${update.idComment2.createdBy2?.lastName}`}
                    creation={update.idComment2.createdAt ?? new Date()}
                    editedData={
                      update.idComment2.updatedBy2
                        ? {
                            editedAt: update.idComment2.updatedAt,
                            editedBy: `${update.idComment2.updatedBy2?.firstName} ${update.idComment2.updatedBy2?.lastName}`,
                          }
                        : undefined
                    }
                    commonTag={{
                      className: 'mr-2',
                      rounded: true,
                      isGeneratedSystem: update.idComment2.isGeneratedSystem,
                      isRestrictInfo: update.idComment2.isRestrictInfo,
                      value: undefined,
                      severity: undefined,
                    }}
                    onEdit={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () => handleEditUpdate(update)
                        : undefined
                    }
                    onDelete={
                      currentUserCreatedComment(update.idComment2.createdBy)
                        ? () =>
                            confirmDialog({
                              message:
                                'Are you sure you want to delete this update?',
                              header: 'Delete Confirmation',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-danger',
                              accept: () =>
                                handleDeleteUpdate(
                                  update.idFinancialTransactionComment,
                                ),
                            })
                        : undefined
                    }
                    userImageUrl={update.idComment2.createdBy2?.avatarUrl}
                    key={update.idFinancialTransactionComment}
                    attachmentUrl={update.idComment2.idAttachment2?.url}
                    idCreatedBy={update.idComment2.createdBy}
                    onUploadAttachment={uploadAttachment}
                  >
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: parseCommentMessage(update.idComment2),
                      }}
                      className="comment"
                    />
                  </CommentAndReplies>
                );
              })}
            <CommentPagination
              first={lazyParams.first}
              rows={lazyParams.rows}
              totalRecords={updates?.items ?? 0}
              onPage={e => onPage(e)}
              loading={updatesDataLoading}
              page={lazyParams.page}
            />
          </Comments>
          {financialTransactionAttachments &&
          financialTransactionAttachments.length > 0 ? (
            <Attachments className="col-6">
              <div className="col-12 subtitle">
                <IoMdAttach className="attachment-icon" size={25} />
                <span>Attachment</span>
              </div>
              {financialTransactionAttachments?.map(
                financialTransactionAttachment => {
                  const attachment =
                    financialTransactionAttachment?.idAttachment2;
                  const fileExtension = attachment?.name?.split('.').pop();
                  return (
                    <Attachment
                      key={
                        financialTransactionAttachment?.idFinancialTransactionAttachment
                      }
                    >
                      <div className="col-3">
                        {fileExtension &&
                        imageExtensions.includes(fileExtension) ? (
                          <Image
                            src={attachment?.url ?? ''}
                            alt={`Comment image ${attachment?.nameGivenByUser}`}
                          />
                        ) : (
                          <div className="flex justify-content-center">
                            <div className="file-extension">
                              {fileExtension}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-9 image-info">
                        <div className="col-12 flex align-items-center">
                          <span className="image-name">
                            {attachment?.nameGivenByUser}
                          </span>
                          <a
                            href={attachment?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="attachment"
                          >
                            <FiExternalLink className="mx-2" />
                          </a>
                        </div>
                        <div className="col-12">
                          {`Added: ${
                            attachment?.createdAt
                              ? new Date(attachment.createdAt).toLocaleString()
                              : ''
                          } - `}
                          <button
                            onClick={() =>
                              confirmDialog({
                                message: 'Are you sure you want to delete?',
                                header: 'Delete Confirmation',
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                accept: () =>
                                  handleDeleteAttachment(
                                    financialTransactionAttachment,
                                  ),
                              })
                            }
                            type="button"
                          >
                            Delete
                          </button>
                          {' - '}
                          <button
                            onClick={() =>
                              handleAddAttachmentComment(
                                financialTransactionAttachment?.idAttachment2,
                              )
                            }
                            type="button"
                          >
                            Add Comment
                          </button>
                        </div>
                      </div>
                    </Attachment>
                  );
                },
              )}
            </Attachments>
          ) : (
            <div className="w-full p-5 flex justify-content-center">
              <span>No attachments found</span>
            </div>
          )}
        </div>
      </Container>
      {pageLoading && <Loading />}
    </PageTabContainer>
  );
};

export default Updates;
