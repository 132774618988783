/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useRef } from 'react';

import { CgLogOut } from 'react-icons/cg';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineBell, AiFillBell } from 'react-icons/ai';
import { gql, useLazyQuery } from '@apollo/client';
import { OverlayPanel } from 'primereact/overlaypanel';
import Tag from '../Tag';
import logoHeader from '../../assets/logoHeader.svg';
import {
  Container,
  Actions,
  NotificationButton,
  NotificationContainer,
  NotificationText,
  NotificationBadge,
  NotificationImage,
  NotificationBadgeContainer,
  NotificationBadgeText,
} from './styles';
import { useAuth } from '../../hooks/useAuth';

import { useMenu } from '../../hooks/useMenu';
import SearchAll from './SearchAll';
import Notifications from '../Notifications';

const Header: React.FC = () => {
  const { changeVisibility } = useMenu();

  const notificationsOverlayPanelRef = useRef<OverlayPanel>(null);

  // Dados do usuário
  const [qtyNotification, setQtyNotification] = useState<number>(0);

  // Função de logout
  const { signOut, idUser } = useAuth();

  // Redirect
  const history = useHistory();

  // Efetua logout
  function handleSignOut() {
    signOut();
    history.push('/');
  }

  function handleSeverityByEnvironment(
    environment: string,
  ): 'success' | 'danger' | undefined {
    switch (environment) {
      case 'local':
        return 'success';
      case 'development':
        return 'danger';
      case 'test':
      default:
        return undefined;
    }
  }

  const listUserQuery = gql`
    query listUser($listUserIdUser: Int) {
      listUser(idUser: $listUserIdUser) {
        qtyNotification
      }
    }
  `;

  const [loadUserData] = useLazyQuery(listUserQuery, {
    variables: {
      listUserIdUser: idUser,
    },
    onCompleted: response => {
      setQtyNotification(response.listUser.qtyNotification);
    },
  });

  useEffect(() => {
    loadUserData();
    const interval = setInterval(() => {
      loadUserData();
    }, 180000);
    return () => clearInterval(interval);
  }, [idUser, loadUserData]);
  function hasNotification(): boolean {
    if (qtyNotification > 0) {
      return true;
    }
    return false;
  }
  function formatNotifications(): string {
    if (qtyNotification > 9) {
      return '9+';
    }

    return qtyNotification.toString() ?? '';
  }

  return (
    <Container>
      <span>
        <button type="button" onClick={changeVisibility}>
          <AiOutlineMenu size={20} />
        </button>
        <Link to="/">
          <img src={logoHeader} alt="SAT" />
        </Link>
        {process.env.REACT_APP_ENV &&
          process.env.REACT_APP_ENV !== 'production' && (
            <Tag
              value={process.env.REACT_APP_ENV.toUpperCase()}
              severity={handleSeverityByEnvironment(process.env.REACT_APP_ENV)}
            />
          )}
      </span>

      <SearchAll />

      <Actions>
        <OverlayPanel ref={notificationsOverlayPanelRef}>
          <Notifications setQtyNotification={setQtyNotification} />
        </OverlayPanel>
        <NotificationButton
          onClick={e => {
            loadUserData();
            notificationsOverlayPanelRef.current?.toggle(e);
          }}
        >
          <NotificationContainer>
            <NotificationBadge
              style={hasNotification() ? {} : { width: '80px' }}
            >
              <NotificationImage>
                {hasNotification() && <AiFillBell size={15} />}
                {!hasNotification() && <AiOutlineBell size={15} />}
              </NotificationImage>
              <NotificationBadgeContainer
                style={hasNotification() ? {} : { display: 'none' }}
              >
                <NotificationBadgeText
                  value={formatNotifications()}
                  severity="danger"
                />
              </NotificationBadgeContainer>
            </NotificationBadge>
            <NotificationText>Notifications</NotificationText>
          </NotificationContainer>
        </NotificationButton>

        <button type="button" onClick={handleSignOut}>
          <CgLogOut size={15} />
          Exit
        </button>
      </Actions>
    </Container>
  );
};

export default Header;
