import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { PortType } from '../../../shared/enums/domains';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadAllClientContactsDepartments } from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadPartners } from '../../../shared/querys/partner';
import { asyncSelectLoadPortsOptions } from '../../../shared/querys/port';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  prc: { field: 'prc', header: 'PRC Linked', advancedFilterField: 'prc' },
  cq: { field: 'cq', header: 'CQ Linked', advancedFilterField: 'cq' },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  sunNumber: {
    field: 'sunNumber',
    header: 'SUN Number',
    advancedFilterField: 'sunNumber',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  statusSat: {
    field: 'statusSat',
    header: 'SAT Status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'idProductionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'idStatusPurchasePayment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  typeOfOrder: {
    field: 'typeOfOrder',
    header: 'Type of Order',
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    advancedFilterField: '',
  },
  supplierNameShipment: {
    field: 'supplierNameShipment',
    header: 'Supplier Name',
    advancedFilterField: 'idSupplierShipment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPartner',
      optionData: asyncSelectLoadPartners,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty of CTNR + Qty type CTNR',
    advancedFilterField: '',
  },
  portLoading: {
    field: 'portLoading',
    header: 'Port of Loading',
    advancedFilterField: 'idLoadingPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
      additional: {
        type: PortType.PORT,
      },
    },
  },
  portDischarge: {
    field: 'portDischarge',
    header: 'Port of Discharge',
    advancedFilterField: 'idPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
      additional: {
        type: PortType.PORT,
      },
    },
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  createdAt: {
    field: 'createdAt',
    header: 'Created At',
    advancedFilterField: 'createdAt',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  confirmOrderPurchase: {
    field: 'confirmOrderPurchase',
    header: 'Confirm Order Purchase',
    advancedFilterField: 'confirmOrderPurchase',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  productionTime: {
    field: 'productionTime',
    header: 'Production Time',
    advancedFilterField: '',
  },
  samples: {
    field: 'samples',
    header: 'Samples',
    advancedFilterField: 'samples',
    type: AdvancedFiltersFieldType.BOOLEAN,
  },
  sampleLeadTime: {
    field: 'sampleLeadTime',
    header: 'Sample Lead Time',
    advancedFilterField: '',
  },
  estimatedInspectionDate: {
    field: 'estimatedInspectionDate',
    header: 'Estimated Inspection Date',
    advancedFilterField: 'estimatedInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    advancedFilterField: '',
  },
  quantityPiBySun: {
    field: 'quantityPiBySun',
    header: 'Quantity PI Shipment by SUN',
    advancedFilterField: '',
  },
  purchaseEstimatedAdvance: {
    field: 'purchaseEstimatedAdvance',
    header: 'Purchase Estimated Advance',
    advancedFilterField: '',
  },
  purchaseEstimatedBalance: {
    field: 'purchaseEstimatedBalance',
    header: 'Purchase Estimated Balance',
    advancedFilterField: '',
  },
  purchaseEstimatedTermCondition: {
    field: 'purchaseEstimatedTermCondition',
    header: 'Purchase Estimated Term Condition',
    advancedFilterField: '',
  },
  purchaseIncoterm: {
    field: 'purchaseIncoterm',
    header: 'Purchase Incoterm',
    advancedFilterField: 'idPurchaseIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  totalCotPi: {
    field: 'totalCotPi',
    header: 'Total COT PI',
    advancedFilterField: '',
  },
  totalCotCi: {
    field: 'totalCotCi',
    header: 'Total COT CI',
    advancedFilterField: '',
  },
  purchaseEstimatedTotalAdvance: {
    field: 'purchaseEstimatedTotalAdvance',
    header: 'Purchase Estimated Total Advance',
    advancedFilterField: '',
  },
  purchaseEstimatedAdvancePaymentRequest: {
    field: 'purchaseEstimatedAdvancePaymentRequest',
    header: 'Purchase Estimated Advance Payment Request',
    advancedFilterField: '',
  },
  purchaseEstimatedTotalBalance: {
    field: 'purchaseEstimatedTotalBalance',
    header: 'Purchase Estimated Total Balance',
    advancedFilterField: '',
  },
  paymentTermAdvance: {
    field: 'paymentTermAdvance',
    header: 'Seller Payment Term Advance',
    advancedFilterField: '',
  },
  paymentTermBalance: {
    field: 'paymentTermBalance',
    header: 'Seller Payment Term Balance',
    advancedFilterField: '',
  },
  paymentTermCondition: {
    field: 'paymentTermCondition',
    header: 'Seller Payment Term Condition',
    advancedFilterField: '',
  },
  paymentSellingIncoterm: {
    field: 'paymentSellingIncoterm',
    header: 'Payment Selling Incoterm',
    advancedFilterField: 'idClientIncoterm',
  },
  totalSunPi: {
    field: 'totalSunPi',
    header: 'Total SUN PI',
    advancedFilterField: '',
  },
  totalSunCi: {
    field: 'totalSunCi',
    header: 'Total SUN CI',
    advancedFilterField: '',
  },
  estimatedGrossMargin: {
    field: 'estimatedGrossMargin',
    header: 'Estimated Gross Margin',
    advancedFilterField: '',
  },
  estimatedGrossProfit: {
    field: 'estimatedGrossProfit',
    header: 'Estimated Gross Profit',
    advancedFilterField: '',
  },
  estimatedNetMargin: {
    field: 'estimatedNetMargin',
    header: 'Estimated Net Margin',
    advancedFilterField: '',
  },
  estimatedNetProfit: {
    field: 'estimatedNetProfit',
    header: 'Estimated Net Profit',
    advancedFilterField: '',
  },
  grossMargin: {
    field: 'grossMargin',
    header: 'Gross Margin',
    advancedFilterField: '',
  },
  grossProfit: {
    field: 'grossProfit',
    header: 'Gross Profit',
    advancedFilterField: '',
  },
  netMargin: {
    field: 'netMargin',
    header: 'NET Margin',
    advancedFilterField: '',
  },
  netProfit: {
    field: 'netProfit',
    header: 'NET Profit',
    advancedFilterField: '',
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  designUser: {
    field: 'designUser',
    header: 'Design User',
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  category: {
    field: 'category',
    header: 'Category',
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: 'preview',
    label: 'Preview',
    data: [],
    children: [
      {
        key: 'preview-resume',
        label: 'Resume',
        data: [
          gridColumnsData.prc.field,
          gridColumnsData.cq.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.shipment.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.statusSat.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.replacementFrom.field,
          gridColumnsData.supplierNameShipment.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.portLoading.field,
          gridColumnsData.portDischarge.field,
          gridColumnsData.countryOrigin.field,
          gridColumnsData.createdAt.field,
          gridColumnsData.confirmOrderPurchase.field,
          gridColumnsData.productionTime.field,
          gridColumnsData.samples.field,
          gridColumnsData.estimatedInspectionDate.field,
          gridColumnsData.realInspectionDate.field,
          gridColumnsData.estimatedShipmentDate.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.supplierPaymentStatus.field,
          gridColumnsData.shipmentResume.field,
          gridColumnsData.quantityPiBySun.field,
          gridColumnsData.purchaseEstimatedAdvance.field,
          gridColumnsData.purchaseEstimatedBalance.field,
          gridColumnsData.purchaseEstimatedTermCondition.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.totalCotPi.field,
          gridColumnsData.totalCotCi.field,
          gridColumnsData.paymentTermAdvance.field,
          gridColumnsData.paymentTermBalance.field,
          gridColumnsData.paymentTermCondition.field,
          gridColumnsData.paymentSellingIncoterm.field,
          gridColumnsData.totalSunPi.field,
          gridColumnsData.totalSunCi.field,
          gridColumnsData.estimatedGrossMargin.field,
          gridColumnsData.estimatedGrossProfit.field,
          gridColumnsData.estimatedNetMargin.field,
          gridColumnsData.estimatedNetProfit.field,
          gridColumnsData.grossMargin.field,
          gridColumnsData.grossProfit.field,
          gridColumnsData.netMargin.field,
          gridColumnsData.netProfit.field,
          gridColumnsData.purchaseUser.field,
          gridColumnsData.firstSalesUser.field,
          gridColumnsData.secondSalesUser.field,
        ],
      },
      {
        key: 'preview-full',
        label: 'Full',
        data: Object.values(gridColumnsData).map(column => column.field),
      },
    ],
  },
];
