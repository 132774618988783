import { gql } from '@apollo/client';

// Mutation para conciliar o pagamento
export const reconcileFinancialTransactionQuery = gql`
  mutation ReconcileFinancialTransactions(
    $data: [ReconcileFinancialTransactionsInput]!
  ) {
    reconcileFinancialTransactions(data: $data)
  }
`;
