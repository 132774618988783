import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import { asyncSelectLoadRncNumbers } from '../../../shared/querys/satRnc';
import { asyncSelectLoadSuppliers } from '../../../shared/querys/supplier';

interface IColumnData {
  [key: string]: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  rncNumber: {
    field: 'rncNumber',
    header: 'RNC Number',
    value: undefined,
    advancedFilterField: 'idSatRnc',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'rncNumber',
      optionValue: 'idSatRnc',
      optionData: asyncSelectLoadRncNumbers,
      isMulti: true,
    },
  },

  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    value: undefined,
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },

  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    value: undefined,
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },

  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },

  sunNumber: {
    field: 'sunNumber',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'idSupplier',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },

  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    value: undefined,
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },

  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    value: undefined,
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },

  contactAtClient: {
    field: 'contactAtClient',
    header: 'Contact At Client',
    advancedFilterField: 'contactAtClient',
  },

  priority: {
    field: 'priority',
    header: 'Priority',
    value: undefined,
    advancedFilterField: 'idPriority',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_PRIORITY,
    },
  },

  deadline: {
    field: 'deadline',
    header: 'Deadline',
    value: undefined,
    advancedFilterField: '',
  },

  waitingFor: {
    field: 'waitingFor',
    header: 'Waiting For',
    value: undefined,
    advancedFilterField: 'idWaitingFor',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },

  problemType: {
    field: 'problemType',
    header: 'Problem Type',
    value: undefined,
    advancedFilterField: 'idProblemType',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_PROBLEM_TYPE,
    },
  },

  problemDescription: {
    field: 'problemDescription',
    header: 'Problem Description',
    value: undefined,
    advancedFilterField: '',
  },

  proposedSolution: {
    field: 'proposedSolution',
    header: 'Proposed Solution',
    value: undefined,
    advancedFilterField: '',
  },

  rncResponsible: {
    field: 'rncResponsible',
    header: 'RNC Responsible',
    value: undefined,
    advancedFilterField: 'idRncResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },

  coResponsible: {
    field: 'coResponsible',
    header: 'CO-Responsible',
    value: undefined,
    advancedFilterField: 'coResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },

  cost: {
    field: 'cost',
    header: 'RNC Cost',
    value: undefined,
    advancedFilterField: 'idCost',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_COST,
    },
  },

  costResponsible: {
    field: 'costResponsible',
    header: 'RNC Cost Responsible',
    value: undefined,
    advancedFilterField: 'idCostResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_COST_RESPONSIBLE,
    },
  },

  reWork: {
    field: 'reWork',
    header: 'Rework',
    value: undefined,
    advancedFilterField: 'idReWork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.CAMPO_YES_NO,
    },
  },

  totalCostFinal: {
    field: 'totalCostFinal',
    header: 'Final RNC Cost USD/EUR)',
    value: undefined,
    advancedFilterField: '',
  },

  refund: {
    field: 'refund',
    header: 'Refund (USD/EUR)',
    value: undefined,
    advancedFilterField: '',
  },

  itemsTotalUsd: {
    field: 'itemsTotalUsd',
    header: 'Total Items USD',
    value: undefined,
    advancedFilterField: '',
  },

  itemsTotalBrl: {
    field: 'itemsTotalBrl',
    header: 'Total Items BRL',
    value: undefined,
    advancedFilterField: '',
  },

  totalCostCurrencySat: {
    field: 'totalCostCurrencySat',
    header: 'Total Cost (USD/EUR)',
    value: undefined,
    advancedFilterField: '',
  },

  totalCostBrl: {
    field: 'totalCostBrl',
    header: 'Total Cost (R$)',
    value: undefined,
    advancedFilterField: '',
  },

  exchangeRate: {
    field: 'exchangeRate',
    header: 'Exchange Rate',
    value: undefined,
    advancedFilterField: '',
  },

  finalCreditNote: {
    field: 'finalCreditNote',
    header: 'Final Credit Note',
    value: undefined,
    advancedFilterField: '',
  },

  rncCreditNote: {
    field: 'rncCreditNote',
    header: 'RNC Credit Note',
    value: undefined,
    advancedFilterField: '',
  },

  finalDebitNote: {
    field: 'finalDebitNote',
    header: 'Final Debit Note  ',
    value: undefined,
    advancedFilterField: '',
  },

  rncDebitNote: {
    field: 'rncDebitNote',
    header: 'RNC Debit Note',
    value: undefined,
    advancedFilterField: '',
  },

  clientDebitNoteNumber: {
    field: 'clientDebitNoteNumber',
    header: 'Client Debit Note Number',
    advancedFilterField: 'clientDebitNoteNumber',
  },

  clientDebitNoteStatus: {
    field: 'clientDebitNoteStatus',
    header: 'Client Debit Note Status',
    value: undefined,
    advancedFilterField: 'idClientDebitNoteStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_CLIENT_DEBIT_NOTE_STATUS,
    },
  },

  clientNf: {
    field: 'clientNf',
    header: 'Client NF',
    advancedFilterField: 'clientNf',
  },

  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },

  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },

  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },

  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },

  statusRNC: {
    field: 'statusRNC',
    header: 'RNC Status',
    value: undefined,
    advancedFilterField: 'idStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_STATUS,
    },
  },

  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },

  daysOpen: {
    field: 'daysOpen',
    header: 'Days Open',
    value: undefined,
    advancedFilterField: '',
  },

  openingDate: {
    field: 'openingDate',
    header: 'Opening Date',
    value: undefined,
    advancedFilterField: 'openingDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },

  finishingDate: {
    field: 'finishingDate',
    header: 'Finishing Date',
    value: undefined,
    advancedFilterField: 'finishingDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },

  openedBy: {
    field: 'openedBy',
    header: 'Opened By',
    value: undefined,
    advancedFilterField: '',
  },

  category: {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },

  updatesRnc: {
    field: 'updatesRnc',
    header: 'Updates RNC',
    value: undefined,
    advancedFilterField: '',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.statusRNC.field,
          gridColumnsData.rncNumber.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.shipment.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.priority.field,
          gridColumnsData.deadline.field,
          gridColumnsData.waitingFor.field,
          gridColumnsData.problemType.field,
          gridColumnsData.problemDescription.field,
          gridColumnsData.proposedSolution.field,
          gridColumnsData.rncResponsible.field,
          gridColumnsData.coResponsible.field,
          gridColumnsData.cost.field,
          gridColumnsData.costResponsible.field,
          gridColumnsData.reWork.field,
          gridColumnsData.totalCostFinal.field,
          gridColumnsData.refund.field,
          gridColumnsData.rncCreditNote.field,
          gridColumnsData.rncDebitNote.field,
          gridColumnsData.clientDebitNoteNumber.field,
          gridColumnsData.clientDebitNoteStatus.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.countryOrigin.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.shipmentResume.field,
          gridColumnsData.daysOpen.field,
          gridColumnsData.openingDate.field,
          gridColumnsData.finishingDate.field,
          gridColumnsData.category.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
