import generateRandomString from '../../../../../../../utils/generateRandomString';
import { ProductPackageSizeBarcode } from '../../../interfaces';
import {
  PackageReferenceContentReducer,
  PackageReferenceContentReducerAction,
  PackageReferenceContentReducerActionKind,
} from './interfaces';

export const packageReferenceContentReducer = (
  state: PackageReferenceContentReducer,
  action: PackageReferenceContentReducerAction,
): PackageReferenceContentReducer => {
  switch (action.type) {
    case PackageReferenceContentReducerActionKind.ADD_SIZE: {
      // if (!action.payload?.idPackageReference) {
      //   throw new Error(
      //     'You need to provide a package reference to be associated',
      //   );
      // }

      if (!action.payload?.size) {
        throw new Error('You need to inform the size selected');
      }

      const newItem: ProductPackageSizeBarcode = {
        idPackageReference: action.payload.idPackageReference,
        idPackageSizeBarcode: generateRandomString(4),
        size: action.payload.size.idDomain,
        size2: { description: action.payload.size.description },
      };

      return {
        ...state,
        productPackageSizeBarcodes: state.productPackageSizeBarcodes
          ? [...state.productPackageSizeBarcodes, newItem]
          : [newItem],
      };
    }

    case PackageReferenceContentReducerActionKind.DELETE_SIZE: {
      if (!action.payload?.size) {
        throw new Error('You need to inform the size to remove');
      }

      const itemToDelete = state.productPackageSizeBarcodes?.find(
        barcode => barcode.size === action.payload?.size?.idDomain,
      );

      if (!itemToDelete) return state;

      const filteredItems = state.productPackageSizeBarcodes?.filter(
        barcode =>
          barcode.idPackageSizeBarcode !== itemToDelete.idPackageSizeBarcode,
      );

      // Se o ID for do tipo number, significa que foi gerado pelo backend
      // e deve ser informado para remocao no banco
      if (typeof itemToDelete.idPackageSizeBarcode === 'number') {
        return {
          ...state,
          productPackageSizeBarcodes: filteredItems,
          sizesToDelete: [
            ...state.sizesToDelete,
            itemToDelete.idPackageSizeBarcode,
          ],
        };
      }

      return {
        ...state,
        productPackageSizeBarcodes: filteredItems,
      };
    }

    case PackageReferenceContentReducerActionKind.CHANGE_SIZE_GROUP: {
      if (!action.payload?.idSizeGroup) {
        throw new Error('You need to inform the size group');
      }

      if (action.payload.idSizeGroup === state.idSizeGroup) return state;

      // Como o usuario trocou o Size Group, as informacoes de sizes e
      // barcodes existentes nao sao mais compativeis e devem ser apagadas
      return {
        ...state,
        idSizeGroup: action.payload.idSizeGroup,
        sizesToDelete: [],
        productPackageSizeBarcodes: [],
      };
    }

    default:
      throw new Error('Invalid action type');
  }
};
