import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .buttons-header {
    display: flex;
    button + button {
      margin-left: 12px;
    }
  }

  .p-datatable {
    // Permite definir largura fixa para as colunas da Grid
    thead th,
    tbody td {
      flex: none;
    }
  }

  .attachment {
    display: flex;

    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    svg {
      margin-left: 12px;
    }
    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }
  }
`;

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  .grid-filters {
    display: flex;
    .p-inputtext {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .grid-header-buttons {
    display: flex;
    button {
      margin-right: 12px;
    }
  }

  .advanced-filters-button {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .applied-filters-button {
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
`;
