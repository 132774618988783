import styled from 'styled-components';

export const FinancialData = styled.div`
  grid-column-gap: 36px;
  display: grid;

  .form-title {
    grid-area: head;
  }

  .purchase-pi {
    grid-area: purchase-pi;
    h2 {
      color: var(--blue);
    }
  }

  .seller-pi {
    grid-area: seller-pi;
    h2 {
      color: var(--orange);
    }
  }

  .results-area {
    grid-area: results-area;
    h2 {
      color: var(--sunasia-sectors-qualidade);
    }
  }

  grid-template-columns: 420px 420px;

  grid-template-areas:
    'head head'
    'purchase-pi seller-pi'
    'results-area .';

  & + & {
    margin-top: 50px;
  }
`;

export const FinancialBlock = styled.div`
  .input-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 16px 0;
    gap: 16px;
    .form-element {
      width: unset;
    }
  }
`;

export const TotalFinancial = styled.div`
  margin-top: 37px;
  max-width: 50rem;
  div {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
`;
