import { gql } from '@apollo/client';

export const listOtherExpensesTotal = gql`
  query GetSumDataFromSatForeignTradeSupplierOtherExpenses(
    $idSatForeignTradeSupplier: Int!
    $typeIdDomainGroup: Int!
  ) {
    getSumDataFromSatForeignTradeSupplierOtherExpenses(
      idSatForeignTradeSupplier: $idSatForeignTradeSupplier
      typeIdDomainGroup: $typeIdDomainGroup
    ) {
      totalOtherExpenses
      totalOnMargin
      totalOutOfMargin
      totalDiscountFromSupplier
      totalPayToSupplier
    }
  }
`;
