import { gql } from '@apollo/client';

/**
 * Query para listar compare files de uma SAT
 */
export const listSatCompareFilesQuery = gql`
  query ListSatAttachmentsBySatId($data: ListSatAttachmentsBySatIdInput!) {
    listSatAttachmentsBySatId(data: $data) {
      data {
        idSatAttachment
        idAttachment2 {
          url
          name
          createdAt
          createdBy2 {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
