import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useRef } from 'react';
import Button from '../../../../../../components/Button';
import FilesByTypes, {
  IFilesByTypesRef,
} from '../../../../../../components/FilesByTypes';
import { IFileType } from '../../../../../../components/FilesByTypes/interfaces';
import FormCheckbox from '../../../../../../components/FormCheckbox';
import FormTitle from '../../../../../../components/FormTitle';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { ForeignTradeTypeFiles } from '../../../../../../shared/enums/domains';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { deleteSatAttachmentsQuery } from '../../../queries';
import FilesUpload, { IFilesUploadRef } from './FilesUpload';
import {
  downloadSatForeignTradeAttachmentsQuery,
  listSatAttachmentsBySatIdQuery,
} from './queries';

interface IFilesProps {
  idSat: number;
  idSatForeignTrade: number;
  foreignTradeFileTypesData: IFileType[];
  foreignTradeFileTypesLoading: boolean;
  selected: boolean;
  userCanUploadFiles: boolean;
  userCanDeleteFiles: boolean;
  shipmentIsCanceled: boolean;
}

const Files: React.FC<IFilesProps> = ({
  idSat,
  idSatForeignTrade,
  foreignTradeFileTypesData,
  foreignTradeFileTypesLoading,
  selected,
  userCanUploadFiles,
  userCanDeleteFiles,
  shipmentIsCanceled,
}) => {
  const { toastRef } = useRefHook();

  const filesUploadRef = useRef<IFilesUploadRef>(null);
  const filesByTypesRef = useRef<IFilesByTypesRef>(null);

  const [deleteSatAttachmentsMutation, { loading: fileDeleteInProgress }] =
    useMutation(deleteSatAttachmentsQuery);

  const [
    loadSatForeignTradeFiles,
    { loading: foreignTradeFilesLoading, data: foreignTradeFilesData },
  ] = useLazyQuery(listSatAttachmentsBySatIdQuery, {
    variables: {
      data: {
        idSat,
        idSatForeignTrade,
        idTypes: Object.values(ForeignTradeTypeFiles).filter(
          value => typeof value === 'number',
        ),
        _orderBy: 'attachment.createdAt',
        _sortOrder: 'DESC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Foreign Trade Files',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  const [
    downloadSatForeignTradeFiles,
    { loading: satForeignTradeFilesDownloadInProgress },
  ] = useLazyQuery(downloadSatForeignTradeAttachmentsQuery, {
    onCompleted: async response => {
      if (response.downloadSatForeignTradeFiles) {
        window.open(response.downloadSatForeignTradeFiles, '_blank');
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while downloading files',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while downloading files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function handleDownloadFiles() {
    const filesToDownload = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDownload?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do download',
        life: ToastLife.ERROR,
      });

      return;
    }

    downloadSatForeignTradeFiles({
      variables: {
        idsSatAttachments: filesToDownload,
      },
    });
  }

  async function handleDeleteFiles() {
    const filesToDelete = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDelete?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do delete',
        life: ToastLife.ERROR,
      });

      return;
    }

    try {
      await deleteSatAttachmentsMutation({
        variables: {
          satAttachmentIds: filesToDelete,
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'Files deleted',
        life: ToastLife.SUCCESS,
      });

      loadSatForeignTradeFiles();
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    }
  }

  useEffect(() => {
    if (selected && !foreignTradeFilesData) {
      loadSatForeignTradeFiles();
    }
  }, [foreignTradeFilesData, loadSatForeignTradeFiles, selected]);

  return (
    <div className="form-group grid">
      <FormTitle className="col-12" name="Foreign Trade Files">
        {userCanUploadFiles && !shipmentIsCanceled && (
          <Button
            type="button"
            label="Upload"
            icon="pi pi-upload"
            onClick={() => filesUploadRef.current?.toggleModal()}
          />
        )}

        <Button
          type="button"
          label="Download"
          onClick={() => handleDownloadFiles()}
          disabled={satForeignTradeFilesDownloadInProgress}
          icon={
            satForeignTradeFilesDownloadInProgress
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-download'
          }
        />

        {userCanDeleteFiles && !shipmentIsCanceled && (
          <Button
            type="button"
            label="Delete"
            onClick={() =>
              confirmDialog({
                message: 'Are you sure you want to delete the selected files?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: () => handleDeleteFiles(),
              })
            }
            disabled={fileDeleteInProgress}
            icon={
              fileDeleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'
            }
            severity="danger"
          />
        )}
      </FormTitle>

      <FilesUpload
        ref={filesUploadRef}
        idSat={idSat}
        idSatForeignTrade={idSatForeignTrade}
        foreignTradeFilesRefetch={loadSatForeignTradeFiles}
      />

      <FormCheckbox className="col-12" name="noReport" label="No Report" />
      <FormCheckbox
        className="col-12"
        name="noLoadingPictures"
        label="No Loading Pictures"
      />

      <FilesByTypes
        ref={filesByTypesRef}
        className="col-12"
        fileTypes={foreignTradeFileTypesData}
        files={foreignTradeFilesData?.listSatAttachmentsBySatId.data}
        typesLoading={foreignTradeFileTypesLoading}
        fileIdKey="idSatAttachment"
        filesLoading={foreignTradeFilesLoading}
        fileDescriptionKey="idAttachment2.nameGivenByUser"
        fileTypeKey="idAttachment2.idType"
        fileCreatedAtKey="idAttachment2.createdAt"
      />
    </div>
  );
};

export default Files;
