import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiDownload } from 'react-icons/fi';
import { useLazyQuery } from '@apollo/client';
import { prcFileLogInitialState, prcFileLogReducer } from './reducer';
import { IPrcFileLogRef, PrcFileLogReducerActionKind } from './interfaces';
import TransparentButton from '../../../../components/TransparentButton';
import { downloadPrcFileLogQuery } from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';

interface IFileLogProps {
  ref: Ref<IPrcFileLogRef>;
  header?: string;
  position?:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';
}

const PrcFileLog: React.FC<IFileLogProps> = forwardRef(
  ({ header, position }, ref) => {
    const [state, stateDispatch] = useReducer(
      prcFileLogReducer,
      prcFileLogInitialState,
    );

    useImperativeHandle(ref, () => ({
      toggleDialog(data, tempHeader) {
        stateDispatch({
          type: PrcFileLogReducerActionKind.OPEN_DIALOG,
          payload: { data, header: tempHeader },
        });
      },
    }));

    const { showError } = useRefHook();
    const [itemBeingDowloaded, setItemBeingDownloaded] = useState<number>();

    /**
     * Query para buscar arquivo do storage pelo servidor
     */
    const [downloadPrcFile] = useLazyQuery(downloadPrcFileLogQuery, {
      onCompleted: async response => {
        if (response.downloadPrcFileLog) {
          window.open(response.downloadPrcFileLog, '_blank');
        } else {
          showError({ summary: 'Error while getting PRC file' });
        }
      },
    });

    async function handleDownloadFile(idPrcFileLog: number) {
      setItemBeingDownloaded(idPrcFileLog);
      try {
        await downloadPrcFile({
          variables: {
            idPrcFileLog,
          },
        });
      } catch (error) {
        showError({
          summary: 'Error while getting PRC file',
          detail: error.message,
        });
      } finally {
        setItemBeingDownloaded(undefined);
      }
    }

    return (
      <Dialog
        header={state.header ?? header}
        visible={state.displayDialog}
        onHide={() =>
          stateDispatch({
            type: PrcFileLogReducerActionKind.CLOSE_DIALOG,
          })
        }
        closable
        closeOnEscape
        dismissableMask
        position={position}
      >
        <DataTable
          value={state.data}
          style={{ maxWidth: '400px' }}
          removableSort
          emptyMessage="No items to show"
        >
          <Column
            field="createdAt"
            header="Created At"
            body={e => new Date(e.createdAt).toLocaleString()}
            sortable
          />
          <Column
            field="createdBy2.firstName"
            header="Created By"
            body={e => `${e.createdBy2.firstName} ${e.createdBy2.lastName}`}
            sortable
          />
          <Column
            field="file"
            header="File"
            body={e => (
              <TransparentButton
                type="button"
                onClick={() => handleDownloadFile(e.idPrcFileLog)}
                disabled={!!itemBeingDowloaded}
              >
                {itemBeingDowloaded === e.idPrcFileLog ? (
                  <i className="pi pi-spin pi-spinner" />
                ) : (
                  <FiDownload size={18} />
                )}
              </TransparentButton>
            )}
          />
        </DataTable>
      </Dialog>
    );
  },
);

export default PrcFileLog;
