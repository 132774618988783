import { gql } from '@apollo/client';

export const listCqAttachmentsByIdCqQuery = gql`
  query ListAllCqAttachments($data: ListAllCqAttachmentsInput!) {
    listAllCqAttachments(data: $data) {
      items
      data {
        idCqAttachment
        idCq
        idAttachment
        idAttachment2 {
          idAttachment
          url
          name
          nameGivenByUser
          idType
          createdBy2 {
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;

// Query para listar Client Quotation
export const listClientQuotationQuery = gql`
  query ListClientQuotationById($idCq: Int!) {
    listClientQuotationById(idCq: $idCq) {
      idCq
      cqNumber
      mainProduct
      idCategory
      idCategory2 {
        idCategory
        name
        indConfection
      }
      remarks
      packageNotes
      indSamples
      samplesLeadTime
      cqCtnrs {
        idCqCtnr
        qtyCtnr
        idTypeCtnr
        idTypeCtnr2 {
          idDomain
          description
        }
      }
      idClient
      idClient2 {
        idClient
        name
        clientPorts {
          idPort2 {
            idPort
            name
          }
        }
      }
      clientCompanyName
      clientCnpj
      clientAddress
      idFirstBuyer
      idFirstBuyer2 {
        idClientContact
        name
      }
      idSecondBuyer
      idSecondBuyer2 {
        idClientContact
        name
      }
      idImporter
      idImporter2 {
        idClient
        name
      }
      importerCompanyName
      importerCnpj
      importerAddress
      idSellingIncoterm2 {
        idDomain
        description
      }
      paymentTermAdvance
      paymentTermBalance
      idPaymentTermCondition2 {
        idDomain
        description
      }
      idExporter
      idExporter2 {
        idSupplier
        name
        sunNumber
        idIncoterm
        idIncotermSecond
      }
      exporterAddress
      idPurchaseIncoterm
      idPurchaseIncoterm2 {
        idDomain
        description
      }
      productionTime
      cqSuppliers {
        idCqSupplier
        idSupplier
        idSupplier2 {
          idIncoterm
          idIncotermSecond
          sunNumber
        }
      }
      idPortOfLoading
      idPortOfLoading2 {
        idPort
        name
        idCountry
        idCountry2 {
          idCountry
          name
        }
      }
      idPortOfDischarge
      idPortOfDischarge2 {
        idPort
        name
        idCountry
        idCountry2 {
          idCountry
          name
        }
      }
      idCountryOrigin
      idCountryOrigin2 {
        idCountry
        name
      }
      idCountryAcquisition
      idCountryAcquisition2 {
        idCountry
        name
      }
      idCountryProcedence
      idCountryProcedence2 {
        idCountry
        name
      }
      estimatedInspection
      estimatedShipment
      estimatedArrival
      estimatedArrivalAtClient
      purchaseUser
      purchaseUser2 {
        idUser
        firstName
        lastName
      }
      salesUser
      salesUser2 {
        idUser
        firstName
        lastName
      }
      approvedAt
      approvedOn
      approvedOn2 {
        idUser
        firstName
      }
      idStatus
      idStatus2 {
        description
      }
      idResponsibleUser
      versionLock
      createdAt
      createdBy2 {
        firstName
        lastName
        roleUsers {
          idRole2 {
            name
          }
        }
      }
      updatedAt
      updatedBy2 {
        firstName
        lastName
      }
      idPurchaseIncoterm2 {
        idDomain
        description
      }
      indConfection
      paymentTermBalance
      paymentTermAdvance
      idPaymentTermCondition2 {
        description
      }
      amountCot
      amountSun
      grossMargin
      suppliersPurchaseIncoterms {
        idDomain
        description
      }
    }
  }
`;

// Mutation de acao elaborar Client Quotation
export const clientQuotationElaborarActionQuery = gql`
  mutation ClientQuotationElaborarAction(
    $data: ClientQuotationElaborarActionInput!
  ) {
    clientQuotationElaborarAction(data: $data)
  }
`;

// Mutation de acao Client Quotation pool purchase
export const clientQuotationPoolPurchaseActionQuery = gql`
  mutation ClientQuotationPoolPurchaseAction($idCq: Int!) {
    clientQuotationPoolPurchaseAction(idCq: $idCq)
  }
`;

// Mutation de acao Client Quotation pool purchase
export const clientQuotationPurchaseActionQuery = gql`
  mutation ClientQuotationPurchaseAction(
    $data: ClientQuotationPurchaseActionInput!
  ) {
    clientQuotationPurchaseAction(data: $data)
  }
`;

// Mutation de acao aprovar Client Quotation
export const clientQuotationAprovarActionQuery = gql`
  mutation ClientQuotationAprovarAction(
    $data: ClientQuotationAprovarActionInput!
  ) {
    clientQuotationAprovarAction(data: $data)
  }
`;

// Mutation para duplicar client quotation
export const duplicateClientQuotationQuery = gql`
  mutation DuplicateClientQuotation($idCq: Int!) {
    duplicateClientQuotation(idCq: $idCq) {
      idCq
      cqNumber
    }
  }
`;

// Mutation de criacao de SAT
export const createSatQuery = gql`
  mutation CreateSat($idCq: Int!) {
    createSat(idCq: $idCq) {
      idSat
      satNumber
    }
  }
`;
