import { gql } from '@apollo/client';

export const listProductBrandsQuery = gql`
  query ListProductBrands($data: ListProductBrandsInput!) {
    listProductBrands(data: $data) {
      items
      data {
        idProductBrand
        imageUrl
        code
        idClient2 {
          idClient
          name
        }
        idClientBrand2 {
          idClientBrand
          brand
        }
      }
    }
  }
`;

export const deleteProductBrandsQuery = gql`
  mutation DeleteProductBrands($idsProductBrands: [Int!]!) {
    deleteProductBrands(idsProductBrands: $idsProductBrands)
  }
`;
