import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarInput = styled.div`
  margin-right: 12px;
  display: flex;
  position: relative;

  place-content: center;

  img {
    width: 218px;
    height: 164px;

    border: solid 1px #d7d6d6;
    border-radius: 4px;
    object-fit: scale-down;
  }
  label {
    position: absolute;
    right: 2px;
    bottom: 2px;
    width: 48px;
    height: 48px;
    background: #ff9000;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: background 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }
    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;
