import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Form } from '@unform/web';
import { Skeleton } from 'primereact/skeleton';
import React, {
  Dispatch,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
  RefObject,
} from 'react';
import * as Yup from 'yup';
import FormAsyncSelect from '../../../../components/FormAsyncSelect';
import FormInput from '../../../../components/FormInput';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormTitle from '../../../../components/FormTitle';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import ToastLife from '../../../../shared/enums/toastLife';
import { asyncSelectLoadDomains } from '../../../../shared/querys/domain';
import getValidationErrors from '../../../../utils/getValidationErrors';
import {
  IClientQuotation,
  IClientQuotationSupplier,
  UpdateClientQuotationSupplierFormData,
} from '../interfaces';

import { FinancialBlock, FinancialData, TotalFinancial } from './styles';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';
import { IClientQuotationSuppliersRef } from '../Suppliers';

interface IFinancialProps extends PageTabContainerProps {
  /**
   * Objeto de Client Quotation
   */
  clientQuotation: IClientQuotation;

  ref: Ref<IClientQuotationFinancialRef>;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  pageMenuItemKey: string;
  roleEntityFields: IRoleEntityField[];
  suppliersRef: RefObject<IClientQuotationSuppliersRef>;
}

export interface IClientQuotationFinancialRef {
  handleUpdateSuppliers(): Promise<void>;
  handleValidateFinancialForm(): Promise<boolean>;
}

const Financial: React.FC<IFinancialProps> = React.forwardRef(
  (
    {
      selected,
      clientQuotation,
      setSelectedMenuItem,
      pageMenuItemKey,
      roleEntityFields,
      suppliersRef,
    },
    ref,
  ) => {
    const [clientQuotationSuppliersData, setClientQuotationSuppliersData] =
      useState<{
        listClientQuotationSuppliersByClientQuotationId: {
          data: IClientQuotationSupplier[];
        };
      }>();

    /**
     * Busca de Client Quotation Suppliers
     */
    const listClientQuotationSuppliersByClientQuotationIdQuery = gql`
      query ListClientQuotationSuppliersByClientQuotationId(
        $data: ListClientQuotationSuppliersByClientQuotationIdInput!
      ) {
        listClientQuotationSuppliersByClientQuotationId(data: $data) {
          items
          data {
            idCqSupplier
            idSupplier2 {
              sunNumber
              name
            }
            amountCot
            paymentTermAdvance
            estimatedTotalAdvance
            amountCot
            paymentTermBalance
            estimatedTotalBalance
            idPaymentTermCondition2 {
              idDomain
              description
            }
            amountSun
            paymentTermBalance
            estimatedTotalAdvanceSeller
            estimatedTotalBalanceSeller
            grossMargin
          }
        }
      }
    `;

    // Referencia ao toast e formulario
    const { toastRef, formRef } = useRefHook();

    const [loadFinancialData, { loading: clientQuotationSuppliersLoading }] =
      useLazyQuery(listClientQuotationSuppliersByClientQuotationIdQuery, {
        variables: {
          data: {
            pagination: {
              _page: 1,
              _limit: 0,
            },
            idCq: clientQuotation.idCq,
          },
        },
        onCompleted: response => {
          if (response.listClientQuotationSuppliersByClientQuotationId) {
            setClientQuotationSuppliersData(response);
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting financial data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

    const [suppliersToUpdate, setSuppliersToUpdate] = useState<number[]>([]);

    const updateClientQuotationSuppliersQuery = gql`
      mutation UpdateClientQuotationSuppliers(
        $data: [UpdateClientQuotationSuppliersInput]!
      ) {
        updateClientQuotationSuppliers(data: $data) {
          idCqSupplier
        }
      }
    `;

    const [updateClientQuotationSuppliersMutation] = useMutation(
      updateClientQuotationSuppliersQuery,
    );

    /**
     * Efetua multiplicacao entre propriedades do formulario
     */
    const calcPercentage = useCallback(
      (
        targetProperty: string,
        valueToCalcPercentage: number | undefined,
        percentageProperty: string,
        propertyIndex: number,
      ) => {
        const percentageValue = formRef.current?.getFieldValue(
          `data[${propertyIndex}].${percentageProperty}`,
        );

        const result = (valueToCalcPercentage ?? 0) * (percentageValue / 100);

        formRef.current?.setFieldValue(
          `data[${propertyIndex}].${targetProperty}`,
          result.toLocaleString('pt-BR'),
        );
      },
      [formRef],
    );

    /**
     * Mantem valor de 100% entre propriedades de paymentTermAdvance e paymentTermBalance
     */
    const calcBalanceAndAdvance = useCallback(
      (
        targetProperty: string,
        propertyIndex: number,
        value: number | undefined,
      ) => {
        const targetValue = 100 - (value || 0);

        formRef.current?.setFieldValue(
          `data[${propertyIndex}].${targetProperty}`,
          targetValue.toLocaleString('pt-BR'),
        );
      },
      [formRef],
    );

    const handleAddSupplierToUpdate = useCallback(
      (newId: number) => {
        if (!suppliersToUpdate.includes(newId)) {
          setSuppliersToUpdate([...suppliersToUpdate, newId]);
        }
      },
      [suppliersToUpdate],
    );

    const handleSubmit = useCallback(
      async (formData: UpdateClientQuotationSupplierFormData) => {
        const suppliersChanged = formData.data.filter(supplierForm =>
          suppliersToUpdate.includes(parseInt(supplierForm.idCqSupplier, 10)),
        );

        if (!suppliersChanged.length) {
          setClientQuotationSuppliersData(undefined);
          return;
        }

        try {
          const parsedData = suppliersChanged?.map(clientQuotationSupplier => {
            return {
              idCqSupplier: parseInt(clientQuotationSupplier.idCqSupplier, 10),
              paymentTermAdvance: clientQuotationSupplier.paymentTermAdvance,
              paymentTermBalance: clientQuotationSupplier.paymentTermBalance,
              idPaymentTermCondition:
                clientQuotationSupplier.idPaymentTermCondition,
            };
          });

          await updateClientQuotationSuppliersMutation({
            variables: {
              data: parsedData,
            },
          });

          suppliersRef.current?.clearSuppliersData();
          setClientQuotationSuppliersData(undefined);
        } catch (error) {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating financial data',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      },
      [
        suppliersRef,
        suppliersToUpdate,
        toastRef,
        updateClientQuotationSuppliersMutation,
      ],
    );

    useImperativeHandle(ref, () => ({
      handleUpdateSuppliers: async () => {
        const formData = formRef.current?.getData();
        if (formData) {
          await handleSubmit(formData as UpdateClientQuotationSupplierFormData);
        }
      },
      handleValidateFinancialForm: async () => {
        const formData = formRef.current?.getData();

        try {
          // Esvazia possíveis erros já existentes no formulário
          formRef.current?.setErrors({});

          // Define requisitos de preenchimento do formulario
          const schema = Yup.object().shape({
            data: Yup.array().of(
              Yup.object().shape({
                paymentTermAdvance: Yup.number()
                  .nullable()
                  .required('Enter a valid estimated advanced'),
                paymentTermBalance: Yup.number()
                  .nullable()
                  .required('Enter a valid estimated balance'),
                idPaymentTermCondition: Yup.number()
                  .nullable()
                  .required('Select a payment condition'),
              }),
            ),
          });

          // Efetua validação
          await schema.validate(formData, { abortEarly: false });

          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          const firstError = error.inner[0];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);
          inputWithError.focus();

          toastRef.current?.show({
            severity: 'warn',
            summary: 'Financial tab',
            detail: 'Please fill all the required fields',
            life: ToastLife.WARN,
          });

          throw new Error(error);
        }
      },
    }));

    useEffect(() => {
      if (selected && !clientQuotationSuppliersData) {
        loadFinancialData();
      }
    }, [clientQuotationSuppliersData, loadFinancialData, selected]);
    const {
      idFieldIdPaymentTermCondition,
      idFieldIdPurchaseIncoterm,
      idFieldIdSupplier,
      idFieldEstimatedTotalAdvance,
      idFieldEstimatedTotalBalance,
      idFieldGrossMargin,
      idFieldAmountSun,
      idFieldEstimatedAdvance,
      idFieldEstimatedBalance,
      idFieldAmountSunSeller,
      idFieldIdPurchaseIncotermSeller,
      idFieldEstimatedAdvanceSeller,
      idFieldEstimatedTotalAdvanceSeller,
      idFieldEstimatedBalanceSeller,
      idFieldEstimatedTotalBalanceSeller,
      idFieldIdPaymentTermConditionSeller,
      idFieldAmountSunTot,
      idFieldAmountSunSellerTot,
      idFieldGrossMarginTot,
      idFieldIdSupplierInfomation,
    } = clientQuotationRoles.fields;

    function showField(idField: number): boolean {
      return getFieldPermission(idField, roleEntityFields).view;
    }

    function validateSunNumber(cqSupplier: any) {
      if (showField(idFieldIdSupplier)) {
        return `${cqSupplier.idSupplier2.sunNumber} `;
      }
      return '';
    }

    function validateSunName(cqSupplier: any) {
      if (showField(idFieldIdSupplierInfomation)) {
        return `- ${cqSupplier.idSupplier2.name}`;
      }
      return '';
    }

    return (
      <PageTabContainer selected={selected}>
        {!clientQuotationSuppliersLoading &&
          clientQuotationSuppliersData?.listClientQuotationSuppliersByClientQuotationId && (
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={
                clientQuotationSuppliersData?.listClientQuotationSuppliersByClientQuotationId
              }
            >
              {clientQuotationSuppliersData?.listClientQuotationSuppliersByClientQuotationId.data.map(
                (cqSupplier: IClientQuotationSupplier, index: number) => {
                  return (
                    <FinancialData key={cqSupplier.idCqSupplier}>
                      <FormInput
                        name={`data[${index}].idCqSupplier`}
                        value={cqSupplier.idCqSupplier}
                        type="hidden"
                        label=""
                      />
                      <FormTitle
                        name={
                          validateSunNumber(cqSupplier) +
                          validateSunName(cqSupplier)
                        }
                      />

                      <FinancialBlock className="purchase-pi">
                        <h2>Purchase PI</h2>
                        <div className="input-div">
                          {showField(idFieldAmountSun) && (
                            <FormInputNumber
                              name={`data[${index}].amountCot`}
                              label="Total Purchase PI"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="$"
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldIdPurchaseIncoterm) && (
                            <FormInput
                              name={`data[${index}].purchaseIncoterm`}
                              label="Purchase Incoterm"
                              value={
                                clientQuotation.idPurchaseIncoterm2?.description
                              }
                              readOnly
                            />
                          )}
                        </div>

                        <h3>Estimated - Payment</h3>
                        <div className="input-div">
                          {showField(idFieldEstimatedAdvance) && (
                            <FormInputNumber
                              name={`data[${index}].paymentTermAdvance`}
                              label="Estimated Advanced (%)"
                              suffix="%"
                              decimalScale={2}
                              max={100}
                              min={0}
                              thousandSeparator="."
                              decimalSeparator=","
                              onValueChange={value => {
                                calcPercentage(
                                  'estimatedTotalAdvance',
                                  value.floatValue,
                                  'amountCot',
                                  index,
                                );

                                calcBalanceAndAdvance(
                                  'paymentTermBalance',
                                  index,
                                  value.floatValue,
                                );

                                handleAddSupplierToUpdate(
                                  cqSupplier.idCqSupplier,
                                );
                              }}
                            />
                          )}
                          {showField(idFieldEstimatedTotalAdvance) && (
                            <FormInputNumber
                              name={`data[${index}].estimatedTotalAdvance`}
                              label="Estimated Total Advanced"
                              prefix="$"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldEstimatedBalance) && (
                            <FormInputNumber
                              name={`data[${index}].paymentTermBalance`}
                              label="Estimated Balance (%)"
                              suffix="%"
                              decimalScale={2}
                              max={100}
                              min={0}
                              thousandSeparator="."
                              decimalSeparator=","
                              onValueChange={value => {
                                calcPercentage(
                                  'estimatedTotalBalance',
                                  value.floatValue,
                                  'amountCot',
                                  index,
                                );

                                calcBalanceAndAdvance(
                                  'paymentTermAdvance',
                                  index,
                                  value.floatValue,
                                );

                                handleAddSupplierToUpdate(
                                  cqSupplier.idCqSupplier,
                                );
                              }}
                            />
                          )}
                          {showField(idFieldEstimatedTotalBalance) && (
                            <FormInputNumber
                              name={`data[${index}].estimatedTotalBalance`}
                              label="Estimated Total Balance"
                              prefix="$"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldIdPaymentTermCondition) && (
                            <FormAsyncSelect
                              name={`data[${index}].idPaymentTermCondition`}
                              label="Payment Condition"
                              loadOptions={asyncSelectLoadDomains}
                              debounceTimeout={1000}
                              getOptionLabel={(option: any) =>
                                option.description
                              }
                              getOptionValue={(option: any) => option.idDomain}
                              additional={{
                                page: 1,
                                id: DomainGroup.PAYMENT_CONDITIONS,
                              }}
                              noOptionsMessage={() =>
                                'No payment conditions found'
                              }
                              initialValue={cqSupplier.idPaymentTermCondition2}
                              onValueChange={() =>
                                handleAddSupplierToUpdate(
                                  cqSupplier.idCqSupplier,
                                )
                              }
                            />
                          )}
                        </div>
                      </FinancialBlock>
                      <FinancialBlock className="seller-pi">
                        <h2>Seller PI</h2>
                        <div className="input-div">
                          {showField(idFieldAmountSunSeller) && (
                            <FormInputNumber
                              name={`data[${index}].amountSun`}
                              label="Total Seller PI"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="$"
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldIdPurchaseIncotermSeller) && (
                            <FormInput
                              name={`data[${index}].sellingIncoterm`}
                              label="Selling Incoterm"
                              value={
                                clientQuotation.idSellingIncoterm2?.description
                              }
                              readOnly
                            />
                          )}
                        </div>

                        <h3>Estimated - Payment</h3>
                        <div className="input-div">
                          {showField(idFieldEstimatedAdvanceSeller) && (
                            <FormInputNumber
                              name={`data[${index}].estimatedTotalAdvanceSellerPi`}
                              label="Estimated Advanced (%)"
                              suffix="%"
                              decimalScale={2}
                              readOnly
                              value={clientQuotation.paymentTermAdvance}
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          )}
                          {showField(idFieldEstimatedTotalAdvanceSeller) && (
                            <FormInputNumber
                              name={`data[${index}].estimatedTotalAdvanceSeller`}
                              label="Estimated Total Advanced"
                              prefix="$"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldEstimatedBalanceSeller) && (
                            <FormInputNumber
                              name={`data[${index}].paymentTermBalanceSellerPi`}
                              label="Estimated Balance (%)"
                              suffix="%"
                              decimalScale={2}
                              max={100}
                              readOnly
                              value={clientQuotation.paymentTermBalance}
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          )}
                          {showField(idFieldEstimatedTotalBalanceSeller) && (
                            <FormInputNumber
                              name={`data[${index}].estimatedTotalBalanceSeller`}
                              label="Estimated Total Balance"
                              prefix="$"
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                            />
                          )}
                          {showField(idFieldIdPaymentTermConditionSeller) && (
                            <FormInput
                              name={`data[${index}].paymentConmditionSellerPi`}
                              label="Payment Condition"
                              value={
                                clientQuotation.idPaymentTermCondition2
                                  ?.description
                              }
                              readOnly
                            />
                          )}
                        </div>
                      </FinancialBlock>
                      <FinancialBlock className="results-area">
                        <h2>Results Area</h2>
                        <div className="input-div">
                          {showField(idFieldGrossMargin) && (
                            <FormInputNumber
                              name={`data[${index}].grossMargin`}
                              label="Gross Margin (%)"
                              suffix="%"
                              decimalScale={2}
                              readOnly
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          )}
                        </div>
                      </FinancialBlock>
                    </FinancialData>
                  );
                },
              )}
              {clientQuotationSuppliersData
                ?.listClientQuotationSuppliersByClientQuotationId.data
                .length ? (
                <TotalFinancial>
                  <h2>Total Financial</h2>

                  <div>
                    {showField(idFieldAmountSunTot) && (
                      <FormInputNumber
                        name="cqAmountCot"
                        label="Total Purchase PI"
                        value={clientQuotation.amountCot}
                        readOnly
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="$"
                        decimalScale={2}
                      />
                    )}
                    {showField(idFieldAmountSunSellerTot) && (
                      <FormInputNumber
                        name="cqAmountSun"
                        label="Total Seller PI"
                        value={clientQuotation.amountSun}
                        readOnly
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="$"
                        decimalScale={2}
                      />
                    )}
                    {showField(idFieldGrossMarginTot) && (
                      <FormInputNumber
                        name="cqGrossMargin"
                        label="Gross Margin (%)"
                        value={clientQuotation.grossMargin}
                        readOnly
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        suffix="%"
                      />
                    )}
                  </div>
                </TotalFinancial>
              ) : (
                <p>
                  CQ needs to have at least one supplier to display any
                  financial data
                </p>
              )}
            </Form>
          )}

        {!clientQuotationSuppliersLoading &&
          !clientQuotationSuppliersData?.listClientQuotationSuppliersByClientQuotationId && (
            <p>There is no Financial data to show</p>
          )}

        {clientQuotationSuppliersLoading && (
          <FinancialData>
            <Skeleton width="35rem" className="mb-2 form-title" height="2rem" />
            <FinancialBlock className="purchase-pi">
              <Skeleton width="10rem" height="1.5rem" className="mb-2" />
              <div className="input-div">
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
              </div>

              <Skeleton width="12rem" height="1.3rem" className="mb-2" />
              <div className="input-div">
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
              </div>
            </FinancialBlock>

            <FinancialBlock className="seller-pi">
              <Skeleton width="10rem" height="1.5rem" className="mb-2" />
              <div className="input-div">
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
              </div>

              <Skeleton width="12rem" height="1.3rem" className="mb-2" />
              <div className="input-div">
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
                <Skeleton height="3rem" />
              </div>
            </FinancialBlock>

            <FinancialBlock className="results-area">
              <Skeleton width="10rem" height="1.5rem" className="mb-2" />
              <div className="input-div">
                <Skeleton height="3rem" />
              </div>
            </FinancialBlock>
          </FinancialData>
        )}
      </PageTabContainer>
    );
  },
);

export default Financial;
