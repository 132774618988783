import styled from 'styled-components';

export const Container = styled.div``;

export const Checklist = styled.div`
  max-width: 760px;
  padding: 12px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  margin-bottom: 20px;

  .button-div {
    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;
      margin-top: -4px;
      &:hover {
        color: var(--red);
      }
    }
  }

  .two-columns,
  .three-columns {
    display: flex;
    flex-wrap: wrap;
  }

  .inline .checklist-answer-type {
    padding-top: 10px;
  }

  .two-columns .checklist-question,
  .two-columns .checklist-answer-type,
  .three-columns .checklist-question,
  .three-columns .checklist-answer-type {
    width: 50%;
    float: left;
  }

  .three-columns .checklist-answer-option {
    width: 50%;
    float: left;
  }

  .inline .radio label,
  .two-columns .radio label,
  .three-columns .radio label,
  .form-checkbox {
    float: left;
    display: flex;
    align-items: center;
  }

  .inline .radio label,
  .two-columns .radio label {
    width: 100%;
  }

  .three-columns .radio label {
    width: 50%;
  }

  .radio label {
    padding: 5px;
  }
`;

export const ChecklistItem = styled.div`
  padding: 12px;
  border: 2px solid var(--grey);
  border-radius: 8px;

  .button-div {
    width: 100%;
  }
`;

export const Question = styled.div``;

export const AnswerType = styled.div``;

export const AnswerOption = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;

  span {
    padding-left: 5px;
  }

  img {
    padding-left: 5px;
  }

  .radio label {
    padding-left: 5px;
  }

  .form-checkbox {
    margin: 0;
  }
`;

export const Comment = styled.div`
  width: 100%;
  padding-top: 10px;
`;
