/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dropdown as PrimeDropdown,
  DropdownProps,
  DropdownChangeEvent,
} from 'primereact/dropdown';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles';

// Interface das propriedades do componente
interface IDropdownProps extends DropdownProps {
  name: string;
  label: string;
  required?: boolean;
  onValueChange?(value: Record<string, unknown>): void;
  initialValue?: any;
  className?: string;
  readOnly?: boolean;
}

const FormDropdown: React.FC<IDropdownProps> = ({
  name,
  label,
  required,
  onValueChange,
  initialValue,
  className,
  disabled,
  readOnly,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(initialValue);
  const { fieldName, error, registerField } = useField(name);

  const handleDropdownBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!dropdownRef.current?.value);
  }, []);

  const handleDropdownFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  // Registra o campo
  useEffect(() => {
    registerField({
      name: fieldName, // Nome do campo
      ref: dropdownRef.current, // Referência
      getValue: () => {
        if (selectedData === undefined || selectedData === null) {
          return '';
        }
        return selectedData;
      },
      setValue: (_, value: any) => {
        setSelectedData(value);
      },
    });
  }, [fieldName, registerField, selectedData]);

  function changeSelectedData(e: DropdownChangeEvent) {
    setSelectedData(e.value);

    if (onValueChange) {
      onValueChange(e.value);
    }
  }
  return (
    <div
      className={`${
        className ? `${className} form-dropdown` : 'form-dropdown'
      }`}
    >
      <Container
        hasError={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        required={required}
        className={`${
          className ? `${className} form-dropdown` : 'form-dropdown'
        }`}
        disabled={disabled}
        readOnly={readOnly}
      >
        <span>
          {error && <FiAlertCircle color="#c53030" size={15} />}
          <p>{label}:</p>
        </span>
        <PrimeDropdown
          {...rest}
          inputRef={dropdownRef} // Referêcia ao dropdown
          value={selectedData}
          onChange={e => changeSelectedData(e)}
          className={error ? 'p-invalid block' : 'block'} // Define classe de erro se houver
          onFocus={e => {
            const inputPageHeight = e.target.getBoundingClientRect().top + 60;
            const pageHeightTop = window.innerHeight;
            e.target.scrollIntoView({
              behavior: 'smooth',
              block: inputPageHeight > pageHeightTop ? 'center' : 'nearest',
              inline: 'nearest',
            });
            handleDropdownFocus();
          }}
          onBlur={handleDropdownBlur}
          name={name}
          disabled={disabled || readOnly}
        />

        {/* Se houver erro, exibe texto indicativo */}
        {error && (
          <small id="username2-help" className="p-error block">
            {error}
          </small>
        )}
      </Container>
    </div>
  );
};

export default FormDropdown;
