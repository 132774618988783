import React, { useEffect, useState, Fragment } from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Container, Times, Currency } from './styles';

import usdFlag from '../../assets/countriesFlags/usdFlag.svg';
import eurFlag from '../../assets/countriesFlags/eurFlag.svg';
import brlFlag from '../../assets/countriesFlags/brlFlag.svg';
import tryFlag from '../../assets/countriesFlags/tryFlag.svg';
import cnyFlag from '../../assets/countriesFlags/cnyFlag.svg';
import egpFlag from '../../assets/countriesFlags/egpFlag.svg';

import currencyApi from '../../services/axios/currencyApi/index';
import CurrencyResponse from '../../services/axios/currencyApi/CurrencyResponse';

const Footer: React.FC = () => {
  // Define estado para cada timezone
  const [utcSaoPaulo, setUtcSaoPaulo] = useState('--:--');
  const [utcBeijing, setUtcBeijing] = useState('--:--');
  const [utcCairo, setUtcCairo] = useState('--:--');
  const [utcNewDelhi, setUtcNewDelhi] = useState('--:--');
  const [currencies, setCurrencies] = useState<CurrencyResponse>();
  const [currencyUpdated, setCurrencyUpdated] = useState('--:--');

  // Carrega horarios para cada timezone
  useEffect(() => {
    const updateTime = setInterval(() => {
      const date = new Date();
      const pattern = 'KK:mm a';
      setUtcSaoPaulo(
        format(utcToZonedTime(date, 'America/Sao_Paulo'), pattern),
      );
      setUtcBeijing(format(utcToZonedTime(date, 'Asia/Shanghai'), pattern));
      setUtcCairo(format(utcToZonedTime(date, 'Africa/Cairo'), pattern));
      setUtcNewDelhi(format(utcToZonedTime(date, 'Asia/Kolkata'), pattern));
    }, 1000);

    return function cleanup() {
      clearInterval(updateTime);
    };
  }, []);

  // Carrega dados de moeda a cada 15 minutos
  useEffect(() => {
    function getCurrency() {
      currencyApi
        .get('last/USD-EUR,USD-BRL,USD-TRY,USD-CNY,USD-EGP')
        .then(response => {
          const date = new Date().toLocaleString();
          setCurrencyUpdated(date);
          setCurrencies(response.data);
        });
    }

    getCurrency();
    const updateCurrency = setInterval(() => {
      getCurrency();
    }, 15 * 60000);

    return function cleanup() {
      clearInterval(updateCurrency);
    };
  }, []);

  // Retorna respectiva bandeira
  function returnFlag(currency: string) {
    switch (currency) {
      case 'USD':
        return usdFlag;
      case 'BRL':
        return brlFlag;
      case 'CNY':
        return cnyFlag;
      case 'EGP':
        return egpFlag;
      case 'EUR':
        return eurFlag;
      case 'TRY':
        return tryFlag;
      default:
        return undefined;
    }
  }

  return (
    <Container>
      <Times>
        <span>
          <p className="label">São Paulo</p>
          <p className="date">{utcSaoPaulo}</p>
        </span>
        <p className="separator">{'\u2022'}</p>
        <span>
          <p className="label">Beijing</p>
          <p className="date">{utcBeijing}</p>
        </span>
        <p className="separator">{'\u2022'}</p>
        <span>
          <p className="label">Cairo</p>
          <p className="date">{utcCairo}</p>
        </span>
        <p className="separator">{'\u2022'}</p>
        <span>
          <p className="label">New Delhi</p>
          <p className="date">{utcNewDelhi}</p>
        </span>
      </Times>
      <Currency>
        <span>
          <p className="label">Exchange rate:</p>
        </span>
        <span>
          <img src={usdFlag} alt="USD" />
          <p className="currency">1.00 USD</p>
        </span>
        <p className="separator">{'\u2022'}</p>
        {currencies &&
          Object.values(currencies).map(currency => {
            return (
              <Fragment key={currency.codein}>
                <span>
                  <img
                    src={returnFlag(currency.codein)}
                    alt={currency.codein}
                  />
                  <p className="currency">
                    {currency.bid}
                    {'\n'}
                    {currency.codein}
                  </p>
                </span>
                <p className="separator">{'\u2022'}</p>
              </Fragment>
            );
          })}
        <span>
          <p className="label">Updated {currencyUpdated}</p>
        </span>
      </Currency>
    </Container>
  );
};

export default Footer;
