export const registerRoles = {
  idEntity: 47,
  idModule: 12,
  fields: {
    idFieldStatus: 1474,
    idFieldConfirmOrder: 1475,
    idFieldSatNumber: 1476,
    idFieldSatForeignTradeNumber: 1477,
    idFieldMainProduct: 1478,
    idFieldTypeOfOrder: 1479,
    idFieldExporter: 1480,
    idFieldImporterConsignee: 1481,
    idFieldClientNotify: 1482,
    idFieldCtnrs: 1483,
    idFieldPortLoading: 1484,
    idFieldPortDischarge: 1485,
    idFieldCountryOrigin: 1486,
    idFieldCountryAcquisition: 1487,
    idFieldCountryProcedence: 1488,
    idFieldSumQuantityPI: 1489,
    idFieldSellingIncoterm: 1490,
    idFieldTotalSunPi: 1491,
    idFieldInternalInfo: 1492,
    idFieldClientInfo: 1493,
    idFieldHrp: 1494,
    idFieldArtworkDone: 1495,
    idFieldRealEtd: 1496,
    idFieldSuppliers: 1497,
    idFieldClientOrder: 1498,
    idFieldClientDepartment: 1499,
    idFieldFirstSalesUser: 1500,
    idFieldSecondSalesUser: 1501,
    idFieldPurchaseUser: 1502,
    idFieldExportUser: 1503,
    idFieldImportUser: 1504,
    idFieldDesignUser: 1505,
    idFieldFinancialUser: 1506,
    idFieldReplacementFrom: 1507,
    idFieldCountSatItems: 1508,
    idFieldSumQuantityCtn: 1509,
    idFieldCreatedAt: 1510,
    idFieldConfirmOrderComercial: 1511,
    idFieldEstimatedInspectionDate: 1512,
    idFieldEstimatedShipmentDate: 1513,
    idFieldRealInspectionDate: 1791,
    idFieldStatusImport: 1514,
    idFieldFirstBuyer: 1515,
    idFieldSecondBuyer: 1516,
    idFieldPackagesNotes: 1517,
    idFieldStatusArtwork: 2146,
    idFieldRegisterStatus: 2189,
    idFieldProductionStatus: 2190,
  },
  permissions: {
    idPermissionExportSatsRegister: 177,
  },
};
