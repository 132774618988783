import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;
