import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 12px;
  position: relative;

  img {
    width: 200px;
    height: 110px;

    border: solid 0.1px #d7d6d6;
    border-radius: 4px;
    object-fit: scale-down;
  }

  .clickable:hover {
    filter: brightness(90%);
    cursor: pointer;
  }

  .p-fileupload {
    position: absolute;
    right: 7px;
    bottom: 17px;

    .p-button {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1 !important;

      .p-button-icon {
        font-size: 1.25rem !important;
        margin-right: 0;
        z-index: 2;
      }

      span {
        position: absolute;
      }
    }
  }
`;
