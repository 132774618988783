import { DocumentNode, gql } from '@apollo/client';
import { SatFinancialBalanceFieldsPermissions } from '../../interfaces';

export const listAllSatForeignTradePaymentsQuery = (
  fieldsPermissions: SatFinancialBalanceFieldsPermissions,
): DocumentNode => gql`
  query ListAllSatForeignTradePayments(
    $data: ListAllSatForeignTradePaymentsInput!
  ) {
    listAllSatForeignTradePayments(data: $data) {
      data {
        idType
        idSatForeignTradePayment
        hasFinancialTransactionReconciled
        ${fieldsPermissions.percentage.view ? 'percentage' : ''}
        ${fieldsPermissions.amount.view ? 'amount' : ''}
        ${fieldsPermissions.paymentDate.view ? 'paymentDate' : ''}
      }
    }
  }
`;

export const listBalancePaidTotals = gql`
  query ListSatForeignTradeSupplierBalanceTotals(
    $idSatForeignTradeSupplier: Int!
    $balanceIdType: Int!
  ) {
    listSatForeignTradeSupplierBalanceTotals(
      idSatForeignTradeSupplier: $idSatForeignTradeSupplier
      balanceIdType: $balanceIdType
    ) {
      totalAmount
    }
  }
`;

// PODE SER UTILIZADO FUTURAMENTE NA AUTOMATIZACAO
// export const requestBalancePaymentQuery = gql`
//   mutation RequestBalancePayment($idSatForeignTradeSupplier: Int!) {
//     requestBalancePayment(idSatForeignTradeSupplier: $idSatForeignTradeSupplier)
//   }
// `;
