export default {
  itemsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  initialLazyParams: {
    first: 0,
    rows: 25,
    page: 0,
  },
};

export const searchDelayMiliseconds = 2000;
