export const userGroupRoles = {
  idEntity: 53,
  idModule: 7,
  fields: {
    name: 1886,
    type: 1887,
    firstName: 1888,
    lastName: 1889,
    email: 1890,
    active: 1891,
  },
  permissions: {
    createUserGroup: 184,
    editUserGroup: 185,
    deleteUserGroup: 186,
    addUser: 187,
    removeUser: 188,
  },
};
