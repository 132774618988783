/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-alert */
import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import { IPartner } from '../../services/dataInterface';
import ILazyParams from '../../services/lazyParams';
import { queryToListPartners } from '../../services/querysGql';

import { useRefHook } from '../../hooks/useRefHook';

import AdvancedFiltersPanel from '../../components/AdvancedFiltersPanel';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import MultiSelect from '../../components/Grid/MultiSelect';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';
import PageHeader from '../../components/PageHeader';
import pagination, { searchDelayMiliseconds } from '../../config/pagination';
import { useAuth } from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import ToastLife from '../../shared/enums/toastLife';
import { partnersListRoles } from '../../shared/roles/partners';
import getFieldPermission from '../../utils/getFieldPermission';
import getUserFieldsAndPermissionsByEntity from '../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';
import userHasPermission from '../../utils/userHasPermission';
import { gridColumnsData } from './contants';
import {
  ColumnData,
  DataTableMultiSortMetaType,
} from '../../components/Grid/interfaces';

interface IPartnersLazyParams extends ILazyParams {
  [key: string]: number | string | DataTableMultiSortMetaType | undefined;
  ptnNumber?: string;
  core?: string;
  name?: string;
  country?: string;
  state?: string;
  city?: string;
}

const pageTitle = 'List of Partners';

const PartnersList: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:partnersGridColumns';

  const lazyParamsName = '@SAT:partnersLazyParams';

  const advancedFiltersName = '@SAT:partnersAdvancedFilters';

  // ID da entity de usuario
  const { idEntity } = partnersListRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  // Permissions de Partners
  const { idPermissionDeletePartner } = partnersListRoles.permissions;

  const { idFieldCity, idFieldRegion } = partnersListRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, userPermissions.userFields).view;
  }
  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];
    columnList.push(gridColumnsData.ptnNumber);
    columnList.push(gridColumnsData.core);
    columnList.push(gridColumnsData.name);
    columnList.push(gridColumnsData.country);
    if (showField(idFieldRegion)) {
      columnList.push(gridColumnsData.state);
    }
    if (showField(idFieldCity)) {
      columnList.push(gridColumnsData.city);
    }

    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // URL params
  const location = useLocation();

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Referencia a grid
  const gridRef = useRef<DataTable<ColumnData[]>>(null);

  // Redirect
  const history = useHistory();

  // Armazena os parceiros selecionados
  const [selectedPartners, setSelectedPartners] = useState([]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );
  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
    ptnNumber: undefined,
    core: undefined,
    name: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IPartnersLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: IPartnersLazyParams) => {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: IPartnersLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  // Query para deletar parceiro
  const queryToDeletePartners = gql`
    mutation DeletePartnersMutation($partnersId: [Int]!) {
      deletePartners(partnersId: $partnersId)
    }
  `;

  // cria método para chamar a mutation
  const [deletePartners] = useMutation(queryToDeletePartners);

  // Busca parceiros
  const {
    loading: partnersLoading,
    data: partnersData,
    error: partnersError,
    refetch: partnersRefetch,
  } = useQuery(queryToListPartners, {
    variables: {
      listAllPartnersInput: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        globalSearch: lazyParams.globalFilter,
        ptnNumber: lazyParams.ptnNumber,
        core: lazyParams.core,
        name: lazyParams.name,
        country: lazyParams.country,
        state: showField(idFieldRegion) ? lazyParams.state : '',
        city: showField(idFieldCity) ? lazyParams.city : '',
        active: 1,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting partners',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Deleta parceiros selecionados
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedPartners.length} partners?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada parceiro
        const idsToDelete = selectedPartners.map((a: IPartner) => a.idPartner);
        try {
          // Deleta parceiros
          await deletePartners({
            variables: {
              partnersId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedPartners.length} partners`,
            life: ToastLife.SUCCESS,
          });

          // Zera estado de parceiros selecionadas
          setSelectedPartners([]);

          // Atualiza grid de parceiros
          await partnersRefetch();
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting partners',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Direciona usuario para pagina do partner clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/partners/list/${e.data.idPartner}`);
  }

  // Ao mudar pagina da tabela, muda os parametros de busca no backend
  // @param event Parametros de paginacao da tabela
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  // Ao fazer sort de alguma coluna, muda os parametros de busca no backend
  // @param event Parametros de sort da tabela
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function handleColumnSize(column: string) {
    switch (column) {
      case 'ptnNumber':
        return { width: '110px' };
      case 'coresString':
        return { width: '180px' };
      case 'name':
        return { width: '250px' };
      case 'idCountry2.name':
        return { width: '150px' };
      case 'idRegion2.name':
        return { width: '150px' };
      case 'idCity2.name':
        return { width: '150px' };
      default:
        return {
          width: '120px',
        };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map((col: any) => {
    return (
      <Column
        key={col.field}
        // Valida o body da coluna (caso precise ser um link)
        // body={handleColumn(col.field)}
        // body={col.field}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={handleColumnSize(col.field)}
        sortable={col.field !== 'coresString'}
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );
    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar parceiro */}
        <MainButton
          className="mainButton"
          label="New Partner"
          onClick={() => history.push('/partners/list/create')}
        />

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir parceiros selecionados */}
        {userHasPermission(
          idPermissionDeletePartner,
          userPermissions.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedPartners.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a partner"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="partners"
        lazy
        totalRecords={
          !partnersData || partnersError
            ? 0
            : partnersData.listAllPartners.items
        }
        value={
          !partnersData || partnersError
            ? undefined
            : partnersData.listAllPartners.data
        }
        globalFilter={globalFilter}
        emptyMessage="No partners found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedPartners}
        onSelectionChange={e => setSelectedPartners(e.value)}
      >
        {userHasPermission(
          idPermissionDeletePartner,
          userPermissions.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}
        {dynamicColumns}
      </Grid>
      {(pageLoading || partnersLoading) && <Loading />}
    </div>
  );
};

export default PartnersList;
