import { useLazyQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { SelectInstance } from 'react-select';
import FormTitle from '../../../../../../components/FormTitle';
import { GridActions } from '../../../../../../components/Grid/styles';
import MainButton from '../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { numberToPtString } from '../../../../../../utils/formatLocale';
import { parsePtBrNumberColumn } from '../../../../../../utils/gridColumnsParse';
import {
  ForeignTradeReducerAction,
  ForeignTradeReducerActionKind,
  SatForeignTrade,
  SelectedDivergentData,
} from '../../interfaces';
import { SatForeignTradeConsolidatedData } from './interfaces';
import {
  addSatForeignTradeToGroupQuery,
  asyncSelectLoadSatForeignTradesAvailable,
  listSatForeignTradesConsolidatedQuery,
  removeSatForeignTradeFromGroupQuery,
} from './queries';
import { gridConstants } from '../../../../../../components/Grid/constants';
import DivergentForeignTradesModal, {
  IDivergentForeignTradesModalRef,
} from '../../DivergentForeignTradesModal';
import { DivergentForeignTradesResponse } from '../../DivergentForeignTradesModal/interfaces';

interface IConsolidationProps {
  satForeignTrade: SatForeignTrade;
  selected: boolean;
  shipmentIsCanceled: boolean;
  foreignTradesDispatch: Dispatch<ForeignTradeReducerAction>;
}

const Consolidation: React.FC<IConsolidationProps> = ({
  satForeignTrade,
  selected,
  shipmentIsCanceled,
  foreignTradesDispatch,
}) => {
  const [selectedSatForeignTrade, setSelectedSatForeignTrade] = useState<{
    idSatForeignTrade: number;
    satForeignTradeNumber: string;
    realEtd?: string;
    realEta?: string;
    realArrivalAtClient?: string;
    idLoadingPort?: number;
    idLoadingPort2?: { name?: string };
    idPort?: number;
    idPort2?: { name?: string };
    bookingConfirmed?: string;
    shipmentConfirmed?: string;
    freightForwarderOrigin?: number;
    freightForwarderOrigin2?: { name?: string };
    freightForwarderDestination?: number;
    freightForwarderDestination2?: { name?: string };
  }>();

  const [satForeignTradeGroup, setSatForeignTradeGroup] = useState(
    satForeignTrade.idSatForeignTradeGroup,
  );

  const [itemBeingDeleted, setItemBeingDeleted] = useState<number>();

  const { toastRef, showWarn } = useRefHook();

  const divergentForeignTradesRef =
    useRef<IDivergentForeignTradesModalRef>(null);

  const selectRef = useRef<SelectInstance<any>>(null);

  const [
    addSatForeignTradeToGroupMutation,
    { loading: groupAddingInProgress },
  ] = useMutation(addSatForeignTradeToGroupQuery);

  const [removeSatForeignTradeFromGroupMutation] = useMutation(
    removeSatForeignTradeFromGroupQuery,
  );

  const [
    loadConsolidatedForeignTrades,
    {
      loading: consolidatedForeignTradesLoading,
      data: consolidatedForeignTradesData,
    },
  ] = useLazyQuery(listSatForeignTradesConsolidatedQuery, {
    variables: {
      idSatForeignTradeGroup: satForeignTradeGroup,
    },
    onCompleted: response => {
      if (response.listSatForeignTradesConsolidatedByIdSatForeignTradeGroup) {
        foreignTradesDispatch({
          type: ForeignTradeReducerActionKind.SET_CONSOLIDATION_DATA,
          payload: {
            consolidationData:
              response.listSatForeignTradesConsolidatedByIdSatForeignTradeGroup
                ?.satForeignTradesData,
          },
        });
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Foreign Trades Consolidated',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  async function handleRemoveForeignTradeFromGroup(idSatForeignTrade: number) {
    try {
      setItemBeingDeleted(idSatForeignTrade);

      await removeSatForeignTradeFromGroupMutation({
        variables: {
          idSatForeignTrade,
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'SAT Foreign Trade removed from consolidation',
        life: ToastLife.SUCCESS,
      });

      loadConsolidatedForeignTrades();
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while removing SAT Foreign Trade',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    } finally {
      setItemBeingDeleted(undefined);
    }
  }

  async function addForeignTradeToGroup(
    idSatForeignTradeToReplicateData: number,
  ) {
    const response = await addSatForeignTradeToGroupMutation({
      variables: {
        data: {
          idSatForeignTradeThatBelongsToTheGroup:
            satForeignTrade.idSatForeignTrade,
          idSatForeignTradeToAddToTheGroup:
            selectedSatForeignTrade?.idSatForeignTrade,
          idSatForeignTradeToReplicateData,
        },
      },
    });

    toastRef.current?.show({
      severity: 'success',
      summary: 'Foreign trade added to consolidation',
      life: ToastLife.SUCCESS,
    });

    setSatForeignTradeGroup(
      response.data.addSatForeignTradeToSameGroupAsTheOther,
    );

    selectRef.current?.clearValue();

    loadConsolidatedForeignTrades();
  }

  async function handleSubmitDivergentForeignTrades(
    e: SelectedDivergentData[],
  ) {
    if (!e.length) showWarn({ summary: 'Please select one option' });

    if (e[0].idSatForeignTrade) {
      await addForeignTradeToGroup(e[0].idSatForeignTrade);
    }
  }

  function checkDivergentConsolidatedData() {
    // Se nao houverem diferencas entre os valores de FT, retorna false
    const foreignTradesAreEqual =
      selectedSatForeignTrade &&
      satForeignTrade.realArrivalAtClient ===
        selectedSatForeignTrade.realArrivalAtClient &&
      satForeignTrade.realEta === selectedSatForeignTrade.realEta &&
      satForeignTrade.realEtd === selectedSatForeignTrade.realEtd &&
      satForeignTrade.idLoadingPort2?.idPort ===
        selectedSatForeignTrade.idPort &&
      satForeignTrade.idPort2?.idPort === selectedSatForeignTrade.idPort &&
      satForeignTrade.bookingConfirmed ===
        selectedSatForeignTrade.bookingConfirmed &&
      satForeignTrade.shipmentConfirmed ===
        selectedSatForeignTrade.shipmentConfirmed &&
      satForeignTrade.freightForwarderOrigin ===
        selectedSatForeignTrade.freightForwarderOrigin &&
      satForeignTrade.freightForwarderDestination ===
        selectedSatForeignTrade.freightForwarderDestination;

    if (!selectedSatForeignTrade || foreignTradesAreEqual) {
      return false;
    }

    const divergentData: DivergentForeignTradesResponse = {
      idForeignTradeSent: satForeignTrade.idSatForeignTrade,
      foreignTradeSentNumber: satForeignTrade.satForeignTradeNumber,
      divergentData: [
        {
          idSatForeignTrade: satForeignTrade.idSatForeignTrade,
          satForeignTradeNumber: satForeignTrade.satForeignTradeNumber,
          realArrivalAtClient: satForeignTrade.realArrivalAtClient,
          realEta: satForeignTrade.realEta,
          realEtd: satForeignTrade.realEtd,
          idLoadingPort: satForeignTrade.idLoadingPort2?.idPort,
          loadingPortName: satForeignTrade.idLoadingPort2?.name,
          idPort: satForeignTrade.idPort2?.idPort,
          portName: satForeignTrade.idPort2?.name,
          bookingConfirmed: satForeignTrade.bookingConfirmed,
          shipmentConfirmed: satForeignTrade.shipmentConfirmed,
          freightForwarderOrigin: satForeignTrade.freightForwarderOrigin,
          freightForwarderOriginName:
            satForeignTrade.freightForwarderOrigin2?.name,
          freightForwarderDestination:
            satForeignTrade.freightForwarderDestination,
          freightForwarderDestinationName:
            satForeignTrade.freightForwarderDestination2?.name,
        },
        {
          idSatForeignTrade: selectedSatForeignTrade.idSatForeignTrade,
          satForeignTradeNumber: selectedSatForeignTrade.satForeignTradeNumber,
          realArrivalAtClient: selectedSatForeignTrade.realArrivalAtClient,
          realEta: selectedSatForeignTrade.realEta,
          realEtd: selectedSatForeignTrade.realEtd,
          idLoadingPort: selectedSatForeignTrade.idLoadingPort,
          loadingPortName: selectedSatForeignTrade.idLoadingPort2?.name,
          idPort: selectedSatForeignTrade.idPort,
          portName: selectedSatForeignTrade.idPort2?.name,
          bookingConfirmed: selectedSatForeignTrade.bookingConfirmed,
          shipmentConfirmed: selectedSatForeignTrade.shipmentConfirmed,
          freightForwarderOrigin:
            selectedSatForeignTrade.freightForwarderOrigin,
          freightForwarderOriginName:
            selectedSatForeignTrade.freightForwarderOrigin2?.name,
          freightForwarderDestination:
            selectedSatForeignTrade.freightForwarderDestination,
          freightForwarderDestinationName:
            selectedSatForeignTrade.freightForwarderDestination2?.name,
        },
      ],
    };

    divergentForeignTradesRef.current?.toggle([divergentData]);

    return true;
  }

  const gridActions = (rowData: SatForeignTradeConsolidatedData) => {
    if (rowData.idSatForeignTrade === satForeignTrade.idSatForeignTrade) {
      return undefined;
    }

    const isBeingDeleted = itemBeingDeleted === rowData.idSatForeignTrade;

    return (
      <GridActions>
        <button
          type="button"
          className="trash-button"
          onClick={() =>
            confirmDialog({
              message: 'Are you sure you want to delete this item?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () =>
                handleRemoveForeignTradeFromGroup(rowData.idSatForeignTrade),
            })
          }
          disabled={!!itemBeingDeleted}
        >
          {isBeingDeleted ? (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: '1.43rem' }}
            />
          ) : (
            <i className="pi pi-trash" style={{ fontSize: '1.25rem' }} />
          )}
        </button>
      </GridActions>
    );
  };

  async function checkForApiIssuesBeforeSave() {
    const divergentData = checkDivergentConsolidatedData();

    return divergentData;
  }

  async function handleAddForeignTradeToGroup() {
    try {
      const apiIssues = await checkForApiIssuesBeforeSave();

      if (apiIssues) return;

      await addForeignTradeToGroup(satForeignTrade.idSatForeignTrade);
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while adding SAT Foreign Trade to consolidation',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    }
  }

  useEffect(() => {
    if (selected && satForeignTradeGroup && !consolidatedForeignTradesData) {
      loadConsolidatedForeignTrades();
    }
  }, [
    consolidatedForeignTradesData,
    loadConsolidatedForeignTrades,
    satForeignTradeGroup,
    selected,
  ]);

  return (
    <div className="form-group grid">
      <FormTitle className="col-12" name="Consolidation" />

      {!shipmentIsCanceled && (
        <div className="flex col-12 align-items-center">
          <AsyncPaginate
            selectRef={selectRef}
            className="mr-2"
            name="satShipment"
            loadOptions={asyncSelectLoadSatForeignTradesAvailable}
            getOptionLabel={option => option.satForeignTradeNumber}
            getOptionValue={option => option.idSatForeignTrade}
            additional={{
              idSatForeignTradeToExclude: satForeignTrade.idSatForeignTrade,
              page: 1,
            }}
            onChange={e => setSelectedSatForeignTrade(e)}
            noOptionsMessage={() => 'No shipments found'}
            cacheUniqs={[consolidatedForeignTradesData]}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 2 }),
              container: base => ({
                ...base,
                width: '278px',
              }),
            }}
            menuPosition="fixed"
            isDisabled={groupAddingInProgress}
            placeholder="Select a SAT Shipment"
            debounceTimeout={1000}
          />

          <MainButton
            label="Add"
            type="button"
            icon={groupAddingInProgress ? 'pi pi-spin pi-spinner' : undefined}
            onClick={() => handleAddForeignTradeToGroup()}
            disabled={!selectedSatForeignTrade || groupAddingInProgress}
          />
        </div>
      )}

      <DataTable
        className="col-7"
        value={
          consolidatedForeignTradesData
            ?.listSatForeignTradesConsolidatedByIdSatForeignTradeGroup
            .satForeignTradesData
        }
        loading={consolidatedForeignTradesLoading}
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
      >
        {!shipmentIsCanceled && (
          <Column
            field="actions"
            headerStyle={{ width: '4rem' }}
            body={gridActions}
          />
        )}

        <Column header="SAT Shipment" field="satForeignTradeNumber" />
        <Column header="Qty / Type of Ctnr" field="qtyAndTypeOfCtnr" />
        <Column
          header="Qty CI"
          field="qtyCi"
          body={parsePtBrNumberColumn}
          footer={numberToPtString(
            consolidatedForeignTradesData
              ?.listSatForeignTradesConsolidatedByIdSatForeignTradeGroup
              .totalQtyCi,
          )}
        />
        <Column
          header="CBM CI"
          field="cbmCi"
          body={parsePtBrNumberColumn}
          footer={numberToPtString(
            consolidatedForeignTradesData
              ?.listSatForeignTradesConsolidatedByIdSatForeignTradeGroup
              .totalCbmCi,
          )}
        />
      </DataTable>

      <DivergentForeignTradesModal
        ref={divergentForeignTradesRef}
        onSubmit={e => handleSubmitDivergentForeignTrades(e)}
        getDataMode="addGroup"
      />
    </div>
  );
};

export default Consolidation;
