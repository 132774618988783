import hotkeys from 'hotkeys-js';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { SelectInstance } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { asyncSelectLoadSearchAllOptions } from './searchAll';
import { Container } from './styles';
import { EntityType } from '../../../shared/enums/entity';

interface ISearchAllResult {
  idEntity: number;
  id: number;
}

const SearchAll: React.FC = () => {
  // Referencia do Search all
  const selectRef = useRef<SelectInstance<any, boolean, any>>(null);

  // Redirect
  const history = useHistory();

  function getItemUrl(idEntity: number, idItem: number) {
    switch (idEntity) {
      case EntityType.PRODUCT:
        return `/products/list/${idItem}`;
      case EntityType.SUPPLIER:
        return `/suppliers/list/${idItem}`;
      case EntityType.SAT:
        return `/commercial/sats/${idItem}`;
      case EntityType.CLIENT_QUOTATION:
        return `/client-quotations/list/${idItem}`;
      case EntityType.PRC:
        return `/prcs/list/${idItem}`;
      case EntityType.CLIENT:
        return `/clients/list/${idItem}`;
      case EntityType.PARTNER:
        return `/partners/list/${idItem}`;
      default:
        return undefined;
    }
  }

  function changeSelectedData(e: ISearchAllResult) {
    if (e) {
      selectRef.current?.blurInput();
      const itemUrl = getItemUrl(e.idEntity, e.id);
      if (itemUrl) history.push(itemUrl);
    }
  }

  // Captura atalho do teclado
  hotkeys('ctrl+l', function getField(event) {
    // Previne conportamento padrão do atalho
    event.preventDefault();
    // Busca o input de busca
    selectRef.current?.focusInput();
  });

  return (
    <Container>
      <AsyncPaginate
        selectRef={selectRef}
        name="searchAll"
        className="searchAll"
        loadOptions={asyncSelectLoadSearchAllOptions}
        getOptionLabel={option => option.description}
        additional={{ page: 1 }}
        noOptionsMessage={() => 'No results found'}
        placeholder="Search for Products, SATs and Quotations... (Ctrl+L)"
        debounceTimeout={1000}
        isClearable
        openMenuOnFocus
        onChange={e => changeSelectedData(e)}
        value={null}
      />
    </Container>
  );
};

export default SearchAll;
