import { DocumentNode, gql } from '@apollo/client';
import { SatListDesignPermissions } from './interfaces';

export const listSatListDesignViewQuery = (
  fieldsPermissions: SatListDesignPermissions,
): DocumentNode => {
  const fields = Object.keys(fieldsPermissions).filter(
    key => fieldsPermissions[key].view,
  );
  const fieldsString = fields.join('\n');

  return gql`
    query ListSatListDesignView($data: ListSatListDesignViewInput!) {
      listSatListDesignView(data: $data) {
        items
        data {
          idSat
          ${fieldsPermissions.importerConsignee.view ? 'idImporter' : ''}
          ${fieldsPermissions.clientNotify.view ? 'idClient' : ''}
          ${fieldsPermissions.status.view ? 'statusSat' : ''}
          ${fieldsString}
        }
      }
    }
  `;
};

/**
 * Busca os somatorios das colunas da lista de Design para adicionar no footer
 */
export const getSumDataFromSatDesignListQuery = gql`
  query GetSumDataFromSatDesignList($data: ListSatListDesignViewFieldsSearch!) {
    getSumDataFromSatDesignList(data: $data) {
      satCount
      artworkQuantity
    }
  }
`;
