import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { TabPanel, TabView } from 'primereact/tabview';
import dayjs from 'dayjs';
import { Tooltip } from 'primereact/tooltip';
import useTitle from '../../../hooks/useTitle';
import { useRefHook } from '../../../hooks/useRefHook';
import {
  listAllCompaniesQuery,
  listAllCurrenciesInFinancialAccountsQuery,
} from './queries';
import PageHeader from '../../../components/PageHeader';
import Loading from '../../../components/Loading';
import { compareIfBefore } from '../../../utils/dateUtil';
import { CurrencyCard } from './styles';
import { renderNumber } from '../../../utils/formatLocale';
import { ICompany, ICurrency } from './interfaces';
import CompanyContent from './CompanyContent';
import { CurrencyAbbreviation } from '../../../shared/enums/currencyAbbreviation';

const Cashflow: React.FC = () => {
  const pageTitle = 'Cashflow';
  useTitle(pageTitle);

  const { showError } = useRefHook();

  const [currencies, setCurrencies] = useState<ICurrency[]>();

  const [currentCompanyTabIndex, setCurrentCompanyTabIndex] =
    useState<number>(0);

  const { loading: currenciesLoading } = useQuery(
    listAllCurrenciesInFinancialAccountsQuery,
    {
      onCompleted: response => {
        if (response?.listAllCurrenciesInFinancialAccounts) {
          setCurrencies(response?.listAllCurrenciesInFinancialAccounts);
        }
      },
      onError: errorData => {
        showError({
          summary: `Error while getting currencies data`,
          detail: errorData.message,
        });
      },
    },
  );

  const { loading: companiesLoading, data: companiesData } = useQuery(
    listAllCompaniesQuery,
    {
      variables: {
        data: {
          pagination: {
            _page: 0,
            _limit: 0,
          },
        },
      },
      onError: errorData => {
        showError({
          summary: `Error while getting companies data`,
          detail: errorData.message,
        });
      },
    },
  );

  // Cria a string de data do Exchange Rate
  function renderOldestExchangeRateDate() {
    const exchangeRateDates = currencies?.map(
      currency => currency.usdExchangeRateUpdatedAt,
    );

    const oldestDate = exchangeRateDates?.reduce((prev, curr) => {
      return compareIfBefore(prev, curr) ? curr : prev;
    }, new Date());

    return dayjs(oldestDate).format('DD MMMM / dddd YYYY');
  }

  return (
    <div>
      <PageHeader title={pageTitle} />

      {currencies?.length && !currenciesLoading && (
        <div className="flex flex-column mt-6 ml-6">
          <h2>Conversão Dólar ({renderOldestExchangeRateDate()})</h2>

          <span className="flex gap-2 py-3">
            {currencies.map((currency: ICurrency) => {
              if (currency.idCurrency === CurrencyAbbreviation.USD) return '';

              const currencyWithAllDecimalPlaces =
                currency.usdExchangeRate.toLocaleString('pt', {
                  maximumFractionDigits: 7,
                });

              return (
                <>
                  <Tooltip target={`.s-currency-card-${currency.idCurrency}`} />
                  <CurrencyCard
                    className={`s-currency-card-${currency.idCurrency} cursor-pointer hover:surface-50`}
                    key={currency.idCurrency}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        currencyWithAllDecimalPlaces,
                      )
                    }
                    data-pr-tooltip={currencyWithAllDecimalPlaces}
                    data-pr-position="bottom"
                  >
                    <p className="text-2xl">
                      {renderNumber(
                        currency.usdExchangeRate,
                        'currency',
                        currency.abbreviation,
                      )}
                    </p>
                    <p className="text-xl">{currency.abbreviation}</p>
                  </CurrencyCard>
                </>
              );
            })}
          </span>

          <TabView
            activeIndex={currentCompanyTabIndex}
            onTabChange={e => setCurrentCompanyTabIndex(e.index)}
            renderActiveOnly={false}
            scrollable
          >
            {companiesData?.listAllCompanies?.data?.map(
              (company: ICompany, index: number) => {
                return (
                  <TabPanel
                    key={company.idCompany.toString()}
                    header={company.name}
                  >
                    <CompanyContent
                      idCompany={company.idCompany}
                      companyName={company.name}
                      selected={currentCompanyTabIndex === index}
                    />
                  </TabPanel>
                );
              },
            )}
          </TabView>
        </div>
      )}

      {currenciesLoading && companiesLoading && <Loading />}
    </div>
  );
};

export default Cashflow;
