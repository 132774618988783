/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import FormInputTextArea from '../../../components/FormInputTextArea';
import { IAirFreightTaxFormData, AirFreightTaxRouteParams } from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import ToastLife from '../../../shared/enums/toastLife';
import { airFreightTaxRoles } from '../../../shared/roles/airFreightTax';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';

const defaultPageTitle = 'Manage Air Freight Tax';

const AirFreightTax: React.FC = () => {
  useTitle(defaultPageTitle);

  // Redirect
  const history = useHistory();

  // ID de air freight tax
  const idAirFreightTax = parseInt(
    useParams<AirFreightTaxRouteParams>().idAirFreightTax,
    10,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Estado de bcCif
  const [bcCif, setBcCif] = useState<boolean>();

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsAirFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    airFreightTaxRoles.idEntity,
  );

  // Permissions de Air Freight Tax
  const { idPermissionUpdateAirFreightTax } = airFreightTaxRoles.permissions;

  // Valida se usuario pode atualizar Air Freight Tax
  const userCanChange = userHasPermission(
    idPermissionUpdateAirFreightTax,
    userPermissionsAirFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Air Freight Taxes',
      path:
        initialPage && initialFirst
          ? `/logistics/airFreightTaxes?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/airFreightTaxes',
    },
    {
      name: 'Manage Air Freight Tax',
      path: pathname,
    },
  ];

  // Query para listar air freight tax
  const listAirFreightTaxQuery = gql`
    query listLogisticAirFreightTaxById($idAirFreightTax: Int!) {
      listLogisticAirFreightTaxById(idAirFreightTax: $idAirFreightTax) {
        idAirFreightTax
        idTaxName2 {
          description
        }
        taxDescription
        taxValue
        idCurTax2 {
          idCurrency
          abbreviation
        }
        idBcValue2 {
          idDomain
          description
        }
        bcCif
        versionLock
        createdAt
        createdBy2 {
          firstName
          lastName
        }
        updatedAt
        updatedBy2 {
          firstName
          lastName
        }
      }
    }
  `;

  /**
   * Busca dados de Air Freight Tax
   */
  const [
    loadAirFreightTaxData,
    { loading: airFreightTaxLoading, data: airFreightTaxData },
  ] = useLazyQuery(listAirFreightTaxQuery, {
    variables: {
      idAirFreightTax,
    },
    onCompleted: response => {
      if (response.listLogisticAirFreightTaxById) {
        setBcCif(response.listLogisticAirFreightTaxById.bcCif);
      } else {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Air Freight Tax not found',
          life: ToastLife.ERROR,
        });
      }
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting air freight tax data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/airFreightTaxes'),
    });
  }

  // Mutation de atualizacao de air freight tax
  const updateAirFreightTaxQuery = gql`
    mutation UpdateAirFreightTaxMutation(
      $data: UpdateLogisticsAirFreightTaxInput!
    ) {
      updateLogisticsAirFreightTax(data: $data) {
        idAirFreightTax
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [updateAirFreightTaxMutation] = useMutation(updateAirFreightTaxQuery);

  // Submit do formulario de air freight
  const handleAirFreightSubmit = useCallback(
    async (data: IAirFreightTaxFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          idCurTax: Yup.string().nullable().required('Required Field'),
          idBcValue: Yup.string().nullable().required('Required Field'),
          taxValue: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Chamada atualizacao de air freight tax
        await updateAirFreightTaxMutation({
          variables: {
            data: {
              idAirFreightTax,
              versionLock:
                airFreightTaxData.listLogisticAirFreightTaxById?.versionLock,
              idCurTax: data.idCurTax,
              idBcValue: data.idBcValue,
              taxValue: data.taxValue,
              bcCif,
            },
          },
        });

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'Air Freight tax updated',
          life: ToastLife.SUCCESS,
        });

        // Atualiza dados
        loadAirFreightTaxData();
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating air freight tax',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      bcCif,
      formRef,
      idAirFreightTax,
      loadAirFreightTaxData,
      airFreightTaxData,
      toastRef,
      updateAirFreightTaxMutation,
    ],
  );

  // Carrega dados de air freight tax ao montar a tela
  useEffect(() => {
    loadAirFreightTaxData();
  }, [loadAirFreightTaxData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader title={defaultPageTitle} minScrollStickyButtons={160}>
        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />

        <p className="createdAt">
          {airFreightTaxData && !airFreightTaxLoading
            ? `${new Date(
                airFreightTaxData?.listLogisticAirFreightTaxById?.createdAt,
              ).toLocaleString()} by ${
                airFreightTaxData?.listLogisticAirFreightTaxById?.createdBy2
                  ?.firstName
              } ${
                airFreightTaxData?.listLogisticAirFreightTaxById?.createdBy2
                  ?.lastName
              }`
            : ''}
        </p>
        <p className="updatedAt">
          {airFreightTaxData && !airFreightTaxLoading
            ? `${new Date(
                airFreightTaxData?.listLogisticAirFreightTaxById?.updatedAt ??
                  airFreightTaxData?.listLogisticAirFreightTaxById?.createdAt,
              ).toLocaleString()} by ${
                airFreightTaxData?.listLogisticAirFreightTaxById.updatedBy2
                  ?.firstName ??
                airFreightTaxData?.listLogisticAirFreightTaxById.createdBy2
                  ?.firstName
              } ${
                airFreightTaxData?.listLogisticAirFreightTaxById.updatedBy2
                  ?.lastName ??
                airFreightTaxData?.listLogisticAirFreightTaxById.createdBy2
                  ?.lastName
              }`
            : ''}
        </p>
      </PageHeader>

      {/* Formulario */}
      {!airFreightTaxLoading &&
        airFreightTaxData?.listLogisticAirFreightTaxById?.idAirFreightTax && (
          <CrudForm>
            <Form
              ref={formRef}
              onSubmit={handleAirFreightSubmit}
              initialData={airFreightTaxData.listLogisticAirFreightTaxById}
            >
              <Row>
                <FormInput
                  name="taxName"
                  label="Tax Name"
                  defaultValue={
                    airFreightTaxData?.listLogisticAirFreightTaxById.idTaxName2
                      ?.description
                  }
                  readOnly
                />
              </Row>

              <FormInputTextArea
                name="taxDescription"
                className="text-area"
                label="Description"
                defaultValue={
                  airFreightTaxData?.listLogisticAirFreightTaxById
                    .taxDescription
                }
                readOnly
              />

              <Row>
                <FormInputNumber
                  required
                  name="taxValue"
                  label="Air Freight Tax Value"
                  decimalScale={2}
                  min={0}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />
              </Row>

              <Row>
                <FormAsyncSelect
                  name="idCurTax"
                  className="currency-dropdown"
                  label="Currency"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.abbreviation}
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={
                    airFreightTaxData.listLogisticAirFreightTaxById.idCurTax2
                  }
                  readOnly={!userCanChange}
                  required
                />
              </Row>

              <Row>
                <FormAsyncSelect
                  name="idBcValue"
                  className="generalInput"
                  label="BC Value"
                  required
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: 29,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={
                    airFreightTaxData?.listLogisticAirFreightTaxById.idBcValue2
                  }
                  readOnly={!userCanChange}
                />
              </Row>

              <Row>
                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="airFreight.bcCif"
                      value
                      checked={bcCif === true}
                      onChange={() => setBcCif(true)}
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="airFreight.bcCif"
                      value={false}
                      checked={bcCif !== true}
                      onChange={() => setBcCif(false)}
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
            </Form>
          </CrudForm>
        )}
      {(airFreightTaxLoading || loading) && <Loading />}
    </Container>
  );
};

export default AirFreightTax;
