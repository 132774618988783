/* eslint-disable no-shadow */

/**
 * Partner Cores
 */
export enum PartnerCores {
  IND_INLAND_TRANSPORTS_ORIGIN = 'Inland Transports Origin',
  IND_INLAND_TRANSPORTS_DESTINATION = 'Inland Transports Destination',
  IND_FREIGHT_FORWARDER_ORIGIN = 'Freight Forwarder Origin',
  IND_FREIGHT_FORWARDER_DESTINATION = 'Freight Forwarder Destination',
  IND_CUSTOMS_BROKER = 'Customs Broker',
  IND_AUDIT = 'Audit',
  IND_WAREHOUSE = 'Warehouse',
  IND_INSURANCE_COMPANY = 'Insurance Company',
  IND_REPRESENTATIVE = 'Representative',
  IND_FINANCIAL_SERVICES = 'Financial Services',
  IND_SHIPOWNER = 'Shipowner',
  IND_COURIER = 'Courier',
  IND_CERTIFYING_ENTITY = 'Certifying Entity',
  IND_LICENSOR = 'Licensor',
  IND_OTHERS = 'Others',
}
