import styled from 'styled-components';

export const BalanceCard = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 18em;
  height: 25em;
  padding: 1em;
  gap: 2.5em;
`;
