import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { InputText } from 'primereact/inputtext';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { sortBy } from 'lodash';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Skeleton } from 'primereact/skeleton';
import useTitle from '../../../hooks/useTitle';
import PageHeader from '../../../components/PageHeader';
import { expandOrCollapseHeader } from '../../../services/layoutsDefinitions';
import Grid, { GridRef } from '../../../components/Grid';
import {
  SatListExportPermissions,
  SatListExportView,
  SatListExportViewLazyParams,
} from './interfaces';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import {
  exportListQuery,
  getSumDataFromSatExportListQuery,
  listSatListExportViewQuery,
} from './queries';
import { useRefHook } from '../../../hooks/useRefHook';
import { gridColumnsData, groupedColumns } from './contants';
import { ColumnData } from '../../../components/Grid/interfaces';
import Loading from '../../../components/Loading';
import Empty from '../../../components/Empty';
import SatStatusTag from '../../../components/SatStatusTag';
import {
  parseCurrencyPtBrNumberColumn,
  parseDateColumm,
  parseDateTimeColumm,
  parseNullableBooleanColumn,
  parsePercentageColumnPt,
  parsePtBrNumberColumn,
  parseSatColumn,
} from '../../../utils/gridColumnsParse';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { satsExportRoles } from '../../../shared/roles/satsExportRoles';
import getFieldPermission from '../../../utils/getFieldPermission';
import Button from '../../../components/Button';
import userHasPermission from '../../../utils/userHasPermission';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import MultiSelectGroup, {
  MultiSelectGroupData,
} from '../../../components/Grid/MultiSelectGroup';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';
import { renderNumber } from '../../../utils/formatLocale';

const pageTitle = 'Export List';

const gridColumnsName = '@SAT:satControlsExportGridColumns';

const lazyParamsName = '@SAT:satControlsExportLazyParams';

const advancedFiltersName = '@SAT:satControlsExportAdvancedFilters';

// Key das opcoes de coluna que resetam preferencias da grid
const gridResetOptionKey = 'preview-full';

const Export: React.FC = () => {
  useTitle(pageTitle);

  const { showError } = useRefHook();
  const gridRef = useRef<GridRef<SatListExportView[]>>(null);
  const isMounted = useRef(false);
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const history = useHistory();

  const { roles } = useAuth();

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalSearch || '',
  );
  const [exportView, setExportView] = useState<[SatListExportView[], number]>([
    [],
    0,
  ]);
  const [lazyParams, setLazyParams] = useState<SatListExportViewLazyParams>(
    localStorageLazyParams || pagination.initialLazyParams,
  );

  const [hasFilterApplied, setHasFilterApplied] = useState(false);
  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(
      roles.rolesUser,
      satsExportRoles.idEntity,
    );
  }, [roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        satsExportRoles.permissions.idPermissionExportSatsExport,
        userPermissions,
      ),
    };
  }, [userPermissions]);

  /**
   * Permissao do usuario para cada coluna da grid
   */
  const fieldsPermissions: SatListExportPermissions = useMemo(() => {
    const fields = {} as SatListExportPermissions;

    Object.entries(satsExportRoles.fields).forEach(([key, value]) => {
      fields[key as keyof SatListExportPermissions] = getFieldPermission(
        value,
        userFields,
      );
    });

    return fields;
  }, [userFields]);

  /**
   * Colunas da grid que o usuario tem permissao de visualizar
   */
  const columns: ColumnData[] = useMemo(() => {
    return Object.values(gridColumnsData).reduce((acc, value) => {
      if (
        fieldsPermissions[value.field as keyof SatListExportPermissions].view
      ) {
        acc.push(value);
      }
      return acc;
    }, [] as ColumnData[]);
  }, [fieldsPermissions]);

  const [selectedColumns, setSelectedColumns] = useState<MultiSelectGroupData>({
    columns: columns.filter(column =>
      groupedColumns[0]?.children?.[0]?.data?.includes(column.field),
    ),
    treeSelectedData: {
      0: {
        checked: false,
        partialChecked: true,
      },
      'preview-resume': {
        checked: true,
        partialChecked: false,
      },
    },
  });

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key =>
          lazyParams[key as keyof SatListExportViewLazyParams] &&
          key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const exportCommonVariables = {
    allColumns: lazyParams.globalSearch,
    idImportStatus: lazyParams.idImportStatus,
    idRegisterStatus: lazyParams.idRegisterStatus,
    idStatusArtwork: lazyParams.idStatusArtwork,
    idProductionStatus: lazyParams.idProductionStatus,
    idShipmentStatus: lazyParams.idShipmentStatus,
    idDocumentsStatus: lazyParams.idDocumentsStatus,
    idStatusPurchasePayment: lazyParams.idStatusPurchasePayment,
    idStatusSellingPayment: lazyParams.idStatusSellingPayment,
    idExportStatus: lazyParams.idExportStatus,
    shipmentResume: lazyParams.shipmentResume,
    idSatForeignTrade: lazyParams.idSatForeignTrade,
    status: lazyParams.status,
    idSat: lazyParams.idSat,
    satCreatedAt: lazyParams.satCreatedAt,
    confirmOrderPurchase: lazyParams.confirmOrderPurchase,
    idTypeOfOrder: lazyParams.idTypeOfOrder,
    idSupplierExporter: lazyParams.idSupplierExporter,
    manufacturer: lazyParams.manufacturer,
    idCountryOrigin: lazyParams.idCountryOrigin,
    idClient: lazyParams.idClient,
    mainProduct: lazyParams.mainProduct,
    category: lazyParams.category,
    sentFinalArtWork: lazyParams.sentFinalArtWork,
    sketchesReceived: lazyParams.sketchesReceived,
    artworkDone: lazyParams.artworkDone,
    packagesNotes: lazyParams.packagesNotes,
    estimatedInspectionDate: lazyParams.estimatedInspectionDate,
    realInspectionDate: lazyParams.realInspectionDate,
    inspectionScheduled: lazyParams.inspectionScheduled,
    idRework: lazyParams.idRework,
    inspectionApproval: lazyParams.inspectionApproval,
    estimatedShipmentDate: lazyParams.estimatedShipmentDate,
    realEtd: lazyParams.realEtd,
    idLoadingPort: lazyParams.idLoadingPort,
    realEta: lazyParams.realEta,
    idPort: lazyParams.idPort,
    idPurchaseIncoterm: lazyParams.idPurchaseIncoterm,
    shipmentAuthorized: lazyParams.shipmentAuthorized,
    bookingConfirmed: lazyParams.bookingConfirmed,
    shipmentConfirmed: lazyParams.shipmentConfirmed,
    trakingNumber: lazyParams.trakingNumber,
    idClientIncoterm: lazyParams.idClientIncoterm,
    idPurchaseUser: lazyParams.idPurchaseUser,
    idComexExportUser: lazyParams.idComexExportUser,
    idComexImportUser: lazyParams.idComexImportUser,
    idDesignerUser: lazyParams.idDesignerUser,
    idFinancialUser: lazyParams.idFinancialUser,
    shipmentIdSupplier: lazyParams.shipmentIdSupplier,
  };

  /**
   * Retorna coluna de Shipment/Foreign Trade com link para pagina de SAT
   * @param rowData Dados da linha
   * @returns Coluna de Shipment/Foreign Trade com link para pagina de SAT
   */
  const parseShipmentColumn = (rowData: SatListExportView) => {
    if (rowData.idSat && rowData.idSatForeignTrade && rowData.shipment) {
      return (
        <Link
          to={`/commercial/sats/${rowData.idSat}?tab=foreignTrade&idSatForeignTrade=${rowData.idSatForeignTrade}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.shipment}
          <FiExternalLink />
        </Link>
      );
    }
    return rowData.shipment;
  };

  function expandCollapsePageHeader() {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  }

  function saveLazyParams(newLazyParams: SatListExportViewLazyParams) {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }

  function onPage(event: DataTablePageEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          first: event.first,
          rows: event.rows,
          page: event.page ?? current.page,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
      gridRef.current?.resetScroll();
    }
  }

  function onSort(event: DataTableSortEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          multiSortMeta: event.multiSortMeta,
          sortField: event.sortField,
          sortOrder: event.sortOrder,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
    }
  }

  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/commercial/sats/${e.data.idSat}`);
  }

  function onColumnToggle(event: MultiSelectGroupData) {
    const orderedSelectedColumns = columns.filter(col =>
      event.columns.some((sCol: { field: string }) => sCol.field === col.field),
    );

    const finalObject = {
      columns: orderedSelectedColumns,
      treeSelectedData: event.treeSelectedData,
    };

    // Salva colunas selecionadas no local storage
    localStorage.setItem(gridColumnsName, JSON.stringify(finalObject));
    setSelectedColumns(finalObject);

    updateLocalStorageInDb(gridColumnsName, finalObject);
  }

  function renderColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}

        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function setColumnSize(column: string) {
    switch (column) {
      case gridColumnsData.transitTime.field:
      case gridColumnsData.statusSat.field:
        return { width: '150px' };
      case gridColumnsData.countryOfOrigin.field:
      case gridColumnsData.sentFinalArtWork.field:
      case gridColumnsData.sketchesReceived.field:
      case gridColumnsData.portDischarge.field:
      case gridColumnsData.purchaseIncoterm.field:
      case gridColumnsData.bookingConfirmed.field:
        return { width: '200px' };
      case gridColumnsData.realInspectionDate.field:
      case gridColumnsData.inspectionScheduled.field:
      case gridColumnsData.inspectionApproval.field:
      case gridColumnsData.purchaseTotalCotPi.field:
      case gridColumnsData.purchaseTotalCotCi.field:
      case gridColumnsData.realProductionTime.field:
      case gridColumnsData.authorizationTiming.field:
      case gridColumnsData.preShipmentTiming.field:
      case gridColumnsData.estimatedGrossMargin.field:
      case gridColumnsData.estimatedNetMargin.field:
      case gridColumnsData.estimatedNetProfit.field:
      case gridColumnsData.originalDocsTiming.field:
        return { width: '210px' };
      case gridColumnsData.deliveryAtClientTiming.field:
      case gridColumnsData.totalForeignTradeTiming.field:
      case gridColumnsData.estimatedShipmentDate.field:
      case gridColumnsData.confirmOrderPurchase.field:
      case gridColumnsData.estimatedInspectionDate.field:
      case gridColumnsData.authorizationRequested.field:
      case gridColumnsData.shipmentAuthorized.field:
      case gridColumnsData.shipmentConfirmed.field:
      case gridColumnsData.freightForwarderOrigin.field:
      case gridColumnsData.purchasePaidAdvance.field:
      case gridColumnsData.purchasePaidBalance.field:
      case gridColumnsData.startProductionTiming.field:
      case gridColumnsData.bookingShipmentTiming.field:
        return { width: '240px' };
      case gridColumnsData.authorizationBookingTiming.field:
      case gridColumnsData.freightForwarderDestination.field:
        return { width: '250px' };
      case gridColumnsData.authorizationShipmentTiming.field:
        return { width: '260px' };
      case gridColumnsData.purchasePaymentTermCondition.field:
      case gridColumnsData.category.field:
        return { width: '300px' };
      default:
        return { width: '180px' };
    }
  }

  const parseExporterColumn = (rowData: SatListExportView) => {
    return (
      rowData.idSupplierExporter && (
        <Link
          to={`/suppliers/list/${rowData.idSupplierExporter}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.exporter}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  const parseClientNotifyColumn = (rowData: SatListExportView) => {
    return (
      rowData.idClient && (
        <Link
          to={`/clients/list/${rowData.idClient}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.clientNotify}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  const parsePartnerColumn = (
    rowData: SatListExportView,
    column: ColumnBodyOptions,
    idField: string,
  ) => {
    const fieldValue = rowData[column.field as keyof SatListExportView];
    const idFieldValue = rowData[idField as keyof SatListExportView];

    return (
      idFieldValue && (
        <Link
          to={`/partners/list/${idFieldValue}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {fieldValue}
          <span>
            <FiExternalLink />
          </span>
        </Link>
      )
    );
  };

  const parseUpdatesEnColumn = (rowData: SatListExportView) => {
    return (
      <Link
        to={`/commercial/sats/${rowData.idSat}?tab=updates`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        Open updates
        <span>
          <FiExternalLink />
        </span>
      </Link>
    );
  };

  const parseSunNumberColumn = (rowData: SatListExportView) => {
    return (
      <Link
        to={`/suppliers/list/${rowData.shipmentIdSupplier}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.shipmentSunNumber}
        <FiExternalLink />
      </Link>
    );
  };

  function renderColumn(rowData: SatListExportView, column: ColumnBodyOptions) {
    switch (column.field) {
      case gridColumnsData.shipment.field:
        return parseShipmentColumn(rowData);
      case gridColumnsData.statusSat.field:
        return <SatStatusTag status={rowData.status} />;
      case gridColumnsData.satNumber.field:
        return parseSatColumn(rowData, column);
      case gridColumnsData.satCreatedAt.field:
      case gridColumnsData.confirmOrderPurchase.field:
        return parseDateTimeColumm(rowData, column);
      case gridColumnsData.exporter.field:
        return parseExporterColumn(rowData);
      case gridColumnsData.clientNotify.field:
        return parseClientNotifyColumn(rowData);
      case gridColumnsData.sentFinalArtWork.field:
      case gridColumnsData.sketchesReceived.field:
      case gridColumnsData.artworkDone.field:
      case gridColumnsData.estimatedInspectionDate.field:
      case gridColumnsData.realInspectionDate.field:
      case gridColumnsData.inspectionScheduled.field:
      case gridColumnsData.reworkDate.field:
      case gridColumnsData.inspectionApproval.field:
      case gridColumnsData.estimatedShipmentDate.field:
      case gridColumnsData.realEtd.field:
      case gridColumnsData.realEta.field:
      case gridColumnsData.authorizationRequested.field:
      case gridColumnsData.shipmentAuthorized.field:
      case gridColumnsData.bookingConfirmed.field:
      case gridColumnsData.shipmentConfirmed.field:
        return parseDateColumm(rowData, column);
      case gridColumnsData.freightForwarderOrigin.field:
        return parsePartnerColumn(rowData, column, 'idFreightForwarderOrigin');
      case gridColumnsData.freightForwarderDestination.field:
        return parsePartnerColumn(
          rowData,
          column,
          'idFreightForwarderDestination',
        );
      case gridColumnsData.estimatedGrossMargin.field:
      case gridColumnsData.grossMargin.field:
      case gridColumnsData.estimatedNetMargin.field:
        return parsePercentageColumnPt(rowData, column);
      case gridColumnsData.estimatedNetProfit.field:
      case gridColumnsData.freightCost.field:
      case gridColumnsData.freightExtraCost.field:
      case gridColumnsData.totalPaid.field:
      case gridColumnsData.totalCotRemain.field:
      case gridColumnsData.purchaseTotalCotPi.field:
      case gridColumnsData.purchaseTotalCotCi.field:
      case gridColumnsData.sellerTotalSunPi.field:
      case gridColumnsData.sellerTotalSunCi.field:
        return parseCurrencyPtBrNumberColumn(
          rowData,
          column,
          2,
          rowData.satCurrency,
        );
      case gridColumnsData.samples.field:
        return parseNullableBooleanColumn(rowData, column);
      case gridColumnsData.updatesEn.field:
        return parseUpdatesEnColumn(rowData);
      case gridColumnsData.productionTime.field:
      case gridColumnsData.quantityPiBySun.field:
      case gridColumnsData.quantityCiBySun.field:
      case gridColumnsData.authorizationBookingTiming.field:
      case gridColumnsData.authorizationTiming.field:
      case gridColumnsData.authorizationShipmentTiming.field:
      case gridColumnsData.bookingShipmentTiming.field:
      case gridColumnsData.preShipmentTiming.field:
      case gridColumnsData.transitTime.field:
      case gridColumnsData.hblFinalTiming.field:
      case gridColumnsData.startProductionTiming.field:
      case gridColumnsData.deliveryAtClientTiming.field:
      case gridColumnsData.totalForeignTradeTiming.field:
      case gridColumnsData.samplesLeadTime.field:
        return parsePtBrNumberColumn(rowData, column, 0);
      case gridColumnsData.purchasePaidAdvance.field:
      case gridColumnsData.purchasePaidBalance.field:
        return parsePtBrNumberColumn(rowData, column, 2);
      case gridColumnsData.reworkCost.field:
        return parseCurrencyPtBrNumberColumn(rowData, column, 2);
      case gridColumnsData.shipmentSunNumber.field:
        return parseSunNumberColumn(rowData);
      default:
        return rowData[column.field as keyof SatListExportView];
    }
  }

  /**
   * Busca colunas selecionadas na ordem em que sao apresentadas ao usuario
   * @returns Colunas selecionadas na ordem em que sao apresentadas ao usuario
   */
  function getOrderedSelectedColumns() {
    const columnsOrder = gridRef.current?.columnOrder;

    if (columnsOrder && columnsOrder.length) {
      // Ordena itens de acordo com o array columnsOrder. Itens que nao existam
      // em columnsOrder sao colocados no final do array
      const sortedSelectedColumns = sortBy(selectedColumns.columns, item => {
        const index = columnsOrder.indexOf(item.field);
        return index === -1 ? Infinity : index;
      });

      return sortedSelectedColumns.map(col => col.field);
    }

    // Se nao houver informacao de ordenacao das colunas, retorna colunas
    // selecionadas na ordem default
    return selectedColumns.columns.map(col => col.field);
  }

  const { loading: satListExportViewLoading, data: satListExportViewData } =
    useQuery(listSatListExportViewQuery(fieldsPermissions), {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          fieldsSearch: exportCommonVariables,
        },
      },
      onCompleted: data => {
        const { listSatListExportView } = data;
        if (listSatListExportView) {
          setExportView([
            listSatListExportView.data,
            listSatListExportView.items,
          ]);
        } else {
          setExportView([[], 0]);
          showError({
            summary: 'Error while getting Export List',
          });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Export List',
          detail: errorData.message,
        });
      },
    });
  const [exportExportView, { loading: exportLoading }] = useLazyQuery(
    exportListQuery,
    {
      onCompleted: response => {
        if (response.exportSatListExportView) {
          window.open(response.exportSatListExportView, '_blank');
        } else {
          showError({
            summary: 'Error while exporting list of SATs',
          });
        }
      },

      onError: error => {
        showError({
          summary: 'Error while exporting list of SATs',
          detail: error.message,
        });
      },
    },
  );

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromSatExportListQuery,
    {
      variables: {
        data: exportCommonVariables,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Export List totals',
          detail: errorData.message,
        });
      },
    },
  );

  function handleColumnFooter(field: string) {
    if (listSumLoading) return <Skeleton />;
    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // SATs possuirem a mesma currency
    const currencyAbbreviation =
      satListExportViewData?.listSatListExportView?.data[0]?.satCurrency;

    const value = listSumData?.getSumDataFromSatExportList[field];

    switch (field) {
      case 'satNumber':
        return renderNumber(
          listSumData?.getSumDataFromSatExportList.satCount,
          'int',
        );
      case 'purchaseTotalCotPi':
      case 'purchaseTotalCotCi':
      case 'sellerTotalSunPi':
      case 'sellerTotalSunCi':
      case 'purchasePaidAdvance':
      case 'purchasePaidBalance':
      case 'totalPaid':
      case 'totalCotRemain':
      case 'freightCost':
      case 'freightExtraCost':
      case 'estimatedNetProfit':
        return renderNumber(value, 'currency', currencyAbbreviation);
      case 'quantityPiBySun':
      case 'quantityCiBySun':
        return renderNumber(value, 'float');
      case 'estimatedGrossMargin':
      case 'estimatedNetMargin':
      case 'grossMargin': {
        const valuePercent = value / 100;
        return renderNumber(valuePercent, 'percent');
      }
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          field={col.field}
          body={(rowData, props) => renderColumn(rowData, props)}
          header={renderColumnHeader(col.header)}
          style={setColumnSize(col.field)}
          sortable={col.field !== gridColumnsData.updatesEn.field}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  /**
   * Atualiza o filtro global da grid
   */
  useEffect(() => {
    // Evita que o filtro seja atualizado na montagem do componente
    if (isMounted.current) {
      const { first, page } = pagination.initialLazyParams;

      const delayDebounceFn = setTimeout(() => {
        setLazyParams(current => {
          const newLazyParams = {
            ...current,
            first,
            page,
            globalSearch: globalFilter,
          };

          saveLazyParams(newLazyParams);

          return newLazyParams;
        });
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }

    isMounted.current = true;
    return undefined;
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const localStorageObject = JSON.parse(localStorageSelectedColumns);
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        localStorageObject.columns,
        columns,
      );

      setSelectedColumns({
        ...localStorageObject,
        columns: allowedColumns,
      });
    }
  }, [columns]);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            setLazyParams(current => {
              const newLazyParams = {
                ...current,
                ...e,
                first: pagination.initialLazyParams.first,
                page: pagination.initialLazyParams.page,
                rows: pagination.initialLazyParams.rows,
              };

              saveLazyParams(newLazyParams);

              return newLazyParams;
            })
          }
          onClear={() =>
            setLazyParams({
              ...pagination.initialLazyParams,
              globalSearch: globalFilter,
            })
          }
        />
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>

        {permissions.export && (
          <Button
            label="Export Grid"
            className="export-xlsx"
            loading={exportLoading}
            onClick={() =>
              exportExportView({
                variables: {
                  data: {
                    pagination: {
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    columnsToExport: getOrderedSelectedColumns(),
                    fieldsSearch: exportCommonVariables,
                  },
                },
              })
            }
          />
        )}
        <MultiSelectGroup
          gridRef={gridRef}
          className="grid-multiselect-panel"
          allColumns={columns}
          groups={groupedColumns}
          showFields
          onSelectionChange={e => onColumnToggle(e)}
          initialData={selectedColumns.treeSelectedData}
          resetOptionKey={gridResetOptionKey}
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a SAT"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        name="controlsExport"
        lazy
        totalRecords={exportView[1]}
        value={exportView[0]}
        globalFilter={globalFilter}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
        rowHover
      >
        {dynamicColumns}
      </Grid>

      {satListExportViewLoading && <Loading />}

      {columns && !columns.length && (
        <Empty message="You do not have permission to see any columns">
          <i className="pi pi-exclamation-circle" />
        </Empty>
      )}

      {columns && columns.length && !selectedColumns.columns.length && (
        <Empty message="You need to select at least one column to see any data" />
      )}
    </div>
  );
};

export default Export;
