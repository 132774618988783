/* eslint-disable jsx-a11y/label-has-associated-control */

import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { confirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';
import { Panel } from 'primereact/panel';

import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';

import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';

import getValidationErrors from '../../../utils/getValidationErrors';
import Loading from '../../../components/Loading';
import { Container } from './styles';
import FormTreeSelect from '../../../components/FormTreeSelect';
import ToastLife from '../../../shared/enums/toastLife';
import useTitle from '../../../hooks/useTitle';
import { TreeSelectSelectionKeys } from '../../../components/Grid/interfaces';

interface IFormData {
  name: string;
  idParentCategory: number;
}
interface IChecklist {
  idCheckList: number;
  name: string;
  categoriesString: string;
  categories: ICategoriesChecklist[];
}
interface ICategoriesChecklist {
  name: string;
}
interface ICategoryEdit {
  idCategory: number | undefined;
  idParentCategory: number | undefined;
  idCheckList: number | undefined;
  name: string | undefined;
  lenghtTip: string | undefined;
  widthTip: string | undefined;
  volumeTip: string | undefined;
  capacityTip: string | undefined;
  thicknessTip: string | undefined;
  depthTip: string | undefined;
  bottomDiamterTip: string | undefined;
  grammageTip: string | undefined;
  heightTip: string | undefined;
  topDiameterTip: string | undefined;
  versionLock: number;
}

// Parametros da rota de Category
interface CategoryRouteParams {
  categoryId: string;
}

const Category: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID da category
  const categoryId = parseInt(useParams<CategoryRouteParams>().categoryId, 10);

  const defaultPageTitle = `${categoryId ? 'Manage' : 'Create'} Category`;

  // Nome da Category
  const [categoryName, setCategoryName] = useState('');

  useTitle(
    categoryName ? `${categoryName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  // Estado do Category
  // é o mesmo do categoryData, precisa?
  const [categoryEdit, setCategoryEdit] = useState<ICategoryEdit>({
    name: '',
    idCategory: undefined,
    idParentCategory: undefined,
    idCheckList: undefined,
    lenghtTip: 'Could Have',
    widthTip: 'Could Have',
    volumeTip: 'Could Have',
    capacityTip: 'Could Have',
    thicknessTip: 'Could Have',
    depthTip: 'Could Have',
    bottomDiamterTip: 'Could Have',
    grammageTip: 'Could Have',
    heightTip: 'Could Have',
    topDiameterTip: 'Could Have',
    versionLock: 0,
  });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/categories'),
    });
  }

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Categories',
      path: '/list/categories',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  // Parents categories
  const [categoriesParent, setCategoriesParent] = useState([]);
  const [selectedParentCatKey, setSelectedParentCatKey] =
    useState<TreeSelectSelectionKeys>();
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [selectedChecklist, setSelectedChecklist] = useState('');

  // Query para listar categories no treeselect
  const listCategoriesQuery = gql`
    query listAllCategoriesQuery(
      $listAllCategoriesInput: ListAllCategoriesInput
    ) {
      listAllCategories(listAllCategoriesInput: $listAllCategoriesInput) {
        data
      }
    }
  `;

  /**
   * Busca Categories
   */
  useQuery(listCategoriesQuery, {
    variables: {
      listAllCategoriesInput: {},
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting categories',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listAllCategories) {
        const c = JSON.parse(response.listAllCategories.data).filter(
          (item: { key: number }) => item.key !== categoryId,
        );
        setCategoriesParent(c);
      } else {
        setCategoriesParent([]);
      }
    },
  });

  // Query para listar Category
  const listCategoryQuery = gql`
    query ListCategoryQuery($id: Int!) {
      listCategory(id: $id) {
        idCategory
        idParentCategory
        idCheckList
        name
        lenghtTip
        widthTip
        volumeTip
        capacityTip
        thicknessTip
        depthTip
        bottomDiamterTip
        grammageTip
        heightTip
        topDiameterTip
        versionLock
      }
    }
  `;

  // Query para listar checklists
  const listChecklistsQuery = gql`
    query ListChecklistsWithCategoriesQuery(
      $pagination: Pagination!
      $categoryName: String
      $name: String
      $globalSearch: String
    ) {
      listChecklistsWithCategories(
        pagination: $pagination
        name: $name
        categoryName: $categoryName
        globalSearch: $globalSearch
      ) {
        data {
          idCheckList
          name
          categoriesString
          categories {
            idCategory
            name
          }
        }
        items
      }
    }
  `;

  /**
   * Busca Checklists
   */
  useQuery(listChecklistsQuery, {
    variables: {
      pagination: {
        _page: 1,
        _limit: 1000,
        _orderBy: '',
        _sortOrder: 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting checklists',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
    onCompleted: async response => {
      if (response.listChecklistsWithCategories.data) {
        setChecklists(response.listChecklistsWithCategories.data);
      } else setChecklists([]);
    },
  });

  // Mutation para criar Category
  const createCategoryQuery = gql`
    mutation CreateCategory($category: CreateCategoryInput) {
      createCategory(category: $category) {
        idCategory
      }
    }
  `;

  // Mutation para atualizar Category
  const updateCategoryQuery = gql`
    mutation UpdateCategory($category: UpdateCategoryInput!) {
      updateCategory(category: $category) {
        idCategory
      }
    }
  `;

  // cria método para chamar a mutation
  const [createCategoryMutation] = useMutation(createCategoryQuery);
  const [updateCategoryMutation] = useMutation(updateCategoryQuery);

  // Submit do formulario
  const handleSubmitCategory = useCallback(
    async (formData: IFormData) => {
      setPageLoading(true);
      // Efetua validação dos dados

      try {
        setCategoryEdit({
          ...categoryEdit,
          name: formData.name,
        });
        categoryEdit.name = formData.name;
        categoryEdit.idParentCategory = formData.idParentCategory;
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          name: Yup.string().required('Enter a name'),
        });

        // Efetua validação
        await schema.validate(formData, { abortEarly: false });

        // TODO checklist é obrigatório?
        const hasEmptyField =
          !categoryEdit.name ||
          !categoryEdit.widthTip ||
          !categoryEdit.lenghtTip ||
          !categoryEdit.volumeTip ||
          !categoryEdit.capacityTip ||
          !categoryEdit.thicknessTip ||
          !categoryEdit.depthTip ||
          !categoryEdit.bottomDiamterTip ||
          !categoryEdit.grammageTip ||
          !categoryEdit.heightTip ||
          !categoryEdit.topDiameterTip;

        // Valida se ha algum campo em branco
        if (hasEmptyField) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'Please fill all fields',
            life: ToastLife.WARN,
          });
          return;
        }

        if (!categoryEdit.idParentCategory && !categoryEdit.idCheckList) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'warn',
            summary: 'First level categories need to have a linked checklist',
            detail: 'Please select a checklist',
            life: ToastLife.WARN,
          });
          return;
        }

        if (categoryId) {
          // Atualiza category
          const response = await updateCategoryMutation({
            variables: {
              category: {
                name: categoryEdit.name,
                idCategory: categoryId,
                idParentCategory: categoryEdit.idParentCategory || undefined,
                idCheckList: categoryEdit.idCheckList || undefined,
                lenghtTip: categoryEdit.lenghtTip,
                widthTip: categoryEdit.widthTip,
                volumeTip: categoryEdit.volumeTip,
                capacityTip: categoryEdit.capacityTip,
                thicknessTip: categoryEdit.thicknessTip,
                depthTip: categoryEdit.depthTip,
                bottomDiamterTip: categoryEdit.bottomDiamterTip,
                grammageTip: categoryEdit.grammageTip,
                heightTip: categoryEdit.heightTip,
                topDiameterTip: categoryEdit.topDiameterTip,
                versionLock: categoryEdit.versionLock,
              },
            },
          });

          setCategoryEdit(response.data);
        } else {
          // Cria category
          const response = await createCategoryMutation({
            variables: {
              category: {
                name: categoryEdit.name,
                idCategory: categoryEdit.idCategory || undefined,
                idParentCategory: categoryEdit.idParentCategory || undefined,
                idCheckList: categoryEdit.idCheckList || undefined,
                lenghtTip: categoryEdit.lenghtTip,
                widthTip: categoryEdit.widthTip,
                volumeTip: categoryEdit.volumeTip,
                capacityTip: categoryEdit.capacityTip,
                thicknessTip: categoryEdit.thicknessTip,
                depthTip: categoryEdit.depthTip,
                bottomDiamterTip: categoryEdit.bottomDiamterTip,
                grammageTip: categoryEdit.grammageTip,
                heightTip: categoryEdit.heightTip,
                topDiameterTip: categoryEdit.topDiameterTip,
                versionLock: categoryEdit.versionLock,
              },
            },
          });
          setCategoryEdit(response.data);
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Category ${categoryId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de categories
        history.push('/list/categories');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              categoryId ? 'editing' : 'creating'
            }  Category`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setPageLoading(false);
      }
    },
    [
      categoryEdit,
      formRef,
      categoryId,
      toastRef,
      history,
      updateCategoryMutation,
      createCategoryMutation,
    ],
  );

  // Carrega response do status
  const [
    loadCategoryData,
    {
      called: categoryCalled,
      loading: categoryLoading,
      data: categoryData,
      error: categoryError,
    },
  ] = useLazyQuery(listCategoryQuery, {
    variables: {
      id: categoryId,
    },
    onCompleted: async response => {
      setCategoryName(response.listCategory.name);
      setSelectedChecklist(response.listCategory.idCheckList);

      setCategoryEdit(response.listCategory);
      setSelectedParentCatKey(response.listCategory.idParentCategory);

      setPageLoading(false);
    },

    onError: error => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting Category data',
        detail: error.message,
        life: ToastLife.ERROR,
      });

      setPageLoading(false);
      history.push('/list/categories');
    },
  });

  useEffect(() => {
    if (categoryId) {
      setPageLoading(true);
      loadCategoryData();
    }
  }, [categoryId, loadCategoryData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      {/* Header da pagina */}
      <PageHeader
        title={
          categoryName
            ? `${defaultPageTitle} - ${categoryName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="secondaryButton"
          label="Save"
          onClick={() => {
            formRef.current?.submitForm();
          }}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {((categoryId && !categoryLoading && !pageLoading) || !categoryId) && (
        <div style={{ padding: '30px' }} className="grid nested-grid">
          <div className="col-7">
            <div className="grid">
              <div style={{ paddingRight: '200px' }} className="col-12">
                <h2>General</h2>
                <br />
                <Form
                  ref={formRef}
                  onSubmit={handleSubmitCategory}
                  initialData={
                    categoryCalled && !categoryLoading && !categoryError
                      ? categoryData.listCategory
                      : undefined
                  }
                >
                  <FormInput name="name" label="Category name" required />
                  <br />
                  <FormTreeSelect
                    className="generalInput"
                    name="idParentCategory"
                    label="Master Category"
                    options={categoriesParent}
                    filter
                    placeholder="Select a Category"
                    initialValue={selectedParentCatKey}
                  />
                </Form>
              </div>
              <div className="col-12">
                <h2>Tips</h2>
                <br />
                <table
                  style={{
                    backgroundColor: 'whitesmoke',
                    padding: '10px',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    lineHeight: '1.6',
                  }}
                >
                  <tr>
                    <td> </td>
                    <th style={{ padding: '4px' }}>Should Have</th>
                    <th>Could Have </th>
                  </tr>
                  <tr>
                    <td>Length Tip</td>
                    <td>
                      <RadioButton
                        inputId="lenghtTipSH"
                        name="lenghtTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            lenghtTip: e.value,
                          })
                        }
                        checked={categoryEdit.lenghtTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="lenghtTipCH"
                        name="lenghtTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            lenghtTip: e.value,
                          })
                        }
                        checked={categoryEdit.lenghtTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Width Tip</td>
                    <td>
                      <RadioButton
                        inputId="widthTipSH"
                        name="widthTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            widthTip: e.value,
                          })
                        }
                        checked={categoryEdit.widthTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="widthTipCH"
                        name="widthTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            widthTip: e.value,
                          })
                        }
                        checked={categoryEdit.widthTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Volume Tip</td>
                    <td>
                      <RadioButton
                        inputId="volumeTipSH"
                        name="volumeTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            volumeTip: e.value,
                          })
                        }
                        checked={categoryEdit.volumeTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="volumeTipCH"
                        name="volumeTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            volumeTip: e.value,
                          })
                        }
                        checked={categoryEdit.volumeTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Capacity Tip</td>
                    <td>
                      <RadioButton
                        inputId="capacityTipSH"
                        name="capacityTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            capacityTip: e.value,
                          })
                        }
                        checked={categoryEdit.capacityTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="capacityTippCH"
                        name="capacityTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            capacityTip: e.value,
                          })
                        }
                        checked={categoryEdit.capacityTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Thickness Tip</td>
                    <td>
                      <RadioButton
                        inputId="thicknessTipSH"
                        name="thicknessTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            thicknessTip: e.value,
                          })
                        }
                        checked={categoryEdit.thicknessTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="thicknessTipCH"
                        name="thicknessTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            thicknessTip: e.value,
                          })
                        }
                        checked={categoryEdit.thicknessTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Depth Tip</td>
                    <td>
                      <RadioButton
                        inputId="depthTipSH"
                        name="depthTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            depthTip: e.value,
                          })
                        }
                        checked={categoryEdit.depthTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="depthTipCH"
                        name="depthTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            depthTip: e.value,
                          })
                        }
                        checked={categoryEdit.depthTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Bottom Tip</td>
                    <td>
                      <RadioButton
                        inputId="bottomTipSH"
                        name="bottomTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            bottomDiamterTip: e.value,
                          })
                        }
                        checked={
                          categoryEdit.bottomDiamterTip === 'Should Have'
                        }
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="bottomDiamterTipCH"
                        name="bottomDiamterTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            bottomDiamterTip: e.value,
                          })
                        }
                        checked={categoryEdit.bottomDiamterTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Grammage Tip</td>
                    <td>
                      <RadioButton
                        inputId="grammageTipSH"
                        name="grammageTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            grammageTip: e.value,
                          })
                        }
                        checked={categoryEdit.grammageTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="grammageTipCH"
                        name="grammageTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            grammageTip: e.value,
                          })
                        }
                        checked={categoryEdit.grammageTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Height Tip</td>
                    <td>
                      <RadioButton
                        inputId="heightTipSH"
                        name="heightTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            heightTip: e.value,
                          })
                        }
                        checked={categoryEdit.heightTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="heightTipCH"
                        name="heightTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            heightTip: e.value,
                          })
                        }
                        checked={categoryEdit.heightTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Top Diameter Tip</td>
                    <td>
                      <RadioButton
                        inputId="widthTipSH"
                        name="topDiameterTip"
                        value="Should Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            topDiameterTip: e.value,
                          })
                        }
                        checked={categoryEdit.topDiameterTip === 'Should Have'}
                        required
                      />
                    </td>
                    <td>
                      <RadioButton
                        inputId="topDiameterTipCH"
                        name="topDiameterTip"
                        value="Could Have"
                        onChange={e =>
                          setCategoryEdit({
                            ...categoryEdit,
                            topDiameterTip: e.value,
                          })
                        }
                        checked={categoryEdit.topDiameterTip === 'Could Have'}
                        required
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="col-5">
            <h2>Checklist</h2>
            <br />
            <ScrollPanel style={{ width: '100%', height: '300px' }}>
              {checklists.map(ck => {
                return (
                  <React.Fragment key={ck.idCheckList}>
                    <Panel
                      toggleable
                      collapsed
                      header={
                        <>
                          <RadioButton
                            inputId={ck.idCheckList as unknown as string}
                            name="category"
                            value={ck.idCheckList}
                            onChange={e => {
                              setCategoryEdit({
                                ...categoryEdit,
                                idCheckList: parseInt(e.value, 10),
                              });
                              setSelectedChecklist(e.value);
                            }}
                            checked={
                              selectedChecklist ===
                              (ck.idCheckList as unknown as string)
                            }
                          />
                          <label htmlFor={ck.idCheckList as unknown as string}>
                            {ck.name}
                          </label>
                        </>
                      }
                    >
                      <div key={ck.idCheckList} className="field-radiobutton">
                        <ScrollPanel style={{ width: '100%', height: '100px' }}>
                          <div className="grid">
                            {ck.categories.length === 0 && (
                              <div className="col-12">No Checks Found</div>
                            )}
                            {ck.categories.map(i => {
                              return (
                                <div className="col-6">
                                  <li>{i.name}</li>
                                </div>
                              );
                            })}
                          </div>
                        </ScrollPanel>
                      </div>
                    </Panel>
                  </React.Fragment>
                );
              })}
            </ScrollPanel>
            <Divider />
          </div>
        </div>
      )}

      {pageLoading && <Loading />}
    </Container>
  );
};

export default Category;
