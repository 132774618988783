import { gql } from '@apollo/client';

export const clientPaymentDashboardQuery = gql`
  query Data($data: GetSatClientPaymentProcessDashboardInput) {
    getSatClientPaymentProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        idSatForeignTrade
        satNumber
        shipment
        mainProduct
        suppliers
        clientNotify
        exporter
        countryOrigin
        satCurrency
        paymentSellingIncoterm
        realInspectionDate
        realEtd
        categoryIcon
        category
      }
      items
    }
  }
`;
