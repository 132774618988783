import { useLazyQuery, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { omit } from 'lodash';
import { TabPanel } from 'primereact/tabview';
import React, {
  Dispatch,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { array, number, object, string, ValidationError } from 'yup';
import { useLocation } from 'react-router-dom';
import Empty from '../../../../components/Empty';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import TabViewAsync, {
  ITabViewAsyncPageParams,
} from '../../../../components/TabViewAsync';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { ISat, ISatRnc, ISatRncCoResponsible } from '../interfaces';
import {
  listDomainsByGroupIdQuery,
  listSatRncsByIdSatQuery,
  updateSatRncItemsQuery,
  updateSatRncsQuery,
} from './queries';
import { Container } from './styles';

import Loading from '../../../../components/Loading';
import ILazyParams from '../../../../services/lazyParams';
import { UpdateSatRncResponse } from './interfaces';

import { CostType, StatusRnc } from '../../../../shared/enums/costType';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { ProblemType } from '../../../../shared/enums/problemType';
import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import getValidationErrors, {
  getIndexPositionFromErrorPath,
  requiredFieldErrorMessage,
} from '../../../../utils/getValidationErrors';
import { objectsAreEqual } from '../../../../utils/objectsAreEqual';
import RncContent from './RncContent';
import { SatRncItem } from './RncContent/RncItems/interfaces';

export interface ISatRncRef {
  refetch(): Promise<void>;
  validateRncForm(): Promise<boolean>;
  handleUpdateRnc(): Promise<UpdateSatRncResponse>;
}

interface IRncProps extends PageTabContainerProps {
  sat: ISat;
  ref: Ref<ISatRncRef>;
  pageMenuItemKey: string;
  userPermissions: IUserFieldsAndPermissionsResponse;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
}

const Rnc: React.FC<IRncProps> = React.forwardRef(
  (
    { selected, sat, userPermissions, pageMenuItemKey, setSelectedMenuItem },
    ref,
  ) => {
    const { showError } = useRefHook();

    const formRef = useRef<FormHandles>(null);

    const { search } = useLocation();

    const [satRncs, setSatRncs] =
      useState<{ data: ISatRnc[]; items: number }>();

    const [itemsToUpdate, setItemsToUpdate] = useState<SatRncItem[]>([]);

    const [tabActiveIndex, setTabActiveIndex] = useState<number>(0);

    const urlParam = new URLSearchParams(search).get('rncId');

    const initialLazyParams = useMemo(() => {
      return {
        ...pagination.initialLazyParams,
      };
    }, []);

    const [lazyParams, setLazyParams] =
      useState<ILazyParams>(initialLazyParams);

    function selectRncTab(rncs: ISatRnc[]) {
      if (urlParam) {
        const rncId = parseInt(urlParam, 10);
        const index = rncs?.findIndex(rnc => rnc.idSatRnc === rncId);
        setTabActiveIndex(index || 0);
      }
    }

    const [loadSatRncsData, { loading: satRncsLoading, data: satRncsData }] =
      useLazyQuery(listSatRncsByIdSatQuery, {
        variables: {
          data: {
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
            },
            idSat: sat.idSat,
          },
        },
        onCompleted: response => {
          if (response.listSatRncsByIdSat) {
            setSatRncs(response.listSatRncsByIdSat);
            selectRncTab(response.listSatRncsByIdSat.data);
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting RNCs',
            detail: errorData.message,
          });
        },
      });

    const [
      loadRncFileTypesData,
      { loading: rncFileTypesLoading, data: rncFileTypesData },
    ] = useLazyQuery(listDomainsByGroupIdQuery, {
      variables: {
        pagination: {
          _limit: 0,
          _page: 1,
        },
        id: DomainGroup.RNC_TYPE_FILES,
        isActive: true,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Foreign Trade File Types',
          detail: errorData.message,
        });
      },
    });

    const validateForm = useCallback(
      async (data: any) => {
        if (!data) return false;
        const schema = object().shape({
          data: array().of(
            object().shape({
              idStatus: number().nullable().required(requiredFieldErrorMessage),
              idRncResponsible: number()
                .nullable()
                .required(requiredFieldErrorMessage),
              idPriority: number()
                .nullable()
                .required(requiredFieldErrorMessage),
              idProblemType: number()
                .nullable()
                .required(requiredFieldErrorMessage),
              problemTypeDescription: string().when('idProblemType', {
                is: ProblemType.OTHER,
                then: string().required(requiredFieldErrorMessage),
                otherwise: string().notRequired(),
              }),
              problemDescription: string().required(requiredFieldErrorMessage),
              proposedSolution: string().required(requiredFieldErrorMessage),
              idReWork: number().nullable().required(requiredFieldErrorMessage),
              idCost: number().nullable().required(requiredFieldErrorMessage),
              idCostResponsible: number()
                .when('idCost', {
                  is: CostType.YES_NOT_REFUNDABLE,
                  then: number().nullable().required(requiredFieldErrorMessage),
                  otherwise: number().nullable().notRequired(),
                })
                .when('idCost', {
                  is: CostType.YES_REFUNDABLE,
                  then: number().nullable().required(requiredFieldErrorMessage),
                  otherwise: number().nullable().notRequired(),
                })
                .when('idCost', {
                  is: CostType.YES_REFUNDED,
                  then: number().nullable().required(requiredFieldErrorMessage),
                  otherwise: number().nullable().notRequired(),
                }),
              finalRncCostTotal: number().when('idCost', {
                is: CostType.YES_NOT_REFUNDABLE,
                then: number()
                  .nullable()
                  .when('idStatus', {
                    is: StatusRnc.CLOSED,
                    then: number()
                      .nullable()
                      .required(requiredFieldErrorMessage),
                    otherwise: number().nullable().notRequired(),
                  }),
                otherwise: number().nullable().notRequired(),
              }),
            }),
          ),
        });

        try {
          formRef.current?.setErrors({});
          await schema.validate(data, { abortEarly: false });
          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];
          const inputWithError = formRef.current?.getFieldRef(firstError.path);

          const tabWithError = getIndexPositionFromErrorPath(firstError.path);

          if (tabWithError !== undefined) {
            setTabActiveIndex(tabWithError);
          }

          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          showError({
            summary: 'RNC Tab',
            detail: 'Please fill all the required fields',
          });
          throw new ValidationError(error);
        }
      },
      [pageMenuItemKey, setSelectedMenuItem, showError],
    );

    const [updateSatRncMutation] = useMutation(updateSatRncsQuery);

    const [updateSatRncItemsMutation] = useMutation(updateSatRncItemsQuery);

    const handleSubmit = useCallback(
      async (data: ISatRnc[]) => {
        const response: UpdateSatRncResponse = {
          errors: [],
          warnings: [],
        };

        if (!data) return response;

        const satRncsToUpdate: ISatRnc[] = [];

        data.forEach(satRncData => {
          const currentValue = satRncs?.data.find(
            current => current.idSatRnc === satRncData.idSatRnc,
          );

          const satRncCoResponsibles =
            satRncData.satRncCoResponsibleDomainIds?.map(id => {
              return {
                idRncCoResponsible: id,
              } as ISatRncCoResponsible;
            });

          const satRncToUpdate = {
            ...omit(satRncData, [
              'closedAt',
              'createdAt',
              'createdBy',
              'createdBy2',
              'idSupplier2',
              'rncNumber',
              'daysOpen',
              'satShipment',
              'satRncCoResponsibleDomainIds',
              'totalCostAmountBr',
              'totalCostAmountSat',
              'finalRncCostTotal',
              'refund',
              'finalCreditNoteTotal',
              'finalDebitNoteTotal',
            ]),
            satRncCoResponsibles,
          };

          const objectsAreEquals = objectsAreEqual(
            satRncToUpdate,
            currentValue,
          );

          if (
            currentValue &&
            (!objectsAreEquals ||
              satRncCoResponsibles
                ?.map(res => res.idRncCoResponsible)
                ?.sort()
                .join(',') !==
                currentValue.satRncCoResponsibles
                  ?.map(res => res.idRncCoResponsible)
                  ?.sort()
                  .join(','))
          ) {
            satRncsToUpdate.push(satRncToUpdate);
          }
        });

        if (!satRncsToUpdate.length && !itemsToUpdate.length) return response;

        try {
          const clientDebitNoteNumbers = satRncsToUpdate.map(satRnc => ({
            satRnc: satRnc.idSatRnc,
            clientDebitNoteNumber: satRnc.clientDebitNoteNumber,
          }));

          if (itemsToUpdate.length) {
            await updateSatRncItemsMutation({
              variables: {
                data: {
                  clientDebitNoteNumbers,
                  items: itemsToUpdate.map(item => {
                    return {
                      idSatRncItem: item.idSatRncItem,
                      quantityComplained: item.quantityComplained,
                      nationalizedUnit: item.nationalizedUnit,
                      clientAware: item.clientAware,
                      idType: item.idType2?.idDomain,
                      idDebitCategory: item.idDebitCategory2?.idDomain,
                      notes: item.notes,
                    };
                  }),
                },
              },
            });
          }

          if (satRncsToUpdate.length) {
            const result = await updateSatRncMutation({
              variables: {
                data: satRncsToUpdate,
              },
            });

            response.warnings.push(...result.data.updateSatRncs.warnings);
          }

          return response;
        } catch (error) {
          response.errors.push(error.message);
          return response;
        }
      },
      [
        itemsToUpdate,
        satRncs?.data,
        updateSatRncItemsMutation,
        updateSatRncMutation,
      ],
    );

    useImperativeHandle(ref, () => ({
      validateRncForm: async () => {
        const data = formRef.current?.getData();
        return validateForm(data);
      },
      refetch: async () => {
        if (satRncsData) {
          setSatRncs(undefined);
          loadSatRncsData();
        }
      },
      handleUpdateRnc: async () => {
        const data = formRef.current?.getData();
        return handleSubmit(data?.data);
      },
    }));

    const onPage = useCallback(
      (e: ITabViewAsyncPageParams) => {
        setLazyParams({ ...lazyParams, ...e });
      },
      [lazyParams],
    );

    useEffect(() => {
      if (!satRncsData && selected) {
        loadSatRncsData();
        loadRncFileTypesData();
      }
    }, [satRncsData, loadSatRncsData, selected, loadRncFileTypesData]);

    return (
      <PageTabContainer selected={selected}>
        <Container>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={satRncs}>
            {((satRncsLoading && !!lazyParams.page) ||
              (!satRncsLoading && !rncFileTypesLoading)) &&
              satRncs && (
                <TabViewAsync
                  scrollable
                  first={lazyParams.first}
                  rows={lazyParams.rows}
                  totalRecords={satRncs?.items ?? 0}
                  loading={satRncsLoading}
                  page={lazyParams.page}
                  itemsToRenderLength={satRncs?.data.length ?? 0}
                  onPage={e => onPage(e)}
                  activeIndex={tabActiveIndex}
                  onTabChange={e => setTabActiveIndex(e.index)}
                  renderActiveOnly={false}
                >
                  {satRncs?.data.map((satRnc, index) => {
                    return (
                      <TabPanel key={satRnc.idSatRnc} header={satRnc.rncNumber}>
                        <RncContent
                          formRef={formRef}
                          sat={sat}
                          satRncs={satRncs}
                          itemIndex={index}
                          selected={tabActiveIndex === index}
                          setItemsToUpdate={setItemsToUpdate}
                          userPermissions={userPermissions}
                          rncFileTypes={
                            rncFileTypesData.listDomainsByGroupId.data
                          }
                          rncFileTypesLoading={rncFileTypesLoading}
                        />
                      </TabPanel>
                    );
                  })}
                </TabViewAsync>
              )}
            {!satRncsLoading && !satRncs?.items && (
              <Empty message="There is no RNC for this SAT" />
            )}
          </Form>
          {satRncsLoading && <Loading />}
        </Container>
      </PageTabContainer>
    );
  },
);

export default Rnc;
