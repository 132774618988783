import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLazyQuery } from '@apollo/client';
import { Container } from './styles';
import { listApportionmentQuery } from './queries';
import {
  FinancialApportionmentFieldsPermissions,
  SatForeignTrade,
} from '../interfaces';
import ToastLife from '../../../../../shared/enums/toastLife';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { Apportionment } from './interfaces';
import {
  parseCurrencyPtBrNumberColumn,
  parsePercentageColumnPt,
  parsePtBrNumberColumn,
} from '../../../../../utils/gridColumnsParse';
import { numberToPtString } from '../../../../../utils/formatLocale';
import { gridConstants } from '../../../../../components/Grid/constants';

interface IContentProps {
  selected: boolean;
  satForeignTrade: SatForeignTrade;
  fieldsPermissions: FinancialApportionmentFieldsPermissions;
}

const Content: React.FC<IContentProps> = ({
  satForeignTrade,
  selected,
  fieldsPermissions,
}) => {
  const { toastRef } = useRefHook();

  const [apportionment, setApportionment] = useState<Apportionment>();

  const [
    loadApportionmentData,
    { loading: apportionmentLoading, data: apportionmentData },
  ] = useLazyQuery(listApportionmentQuery(fieldsPermissions), {
    variables: {
      idSatForeignTrade: satForeignTrade.idSatForeignTrade,
    },
    onCompleted: data => {
      setApportionment(data.getSatForeignTradeApportionment);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting apportionment data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  useEffect(() => {
    if (selected && !apportionmentData) {
      loadApportionmentData();
    }
  }, [loadApportionmentData, selected, apportionmentData]);

  return (
    <Container className="grid">
      <h1 className="col-12">
        Apportionment -{' '}
        <span className="sat-apportionment-ft-number">
          {satForeignTrade.satForeignTradeNumber}
        </span>
      </h1>

      <DataTable
        className="p-datatable-sm col-12"
        emptyMessage="No apportionment data found"
        loading={apportionmentLoading}
        value={apportionment?.data}
        scrollHeight={gridConstants.internalGridScrollHeight}
        scrollable
      >
        {fieldsPermissions.stCode.view && (
          <Column
            field="stCode"
            header="ST Code"
            footer="TOTAL"
            headerStyle={{ width: '80px' }}
          />
        )}
        {fieldsPermissions.quantityPi.view && (
          <Column
            field="quantityPi"
            header="Qty PI"
            footer={apportionment?.sum.quantityPiSum}
            headerStyle={{ width: '60px' }}
          />
        )}
        {fieldsPermissions.quantityCi.view && (
          <Column
            field="quantityCi"
            header="Qty CI"
            footer={apportionment?.sum.quantityCiSum}
            headerStyle={{ width: '60px' }}
          />
        )}
        {fieldsPermissions.sunPrice.view && (
          <Column
            field="sunPrice"
            header="SUN Price"
            body={parseCurrencyPtBrNumberColumn}
            headerStyle={{ width: '80px' }}
          />
        )}
        {fieldsPermissions.sunPriceTotal.view && (
          <Column
            field="sunPriceTotal"
            header="SUN Price Total"
            body={parseCurrencyPtBrNumberColumn}
            footer={`$ ${numberToPtString(
              apportionment?.sum.sunPriceTotalSum,
              2,
            )}`}
            headerStyle={{ width: '80px' }}
          />
        )}
        {fieldsPermissions.netWeight.view && (
          <Column
            field="netWeight"
            header="Net Weight (KG)"
            body={parsePtBrNumberColumn}
            headerStyle={{ width: '100px' }}
          />
        )}
        {fieldsPermissions.netWeightTotal.view && (
          <Column
            field="netWeightTotal"
            header="Net Weight (KG) Total"
            body={parsePtBrNumberColumn}
            footer={numberToPtString(apportionment?.sum.netWeightTotalSum, 2)}
            headerStyle={{ width: '100px' }}
          />
        )}
        {fieldsPermissions.grossWeight.view && (
          <Column
            field="grossWeight"
            header="Gross Weight"
            body={parsePtBrNumberColumn}
            headerStyle={{ width: '100px' }}
          />
        )}
        {fieldsPermissions.grossWeightTotal.view && (
          <Column
            field="grossWeightTotal"
            header="Gross Weight Total"
            body={parsePtBrNumberColumn}
            footer={numberToPtString(apportionment?.sum.grossWeightTotalSum, 2)}
            headerStyle={{ width: '100px' }}
          />
        )}
        {fieldsPermissions.calculationBasisNetWeight.view && (
          <Column
            field="calculationBasisNetWeight"
            header="Calculation Basis Net Weight (%)"
            headerClassName="sat-apportionment-calculation-basis"
            body={parsePercentageColumnPt}
            footer={`${numberToPtString(
              apportionment?.sum.calculationBasisNetWeightSum,
              2,
            )}%`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.calculationBasisGrossWeight.view && (
          <Column
            field="calculationBasisGrossWeight"
            header="Calculation Basis Gross Weight (%)"
            headerClassName="sat-apportionment-calculation-basis"
            body={parsePercentageColumnPt}
            footer={`${numberToPtString(
              apportionment?.sum.calculationBasisGrossWeightSum,
              2,
            )}%`}
            headerStyle={{ width: '130px' }}
          />
        )}
        {fieldsPermissions.calculationBasisSunPrice.view && (
          <Column
            field="calculationBasisSunPrice"
            header="Calculation Basis Sun Price (%)"
            headerClassName="sat-apportionment-calculation-basis"
            body={parsePercentageColumnPt}
            footer={`${numberToPtString(
              apportionment?.sum.calculationBasisSunPriceSum,
              2,
            )}%`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.freight.view && (
          <Column
            field="freight"
            header="Apportionment Freight"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(apportionment?.sum.freightSum, 4)}`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.freightTaxes.view && (
          <Column
            field="freightTaxes"
            header="Apportionment Freight Taxes"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.freightTaxesSum,
              4,
            )}`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.fobCharges.view && (
          <Column
            field="fobCharges"
            header="Apportionment FOB Charges"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.fobChargesSum,
              4,
            )}`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.yiwu.view && (
          <Column
            field="yiwu"
            header="Apportionment Yiwu"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(apportionment?.sum.yiwuSum, 4)}`}
            headerStyle={{ width: '120px' }}
          />
        )}
        {fieldsPermissions.samplesNetWeight.view && (
          <Column
            field="samplesNetWeight"
            header="Apportionment Samples/Net Weight"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.samplesNetWeightSum,
              4,
            )}`}
            headerStyle={{ width: '150px' }}
          />
        )}
        {fieldsPermissions.samplesGrossWeight.view && (
          <Column
            field="samplesGrossWeight"
            header="Apportionment Samples/Gross Weight"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.samplesGrossWeightSum,
              4,
            )}`}
            headerStyle={{ width: '160px' }}
          />
        )}
        {fieldsPermissions.samplesSunPrice.view && (
          <Column
            field="samplesSunPrice"
            header="Apportionment Samples/SUN Price"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.samplesSunPriceSum,
              4,
            )}`}
            headerStyle={{ width: '140px' }}
          />
        )}
        {fieldsPermissions.otherNetWeight.view && (
          <Column
            field="otherNetWeight"
            header="Apportionment Other/Net Weight"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.otherNetWeightSum,
              4,
            )}`}
            headerStyle={{ width: '130px' }}
          />
        )}
        {fieldsPermissions.otherGrossWeight.view && (
          <Column
            field="otherGrossWeight"
            header="Apportionment Other/Gross Weight"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.otherGrossWeightSum,
              4,
            )}`}
            headerStyle={{ width: '150px' }}
          />
        )}
        {fieldsPermissions.otherSunPrice.view && (
          <Column
            field="otherSunPrice"
            header="Apportionment Other/SUN Price"
            headerClassName="sat-apportionment-freight"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.otherSunPriceSum,
              4,
            )}`}
            headerStyle={{ width: '130px' }}
          />
        )}
        {fieldsPermissions.sunWithApportionmentTotal.view && (
          <Column
            field="sunWithApportionmentTotal"
            header="SUN With Apportionment Total"
            headerClassName="sat-apportionment-sun"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            footer={`$ ${numberToPtString(
              apportionment?.sum.sunWithApportionmentTotalSum,
              4,
            )}`}
            headerStyle={{ width: '150px' }}
          />
        )}
        {fieldsPermissions.sunWithApportionmentUnit.view && (
          <Column
            field="sunWithApportionmentUnit"
            header="SUN With Apportionment Unit"
            headerClassName="sat-apportionment-sun"
            body={(data, props) =>
              parseCurrencyPtBrNumberColumn(data, props, 4)
            }
            headerStyle={{ width: '150px' }}
          />
        )}
      </DataTable>
    </Container>
  );
};

export default Content;
