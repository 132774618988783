import { IRoleUser } from '../hooks/useAuth';
import { IRoleEntityField } from '../interfaces/IRoleEntityField';
import { IRoleEntityPermission } from '../interfaces/IRoleEntityPermission';

export interface IUserFieldsAndPermissionsResponse {
  userFields: IRoleEntityField[];
  userPermissions: IRoleEntityPermission[];
}

/**
 * Busca fields e permissoes do usuario para determinada entity
 * @param role Roles do usuario
 * @param idEntity ID da entity
 * @returns Permissoes e fields do usuario para determinada entity
 */
export default function getUserFieldsAndPermissionsByEntity(
  role: IRoleUser[],
  idEntity: number,
): IUserFieldsAndPermissionsResponse {
  const userRoleEntity = role
    .map(roleUser => {
      return roleUser.idRole2.roleEntities.find(
        roleEntity => roleEntity.idEntity2.idEntity === idEntity,
      );
    })
    .filter(roleUser => roleUser !== undefined);

  if (userRoleEntity.length) {
    return {
      userFields: userRoleEntity.reduce(
        (acc, val) => acc.concat(val?.roleEntityFields ?? []),
        [] as IRoleEntityField[],
      ),
      userPermissions: userRoleEntity.reduce(
        (acc, val) => acc.concat(val?.roleEntityPermissions ?? []),
        [] as IRoleEntityPermission[],
      ),
    };
  }
  return {} as IUserFieldsAndPermissionsResponse;
}
