import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1154px);
  gap: 2rem;

  & + & {
    margin-top: 12px;
  }

  .purchase-form-title,
  .oe-grid-debit-note-to-add td,
  .oe-grid-credit-note-to-add td {
    color: var(--blue);
  }

  .seller-form-title {
    color: var(--orange);
  }

  .oe-grid-debit-note-added td,
  .ap-grid-rolling-from-destination td,
  .oe-grid-credit-note-added td,
  .ar-grid-rolling-from-destination td {
    color: green;
  }

  .oe-grid-debit-note-added-from-origin td,
  .ap-grid-rolling-from-origin td,
  .oe-grid-credit-note-added-from-origin td,
  .ar-grid-rolling-from-origin td {
    color: var(--sunasia-comex);
  }
`;

export const ResultsContainer = styled.div`
  min-width: 53rem;
  margin-left: 2rem;

  .results-title {
    color: var(--sunasia-sectors-qualidade);
  }

  .results-cot-item {
    font-weight: 500;
    color: var(--blue);
  }

  .results-sun-item {
    font-weight: 500;
    color: var(--orange);
  }
`;
