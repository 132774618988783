import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 1600px) {
    max-width: 90vw;
  }

  @media (min-width: 1600px) {
    max-width: 1500px;
  }

  .sunButton {
    display: flex;
    background: none;
    border: none;
    color: var(--blue);
    transition: color 0.2s;

    svg {
      margin-left: 13px;
    }

    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }
`;

export const GridHeader = styled.div`
  display: flex;
  place-content: end;
  margin-bottom: 2px;

  .p-inputtext {
    margin-right: 12px;
  }
`;
