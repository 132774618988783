import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .generalInput + .generalInput {
    margin-left: 16px;
  }

  .generalInput {
    width: 263px;
  }

  margin-bottom: 25px;
`;

export const NcmItem = styled.div`
  margin-bottom: 14px;
  margin-top: 25px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 999px;

  .link-span {
    width: 200px;

    display: flex;
    align-items: center;
    place-content: center;

    a {
      text-decoration: none;
      color: var(--blue);
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#4C85C8')};
      }
    }

    svg {
      margin-left: 5px;
    }
  }

  .button-div {
    right: 0;

    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }

  .size-one {
    width: 188px;
  }

  .size-two {
    width: 161px;
  }

  .size-three {
    width: 80px;
  }

  .size-four {
    width: 105px;
  }

  .input + .input {
    margin-left: 12px;
  }

  .input-bottom + .input-bottom {
    margin-left: 5px;
  }

  .row {
    display: flex;
  }

  .row + .row {
    margin-top: 11px;
  }
`;
