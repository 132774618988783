import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { financialTransactionStatusOptions } from '../../../shared/options/financial';
import { shipmentResumeOptions } from '../../../shared/options/sat';
import { asyncSelectLoadBeneficiaries } from '../../../shared/querys/beneficiary';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCompanies } from '../../../shared/querys/companies';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import {
  asyncSelectLoadDistinctFinancialTransactionCategoryGroups,
  asyncSelectLoadFinancialTransactionCategories,
} from '../../../shared/querys/financials';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import { asyncSelectLoadSuppliersName } from '../../../shared/querys/supplier';

type FinancialTransactionsGridColumnData = {
  status: ColumnData;
  paymentSituationDescription: ColumnData;
  reference: ColumnData;
  idSatForeignTrade: ColumnData;
  financialAccountCompanyName: ColumnData;
  paymentForecast: ColumnData;
  competencyRegime: ColumnData;
  cashflowRegime: ColumnData;
  financialAccountCurrencyAbbreviation: ColumnData;
  amount: ColumnData;
  amountUSD: ColumnData;
  financialTransactionCategoryName: ColumnData;
  financialTransactionCategoryGroup: ColumnData;
  countryName: ColumnData;
  costCenterByCountryName: ColumnData;
  clientName: ColumnData;
  supplierName: ColumnData;
  product: ColumnData;
  idBeneficiary: ColumnData;
  description: ColumnData;
  satForeignTradeShipmentStatusDescription: ColumnData;
};

export const gridColumnsData: FinancialTransactionsGridColumnData = {
  status: {
    header: 'Status',
    field: 'status',
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.DROPDOWN,
    options: financialTransactionStatusOptions,
  },
  paymentSituationDescription: {
    header: 'Payment Situation',
    field: 'idPaymentSituation2.description',
    advancedFilterField: 'idPaymentSituation',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_PAYMENT_SITUATION,
      additional: {
        orderByDescription: true,
      },
    },
  },
  reference: {
    header: 'Reference',
    field: 'reference',
    advancedFilterField: 'reference',
  },
  idSatForeignTrade: {
    header: 'Shipment',
    field: 'idSatForeignTrade2.satForeignTradeNumber',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  financialAccountCompanyName: {
    header: 'Company',
    field: 'idFinancialAccount2.idCompany2.name',
    advancedFilterField: 'financialAccountIdCompany',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCompany',
      optionData: asyncSelectLoadCompanies,
      isMulti: true,
    },
  },
  paymentForecast: {
    header: 'Forecast Regime',
    field: 'paymentForecast',
    advancedFilterField: 'paymentForecast',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  competencyRegime: {
    header: 'Competency Regime',
    field: 'competencyRegime',
    advancedFilterField: 'competencyRegime',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  cashflowRegime: {
    header: 'Cashflow Regime',
    field: 'cashflowRegime',
    advancedFilterField: 'cashflowRegime',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  financialAccountCurrencyAbbreviation: {
    header: 'Currency',
    field: 'idFinancialAccount2.idCurrency2.abbreviation',
    advancedFilterField: 'financialAccountIdCurrency',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'abbreviation',
      optionValue: 'idCurrency',
      optionData: asyncSelectLoadCurrencies,
      isMulti: true,
    },
  },
  amount: {
    header: 'Amount',
    field: 'amount',
    advancedFilterField: 'amount',
    type: AdvancedFiltersFieldType.NUMBER,
  },
  amountUSD: {
    header: 'Amount (USD)',
    field: 'amountUSD',
    advancedFilterField: '',
  },
  financialTransactionCategoryName: {
    header: 'Category',
    field: 'idFinancialTransactionCategory2.name',
    advancedFilterField: 'idFinancialTransactionCategory',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idFinancialTransactionCategory',
      optionData: asyncSelectLoadFinancialTransactionCategories,
      isMulti: true,
    },
  },
  financialTransactionCategoryGroup: {
    header: 'Group',
    field: 'idFinancialTransactionCategory2.group',
    advancedFilterField: 'financialTransactionCategoryGroup',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionData: asyncSelectLoadDistinctFinancialTransactionCategoryGroups,
      isMulti: true,
      optionLabel: 'label',
      optionValue: 'value',
    },
  },
  countryName: {
    header: 'Country',
    field: 'idCountry2.name',
    advancedFilterField: 'idCountry',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  costCenterByCountryName: {
    header: 'Cost Center By Country',
    field: 'idCostCenterByCountry2.name',
    advancedFilterField: 'idCostCenterByCountry',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  clientName: {
    header: 'Client',
    field: 'idClient2.name',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  supplierName: {
    header: 'Supplier',
    field: 'idSupplier2.name',
    advancedFilterField: 'idSupplier',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  product: {
    header: 'Product',
    field: 'product',
    advancedFilterField: 'product',
  },
  idBeneficiary: {
    header: 'Beneficiary',
    field: 'idBeneficiary2.name',
    advancedFilterField: 'idBeneficiary',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idBeneficiary',
      optionData: asyncSelectLoadBeneficiaries,
      isMulti: true,
    },
  },
  description: {
    header: 'Description',
    field: 'description',
    advancedFilterField: 'description',
  },
  satForeignTradeShipmentStatusDescription: {
    header: 'Shipment Resume',
    field: 'idSatForeignTrade2.shipmentStatus',
    advancedFilterField: 'satForeignTradeShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'value',
      optionValue: 'value',
      isMulti: true,
    },
  },
};
