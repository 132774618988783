export const satsPurchaseRoles = {
  idEntity: 55,
  idModule: 12,
  fields: {
    prc: 1947,
    cq: 1948,
    satNumber: 1949,
    shipment: 1950,
    sunNumber: 1951,
    mainProduct: 1952,
    statusSat: 1953,
    typeOfOrder: 1954,
    replacementFrom: 1955,
    supplierNameShipment: 1956,
    exporter: 1957,
    importerConsignee: 1958,
    clientNotify: 1959,
    clientDepartment: 1960,
    ctnrs: 1961,
    portLoading: 1962,
    portDischarge: 1963,
    countryOrigin: 1964,
    createdAt: 1965,
    confirmOrderPurchase: 1966,
    productionTime: 1967,
    samples: 1968,
    sampleLeadTime: 1969,
    estimatedInspectionDate: 1970,
    realInspectionDate: 1971,
    estimatedShipmentDate: 1972,
    realEtd: 1973,
    shipmentResume: 2150,
    importStatus: 1974,
    registerStatus: 2210,
    artworkStatus: 2211,
    productionStatus: 2212,
    shipmentStatus: 2213,
    supplierPaymentStatus: 2214,
    quantityPiBySun: 1975,
    purchaseEstimatedAdvance: 1976,
    purchaseEstimatedBalance: 1977,
    purchaseEstimatedTermCondition: 1978,
    purchaseIncoterm: 1979,
    totalCotPi: 1980,
    totalCotCi: 1981,
    purchaseEstimatedTotalAdvance: 1982,
    purchaseEstimatedAdvancePaymentRequest: 1983,
    purchaseEstimatedTotalBalance: 1984,
    paymentTermAdvance: 1985,
    paymentTermBalance: 1986,
    paymentTermCondition: 1987,
    paymentSellingIncoterm: 1988,
    totalSunPi: 1989,
    totalSunCi: 1990,
    estimatedGrossMargin: 1991,
    estimatedGrossProfit: 1992,
    estimatedNetMargin: 1993,
    estimatedNetProfit: 1994,
    grossMargin: 1995,
    grossProfit: 1996,
    netMargin: 1997,
    netProfit: 1998,
    purchaseUser: 1999,
    firstSalesUser: 2000,
    secondSalesUser: 2001,
    exportUser: 2002,
    importUser: 2003,
    designUser: 2004,
    financialUser: 2005,
    category: 2006,
    realEta: 2238,
  },
  permissions: {
    idPermissionExportSatsPurchase: 190,
  },
};
