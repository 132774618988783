import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;

  form {
    width: 100%;
  }

  .p-fileupload {
    .p-button {
      background-color: #fff;
      color: var(--blue);
      border-color: var(--blue);
      font-weight: 500;

      transition: background-color 0.2s;

      &:hover {
        background-color: ${() => shade(0.01, '#f0f8ff')} !important;
        color: var(--blue);
      }
    }
  }

  .last-prc-file {
    display: flex;
    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    svg {
      margin-right: 12px;
    }

    &:hover {
      color: ${shade(0.2, '#4C85C8')};
    }
  }

  .items-log {
    background: none;
    border: none;
    color: var(--grey-scale);
    transition: background-color 0.2s;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--grey);
    }
  }
`;
