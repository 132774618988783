import { gql } from '@apollo/client';

export const listPartnerBrandingsQuery = gql`
  query ListPartnerBrandings($data: ListPartnerBrandingsInput!) {
    listPartnerBrandings(data: $data) {
      items
      data {
        idPartnerBranding
        name
        expirationDate
        imageUrl
        versionLock
      }
    }
  }
`;

export const deletePartnerBrandingsQuery = gql`
  mutation DeletePartnerBrandings($idsPartnerBrandings: [Int]!) {
    deletePartnerBrandings(idsPartnerBrandings: $idsPartnerBrandings)
  }
`;
