type Comment = {
  message?: string | null;
  idAttachment2?: {
    nameGivenByUser?: string;
    url?: string;
  };
};

export function parseCommentMessage(comment: Comment | undefined): string {
  const message = comment?.message;
  if (!message) return '';

  const attachment = comment.idAttachment2;

  let parsedMessage = message.replace(
    `<${attachment?.nameGivenByUser}>`,
    `<a href="${attachment?.url}">${attachment?.nameGivenByUser}</a>`,
  );

  parsedMessage = parsedMessage
    .replaceAll('#', window.location.origin)
    .replaceAll('href', 'target="_blank" href')
    .replaceAll(
      /@\[(.*?)\]@(?:\(\d+\)|Team\(\d+\))/g,
      '<span class="comment-mention">$1</span>',
    );

  return parsedMessage;
}
