import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  margin: 0 auto;

  left: 0;
  top: 0;

  z-index: 99999;
  width: 100vw;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  place-content: center;
  background-color: #fefefe;

  padding: 20px;
  border: 1px solid #888;
  width: 180px;
  height: 80px;
  border-radius: 8px;

  p {
    font-weight: 500;
    font-size: 20px;
    margin-left: 10px;
  }

  .p-progress-spinner {
    margin: unset;
    width: 30px;
  }
`;
