import { gql } from '@apollo/client';

export const createInlandFreightTaxesBulkQuery = gql`
  mutation createLogisticInlandFreightTaxesBulk($uploadedFile: String!) {
    createLogisticInlandFreightTaxesBulk(uploadedFile: $uploadedFile) {
      inlandFreightTaxesTotal
      createdInlandFreightTaxes
      inlandFreightTaxesWithError
    }
  }
`;

export const updateInlandFreightTaxesBulkQuery = gql`
  mutation updateLogisticInlandFreightTaxesBulk($uploadedFile: String!) {
    updateLogisticInlandFreightTaxesBulk(uploadedFile: $uploadedFile) {
      inlandFreightTaxesTotal
      updatedInlandFreightTaxes
      inlandFreightTaxesWithError
    }
  }
`;
