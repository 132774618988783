import { gql } from '@apollo/client';

export const listSatItemsBySatIdQuery = gql`
  query listSatItemsBySatId($data: ListSatItemsBySatIdInput!) {
    listSatItemsBySatId(data: $data) {
      data {
        idSatItem
        idProduct
        stCode
        namePt
        packageDescription
        packageDescriptionResponsibles
        packageNotes
        packageEan13
        innerdun14
        dun14
        extraInfo
        ncmAnuencias
        sample
        samplesApproved
        reviewNotes
        commercialReview
        designReview
        licensorApproval
        shippingMarks
        finalArtwork
        sketches
        idSatSupplier2 {
          idSupplier
        }
        idProduct2 {
          productImages {
            productImageTypes {
              type
            }
          }
        }
        satItemReferences {
          idSatItemReference
          colourEn
          satReferenceSizeBarcodes {
            idSatReferenceSizeBarcode
            sellingPackage
            innerBox
            masterBox
            reference
            commercialReview
            designReview
            finalArtwork
            sketches
          }
        }
        artworkPt
        techniquePt
      }
      items
    }
  }
`;
