import React, { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { object, string, ValidationError } from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { BeneficiaryFormData, BeneficiaryRouteParams } from './interfaces';
import useTitle from '../../../hooks/useTitle';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import getValidationErrors, {
  requiredFieldErrorMessage,
} from '../../../utils/getValidationErrors';
import { useRefHook } from '../../../hooks/useRefHook';
import {
  createBeneficiaryQuery,
  getBeneficiaryQuery,
  updateBeneficiaryQuery,
} from './queries';
import Loading from '../../../components/Loading';
import FormInputNumber from '../../../components/FormInputNumber';

const Beneficiary: React.FC = () => {
  const history = useHistory();

  const location = useLocation();

  const formRef = useRef<FormHandles>(null);
  const { showError, showSuccess } = useRefHook();

  const idBeneficiary = parseInt(
    useParams<BeneficiaryRouteParams>().idBeneficiary,
    10,
  );

  const [createBeneficiaryMutation, { loading: createBeneficiaryLoading }] =
    useMutation(createBeneficiaryQuery);
  const [updateBeneficiaryMutation, { loading: updateBeneficiaryLoading }] =
    useMutation(updateBeneficiaryQuery);

  const {
    data: beneficiaryData,
    loading: getBeneficiaryLoading,
    refetch,
  } = useQuery(getBeneficiaryQuery, {
    notifyOnNetworkStatusChange: true,
    skip: !idBeneficiary,
    variables: {
      idBeneficiary,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Beneficiary',
        detail: errorData.message,
      });
    },
    onCompleted: response => {
      formRef.current?.setData(response.getBeneficiary);
    },
  });

  const pageLoading =
    createBeneficiaryLoading ||
    getBeneficiaryLoading ||
    updateBeneficiaryLoading;

  const defaultPageTitle = `${idBeneficiary ? 'Manage' : 'Create'} Beneficiary`;

  useTitle(
    beneficiaryData?.getBeneficiary?.name
      ? `${beneficiaryData.getBeneficiary.name} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Busca dados de pagina inicial da url
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage') ?? '0',
    10,
  );
  // Busca dados de quantidade da url
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst') ?? '0',
    10,
  );

  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Beneficiaries',
      path:
        initialPage && initialFirst
          ? `/list/beneficiaries?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/list/beneficiaries',
    },
    {
      name: defaultPageTitle,
      path: location.pathname,
    },
  ];

  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/list/beneficiaries'),
    });
  }

  async function handleSubmit(data: BeneficiaryFormData) {
    try {
      formRef.current?.setErrors({});

      const schema = object().shape({
        name: string().required(requiredFieldErrorMessage),
        phone: string().nullable(),
        description: string().nullable(),
      });

      await schema.validate(data, { abortEarly: false });

      if (!idBeneficiary) {
        const response = await createBeneficiaryMutation({
          variables: {
            data,
          },
        });

        showSuccess({
          summary: 'Beneficiary created successfully',
        });

        history.replace(
          `/list/beneficiaries/${response.data.createBeneficiary.idBeneficiary}`,
        );
      } else {
        await updateBeneficiaryMutation({
          variables: {
            data: {
              ...data,
              idBeneficiary,
            },
          },
        });

        showSuccess({
          summary: 'Beneficiary updated successfully',
        });

        refetch();
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      } else {
        showError({
          summary: `Error while ${
            idBeneficiary ? 'updating' : 'creating'
          } Beneficiary`,
          detail: error.message,
        });
      }
    }
  }

  function renderChangedByUserInfo(field: 'createdAt' | 'updatedAt') {
    if (!idBeneficiary || !beneficiaryData?.getBeneficiary) return undefined;
    const beneficiary = beneficiaryData.getBeneficiary;

    const userField = field === 'createdAt' ? 'createdBy2' : 'updatedBy2';

    return (
      <p className={field}>
        {new Date(beneficiary[field]).toLocaleString()} by&nbsp;
        {beneficiary[userField]?.firstName} {beneficiary[userField]?.lastName}
      </p>
    );
  }

  return (
    <div>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          beneficiaryData?.getBeneficiary?.name
            ? `${defaultPageTitle} - ${beneficiaryData.getBeneficiary.name}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        <MainButton
          className="mainButton"
          label="Save"
          onClick={() => formRef.current?.submitForm()}
        />
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={handleCancel}
        />

        {renderChangedByUserInfo('createdAt')}
        {renderChangedByUserInfo('updatedAt')}
      </PageHeader>

      <Form
        className="formgrid grid p-5 max-w-max"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <FormInput
          className="field col-12 sm:col-7 md:col-5"
          name="name"
          label="Name"
          required
          autoComplete="off"
          maxLength={255}
        />
        <FormInput
          className="field col-12 sm:col-5 md:col-4"
          name="email"
          label="Email"
          type="email"
          autoComplete="off"
          maxLength={100}
        />
        <FormInput
          className="field col-12 sm:col-3"
          name="phone"
          label="Phone"
          type="tel"
          autoComplete="off"
          maxLength={20}
        />
        <FormInput
          className="field col-12 sm:col-9 md:col-12"
          name="description"
          label="Description"
          type="text"
          autoComplete="off"
          maxLength={255}
        />
        {!!idBeneficiary && (
          <>
            <FormCheckbox className="col" name="active" label="Active" />
            <FormInputNumber name="versionLock" label="Version Lock" hidden />
          </>
        )}
      </Form>

      {pageLoading && <Loading />}
    </div>
  );
};

export default Beneficiary;
