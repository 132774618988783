import { gql } from '@apollo/client';

/**
 * Query para listar SupplierAttachments
 */
export const listSupplierAttachmentsByIdSupplierQuery = gql`
  query ListSupplierAttachmentsByIdSupplier($idSupplier: Int!) {
    listSupplierAttachmentsByIdSupplier(idSupplier: $idSupplier) {
      items
      data {
        idSupplierAttachment
        idSupplier
        idAttachment
        idAttachment2 {
          idAttachment
          url
          name
          nameGivenByUser
        }
        createdAt
        createdBy2 {
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * Mutation para apagar SupplierAttachments
 */
export const deleteSupplierAttachmentsQuery = gql`
  mutation DeleteSupplierAttachments($supplierAttachmentIds: [Int]!) {
    deleteSupplierAttachments(supplierAttachmentIds: $supplierAttachmentIds)
  }
`;

export const createSupplierAttachmentsQuery = gql`
  mutation CreateSupplierAttachments($data: CreateSupplierAttachmentInput!) {
    createSupplierAttachments(data: $data) {
      items
      data {
        idSupplierAttachment
        idAttachment2 {
          idAttachment
          name
          url
          nameGivenByUser
        }
      }
    }
  }
`;
