import { gql } from '@apollo/client';

export const createPrcQuotationQuery = gql`
  mutation CreatePrcQuotation($data: CreatePrcQuotationInput!) {
    createPrcQuotation(data: $data) {
      idPrcQuotation
    }
  }
`;

export const updatePrcQuotationQuery = gql`
  mutation UpdatePrcQuotation($data: UpdatePrcQuotationInput!) {
    updatePrcQuotation(data: $data) {
      idPrcQuotation
    }
  }
`;
