export const oceanFreightRoles = {
  idEntity: 34,
  idModule: 10,
  permissions: {
    idPermissionDeleteOceanFreight: 53,
    idPermissionCreateOceanFreight: 51,
    idPermissionUpdateOceanFreight: 52,
    idPermissionMassiveActionOceanFreight: 55,
    idPermissionExportListOceanFreight: 57,
  },
};
