import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import { loadUsersByRolesOptions } from '../../../../../../shared/querys/user';
import { Role } from '../../../../../../shared/enums/role';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';

interface IUsersProps {
  foreignTrade: SatForeignTrade;
  shipmentIsCanceled?: boolean;
  fieldsPermissions: ForeignTradeFieldsPermissions;
}

const Users: React.FC<IUsersProps> = ({
  foreignTrade,
  shipmentIsCanceled,
  fieldsPermissions,
}) => {
  return (
    <div className="form-group grid">
      <FormTitle className="col-12" name="Users" />

      {fieldsPermissions.idPurchaseUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idPurchaseUser"
          label="Purchase User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.PURCHASE, Role.PURCHASE_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idPurchaseUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idPurchaseUser.edit
          }
          required
        />
      )}

      {fieldsPermissions.idSalesUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idSalesUser"
          label="1st Commercial User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idSalesUser2}
          readOnly={shipmentIsCanceled || !fieldsPermissions.idSalesUser.edit}
          required
        />
      )}

      {fieldsPermissions.idSalesSecondUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idSalesSecondUser"
          label="2nd Commercial User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.COMMERCIAL, Role.COMMERCIAL_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idSalesSecondUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idSalesSecondUser.edit
          }
        />
      )}

      {fieldsPermissions.idFinancialUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idFinancialUser"
          label="Financial User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.FINANCIAL, Role.FINANCIAL_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idFinancialUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idFinancialUser.edit
          }
        />
      )}

      {fieldsPermissions.idComexExportUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idComexExportUser"
          label="Export User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.COMEX_EXPORT, Role.COMEX_EXPORT_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idComexExportUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idComexExportUser.edit
          }
        />
      )}

      {fieldsPermissions.idComexImportUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idComexImportUser"
          label="Import User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.COMEX_IMPORT, Role.COMEX_IMPORT_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idComexImportUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idComexImportUser.edit
          }
        />
      )}

      {fieldsPermissions.idDesignerUser.view && (
        <FormAsyncSelect
          className="col-3"
          name="idDesignerUser"
          label="Designer User"
          loadOptions={loadUsersByRolesOptions}
          getOptionLabel={option =>
            `${option.firstName}  ${option.lastName} - ${option.email} (${option.username})`
          }
          getOptionValue={option => option.idUser}
          additional={{
            idsRoles: [Role.DESIGN, Role.DESIGN_KEY_USER],
          }}
          noOptionsMessage={() => 'No users found'}
          initialValue={foreignTrade.idDesignerUser2}
          readOnly={
            shipmentIsCanceled || !fieldsPermissions.idDesignerUser.edit
          }
        />
      )}
    </div>
  );
};

export default Users;
