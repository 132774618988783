import { gql } from '@apollo/client';

export const updateSatForeignTradeSuppliersQuery = gql`
  mutation UpdateSatForeignTradeSuppliers(
    $data: [UpdateSatForeignTradeSuppliersInput]!
  ) {
    updateSatForeignTradeSuppliers(data: $data) {
      warnings
    }
  }
`;

export const deleteSatForeignTradePaymentQuery = gql`
  mutation DeleteSatForeignTradePayment($satForeignTradePaymentId: Int!) {
    deleteSatForeignTradePayment(
      satForeignTradePaymentId: $satForeignTradePaymentId
    )
  }
`;

export const createSatForeignTradePaymentQuery = gql`
  mutation CreateSatForeignTradePayment(
    $data: CreateSatForeignTradePaymentInput!
  ) {
    createSatForeignTradePayment(data: $data) {
      idSatForeignTradePayment
    }
  }
`;

export const updateSatForeignTradePaymentQuery = gql`
  mutation UpdateSatForeignTradePayment(
    $data: UpdateSatForeignTradePaymentInput!
  ) {
    updateSatForeignTradePayment(data: $data) {
      idSatForeignTradePayment
    }
  }
`;

export const listDomainById = gql`
  query ListDomainById($idDomain: Int!) {
    listDomainById(id: $idDomain) {
      idDomain
      description
    }
  }
`;
