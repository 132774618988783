import {
  ManagePackageDescriptionReducer,
  ManagePackageDescriptionReducerAction,
  ManagePackageDescriptionReducerActionKind,
} from './interfaces';

export const managePackageDescriptionInitialState: ManagePackageDescriptionReducer =
  {
    displayDialog: false,
  };

export const managePackageDescriptionReducer = (
  state: ManagePackageDescriptionReducer,
  action: ManagePackageDescriptionReducerAction,
): ManagePackageDescriptionReducer => {
  switch (action.type) {
    case ManagePackageDescriptionReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        packageDescription: undefined,
      };

    case ManagePackageDescriptionReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.packageDescription) {
        return {
          ...state,
          packageDescription: action.payload?.packageDescription,
          displayDialog: !state.displayDialog,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    default:
      throw new Error();
  }
};
