export const airFreightRoles = {
  idEntity: 38,
  idModule: 10,
  permissions: {
    idPermissionDeleteAirFreight: 73,
    idPermissionCreateAirFreight: 71,
    idPermissionUpdateAirFreight: 72,
    idPermissionMassiveActionAirFreight: 74,
    idPermissionExportListAirFreight: 75,
  },
};
