import { gql } from '@apollo/client';

export const getToAdvancePaymentRequestQuery = gql`
  query GetSatForeignTradeToAdvancePaymentRequest($idSatForeignTrade: Int!) {
    getSatForeignTradeToAdvancePaymentRequest(
      idSatForeignTrade: $idSatForeignTrade
    ) {
      mainProduct
      currency
      totalSunPi
      estimatedTotalAdvance
      paymentTerm
      qtyTypeCtnr
      buyer
      clientOrder
    }
  }
`;

export const sendAdvancePaymentRequestQuery = gql`
  mutation SendAdvancePaymentRequest($data: SendAdvancePaymentRequestInput!) {
    sendAdvancePaymentRequest(data: $data)
  }
`;

export const generateSellerPISimplifiedQuery = gql`
  query generateSellerPISimplified($idSat: Int!) {
    generateSellerPISimplified(idSat: $idSat)
  }
`;
