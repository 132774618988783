/* eslint-disable no-useless-concat */
import React, { Dispatch, RefObject, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import FormCheckbox from '../../../components/FormCheckbox';
import { Container } from './styles';

import FormInput from '../../../components/FormInput';

import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import Button from '../../../components/Button';
import { IListUserResponse } from '..';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import { IRolesRef } from '../Roles';
import { IRoleEntityField } from '../../../interfaces/IRoleEntityField';
import getFieldPermission from '../../../utils/getFieldPermission';
import { userRoles } from '../../../shared/roles/user';
import ToastLife from '../../../shared/enums/toastLife';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import { asyncSelectLoadOffices } from '../../../shared/querys/office';

interface IGeneralInformationFormData {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  namePrefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  nameSuffix?: string;
  birthday?: string;
  userOffices: number[];
  phone?: string;
  title?: string;
  active: boolean;
}

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: string;
  setUserName: Dispatch<React.SetStateAction<string>>;
  user?: IListUserResponse;
  userId?: number;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  rolesRef: RefObject<IRolesRef>;
  roleEntityFields: IRoleEntityField[];
  versionLock?: number;
  setVersionLock: Dispatch<React.SetStateAction<number | undefined>>;
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  avatar,
  setUserName,
  user,
  selected,
  userId,
  setLoading,
  rolesRef,
  roleEntityFields,
  versionLock,
  setVersionLock,
}) => {
  // Referencia ao formulario e toast
  const { formRef, toastRef } = useRefHook();

  const [isUserActive, setIsUserActive] = useState(user?.active === 'S');

  const {
    idFieldPhone,
    idFieldNamePrefix,
    idFieldMiddleName,
    idFieldNameSuffix,
    idFieldTitle,
    idFieldBirthday,
    idFieldUserOffice,
    idFieldActive,
  } = userRoles.fields;

  // Redirect
  const history = useHistory();

  // Mutation de criacao de usuario
  const createUserQuery = gql`
    mutation CreateUserMutation($createUserUser: CreateUser) {
      createUser(user: $createUserUser) {
        idUser
        username
      }
    }
  `;

  // Mutation de update de usuario
  const updateUserQuery = gql`
    mutation UpdateUserMutation($updateUser: UpdateUser) {
      updateUser(user: $updateUser) {
        idUser
        username
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createUserMutation] = useMutation(createUserQuery);
  const [updateUserMutation] = useMutation(updateUserQuery);

  // Submit do formulario de criacao de usuario
  const handleCreateUserSubmit = useCallback(
    async (data: IGeneralInformationFormData) => {
      setLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          username: Yup.string().required('Enter an username'),
          email: Yup.string().email().required('Enter an email'),
          password: Yup.string().required('Enter a password'),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match',
          ),
          firstName: Yup.string().required('Insert a first name'),
          lastName: Yup.string().required('Insert a last name'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Separa cada propriedade
        const {
          email,
          firstName,
          lastName,
          password,
          username,
          birthday,
          userOffices,
          middleName,
          namePrefix,
          nameSuffix,
          phone,
          title,
          active,
        } = data;

        // Chamada criacao de usuario
        const response = await createUserMutation({
          variables: {
            createUserUser: {
              email,
              firstName,
              lastName,
              password,
              username,
              birthday,
              userOffices,
              middleName,
              namePrefix,
              nameSuffix,
              phone,
              title,
              active: active ? 'S' : 'N',
              uploadedFile: avatar,
              versionLock,
            },
          },
        });

        rolesRef.current?.handleUpdateRoles(response.data.createUser.idUser);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'User created',
          life: ToastLife.SUCCESS,
        });

        // Direciona usuario para listagem de usuarios
        history.push('/users');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating user',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      formRef,
      createUserMutation,
      avatar,
      versionLock,
      rolesRef,
      toastRef,
      history,
    ],
  );

  // Submit do formulario de edicao de usuario
  const handleEditUserSubmit = useCallback(
    async (data: IGeneralInformationFormData) => {
      setLoading(true);
      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          email: Yup.string().email().required('Enter an email'),
          password: Yup.string(),
          passwordConfirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match',
          ),
          firstName: Yup.string().required('Insert a first name'),
          lastName: Yup.string().required('Insert a last name'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Separa cada propriedade
        const {
          email,
          firstName,
          lastName,
          password,
          birthday,
          userOffices,
          middleName,
          namePrefix,
          nameSuffix,
          phone,
          title,
        } = data;

        // Chamada criacao de usuario
        const updtUserResponse = await updateUserMutation({
          variables: {
            updateUser: {
              idUser: userId,
              email,
              firstName,
              lastName,
              password,
              birthday,
              userOffices,
              middleName,
              namePrefix,
              nameSuffix,
              phone,
              title,
              active: isUserActive ? 'S' : 'N',
              uploadedFile: avatar,
              versionLock,
            },
          },
        });

        rolesRef.current?.handleUpdateRoles();
        setVersionLock(updtUserResponse.data.updateUser.versionLock);

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: 'User updated',
          life: ToastLife.SUCCESS,
        });

        setUserName(`${firstName}` + ' ' + `${lastName}`);

        // Direciona usuario para listagem de usuarios
        // history.push('/users');
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while updating user',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      formRef,
      updateUserMutation,
      userId,
      isUserActive,
      avatar,
      versionLock,
      rolesRef,
      setVersionLock,
      toastRef,
      setUserName,
    ],
  );

  /**
   * Gera senha e preenche nos inputs
   */
  function suggestPassword() {
    // Tamanho da senha
    const length = 8;
    // Lista de caracteres desejaveis na senha
    const wishlist =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';

    // Gera senha com base nos parametros acima
    const generatedPassword = Array.from(
      crypto.getRandomValues(new Uint32Array(length)),
    )
      .map(x => wishlist[x % wishlist.length])
      .join('');

    // Define senha gerada para o input de password
    formRef.current?.setFieldValue('password', generatedPassword);
    // Busca referencia do input de password
    const passRef = formRef.current?.getFieldRef('password');
    // Muda o tipo do campo para texto plano, exibindo a senha gerada
    passRef.setAttribute('type', 'text');
    // Define senha gerada para o input de confirmacao de senha
    formRef.current?.setFieldValue('passwordConfirmation', generatedPassword);
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <Form
          ref={formRef}
          onSubmit={userId ? handleEditUserSubmit : handleCreateUserSubmit}
          initialData={user}
        >
          <h1>General</h1>

          <FormInput
            name="username"
            label="Username"
            required={!userId}
            readOnly={!!userId}
          />

          <div className="form-group">
            <FormInput name="firstName" label="First Name" required />
            {getFieldPermission(idFieldMiddleName, roleEntityFields).view && (
              <FormInput
                name="middleName"
                label="Middle Name"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldMiddleName, roleEntityFields)
                        .edit
                    : !getFieldPermission(idFieldMiddleName, roleEntityFields)
                        .create
                }
              />
            )}
            <FormInput name="lastName" label="Last Name" required />
          </div>

          <FormInput name="email" label="Email" required />

          <div className="form-group">
            {getFieldPermission(idFieldNamePrefix, roleEntityFields).view && (
              <FormInput
                name="namePrefix"
                label="Name Prefix"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldNamePrefix, roleEntityFields)
                        .edit
                    : !getFieldPermission(idFieldNamePrefix, roleEntityFields)
                        .create
                }
              />
            )}
            {getFieldPermission(idFieldNameSuffix, roleEntityFields).view && (
              <FormInput
                name="nameSuffix"
                label="Name Suffix"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldNameSuffix, roleEntityFields)
                        .edit
                    : !getFieldPermission(idFieldNameSuffix, roleEntityFields)
                        .create
                }
              />
            )}
            {getFieldPermission(idFieldPhone, roleEntityFields).view && (
              <FormInput
                name="phone"
                label="Phone"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldPhone, roleEntityFields).edit
                    : !getFieldPermission(idFieldPhone, roleEntityFields).create
                }
              />
            )}
          </div>

          <div className="form-group">
            {getFieldPermission(idFieldTitle, roleEntityFields).view && (
              <FormInput
                name="title"
                label="Title"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldTitle, roleEntityFields).edit
                    : !getFieldPermission(idFieldTitle, roleEntityFields).create
                }
              />
            )}
            {getFieldPermission(idFieldBirthday, roleEntityFields).view && (
              <FormInput
                type="date"
                name="birthday"
                label="Birthday"
                readOnly={
                  userId
                    ? !getFieldPermission(idFieldBirthday, roleEntityFields)
                        .edit
                    : !getFieldPermission(idFieldBirthday, roleEntityFields)
                        .create
                }
              />
            )}
            {getFieldPermission(idFieldUserOffice, roleEntityFields).view && (
              <span className="formInput">
                <FormAsyncSelect
                  name="userOffices"
                  label="Office"
                  required
                  loadOptions={asyncSelectLoadOffices}
                  isMulti
                  closeMenuOnSelect={false}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.officeName}
                  getOptionValue={(option: any) => option.idOffice}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No offices found'}
                  initialValue={() =>
                    user?.userOffices
                      ? user?.userOffices.map(userOffice => {
                          return {
                            officeName: userOffice.idOffice2.officeName,
                            idOffice: userOffice.idOffice2.idOffice,
                          };
                        })
                      : undefined
                  }
                  readOnly={
                    userId
                      ? !getFieldPermission(idFieldUserOffice, roleEntityFields)
                          .edit
                      : !getFieldPermission(idFieldUserOffice, roleEntityFields)
                          .create
                  }
                />
              </span>
            )}
          </div>

          <h1>Password</h1>

          <FormInput
            type="password"
            name="password"
            label="Password"
            required={!userId}
          />
          <FormInput
            type="password"
            name="passwordConfirmation"
            label="Re-Enter Password"
            required={!userId}
          />
          <Button
            type="button"
            label="Suggest Password"
            className="suggest-password"
            onClick={() => {
              suggestPassword();
            }}
          />

          {getFieldPermission(idFieldActive, roleEntityFields).view && (
            <FormCheckbox
              name="activeTest"
              label="Active"
              className="activeCheckbox"
              value={isUserActive}
              initialValue={isUserActive}
              onValueChange={e => {
                setIsUserActive(!!e.checked);
                return true;
              }}
              readOnly={
                userId
                  ? !getFieldPermission(idFieldActive, roleEntityFields).edit
                  : !getFieldPermission(idFieldActive, roleEntityFields).create
              }
            />
          )}
        </Form>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
