import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { gql, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { GridHeader } from './styles';

import AdvancedFiltersPanel from '../../../../components/AdvancedFiltersPanel';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import { gridConstants } from '../../../../components/Grid/constants';
import {
  ColumnData,
  DataTableMultiSortMetaType,
} from '../../../../components/Grid/interfaces';
import MultiSelect from '../../../../components/Grid/MultiSelect';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import pagination, {
  searchDelayMiliseconds,
} from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import ILazyParams from '../../../../services/lazyParams';
import ToastLife from '../../../../shared/enums/toastLife';
import { productRoles } from '../../../../shared/roles/product';
import { showField } from '../../../../utils/showField';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';
import { gridColumnsData } from './constants';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../../components/Grid/utils';

interface ISatsProps extends PageTabContainerProps {
  idProduct?: number;
  roleEntityFields: IRoleEntityField[];
}

/**
 * Interface dos parametros do backend
 */
interface ISatsLazyParams extends ILazyParams {
  [key: string]: number | string | DataTableMultiSortMetaType | undefined;
  satNumber?: string;
  companyName?: string;
  clientCnpj?: string;
  importer?: string;
  importerCnpj?: string;
  username?: string;
  port?: string;
  paymentTermAdvance?: number;
  paymentTermBalance?: number;
}

const Sats: React.FC<ISatsProps> = ({
  selected,
  idProduct,
  roleEntityFields,
}) => {
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:productSatsGridColumns';

  const lazyParamsName = '@SAT:productSatsLazyParams';

  const advancedFiltersName = '@SAT:productSatsAdvancedFilters';

  // Referencia ao toast
  const { toastRef } = useRefHook();

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);
  const gridRef = useRef<DataTable<any>>(null);

  // Referencia ao painel de advancd filters
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  // Estado inicial de lazy params
  const initialLazyParams = {
    first: 0,
    rows: 25,
    page: 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ISatsLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback((newLazyParams: ISatsLazyParams) => {
    const lazyParamsToSave = {
      ...newLazyParams,
      ...pagination.initialLazyParams,
    };
    localStorage.setItem(lazyParamsName, JSON.stringify(lazyParamsToSave));
    updateLocalStorageInDb(lazyParamsName, lazyParamsToSave);
  }, []);

  const changeLazyParams = useCallback(
    (newLazyParams: ISatsLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  // Query para listar price history
  const listSatsQuery = gql`
    query listSatsItemsByProductIdQuery($data: ListSatsItemsByProductIdInput!) {
      listSatsItemsByProductId(data: $data) {
        data {
          idSatItem
          idProduct
          idSat
          idSat2 {
            idSat
            idPort
            ${
              showField(productRoles.fields.idFieldClientCnpj, roleEntityFields)
                ? 'clientCnpj'
                : ''
            }
            ${
              showField(productRoles.fields.idFieldSatClient, roleEntityFields)
                ? 'companyName'
                : ''
            }
            ${
              showField(
                productRoles.fields.idFieldImporterCnpj,
                roleEntityFields,
              )
                ? 'importerCnpj'
                : ''
            }
            ${
              showField(
                productRoles.fields.idFieldPaymentTermAdvance,
                roleEntityFields,
              )
                ? 'paymentTermAdvance'
                : ''
            }
            ${
              showField(
                productRoles.fields.idFieldPaymentTermBalance,
                roleEntityFields,
              )
                ? 'paymentTermBalance'
                : ''
            }
            importerAddress
            ${
              showField(productRoles.fields.idFieldImporter, roleEntityFields)
                ? 'importerName'
                : ''
            }
            paymentTermCondition
            ${
              showField(productRoles.fields.idFieldSatNumber, roleEntityFields)
                ? 'satNumber'
                : ''
            }
            typeOfOrder
            idImporter
            idSalesUser
            ${
              showField(productRoles.fields.idFieldImporter, roleEntityFields)
                ? `idImporter2 {
              idClient
              name
            }`
                : ''
            }
            ${
              showField(productRoles.fields.idFieldSales, roleEntityFields)
                ? `idSalesUser2 {
              username
              email
            }`
                : ''
            }
            ${
              showField(
                productRoles.fields.idFieldPortOfDischarge,
                roleEntityFields,
              )
                ? `idPort2 {
              name
            }`
                : ''
            }
          }
        }
        items
      }
    }
  `;

  /**
   * Busca Price history
   */
  const { loading: satsLoading, data: satsData } = useQuery(listSatsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        idProduct,
        globalSearch: lazyParams.globalFilter,
        satNumber: lazyParams.satNumber,
        companyName: lazyParams.companyName,
        clientCnpj: lazyParams.clientCnpj,
        importer: lazyParams.importer,
        importerCnpj: lazyParams.importerCnpj,
        username: lazyParams.username,
        port: lazyParams.port,
        paymentTermAdvance: lazyParams.paymentTermAdvance,
        paymentTermBalance: lazyParams.paymentTermBalance,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting SATs data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList: ColumnData[] = [];

    if (showField(productRoles.fields.idFieldSatNumber, roleEntityFields)) {
      columnList.push(gridColumnsData.satNumber);
    }

    if (showField(productRoles.fields.idFieldSatClient, roleEntityFields)) {
      columnList.push(gridColumnsData.companyName);
    }

    if (showField(productRoles.fields.idFieldClientCnpj, roleEntityFields)) {
      columnList.push(gridColumnsData.clientCnpj);
    }

    if (showField(productRoles.fields.idFieldImporter, roleEntityFields)) {
      columnList.push(gridColumnsData.importer);
    }

    if (showField(productRoles.fields.idFieldImporterCnpj, roleEntityFields)) {
      columnList.push(gridColumnsData.importerCnpj);
    }

    if (showField(productRoles.fields.idFieldSales, roleEntityFields)) {
      columnList.push(gridColumnsData.idSales);
    }

    if (
      showField(productRoles.fields.idFieldPortOfDischarge, roleEntityFields)
    ) {
      columnList.push(gridColumnsData.port);
    }

    if (
      showField(productRoles.fields.idFieldPaymentTermAdvance, roleEntityFields)
    ) {
      columnList.push(gridColumnsData.paymentTermAdvance);
    }

    if (
      showField(productRoles.fields.idFieldPaymentTermBalance, roleEntityFields)
    ) {
      columnList.push(gridColumnsData.paymentTermBalance);
    }

    return columnList;
  }, [roleEntityFields]);

  // Inputs para advanced filters
  const advancedFiltersInputsConst = useMemo(() => {
    return [
      { field: 'idSat2.satNumber', header: 'SAT Number', value: '' },
      { field: 'idSat2.companyName', header: 'Client', value: '' },
      { field: 'idSat2.clientCnpj', header: 'Tax ID/CNPJ', value: '' },
      { field: 'idSat2.idImporter2.name', header: 'Importer', value: '' },
      { field: 'idSat2.importerCnpj', header: 'Tax ID', value: '' },
      { field: 'idSat2.idSalesUser2.username', header: 'Sales', value: '' },
      { field: 'idSat2.idPort2.name', header: 'Port of Discharge', value: '' },
      {
        field: 'idSat2.paymentTermAdvance',
        header: 'Payment Term Advance',
        value: '',
      },
      {
        field: 'idSat2.paymentTermBalance',
        header: 'Payment Term Balance',
        value: '',
      },
    ];
  }, []);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Colunas da grid que possuem filtro aplicado
  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    changeLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Retorna coluna SAT Number com Link para pagina de SAT
   * @param rowData Dados da linha
   * @returns Coluna SAT Number com Link para pagina de SAT
   */
  const parseSatColumn = (rowData: any) => {
    return (
      rowData.idSat && (
        <Link
          to={`/commercial/sats/${rowData.idSat}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.idSat2.satNumber}
          <FiExternalLink size={15} />
        </Link>
      )
    );
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'idSat2.satNumber':
        return parseSatColumn;
      default:
        return undefined;
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={{ flex: 'auto', minWidth: '12rem' }}
          sortable
        />
      )
    );
  });

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns,
      );
      setSelectedColumns(allowedColumns);
    } else {
      setSelectedColumns(columns);
    }
  }, [advancedFiltersInputsConst, columns]);

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <PageTabContainer className="overflow-hidden" selected={selected}>
      <GridHeader className="my-2">
        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search a SAT"
        />
        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          type="button"
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
          type="button"
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: pagination.initialLazyParams.first,
              page: pagination.initialLazyParams.page,
              rows: pagination.initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
        />
      </GridHeader>
      <Grid
        ref={gridRef}
        name="productSats"
        lazy
        totalRecords={
          !satsData || !satsData.listSatsItemsByProductId
            ? 0
            : satsData.listSatsItemsByProductId.items
        }
        value={
          !satsData || !satsData.listSatsItemsByProductId
            ? undefined
            : satsData.listSatsItemsByProductId.data
        }
        globalFilter={globalFilter}
        loading={satsLoading}
        emptyMessage="No SATs found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight={gridConstants.internalGridScrollHeight}
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
      >
        {dynamicColumns}
      </Grid>
    </PageTabContainer>
  );
};

export default Sats;
