/* eslint-disable no-shadow */
export enum CurrencyAbbreviation {
  BRL = 1,
  RMB = 2,
  EGP = 3,
  EUR = 4,
  GBP = 5,
  HKD = 6,
  INR = 7,
  USD = 8,
}
