/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import * as Yup from 'yup';
import BreadCrumb, { IBreadCrumbItem } from '../../../../components/BreadCrumb';
import Button from '../../../../components/Button';
import FormInputMask from '../../../../components/FormInputMask';
import FormInputNumber from '../../../../components/FormInputNumber';
import FormInput from '../../../../components/FormInput';
import MainButton from '../../../../components/MainButton';
import PageHeader from '../../../../components/PageHeader';
import Tag from '../../../../components/Tag';
import { useRefHook } from '../../../../hooks/useRefHook';
import { INcm, NcmRouteParams } from './interfaces';
import { Container, CrudForm, GeneralInformation, Row } from './styles';
import FormDropdown from '../../../../components/FormDropdown';
import { booleanOptions } from '../../../../shared/options/boolean-options';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import Loading from '../../../../components/Loading';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useAuth } from '../../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import { ncmRoles } from '../../../../shared/roles/ncm';
import getFieldPermission from '../../../../utils/getFieldPermission';
import userHasPermission from '../../../../utils/userHasPermission';
import FormInputTextArea from '../../../../components/FormInputTextArea';
import ToastLife from '../../../../shared/enums/toastLife';
import useTitle from '../../../../hooks/useTitle';

const Ncm: React.FC = () => {
  // URL
  const { pathname } = useLocation();

  // ID do NCM
  const { ncmId } = useParams<NcmRouteParams>();

  const defaultPageTitle = `${ncmId ? 'Manage' : 'Create'} NCM`;

  const [ncm, setNcm] = useState<INcm>({} as INcm);

  useTitle(
    ncm?.ncmName ? `${ncm?.ncmName} - ${defaultPageTitle}` : defaultPageTitle,
  );

  const [li, setLi] = useState();

  const [linkDumping, setLinkDumping] = useState<string>();

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Redirect
  const history = useHistory();

  // URL params
  const location = useLocation();

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    ncmRoles.idEntity,
  );

  const roleEntityFields = userPermissions.userFields;

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );

  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'NCMs',
      path:
        initialPage && initialFirst
          ? `/products/ncms?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/products/ncms',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  const {
    idFieldAnuencias,
    idFieldBaseDumping,
    idFieldDestaque,
    idFieldDumping,
    idFieldExclusaoTTD,
    idFieldIcmsST,
    idFieldLi,
    idFieldLinkDumping,
    idFieldListaCamex,
    idFieldMva,
    idFieldNotes,
    idFieldNve1,
    idFieldNve2,
    idFieldNve3,
    idFieldNve4,
    idFieldNve5,
    idFieldNve6,
    idFieldPriceMin,
  } = ncmRoles.fields;

  const { idPermissionUpdateNcm, idPermissionCreateNcm } = ncmRoles.permissions;

  // Valida se usuario pode criar ou atualizar NCM
  const userCanChange = userHasPermission(
    ncmId ? idPermissionUpdateNcm : idPermissionCreateNcm,
    userPermissions.userPermissions,
  );

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return ncm.idNcm
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  // Query para listar Sat
  const listNcmQuery = gql`
    query listNcmQuery($idNcm: Int!) {
      listNcmById(idNcm: $idNcm) {
        active
        idNcm
        ncmName
        ncm
        description
        ${showField(idFieldDestaque) ? 'destaque' : ''}
        ${showField(idFieldNve1) ? 'nve1' : ''}
        ${showField(idFieldNve2) ? 'nve2' : ''}
        ${showField(idFieldNve3) ? 'nve3' : ''}
        ${showField(idFieldNve4) ? 'nve4' : ''}
        ${showField(idFieldNve5) ? 'nve5' : ''}
        ${showField(idFieldNve6) ? 'nve6' : ''}
        nveDesc1
        nveDesc2
        nveDesc3
        nveDesc4
        nveDesc5
        nveDesc6
        ${showField(idFieldMva) ? 'mva' : ''}
        ${showField(idFieldLi) ? 'li' : ''}
        ${showField(idFieldAnuencias) ? 'anuencias' : ''}
        ${showField(idFieldDumping) ? 'dumping' : ''}
        ${showField(idFieldBaseDumping) ? 'baseDumping' : ''}
        ${showField(idFieldLinkDumping) ? 'linkDumping' : ''}
        ${showField(idFieldPriceMin) ? 'priceMin' : ''}
        ${showField(idFieldListaCamex) ? 'listaCamex' : ''}
        ${showField(idFieldExclusaoTTD) ? 'exclusaoTtd' : ''}
        ${showField(idFieldIcmsST) ? 'icmsSt' : ''}
        ii
        ipi
        pis
        cofins
        ${showField(idFieldNotes) ? 'notes' : ''}
        createdAt
        createdBy
        createdBy2 {
          firstName
          lastName
        }
        updatedAt
        updatedBy
        updatedBy2 {
          firstName
          lastName
        }
        versionLock
      }
    }
  `;

  /**
   * Busca NCM
   */
  const [loadNcmData, { loading: ncmLoading }] = useLazyQuery(listNcmQuery, {
    variables: {
      idNcm: parseInt(ncmId, 10),
    },
    onCompleted: response => {
      setLoading(false);
      setNcm(response.listNcmById);
      setLi(response.listNcmById.li);
      setLinkDumping(response.listNcmById.linkDumping);
    },
    onError: errorData => {
      setLoading(false);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting NCM data',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Ao carregar a pagina, carrega dados do ncm
  useEffect(() => {
    if (ncmId) {
      setLoading(true);
      loadNcmData();
    }
  }, [loadNcmData, ncmId]);

  // Query para listar base dumping
  const listBaseDumpingQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $isActive: Boolean
      $pagination: Pagination!
    ) {
      listDomainsByGroupId(
        id: $id
        isActive: $isActive
        pagination: $pagination
      ) {
        data {
          idDomain
          description
        }
      }
    }
  `;

  /**
   * Busca Base Dumping
   */
  const {
    loading: baseDumpingLoading,
    data: baseDumpingData,
    error: baseDumpingError,
  } = useQuery(listBaseDumpingQuery, {
    variables: {
      id: DomainGroup.BASE_DUMPING,
      isActive: true,
      pagination: {
        _page: 1,
        _limit: 0,
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting base dumping',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Mutation de criacao de Ncm
  const createNcmQuery = gql`
    mutation CreateNcmMutation($ncm: CreateNcmInput) {
      createNcm(ncm: $ncm) {
        idNcm
      }
    }
  `;

  // Mutation de atualizacao de Ncm
  const updateNcmQuery = gql`
    mutation UpdateNcmMutation($ncm: UpdateNcmInput!) {
      updateNcm(ncm: $ncm) {
        idNcm
      }
    }
  `;

  function isEmptyNullOrUndefined(value: any): boolean {
    return value === null || value === undefined || value === '';
  }

  // cria método para chamar a mutation
  const [createNcmMutation] = useMutation(createNcmQuery);
  const [updateNcmMutation] = useMutation(updateNcmQuery);

  // Submit do formulario de criacao de Ncm
  const handleCreateNcmSubmit = useCallback(
    async (data: INcm) => {
      setLoading(true);
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          ncm: Yup.string()
            .required('Required Field')
            .test(
              'len',
              'Invalid NCM number',
              val => val?.replaceAll('.', '').length === 8,
            ),
          ncmName: Yup.string().required('Required Field'),
          description: Yup.string().required('Required Field'),
          ii: Yup.number().nullable().required('Required Field'),
          // li: Yup.boolean(),
          anuencias: Yup.string().when('li', {
            is: true,
            then: Yup.string().required('Required Field'),
          }),
          ipi: Yup.number().nullable().required('Required Field'),
          pis: Yup.number().nullable().required('Required Field'),
          cofins: Yup.number().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        const objectToSave = {
          ncm: data.ncm
            ? parseInt(data.ncm.toString().replaceAll('.', ''), 10)
            : undefined,
          ncmName: data.ncmName,
          description: data.description,
          destaque: data.destaque
            ? parseInt(data.destaque.toString(), 10)
            : undefined,
          nve1: data.nve1,
          nveDesc1: data.nveDesc1,
          nve2: data.nve2,
          nveDesc2: data.nveDesc2,
          nve3: data.nve3,
          nveDesc3: data.nveDesc3,
          nve4: data.nve4,
          nveDesc4: data.nveDesc4,
          nve5: data.nve5,
          nveDesc5: data.nveDesc5,
          nve6: data.nve6,
          nveDesc6: data.nveDesc6,
          ii: data.ii,
          ipi: data.ipi,
          pis: data.pis,
          cofins: data.cofins,
          mva: !isEmptyNullOrUndefined(data.mva) ? data.mva : null,
          li: !isEmptyNullOrUndefined(data.li) ? data.li : null,
          anuencias: data.anuencias,
          dumping: !isEmptyNullOrUndefined(data.dumping) ? data.dumping : null,
          baseDumping: !isEmptyNullOrUndefined(data.baseDumping)
            ? data.baseDumping
            : undefined,
          linkDumping: data.linkDumping,
          priceMin: !isEmptyNullOrUndefined(data.priceMin)
            ? data.priceMin
            : null,
          listaCamex: !isEmptyNullOrUndefined(data.listaCamex)
            ? data.listaCamex
            : null,
          icmsSt: !isEmptyNullOrUndefined(data.icmsSt) ? data.icmsSt : null,
          exclusaoTtd: !isEmptyNullOrUndefined(data.exclusaoTtd)
            ? data.exclusaoTtd
            : null,
          notes: data.notes,
        };

        let response;
        if (!ncmId) {
          response = await createNcmMutation({
            variables: {
              ncm: {
                ...objectToSave,
                versionLock: 0,
              },
            },
          });
        } else {
          response = await updateNcmMutation({
            variables: {
              ncm: {
                ...objectToSave,
                idNcm: parseInt(ncmId, 10),
                versionLock: ncm.versionLock,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `NCM ${ncmId ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!ncmId) {
          history.push(`/products/ncm/${response.data.createNcm.idNcm}`);
        } else {
          setNcm({} as INcm);
          loadNcmData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);
        } else {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while creating NCM',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      ncm,
      ncmId,
      formRef,
      history,
      toastRef,
      setNcm,
      loadNcmData,
      createNcmMutation,
      updateNcmMutation,
    ],
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/products/ncms'),
    });
  }

  function handleLiChange(e: any) {
    formRef.current?.setFieldValue('anuencias', '');
    setLi(e);
  }

  function onBlurNve(e: any, field: string) {
    const value = e.target.value.toString();
    formRef.current?.setFieldValue(field, value.toUpperCase());
  }

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />
      <PageHeader
        title={
          ncm?.ncmName
            ? `${defaultPageTitle} - ${ncm.ncmName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {ncmId ? (
          ncm.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}

        <p className="createdAt">
          {ncm.createdAt && !ncmLoading
            ? `${new Date(ncm.createdAt).toLocaleString()} by ${
                ncm.createdBy2?.firstName
              } ${ncm.createdBy2?.lastName}`
            : ''}
        </p>
        <p className="updatedAt">
          {ncm.updatedAt && !ncmLoading
            ? `${new Date(ncm.updatedAt).toLocaleString()} by ${
                ncm.updatedBy2?.firstName
              } ${ncm.updatedBy2?.lastName}`
            : ''}
        </p>

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}
        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>
      <CrudForm>
        <GeneralInformation>
          {((ncmId && !ncmLoading && ncm.active !== undefined) || !ncmId) && (
            <Form
              ref={formRef}
              initialData={ncm}
              onSubmit={handleCreateNcmSubmit}
            >
              <h1>General Information</h1>
              <Row>
                <FormInput
                  className="largeInput"
                  name="ncmName"
                  label="NCM Name"
                  required
                  readOnly={!userCanChange}
                />
                <FormInputMask
                  className="smallInput"
                  name="ncm"
                  label="NCM"
                  mask="9999.99.99"
                  required
                  readOnly={!userCanChange}
                />
              </Row>
              <Row>
                <FormInput
                  className="largeInput"
                  name="description"
                  label="Descrição"
                  required
                  readOnly={!userCanChange}
                />
                {showField(idFieldDestaque) && (
                  <FormInputMask
                    className="smallInput"
                    name="destaque"
                    label="Destaque"
                    mask="999"
                    autoClear={false}
                    readOnly={disableField(idFieldDestaque) || !userCanChange}
                  />
                )}
              </Row>
              <Row>
                {showField(idFieldNve1) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve1"
                    label="NVE 1"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve1')}
                    readOnly={disableField(idFieldNve1) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc1"
                  label="Descrição 1"
                  readOnly={!userCanChange}
                />
                {showField(idFieldNve2) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve2"
                    label="NVE 2"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve2')}
                    readOnly={disableField(idFieldNve2) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc2"
                  label="Descrição 2"
                  readOnly={!userCanChange}
                />
              </Row>
              <Row>
                {showField(idFieldNve3) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve3"
                    label="NVE 3"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve3')}
                    readOnly={disableField(idFieldNve3) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc3"
                  label="Descrição 3"
                  readOnly={!userCanChange}
                />
                {showField(idFieldNve4) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve4"
                    label="NVE 4"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve4')}
                    readOnly={disableField(idFieldNve4) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc4"
                  label="Descrição 4"
                  readOnly={!userCanChange}
                />
              </Row>
              <Row>
                {showField(idFieldNve5) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve5"
                    label="NVE 5"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve5')}
                    readOnly={disableField(idFieldNve5) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc5"
                  label="Descrição 5"
                  readOnly={!userCanChange}
                />
                {showField(idFieldNve6) && (
                  <FormInputMask
                    className="tinyInput"
                    name="nve6"
                    label="NVE 6"
                    mask="aa9999"
                    onBlur={e => onBlurNve(e, 'nve6')}
                    readOnly={disableField(idFieldNve6) || !userCanChange}
                  />
                )}
                <FormInput
                  className="mediumInput"
                  name="nveDesc6"
                  label="Descrição 6"
                  readOnly={!userCanChange}
                />
              </Row>
              <Row>
                {showField(idFieldLi) && (
                  <FormDropdown
                    className="smallInput"
                    name="li"
                    label="LI"
                    placeholder="Select"
                    initialValue={ncm.li}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={disableField(idFieldLi) || !userCanChange}
                    onValueChange={e => handleLiChange(e)}
                  />
                )}
                {showField(idFieldAnuencias) && (
                  <FormInput
                    className="largeInput"
                    name="anuencias"
                    label="Anuências"
                    readOnly={
                      disableField(idFieldAnuencias) || !li || !userCanChange
                    }
                    required={li}
                  />
                )}
              </Row>
              <Row>
                <FormInputNumber
                  className="smallInput"
                  name="ii"
                  label="% II"
                  required
                  decimalSeparator=","
                  readOnly={!userCanChange}
                  decimalScale={2}
                  fixedDecimalScale
                />
                <FormInputNumber
                  className="smallInput"
                  name="ipi"
                  label="% IPI"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  decimalSeparator=","
                  readOnly={!userCanChange}
                />
                <FormInputNumber
                  className="smallInput"
                  name="pis"
                  label="% PIS"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  decimalSeparator=","
                  readOnly={!userCanChange}
                />
                <FormInputNumber
                  className="smallInput"
                  name="cofins"
                  label="% COFINS"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  decimalSeparator=","
                  readOnly={!userCanChange}
                />
              </Row>
              <Row>
                {showField(idFieldMva) && (
                  <FormDropdown
                    className="smallInput"
                    name="mva"
                    label="MVA"
                    placeholder="Select"
                    initialValue={ncm.mva}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={disableField(idFieldMva) || !userCanChange}
                  />
                )}
                {showField(idFieldListaCamex) && (
                  <FormDropdown
                    className="smallInput"
                    name="listaCamex"
                    label="List Camex"
                    placeholder="Select"
                    initialValue={ncm.listaCamex}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={disableField(idFieldListaCamex) || !userCanChange}
                  />
                )}
                {showField(idFieldExclusaoTTD) && (
                  <FormDropdown
                    className="smallInput"
                    name="exclusaoTtd"
                    label="Exclusão TTD"
                    placeholder="Select"
                    initialValue={ncm.exclusaoTtd}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={
                      disableField(idFieldExclusaoTTD) || !userCanChange
                    }
                  />
                )}
                {showField(idFieldIcmsST) && (
                  <FormDropdown
                    className="smallInput"
                    name="icmsSt"
                    label="ICMS ST"
                    placeholder="Select"
                    initialValue={ncm.icmsSt}
                    options={booleanOptions}
                    optionLabel="label"
                    optionValue="value"
                    showClear
                    readOnly={disableField(idFieldIcmsST) || !userCanChange}
                  />
                )}
              </Row>
              <Row>
                {showField(idFieldDumping) && (
                  <FormDropdown
                    className="smallInput"
                    name="dumping"
                    label="Dumping"
                    placeholder="Select"
                    initialValue={ncm.dumping}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={disableField(idFieldDumping) || !userCanChange}
                  />
                )}
                {showField(idFieldBaseDumping) && (
                  <FormDropdown
                    className="smallInput"
                    name="baseDumping"
                    label="Base Dumping"
                    placeholder="Select"
                    initialValue={ncm.baseDumping}
                    options={
                      baseDumpingLoading || baseDumpingError
                        ? undefined
                        : baseDumpingData.listDomainsByGroupId.data
                    }
                    optionLabel="description"
                    optionValue="idDomain"
                    showClear
                    readOnly={
                      disableField(idFieldBaseDumping) || !userCanChange
                    }
                  />
                )}
                {showField(idFieldPriceMin) && (
                  <FormDropdown
                    className="smallInput"
                    name="priceMin"
                    label="Preço Mínimo"
                    placeholder="Select"
                    initialValue={ncm.priceMin}
                    options={booleanOptions}
                    optionLabel="label"
                    showClear
                    readOnly={disableField(idFieldPriceMin) || !userCanChange}
                  />
                )}
                {showField(idFieldLinkDumping) && (
                  <span className="input-link">
                    <FormInput
                      className="link-input"
                      name="linkDumping"
                      label="Link Dumping"
                      onBlur={e => setLinkDumping(e.target.value)}
                      readOnly={
                        disableField(idFieldLinkDumping) || !userCanChange
                      }
                    />

                    <a
                      className="link"
                      href={
                        linkDumping?.match('http')
                          ? linkDumping
                          : `http://${linkDumping}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiExternalLink size={15} />
                    </a>
                  </span>
                )}
              </Row>
              <Row>
                {showField(idFieldNotes) && (
                  <FormInputTextArea
                    className="fullInput"
                    name="notes"
                    label="Notes"
                    readOnly={disableField(idFieldNotes) || !userCanChange}
                    rows={3}
                  />
                )}
              </Row>
            </Form>
          )}
          {(ncmLoading || loading) && <Loading />}
        </GeneralInformation>
      </CrudForm>
    </Container>
  );
};

export default Ncm;
