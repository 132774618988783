import { gql } from '@apollo/client';

export const listPurchaseTotalPaidData = gql`
  query ListSatForeignTradeSupplierPurchaseTotalPaid(
    $idSatForeignTradeSupplier: Int!
  ) {
    listSatForeignTradeSupplierPurchaseTotalPaid(
      idSatForeignTradeSupplier: $idSatForeignTradeSupplier
    ) {
      totalPaid
      totalRemainCot
    }
  }
`;

export const listSellerTotalReceivedData = gql`
  query ListSatForeignTradeSupplierSellerTotalReceived(
    $idSatForeignTradeSupplier: Int!
  ) {
    listSatForeignTradeSupplierSellerTotalReceived(
      idSatForeignTradeSupplier: $idSatForeignTradeSupplier
    ) {
      totalReceived
      totalRemainSun
    }
  }
`;

/**
 * Query para listar SAT Attachments pelo ID da SAT para a tela de Financial
 */
export const listSatAttachmentsToFinancialByIdSatQuery = gql`
  query ListSatAttachmentsToFinancialByIdSat(
    $data: ListSatAttachmentsToFinancialByIdSatInput!
  ) {
    listSatAttachmentsToFinancialByIdSat(data: $data) {
      idSatAttachment
      idAttachment2 {
        url
        nameGivenByUser
        createdAt
        idType
      }
    }
  }
`;
