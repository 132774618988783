import styled from 'styled-components';

export const Container = styled.div`
  .status {
    padding: 3px;
    border-radius: 20px;
    text-align: center;
    color: #fff;
  }

  .DRAFT,
  .CANCELLED {
    background-color: #c1272d;
  }

  .APPROVED {
    background-color: #6ab7a8;
  }

  .WAITING_PURCHASE {
    background-color: #e51fb2;
  }

  .AT_PURCHASE {
    background-color: #e51fb2;
  }

  .AT_COMMERCIAL {
    background-color: #662d91;
  }
`;
