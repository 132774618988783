/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import {
  IInlandFreightTax,
  IInlandFreightTaxFormData,
  IInlandFreightTaxRouteParams,
} from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { inlandFreightTaxRoles } from '../../../shared/roles/inlandFreightTax';
import Tag from '../../../components/Tag';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';
import { DomainGroup } from '../../../shared/enums/domainGroup';

const InlandFreightTax: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de InlandFreight Tax
  const idInlandFreightTax = parseInt(
    useParams<IInlandFreightTaxRouteParams>().idInlandFreightTax,
    10,
  );

  const defaultPageTitle = `${
    idInlandFreightTax ? 'Manage' : 'Create'
  } Inland Freight Tax`;

  // Estado de InlandFreight Tax
  const [inlandFreightTax, setInlandFreightTax] = useState<IInlandFreightTax>(
    {} as IInlandFreightTax,
  );

  useTitle(
    inlandFreightTax?.inlandFreightTaxName
      ? `${inlandFreightTax?.inlandFreightTaxName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsInlandFreightTax = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    inlandFreightTaxRoles.idEntity,
  );

  // Permissions de InlandFreight Tax
  const {
    idPermissionCreateInlandFreightTax,
    idPermissionUpdateInlandFreightTax,
  } = inlandFreightTaxRoles.permissions;

  // Valida se usuario pode criar ou atualizar InlandFreight Tax
  const userCanChange = userHasPermission(
    idInlandFreightTax
      ? idPermissionUpdateInlandFreightTax
      : idPermissionCreateInlandFreightTax,
    userPermissionsInlandFreightTax.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Inland Freight Taxes',
      path:
        initialPage && initialFirst
          ? `/logistics/inlandFreightTaxes?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/inlandFreightTaxes',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadInlandFreights: LoadOptions<
    any,
    GroupBase<any>,
    { page: any }
  > = async (search: string, prevOptions: any, pageData) => {
    const res = await client.query({
      query: gql`
        query listAllInlandFreightsQuery(
          $listAllPortsInput: ListAllPortsInput!
        ) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: pageData?.page,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar inlandFreight tax
  const listLogisticInlandFreightTaxQuery = gql`
    query listLogisticInlandFreightTaxById($idInlandFreightTax: Int!) {
      listLogisticInlandFreightTaxById(
        idInlandFreightTax: $idInlandFreightTax
      ) {
        idInlandFreightTax
        inlandFreightTaxName
        idPortAirportDestination2 {
          idPort
          name
        }
        idCurPos2 {
          idCurrency
          abbreviation
          currency
        }
        taxValuePos
        idBcValuePos2 {
          idDomain
          description
        }
        bcCifPos
        updatedAtPos
        idCurArm2 {
          idCurrency
          abbreviation
          currency
        }
        idCurPes2 {
          idCurrency
          abbreviation
          currency
        }
        taxValuePes
        idBcValuePes2 {
          idDomain
          description
        }
        bcCifPes
        updatedAtPes
        idCurArm2 {
          idCurrency
          abbreviation
          currency
        }
        taxValueArm
        idBcValueArm2 {
          idDomain
          description
        }
        bcCifArm
        updatedAtArm
        idCurAdm2 {
          idCurrency
          abbreviation
          currency
        }
        taxValueAdm
        idBcValueAdm2 {
          idDomain
          description
        }
        bcCifAdm
        updatedAtAdm
        createdBy2 {
          firstName
          lastName
        }
        createdAt
        updatedBy2 {
          firstName
          lastName
        }
        updatedAt
        versionLock
        active
      }
    }
  `;

  /**
   * Busca dados de InlandFreight Tax
   */
  const [loadInlandFreightTaxData, { loading: inlandFreightTaxLoading }] =
    useLazyQuery(listLogisticInlandFreightTaxQuery, {
      variables: {
        idInlandFreightTax,
      },
      onCompleted: response => {
        if (response.listLogisticInlandFreightTaxById) {
          setInlandFreightTax(response.listLogisticInlandFreightTaxById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Inland Freight Tax not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting inland Freight tax data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/inlandFreightTaxes'),
    });
  }

  // Mutation de criacao de InlandFreight Tax
  const createInlandFreightTaxQuery = gql`
    mutation CreateLogisticInlandFreightTaxMutation(
      $data: CreateLogisticInlandFreightTaxInput!
    ) {
      createLogisticInlandFreightTax(data: $data) {
        idInlandFreightTax
      }
    }
  `;

  // Mutation de atualizacao de inlandFreight tax
  const updateInlandFreightTaxQuery = gql`
    mutation UpdateInlandFreightTaxMutation(
      $data: UpdateLogisticInlandFreightTaxInput!
    ) {
      updateLogisticInlandFreightTax(data: $data) {
        idInlandFreightTax
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createInlandFreightTaxMutation] = useMutation(
    createInlandFreightTaxQuery,
  );
  const [updateInlandFreightTaxMutation] = useMutation(
    updateInlandFreightTaxQuery,
  );

  // Submit do formulario de inlandFreight tax
  const handleInlandFreightTaxSubmit = useCallback(
    async (data: IInlandFreightTaxFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          inlandFreightTaxName: Yup.string().required('Required Field'),
          idPortAirportDestination: Yup.string()
            .nullable()
            .required('Required Field'),
          idCurPos: Yup.number().nullable().required('Required Field'),
          taxValuePos: Yup.string().nullable().required('Required Field'),
          idBcValuePos: Yup.string().nullable().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        const apiValues = {
          inlandFreightTaxName: data.inlandFreightTaxName,
          idPortAirportDestination: data.idPortAirportDestination,
          idCurPos: data.idCurPos,
          taxValuePos: data.taxValuePos,
          idBcValuePos: data.idBcValuePos,
          bcCifPos: !!inlandFreightTax.bcCifPos,
          idCurPes: data.idCurPes,
          taxValuePes: data.taxValuePes,
          idBcValuePes: data.idBcValuePes,
          bcCifPes: !!inlandFreightTax.bcCifPes,
          idCurArm: data.idCurArm,
          taxValueArm: data.taxValueArm,
          idBcValueArm: data.idBcValueArm,
          bcCifArm: !!inlandFreightTax.bcCifArm,
          idCurAdm: data.idCurAdm,
          taxValueAdm: data.taxValueAdm,
          idBcValueAdm: data.idBcValueAdm,
          bcCifAdm: !!inlandFreightTax.bcCifAdm,
        };

        // Valida se esta editando ou criando um novo inlandFreight tax
        if (!idInlandFreightTax) {
          // Chamada criacao de inlandFreight tax
          response = await createInlandFreightTaxMutation({
            variables: {
              data: { ...apiValues },
            },
          });
        } else {
          // Chamada atualizacao de inlandFreight tax
          response = await updateInlandFreightTaxMutation({
            variables: {
              data: {
                idInlandFreightTax,
                versionLock: inlandFreightTax.versionLock,
                ...apiValues,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Inland Freight Tax ${
            idInlandFreightTax ? 'updated' : 'created'
          }`,
          life: ToastLife.SUCCESS,
        });

        if (!idInlandFreightTax) {
          // Direciona usuario para listagem da inlandFreight tax
          history.push(
            `/logistics/inlandFreightTaxes/${response.data.createLogisticInlandFreightTax.idInlandFreightTax}`,
          );
        } else {
          setInlandFreightTax({} as IInlandFreightTax);
          loadInlandFreightTaxData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idInlandFreightTax ? 'updating' : 'creating'
            } inland Freight tax`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createInlandFreightTaxMutation,
      formRef,
      history,
      idInlandFreightTax,
      loadInlandFreightTaxData,
      inlandFreightTax.bcCifPos,
      inlandFreightTax.bcCifPes,
      inlandFreightTax.bcCifArm,
      inlandFreightTax.bcCifAdm,
      inlandFreightTax.versionLock,
      toastRef,
      updateInlandFreightTaxMutation,
    ],
  );

  useEffect(() => {
    if (idInlandFreightTax) {
      loadInlandFreightTaxData();
    }
  }, [idInlandFreightTax, loadInlandFreightTaxData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader
        title={
          inlandFreightTax?.idInlandFreightTax
            ? `${defaultPageTitle} - ${inlandFreightTax.inlandFreightTaxName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {inlandFreightTax.idInlandFreightTax ? (
          inlandFreightTax.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}
        <p className={idInlandFreightTax ? 'createdAt' : ''}>
          {inlandFreightTax.idInlandFreightTax && !inlandFreightTaxLoading
            ? `${new Date(inlandFreightTax.createdAt).toLocaleString()} by ${
                inlandFreightTax.createdBy2?.firstName
              } ${inlandFreightTax.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idInlandFreightTax ? 'updatedAt' : ''}>
          {inlandFreightTax && !inlandFreightTaxLoading
            ? `${new Date(inlandFreightTax.updatedAt).toLocaleString()} by ${
                inlandFreightTax.updatedBy2?.firstName
              } ${inlandFreightTax.updatedBy2?.lastName}`
            : ''}
        </p>

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {/* Formulario */}
      {((idInlandFreightTax &&
        !inlandFreightTaxLoading &&
        inlandFreightTax.idInlandFreightTax) ||
        !idInlandFreightTax) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handleInlandFreightTaxSubmit}
            initialData={inlandFreightTax}
          >
            <Row>
              <FormInput
                name="inlandFreightTaxName"
                label="Tax Name"
                required
                className="form-input"
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idPortAirportDestination"
                label="Port/Airport of Destination"
                required
                className="form-input"
                loadOptions={loadInlandFreights}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No Inland Freights found'}
                initialValue={inlandFreightTax?.idPortAirportDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValuePos"
                  label="Posicionamento"
                  required
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurPos"
                  required
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={inlandFreightTax.idCurPos2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValuePos"
                  required
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={inlandFreightTax.idBcValuePos2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div radio-button-required generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      required
                      inputId="yes"
                      name="inlandFreightTax.bcCifPos"
                      value
                      checked={inlandFreightTax.bcCifPos === true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifPos',
                          true,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="inlandFreightTax.bcCifPos"
                      value={false}
                      checked={inlandFreightTax.bcCifPos !== true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifPos',
                          false,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Taxa cobrada pela posicionamento</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValuePes"
                  label="Pesagem"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurPes"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={inlandFreightTax.idCurPes2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValuePes"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={inlandFreightTax.idBcValuePes2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="inlandFreightTax.bcCifPes"
                      value
                      checked={inlandFreightTax.bcCifPes === true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifPes',
                          true,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="inlandFreightTax.bcCifPes"
                      value={false}
                      checked={inlandFreightTax.bcCifPes !== true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifPes',
                          false,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Taxa cobrada pela pesagem</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueArm"
                  label="Armazenagem Porto (10 dias)"
                  min={0}
                  suffix="%"
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurArm"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={inlandFreightTax.idCurArm2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueArm"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.idDomain}
                  additional={{
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={inlandFreightTax.idBcValueArm2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="inlandFreightTax.bcCifArm"
                      value
                      checked={inlandFreightTax.bcCifArm === true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifArm',
                          true,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="inlandFreightTax.bcCifArm"
                      value={false}
                      checked={inlandFreightTax.bcCifArm !== true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifArm',
                          false,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">
                Taxa cobrada pela armazenagem da mercadoria no porto (10 dias)
              </p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueAdm"
                  label="Taxa administrativa"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurAdm"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={inlandFreightTax.idCurAdm2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueAdm"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={inlandFreightTax.idBcValueAdm2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="inlandFreightTax.bcCifAdm"
                      value
                      checked={inlandFreightTax.bcCifAdm === true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifAdm',
                          true,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="inlandFreightTax.bcCifAdm"
                      value={false}
                      checked={inlandFreightTax.bcCifAdm !== true}
                      onChange={() =>
                        changeStateProperty(
                          'bcCifAdm',
                          false,
                          setInlandFreightTax,
                        )
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Despesas administrativas</p>
            </span>
          </Form>
        </CrudForm>
      )}
      {(inlandFreightTaxLoading || loading) && <Loading />}
    </Container>
  );
};

export default InlandFreightTax;
