import { FormHandles } from '@unform/core';
import React, {
  Dispatch,
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { Form } from '@unform/web';
import { number, object } from 'yup';
import {
  PackageDetailsFieldsPermissions,
  ProductPackage,
} from '../../interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import FormCheckbox from '../../../../../../components/FormCheckbox';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormInputTextArea from '../../../../../../components/FormInputTextArea';
import { IPackageDetailsRef, PackageDetailsFormData } from './interfaces';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { objectsAreEqual } from '../../../../../../utils/objectsAreEqual';
import { EnumTypeofPackage } from '../../../enums';

interface IPackageDetailsProps {
  productPackage?: ProductPackage;
  indConfection?: boolean;
  handleChangeDefault?: (
    idProductPackage: number,
    e: CheckboxChangeEvent,
  ) => boolean;
  ref: Ref<IPackageDetailsRef>;
  idTypePackage?: number;
  setIdTypePackage: Dispatch<React.SetStateAction<number | undefined>>;
  fieldsPermissions: PackageDetailsFieldsPermissions;
  userCanChangeFields: boolean;
}

const PackageDetails: React.FC<IPackageDetailsProps> = forwardRef(
  (
    {
      productPackage,
      indConfection,
      handleChangeDefault,
      idTypePackage,
      setIdTypePackage,
      fieldsPermissions,
      userCanChangeFields,
    },
    ref,
  ) => {
    const formRef = useRef<FormHandles>(null);

    function onSubmit(formData: PackageDetailsFormData) {
      if (productPackage && objectsAreEqual(formData, productPackage)) {
        return undefined;
      }

      return formData;
    }

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        const formData = formRef.current?.getData();

        if (!formData) return false;

        const schema = object().shape({
          idTypePackage: indConfection
            ? number().nullable().required('Required Field')
            : number().nullable().notRequired(),
          packageUnit: number().nullable().required('Required Field'),
          masterQuantity: number().nullable().required('Required Field'),
          masterCbm: number().nullable().required('Required Field'),
        });

        try {
          formRef.current?.setErrors({});

          await schema.validate(formData, { abortEarly: false });

          return true;
        } catch (error) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          const firstError = error.inner[0];

          const inputWithError = formRef.current?.getFieldRef(firstError.path);

          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          throw error;
        }
      },
      getDataIfChanged: () => {
        const formData = formRef.current?.getData() as PackageDetailsFormData;

        return onSubmit(formData);
      },
      setIsDefault: (isDefault: boolean) => {
        formRef.current?.setFieldValue('isDefault', isDefault);
      },
    }));

    function handleCbmCalc() {
      const length = formRef.current?.getFieldValue('masterLength');

      const width = formRef.current?.getFieldValue('masterWidth');

      const height = formRef.current?.getFieldValue('masterHeight');

      if (length && width && height) {
        const cbm = (length * width * height) / 1000000;

        formRef.current?.setFieldValue('masterCbm', cbm.toFixed(4));
      }
    }

    return (
      <Form
        className="col-12"
        ref={formRef}
        initialData={productPackage}
        onSubmit={data => onSubmit(data)}
      >
        <div className="formgrid grid">
          <FormTitle className="field col-12" name="Type of Package" />
          {indConfection && (
            <FormAsyncSelect
              className="field col-4"
              name="idTypePackage"
              label="Type of Package"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              initialValue={productPackage?.idTypePackage2}
              onValueChange={e => setIdTypePackage(e.idDomain)}
              additional={{
                id: DomainGroup.TYPE_OF_PACKAGE,
              }}
              noOptionsMessage={() => 'No Package Types found'}
              required
            />
          )}
          {fieldsPermissions.isDefault.view && (
            <FormCheckbox
              className="flex align-self-center field col-2"
              name="isDefault"
              label="Default"
              onValueChange={e => {
                if (handleChangeDefault && productPackage) {
                  return handleChangeDefault(
                    productPackage.idProductPackage,
                    e,
                  );
                }

                return true;
              }}
              readOnly={
                !userCanChangeFields ||
                (productPackage?.idProductPackage
                  ? !fieldsPermissions.isDefault.edit
                  : !fieldsPermissions.isDefault.create)
              }
            />
          )}
        </div>

        <div className="formgrid grid">
          <span className="col-3">
            <h2 className="field">Selling Package</h2>
            {fieldsPermissions.packageCostPrice.view && (
              <FormInputNumber
                className="field"
                name="packageCostPrice"
                label="Cost Price"
                decimalScale={2}
                fixedDecimalScale
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageCostPrice.edit
                    : !fieldsPermissions.packageCostPrice.create)
                }
              />
            )}

            {fieldsPermissions.packageUnit.view && (
              <FormAsyncSelect
                name="packageUnit"
                className="field"
                label="Unit"
                required
                loadOptions={asyncSelectLoadDomains}
                getOptionLabel={option => option.description}
                getOptionValue={option => option.idDomain}
                initialValue={productPackage?.packageUnit2}
                additional={{
                  id: DomainGroup.UNITS_OF_PRODUCTS,
                }}
                noOptionsMessage={() => 'No Units found'}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageUnit.edit
                    : !fieldsPermissions.packageUnit.create)
                }
              />
            )}

            {fieldsPermissions.packagePcQuantity.view && (
              <FormInputNumber
                className="field"
                name="packagePcQuantity"
                label="QTY Per Selling Package"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packagePcQuantity.edit
                    : !fieldsPermissions.packagePcQuantity.create)
                }
              />
            )}

            {fieldsPermissions.packageLength.view && (
              <FormInputNumber
                className="field"
                name="packageLength"
                label="Length (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageLength.edit
                    : !fieldsPermissions.packageLength.create)
                }
              />
            )}

            {fieldsPermissions.packageWidth.view && (
              <FormInputNumber
                className="field"
                name="packageWidth"
                label="Width (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageWidth.edit
                    : !fieldsPermissions.packageWidth.create)
                }
              />
            )}

            {fieldsPermissions.packageHeight.view && (
              <FormInputNumber
                className="field"
                name="packageHeight"
                label="Height (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageHeight.edit
                    : !fieldsPermissions.packageHeight.create)
                }
              />
            )}

            {fieldsPermissions.packageGrossWeight.view && (
              <FormInputNumber
                className="field"
                name="packageGrossWeight"
                label="Gross Weight (kg)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.packageGrossWeight.edit
                    : !fieldsPermissions.packageGrossWeight.create)
                }
              />
            )}
          </span>
          <span className="col-3">
            <h2 className="field">Inner Box</h2>

            {fieldsPermissions.innerQuantity.view && (
              <FormInputNumber
                className="field"
                name="innerQuantity"
                label="Un/Set Per Inner"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerQuantity.edit
                    : !fieldsPermissions.innerQuantity.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}

            {fieldsPermissions.innerLength.view && (
              <FormInputNumber
                className="field"
                name="innerLength"
                label="Length (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerLength.edit
                    : !fieldsPermissions.innerLength.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}

            {fieldsPermissions.innerWidth.view && (
              <FormInputNumber
                className="field"
                name="innerWidth"
                label="Width (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerWidth.edit
                    : !fieldsPermissions.innerWidth.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}

            {fieldsPermissions.innerHeight.view && (
              <FormInputNumber
                className="field"
                name="innerHeight"
                label="Height (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerHeight.edit
                    : !fieldsPermissions.innerHeight.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}

            {fieldsPermissions.innerGrossWeight.view && (
              <FormInputNumber
                className="field"
                name="innerGrossWeight"
                label="Gross Weight (kg)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerGrossWeight.edit
                    : !fieldsPermissions.innerGrossWeight.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}

            {fieldsPermissions.isInnerDisplay.view && (
              <FormCheckbox
                name="isInnerDisplay"
                label="Display"
                className="field flex"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.isInnerDisplay.edit
                    : !fieldsPermissions.isInnerDisplay.create)
                }
                disabled={idTypePackage === EnumTypeofPackage.PACK}
              />
            )}
          </span>
          <span className="col-3">
            <h2 className="field">Master Box</h2>
            {fieldsPermissions.innerBox.view && (
              <FormInputNumber
                className="field"
                name="innerBox"
                label="Inner Per CTN"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.innerBox.edit
                    : !fieldsPermissions.innerBox.create)
                }
              />
            )}

            {fieldsPermissions.masterQuantity.view && (
              <FormInputNumber
                className="field"
                name="masterQuantity"
                label="Un/SET Per CTN"
                required
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterQuantity.edit
                    : !fieldsPermissions.masterQuantity.create)
                }
              />
            )}

            {fieldsPermissions.masterLength.view && (
              <FormInputNumber
                className="field"
                name="masterLength"
                label="Length (cm)"
                decimalScale={4}
                onBlur={() => handleCbmCalc()}
                defaultValue={0}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterLength.edit
                    : !fieldsPermissions.masterLength.create)
                }
              />
            )}

            {fieldsPermissions.masterWidth.view && (
              <FormInputNumber
                className="field"
                name="masterWidth"
                label="Width (cm)"
                decimalScale={4}
                onBlur={() => handleCbmCalc()}
                defaultValue={0}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterWidth.edit
                    : !fieldsPermissions.masterWidth.create)
                }
              />
            )}

            {fieldsPermissions.masterHeight.view && (
              <FormInputNumber
                className="field"
                name="masterHeight"
                label="Height (cm)"
                decimalScale={4}
                onBlur={() => handleCbmCalc()}
                defaultValue={0}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterHeight.edit
                    : !fieldsPermissions.masterHeight.create)
                }
              />
            )}

            {fieldsPermissions.masterCbm.view && (
              <FormInputNumber
                className="field"
                name="masterCbm"
                label="CBM"
                required
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterCbm.edit
                    : !fieldsPermissions.masterCbm.create)
                }
              />
            )}

            {fieldsPermissions.masterNetWeight.view && (
              <FormInputNumber
                className="field"
                name="masterNetWeight"
                label="Net Weight (kg)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterNetWeight.edit
                    : !fieldsPermissions.masterNetWeight.create)
                }
              />
            )}

            {fieldsPermissions.masterGrossWeight.view && (
              <FormInputNumber
                className="field"
                name="masterGrossWeight"
                label="Gross Weight (kg)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.masterGrossWeight.edit
                    : !fieldsPermissions.masterGrossWeight.create)
                }
              />
            )}
          </span>
          <span className="col-3">
            <h2 className="field">Pallet</h2>

            {fieldsPermissions.palletQuantity.view && (
              <FormInputNumber
                className="field"
                name="palletQuantity"
                label="Un/Set Per Inner"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletQuantity.edit
                    : !fieldsPermissions.palletQuantity.create)
                }
                disabled={indConfection}
              />
            )}

            {fieldsPermissions.palletLength.view && (
              <FormInputNumber
                className="field"
                name="palletLength"
                label="Length (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletLength.edit
                    : !fieldsPermissions.palletLength.create)
                }
                disabled={indConfection}
              />
            )}

            {fieldsPermissions.palletWidth.view && (
              <FormInputNumber
                className="field"
                name="palletWidth"
                label="Width (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletWidth.edit
                    : !fieldsPermissions.palletWidth.create)
                }
                disabled={indConfection}
              />
            )}

            {fieldsPermissions.palletHeight.view && (
              <FormInputNumber
                className="field"
                name="palletHeight"
                label="Height (cm)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletHeight.edit
                    : !fieldsPermissions.palletHeight.create)
                }
                disabled={indConfection}
              />
            )}

            {fieldsPermissions.palletGrossWeight.view && (
              <FormInputNumber
                className="field"
                name="palletGrossWeight"
                label="Gross Weight (kg)"
                decimalScale={4}
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletGrossWeight.edit
                    : !fieldsPermissions.palletGrossWeight.create)
                }
                disabled={indConfection}
              />
            )}

            {fieldsPermissions.palletDisplay.view && (
              <FormCheckbox
                name="palletDisplay"
                label="Display"
                className="field flex"
                readOnly={
                  !userCanChangeFields ||
                  (productPackage?.idProductPackage
                    ? !fieldsPermissions.palletDisplay.edit
                    : !fieldsPermissions.palletDisplay.create)
                }
                disabled={indConfection}
              />
            )}
          </span>
        </div>

        {fieldsPermissions.packageNote.view && (
          <FormInputTextArea
            className="field"
            name="packageNote"
            label="Package Note"
            rows={5}
            style={{ resize: 'vertical' }}
            readOnly={
              !userCanChangeFields ||
              (productPackage?.idProductPackage
                ? !fieldsPermissions.packageNote.edit
                : !fieldsPermissions.packageNote.create)
            }
          />
        )}
      </Form>
    );
  },
);

export default PackageDetails;
