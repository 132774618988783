import { cloneDeep } from 'lodash';
import {
  AdvancedFiltersPanelReducerAction,
  AdvancedFiltersPanelReducerActionKind,
  IAdvancedFiltersPanelReducer,
} from './interfaces';

export const advancedFiltersPanelReducerInitialState: IAdvancedFiltersPanelReducer =
  {
    fields: [],
    filteredFields: [],
    fieldsSearch: '',
  };

export const advancedFiltersPanelReducer = (
  state: IAdvancedFiltersPanelReducer,
  action: AdvancedFiltersPanelReducerAction,
): IAdvancedFiltersPanelReducer => {
  switch (action.type) {
    case AdvancedFiltersPanelReducerActionKind.FILTER_FIELDS:
      if (!action.payload?.filter) {
        return {
          ...state,
          filteredFields: state.fields,
          fieldsSearch: action.payload?.filter,
        };
      }

      return {
        ...state,
        fieldsSearch: action.payload?.filter,
        filteredFields: state.fields.filter(field =>
          field.header
            .toLocaleLowerCase()
            .includes(action.payload?.filter?.toLocaleLowerCase() ?? ''),
        ),
      };
    case AdvancedFiltersPanelReducerActionKind.CHANGE_FIELD_VALUE: {
      if (!action.payload || !action.payload.field) return state;

      const { field } = action.payload;

      const { fields, filteredFields } = state;

      // Se valor do campo for array vazio, seta undefined
      if (Array.isArray(field.value) && !field.value.length) {
        field.value = undefined;
      }

      // Busca index do input no estado de inputs
      const fieldIndex = fields.findIndex(
        stateField =>
          stateField.advancedFilterField === field.advancedFilterField,
      );

      fields.splice(fieldIndex, 1, field);

      if (state.fieldsSearch) {
        const filteredFieldsIndex = filteredFields.findIndex(
          stateField =>
            stateField.advancedFilterField === field.advancedFilterField,
        );

        filteredFields.splice(filteredFieldsIndex, 1, field);
      }

      return {
        ...state,
        fields,
        filteredFields,
      };
    }
    case AdvancedFiltersPanelReducerActionKind.SAVE_PANEL_STATE: {
      if (state.panelStateOnShow) {
        return state;
      }

      const { fields, filteredFields, fieldsSearch } = cloneDeep(state);

      return {
        ...state,
        panelStateOnShow: { fields, filteredFields, fieldsSearch },
      };
    }
    case AdvancedFiltersPanelReducerActionKind.RESTORE_PANEL_STATE: {
      if (state.panelStateOnShow) {
        const { fields, filteredFields, fieldsSearch } = state.panelStateOnShow;

        return {
          ...state,
          fields,
          fieldsSearch,
          filteredFields,
          panelStateOnShow: undefined,
        };
      }

      return state;
    }
    case AdvancedFiltersPanelReducerActionKind.CLEAR_FIELDS: {
      if (!action.payload || !action.payload.fields) return state;

      const { fields } = action.payload;

      fields.forEach((_, index) => {
        fields[index].value = undefined;
      });

      return {
        ...state,
        fields,
        fieldsSearch: '',
        filteredFields: fields,
        panelStateOnShow: undefined,
      };
    }
    default:
      throw new Error();
  }
};
