import React from 'react';

import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';

import { Content, Structure } from './styles';
import { useRefHook } from '../../../hooks/useRefHook';

const DefaultLayout: React.FC = ({ children }) => {
  // Referencia ao toast
  const { toastRef } = useRefHook();
  return (
    <Structure>
      <Header />
      <Sidebar />
      <Content id="site-content">{children}</Content>
      <Footer />

      <Toast ref={toastRef} appendTo={document.body} />
      <ConfirmDialog />
    </Structure>
  );
};

export default DefaultLayout;
