import { useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useRef } from 'react';
import Button from '../../../../../../components/Button';
import FilesByTypes, {
  IFilesByTypesRef,
} from '../../../../../../components/FilesByTypes';
import { IFileType } from '../../../../../../components/FilesByTypes/interfaces';
import FormTitle from '../../../../../../components/FormTitle';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { deleteSatAttachmentsQuery } from '../../../queries';
import {
  downloadSatRncAttachmentsQuery,
  listSatAttachmentsBySatIdQuery,
} from './queries';
import FilesUpload, { IFilesUploadRef } from './RncFilesUpload';
import { Container } from './styles';

interface IFilesProps {
  idSat: number;
  idSatRnc?: number;
  rncFileTypesData: IFileType[];
  rncFileTypesLoading: boolean;
  selected: boolean;
  isReadOnly: boolean;
}

const RncFiles: React.FC<IFilesProps> = ({
  idSat,
  idSatRnc,
  rncFileTypesData,
  rncFileTypesLoading,
  selected,
  isReadOnly,
}) => {
  const { toastRef } = useRefHook();

  const filesUploadRef = useRef<IFilesUploadRef>(null);
  const filesByTypesRef = useRef<IFilesByTypesRef>(null);

  const [deleteSatAttachmentsMutation, { loading: fileDeleteInProgress }] =
    useMutation(deleteSatAttachmentsQuery);

  const [loadSatRncFiles, { loading: rncFilesLoading, data: rncFilesData }] =
    useLazyQuery(listSatAttachmentsBySatIdQuery, {
      variables: {
        data: {
          idSat,
          idSatRnc,
          idTypes: rncFileTypesData.map(type => type.idDomain),
          _orderBy: 'attachment.createdAt',
          _sortOrder: 'DESC',
        },
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting RNC Files',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

  const [downloadSatRncFiles, { loading: satRncFilesDownloadInProgress }] =
    useLazyQuery(downloadSatRncAttachmentsQuery, {
      onCompleted: async response => {
        if (response.downloadSatRncFiles) {
          window.open(response.downloadSatRncFiles, '_blank');
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while downloading files',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while downloading files',
          detail: error.message,
          life: ToastLife.ERROR,
        });
      },
    });

  function handleDownloadFiles() {
    const filesToDownload = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDownload?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do download',
        life: ToastLife.ERROR,
      });

      return;
    }

    downloadSatRncFiles({
      variables: {
        idsSatAttachments: filesToDownload,
      },
    });
  }

  async function handleDeleteFiles() {
    const filesToDelete = filesByTypesRef.current?.getSelectedItems();

    if (!filesToDelete?.length) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'You need to select at least one item do delete',
        life: ToastLife.ERROR,
      });

      return;
    }

    try {
      await deleteSatAttachmentsMutation({
        variables: {
          satAttachmentIds: filesToDelete,
        },
      });

      toastRef.current?.show({
        severity: 'success',
        summary: 'Files deleted',
        life: ToastLife.SUCCESS,
      });

      loadSatRncFiles();
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while deleting files',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    }
  }

  useEffect(() => {
    if (selected && !rncFilesData) {
      loadSatRncFiles();
    }
  }, [rncFilesData, loadSatRncFiles, selected]);

  return (
    <Container className="form-group grid">
      <FormTitle className="col-12" name="RNC Files">
        <Button
          type="button"
          label="Upload"
          icon="pi pi-upload"
          onClick={() => filesUploadRef.current?.toggleModal()}
          disabled={isReadOnly}
        />

        <Button
          type="button"
          label="Download"
          onClick={() => handleDownloadFiles()}
          disabled={satRncFilesDownloadInProgress}
          icon={
            satRncFilesDownloadInProgress
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-download'
          }
        />

        <Button
          type="button"
          label="Delete"
          onClick={() =>
            confirmDialog({
              message: 'Are you sure you want to delete the selected files?',
              header: 'Delete Confirmation',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => handleDeleteFiles(),
            })
          }
          disabled={fileDeleteInProgress || isReadOnly}
          icon={fileDeleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
          severity="danger"
        />
      </FormTitle>
      {idSatRnc && (
        <FilesUpload
          ref={filesUploadRef}
          idSat={idSat}
          idSatRnc={idSatRnc}
          rncFilesRefetch={loadSatRncFiles}
        />
      )}
      <FilesByTypes
        ref={filesByTypesRef}
        className="col-12"
        fileTypes={rncFileTypesData}
        files={rncFilesData?.listSatAttachmentsBySatId?.data}
        typesLoading={rncFileTypesLoading}
        fileIdKey="idSatAttachment"
        filesLoading={rncFilesLoading}
        fileDescriptionKey="idAttachment2.nameGivenByUser"
        fileTypeKey="idAttachment2.idType"
        fileCreatedAtKey="idAttachment2.createdAt"
      />
    </Container>
  );
};

export default RncFiles;
