import { useLazyQuery, useMutation } from '@apollo/client';
import { uniqBy } from 'lodash';
import { PickList, PickListChangeEvent } from 'primereact/picklist';
import React, { useCallback, useEffect, useState } from 'react';
import imagePlaceholder from '../../../../../../../assets/imagePlaceholder.svg';
import Button from '../../../../../../../components/Button';
import Loading from '../../../../../../../components/Loading';
import MainButton from '../../../../../../../components/MainButton';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import {
  createSatRncItemsQuery,
  listAvailableSatItemsToRncQuery,
} from './queries';
import { Container } from './styles';

interface IAddRncItemModal {
  idSat?: number;
  idSatRnc?: number;
  rncItemsRefetch(): void;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddRncItemModal: React.FC<IAddRncItemModal> = ({
  idSat,
  idSatRnc,
  rncItemsRefetch,
  setDisplayModal,
}) => {
  const { showError, showSuccess } = useRefHook();

  const [source, setSource] = useState([]);

  const [target, setTarget] = useState<any[]>([]);

  const [saveOnGoing, setSaveOnGoing] = useState(false);

  const [loadSatItemsData, { loading: loadingData }] = useLazyQuery(
    listAvailableSatItemsToRncQuery,
    {
      variables: {
        idSat,
        idSatRnc,
      },
      onCompleted: response => {
        if (response.listAvailableSatItemsToRnc) {
          setSource(response.listAvailableSatItemsToRnc);
        } else {
          showError({
            summary: 'No items found',
          });
        }
      },
      onError: () => {
        showError({
          summary: 'Error while getting sat items data',
        });
      },
    },
  );

  const itemTemplate = (item: any) => {
    return (
      <div className="product-item flex flex-wrap">
        <div className="image-container col-4">
          <img
            src={item.idImage2?.url ?? ''}
            onError={e => {
              e.currentTarget.src = imagePlaceholder;
            }}
            alt={item.name}
            style={{ width: '90px' }}
          />
        </div>
        <div className="product-list-detail col-8">
          <h5 className="mb-2">{item.name}</h5>
          <span className="product-category">{item.stCode}</span>
        </div>
      </div>
    );
  };

  const onChange = (event: PickListChangeEvent) => {
    setSource(event.source);
    setTarget(uniqBy(event.target, 'idSatItem'));
  };

  useEffect(() => {
    loadSatItemsData();
  }, [loadSatItemsData]);

  const [createSatItemsMutation] = useMutation(createSatRncItemsQuery);

  const handleAddItems = useCallback(async () => {
    setSaveOnGoing(true);
    try {
      await createSatItemsMutation({
        variables: {
          data: {
            items: target.map(item => {
              return {
                idSatRnc,
                idSatItem: item.idSatItem,
              };
            }),
          },
        },
      });
      rncItemsRefetch();
      setSaveOnGoing(false);
      setDisplayModal(false);
      showSuccess({
        summary: 'SAT RNC Item(s) created',
      });
    } catch (error) {
      setSaveOnGoing(false);
      showError({
        summary: 'Error while adding SAT RNC items',
        detail: error.message,
      });
    }
  }, [
    createSatItemsMutation,
    idSatRnc,
    rncItemsRefetch,
    setDisplayModal,
    showError,
    showSuccess,
    target,
  ]);

  const handleCancel = useCallback(() => {
    setDisplayModal(false);
  }, [setDisplayModal]);

  return (
    <Container>
      {!loadingData && (source.length > 0 || target.length > 0) && (
        <div>
          <PickList
            source={source}
            target={target}
            itemTemplate={itemTemplate}
            targetHeader="Selected"
            sourceHeader="Available"
            sourceStyle={{ height: '342px' }}
            targetStyle={{ height: '342px' }}
            onChange={onChange}
            dataKey="idSatItem"
            showSourceControls={false}
            showTargetControls={false}
          />
          <div
            className="mt-3"
            style={{ display: 'flex', placeContent: 'end' }}
          >
            <MainButton
              icon={saveOnGoing ? 'pi pi-spin pi-spinner' : undefined}
              label="Confirm"
              className="mx-2"
              onClick={() => handleAddItems()}
              disabled={saveOnGoing || !target.length}
            />
            <Button
              label="Cancel"
              onClick={() => handleCancel()}
              className="p-button-danger"
              disabled={saveOnGoing}
            />
          </div>
        </div>
      )}
      {!loadingData && !source.length && !target.length && (
        <span>No items found</span>
      )}
      {loadingData && <Loading />}
    </Container>
  );
};
export default AddRncItemModal;
