import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .xls-split-button button {
    background-color: #2196f3 !important;
  }
`;
export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;

  form {
    width: 100%;
  }
`;
