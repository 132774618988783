import styled from 'styled-components';
import { Tag } from 'primereact/tag';
import { ITagProps } from '.';

const handleColorType = (tag: ITagProps) => {
  switch (tag.severity) {
    case 'secondary':
      return 'var(--sunasia-design)';
    case 'danger':
      return 'var(--red)';
    case 'info':
      return 'var(--grey-scale)';
    case 'warning':
      return '#EA8D00';
    case 'success':
      return '#148059';
    default:
      return 'var(--blue)';
  }
};

export const Container = styled(Tag)<ITagProps>`
  border-radius: 25px;
  border: 2px solid ${props => handleColorType(props)};
  background-color: #fff !important;
  color: ${props => handleColorType(props)} !important;
  font-size: 11px;
  font-weight: 400;
`;
