/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  DataTable,
  DataTablePageEvent,
  DataTableRowClickEvent,
  SortOrder,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Grid from '../../components/Grid';
import MultiSelect from '../../components/Grid/MultiSelect';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';
import PageHeader from '../../components/PageHeader';
import { searchDelayMiliseconds } from '../../config/pagination';
import { useAuth } from '../../hooks/useAuth';
import { useRefHook } from '../../hooks/useRefHook';
import useTitle from '../../hooks/useTitle';
import ToastLife from '../../shared/enums/toastLife';
import { finishingRoles } from '../../shared/roles/finishing';
import getUserFieldsAndPermissionsByEntity from '../../utils/getUserFieldsAndPermissionsByEntity';
import updateLocalStorageInDb from '../../utils/updateLocalStorageInDb';
import userHasPermission from '../../utils/userHasPermission';
/**
 * Interface de Finalizacap
 */
interface IFinishing {
  idFinishing: number;
  name: string;
  namePt: string;
}

/**
 * Interface dos parametros do backend
 */
interface ILazyParams {
  first: number;
  rows: number;
  page: number;
  sortField?: string;
  sortOrder?: SortOrder;
  globalFilter?: string;
  name?: string;
  namePt?: string;
}

const pageTitle = 'List of Finishings';

const Finishings: React.FC = () => {
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:finishingsGridColumns';

  // Redirect
  const history = useHistory();

  // Referencia ao formulario e toast
  const { toastRef } = useRefHook();

  // Roles do usuario
  const { roles } = useAuth();

  // Permissions de Finishings
  const { idPermissionDeleteFinishing } = finishingRoles.permissions;

  // Busca permissoes do usuario para a entity
  const userPermissionsFinishing = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    finishingRoles.idEntity,
  );

  // Referencia a grid
  const gridRef = useRef<DataTable<any>>(null);

  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Colunas da grid
  const columns = [
    { field: 'name', header: 'Name' },
    { field: 'namePt', header: 'Name (PT)' },
  ];

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ILazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns);

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Produtos selecionados
  const [selectedFinishings, setSelectedFinishings] = useState([]);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Query para listar finalizacoes
  const listFinishingsQuery = gql`
    query listAllFinishingsQuery(
      $listAllFinishingsInput: ListAllFinishingsInput
      $listAllFinishingsPagination: Pagination!
    ) {
      listAllFinishings(
        listAllFinishingsInput: $listAllFinishingsInput
        pagination: $listAllFinishingsPagination
      ) {
        data {
          idFinishing
          name
          namePt
        }
        items
      }
    }
  `;

  // Query para deletar finalizacao
  const deleteFinishingsQuery = gql`
    mutation DeleteFinishingsMutation($finishingsId: [Int]!) {
      deleteFinishings(finishingsId: $finishingsId)
    }
  `;

  // cria método para chamar a mutation
  const [deleteFinishings] = useMutation(deleteFinishingsQuery);

  /**
   * Busca Finalizacoes
   */
  const {
    loading: finishingsLoading,
    data: finishingsData,
    error: finishingsError,
    refetch: finishingsRefetch,
  } = useQuery(listFinishingsQuery, {
    variables: {
      listAllFinishingsInput: {
        globalSearch: lazyParams.globalFilter,
        name: lazyParams.name,
        namePt: lazyParams.namePt,
      },
      listAllFinishingsPagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting finishings',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Define a selecao de colunas no estado
   */
  useEffect(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    }
  }, []);

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return <span className="custom-header">{headerName}</span>;
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        // Valida necessidade de icone de filtro no header
        header={handleColumnHeader(col.header)}
        style={{ width: '935px' }}
        sortable
      />
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina da finalizacao clicada
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/finishings/${e.data.idFinishing}`);
  }

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Deleta finalizacoes selecionadas
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedFinishings.length} finishings?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada finalizacao
        const idsToDelete = selectedFinishings.map(
          (a: IFinishing) => a.idFinishing,
        );
        try {
          // Deleta finalizacoes
          await deleteFinishings({
            variables: {
              finishingsId: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          toastRef.current?.show({
            severity: 'success',
            summary: 'Deleted',
            detail: `You have deleted ${selectedFinishings.length} finishings`,
            life: ToastLife.SUCCESS,
          });

          // Atualiza grid de finalizacoes
          await finishingsRefetch();

          // Zera estado de finalizacoes selecionadas
          setSelectedFinishings([]);
        } catch (error) {
          // Exibe toast de erro
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while deleting finishings',
            detail: error.message,
            life: ToastLife.ERROR,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar finalizacao */}
        <MainButton
          className="mainButton"
          label="New Finishing"
          onClick={() => history.push('/finishings/create')}
        />

        {/* Botao para excluir finalizacoes selecionadas */}
        {userHasPermission(
          idPermissionDeleteFinishing,
          userPermissionsFinishing.userPermissions,
        ) && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={selectedFinishings.length === 0}
            onClick={handleDeleteSelected}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns}
          onChange={onColumnToggle}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a finishing"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="finishings"
        lazy
        totalRecords={
          !finishingsData || finishingsError
            ? 0
            : finishingsData.listAllFinishings.items
        }
        value={
          !finishingsData || finishingsError
            ? undefined
            : finishingsData.listAllFinishings.data
        }
        globalFilter={globalFilter}
        emptyMessage="No finishings found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedFinishings}
        onSelectionChange={e => setSelectedFinishings(e.value)}
      >
        {userHasPermission(
          idPermissionDeleteFinishing,
          userPermissionsFinishing.userPermissions,
        ) && (
          <Column
            columnKey="multiple"
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
        )}

        {dynamicColumns}
      </Grid>
      {(pageLoading || finishingsLoading) && <Loading />}
    </div>
  );
};
export default Finishings;
