import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PageMenuButton from '../PageMenuButton';

import { Wrapper, Container } from './styles';
import { commentSearchParams } from '../Comments/CommentAndReplies';

/**
 * Interface do componente
 */
interface PageMenuProps {
  /**
   * Width do menu
   */
  menuWidth: string;

  /**
   * Margem inferior do menu
   */
  menuMarginBottom?: string;

  /**
   * Orientação do menu
   */
  menuFlexDirection?: string;

  /**
   * Distancia do topo da tela quando sobrepõe os outros elementos da tela.
   */
  stickyMenuTopDistance?: string;

  /**
   * Itens do menu
   */
  items?: IPageMenuItem[];

  /**
   * Estado que define key do item selecionado no menu
   */
  setSelectedMenuItem?: Dispatch<SetStateAction<string>>;

  /**
   * Key do item selecionado no menu
   */
  selectedMenuItem?: string;
}

/**
 * Interface de Item do Menu
 */
export interface IPageMenuItem {
  /**
   * Nome do Item
   */
  label: string;

  /**
   * Key do Item
   */
  key: string;
}

const PageMenu: React.FC<PageMenuProps> = ({
  children,
  menuWidth,
  menuFlexDirection,
  menuMarginBottom,
  stickyMenuTopDistance,
  items,
  setSelectedMenuItem,
  selectedMenuItem,
}) => {
  const history = useHistory();
  const location = useLocation();

  // Estado de scroll
  const [scroll, setScroll] = useState(window.scrollY);

  /**
   * Atualiza estado do scroll vertical
   */
  function handleScroll() {
    setScroll(window.scrollY);
  }

  function handleMenuItemClick(key: string) {
    if (setSelectedMenuItem) setSelectedMenuItem(key);

    const searchParams = new URLSearchParams(location.search);

    // Remove parametros de comentarios da URL, para evitar que comentarios
    // continuem sendo destacados quando usuario interagir com o menu
    if (
      searchParams.has(commentSearchParams.idComment) ||
      searchParams.has(commentSearchParams.idChildComment)
    ) {
      searchParams.delete(commentSearchParams.idComment);
      searchParams.delete(commentSearchParams.idChildComment);

      history.replace({
        search: searchParams.toString(),
      });
    }
  }

  /**
   * A cada evento de scroll, chama função para atualizar estado
   */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Wrapper menuWidth={menuWidth} menuMarginBottom={menuMarginBottom || '0px'}>
      <Container
        stickyMenu={scroll > 220}
        menuFlexDirection={menuFlexDirection || 'column'}
        stickyMenuTopDistance={stickyMenuTopDistance || '140px'}
      >
        {setSelectedMenuItem &&
          items?.map(item => {
            return (
              <PageMenuButton
                key={item.key}
                onClick={() => handleMenuItemClick(item.key)}
                selected={selectedMenuItem === item.key}
                title={item.label}
                buttonDirection={menuFlexDirection}
              />
            );
          })}
        {children}
      </Container>
    </Wrapper>
  );
};

export default PageMenu;
