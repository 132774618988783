import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  grid-area: structure-content;
  margin-top: var(--header-height);
  height: auto;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  z-index: 1;
`;

export const Structure = styled.div`
  /**
    Variaveis de layout
  */
  --header-height: 64px;
  --sidebar-width: 197px;

  grid-gap: 0;
  display: grid;
  height: 100vh;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-areas:
    'structure-header structure-header'
    'structure-sidebar structure-content'
    'structure-footer structure-footer';
`;
