import FormInput from '../../../../../../components/FormInput';
import { IListSatSupplierPurchase } from './index';
import CheckboxItems from '../../CheckBoxItems';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import { ISat } from '../../../interfaces';
import { removeDaysFromDate } from '../../../../../../utils/dateUtil';

interface IPurchaseFormSupplierContent {
  satPurchaseData: ISat;
  satSupplierData: IListSatSupplierPurchase;
  category: string;
  indexSat: number;
  indexSupplier: number;
}

const PurchaseFormSupplierContent: React.FC<IPurchaseFormSupplierContent> = ({
  satPurchaseData,
  satSupplierData,
  category,
  indexSat,
  indexSupplier,
}) => {
  const dieCutHrpItems = [
    {
      name: 'NO',
      isChecked: false,
    },
    {
      name: 'YES',
      isChecked: false,
    },
  ];

  const rfIdItems = [
    {
      name: 'NO',
      isChecked: false,
    },
    {
      name: 'YES',
      isChecked: false,
      additionalValue: '',
    },
  ];

  const formulaEstimatedDepositDate = (
    date: string | undefined,
    productionTime: number,
    samplesLeadTime: number,
  ): string => {
    if (date) {
      const daysToRemove = productionTime + samplesLeadTime + 20;

      return removeDaysFromDate(daysToRemove, date, true) ?? '';
    }
    return '';
  };

  return (
    <>
      <div className="grid">
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].client`}
          defaultValue={satPurchaseData.idClient2?.name}
          label="Client"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].satSupplier`}
          defaultValue={satSupplierData?.name ?? ''}
          label="Supplier"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].mainProduct`}
          defaultValue={satPurchaseData?.mainProduct ?? ''}
          label="Product"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].category`}
          defaultValue={category}
          label="Category"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].purchaseUser`}
          defaultValue={
            `${satPurchaseData.idPurchaseUser2?.firstName} ${satPurchaseData.idPurchaseUser2?.lastName}` ??
            ''
          }
          label="Purchase Responsible"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].responsibleCommercialUser`}
          defaultValue={`${satPurchaseData.idSalesUser2?.firstName ?? ''} ${
            satPurchaseData.idSalesUser2?.lastName ?? ''
          }`}
          label="Sales Responsible"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].assistentCommercialUser`}
          defaultValue={`${
            satPurchaseData.idSalesSecondUser2?.firstName ?? ''
          } ${satPurchaseData.idSalesSecondUser2?.lastName ?? ''}`}
          label="Sales Assistant"
        />
        <FormInputNumber
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].amountCot`}
          defaultValue={satSupplierData.supplierAmountCotTotal ?? ''}
          label="Purchase Amount"
          currency={satPurchaseData.idCurrency2?.abbreviation}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
        <FormInputNumber
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].amountSun`}
          defaultValue={satSupplierData.supplierAmountTotal ?? ''}
          label="Selling Amount"
          currency={satPurchaseData.idCurrency2?.abbreviation}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].purchasePaymentTerm`}
          defaultValue={`PAYMENT TERM ADVANCE ${satSupplierData.paymentTermAdvance}% - PAYMENT TERM BALANCE ${satSupplierData.paymentTermBalance}% - ${satSupplierData.paymentTermCondition}`}
          label="Purchase Payment Terms"
        />
        <FormInputNumber
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].purchaseDespositAmount`}
          defaultValue={
            ((satSupplierData.supplierAmountCotTotal ?? 0) *
              satSupplierData.paymentTermAdvance) /
              100 ?? ''
          }
          label="Purchase Deposit Amount"
          currency={satPurchaseData.idCurrency2?.abbreviation}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
        <FormInputNumber
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].productionTime`}
          defaultValue={satPurchaseData?.productionTime ?? ''}
          label="Production Time"
        />
        <FormInput
          type="date"
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].estimatedShipmentDate`}
          defaultValue={satPurchaseData?.estimatedShipmentDate?.toString()}
          label="Negotiated Delivery Date"
        />
        <FormInput
          type="date"
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].estimatedDepositDate`}
          defaultValue={formulaEstimatedDepositDate(
            satPurchaseData?.estimatedShipmentDate,
            satPurchaseData?.productionTime ?? 0,
            satPurchaseData.samplesLeadTime ?? 0,
          )}
          label="Estimated Deposit Date"
        />
        <FormInput
          className="col-4"
          name={`data[${indexSat}].suppliersData[${indexSupplier}].exporter`}
          defaultValue={satPurchaseData.idSupplierExporter2?.name ?? ''}
          label="Exporter"
        />
        <div className="grid" style={{ margin: '10px' }}>
          <div className="col-12">
            <h4>Die CUT/HRP</h4>
            <CheckboxItems
              objectArrayName={`data[${indexSat}].suppliersData[${indexSupplier}].dieCutHrpItems`}
              checkboxMenu={dieCutHrpItems}
            />
          </div>
          <div className="col-12">
            <h4>RFid</h4>
            <CheckboxItems
              objectArrayName={`data[${indexSat}].suppliersData[${indexSupplier}].rfid`}
              checkboxMenu={rfIdItems}
            />
          </div>
        </div>
      </div>
      <FormInput
        className="col-4"
        name={`data[${indexSat}].suppliersData[${indexSupplier}].remarks`}
        defaultValue={satPurchaseData?.remarks ?? ''}
        label="Remarks"
      />
    </>
  );
};

export default PurchaseFormSupplierContent;
