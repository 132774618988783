// eslint-disable-next-line no-shadow
export enum FilesByTypesReducerActionKind {
  SET_FILE_TYPES,
  SET_FILES,
  TOGGLE_FILE_CHECKBOX,
  TOGGLE_FILE_TYPE_CHECKBOX,
}

export interface IFileType {
  idDomain: number;
  description: string;
  isSelected?: boolean;
}

export interface IFilesByTypesReducer {
  fileTypes: IFileType[];
  files: any[];
}

export interface IFilesByTypesReducerAction {
  type: FilesByTypesReducerActionKind;
  payload: {
    fileTypes?: IFileType[];
    files?: any[];
    toggleFileCheckboxData?: {
      checked: boolean;
      fileId: number;
      fileIdKey: string;
      fileTypeKey: string;
    };
    toggleFileTypeCheckboxData?: {
      checked: boolean;
      idDomain: number;
      fileTypeKey: string;
    };
  };
}
