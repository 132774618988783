export const airportTaxRoles = {
  idEntity: 40,
  idModule: 10,
  permissions: {
    idPermissionCreateAirportTax: 77,
    idPermissionUpdateAirportTax: 78,
    idPermissionDeleteAirportTax: 79,
    idPermissionMassiveActionAirportTax: 80,
    idPermissionExportListAirportTax: 81,
  },
};
