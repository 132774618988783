import styled from 'styled-components';

export const Container = styled.div`
  .input-wrapper {
    border: 1px solid #ced4da;
    border-radius: 3px;

    .mention__suggestions {
      margin-top: 24px !important;
    }

    .paperclip-icon {
      padding: 0.5rem;
    }

    .paperclip-icon {
      background: none !important;
      border: none;
      color: #000000 !important;
      box-shadow: none !important;
    }

    .paperclip-icon:hover {
      opacity: 0.7;
    }

    .paperclip-icon .pi-paperclip {
      font-size: 1rem;
      transform: rotate(-46deg);
      margin: 0;
    }
  }
`;
