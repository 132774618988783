import { gql, useLazyQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTablePageEvent, DataTableSortEvent } from 'primereact/datatable';
import React, {
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { FiExternalLink } from 'react-icons/fi';
import Grid from '../../../../components/Grid';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import ILazyParams from '../../../../services/lazyParams';
import ToastLife from '../../../../shared/enums/toastLife';
import { IClientQuotation, IClientQuotationSupplier } from '../interfaces';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { clientQuotationRoles } from '../../../../shared/roles/clientQuotation';

import Supplier from './Supplier';
import { IRoleEntityPermission } from '../../../../interfaces/IRoleEntityPermission';
import userHasPermission from '../../../../utils/userHasPermission';
import { gridConstants } from '../../../../components/Grid/constants';

interface ISuppliersProps extends PageTabContainerProps {
  /**
   * Objeto de Client Quotation
   */
  clientQuotation: IClientQuotation;

  ref: Ref<IClientQuotationSuppliersRef>;

  roleEntityFields: IRoleEntityField[];
  roleEntityPermissions: IRoleEntityPermission[];
}

export interface IClientQuotationSuppliersRef {
  clearSuppliersData(): void;
}

const Suppliers: React.FC<ISuppliersProps> = React.forwardRef(
  (
    { selected, clientQuotation, roleEntityFields, roleEntityPermissions },
    ref,
  ) => {
    const {
      idFieldPaymentTermAdvance,
      idFieldPaymentTermBalance,
      idFieldIdPaymentTermCondition,
      idFieldProductionTime,
      idFieldIdSupplier,
      idFieldCompanyName,
      idFieldIdPort,
      idFieldIdIncoterm,
      idFieldIdIncotermSecond,
      idFieldEstimatedTotalAdvance,
      idFieldEstimatedTotalBalance,
      idFieldAmountCot,
      idFieldGrossMargin,
    } = clientQuotationRoles.fields;

    // Permissions de Client Quotation
    const { idPermissionSupplierLinkClientQuotation } =
      clientQuotationRoles.permissions;

    function showField(idField: number): boolean {
      return getFieldPermission(idField, roleEntityFields).view;
    }

    const [clientQuotationSuppliersData, setClientQuotationSuppliersData] =
      useState<{
        listClientQuotationSuppliersByClientQuotationId: {
          data: IClientQuotationSupplier[];
          items: number;
        };
      }>();

    /**
     * Busca de Client Quotation Suppliers
     */
    const listClientQuotationSuppliersByClientQuotationIdQuery = gql`
      query ListClientQuotationSuppliersByClientQuotationId(
        $data: ListClientQuotationSuppliersByClientQuotationIdInput!
      ) {
        listClientQuotationSuppliersByClientQuotationId(data: $data) {
          items
          data {
            idCqSupplier
            idSupplier2 {
              idSupplier
              ${showField(idFieldIdSupplier) ? 'sunNumber' : ''}
              ${showField(idFieldCompanyName) ? 'name' : ''}
            address
            ${showField(idFieldProductionTime) ? 'productionTime' : ''}
            ${
              showField(idFieldIdIncoterm)
                ? `
              idIncoterm2 {
                description
              }`
                : ''
            }
            ${
              showField(idFieldIdIncotermSecond)
                ? `
              idIncotermSecond2 {
                description
              }`
                : ''
            }
            ${
              showField(idFieldIdPort)
                ? `supplierPorts {
                      idPort2 {
                        name
                      }
                    }`
                : ''
            }
              supplierBankAccounts {
                beneficiaryName
                mainBankName
                mainBankAddress
                bankCity
                swift
                accountNumber
                iban
                intermediaryBeneficiaryName
                intermediaryBankAddress
                intermediaryBankCity
                intermediarySwift
                intermediaryAccountNumber
                intermediaryIban
                isIntermediaryBank
                idCountry2 {
                  name
                }
                idRegion2 {
                  name
                }
                idSecondCountry2 {
                  name
                }
                idSecondRegion2 {
                  name
                }
              }
              pdf
              ai
              corel17
              corel20
              jpeg
              eps
              psd
              indd
              xls
              otherFormat
            }
            ${showField(idFieldAmountCot) ? 'amountCot' : ''}
          ${showField(idFieldPaymentTermAdvance) ? 'paymentTermAdvance' : ''}
          ${
            showField(idFieldEstimatedTotalAdvance)
              ? 'estimatedTotalAdvance'
              : ''
          }
          ${
            showField(idFieldEstimatedTotalBalance)
              ? 'estimatedTotalBalance'
              : ''
          }
          ${
            showField(idFieldIdPaymentTermCondition)
              ? `idPaymentTermCondition2 {
                    description
                  }`
              : ''
          }
            amountSun
            ${showField(idFieldPaymentTermBalance) ? 'paymentTermBalance' : ''}
            estimatedTotalAdvanceSeller
            ${showField(idFieldGrossMargin) ? 'grossMargin' : ''}
          }
        }
      }
    `;

    // Estado inicial de lazy params
    const initialLazyParams = {
      first: 0,
      rows: 25,
      page: 0,
    };

    // Parametros de paginacao/backend
    const [lazyParams, setLazyParams] =
      useState<ILazyParams>(initialLazyParams);

    // Referencia ao toast e formulario
    const { toastRef } = useRefHook();

    const [
      loadClientQuotationSupplierData,
      { loading: clientQuotationSuppliersLoading },
    ] = useLazyQuery(listClientQuotationSuppliersByClientQuotationIdQuery, {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          idCq: clientQuotation.idCq,
        },
      },
      onCompleted: response => {
        if (response.listClientQuotationSuppliersByClientQuotationId) {
          setClientQuotationSuppliersData(response);
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting suppliers data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    const [supplierToDetail, setSupplierToDetail] =
      useState<IClientQuotationSupplier>();

    useImperativeHandle(ref, () => ({
      clearSuppliersData: () => {
        setSupplierToDetail(undefined);
        setClientQuotationSuppliersData(undefined);
      },
    }));

    useEffect(() => {
      if (selected && !clientQuotationSuppliersData) {
        loadClientQuotationSupplierData();
      }
    }, [
      clientQuotationSuppliersData,
      loadClientQuotationSupplierData,
      selected,
    ]);

    /**
     * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
     * @param event Parametros de sort da tabela
     */
    const onSort = useCallback(
      (event: DataTableSortEvent) => {
        const newLazyParams = { ...lazyParams, ...event };
        setLazyParams(newLazyParams);
      },
      [lazyParams],
    );

    /**
     * Ao mudar pagina da tabela, muda os parametros de busca no backend
     * @param event Parametros de paginacao da tabela
     */
    const onPage = useCallback(
      (event: DataTablePageEvent) => {
        const newLazyParams = { ...lazyParams, ...event };
        setLazyParams(newLazyParams);
      },
      [lazyParams],
    );

    /**
     * Retorna coluna com link para cadastro de Supplier
     * @param rowData Dados da Linha
     * @returns Coluna com link para cadastro de Supplier
     */
    const parseSunColumn = (rowData: IClientQuotationSupplier) => {
      if (
        userHasPermission(
          idPermissionSupplierLinkClientQuotation,
          roleEntityPermissions,
        ) &&
        rowData.idSupplier2
      ) {
        return (
          <button
            type="button"
            className="flex gap-2 text-blue-600 hover:text-blue-700 s-transparent-button"
            onClick={() => setSupplierToDetail(rowData)}
          >
            {rowData.idSupplier2?.sunNumber}
            <FiExternalLink size={15} />
          </button>
        );
      }
      return rowData.idSupplier2 && <div>{rowData.idSupplier2?.sunNumber}</div>;
    };

    const renderSupplierInfoColumn = useCallback((rowData: any) => {
      return (
        <p>{`${rowData.idSupplier2.name}${
          rowData.idSupplier2.address ? `/${rowData.idSupplier2.address}` : ''
        }`}</p>
      );
    }, []);

    const renderSupplierPortColumn = useCallback((rowData: any) => {
      if (rowData.idSupplier2.supplierPorts?.length) {
        return <p>{rowData.idSupplier2.supplierPorts[0].idPort2.name}</p>;
      }
      return undefined;
    }, []);

    return (
      <PageTabContainer className="overflow-hidden" selected={selected}>
        {!supplierToDetail && (
          <Grid
            lazy
            totalRecords={
              !clientQuotationSuppliersData
                ? 0
                : clientQuotationSuppliersData
                    .listClientQuotationSuppliersByClientQuotationId?.items
            }
            value={
              clientQuotationSuppliersData
                ?.listClientQuotationSuppliersByClientQuotationId?.data
            }
            emptyMessage="No CQ Suppliers found."
            reorderableColumns
            removableSort
            rows={lazyParams.rows}
            first={lazyParams.first}
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            loading={clientQuotationSuppliersLoading}
            scrollable
            scrollHeight={gridConstants.internalGridScrollHeight}
          >
            {showField(idFieldIdSupplier) && (
              <Column
                field="idSupplier2.sunNumber"
                header="SUN"
                sortable
                body={parseSunColumn}
                style={{ width: '10.71rem' }}
                columnKey="idSupplier2.sunNumber"
              />
            )}
            {showField(idFieldCompanyName) && (
              <Column
                field="idSupplier2.name"
                header="SUPPLIER INFO"
                sortable
                body={renderSupplierInfoColumn}
                style={{ width: '21.43rem' }}
                columnKey="idSupplier2.name"
              />
            )}
            {showField(idFieldIdPort) && (
              <Column
                field="idPort2.name"
                header="PORT/AIRPORT OF LOADING"
                sortable
                body={renderSupplierPortColumn}
                style={{ width: '17.86rem' }}
                columnKey="idPort2.name"
              />
            )}
            {showField(idFieldIdIncoterm) && (
              <Column
                field="idIncoterm2.description"
                header="INCOTERM"
                sortable
                style={{ width: '10.71rem' }}
                body={e => e.idSupplier2.idIncoterm2?.description}
                columnKey="idIncoterm2.description"
              />
            )}
            {showField(idFieldIdIncotermSecond) && (
              <Column
                field="idIncotermSecond2.description"
                header="SECOND INCOTERM"
                sortable
                style={{ width: '13.57rem' }}
                body={e => e.idSupplier2.idIncotermSecond2?.description}
                columnKey="idIncotermSecond2.description"
              />
            )}
            {showField(idFieldProductionTime) && (
              <Column
                field="idSupplier2.productionTime"
                header="PRODUCTION TIME"
                sortable
                style={{ width: '12.86rem' }}
                columnKey="idSupplier2.productionTime"
              />
            )}
            {showField(idFieldPaymentTermAdvance) && (
              <Column
                field="paymentTermAdvance"
                header="PAYMENT TERM ADVANCE (%)"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="paymentTermAdvance"
              />
            )}
            {showField(idFieldPaymentTermBalance) && (
              <Column
                field="paymentTermBalance"
                header="PAYMENT TERM BALANCE (%)"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="paymentTermBalance"
              />
            )}
            {showField(idFieldIdPaymentTermCondition) && (
              <Column
                field="idPaymentTermCondition2.description"
                header="PAYMENT TERM CONDITION"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="idPaymentTermCondition2.description"
              />
            )}
            {showField(idFieldEstimatedTotalAdvance) && (
              <Column
                field="estimatedTotalAdvance"
                header="ESTIMATED TOTAL ADVANCE"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="estimatedTotalAdvance"
              />
            )}
            {showField(idFieldEstimatedTotalBalance) && (
              <Column
                field="estimatedTotalBalance"
                header="ESTIMATED TOTAL BALANCE"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="estimatedTotalBalance"
              />
            )}
            {showField(idFieldAmountCot) && (
              <Column
                field="amountCot"
                header="TOTAL PURCHASE PI (PI COT)"
                sortable
                style={{ width: '17.86rem' }}
                columnKey="amountCot"
              />
            )}
            {showField(idFieldGrossMargin) && (
              <Column
                field="grossMargin"
                header="GROSS MARGIN"
                sortable
                style={{ width: '12.86rem' }}
                columnKey="grossMargin"
              />
            )}
          </Grid>
        )}

        {supplierToDetail && (
          <Supplier
            supplierToDetail={supplierToDetail}
            setSupplierToDetail={setSupplierToDetail}
            roleEntityFields={roleEntityFields}
          />
        )}
      </PageTabContainer>
    );
  },
);

export default Suppliers;
