import { gql } from '@apollo/client';

export const updateSatForeignTradesQuery = gql`
  mutation UpdateSatForeignTrades($data: [UpdateSatForeignTradesInput]!) {
    updateSatForeignTrades(data: $data) {
      warnings
    }
  }
`;

export const updateSatForeignTradeItemsQuery = gql`
  mutation UpdateSatForeignTradeItems($data: UpdateSatForeignTradeItemsInput!) {
    updateSatForeignTradeItems(data: $data) {
      warnings
    }
  }
`;

export const listDomainsByGroupIdQuery = gql`
  query ListDomainsByGroupId(
    $listDomainsByGroupIdId: Int!
    $pagination: Pagination!
    $isActive: Boolean
  ) {
    listDomainsByGroupId(
      id: $listDomainsByGroupIdId
      pagination: $pagination
      isActive: $isActive
    ) {
      data {
        idDomain
        description
      }
    }
  }
`;

export const listSatForeignTradesNumbersQuery = gql`
  query ListSatForeignTradesNumbersBySatId(
    $data: ListSatForeignTradesNumbersBySatIdInput!
  ) {
    listSatForeignTradesNumbersBySatId(data: $data) {
      items
      data {
        idSatForeignTrade
        satForeignTradeNumber
      }
    }
  }
`;

export const getDivergentConsolidatedForeignTradesQuery = gql`
  query GetDivergentConsolidatedForeignTrades(
    $data: [GetDivergentConsolidatedForeignTradesInput]!
  ) {
    getDivergentConsolidatedForeignTrades(data: $data) {
      idForeignTradeSent
      foreignTradeSentNumber
      divergentData {
        idSatForeignTrade
        realArrivalAtClient
        realEta
        realEtd
        satForeignTradeNumber
        idLoadingPort
        loadingPortName
        idPort
        portName
        bookingConfirmed
        shipmentConfirmed
        freightForwarderOrigin
        freightForwarderOriginName
        freightForwarderDestination
        freightForwarderDestinationName
      }
    }
  }
`;
