import { useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import React, { Ref, useImperativeHandle, useState } from 'react';
import FileUpload from '../../../../../../../components/FileUpload';
import FormAsyncSelect from '../../../../../../../components/FormAsyncSelect';
import { useRefHook } from '../../../../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../../../../shared/enums/domainGroup';
import { FileType } from '../../../../../../../shared/enums/fileType';
import { asyncSelectLoadDomains } from '../../../../../../../shared/querys/domain';
import { createSatAttachmentsQuery } from '../../../../queries';
import { FileUploadResponse } from '../../../../../../../components/FileUpload/interfaces';

export interface IFilesUploadRef {
  toggleModal(): void;
}

interface IFilesUploadProps {
  ref: Ref<IFilesUploadRef>;
  idSat: number;
  idSatRnc: number;
  rncFilesRefetch(): void;
}

const FilesUpload: React.FC<IFilesUploadProps> = React.forwardRef(
  ({ idSat, idSatRnc, rncFilesRefetch }, ref) => {
    const { showError, showSuccess } = useRefHook();

    const [displayModal, setDisplayModal] = useState(false);

    const [typeOfFile, setTypeOfFile] = useState<number>();

    const [createSatAttachmentsMutation, { loading }] = useMutation(
      createSatAttachmentsQuery,
    );

    function handleCloseModal() {
      setDisplayModal(false);
      setTypeOfFile(undefined);
    }

    async function handleUploadConfirm(e: FileUploadResponse[]) {
      try {
        await createSatAttachmentsMutation({
          variables: {
            data: {
              idSat,
              idType: typeOfFile,
              uploadedFiles: e.map(file => file.serverName),
              idSatRnc,
            },
          },
        });

        showSuccess({
          summary: 'Files created',
        });

        rncFilesRefetch();

        handleCloseModal();
      } catch (error) {
        showError({
          summary: 'Error while creating files',
          detail: error.message,
        });
      }
    }

    useImperativeHandle(ref, () => ({
      toggleModal: () => {
        if (displayModal) {
          handleCloseModal();
        } else {
          setDisplayModal(true);
        }
      },
    }));

    return (
      <div>
        <Dialog
          header="Upload RNC Files"
          visible={displayModal}
          style={{ width: '40vw' }}
          onHide={() => handleCloseModal()}
          closable
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <FormAsyncSelect
            name="idType"
            label="Type of Files"
            loadOptions={asyncSelectLoadDomains}
            getOptionLabel={option => option.description}
            getOptionValue={option => option.idDomain}
            noOptionsMessage={() => 'No type of files found'}
            onValueChange={e => setTypeOfFile(e.idDomain)}
            additional={{
              id: DomainGroup.RNC_TYPE_FILES,
            }}
          />
          <FileUpload
            multiple
            accept={`${FileType.ALL_IMAGES},${FileType.PDF},${FileType.XLS},${FileType.XLSX},${FileType.DOC},${FileType.DOCX},${FileType.TXT},${FileType.ZIP}`}
            onConfirm={e => handleUploadConfirm(e)}
            disabled={!typeOfFile || loading}
            uploadInProgress={loading}
          />
        </Dialog>
      </div>
    );
  },
);

export default FilesUpload;
