import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadPortsOptions } from '../../../shared/querys/port';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import {
  asyncSelectLoadSuppliers,
  asyncSelectLoadSuppliersName,
} from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const confirmOrderOptions = [
  { label: 'ON NEGOTIATION', value: 'On Negotiation' },
  { label: 'CONFIRMED', value: 'Confirmed' },
  { label: 'CONFIRMED COMMERCIAL', value: 'Confirmed Commercial' },
];

export const gridColumnsData: IColumnData = {
  status: {
    field: 'status',
    header: 'Status',
    value: undefined,
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  confirmOrder: {
    field: 'confirmOrder',
    header: 'Confirm Order',
    value: undefined,
    advancedFilterField: 'confirmOrder',
    options: confirmOrderOptions,
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    value: undefined,
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  satForeignTradeNumber: {
    field: 'satForeignTradeNumber',
    header: 'SAT Shipment',
    value: undefined,
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    value: undefined,
    advancedFilterField: 'mainProduct',
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'productionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  typeOfOrder: {
    field: 'typeOfOrder',
    header: 'Type of Order',
    value: undefined,
    advancedFilterField: 'idTypeOfOrder',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.TYPE_OF_ORDER,
    },
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    value: '',
    advancedFilterField: '',
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    value: undefined,
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    value: undefined,
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    value: undefined,
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty/Type CTNR',
    value: '',
    advancedFilterField: '',
  },
  portLoading: {
    field: 'portLoading',
    header: 'Port of Loading',
    value: undefined,
    advancedFilterField: 'idLoadingPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  portDischarge: {
    field: 'portDischarge',
    header: 'Port of Discharge',
    value: undefined,
    advancedFilterField: 'idPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  countryAcquisition: {
    field: 'countryAcquisition',
    header: 'Country of Acquisition',
    value: undefined,
    advancedFilterField: 'idCountryAcquisition',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  countryProcedence: {
    field: 'countryProcedence',
    header: 'Country of Procedence',
    value: undefined,
    advancedFilterField: 'idCountryProcedence',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  sumQuantityPI: {
    field: 'sumQuantityPI',
    header: 'Quantity PI Shipment',
    value: '',
    advancedFilterField: '',
  },
  countSatItems: {
    field: 'countSatItems',
    header: 'Quantity SAT Items',
    value: '',
    advancedFilterField: '',
  },
  sumQuantityCtn: {
    field: 'sumQuantityCtn',
    header: 'Quantity of CTN',
    value: '',
    advancedFilterField: '',
  },
  sellingIncoterm: {
    field: 'sellingIncoterm',
    header: 'Selling Incoterm',
    value: '',
    advancedFilterField: '',
  },
  totalSunPi: {
    field: 'totalSunPi',
    header: 'Total SUN PI',
    value: '',
    advancedFilterField: '',
  },
  createdAt: {
    field: 'createdAt',
    header: 'Created At',
    value: undefined,
    advancedFilterField: 'createdAt',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  confirmOrderComercial: {
    field: 'confirmOrderComercial',
    header: 'Confirmation Date',
    value: undefined,
    advancedFilterField: 'confirmOrderComercial',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  internalInfo: {
    field: 'internalInfo',
    header: 'Internal Info',
    value: undefined,
    advancedFilterField: 'internalInfo',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  clientInfo: {
    field: 'clientInfo',
    header: 'Client Info',
    value: undefined,
    advancedFilterField: 'clientInfo',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },

  hrp: {
    field: 'hrp',
    header: 'HRP',
    value: undefined,
    advancedFilterField: 'hrp',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  artworkDone: {
    field: 'artworkDone',
    header: 'Artwork Done',
    value: undefined,
    advancedFilterField: 'artworkDone',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedInspectionDate: {
    field: 'estimatedInspectionDate',
    header: 'Estimated Inspection Date',
    value: undefined,
    advancedFilterField: 'estimatedInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    value: undefined,
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    value: '',
    advancedFilterField: '',
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    value: undefined,
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  suppliers: {
    field: 'suppliers',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'suppliers',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'sunNumber',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  clientOrder: {
    field: 'clientOrder',
    header: 'Client Order',
    value: undefined,
    advancedFilterField: 'clientOrder',
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    value: undefined,
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  firstBuyer: {
    field: 'firstBuyer',
    header: 'First Buyer',
    value: undefined,
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  secondBuyer: {
    field: 'secondBuyer',
    header: 'Second Buyer',
    value: undefined,
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  purchaseUser: {
    field: 'purchaseUser',
    header: 'Purchase User',
    value: undefined,
    advancedFilterField: 'idPurchaseUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    value: undefined,
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    value: undefined,
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  designUser: {
    field: 'designUser',
    header: 'Design User',
    value: undefined,
    advancedFilterField: 'idDesignerUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  financialUser: {
    field: 'financialUser',
    header: 'Financial User',
    value: undefined,
    advancedFilterField: 'idFinancialUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  packagesNotes: {
    field: 'packagesNotes',
    header: 'Packages Notes',
    value: '',
    advancedFilterField: '',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.status.field,
          gridColumnsData.confirmOrder.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.satForeignTradeNumber.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.exporter.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.portLoading.field,
          gridColumnsData.portDischarge.field,
          gridColumnsData.countryOrigin.field,
          gridColumnsData.countryAcquisition.field,
          gridColumnsData.countryProcedence.field,
          gridColumnsData.sumQuantityPI.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.totalSunPi.field,
          gridColumnsData.internalInfo.field,
          gridColumnsData.clientInfo.field,
          gridColumnsData.hrp.field,
          gridColumnsData.artworkDone.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.suppliers.field,
          gridColumnsData.clientOrder.field,
          gridColumnsData.clientDepartment.field,
          gridColumnsData.firstSalesUser.field,
          gridColumnsData.secondSalesUser.field,
          gridColumnsData.purchaseUser.field,
          gridColumnsData.exportUser.field,
          gridColumnsData.importUser.field,
          gridColumnsData.designUser.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: [
          gridColumnsData.status.field,
          gridColumnsData.confirmOrder.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.satForeignTradeNumber.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.typeOfOrder.field,
          gridColumnsData.replacementFrom.field,
          gridColumnsData.exporter.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.portLoading.field,
          gridColumnsData.portDischarge.field,
          gridColumnsData.countryOrigin.field,
          gridColumnsData.countryAcquisition.field,
          gridColumnsData.countryProcedence.field,
          gridColumnsData.sumQuantityPI.field,
          gridColumnsData.countSatItems.field,
          gridColumnsData.sumQuantityCtn.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.totalSunPi.field,
          gridColumnsData.createdAt.field,
          gridColumnsData.confirmOrderComercial.field,
          gridColumnsData.internalInfo.field,
          gridColumnsData.clientInfo.field,
          gridColumnsData.hrp.field,
          gridColumnsData.artworkDone.field,
          gridColumnsData.estimatedInspectionDate.field,
          gridColumnsData.estimatedShipmentDate.field,
          gridColumnsData.realInspectionDate.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.suppliers.field,
          gridColumnsData.clientOrder.field,
          gridColumnsData.clientDepartment.field,
          gridColumnsData.firstBuyer.field,
          gridColumnsData.secondBuyer.field,
          gridColumnsData.firstSalesUser.field,
          gridColumnsData.secondSalesUser.field,
          gridColumnsData.purchaseUser.field,
          gridColumnsData.exportUser.field,
          gridColumnsData.importUser.field,
          gridColumnsData.designUser.field,
          gridColumnsData.financialUser.field,
          gridColumnsData.packagesNotes.field,
        ],
      },
    ],
  },
];
