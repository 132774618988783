import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  image: { field: 'imageUrl', header: 'Logo', advancedFilterField: '' },
  cltNumber: {
    field: 'cltNumber',
    header: 'CLT Number',
    advancedFilterField: 'cltNumber',
  },
  name: { field: 'name', header: 'Client Name', advancedFilterField: 'name' },
  companyName: {
    field: 'companyName',
    header: 'Formal Company Name',
    advancedFilterField: 'companyName',
  },
  status: {
    field: 'status2.description',
    header: 'Status',
    advancedFilterField: 'status',
  },
  cnpj: { field: 'cnpj', header: 'Tax ID/CNPJ', advancedFilterField: 'cnpj' },
  businessType: {
    field: 'businessType2.description',
    header: 'Type of Business',
    advancedFilterField: 'businessType',
  },
  numberOfStores: {
    field: 'numberOfStores2.description',
    header: 'Number of Stores',
    advancedFilterField: 'numberOfStores',
  },
  address: {
    field: 'address',
    header: 'Address',
    advancedFilterField: 'address',
  },
  idCity: {
    field: 'idCity2.name',
    header: 'City',
    advancedFilterField: 'idCity',
  },
  zipCode: {
    field: 'zipCode',
    header: 'Zip Code',
    advancedFilterField: 'zipCode',
  },
  paymentAdvance: {
    field: 'paymentAdvance',
    header: 'Payment Term Advance',
    advancedFilterField: 'paymentAdvance',
  },
  paymentBalance: {
    field: 'paymentBalance',
    header: 'Payment Term Balance',
    advancedFilterField: 'paymentBalance',
  },
  paymentDeadline: {
    field: 'paymentDeadline',
    header: 'Estimated Payment Deadline',
    advancedFilterField: 'paymentDeadline',
  },
  salesMultiplier: {
    field: 'salesMultiplier',
    header: 'Sales Multiplier',
    advancedFilterField: 'salesMultiplier',
  },
  costsMultiplier: {
    field: 'costsMultiplier',
    header: 'Costs Multiplier',
    advancedFilterField: 'costsMultiplier',
  },
  incoterm: {
    field: 'incoterm2.description',
    header: 'Incoterm',
    advancedFilterField: 'incoterm',
  },
};
