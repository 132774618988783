import { Dialog } from 'primereact/dialog';
import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import { Checkbox } from 'primereact/checkbox';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from 'primereact/button';
import { SelectInstance } from 'react-select';
import { useRefHook } from '../../hooks/useRefHook';
import {
  ISelectRecipientsModalProps,
  IUserEmailsProps,
  IUserGroupsProps,
} from './interfaces';
import { listAllUserGroupsQuery } from '../../shared/querys/userGroups';
import { UserGroupType } from '../../shared/enums/userGroup';
import { Row } from './styles';
import { asyncSelectLoadUsersOptions } from '../../shared/querys/user';

const SelectRecipientsModal: React.FC<ISelectRecipientsModalProps> = ({
  isVisibleRecipientsModal,
  setIsVisibleRecipientsModal,
  selectedUsers,
  userGroups,
  setSelectedUsers,
  setUserGroups,
}) => {
  const { showError } = useRefHook();
  const selectRef = useRef<SelectInstance<any, boolean, any>>(null);

  const checkGroup = (e: number) => {
    if (userGroups) {
      const data = userGroups;
      const index = userGroups.findIndex(x => x.idUserGroup === e);
      data[index].isChecked = !data[index].isChecked;
      setUserGroups([...data]);
    }
  };

  /**
   * Busca grupos de emails
   */
  const { loading } = useQuery(listAllUserGroupsQuery, {
    variables: {
      data: {
        type: UserGroupType.EMAIL_GROUP,
        active: true,
        onlyWithActiveUsers: true,
      },
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting emails groups',
        detail: errorData.message,
      });
    },
    onCompleted: async response => {
      if (response.listAllUserGroups.data) {
        const dataUserGroups: IUserGroupsProps[] = [];
        response.listAllUserGroups.data.forEach((element: any) => {
          const userGroup = element;
          userGroup.isChecked = false;
          dataUserGroups.push(userGroup);
        });
        setUserGroups(dataUserGroups);
      }
    },
  });

  const onHide = () => {
    setIsVisibleRecipientsModal(false);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />

        <Button
          label="Confirm"
          icon="pi pi-check"
          onClick={() => onHide()}
          autoFocus
        />
      </div>
    );
  };

  return (
    <Dialog
      header="Recipients"
      visible={isVisibleRecipientsModal}
      style={{ width: '30vw' }}
      footer={renderFooter()}
      onHide={() => onHide()}
    >
      <Row>
        <h3>Select groups and users:</h3>

        <AsyncPaginate
          loadOptionsOnMenuOpen
          value={selectedUsers}
          loadOptions={asyncSelectLoadUsersOptions}
          debounceTimeout={1000}
          isMulti
          selectRef={selectRef}
          getOptionLabel={(option: any) =>
            `${option.firstName} ${option.lastName}`
          }
          getOptionValue={(option: any) => option.idUser}
          onChange={e => setSelectedUsers(e as IUserEmailsProps[])}
          additional={{
            page: 1,
          }}
        />

        <div className="grid mt-2">
          {!loading &&
            userGroups &&
            userGroups.map((element: any) => {
              return (
                <div key={element.idUserGroup} className="col-6">
                  <div className="field-checkbox">
                    <Checkbox
                      inputId={element.idUserGroup}
                      name={element.name}
                      value={element.idUserGroup}
                      onChange={e => checkGroup(e.value)}
                      checked={element.isChecked}
                    />
                    <p className="ml-2">{` ${element.name}`}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </Row>
    </Dialog>
  );
};

export default SelectRecipientsModal;
