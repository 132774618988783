import {
  DivergentForeignTradesReducer,
  DivergentForeignTradesReducerAction,
  DivergentForeignTradesReducerActionKind,
} from './interfaces';

export const divergentForeignTradesReducerInitialState: DivergentForeignTradesReducer =
  {
    displayDialog: false,
    divergentForeignTrades: [],
  };

export const divergentForeignTradesReducer = (
  state: DivergentForeignTradesReducer,
  action: DivergentForeignTradesReducerAction,
): DivergentForeignTradesReducer => {
  switch (action.type) {
    case DivergentForeignTradesReducerActionKind.CLOSE_DIALOG:
      return {
        ...state,
        displayDialog: false,
        divergentForeignTrades: [],
      };

    case DivergentForeignTradesReducerActionKind.OPEN_DIALOG: {
      if (!state.displayDialog && action.payload?.divergentForeignTrades) {
        return {
          ...state,
          divergentForeignTrades: action.payload.divergentForeignTrades,
          displayDialog: !state.displayDialog,
        };
      }

      return { ...state, displayDialog: !state.displayDialog };
    }

    default:
      throw new Error();
  }
};
