import { gql } from '@apollo/client';

export const registerDashboardQuery = gql`
  query GetSatRegisterProcessDashboard(
    $data: GetSatRegisterProcessDashboardInput
  ) {
    getSatRegisterProcessDashboard(data: $data) {
      idDomain
      description
      order
      data {
        idSat
        satNumber
        satForeignTradeNumber
        realInspectionDate
        realEtd
        mainProduct
        importerConsignee
        idSatForeignTrade
        clientOrder
        clientNotify
        countryOrigin
        categoryIcon
        category
      }
      items
    }
  }
`;
