import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import MultiSelectGroup from '../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import {
  asyncSelectLoadAllClientContacts,
  asyncSelectLoadAllClientContactsDepartments,
} from '../../../shared/querys/clientContacts';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadPortsOptions } from '../../../shared/querys/port';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import { asyncSelectLoadSuppliersName } from '../../../shared/querys/supplier';
import { asyncSelectLoadUsersOptions } from '../../../shared/querys/user';

interface IColumnData {
  [key: string]: ColumnData;
}

const shipmentResumeOptions = [
  { label: 'SHIPPED', value: 'Shipped' },
  { label: 'TO SHIP', value: 'To Ship' },
  { label: 'CANCELED', value: 'Canceled' },
];

export const gridColumnsData: IColumnData = {
  status: {
    field: 'status',
    header: 'Status',
    value: undefined,
    advancedFilterField: 'status',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_SAT,
    },
  },
  satNumber: {
    field: 'satNumber',
    header: 'SAT Number',
    value: undefined,
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  shipment: {
    field: 'shipment',
    header: 'SAT Shipment',
    value: undefined,
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  clientOrder: {
    field: 'clientOrder',
    header: 'Client Order',
    value: undefined,
    advancedFilterField: 'clientOrder',
  },
  mainProduct: {
    field: 'mainProduct',
    header: 'Main Product',
    value: undefined,
    advancedFilterField: 'mainProduct',
  },
  exporter: {
    field: 'exporter',
    header: 'Exporter',
    value: undefined,
    advancedFilterField: 'idSupplierExporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliersName,
      isMulti: true,
    },
  },
  importerConsignee: {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    value: undefined,
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  clientNotify: {
    field: 'clientNotify',
    header: 'Client/Notify',
    value: undefined,
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  ctnrs: {
    field: 'ctnrs',
    header: 'Qty/Type CTNR',
    value: undefined,
    advancedFilterField: '',
  },
  shipmentResume: {
    field: 'shipmentResume',
    header: 'Shipment Resume',
    value: undefined,
    advancedFilterField: 'shipmentResume',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    options: shipmentResumeOptions,
    asyncSelectOptions: {
      optionLabel: 'label',
      optionValue: 'value',
      isMulti: true,
    },
  },
  importStatus: {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  registerStatus: {
    field: 'registerStatus',
    header: 'Register Status',
    value: undefined,
    advancedFilterField: 'idRegisterStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.REGISTER_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  artworkStatus: {
    field: 'artworkStatus',
    header: 'Artwork Status',
    value: undefined,
    advancedFilterField: 'idStatusArtwork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.STATUS_ARTWORK,
      additional: {
        orderByDescription: true,
      },
    },
  },
  productionStatus: {
    field: 'productionStatus',
    header: 'Production Status',
    value: undefined,
    advancedFilterField: 'productionStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.PRODUCTION_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  shipmentStatus: {
    field: 'shipmentStatus',
    header: 'Shipment Status',
    value: undefined,
    advancedFilterField: 'idShipmentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.SHIPMENT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  documentsStatus: {
    field: 'documentsStatus',
    header: 'Documents Status',
    value: undefined,
    advancedFilterField: 'idDocumentsStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.DOCUMENTS_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  supplierPaymentStatus: {
    field: 'supplierPaymentStatus',
    header: 'Supplier Payment Status',
    advancedFilterField: 'supplierPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_PURCHASE_PAYMENT,
    },
  },
  clientPaymentStatus: {
    field: 'clientPaymentStatus',
    header: 'Client Payment Status',
    advancedFilterField: 'clientPaymentStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FINANCIAL_STATUS_SELLING_PAYMENT,
    },
  },
  exportStatus: {
    field: 'exportStatus',
    header: 'Export Status',
    value: undefined,
    advancedFilterField: 'idExportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.EXPORT_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  startDate: {
    field: 'startDate',
    header: 'Start Date',
    value: undefined,
    advancedFilterField: 'startDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  productionTime: {
    field: 'productionTime',
    header: 'Production Time',
    value: undefined,
    advancedFilterField: '',
  },
  samples: {
    field: 'samples',
    header: 'Samples',
    value: undefined,
    advancedFilterField: '',
  },
  estimatedInspectionDate: {
    field: 'estimatedInspectionDate',
    header: 'Estimated Inspection Date',
    value: undefined,
    advancedFilterField: 'estimatedInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realInspectionDate: {
    field: 'realInspectionDate',
    header: 'Real Inspection Date',
    value: undefined,
    advancedFilterField: 'realInspectionDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  inspectionScheduled: {
    field: 'inspectionScheduled',
    header: 'Inspection Scheduled',
    value: undefined,
    advancedFilterField: '',
  },
  inspectionApproval: {
    field: 'inspectionApproval',
    header: 'Inspection Approval',
    value: undefined,
    advancedFilterField: 'inspectionApproval',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  rework: {
    field: 'rework',
    header: 'Rework',
    value: undefined,
    advancedFilterField: '',
  },
  reworkDate: {
    field: 'reworkDate',
    header: 'Rework Date',
    value: undefined,
    advancedFilterField: '',
  },
  authorizationRequested: {
    field: 'authorizationRequested',
    header: 'Authorization Requested',
    value: undefined,
    advancedFilterField: '',
  },
  shipmentAuthorized: {
    field: 'shipmentAuthorized',
    header: 'Shipment Authorized',
    value: undefined,
    advancedFilterField: '',
  },
  importLicenseNumber: {
    field: 'importLicenseNumber',
    header: 'Import License Number',
    value: undefined,
    advancedFilterField: '',
  },
  importLicenseExpireDate: {
    field: 'importLicenseExpireDate',
    header: 'Import License Expire Date',
    value: undefined,
    advancedFilterField: '',
  },
  estimatedShipmentDate: {
    field: 'estimatedShipmentDate',
    header: 'Estimated Shipment Date',
    value: undefined,
    advancedFilterField: 'estimatedShipmentDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEtd: {
    field: 'realEtd',
    header: 'Real ETD',
    value: undefined,
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  portLoading: {
    field: 'portLoading',
    header: 'Port of Loading',
    value: undefined,
    advancedFilterField: 'idLoadingPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  freightForwarderOrigin: {
    field: 'freightForwarderOrigin',
    header: 'Freight Forwarder Origin',
    value: undefined,
    advancedFilterField: '',
  },
  estimatedArrivalDate: {
    field: 'estimatedArrivalDate',
    header: 'Estimated Arrival Date',
    value: undefined,
    advancedFilterField: 'estimatedArrivalDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realEta: {
    field: 'realEta',
    header: 'Real ETA',
    value: undefined,
    advancedFilterField: 'realEta',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  portDischarge: {
    field: 'portDischarge',
    header: 'Port of Discharge',
    value: undefined,
    advancedFilterField: 'idPort',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idPort',
      optionData: asyncSelectLoadPortsOptions,
      isMulti: true,
    },
  },
  freightForwarderDestination: {
    field: 'freightForwarderDestination',
    header: 'Freight Forwarder Destination',
    value: undefined,
    advancedFilterField: '',
  },
  trakingNumber: {
    field: 'trakingNumber',
    header: 'Traking Number',
    value: undefined,
    advancedFilterField: '',
  },
  courier: {
    field: 'courier',
    header: 'Courier',
    value: undefined,
    advancedFilterField: '',
  },
  estimatedArrivalClientDate: {
    field: 'estimatedArrivalClientDate',
    header: 'Estimated Arrival Client Date',
    value: undefined,
    advancedFilterField: 'estimatedArrivalClientDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  realArrivalAtClient: {
    field: 'realArrivalAtClient',
    header: 'Real Arrival at Client',
    value: undefined,
    advancedFilterField: 'realArrivalAtClient',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  diNumber: {
    field: 'diNumber',
    header: 'DI Number',
    value: undefined,
    advancedFilterField: '',
  },
  paymentTermAdvance: {
    field: 'paymentTermAdvance',
    header: 'Payment Term Advance',
    value: undefined,
    advancedFilterField: '',
  },
  paymentTermBalance: {
    field: 'paymentTermBalance',
    header: 'Payment Term Balance',
    value: undefined,
    advancedFilterField: '',
  },
  paymentTermCondition: {
    field: 'paymentTermCondition',
    header: 'Payment Term Condition',
    value: undefined,
    advancedFilterField: '',
  },
  paymentSellingIncoterm: {
    field: 'paymentSellingIncoterm',
    header: 'Selling Incoterm',
    value: undefined,
    advancedFilterField: 'idClientIncoterm',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.INCOTERM,
    },
  },
  sellerTotalSunPi: {
    field: 'sellerTotalSunPi',
    header: 'Seller Total Sun PI',
    value: undefined,
    advancedFilterField: '',
  },
  sellerTotalSunPiUsd: {
    field: 'sellerTotalSunPiUsd',
    header: 'Seller Total Sun PI USD',
    value: undefined,
    advancedFilterField: '',
  },
  sellerTotalSunCi: {
    field: 'sellerTotalSunCi',
    header: 'Seller Total Sun CI',
    value: undefined,
    advancedFilterField: '',
  },
  sellerTotalSunCiUsd: {
    field: 'sellerTotalSunCiUsd',
    header: 'Seller Total Sun CI USD',
    value: undefined,
    advancedFilterField: '',
  },
  freightCost: {
    field: 'freightCost',
    header: 'Freight Cost',
    value: undefined,
    advancedFilterField: '',
  },
  freightExtraCost: {
    field: 'freightExtraCost',
    header: 'Freight Extra Cost',
    value: undefined,
    advancedFilterField: '',
  },
  apportionment: {
    field: 'apportionment',
    header: 'Apportionment',
    value: undefined,
    advancedFilterField: '',
  },
  totalWithApportionment: {
    field: 'totalWithApportionment',
    header: 'Total with Apportionment',
    value: undefined,
    advancedFilterField: '',
  },
  rncCreditNote: {
    field: 'rncCreditNote',
    header: 'RNC Credit Note',
    value: undefined,
    advancedFilterField: '',
  },
  creditToClient: {
    field: 'creditToClient',
    header: 'Credit to Client',
    value: undefined,
    advancedFilterField: '',
  },
  totalAdvance: {
    field: 'totalAdvance',
    header: 'Total Advance Received',
    value: undefined,
    advancedFilterField: '',
  },
  maxAdvanceReceiveDate: {
    field: 'maxAdvanceReceiveDate',
    header: 'Advance Receive Date',
    value: undefined,
    advancedFilterField: '',
  },
  maxAdvancePaymentDate: {
    field: 'maxAdvancePaymentDate',
    header: 'Advance Payment Date',
    value: undefined,
    advancedFilterField: '',
  },
  totalBalance: {
    field: 'totalBalance',
    header: 'Total Balance Received',
    value: undefined,
    advancedFilterField: '',
  },
  maxBalanceReceiveDate: {
    field: 'maxBalanceReceiveDate',
    header: 'Balance Receive Date',
    value: undefined,
    advancedFilterField: '',
  },
  maxBalancePaymentDate: {
    field: 'maxBalancePaymentDate',
    header: 'Balance Payment Date',
    value: undefined,
    advancedFilterField: '',
  },
  totalRemainSun: {
    field: 'totalRemainSun',
    header: 'Total Remain Sun',
    value: undefined,
    advancedFilterField: '',
  },
  importLevel: {
    field: 'importLevel',
    header: 'Import Level',
    value: undefined,
    advancedFilterField: '',
  },
  typeOfImportService: {
    field: 'typeOfImportService',
    header: 'Type of Import Service',
    value: undefined,
    advancedFilterField: '',
  },
  typeOperation: {
    field: 'typeOperation',
    header: 'Type of Operation',
    value: undefined,
    advancedFilterField: '',
  },
  replacementFrom: {
    field: 'replacementFrom',
    header: 'Replacement From',
    value: undefined,
    advancedFilterField: '',
  },
  consolidatedWith: {
    field: 'consolidatedWith',
    header: 'Consolidated With',
    value: undefined,
    advancedFilterField: '',
  },
  satGroup: {
    field: 'satGroup',
    header: 'SAT Group',
    value: undefined,
    advancedFilterField: '',
  },
  category: {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.TREE_SELECT,
  },
  countryOrigin: {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  clientDepartment: {
    field: 'clientDepartment',
    header: 'Client Department',
    value: undefined,
    advancedFilterField: 'clientDepartment',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'department',
      optionValue: 'department',
      optionData: asyncSelectLoadAllClientContactsDepartments,
      isMulti: true,
    },
  },
  firstBuyer: {
    field: 'firstBuyer',
    header: 'First Buyer',
    value: undefined,
    advancedFilterField: 'idFirstBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  secondBuyer: {
    field: 'secondBuyer',
    header: 'Second Buyer',
    value: undefined,
    advancedFilterField: 'idSecondBuyer',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'clientNameWithContactName',
      optionValue: 'idClientContact',
      optionData: asyncSelectLoadAllClientContacts,
      isMulti: true,
    },
  },
  firstSalesUser: {
    field: 'firstSalesUser',
    header: '1st Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  secondSalesUser: {
    field: 'secondSalesUser',
    header: '2nd Commercial User',
    value: undefined,
    advancedFilterField: 'idSalesSecondUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  exportUser: {
    field: 'exportUser',
    header: 'Export User',
    value: undefined,
    advancedFilterField: 'idComexExportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  importUser: {
    field: 'importUser',
    header: 'Import User',
    value: undefined,
    advancedFilterField: 'idComexImportUser',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'fullName',
      optionValue: 'idUser',
      optionData: asyncSelectLoadUsersOptions,
      isMulti: true,
    },
  },
  startProductionTiming: {
    field: 'startProductionTiming',
    header: 'Start Production Timing',
    value: undefined,
    advancedFilterField: '',
  },
  realProductionTime: {
    field: 'realProductionTime',
    header: 'Real Production Time',
    value: undefined,
    advancedFilterField: '',
  },
  authorizationTiming: {
    field: 'authorizationTiming',
    header: 'Authorization Timing',
    value: undefined,
    advancedFilterField: '',
  },
  authorizationBookingTiming: {
    field: 'authorizationBookingTiming',
    header: 'Authorization Booking Timing',
    value: undefined,
    advancedFilterField: '',
  },
  authorizationShipmentTiming: {
    field: 'authorizationShipmentTiming',
    header: 'Authorization Shipment Timing',
    value: undefined,
    advancedFilterField: '',
  },
  bookingShipmentTiming: {
    field: 'bookingShipmentTiming',
    header: 'Booking Shipment Timing',
    value: undefined,
    advancedFilterField: '',
  },
  preShipmentTiming: {
    field: 'preShipmentTiming',
    header: 'Pre-Shipment Timing',
    value: undefined,
    advancedFilterField: '',
  },
  transitTime: {
    field: 'transitTime',
    header: 'Transit Time',
    value: undefined,
    advancedFilterField: '',
  },
  hblFinalTiming: {
    field: 'hblFinalTiming',
    header: 'HBL Final Timing',
    value: undefined,
    advancedFilterField: '',
  },
  originalDocsTiming: {
    field: 'originalDocsTiming',
    header: 'Original Docs Timing',
    value: undefined,
    advancedFilterField: '',
  },
  deliveryAtClientTiming: {
    field: 'deliveryAtClientTiming',
    header: 'Delivery at Client Timing',
    value: undefined,
    advancedFilterField: '',
  },
  totalForeignTradeTiming: {
    field: 'totalForeignTradeTiming',
    header: 'Total Foreign Trade Timing',
    value: undefined,
    advancedFilterField: '',
  },
  importNotes: {
    field: 'importNotes',
    header: 'Import Notes',
    value: undefined,
    advancedFilterField: '',
  },
  remarks: {
    field: 'remarks',
    header: 'Remarks',
    value: undefined,
    advancedFilterField: '',
  },
  updatesEn: {
    field: 'updatesEn',
    header: 'Updates EN',
    value: undefined,
    advancedFilterField: '',
  },
  followUpPt: {
    field: 'followUpPt',
    header: 'Follow Up PT',
    value: undefined,
    advancedFilterField: '',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.status.field,
          gridColumnsData.satNumber.field,
          gridColumnsData.shipment.field,
          gridColumnsData.clientOrder.field,
          gridColumnsData.mainProduct.field,
          gridColumnsData.exporter.field,
          gridColumnsData.importerConsignee.field,
          gridColumnsData.clientNotify.field,
          gridColumnsData.ctnrs.field,
          gridColumnsData.importStatus.field,
          gridColumnsData.registerStatus.field,
          gridColumnsData.artworkStatus.field,
          gridColumnsData.productionStatus.field,
          gridColumnsData.shipmentStatus.field,
          gridColumnsData.documentsStatus.field,
          gridColumnsData.supplierPaymentStatus.field,
          gridColumnsData.clientPaymentStatus.field,
          gridColumnsData.shipmentResume.field,
          gridColumnsData.startDate.field,
          gridColumnsData.estimatedInspectionDate.field,
          gridColumnsData.realInspectionDate.field,
          gridColumnsData.estimatedShipmentDate.field,
          gridColumnsData.realEtd.field,
          gridColumnsData.portLoading.field,
          gridColumnsData.estimatedArrivalDate.field,
          gridColumnsData.realEta.field,
          gridColumnsData.portDischarge.field,
          gridColumnsData.estimatedArrivalClientDate.field,
          gridColumnsData.realArrivalAtClient.field,
          gridColumnsData.paymentSellingIncoterm.field,
          gridColumnsData.sellerTotalSunPi.field,
          gridColumnsData.sellerTotalSunPiUsd.field,
          gridColumnsData.sellerTotalSunCi.field,
          gridColumnsData.sellerTotalSunCiUsd.field,
          gridColumnsData.totalWithApportionment.field,
          gridColumnsData.creditToClient.field,
          gridColumnsData.totalAdvance.field,
          gridColumnsData.totalBalance.field,
          gridColumnsData.totalRemainSun.field,
          gridColumnsData.importLevel.field,
          gridColumnsData.typeOfImportService.field,
          gridColumnsData.consolidatedWith.field,
          gridColumnsData.satGroup.field,
          gridColumnsData.category.field,
          gridColumnsData.clientDepartment.field,
          gridColumnsData.firstBuyer.field,
          gridColumnsData.secondBuyer.field,
          gridColumnsData.firstSalesUser.field,
          gridColumnsData.secondSalesUser.field,
          gridColumnsData.exportUser.field,
          gridColumnsData.importUser.field,
          gridColumnsData.totalForeignTradeTiming.field,
          gridColumnsData.updatesEn.field,
          gridColumnsData.followUpPt.field,
        ],
      },
      {
        key: '0-1',
        label: 'Full',
        data: Object.entries(gridColumnsData).map(column => column[1].field),
      },
    ],
  },
];
