import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .s-readonly-input {
    width: 263px;
  }

  .supplierInfoInput {
    width: 821px;
  }

  textarea {
    resize: vertical;
  }

  .form-item + .form-item {
    margin-top: 32px;
  }
`;

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  margin-bottom: 32px;

  svg {
    margin: 0 11px;
    color: var(--grey-scale);
  }

  button {
    font-size: 14px;
    background: none;
    border: none;
    color: var(--blue);
  }
`;

export const Row = styled.div`
  display: flex;

  .s-readonly-input + .s-readonly-input {
    margin-left: 16px;
  }

  .form-checkbox {
    margin-left: 16px;
  }

  .form-checkbox + .form-checkbox {
    margin-top: 6px;
  }

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    width: 263px;
    padding: 4px;

    label + .p-radiobutton {
      margin-left: 12px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
  }

  && {
    margin-top: 16px;
  }
`;
