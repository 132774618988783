export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const booleanStringValueOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const booleanNumberValueOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
