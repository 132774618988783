import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ISat } from '../../../interfaces';
import {
  FinancialFieldsCommonWithSatFieldPermissions,
  FinancialFieldsPermissions,
  SatForeignTradeSupplier,
} from '../../interfaces';

interface ISellerProps {
  satForeignTradeSupplier: SatForeignTradeSupplier;
  sat: ISat;
  fieldsPermissions: FinancialFieldsPermissions;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
  userCanSeeSellerBlock: boolean;
}

const Seller: React.FC<ISellerProps> = ({
  satForeignTradeSupplier,
  sat,
  fieldsPermissions,
  commonWithSatFieldsPermissions,
  userCanSeeSellerBlock,
}) => {
  return userCanSeeSellerBlock ? (
    <div className="grid">
      <FormTitle className="col-12 seller-form-title" name="Seller" />

      <div className="grid nested-grid col-12">
        {fieldsPermissions.idStatusSellingPayment.view && (
          <ReadOnlyInput
            className="col-2"
            label="Status Selling Payment"
            value={satForeignTradeSupplier.idStatusSellingPayment2?.description}
          />
        )}

        {commonWithSatFieldsPermissions.companyName.view && (
          <ReadOnlyInput
            className="col-3"
            label="Client/Notify"
            value={sat.companyName}
          />
        )}

        {commonWithSatFieldsPermissions.importerName.view && (
          <ReadOnlyInput
            className="col-3"
            label="Importer/Consignee"
            value={sat.importerName}
          />
        )}

        {fieldsPermissions.exchangeRate.view && (
          <ReadOnlyInput
            className="col-2"
            label="Exchange Rate"
            type="number"
            locale="pt-BR"
            minimumFractionDigits={4}
            value={satForeignTradeSupplier.exchangeRate}
            prefix="$"
          />
        )}
      </div>

      <div className="grid nested-grid col-12">
        {commonWithSatFieldsPermissions.idClientIncoterm.view && (
          <ReadOnlyInput
            className="col-2"
            label="Selling Incoterm"
            value={sat.idClientIncoterm2?.description}
          />
        )}

        {fieldsPermissions.idImportStatus.view && (
          <ReadOnlyInput
            className="col-3"
            label="Import Status"
            value={
              satForeignTradeSupplier.idSatForeignTrade2.idImportStatus2
                .description
            }
          />
        )}

        {fieldsPermissions.realInspectionDate.view && (
          <ReadOnlyInput
            className="col-3"
            label="Real Inspection Date"
            value={
              satForeignTradeSupplier.idSatForeignTrade2.realInspectionDate
            }
            type="date"
          />
        )}

        {fieldsPermissions.realEtd.view && (
          <ReadOnlyInput
            className="col-2"
            label="Real ETD"
            value={satForeignTradeSupplier.idSatForeignTrade2.realEtd}
            type="date"
          />
        )}

        {fieldsPermissions.realEta.view && (
          <ReadOnlyInput
            className="col-2"
            label="Real ETA"
            value={satForeignTradeSupplier.idSatForeignTrade2.realEta}
            type="date"
          />
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default Seller;
