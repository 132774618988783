import { gql, useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { confirmDialog } from 'primereact/confirmdialog';
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import Empty from '../../../../../../components/Empty';
import FormCheckbox from '../../../../../../components/FormCheckbox';
import Loading from '../../../../../../components/Loading';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import { IRoleEntityField } from '../../../../../../interfaces/IRoleEntityField';
import ToastLife from '../../../../../../shared/enums/toastLife';
import { satItemsRoles } from '../../../../../../shared/roles/satItems';
import getFieldPermission from '../../../../../../utils/getFieldPermission';
import { IListSatItem } from '../../../interfaces';
import {
  DifferenceToRefreshSatItemsResponse,
  IRefreshSatItemOptions,
  ISatItemsReducerAction,
  RefreshSelectFieldsPermissions,
  SatItemsReducerActionKind,
} from '../interfaces';

import { CheckboxBlock, Container } from './styles';

/**
 * Interface de Ref do componente de Refresh Item
 */
export interface IRefreshSatItemSelectFieldsRef {
  /**
   * Funcao para selecionar todos os registros
   */
  handleSelectAll(): void;

  /**
   * Funcao para submit do formulario
   */
  submitForm(): void;
}

/**
 * Interface de Props do componente de Refresh Item
 */
interface IRefreshItemSelectFieldsProps {
  /**
   * Client Quotation Is Confection
   */
  indConfection?: boolean;

  /**
   * Referencia do componente de Refresh Item
   */
  ref: Ref<IRefreshSatItemSelectFieldsRef>;

  /**
   * Estado de todos os fields selecionados
   */
  allFieldsSelected: boolean;

  /**
   * Dispatch do reducer de modal
   */
  modalsDispatch: Dispatch<ISatItemsReducerAction>;

  /**
   * Define estado de response do refresh de fields selecionados
   */
  setRefreshSelectedFieldsResponse: Dispatch<
    SetStateAction<DifferenceToRefreshSatItemsResponse[]>
  >;

  /**
   * SAT Items selecionados
   */
  selectedSatItems: IListSatItem[];

  /**
   * Define estado de fields que deverao ser atualizados em cada item
   */
  setItemsFieldsToUpdate: Dispatch<
    SetStateAction<IRefreshSatItemOptions | undefined>
  >;

  userFields: IRoleEntityField[];
}

const disabledSunFieldTooltip =
  'This field will be updated because you opted to update the SUN Number';

const RefreshItemSelectFields: React.FC<IRefreshItemSelectFieldsProps> =
  React.forwardRef(
    (
      {
        indConfection,
        allFieldsSelected,
        modalsDispatch,
        setRefreshSelectedFieldsResponse,
        selectedSatItems,
        setItemsFieldsToUpdate,
        userFields,
      },
      ref,
    ) => {
      // Referencia ao toast
      const { toastRef } = useRefHook();

      const [userWantsToUpdateSunNumber, setUserWantsToUpdateSunNumber] =
        useState(false);

      const fieldsPermissions: RefreshSelectFieldsPermissions = useMemo(() => {
        return {
          artworkTechnique: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshArtworkTechnique,
            userFields,
          ),
          brand: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshBrand,
            userFields,
          ),
          colour: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshColour,
            userFields,
          ),
          colourImage: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshColourImage,
            userFields,
          ),
          colourPt: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshColourPt,
            userFields,
          ),
          cotPrice: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshCotPrice,
            userFields,
          ),
          description: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshDescription,
            userFields,
          ),
          descriptionPt: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshDescriptionPt,
            userFields,
          ),
          dimensions: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshDimensions,
            userFields,
          ),
          hsCode: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshHsCode,
            userFields,
          ),
          image: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshImage,
            userFields,
          ),
          importantInformation: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshImportantInformation,
            userFields,
          ),
          m2Pc: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshM2Pc,
            userFields,
          ),
          materialComposition: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshMaterialComposition,
            userFields,
          ),
          materialPt: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshMaterialPt,
            userFields,
          ),
          model: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshModel,
            userFields,
          ),
          moq: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshMoq,
            userFields,
          ),
          name: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshName,
            userFields,
          ),
          detailedDescriptionPt: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshDetailedDescriptionPt,
            userFields,
          ),
          namePt: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshNamePt,
            userFields,
          ),
          ncm: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshNcm,
            userFields,
          ),
          netWeight: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshNetWeight,
            userFields,
          ),
          package: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshPackage,
            userFields,
          ),
          sku: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshSku,
            userFields,
          ),
          sunNumber: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshSunNumber,
            userFields,
          ),
          sunPrice: getFieldPermission(
            satItemsRoles.fields.idFieldRefreshSunPrice,
            userFields,
          ),
        };
      }, [userFields]);

      const userCanSeeSomeRefreshFieldsInfo = Object.keys(
        fieldsPermissions,
      ).some(
        key =>
          fieldsPermissions[key as keyof RefreshSelectFieldsPermissions].view,
      );

      // Query para buscar diferenca entre cada field
      const getDifferenceToRefreshSatItemsQuery = gql`
        query GetDifferenceToRefreshSatItems($data: RefreshSatItemsInput!) {
          getDifferenceToRefreshSatItems(data: $data) {
            idSatItem
            stCode
            data {
              property
              label
              oldValue
              newValue
              changed
            }
          }
        }
      `;

      /**
       * Busca dados de SAT Item
       */
      const [
        loadDifferenceToRefreshSatItemsData,
        { loading: getDifferenceLoading },
      ] = useLazyQuery(getDifferenceToRefreshSatItemsQuery, {
        onCompleted: response => {
          if (response.getDifferenceToRefreshSatItems) {
            setRefreshSelectedFieldsResponse(
              response.getDifferenceToRefreshSatItems,
            );
          } else {
            toastRef.current?.show({
              severity: 'error',
              summary: 'Data not found',
              life: ToastLife.ERROR,
            });
          }
        },
        onError: errorData => {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error while getting difference data',
            detail: errorData.message,
            life: ToastLife.ERROR,
          });
        },
      });

      // Referencia ao formulario para atualizar sat item
      const formRef = useRef<FormHandles>(null);

      useImperativeHandle(
        ref,
        () => ({
          handleSelectAll: () => {
            // Nega valor atual de selecao dos fields
            const value = !allFieldsSelected;

            setUserWantsToUpdateSunNumber(value);

            // Busca todos os dados do formulario
            const formData = formRef.current?.getData();

            if (formData) {
              // Define todos os valores do formulario de acordo com a selecao
              // desejada
              Object.keys(formData).forEach(key => {
                // Define valores que nao devem ser alterados
                if ((key === 'colour' || key === 'colourPt') && indConfection) {
                  return;
                }
                if (key === 'artworkTechnique' && !indConfection) return;

                // Atribui valor de selecao
                formData[key] = value;
              });

              // Define novo objeto no formulario
              formRef.current?.setData(formData);

              // Define novo valor de selecao geral
              modalsDispatch({
                type: SatItemsReducerActionKind.CHANGE_ALL_FIELDS_SELECTED,
              });
            }
          },
          submitForm: () => {
            formRef.current?.submitForm();
          },
        }),
        [allFieldsSelected, indConfection, modalsDispatch],
      );

      /**
       * Executa request para refresh dos itens
       */
      const callRefreshItems = useCallback(
        (data: IRefreshSatItemOptions) => {
          // Busca IDs de SAT Items
          const idsSatItems = selectedSatItems.map(item => item.idSatItem);

          // Chama query
          loadDifferenceToRefreshSatItemsData({
            variables: {
              data: {
                ...data,
                idsSatItems,
              },
            },
          });

          // Salva fields que precisam ser atualizados no estado
          setItemsFieldsToUpdate(data);
        },
        [
          loadDifferenceToRefreshSatItemsData,
          selectedSatItems,
          setItemsFieldsToUpdate,
        ],
      );

      /**
       * Efetua solicitacao para refresh dos itens
       * @param data Dados do formulario
       */
      const handleRequestRefreshItem = useCallback(
        (data: IRefreshSatItemOptions) => {
          if (data.sunPrice || data.cotPrice) {
            confirmDialog({
              message:
                'Please note the price will be updated according to MD-Product. Prices updated in the SAT Item will be replaced. Confirm?',
              header: 'Alert',
              icon: 'pi pi-info-circle',
              acceptClassName: 'p-button-danger',
              accept: () => callRefreshItems(data),
            });
          } else {
            callRefreshItems(data);
          }
        },

        [callRefreshItems],
      );

      function handleChangeSunNumber(e: CheckboxChangeEvent) {
        setUserWantsToUpdateSunNumber(!!e.checked);

        if (e.checked) {
          formRef.current?.setFieldValue('sunPrice', true);
          formRef.current?.setFieldValue('cotPrice', true);
          formRef.current?.setFieldValue('moq', true);
        }

        return true;
      }

      return (
        <Container>
          {userCanSeeSomeRefreshFieldsInfo && (
            <Form ref={formRef} onSubmit={handleRequestRefreshItem}>
              <CheckboxBlock>
                {fieldsPermissions.sunPrice.view && (
                  <FormCheckbox
                    className="m-2"
                    name="sunPrice"
                    label="Update SUN Price"
                    disabled={userWantsToUpdateSunNumber}
                    tooltip={
                      userWantsToUpdateSunNumber
                        ? disabledSunFieldTooltip
                        : undefined
                    }
                  />
                )}
                {fieldsPermissions.cotPrice.view && (
                  <FormCheckbox
                    className="m-2"
                    name="cotPrice"
                    label="Update Cot Price"
                    disabled={userWantsToUpdateSunNumber}
                    tooltip={
                      userWantsToUpdateSunNumber
                        ? disabledSunFieldTooltip
                        : undefined
                    }
                  />
                )}
                {fieldsPermissions.sunNumber.view && (
                  <FormCheckbox
                    className="m-2"
                    name="sunNumber"
                    label="SUN Number"
                    onValueChange={e => handleChangeSunNumber(e)}
                  />
                )}
                {fieldsPermissions.moq.view && (
                  <FormCheckbox
                    className="m-2"
                    name="moq"
                    label="MOQ"
                    initialValue
                    disabled={userWantsToUpdateSunNumber}
                    tooltip={
                      userWantsToUpdateSunNumber
                        ? disabledSunFieldTooltip
                        : undefined
                    }
                  />
                )}
              </CheckboxBlock>
              <CheckboxBlock>
                {fieldsPermissions.model.view && (
                  <FormCheckbox
                    className="m-2"
                    name="model"
                    label="Model"
                    initialValue
                  />
                )}
                {fieldsPermissions.sku.view && (
                  <FormCheckbox
                    className="m-2"
                    name="sku"
                    label="SKU"
                    initialValue
                  />
                )}
                {fieldsPermissions.netWeight.view && (
                  <FormCheckbox
                    className="m-2"
                    name="netWeight"
                    label="Net Weight"
                    initialValue
                  />
                )}
                {fieldsPermissions.name.view && (
                  <FormCheckbox
                    className="m-2"
                    name="name"
                    label="Name (EN)"
                    initialValue
                  />
                )}
                {fieldsPermissions.namePt.view && (
                  <FormCheckbox
                    className="m-2"
                    name="namePt"
                    label="Name (PT)"
                    initialValue
                  />
                )}
                {fieldsPermissions.materialComposition.view && (
                  <FormCheckbox
                    className="m-2"
                    name="materialComposition"
                    label="Material (EN) + Composition (%)"
                    initialValue
                  />
                )}
                {fieldsPermissions.materialPt.view && (
                  <FormCheckbox
                    className="m-2"
                    name="materialPt"
                    label="Material + Composition (PT)"
                    initialValue
                  />
                )}
                {fieldsPermissions.colour.view && (
                  <FormCheckbox
                    className="m-2"
                    name="colour"
                    label="Colour (EN)"
                    disabled={indConfection}
                    initialValue={!indConfection}
                  />
                )}
                {fieldsPermissions.colourPt.view && (
                  <FormCheckbox
                    className="m-2"
                    name="colourPt"
                    label="Colour (PT)"
                    disabled={indConfection}
                    initialValue={!indConfection}
                  />
                )}
                {fieldsPermissions.description.view && (
                  <FormCheckbox
                    className="m-2"
                    name="description"
                    label="Description (EN)"
                    initialValue
                  />
                )}
                {fieldsPermissions.descriptionPt.view && (
                  <FormCheckbox
                    className="m-2"
                    name="descriptionPt"
                    label="Description (PT)"
                    initialValue
                  />
                )}
              </CheckboxBlock>
              <CheckboxBlock>
                {fieldsPermissions.dimensions.view && (
                  <FormCheckbox
                    className="m-2"
                    name="dimensions"
                    label="Dimensions"
                    initialValue
                  />
                )}
                {fieldsPermissions.importantInformation.view && (
                  <FormCheckbox
                    className="m-2"
                    name="importantInformation"
                    label="Important information"
                    initialValue
                  />
                )}
                {fieldsPermissions.package.view && (
                  <FormCheckbox
                    className="m-2"
                    name="package"
                    label="Package"
                    initialValue
                  />
                )}
                {fieldsPermissions.brand.view && (
                  <FormCheckbox
                    className="m-2"
                    name="brand"
                    label="Branding"
                    initialValue
                  />
                )}
                {fieldsPermissions.artworkTechnique.view && (
                  <FormCheckbox
                    className="m-2"
                    name="artworkTechnique"
                    label="Artwork/Technique"
                    disabled={!indConfection}
                    initialValue={!!indConfection}
                  />
                )}
                {fieldsPermissions.m2Pc.view && (
                  <FormCheckbox
                    className="m-2"
                    name="m2Pc"
                    label="SQM Unit"
                    initialValue
                  />
                )}
              </CheckboxBlock>
              <CheckboxBlock>
                {fieldsPermissions.image.view && (
                  <FormCheckbox
                    className="m-2"
                    name="image"
                    label="Image"
                    initialValue
                  />
                )}
                {fieldsPermissions.colourImage.view && (
                  <FormCheckbox
                    className="m-2"
                    name="colourImage"
                    label="Colour Image"
                    initialValue
                  />
                )}
              </CheckboxBlock>
              <CheckboxBlock>
                {fieldsPermissions.hsCode.view && (
                  <FormCheckbox
                    className="m-2"
                    name="hsCode"
                    label="HS Code"
                    initialValue
                  />
                )}
                {fieldsPermissions.ncm.view && (
                  <FormCheckbox
                    className="m-2"
                    name="ncm"
                    label="NCM"
                    initialValue
                  />
                )}
              </CheckboxBlock>
            </Form>
          )}
          {!userCanSeeSomeRefreshFieldsInfo && (
            <Empty message="You do not have permission to see any options" />
          )}
          {getDifferenceLoading && <Loading />}
        </Container>
      );
    },
  );

export default RefreshItemSelectFields;
