import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { gql } from '@apollo/client';
import client from '../../services/apollo/client';
import pagination from '../../config/pagination';

/**
 * Carrega ST Codes
 * @param search Valor escrito no input
 * @param prevOptions Opcoes carregadas anteriormente
 * @param pageData Dados da pagina
 * @returns Dados dos products
 */
export const asyncSelectLoadProductsStCodes: LoadOptions<
  any,
  GroupBase<any>,
  { page: any }
> = async (search: string, prevOptions: any, pageData) => {
  const res = await client.query({
    query: gql`
      query listAllProductsWithoutRelationsQuery(
        $data: ListAllProductsWithoutRelationsInput!
      ) {
        listAllProductsWithoutRelations(data: $data) {
          data {
            idProduct
            stCode
          }
          items
        }
      }
    `,
    variables: {
      data: {
        pagination: {
          _page: pageData?.page,
          _limit: pagination.itemsPerPage,
        },
        stCode: search,
      },
    },
  });

  // Opcoes filtradas
  const filteredOptions = res.data.listAllProductsWithoutRelations.data;
  // Quantidade de itens
  const quantity = res.data.listAllProductsWithoutRelations.items;
  // Valida se ha mais resultados para serem buscados
  const hasMore = quantity > prevOptions.length + pagination.itemsPerPage;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: pageData?.page + 1,
    },
  };
};
