/* eslint-disable @typescript-eslint/ban-types */
import { gql } from '@apollo/client';
import client from '../services/apollo/client';

// Mutation de user local storage
const writeUserLocalStorage = gql`
  mutation WriteUserLocalStorage($data: WriteUserLocalStorage!) {
    writeUserLocalStorage(data: $data)
  }
`;

/**
 * Atualiza dado de local storage no banco de dados
 * @param key Chave do local storage
 * @param value Valor armazenado em local storage
 */
async function updateLocalStorageInDb(
  key: string,
  value: object,
): Promise<void> {
  try {
    await client.mutate({
      mutation: writeUserLocalStorage,
      variables: {
        data: {
          key,
          value,
        },
      },
    });
  } catch (e) {
    //
  }
}

export default updateLocalStorageInDb;
