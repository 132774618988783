import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .form-div-margin {
    margin-top: 32px;
  }

  .form-div {
    margin-top: 32px;
    display: flex;

    textarea {
      resize: vertical;
    }

    h1 + div,
    .formInput + .formInput,
    .form-dropdown + .formInput,
    .formInput + .form-dropdown,
    .formInput + .form-textarea-input {
      margin-top: 16px;
    }
    .form-dropdown {
      height: 46px;
      width: 100%;
    }
    .descriptions-div + .descriptions-div {
      margin-left: 20px;
    }
  }

  .generalInput {
    width: 263px;
  }

  .descriptions-div,
  .form-textarea-input {
    width: 540px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  .generalInput + .generalInput {
    margin-left: 16px;
  }

  .form-checkbox {
    margin-left: 16px;
    width: unset;
  }

  .radio-button-div {
    background-color: #f4f4f4;

    > label {
      color: var(--grey-scale);
    }

    width: 540px;
    padding: 4px;
    padding-bottom: 15px;
    margin-bottom: 20px;

    label + .p-radiobutton {
      margin-left: 24px;
    }

    .p-radiobutton + label {
      margin-left: 4px;
    }
    .p-hidden-accessible {
      position: initial;
    }
  }

  && {
    margin-top: 16px;
  }
`;
export const SupplierCategoryItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 540px;

  textarea {
    resize: vertical;
  }

  .form-textarea-input {
    width: 274px;
  }

  .form-tree-select {
    width: 450px;
  }

  .form-dropdown {
    width: 450px;
  }

  > div + div {
    margin-left: 20px;
  }

  div {
    .form-textarea-input + .form-checkbox,
    .form-textarea-input + .generalInput,
    .generalInput + .form-textarea-input,
    .generalInput + .generalInput {
      margin-top: 16px;
    }
  }
  .formInput {
    width: 274px;
  }

  .button-div {
    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;

export const SupplierPortItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  border: 2px solid var(--grey);
  border-radius: 8px;
  padding: 12px;
  width: 540px;

  textarea {
    resize: vertical;
  }

  .form-textarea-input {
    width: 274px;
  }

  .form-dropdown {
    width: 450px;
  }

  > div + div {
    margin-left: 20px;
  }

  div {
    .form-textarea-input + .form-checkbox,
    .form-textarea-input + .generalInput,
    .generalInput + .form-textarea-input,
    .generalInput + .generalInput {
      margin-top: 16px;
    }
  }
  .formInput {
    width: 274px;
  }

  .button-div {
    display: flex;
    align-items: center;

    button {
      float: right;
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;

      &:hover {
        color: var(--red);
      }
    }
  }
`;
