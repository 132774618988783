import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import { useRefHook } from '../../../hooks/useRefHook';
import pagination from '../../../config/pagination';
import {
  IFinancialTransactionComment,
  IFinancialTransactionUpdatesLazyParams,
} from '../FinancialTransaction/Updates/interfaces';
import Loading from '../../../components/Loading';
import Comment from '../../../components/Comment';
import { parseCommentMessage } from '../../../utils/parseCommentMessage';
import { listFinancialTransactionCommentsQuery } from '../FinancialTransaction/Updates/queries';
import CommentPagination, {
  ICommentPaginationPageParams,
} from '../../../components/CommentPagination';

interface IFinancialTransactionAlertsProps {
  idFinancialTransaction: number;
}

const FinancialTransactionAlerts: React.FC<IFinancialTransactionAlertsProps> =
  ({ idFinancialTransaction }) => {
    const { showError } = useRefHook();
    const [displayModal, setDisplayModal] = useState(false);
    const [comments, setComments] =
      useState<{ data: IFinancialTransactionComment[]; items: number }>();

    const [lazyParams, setLazyParams] =
      useState<IFinancialTransactionUpdatesLazyParams>(
        pagination.initialLazyParams,
      );

    const { loading: generatedSystemCommentsLoading } = useQuery(
      listFinancialTransactionCommentsQuery,
      {
        skip: !idFinancialTransaction || !displayModal,
        variables: {
          data: {
            idFinancialTransaction,
            isGeneratedSystem: true,
            pagination: {
              _page: lazyParams.page + 1,
              _limit: lazyParams.rows,
            },
          },
        },
        onCompleted: response => {
          if (response.listFinancialTransactionComments) {
            if (lazyParams.page && comments) {
              setComments({
                items: response.listFinancialTransactionComments.items,
                data: [
                  ...comments.data,
                  ...response.listFinancialTransactionComments.data,
                ],
              });
            } else {
              setComments(response.listFinancialTransactionComments);
            }
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting data',
            detail: errorData.message,
          });
        },
      },
    );

    function handleCloseModal() {
      setDisplayModal(false);
      setComments(undefined);
      setLazyParams(pagination.initialLazyParams);
    }

    function handleAlertButtonClick() {
      setDisplayModal(true);
    }

    const onPage = (e: ICommentPaginationPageParams) => {
      setLazyParams({
        ...lazyParams,
        ...e,
      });
    };

    return (
      <div>
        <button
          type="button"
          onClick={() => handleAlertButtonClick()}
          title="Alerts"
        >
          <i className="pi pi-exclamation-circle text-yellow-600 hover:text-yellow-500 transition-duration-200" />
        </button>

        <Dialog
          header="Alerts"
          visible={displayModal}
          style={{ width: '35vw' }}
          onHide={() => handleCloseModal()}
          closable
          closeOnEscape
          dismissableMask
          onClick={e => e.stopPropagation()}
        >
          {generatedSystemCommentsLoading && !comments ? (
            <Loading />
          ) : comments?.data?.length ? (
            <>
              {comments?.data?.map((comment: IFinancialTransactionComment) => (
                <Comment
                  key={comment.idComment}
                  idComment={comment.idComment}
                  userName={`${comment.idComment2.createdBy2?.firstName} ${comment.idComment2.createdBy2?.lastName}`}
                  creation={comment.idComment2.createdAt}
                  commonTag={{
                    className: 'mr-2',
                    rounded: true,
                    isGeneratedSystem: comment.idComment2.isGeneratedSystem,
                  }}
                  userImageUrl={comment.idComment2.createdBy2?.avatarUrl}
                  idCreatedBy={comment.idComment2.createdBy2?.idUser}
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: parseCommentMessage(comment.idComment2),
                    }}
                    className="comment"
                  />
                </Comment>
              ))}
              <CommentPagination
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={comments?.items ?? 0}
                onPage={e => onPage(e)}
                loading={generatedSystemCommentsLoading}
                page={lazyParams.page}
              />
            </>
          ) : (
            <p>No alerts available.</p>
          )}
        </Dialog>
      </div>
    );
  };

export default FinancialTransactionAlerts;
