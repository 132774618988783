import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  gap: 20px 12px;

  grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
`;

export const Column = styled.div`
  max-width: 272px;

  .header {
    padding: 15px 0;
    border-bottom: 1px solid #6c757d;
    font-weight: 700;
    text-transform: uppercase;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Option = styled.div`
  display: flex;
  gap: 8px;

  & + & {
    margin-top: 10px;
  }
`;
