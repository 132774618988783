import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ForeignTradeSupplierTotalReducer } from '../interfaces';
import { SatFinancialTotalFieldsPermissions } from '../../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';

interface IPurchaseTotalPaidProps {
  totalState: ForeignTradeSupplierTotalReducer;
  fieldsPermissions: SatFinancialTotalFieldsPermissions;
  satCurrency?: string;
}

const PurchaseTotalPaid: React.FC<IPurchaseTotalPaidProps> = ({
  totalState,
  fieldsPermissions,
  satCurrency,
}) => {
  const userCanSeeBlock =
    fieldsPermissions.total.view || fieldsPermissions.totalRemain.view;

  const satCurrencySymbol = getCurrencySymbol(satCurrency);

  return userCanSeeBlock ? (
    <div className="grid">
      <FormTitle className="col-12" name="Total Paid" />

      {fieldsPermissions.total.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Paid"
          value={totalState.purchaseTotalPaid}
          loading={
            totalState.purchaseAdvancePaidTotalLoading ||
            totalState.purchaseBalancePaidTotalLoading
          }
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
        />
      )}

      {fieldsPermissions.totalRemain.view && (
        <ReadOnlyInput
          className="col-2"
          label="Total Remain COT"
          value={totalState.purchaseTotalRemainCot}
          loading={
            totalState.purchaseAdvancePaidTotalLoading ||
            totalState.purchaseBalancePaidTotalLoading ||
            totalState.purchaseOtherExpensesTotalLoading ||
            totalState.financialTotalLoading
          }
          type="number"
          locale="pt-BR"
          prefix={satCurrencySymbol}
          maximumFractionDigits={4}
          minimumFractionDigits={2}
          severity={
            totalState.purchaseTotalRemainCot &&
            totalState.purchaseTotalRemainCot < 0
              ? 'danger'
              : undefined
          }
        />
      )}
    </div>
  ) : (
    <div />
  );
};

export default PurchaseTotalPaid;
