import MultiSelectGroup from '../../../../components/Grid/MultiSelectGroup';
import { ColumnData } from '../../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  imageUrl: {
    header: 'Image',
    field: 'idImage2.url',
    advancedFilterField: '',
  },
  sunNumber: {
    header: 'SUN',
    field: 'idSupplier2.sunNumber',
    advancedFilterField: '',
  },
  stCode: {
    header: 'ST CODE',
    field: 'stCode',
    advancedFilterField: '',
  },
  refCode: {
    header: 'REF CODE',
    field: 'refCode',
    advancedFilterField: '',
  },
  name: {
    header: 'NAME (EN)',
    field: 'name',
    advancedFilterField: '',
  },
  description: {
    header: 'DESCRIPTION (EN)',
    field: 'description',
    advancedFilterField: '',
  },
  namePt: {
    header: 'NAME (PT)',
    field: 'namePt',
    advancedFilterField: '',
  },
  descriptionPt: {
    header: 'DESCRIPTION (PT)',
    field: 'descriptionPt',
    advancedFilterField: '',
  },
  model: {
    header: 'MODEL',
    field: 'model',
    advancedFilterField: '',
  },
  sku: {
    header: 'SKU',
    field: 'sku',
    advancedFilterField: '',
  },
  colour: {
    header: 'COLOR (EN)',
    field: 'colour',
    advancedFilterField: '',
  },
  colourPt: {
    header: 'COLOR (PT)',
    field: 'colourPt',
    advancedFilterField: '',
  },
  colourImage: {
    header: 'COLOR IMAGE',
    field: 'idColourImage2.url',
    advancedFilterField: '',
  },
  materialComposition: {
    header: 'MATERIAL/COMPOSITION (EN)',
    field: 'materialComposition',
    advancedFilterField: '',
  },
  materialPt: {
    header: 'MATERIAL/COMPOSITION (PT)',
    field: 'materialPt',
    advancedFilterField: '',
  },
  dimensions: {
    header: 'DIMENSION',
    field: 'dimensions',
    advancedFilterField: '',
  },
  netWeight: {
    header: 'NET WEIGHT (Kg)',
    field: 'netWeight',
    advancedFilterField: '',
  },
  artworkEn: {
    header: 'ARTWORK (EN)',
    field: 'artworkEn',
    advancedFilterField: '',
  },
  techniqueEn: {
    header: 'TECHNIQUE (EN)',
    field: 'techniqueEn',
    advancedFilterField: '',
  },
  typeOfFabric: {
    header: 'TYPE OF FABRIC',
    field: 'typeOfFabric',
    advancedFilterField: '',
  },
  lining: {
    header: 'LINING',
    field: 'lining',
    advancedFilterField: '',
  },
  liningComposition: {
    header: 'LINING COMPOSITION (%)',
    field: 'liningComposition',
    advancedFilterField: '',
  },
  linedDescription: {
    header: 'LINED DESCRIPTION',
    field: 'linedDescription',
    advancedFilterField: '',
  },
  typeOfPackage: {
    header: 'TYPE OF PACKAGE',
    field: 'idTypePackage2.description',
    advancedFilterField: '',
  },
  packageDescription: {
    header: 'SELLING PACKAGE',
    field: 'packageDescription',
    advancedFilterField: '',
  },
  packageNotes: {
    header: 'PACKAGE NOTES',
    field: 'packageNotes',
    advancedFilterField: '',
  },
  sellingPackagePcQuantity: {
    header: 'QUANTITY/SELLING PACKAGE',
    field: 'sellingPackagePcQuantity',
    advancedFilterField: '',
  },
  sellingPackageDimensions: {
    header: 'SELLING PACKAGE DIMENSION',
    field: 'sellingPackageDimensions',
    advancedFilterField: '',
  },
  sellingPackageGrossWeight: {
    header: 'SELLING PACKAGE GROSS WEIGHT',
    field: 'sellingPackageGrossWeight',
    advancedFilterField: '',
  },
  stickerDimensionsPt: {
    header: 'STICKERS DIMENSIONS',
    field: 'stickerDimensionsPt',
    advancedFilterField: '',
  },
  quantityInner: {
    header: 'UN OR SET/INNER',
    field: 'quantityInner',
    advancedFilterField: '',
  },
  boxInner: {
    header: 'INNER/CTN',
    field: 'boxInner',
    advancedFilterField: '',
  },
  packageEan13: {
    header: 'PACKAGE EAN 13',
    field: 'packageEan13',
    advancedFilterField: '',
  },
  innerDun14: {
    header: 'INNER DUN 14',
    field: 'innerDun14',
    advancedFilterField: '',
  },
  dun14: {
    header: 'DUN 14',
    field: 'dun14',
    advancedFilterField: '',
  },
  brand: {
    header: 'BRAND',
    field: 'brand',
    advancedFilterField: '',
  },
  productLine: {
    header: 'PRODUCT LINE',
    field: 'productLine',
    advancedFilterField: '',
  },
  quantity: {
    header: 'QUANTITY PI',
    field: 'quantity',
    advancedFilterField: '',
  },
  unit: {
    header: 'UNIT',
    field: 'unit2.description',
    advancedFilterField: '',
  },
  moq: {
    header: 'MOQ',
    field: 'moq',
    advancedFilterField: '',
  },
  m2Pc: {
    header: 'SQM UNIT',
    field: 'm2Pc',
    advancedFilterField: '',
  },
  totalMasterM2: {
    header: 'TOTAL SQM',
    field: 'totalMasterM2',
    advancedFilterField: '',
  },
  cotPriceSqm: {
    header: 'COT PRICE SQM',
    field: 'cotPriceSqm',
    advancedFilterField: '',
  },
  sunPriceSqm: {
    header: 'SUN PRICE SQM',
    field: 'sunPriceSqm',
    advancedFilterField: '',
  },
  quantityContainer: {
    header: 'QUANTITY OF CTN',
    field: 'quantityContainer',
    advancedFilterField: '',
  },
  piecesContainer: {
    header: 'PCS/CTN',
    field: 'piecesContainer',
    advancedFilterField: '',
  },
  masterNetWeight: {
    header: 'NET WEIGHT (CTN)',
    field: 'masterNetWeight',
    advancedFilterField: '',
  },
  masterNetWeightTotal: {
    header: 'NET WEIGHT TOTAL',
    field: 'masterNetWeightTotal',
    advancedFilterField: '',
  },
  grossWeight: {
    header: 'GROSS WEIGHT',
    field: 'grossWeight',
    advancedFilterField: '',
  },
  grossWeightTotal: {
    header: 'GROSS WEIGHT TOTAL',
    field: 'grossWeightTotal',
    advancedFilterField: '',
  },
  length: {
    header: 'LENGTH',
    field: 'length',
    advancedFilterField: '',
  },
  width: {
    header: 'WIDTH',
    field: 'width',
    advancedFilterField: '',
  },
  height: {
    header: 'HEIGHT',
    field: 'height',
    advancedFilterField: '',
  },
  cbm: {
    header: 'CBM',
    field: 'cbm',
    advancedFilterField: '',
  },
  cbmTotal: {
    header: 'CBM TOTAL',
    field: 'cbmTotal',
    advancedFilterField: '',
  },
  purchaseIncoterm: {
    header: 'PURCHASE INCOTERM',
    field: 'idPurchaseIncoterm2.description',
    advancedFilterField: '',
  },
  targetCot: {
    header: 'TARGET COT',
    field: 'targetCot',
    advancedFilterField: '',
  },
  cotPrice: {
    header: 'COT PRICE',
    field: 'cotPrice',
    advancedFilterField: '',
  },
  amountCot: {
    header: 'TOTAL COT',
    field: 'amountCot',
    advancedFilterField: '',
  },
  sellingIncoterm: {
    header: 'SELLING INCOTERM',
    field: 'idSellingIncoterm2.description',
    advancedFilterField: '',
  },
  targetSun: {
    header: 'TARGET SUN',
    field: 'targetSun',
    advancedFilterField: '',
  },
  sunPrice: {
    header: 'SUN PRICE',
    field: 'sunPrice',
    advancedFilterField: '',
  },
  amount: {
    header: 'TOTAL SUN PI',
    field: 'amount',
    advancedFilterField: '',
  },
  estimateProfit: {
    header: 'ESTIMATED PROFIT',
    field: 'estimateProfit',
    advancedFilterField: '',
  },
  estimateMargin: {
    header: 'ESTIMATED MARGIN',
    field: 'estimateMargin',
    advancedFilterField: '',
  },
  hsCode: {
    header: 'HS CODE',
    field: 'hsCode',
    advancedFilterField: '',
  },
  ncm: {
    header: 'NCM',
    field: 'ncm',
    advancedFilterField: '',
  },
  ncmDestaque: {
    header: 'DESTAQUE',
    field: 'ncmDestaque',
    advancedFilterField: '',
  },
  ncmForeignTradeReview: {
    header: 'FOREIGN TRADE REVIEW',
    field: 'ncmForeignTradeReview',
    advancedFilterField: '',
  },
  ncmLi: {
    header: 'IMPORT LICENSE',
    field: 'ncmLi',
    advancedFilterField: '',
  },
  ncmIi: {
    header: '% II',
    field: 'ncmIi',
    advancedFilterField: '',
  },
  ncmIpi: {
    header: '% IPI',
    field: 'ncmIpi',
    advancedFilterField: '',
  },
  ncmPis: {
    header: '% PIS',
    field: 'ncmPis',
    advancedFilterField: '',
  },
  ncmCofins: {
    header: '% COFINS',
    field: 'ncmCofins',
    advancedFilterField: '',
  },
  ncmDumping: {
    header: 'DUMPING',
    field: 'ncmDumping',
    advancedFilterField: '',
  },
  samples: {
    header: 'SAMPLES',
    field: 'samples',
    advancedFilterField: '',
  },
  sampleLeadTime: {
    header: 'SAMPLE LEAD TIME',
    field: 'sampleLeadTime',
    advancedFilterField: '',
  },
  samplesApproved: {
    header: 'SAMPLES APPROVED',
    field: 'samplesApproved',
    advancedFilterField: '',
  },
  sampleNotes: {
    header: 'SAMPLE NOTES',
    field: 'sampleNotes',
    advancedFilterField: '',
  },
  licensed: {
    header: 'LICENSED',
    field: 'licensed',
    advancedFilterField: '',
  },
  licensor: {
    header: 'LICENSOR',
    field: 'idLicensor2.name',
    advancedFilterField: '',
  },
  royalties: {
    header: 'ROYALTIES',
    field: 'royalties',
    advancedFilterField: '',
  },
};

export const groupedColumns: MultiSelectGroup[] = [
  {
    key: '0',
    label: 'Preview',
    data: [],
    children: [
      {
        key: '0-0',
        label: 'Resume',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '0-1',
        label: 'Purchase',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '0-2',
        label: 'Commercial',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '0-3',
        label: 'SQM',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '0-4',
        label: 'Fashion',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.typeOfFabric.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.linedDescription.field,
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
    ],
  },
  {
    key: '1',
    label: 'Field Groups',
    data: [],
    children: [
      {
        key: '1-0',
        label: 'Produto',
        data: [
          gridColumnsData.imageUrl.field,
          gridColumnsData.sunNumber.field,
          gridColumnsData.stCode.field,
          gridColumnsData.name.field,
          gridColumnsData.description.field,
          gridColumnsData.namePt.field,
          gridColumnsData.descriptionPt.field,
          gridColumnsData.colour.field,
          gridColumnsData.colourPt.field,
          gridColumnsData.colourImage.field,
          gridColumnsData.materialComposition.field,
          gridColumnsData.materialPt.field,
          gridColumnsData.dimensions.field,
          gridColumnsData.netWeight.field,
          gridColumnsData.artworkEn.field,
          gridColumnsData.techniqueEn.field,
          gridColumnsData.typeOfFabric.field,
          gridColumnsData.lining.field,
          gridColumnsData.liningComposition.field,
          gridColumnsData.linedDescription.field,
          gridColumnsData.typeOfPackage.field,
        ],
      },
      {
        key: '1-1',
        label: 'Package',
        data: [
          gridColumnsData.typeOfPackage.field,
          gridColumnsData.packageDescription.field,
          gridColumnsData.packageNotes.field,
          gridColumnsData.sellingPackagePcQuantity.field,
          gridColumnsData.sellingPackageDimensions.field,
          gridColumnsData.sellingPackageGrossWeight.field,
          gridColumnsData.stickerDimensionsPt.field,
          gridColumnsData.quantityInner.field,
          gridColumnsData.boxInner.field,
          gridColumnsData.packageEan13.field,
          gridColumnsData.innerDun14.field,
          gridColumnsData.dun14.field,
          gridColumnsData.brand.field,
          gridColumnsData.productLine.field,
          gridColumnsData.quantityContainer.field,
          gridColumnsData.piecesContainer.field,
          gridColumnsData.masterNetWeight.field,
          gridColumnsData.masterNetWeightTotal.field,
          gridColumnsData.grossWeight.field,
          gridColumnsData.grossWeightTotal.field,
          gridColumnsData.length.field,
          gridColumnsData.width.field,
          gridColumnsData.height.field,
          gridColumnsData.cbm.field,
          gridColumnsData.cbmTotal.field,
        ],
      },
      {
        key: '1-2',
        label: 'QTY',
        data: [
          gridColumnsData.quantity.field,
          gridColumnsData.unit.field,
          gridColumnsData.moq.field,
        ],
      },
      {
        key: '1-3',
        label: 'SQM',
        data: [
          gridColumnsData.m2Pc.field,
          gridColumnsData.totalMasterM2.field,
          gridColumnsData.cotPriceSqm.field,
          gridColumnsData.sunPriceSqm.field,
        ],
      },
      {
        key: '1-4',
        label: 'Carton',
        data: [],
      },
      {
        key: '1-5',
        label: 'Prices',
        data: [
          gridColumnsData.purchaseIncoterm.field,
          gridColumnsData.targetCot.field,
          gridColumnsData.cotPrice.field,
          gridColumnsData.amountCot.field,
          gridColumnsData.sellingIncoterm.field,
          gridColumnsData.targetSun.field,
          gridColumnsData.sunPrice.field,
          gridColumnsData.amount.field,
          gridColumnsData.estimateProfit.field,
          gridColumnsData.estimateMargin.field,
        ],
      },
      {
        key: '1-6',
        label: 'Samples',
        data: [
          gridColumnsData.samples.field,
          gridColumnsData.sampleLeadTime.field,
          gridColumnsData.samplesApproved.field,
          gridColumnsData.sampleNotes.field,
        ],
      },
      {
        key: '1-7',
        label: 'Licensing',
        data: [
          gridColumnsData.licensed.field,
          gridColumnsData.licensor.field,
          gridColumnsData.royalties.field,
        ],
      },
    ],
  },
];
