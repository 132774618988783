/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-return-assign */
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import { Menu } from 'primereact/menu';
import Button from '../../../components/Button';
import Grid, { GridRef } from '../../../components/Grid';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import MainButton from '../../../components/MainButton';
import PageHeader from '../../../components/PageHeader';
import { useRefHook } from '../../../hooks/useRefHook';

import imagePlaceholder from '../../../assets/imagePlaceholder.svg';

import Tag from '../../../components/Tag';
import getFieldPermission from '../../../utils/getFieldPermission';
import { productRoles, productViewRoles } from '../../../shared/roles/product';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import userHasPermission from '../../../utils/userHasPermission';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import {
  parseFormatedTextColumn,
  parseStCodeColumn,
} from '../../../utils/gridColumnsParse';
import ReferenceSizes from './ReferenceSizes';
import {
  IBulkResponse,
  IProductListViewLazyParams,
  ProductListView,
  ProductListViewPermissions,
} from './interfaces';
import { ProductStatus } from '../Product/enums';
import { searchDelayMiliseconds } from '../../../config/pagination';
import useTitle from '../../../hooks/useTitle';
import { ColumnData } from '../../../components/Grid/interfaces';
import { gridColumnsData } from './constants';
import { getUTC } from '../../../utils/dateUtil';
import {
  createProductsBulkQuery,
  exportProductListQuery,
  listProductListViewQuery,
  updateProductsBulkQuery,
} from './queries';
import { filterOnlyAllowedColumnsFromLocalStorage } from '../../../components/Grid/utils';
import CreateProductsAttachmentsBulk, {
  ICreateProductsAttachmentsBulkRef,
} from './CreateProductsAttachmentsBulk';
import {
  FileUploadRef,
  FileUploadResponse,
} from '../../../components/FileUpload/interfaces';
import { FileType } from '../../../shared/enums/fileType';
import FileUpload from '../../../components/FileUpload';
import {
  createClientQuotation,
  createClientQuotationQuery,
} from '../../../shared/mutations/clientQuotation';

const screenSubject = 'Products';
const pageTitle = `List of ${screenSubject}`;

const Products: React.FC = () => {
  const inputImportBulkRef = useRef<FileUploadRef>(null);
  const inputUpdateBulkRef = useRef<FileUploadRef>(null);
  const massiveActionMenuRef = useRef<Menu>(null);
  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);
  const isMounted = useRef(false);

  const gridRef = useRef<GridRef<ProductListView[]>>(null);
  const { showError, showSuccess } = useRefHook();
  useTitle(pageTitle);

  // Nome da key de grid columns
  const gridColumnsName = '@SAT:productsGridColumns';

  const lazyParamsName = '@SAT:productsLazyParams';

  const advancedFiltersName = '@SAT:productsAdvancedFilters';

  const createAttachmentsBulkRef =
    useRef<ICreateProductsAttachmentsBulkRef>(null);

  // Redirect
  const history = useHistory();

  // URL params
  const location = useLocation();

  // ID da entity de usuario
  const { idEntity } = productViewRoles;

  // Roles do usuario
  const { roles } = useAuth();

  const { userFields, userPermissions } = useMemo(() => {
    return getUserFieldsAndPermissionsByEntity(roles.rolesUser, idEntity);
  }, [idEntity, roles.rolesUser]);

  const permissions = useMemo(() => {
    return {
      export: userHasPermission(
        productRoles.permissions.idPermissionExportProductList,
        userPermissions,
      ),
      delete: userHasPermission(
        productRoles.permissions.idPermissionDeleteProduct,
        userPermissions,
      ),
      createSat: userHasPermission(
        productRoles.permissions.idPermissionCreateSat,
        userPermissions,
      ),
      createClientQuotation: userHasPermission(
        productRoles.permissions.idPermissionCreateClientQuotation,
        userPermissions,
      ),
    };
  }, [userPermissions]);

  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);

  // Estado de botoes do header fixos
  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);

  // Estado de loading
  const [pageLoading, setPageLoading] = useState(false);

  // Produtos selecionados
  const [selectedProducts, setSelectedProducts] = useState<ProductListView[]>(
    [],
  );

  // Linhas expandidas da grid
  const [expandedRows, setExpandedRows] = useState<any>();

  // State do dialog ao importar em massa
  const [displayDialogBulk, setDisplayDialogBulk] = useState(false);

  const [bulkResponse, setBulkResponse] = useState<IBulkResponse>();

  const [hasFilterApplied, setHasFilterApplied] = useState(false);

  /**
   * Permissao do usuario para cada coluna da grid
   */
  const fieldsPermissions: ProductListViewPermissions = useMemo(() => {
    const fields = {} as ProductListViewPermissions;

    Object.entries(productViewRoles.fields).forEach(([key, value]) => {
      fields[key] = getFieldPermission(value, userFields);
    });

    return fields;
  }, [userFields]);

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = Object.values(gridColumnsData).reduce((acc, value) => {
      if (fieldsPermissions[value.field]?.view) {
        acc.push(value);
      }

      return acc;
    }, [] as ColumnData[]);

    columnList.push(gridColumnsData.imageUrl);

    const columnListAdvFilters: ColumnData[] = [
      gridColumnsData.status,
      gridColumnsData.outdated,
      gridColumnsData.taxmissing,
      gridColumnsData.verified,
      ...columnList,
    ];

    return { columnList, columnListAdvFilters };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

  const localStorageLazyParams = localStorageLazyParamsData
    ? JSON.parse(localStorageLazyParamsData)
    : undefined;

  const [globalFilter, setGlobalFilter] = useState(
    localStorageLazyParams?.globalFilter || '',
  );

  const initialLazyParams: IProductListViewLazyParams = {
    first: initialFirst || 0,
    rows: 25,
    page: initialPage || 0,
  };

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<IProductListViewLazyParams>(
    localStorageLazyParams || initialLazyParams,
  );

  const saveLazyParams = useCallback(
    (newLazyParams: IProductListViewLazyParams) => {
      localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
      updateLocalStorageInDb(lazyParamsName, newLazyParams);
    },
    [],
  );

  const changeLazyParams = useCallback(
    (newLazyParams: IProductListViewLazyParams) => {
      setLazyParams(newLazyParams);
      saveLazyParams(newLazyParams);
    },
    [saveLazyParams],
  );

  const [productListView, setProductListView] = useState<
    [ProductListView[], number]
  >([[], 0]);

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columns.columnList);

  const filteredColumnsHeader = useMemo(() => {
    return columns.columnListAdvFilters.filter(field =>
      Object.keys(lazyParams).find(
        key => lazyParams[key] && key === field.advancedFilterField,
      ),
    );
  }, [columns.columnListAdvFilters, lazyParams]);

  /**
   * Define scrollHeight da grid e sticky buttons do header de acordo com o
   * clique no botao para expandir ou colapsar o header
   */
  function expandCollapsePageHeader() {
    if (!fixedStickyButtons) {
      setFixedStickyButtons(true);
    } else {
      setFixedStickyButtons(false);
    }
  }

  // Mutation de exclusao de produto
  const deleteProductQuery = gql`
    mutation DeleteProductsMutation($productsIds: [Int]!) {
      deleteProducts(productsIds: $productsIds)
    }
  `;

  // Mutation de criacao de SAT
  const createSatQuery = gql`
    mutation CreateSat($idsProducts: [Int]!) {
      createSat(idsProducts: $idsProducts) {
        idSat
        satNumber
      }
    }
  `;

  // cria método para chamar a mutation
  const [deleteProductMutation] = useMutation(deleteProductQuery);
  const [createSatMutation] = useMutation(createSatQuery);
  const [createClientQuotationMutation] = useMutation(
    createClientQuotationQuery,
  );

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.columnList.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Direciona usuario para pagina do porto clicado
   * @param e Evento de clique na linha da tabela
   */
  function onRowClick(e: DataTableRowClickEvent) {
    history.push(
      `/products/list/${e.data.idProduct}?initialPage=${lazyParams.page}&initialFirst=${lazyParams.first}`,
    );
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  const onSort = (event: DataTableSortEvent) => {
    const newLazyParams = {
      ...lazyParams,
      multiSortMeta: event.multiSortMeta,
      sortField: event.sortField ?? initialLazyParams.sortField,
      sortOrder: event.sortOrder,
    };
    changeLazyParams(newLazyParams);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  const onPage = useCallback(
    (event: DataTablePageEvent) => {
      const newLazyParams = { ...lazyParams, ...event };
      changeLazyParams(newLazyParams);
    },
    [changeLazyParams, lazyParams],
  );

  // Query para buscar arquivo template
  const getAttachmentDownloadLinkQuery = gql`
    query getAttachmentDownloadLinkQuery($name: String!) {
      getAttachmentDownloadLink(name: $name)
    }
  `;
  // Carrega dados do porto
  const [getBulkTemplateLink, { loading: bulkTemplateLoading }] = useLazyQuery(
    getAttachmentDownloadLinkQuery,
    {
      variables: {
        name: 'products_bulk_template_v7.xlsx',
      },
      onCompleted: async response => {
        window.open(response.getAttachmentDownloadLink, '_blank');
      },
      onError: error => {
        showError({
          summary: 'Error while getting template',
          detail: error.message,
        });
      },
    },
  );

  const productListViewCommonVariables = {
    status: lazyParams.status,
    outdated: lazyParams.outdated,
    taxmissing: lazyParams.taxmissing,
    verified: lazyParams.verified,
    idProduct: lazyParams.idProduct,
    sunNumber: lazyParams.sunNumber,
    category: lazyParams.category,
    model: lazyParams.model,
    nameEn: lazyParams.nameEn,
    namePt: lazyParams.namePt,
    colourEn: lazyParams.colourEn,
    colourPt: lazyParams.colourPt,
    licensedProduct: lazyParams.licensedProduct,
    stock: lazyParams.stock,
    exclusivity: lazyParams.exclusivity,
    unit: lazyParams.unit,
    sku: lazyParams.sku,
    detailedDescriptionPt: lazyParams.detailedDescriptionPt,
    materialEn: lazyParams.materialEn,
    materialPt: lazyParams.materialPt,
    descriptionEn: lazyParams.descriptionEn,
    descriptionPt: lazyParams.descriptionPt,
    finishing: lazyParams.finishing,
    extraInfo: lazyParams.extraInfo,
    tradeReview: lazyParams.tradeReview,
    ncm: lazyParams.ncm,
    hsCode: lazyParams.hsCode,
    importLicense: lazyParams.importLicense,
    dumping: lazyParams.dumping,
    purchaseCurrency: lazyParams.purchaseCurrency,
    cotPrice: lazyParams.cotPrice,
    markup: lazyParams.markup,
    dtAging: lazyParams.dtAging,
    salesCurrency: lazyParams.salesCurrency,
    sunPrice: lazyParams.sunPrice,
    salesIncoterm: lazyParams.salesIncoterm,
    brands: lazyParams.brands,
    ncmClient: lazyParams.ncmClient,
    li: lazyParams.li,
    ncmName: lazyParams.ncmName,
    precoMinimo: lazyParams.precoMinimo,
    reference: lazyParams.reference,
    sellingPackageDescription: lazyParams.sellingPackageDescription,
    responsible: lazyParams.responsible,
    typeOfPackage: lazyParams.typeOfPackage,
    packageEan13: lazyParams.packageEan13,
    innerDun14: lazyParams.innerDun14,
    masterDun14: lazyParams.masterDun14,
  };

  /**
   * Busca Products
   */
  const { loading: productsLoading, refetch: productsRefetch } = useQuery(
    listProductListViewQuery(fieldsPermissions),
    {
      variables: {
        data: {
          pagination: {
            _page: lazyParams.page + 1,
            _limit: lazyParams.rows,
            _orderBy: lazyParams.sortField,
            _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
          },
          globalSearch: lazyParams.globalFilter,
          fieldsSearch: productListViewCommonVariables,
        },
      },
      onCompleted: data => {
        const { listProductListView } = data;
        if (listProductListView) {
          setProductListView([
            listProductListView.data,
            listProductListView.items,
          ]);
        } else {
          setProductListView([[], 0]);
          showError({
            summary: 'Error while getting Product List',
          });
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting products',
          detail: errorData.message,
        });
      },
    },
  );

  const [exportProductListView, { loading: exportLoading }] = useLazyQuery(
    exportProductListQuery,
    {
      onCompleted: response => {
        if (response.exportProductListView) {
          window.open(response.exportProductListView, '_blank');
        } else {
          showError({
            summary: 'Error while exporting list of Products',
          });
        }
      },

      onError: error => {
        showError({
          summary: 'Error while exporting list of Products',
          detail: error.message,
        });
      },
    },
  );

  const parseStatusColumn = (rowData: any) => {
    return (
      <div className="flex flex-column gap-1">
        {rowData.idStatus === ProductStatus.ACTIVED ? (
          <Tag value="Active" severity="success" />
        ) : (
          <Tag value="Inactive" severity="danger" />
        )}
        {!!rowData.indConfection && (
          <Tag value="Fashion Line" severity="warning" />
        )}
        {!!rowData.taxmissing && <Tag value="Tax Missing" />}
        {!!rowData.outdated && <Tag value="Outdated" severity="info" />}
        {!!rowData.verified && <Tag value="Verified" severity="success" />}
      </div>
    );
  };

  const parseImageColumn = (rowData: any) => {
    return (
      <img
        src={`${rowData.imageUrl}`}
        onError={e => (e.currentTarget.src = imagePlaceholder)}
        alt="Product"
        className="product-image"
        style={{ width: '90px' }}
      />
    );
  };

  /**
   * Retorna coluna com link para cadastro de Supplier
   * @param rowData Dados da Linha
   * @returns Coluna com link para cadastro de Supplier
   */
  const parseSunColumn = (rowData: any) => {
    return (
      rowData.idSupplier && (
        <Link
          to={`/suppliers/list/${rowData.idSupplier}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {rowData.sunNumber}
          <FiExternalLink size={15} />
        </Link>
      )
    );
  };

  const parseDateColumm = (rowData: any, field: any) => {
    let date;
    if (rowData[field.field]) {
      date = getUTC(rowData[field.field]).toLocaleString();
    }
    return <p>{date?.split(',')[0]}</p>;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'imageUrl':
        return parseImageColumn;
      case 'sunNumber':
        return parseSunColumn;
      case 'stCode':
        return parseStCodeColumn;
      case 'descriptionEn':
      case 'descriptionPt':
      case 'materialEn':
      case 'materialPt':
        return parseFormatedTextColumn;
      case 'dtAging':
        return parseDateColumm;
      default:
        return undefined;
    }
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'salesIncoterm':
      case 'ncmNames':
      case 'ncmClients':
      case 'model':
      case 'precoMinimo':
        return { width: '10.71rem' };
      case 'purchaseCurrency':
        return { width: '12.86rem' };
      case 'licensedProduct':
        return { width: '13.57rem' };
      case 'unit':
        return { width: '7.86rem' };
      case 'detailedDescriptionPt':
      case 'materialEn':
      case 'materialPt':
      case 'clients':
      case 'nameEn':
      case 'namePt':
      case 'innerDun14':
        return { width: '14.28rem' };
      case 'packageEan13':
      case 'masterDun14':
        return { width: '15.71rem' };
      case 'typeOfPackage':
        return { width: '17.86rem' };
      case 'descriptionEn':
      case 'descriptionPt':
      case 'brands':
      case 'sellingPackageDescription':
        return { width: '21.42rem' };
      case 'tradeReview':
        return { width: '11.43rem' };
      default:
        return { width: '10rem' };
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable={col.header !== 'Image'}
        />
      )
    );
  });

  /**
   * Deleta produtos selecionados
   */
  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedProducts.length} products?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada produto
        const idsToDelete = selectedProducts.map((a: any) => a.idProduct);
        try {
          // Deleta produtos
          await deleteProductMutation({
            variables: {
              productsIds: idsToDelete,
            },
          });

          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedProducts.length} products`,
          });

          // Zera estado de produtos selecionadas
          setSelectedProducts([]);

          // Atualiza grid de produtos
          await productsRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting products',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Cria SAT com os produtos selecionados
   */
  function handleCreateSatWithSelectedProducts() {
    confirmDialog({
      message: `You have selected (${selectedProducts.length}) records. Confirm create SAT?`,
      header: 'Create SAT confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de cada produto
        const idsToCreate = selectedProducts.map((a: any) => a.idProduct);
        try {
          // Deleta portos
          const response = await createSatMutation({
            variables: {
              idsProducts: idsToCreate,
            },
          });

          showSuccess({
            summary: 'SAT Created',
            detail: (
              <>
                <p>Products added in the new SAT: </p>
                <Link
                  to={`/commercial/sats/${response.data.createSat.idSat}`}
                  style={{ textDecoration: 'none', color: 'unset' }}
                >
                  {response.data.createSat.satNumber}
                </Link>
              </>
            ),
          });

          // Zera estado de portos selecionadas
          setSelectedProducts([]);

          // Atualiza grid de portos
          await productsRefetch();
        } catch (error) {
          showError({
            summary: 'Error while creating SAT',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Busca colunas selecionadas na ordem em que sao apresentadas ao usuario
   * @returns Colunas selecionadas na ordem em que sao apresentadas ao usuario
   */
  function getOrderedSelectedColumns() {
    const columnsOrder = gridRef.current?.columnOrder;

    if (columnsOrder && columnsOrder.length) {
      // Ordena itens de acordo com o array columnsOrder. Itens que nao existam
      // em columnsOrder sao colocados no final do array
      const sortedSelectedColumns = sortBy(selectedColumns, item => {
        const index = columnsOrder.indexOf(item.field);
        return index === -1 ? Infinity : index;
      });

      return sortedSelectedColumns.map(col => col.field);
    }

    // Se nao houver informacao de ordenacao das colunas, retorna colunas
    // selecionadas na ordem default
    return selectedColumns.map(col => col.field);
  }

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        changeLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
      const allowedColumns = filterOnlyAllowedColumnsFromLocalStorage(
        JSON.parse(localStorageSelectedColumns),
        columns.columnList,
      );
      setSelectedColumns(allowedColumns);
    } else {
      setSelectedColumns(columns.columnList);
    }
  }, [columns]);

  // A cada alteracao na bulkResponse, exibe o dialog com os dados
  useEffect(() => {
    if (bulkResponse) {
      setDisplayDialogBulk(true);
    }
  }, [bulkResponse]);

  // Itens do botao bulk
  const importBulkButtonItems = [
    {
      label: `Create ${screenSubject}`,
      icon: 'pi pi-plus',
      command: () => {
        inputImportBulkRef.current?.choose();
      },
    },
    {
      label: `Update ${screenSubject}`,
      icon: 'pi pi-refresh',
      command: () => {
        inputUpdateBulkRef.current?.choose();
      },
    },
    {
      label: 'Download template spreadsheet',
      icon: 'pi pi-download',
      command: () => {
        getBulkTemplateLink();
      },
    },
    {
      label: `Create ${screenSubject} Attachments`,
      icon: 'pi pi-upload',
      command: () => {
        createAttachmentsBulkRef.current?.toggleModal();
      },
    },
  ];

  const [createProductsBulkMutation] = useMutation(createProductsBulkQuery);
  const [updateProductsBulkMutation] = useMutation(updateProductsBulkQuery);

  async function handleImportBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      // Cria produtos em massa
      const response = await createProductsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.createProductsBulk,
        type: 'CREATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while creating products',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function handleUpdateBulkUpload(e: FileUploadResponse[]) {
    try {
      setPageLoading(true);

      const response = await updateProductsBulkMutation({
        variables: {
          uploadedFile: e[0].serverName,
        },
      });

      setBulkResponse({
        ...response.data.updateProductsBulk,
        type: 'UPDATE',
      });
    } catch (error) {
      showError({
        summary: 'Error while updating products',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  function rowExpansionTemplate(data: any) {
    return (
      <ReferenceSizes
        idProduct={data.idProduct}
        userFields={userFields}
        isExpanded={expandedRows[data.idProduct] === true}
      />
    );
  }

  /**
   * Gerencia se expander deve ser exibido
   */
  function handleExpander(data: any, rowData: any) {
    // Se produto nao for fashion, nao exibe expander
    if (!data.indConfection) {
      return undefined;
    }

    // Retorna body do expander
    return rowData.expander.element;
  }

  function handleCreateClientQuotation() {
    confirmDialog({
      message: `You have selected (${selectedProducts.length}) records. Confirm create CQ?`,
      header: 'Create CQ confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        setPageLoading(true);
        const idsToCreate = selectedProducts.map(a => a.idProduct);
        try {
          const response = await createClientQuotation(
            createClientQuotationMutation,
            undefined,
            undefined,
            idsToCreate,
          );

          showSuccess({
            summary: 'CQ Created',
            detail: (
              <>
                <p>Products added in the new CQ: </p>
                <Link
                  to={`/client-quotations/list/${response.data.createClientQuotation.idCq}`}
                  className="transparent-link"
                >
                  {response.data.createClientQuotation.cqNumber}
                </Link>
              </>
            ),
          });

          setSelectedProducts([]);
        } catch (error) {
          showError({
            summary: 'Error while creating CQ',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns.columnListAdvFilters).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <Menu ref={massiveActionMenuRef} popup model={importBulkButtonItems} />
      <FileUpload
        ref={inputImportBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleImportBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />
      <FileUpload
        ref={inputUpdateBulkRef}
        mode="basic"
        auto
        accept={`${FileType.XLSX}`}
        onConfirm={e => handleUpdateBulkUpload(e)}
        style={{ display: 'none' }}
        showFullscreenLoading
      />

      <CreateProductsAttachmentsBulk ref={createAttachmentsBulkRef} />

      <Dialog
        header={
          bulkResponse?.type === 'CREATE'
            ? `Create ${screenSubject} response`
            : `Update ${screenSubject} response`
        }
        visible={displayDialogBulk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setDisplayDialogBulk(false)}
      >
        <p>
          <b>Products found in the spreadsheet:</b>{' '}
          {bulkResponse?.productsTotal}
        </p>
        <p>
          <b>
            Products {bulkResponse?.type === 'CREATE' ? 'created:' : 'updated:'}
          </b>{' '}
          {bulkResponse?.createdProducts || bulkResponse?.updatedProducts}
        </p>
        {!!bulkResponse?.productsWithError.length && (
          <p>
            <b>Products/Cells with error:</b>
            <br />
            {bulkResponse?.productsWithError.map(error => (
              <p>{error}</p>
            ))}
          </p>
        )}
      </Dialog>

      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        {/* Botao para criar product */}
        {userHasPermission(
          productRoles.permissions.idPermissionCreateProduct,
          userPermissions,
        ) && (
          <MainButton
            className="mainButton"
            label="New Product"
            onClick={() => history.push('/products/list/create')}
          />
        )}

        {permissions.createSat && (
          <Button
            className="secondaryButton"
            label="Create SAT"
            onClick={() => {
              handleCreateSatWithSelectedProducts();
            }}
            disabled={!selectedProducts.length}
          />
        )}

        {permissions.createClientQuotation && (
          <Button
            className="secondaryButton"
            label="Create CQ"
            onClick={() => handleCreateClientQuotation()}
            disabled={!selectedProducts.length}
          />
        )}

        {/* Advanced Filters */}
        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />

        {/* Painel com todos os filtros */}
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns.columnListAdvFilters}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            changeLazyParams({
              ...lazyParams,
              ...e,
              first: initialLazyParams.first,
              page: initialLazyParams.page,
              rows: initialLazyParams.rows,
            })
          }
          onClear={() =>
            changeLazyParams({
              ...initialLazyParams,
              globalFilter,
            })
          }
        />

        {/* Botao para excluir produtos selecionados */}
        {permissions.delete && (
          <Button
            label="Delete selected"
            className="p-button-danger"
            severity="danger"
            disabled={!selectedProducts.length}
            onClick={handleDeleteSelected}
          />
        )}

        {userHasPermission(
          productRoles.permissions.idPermissionMassiveAction,
          userPermissions,
        ) && (
          <Button
            label="Massive Action"
            className="massive-action-button"
            onClick={event => massiveActionMenuRef.current?.toggle(event)}
            loading={bulkTemplateLoading}
          />
        )}

        {permissions.export && (
          <Button
            type="button"
            className="export-xlsx"
            label="Export Grid"
            loading={exportLoading}
            onClick={() => {
              exportProductListView({
                variables: {
                  data: {
                    globalSearch: lazyParams.globalFilter,
                    pagination: {
                      _orderBy: lazyParams.sortField,
                      _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                    },
                    columnsToExport: getOrderedSelectedColumns(),
                    fieldsSearch: productListViewCommonVariables,
                  },
                },
              });
            }}
          />
        )}

        {/* Multi select de colunas da grid */}
        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.columnList}
          onChange={onColumnToggle}
        />

        {/* Busca global */}
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for a product"
        />

        {/* Botao para expandir ou colapsar o haeader */}
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>
      </PageHeader>
      <Grid
        ref={gridRef}
        name="products"
        lazy
        totalRecords={productListView[1]}
        value={productListView[0]}
        globalFilter={globalFilter}
        emptyMessage="No products found."
        onRowClick={onRowClick}
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        selection={selectedProducts}
        onSelectionChange={e => setSelectedProducts(e.value)}
        expandedRows={expandedRows}
        onRowToggle={e => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="idProduct"
      >
        <Column
          style={{ width: '4rem' }}
          columnKey="expanderColumn"
          reorderable={false}
          body={handleExpander}
          expander
        />
        {(permissions.delete || permissions.createSat) && (
          <Column
            selectionMode="multiple"
            columnKey="multiple"
            style={{ width: '3rem' }}
            reorderable={false}
          />
        )}
        <Column
          header="Status"
          columnKey="status"
          body={parseStatusColumn}
          style={{ width: '8rem' }}
        />
        {dynamicColumns}
      </Grid>
      {(pageLoading || productsLoading) && <Loading />}
    </div>
  );
};
export default Products;
