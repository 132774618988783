/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-shadow */
import { Column } from 'primereact/column';
import { gridConstants } from '../../components/Grid/constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const expandOrCollapseHeader = (
  fixedStickyButtons: boolean,
): Array<any> => {
  if (!fixedStickyButtons) {
    return [gridConstants.collapsedHeaderScrollHeight, true];
  }
  return [gridConstants.expandedHeaderScrollHeight, false];
};

// Colunas da grid
const columns = [{ field: 'description', header: 'Name' }];

// Reproduz as colunas selecionadas na configuracao
export const dynamicColumns = columns.map(col => {
  return (
    <Column
      key={col.field}
      columnKey={col.field}
      field={col.field}
      header={col.header}
      style={{ width: '100px', flex: 'auto' }}
      sortable
    />
  );
});

// Parametros de paginacao/backend
export const lazyParamsPaginationGrid = {
  first: 0,
  rows: 25,
  page: 0,
};
