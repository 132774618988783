import styled from 'styled-components';
import { lighten, shade } from 'polished';

interface IContainerProps {
  scrollHeight?: string;
}

/**
 * Renderiza componente preparado para o scroll da Grid
 * @param scrollHeight altura do scroll
 * @returns CSS
 */
function renderScrollHeight(scrollHeight?: string) {
  if (scrollHeight !== 'flex') return '';

  /**
   * Se o scroll for flex, a Grid deve adaptar o tamanho do scroll
   * https://primereact.org/datatable/#flex_scroll
   */
  return `
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `;
}

export const Container = styled.div<IContainerProps>`
  ${({ scrollHeight }) => renderScrollHeight(scrollHeight)};

  .p-datatable {
    .p-inputtext {
      width: 100%;
    }

    // Permite definir largura fixa para as colunas da Grid
    /* thead th,
    tbody td,
    tfoot td {
      flex: none;
    } */

    .field-radiobutton {
      label {
        display: flex;
        align-items: center;

        .p-radiobutton {
          margin-left: 2px;
        }
      }
    }

    textarea {
      resize: vertical;
    }

    .s-grid-break-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }
  }

  .s-grid-column-multiple-links {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  a {
    display: flex;

    text-decoration: none;
    transition: color 0.2s;
    color: var(--blue);
    align-items: center;

    &:hover {
      color: ${lighten(0.1, '#4C85C8')};
    }

    svg {
      margin-left: 12px;
      min-width: 15px;
      min-height: 15px;
    }
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      background: none;
      color: var(--grey-scale);
      transition: color 0.2s;
      &:hover {
        color: var(--blue);
      }
    }
  }

  .p-paginator {
    justify-content: flex-start;
    border: none;
    .p-paginator-current {
      color: var(--grey-scale);
      right: 0;
      margin-right: 30px;
      position: absolute;
    }
  }

  th {
    background: none !important;

    .custom-header {
      i {
        margin-left: 0.5rem;
      }
    }
  }

  td {
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .imageWithTextDiv {
    align-items: center;
    img {
      margin-right: 22px;
    }
  }
`;

export const GridActions = styled.div`
  display: flex;
  gap: 0.57rem;
  margin-left: 0.57rem;

  button {
    background: none;
    border: none;
    color: #7f7f80;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.4, '#7f7f80')};
    }
  }

  .trash-button {
    color: var(--red);

    &:hover {
      color: ${shade(0.2, '#FD2D2D')};
    }
  }
`;
