import FormCheckbox from '../../../../../components/FormCheckbox';
import FormInput from '../../../../../components/FormInput';
import { CheckboxItemsFunction, ICheckboxMenu } from '../interfaces';

const CheckboxItems: React.FC<CheckboxItemsFunction> = ({
  objectArrayName,
  checkboxMenu,
}) => {
  return (
    <div className="grid">
      {checkboxMenu.map((element: ICheckboxMenu, index: number) => {
        return (
          <div
            key={`${element.name}-${objectArrayName}`}
            className="col-2"
            style={{ alignItems: 'center' }}
          >
            <div className="flex flex-column">
              <FormInput
                name={`${objectArrayName}[${index}].name`}
                label="Value"
                type="hidden"
                defaultValue={element.name}
              />
              <FormCheckbox
                inputId={index.toString()}
                name={`${objectArrayName}[${index}].isChecked`}
                label={element.name}
              />

              {element.additionalValue !== undefined && (
                <FormInput
                  name={`${objectArrayName}[${index}].additionalValue`}
                  defaultValue={element.additionalValue ?? ''}
                  label="Value"
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxItems;
