import { gql, useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cloneDeep } from 'lodash';
import { FiExternalLink } from 'react-icons/fi';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTablePageEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import AdvancedFiltersPanel from '../../../../components/Grid/AdvancedFiltersPanel';
import MultiSelect from '../../../../components/Grid/MultiSelect';
import Input from '../../../../components/Input';
import MainButton from '../../../../components/MainButton';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { useRefHook } from '../../../../hooks/useRefHook';
import ILazyParams from '../../../../services/lazyParams';
import { IListSatSupplier, ISat } from '../interfaces';
import Supplier from './Supplier';

import { Container, GridHeader } from './styles';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { satSuppliersRoles } from '../../../../shared/roles/satSuppliers';
import getFieldPermission from '../../../../utils/getFieldPermission';
import updateLocalStorageInDb from '../../../../utils/updateLocalStorageInDb';
import ToastLife from '../../../../shared/enums/toastLife';
import userHasPermission from '../../../../utils/userHasPermission';
import getUserFieldsAndPermissionsByEntity from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import { useAuth } from '../../../../hooks/useAuth';
import { searchDelayMiliseconds } from '../../../../config/pagination';
import { gridConstants } from '../../../../components/Grid/constants';

interface ISuppliersProps extends PageTabContainerProps {
  sat: ISat;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
  setSat: Dispatch<React.SetStateAction<ISat>>;
  setShouldRefetchSatSuppliers: Dispatch<React.SetStateAction<boolean>>;
  shouldRefetchSatSuppliers: boolean;
  roleEntityFields: IRoleEntityField[];
  satRefetch(): Promise<void>;
}

/**
 * Interface do input de filtros
 */
interface IFilterInput {
  field?: string;
  header?: string;
  value?: any;
  type?: string;
}

/**
 * Interface dos parametros do backend
 */
interface ISatSuppliersLazyParams extends ILazyParams {
  sunNumber?: number;
  name?: string;
  address?: string;
  loadingPort?: string;
  estimatedInspectionDate?: Date;
  estimatedShipmentDate?: Date;
  estimatedArrivalDate?: Date;
  incoterm?: string;
  productionTime?: number;
  artWork?: string;
  paymentTermAdvance?: number;
  paymentTermBalance?: number;
  paymentTermCondition?: string;
  estimatedAdvancePaymentDate?: Date;
  advancePaymentPercentage?: number;
  estAdvancePaymentAmount?: number;
  estimatedBalancePaymentDate?: Date;
  balancePaymentPercentage?: number;
  estBalancePaymentAmount?: number;
  purchasePI?: number;
  purchaseCI?: number;
  grossMargin?: number;
  netMargin?: number;
  supplierLoadingAmount?: number;
}

const Suppliers: React.FC<ISuppliersProps> = ({
  selected,
  sat,
  setShouldRefetchSatSuppliers,
  shouldRefetchSatSuppliers,
  roleEntityFields,
}) => {
  // Nome da key de grid columns
  const gridColumnsName = '@SAT:satSuppliersGridColumns';

  // Busca global na grid
  const [globalFilter, setGlobalFilter] = useState('');

  // Referencia ao toast
  const { toastRef } = useRefHook();

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);
  const advancedFiltersFormRef = useRef<FormHandles>(null);
  const gridRef = useRef<DataTable<any>>(null);
  // Referencia ao componente (se esta montado)
  const isMounted = useRef(false);

  const [satSuppliers, setSatSuppliers] = useState<IListSatSupplier[]>([]);

  const [supplierToDetail, setSupplierToDetail] = useState<IListSatSupplier>();

  // ID da entity de SAT
  const { idEntity } = satSuppliersRoles;

  // Roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissions = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    idEntity,
  );

  const {
    idFieldAdvancePaymentPercentage,
    idFieldArtWork,
    idFieldBalancePaymentPercentage,
    idFieldEstAdvancePaymentAmount,
    idFieldEstBalancePaymentAmount,
    idFieldEstimatedAdvancePaymentDate,
    idFieldEstimatedBalancePaymentDate,
    idFieldIdLoadingPort,
    idFieldIncoterm,
    idFieldPaymentTermAdvance,
    idFieldPaymentTermBalance,
    idFieldPaymentTermCondition,
    idFieldProductionTime,
    idFieldPurchaseCI,
    idFieldPurchasePI,
    idFieldSupplierLoadingAmountCot,
    idFieldName,
    idFieldAddress,
    idFieldGrossMargin,
    idFieldNetMargin,
    idFieldRemainTotalCot,
    idFieldBeneficiaryName,
    idFieldMainBankName,
    idFieldMainBankAddress,
    idFieldIdBankCountry,
    idFieldIdBankRegion,
    idFieldBankCity,
    idFieldSwift,
    idFieldAccountNumber,
    idFieldIban,
    idFieldIntermediaryBeneficiaryName,
    idFieldIntermediaryBankAddress,
    idFieldIdBankSecondCountry,
    idFieldIdBankSecondRegion,
    idFieldIntermediaryBankCity,
    idFieldIntermediarySwift,
    idFieldIntermediaryAccountNumber,
    idFieldIntermediaryIban,
    idFieldEstimatedArrivalDate,
    idFieldEstimatedInspectionDate,
    idFieldEstimatedShipmentDate,
  } = satSuppliersRoles.fields;

  const { idPermissionLinkSupplierDetails } = satSuppliersRoles.permissions;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  // Colunas da grid
  const columns = useMemo(() => {
    const columnList = [];

    columnList.push({ field: 'idSupplier2.sunNumber', header: 'SUN' });

    if (showField(idFieldName)) {
      columnList.push({ field: 'name', header: 'Supplier Name' });
    }
    if (showField(idFieldAddress)) {
      columnList.push({ field: 'address', header: 'Supplier Address' });
    }
    if (showField(idFieldIdLoadingPort)) {
      columnList.push({
        field: 'idLoadingPort2.name',
        header: 'Port/Airport Of Loading',
      });
    }
    if (showField(idFieldEstimatedInspectionDate)) {
      columnList.push({
        field: 'idSat2.estimatedInspectionDate',
        header: 'Previous Inspection Date',
      });
    }
    if (showField(idFieldEstimatedShipmentDate)) {
      columnList.push({
        field: 'idSat2.estimatedShipmentDate',
        header: 'Previous Shipment Date',
      });
    }
    if (showField(idFieldEstimatedArrivalDate)) {
      columnList.push({
        field: 'idSat2.estimatedArrivalDate',
        header: 'Previous Arrival Date',
      });
    }
    if (showField(idFieldIncoterm)) {
      columnList.push({ field: 'incoterm', header: 'Purchase Incoterm' });
    }
    if (showField(idFieldProductionTime)) {
      columnList.push({ field: 'productionTime', header: 'Production Time' });
    }
    if (showField(idFieldArtWork)) {
      columnList.push({ field: 'artWork', header: 'Art Work Format' });
    }
    if (showField(idFieldPaymentTermAdvance)) {
      columnList.push({
        field: 'paymentTermAdvance',
        header: 'Payment Term Advance (%)',
      });
    }
    if (showField(idFieldPaymentTermBalance)) {
      columnList.push({
        field: 'paymentTermBalance',
        header: 'Payment Term Balance (%)',
      });
    }
    if (showField(idFieldPaymentTermCondition)) {
      columnList.push({
        field: 'paymentTermCondition',
        header: 'Payment Term Condition',
      });
    }
    if (showField(idFieldEstimatedAdvancePaymentDate)) {
      columnList.push({
        field: 'estimatedAdvancePaymentDate',
        header: 'Advance Payment Request Date',
      });
    }
    if (showField(idFieldAdvancePaymentPercentage)) {
      columnList.push({
        field: 'advancePaymentPercentage',
        header: 'Advance %',
      });
    }
    if (showField(idFieldEstAdvancePaymentAmount)) {
      columnList.push({
        field: 'estAdvancePaymentAmount',
        header: 'Advance Amount',
      });
    }
    if (showField(idFieldEstimatedBalancePaymentDate)) {
      columnList.push({
        field: 'estimatedBalancePaymentDate',
        header: 'Balance Payment Request Date',
      });
    }
    if (showField(idFieldBalancePaymentPercentage)) {
      columnList.push({
        field: 'balancePaymentPercentage',
        header: 'Balance %',
      });
    }
    if (showField(idFieldEstBalancePaymentAmount)) {
      columnList.push({
        field: 'estBalancePaymentAmount',
        header: 'Balance Amount',
      });
    }
    if (showField(idFieldPurchasePI)) {
      columnList.push({
        field: 'purchasePI',
        header: 'Total COT PI',
      });
    }
    if (showField(idFieldPurchaseCI)) {
      columnList.push({
        field: 'purchaseCI',
        header: 'Total COT CI',
      });
    }
    if (showField(idFieldGrossMargin)) {
      columnList.push({
        field: 'grossMargin',
        header: 'Gross Margin',
      });
    }
    if (showField(idFieldNetMargin)) {
      columnList.push({
        field: 'netMargin',
        header: 'Net Margin',
      });
    }
    if (showField(idFieldSupplierLoadingAmountCot)) {
      columnList.push({
        field: 'supplierLoadingAmountCot',
        header: 'Loading Amount COT',
      });
    }
    if (showField(idFieldRemainTotalCot)) {
      columnList.push({
        field: 'remainTotalCot',
        header: 'Remain Amount COT',
      });
    }
    return columnList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Inputs para advanced filters
  const advancedFiltersInputsConst = [
    { field: 'sunNumber', header: 'SUN', value: '' },
    { field: 'name', header: 'Supplier Name', value: '' },
    { field: 'address', header: 'Supplier Address', value: '' },
    {
      field: 'idLoadingPort2.name',
      header: 'Port/Airport Of Loading',
      value: '',
    },
    {
      field: 'estimatedInspectionDate',
      header: 'Previous Inspection Date',
      value: '',
      type: 'date',
    },
    {
      field: 'estimatedShipmentDate',
      header: 'Previous Shipment Date',
      value: '',
      type: 'date',
    },
    {
      field: 'estimatedArrivalDate',
      header: 'Previous Arrival Date',
      value: '',
      type: 'date',
    },
    { field: 'incoterm', header: 'Purchase Incoterm', value: '' },
    {
      field: 'productionTime',
      header: 'Production Time',
      value: '',
      type: 'number',
    },
    { field: 'artWork', header: 'Art Work Format', value: '' },
    {
      field: 'paymentTermAdvance',
      header: 'Payment Term Advance (%)',
      value: '',
    },
    {
      field: 'paymentTermBalance',
      header: 'Payment Term Balance (%)',
      value: '',
    },
    {
      field: 'paymentTermCondition',
      header: 'Payment Term Condition',
      value: '',
    },
    {
      field: 'estimatedAdvancePaymentDate',
      header: 'Advance Payment Request Date',
      value: '',
      type: 'date',
    },
    {
      field: 'advancePaymentPercentage',
      header: 'Advance %',
      value: '',
    },
    {
      field: 'estAdvancePaymentAmount',
      header: 'Advance Amount',
      value: '',
    },
    {
      field: 'estimatedBalancePaymentDate',
      header: 'Balance Payment Request Date',
      value: '',
      type: 'date',
    },
    {
      field: 'balancePaymentPercentage',
      header: 'Balance %',
      value: '',
    },
    {
      field: 'estBalancePaymentAmount',
      header: 'Balance Amount',
      value: '',
    },
    { field: 'purchasePI', header: 'Total COT PI', value: '' },
    { field: 'purchaseCI', header: 'Total COT CI', value: '' },
    { field: 'grossMargin', header: 'Gross Margin', value: '' },
    { field: 'netMargin', header: 'Net Margin', value: '' },
    {
      field: 'supplierLoadingAmountCot',
      header: 'Loading Amount COT',
      value: '',
    },
  ];

  // Colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState([
    { field: 'idSupplier2.sunNumber', header: 'SUN' },
    { field: 'idLoadingPort2.name', header: 'Port/Airport Of Loading' },
    { field: 'incoterm', header: 'Incoterm' },
    { field: 'productionTime', header: 'Production Time' },
  ]);

  // Parametros de paginacao/backend
  const [lazyParams, setLazyParams] = useState<ISatSuppliersLazyParams>({
    first: 0,
    rows: 25,
    page: 0,
  });

  // Query para listar sat suppliers
  const listSatSuppliersBySatIdQuery = gql`
    query listSatSuppliersBySatId($data: ListSatSuppliersBySatIdInput!) {
      listSatSuppliersBySatId(data: $data) {
        items
        data {
          idSatSupplier
          idSupplier
          idSupplier2 {
            sunNumber
          }
          ${showField(idFieldName) ? 'name' : ''}
          ${showField(idFieldAddress) ? 'address' : ''}
          ${showField(idFieldIncoterm) ? 'incoterm' : ''}
          ${
            showField(idFieldEstimatedInspectionDate) ||
            showField(idFieldEstimatedShipmentDate) ||
            showField(idFieldEstimatedArrivalDate)
              ? `
                idSat2 {
                  ${
                    showField(idFieldEstimatedInspectionDate)
                      ? 'estimatedInspectionDate'
                      : ''
                  }
                  ${
                    showField(idFieldEstimatedShipmentDate)
                      ? 'estimatedShipmentDate'
                      : ''
                  }
                  ${
                    showField(idFieldEstimatedArrivalDate)
                      ? 'estimatedArrivalDate'
                      : ''
                  }
                }
                `
              : ''
          }
          ${showField(idFieldProductionTime) ? 'productionTime' : ''}
          ${showField(idFieldIdLoadingPort) ? 'idLoadingPort' : ''}
          ${
            showField(idFieldIdLoadingPort)
              ? `idLoadingPort2 {
                  name
                }`
              : ''
          }
          ${showField(idFieldArtWork) ? 'artWork' : ''}
          ${showField(idFieldPaymentTermAdvance) ? 'paymentTermAdvance' : ''}
          ${showField(idFieldPaymentTermBalance) ? 'paymentTermBalance' : ''}
          ${
            showField(idFieldPaymentTermCondition) ? 'paymentTermCondition' : ''
          }
          ${
            showField(idFieldEstimatedAdvancePaymentDate)
              ? 'estimatedAdvancePaymentDate'
              : ''
          }
          ${
            showField(idFieldAdvancePaymentPercentage)
              ? 'advancePaymentPercentage'
              : ''
          }
          ${
            showField(idFieldEstAdvancePaymentAmount)
              ? 'estAdvancePaymentAmount'
              : ''
          }
          ${
            showField(idFieldEstimatedBalancePaymentDate)
              ? 'estimatedBalancePaymentDate'
              : ''
          }
          ${
            showField(idFieldBalancePaymentPercentage)
              ? 'balancePaymentPercentage'
              : ''
          }
          ${
            showField(idFieldEstBalancePaymentAmount)
              ? 'estBalancePaymentAmount'
              : ''
          }
          ${showField(idFieldPurchasePI) ? 'purchasePI' : ''}
          ${showField(idFieldPurchaseCI) ? 'purchaseCI' : ''}
          ${showField(idFieldGrossMargin) ? 'grossMargin' : ''}
          ${showField(idFieldNetMargin) ? 'netMargin' : ''}
          ${
            showField(idFieldSupplierLoadingAmountCot)
              ? 'supplierLoadingAmountCot'
              : ''
          }
          ${showField(idFieldRemainTotalCot) ? 'remainTotalCot' : ''}
          ${showField(idFieldBeneficiaryName) ? 'beneficiaryName' : ''}
          ${showField(idFieldMainBankName) ? 'mainBankName' : ''}
          ${showField(idFieldMainBankAddress) ? 'mainBankAddress' : ''}
          ${showField(idFieldBankCity) ? 'bankCity' : ''}
          ${showField(idFieldSwift) ? 'swift' : ''}
          ${showField(idFieldIban) ? 'iban' : ''}
          ${showField(idFieldAccountNumber) ? 'accountNumber' : ''}
          ${
            showField(idFieldIntermediaryBeneficiaryName)
              ? 'intermediaryBeneficiaryName'
              : ''
          }
          ${
            showField(idFieldIntermediaryBankAddress)
              ? 'intermediaryBankAddress'
              : ''
          }
          ${
            showField(idFieldIntermediaryBankCity) ? 'intermediaryBankCity' : ''
          }
          ${showField(idFieldIntermediarySwift) ? 'intermediarySwift' : ''}
          ${showField(idFieldIntermediaryIban) ? 'intermediaryIban' : ''}
          ${
            showField(idFieldIntermediaryAccountNumber)
              ? 'intermediaryAccountNumber'
              : ''
          }
          ${
            showField(idFieldIdBankCountry)
              ? `idBankCountry2 {
                  name
                }`
              : ''
          }
          ${
            showField(idFieldIdBankRegion)
              ? `idBankRegion2 {
                  name
                }`
              : ''
          }
          ${
            showField(idFieldIdBankSecondCountry)
              ? `idBankSecondCountry2 {
                  name
                }`
              : ''
          }
          ${
            showField(idFieldIdBankSecondRegion)
              ? `idBankSecondRegion2 {
                  name
                }`
              : ''
          }
        }
      }
    }
  `;

  /**
   * Busca SAT Suppliers
   */
  const {
    loading: satSuppliersLoading,
    data: satSuppliersData,
    refetch: satSuppliersRefetch,
  } = useQuery(listSatSuppliersBySatIdQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        globalSearch: lazyParams.globalFilter,
        idSat: sat.idSat,
        sunNumber: lazyParams.sunNumber,
        name: lazyParams.name,
        address: lazyParams.address,
        loadingPort: lazyParams.loadingPort,
        estimatedInspectionDate: lazyParams.estimatedInspectionDate,
        estimatedShipmentDate: lazyParams.estimatedShipmentDate,
        estimatedArrivalDate: lazyParams.estimatedArrivalDate,
        incoterm: lazyParams.incoterm,
        productionTime: lazyParams.productionTime,
        artWork: lazyParams.artWork,
        paymentTermAdvance: lazyParams.paymentTermAdvance,
        paymentTermBalance: lazyParams.paymentTermBalance,
        paymentTermCondition: lazyParams.paymentTermCondition,
        estimatedAdvancePaymentDate: lazyParams.estimatedAdvancePaymentDate,
        advancePaymentPercentage: lazyParams.advancePaymentPercentage,
        estAdvancePaymentAmount: lazyParams.estAdvancePaymentAmount,
        estimatedBalancePaymentDate: lazyParams.estimatedBalancePaymentDate,
        balancePaymentPercentage: lazyParams.balancePaymentPercentage,
        estBalancePaymentAmount: lazyParams.estBalancePaymentAmount,
        purchasePI: lazyParams.purchasePI,
        purchaseCI: lazyParams.purchaseCI,
        grossMargin: lazyParams.grossMargin,
        netMargin: lazyParams.netMargin,
        supplierLoadingAmount: lazyParams.supplierLoadingAmount,
      },
    },
    onCompleted: response => {
      setSatSuppliers(response.listSatSuppliersBySatId.data);
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting SAT Suppliers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  // Estado de inputs do advanced filters antes de cancelar
  const [
    beforeCancelAdvancedFiltersInputs,
    setBeforeCancelAdvancedFiltersInputs,
  ] = useState<IFilterInput[]>(advancedFiltersInputsConst);

  // Estado de inputs do advanced filters
  const [advancedFiltersInputs, setAdvancedFiltersInputs] = useState<
    IFilterInput[]
  >(advancedFiltersInputsConst);

  // Inputs filtrados no advanced filters antes de cancelar
  const [
    beforeCancelAdvancedFiltersFilteredInputs,
    setBeforeCancelAdvancedFiltersFilteredInputs,
  ] = useState<IFilterInput[]>(advancedFiltersInputs);

  // Inputs filtrados no advanced filters
  const [advancedFiltersFilteredInputs, setAdvancedFiltersFilteredInputs] =
    useState<IFilterInput[]>(advancedFiltersInputs);

  // Input de busca nos advanced filters antes de cancelar
  const [
    beforeCancelAdvancedFiltersInputSearch,
    setBeforeCancelAdvancedFiltersInputSearch,
  ] = useState('');

  // Colunas da grid que possuem filtro aplicado
  const [filteredColumnsHeader, setFilteredColumnsHeader] = useState<
    IFilterInput[]
  >([]);

  // Input de busca nos advanced filters
  const [advancedFiltersInputSearch, setAdvancedFiltersInputSearch] =
    useState('');

  /**
   * Abrir painel de advanced filters
   * @param event Evento de overlay panel
   */
  function handleAdvancedFiltersToggle(event: React.MouseEvent) {
    // Copia valores para estados de antes de cancelamento
    setBeforeCancelAdvancedFiltersInputs(cloneDeep(advancedFiltersInputs));
    setBeforeCancelAdvancedFiltersFilteredInputs(
      cloneDeep(advancedFiltersFilteredInputs),
    );
    setBeforeCancelAdvancedFiltersInputSearch(
      cloneDeep(advancedFiltersInputSearch),
    );
    // Abre o painel
    advancedFiltersPanelRef.current?.toggle(event, event?.target);
  }

  /**
   * Filtra os inputs de advanced filters
   * @param event evento de mudança no input
   */
  function handleFilterAdvancedFilters(event: ChangeEvent<HTMLInputElement>) {
    // Valor do input de busca
    const searchInputValue = event.currentTarget.value;

    // Define o estado do input de busca com o valor digitado
    setAdvancedFiltersInputSearch(searchInputValue);

    // Clona valores atuais dos inputs
    const advancedFiltersInputsClone = [...advancedFiltersInputs];

    // Filtra os inputs de acordo com a pesquisa
    const filteredInputs = advancedFiltersInputsClone.filter(input =>
      input.header?.toLowerCase().includes(searchInputValue.toLowerCase()),
    );

    // Define inputs filtrados
    setAdvancedFiltersFilteredInputs(filteredInputs);
  }

  /**
   * Aplica advanced filters
   */
  function handleApplyAdvancedFilters() {
    // Busca apenas inputs de filtro com algum valor
    const filtersWithValues = advancedFiltersInputs.filter(
      filter => filter.value !== '' && filter.value !== undefined,
    );

    // Define headers de colunas que serao filtradas
    setFilteredColumnsHeader(filtersWithValues);

    // Busca dados que serao filtrados
    const data = {
      sunNumber: filtersWithValues.find(input => input.field === 'sunNumber')
        ?.value,
      name: filtersWithValues.find(input => input.field === 'name')?.value,
      address: filtersWithValues.find(input => input.field === 'address')
        ?.value,
      loadingPort: filtersWithValues.find(
        input => input.field === 'idLoadingPort2.name',
      )?.value,
      estimatedInspectionDate: filtersWithValues.find(
        input => input.field === 'estimatedInspectionDate',
      )?.value,
      estimatedShipmentDate: filtersWithValues.find(
        input => input.field === 'estimatedShipmentDate',
      )?.value,
      estimatedArrivalDate: filtersWithValues.find(
        input => input.field === 'estimatedArrivalDate',
      )?.value,
      incoterm: filtersWithValues.find(input => input.field === 'incoterm')
        ?.value,
      productionTime: filtersWithValues.find(
        input => input.field === 'productionTime',
      )?.value,
      artWork: filtersWithValues.find(input => input.field === 'artWork')
        ?.value,
      paymentTermAdvance: filtersWithValues.find(
        input => input.field === 'paymentTermAdvance',
      )?.value,
      paymentTermBalance: filtersWithValues.find(
        input => input.field === 'paymentTermBalance',
      )?.value,
      paymentTermCondition: filtersWithValues.find(
        input => input.field === 'paymentTermCondition',
      )?.value,
      estimatedAdvancePaymentDate: filtersWithValues.find(
        input => input.field === 'estimatedAdvancePaymentDate',
      )?.value,
      advancePaymentPercentage: filtersWithValues.find(
        input => input.field === 'advancePaymentPercentage',
      )?.value,
      estAdvancePaymentAmount: filtersWithValues.find(
        input => input.field === 'estAdvancePaymentAmount',
      )?.value,
      estimatedBalancePaymentDate: filtersWithValues.find(
        input => input.field === 'estimatedBalancePaymentDate',
      )?.value,
      balancePaymentPercentage: filtersWithValues.find(
        input => input.field === 'balancePaymentPercentage',
      )?.value,
      estBalancePaymentAmount: filtersWithValues.find(
        input => input.field === 'estBalancePaymentAmount',
      )?.value,
      purchasePI: filtersWithValues.find(input => input.field === 'purchasePI')
        ?.value,
      purchaseCI: filtersWithValues.find(input => input.field === 'purchaseCI')
        ?.value,
      grossMargin: filtersWithValues.find(
        input => input.field === 'grossMargin',
      )?.value,
      netMargin: filtersWithValues.find(input => input.field === 'netMargin')
        ?.value,
      supplierLoadingAmount: filtersWithValues.find(
        input => input.field === 'supplierLoadingAmount',
      )?.value,
    };

    // Define dados nos lazyParams
    const newLazyParams = { ...lazyParams };
    newLazyParams.first = 0;
    newLazyParams.page = 0;
    newLazyParams.sunNumber = data.sunNumber;
    newLazyParams.name = data.name;
    newLazyParams.address = data.address;
    newLazyParams.loadingPort = data.loadingPort;
    newLazyParams.estimatedInspectionDate = data.estimatedInspectionDate
      ? new Date(Date.parse(data.estimatedInspectionDate))
      : undefined;
    newLazyParams.estimatedShipmentDate = data.estimatedShipmentDate
      ? new Date(Date.parse(data.estimatedShipmentDate))
      : undefined;
    newLazyParams.estimatedArrivalDate = data.estimatedArrivalDate
      ? new Date(Date.parse(data.estimatedArrivalDate))
      : undefined;
    newLazyParams.incoterm = data.incoterm;
    newLazyParams.productionTime = parseInt(data.productionTime, 10);
    newLazyParams.artWork = data.artWork;
    newLazyParams.paymentTermAdvance = data.paymentTermAdvance;
    newLazyParams.paymentTermBalance = data.paymentTermBalance;
    newLazyParams.paymentTermCondition = data.paymentTermCondition;
    newLazyParams.estimatedAdvancePaymentDate = data.estimatedAdvancePaymentDate
      ? new Date(Date.parse(data.estimatedAdvancePaymentDate))
      : undefined;
    newLazyParams.advancePaymentPercentage = data.advancePaymentPercentage;
    newLazyParams.estAdvancePaymentAmount = data.estAdvancePaymentAmount;
    newLazyParams.estimatedBalancePaymentDate = data.estimatedBalancePaymentDate
      ? new Date(Date.parse(data.estimatedBalancePaymentDate))
      : undefined;
    newLazyParams.balancePaymentPercentage = data.balancePaymentPercentage;
    newLazyParams.estBalancePaymentAmount = data.estBalancePaymentAmount;
    newLazyParams.purchasePI = data.purchasePI;
    newLazyParams.purchaseCI = data.purchaseCI;
    newLazyParams.grossMargin = data.grossMargin;
    newLazyParams.netMargin = data.netMargin;
    newLazyParams.supplierLoadingAmount = data.supplierLoadingAmount;

    setLazyParams(newLazyParams);

    // Oculta o painel de advanced filters
    advancedFiltersPanelRef.current?.hide();
  }

  /**
   * Salva valor do input a cada mudanca
   * @param event evento de mudaca no input
   * @param filter Input de filtro
   */
  function handleChangeAdvancedFilterInput(value: any, filter: IFilterInput) {
    // Busca valor atual do input
    const currentFilterInput = filter;

    // Muda o valor de acordo com o que foi digitado
    currentFilterInput.value = value;

    // Busca index do input no estado de inputs
    const currentFilterInputIndex = advancedFiltersInputs.findIndex(
      input => input.field === filter.field,
    );

    // Remove o input do estado de inputs
    const filteredInputs = advancedFiltersInputs.filter(
      input => input.field !== filter.field,
    );

    // Adiciona o input com valor atualizado na mesma posicao anterior
    filteredInputs.splice(currentFilterInputIndex, 0, currentFilterInput);

    // Define o estado de input com valores atualizados
    setAdvancedFiltersInputs(filteredInputs);
  }

  /**
   * Cancelar advanced filters
   */
  function handleCancelAdvancedFilters() {
    // Oculta o painel
    advancedFiltersPanelRef.current?.hide();

    // Restaura valores para antes de abrir o painel
    setAdvancedFiltersInputs(beforeCancelAdvancedFiltersInputs);
    setAdvancedFiltersFilteredInputs(beforeCancelAdvancedFiltersFilteredInputs);
    setAdvancedFiltersInputSearch(beforeCancelAdvancedFiltersInputSearch);
  }

  /**
   * Limpa filtros aplicados no advanced filters
   */
  function clearAdvancedFilters() {
    // Oculta o painel de advanced filters
    advancedFiltersPanelRef.current?.hide();

    // Reseta os inputs para valores vazios
    setAdvancedFiltersInputs(advancedFiltersInputsConst);

    // Reseta os inputs filtrados para valores vazios
    setAdvancedFiltersFilteredInputs(advancedFiltersInputsConst);

    // Limpa estado de colunas com filtro
    setFilteredColumnsHeader([]);

    // Define input de busca dos advanced filters como vazio
    setAdvancedFiltersInputSearch('');

    // Limpa lazy params
    const newLazyParams: ISatSuppliersLazyParams = {
      first: 0,
      page: 0,
      rows: lazyParams.rows,
    };

    setLazyParams(newLazyParams);
  }

  const parseSunColumn = (rowData: any) => {
    const permission = userHasPermission(
      idPermissionLinkSupplierDetails,
      userPermissions.userPermissions,
    );
    if (rowData.idSupplier2?.sunNumber && permission) {
      return (
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="sunButton"
            onClick={() => setSupplierToDetail(rowData)}
          >
            {rowData.idSupplier2.sunNumber}
          </button>
          <Link
            to={`/suppliers/list/${rowData.idSupplier}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <FiExternalLink size={15} />
          </Link>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (rowData.idSupplier2?.sunNumber) {
      return rowData.idSupplier2.sunNumber;
    }
    return undefined;
  };

  const percentBalance = (rowData: any) => {
    return rowData.balancePaymentPercentage ? (
      <span>{rowData.balancePaymentPercentage * 100}%</span>
    ) : undefined;
  };

  const percentAdvanced = (rowData: any) => {
    return rowData.advancePaymentPercentage ? (
      <span>{rowData.advancePaymentPercentage * 100}%</span>
    ) : undefined;
  };

  /**
   * Retorna componentes diferentes dependendo da coluna
   * @param field Coluna atual
   * @returns Respectivo componente
   */
  function handleColumn(field: string) {
    switch (field) {
      case 'idSupplier2.sunNumber':
        return parseSunColumn;
      case 'balancePaymentPercentage':
        return percentBalance;
      case 'advancePaymentPercentage':
        return percentAdvanced;
      default:
        return undefined;
    }
  }

  /**
   * Retorna componente de header com icone de filtro caso esteja aplicado a coluna
   * @param headerName Nome do header
   * @returns componente de header
   */
  function handleColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}
        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  /**
   * Define o tamanho de cada coluna de acordo com seu nome
   * @param column Nome da coluna
   * @returns estilo da coluna
   */
  function handleColumnSize(column: string) {
    switch (column) {
      case 'incoterm':
        return { width: '5rem' };
      case 'productionTime':
        return { width: '7.14rem' };
      case 'idSupplier2.sunNumber':
        return { width: '7.86rem' };
      default:
        return { width: '10rem' };
    }
  }

  /**
   * Reproduz as colunas selecionadas na configuracao
   */
  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          columnKey={col.field}
          field={col.field}
          body={handleColumn(col.field)}
          // Valida necessidade de icone de filtro no header
          header={handleColumnHeader(col.header)}
          style={handleColumnSize(col.field)}
          sortable
        />
      )
    );
  });

  /**
   * Ordenacao das colunas
   * @param event
   */
  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  };

  /**
   * Ao mudar pagina da tabela, muda os parametros de busca no backend
   * @param event Parametros de paginacao da tabela
   */
  function onPage(event: DataTablePageEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  /**
   * Ao fazer sort de alguma coluna, muda os parametros de busca no backend
   * @param event Parametros de sort da tabela
   */
  function onSort(event: DataTableSortEvent) {
    const newLazyParams = { ...lazyParams, ...event };
    setLazyParams(newLazyParams);
  }

  useEffect(() => {
    // Busca preferencias de exibicao de colunas do usuario
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      setSelectedColumns(JSON.parse(localStorageSelectedColumns));
    } else {
      setSelectedColumns(columns);
    }
  }, [columns]);

  // Ao pesquisar no filtro global
  useEffect(() => {
    // Valida se componente esta montado
    if (isMounted.current) {
      // Define delay na busca para nao bater no backend a cada tecla digitada
      const delayDebounceFn = setTimeout(() => {
        const newLazyParams = { ...lazyParams };
        newLazyParams.first = 0;
        newLazyParams.page = 0;
        newLazyParams.globalFilter = globalFilter;
        setLazyParams(newLazyParams);
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }
    // Define que componente esta montado
    isMounted.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  /**
   * Monitora necessidade de refetch de Sat Suppliers
   */
  useEffect(() => {
    async function handleRefetchSatSuppliers() {
      if (shouldRefetchSatSuppliers) {
        const refetchSuppliers = await satSuppliersRefetch();
        setSatSuppliers(refetchSuppliers.data.listSatSuppliersBySatId.data);
        setSupplierToDetail(undefined);
        setShouldRefetchSatSuppliers(false);
      }
    }
    handleRefetchSatSuppliers();
  }, [
    satSuppliersRefetch,
    setShouldRefetchSatSuppliers,
    shouldRefetchSatSuppliers,
  ]);

  return (
    <PageTabContainer selected={selected}>
      {!supplierToDetail && (
        <Container>
          <GridHeader>
            {/* Busca global */}
            <InputText
              className="gridSearch"
              type="search"
              value={globalFilter}
              onChange={e => setGlobalFilter(e.target.value)}
              placeholder="Search a Supplier"
            />
            {/* Advanced Filters */}
            <Button
              className="advanced-filters-button"
              label="Advanced Filters"
              onClick={e => handleAdvancedFiltersToggle(e)}
            />

            {/* Painel com todos os filtros */}
            <AdvancedFiltersPanel
              innerRef={advancedFiltersPanelRef}
              className="advanced-filters-form"
            >
              {/* Busca nos filtros */}
              <IconField iconPosition="left" className="mb-3">
                <InputIcon className="pi pi-search" />
                <InputText
                  type="search"
                  placeholder="Search available filters"
                  value={advancedFiltersInputSearch}
                  onChange={e => handleFilterAdvancedFilters(e)}
                  variant="filled"
                />
              </IconField>
              <Form
                ref={advancedFiltersFormRef}
                onSubmit={handleApplyAdvancedFilters}
              >
                <div className="inputs">
                  {advancedFiltersFilteredInputs.map(filter => {
                    return filter.field && filter.header ? (
                      <div key={filter.field} className="form-field">
                        <label htmlFor={filter.field}>{filter.header}</label>

                        <Input
                          type={
                            filter.type === 'date'
                              ? 'date'
                              : filter.type === 'number'
                              ? 'number'
                              : 'search'
                          }
                          keyfilter={
                            filter.type === 'number' ? 'int' : undefined
                          }
                          name={filter.field}
                          id={filter.field}
                          placeholder={`Search by ${filter.header}`}
                          value={filter.value}
                          onChange={e =>
                            handleChangeAdvancedFilterInput(
                              e.currentTarget.value,
                              filter,
                            )
                          }
                        />
                      </div>
                    ) : undefined;
                  })}
                </div>

                <div className="form-footer">
                  {/* Botão submit */}
                  <span>
                    <MainButton type="submit" label="Apply Filters" />
                    <Button
                      type="button"
                      label="Cancel"
                      onClick={handleCancelAdvancedFilters}
                    />
                  </span>
                  <Button
                    type="button"
                    severity="danger"
                    className="p-button-danger"
                    label="Clear Filters"
                    onClick={clearAdvancedFilters}
                  />
                </div>
              </Form>
            </AdvancedFiltersPanel>

            {/* Multi select de colunas da grid */}
            <MultiSelect
              gridRef={gridRef}
              className="grid-multiselect-panel"
              value={selectedColumns}
              options={columns.filter(column => column.field && column.header)}
              onChange={onColumnToggle}
            />
          </GridHeader>
          <Grid
            ref={gridRef}
            name="satSuppliers"
            lazy
            totalRecords={
              !satSuppliersData || !satSuppliersData.listSatSuppliersBySatId
                ? 0
                : satSuppliersData.listSatSuppliersBySatId.items
            }
            value={
              !satSuppliersData || !satSuppliersData.listSatSuppliersBySatId
                ? undefined
                : satSuppliers
            }
            globalFilter={globalFilter}
            emptyMessage="No SAT suppliers found."
            reorderableColumns
            removableSort
            scrollable
            scrollHeight={gridConstants.internalGridScrollHeight}
            rows={lazyParams.rows}
            first={lazyParams.first}
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            loading={satSuppliersLoading}
          >
            {dynamicColumns}
          </Grid>
        </Container>
      )}

      {supplierToDetail && (
        <Supplier
          supplierToDetail={supplierToDetail}
          setSupplierToDetail={setSupplierToDetail}
          sat={sat}
          roleEntityFields={roleEntityFields}
        />
      )}
    </PageTabContainer>
  );
};

export default Suppliers;
