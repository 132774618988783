import { DocumentNode, gql } from '@apollo/client';
import { ForeignTradeContainerItemsPermissions } from '../../interfaces';

export const getSumDataFromSatForeignTradeItemsQuery = gql`
  query GetSumDataFromSatForeignTradeItems($idSatForeignTrade: Int!) {
    getSumDataFromSatForeignTradeItems(idSatForeignTrade: $idSatForeignTrade) {
      quantityCiItemsSum
      quantityPiItemsSum
      totalCotCiItemsSum
      totalSunCiItemsSum
      totalGrossWeightItemsSum
      totalNetWeightItemsSum
      totalCbmItemsSum
      remainQuantityPiShipmentSum
      quantityPiShipmentSum
      containersTotals {
        idSatForeignTradeCtnr
        quantityCtnrSum
        grossWeightSum
        netWeightSum
        cbmSum
      }
    }
  }
`;

export const deleteSatForeignTradeItemsQuery = gql`
  mutation DeleteSatForeignTradeItems($idsSatForeignTradeItems: [Int!]) {
    deleteSatForeignTradeItems(
      idsSatForeignTradeItems: $idsSatForeignTradeItems
    )
  }
`;

export const listSatForeignTradeItemsBySatForeignTradeIdQuery = (
  containerItemsPermissions: ForeignTradeContainerItemsPermissions,
): DocumentNode => gql`
query ListSatForeignTradeItemsBySatForeignTradeId(
  $data: ListSatForeignTradeItemsInput!
) {
  listSatForeignTradeItemsBySatForeignTradeId(data: $data) {
    items
    data {
      idSatForeignTrade
      idSatForeignTradeItem
      ${
        containerItemsPermissions.quantityPiShipment.view
          ? 'quantityPiShipment'
          : ''
      }
      ${containerItemsPermissions.quantityCi.view ? 'quantityCi' : ''}
      ${containerItemsPermissions.sunPrice.view ? 'sunPrice' : ''}
      ${containerItemsPermissions.cotPrice.view ? 'cotPrice' : ''}
      ${containerItemsPermissions.totalCotCi.view ? 'totalCotCi' : ''}
      ${containerItemsPermissions.totalSunCi.view ? 'totalSunCi' : ''}
      idSatItem2 {
        ${containerItemsPermissions.stCode.view ? 'stCode' : ''}
        ${containerItemsPermissions.quantity.view ? 'quantity' : ''}
        order
        ${
          containerItemsPermissions.piecesContainer.view
            ? 'piecesContainer'
            : ''
        }
        ${
          containerItemsPermissions.remainQuantityPiShipment.view
            ? 'remainQuantityPiShipment'
            : ''
        }
        cbm
      }
      satForeignTradeItemCtnrs {
              idSatForeignTradeItemCtnr
              idSatForeignTradeItemReference
              idSatForeignTradeCtnr
              ${
                containerItemsPermissions.quantityCtnr.view
                  ? 'quantityCtnr'
                  : ''
              }
              ${containerItemsPermissions.grossWeight.view ? 'grossWeight' : ''}
              ${containerItemsPermissions.netWeight.view ? 'netWeight' : ''}
              ${containerItemsPermissions.cbm.view ? 'cbm' : ''}
      }
      satItemReferences {
          idSatItemReference
          colourEn
          totalQtyPiSize
          totalQuantityCi
          totalCotCi
          totalSunCi
          ${
            containerItemsPermissions.totalQuantityPiShipment.view
              ? 'totalQuantityPiShipment'
              : ''
          }
          remainQuantityPiShipment
          satForeignTradeItemReferences {
            idSatForeignTradeItemReference
            ${
              containerItemsPermissions.quantityPiShipment.view
                ? 'quantityPiShipment'
                : ''
            }
            ${containerItemsPermissions.quantityCi.view ? 'quantityCi' : ''}
            ${containerItemsPermissions.totalCotCi.view ? 'totalCotCi' : ''}
            ${containerItemsPermissions.totalSunCi.view ? 'totalSunCi' : ''}
            idSatReferenceSizeBarcode2 {
              idSatReferenceSizeBarcode
              ${containerItemsPermissions.stCode.view ? 'reference' : ''}
              ${containerItemsPermissions.quantity.view ? 'qtyPiSize' : ''}
              ${
                containerItemsPermissions.remainQuantityPiShipment.view
                  ? 'remainQuantityPiShipment'
                  : ''
              }
              ${containerItemsPermissions.sizeRange.view ? 'sizeRange' : ''}
            }
        }
      }
    }
  }
}
`;
