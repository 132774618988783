import styled from 'styled-components';

export const Container = styled.div``;

export const ResponseItem = styled.div`
  h2 {
    padding: 12px 0;
  }

  .warning-title {
    color: var(--orange-500);
  }

  .error-title {
    color: var(--pink-500);
  }

  h3 {
    padding-bottom: 8px;

    i {
      margin-right: 4px;
    }
  }

  .response-content + .response-content {
    margin-top: 12px;
  }

  & + & {
    margin-top: 12px;
    border-top: 1px solid var(--grey-border);
  }
`;
