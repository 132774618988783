import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CrudForm = styled.div`
  padding: 26px 32px;
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GeneralInformation = styled.div`
  margin-left: 16px;
  width: 100%;

  .form-div {
    margin-top: 32px;
    display: flex;
    form {
      div + div,
      h1 + div {
        margin-top: 16px;
      }
    }
    form + form {
      margin-left: 20px;
    }
  }
  .secondFormInput {
    width: 550px;
  }
  .generalInput {
    width: 263px;
  }
`;

export const Row = styled.div`
  display: flex;

  div + div {
    margin-left: 16px;
  }

  && {
    margin-top: 16px;
  }
`;
