import { gql } from '@apollo/client';

export const listUsersQuery = gql`
  query listAllUsers($listAllUsersInput: ListAllUsersInput) {
    listAllUsers(listAllUsersInput: $listAllUsersInput) {
      data {
        idUser
        username
        firstName
        lastName
      }
      items
    }
  }
`;

export const listClientQuotationsQuery = gql`
  query listAllClientQuotationsQuery(
    $listAllClientQuotationsInput: ListAllClientQuotationsInput!
  ) {
    listAllClientQuotations(
      listAllClientQuotationsInput: $listAllClientQuotationsInput
    ) {
      data {
        idCq
        cqNumber
      }
    }
  }
`;

export const listPrcsQuery = gql`
  query listAllPrcsQuery($data: ListAllPrcsInput!) {
    listAllPrcsByPrcCodeAndPrcCodeNotNull(data: $data) {
      idPrc
      prcCode
    }
  }
`;

export const listSatsQuery = gql`
  query listAllSatsQuery($listAllSatsInput: ListAllSatsInput!) {
    listAllSats(listAllSatsInput: $listAllSatsInput) {
      data {
        idSat
        satNumber
      }
    }
  }
`;

export const listProductsQuery = gql`
  query listAllProductsQuery($listAllProductsInput: ListAllProductsInput!) {
    listAllProducts(listAllProductsInput: $listAllProductsInput) {
      data {
        idProduct
        stCode
      }
    }
  }
`;
