import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const defaultTitle = 'SAT - Sun Asia Trade Co.';

/**
 * Define a propriedade Title da pagina
 * @param title Titulo da pagina
 */
const useTitle = (title: string): void => {
  const history = useHistory();

  useEffect(() => {
    document.title = `${title} - SAT`;
  }, [title]);

  // Reseta o titulo da pagina quando o componente for desmontado
  useEffect(() => {
    const resetTitle = () => {
      document.title = defaultTitle;
    };

    const unlisten = history.listen(resetTitle);

    return () => {
      unlisten();
    };
  }, [history]);
};

export default useTitle;
