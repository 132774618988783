import React, {
  Ref,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Dialog } from 'primereact/dialog';
import {
  PackageDescriptionFieldsPermissions,
  ProductPackageDescription,
} from '../../../interfaces';
import {
  managePackageDescriptionInitialState,
  managePackageDescriptionReducer,
} from './reducer';
import { ManagePackageDescriptionReducerActionKind } from './interfaces';
import Button from '../../../../../../../components/Button';
import PackageDescriptionContent from '../PackageDescriptionContent';
import { IPackageDescriptionContentRef } from '../PackageDescriptionContent/interfaces';
import {
  createProductPackageDescriptionQuery,
  updateProductPackageDescriptionQuery,
} from './queries';
import { useRefHook } from '../../../../../../../hooks/useRefHook';

export interface IManagePackageDescriptionRef {
  toggleManageDescriptionDialog(
    packageDescription?: ProductPackageDescription,
  ): void;
}

interface IPackageDescriptionProps {
  ref: Ref<IManagePackageDescriptionRef>;
  packageDescriptionsRefetch(): Promise<ApolloQueryResult<any>>;
  fieldsPermissions: PackageDescriptionFieldsPermissions;
  userCanChangeFields: boolean;
}

const ManagePackageDescription: React.FC<IPackageDescriptionProps> =
  React.forwardRef(
    (
      { packageDescriptionsRefetch, fieldsPermissions, userCanChangeFields },
      ref,
    ) => {
      const [updateProductPackageDescription] = useMutation(
        updateProductPackageDescriptionQuery,
      );
      const [createProductPackageDescription] = useMutation(
        createProductPackageDescriptionQuery,
      );

      const contentRef = useRef<IPackageDescriptionContentRef>(null);

      const { showSuccess, showError } = useRefHook();

      const [packageDescriptionState, packageDescriptionDispatch] = useReducer(
        managePackageDescriptionReducer,
        managePackageDescriptionInitialState,
      );

      const isCreate =
        !packageDescriptionState.packageDescription?.idPackageDescription;

      const [loading, setLoading] = useState(false);

      useImperativeHandle(ref, () => ({
        toggleManageDescriptionDialog: packageDescription => {
          packageDescriptionDispatch({
            type: ManagePackageDescriptionReducerActionKind.OPEN_DIALOG,
            payload: { packageDescription },
          });
        },
      }));

      async function handleSaveDescription() {
        const formData = await contentRef.current?.submit();

        if (!formData) return;

        setLoading(true);

        try {
          if (isCreate) {
            await createProductPackageDescription({
              variables: {
                data: {
                  idPackageDescription: formData.idPackageDescription,
                  idProductPackage: formData.idProductPackage,
                  idResponsible: formData.idResponsible,
                  comments: formData.comments,
                },
              },
            });
          } else {
            await updateProductPackageDescription({
              variables: {
                data: {
                  idPackageDescription: formData.idPackageDescription,
                  idProductPackage: formData.idProductPackage,
                  idResponsible: formData.idResponsible,
                  previousIdPackageDescription:
                    formData.previousIdPackageDescription,
                  comments: formData.comments,
                },
              },
            });
          }

          await packageDescriptionsRefetch();

          showSuccess({ summary: 'Description saved' });

          packageDescriptionDispatch({
            type: ManagePackageDescriptionReducerActionKind.CLOSE_DIALOG,
          });
        } catch (error) {
          showError({
            summary: 'Error while saving description',
            detail: error.message,
          });
        } finally {
          setLoading(false);
        }
      }

      const dialogFooter = () => {
        return (
          <div className="flex gap-2 justify-content-end">
            <Button
              label="Save"
              icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
              onClick={() => handleSaveDescription()}
              disabled={loading}
            />
            <Button
              label="Cancel"
              severity="danger"
              icon="pi pi-times"
              onClick={() =>
                packageDescriptionDispatch({
                  type: ManagePackageDescriptionReducerActionKind.CLOSE_DIALOG,
                })
              }
              disabled={loading}
            />
          </div>
        );
      };

      return (
        <Dialog
          header={isCreate ? 'Add Description' : 'Manage Description'}
          visible={packageDescriptionState.displayDialog}
          style={{ width: '500px' }}
          modal
          onHide={() =>
            packageDescriptionDispatch({
              type: ManagePackageDescriptionReducerActionKind.CLOSE_DIALOG,
            })
          }
          footer={dialogFooter()}
          closable={false}
        >
          <PackageDescriptionContent
            ref={contentRef}
            packageDescription={packageDescriptionState.packageDescription}
            fieldsPermissions={fieldsPermissions}
            userCanChangeFields={userCanChangeFields}
          />
        </Dialog>
      );
    },
  );

export default ManagePackageDescription;
