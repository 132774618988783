import { DocumentNode, gql } from '@apollo/client';
import { FinancialApportionmentFieldsPermissions } from '../interfaces';

export const listApportionmentQuery = (
  fieldsPermissions: FinancialApportionmentFieldsPermissions,
): DocumentNode => gql`
  query GetSatForeignTradeApportionment($idSatForeignTrade: Int!) {
    getSatForeignTradeApportionment(idSatForeignTrade: $idSatForeignTrade) {
      data {
        ${fieldsPermissions.stCode.view ? 'stCode' : ''}
        ${fieldsPermissions.quantityPi.view ? 'quantityPi' : ''}
        ${fieldsPermissions.quantityCi.view ? 'quantityCi' : ''}
        ${fieldsPermissions.sunPrice.view ? 'sunPrice' : ''}
        ${fieldsPermissions.sunPriceTotal.view ? 'sunPriceTotal' : ''}
        ${fieldsPermissions.netWeight.view ? 'netWeight' : ''}
        ${fieldsPermissions.netWeightTotal.view ? 'netWeightTotal' : ''}
        ${fieldsPermissions.grossWeight.view ? 'grossWeight' : ''}
        ${fieldsPermissions.grossWeightTotal.view ? 'grossWeightTotal' : ''}
        ${
          fieldsPermissions.calculationBasisNetWeight.view
            ? 'calculationBasisNetWeight'
            : ''
        }
        ${
          fieldsPermissions.calculationBasisGrossWeight.view
            ? 'calculationBasisGrossWeight'
            : ''
        }
        ${
          fieldsPermissions.calculationBasisSunPrice.view
            ? 'calculationBasisSunPrice'
            : ''
        }
        ${fieldsPermissions.freight.view ? 'freight' : ''}
        ${fieldsPermissions.freightTaxes.view ? 'freightTaxes' : ''}
        ${fieldsPermissions.fobCharges.view ? 'fobCharges' : ''}
        ${fieldsPermissions.yiwu.view ? 'yiwu' : ''}
        ${fieldsPermissions.samplesNetWeight.view ? 'samplesNetWeight' : ''}
        ${fieldsPermissions.samplesGrossWeight.view ? 'samplesGrossWeight' : ''}
        ${fieldsPermissions.samplesSunPrice.view ? 'samplesSunPrice' : ''}
        ${fieldsPermissions.otherNetWeight.view ? 'otherNetWeight' : ''}
        ${fieldsPermissions.otherGrossWeight.view ? 'otherGrossWeight' : ''}
        ${fieldsPermissions.otherSunPrice.view ? 'otherSunPrice' : ''}
        ${
          fieldsPermissions.sunWithApportionmentTotal.view
            ? 'sunWithApportionmentTotal'
            : ''
        }
        ${
          fieldsPermissions.sunWithApportionmentUnit.view
            ? 'sunWithApportionmentUnit'
            : ''
        }
      }
      sum {
        __typename
        ${fieldsPermissions.quantityPi.view ? 'quantityPiSum' : ''}
        ${fieldsPermissions.quantityCi.view ? 'quantityCiSum' : ''}
        ${fieldsPermissions.sunPriceTotal.view ? 'sunPriceTotalSum' : ''}
        ${fieldsPermissions.netWeightTotal.view ? 'netWeightTotalSum' : ''}
        ${fieldsPermissions.grossWeightTotal.view ? 'grossWeightTotalSum' : ''}
        ${
          fieldsPermissions.calculationBasisNetWeight.view
            ? 'calculationBasisNetWeightSum'
            : ''
        }
        ${
          fieldsPermissions.calculationBasisGrossWeight.view
            ? 'calculationBasisGrossWeightSum'
            : ''
        }
        ${
          fieldsPermissions.calculationBasisSunPrice.view
            ? 'calculationBasisSunPriceSum'
            : ''
        }
        ${fieldsPermissions.freight.view ? 'freightSum' : ''}
        ${fieldsPermissions.freightTaxes.view ? 'freightTaxesSum' : ''}
        ${fieldsPermissions.fobCharges.view ? 'fobChargesSum' : ''}
        ${fieldsPermissions.yiwu.view ? 'yiwuSum' : ''}
        ${fieldsPermissions.samplesNetWeight.view ? 'samplesNetWeightSum' : ''}
        ${
          fieldsPermissions.samplesGrossWeight.view
            ? 'samplesGrossWeightSum'
            : ''
        }
        ${fieldsPermissions.samplesSunPrice.view ? 'samplesSunPriceSum' : ''}
        ${fieldsPermissions.otherNetWeight.view ? 'otherNetWeightSum' : ''}
        ${fieldsPermissions.otherGrossWeight.view ? 'otherGrossWeightSum' : ''}
        ${fieldsPermissions.otherSunPrice.view ? 'otherSunPriceSum' : ''}
        ${
          fieldsPermissions.sunWithApportionmentTotal.view
            ? 'sunWithApportionmentTotalSum'
            : ''
        }
      }
    }
  }
`;
