import { divide, subtract } from '../../../../utils/mathUtil';

/**
 * Busca valor de Estimated NET Margin
 * @param sellerTotalOnMargin Valor de Seller Total On Margin
 * @param purchaseTotalOnMargin Valor de Purchase Total On Margin
 * @param supplierAmountCotTotal Valor de Supplier Amount Cot Total
 * @param supplierAmountTotal Valor de Supplier Amount Total
 * @returns Estimated NET Margin ou Undefined
 */
export function getEstimatedNetMargin(
  sellerTotalOnMargin: number,
  purchaseTotalOnMargin: number,
  supplierAmountCotTotal?: number,
  supplierAmountTotal?: number,
): number | undefined {
  const totalOnMarginFromSeller =
    (supplierAmountTotal || 0) + sellerTotalOnMargin;

  const totalOnMarginFromPurchase =
    (supplierAmountCotTotal || 0) + purchaseTotalOnMargin;

  if (totalOnMarginFromSeller && totalOnMarginFromPurchase) {
    const divisionResult = divide(
      totalOnMarginFromSeller,
      totalOnMarginFromPurchase,
    );

    if (divisionResult !== undefined) {
      return (divisionResult - 1) * 100;
    }
  }

  return undefined;
}

/**
 * Busca valor de NET Margin
 * @param sellerTotalOnMargin Valor de Seller Total On Margin
 * @param purchaseTotalOnMargin Valor de Purchase Total On Margin
 * @param totalSunCiShipment Valor de Total Sun Ci Shipment
 * @param totalCotCiShipment Valor de Total COT Ci Shipment
 * @returns NET Margin ou Undefined
 */
export function getNetMargin(
  sellerTotalOnMargin: number,
  purchaseTotalOnMargin: number,
  totalSunCiShipment?: number,
  totalCotCiShipment?: number,
): number | undefined {
  const totalOnMarginFromSeller =
    sellerTotalOnMargin + (totalSunCiShipment || 0);

  const totalOnMarginFromPurchase =
    purchaseTotalOnMargin + (totalCotCiShipment || 0);

  if (totalOnMarginFromSeller && totalOnMarginFromPurchase) {
    const divisionResult = divide(
      totalOnMarginFromSeller,
      totalOnMarginFromPurchase,
    );

    if (divisionResult !== undefined) {
      return (divisionResult - 1) * 100;
    }
  }

  return undefined;
}

/**
 * Busca valor de Estimated NET Profit
 * @param sellerTotalOnMargin Valor de Seller Total On Margin
 * @param purchaseTotalOnMargin Valor de Purchase Total On Margin
 * @param supplierAmountCotTotal Valor de Supplier Amount Cot Total
 * @param supplierAmountTotal Valor de Supplier Amount Total
 * @returns Estimated NET Profit ou Undefined
 */
export function getEstimatedNetProfit(
  sellerTotalOnMargin: number,
  purchaseTotalOnMargin: number,
  supplierAmountCotTotal?: number,
  supplierAmountTotal?: number,
): number | undefined {
  const totalOnMarginFromSeller =
    (supplierAmountTotal || 0) + sellerTotalOnMargin;

  const totalOnMarginFromPurchase =
    (supplierAmountCotTotal || 0) + purchaseTotalOnMargin;

  return subtract(totalOnMarginFromSeller, totalOnMarginFromPurchase);
}

/**
 * Busca valor de NET Profit
 * @param sellerTotalOnMargin Valor de Seller Total On Margin
 * @param purchaseTotalOnMargin Valor de Purchase Total On Margin
 * @param totalSunCiShipment Valor de Total Sun Ci Shipment
 * @param totalCotCiShipment Valor de Total COT Ci Shipment
 * @returns NET Profit ou Undefined
 */
export function getNetProfit(
  sellerTotalOnMargin: number,
  purchaseTotalOnMargin: number,
  totalSunCiShipment?: number,
  totalCotCiShipment?: number,
): number | undefined {
  const totalOnMarginFromSeller =
    sellerTotalOnMargin + (totalSunCiShipment || 0);

  const totalOnMarginFromPurchase =
    purchaseTotalOnMargin + (totalCotCiShipment || 0);

  return subtract(totalOnMarginFromSeller, totalOnMarginFromPurchase);
}
