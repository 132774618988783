import styled from 'styled-components';

export const Container = styled.div`
  min-width: 400px;
`;

export const CheckboxBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  && {
    margin-top: 30px;
  }
`;
