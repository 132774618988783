/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  RefObject,
} from 'react';

import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Container,
  Row,
  CheckboxContainer,
  CoreContainer,
  InputsContainer,
} from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../components/PageTabContainer';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';
import { IPartner, ISelectCategory } from '../interfaces';
import FormInputTextArea from '../../../components/FormInputTextArea';
import FormInputNumber from '../../../components/FormInputNumber';
import { useRefHook } from '../../../hooks/useRefHook';
import FormDropdown from '../../../components/FormDropdown';
import { lazyParamsPaginationGrid } from '../../../services/layoutsDefinitions';
import ILazyParams from '../../../services/lazyParams';
import { IRoleEntityField } from '../../../interfaces/IRoleEntityField';
import { IRolesRef } from '../../User/Roles';
import getFieldPermission from '../../../utils/getFieldPermission';
import { partnersListRoles } from '../../../shared/roles/partners';
import ToastLife from '../../../shared/enums/toastLife';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  partner: IPartner;
  changePropertyValue(property: string, value: any): void;
  setSelectedCategory: Dispatch<SetStateAction<ISelectCategory | undefined>>;
  selectedCategory?: ISelectCategory;
  rolesRef: RefObject<IRolesRef>;
  roleEntityFields: IRoleEntityField[];
}

const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  selected,
  partner,
  roleEntityFields,
}) => {
  // Referencia ao toast
  const { toastRef, formRef } = useRefHook();

  const {
    idFieldFormalPartnerName,
    idFieldTaxId,
    idFieldAddress,
    idFieldCity,
    idFieldZipCode,
    idFieldCourier,
    idFieldRegistrationNumber,
    idFieldValue,
    idFieldPaymentTerms,
    idFieldRegion,
    idFieldIndAudit,
    idFieldIndCertifyingEntity,
    idFieldIndCourier,
    idFieldIndCustomsBroker,
    idFieldIndFinancialServices,
    idFieldIndFreightForwarderDestination,
    idFieldIndFreightForwarderOrigin,
    idFieldIndInlandTransportsDestination,
    idFieldIndInlandTransportsOrigin,
    idFieldIndInsuranceCompany,
    idFieldIndLicensor,
    idFieldIndOthers,
    idFieldIndRepresentative,
    idFieldIndShipowner,
    idFieldIndWarehouse,
  } = partnersListRoles.fields;

  // ID do pais selecionado
  const [selectedCountryId, setSelectedCountryId] = useState<any>();

  // ID da regiao selecionada
  const [selectedRegionId, setSelectedRegionId] = useState<any>();

  // Parametros de paginacao/backend
  const [lazyParams] = useState<ILazyParams>(lazyParamsPaginationGrid);

  // Query de listagem de paises
  const listAllCountriesQuery = gql`
    query ListAllCountriesQuery {
      listAllCountries {
        idCountry
        name
      }
    }
  `;

  // Query para listar regioes
  const listRegionsQuery = gql`
    query ListRegionsByCountryIdQuery($id: Int!) {
      listRegionsByCountryId(id: $id) {
        idRegion
        name
      }
    }
  `;

  // Query para listar cidades
  const listCitiesQuery = gql`
    query ListCitiesByRegionIdQuery($id: Int!) {
      listCitiesByRegionId(id: $id) {
        idCity
        name
      }
    }
  `;

  // Query para listar correios
  const listCouriersQuery = gql`
    query ListDomainsByGroupIdQuery(
      $id: Int!
      $pagination: Pagination!
      $description: String
    ) {
      listDomainsByGroupId(
        id: $id
        pagination: $pagination
        description: $description
      ) {
        data {
          idDomain
          description
        }
        items
      }
    }
  `;

  /**
   * Busca Paises
   */
  const {
    loading: countriesLoading,
    data: countriesData,
    error: countriesError,
  } = useQuery(listAllCountriesQuery, {
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting countries',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca regioes
   */
  const [
    loadRegions,
    {
      called: regionsCalled,
      loading: regionsLoading,
      data: regionsData,
      error: regionsError,
    },
  ] = useLazyQuery(listRegionsQuery, {
    variables: {
      id: selectedCountryId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting regions',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca cidades
   */
  const [
    loadCities,
    {
      called: citiesCalled,
      loading: citiesLoading,
      data: citiesData,
      error: citiesError,
    },
  ] = useLazyQuery(listCitiesQuery, {
    variables: {
      id: selectedRegionId,
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting cities',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  /**
   * Busca correios
   */
  const [
    loadCouriers,
    {
      called: couriersCalled,
      loading: couriersLoading,
      data: couriersData,
      error: couriersError,
    },
  ] = useLazyQuery(listCouriersQuery, {
    variables: {
      id: 3,
      description: lazyParams.globalFilter,
      pagination: {
        _page: lazyParams.page + 1,
        _limit: lazyParams.rows,
        _orderBy: lazyParams.sortField,
        _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
      },
    },
    onError: errorData => {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while getting couriers',
        detail: errorData.message,
        life: ToastLife.ERROR,
      });
    },
  });

  function onSelectCountry(country: Record<string, unknown>) {
    setSelectedCountryId(country.idCountry);
    formRef.current?.setFieldValue('region', undefined);
    loadRegions();
    formRef.current?.setFieldValue('cityName', undefined);
  }

  function onSelectRegion(region: Record<string, unknown>) {
    formRef.current?.setFieldValue('cityName', undefined);
    if (region) {
      setSelectedRegionId(region.idRegion);
      loadCities();
    }
  }

  useEffect(() => {
    if (partner.idPartner) {
      setSelectedCountryId(partner.idCountry2.idCountry);
      loadRegions();
      if (partner.idRegion) {
        setSelectedRegionId(partner.idRegion2.idRegion);
        loadCities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadRegions, loadCities]);

  useEffect(() => {
    loadCouriers();
  }, [loadCouriers]);

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return partner.idPartner
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <CoreContainer>
          <h3>Core</h3>
          <FiAlertCircle color="#c53030" size={15} />
          <CheckboxContainer>
            {showField(idFieldIndInlandTransportsOrigin) && (
              <FormCheckbox
                className="m-2"
                name="indInlandTransportsOrigin"
                label="Inland Transport (Origin)"
                readOnly={disableField(idFieldIndInlandTransportsOrigin)}
                initialValue={
                  partner ? partner.indInlandTransportsOrigin : false
                }
              />
            )}
            {showField(idFieldIndInlandTransportsDestination) && (
              <FormCheckbox
                className="m-2"
                name="indInlandTransportsDestination"
                label="Inland Transport (Destination)"
                readOnly={disableField(idFieldIndInlandTransportsDestination)}
                initialValue={
                  partner ? partner.indInlandTransportsDestination : false
                }
              />
            )}
            {showField(idFieldIndFreightForwarderOrigin) && (
              <FormCheckbox
                className="m-2"
                name="indFreightForwarderOrigin"
                label="Freight Forwarder (Origin)"
                readOnly={disableField(idFieldIndFreightForwarderOrigin)}
                initialValue={
                  partner ? partner.indFreightForwarderOrigin : false
                }
              />
            )}
            {showField(idFieldIndFreightForwarderDestination) && (
              <FormCheckbox
                className="m-2"
                name="indFreightForwarderDestination"
                label="Freight Forwarder (Destination)"
                readOnly={disableField(idFieldIndFreightForwarderDestination)}
                initialValue={
                  partner ? partner.indFreightForwarderDestination : false
                }
              />
            )}
            {showField(idFieldIndCustomsBroker) && (
              <FormCheckbox
                className="m-2"
                name="indCustomsBroker"
                label="Customs Broker"
                readOnly={disableField(idFieldIndCustomsBroker)}
                initialValue={partner ? partner.indCustomsBroker : false}
              />
            )}
            {showField(idFieldIndAudit) && (
              <FormCheckbox
                className="m-2"
                name="indAudit"
                label="Audit"
                readOnly={disableField(idFieldIndAudit)}
                initialValue={partner ? partner.indAudit : false}
              />
            )}
            {showField(idFieldIndWarehouse) && (
              <FormCheckbox
                className="m-2"
                name="indWarehouse"
                label="Warehouse"
                readOnly={disableField(idFieldIndWarehouse)}
                initialValue={partner ? partner.indWarehouse : false}
              />
            )}
            {showField(idFieldIndInsuranceCompany) && (
              <FormCheckbox
                className="m-2"
                name="indInsuranceCompany"
                label="Insurance Company"
                readOnly={disableField(idFieldIndInsuranceCompany)}
                initialValue={partner ? partner.indInsuranceCompany : false}
              />
            )}
            {showField(idFieldIndRepresentative) && (
              <FormCheckbox
                className="m-2"
                name="indRepresentative"
                label="Representative"
                readOnly={disableField(idFieldIndRepresentative)}
                initialValue={partner ? partner.indRepresentative : false}
              />
            )}
            {showField(idFieldIndFinancialServices) && (
              <FormCheckbox
                className="m-2"
                name="indFinancialServices"
                label="Financial Services"
                readOnly={disableField(idFieldIndFinancialServices)}
                initialValue={partner ? partner.indFinancialServices : false}
              />
            )}
            {showField(idFieldIndShipowner) && (
              <FormCheckbox
                className="m-2"
                name="indShipowner"
                label="Shipowner"
                readOnly={disableField(idFieldIndShipowner)}
                initialValue={partner ? partner.indShipowner : false}
              />
            )}
            {showField(idFieldIndCourier) && (
              <FormCheckbox
                className="m-2"
                name="indCourier"
                label="Courier"
                readOnly={disableField(idFieldIndCourier)}
                initialValue={partner ? partner.indCourier : false}
              />
            )}
            {showField(idFieldIndCertifyingEntity) && (
              <FormCheckbox
                className="m-2"
                name="indCertifyingEntity"
                label="Certify Entity"
                readOnly={disableField(idFieldIndCertifyingEntity)}
                initialValue={partner ? partner.indCertifyingEntity : false}
              />
            )}
            {showField(idFieldIndLicensor) && (
              <FormCheckbox
                className="m-2"
                name="indLicensor"
                label="Licensor"
                readOnly={disableField(idFieldIndLicensor)}
                initialValue={partner ? partner.indLicensor : false}
              />
            )}
            {showField(idFieldIndOthers) && (
              <FormCheckbox
                className="m-2"
                name="indOthers"
                label="Others"
                readOnly={disableField(idFieldIndOthers)}
                initialValue={partner ? partner.indOthers : false}
              />
            )}
          </CheckboxContainer>
        </CoreContainer>

        <InputsContainer>
          <Row>
            <FormInput name="name" label="Partner Name" required />
          </Row>
          <Row>
            {showField(idFieldFormalPartnerName) && (
              <FormInput
                name="formalName"
                label="Formal Partner Name"
                required
                readOnly={disableField(idFieldFormalPartnerName)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldTaxId) && (
              <FormInput
                name="idTax"
                label="Tax ID/CNPJ"
                required
                readOnly={disableField(idFieldTaxId)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldAddress) && (
              <FormInputTextArea
                name="address"
                label="Address"
                rows={5}
                cols={73}
                readOnly={disableField(idFieldAddress)}
              />
            )}
          </Row>
          <Row>
            <FormDropdown
              className="countryInput"
              name="country"
              label="Country"
              readOnly={countriesLoading || countriesError !== undefined}
              options={
                countriesLoading || countriesError
                  ? undefined
                  : countriesData.listAllCountries
              }
              optionLabel="name"
              placeholder={countriesLoading ? 'Loading' : ''}
              initialValue={partner.idCountry2}
              filter
              filterBy="name"
              onValueChange={onSelectCountry}
              required
            />
          </Row>
          <Row>
            {showField(idFieldRegion) && (
              <FormDropdown
                name="region"
                label="State/Provincy"
                placeholder={regionsLoading ? 'Loading' : ''}
                options={
                  regionsCalled && !regionsLoading && !regionsError
                    ? regionsData.listRegionsByCountryId
                    : undefined
                }
                optionLabel="name"
                initialValue={partner.idRegion2}
                filter
                filterBy="name"
                onValueChange={onSelectRegion}
                showClear
                readOnly={disableField(idFieldRegion)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldCity) && (
              <FormDropdown
                name="cityName"
                label="City"
                placeholder={citiesLoading ? 'Loading' : ''}
                options={
                  citiesCalled && !citiesLoading && !citiesError
                    ? citiesData.listCitiesByRegionId
                    : undefined
                }
                optionLabel="name"
                initialValue={partner.idCity2}
                filter
                filterBy="name"
                showClear
                readOnly={disableField(idFieldCity)}
              />
            )}
          </Row>

          <Row>
            {showField(idFieldZipCode) && (
              <FormInput
                name="zipCode"
                label="Zip Code"
                readOnly={disableField(idFieldZipCode)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldCourier) && (
              <FormDropdown
                name="courier"
                label="Courier"
                placeholder={couriersLoading ? 'Loading' : ''}
                options={
                  couriersCalled && !couriersLoading && !couriersError
                    ? couriersData.listDomainsByGroupId.data
                    : undefined
                }
                optionLabel="description"
                initialValue={partner.courier2}
                filter
                filterBy="description"
                editable
                showClear
                readOnly={disableField(idFieldCourier)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldRegistrationNumber) && (
              <FormInput
                name="registrationNumber"
                label="Registration Number"
                readOnly={disableField(idFieldRegistrationNumber)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldValue) && (
              <FormInputNumber
                name="value"
                label="Value"
                decimalScale={4}
                fixedDecimalScale
                readOnly={disableField(idFieldValue)}
              />
            )}
          </Row>
          <Row>
            {showField(idFieldPaymentTerms) && (
              <FormInputNumber
                name="paymentTerm"
                label="Payment Terms"
                decimalScale={4}
                fixedDecimalScale
                readOnly={disableField(idFieldPaymentTerms)}
              />
            )}
          </Row>
        </InputsContainer>
      </Container>
    </PageTabContainer>
  );
};

export default GeneralInformation;
