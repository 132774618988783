import React from 'react';
import { ResultsContainer } from '../../styles';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import { ForeignTradeSupplierTotalReducer } from '../interfaces';
import { getCurrencySymbol } from '../../../../../../utils/getCurrencySymbol';
import {
  SatFinancialResultsPermissions,
  UserCanResultsBlock,
} from '../../interfaces';

interface IResultsProps {
  totalState: ForeignTradeSupplierTotalReducer;
  satCurrencyAbbreviation?: string;
  fieldsPermissions: SatFinancialResultsPermissions;
  userCan: UserCanResultsBlock;
  satCurrency?: string;
}

const Results: React.FC<IResultsProps> = ({
  totalState,
  satCurrencyAbbreviation,
  fieldsPermissions,
  userCan,
}) => {
  const satCurrencySymbol = getCurrencySymbol(satCurrencyAbbreviation);

  const usdSymbol = getCurrencySymbol('USD');

  const {
    convertedFieldsPermissions,
    mainFieldsPermissions,
    grossFieldsPermissions,
    otherExpensesFieldsPermissions,
    netFieldsPermissions,
  } = fieldsPermissions;

  const userCanSeeCot =
    mainFieldsPermissions.totalCotPiShipment.view ||
    mainFieldsPermissions.totalCotCiShipment.view;

  const userCanSeeSun =
    mainFieldsPermissions.totalSunPiShipment.view ||
    mainFieldsPermissions.totalSunCiShipment.view ||
    mainFieldsPermissions.totalWithApportionment.view;

  const userCanSeeConvertedCot =
    convertedFieldsPermissions.totalConvertedCotPi.view ||
    convertedFieldsPermissions.totalConvertedCotCi.view;

  const userCanSeeConvertedSun =
    convertedFieldsPermissions.totalConvertedSunPi.view ||
    convertedFieldsPermissions.totalConvertedSunCi.view;

  const userCanSeeOtherExpensesCot =
    otherExpensesFieldsPermissions.purchaseTotalOtherExpenses.view ||
    otherExpensesFieldsPermissions.purchaseTotalOnMargin.view ||
    otherExpensesFieldsPermissions.purchaseTotalOutOfMargin.view;

  const userCanSeeOtherExpensesSun =
    otherExpensesFieldsPermissions.sellerTotalOtherExpenses.view ||
    otherExpensesFieldsPermissions.sellerTotalOnMargin.view ||
    otherExpensesFieldsPermissions.sellerTotalOutOfMargin.view ||
    otherExpensesFieldsPermissions.sellerTotalForApportionment.view;

  return (
    <ResultsContainer>
      {userCan.userCanSeeResultsBlock && (
        <FormTitle className="results-title" name="Results" />
      )}

      {userCanSeeCot && (
        <div className="grid align-items-center">
          <span className="col-1 results-cot-item">COT:</span>

          {mainFieldsPermissions.totalCotPiShipment.view && (
            <ReadOnlyInput
              className="col-2"
              label="Total COT PI"
              value={totalState.financialTotal?.totalCotPiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
              loading={totalState.financialTotalLoading}
            />
          )}

          {mainFieldsPermissions.totalCotCiShipment.view && (
            <ReadOnlyInput
              className="col-2"
              label="Total COT CI"
              value={totalState.financialTotal?.totalCotCiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
              loading={totalState.financialTotalLoading}
            />
          )}
        </div>
      )}

      {userCanSeeSun && (
        <div className="grid align-items-center">
          <span className="col-1 results-sun-item">SUN:</span>
          {mainFieldsPermissions.totalSunPiShipment.view && (
            <ReadOnlyInput
              className="col-2"
              label="Total SUN PI"
              value={totalState.financialTotal?.totalSunPiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
              loading={totalState.financialTotalLoading}
            />
          )}

          {mainFieldsPermissions.totalSunCiShipment.view && (
            <ReadOnlyInput
              className="col-2"
              label="Total SUN CI"
              value={totalState.financialTotal?.totalSunCiShipment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              prefix={satCurrencySymbol}
              loading={totalState.financialTotalLoading}
            />
          )}

          {mainFieldsPermissions.totalWithApportionment.view && (
            <ReadOnlyInput
              className="col-4"
              label="Total With Apportionment"
              value={totalState.sellerTotalWithApportionment}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={4}
              prefix={satCurrencySymbol}
              loading={
                totalState.financialTotalLoading ||
                totalState.sellerOtherExpensesTotalLoading
              }
            />
          )}
        </div>
      )}

      {userCan.userCanSeeResultsConvertedBlock && (
        <FormTitle name="Converted" />
      )}

      {userCanSeeConvertedCot && (
        <div className="grid align-items-center">
          <span className="col-1 results-cot-item">COT:</span>

          {convertedFieldsPermissions.totalConvertedCotPi.view && (
            <ReadOnlyInput
              className="col-4"
              label="Total Converted COT PI (USD)"
              prefix={usdSymbol}
              value={totalState.financialTotal?.totalCotPiShipmentInDollar}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              loading={totalState.financialTotalLoading}
            />
          )}

          {convertedFieldsPermissions.totalConvertedCotCi.view && (
            <ReadOnlyInput
              className="col-4"
              label="Total Converted COT CI (USD)"
              prefix={usdSymbol}
              value={totalState.financialTotal?.totalCotCiShipmentInDollar}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              loading={totalState.financialTotalLoading}
            />
          )}
        </div>
      )}

      {userCanSeeConvertedSun && (
        <div className="grid align-items-center">
          <span className="col-1 results-sun-item">SUN:</span>
          {convertedFieldsPermissions.totalConvertedSunPi.view && (
            <ReadOnlyInput
              className="col-4"
              label="Total Converted SUN PI (USD)"
              prefix={usdSymbol}
              value={totalState.financialTotal?.totalSunPiShipmentInDollar}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
            />
          )}
          {convertedFieldsPermissions.totalConvertedSunCi.view && (
            <ReadOnlyInput
              className="col-3"
              label="Total Converted SUN CI (USD)"
              prefix={usdSymbol}
              value={totalState.financialTotal?.totalSunCiShipmentInDollar}
              type="number"
              locale="pt-BR"
              minimumFractionDigits={2}
              loading={totalState.financialTotalLoading}
            />
          )}
        </div>
      )}

      {userCan.userCanSeeResultsGrossBlock && <FormTitle name="Gross" />}

      <div className="grid">
        {grossFieldsPermissions.estimatedGrossMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Estimated Gross Margin"
            suffix="%"
            value={totalState.financialTotal?.estimatedGrossMargin}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            loading={totalState.financialTotalLoading}
          />
        )}

        {grossFieldsPermissions.grossMargin.view && (
          <ReadOnlyInput
            className="col-4"
            label="Gross Margin"
            suffix="%"
            value={totalState.financialTotal?.grossMargin}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            loading={totalState.financialTotalLoading}
          />
        )}
      </div>

      <div className="grid">
        {grossFieldsPermissions.estimatedGrossProfit.view && (
          <ReadOnlyInput
            className="col-3"
            label="Estimated Gross Profit"
            prefix={satCurrencySymbol}
            value={totalState.financialTotal?.estimatedGrossProfit}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            loading={totalState.financialTotalLoading}
          />
        )}

        {grossFieldsPermissions.grossProfit.view && (
          <ReadOnlyInput
            className="col-4"
            label="Gross Profit"
            prefix={satCurrencySymbol}
            value={totalState.financialTotal?.grossProfit}
            type="number"
            locale="pt-BR"
            minimumFractionDigits={2}
            loading={totalState.financialTotalLoading}
          />
        )}
      </div>

      {userCan.userCanSeeResultsOtherExpensesBlock && (
        <FormTitle name="Other Expenses" />
      )}

      <div className="grid align-items-center">
        {userCanSeeOtherExpensesCot && (
          <span className="col-1 results-cot-item">COT:</span>
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOtherExpenses.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Other Expenses"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.purchaseOtherExpensesTotal?.totalOtherExpenses}
            loading={totalState?.purchaseOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOnMargin.view && (
          <ReadOnlyInput
            className="col-2"
            label="Total On Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.purchaseOtherExpensesTotal?.totalOnMargin}
            loading={totalState?.purchaseOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.purchaseTotalOutOfMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Out Of Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.purchaseOtherExpensesTotal?.totalOutOfMargin}
            loading={totalState?.purchaseOtherExpensesTotalLoading}
          />
        )}
      </div>

      <div className="grid align-items-center">
        {userCanSeeOtherExpensesSun && (
          <span className="col-1 results-sun-item">SUN:</span>
        )}

        {otherExpensesFieldsPermissions.sellerTotalOtherExpenses.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Other Expenses"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.sellerOtherExpensesTotal?.totalOtherExpenses}
            loading={totalState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalOnMargin.view && (
          <ReadOnlyInput
            className="col-2"
            label="Total On Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.sellerOtherExpensesTotal?.totalOnMargin}
            loading={totalState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalOutOfMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total Out Of Margin"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState?.sellerOtherExpensesTotal?.totalOutOfMargin}
            loading={totalState?.sellerOtherExpensesTotalLoading}
          />
        )}

        {otherExpensesFieldsPermissions.sellerTotalForApportionment.view && (
          <ReadOnlyInput
            className="col-3"
            label="Total For Apportionment"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={4}
            minimumFractionDigits={4}
            value={totalState?.sellerOtherExpensesTotal?.totalForApportionment}
            loading={totalState?.sellerOtherExpensesTotalLoading}
          />
        )}
      </div>

      {userCan.userCanSeeResultsNetBlock && <FormTitle name="NET" />}

      <div className="grid">
        {netFieldsPermissions.estimatedNetMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="Estimated NET Margin"
            type="number"
            locale="pt-BR"
            suffix="%"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.estimatedNetMargin}
            loading={
              totalState.sellerOtherExpensesTotalLoading ||
              totalState.purchaseOtherExpensesTotalLoading ||
              totalState.financialTotalLoading
            }
          />
        )}

        {netFieldsPermissions.netMargin.view && (
          <ReadOnlyInput
            className="col-3"
            label="NET Margin"
            type="number"
            locale="pt-BR"
            suffix="%"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.netMargin}
            loading={
              totalState.sellerOtherExpensesTotalLoading ||
              totalState.purchaseOtherExpensesTotalLoading ||
              totalState.financialTotalLoading
            }
          />
        )}
      </div>

      <div className="grid">
        {netFieldsPermissions.estimatedNetProfit.view && (
          <ReadOnlyInput
            className="col-3"
            label="Estimated NET Profit"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.estimatedNetProfit}
            loading={
              totalState.sellerOtherExpensesTotalLoading ||
              totalState.purchaseOtherExpensesTotalLoading ||
              totalState.financialTotalLoading
            }
          />
        )}

        {netFieldsPermissions.netProfit.view && (
          <ReadOnlyInput
            className="col-3"
            label="NET Profit"
            type="number"
            locale="pt-BR"
            prefix={satCurrencySymbol}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            value={totalState.netProfit}
            loading={
              totalState.sellerOtherExpensesTotalLoading ||
              totalState.purchaseOtherExpensesTotalLoading ||
              totalState.financialTotalLoading
            }
          />
        )}
      </div>
    </ResultsContainer>
  );
};

export default Results;
