import styled from 'styled-components';

export const Container = styled.div`
  .formInput,
  .form-textarea-input,
  .form-dropdown {
    margin-bottom: 12px;
    width: 100%;
  }
`;
