import { gql } from '@apollo/client';

export const getDefaultProductPackageQuery = gql`
  query ListDefaultProductPackageByIdProduct($idProduct: Int!) {
    listDefaultProductPackageByIdProduct(idProduct: $idProduct) {
      idProductPackage
      packagePcQuantity
      isDefault
      packageUnit
    }
  }
`;
