import styled, { css } from 'styled-components';

interface IContainerProps {
  position?: 'left' | 'right' | 'center';
}

export const Container = styled.div<IContainerProps>`
  text-align: center;
  ${props =>
    props.position &&
    css`
      text-align: ${props.position};
    `}
  color: #00000040;
  font-size: 14px;
  margin: 32px 0;
`;
