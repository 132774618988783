/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { RadioButton } from 'primereact/radiobutton';
import { Container, CrudForm, Row } from './styles';
import BreadCrumb, { IBreadCrumbItem } from '../../../components/BreadCrumb';
import FormInput from '../../../components/FormInput';
import { IPortTax, IPortTaxFormData, IPortTaxRouteParams } from './interfaces';
import { useRefHook } from '../../../hooks/useRefHook';
import getValidationErrors from '../../../utils/getValidationErrors';
import PageHeader from '../../../components/PageHeader';
import MainButton from '../../../components/MainButton';
import Button from '../../../components/Button';
import client from '../../../services/apollo/client';
import FormAsyncSelect from '../../../components/FormAsyncSelect';
import FormInputNumber from '../../../components/FormInputNumber';
import changeStateProperty from '../../../utils/changeStateProperty';
import Loading from '../../../components/Loading';
import userHasPermission from '../../../utils/userHasPermission';
import { useAuth } from '../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../utils/getUserFieldsAndPermissionsByEntity';
import { portTaxRoles } from '../../../shared/roles/portTax';
import Tag from '../../../components/Tag';
import ToastLife from '../../../shared/enums/toastLife';
import { asyncSelectLoadCurrencies } from '../../../shared/querys/currency';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import useTitle from '../../../hooks/useTitle';
import { DomainGroup } from '../../../shared/enums/domainGroup';

const PortTax: React.FC = () => {
  // Redirect
  const history = useHistory();

  // ID de Port Tax
  const idPortTax = parseInt(useParams<IPortTaxRouteParams>().idPortTax, 10);

  const defaultPageTitle = `${idPortTax ? 'Manage' : 'Create'} Port Tax`;

  // Estado de Port Tax
  const [portTax, setPortTax] = useState<IPortTax>({} as IPortTax);

  useTitle(
    portTax?.portTaxName
      ? `${portTax.portTaxName} - ${defaultPageTitle}`
      : defaultPageTitle,
  );

  // Estado de loading
  const [loading, setLoading] = useState(false);

  // URL
  const { pathname } = useLocation();

  // URL params
  const location = useLocation();

  // Dados para retornar para listagem de produtos
  const initialPage = parseInt(
    new URLSearchParams(location.search).get('initialPage')!,
    10,
  );
  const initialFirst = parseInt(
    new URLSearchParams(location.search).get('initialFirst')!,
    10,
  );

  // Referencia ao toast e formulario
  const { toastRef, formRef } = useRefHook();

  // Busca roles do usuario
  const { roles } = useAuth();

  // Busca permissoes do usuario para a entity
  const userPermissionsOceanFreight = getUserFieldsAndPermissionsByEntity(
    roles.rolesUser,
    portTaxRoles.idEntity,
  );

  // Permissions de Port Tax
  const { idPermissionCreatePortTax, idPermissionUpdatePortTax } =
    portTaxRoles.permissions;

  // Valida se usuario pode criar ou atualizar Port Tax
  const userCanChange = userHasPermission(
    idPortTax ? idPermissionUpdatePortTax : idPermissionCreatePortTax,
    userPermissionsOceanFreight.userPermissions,
  );

  // Itens do breadCrumb
  const breadCrumbItems: IBreadCrumbItem[] = [
    {
      name: 'Port Taxes',
      path:
        initialPage && initialFirst
          ? `/logistics/portTaxes?initialPage=${initialPage}&initialFirst=${initialFirst}`
          : '/logistics/portTaxes',
    },
    {
      name: defaultPageTitle,
      path: pathname,
    },
  ];

  /**
   * Carrega Portos
   * @param search Valor escrito no input
   * @param prevOptions Opcoes carregadas anteriormente
   * @param pageData Dados da pagina
   * @returns Dados de Portos
   */
  const loadPorts: LoadOptions<any, GroupBase<any>, { page: any }> = async (
    search: string,
    prevOptions: any,
    pageData,
  ) => {
    const res = await client.query({
      query: gql`
        query listAllPortsQuery($listAllPortsInput: ListAllPortsInput!) {
          listAllPorts(listAllPortsInput: $listAllPortsInput) {
            data {
              idPort
              name
            }
            items
          }
        }
      `,
      variables: {
        listAllPortsInput: {
          _page: 1,
          _limit: 100,
          _orderBy: 'name',
          _sortOrder: 'DESC',
          name: search,
          type: 2,
        },
      },
    });
    // Opcoes filtradas
    const filteredOptions = res.data.listAllPorts.data;
    // Quantidade de itens
    const quantity = res.data.listAllPorts.items;
    // Valida se ha mais resultados para serem buscados
    const hasMore = quantity > prevOptions.length + 25;

    return {
      options: filteredOptions,
      hasMore,
      additional: {
        page: pageData?.page + 1,
      },
    };
  };

  // Query para listar port tax
  const listLogisticPortTaxQuery = gql`
    query listLogisticPortTaxById($idPortTax: Int!) {
      listLogisticPortTaxById(idPortTax: $idPortTax) {
        idPortTax
        portTaxName
        idPortDestination2 {
          idPort
          name
        }
        idCurArm2 {
          idCurrency
          currency
          abbreviation
        }
        taxValueArm
        idBcValueArm2 {
          idDomain
          description
        }
        bcCifArm
        idCurLev2 {
          idCurrency
          currency
          abbreviation
        }
        taxValueLev
        idBcValueLev2 {
          idDomain
          description
        }
        bcCifLev
        idCurPes2 {
          idCurrency
          currency
          abbreviation
        }
        taxValuePes
        idBcValuePes2 {
          idDomain
          description
        }
        bcCifPes
        idCurSca2 {
          idCurrency
          currency
          abbreviation
        }
        taxValueSca
        idBcValueSca2 {
          idDomain
          description
        }
        bcCifSca
        idCurPos2 {
          idCurrency
          currency
          abbreviation
        }
        taxValuePos
        idBcValuePos2 {
          idDomain
          description
        }
        bcCifPos
        createdBy2 {
          firstName
          lastName
        }
        createdAt
        updatedBy2 {
          firstName
          lastName
        }
        updatedAt
        versionLock
        active
      }
    }
  `;

  /**
   * Busca dados de Port Tax
   */
  const [loadPortTaxData, { loading: portTaxLoading }] = useLazyQuery(
    listLogisticPortTaxQuery,
    {
      variables: {
        idPortTax,
      },
      onCompleted: response => {
        if (response.listLogisticPortTaxById) {
          setPortTax(response.listLogisticPortTaxById);
        } else {
          toastRef.current?.show({
            severity: 'error',
            summary: 'Port Tax not found',
            life: ToastLife.ERROR,
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting port tax data',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    },
  );

  /**
   * Cancela operacao atual
   */
  function handleCancel() {
    confirmDialog({
      message: 'Are you sure you want to cancel?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => history.push('/logistics/portTaxes'),
    });
  }

  // Mutation de criacao de Port Tax
  const createPortTaxQuery = gql`
    mutation CreateLogisticPortTaxMutation($data: CreateLogisticPortTaxInput!) {
      createLogisticPortTax(data: $data) {
        idPortTax
      }
    }
  `;

  // Mutation de atualizacao de port tax
  const updatePortTaxQuery = gql`
    mutation UpdatePortTaxMutation($data: UpdateLogisticPortTaxInput!) {
      updateLogisticPortTax(data: $data) {
        idPortTax
        versionLock
      }
    }
  `;

  // cria método para chamar a mutation
  const [createPortTaxMutation] = useMutation(createPortTaxQuery);
  const [updatePortTaxMutation] = useMutation(updatePortTaxQuery);

  // Submit do formulario de port tax
  const handlePortTaxSubmit = useCallback(
    async (data: IPortTaxFormData) => {
      setLoading(true);

      // Efetua validação dos dados
      try {
        // Esvazia possíveis erros já existentes no formulário
        formRef.current?.setErrors({});

        // Define requisitos de preenchimento do formulario
        const schema = Yup.object().shape({
          portTaxName: Yup.string().required('Required Field'),
          idPortDestination: Yup.string().nullable().required('Required Field'),
          idCurArm: Yup.string().nullable().required('Required Field'),
          taxValueArm: Yup.string().nullable().required('Required Field'),
          idBcValueArm: Yup.string().required('Required Field'),
        });

        // Efetua validação
        await schema.validate(data, { abortEarly: false });

        // Valor de resposta da API
        let response;

        const apiValues = {
          portTaxName: data.portTaxName,
          idPortDestination: data.idPortDestination,
          idCurArm: data.idCurArm,
          taxValueArm: data.taxValueArm,
          idBcValueArm: data.idBcValueArm,
          bcCifArm: !!portTax.bcCifArm,
          idCurLev: data.idCurLev,
          taxValueLev: data.taxValueLev,
          bcCifLev: portTax.bcCifLev,
          idBcValueLev: data.idBcValueLev,
          idCurPes: data.idCurPes,
          taxValuePes: data.taxValuePes,
          idBcValuePes: data.idBcValuePes,
          bcCifPes: portTax.bcCifPes,
          idCurSca: data.idCurSca,
          taxValueSca: data.taxValueSca,
          idBcValueSca: data.idBcValueSca,
          idCurPos: data.idCurPos,
          bcCifSca: portTax.bcCifSca,
          taxValuePos: data.taxValuePos,
          idBcValuePos: data.idBcValuePos,
          bcCifPos: portTax.bcCifPos,
        };

        // Valida se esta editando ou criando um novo port tax
        if (!idPortTax) {
          // Chamada criacao de port tax
          response = await createPortTaxMutation({
            variables: {
              data: { ...apiValues },
            },
          });
        } else {
          // Chamada atualizacao de port tax
          response = await updatePortTaxMutation({
            variables: {
              data: {
                idPortTax,
                versionLock: portTax.versionLock,
                ...apiValues,
              },
            },
          });
        }

        // Em caso de sucesso exibe toast
        toastRef.current?.show({
          severity: 'success',
          summary: `Port Tax ${idPortTax ? 'updated' : 'created'}`,
          life: ToastLife.SUCCESS,
        });

        if (!idPortTax) {
          // Direciona usuario para listagem da port tax
          history.push(
            `/logistics/portTaxes/${response.data.createLogisticPortTax.idPortTax}`,
          );
        } else {
          setPortTax({} as IPortTax);
          loadPortTaxData();
        }
      } catch (error) {
        // Verifica se são erros de validação
        if (error instanceof Yup.ValidationError) {
          // Pega os erros de cada input
          const errors = getValidationErrors(error);

          // Define os erros para cada input
          formRef.current?.setErrors(errors);

          // Busca primeiro erro da lista
          const firstError = error.inner[0];

          // Busca ref do input com erro
          const inputWithError = formRef.current?.getFieldRef(firstError.path!);

          // Define foco no input
          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef.current?.focus) {
            inputWithError.inputRef.current.focus();
          }
        } else if (error instanceof Error) {
          // Exibe toast sobre o erro
          toastRef.current?.show({
            severity: 'error',
            summary: `Error while ${
              idPortTax ? 'updating' : 'creating'
            } port tax`,
            detail: error.message,
            life: ToastLife.ERROR,
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createPortTaxMutation,
      formRef,
      history,
      idPortTax,
      loadPortTaxData,
      portTax.bcCifArm,
      portTax.bcCifLev,
      portTax.bcCifPes,
      portTax.bcCifPos,
      portTax.bcCifSca,
      portTax.versionLock,
      toastRef,
      updatePortTaxMutation,
    ],
  );

  useEffect(() => {
    if (idPortTax) {
      loadPortTaxData();
    }
  }, [idPortTax, loadPortTaxData]);

  return (
    <Container>
      <BreadCrumb items={breadCrumbItems} />

      <PageHeader
        title={
          portTax?.idPortTax
            ? `${defaultPageTitle} - ${portTax.portTaxName}`
            : defaultPageTitle
        }
        minScrollStickyButtons={160}
      >
        {portTax.idPortTax ? (
          portTax.active ? (
            <Tag className="tag" value="Active" severity="success" />
          ) : (
            <Tag className="tag" value="Inactive" severity="danger" />
          )
        ) : undefined}
        <p className={idPortTax ? 'createdAt' : ''}>
          {portTax.idPortTax && !portTaxLoading
            ? `${new Date(portTax.createdAt).toLocaleString()} by ${
                portTax.createdBy2?.firstName
              } ${portTax.createdBy2?.lastName}`
            : ''}
        </p>
        <p className={idPortTax ? 'updatedAt' : ''}>
          {portTax && !portTaxLoading
            ? `${new Date(portTax.updatedAt).toLocaleString()} by ${
                portTax.updatedBy2?.firstName
              } ${portTax.updatedBy2?.lastName}`
            : ''}
        </p>

        {userCanChange && (
          <MainButton
            className="secondaryButton"
            label="Save"
            onClick={() => {
              formRef.current?.submitForm();
            }}
          />
        )}

        <Button
          className="secondaryButton"
          label="Cancel"
          onClick={() => {
            handleCancel();
          }}
        />
      </PageHeader>

      {/* Formulario */}
      {((idPortTax && portTax.idPortTax) || !idPortTax) && (
        <CrudForm>
          <Form
            ref={formRef}
            onSubmit={handlePortTaxSubmit}
            initialData={portTax && !portTaxLoading ? portTax : undefined}
          >
            <Row>
              <FormInput
                name="portTaxName"
                label="Name"
                required
                className="form-input"
                readOnly={!userCanChange}
              />

              <FormAsyncSelect
                name="idPortDestination"
                label="Port of Destination"
                required
                className="form-input"
                loadOptions={loadPorts}
                debounceTimeout={1000}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.idPort}
                additional={{
                  page: 1,
                }}
                noOptionsMessage={() => 'No ports found'}
                initialValue={portTax?.idPortDestination2}
                readOnly={!userCanChange}
              />
            </Row>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueArm"
                  label="Armazenagem Porto (10 dias)"
                  required
                  max={100}
                  min={0}
                  suffix="%"
                  decimalScale={3}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurArm"
                  label="Currency"
                  className="async-input"
                  required
                  loadOptions={asyncSelectLoadCurrencies}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={portTax.idCurArm2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueArm"
                  className="async-input"
                  label="BC Value"
                  required
                  loadOptions={asyncSelectLoadDomains}
                  getOptionLabel={option => option.description}
                  getOptionValue={option => option.idDomain}
                  additional={{
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={portTax.idBcValueArm2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCifArm"
                      value
                      checked={portTax.bcCifArm === true}
                      onChange={() =>
                        changeStateProperty('bcCifArm', true, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCif"
                      value={false}
                      checked={portTax.bcCifArm !== true}
                      onChange={() =>
                        changeStateProperty('bcCifArm', false, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">
                Taxa cobrada pela armazenagem da mercadoria no porto (10 dias)
              </p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueLev"
                  label="Levante / CTNR"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurLev"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={portTax.idCurLev2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueLev"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={portTax.idBcValueLev2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCifLev"
                      value
                      checked={portTax.bcCifLev === true}
                      onChange={() =>
                        changeStateProperty('bcCifLev', true, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCif"
                      value={false}
                      checked={portTax.bcCifLev === false}
                      onChange={() =>
                        changeStateProperty('bcCifLev', false, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Taxa para movimentação de container</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValuePes"
                  label="Pesagem / CTNR"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurPes"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={portTax.idCurPes2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValuePes"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={portTax.idBcValuePes2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCifPes"
                      value
                      checked={portTax.bcCifPes === true}
                      onChange={() =>
                        changeStateProperty('bcCifPes', true, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCifPes"
                      value={false}
                      checked={portTax.bcCifPes === false}
                      onChange={() =>
                        changeStateProperty('bcCifPes', false, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Taxa para pesagem de container</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValueSca"
                  label="Scanner / CTNR"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurSca"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={portTax.idCurSca2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValueSca"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={portTax.idBcValueSca2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCifSca"
                      value
                      checked={portTax.bcCifSca === true}
                      onChange={() =>
                        changeStateProperty('bcCifSca', true, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCifSca"
                      value={false}
                      checked={portTax.bcCifSca === false}
                      onChange={() =>
                        changeStateProperty('bcCifSca', false, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">Taxa para escaneamento de container</p>
            </span>

            <span>
              <Row>
                <FormInputNumber
                  name="taxValuePos"
                  label="Posicionamento / CTNR"
                  min={0}
                  decimalScale={2}
                  readOnly={!userCanChange}
                  decimalSeparator=","
                />

                <FormAsyncSelect
                  name="idCurPos"
                  label="Currency"
                  className="async-input"
                  loadOptions={asyncSelectLoadCurrencies}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) =>
                    `${option.abbreviation} - ${option.currency}`
                  }
                  getOptionValue={(option: any) => option.idCurrency}
                  additional={{
                    page: 1,
                  }}
                  noOptionsMessage={() => 'No currencies found'}
                  initialValue={portTax.idCurPos2}
                  readOnly={!userCanChange}
                />

                <FormAsyncSelect
                  name="idBcValuePos"
                  className="async-input"
                  label="BC Value"
                  loadOptions={asyncSelectLoadDomains}
                  debounceTimeout={1000}
                  getOptionLabel={(option: any) => option.description}
                  getOptionValue={(option: any) => option.idDomain}
                  additional={{
                    page: 1,
                    id: DomainGroup.LOGISTICS_CALCULATION_BASIS,
                  }}
                  noOptionsMessage={() => 'No BC values found'}
                  initialValue={portTax.idBcValuePos2}
                  readOnly={!userCanChange}
                />

                <div className="radio-button-div generalInput">
                  <label>BC CIF:</label>
                  <div>
                    <RadioButton
                      inputId="yes"
                      name="oceanFreight.bcCifPos"
                      value
                      checked={portTax.bcCifPos === true}
                      onChange={() =>
                        changeStateProperty('bcCifPos', true, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>Yes</label>
                    <RadioButton
                      inputId="no"
                      name="oceanFreight.bcCifPos"
                      value={false}
                      checked={portTax.bcCifPos === false}
                      onChange={() =>
                        changeStateProperty('bcCifPos', false, setPortTax)
                      }
                      disabled={!userCanChange}
                    />
                    <label>No</label>
                  </div>
                </div>
              </Row>
              <p className="row-label">
                Taxa para posicionamento de container no porto
              </p>
            </span>
          </Form>
        </CrudForm>
      )}
      {(portTaxLoading || loading) && <Loading />}
    </Container>
  );
};

export default PortTax;
