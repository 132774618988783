import React, { useRef } from 'react';
import { ButtonProps } from 'primereact/button';
import { Container } from './styles';

type action = 'approve' | 'reject';

// Interface das propriedades do componente
interface CustomButtonProps extends ButtonProps {
  action?: action;
}

const Button: React.FC<CustomButtonProps> = ({ children, ...rest }) => {
  const buttonRef = useRef(null);
  return (
    <Container {...rest} ref={buttonRef}>
      {children}
    </Container>
  );
};

export default Button;
