/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: "error" */
import React, { Dispatch, SetStateAction } from 'react';

import { Container, Row } from './styles';
import FormCheckbox from '../../../../components/FormCheckbox';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import { ISupplier } from '../interfaces';
import FormInput from '../../../../components/FormInput';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { supplierRoles } from '../../../../shared/roles/supplier';
import getFieldPermission from '../../../../utils/getFieldPermission';

interface IGeneralInformationProps extends PageTabContainerProps {
  avatar?: File;
  supplier: ISupplier;
  setSupplier: Dispatch<SetStateAction<ISupplier>>;
  roleEntityFields: IRoleEntityField[];
}

const ArtworkFormat: React.FC<IGeneralInformationProps> = ({
  selected,
  supplier,
  roleEntityFields,
}) => {
  const {
    idFieldAi,
    idFieldCorel17,
    idFieldCorel20,
    idFieldEps,
    idFieldIndd,
    idFieldJpeg,
    idFieldOtherFormat,
    idFieldPdf,
    idFieldPsd,
    idFieldXls,
    idFieldColorCemik,
    idFieldColorOther,
    idFieldColorPantone,
    idFieldColorRgd,
  } = supplierRoles.fields;

  function showField(idField: number): boolean {
    return getFieldPermission(idField, roleEntityFields).view;
  }

  function disableField(idField: number): boolean {
    return supplier.idSupplier
      ? !getFieldPermission(idField, roleEntityFields).edit
      : !getFieldPermission(idField, roleEntityFields).create;
  }

  return (
    <PageTabContainer selected={selected}>
      <Container>
        <h1>Artwork Format</h1>

        <Row>
          <div>
            {showField(idFieldPdf) && (
              <FormCheckbox
                className="m-2"
                name="pdf"
                label="PDF"
                initialValue={supplier ? supplier.pdf : true}
                readOnly={disableField(idFieldPdf)}
              />
            )}
            {showField(idFieldAi) && (
              <FormCheckbox
                className="m-2"
                name="ai"
                label="AI"
                initialValue={supplier ? supplier.ai : true}
                readOnly={disableField(idFieldAi)}
              />
            )}
            {showField(idFieldCorel17) && (
              <FormCheckbox
                className="m-2"
                name="corel17"
                label="COREL 17"
                initialValue={supplier ? supplier.corel17 : true}
                readOnly={disableField(idFieldCorel17)}
              />
            )}
            {showField(idFieldCorel20) && (
              <FormCheckbox
                className="m-2"
                name="corel20"
                label="COREL 20"
                initialValue={supplier ? supplier.corel20 : true}
                readOnly={disableField(idFieldCorel20)}
              />
            )}
            {showField(idFieldJpeg) && (
              <FormCheckbox
                className="m-2"
                name="jpeg"
                label="JPEG"
                initialValue={supplier ? supplier.jpeg : true}
                readOnly={disableField(idFieldJpeg)}
              />
            )}
          </div>
          <div>
            {showField(idFieldEps) && (
              <FormCheckbox
                className="m-2"
                name="eps"
                label="EPS"
                initialValue={supplier ? supplier.eps : true}
                readOnly={disableField(idFieldEps)}
              />
            )}
            {showField(idFieldPsd) && (
              <FormCheckbox
                className="m-2"
                name="psd"
                label="PSD"
                initialValue={supplier ? supplier.psd : true}
                readOnly={disableField(idFieldPsd)}
              />
            )}
            {showField(idFieldIndd) && (
              <FormCheckbox
                className="m-2"
                name="indd"
                label="INDD"
                initialValue={supplier ? supplier.indd : true}
                readOnly={disableField(idFieldIndd)}
              />
            )}
            {showField(idFieldXls) && (
              <FormCheckbox
                className="m-2"
                name="xls"
                label="XLS"
                initialValue={supplier ? supplier.xls : true}
                readOnly={disableField(idFieldXls)}
              />
            )}
          </div>
        </Row>
        <Row>
          {showField(idFieldOtherFormat) && (
            <FormInput
              className="generalInput"
              name="otherFormat"
              label="Other Format"
              readOnly={disableField(idFieldOtherFormat)}
            />
          )}
        </Row>

        <Row>
          <div>
            <h1>Color Calibration</h1>
            {showField(idFieldColorPantone) && (
              <FormCheckbox
                className="m-2"
                name="colorPantone"
                label="PANTONE"
                initialValue={supplier ? supplier.colorPantone : true}
                readOnly={disableField(idFieldColorPantone)}
              />
            )}
            {showField(idFieldColorCemik) && (
              <FormCheckbox
                className="m-2"
                name="colorCemik"
                label="CMYK"
                initialValue={supplier ? supplier.colorCemik : true}
                readOnly={disableField(idFieldColorCemik)}
              />
            )}
            {showField(idFieldColorRgd) && (
              <FormCheckbox
                className="m-2"
                name="colorRgd"
                label="RGB"
                initialValue={supplier ? supplier.colorRgd : true}
                readOnly={disableField(idFieldColorRgd)}
              />
            )}
          </div>
        </Row>
        <Row>
          {showField(idFieldColorOther) && (
            <FormInput
              className="generalInput"
              name="colorOther"
              label="Other Color"
              readOnly={disableField(idFieldColorOther)}
            />
          )}
        </Row>
      </Container>
    </PageTabContainer>
  );
};

export default ArtworkFormat;
