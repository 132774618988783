import dayjs from 'dayjs';
import React from 'react';
import FormTitle from '../../../../../../components/FormTitle';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import {
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';

interface ITimingProps {
  foreignTrade: SatForeignTrade;
  confirmOrderComercial?: Date;
  fieldsPermissions: ForeignTradeFieldsPermissions;
}

const Timing: React.FC<ITimingProps> = ({
  foreignTrade,
  confirmOrderComercial,
  fieldsPermissions,
}) => {
  function getDifferenceBetweenDates(
    date1?: Date | string,
    date2?: Date | string,
  ) {
    if (!date1 || !date2) return '';

    const difference = dayjs(date1)
      .startOf('day')
      .diff(dayjs(date2).startOf('day'), 'day');

    return difference;
  }

  return (
    <div className="form-group grid">
      <FormTitle className="col-12" name="Timing" />

      {fieldsPermissions.startProductionTiming.view && (
        <ReadOnlyInput className="col-3" label="Start Production Timing" />
      )}

      {fieldsPermissions.realProductionTime.view && (
        <ReadOnlyInput className="col-3" label="Real Production Time" />
      )}

      {fieldsPermissions.authorizationBookingTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Authorization X Booking Timing"
          value={foreignTrade.authorizationBookingTiming}
        />
      )}

      {fieldsPermissions.authorizationShippingTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Authorization X Shipment Timing"
          value={foreignTrade.authorizationShippingTiming}
        />
      )}

      {fieldsPermissions.bookingShippingTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Booking X Shipment Timing"
          value={foreignTrade.bookingShippingTiming}
        />
      )}

      {fieldsPermissions.preShipmentTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Pre Shipment Timing"
          value={getDifferenceBetweenDates(
            foreignTrade.realEtd,
            confirmOrderComercial,
          )}
        />
      )}

      {fieldsPermissions.transitTime.view && (
        <ReadOnlyInput
          className="col-3"
          label="Transit Time"
          value={foreignTrade.transitTime}
        />
      )}

      {fieldsPermissions.hblFinalTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="HBL Final Timing"
          value={foreignTrade.hblFinalTiming}
        />
      )}

      {fieldsPermissions.originalDocsTiming.view && (
        <ReadOnlyInput className="col-3" label="Original Docs Timing" />
      )}

      {fieldsPermissions.deliveryAtClientTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Delivery At Client Timing"
          value={foreignTrade.deliveryAtClientTiming}
        />
      )}

      {fieldsPermissions.totalForeignTradeTiming.view && (
        <ReadOnlyInput
          className="col-3"
          label="Total Foreign Trade Timing"
          value={getDifferenceBetweenDates(
            foreignTrade.realArrivalAtClient,
            confirmOrderComercial,
          )}
        />
      )}
    </div>
  );
};

export default Timing;
