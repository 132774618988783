import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';

interface IColumnData {
  [key: string]: ColumnData;
}

export const gridColumnsData: IColumnData = {
  portLoading: {
    field: 'idPortLoading2.name',
    header: 'Port of Loading',
    advancedFilterField: 'portLoading',
  },
  portDestination: {
    field: 'idPortDestination2.name',
    header: 'Port of Destination',
    advancedFilterField: 'portDestination',
  },
  valor20Gp: {
    field: 'valor20Gp',
    header: 'Value 20’ GP',
    advancedFilterField: 'valor20Gp',
  },
  curValor20Gp: {
    field: 'idCurValor20Gp2.abbreviation',
    header: 'Currency 20’ GP',
    advancedFilterField: 'curValor20Gp',
  },
  valor40Hc: {
    field: 'valor40Hc',
    header: 'Value 40’ HC',
    advancedFilterField: 'valor40Hc',
  },
  curValor40Hc: {
    field: 'idCurValor40Hc2.abbreviation',
    header: 'Currency 40’ HC',
    advancedFilterField: 'curValor40Hc',
  },
  bcValor: {
    field: 'idBcValor2.description',
    header: 'BC  Value',
    advancedFilterField: 'bcValor',
  },
  bcCif: {
    field: 'bcCif',
    header: 'BC CIF',
    advancedFilterField: 'bcCif',
    type: AdvancedFiltersFieldType.BOOLEAN_CLEARABLE,
  },
  updatedAt: {
    field: 'updatedAt',
    header: 'Last Updated',
    advancedFilterField: '',
  },
};
