/* eslint-disable no-shadow */
import { OverlayPanel, OverlayPanelProps } from 'primereact/overlaypanel';
import { Ref, RefObject } from 'react';

export enum AdvancedFiltersPanelReducerActionKind {
  FILTER_FIELDS,
  CHANGE_FIELD_VALUE,
  SAVE_PANEL_STATE,
  RESTORE_PANEL_STATE,
  CLEAR_FIELDS,
}

export enum AdvancedFiltersFieldType {
  BOOLEAN,
  BOOLEAN_CLEARABLE,
  DATE = 'date',
  NUMBER = 'number',
  DROPDOWN = 'dropdown',
  ASYNC_SELECT = 'asyncSelect',
  DATE_RANGE = 'dateRange',
  TREE_SELECT = 'treeSelect',
  CATEGORY_TREE_SELECT = 'categoryTreeSelect',
}

export interface AdvancedFiltersPanelReducerAction {
  type: AdvancedFiltersPanelReducerActionKind;
  payload?: {
    filter?: string;
    field?: IAdvancedFiltersField;
    fields?: IAdvancedFiltersField[];
  };
}

/**
 * Interface de Field
 */
export interface IAdvancedFiltersField {
  advancedFilterField: string;
  header: string;
  field?: string;
  value?: any;
  type?: AdvancedFiltersFieldType;
  options?: any;
  asyncSelectOptions?: {
    optionLabel?: any;
    optionValue?: any;
    optionData?: any;
    isMulti?: boolean;
    domainGroup?: any;
    additional?: any;
  };
}

export interface IAdvancedFiltersPanelRef {
  getFieldsValues(): any;
}

/**
 * Interface do componente de Advanced Filters Panel
 */
export interface AdvancedFiltersPanelProps extends OverlayPanelProps {
  ref?: Ref<IAdvancedFiltersPanelRef>;
  innerRef?: RefObject<OverlayPanel>;
  fields: IAdvancedFiltersField[];
  appliedFiltersOnly?: boolean;
  advancedFiltersName?: string;
  onCancel?(object: any): void;
  onClear?(): void;
  onApply?(object: any): void;
}

/**
 * Estrutura do reducer de Advanced Filters Panel
 */
export interface IAdvancedFiltersPanelReducer {
  fields: IAdvancedFiltersField[];
  fieldsSearch?: string;
  filteredFields: IAdvancedFiltersField[];
  panelStateOnShow?: {
    fields: IAdvancedFiltersField[];
    fieldsSearch?: string;
    filteredFields: IAdvancedFiltersField[];
  };
}
