import { gql } from '@apollo/client';

export const listProductQuery = gql`
  query listProductQuery($idProduct: Int!) {
    listProductById(idProduct: $idProduct) {
      idCategory2 {
        idCategory
        name
        idCheckList
        indConfection
      }
      licensedProduct
      stock
      versionLock
      idStatus
      idProduct
      idimage
      model
      imageUrl
      sku
      netWeight
      isM2
      indLicensedProduct
      idCategory
      nameEn
      namePt
      materialEn
      materialPt
      colourEn
      colourPt
      extraInfoPt
      descriptionEn
      descriptionPt
      stCode
      unitsPerContainer
      freightValue
      insurancePer
      thc
      iiPer
      ipiPer
      pisPer
      cofinsPer
      siscomex
      dumpingDutyValue
      icmsPer
      blRelease
      blDeconsolidation
      dropFee
      isps
      trs
      containerLifting
      internalFreight
      dispatcherFees
      consultingNdiPer
      freightCurrency
      foreignTradeReview
      hsCode
      registerNumber
      validityDate
      dumpingNote
      dumpingDutyCurrency
      outdated
      taxmissing
      verified
      extraInfoEn
      detailedDescriptionPt
      ownerLicenesed
      royaltiesLicenesed
      exclusivity
      m2Pc
      idClient2 {
        name
        idClient
      }
      idFinishing2 {
        idFinishing
        name
        namePt
      }
      productClients {
        idClient2 {
          name
          idClient
        }
      }
      productDimensions {
        idProductDimension
        namePt
        nameEn
        length
        lengthUnit
        width
        widthUnit
        height
        volume
        heightUnit
        volumeUnit
        capacity
        capacityUnit
        diameterTop
        diameterTopUnit
        diameterBottom
        diameterBottomUnit
        thickness
        thicknessUnit
        depth
        depthUnit
        grammage
        grammageUnit
        quantity
        inmetroLength
        inmetroLengthUnit
        inmetroWidth
        inmetroWidthUnit
        inmetroHeight
        inmetroHeightUnit
        inmetroVolume
        inmetroVolumeUnit
        inmetroCapacity
        inmetroCapacityUnit
        inmetroDiameterTop
        inmetroDiameterTopUnit
        inmetroDiameterBottom
        inmetroDiameterBottomUnit
        inmetroThickness
        inmetroThicknessUnit
        inmetroDepth
        inmetroDepthUnit
        inmetroGrammage
        inmetroGrammageUnit
      }
      productPackages {
        idProductPackage
        packageCostPrice
        isDefault
        packageUnit
        packageUnit2 {
          idDomain
          description
        }
        idTypePackage
        idTypePackage2 {
          idDomain
          description
        }
        packagePcQuantity
        packageLength
        packageWidth
        packageHeight
        packageGrossWeight
        innerBox
        innerLength
        innerWidth
        innerHeight
        innerGrossWeight
        isInnerDisplay
        innerQuantity
        masterQuantity
        masterLength
        masterWidth
        masterHeight
        masterGrossWeight
        masterNetWeight
        masterCbm
        palletQuantity
        palletLength
        palletWidth
        palletHeight
        palletGrossWeight
        palletDisplay
        packageNote
        productPackageDescriptions {
          idPackageDescription2 {
            idDomain
            description
          }
          idResponsible2 {
            idDomain
            description
          }
          comments
        }
        productPackageBarcodes {
          idProductPackageBarcode
          packageEan13
          innerDun14
          masterDun14
          auto
          idClient2 {
            idClient
            name
          }
        }
        productPackageReferences {
          idPackageReference
          colourEn
          colourPt
          idGender
          idGender2 {
            idDomain
            description
          }
          idSizeGroup
          idSizeGroup2 {
            idDomainGroup
            description
          }
          masterBarcode
          productPackageSizeBarcodes {
            innerBox
            size
            size2 {
              idDomain
              description
            }
            unit
            unit2 {
              idDomain
              description
            }
            reference
            sizeRange
            sellingPackage
            unitInnerBox
            masterBox
            unitMasterBox
            auto
            autoMasterBox
            autoInnerBox
            idPackageSizeBarcode
          }
        }
      }
      productSuppliers {
        idProductSupplier
        idSupplier
        idSupplier2 {
          idSupplier
          sunNumber
          name
        }
        idIncoterm
        idIncoterm2 {
          idDomain
          description
        }
        idPurchaseCurrency2 {
          idCurrency
          currency
          abbreviation
        }
        cotPrice
        cotPriceNotes
        dtAging
        markup
        idSalesIncoterm
        idSalesIncoterm2 {
          idDomain
          description
        }
        idSalesCurrency
        idSalesCurrency2 {
          idCurrency
          currency
          abbreviation
        }
        sunPrice
        sunPriceNotes
        profit
        moq
        moqNote
        isDefault
        cotPriceSqm
        sunPriceSqm
        updatedAt
        createdAt
      }
      unit2 {
        idDomain
        description
      }
      productNcms {
        idProductNcm
        idClient2 {
          idClient
          name
        }
        idNcm2 {
          idNcm
          ncmName
          ncm
          destaque
          li
          nve1
          nve2
          nve3
          nve4
          nve5
          nve6
          dumping
          baseDumping
          baseDumping2 {
            description
          }
          priceMin
        }
        foreignTradeReview
      }
      idCertifyingEntity2 {
        idPartner
        name
        ptnNumber
      }
      idLicensor2 {
        idPartner
        name
        ptnNumber
      }
      indConfection
      reference
      createdAt
      updatedAt
      createdBy2 {
        firstName
        lastName
      }
      updatedBy2 {
        firstName
        lastName
      }
      productArtworkTechniques {
        idProductArtwTech
        namePcEn
        namePcPt
        indAopArtw
        codeAopArtw
        indLocPrintArtw
        codeLocPrintArtw
        indEmbPatArtw
        codEmbPatArtw
        indYarnDyeTech
        indRotPrintTech
        indSilkScreenTech
        indJacquardTech
        indSublimationTech
        indEmbroideryTech
        indSolidColorTech
        indOthersTech
        dsOthersTech
      }
    }
  }
`;

export const createProductQuery = gql`
  mutation CreateProductMutation($product: CreateProductInput) {
    createProduct(product: $product) {
      idProduct
    }
  }
`;

export const updateProductQuery = gql`
  mutation UpdateProductMutation($product: UpdateProductInput!) {
    updateProduct(product: $product) {
      idProduct
      versionLock
    }
  }
`;

export const duplicateProductQuery = gql`
  mutation DuplicateProduct($idProduct: Int!) {
    duplicateProduct(idProduct: $idProduct) {
      idProduct
      stCode
    }
  }
`;

export const createCheckListBulkQuery = gql`
  mutation createProductCheckListBulkMutation($uploadedFile: String!) {
    createProductCheckListBulk(uploadedFile: $uploadedFile) {
      productCheckListNamesTotal
      createdProductCheckListNames
      updatedProductCheckListNames
      productCheckListNamesWithError
      productCheckListWithError
    }
  }
`;

export const getAttachmentDownloadLinkQuery = gql`
  query getAttachmentDownloadLinkQuery($name: String!) {
    getAttachmentDownloadLink(name: $name)
  }
`;
