import { gql } from '@apollo/client';

/**
 * Query para baixar SAT Foreign Trade Files
 */
export const downloadSatForeignTradeSupplierAttachmentsQuery = gql`
  query Query($idsSatAttachments: [Int]!) {
    downloadSatForeignTradeSupplierFiles(idsSatAttachments: $idsSatAttachments)
  }
`;
