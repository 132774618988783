import React from 'react';

import { Container } from './styles';

export interface PageTabContainerProps {
  selected: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const PageTabContainer: React.FC<PageTabContainerProps> = ({
  selected,
  children,
  className,
  style,
}) => {
  return (
    <Container className={className} selected={selected} style={style}>
      {children}
    </Container>
  );
};

export default PageTabContainer;
