/* eslint-disable no-shadow */
export enum ProblemType {
  SELLING_PACKAGE = 931,
  MASTER_BOX = 932,
  PRODUCT_QUALITY = 933,
  DOCUMENTS = 934,
  PAYMENT = 935,
  MISSING_QTY = 936,
  BROKEN_ITEMS = 937,
  OTHER = 938,
}
