import { AdvancedFiltersFieldType } from '../../../components/AdvancedFiltersPanel/interfaces';
import { ColumnData } from '../../../components/Grid/interfaces';
import { DomainGroup } from '../../../shared/enums/domainGroup';
import { asyncSelectLoadClients } from '../../../shared/querys/client';
import { asyncSelectLoadCountries } from '../../../shared/querys/country';
import { asyncSelectLoadDomains } from '../../../shared/querys/domain';
import { asyncSelectLoadSatNumbers } from '../../../shared/querys/sat';
import { asyncSelectLoadSatForeignTrades } from '../../../shared/querys/satForeignTrade';
import { asyncSelectLoadRncNumbers } from '../../../shared/querys/satRnc';
import { asyncSelectLoadSuppliers } from '../../../shared/querys/supplier';

export const rncFiltersData: ColumnData[] = [
  {
    field: 'rncStatus',
    header: 'RNC Status',
    value: undefined,
    advancedFilterField: 'idStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'rncNumber',
    header: 'RNC Number',
    value: undefined,
    advancedFilterField: 'idSatRnc',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'rncNumber',
      optionValue: 'idSatRnc',
      optionData: asyncSelectLoadRncNumbers,
      isMulti: true,
    },
  },
  {
    field: 'satNumber',
    header: 'SAT Number',
    advancedFilterField: 'idSat',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satNumber',
      optionValue: 'idSat',
      optionData: asyncSelectLoadSatNumbers,
      isMulti: true,
    },
  },
  {
    field: 'shipment',
    header: 'SAT Shipment',
    advancedFilterField: 'idSatForeignTrade',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'satForeignTradeNumber',
      optionValue: 'idSatForeignTrade',
      optionData: asyncSelectLoadSatForeignTrades,
      isMulti: true,
    },
  },
  {
    field: 'mainProduct',
    header: 'Main Product',
    advancedFilterField: 'mainProduct',
  },
  {
    field: 'supplier',
    header: 'SUN Number',
    value: undefined,
    advancedFilterField: 'idSupplier',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'sunNumber',
      optionValue: 'idSupplier',
      optionData: asyncSelectLoadSuppliers,
      isMulti: true,
    },
  },
  {
    field: 'importerConsignee',
    header: 'Importer/Consignee',
    advancedFilterField: 'idImporter',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'clientNotify',
    header: 'Client/Notify',
    advancedFilterField: 'idClient',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idClient',
      optionData: asyncSelectLoadClients,
      isMulti: true,
    },
  },
  {
    field: 'contactAtClient',
    header: 'Contact At Client',
    advancedFilterField: 'contactAtClient',
  },
  {
    field: 'priority',
    header: 'Priority',
    value: undefined,
    advancedFilterField: 'idPriority',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_PRIORITY,
    },
  },
  {
    field: 'waitingFor',
    header: 'Waiting For',
    value: undefined,
    advancedFilterField: 'idWaitingFor',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },
  {
    field: 'problemType',
    header: 'Problem Type',
    value: undefined,
    advancedFilterField: 'idProblemType',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_PROBLEM_TYPE,
    },
  },
  {
    field: 'rncResponsible',
    header: 'RNC Responsible',
    value: undefined,
    advancedFilterField: 'idRncResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },
  {
    field: 'coResponsible',
    header: 'CO-Responsible',
    value: undefined,
    advancedFilterField: 'coResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'description',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_RESPONSIBLE_WAITING_FOR,
    },
  },
  {
    field: 'cost',
    header: 'RNC Cost',
    value: undefined,
    advancedFilterField: 'idCost',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_COST,
    },
  },
  {
    field: 'costResponsible',
    header: 'RNC Cost Responsible',
    value: undefined,
    advancedFilterField: 'idCostResponsible',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_COST_RESPONSIBLE,
    },
  },
  {
    field: 'reWork',
    header: 'Rework',
    value: undefined,
    advancedFilterField: 'idReWork',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.CAMPO_YES_NO,
    },
  },
  {
    field: 'clientDebitNoteNumber',
    header: 'Client Debit Note Number',
    advancedFilterField: 'clientDebitNoteNumber',
  },
  {
    field: 'clientDebitNoteStatus',
    header: 'Client Debit Note Status',
    value: undefined,
    advancedFilterField: 'idClientDebitNoteStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.RNC_CLIENT_DEBIT_NOTE_STATUS,
    },
  },
  {
    field: 'clientNf',
    header: 'Client NF',
    advancedFilterField: 'clientNf',
  },
  {
    field: 'realEtd',
    header: 'Real ETD',
    advancedFilterField: 'realEtd',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'countryOrigin',
    header: 'Country of Origin',
    value: undefined,
    advancedFilterField: 'idCountryOrigin',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'name',
      optionValue: 'idCountry',
      optionData: asyncSelectLoadCountries,
      isMulti: true,
    },
  },
  {
    field: 'importStatus',
    header: 'Import Status',
    value: undefined,
    advancedFilterField: 'idImportStatus',
    type: AdvancedFiltersFieldType.ASYNC_SELECT,
    asyncSelectOptions: {
      optionLabel: 'description',
      optionValue: 'idDomain',
      optionData: asyncSelectLoadDomains,
      isMulti: true,
      domainGroup: DomainGroup.FOREIGN_TRADE_STATUS,
      additional: {
        orderByDescription: true,
      },
    },
  },
  {
    field: 'openingDate',
    header: 'Opening Date',
    value: undefined,
    advancedFilterField: 'openingDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'finishingDate',
    header: 'Finishing Date',
    value: undefined,
    advancedFilterField: 'finishingDate',
    type: AdvancedFiltersFieldType.DATE_RANGE,
  },
  {
    field: 'category',
    header: 'Category',
    value: undefined,
    advancedFilterField: 'category',
    type: AdvancedFiltersFieldType.CATEGORY_TREE_SELECT,
  },
];
