import React from 'react';

import { Container } from './styles';

// Props do componente de Titulo do Formulario
interface IFormTitleProps {
  name: string;
  className?: string;
  noGap?: boolean;
}

// Componente de titulo do formulario
const FormTitle: React.FC<IFormTitleProps> = ({
  name,
  className,
  children,
  noGap,
}) => {
  return (
    <Container
      className={className ? `form-title ${className}` : 'form-title'}
      noGap={noGap}
    >
      <h1>{name}</h1>
      {children}
    </Container>
  );
};

export default FormTitle;
