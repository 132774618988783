import { gql } from '@apollo/client';

export const getSumDatFromFinancialQuery = gql`
  query GetSumDataFromSatForeignTradeSuppliers($idSat: Int!) {
    getSumDataFromSatForeignTradeSuppliers(idSat: $idSat) {
      totalCotPiShipment
      totalCotPiShipmentInDollar
      totalCotCiShipment
      totalCotCiShipmentInDollar
      totalSunPiShipment
      totalSunPiShipmentInDollar
      totalSunCiShipment
      totalSunCiShipmentInDollar
      estimatedGrossMargin
      grossMargin
      estimatedGrossProfit
      grossProfit
    }
  }
`;
