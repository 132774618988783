/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { NavLink, useHistory } from 'react-router-dom';
import { FiChevronUp, FiChevronDown, FiSettings } from 'react-icons/fi';
import hotkeys from 'hotkeys-js';
import { cloneDeep } from 'lodash';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { userRoles } from '../../shared/roles/user';
import { roleRoles } from '../../shared/roles/role';

import {
  Container,
  SidebarItems,
  ItemsGroup,
  ItemsGroupHeader,
  ItemsGroupItems,
  AdminButton,
  AdminButtonContent,
} from './styles';

import { sidebarItems, ItemGroup, ISidebarItems } from './items';

import { useMenu } from '../../hooks/useMenu';
import { useAuth } from '../../hooks/useAuth';
import { userGroupRoles } from '../../shared/roles/userGroup';
import {
  financialIdModule,
  isFinancialModuleAllowed,
} from '../../shared/roles/financial';

/**
 * @returns Componente de sidebar
 */
const Sidebar: React.FC = () => {
  const { visible } = useMenu();

  // Funcao para decodificar token com roles
  const { roles, signOut, idUser } = useAuth();

  // Redirect
  const history = useHistory();

  // Valida se opcoes de administrador do sistema sao visiveis
  const [sysAdminButtonVisible] = useState(() => {
    // Valida se usuario esta com token contendo roles
    if (roles.modules === undefined) {
      signOut();
      history.push('/');
      return false;
    }
    // Valida se roles do usuario possuem modulo de administrador do sistema
    return roles.modules.includes(userRoles.idModule);
  });

  // Estado de itens filtrados
  const [filteredSidebarItems, setFilteredSidebarItems] =
    useState<ISidebarItems>();

  // Referencia do input de busca
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>(null);

  // Estado contendo itens isolados da sidebar
  const [sidebarItemsState, setSidebarItemsState] = useState(
    filteredSidebarItems?.items,
  );

  // Estado contendo grupo de itens da sidebar
  const [sidebarItemsGroup, setSidebarItemsGroup] = useState(
    filteredSidebarItems?.itemGroups,
  );

  // Estado contendo grupo de itens da sidebar
  const [adminButtonCollapse, setAdminButtonCollapse] = useState<boolean>(true);

  /**
   * Expandir ou ocultar grupo de itens
   * @param group Grupo de Itens
   * @param index índice do grupo de itens
   */
  function expandCollapseGroup(group: ItemGroup, index: number) {
    // grupo que deve ser alterado
    const currentGroup = group;

    // Demais grupos
    const newItemGroups = sidebarItemsGroup?.filter(
      itemGroup => itemGroup.label !== currentGroup.label,
    );

    // Altera o status do grupo clicado
    currentGroup.collapse = !currentGroup.collapse;

    // Adiciona o grupo clicado no array de grupos filtrados, na devida posição
    newItemGroups?.splice(index, 0, currentGroup);

    // define o estado com o grupo atualizado
    setSidebarItemsGroup(newItemGroups);
  }

  /**
   * Captura atalho do teclado
   */
  hotkeys('alt+m', function getField(event) {
    // Previne conportamento padrão do atalho
    event.preventDefault();

    // Valida existencia do input de busca
    if (inputRef.current) {
      // define foco
      inputRef.current.focus();
    }
  });

  /**
   * Faz a busca dos itens da sidebar
   */
  function handleSearch() {
    // Valida existencia do input de busca
    if (inputRef.current) {
      // Atribui valor para constante
      const searchInputValue = inputRef.current.value;

      // Copia valores dos itens
      const sidebarItemsClone = cloneDeep(filteredSidebarItems);

      // Filtra os itens individuais de acordo com o input
      const filteredItems = sidebarItemsClone?.items.filter(item =>
        item.label.toLowerCase().includes(searchInputValue.toLowerCase()),
      );

      // Define o estado de itens com os filtrados
      setSidebarItemsState(filteredItems);

      // Filtra os grupos de acordo com o input
      const filteredGroups = sidebarItemsClone?.itemGroups?.filter(group =>
        group.items.some(item =>
          item.label.toLowerCase().includes(searchInputValue.toLowerCase()),
        ),
      );

      // Para cada grupo filtrado, deixa apenas os itens de acordo com o input
      if (filteredGroups) {
        filteredGroups.forEach(group => {
          group.collapse = false;
          group.items = group.items.filter(item =>
            item.label.toLowerCase().includes(searchInputValue.toLowerCase()),
          );
        });
      }

      // Filtra os grupos pelo titulo de acordo com o input
      const filteredLabelGroups = sidebarItemsClone?.itemGroups?.filter(group =>
        group.label
          .toLocaleLowerCase()
          .includes(searchInputValue.toLowerCase()),
      );

      // Exibe apenas grupos se não tiverem itens que correspondem ao input
      const groupsOnly = filteredLabelGroups?.filter(el => {
        return !filteredGroups?.some(f => {
          return el.label === f.label;
        });
      });

      // Define o estado de grupos de acordo com os filtros
      setSidebarItemsGroup([...filteredGroups!, ...groupsOnly!]);
    }
  }

  /**
   * Exibe itens de acordo com modulos do usuario
   */
  useEffect(() => {
    // Copia valores dos itens
    const sidebarItemsClone = cloneDeep(sidebarItems);

    // Filtra os grupos de itens de acordo com os modulos
    const filteredItemsGroup = sidebarItemsClone.itemGroups?.filter(
      sidebarItemGroup => {
        if (sidebarItemGroup.idModule === financialIdModule) {
          return isFinancialModuleAllowed(idUser);
        }

        // Filtra os itens do grupo de acordo com as entities do usuario
        sidebarItemGroup.items = sidebarItemGroup.items.filter(item => {
          return roles.entities.includes(item.idEntity);
        });
        // Retorna modulos de acordo com os do usuario
        return roles.modules.includes(sidebarItemGroup.idModule);
      },
    );

    // Filtra itens individuais de acordo com modulos do usuario
    const filteredItems = sidebarItemsClone.items.filter(sidebarItem => {
      return roles.entities.includes(sidebarItem.idEntity);
    });

    // Define itens filtrados
    setFilteredSidebarItems({
      items: filteredItems,
      itemGroups: filteredItemsGroup,
    });
  }, [idUser, roles]);

  /**
   * Atualiza grupos e itens individuais de acordo com o filtro por modulo dos itens
   */
  useEffect(() => {
    if (filteredSidebarItems) {
      setSidebarItemsGroup(filteredSidebarItems.itemGroups);
      setSidebarItemsState(filteredSidebarItems.items);
    }
  }, [filteredSidebarItems]);

  return (
    <Container visible={visible} sysAdminButtonVisible={sysAdminButtonVisible}>
      {/* Busca */}
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          ref={inputRef}
          placeholder="Search menu (Alt+M)"
          onKeyUp={handleSearch}
        />
      </IconField>

      <SidebarItems>
        <nav>
          {/* Itens individuais */}
          {sidebarItemsState?.map(item => {
            return (
              <NavLink
                key={item.label}
                activeClassName="selected"
                to={item.path}
              >
                {item.Icon}
                <p>{item.label}</p>
              </NavLink>
            );
          })}

          {/* Grupo de Itens */}
          {sidebarItemsGroup?.map((group, index) => {
            return (
              <ItemsGroup key={group.label} className="noselect">
                <ItemsGroupHeader
                  iconBackground={group.iconBackground}
                  onClick={() => {
                    expandCollapseGroup(group, index);
                  }}
                >
                  <div>
                    <span>{group.Icon}</span>
                    <p>{group.label}</p>
                  </div>
                  {group.collapse ? (
                    <FiChevronDown size={15} />
                  ) : (
                    <FiChevronUp size={15} />
                  )}
                </ItemsGroupHeader>
                <ItemsGroupItems collapse={group.collapse}>
                  {group.items.map(item => {
                    return (
                      <NavLink
                        key={item.label}
                        activeClassName="selected"
                        to={item.path}
                      >
                        <p>{item.label}</p>
                      </NavLink>
                    );
                  })}
                </ItemsGroupItems>
              </ItemsGroup>
            );
          })}
        </nav>
      </SidebarItems>

      {sysAdminButtonVisible && (
        <AdminButton
          onClick={() => setAdminButtonCollapse(!adminButtonCollapse)}
          className="p-0"
        >
          <div className="adminButtonHeader">
            <div>
              <FiSettings className="settingsIcon" size={25} />
              <p>System Admin</p>
            </div>
            {adminButtonCollapse ? (
              <FiChevronDown className="chevronIcon" size={15} />
            ) : (
              <FiChevronUp className="chevronIcon" size={15} />
            )}
          </div>
          <AdminButtonContent collapse={adminButtonCollapse}>
            {roles.entities.includes(userRoles.idEntity) && (
              <NavLink activeClassName="selected" to="/users">
                <p>Users</p>
              </NavLink>
            )}
            {roles.entities.includes(userGroupRoles.idEntity) && (
              <NavLink activeClassName="selected" to="/userGroups">
                <p>User Groups</p>
              </NavLink>
            )}
            {roles.entities.includes(roleRoles.idEntity) && (
              <NavLink activeClassName="selected" to="/roles">
                <p>Roles</p>
              </NavLink>
            )}
          </AdminButtonContent>
        </AdminButton>
      )}
    </Container>
  );
};

export default Sidebar;
