// eslint-disable-next-line no-shadow
export enum RncDebitNoteStatus {
  WAITING_SAT_TO_APPLY = 988,
  REQUEST_DEBIT_NOTE = 989,
  WAITING_FINANCIAL_TO_CREATE = 990,
  DN_CREATED_WAITING_BALANCE = 991,
  DN_CREATED_APPLIED = 992,
  NOT_REQUIRED = 993,
  CANCELLED = 994,
}
