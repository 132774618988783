/* eslint-disable no-return-assign */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiExternalLink } from 'react-icons/fi';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Container } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import Button from '../../../../components/Button';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  deleteSupplierAttachmentsQuery,
  listSupplierAttachmentsByIdSupplierQuery,
} from './queries';
import AttachmentsUpload from './AttachmentsUpload';
import { ISupplierAttachment } from '../interfaces';

interface ISupplierAttachmentProps extends PageTabContainerProps {
  idSupplier: number;
  setPageLoading: Dispatch<SetStateAction<boolean>>;
}

const Attachment: React.FC<ISupplierAttachmentProps> = ({
  selected,
  idSupplier,
  setPageLoading,
}) => {
  // Referencia ao toast e formulario
  const { showError, showSuccess } = useRefHook();

  // Attachments selecionados
  const [selectedAttachments, setSelectedAttachments] = useState<
    ISupplierAttachment[]
  >([]);

  // Attachments de Supplier
  const [supplierAttachments, setSupplierAttachments] = useState<
    ISupplierAttachment[]
  >([]);

  const [displayModal, setDisplayModal] = useState(false);

  /**
   * Busca Supplier Attachments
   */
  const [loadSupplierAttachments, { loading: attachmentsLoading }] =
    useLazyQuery(listSupplierAttachmentsByIdSupplierQuery, {
      variables: {
        idSupplier,
      },
      onCompleted: response => {
        setSupplierAttachments(
          response.listSupplierAttachmentsByIdSupplier?.data,
        );
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Supplier Attachments',
          detail: errorData.message,
        });
      },
    });

  const [deleteSupplierAttachmentsMutation] = useMutation(
    deleteSupplierAttachmentsQuery,
  );

  const imageBodyTemplate = (rowData: ISupplierAttachment) => {
    return rowData.idAttachment2?.url ? (
      <a
        href={`${rowData.idAttachment2?.url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="attachment"
      >
        Open
        <FiExternalLink />
      </a>
    ) : (
      <p>Attachment not found in the bucket</p>
    );
  };

  /**
   * Deleta Supplier Attachments
   */
  async function handleDeleteSelected() {
    if (!selectedAttachments?.length) {
      showError({
        summary: 'You need to select at least one item do delete',
      });
      return;
    }

    setPageLoading(true);

    try {
      await deleteSupplierAttachmentsMutation({
        variables: {
          supplierAttachmentIds: selectedAttachments.map(
            attachment => attachment.idSupplierAttachment,
          ),
        },
      });

      showSuccess({
        summary: 'Attachments deleted',
      });

      loadSupplierAttachments();
    } catch (error) {
      showError({
        summary: 'Error while deleting attachments',
        detail: error.message,
      });
    } finally {
      setPageLoading(false);
    }
  }

  const showUploadModal = () => {
    setDisplayModal(true);
  };

  const gridHeader = (
    <div className="buttons-header">
      <Button label="Add Attachment" onClick={showUploadModal} />
      <Button
        label="Delete selected"
        severity="danger"
        onClick={handleDeleteSelected}
        disabled={!selectedAttachments.length}
      />
    </div>
  );

  useEffect(() => {
    if (selected && !supplierAttachments.length) {
      loadSupplierAttachments();
    }
  }, [supplierAttachments.length, loadSupplierAttachments, selected]);

  return (
    <PageTabContainer selected={selected}>
      <AttachmentsUpload
        idSupplier={idSupplier}
        supplierAttachmentsRefetch={loadSupplierAttachments}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
      />
      <Container>
        <DataTable
          value={supplierAttachments}
          header={gridHeader}
          selection={selectedAttachments}
          onSelectionChange={e => setSelectedAttachments(e.value)}
          emptyMessage="No attachments found"
          selectionMode="checkbox"
          loading={attachmentsLoading}
          removableSort
        >
          <Column
            selectionMode="multiple"
            style={{ width: '3em' }}
            reorderable={false}
          />
          <Column
            field="idAttachment2.nameGivenByUser"
            header="Name"
            sortable
          />
          <Column
            field="idAttachment2.url"
            header="Attachment"
            body={imageBodyTemplate}
          />
          <Column
            field="createdBy2.firstName"
            header="Created By"
            body={e => `${e.createdBy2?.firstName} ${e.createdBy2?.lastName}`}
            sortable
          />
          <Column
            field="createdAt"
            header="Created At"
            body={e =>
              e.createdAt ? new Date(e.createdAt).toLocaleString() : ''
            }
            sortable
          />
        </DataTable>
      </Container>
    </PageTabContainer>
  );
};

export default Attachment;
