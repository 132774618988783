import styled from 'styled-components';

interface DotProps {
  value: boolean;
}

interface ConainerProps {
  disabled: boolean;
}

export const Container = styled.div<ConainerProps>`
  background: var(--grey);
  padding: 4px;
  height: 46px;
  border: solid 1px transparent;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.2s;

  span {
    display: flex;
  }

  p {
    color: var(--grey-scale);
  }
`;

export const Dot = styled.div<DotProps>`
  height: 15px;
  width: 15px;
  background-color: ${props => (props.value ? '#FD2D2D' : '#28A745')};
  border-radius: 50%;
  margin: auto;
`;
