import pagination from '../../../../config/pagination';
import {
  ForeignTradeSupplierReducer,
  ForeignTradeSupplierReducerAction,
  ForeignTradeSupplierReducerActionKind,
} from './interfaces';

export const foreignTradeSupplierReducerInitialState: ForeignTradeSupplierReducer =
  {
    foreignTradeSuppliers: undefined,
    tabActiveIndex: 1,
    lazyParams: pagination.initialLazyParams,
  };

export const foreignTradeSuppliersReducer = (
  state: ForeignTradeSupplierReducer,
  action: ForeignTradeSupplierReducerAction,
): ForeignTradeSupplierReducer => {
  switch (action.type) {
    case ForeignTradeSupplierReducerActionKind.SET_FOREIGN_TRADE_SUPPLIERS:
      if (
        action.payload?.foreignTradeSuppliers &&
        state.lazyParams.page &&
        state.foreignTradeSuppliers
      ) {
        return {
          ...state,
          foreignTradeSuppliers: {
            items: action.payload.foreignTradeSuppliers.items,
            data: {
              ...state.foreignTradeSuppliers.data,
              ...action.payload.foreignTradeSuppliers.data,
            },
          },
        };
      }

      return {
        ...state,
        foreignTradeSuppliers: action.payload?.foreignTradeSuppliers,
        tabActiveIndex:
          action.payload?.tabActiveIndex ??
          foreignTradeSupplierReducerInitialState.tabActiveIndex,
      };

    case ForeignTradeSupplierReducerActionKind.RESET_STATE: {
      return foreignTradeSupplierReducerInitialState;
    }

    case ForeignTradeSupplierReducerActionKind.SET_LAZY_PARAMS: {
      if (action.payload?.lazyParams === undefined) return state;

      return { ...state, lazyParams: action.payload.lazyParams };
    }

    case ForeignTradeSupplierReducerActionKind.SET_ACTIVE_TAB_INDEX: {
      if (action.payload?.tabActiveIndex === undefined) return state;

      return { ...state, tabActiveIndex: action.payload.tabActiveIndex };
    }

    default:
      throw new Error();
  }
};
