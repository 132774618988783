import React, { useEffect } from 'react';

import { FaTimes } from 'react-icons/fa';
import { Container, DimensionItem } from './styles';

import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import FormInput from '../../../../components/FormInput';
import FormInputNumber from '../../../../components/FormInputNumber';
import { productRoles } from '../../../../shared/roles/product';
import MainButton from '../../../../components/MainButton';
import { IProduct, IProductDimension } from '../interfaces';
import generateRandomString from '../../../../utils/generateRandomString';
import FormDropdown from '../../../../components/FormDropdown';
import { useRefHook } from '../../../../hooks/useRefHook';
import {
  cmToIn,
  cmToM,
  cmToMm,
  inToCm,
  inToM,
  inToMm,
  kgToG,
  kgToMg,
  lToMl,
  mgToG,
  mgToKg,
  mlToL,
  mmToCm,
  mmToIn,
  mmToM,
  mToCm,
  mToIn,
  mToMm,
  gToMg,
  gToKg,
} from '../../../../utils/convertDimension';
import { showField } from '../../../../utils/showField';
import { IRoleEntityField } from '../../../../interfaces/IRoleEntityField';
import { definePermissionAndDisableField } from '../../../../utils/disableField';

interface IDimensionProps extends PageTabContainerProps {
  product: IProduct;
  changePropertyValue(property: string, value: any): void;
  roleEntityFields: IRoleEntityField[];
  userCanChangeFields: boolean;
}

const Dimension: React.FC<IDimensionProps> = ({
  selected,
  product,
  changePropertyValue,
  roleEntityFields,
  userCanChangeFields,
}) => {
  const lwhUnits = [
    { value: 'MM' },
    { value: 'CM' },
    { value: 'M' },
    { value: 'IN' },
  ];

  const vUnits = [{ value: 'ML' }, { value: 'L' }];

  const cUnits = [{ value: 'MG' }, { value: 'KG' }, { value: 'G' }];

  const gUnits = [{ value: 'GMS' }];

  // Referencia ao toast e formulario
  const { formRef } = useRefHook();

  function addDimensionItem() {
    let productDimensions: IProductDimension[];
    if (product.productDimensions) {
      productDimensions = [
        ...product.productDimensions,
        {
          idProductDimension: generateRandomString(4),
          lengthUnit: 'CM',
          inmetroLengthUnit: 'CM',
          widthUnit: 'CM',
          inmetroWidthUnit: 'CM',
          heightUnit: 'CM',
          inmetroHeightUnit: 'CM',
          diameterTopUnit: 'CM',
          inmetroDiameterTopUnit: 'CM',
          diameterBottomUnit: 'CM',
          inmetroDiameterBottomUnit: 'CM',
          thicknessUnit: 'CM',
          inmetroThicknessUnit: 'CM',
          inmetroDepthUnit: 'CM',
          depthUnit: 'CM',
          grammageUnit: 'GMS',
          inmetroGrammageUnit: 'GMS',
        } as IProductDimension,
      ];
    } else {
      productDimensions = [
        {
          idProductDimension: generateRandomString(4),
          lengthUnit: 'CM',
          inmetroLengthUnit: 'CM',
          widthUnit: 'CM',
          inmetroWidthUnit: 'CM',
          heightUnit: 'CM',
          inmetroHeightUnit: 'CM',
          diameterTopUnit: 'CM',
          inmetroDiameterTopUnit: 'CM',
          diameterBottomUnit: 'CM',
          inmetroDiameterBottomUnit: 'CM',
          thicknessUnit: 'CM',
          inmetroThicknessUnit: 'CM',
          inmetroDepthUnit: 'CM',
          depthUnit: 'CM',
          grammageUnit: 'GMS',
          inmetroGrammageUnit: 'GMS',
        } as IProductDimension,
      ];
    }

    changePropertyValue('productDimensions', productDimensions);
  }

  function removeDimensionItem(index: number) {
    const productDimensions = [...product.productDimensions];
    productDimensions.splice(index, 1);
    changePropertyValue('productDimensions', productDimensions);
  }

  // Altera o valor das dimensions para serem utilizadas no autocomplete
  function changeValue(name: string, value: any, index: number) {
    const newDimensions = [...product.productDimensions];
    newDimensions[index][name] = value;

    changePropertyValue(`productDimensions`, newDimensions);
  }

  /**
   * Efetua conversoes de dimensoes para valores inmetros
   * @param fieldValue Valor do input
   * @param index Indice do input
   * @param unit Unidade do input
   * @param inmetroProperty Propriedade Inmetro correspondente
   */
  function parseUnit(
    index: number,
    property: string,
    inmetroProperty: string,
    paramPropertyUnit?: string,
    paramInmetroUnit?: string,
  ) {
    const fieldValue = formRef.current?.getFieldValue(
      `productDimensions[${index}].${property}`,
    );

    if (!fieldValue) {
      formRef.current?.setFieldValue(
        `productDimensions[${index}].${inmetroProperty}`,
        '',
      );
      changeValue(property, fieldValue, index);
      return;
    }

    const propertyUnit =
      paramPropertyUnit ||
      formRef.current?.getFieldValue(
        `productDimensions[${index}].${property}Unit`,
      );

    const inmetroUnit =
      paramInmetroUnit ||
      formRef.current?.getFieldValue(
        `productDimensions[${index}].${inmetroProperty}Unit`,
      );

    switch (propertyUnit) {
      case 'MM':
        switch (inmetroUnit) {
          case 'CM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mmToCm(fieldValue).toFixed(4)),
            );
            break;
          case 'M':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mmToM(fieldValue).toFixed(4)),
            );
            break;
          case 'IN':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mmToIn(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'CM':
        switch (inmetroUnit) {
          case 'MM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(cmToMm(fieldValue).toFixed(4)),
            );
            break;
          case 'M':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(cmToM(fieldValue).toFixed(4)),
            );
            break;
          case 'IN':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(cmToIn(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'M':
        switch (inmetroUnit) {
          case 'MM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mToMm(fieldValue).toFixed(4)),
            );
            break;
          case 'CM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mToCm(fieldValue).toFixed(4)),
            );
            break;
          case 'IN':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mToIn(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'IN':
        switch (inmetroUnit) {
          case 'MM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(inToMm(fieldValue).toFixed(4)),
            );
            break;
          case 'CM':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(inToCm(fieldValue).toFixed(4)),
            );
            break;
          case 'M':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(inToM(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'ML':
        switch (inmetroUnit) {
          case 'L':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mlToL(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'L':
        switch (inmetroUnit) {
          case 'ML':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(lToMl(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'MG':
        switch (inmetroUnit) {
          case 'KG':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mgToKg(fieldValue).toFixed(4)),
            );
            break;
          case 'G':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(mgToG(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'KG':
        switch (inmetroUnit) {
          case 'MG':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(kgToMg(fieldValue).toFixed(4)),
            );
            break;
          case 'G':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(kgToG(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      case 'G':
        switch (inmetroUnit) {
          case 'MG':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(gToMg(fieldValue).toFixed(4)),
            );
            break;
          case 'KG':
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              parseFloat(gToKg(fieldValue).toFixed(4)),
            );
            break;
          default:
            formRef.current?.setFieldValue(
              `productDimensions[${index}].${inmetroProperty}`,
              fieldValue,
            );
            break;
        }
        break;
      default:
        formRef.current?.setFieldValue(
          `productDimensions[${index}].${inmetroProperty}`,
          fieldValue,
        );
        break;
    }

    const value = formRef.current?.getFieldValue(
      `productDimensions[${index}].${property}`,
    );

    const unitValue = formRef.current?.getFieldValue(
      `productDimensions[${index}].${property}Unit`,
    );

    changeValue(property, value, index);
    changeValue(`${property}Unit`, unitValue, index);
  }

  /**
   * Adiciona item de Dimension automaticamente ao criar novo produto
   */
  useEffect(() => {
    if (!product.productDimensions) {
      const productDimensions = [
        {
          idProductDimension: generateRandomString(4),
          lengthUnit: 'CM',
          inmetroLengthUnit: 'CM',
          widthUnit: 'CM',
          inmetroWidthUnit: 'CM',
          heightUnit: 'CM',
          inmetroHeightUnit: 'CM',
          diameterTopUnit: 'CM',
          inmetroDiameterTopUnit: 'CM',
          diameterBottomUnit: 'CM',
          inmetroDiameterBottomUnit: 'CM',
          thicknessUnit: 'CM',
          inmetroThicknessUnit: 'CM',
          inmetroDepthUnit: 'CM',
          depthUnit: 'CM',
        } as IProductDimension,
      ];

      // Altera array atual pelo novo no objeto de productDimensions
      changePropertyValue('productDimensions', productDimensions);
    }
  }, [changePropertyValue, product.productDimensions]);

  return (
    <PageTabContainer selected={selected}>
      <Container>
        {product.productDimensions &&
          product.productDimensions.map((productDimension, index) => {
            return (
              <DimensionItem key={productDimension.idProductDimension}>
                <div className="name-input-div">
                  {showField(
                    productRoles.fields.idFieldDimensionNameEn,
                    roleEntityFields,
                  ) && (
                    <FormInput
                      name={`productDimensions[${index}].nameEn`}
                      label="Dimension Name (EN)"
                      onBlur={e => changeValue('nameEn', e.target.value, index)}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldDimensionNameEn,
                          roleEntityFields,
                          productDimension.idProductDimension,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  {showField(
                    productRoles.fields.idFieldDimensionNamePt,
                    roleEntityFields,
                  ) && (
                    <FormInput
                      name={`productDimensions[${index}].namePt`}
                      label="Dimension Name (PT)"
                      onBlur={e => changeValue('namePt', e.target.value, index)}
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldDimensionNamePt,
                          roleEntityFields,
                          productDimension.idProductDimension,
                        ) || !userCanChangeFields
                      }
                    />
                  )}
                </div>
                <div className="inputs-div">
                  {showField(
                    productRoles.fields.idFieldQuantity,
                    roleEntityFields,
                  ) && (
                    <FormInputNumber
                      className="quantity-input"
                      name={`productDimensions[${index}].quantity`}
                      label="Quantity"
                      onBlur={e =>
                        changeValue('quantity', e.target.value, index)
                      }
                      readOnly={
                        definePermissionAndDisableField(
                          productRoles.fields.idFieldQuantity,
                          roleEntityFields,
                          productDimension.idProductDimension,
                        ) || !userCanChangeFields
                      }
                    />
                  )}

                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldVolume,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].volume`}
                          label="Volume"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'volume', 'inmetroVolume')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldVolume,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldVolumeUnit,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].volumeUnit`}
                          options={vUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].volumeUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'volume',
                              'inmetroVolume',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldVolumeUnit,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldVolume,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroVolume`}
                          label="Volume"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldVolumeUnit,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroVolumeUnit`}
                          options={vUnits}
                          optionLabel="value"
                          label="Unit"
                          initialValue={
                            product.productDimensions[index].inmetroVolumeUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'volume',
                              'inmetroVolume',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldVolumeUnit,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldCapacity,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].capacity`}
                          label="Capacity"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'capacity', 'inmetroCapacity')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldCapacity,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitCapacity,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].capacityUnit`}
                          options={cUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].capacityUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'capacity',
                              'inmetroCapacity',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitCapacity,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldCapacity,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroCapacity`}
                          label="Capacity"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitCapacity,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroCapacityUnit`}
                          label="Unit"
                          options={cUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroCapacityUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'capacity',
                              'inmetroCapacity',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitCapacity,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                {product?.indConfection && (
                  <div className="h2-split">
                    <h2>Folded pcs&#39; dimensions</h2>
                  </div>
                )}
                <div className="inputs-div">
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldLength,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].length`}
                          label="Length"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'length', 'inmetroLength')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldLength,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitLength,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].lengthUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].lengthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'length',
                              'inmetroLength',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitLength,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldLength,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroLength`}
                          label="Length"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitLength,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroLengthUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroLengthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'length',
                              'inmetroLength',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitLength,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldWidth,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].width`}
                          label="Width"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'width', 'inmetroWidth')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldWidth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitWidth,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].widthUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].widthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'width',
                              'inmetroWidth',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitWidth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldWidth,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroWidth`}
                          label="Width"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitWidth,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroWidthUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroWidthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'width',
                              'inmetroWidth',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitWidth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldHeight,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].height`}
                          label="Height"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'height', 'inmetroHeight')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldHeight,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitHeight,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].heightUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].heightUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'height',
                              'inmetroHeight',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitHeight,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldHeight,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroHeight`}
                          label="Height"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitHeight,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroHeightUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroHeightUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'height',
                              'inmetroHeight',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitHeight,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldTopDiameter,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].diameterTop`}
                          label="Top Diameter"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(
                              index,
                              'diameterTop',
                              'inmetroDiameterTop',
                            )
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldTopDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitTopDiameter,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].diameterTopUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].diameterTopUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'diameterTop',
                              'inmetroDiameterTop',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitTopDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldTopDiameter,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroDiameterTop`}
                          label="Top Diameter"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitTopDiameter,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroDiameterTopUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index]
                              .inmetroDiameterTopUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'diameterTop',
                              'inmetroDiameterTop',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitTopDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldBottomDiameter,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].diameterBottom`}
                          label="Bottom Diameter"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(
                              index,
                              'diameterBottom',
                              'inmetroDiameterBottom',
                            )
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldBottomDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitBottomDiameter,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].diameterBottomUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].diameterBottomUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'diameterBottom',
                              'inmetroDiameterBottom',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitBottomDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldBottomDiameter,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroDiameterBottom`}
                          label="Bottom Diameter"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitBottomDiameter,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroDiameterBottomUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index]
                              .inmetroDiameterBottomUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'diameterBottom',
                              'inmetroDiameterBottom',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitBottomDiameter,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldThickness,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].thickness`}
                          label="Thickness"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'thickness', 'inmetroThickness')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldThickness,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitThickness,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].thicknessUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].thicknessUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'thickness',
                              'inmetroThickness',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitThickness,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldThickness,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroThickness`}
                          label="Thickness"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitThickness,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroThicknessUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index]
                              .inmetroThicknessUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'thickness',
                              'inmetroThickness',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitThickness,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldDepth,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].depth`}
                          label="Depth"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'depth', 'inmetroDepth')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldDepth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitDepth,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].depthUnit`}
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].depthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'depth',
                              'inmetroDepth',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitDepth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldDepth,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroDepth`}
                          label="Depth"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitDepth,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroDepthUnit`}
                          label="Unit"
                          options={lwhUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroDepthUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'depth',
                              'inmetroDepth',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitDepth,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="item-content">
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldGrammage,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].grammage`}
                          label="Grammage"
                          decimalScale={4}
                          onBlur={() =>
                            parseUnit(index, 'grammage', 'inmetroGrammage')
                          }
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldGrammage,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitGrammage,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          label="Unit"
                          name={`productDimensions[${index}].grammageUnit`}
                          options={gUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].grammageUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'grammage',
                              'inmetroGrammage',
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitGrammage,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                    <div className="sub-item">
                      {showField(
                        productRoles.fields.idFieldGrammage,
                        roleEntityFields,
                      ) && (
                        <FormInputNumber
                          name={`productDimensions[${index}].inmetroGrammage`}
                          label="Grammage"
                          readOnly
                          decimalScale={4}
                        />
                      )}

                      {showField(
                        productRoles.fields.idFieldUnitGrammage,
                        roleEntityFields,
                      ) && (
                        <FormDropdown
                          className="unit"
                          name={`productDimensions[${index}].inmetroGrammageUnit`}
                          label="Unit"
                          options={gUnits}
                          optionLabel="value"
                          initialValue={
                            product.productDimensions[index].inmetroGrammageUnit
                          }
                          onValueChange={e => {
                            parseUnit(
                              index,
                              'grammage',
                              'inmetroGrammage',
                              undefined,
                              e.toString(),
                            );
                          }}
                          readOnly={
                            definePermissionAndDisableField(
                              productRoles.fields.idFieldUnitGrammage,
                              roleEntityFields,
                              productDimension.idProductDimension,
                            ) || !userCanChangeFields
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="button-div">
                  <button
                    type="button"
                    onClick={() => removeDimensionItem(index)}
                    disabled={!userCanChangeFields}
                  >
                    <FaTimes size={18} />
                  </button>
                </div>
              </DimensionItem>
            );
          })}

        <MainButton
          label="Add dimension"
          onClick={() => addDimensionItem()}
          disabled={!userCanChangeFields}
          type="button"
        />
      </Container>
    </PageTabContainer>
  );
};

export default Dimension;
